import axios from 'axios';

import {
  STAFF_LOADING,
  STAFF_CREATED,
  STAFF_CREATE_ERROR,
  STAFF_FAIL,
  STAFF_MODAL_LOADING,
  STAFF_DETAIL_PASSWORD_UPDATED,
  STAFF_DETAIL_PASSWORD_UPDATE_ERROR,
  STAFF_UPDATED,
  STAFF_UPDATE_ERROR,
  STAFF_DELETED,
  STAFF_DELETE_ERROR,
  STAFF_LIST_UPDATED,
  GET_STAFF_BY_ID,
  STAFF_CHANGE_STATUS,
  STAFF_CHANGE_STATUS_ERROR,
  GET_STAFF_BY_ID_ERROR,
  SET_ERRORS_LIST,
} from 'actions/types';

import { updateFilterData } from 'actions/commonActions';

// Create Staff
export const create = (formData, navigate) => async (dispatch) => {
  try {
    dispatch({
      type: STAFF_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.post('/api/admin/staff/create', formData, config);
    if (res.data.status === true) {
      dispatch({
        type: STAFF_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      navigate('/admin/staff-members');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: STAFF_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
    }
  } catch (err) {
    dispatch({
      type: STAFF_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit Password
export const editPassword = (formData, staff_id) => async (dispatch) => {
  dispatch({
    type: STAFF_MODAL_LOADING,
    payload: true,
  });
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.post(
      `/api/admin/staff/edit/password/${staff_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: STAFF_DETAIL_PASSWORD_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: STAFF_DETAIL_PASSWORD_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: STAFF_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit Staff
export const edit = (formData, navigate, staff_id) => async (dispatch) => {
  try {
    dispatch({
      type: STAFF_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.post(
      `/api/admin/staff/${staff_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: STAFF_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      navigate('/admin/staff-members');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: STAFF_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
    }
  } catch (err) {
    dispatch({
      type: STAFF_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete Staff
export const deleteStaff = (staff_id) => async (dispatch) => {
  try {
    dispatch({
      type: STAFF_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(`/api/admin/staff/${staff_id}`, config);
    if (res.data.status === true) {
      await dispatch({
        type: STAFF_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: STAFF_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: STAFF_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get staff list

export const getStaffList = (staffParams) => async (dispatch) => {
  try {
    dispatch({
      type: STAFF_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    staffParams = updateFilterData(staffParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: staffParams,
    };

    const res = await axios.get(`/api/admin/staff/`, config);
    dispatch({
      type: STAFF_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          staffParams: staffParams,
          staffList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    dispatch({
      type: STAFF_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// get Staff by id
export const getStaffById = (staff_id, navigate) => async (dispatch) => {
  try {
    dispatch({
      type: STAFF_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/staff/${staff_id}`, config);
    if (res.data.status === true) {
      await dispatch({
        type: GET_STAFF_BY_ID,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      dispatch({
        type: GET_STAFF_BY_ID_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: {},
        },
      });
      navigate('/admin/staff-members');
    }
  } catch (err) {
    dispatch({
      type: STAFF_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    navigate('/admin/staff-members');
  }
};

//change status
export const changeStatus = (staff_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: STAFF_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/staff/change-status/${staff_id}`,
      { status },
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: STAFF_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: STAFF_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: staff_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: STAFF_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
