import React, {
    useEffect,
    useState,
    useRef,
    useMemo,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate, useMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { getShippingBoxList } from 'actions/admin/shippingBox';
import { getGearShippingBoxList } from 'actions/admin/gearShippingBox';
import { getOrderShippingBoxDetails, getOrderById, downloadInvoice, getRatesForEditedShippingBoxes, saveEditedShippingBoxes } from 'actions/admin/order';
import {
    Button,
    Table,
    Card,
    CardBody,
    Col,
    Row,
    CardFooter,
    CardHeader,
    Form,
    FormGroup,
    Label,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import Spinner from 'views/Spinner';
import orderShippingBoxes from 'reducers/admin/orderShippingBoxes';
import errors from 'reducers/errors';


const EditOrderShippingBoxes = ({ getOrderShippingBoxDetails, getShippingBoxList, getOrderById, OrderShippingBoxDetails, loading, auth: { user }, shippingBoxList, currentOrder, downloadInvoice, getRatesForEditedShippingBoxes, saveEditedShippingBoxes, getGearShippingBoxList, gearShippingBoxList }) => {
    const history = useNavigate();
    const inititalCustomBox = {
        box: { name: "", length: "", width: "", height: "", weight: "", min_quantity: "", max_quantity: "" },
        items: [],
        orderId: "",
        shipping_address: {},
        shipping_box_status: 1,
        weight: ""
    }
    const [formData, setFormData] = useState({ orderDetails: {}, orderShippingBoxes: [] });
    const [orderProducts, setOrderProducts] = useState([]);
    const [openAddShippingBox, setOpenAddShippingBox] = useState(false);
    const [shippingBoxQuantityError, setshippingBoxQuantityError] = useState(false);
    const [itemQuantityError, setItemQuantityError] = useState(false);
    const [shippingBoxQuantityErrorMessage, setShippingBoxQuantityErrorMessage] = useState('');
    const [itemQuantityErrorMessage, setItemQuantityErrorMessage] = useState([]);
    const [customShippingBox, setCustomShippingBox] = useState(inititalCustomBox);
    const [downloadInvoiceURL, setDownloadInvoiceURL] = useState('');
    const [submitResponseStatus, setSubmitResponseStatus] = useState(false);
    const [submitResponse, setSubmitResponse] = useState({ editedShippingBoxes: [], newShippingRate: {}, oldShippingRate: {}, redirect: false });
    const [finalSubmitModalLoading, setFinalSubmitModalLoading] = useState(false);
    const [shippingBoxes, setShippingBoxes] = useState([]);
    const [gearShippingBoxes, setGearShippingBoxes] = useState([]);
    const [payer, setPayer] = useState("");
    const [shippingBoxType, setShippingBoxType] = useState("SHAFTS");

    const onOpenAddShippingBox = () => {
        setOpenAddShippingBox(true);
        setCustomShippingBox({ ...inititalCustomBox, box: { name: shippingBoxes[0]?.name, width: shippingBoxes[0]?.width, length: shippingBoxes[0]?.length, height: shippingBoxes[0]?.height, weight: shippingBoxes[0]?.weight, min_quantity: shippingBoxes[0]?.min_quantity, max_quantity: shippingBoxes[0]?.max_quantity } })
    }

    const onCloseAddShippingBox = () => {
        setCustomShippingBox(inititalCustomBox)
        setOpenAddShippingBox(false);
        setshippingBoxQuantityError(false);
        setItemQuantityError(false);
        setItemQuantityErrorMessage([]);
        setShippingBoxType("SHAFTS");
    }


    const onCloseFinalSubmitModal = () => {
        setSubmitResponseStatus(false);
    }

    const onChangePayer = (e) => {
        setPayer(e.target.value)
    }

    const onChangeShippingBoxType = (e) => {
        setShippingBoxType(e.target.value);
        const items = document.getElementsByName("item");
        for (let item of items) {
            item.value = 0
        }
        if (e.target.value === "SHAFTS") {
            setCustomShippingBox({ ...inititalCustomBox, box: { name: shippingBoxes[0]?.name, width: shippingBoxes[0]?.width, length: shippingBoxes[0]?.length, height: shippingBoxes[0]?.height, weight: shippingBoxes[0]?.weight, min_quantity: shippingBoxes[0]?.min_quantity, max_quantity: shippingBoxes[0]?.max_quantity } })
        } else {
            setCustomShippingBox({ ...inititalCustomBox, box: { name: gearShippingBoxes[0]?.name, width: gearShippingBoxes[0]?.width, length: gearShippingBoxes[0]?.length, height: gearShippingBoxes[0]?.height, weight: gearShippingBoxes[0]?.weight, min_quantity: gearShippingBoxes[0]?.min_quantity, max_quantity: gearShippingBoxes[0]?.max_quantity } })
        }
    }

    const match = useMatch('/admin/orders/edit-shipping-boxes/:order_id');

    useEffect(() => {
        getOrderShippingBoxDetails(match.params.order_id);
        getOrderById(match.params.order_id)
        getShippingBoxList({
            limit: 50,
            page: 1,
            orderBy: 'created_at',
            ascending: 'desc',
            query: '',
            filter: { "is_customised": { "filterType": "boolean", "type": "equals", "filter": false } },
            onLoad: true,
        });
        getGearShippingBoxList({
            limit: 50,
            page: 1,
            orderBy: 'created_at',
            ascending: 'desc',
            query: '',
            filter: {},
            onLoad: true,
        })
    }, [getOrderShippingBoxDetails, getShippingBoxList, getOrderById, getGearShippingBoxList, match.params.order_id])

    useEffect(() => {
        const filteredShippingBoxes = OrderShippingBoxDetails?.orderShippingBoxes?.filter((box) => {
            const customProduct = box?.items?.find((item) => item?.isCustomised === true);
            if (customProduct !== undefined) {
                box["isCustomised"] = true;
            } else {
                box["isCustomised"] = false;
            }
            const newBox = box?.items?.map((a) => {
                let itemOrderTotal = currentOrder?.products?.find((prod) => a.isCustomised === prod.fitting_options?.length > 0 && a.product_variation_sku === prod.sku)
                if (a.isCustomised === true) {
                    itemOrderTotal = currentOrder?.products?.find((prod) => a.isCustomised === prod.fitting_options.length > 0 && a.product_variation_sku === prod.sku && a.fitting_options[0]?.option_id === prod.fitting_options[0]?.option_id && a.fitting_options[0]?.sub_option_id === prod.fitting_options[0]?.sub_option_id && a.fitting_options[1]?.option_id === prod.fitting_options[1]?.option_id && a.fitting_options[1]?.sub_option_id === prod.fitting_options[1]?.sub_option_id && a.fitting_options[2]?.option_id === prod.fitting_options[2]?.option_id && a.fitting_options[3]?.attribute_value === prod.fitting_options[3]?.attribute_value && a.fitting_options[4]?.attribute_value === prod.fitting_options[4]?.attribute_value)
                }

                if (!itemOrderTotal) {
                    itemOrderTotal = currentOrder?.buildShopSupplyProduct?.find((prod) => prod?.sku === a?.product_variation_sku)
                }
                if (!itemOrderTotal) {
                    return {
                        ...a,
                        order_quantity: a?.quantity
                    }
                }
                return {
                    ...a,
                    order_quantity: itemOrderTotal?.quantity
                }
            });
            box['items'] = newBox
            return box
        })
        const result = filteredShippingBoxes?.sort((a, b) => a.isCustomised === true ? 1 : -1);

        let order_Products = [];
        OrderShippingBoxDetails?.orderShippingBoxes?.map((shipbox) => {
            shipbox.items.map((shipitem) => {
                let itemExist = order_Products.findIndex((itm) => itm.product_variation_sku === shipitem.product_variation_sku && itm.isCustomised === shipitem.isCustomised);
                let productIndex = itemExist;
                if (shipitem.isCustomised === true) {
                    itemExist = order_Products.findIndex((itm) => itm.product_variation_sku === shipitem.product_variation_sku && itm.isCustomised === shipitem.isCustomised && shipitem?.fitting_options[0]?.option_id === itm?.fitting_options[0]?.option_id &&
                        shipitem?.fitting_options[0]?.sub_option_id === itm?.fitting_options[0]?.sub_option_id &&
                        shipitem?.fitting_options[1]?.option_id === itm?.fitting_options[1]?.option_id &&
                        shipitem?.fitting_options[1]?.sub_option_id === itm?.fitting_options[1]?.sub_option_id &&
                        shipitem?.fitting_options[2]?.option_id === itm?.fitting_options[2]?.option_id &&
                        shipitem?.fitting_options[3]?.attribute_value === itm?.fitting_options[3]?.attribute_value &&
                        shipitem?.fitting_options[4]?.attribute_value === itm?.fitting_options[4]?.attribute_value);

                    if (itemExist >= 0) {
                        productIndex = itemExist;
                    } else {
                        productIndex = -1;
                    }
                }
                if (productIndex >= 0) {
                    order_Products[productIndex] = { ...order_Products[productIndex], quantity: Number(order_Products[productIndex]?.quantity) + shipitem.quantity }
                } else {
                    order_Products.push(shipitem)
                }
            });

        });
        setOrderProducts(order_Products);
        setFormData({ ...formData, orderDetails: OrderShippingBoxDetails?.orderDetails, orderShippingBoxes: result });

        if (shippingBoxList.data && shippingBoxList.data?.length > 0) {
            const shaftBoxes = shippingBoxList.data?.filter((box) => box.status === 1).map((box) => { return { ...box, type: "SHAFTS" } });
            setShippingBoxes(shaftBoxes);
        }
        if (gearShippingBoxList.data && gearShippingBoxList.data?.length > 0) {
            const gearBoxes = gearShippingBoxList.data?.filter((box) => box.status === 1).map((box) => { return { ...box, type: "OTHER" } });
            setGearShippingBoxes(gearBoxes)
        }
    }, [OrderShippingBoxDetails, currentOrder, shippingBoxList, gearShippingBoxList])

    const onSelectShippingBox = (e) => {
        const box = JSON.parse(e.target.value);
        setshippingBoxQuantityError(false);
        setItemQuantityError(false);
        setItemQuantityErrorMessage([]);
        const items = document.getElementsByName("item");
        for (let item of items) {
            item.value = 0
        }
        setCustomShippingBox({ ...customShippingBox, items: [], [e.target.name]: { name: box.name, width: box.width, length: box.length, height: box.height, weight: box.weight, min_quantity: box.min_quantity, max_quantity: box.max_quantity } })
    }

    const onChangeModalProductQuantity = (e, item) => {
        let product = customShippingBox.items.find((itm) => itm.isCustomised === item.isCustomised && itm.product_variation_sku === item.product_variation_sku);
        if (item.isCustomised === true) {
            product = customShippingBox.items.find((itm) => itm.isCustomised === item.isCustomised && itm.product_variation_sku === item.product_variation_sku && itm.fitting_options[0]?.option_id === item.fitting_options[0]?.option_id && itm.fitting_options[0]?.sub_option_id === item.fitting_options[0]?.sub_option_id && itm.fitting_options[1]?.option_id === item.fitting_options[1]?.option_id && itm.fitting_options[1]?.sub_option_id === item.fitting_options[1]?.sub_option_id && itm.fitting_options[2]?.option_id === item.fitting_options[2]?.option_id && itm.fitting_options[3]?.attribute_value === item.fitting_options[3]?.attribute_value && itm.fitting_options[4]?.attribute_value === item.fitting_options[4]?.attribute_value);
        }
        if (product !== undefined) {

            const newItems = customShippingBox.items.map((a) => {
                if (a.isCustomised === product.isCustomised && a.product_variation_sku === product.product_variation_sku && a.fitting_options[0]?.option_id === product.fitting_options[0]?.option_id && a.fitting_options[0]?.sub_option_id === product.fitting_options[0]?.sub_option_id && a.fitting_options[1]?.option_id === product.fitting_options[1]?.option_id && a.fitting_options[1]?.sub_option_id === product.fitting_options[1]?.sub_option_id && a.fitting_options[2]?.option_id === product.fitting_options[2]?.option_id && a.fitting_options[3]?.attribute_value === product.fitting_options[3]?.attribute_value && a.fitting_options[4]?.attribute_value === product.fitting_options[4]?.attribute_value) {
                    return { ...a, quantity: Number(e.target.value) }
                }
                return a;
            });
            setCustomShippingBox({ ...customShippingBox, items: newItems })

        } else {
            setCustomShippingBox({ ...customShippingBox, items: [...customShippingBox.items, { ...item, quantity: Number(e.target.value) }] })
        }

    }

    const onChangeProductQuantity = (e, boxIndex, itemIndex) => {
        setItemQuantityError(false);
        setItemQuantityErrorMessage([]);
        let newOrderShippingBoxes = formData.orderShippingBoxes.map((item, indx) => {
            if (indx !== boxIndex) {
                return item;
            }
            item.items[itemIndex] = { ...item.items[itemIndex], quantity: e.target.value }
            return item;
        });
        setFormData({ ...formData, orderShippingBoxes: newOrderShippingBoxes })
    }

    const onClickDownloadInvoice = async (orderId) => {
        let invoiceRespose = await downloadInvoice(orderId);
        if (invoiceRespose) {
            setDownloadInvoiceURL(invoiceRespose.data);
            document.getElementById('downloadInvoice').click();
        }
    }

    const onClickDeleteShippingBox = (index) => {
        let orderShippingBoxes = formData.orderShippingBoxes;
        orderShippingBoxes.splice(index, 1);
        setFormData({ ...formData, orderShippingBoxes: orderShippingBoxes })
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        let modified_order_Products = [];
        if (formData.orderShippingBoxes?.length === 0) {
            setItemQuantityErrorMessage(["You haven't assigned any shipping box to the order...!"]);
            setItemQuantityError(true);
            return;
        }
        formData.orderShippingBoxes?.map((shipbox) => {
            shipbox.items.map((shipitem, ind) => {
                let itemExist = modified_order_Products.findIndex((itm) => itm.product_variation_sku === shipitem.product_variation_sku && itm.isCustomised === shipitem.isCustomised);
                let productIndex = itemExist;
                if (shipitem.isCustomised === true) {
                    itemExist = modified_order_Products.findIndex((itm) => itm.product_variation_sku === shipitem.product_variation_sku && itm.isCustomised === shipitem.isCustomised && shipitem?.fitting_options[0]?.option_id === itm.fitting_options[0]?.option_id &&
                        shipitem?.fitting_options[0]?.sub_option_id === itm.fitting_options[0]?.sub_option_id &&
                        shipitem?.fitting_options[1]?.option_id === itm.fitting_options[1]?.option_id &&
                        shipitem?.fitting_options[1]?.sub_option_id === itm.fitting_options[1]?.sub_option_id &&
                        shipitem?.fitting_options[2]?.option_id === itm.fitting_options[2]?.option_id &&
                        shipitem?.fitting_options[3]?.attribute_value === itm.fitting_options[3]?.attribute_value &&
                        shipitem?.fitting_options[4]?.attribute_value === itm.fitting_options[4]?.attribute_value);
                    if (itemExist >= 0) {
                        productIndex = itemExist;
                    } else {
                        productIndex = -1;
                    }
                }
                if (productIndex >= 0) {
                    modified_order_Products[productIndex] = { ...modified_order_Products[productIndex], quantity: Number(modified_order_Products[productIndex]?.quantity) + Number(shipitem.quantity) }
                } else {
                    modified_order_Products.push(shipitem)
                }
            });
        });
        let quantityError = false;
        let errorMessages = [];

        orderProducts.map((item) => {
            let addedItem = modified_order_Products.find((prod) => prod.isCustomised === item.isCustomised && prod.product_variation_sku === item.product_variation_sku);
            if (item.isCustomised === true) {
                addedItem = modified_order_Products.find((prod) => prod.isCustomised === item.isCustomised && prod.product_variation_sku === item.product_variation_sku && prod.fitting_options[0]?.option_id === item.fitting_options[0]?.option_id && prod.fitting_options[0]?.sub_option_id === item.fitting_options[0]?.sub_option_id && prod.fitting_options[1]?.option_id === item.fitting_options[1]?.option_id && prod.fitting_options[1]?.sub_option_id === item.fitting_options[1]?.sub_option_id && prod.fitting_options[2]?.option_id === item.fitting_options[2]?.option_id && prod.fitting_options[3]?.attribute_value === item.fitting_options[3]?.attribute_value && prod.fitting_options[4]?.attribute_value === item.fitting_options[4]?.attribute_value);
            }
            if (addedItem === undefined || addedItem.quantity === "") {
                errorMessages.push(`You have assigned 0 Item of ${item.product_title}(${item.category_name}) out of ${item.quantity}`);
                quantityError = true;
            } else {
                if (Number(addedItem.quantity) > Number(item.quantity) || Number(addedItem.quantity) < Number(item.quantity)) {
                    if ((Number(addedItem.quantity) === 1) || (Number(addedItem.quantity) === 0)) {
                        errorMessages.push(`You have assigned ${addedItem.quantity} Item of ${item.product_title}(${item.category_name}) out of ${item.quantity}`);
                    } else {
                        errorMessages.push(`You have assigned ${addedItem.quantity} Items of ${item.product_title}(${item.category_name}) out of ${item.quantity}`);
                    }
                    quantityError = true;
                }
            }
        });
        setItemQuantityErrorMessage([...errorMessages])
        if (quantityError) {
            setItemQuantityError(true)
            return;
        }

        const filteredProducts = formData.orderShippingBoxes.map((box) => {
            const filteredItems = box.items.filter((item) =>
                Number(item.quantity) > 0
            );
            return { ...box, items: [...filteredItems] }
        });
        const finalProducts = filteredProducts.filter((item) => item.items?.length > 0);
        const response = await getRatesForEditedShippingBoxes(match.params.order_id, finalProducts);
        if (response.status) {
            if (response.response?.redirect === true) {
                history(`/admin/orders/shipping-boxes/${match.params.order_id}`);
                return;
            }
            setSubmitResponse(response.response);
            setSubmitResponseStatus(response.status);
        }

    }

    const onSubmitModal = (e) => {
        e.preventDefault();
        setshippingBoxQuantityError(false);
        setItemQuantityError(false);
        setItemQuantityErrorMessage([]);
        if (customShippingBox?.box?.name === "") {
            setShippingBoxQuantityErrorMessage("Please select the shipping box..!");
            setshippingBoxQuantityError(true);
            return;
        }
        const selectedShippingBoxMinQuantity = customShippingBox?.box?.min_quantity;
        const selectedShippingBoxMaxQuantity = customShippingBox?.box?.max_quantity;
        let addedQuantity = 0;
        customShippingBox.items.map((item) => addedQuantity += item.quantity);
        if (addedQuantity < selectedShippingBoxMinQuantity) {
            setShippingBoxQuantityErrorMessage("Added items quantity doesn't meet the minimum required quantity of selected shipping box");
            setshippingBoxQuantityError(true);
            return;
        };
        if (addedQuantity > selectedShippingBoxMaxQuantity) {
            setShippingBoxQuantityErrorMessage("Added items quantity crossed the maximum allowed quantity of selected shipping box");
            setshippingBoxQuantityError(true);
            return;
        }
        let moreQuantityError = false;
        customShippingBox.items.map((item) => {
            let orderedItem = orderProducts.find((prod) => prod.isCustomised === item.isCustomised && prod.product_variation_sku === item.product_variation_sku);
            if (item.isCustomised === true) {
                orderedItem = orderProducts.find((prod) => prod.isCustomised === item.isCustomised && prod.product_variation_sku === item.product_variation_sku && prod.fitting_options[0]?.option_id === item.fitting_options[0]?.option_id && prod.fitting_options[0]?.sub_option_id === item.fitting_options[0]?.sub_option_id && prod.fitting_options[1]?.option_id === item.fitting_options[1]?.option_id && prod.fitting_options[1]?.sub_option_id === item.fitting_options[1]?.sub_option_id && prod.fitting_options[2]?.option_id === item.fitting_options[2]?.option_id && prod.fitting_options[3]?.attribute_value === item.fitting_options[3]?.attribute_value && prod.fitting_options[4]?.attribute_value === item.fitting_options[4]?.attribute_value);
            }
            if (item.quantity > orderedItem.quantity) {
                setItemQuantityErrorMessage([...itemQuantityErrorMessage, `You have entered more quantity of ${item.product_title}(${item.category_name}) than ordered quantity`]);
                moreQuantityError = true;
                setItemQuantityError(true)
            }
        })
        if (moreQuantityError) {
            return
        }
        setFormData({ ...formData, orderShippingBoxes: [...formData.orderShippingBoxes, { ...customShippingBox, orderId: match.params.order_id, shipping_address: { ...currentOrder?.shipping_address, city: currentOrder?.shipping_city, country: currentOrder?.shipping_country, country_code: currentOrder?.shipping_country_code, state: currentOrder?.shipping_state, zipcode: currentOrder?.shipping_zipcode } }] });
        setCustomShippingBox(inititalCustomBox);
        onCloseAddShippingBox();

    }

    const onSubmitFinalModal = async (e) => {
        e.preventDefault();
        setFinalSubmitModalLoading(true);
        const response = await saveEditedShippingBoxes(match.params.order_id, { ...submitResponse, payer });
        console.log(response);
        if (response.status) {
            if (payer === "customer") {
                history("/admin/orders")
            }
            else {
                history(`/admin/orders/shipping-boxes/${match.params.order_id}`)
            }
        }
        setFinalSubmitModalLoading(false);
    }

    const { orderDetails, orderShippingBoxes } = formData;
    return loading ? <Spinner /> : (
        <>
            <div className="viewOrderDetailstab">
                <Form onSubmit={onSubmit}>
                    <Col md="12">
                        <Card>
                            <CardBody className="label-tag-header text-center">
                                <Label>
                                    {orderDetails ? <div>Order ID - ({orderDetails?.order_id})</div> : null}
                                </Label>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="12">
                        <Card>
                            <CardHeader style={{ display: "flex", justifyContent: "space-between" }}>
                                Edit Order Shipping Boxes
                                <div>
                                    <div className="add-button-div">
                                        <a
                                            href={downloadInvoiceURL}
                                            id="downloadInvoice"
                                            hidden
                                            className="hide"
                                            target="_blank"
                                            download
                                        ></a>
                                    </div>
                                    <Button type="button" size="sm" color="info" title="Download Invoice" onClick={() => onClickDownloadInvoice(match.params.order_id)} style={{ marginRight: "10px", height: "32px" }}>
                                        <svg
                                            id="Layer_1"
                                            data-name="Layer 1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 309 402"
                                        >
                                            <path d="m29.87,395.75c-2.97-1.23-6.16-2.11-8.87-3.76-7.21-4.41-10.86-11.09-11.32-19.52-.04-.75-.02-1.5-.02-2.24,0-110.75,0-221.51,0-332.26,0-13.18,7.69-22.82,20.02-25.09,1.7-.31,3.47-.36,5.21-.36,50.89-.02,101.77,0,152.66-.05,4.53,0,8.15,1.42,11.36,4.64,31.09,31.17,62.22,62.31,93.4,93.39,3.42,3.41,4.84,7.23,4.83,12.03-.07,81.44-.05,162.89-.05,244.33,0,16.92-4.4,23.2-20.21,28.9H29.87ZM177.33,36.72H33.92v334.86h238.97v-239.32c-1.61,0-2.96,0-4.32,0-22.2,0-44.41.05-66.61-.02-14.22-.04-24.54-10.37-24.61-24.57-.06-14.1-.01-28.19-.02-42.29,0-9.47,0-18.94,0-28.67Zm24.21,17.84v53.47h53.47c-17.82-17.82-35.65-35.65-53.47-53.47Z" />
                                            <path d="m93.5,85.66c0-4.44-.06-8.68.01-12.91.11-7.05,5.2-12.29,11.88-12.33,6.66-.04,11.9,5.2,12.04,12.18.09,4.35.02,8.7.02,12.07,6.67,3.41,13,6.08,18.71,9.73,6.5,4.14,7.76,11.57,3.77,17.2-4.12,5.8-11.34,6.63-17.82,2.05-9.67-6.83-22.8-7.14-32.36-.77-10.98,7.31-10.91,19.63.17,26.88,5.3,3.47,11.23,4.34,17.46,4.59,15.2.62,28.18,6.26,37.74,18.38,14.52,18.41,9.49,43.94-11.28,56.9-4.91,3.06-10.49,5.04-16.37,7.8,0,3.47.04,7.67,0,11.88-.08,7.33-5.11,12.67-11.9,12.7-6.79.04-11.94-5.32-12.03-12.58-.05-4.11,0-8.22,0-12.58-1.06-.37-2.13-.62-3.08-1.09-5.45-2.68-11.16-4.95-16.22-8.23-6.01-3.89-7.04-11.27-3.15-16.72,3.99-5.59,11.23-6.63,17.2-2.48,10.66,7.41,23.83,7.72,33.58.78,10.01-7.12,10.1-18.65.13-25.83-4.91-3.54-10.5-5.04-16.52-5.1-9.67-.09-18.84-2.28-27.05-7.44-13.78-8.66-22.1-20.83-20.58-37.56,1.6-17.58,12.56-28.42,28.25-35.02,2.28-.96,4.71-1.6,7.44-2.5Z" />
                                            <path d="m153.21,275.98c27.69,0,55.38-.02,83.08.01,9.19.01,15.22,8.02,12.1,16.09-1.89,4.88-5.61,7.56-10.85,7.8-3.61.17-7.23.04-10.85.04-51.89,0-103.78-.02-155.67.03-5.49,0-9.79-1.86-12.25-6.9-3.82-7.84,1.76-16.84,10.63-17.03,8.35-.18,16.71-.04,25.07-.04,19.58,0,39.17,0,58.75,0Z" />
                                            <path d="m153.75,323.89c27.57,0,55.13-.01,82.7.01,6.21,0,10.74,3.37,12.31,8.93,1.4,4.97-.69,10.74-5.42,13.14-2.43,1.23-5.46,1.79-8.22,1.79-51.14.1-102.28.07-153.43.07-3.37,0-6.74,0-10.1,0-8.6-.01-14.07-4.74-14.01-12.08.06-7.18,5.51-11.86,13.85-11.86,27.44,0,54.88,0,82.33,0Z" />
                                            <path d="m213.08,204.12c-7.61,0-15.22.03-22.82,0-7.61-.04-12.96-5.07-12.92-12.04.04-6.94,5.42-11.88,13.06-11.89,15.21-.03,30.43-.03,45.64,0,7.65.01,13.06,4.94,13.12,11.85.06,7.06-5.4,12.06-13.27,12.09-7.61.03-15.21,0-22.82,0Z" />
                                            <path d="m213.25,228.08c7.73,0,15.46-.04,23.2.01,7.32.05,12.67,5.09,12.72,11.87.05,6.8-5.29,12.02-12.54,12.05-15.59.06-31.18.06-46.77,0-7.29-.03-12.57-5.22-12.52-12.06.05-6.8,5.38-11.81,12.72-11.86,7.73-.05,15.46-.01,23.2-.01Z" />
                                            {' '}
                                        </svg>
                                    </Button>

                                    <Button type="button" color="primary" onClick={onOpenAddShippingBox}>
                                        Add Shipping Box
                                    </Button>
                                </div>
                            </CardHeader>
                            <CardBody>

                                {/* <Row>

                                    <Col sm="12">
                                        <Card>
                                            <CardHeader style={{ textAlign: "center" }}>
                                                Order Details
                                            </CardHeader>
                                        </Card>
                                    </Col>
                                    {currentOrder?.products?.map((item) => {
                                        return (

                                            <Col sm="4" key={item._id}>
                                                <Card>
                                                    <CardBody>
                                                        <div >
                                                            <p>{`${item.product_title}(${item.category_name[0]})`}</p>
                                                            <p>SKU - {item.sku}</p>
                                                            <p>Quantity - {item.quantity}</p>
                                                            <p>{item?.categoryType[0] === "SHAFTS" && item.fitting_options.length > 0 ? "Customized Shaft" : null}</p>
                                                            <p>{item?.categoryType[0] === "SHAFTS" && item.fitting_options.length === 0 ? "Shaft" : null}</p>
                                                            <p>{item?.categoryType[0] === "OTHER" ? "Gear" : null}</p>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>

                                        )
                                    })}

                                </Row> */}

                                <Row>
                                    <Col sm="12">
                                        <Card>
                                            <CardHeader style={{ textAlign: "center" }}>
                                                Order Shipping Boxes
                                            </CardHeader>
                                        </Card>
                                    </Col>



                                    <Col sm="12">
                                        <Card>
                                            <CardBody>
                                                <Table responsive className='shippingboxestable' border="1">
                                                    <thead>
                                                        <tr>
                                                            <th rowspan="2">Box Name</th>
                                                            <th colspan="3" className="shippingproductdetails">Product Details</th>
                                                            <th rowspan="2">Actions</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Product</th>
                                                            <th>SKU</th>
                                                            <th>Quantity</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            orderShippingBoxes?.map((item, index) => {
                                                                return (
                                                                    item.items?.length > 0 ? item.items?.map((prod, ind) => {
                                                                        if (ind === 0) {
                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td rowspan={item.items?.length > 0 ? `${item.items?.length}` : "1"}>
                                                                                            <div><span style={{ fontWeight: "bold" }}>Name -</span> {item.box?.name}</div>
                                                                                            <div><span style={{ fontWeight: "bold" }}>Length -</span> {item.box?.length} <span>(In)</span></div>
                                                                                            <div><span style={{ fontWeight: "bold" }}>Width -</span> {item.box?.width} <span>(In)</span></div>
                                                                                            <div><span style={{ fontWeight: "bold" }}>Height -</span> {item.box?.height} <span>(In)</span></div>
                                                                                            <div><span style={{ fontWeight: "bold" }}>Weight -</span> {item.box?.weight} <span>(Lb)</span></div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div style={{ fontWeight: "bold" }}>{`${prod.product_title} (${prod.category_name})`}</div>
                                                                                            <div>{prod?.categoryType === "OTHER" ? "Gear" : null}</div>
                                                                                            <div>{prod?.categoryType === "SHAFTS" && prod?.fitting_options?.length > 0 ? "Customized Shaft" : null}</div>
                                                                                            <div>{prod?.categoryType === "SHAFTS" && prod?.fitting_options?.length === 0 ? "Shaft" : null}</div>
                                                                                            <div><span>Ordered Quantity - </span>{prod?.order_quantity}</div>
                                                                                        </td>
                                                                                        <td><div>{prod.product_variation_sku}</div></td>
                                                                                        <td>
                                                                                            <div>
                                                                                                <Input value={prod.quantity} min={0} type="number" name="quantity" onChange={(e) => onChangeProductQuantity(e, index, ind)} disabled={item.shipping_box_status > 1 ? true : false} /></div></td>


                                                                                        <td rowspan={item.items?.length > 0 ? `${item.items?.length}` : "1"}>

                                                                                            {item?.shipping_box_status === 3 ? <div><p>Delivered</p></div> : null}
                                                                                            {item?.shipping_box_status === 2 ? <div><p>Shipped</p></div> : null}
                                                                                            {item?.shipping_box_status === 1 ?
                                                                                                <Button
                                                                                                    type="button"
                                                                                                    className="btn-success btn-sm mr-05"
                                                                                                    title="Delete Shipping Box"
                                                                                                    color="primary"
                                                                                                    onClick={() => onClickDeleteShippingBox(index)}
                                                                                                >
                                                                                                    <i className="fa fa-trash" id="deleteBox"></i>

                                                                                                </Button>
                                                                                                : null}
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        } return (
                                                                            <>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div style={{ fontWeight: "bold" }}>{`${prod.product_title} (${prod.category_name})`}</div>
                                                                                        <div>{prod?.categoryType === "OTHER" ? "Gear" : null}</div>
                                                                                        <div>{prod?.categoryType === "SHAFTS" && prod?.fitting_options?.length > 0 ? "Customized Shaft" : null}</div>
                                                                                        <div>{prod?.categoryType === "SHAFTS" && prod?.fitting_options?.length === 0 ? "Shaft" : null}</div>
                                                                                        <div><span>Ordered Quantity - </span>{prod?.order_quantity}</div>

                                                                                    </td>

                                                                                    <td><div>{prod.product_variation_sku}</div></td>
                                                                                    <td>
                                                                                        <div>
                                                                                            <Input value={prod.quantity} min={0} type="number" name="quantity" onChange={(e) => onChangeProductQuantity(e, index, ind)} disabled={item.shipping_box_status > 1 ? true : false} /></div></td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    }) : null
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </CardBody>
                                        </Card>
                                    </Col>


                                </Row>
                                {itemQuantityError && !openAddShippingBox ? <div style={{ color: "red" }}>{itemQuantityErrorMessage.map((msg) => <p>{msg}</p>)}</div> : null}
                            </CardBody>
                            <CardFooter>
                                <Button type="Submit" color="primary" className="mr-05">
                                    Submit
                                </Button>
                                <Link to={`/admin/orders/shipping-boxes/${match.params.order_id}`}>
                                    <Button type="button" color="danger">
                                        Cancel
                                    </Button>
                                </Link>
                            </CardFooter>

                        </Card>
                    </Col>
                </Form>
                <Modal isOpen={openAddShippingBox} toggle={onCloseAddShippingBox}>
                    <ModalHeader toggle={onCloseAddShippingBox}>
                        Select Shipping Box and Items
                    </ModalHeader>
                    <Form onSubmit={onSubmitModal}>
                        <ModalBody>
                            <div>
                                <FormGroup tag="shipping-box-type" style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                    <FormGroup check style={{ marginRight: "15px" }}>
                                        <Label check><Input type="radio" name="shipping-box-type" value="SHAFTS" checked={shippingBoxType === "SHAFTS"} onChange={onChangeShippingBoxType} /> Shaft Shipping Boxes</Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check><Input type="radio" name="shipping-box-type" value="OTHER" checked={shippingBoxType === "OTHER"} onChange={onChangeShippingBoxType} /> Gear Shipping Boxes</Label>
                                    </FormGroup>
                                </FormGroup>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", marginBottom: "30px" }}>
                                <div>Select Shipping Box</div>
                                <Input style={{ width: "230px", marginLeft: "10px" }} type="select" onChange={onSelectShippingBox} name="box">
                                    {shippingBoxType === "SHAFTS" ? shippingBoxes.map((box) => {
                                        return <option key={box._id} value={JSON.stringify(box)}>{box.name} - {`(Min - ${box.min_quantity} , Max - ${box.max_quantity}, Len - ${box.length})`}</option>
                                    }) : gearShippingBoxes.map((box) => {
                                        return <option key={box._id} value={JSON.stringify(box)}>{box.name} - {`(Min - ${box.min_quantity} , Max - ${box.max_quantity}, Len - ${box.length})`}</option>
                                    })}
                                </Input>
                            </div>
                            <hr />
                            {orderProducts?.map((prod) => {
                                return (
                                    <>
                                        <Row>


                                            <>
                                                <div style={{ display: "flex", alignItems: "center" }} >
                                                    <div style={{ width: "300px", marginBottom: "30px", marginRight: "100px" }}>
                                                        <div style={{ fontWeight: "bold" }}>{`${prod.product_title} (${prod.category_name})`}</div>
                                                        <div><span>SKU - </span>{prod.product_variation_sku}</div>
                                                        <div><span>Quantity - </span>{prod?.quantity}</div>
                                                        {prod.fitting_options && prod.fitting_options.length > 0
                                                            ? prod.fitting_options.map((value) => {
                                                                if (value.attribute_name == 'Playing Length') {
                                                                    return (
                                                                        <>
                                                                            <div>
                                                                                <label className="label-tag">
                                                                                    {' '}
                                                                                    Playing Length :
                                                                                </label>{' '}
                                                                                {(value?.attribute_value == 0) ? "Uncut" : value?.attribute_value}
                                                                            </div>
                                                                        </>
                                                                    );
                                                                }

                                                                if (value.attribute_name == 'Extra Wrap') {
                                                                    return (
                                                                        <>
                                                                            <div>
                                                                                <label className="label-tag">
                                                                                    {' '}
                                                                                    Extra Wraps :
                                                                                </label>
                                                                                {value.attribute_value &&
                                                                                    value.attribute_value == 1
                                                                                    ? 'None'
                                                                                    : value.attribute_value == 2
                                                                                        ? '1'
                                                                                        : value.attribute_value == 3
                                                                                            ? '2'
                                                                                            : 'None'}
                                                                            </div>
                                                                        </>
                                                                    );
                                                                }
                                                                if (value.attribute_name == 'Grip Logo') {
                                                                    return (
                                                                        <>
                                                                            <div>
                                                                                <label className="label-tag">
                                                                                    {' '}
                                                                                    Grip Logo :
                                                                                </label>
                                                                                {value.attribute_value &&
                                                                                    value.attribute_value}
                                                                            </div>
                                                                        </>
                                                                    );
                                                                }

                                                                if (value.attribute_name == 'Adaptors') {
                                                                    return (
                                                                        <>
                                                                            <div>
                                                                                <label className="label-tag">
                                                                                    {' '}
                                                                                    Adaptor :
                                                                                </label>{' '}
                                                                                {value.option_name} (${value.price})
                                                                            </div>
                                                                            <div>
                                                                                <label className="label-tag">
                                                                                    {' '}
                                                                                    Adaptor Setting :{' '}
                                                                                </label>
                                                                                {value.sub_option_name}
                                                                            </div>
                                                                        </>
                                                                    );
                                                                }
                                                                if (value.attribute_name == 'Grips') {
                                                                    return (
                                                                        <>
                                                                            <div>
                                                                                <label className="label-tag">
                                                                                    {' '}
                                                                                    Grips Brand :{' '}
                                                                                </label>
                                                                                {value.option_name}
                                                                            </div>
                                                                            <div>
                                                                                <label className="label-tag">
                                                                                    {' '}
                                                                                    Grip Model :{' '}
                                                                                </label>
                                                                                {value.sub_option_name} (${value.price})
                                                                            </div>
                                                                        </>
                                                                    );
                                                                }
                                                            })
                                                            : null}
                                                    </div>
                                                    <Input type="number" min={0} onChange={(e) => onChangeModalProductQuantity(e, prod)} name="item" disabled={prod.length > customShippingBox.box.length || prod.categoryType !== shippingBoxType ? true : false}>
                                                    </Input>
                                                </div>
                                                <hr />
                                            </>

                                        </Row>
                                    </>
                                )
                            })}

                        </ModalBody>
                        <ModalFooter style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", alignContent: "flex-end" }}>
                            {shippingBoxQuantityError ? <p style={{ color: "red" }}>{shippingBoxQuantityErrorMessage}</p> : null}
                            {itemQuantityError ? <div>{itemQuantityErrorMessage.map((msg) => <p style={{ color: "red" }}>{msg}</p>)}</div> : null}
                            <Button type="Submit" color="primary" disabled={!customShippingBox?.box?.name || customShippingBox?.items?.length === 0 ? true : false}>
                                Submit
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
                <Modal size="md" isOpen={submitResponseStatus}>
                    <ModalHeader toggle={onCloseFinalSubmitModal}>
                    </ModalHeader>
                    <Form onSubmit={onSubmitFinalModal}>
                        <ModalBody>

                            <h5>Shipping Charges Increased Due to Shipping Box Changes. Who Will Pay the additional shipping amount..?</h5>
                            <div>
                                <p>Old Shipping Charges - {submitResponse.oldShippingRate?.totalCharges}</p>

                                <p>New Shipping Charges - {submitResponse.newShippingRate?.totalCharges}</p>

                            </div>
                            <FormGroup tag="payer">
                                <FormGroup check>
                                    <Label check><Input type="radio" name="payer" value="customer" checked={payer === "customer"} onChange={onChangePayer} /> Customer</Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check><Input type="radio" name="payer" value="admin" checked={payer === "admin"} onChange={onChangePayer} /> Kinetixx</Label>
                                </FormGroup>
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter>

                            <div className='submit-close modalFooter '>
                                <Button type="Submit" color="primary" className="mr-05" disabled={payer === "" || finalSubmitModalLoading ? true : false}>
                                    {finalSubmitModalLoading ? <Spinner /> : <>Submit</>}
                                </Button>
                                <Button type="button" color="danger" onClick={onCloseFinalSubmitModal}>
                                    Cancel
                                </Button>
                            </div>
                        </ModalFooter>
                    </Form>
                </Modal>
            </div>
        </>
    )
}

EditOrderShippingBoxes.propTypes = {
    getOrderShippingBoxDetails: PropTypes.func.isRequired,
    getShippingBoxList: PropTypes.func.isRequired,
    getGearShippingBoxList: PropTypes.func.isRequired,
    getOrderById: PropTypes.func.isRequired,
    downloadInvoice: PropTypes.func.isRequired,
    getRatesForEditedShippingBoxes: PropTypes.func.isRequired,
    saveEditedShippingBoxes: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    OrderShippingBoxDetails: PropTypes.object.isRequired,
    shippingBoxList: PropTypes.object.isRequired,
    gearShippingBoxList: PropTypes.object.isRequired,
    currentOrder: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
    loading: state.orderShippingBoxes.OrderShippingBoxesLoading,
    OrderShippingBoxDetails: state.orderShippingBoxes.OrderShippingBoxDetails,
    shippingBoxList: state.shippingBox.shippingBoxList,
    gearShippingBoxList: state.gearShippingBox.gearShippingBoxList,
    currentOrder: state.order.currentOrder,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    getOrderShippingBoxDetails,
    getShippingBoxList,
    getOrderById,
    downloadInvoice,
    getRatesForEditedShippingBoxes,
    saveEditedShippingBoxes,
    getGearShippingBoxList
})(EditOrderShippingBoxes);