import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import AddCustomShippingBox from "./AddCustomShippingBox";
import { getCustomShippingBoxList } from 'actions/admin/shippingBox';
import { connect } from "react-redux";
import { useCallback, useState } from 'react';
import convert from 'convert-units';
import PropTypes from 'prop-types';

const CustomShippingForm = ({
    getCustomShippingBoxList,
    customShippingBoxList,
    setInputKinetixxCarrierDetails,
    inputKinetixxCarrierDetails,
    handelKinetixxCarrierDetails,
    kinetixxCarrierError
}) => {
    // State management using React hooks
    const [customShippingBoxModal, setOpenCustomShippingBoxModal] = useState(false);
    const [isMetricImperial, setIsMetricImperial] = useState(false);
    const [shippoWeightUnit, setShippoWeightUnit] = useState('lb');
    const [shippoLengthUnit, setShippoLengthUnit] = useState('in');

    // Function to handle selection of custom shipping box
    const onSelectCustomBox = (e) => {
        if (e.target.value !== "1" && e.target.value === "add_new_box") {
            toggleCustomShippingBoxModal();
        }
        if (e.target.value !== "1" && e.target.value !== "add_new_box") {
            let custom_box = customShippingBoxList.find((box) => box.name === e.target.value)
            setInputKinetixxCarrierDetails({ ...inputKinetixxCarrierDetails, name: custom_box.name, box_weight: custom_box.weight, height: custom_box.height, width: custom_box.width, length: custom_box.length });
        }
    }

    // Function to update custom shipping boxes
    const updateCustomShippingBoxes = (box) => {
        console.log(box, "BOXXXXXXXX");
        getCustomShippingBoxList();
        setInputKinetixxCarrierDetails({ ...inputKinetixxCarrierDetails, name: box.name, box_weight: box.weight, height: box.height, width: box.width, length: box.length });
    }

    // Function to toggle the custom shipping box modal
    const toggleCustomShippingBoxModal = () => {
        setOpenCustomShippingBoxModal(!customShippingBoxModal);
    };

    // Function to handle unit change (metric/imperial)
    const handleUnitsCheckedChange = useCallback((e) => {
        setIsMetricImperial(() => (e.target.checked));
        if (e.target.checked) {
            setShippoWeightUnit('g');
            setShippoLengthUnit('cm');
            setInputKinetixxCarrierDetails({
                ...inputKinetixxCarrierDetails,
                box_weight: (convert(inputKinetixxCarrierDetails.box_weight).from('lb').to('g').toFixed(4)),
                product_weight: (convert(inputKinetixxCarrierDetails.product_weight).from('lb').to('g').toFixed(4)),
                length: (convert(inputKinetixxCarrierDetails.length).from('in').to('cm').toFixed(4)),
                width: (convert(inputKinetixxCarrierDetails.width).from('in').to('cm').toFixed(4)),
                height: (convert(inputKinetixxCarrierDetails.height).from('in').to('cm').toFixed(4)),
                distance_unit: "cm",
                mass_unit: "g"
            })
        } else {
            setShippoWeightUnit('lb');
            setShippoLengthUnit('in');
            setInputKinetixxCarrierDetails({
                ...inputKinetixxCarrierDetails,
                box_weight: (convert(inputKinetixxCarrierDetails.box_weight).from('g').to('lb').toFixed(4)),
                product_weight: (convert(inputKinetixxCarrierDetails.product_weight).from('g').to('lb').toFixed(4)),
                length: (convert(inputKinetixxCarrierDetails.length).from('cm').to('in').toFixed(4)),
                width: (convert(inputKinetixxCarrierDetails.width).from('cm').to('in').toFixed(4)),
                height: (convert(inputKinetixxCarrierDetails.height).from('cm').to('in').toFixed(4)),
                distance_unit: "in",
                mass_unit: "lb"
            })

        }
    })

    // Rendering the JSX structure of the component
    return (
        <>
            <Row>
                <Col sm="12" md="6" lg="3">
                    <FormGroup>
                        <Label for="custom_boxes" className="label-tag ms-2">{"Select Custom Shipping Box"}</Label>
                        <Input
                            type="select"
                            id="custom_boxes"
                            name="custom_boxes"
                            className="mr-2"
                            value={inputKinetixxCarrierDetails.name}
                            onChange={(e) => onSelectCustomBox(e)}
                        >
                            <option value="1" key="1" defaultChecked>Select Custom Shipping Box</option>
                            {customShippingBoxList.map((box) => <option value={box?.name} key={box?._id ?? box?.name}>{box?.name}</option>)}
                            <option value="add_new_box" key="add_new_box">Add New Box</option>
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="6" lg="3">
                    <FormGroup>
                        <Input
                            type="checkbox"
                            id="metric_or_imperial"
                            name="metric_or_imperial"
                            checked={isMetricImperial}
                            className="mr-2"
                            onChange={(e) => handleUnitsCheckedChange(e)}
                        />
                        {`  Metric System / Imperial System(${isMetricImperial
                            ? 'uncheck to convert in Imperial System' : 'check to convert in Metric System'
                            })`}
                    </FormGroup>
                </Col>
            </Row>

            <Row >
                <Col sm="12" md="6" lg="3">
                    <FormGroup>
                        <Label for="height" className="label-tag ms-2">{"Height in"} "{shippoLengthUnit}"</Label>
                        <Input
                            type="number"
                            name="height"
                            id="height"
                            min={0}
                            value={inputKinetixxCarrierDetails?.height}
                            onChange={handelKinetixxCarrierDetails}
                            placeholder="Height"
                            required
                            disabled
                        />
                        {kinetixxCarrierError?.height && <p style={{ color: "red", margin: "0px" }}>{kinetixxCarrierError?.height}</p>}
                    </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="3">
                    <FormGroup>
                        <Label for="width" className="label-tag ms-2">{"Width in"} "{shippoLengthUnit}"</Label>
                        <Input
                            type="number"
                            name="width"
                            id="width"
                            min={0}
                            value={inputKinetixxCarrierDetails?.width}
                            placeholder="Width"
                            onChange={handelKinetixxCarrierDetails}
                            required
                            disabled
                        />
                        {kinetixxCarrierError?.width && <p style={{ color: "red", margin: "0px" }}>{kinetixxCarrierError?.width}</p>}
                    </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="3">
                    <FormGroup>
                        <Label for="length" className="label-tag ms-2">{"Length in"} "{shippoLengthUnit}"</Label>
                        <Input
                            type="number"
                            name="length"
                            id="length"
                            min={0}
                            value={inputKinetixxCarrierDetails?.length}
                            placeholder="Length"
                            onChange={handelKinetixxCarrierDetails}
                            required
                            disabled
                        />
                        {kinetixxCarrierError?.length && <p style={{ color: "red", margin: "0px" }}>{kinetixxCarrierError?.length}</p>}

                    </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="3">
                    <FormGroup>
                        <Label for="box_weight" className="label-tag ms-2">{"Box Weight"} "{shippoWeightUnit}"</Label>
                        <Input
                            type="number"
                            name="box_weight"
                            id="box_weight"
                            min={0}
                            value={inputKinetixxCarrierDetails?.box_weight}
                            placeholder="Box Weight"
                            onChange={handelKinetixxCarrierDetails}
                            required
                            disabled
                        />
                        {kinetixxCarrierError?.box_weight && <p style={{ color: "red", margin: "0px" }}>{kinetixxCarrierError?.box_weight}</p>}
                    </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="3">
                    <FormGroup>
                        <Label for="product_weight" className="label-tag ms-2">{"Product Weight"} "{shippoWeightUnit}"</Label>
                        <Input
                            type="number"
                            name="product_weight"
                            id="product_weight"
                            min={0}
                            step={0.1}
                            value={inputKinetixxCarrierDetails?.product_weight}
                            placeholder="Product Weight"
                            onChange={handelKinetixxCarrierDetails}
                            required
                        />
                        {kinetixxCarrierError?.product_weight && <p style={{ color: "red", margin: "0px" }}>{kinetixxCarrierError?.product_weight}</p>}
                    </FormGroup>
                </Col>
            </Row>

            <AddCustomShippingBox
                isOpen={customShippingBoxModal}
                toggle={toggleCustomShippingBoxModal}
                update={updateCustomShippingBoxes}
            />
        </>
    );
}

// PropTypes validation for component props
CustomShippingForm.propTypes = {
    customShippingBoxList: PropTypes.array.isRequired
};

// Mapping state from the Redux store to component props
const mapStateToProps = (state) => ({
    customShippingBoxList: state.shippingBox.customShippingBoxList
});

// Connecting the component to the Redux store and exporting it
export default connect(mapStateToProps, {
    getCustomShippingBoxList
})(CustomShippingForm);  