import {
  EMAIL_TEMPLATE_SECTION_LOADING,
  EMAIL_TEMPLATE_SECTION_CREATED,
  EMIAL_TEMPLATE_SECTION_CREATE_ERROR,
  EMAIL_TEMPLATE_SECTION_FAIL,
  EMAIL_TEMPLATE_SECTION_UPDATED,
  EMIAL_TEMPLATE_SECTION_UPDATE_ERROR,
  EMAIL_TEMPLATE_SECTION_DELETED,
  EMAIL_TEMPLATE_SECTION_DELETE_ERROR,
  EMAIL_TEMPLATE_SECTION_LIST_UPDATED,
  GET_EMAIL_TEMPLATE_SECTION_BY_ID,
} from 'actions/types';

const initalState = {
  emailTemplateSectionList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentEmailTemplateSection: {},
  loading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case EMAIL_TEMPLATE_SECTION_LOADING:
      return {
        ...state,
        loading: payload.is_loading ? payload.is_loading : false,
      };
    case EMAIL_TEMPLATE_SECTION_CREATED:
    case EMIAL_TEMPLATE_SECTION_CREATE_ERROR:
    case EMIAL_TEMPLATE_SECTION_UPDATE_ERROR:
    case EMAIL_TEMPLATE_SECTION_DELETED:
    case EMAIL_TEMPLATE_SECTION_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case EMAIL_TEMPLATE_SECTION_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case EMAIL_TEMPLATE_SECTION_UPDATED:
      return {
        ...state,
        currentEmailTemplateSection: {},
        loading: false,
      };
    case EMAIL_TEMPLATE_SECTION_LIST_UPDATED:
      return {
        ...state,
        emailTemplateSectionList: {
          data: payload.data.emailTemplateSectionList.data,
          page: payload.data.emailTemplateSectionList.metadata[0].current_page,
          count: payload.data.emailTemplateSectionList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_EMAIL_TEMPLATE_SECTION_BY_ID:
      return {
        ...state,
        currentEmailTemplateSection: payload.data,
        loading: false,
      };
    default:
      return state;
  }
}
