/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { getPackingSlipList, deletePackingSlip } from 'actions/admin/packingSlip';
import * as Constants from 'constants/index';




//   PaginationProvider,
//   SizePerPageDropdownStandalone,
// } from 'react-bootstrap-table2-paginator';


import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { Button, Card, CardBody, Col, Row, Input } from 'reactstrap';
import Spinner from 'views/Spinner';
import CustomFilters from './CustomFilters';
import PerPageSelect from "../PerPageSelect";

const actions = (
  <div className="add-button-div specify-width">
    <Link to="/admin/packing-slip/create" className="addNewElementClass">
      <Button color="primary" size="sm">
        <i className="fa fa-plus"></i> Create Packing Slip
      </Button>
    </Link>
  </div>
);

const PackingSlipList = ({
  getPackingSlipList,
  deletePackingSlip,
  packingSlipList: { data, count },
  loading,
  subLoading,
}) => {
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState({
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: 'created_at',
    ascending: 'desc',
    query: '',
  });

  // to resolve useEffect dependency error

  //################## to get updated list ###################
  useEffect(() => {

    getPackingSlipList(sortingParams);
  }, [sortingParams]);


  //#########################Colums defination start ###################
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      filterParams: {
        buttons: ['reset', 'apply'],
        debounceMs: 200,
      },
      //headerSortingStyle
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agDateColumnFilter',
      // pass in additional parameters to the text filter
      filterParams: {
        buttons: ['reset', 'apply'],
        debounceMs: 200,
      },
      cellRenderer: (cellContent, row) => (
        <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
          {row.created_at}
        </Moment>
      ),
      //headerSortingStyle
    },
    {
      field: '_id',
      headerName: 'Actions',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) => (
        <div>
          <Link to={`/admin/packing-slip/${params.data._id}`}>
            <Button type="button" size="sm" color="success">
              <i className="fa fa-pencil"></i>
            </Button>
          </Link>
          <Button
            type="button"
            size="sm"
            color="danger"
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure, you want to delete ${params.data.name} packing slip?`
                )
              ) {
                deletePackingSlip(params.data._id).then((res) => {
                  if (res) getPackingSlipList(sortingParams);
                });
              }
            }}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
  ];

  //################## START Set properties(options) of datatables ###################



  const defaultSorted = [
    {
      dataField: 'created_at',
      order: 'desc',
    },
  ];



  const options = {
    page: sortingParams.page,
    pageStartIndex: 1,
    withFirstAndLast: false, // Hide the going to First and Last page button
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page

    showTotal: true,
    paginationTotalRenderer: customTotal,
    totalSize: count,
    sizePerPageOptionRenderer,
    sizePerPageList: [
      {
        text: Constants.DEFAULT_PAGE_SIZE,
        value: Constants.DEFAULT_PAGE_SIZE,
      },
      {
        text: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
        value: Constants.DEFAULT_PAGE_SIZE_SECOND_OPTION,
      },
      {
        text: 'All',
        value: count,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const [gridApi, setGridApi] = useState(null);
  const onGridReady = (params) => {
    setGridApi(params);

  };

  const onPaginationChange = (pageSize) => {
    gridApi.api.paginationSetPageSize(pageSize);
  };

  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                {actions}
                <div className="rightpaneltop">
                  Packing Slips Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>
              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      domLayout={'autoHeight'}
                      columnDefs={columns}
                      rowData={data}
                      pagination={true}
                      floatingFilter={true}
                      onGridReady={onGridReady}
                      paginationPageSize={20}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

PackingSlipList.propTypes = {
  getPackingSlipList: PropTypes.func.isRequired,
  deletePackingSlip: PropTypes.func.isRequired,
  packingSlipList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  packingSlipList: state.packingSlip.packingSlipList,
  loading: state.packingSlip.loading,
  subLoading: state.packingSlip.isSubLoading,
});

export default connect(mapStateToProps, {
  getPackingSlipList,
  deletePackingSlip,
})(PackingSlipList);
