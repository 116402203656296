/* eslint-disable */
import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { add, getDealerDetailsById } from 'actions/admin/dealerAddress';

import Errors from 'views/Notifications/Errors';
import Spinner from 'views/Spinner';
import Autocomplete from 'react-google-autocomplete';
import { GOOGLE_MAPS_API_KEY } from '../../../config';
import { setGoogleAddressFormData } from 'actions/commonActions';
import 'react-phone-number-input/style.css';
import PhoneInput, {
  getCountryCallingCode,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import extractNumber from 'utils/extractNumber';
import { getCountryShort } from 'utils/countryCode';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { REMOVE_ERRORS, SET_GOOGLE_ADDRESS_FORM_DATA } from 'actions/types';

const AddAddres = ({
  add,
  history,
  match,
  errorList,
  loading,
  getDealerDetailsById,
  currentDealerDetails,
  googleAddressFormData,
  setGoogleAddressFormData,
}) => {
  let navigate = useNavigate();
  const { businessName: businessName, dealer_id: dealer_id } = useParams();

  const [autocompleteValue, setAutocompleteValue] = useState('');
  /**************** use state for form data *********************/
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    alternative_email_one: '',
    alternative_email_two: '',
    ccode: '+1',
    contact_no: '',
    iute164_phone: '',
    address: '',
    latitute: 0,
    longitude: 0,
    address_line_1: '',
    address_line_2: '',
    google_map_link: '',
    fitting_studio: false,
    city: '',
    zipcode: '',
    country: '',
    state: '',
    google_id: '',
    google_place_id: '',
    country_code: '',
    // location: geometry,
  });

  /**************** expload form data *********************/
  const {
    address,
    address_line_1,
    address_line_2,
    latitute,
    longitude,
    name,
    email,
    alternative_email_one,
    alternative_email_two,
    ccode,
    contact_no,
    google_map_link,
    fitting_studio,
    iute164_phone,
    city,
    zipcode,
    country,
    state,
    // location: geometry,
  } = formData;

  // to resolve useEffect dependency error

  /*************************get dealer details********************/
  useMemo(() => {
    getDealerDetailsById(dealer_id);
  }, [dealer_id]);

  /*************************set form data********************/
  useMemo(() => {
    if (Object.keys(currentDealerDetails).length > 0) {
      const ccode = !currentDealerDetails.ccode
        ? '+1'
        : currentDealerDetails.ccode;
      const contact_no = !currentDealerDetails.contact_no
        ? ''
        : currentDealerDetails.contact_no;
      const iute164_phone = ccode + contact_no;

      setFormData({
        ...formData,
        name: currentDealerDetails.name ? currentDealerDetails.name : '',
        email: currentDealerDetails.email ? currentDealerDetails.email : '',
        contact_no: contact_no,
        ccode: ccode,
        iute164_phone: iute164_phone,
      });
    }
  }, [currentDealerDetails]);

  /**************** dispatch to remove error *********************/

  /**************** on Change event *********************/
  const onChange = (e) => {
    //setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === 'name') {
      setFormData({ ...formData, name: e.target.value.trimLeft().replace(/[&#<>@]/g, '') })
    } else {
      const newFormData = { ...formData, [e.target.name]: e.target.value.trimLeft() };
      newFormData['address'] = `${newFormData.address_line_1} ${newFormData.address_line_2 ? newFormData.address_line_2 + ',' : ''
        } ${newFormData.city} ${newFormData.state} ${newFormData.zipcode} ${newFormData.country
        }`;
      setFormData(newFormData);
    }
  };

  const onFittingStudioChange = (e) => {
    setFormData({ ...formData, fitting_studio: e.target.checked });
  };

  //########################## change event for phone number ##############################
  const onPhoneChange = (number) => {
    const contact_no = extractNumber(number, ccode);
    setFormData((form) => ({
      ...form,
      contact_no: contact_no,
      iute164_phone: number ?? '',
    }));
  };

  const onCountryChange = (code) => {
    setFormData((form) => ({
      ...form,
      ccode: '+' + getCountryCallingCode(code ?? getCountryShort(ccode)),
    }));
  };

  /**************** on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    Object.keys(formData).forEach((k) => {
      typeof formData[k] === 'string'
        ? (formData[k] = formData[k]?.trim())
        : (formData[k] = formData[k]);
    });
    add(formData, businessName, dealer_id, navigate);
  };

  // to resolve useEffect dependency error

  //#################### update formdata after changes in google address  #################
  useEffect(() => {
    if (Object.keys(googleAddressFormData).length > 0) {
      setFormData({
        ...formData,
        name: googleAddressFormData.name,
        email: googleAddressFormData.email,
        alternative_email_one: googleAddressFormData.alternative_email_one,
        alternative_email_two: googleAddressFormData.alternative_email_two,
        ccode: googleAddressFormData.ccode,
        contact_no: googleAddressFormData.contact_no,
        iute164_phone: googleAddressFormData.iute164_phone,
      });
    }

    //   type: SET_GOOGLE_ADDRESS_FORM_DATA,
    //   payload: {},
    // });
  }, [address]);

  const setGMapPlace = (location) => {
    const google_id = location.id;
    const google_place_id = location.place_id;
    const address = location.formatted_address;
    const addresses = location.address_components;
    let latitute = location?.geometry?.location?.lat((e) => e.Scopes[0].e);
    let longitude = location?.geometry?.location?.lng((e) => e.Scopes[0].f);

    let city = '';
    let state = '';
    let address_line_1 = '';
    let address_line_2 = '';
    let country = '';
    let country_code = '';
    let zipcode = '';
    if (addresses)
      addresses.forEach((e) => {
        switch (e.types[0]) {
          case 'street_number':
          case 'route':
            address_line_1 += (address_line_1 ? ' ' : '') + e.long_name;

            break;
          case 'sublocality_level_2':
          case 'sublocality_level_1':
            address_line_2 += (address_line_2 ? ' ' : '') + e.long_name;
            break;
          case 'locality':
          case 'sublocality_level_1':
          case 'administrative_area_level_3':
          case 'administrative_area_level_2':
          case 'postal_town':
            if (city == '') {
              city = e.long_name;
            }
            break;
          case 'administrative_area_level_1':
            state = e.long_name;
            break;
          case 'postal_code':
          case 'postal_code_prefix':
            zipcode = e.long_name;
            break;
          case 'country':
            country = e.long_name;
            country_code = e.short_name;
            break;
          default:
            break;
        }
      });

    setFormData({
      ...formData,
      address,
      city,
      latitute,
      longitude,
      address_line_1,
      address_line_2,
      google_id,
      google_place_id,
      state,
      country,
      country_code,
      zipcode,
      // location: geometry,
    });
    setAutocompleteValue('');
  };
  const handleInputChange = (event) => {
    setAutocompleteValue(event.target.value);
  };

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Form
          className="form-horizontal"
          // autoComplete="false"
          onSubmit={(e) => onSubmit(e)}
        >
          <Row>
            <Col xs="12" sm="12">
              <Card className="mb0">
                <CardHeader>
                  <h5>Address Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="name">
                          Name <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="name"
                          name="name"
                          value={name}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.name ? true : false}
                        />
                        <Errors current_key="name" key="name" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="email">
                          Email <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="email"
                          name="email"
                          value={email}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.email ? true : false}
                        />
                        <Errors current_key="email" key="email" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="alternative_email_one">
                          Alternative Email One
                        </Label>
                        <Input
                          type="text"
                          id="alternative_email_one"
                          name="alternative_email_one"
                          value={alternative_email_one}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.alternative_email_one ? true : false}
                        />
                        <Errors current_key="alternative_email_one" key="alternative_email_one" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="alternative_email_two">
                          Alternative Email Two
                        </Label>
                        <Input
                          type="text"
                          id="alternative_email_two"
                          name="alternative_email_two"
                          value={alternative_email_two}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.alternative_email_two ? true : false}
                        />
                        <Errors current_key="alternative_email_two" key="alternative_email_two" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="address">Search Your Address</Label>
                        <Autocomplete
                          apiKey={GOOGLE_MAPS_API_KEY}
                          onPlaceSelected={(place) => {
                            {
                              setGMapPlace(place);
                            }
                          }}
                          options={{
                            types: ['address'],
                          }}
                          value={autocompleteValue}
                          onChange={handleInputChange}
                          className="form-control"
                          onFocus={() => setGoogleAddressFormData(formData)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="address">
                          Address Line 1<span>*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="address_line_1"
                          name="address_line_1"
                          placeholder="Address Line 1 *"
                          maxLength="100"
                          value={address_line_1}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.address_line_1 ? true : false}
                        />
                        <Errors
                          current_key="address_line_1"
                          key="address_line_1"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="address">Address Line 2</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="address_line_2"
                          name="address_line_2"
                          placeholder="Address Line 2"
                          maxLength="100"
                          value={address_line_2}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.address_line_2 ? true : false}
                        />
                        <Errors
                          current_key="address_line_2"
                          key="address_line_2"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="country">
                          Country <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          name="country"
                          id="country"
                          value={country}
                          // disabled={true}
                          // readOnly={true}
                          onChange={(e) => onChange(e)}
                          required
                          invalid={errorList.country ? true : false}
                        ></Input>
                        <Errors current_key="country" key="country" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="state">State</Label>
                        <Input
                          type="text"
                          name="state"
                          id="state"
                          value={state}
                          // disabled={true}
                          // readOnly={true}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.state ? true : false}
                        />
                        <Errors current_key="state" key="state" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="city">
                          City <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="city"
                          name="city"
                          maxLength="100"
                          value={city}
                          // disabled={true}
                          // readOnly={true}
                          onChange={(e) => onChange(e)}
                          required
                          invalid={errorList.city ? true : false}
                        />
                        <Errors current_key="city" key="city" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="zipcode">
                          Zip/Postal Code <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="zipcode"
                          name="zipcode"
                          maxLength="10"
                          // minLength="3"
                          value={zipcode}
                          // disabled={true}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.zipcode ? true : false}
                        />
                        <Errors current_key="zipcode" key="zipcode" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="contact_no">
                          Contact No <span>*</span>
                        </Label>
                        <PhoneInput
                          className=""
                          autoComplete="tel"
                          withCountryCallingCode={true}
                          countryCallingCodeEditable={false}
                          smartCaret={true}
                          international={true}
                          defaultCountry="US"
                          value={iute164_phone}
                          name="contact_no"
                          addInternationalOption={false}
                          onChange={onPhoneChange}
                          onCountryChange={onCountryChange}
                        />
                        <Errors current_key="contact_no" key="contact_no" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="google_map_link">Google Map Link</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="google_map_link"
                        name="google_map_link"
                        value={google_map_link}
                        onChange={(e) => onChange(e)}
                        // disabled={true}
                        invalid={errorList.google_map_link ? true : false}
                      />
                      <Errors
                        current_key="google_map_link"
                        key="google_map_link"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="checkouter m-0">
                      <Label className="custom_check">
                        Fitting Studio
                        <Input
                          type="checkbox"
                          // style={{ transform: "scale(1.5)", marginLeft: "5px" }}
                          id="fitting_studio"
                          name="fitting_studio"
                          className="mr-2"
                          checked={fitting_studio}
                          onChange={onFittingStudioChange}
                          // disabled={true}
                          invalid={errorList.fitting_studio ? true : false}
                        />
                        <span className="check_indicator">&nbsp;</span>
                      </Label>
                      <Errors
                        current_key="fitting_studio"
                        key="fitting_studio"
                      />
                    </FormGroup>
                  </Col>

                  {/* <GMapssetGMapPlace
                    label="Location"
                    onChange={setGMapPlace}
                    placeholder={'Search your address'}
                    types={'address'}
                    formData={formData}
                  /> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardFooter>
                  <Button
                    className="mr-05"
                    type="submit"
                    size="sm"
                    color="primary"
                    disabled={loading}
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Link
                    to={`/admin/dealers/${businessName}/dealer-address/${dealer_id}`}
                  >
                    <Button type="reset" size="sm" color="danger">
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

AddAddres.propTypes = {
  add: PropTypes.func.isRequired,
  errorList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getDealerDetailsById: PropTypes.func.isRequired,
  currentDealerDetails: PropTypes.object.isRequired,
  googleAddressFormData: PropTypes.object.isRequired,
  setGoogleAddressFormData: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  loading: state.dealerAddress.loading,
  errorList: state.errors,
  currentDealerDetails: state.dealerAddress.currentDealerDetails,
  googleAddressFormData: state.commonActions.googleAddressFormData,
});

export default connect(mapStateToProps, {
  add,
  getDealerDetailsById,
  setGoogleAddressFormData,
})(AddAddres);
