/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';

const ShippingRates = ({
  shippingRatesLoading,
  // shippingRatesList,
  setSelectedShippingMode,
  shippingModeError,
  shippingMessages,
  shippingRates,
  groupedRatesByProvider,
  setShippingPrice,
  reApplyCoupon,
  isApplied,
}) => {
  //################ selected shipping method index ############################
  const [shippingMethod, setShippingMethod] = useState(0);

  //################ on change shipping method ############################
  const onShippingMethodChange = (e) => {
    setShippingMethod(e.target.value);
    if (shippingRates.length > 0) {
      setSelectedShippingMode(
        groupedRatesByProvider[e.target.value.split('-')[0]][
          e.target.value.split('-')[1]
        ]
      );
      let shippingPrice = groupedRatesByProvider[e.target.value.split('-')[0]][
        e.target.value.split('-')[1]
      ].totalCharges
        ? groupedRatesByProvider[e.target.value.split('-')[0]][
            e.target.value.split('-')[1]
          ].totalCharges
        : 0;
      setShippingPrice(shippingPrice);
      console.log('Rate changed');
      if (isApplied) {
        reApplyCoupon(shippingPrice);
      }
    }
  };

  // to resolve useEffect dependency error

  useEffect(() => {
    if (shippingRates.length === 0) {
      setSelectedShippingMode({});
      setShippingPrice(0);
      setShippingMethod(0);
    } else {
      setShippingMethod(shippingRates[0].carrier + '-' + 0);
      setSelectedShippingMode(shippingRates[0]);
      let shippingPrice = shippingRates[0].totalCharges
        ? shippingRates[0].totalCharges
        : 0;
      setShippingPrice(shippingPrice);
      if (isApplied) {
        reApplyCoupon(shippingPrice);
      }
    }
  }, [shippingRates]);
  //console.log('shippingRates found/not found',shippingRates[0]);

  return (
    <div className="details-view-outer">
      <Errors current_key="shippingRate" key="shippingRate" />
      {shippingRatesLoading ? (
        <Spinner />
      ) : (
        <Row>
          <Col>
            {shippingRates.length > 0 ? (
              Object.entries(groupedRatesByProvider)?.map(
                ([carrier, rates]) => {
                  return (
                    <div className="shipping-flex">
                      <Label>
                        <b>{carrier}</b>
                      </Label>
                      {rates?.map((value, key) => (
                        <FormGroup key={carrier + '-' + key}>
                          <div className="radio d-flex">
                            <Input
                              type="radio"
                              name="shippingMethod"
                              value={carrier + '-' + key}
                              checked={shippingMethod === carrier + '-' + key}
                              // disabled
                              onChange={(e) => onShippingMethodChange(e)}
                              className="mr-2"
                            />
                            <Label>
                              {' '}
                              {value.service} : ${value.totalCharges}
                            </Label>
                          </div>
                        </FormGroup>
                      ))}
                    </div>
                  );
                }
              )
            ) : (
              <FormGroup>
                <div className="radio d-flex">
                  <Label> {shippingModeError}</Label>
                </div>
              </FormGroup>
            )}
            {shippingMessages.length > 0
              ? shippingMessages.map((value, key) => (
                  <FormGroup key={key}>
                    <div>
                      {shippingMessages[key]?.source !==
                        shippingMessages[key - 1]?.source && (
                        <Label>
                          <b>{value.source}</b>
                        </Label>
                      )}
                    </div>
                    <div className="radio d-flex">
                      <Label> {value.text}</Label>
                    </div>
                  </FormGroup>
                ))
              : null}
          </Col>
        </Row>
      )}
    </div>
  );
};

ShippingRates.propTypes = {
  // loading: PropTypes.bool.isRequired,
  shippingRatesLoading: PropTypes.bool.isRequired,
  // shippingRatesList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  // loading: state.order.shipping_rates_loading,
  shippingRatesLoading: state.order.shipping_rates_loading,
  // shippingRatesList: state.order.shippingRatesList,
});

export default connect(mapStateToProps)(ShippingRates);
