import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    deleteService,
    selectedVariations
} from 'actions/admin/order';

import {
    Button,
    Table
} from 'reactstrap';
import { editProduct } from 'actions/admin/product';
import { useCallback, useEffect } from 'react';

const ServiceCart = ({ selectedVariations, variationData, deleteService, showAction }) => {
    const editProduct = useCallback((item) => {
        const selected = variationData?.serviceDetail.find((i) => i._id === item._id)
        //variationData.quantity
        selected.isEdit = true;
        variationData = {
            ...variationData,
            serviceProduct: selected,
            quantity: selected?.quantity,
            serviceModel: true
        }
        selectedVariations(variationData)
    })

    const deleteProduct = useCallback((item) => {
        let orderId = variationData.orderId !== undefined ? variationData.orderId : variationData.userId
        deleteService(item._id, orderId, "service").then((res) => {
            console.log(res, "updated")
            if (res.status === true) {
                variationData = {
                    ...variationData,
                    serviceProduct: {},
                    serviceDetail: [],
                    serviceTotalPrice: 0,
                    callGetCartApi: true,
                }

            } else {
                variationData = {
                    ...variationData,
                    serviceModel: true
                }
            }
            selectedVariations(variationData)
        })
    })

    useEffect(() => {
        if (variationData.quantity > 0) {
            variationData = {
                ...variationData,
                serviceProduct: { ...variationData.serviceProduct, quantity: variationData.quantity },
            }
            selectedVariations(variationData)
        }
    }, [variationData.quantity])

    return (
        <Table responsive className='orderaddedittable'>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                    <th>Sku</th>
                    {showAction && <th>Action</th>}
                </tr>
            </thead>
            <tbody>
                {variationData?.serviceDetail?.map((item) => (
                    <tr key={item.sku} className='bg-light'>
                        <th>{item.product_title}</th>
                        <th>{item.price}</th>
                        <th>{item.quantity}</th>
                        <th>{item.total_price}</th>
                        <th>{item.sku}</th>
                        {showAction && <th>
                            <Button
                                type="button"
                                className="btn-success btn-sm mr-05"
                                onClick={() => editProduct(item)}
                            >
                                <i className="fa fa-pencil"></i>
                            </Button>
                            <Button
                                type="button"
                                className="btn-danger btn-sm"
                                onClick={() => {
                                    if (
                                        window.confirm(
                                            `Are you sure, you want to delete this product?`
                                        )
                                    ) {
                                        deleteProduct(item);
                                    }
                                }}
                            >
                                <i className="fa fa-trash"></i>
                            </Button>
                        </th>}
                    </tr>
                ))}
            </tbody>
        </Table>
    )
};

const mapStateToProps = (state) => ({
    variationData: state.order.variationData,
});

export default connect(mapStateToProps, {
    selectedVariations,
    deleteService
})(ServiceCart);