import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { CKEditor } from 'ckeditor4-react';
import slugify from 'react-slugify';
import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';

import { create, edit, getNewsById } from 'actions/admin/news';

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import { CKEditorConfig } from 'constants';

const AddEditNews = ({
    create,
    history,
    errorList,
    loading,
    getNewsById,
    edit,
    news: { currentNews },
}) => {
    let navigate = useNavigate();
    /**************** use state for form data *********************/
    const [formData, setFormData] = useState({
        meta_title: '',
        meta_description: '',
        title: '',
        slug: '',
        subtitle: '',
        selectedFile: [],
        image: [],
        deleteFile: [],
        videoUrl: '',
        selectedVideoFile: '',
        deletedVideoFile: '',
        description: '',
    });
    const [descriptionData, setDescriptionData] = useState('');
    const [baseUrl, setBaseUrl] = useState([]);
    const [imagePreview, setImagePreview] = useState([]);

    const { news_id: news_id } = useParams();
    useEffect(() => {
        if (news_id) getNewsById(news_id);
    }, [news_id]);

    /**************** expload form data *********************/
    const {
        meta_title,
        meta_description,
        title,
        slug,
        subtitle,
        image,
        videoUrl,
        selectedVideoFile,
        deleteFile,
        deletedVideoFile,
        selectedFile,
        description,
    } = formData;

    useEffect(() => {
        if (Object.keys(currentNews).length > 0 && news_id) {
            setFormData({ ...currentNews, image: currentNews.images, selectedFile: currentNews.images, deletedVideoFile: "" });
            setBaseUrl(currentNews.images);
            setImagePreview(currentNews.images);
        }
    }, [currentNews]);

    // handle input change
    const handleNewsChange = (e) => {
        if (e.target.name === 'title') {
            let new_slug = slugify(e.target.value);
            setFormData({
                ...formData,
                [e.target.name]: e.target.value.trim(),
                slug: new_slug,
            });
        }

        if (e?.target?.name === 'thumbnail') {
            const selectedImage = e.target.files[0];
            // Set Image in FormData
            const prevImag = URL.createObjectURL(selectedImage);
            setImagePreview((data) => ({
                ...data, [Object.keys(imagePreview).length]: prevImag
            }));

            const reader = new FileReader();
            reader.onloadend = () => {
                // Use a regex to remove data url part
                const base64String = reader.result;
                setBaseUrl((data1) => ({
                    ...data1,
                    [Object.keys(baseUrl).length]: base64String
                }))
            };
            reader.readAsDataURL(selectedImage);
            setFormData((data1) => ({
                ...data1,
                selectedFile: baseUrl, image: imagePreview,
            }));
            document.getElementById(`thumbnail`).value = '';

            // const imageReader = new FileReader();

            // imageReader.onload = (e) => {
            //     const img = new Image();
            //     img.src = e.target.result;
            //     img.onload = () => {
            //         const aspectRatio = img.width / img.height;
            //         if (Math.abs(aspectRatio) < 1.3 || Math.abs(aspectRatio) > 2) {
            //             console.log(aspectRatio, 'Image Aspect Ratio Error');
            //             document.getElementById(`thumbnail`).value = '';
            //             const result = window.confirm('You Are trying to upload the image which aspect ratio is not 16:9.');
            //             if (result) {
            //                 const prevImag = URL.createObjectURL(selectedImage);
            //                 setImagePreview((data) => ({
            //                     ...data, [Object.keys(imagePreview).length]: prevImag
            //                 }));

            //                 const reader = new FileReader();
            //                 reader.onloadend = () => {
            //                     // Use a regex to remove data url part
            //                     const base64String = reader.result;
            //                     setBaseUrl((data1) => ({
            //                         ...data1,
            //                         [Object.keys(baseUrl).length]: base64String
            //                     }))
            //                 };
            //                 reader.readAsDataURL(selectedImage);
            //                 setFormData((data1) => ({
            //                     ...data1,
            //                     selectedFile: baseUrl, image: imagePreview,
            //                 }));
            //             }
            //             document.getElementById(`thumbnail`).value = '';
            //         } else {
            //             // Set Image in FormData
            //             const prevImag = URL.createObjectURL(selectedImage);
            //             setImagePreview((data) => ({
            //                 ...data, [Object.keys(imagePreview).length]: prevImag
            //             }));

            //             const reader = new FileReader();
            //             reader.onloadend = () => {
            //                 // Use a regex to remove data url part
            //                 const base64String = reader.result;
            //                 setBaseUrl((data1) => ({
            //                     ...data1,
            //                     [Object.keys(baseUrl).length]: base64String
            //                 }))
            //             };
            //             reader.readAsDataURL(selectedImage);
            //             setFormData((data1) => ({
            //                 ...data1,
            //                 selectedFile: baseUrl, image: imagePreview,
            //             }));
            //             document.getElementById(`thumbnail`).value = '';
            //         }
            //     };
            // };

            // if (selectedImage) {
            //     imageReader.readAsDataURL(selectedImage);
            // }
        } else if (e.target.name === 'videoUrl') {
            let video_file = e.target.files[0];

            let video_url = URL.createObjectURL(video_file);

            const reader = new FileReader();
            reader.readAsDataURL(video_file);
            reader.onload = () => {
                // Use a regex to remove data url part
                const base64String = reader.result;
                setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                    selectedVideoFile: base64String,
                    videoUrl: video_url,
                    deletedVideoFile: formData?.videoUrl
                });
            };
            document.getElementById(`thumbnail`).value = '';
        } else {
            setFormData((data1) => ({
                ...data1,
                [e?.target?.name]: e?.target?.value.trimLeft()
            }));
        }
    };
    const removeVideoFile = (e) => {
        e.preventDefault();
        setFormData({ ...formData, selectedVideoFile: "", videoUrl: "", deletedVideoFile: !formData?.videoUrl?.includes('blob') ? formData.videoUrl : "" });
    };

    useEffect(() => {
        setFormData((data1) => ({
            ...data1, image: imagePreview, selectedFile: baseUrl
        }))
    }, [imagePreview, baseUrl]);

    const handelDescriptionChange = (event) => {
        setDescriptionData(event.editor.getData());
    };
    let deleteImage = [];
    //remove file
    let removeFile = (index) => (e) => {

        let all_files = Object.values(formData.image);
        let all_selected = Object.values(formData.selectedFile);


        // setDeletedImage(all_selected);
        all_files = all_files.filter(file => file._id !== e?.currentTarget?.getAttribute('data-id'));

        if (all_selected[index]?.original == undefined) {
            all_files = all_files.filter(data => data !== all_files[index]);
            all_selected = all_selected.filter(data => data !== all_selected[index]);
        } else {
            const sdeleteImage = all_selected.find(file => file._id === e?.currentTarget?.getAttribute('data-id'));
            deleteImage.push(sdeleteImage)
            all_selected = all_selected.filter(file => file._id !== e?.currentTarget?.getAttribute('data-id'));
        }
        setImagePreview(all_files)
        setBaseUrl(all_selected)

        setFormData((data1) => ({
            ...data1,
            image: all_files, selectedFile: all_selected, deleteFile: deleteImage
        }));
    };
    useEffect(() => {
        formData.description = descriptionData;
    }, [descriptionData])

    /**************** on submit event *********************/
    const onSubmit = (e) => {
        e.preventDefault();
        if (news_id) {
            edit(formData, navigate, news_id);
        } else {
            create(formData, navigate);
        }
    };

    return (
        <div className="animated fadeIn">
            {loading ? (
                <Spinner />
            ) : (
                <Row>
                    <Col xs="12" sm="12">
                        <Card>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => onSubmit(e)}
                                encType="multipart/form-data"
                            >
                                <CardBody>
                                    <div>
                                        <Card className="my-2">
                                            <CardHeader tag="h5">SEO Information</CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <Label htmlFor="meta_title">
                                                                Title
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                id="meta_title"
                                                                name="meta_title"
                                                                value={meta_title}
                                                                onChange={handleNewsChange}
                                                                invalid={errorList.meta_title ? true : false}
                                                            />
                                                            <Errors
                                                                current_key="meta_title"
                                                                key="meta_title"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <Label htmlFor="meta_description">
                                                                Meta Description
                                                            </Label>
                                                            <Input
                                                                type="textarea"
                                                                id="meta_description"
                                                                name="meta_description"
                                                                value={meta_description}
                                                                onChange={handleNewsChange}
                                                                invalid={errorList.meta_description ? true : false}
                                                            />
                                                            <Errors
                                                                current_key="meta_description"
                                                                key="meta_description"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <Card className="my-2">
                                        <CardHeader tag="h5">
                                            News Information
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="title">
                                                            News Title <span>*</span>
                                                        </Label>
                                                        <input
                                                            className="form-control"
                                                            name="title"
                                                            value={title}
                                                            maxLength={100}
                                                            onChange={e => handleNewsChange(e)}
                                                            invalid={errorList[`title`]}
                                                        />
                                                        <Errors current_key={`title`} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="slug">
                                                            News Slug <span>*</span>
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="slug"
                                                            name="slug"
                                                            maxLength="100"
                                                            value={slug}
                                                            required
                                                            readOnly
                                                            invalid={errorList.slug ? true : false}
                                                        />
                                                        <Errors current_key="slug" key="slug" />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="subtitle">
                                                            News Sub Title
                                                        </Label>
                                                        <input
                                                            className="ml10 form-control"
                                                            name="subtitle"
                                                            value={subtitle}
                                                            maxLength={100}
                                                            onChange={e => handleNewsChange(e)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                {/* <Col xs="6" className="justify-flex-end">
                                                    <FormGroup>
                                                        <Label htmlFor="thumbnail">
                                                            Video URL
                                                        </Label>
                                                        <Input
                                                            type="url"
                                                            id="videoUrl"
                                                            name="videoUrl"
                                                            onChange={(e) => handleNewsChange(e)}
                                                            value={videoUrl}
                                                            className="form-control"
                                                        />
                                                    </FormGroup>
                                                </Col> */}
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="videoUrl">
                                                            Upload Video (Video maximum size should be 500MB.)
                                                        </Label>
                                                        <Input
                                                            type="file"
                                                            accept="video/*"
                                                            id="videoUrl"
                                                            name="videoUrl"
                                                            onChange={(e) => handleNewsChange(e)}
                                                            invalid={errorList.videoUrl ? true : false}
                                                        />
                                                        {videoUrl !== '' &&
                                                            <div className="thumb">
                                                                <video
                                                                    controls={true}
                                                                    src={videoUrl}
                                                                    className="preview-video"
                                                                    alt=""
                                                                />
                                                                <Button
                                                                    title="Remove Video"
                                                                    className="btn btn-sm btn-danger"
                                                                    style={{ height: "30px", borderRadius: "20px", textAlign: "center", paddingTop: "3px" }}
                                                                    onClick={(e) => removeVideoFile(e)}
                                                                >
                                                                    <i className="fa fa-close"></i>
                                                                </Button>
                                                            </div>
                                                        }
                                                        <Errors current_key="videoUrl" key="videoUrl" />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="thumbnail">
                                                            Images <span>*</span> <span>(The image aspect ratio should be 16:9 and the maximum size allowed should be 20MB)</span>
                                                        </Label>
                                                        <Input
                                                            type="file"
                                                            accept="image/*"
                                                            id="thumbnail"
                                                            name="thumbnail"
                                                            onChange={e => handleNewsChange(e)}
                                                        />
                                                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                            {formData.image != undefined && image &&
                                                                Object.values(image).map(
                                                                    (imageItems, index) => {
                                                                        return (
                                                                            <div className="thumb" key={index}>
                                                                                <Col key={`img-${formData.image}`}>
                                                                                    {imageItems ? (
                                                                                        <img
                                                                                            src={imageItems.original ? imageItems.original : imageItems}
                                                                                            className="preview-img"
                                                                                            alt="image_file"
                                                                                            style={{ height: "40px" }}
                                                                                        />
                                                                                    ) : null}
                                                                                </Col>
                                                                                <Button
                                                                                    title="Remove Image"
                                                                                    className="btn btn-sm btn-danger"
                                                                                    data-id={imageItems._id}
                                                                                    onClick={removeFile(index)}
                                                                                >
                                                                                    <i className="fa fa-close"></i>
                                                                                </Button>
                                                                            </div>
                                                                        )
                                                                    })}
                                                        </div>
                                                        {selectedFile && <Errors current_key={`selectedFile`} key="selectedFile" />}
                                                    </FormGroup>
                                                </Col>
                                                {news_id ?
                                                    <Col sm="12">
                                                        <FormGroup>
                                                            <Label htmlFor="description">
                                                                Description
                                                            </Label>
                                                            {description &&
                                                                <CKEditor
                                                                    initData={description}
                                                                    id="description"
                                                                    name="description"
                                                                    config={CKEditorConfig}
                                                                    onChange={e => handelDescriptionChange(e)}
                                                                />
                                                            }
                                                            {description == '' &&
                                                                <CKEditor
                                                                    initData={description}
                                                                    id="description"
                                                                    name="description"
                                                                    config={CKEditorConfig}
                                                                    onChange={e => handelDescriptionChange(e)}
                                                                />}
                                                        </FormGroup>
                                                    </Col>
                                                    :
                                                    <Col sm="12">
                                                        <FormGroup>
                                                            <Label htmlFor="description">
                                                                Description
                                                            </Label>
                                                            <CKEditor
                                                                initData={description}
                                                                id="description"
                                                                name="description"
                                                                config={CKEditorConfig}
                                                                onChange={e => handelDescriptionChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                }
                                            </Row>
                                        </CardBody>
                                        <CardFooter>
                                            <Button type="submit" size="sm" color="primary" disabled={loading}>
                                                <i className="fa fa-dot-circle-o"></i> Submit
                                            </Button>
                                            <Link to="/admin/news">
                                                <Button type="reset" size="sm" color="danger">
                                                    <i className="fa fa-ban"></i> Cancel
                                                </Button>
                                            </Link>
                                        </CardFooter>
                                    </Card>
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            )}
        </div>
    );
};

AddEditNews.propTypes = {
    getNewsById: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    news: PropTypes.object.isRequired,
    create: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
    news: state.news,
    errorList: state.errors,
    loading: state.news.loading,
});

export default connect(mapStateToProps, {
    create,
    getNewsById,
    edit,
})(AddEditNews);
