import React, { Fragment } from 'react';
import Moment from 'react-moment';
const fronturl = window.location.origin;
const DefaultFooter = () => (
  <Fragment>
    <span>
      <a href={fronturl} target="_blank" rel="noopener noreferrer">
        {/* {process.env.REACT_APP_APP_NAME}*/}
        Kinetixx Golf
      </a>{' '}
      &copy; <Moment format="YYYY" locale="de" />
    </span>
  </Fragment>
);

export default DefaultFooter;
