import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';

import { create, edit, getIronNumberById } from 'actions/admin/ironNumber';

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';

import { useNavigate } from 'react-router-dom';

const AddEditIronNomenclature = ({
    create,
    errorList,
    loading,
    getIronNumberById,
    edit,
    ironNumber: { currentIronNumber },
}) => {
    let navigate = useNavigate();
    /**************** use state for form data *********************/
    const [formData, setFormData] = useState({
        iron_type: '',
        shaft_length: '',
    });

    const { id: id } = useParams();

    useEffect(() => {
        if (id) getIronNumberById(id);
    }, [id]);

    /**************** expload form data *********************/
    const {
        iron_type,
        shaft_length,
    } = formData;

    useEffect(() => {
        if (Object.keys(currentIronNumber).length > 0 && id) {
            setFormData(currentIronNumber);
        }
    }, [currentIronNumber]);

    // handle input change
    const handleChange = (e) => {
        setFormData((data1) => ({
            ...data1,
            [e?.target?.name]: e?.target?.value.trimLeft()
        }));
    };

    /**************** on submit event *********************/
    const onSubmit = (e) => {
        e.preventDefault();
        if (id) {
            edit(formData, navigate, id);
        } else {
            create(formData, navigate);
        }
    };

    return (
        <div className="animated fadeIn">
            {loading ? (
                <Spinner />
            ) : (
                <Row>
                    <Col xs="12" sm="12">
                        <Card>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => onSubmit(e)}
                                encType="multipart/form-data"
                            >
                                <CardBody>
                                    <div>
                                        <Card className="my-2">
                                            <CardHeader tag="h5">{id ? 'Edit' : 'Add'} the Iron Number Nomenclature</CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <Label htmlFor="iron_type">
                                                                Iron # / Iron Type  <span>*</span>
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                id="iron_type"
                                                                name="iron_type"
                                                                value={iron_type}
                                                                required
                                                                onChange={handleChange}
                                                                invalid={errorList.iron_type ? true : false}
                                                            />
                                                            <Errors
                                                                current_key="iron_type"
                                                                key="iron_type"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <Label htmlFor="shaft_length">
                                                                Raw Shaft Length Recommendation <span>*</span>
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                id="shaft_length"
                                                                name="shaft_length"
                                                                value={shaft_length}
                                                                required
                                                                onChange={handleChange}
                                                                invalid={errorList.shaft_length ? true : false}
                                                            />
                                                            <Errors
                                                                current_key="shaft_length"
                                                                key="shaft_length"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <Button type="submit" size="sm" color="primary" disabled={loading}>
                                        <i className="fa fa-dot-circle-o"></i> Submit
                                    </Button>
                                    <Link to="/admin/iron-number-nomenclature">
                                        <Button type="reset" size="sm" color="danger">
                                            <i className="fa fa-ban"></i> Cancel
                                        </Button>
                                    </Link>
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            )}
        </div>
    );
};

AddEditIronNomenclature.propTypes = {
    getIronNumberById: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    ironNumber: PropTypes.object.isRequired,
    create: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
    ironNumber: state.ironNumber,
    errorList: state.errors,
    loading: state.ironNumber.loading,
});

export default connect(mapStateToProps, {
    create,
    getIronNumberById,
    edit,
})(AddEditIronNomenclature);
