import axios from 'axios';

import {
    RESOURCE_LOADING,
    RESOURCE_CREATED,
    RESOURCE_CREATE_ERROR,
    RESOURCE_FAIL,
    RESOURCE_UPDATED,
    RESOURCE_UPDATE_ERROR,
    RESOURCE_DELETED,
    RESOURCE_DELETE_ERROR,
    RESOURCE_LIST_UPDATED,
    GET_RESOURCE_BY_ID,
    RESOURCE_CHANGE_STATUS,
    RESOURCE_CHANGE_STATUS_ERROR,
    SET_ERRORS_LIST,
} from 'actions/types';

import { updateFilterData } from 'actions/commonActions';

// Create Resource
export const create = (formData, history) => async (dispatch) => {
    try {
        dispatch({
            type: RESOURCE_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        const config = {
            headers: {
                'content-type': 'application/json',
            },
        };

        const res = await axios.post('/api/admin/resources/create', formData, config);
        if (res.data.status === true) {
            dispatch({
                type: RESOURCE_CREATED,
                payload: {
                    message: res.data.message,
                    alertType: 'success',
                    data: res.data.response,
                },
            });
            history('/admin/resources');
        } else {
            const errors = res.data.errors;
            let errorsList = {};
            if (errors) {
                await errors.forEach((error) => {
                    errorsList = { ...errorsList, [error.param]: error.msg };
                });
            }
            dispatch({
                type: RESOURCE_CREATE_ERROR,
                payload: {
                    message: res.data.message,
                    alertType: 'danger',
                    errorsList,
                },
            });
            dispatch({
                type: SET_ERRORS_LIST,
                payload: { ...errorsList },
            });
        }
    } catch (err) {
        dispatch({
            type: RESOURCE_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
    }
};

// Edit Resource
export const edit = (formData, history, resource_id) => async (dispatch) => {
    try {
        dispatch({
            type: RESOURCE_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        const config = {
            headers: {
                'content-type': 'application/json',
            },
        };

        const res = await axios.post(
            `/api/admin/resources/${resource_id}`,
            formData,
            config
        );
        if (res.data.status === true) {
            dispatch({
                type: RESOURCE_UPDATED,
                payload: {
                    message: res.data.message,
                    alertType: 'success',
                    data: res.data.response,
                },
            });
            history('/admin/resources');
        } else {
            const errors = res.data.errors;
            let errorsList = {};
            if (errors) {
                await errors.forEach((error) => {
                    errorsList = { ...errorsList, [error.param]: error.msg };
                });
            }
            dispatch({
                type: RESOURCE_UPDATE_ERROR,
                payload: {
                    message: res.data.message,
                    alertType: 'danger',
                    errorsList,
                },
            });
            dispatch({
                type: SET_ERRORS_LIST,
                payload: { ...errorsList },
            });
        }
    } catch (err) {
        dispatch({
            type: RESOURCE_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
    }
};

// Delete Resource Page
export const deleteResource = (resource_id) => async (dispatch) => {
    try {
        dispatch({
            type: RESOURCE_LOADING,
            payload: { is_loading: true, is_sub_element: true },
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const res = await axios.delete(`/api/admin/resources/${resource_id}`, config);

        if (res.data.status === true) {
            dispatch({
                type: RESOURCE_DELETED,
                payload: {
                    message: res.data.message,
                    alertType: 'success',
                    data: res.data.response,
                },
            });
            return true;
        } else {
            dispatch({
                type: RESOURCE_DELETE_ERROR,
                payload: {
                    message: res.data.message,
                    alertType: 'danger',
                },
            });
            return false;
        }
    } catch (err) {
        dispatch({
            type: RESOURCE_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
        return false;
    }
};

//get Resource list
export const getResourceList = (resourceParams) => async (dispatch) => {
    try {
        dispatch({
            type: RESOURCE_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        resourceParams = updateFilterData(resourceParams);
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: resourceParams,
        };
        // const query = resourceParams.query ? resourceParams.query : '&query=';

        const res = await axios.get(`/api/admin/resources/`, config);
        dispatch({
            type: RESOURCE_LIST_UPDATED,
            payload: {
                message: res.data.message,
                alertType: 'success',
                data: {
                    resourceList: res.data.response[0],
                },
            },
        });
        return res;
    } catch (err) {
        dispatch({
            type: RESOURCE_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
    }
};

// Get Resource by id
export const getResourceById = (resource_id) => async (dispatch) => {
    try {
        dispatch({
            type: RESOURCE_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const res = await axios.get(`/api/admin/resources/${resource_id}`, config);

        await dispatch({
            type: GET_RESOURCE_BY_ID,
            payload: {
                message: res.data.message,
                alertType: 'success',
                data: res.data.response,
            },
        });
    } catch (err) {
        dispatch({
            type: RESOURCE_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
    }
};

//change status
export const changeStatus = (resource_id, status) => async (dispatch) => {
    try {
        dispatch({
            type: RESOURCE_LOADING,
            payload: { is_loading: true, is_sub_element: true },
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const res = await axios.post(
            `/api/admin/resources/change-status/${resource_id}`,
            { status },
            config
        );

        if (res.data.status === true) {
            await dispatch({
                type: RESOURCE_CHANGE_STATUS,
                payload: {
                    message: res.data.message,
                    alertType: 'success',
                    data: res.data.response,
                },
            });
        } else {
            //add a key is_failed=true else is_failed=false
            dispatch({
                type: RESOURCE_CHANGE_STATUS_ERROR,
                payload: {
                    message: res.data.message,
                    alertType: 'danger',
                    data: resource_id,
                },
            });
        }
    } catch (err) {
        dispatch({
            type: RESOURCE_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
    }
};
