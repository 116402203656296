/* eslint-disable */
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import Moment from 'react-moment';
import Spinner from 'views/Spinner';
import { USER_ROLE, ORDER_LIST_PAYMENT_STATUS } from 'constants/index';
import { ADMIN_CART_UPDATED } from 'actions/types';
import Select from 'react-select';
import {
  getOrderById,
  addToCart,
  getCartDetails,
  addToProducts,
  clearCart,
  calculateShippingRates,
  getUserAddressList,
  updateCart,
  getProductAttributes,
  //getProductsList,
  getUsersList,
  applyCoupon,
  addToOrder,
  removeCoupon,
  getTaxRates,
  addToCartOrderData,
} from 'actions/admin/order';
import './Order.css';
import BillingDetail from 'views/Admin/Orders/BillingDetail';
import PreviewForm from 'views/Admin/Orders/PreviewForm';
import ShippingRates from './ShippingRates';
import { getProductsList } from 'actions/admin/product';
import SelectVariations from './SelectVariations';

const EditOrder = ({
  getOrderById,
  addToCart,
  addToProducts,
  clearCart,
  updateCart,
  currentOrder,
  loading,
  getCartDetails,
  getUserAddressList,
  calculateShippingRates,
  getProductAttributes,
  productsList,
  getProductsList,

  getUsersList,
  applyCoupon,
  usersList,
  user_list_loading,
  addToOrder,
  removeCoupon,
  getTaxRates,
  addToCartOrderData,
}) => {

  const match = useMatch('/admin/orders/edit/:order_id');
  const orderId = currentOrder._id;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sortingParams = {
    limit: 2000,
    page: 1,
    orderBy: 'updated_at',
    ascending: 'desc',
    query: '',
    addEditOrder: true
  };
  const [isOrderUpdate, setIsOrderUpdate] = useState(true);
  const [cartId, setCartId] = useState(0);
  const [total, setTotal] = useState(0);
  const [productTotalAmount, setProductTotalAmount] = useState(0);
  const [fittingTotalAmount, setFittingTotalAmount] = useState(0);
  const [currentCart, setcurrentCart] = useState([]);
  const [addedProduct, setAddedProduct] = useState([]);
  const [isOrderEdit, setIsOrderEdit] = useState(false);
  const [couponCode, setcouponCode] = useState('');
  const [couponDiscount, setDiscountAmount] = useState(0);
  const [productVariatonDetails, setProductVariationDetails] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [showAddEditForm, setShowAddEditForm] = useState(true);
  const [showPreviewForm, setPreviewForm] = useState(false);
  //const [customerName, setCustomerName] = useState();
  const [customShaftChecked, setCustomShaftChecked] = useState();
  const [fittingList, setFittingList] = useState();
  const [customization, setCustomization] = useState(false);
  const [isCartUpdated, setIsCartUpdated] = useState(false);
  const [payment_status, setPaymentStatus] = useState(1);
  let productQuantity = 1;

  const [selectedProduct, setSelectedProduct] = useState('');
  const [cartProducts, setcartProducts] = useState([]);
  const [slug, setSlug] = useState('');
  const [variation, setVariation] = useState({});
  const [variationModal, setOpenVariationModal] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [quantityList, setQuantityList] = useState(null);
  const [cartTotal, setCartTotal] = useState(0);
  const [tempCart, setTempCart] = useState([]);
  const [alreadyPaidAmount, setAlreadyPaidAmount] = useState(0);
  const [is_submit, setIsSubmit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  //########################### usestate for billing address ##############################
  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
  const [selectedBillingLabel, setSelectedBillingLabel] = useState(null);

  //########################### usestate for shipping address ##############################

  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);
  const [selectedShippingLabel, setSelectedShippingLabel] = useState(null);

  //##################### ship to different address checkbox #########################
  const [shippToDifferentAddressChecked, setShippToDifferentAddressChecked] =
    useState(false);
  //########################### usestate for selected shipping method ##############################
  const [selectedShippingMode, setSelectedShippingMode] = useState({});

  //########################### usestate shipping mode error ##############################
  const [shippingModeError, setShippingModeError] = useState(
    'Enter your full address to see shipping costs.'
  );

  //########################### usestate for shipping box ##############################
  const [shippingBox, setShippingBox] = useState([]);

  //########################### usestate to get shipping rates ##############################
  const [isGetShippingRates, setIsGetShippingRates] = useState(false);

  //########################### usestate for delivery instruction ##############################
  const [deliveryInstruction, setDeliveryInstruction] = useState('');

  //########################### usestate for shipping rates ##############################
  const [shippingPrice, setShippingPrice] = useState(0);
  const [shippingMessages, setShippingMessages] = useState([]);
  const [groupedRatesByProvider, setGroupedRatesByProvider] = useState({});

  const [shippingRates, setShippingRates] = useState([]);

  const [taxApplied, setTaxApplied] = useState(0);
  const [totalBeforeTax, setTotalBeforeTax] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [taxData, setTaxData] = useState({});

  //################# set select user name value ################
  const [userValue, setUserValue] = useState('');

  //########################### usestate for Grand total ##############################
  const [grandTotal, setGrandTotal] = useState(0);

  //########################### usestate for EasyPost Order Id ##############################
  const [easyPostOrderId, setEasyPostOrderId] = useState('');

  const [selectedFittingsData, setSelectedFittingsData] = useState();
  const [editAddress, setEditAddress] = useState(false);
  const [shippingService, setShippingService] = useState(false);


  const [carrier, setCarrier] = useState('');
  const [service, setService] = useState('');
  const [shippingCharges, setShippingCharges] = useState('');
  const [paidAmount, setPaidAmount] = useState('');
  const [dueAmount, setDueAmount] = useState(0);

  const initRates = {
    product: 0,
    adaptor: 0,
    grip_option: 0,
  };
  const [productRates, setProductRates] = useState(initRates);

  //################## useState for selected fitting  ##############################
  const [selectedFitting, setSelectedFitting] = useState([]);

  const [variationSelectErrorMsg, setVariationSelectErrorMsg] = useState('');

  const [variationSelectError, setVariationSelectError] = useState(false);
  const [stockQuantity, setStockQuantity] = useState(0);
  let userId = currentOrder.user_id;
  let userType =
    currentOrder.user_type && currentOrder.user_type.length > 0
      ? currentOrder.user_type[0]
      : '';
  let customerName = currentOrder.user_name;
  useEffect(() => {
    setSelectedBillingAddress(null);
    setSelectedShippingAddress(null);
  }, []);

  //########################### useEffect to on change of billing address##############################
  useEffect(() => {
    setEasyPostOrderId('');
    setShippingBox([]);
    setShippingModeError('');
    setShippingMessages([]);
    setShippingRates([]);
    setGroupedRatesByProvider({});
    setSelectedShippingMode({});
    setShippingPrice(0);

    if (selectedShippingAddress != null) {
      let selectedAddress = selectedShippingAddress;
      if (userId !== null && addedProduct.length > 0)
        calculateShippingRates(selectedAddress, addedProduct, userId).then(

          async (res) => {

            if (res.status) {
              setIsEdit(true);
              setTaxData(res.taxData);
              setEasyPostOrderId(res.order_id);
              setShippingBox(res.data);
              setShippingRates(res.rate);
              setTaxApplied(res.taxData.applyTaxRate);
              if (res.rate.length)
                setGroupedRatesByProvider(
                  groupRatesByProvider(res.rate, 'carrier')
                );
              if (res.message) {
                setShippingMessages(res.message);
              }
              //setSelectedShippingMode(currentOrder.shipping_mode);
            } else {
              if (res.message.__all__) {
                setShippingModeError(res.message.__all__[0]);
              } else if (res.message.parcels) {
                let error =
                  Object.keys(res.message.parcels[0]).length > 0 ? (
                    <p>
                      <span>
                        {res.message.parcels[0].__all__
                          ? res.message.parcels[0].__all__[0]
                          : null}
                      </span>
                      <br></br>
                      <span>
                        {res.message.parcels[0].weight
                          ? res.message.parcels[0].weight[0]
                          : null}
                      </span>
                      <br></br>
                      <span>
                        {res.message.parcels[0].height
                          ? res.message.parcels[0].height[0]
                          : null}
                      </span>
                      <br></br>
                      <span>
                        {res.message.parcels[0].length
                          ? res.message.parcels[0].length[0]
                          : null}
                      </span>
                      <br></br>
                      <span>
                        {res.message.parcels[0].width
                          ? res.message.parcels[0].width[0]
                          : null}
                      </span>
                      <br></br>
                    </p>
                  ) : null;

                setShippingModeError(error ?? 'Parcel Error');
              } else {
                setShippingModeError(res.message);
              }
              setEasyPostOrderId('');
              setShippingBox([]);
              setShippingRates([]);
              setGroupedRatesByProvider({});
            }
          }
        );
    }
  }, [
    selectedShippingAddress,
    //selectedShippingLabel,
    addedProduct,
    isCartUpdated,
    productQuantity,
  ]);
  const groupRatesByProvider = (rate, key) => {
    return rate.reduce((reduceRate, reduceKey) => {
      (reduceRate[reduceKey[key]] = reduceRate[reduceKey[key]] ?? []).push(
        reduceKey
      );
      return reduceRate;
    }, {});
  };
  //################## to get updated list ###################
  const [productListValue, setProductListValue] = useState();
  let productListData = [];
  useEffect(() => {
    productsList &&
      productsList.data.map((product, idx) => {
        productListData.push({
          _id: product._id,
          value: product._id,
          label: product.product_title + ' (' + product.category + ')',
          slug: product.slug,
          status: product.status,
          product_title: product.product_title,
        });
      });
    setProductListValue(productListData);
  }, [productsList]);

  useEffect(() => {

    let is_delete = false;
    if (currentOrder.products) {
      getProductAttributes().then((res) => {
        if (res) {
          const cart = [];
          let tempArray = [];
          currentOrder.products.map((item) => {
            return Object.keys(item.product_variation).map((iter) => {
              const attributeType = item.categoryType[0].toLowerCase() === "other" ? item.categoryType[0].toLowerCase() : "shaft";
              tempArray.push(res.find((i) => i.name === iter && i.attribute_type === attributeType))
            });
          });
          currentOrder.products.map((product) => {
            let attribute_list = {};
            for (const [key, value] of Object.entries(
              product.product_variation
            )) {
              let obj = tempArray.find((i) => i.name === `${key}`);
              //console.log(tempArray, "dsdfsdfsdfsdf")
              let obj1 = obj.terms.find((iter) => iter.option === `${value}`);
              if (obj1 !== undefined) {
                attribute_list[obj._id] = obj1._id;
              }
            }

            const productDetails = {
              fitting_options: product.fitting_options
                ? product.fitting_options
                : [],
              product_id: product.product_id,
              product_slug: product.product_slug,
              product_variation: attribute_list,
              product_variation_sku: product.sku,
              product_variation_id: product.product_variation_id,
              quantity: product.quantity,
            };
            cart.push(productDetails);
          });
          console.log(cart, "productDetailsproductDetails")
          setTempCart(cart);
        }
      });
    }
  }, [productsList]);
  //########################### useEffect redirect after update ##############################
  useEffect(() => {
    if (is_submit) navigate('/admin/orders');
  }, [is_submit]);

  //################ get selected user's address list ####################
  useEffect(() => {

    if (currentOrder.user_id) {
      setAlreadyPaidAmount(currentOrder.paid_amount['$numberDecimal']);
      getUserAddressList(currentOrder.user_id).then(async (res) => {
        if (res && res.length > 0) {
          const addresses = await res.filter(
            (address) =>
              address.address_id === currentOrder.shipping_address.address_id &&
              address.address === currentOrder.shipping_address.address
          );
          setShippingAddress(addresses[0]._id);
        }
      });
    }
  }, [currentOrder.user_id]);
  //########################### enable disable add to cart button ##############################
  const [initialEnableDisableCartButton, setInitialEnableDisableCartButton] =
    useState(true);

  //################## to get updated list ###################
  useEffect(() => {
    if (currentOrder.user_id) {
      // Clear Cart On Page Load
      getProductsList(sortingParams);
    }

  }, [currentOrder]);

  useEffect(() => {
    if (selectedProduct?.product_title) {
      setOpenVariationModal(!variationModal);
    }
  }, [selectedProduct?.product_title]);

  //################## Get Details of order by id ###################
  useMemo(() => {
    getOrderById(match.params.order_id);
  }, [getOrderById, match.params.order_id]);
  const handleSelectProduct = (e, action) => {
    setQuantity(1);
    setCustomization(false);
    if (action.action === 'select-option') {
      setSlug(e.slug);
      setSelectedProduct(e);
    }

    setIsEdit(false);
    setCustomShaftChecked(false);
    setFittingList({});
    setSelectedFitting({});
    setSelectedFittingsData({});
  };
  const resetVariationModal = () => {
    setSelectedProduct('');
    setVariationSelectErrorMsg('');
    setVariationSelectError(false);
  };

  const toggleVariationModal = () => {
    setVariation({});
    setOpenVariationModal(!variationModal);
    setInitialEnableDisableCartButton(true);
  };

  const handleVariationSelect = () => {
    selectedProduct.product_variation = variation;
    const productDetails = {
      fitting: selectedFitting,
      product_id: selectedProduct._id,
      product_slug: selectedProduct.slug,
      product_variation: selectedProduct.product_variation,
      product_variation_sku: productVariatonDetails.product_variation_sku,
      product_variation_id: productVariatonDetails.product_variation_id,
      quantity: quantity,
      customization: customization,
    };
    let is_delete = false;
    let cart_user_id = orderId ? orderId : userId;
    {
      isEdit
        ? updateCart(is_delete, productDetails, cart_user_id, orderId).then(
          (res) => {
            if (res?.data && res?.data.status) {
              setIsCartUpdated(true);
              callGetCartApi(orderId);
              setVariation({});
              setOpenVariationModal(!variationModal);
              setInitialEnableDisableCartButton(true);
              setProductVariationDetails({});
              setSelectedFitting([]);
            }
          }
        )
        : addToCart(
          is_delete,
          productDetails,
          cart_user_id,
          '',
          '',
          userType,
          currentOrder.user_id
        ).then((res) => {
          if (res?.data && res?.data.status == true) {
            callGetCartApi(orderId);
            setOpenVariationModal(!variationModal);
            setInitialEnableDisableCartButton(true);
            setProductVariationDetails({});
            setSelectedFitting([]);
            setShippingService(true);
          } else {
            setCustomShaftChecked(true);
            setOpenVariationModal(true);
            setCustomization(true);

            setInitialEnableDisableCartButton(true);
          }
        });
    }
  };

  const editProduct = (product) => {
    const variationData = {};
    product.productVariationDetail.map((variation) => {
      variationData[variation.attribute_id] = variation.term_id;
    });
    const fittingData = {};

    {
      product.productFitting &&
        product.productFitting.map((fittingOptions) => {
          if (fittingOptions.attribute_name == 'Adaptors') {
            fittingData['adaptor'] = fittingOptions.option_id;
            fittingData['adaptor_option'] = fittingOptions.sub_option_id;
          }
          if (fittingOptions.attribute_name == 'Playing Length') {
            fittingData['playing'] = fittingOptions.option_id;
          }
          if (fittingOptions.attribute_name == 'Grips') {
            fittingData['grip'] = fittingOptions.option_id;
            fittingData['grip_option'] = fittingOptions.sub_option_id;
          }
          if (fittingOptions.attribute_name == 'Extra Wrap') {
            fittingData['extra_wrap'] = fittingOptions.attribute_value;
          }
          if (fittingOptions.attribute_name == 'Grip Logo') {
            fittingData['grip_logo'] = fittingOptions.attribute_value;
          }
          if (fittingOptions.attribute_name == 'Product Customization') {
            fittingData['product_customization'] =
              fittingOptions.attribute_value;
          }
        });
      setSelectedFittingsData(fittingData);
    }
    setVariation(variationData);
    setSlug(product.product_slug);
    setSelectedProduct(product);
    setQuantity(product.quantity);
    setIsEdit(true);
    setCustomization(product.productFitting?.length);
  };
  const editOrderProduct = (product) => {
    let is_delete = false;

    addToCartOrderData(
      false,
      tempCart,
      orderId,
      '',
      '',
      userType,
      currentOrder.user_id
    ).then((res) => {
      setAddedProduct(res.data.response.products);
      setIsOrderEdit(true);
      callGetCartApi(orderId);
    });
    const fittingData = {};

    {
      product.productFitting &&
        product.productFitting.map((fittingOptions) => {
          if (fittingOptions.attribute_name == 'Adaptors') {
            fittingData['adaptor'] = fittingOptions.option_id;
            fittingData['adaptor_option'] = fittingOptions.sub_option_id;
          }
          if (fittingOptions.attribute_name == 'Playing Length') {
            fittingData['playing'] = fittingOptions.option_id;
          }
          if (fittingOptions.attribute_name == 'Grips') {
            fittingData['grip'] = fittingOptions.option_id;
            fittingData['grip_option'] = fittingOptions.sub_option_id;
          }
          if (fittingOptions.attribute_name == 'Extra Wrap') {
            fittingData['extra_wrap'] = fittingOptions.attribute_value;
          }
          if (fittingOptions.attribute_name == 'Grip Logo') {
            fittingData['grip_logo'] = fittingOptions.attribute_value;
          }
          if (fittingOptions.attribute_name == 'Product Customization') {
            fittingData['product_customization'] =
              fittingOptions.attribute_value;
          }
        });
      setSelectedFittingsData(fittingData);
    }

    setSlug(product.product_slug);
    setSelectedProduct(product);
    setQuantity(product.quantity);
    setIsEdit(true);
    setCustomization(product.productFitting?.length);
  };

  const deleteProduct = (cartId, cart_product_id) => {
    let is_delete = true;
    const productDetails = {
      cart_id: cartId,
      product_id: cart_product_id,
      quantity: productQuantity,
    };
    addToCart(
      is_delete,
      productDetails,
      orderId,
      cartId,
      cart_product_id,
      userType,
      currentOrder.user_id
    ).then((res) => {

      if (res.data.status) {
        callGetCartApi(orderId);
      }
    });
    setTaxTotal(taxTotal);
  };

  const onChangeProductQuantity = (e, inputId) => {
    let updatedProductList = cartProducts.filter((product) => {
      if (product._id === inputId) {
        product.quantity = e.target.value;
        return product;
      }
      return product;
    });
    setcartProducts(updatedProductList);
  };

  //###################### on payment status change ####################
  const handleOnChange = (e) => {
    if (e.target.name === 'payment_status') {
      setPaymentStatus(e.target.value);
    }
  };

  //########################### usestate for is coupon applied ##############################
  const [isApplied, setIsApplied] = useState(false);
  const [couponDetails, setCouponDetails] = useState({
    coupon_code: '',
    coupon_id: '',
  });

  const onEditAddress = (e) => {
    e.preventDefault();
    setEditAddress(true);
    setShippingService(true);
  };

  const onRemoveClick = () => {
    removeCoupon(userId).then((res) => {
      if (res.status) {
        setDiscountAmount(0);
        let totalAmountData = fittingTotalAmount + productTotalAmount;
        setTotal(totalAmountData);
        setIsApplied(false);
        setCouponDetails({ coupon_code: '', coupon_id: '' });
      }
    });
  };
  const onChangeCouponCode = (e) => {
    e.target.value = e.target.value?.trim()?.replace(/[^a-zA-Z0-9]/g, '');
    setcouponCode(e.target.value?.trim());
  };

  const handleApplyCoupon = (e) => {
    e.preventDefault();
    applyCoupon(couponCode, productTotalAmount, userId, orderId).then((res) => {
      if (res.status) {
        setDiscountAmount(res.data.discounted_amount);
        let totalAmountData =
          fittingTotalAmount + productTotalAmount - res.data.discounted_amount;
        setTotal(totalAmountData);
        setIsApplied(true);
        setCouponDetails({
          coupon_code: res.data.coupon_code,
          coupon_id: res.data.coupon_id,
        });
      } else {
        setDiscountAmount(0);
        setTotal(total);
        setIsApplied(false);
        setCouponDetails({ coupon_code: '', coupon_id: '' });
      }
    });
  };

  //########################### useEffect to set grand total ##############################
  const callGetCartApi = (id = null) => {
    if (id) {

      getCartDetails(id, currentOrder.user_id).then((res) => {

        if (res?.data && res?.data.status) {
          setcurrentCart(res.data.response.products);
          setAddedProduct(
            res.data.response.products ? res.data.response.products : []
          );
          setCartId(res.data.response._id);
          setTotal(res.data.response.totalCartAmount);
          setProductTotalAmount(res.data.response.totalProductAmount);
          setFittingTotalAmount(res.data.response.totalFittingAmount);
          setCartTotal(res.data.response.totalCartAmount);
          if (res.data.response.coupon?.coupon_id) {
            setDiscountAmount(res.data.response.coupon.discounted_amount);
            setTotal(
              res.data.response.totalProductAmount -
              res.data.response.coupon.discounted_amount
            );
            setIsApplied(true);
            setCouponDetails({
              coupon_code: res.data.response.coupon.coupon_code,
              coupon_id: res.data.response.coupon.coupon_id,
            });
          } else {
            setTotal(total);
            setIsApplied(false);
            setCouponDetails({ coupon_code: '', coupon_id: '' });
          }

          setDeliveryInstruction(currentOrder.delivery_instruction);
          //setSelectedShippingMode(currentOrder.shipping_mode);

          if (Object.keys(currentOrder.discounted_amount).length > 0 && currentOrder.discounted_amount.$numberDecimal > 0) {

            setIsApplied(true);
            setDiscountAmount(currentOrder.discounted_amount.$numberDecimal);
            setCouponDetails({
              coupon_code: currentOrder.coupon.coupon_code,
              coupon_id: currentOrder.coupon.coupon_id,
            });
          }

          if (currentOrder.tax_total) {
            setTaxApplied(true)
            setTotalBeforeTax(currentOrder.total_before_tax);
            //setTaxTotal(currentOrder.tax_total ? currentOrder.tax_total : 0);
          }
        } else {
          setcurrentCart([]);
          setAddedProduct([]);
          setTotal(0);
          setCartTotal(0);
          setDiscountAmount(0);
          setIsApplied(false);
          setCouponDetails({ coupon_code: '', coupon_id: '' });
        }
      });
    }
  };
  useEffect(async () => {

    let tax = 0.0;
    if (taxData) {
      if (taxData?.applyTaxRate) {
        const taxRate = Number(taxData.tax.value);
        let amount =
          Number(fittingTotalAmount) +
          Number(productTotalAmount) -
          Number(couponDiscount);
        tax = (Number(amount) * taxRate) / 100;
      }
    }


    if (isEdit == false) {
      setSelectedShippingMode(currentOrder.shipping_mode);
      setShippingPrice(currentOrder.shipping_mode.totalCharges);
      tax = currentOrder.tax_total;
      setTaxTotal(currentOrder.tax_total);
      //setShippingService(true);
      setSelectedShippingAddress(currentOrder.shipping_address.add_id);
      setSelectedBillingAddress(currentOrder.billing_address.add_id);
      setShippToDifferentAddressChecked(currentOrder.shipToDifferent)
      setPaymentStatus(currentOrder.payment_status);
      setPaidAmount(Number(currentOrder.paid_amount['$numberDecimal']));
      //setDueAmount(Number(grandTotal)-Number(currentOrder.paid_amount['$numberDecimal']));
      setShippingPrice(currentOrder.shipping_mode.totalCharges)
    } else {
      //setShippingPrice(shippingPrice);
      setDueAmount((Number(currentOrder.grand_total['$numberDecimal']).toFixed(2) - Number(currentOrder.paid_amount['$numberDecimal'])).toFixed(2));
      setShippingService(true);
      tax = tax;
      setTaxTotal(tax);
    }


    setGrandTotal(
      Number(fittingTotalAmount) +
      Number(productTotalAmount) +
      Number(shippingPrice) +
      Number(tax) -
      Number(couponDiscount)
    );


  }, [
    cartTotal,
    couponDiscount,
    productQuantity,
    shippingPrice,
    addedProduct,
    couponDetails,
    isCartUpdated,
  ]);



  const onSubmit = (e) => {
    e.preventDefault();
    if (
      window.confirm(
        `Do you want to save this order and go to order preview page.`
      )
    ) {
      setShowAddEditForm(false);
      setPreviewForm(true);
    }
  };

  const getFittingPrice = (productItem) => {
    let adaptor_price = 0.0;
    let grip_price = 0.0;
    productItem.fitting_options.length > 0 &&
      productItem.fitting_options.map((fittingData, idx) => {
        if (fittingData.attribute_name == 'Adaptors') {
          adaptor_price = fittingData.price;
        }
        if (fittingData.attribute_name == 'Grips') {
          grip_price = fittingData.price;
        }
      });

    return (
      Number(productItem.product_price['$numberDecimal']) *
      Number(productItem.quantity) +
      Number(adaptor_price) +
      Number(grip_price)
    ).toFixed(2);
  };

  useEffect(() => {
    //setDiscountAmount(0);

    if (tempCart.length > 0) {
      addToCartOrderData(
        false,
        tempCart,
        orderId,
        '',
        '',
        userType,
        currentOrder.user_id
      ).then((res) => {

        setIsEdit(false);
        setEditAddress(false);
        setShippingService(false);
        setDeliveryInstruction(currentOrder.delivery_instruction);
        setSelectedShippingAddress(currentOrder.shipping_address.add_id);
        setSelectedShippingLabel(currentOrder.shipping_address.address);

        setSelectedBillingAddress(currentOrder.billing_address.add_id);
        setSelectedBillingLabel(currentOrder.billing_address.address);
        setShippToDifferentAddressChecked(currentOrder.shipToDifferent)
        //setAddedProduct(res.data.response.products);
        setPaymentStatus(currentOrder.payment_status);
        setDueAmount((Number(currentOrder.grand_total['$numberDecimal']).toFixed(2) - Number(currentOrder.paid_amount['$numberDecimal'])).toFixed(2));
        callGetCartApi(orderId);



      });

    }

  }, [tempCart]);



  return loading ||
    currentOrder === null ||
    currentOrder._id !== match.params.order_id ? (
    <Spinner />
  ) : (
    <div>
      {showAddEditForm && (
        <Form
          id="addEditForm"
          className="form-horizontal"
          onSubmit={(e) => onSubmit(e)}
        >
          <Input
            type="hidden"
            name="order_id"
            id="order_id"
            value={orderId}
          ></Input>
          <SelectVariations
            slug={slug}
            initialEnableDisableCartButton={initialEnableDisableCartButton}
            setInitialEnableDisableCartButton={
              setInitialEnableDisableCartButton
            }
            variation={variation}
            setVariation={setVariation}
            isOpen={variationModal}
            toggle={toggleVariationModal}
            handleVariationSelect={handleVariationSelect}
            userId={userId}
            userType={userType}
            setProductVariationDetails={setProductVariationDetails}
            selectedFitting={selectedFitting}
            setSelectedFitting={setSelectedFitting}
            reset={resetVariationModal}
            setVariationSelectError={setVariationSelectError}
            setVariationSelectErrorMsg={setVariationSelectErrorMsg}
            variationSelectError={variationSelectError}
            variationSelectErrorMsg={variationSelectErrorMsg}
            selectedFittingsData={selectedFittingsData}
            setQuantity={setQuantity}
            quantity={quantity}
            setProductRates={setProductRates}
            isEdit={isEdit}
            selectedProduct={selectedProduct}
            setSelectedFittingsData={setSelectedFittingsData}
            setCustomization={setCustomization}
            setStockQuantity={setStockQuantity}
            stockQuantity={stockQuantity}
            isOrderUpdate={isOrderUpdate}
          ></SelectVariations>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Label className="label-tag-header">
                    {currentOrder.order_id}
                  </Label>
                  (
                  <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
                    {currentOrder.ordered_on}
                  </Moment>
                  )
                </CardHeader>
                <CardBody>
                  <p>
                    <Label className="label-tag">
                      {currentOrder.user_name}{' '}
                    </Label>
                    ({USER_ROLE[currentOrder.user_type]})
                  </p>

                  {currentOrder.payment_order_id && (
                    <p>
                      <Label className="label-tag">Payment ID</Label>(
                      {currentOrder.payment_order_id} )
                    </p>
                  )}

                  <p>
                    <Label className="label-tag">Payment Status</Label>
                    {ORDER_LIST_PAYMENT_STATUS[currentOrder.payment_status]}
                  </p>
                  <hr />
                </CardBody>
              </Card>
              <div>
                <table className="orderaddedittable">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Cost</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {addedProduct.length
                      ? addedProduct.map((item, index) => {
                        let fittingTotalPrice = 0;
                        return (
                          <>
                            <tr key={index} className="bg-light">
                              <td>
                                <table>
                                  {/* ({addedProduct[{index}].productVariationSku}) */}
                                  <tr>
                                    <td className="productheadings">
                                      <strong>
                                        {item.product_title}-
                                        {item.category_name}
                                      </strong>
                                    </td>
                                  </tr>
                                  {item.productVariationDetail.length > 0 ? (
                                    <>
                                      <tr>
                                        <td>
                                          <p>
                                            <span className="table-label">
                                              SKU :
                                            </span>
                                            {''}
                                            {item.productVariationSku}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="">
                                            {item.productVariationDetail.map(
                                              (variation, key) => {
                                                return (
                                                  <p
                                                    className="mb-0"
                                                    key={key}
                                                  >
                                                    <span className="table-label">
                                                      {' '}
                                                      {
                                                        variation.attribute_name
                                                      }{' '}
                                                      :
                                                    </span>{' '}
                                                    {variation.term_option}
                                                  </p>
                                                );
                                              }
                                            )}
                                          </div>

                                          {item.productFitting &&
                                            item.productFitting.length > 0
                                            ? item.productFitting.map(
                                              (fittingOptions) => {
                                                if (
                                                  fittingOptions.attribute_name ==
                                                  'Playing Length'
                                                ) {
                                                  return (
                                                    <>
                                                      <div>
                                                        <span className="table-label">
                                                          {' '}
                                                          Playing Length :{' '}
                                                        </span>{' '}
                                                        {
                                                          fittingOptions.attribute_value
                                                        }
                                                      </div>
                                                    </>
                                                  );
                                                }

                                                if (
                                                  fittingOptions.attribute_name ==
                                                  'Extra Wrap'
                                                ) {
                                                  return (
                                                    <>
                                                      <div>
                                                        <span className="table-label">
                                                          {' '}
                                                          Extra Wraps :
                                                        </span>
                                                        {fittingOptions.attribute_value &&
                                                          fittingOptions.attribute_value ==
                                                          1
                                                          ? 'None'
                                                          : fittingOptions.attribute_value ==
                                                            2
                                                            ? '1'
                                                            : fittingOptions.attribute_value ==
                                                              3
                                                              ? '2'
                                                              : 'None'}
                                                      </div>
                                                    </>
                                                  );
                                                }
                                                if (
                                                  fittingOptions.attribute_name ==
                                                  'Grip Logo'
                                                ) {
                                                  return (
                                                    <>
                                                      <div>
                                                        <span className="table-label">
                                                          {' '}
                                                          Grip Logo :
                                                        </span>
                                                        {fittingOptions.attribute_value &&
                                                          fittingOptions.attribute_value}
                                                      </div>
                                                    </>
                                                  );
                                                }

                                                if (
                                                  fittingOptions.attribute_name ==
                                                  'Adaptors'
                                                ) {
                                                  return (
                                                    <>
                                                      <div>
                                                        <span className="table-label">
                                                          {' '}
                                                          Adaptor :{' '}
                                                        </span>
                                                        {
                                                          fittingOptions.option_name
                                                        }
                                                      </div>
                                                      <div>
                                                        <span className="table-label">
                                                          {' '}
                                                          Adaptor Setting :
                                                        </span>{' '}
                                                        {
                                                          fittingOptions.sub_option_name
                                                        }
                                                      </div>
                                                    </>
                                                  );
                                                }
                                                if (
                                                  fittingOptions.attribute_name ==
                                                  'Grips'
                                                ) {
                                                  return (
                                                    <>
                                                      <div>
                                                        <span className="table-label">
                                                          {' '}
                                                          Grips Brand :
                                                        </span>{' '}
                                                        {
                                                          fittingOptions.option_name
                                                        }
                                                      </div>
                                                      <div>
                                                        <span className="table-label">
                                                          {' '}
                                                          Grip Model :
                                                        </span>{' '}
                                                        {
                                                          fittingOptions.sub_option_name
                                                        }
                                                      </div>
                                                    </>
                                                  );
                                                }

                                                if (
                                                  fittingOptions.attribute_name ==
                                                  'Product Customization'
                                                ) {
                                                  return (
                                                    <>
                                                      <div className="product_customization_notes">
                                                        <span className="table-label">
                                                          {' '}
                                                          Product
                                                          Customization :
                                                        </span>{' '}
                                                        {
                                                          fittingOptions.attribute_value
                                                        }
                                                      </div>
                                                    </>
                                                  );
                                                }

                                                {
                                                  <div>
                                                    <span className="table-label">
                                                      {' '}
                                                      Quantity :
                                                    </span>{' '}
                                                    1
                                                  </div>;
                                                }
                                              }
                                            )
                                            : ''}
                                        </td>
                                      </tr>
                                    </>
                                  ) : null}
                                </table>
                              </td>
                              <td>
                                <table>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      ${item.price}
                                      {item.productVariationDetail.map(
                                        (variation, key) => {
                                          return (
                                            <p className="mb-0" key={key}>
                                              &nbsp;
                                            </p>
                                          );
                                        }
                                      )}
                                      {item.productFitting &&
                                        item.productFitting.length > 0
                                        ? item.productFitting.map(
                                          (fittingOptions) => {
                                            if (
                                              fittingOptions.attribute_name ==
                                              'Adaptors'
                                            ) {
                                              return (
                                                <>
                                                  <div>
                                                    $
                                                    {Number(
                                                      fittingOptions.price
                                                    ).toFixed(2)}
                                                  </div>
                                                  <div>&nbsp;</div>
                                                </>
                                              );
                                            }
                                            if (
                                              fittingOptions.attribute_name ==
                                              'Grips'
                                            ) {
                                              return (
                                                <>
                                                  <div>&nbsp;</div>
                                                  <div>
                                                    $
                                                    {Number(
                                                      fittingOptions.price
                                                    ).toFixed(2)}
                                                  </div>
                                                </>
                                              );
                                            }

                                            if (
                                              fittingOptions.attribute_name ==
                                              'Playing Length'
                                            ) {
                                              return (
                                                <>
                                                  <div>&nbsp;</div>
                                                </>
                                              );
                                            }

                                            if (
                                              fittingOptions.attribute_name ==
                                              'Extra Wrap'
                                            ) {
                                              return (
                                                <>
                                                  <div>&nbsp;</div>
                                                </>
                                              );
                                            }
                                            if (
                                              fittingOptions.attribute_name ==
                                              'Grip Logo'
                                            ) {
                                              return (
                                                <>
                                                  <div>&nbsp;</div>
                                                </>
                                              );
                                            }
                                          }
                                        )
                                        : ''}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                              <td>
                                <table>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <tr>
                                    <td>{item.quantity}</td>
                                  </tr>
                                </table>
                              </td>
                              <td>
                                <table>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <tr></tr>
                                  {item.productFitting.length > 0
                                    ? item.productFitting.map(
                                      (fitting, idx) => {
                                        fitting.price && fitting.price > 0
                                          ? (fittingTotalPrice = Number(
                                            parseFloat(
                                              fittingTotalPrice
                                            ) + parseFloat(fitting.price)
                                          ).toFixed(2))
                                          : '';
                                      }
                                    )
                                    : null}
                                  <tr>
                                    <td>
                                      $
                                      {Number(
                                        parseFloat(item.price) *
                                        parseFloat(item.quantity) +
                                        parseFloat(fittingTotalPrice)
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                              <td>
                                <table>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <tr></tr>

                                  <tr>
                                    <td>
                                      <Button
                                        type="button"
                                        className="btn-success btn-sm mr-05"
                                        onClick={() => {
                                          editProduct(item);
                                        }}
                                      >
                                        <i className="fa fa-pencil"></i>
                                      </Button>

                                      <Button
                                        type="button"
                                        className="btn-danger btn-sm"
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              `Are you sure, you want to delete ${item.product_title} product?`
                                            )
                                          ) {
                                            deleteProduct(cartId, item._id);
                                          }
                                        }}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </Button>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                            </tr>
                          </>
                        );
                      })
                      : null}
                  </tbody>
                </table>
              </div>
              <Row className="mt-3">
                <div className="couponcode mb-2">
                  <h5>Add Product</h5>
                </div>
                <Col sm="12">
                  <FormGroup>
                    <Select
                      value={[]}
                      isClearable={false}
                      id="product"
                      name="product"
                      options={productListValue && productListValue}
                      onChange={(e, action = { action }) => {
                        handleSelectProduct(e, action);
                      }}
                      keepSelectedInList={false}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Card>
                <CardBody>
                  <Row>
                    <Col className="address_box" sm="6">
                      <div>
                        <Label className="label-tag-header">
                          Shipping Address
                        </Label>
                        <p className="data-ptag">
                          <Label className="label-tag">Name :</Label>
                          {currentOrder.shipping_address.name}
                        </p>
                        <p className="data-ptag">
                          <Label className="label-tag">Email :</Label>
                          {currentOrder.shipping_address.email}
                        </p>
                        <p className="data-ptag">
                          <Label className="label-tag">Contact No :</Label>
                          {currentOrder.shipping_address.ccode &&
                            currentOrder.shipping_address.ccode}
                          {currentOrder.shipping_address.contact_no}
                        </p>
                        <p className="data-ptag">
                          <Label className="label-tag">Address :</Label>
                          <span>
                            {currentOrder.shipping_address.address_line_1}
                            <br />
                            <div>{currentOrder.shipping_address.address_line_2
                              ? currentOrder.shipping_address.address_line_2
                              : ''}</div>
                            {currentOrder.shipping_city}{' '}
                            {currentOrder.shipping_state}{' '}
                            {currentOrder.shipping_zipcode} <br />
                            {currentOrder.shipping_country}
                          </span>
                        </p>
                      </div>
                    </Col>
                    <Col className="address_box" sm="5">
                      <div>
                        <Label className="label-tag-header">
                          Billing Address
                        </Label>
                        <p className="data-ptag">
                          <Label className="label-tag">Name :</Label>
                          {currentOrder.billing_address.name}
                        </p>
                        <p className="data-ptag">
                          <Label className="label-tag">Email :</Label>
                          {currentOrder.billing_address.email}
                        </p>
                        <p className="data-ptag">
                          <Label className="label-tag">Contact No :</Label>
                          {currentOrder.billing_address.ccode &&
                            currentOrder.billing_address.ccode}
                          {currentOrder.billing_address.contact_no}
                        </p>
                        <p className="data-ptag">
                          <Label className="label-tag">Address :</Label>
                          <span>
                            {currentOrder.billing_address.address_line_1}
                            <br />
                            <div>{currentOrder.billing_address.address_line_2.length > 0
                              ? currentOrder.billing_address.address_line_2
                              : ''}</div>
                            {currentOrder.billing_city}{' '}
                            {currentOrder.billing_state}{' '}
                            {currentOrder.billing_zipcode} <br />
                            {currentOrder.billing_country}
                          </span>
                        </p>
                      </div>
                    </Col>
                    <Col sm="1">
                      <Row>
                        <Col>
                          <Button
                            type="button"
                            className="btn-success btn-sm"
                            onClick={(e) => onEditAddress(e)}
                          >
                            <i className="fa fa-pencil"></i>
                          </Button>
                        </Col>
                        <Col>

                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {currentOrder.shipping_mode && (
                    <Row className="mt-5">
                      <Col md="6">
                        <FormGroup>
                          <Label>
                            <strong>Shipping Details </strong>
                          </Label>

                          <div className="col-md-6">
                            <strong>Carrier : </strong>
                            {currentOrder.shipping_mode.carrier}
                          </div>
                          <div className="col-md-6">
                            <strong>Service : </strong>
                            {currentOrder.shipping_mode.service}
                          </div>
                          <div className="col-md-6">
                            <strong>Total Charge : </strong>$
                            {shippingPrice}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  {editAddress && (
                    <Row>
                      <BillingDetail
                        userId={userId}
                        selectedBillingAddress={selectedBillingAddress}
                        setSelectedBillingAddress={setSelectedBillingAddress}
                        setSelectedBillingLabel={setSelectedBillingLabel}
                        selectedBillingLabel={selectedBillingLabel}
                        selectedShippingAddress={selectedShippingAddress}
                        setSelectedShippingAddress={setSelectedShippingAddress}
                        setSelectedShippingLabel={setSelectedShippingLabel}
                        selectedShippingLabel={selectedShippingLabel}
                        shippToDifferentAddressChecked={
                          shippToDifferentAddressChecked
                        }
                        setShippToDifferentAddressChecked={
                          setShippToDifferentAddressChecked
                        }
                        setIsGetShippingRates={setIsGetShippingRates}
                        editAddress={editAddress}
                        setEditAddress={setEditAddress}
                        setShippingService={setShippingService}
                      />

                    </Row>
                  )}
                  {
                    shippingService &&
                      selectedShippingAddress != null ? (
                      <div className="mt-20">
                        <h5>Shipping Service </h5>
                        <div className="order-margin">
                          <ShippingRates
                            setSelectedShippingMode={setSelectedShippingMode}
                            setShippingPrice={setShippingPrice}
                            shippingModeError={shippingModeError}
                            shippingMessages={shippingMessages}
                            groupedRatesByProvider={groupedRatesByProvider}
                            shippingRates={shippingRates}
                          />
                        </div>
                      </div>
                    ) : null
                  }
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="couponcode">
                          <Label for="payment_status">
                            <strong>Payment status</strong>
                          </Label>
                        </div>
                        <Input
                          type="select"
                          name="payment_status"
                          id="payment_status"
                          onChange={(e) => handleOnChange(e)}
                        >
                          {Object.keys(ORDER_LIST_PAYMENT_STATUS).map((key) => (
                            <option
                              value={key}
                              key={key}
                              selected={key == currentOrder.payment_status}
                            >
                              {ORDER_LIST_PAYMENT_STATUS[key]}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="couponcode">
                          <Label for="deliveryInstruction">
                            <strong>Delivery Instructions</strong>
                          </Label>
                        </div>
                        <Input
                          type="text"
                          className="form-control text-left"
                          id="deliveryInstruction"
                          name="deliveryInstruction"
                          maxLength="100"
                          // placeholder="Delivery Instruction(max chars limit is 100)"
                          defaultValue={currentOrder.delivery_instruction}
                          onChange={(e) =>
                            setDeliveryInstruction(e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  {currentOrder.coupon != null &&
                    currentOrder.coupon &&
                    Object.keys(currentOrder.coupon).length > 0 ? (
                    <div className="mt-20">
                      <div className="couponcode">
                        <strong>Coupon Code </strong>
                      </div>


                      {

                        couponDiscount ?
                          <>
                            <strong className="coupon-display">
                              {currentOrder.coupon?.coupon_code
                                ? currentOrder.coupon?.coupon_code
                                : ''}
                            </strong>
                            <span
                              onClick={(e) => onRemoveClick(e)}
                              className="remove-coupon-span"
                            >
                              <i className="fa fa-close"></i>
                            </span>
                          </>
                          :
                          <Row>
                            <Col>
                              <Input
                                id="coupon"
                                type="text"
                                name="coupon"
                                maxLength={50}
                                autoComplete="false"
                                placeholder="Coupon Code"
                                onChange={(e) => onChangeCouponCode(e)}
                                className="form-control text-left"
                                disabled={couponDiscount > 0 ? true : false}
                              />
                            </Col>
                            {couponDiscount <= 0 ? (
                              <Col>
                                <Button
                                  className="mr-05"
                                  size="sm"
                                  color="primary"
                                  onClick={(e) => handleApplyCoupon(e)}
                                >
                                  Apply
                                </Button>
                              </Col>
                            ) : null}
                          </Row>
                      }
                    </div>
                  ) : (
                    <div className="mt-20">
                      <div className="couponcode">
                        <strong>Coupon Code </strong>
                      </div>
                      {
                        // Object.keys(currentOrder.coupon).length > 0 && isApplied ? (
                        //   <>
                        //     <strong className="coupon-display">
                        //       {couponDetails?.coupon_code
                        //         ? couponDetails?.coupon_code
                        //         : ''}
                        //     </strong>
                        //     <span
                        //       onClick={(e) => onRemoveClick(e)}
                        //       className="remove-coupon-span"
                        //     >
                        //       <i className="fa fa-close"></i>
                        //     </span>
                        //   </>
                        // ) : (
                        <Row>
                          <Col>
                            <Input
                              id="coupon"
                              type="text"
                              name="coupon"
                              maxLength={50}
                              autoComplete="false"
                              placeholder="Coupon Code"
                              onChange={(e) => onChangeCouponCode(e)}
                              className="form-control text-left"
                              disabled={couponDiscount > 0 ? true : false}
                            />
                          </Col>
                          {couponDiscount <= 0 ? (
                            <Col>
                              <Button
                                className="mr-05"
                                size="sm"
                                color="primary"
                                onClick={(e) => handleApplyCoupon(e)}
                              >
                                Apply
                              </Button>
                            </Col>
                          ) : null}
                        </Row>
                        //)
                      }
                    </div>
                  )}

                  <div>
                    {addedProduct.length ? (
                      <div className="mt-20">
                        <Row className="couponcodelist priceSummary">
                          <Col>
                            {productTotalAmount && productTotalAmount > 0 ? (
                              <div className="couponcode">
                                <strong>Product(s) Total : </strong>$
                                {Number(productTotalAmount).toFixed(2)}
                              </div>
                            ) : null}
                            <div className="couponcode">
                              <strong>Discount : </strong> - ($
                              {Number(couponDiscount).toFixed(2)})
                            </div>
                            {fittingTotalAmount && fittingTotalAmount > 0 ? (
                              <div className="couponcode">
                                <strong>Build Parameter(s) total :</strong> $
                                {Number(fittingTotalAmount).toFixed(2)}
                              </div>
                            ) : null}
                            {taxTotal && taxTotal > 0 ? (
                              <>
                                <div className="couponcode">
                                  <strong>Total before Tax :</strong> $
                                  {(
                                    Number(fittingTotalAmount) +
                                    Number(productTotalAmount) -
                                    Number(couponDiscount)
                                  ).toFixed(2)}
                                </div>
                                <div className="couponcode">
                                  <strong>Sales Tax :</strong> $
                                  {Number(taxTotal).toFixed(2)}
                                </div>
                              </>
                            ) :

                              <>
                                <div className="couponcode">
                                  <strong>Total before Tax :</strong> $
                                  {(
                                    Number(fittingTotalAmount) +
                                    Number(productTotalAmount) -
                                    Number(couponDiscount)
                                  ).toFixed(2)}
                                </div>
                                {Number(currentOrder.tax_total) != 0 && <div className="couponcode">
                                  <strong>Sales Tax :</strong> $
                                  {Number(currentOrder.tax_total).toFixed(2)}
                                </div>}
                              </>
                            }

                            {shippingPrice !== 0 ? (
                              <div className="couponcode">
                                <strong>Shipping :</strong> $
                                {Number(shippingPrice).toFixed(2)}
                              </div>
                            ) :
                              <div className="couponcode">
                                <strong>Shipping :</strong> $
                                {Number(currentOrder.shipping_mode.totalCharges).toFixed(2)}
                              </div>

                            }

                            {
                              isEdit ?
                                <>
                                  <div className="couponcode">
                                    <strong>Paid Amount : </strong>$
                                    {Number(currentOrder.paid_amount['$numberDecimal']).toFixed(2)}
                                  </div>

                                  <div className="couponcode">
                                    <strong>Due Amount : </strong>$
                                    {(Number(grandTotal).toFixed(2) - Number(currentOrder.paid_amount['$numberDecimal']).toFixed(2)).toFixed(2)}
                                  </div>
                                </>
                                : null

                            }

                            <div className="couponcode">
                              <strong>Grand Total : </strong>$
                              {Number(grandTotal).toFixed(2)}
                            </div>

                          </Col>
                        </Row>
                      </div>
                    ) : Object.keys(currentOrder).length > 0 ? (
                      <div className="mt-20">
                        <Row className="couponcodelist priceSummary">
                          <Col>
                            {currentOrder.product_total &&
                              currentOrder.product_total > 0 ? (
                              <div className="couponcode">
                                <strong>Product(s) Total : </strong>$
                                {Number(currentOrder.product_total).toFixed(2)}
                              </div>
                            ) : null}
                            {currentOrder.discounted_amount.$numberDecimal &&
                              currentOrder.discounted_amount.$numberDecimal !=
                              0 && (
                                <div className="couponcode">
                                  <strong>Discount : </strong> - ($
                                  {Number(
                                    currentOrder.discounted_amount
                                      .$numberDecimal
                                  ).toFixed(2)}
                                  )
                                </div>
                              )}
                            {currentOrder.fitting_total &&
                              currentOrder.fitting_total > 0 ? (
                              <div className="couponcode">
                                <strong>Build Parameter(s) total :</strong> $
                                {Number(currentOrder.fitting_total).toFixed(2)}
                              </div>
                            ) : null}
                            {currentOrder.tax_total &&
                              currentOrder.tax_total > 0 ? (
                              <>
                                <div className="couponcode">
                                  <strong>Total before Tax :</strong> $
                                  {(
                                    Number(currentOrder.fitting_total) +
                                    Number(currentOrder.product_total) -
                                    Number(
                                      currentOrder.discounted_amount
                                        .$numberDecimal
                                    )
                                  ).toFixed(2)}
                                </div>
                                <div className="couponcode">
                                  <strong>Sales Tax :</strong> $
                                  {Number(currentOrder.tax_total).toFixed(2)}
                                </div>
                              </>
                            ) : null}

                            {currentOrder.shipping_mode.totalCharges !== 0 ? (
                              <div className="couponcode">
                                <strong>Shipping :</strong> $
                                {Number(
                                  currentOrder.shipping_mode.totalCharges
                                ).toFixed(2)}
                              </div>
                            ) : null}

                            <div className="couponcode">
                              <strong>Grand Total : </strong>$
                              {Number(
                                currentOrder.grand_total.$numberDecimal
                              ).toFixed(2)}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ) : null}
                  </div>
                </CardBody>
              </Card>

              <CardFooter>
                <Button
                  className="mr-05"
                  type="submit"
                  size="sm"
                  color="primary"
                >
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
                <Link to="/admin/orders" className="cancle-btn m-0">
                  <Button type="reset" size="sm" color="danger" className="m-0">
                    <i className="fa fa-ban"></i> Cancel
                  </Button>
                </Link>
              </CardFooter>
            </Col>
          </Row>
        </Form>
      )}
      {showPreviewForm && (
        <PreviewForm
          orderId={orderId}
          userType={userType}
          customerName={customerName}
          userId={userId}
          addedProduct={addedProduct}
          selectedBillingAddress={selectedBillingAddress}
          selectedShippingAddress={selectedShippingAddress}
          selectedBillingLabel={selectedBillingLabel}
          selectedShippingLabel={selectedShippingLabel}
          payment_status={payment_status}
          deliveryInstruction={deliveryInstruction}
          isApplied={isApplied}
          couponDetails={couponDetails}
          productTotalAmount={productTotalAmount}
          fittingTotalAmount={fittingTotalAmount}
          shippingPrice={shippingPrice}
          couponDiscount={couponDiscount}
          grandTotal={grandTotal}
          currentCart={currentCart}
          cartTotal={cartTotal}
          shippToDifferentAddressChecked={shippToDifferentAddressChecked}
          shippingBox={shippingBox}
          selectedShippingMode={selectedShippingMode}
          easyPostOrderId={easyPostOrderId}
          setShowAddEditForm={setShowAddEditForm}
          setPreviewForm={setPreviewForm}
          setIsSubmit={setIsSubmit}
          addToOrder={addToOrder}
          taxApplied={taxApplied}
          totalBeforeTax={totalBeforeTax}
          taxTotal={taxTotal}
          isEdit={isEdit}
          paid_amount={paidAmount}
          due_amount={dueAmount}
        />
      )}
    </div>
  );
};

EditOrder.propTypes = {
  getOrderById: PropTypes.func.isRequired,
  getUserAddressList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  currentOrder: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currentOrder: state.order.currentOrder,
  loading: state.order.loading,
  productsList: state.product.productsList,
  calculateShippingRates: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, {
  getOrderById,
  addToCart,
  getProductsList,
  getCartDetails,
  getUserAddressList,
  calculateShippingRates,
  addToProducts,
  getProductAttributes,
  updateCart,
  clearCart,
  getUsersList,
  applyCoupon,
  addToOrder,
  removeCoupon,
  getTaxRates,
  addToCartOrderData,
})(EditOrder);
