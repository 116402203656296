const PerPageSelect = ({ onPaginationChange, perPage }) => {
    return <select
        onChange={(e) => onPaginationChange(e.target.value)}
        id="page-size"
        className="form-select form-control"
        value={perPage}
    >
        <option value="20">
            20
        </option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="500">500</option>
    </select>
}

export default PerPageSelect;