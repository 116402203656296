import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CKEditor } from 'ckeditor4-react';
import { Link, useParams } from 'react-router-dom';

import {
    getAdditionalProductById,
    edit,
    create,
} from 'actions/admin/additionalProduct';

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';
import { CKEditorConfig } from 'constants';

const AdditionalProduct = ({
    create,
    history,
    errorList,
    loading,
    getAdditionalProductById,
    edit,
    product: { currentProduct },
}) => {
    let navigate = useNavigate();
    const { product_id: product_id } = useParams();
    const [formData, setFormData] = useState({
        name: product_id ? currentProduct.name : '',
        sku: product_id ? currentProduct.sku : '',
        price: product_id ? currentProduct.price : '',
        description: product_id ? currentProduct.description : '',
    });
    let charLimit = 200;
    const {
        name,
        sku,
        price,
        description
    } = formData;

    useEffect(() => {
        if (Object.keys(currentProduct).length > 0 && product_id) {
            setFormData({ ...currentProduct });
        }
    }, [currentProduct]);

    useEffect(() => {
        if (product_id) getAdditionalProductById(product_id);
    }, [product_id]);

    const onChange = (e) => {

        if (e?.editor?.name === 'description') {
            // Get plain text from editor content
            const textContent = e.editor.document.getBody().getText();
            if (textContent.length <= charLimit) {
                setFormData((ev) => ({ ...ev, description: e.editor.getData() }))
            }
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value.trimLeft() });
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (product_id) {
            edit(formData, navigate, product_id);
        } else {
            create(formData, navigate);
        }
    };

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                <Form className="form-horizontal" onSubmit={onSubmit}>
                    <Row>
                        <Col xs="12" sm="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs="12" sm="12">
                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="name">
                                                            Name <span>*</span>
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            maxLength="100"
                                                            value={name}
                                                            required
                                                            onChange={(e) => onChange(e)}
                                                            invalid={errorList.name ? true : false}
                                                        />
                                                        <Errors
                                                            current_key="name"
                                                            key="name"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="sku">
                                                            Sku <span>*</span>
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="sku"
                                                            name="sku"
                                                            maxLength="100"
                                                            minLength="3"
                                                            value={sku}
                                                            required
                                                            onChange={(e) => onChange(e)}
                                                            invalid={errorList.sku ? true : false}
                                                        />
                                                        <Errors
                                                            current_key="sku"
                                                            key="sku"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="price">
                                                            Price <span>*</span>
                                                        </Label>
                                                        <Input
                                                            type="number"
                                                            id="price"
                                                            name="price"
                                                            step={0.01}
                                                            min={0}
                                                            value={price}
                                                            required
                                                            onChange={(e) => onChange(e)}
                                                            invalid={errorList.price ? true : false}
                                                        />
                                                        <Errors
                                                            current_key="price"
                                                            key="price"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                {product_id ?
                                                    <Col sm="12">
                                                        <FormGroup>
                                                            <Label htmlFor="description">
                                                                Description <span>*</span>
                                                            </Label>
                                                            {description &&
                                                                <>
                                                                    <CKEditor
                                                                        editor={description}
                                                                        initData={description}
                                                                        id="description"
                                                                        name="description"
                                                                        config={CKEditorConfig}
                                                                        onChange={e => onChange(e)}
                                                                        onKey={(event) => {
                                                                            const textContent = event.editor.document.getBody().getText();
                                                                            if (textContent.length >= charLimit && ![46, 8].includes(event.data.keyCode)) {
                                                                                console.log(textContent, "aaaaa");
                                                                                event.cancel();  // Cancel the event to prevent further typing
                                                                                //alert(`Character limit of ${charLimit} reached!`);
                                                                            }
                                                                        }} // Handle keypress event to block typing
                                                                        onBeforeLoad={(CKEDITOR) =>
                                                                            (CKEDITOR.disableAutoInline = true)
                                                                        }
                                                                        invalid={errorList.description ? true : false}
                                                                    />
                                                                    <Errors current_key="description" key="description" />
                                                                </>
                                                            }
                                                            {description == '' &&
                                                                <>
                                                                    <CKEditor
                                                                        editor={description}
                                                                        initData={description}
                                                                        id="description"
                                                                        name="description"
                                                                        config={CKEditorConfig}
                                                                        onChange={e => onChange(e)}
                                                                        onKey={(event) => {
                                                                            const textContent = event.editor.document.getBody().getText();
                                                                            if (textContent.length >= charLimit && ![46, 8].includes(event.data.keyCode)) {
                                                                                event.cancel();  // Cancel the event to prevent further typing
                                                                                //alert(`Character limit of ${charLimit} reached!`);
                                                                            }
                                                                        }} // Handle keypress event to block typing

                                                                        onBeforeLoad={(CKEDITOR) =>
                                                                            (CKEDITOR.disableAutoInline = true)
                                                                        }
                                                                        invalid={errorList.description ? true : false}
                                                                    />
                                                                    <Errors current_key="description" key="description" />
                                                                </>
                                                            }
                                                        </FormGroup>
                                                    </Col>
                                                    :
                                                    <Col sm="12">
                                                        <FormGroup>
                                                            <Label htmlFor="description">
                                                                Description <span>*</span>
                                                            </Label>
                                                            <CKEditor
                                                                editor={description}
                                                                initData={description}
                                                                id="description"
                                                                name="description"
                                                                config={CKEditorConfig}
                                                                onChange={e => onChange(e)}
                                                                onKey={(event) => {
                                                                    const textContent = event.editor.document.getBody().getText();
                                                                    if (textContent.length >= charLimit && ![46, 8].includes(event.data.keyCode)) {
                                                                        event.cancel();  // Cancel the event to prevent further typing
                                                                        //alert(`Character limit of ${charLimit} reached!`);
                                                                    }
                                                                }} // Handle keypress event to block typing
                                                                onBeforeLoad={(CKEDITOR) =>
                                                                    (CKEDITOR.disableAutoInline = true)
                                                                }
                                                                invalid={errorList.description ? true : false}
                                                            />
                                                            <Errors current_key="description" key="description" />
                                                        </FormGroup>
                                                    </Col>
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <Button type="submit" size="sm" color="primary" disabled={loading}>
                                        <i className="fa fa-dot-circle-o"></i> Submit
                                    </Button>
                                    <Link to="/admin/services">
                                        <Button type="reset" size="sm" color="danger">
                                            <i className="fa fa-ban"></i> Cancel
                                        </Button>
                                    </Link>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            )
            }
        </>
    );
};

AdditionalProduct.propTypes = {
    getAdditionalProductById: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    edit: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
    product: state.additionalProduct,
    errorList: state.errors,
    loading: state.additionalProduct.loading,
});

export default connect(mapStateToProps, {
    getAdditionalProductById,
    edit,
    create,
})(AdditionalProduct);
