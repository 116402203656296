/* eslint-disable */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import GearBasicInfo from './GearBasicInfo';
import GearProductImages from './GearProductImages';
import GearProductAttributes from './GearProductAttributes';
import GearProductVariations from './GearProductVariations';
import { resetCurrentProduct } from 'actions/admin/GearProduct/gearProduct';

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Col,
  Row,
} from 'reactstrap';
import classnames from 'classnames';
import GearVariationImages from './GearVariationImages';
import GearProductSeoDetail from './GearProductSeoDetail';
import GearStackableProduct from './GearStackableProduct';

const GearCreateProduct = ({ history, gearCurrentProduct, resetCurrentProduct }) => {
  //########################## reset gearCurrentProduct  state ##############################

  // to resolve useEffect dependency error

  useMemo(() => {

    resetCurrentProduct();
  }, []);

  //########################## set the initial active tab ##############################
  const [activeTab, setActiveTab] = useState({
    productTab: 'basic_info_tab',
  });

  //########################## change the active tab ##############################
  const changeTab = (tab) => {
    if (activeTab.productTab !== tab)
      setActiveTab({ ...activeTab, productTab: tab });
  };

  //########################## set product id ##############################
  const [productId, setProductId] = useState(null);

  return (
    <div className="animated fadeIn editProdBtn">
      <Row>
        <Col xs="12" sm="12">
          <Nav tabs className="productTabs">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab.productTab === 'basic_info_tab',
                })}
                onClick={() => {
                  changeTab('basic_info_tab');
                }}
              >
                Basic Info
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab.productTab === 'attributes_tab',
                  disabled: productId === null ? true : false,
                })}
                onClick={() => {
                  changeTab('attributes_tab');
                }}
              >
                Attributes
              </NavLink>
            </NavItem>
            {productId != null &&
              gearCurrentProduct.attributes &&
              gearCurrentProduct.attributes.length > 0 ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab.productTab === 'variations_tab',
                    disabled: productId === null ? true : false,
                  })}
                  onClick={() => {
                    changeTab('variations_tab');
                  }}
                >
                  Variations
                </NavLink>
              </NavItem>
            ) : (
              ''
            )}

            {gearCurrentProduct.attributes &&
              gearCurrentProduct.attributes.length > 0 ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab.productTab === 'images_tab',
                    disabled: productId === null ? true : false,
                  })}
                  onClick={() => {
                    changeTab('images_tab');
                  }}
                >
                  Images
                </NavLink>
              </NavItem>
            ) : (
              ''
            )}
            {gearCurrentProduct.gearProductImages &&
              gearCurrentProduct.gearProductImages.images.length > 0 ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab.productTab === 'attribute_images',
                  })}
                  onClick={() => {
                    changeTab('attribute_images');
                  }}
                >
                  Color
                </NavLink>
              </NavItem>

            ) : (
              ''
            )}
            {gearCurrentProduct.attributes &&
              gearCurrentProduct.attributes.length > 0 ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab.productTab === 'stackable_tab',
                  })}
                  onClick={() => {
                    changeTab('stackable_tab');
                  }}
                >
                  Stackable Product
                </NavLink>
              </NavItem>
            ) : (
              ''
            )}
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab.productTab === 'seo_tab',
                  disabled: productId === null ? true : false,
                })}
                onClick={() => {
                  changeTab('seo_tab');
                }}
              >
                Seo Details
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab.productTab}>
            <TabPane tabId="basic_info_tab">
              <GearBasicInfo
                history={history}
                changeTab={changeTab}
                setProductId={setProductId}
                product_id={productId}
              />
            </TabPane>
            <TabPane tabId="images_tab">
              <GearProductImages
                history={history}
                changeTab={changeTab}
                product_id={productId}
              />
            </TabPane>
            <TabPane tabId="attributes_tab">
              <GearProductAttributes
                history={history}
                changeTab={changeTab}
                product_id={productId}
              />
            </TabPane>
            <TabPane tabId="variations_tab">
              <GearProductVariations history={history} product_id={productId} />
            </TabPane>
            <TabPane tabId="attribute_images">
              <GearVariationImages history={history} product_id={productId} />
            </TabPane>
            <TabPane tabId="stackable_tab">
              <GearStackableProduct
                product_id={productId}
                history={history}
                changeTab={changeTab}
              />
            </TabPane>
            <TabPane tabId="seo_tab">
              <GearProductSeoDetail
                history={history}
                changeTab={changeTab}
                product_id={productId}
              />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

GearCreateProduct.propTypes = {
  gearCurrentProduct: PropTypes.object,
  resetCurrentProduct: PropTypes.func.isRequired,
  // loading: PropTypes.bool.isRequired
};
const mapStateToProps = (state) => ({
  gearCurrentProduct: state.gearProduct.gearCurrentProduct,
  // loading: state.product.loading
});

export default connect(mapStateToProps, { resetCurrentProduct })(GearCreateProduct);
