import {
  BLOG_LOADING,
  BLOG_CREATED,
  BLOG_CREATE_ERROR,
  BLOG_FAIL,
  BLOG_UPDATED,
  BLOG_UPDATE_ERROR,
  BLOG_DELETED,
  BLOG_DELETE_ERROR,
  BLOG_LIST_UPDATED,
  GET_BLOG_BY_ID,
  BLOG_CHANGE_STATUS,
  BLOG_CHANGE_STATUS_ERROR,
} from "actions/types";

const initalState = {
  blogList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentBlog: {},
  loading: false,
  isSubLoading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case BLOG_LOADING:
      return {
        ...state,
        blogList: payload.is_sub_element
          ? state.blogList
          : initalState.blogList,
        currentBlog: payload.is_sub_element
          ? state.currentBlog
          : initalState.currentBlog,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case BLOG_CREATED:
      return {
        ...state,
        loading: false,
      };
    case BLOG_CREATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case BLOG_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case BLOG_UPDATED:
      return {
        ...state,
        currentBlog: {},
        loading: false,
      };
    case BLOG_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case BLOG_DELETED:
      return {
        ...state,
        loading: false,
      };
    case BLOG_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case BLOG_LIST_UPDATED:
      return {
        ...state,
        blogList: {
          data: payload.data.blogList.data,
          page: payload.data.blogList.metadata[0].current_page,
          count: payload.data.blogList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_BLOG_BY_ID:
      return {
        ...state,
        currentBlog: payload.data,
        loading: false,
      };
    case BLOG_CHANGE_STATUS:
      return {
        ...state,
        blogList: {
          ...state.blogList,
          data: state.blogList.data.map((blog) =>
            blog._id === payload.data._id
              ? { ...blog, status: payload.data.status }
              : blog
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case BLOG_CHANGE_STATUS_ERROR:
      return {
        ...state,
        blogList: {
          ...state.blogList,
          data: state.blogList.data.map((blog) =>
            blog._id === payload.data
              ? { ...blog, is_failed: true }
              : { ...blog, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };

    default:
      return state;
  }
}
