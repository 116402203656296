import {
    SET_PREVIOUS_PATH, SET_GOOGLE_ADDRESS_FORM_DATA
  } from "actions/types";
  
  const initialState = {
      previousPath :'/',
      googleAddressFormData: {}
  };

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case SET_PREVIOUS_PATH:
        return {
          ...state,
          previousPath:payload
        };
      case SET_GOOGLE_ADDRESS_FORM_DATA:
        return {
          ...state,
          googleAddressFormData:payload
        };
      default:
        return state;
    }
  }