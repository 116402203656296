import {
    HOME_LOADING,
    HOME_CREATED,
    HOME_CREATE_ERROR,
    HOME_FAIL,
    HOME_UPDATED,
    HOME_UPDATE_ERROR,
    HOME_DELETED,
    HOME_DELETE_ERROR,
    HOME_LIST_UPDATED,
    GET_HOME_BY_ID,
    HOME_CHANGE_STATUS,
    HOME_CHANGE_STATUS_ERROR,
  } from "actions/types";
  
  const initalState = {
    cmsList: {
      page: 1,
      data: [],
      count: 0,
    },
    currentCMS: {},
    loading: false,
    isSubLoading: false,
  };
  
  export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
      case HOME_LOADING:
        return {
          ...state,
          cmsList: payload.is_sub_element ? state.cmsList : initalState.cmsList,
          currentCMS: payload.is_sub_element
            ? state.currentCMS
            : initalState.currentCMS,
          loading: payload.is_loading ? payload.is_loading : false,
          isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
        };
      case HOME_CREATED:
        return {
          ...state,
          loading: false,
        };
      case HOME_CREATE_ERROR:
        return {
          ...state,
          loading: false,
        };
      case HOME_FAIL:
        return {
          ...state,
          loading: false,
          isSubLoading: false
        };
      case HOME_UPDATED:
        return {
          ...state,
          currentCMS: {},
          loading: false,
        };
      case HOME_UPDATE_ERROR:
        return {
          ...state,
          loading: false,
        };
      case HOME_DELETED:
        return {
          ...state,
          loading: false,
        };
      case HOME_DELETE_ERROR:
        return {
          ...state,
          loading: false,
        };
      case HOME_LIST_UPDATED:
        return {
          ...state,
          cmsList: {
            data: payload.data.cmsList.data,
            page: payload.data.cmsList.metadata[0].current_page,
            count: payload.data.cmsList.metadata[0].totalRecord,
          },
          loading: false,
        };
      case GET_HOME_BY_ID:
        return {
          ...state,
          currentCMS: payload.data,
          loading: false,
        };
      case HOME_CHANGE_STATUS:
        return {
          ...state,
          cmsList: {
            ...state.cmsList,
            data: state.cmsList.data.map((cms) =>
              cms._id === payload.data._id
                ? { ...cms, status: payload.data.status }
                : cms
            ),
          },
          loading: false,
          isSubLoading: false
        };
      case HOME_CHANGE_STATUS_ERROR:
        return {
          ...state,
          cmsList: {
            ...state.cmsList,
            data: state.cmsList.data.map((cms) =>
              cms._id === payload.data
                ? { ...cms, is_failed: true }
                : { ...cms, is_failed: false }
            ),
          },
          loading: false,
          isSubLoading: false
        };
  
      default:
        return state;
    }
  }
  