import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { CKEditor } from 'ckeditor4-react';
import slugify from 'react-slugify';

import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';

import { create, edit, getCMSById } from 'actions/admin/cms';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import { CKEditorConfig } from 'constants';

const CreateCMS = ({
  create,
  history,
  errorList,
  loading,
  getCMSById,
  edit,
  cms: { currentCMS },
}) => {
  let navigate = useNavigate();
  /**************** use state for form data *********************/
  const [formData, setFormData] = useState({
    page_name: '',
    page_title: '',
    meta_title: '',
    meta_description: '',
    page_subtitle: '',
    page_description: '',
    slug: '',
    oldImage: '', // old image name (thumbnail)
    banner_image: '',
    selectedFile: '',
    support_title: '',
    support_subtitle: '',
    supportData: [
      {
        title: '',
        email: '',
      }
    ],
    data: [
      {
        title: '',
        subtitle: '',
        selectedFile: [],
        deleteFile: [],
        image: [],
        videoUrl: '',
        selectedVideoFile: '',
        deletedVideoFile: '',
        description: '',
        button_Text: '',
        button_Link: '',
      }
    ]
  });
  const slugData = ['account', 'faq', 'warranty-info', 'shipping-and-returns', 'address', 'shafts', 'shipping', 'gear', 'news', 'press-center', 'contact', 'cart', 'dealer', 'driver-chart', 'fairway-chart', 'hybrid-chart', 'iron-chart', 'fitting-studios', 'terms', "terms-of-sale", "shipping-policy", "return-policy", 'privacy', 'product-comparison-tool', 'kinetixx-fit', 'driver', 'fairway', 'hybrid', 'iron', 'launch-spin-profiles', 'launch-spin-profiles-result', 'order', 'international-dealer', 'international-fitting', 'returns-replacement', 'save-card', 'wishlist', 'driver-category', 'fairway-category', 'hybrid-category', 'iron-category', 'putters-category', 'testing24-category', 'apparels-category', 'bags-category', 'caps-category', 'golf-glove-category', 'pre-build-shafts-category', 'shoes-category', 't-shirts-category', 'test-gear-category', 'test-1-category', 'tshirt-with-cap-category', 'resources'];

  const { cms_id: cms_id } = useParams();
  useEffect(() => {
    if (cms_id) getCMSById(cms_id);
  }, [cms_id]);

  /**************** expload form data *********************/
  const {
    page_name,
    page_title,
    meta_title,
    meta_description,
    page_subtitle,
    page_description,
    slug,
    banner_image,
    selectedFile,
    support_title,
    support_subtitle,
    supportData: [],
    data: [
      {
        title,
        subtitle,
        deleteFile,
        image,
        videoUrl,
        selectedVideoFile,
        deletedVideoFile,
        description,
        button_Text,
        button_Link,
      }
    ]
  } = formData;

  useEffect(() => {
    if (Object.keys(currentCMS).length > 0 && cms_id) {
      setFormData({
        ...currentCMS,
        banner_image: '',
        oldImage: !currentCMS.banner_image ? '' : currentCMS.banner_image,
        selectedFile: !currentCMS.banner_image ? '' : currentCMS.banner_image,
        data: currentCMS.data.map((item) => {
          return {
            ...item,
            image: item.images,
            selectedFile: item.images,
            deletedVideoFile: "",
          }
        }),
      });
    }
  }, [currentCMS]);

  /**************** on Change event *********************/
  const onChange = (e) => {
    if (e.target.name === 'page_name') {
      let new_slug = slugify(e.target.value);
      if (cms_id === undefined) {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value.trimLeft(),
          slug: new_slug
        });
      } else {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value.trimLeft(),
        });
      }
    }
    else if (e.target.name === 'thumbnail') {
      const selectedImage = e.target.files[0];

      // Set the Image In FormData
      let image_file = selectedImage;

      let setFileUrl = URL.createObjectURL(image_file);
      const reader = new FileReader();
      reader.onloadend = () => {
        // Use a regex to remove data url part
        const base64String = reader.result;
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
          selectedFile: base64String,
          banner_image: setFileUrl,
        });
      };
      reader.readAsDataURL(image_file);
      document.getElementById(`thumbnail`).value = '';

      // const imageReader = new FileReader();

      // imageReader.onload = (e) => {
      //   const img = new Image();
      //   img.src = e.target.result;
      //   img.onload = () => {
      //     const aspectRatio = img.width / img.height;
      //     if (Math.abs(aspectRatio) < 3.5 || Math.abs(aspectRatio) > 5) {
      //       console.log(aspectRatio, 'Image Aspect Ratio Error');
      //       const result = window.confirm('You Are trying to upload the wrong aspect ratio image.');
      //       if (result) {
      //         // Set the Image In FormData
      //         let image_file = selectedImage;

      //         let setFileUrl = URL.createObjectURL(image_file);
      //         const reader = new FileReader();
      //         reader.onloadend = () => {
      //           // Use a regex to remove data url part
      //           const base64String = reader.result;
      //           setFormData({
      //             ...formData,
      //             [e.target.name]: e.target.value,
      //             selectedFile: base64String,
      //             banner_image: setFileUrl,
      //           });
      //         };
      //         reader.readAsDataURL(image_file);
      //       }
      //       document.getElementById(`thumbnail`).value = '';
      //     } else {
      //       // Set the Image In FormData
      //       let image_file = selectedImage;

      //       let setFileUrl = URL.createObjectURL(image_file);
      //       const reader = new FileReader();
      //       reader.onloadend = () => {
      //         // Use a regex to remove data url part
      //         const base64String = reader.result;
      //         setFormData({
      //           ...formData,
      //           [e.target.name]: e.target.value,
      //           selectedFile: base64String,
      //           banner_image: setFileUrl,
      //         });
      //       };
      //       reader.readAsDataURL(image_file);
      //       document.getElementById(`thumbnail`).value = '';
      //     }
      //   };
      // };

      // if (selectedImage) {
      //   imageReader.readAsDataURL(selectedImage);
      // }
    }
    else {
      setFormData({ ...formData, [e.target.name]: e.target.value.trimLeft() });
    }

  };

  // handle input change
  const handleCMSChange = (e, index) => {
    const cmsData = { ...formData };
    if (e?.target?.name === 'thumbnail') {
      const selectedImage = e.target.files[0];

      // Set the image in the FormData

      let count = 0;
      let previewSelectedfile = cmsData.data[index].selectedFile;
      let previewImageFile = cmsData.data[index].image;
      for (let property in previewSelectedfile) {
        count = count + 1;
      }
      cmsData.data[index] = {
        ...cmsData.data[index], image: { ...previewImageFile, [count]: URL.createObjectURL(selectedImage) }
      };
      const reader = new FileReader();
      reader.onloadend = () => {
        // Use a regex to remove data url part
        let base64String = reader.result;
        cmsData.data[index] = {
          ...cmsData.data[index], selectedFile: { ...previewSelectedfile, [count]: base64String }
        };
      };
      reader.readAsDataURL(selectedImage);
      setFormData(cmsData);
      document.getElementById(`image-${index}`).value = '';

      // const imageReader = new FileReader();

      // imageReader.onload = (e) => {
      //   const img = new Image();
      //   img.src = e.target.result;
      //   img.onload = () => {
      //     const aspectRatio = img.width / img.height;
      //     if (Math.abs(aspectRatio) < 1.3 || Math.abs(aspectRatio) > 2) {
      //       console.log(aspectRatio, 'Image Aspect Ratio Error');
      //       const result = window.confirm('You Are trying to upload the image which aspect ratio is not 16:9.');
      //       if (result) {
      //         // Set the image in the FormData

      //         let count = 0;
      //         let previewSelectedfile = cmsData.data[index].selectedFile;
      //         let previewImageFile = cmsData.data[index].image;
      //         for (let property in previewSelectedfile) {
      //           count = count + 1;
      //         }
      //         cmsData.data[index] = {
      //           ...cmsData.data[index], image: { ...previewImageFile, [count]: URL.createObjectURL(selectedImage) }
      //         };
      //         const reader = new FileReader();
      //         reader.onloadend = () => {
      //           // Use a regex to remove data url part
      //           let base64String = reader.result;
      //           cmsData.data[index] = {
      //             ...cmsData.data[index], selectedFile: { ...previewSelectedfile, [count]: base64String }
      //           };
      //         };
      //         reader.readAsDataURL(selectedImage);
      //         setFormData(cmsData);
      //       }
      //       document.getElementById(`image-${index}`).value = '';
      //     } else {
      //       // Set the image in the FormData

      //       let count = 0;
      //       let previewSelectedfile = cmsData.data[index].selectedFile;
      //       let previewImageFile = cmsData.data[index].image;
      //       for (let property in previewSelectedfile) {
      //         count = count + 1;
      //       }
      //       cmsData.data[index] = {
      //         ...cmsData.data[index], image: { ...previewImageFile, [count]: URL.createObjectURL(selectedImage) }
      //       };
      //       const reader = new FileReader();
      //       reader.onloadend = () => {
      //         // Use a regex to remove data url part
      //         let base64String = reader.result;
      //         cmsData.data[index] = {
      //           ...cmsData.data[index], selectedFile: { ...previewSelectedfile, [count]: base64String }
      //         };
      //       };
      //       reader.readAsDataURL(selectedImage);
      //       setFormData(cmsData);
      //       document.getElementById(`image-${index}`).value = '';
      //     }
      //   };
      // };

      // if (selectedImage) {
      //   imageReader.readAsDataURL(selectedImage);
      // }
    } else if (e.target.name === 'videoUrl') {
      let video_file = e.target.files[0];

      let video_url = URL.createObjectURL(video_file);

      const reader = new FileReader();
      reader.readAsDataURL(video_file);
      reader.onload = () => {
        // Use a regex to remove data url part
        const base64String = reader.result;
        cmsData.data[index] = {
          ...cmsData.data[index], selectedVideoFile: base64String, videoUrl: video_url, deletedVideoFile: cmsData.data[index]?.videoUrl,
        };
      };
      setFormData(cmsData);
      // document.getElementById(`videoUrl-${index}`).value = '';
    } else {
      cmsData.data[index] = {
        ...cmsData.data[index], [e?.target?.name]: e?.target?.value.trimLeft()
      }
      setFormData(cmsData);
    }
  };

  const handelDescriptionChange = (event, index) => {
    const cmsData = { ...formData };
    cmsData.data[index] = {
      ...cmsData.data[index], description: event.editor.getData(),
    }
    setFormData(cmsData);
  };

  const handelPageDescriptionChange = (event) => {
    setFormData((data) => ({ ...data, page_description: event.editor.getData() }));
  };

  // handle click event of the Remove button
  const handleCMSRemoveClick = (e) => {
    const index = e.currentTarget.getAttribute("data-id");
    const cmsData = [
      ...formData.data
    ];

    cmsData.splice(index, 1);
    setFormData((data1) => ({
      ...data1,
      data: cmsData
    }));
  };

  // handle click event of the Add More button
  const handleCMSAddMore = (e) => {
    e.preventDefault();
    const cmsData = [
      ...formData.data
    ];
    let i = cmsData.length;
    cmsData[i] = {
      ...cmsData[i], title: "", subtitle: "", image: [], description: '', selectedFile: [], videoUrl: '',
    }

    setFormData({
      ...formData,
      data: cmsData
    });
    i++;
  };

  const handleSupportChange = (e, index) => {
    const cmsData = { ...formData };
    cmsData.supportData[index] = {
      ...cmsData.supportData[index], [e?.target?.name]: e?.target?.value.trimLeft()
    }
    setFormData(cmsData);
  };

  const handleSupportAddMore = (e) => {
    e.preventDefault();
    const cmsData = [
      ...formData.supportData
    ];
    let i = cmsData.length;
    cmsData[i] = {
      ...cmsData[i], title: "", email: "",
    }

    setFormData({
      ...formData,
      supportData: cmsData
    });
    i++;
  };

  const handleSupportRemoveClick = (e) => {
    const index = e.currentTarget.getAttribute("data-id");
    const cmsData = [
      ...formData.supportData
    ];

    cmsData.splice(index, 1);
    setFormData((data1) => ({
      ...data1,
      supportData: cmsData
    }));
  };

  //remove file
  let removeFile = (index, i) => (e) => {
    document.getElementById(`image-${i}`).value = '';
    const cmsData = { ...formData };
    let all_files = Object.values(cmsData.data[i].image);
    let all_selected = Object.values(cmsData.data[i].selectedFile);
    let deleteImage = [];

    if (all_selected[index]?.original == undefined) {
      all_files = all_files.filter(data => data !== all_files[index]);
      all_selected = all_selected.filter(data => data !== all_selected[index]);
    } else {
      deleteImage = all_selected.filter(file => file._id === e?.currentTarget?.getAttribute('data-id'));
      all_files = all_files.filter(file => file._id !== e?.currentTarget?.getAttribute('data-id'));
      all_selected = all_selected.filter(file => file._id !== e?.currentTarget?.getAttribute('data-id'));
    }
    cmsData.data[i] = {
      ...cmsData.data[i], image: all_files, selectedFile: all_selected, deleteFile: deleteImage
    }
    setFormData(cmsData);
  };

  const removeVideoFile = (e, i) => {
    e.preventDefault();
    const cmsData = { ...formData };
    cmsData.data[i] = {
      ...cmsData.data[i], videoUrl: "", selectedVideoFile: "", deletedVideoFile: !cmsData.data[i]?.videoUrl?.includes('blob') ? cmsData.data[i]?.videoUrl : "",
    }
    document.getElementById(`videoUrl-${i}`).value = '';
    setFormData(cmsData);
  }

  /**************** on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    if (cms_id) {
      edit(formData, navigate, cms_id);
    } else {
      create(formData, navigate);
    }
  };

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <Form
                className="form-horizontal"
                onSubmit={(e) => onSubmit(e)}
                encType="multipart/form-data"
              >
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="page_name">
                          Page Name <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="page_name"
                          name="page_name"
                          maxLength="100"
                          value={page_name}
                          // required
                          // readOnly
                          onChange={onChange}
                          invalid={errorList.page_name ? true : false}
                        />
                        <Errors current_key="page_name" key="page_name" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="slug">
                          CMS Slug <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="slug"
                          name="slug"
                          maxLength="100"
                          value={slug}
                          // required
                          readOnly
                          invalid={errorList.slug ? true : false}
                        />
                        <Errors current_key="slug" key="slug" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="page_title">
                          Banner Title <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="page_title"
                          name="page_title"
                          maxLength="100"
                          value={page_title}
                          // required
                          onChange={onChange}
                          invalid={errorList.page_title ? true : false}
                        />
                        <Errors current_key="page_title" key="page_title" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="page_subtitle">
                          Banner Sub Title
                        </Label>
                        <Input
                          type="text"
                          id="page_subtitle"
                          name="page_subtitle"
                          value={page_subtitle}
                          onChange={onChange}
                          invalid={errorList.page_subtitle ? true : false}
                        />
                        <Errors
                          current_key="page_subtitle"
                          key="page_subtitle"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="thumbnail">
                          Banner Image <span>(The Banner Image aspect ratio should be 32:7)</span> <span>*</span>
                        </Label>
                        <Input
                          type="file"
                          accept="image/*"
                          id="thumbnail"
                          name="thumbnail"
                          onChange={e => onChange(e)}
                          invalid={errorList.image ? true : false}
                        />
                        <img
                          src={cms_id && banner_image == "" ? formData?.selectedFile : banner_image}
                          className="preview-img" alt="" />
                        <Errors
                          current_key="banner_image"
                          key="banner_image"
                        />
                      </FormGroup>
                    </Col>
                    {['privacy', 'terms', "terms-of-sale", "shipping-policy", "return-policy", 'faq', 'warranty-info', 'shipping-and-returns'].includes(slug) &&
                      <Col sm="12">
                        <FormGroup>
                          <Label htmlFor="description">
                            Page Description
                          </Label>
                          {page_description &&
                            <CKEditor
                              initData={page_description}
                              id="page_description"
                              name="page_description"
                              config={CKEditorConfig}
                              onChange={handelPageDescriptionChange}
                            />
                          }
                          {page_description == '' &&
                            <CKEditor
                              initData={page_description}
                              id="page_description"
                              name="page_description"
                              config={CKEditorConfig}
                              onChange={handelPageDescriptionChange}
                            />
                          }
                        </FormGroup>
                      </Col>
                    }
                  </Row>
                  <div>
                    <Card className="my-2">
                      <CardHeader tag="h5">SEO Information</CardHeader>
                      <CardBody>
                        <Row>
                          <Col sm="6">
                            <FormGroup>
                              <Label htmlFor="meta_title">
                                Title
                              </Label>
                              <Input
                                type="text"
                                id="meta_title"
                                name="meta_title"
                                value={meta_title}
                                onChange={onChange}
                                invalid={errorList.meta_title ? true : false}
                              />
                              <Errors
                                current_key="meta_title"
                                key="meta_title"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="6">
                            <FormGroup>
                              <Label htmlFor="meta_description">
                                Meta Description
                              </Label>
                              <Input
                                type="textarea"
                                id="meta_description"
                                name="meta_description"
                                value={meta_description}
                                onChange={onChange}
                                invalid={errorList.meta_description ? true : false}
                              />
                              <Errors
                                current_key="meta_description"
                                key="meta_description"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                  {['contact'].includes(slug) &&
                    <div>
                      <Card className="my-2">
                        <CardHeader tag="h5">Support Information</CardHeader>
                        <CardBody>
                          <Row>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="support_title">
                                  Title <span>*</span>
                                </Label>
                                <Input
                                  type="text"
                                  id="support_title"
                                  name="support_title"
                                  value={support_title}
                                  onChange={onChange}
                                  required
                                  invalid={errorList.support_title ? true : false}
                                />
                                <Errors
                                  current_key="support_title"
                                  key="support_title"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="support_subtitle">
                                  Sub Title
                                </Label>
                                <Input
                                  type="text"
                                  id="support_subtitle"
                                  name="support_subtitle"
                                  value={support_subtitle}
                                  onChange={onChange}
                                  invalid={errorList.support_subtitle ? true : false}
                                />
                                <Errors
                                  current_key="support_subtitle"
                                  key="support_subtitle"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <>
                            {formData?.supportData?.map((x, i) => {
                              return (

                                <div key={i}>
                                  <Card className="my-2">
                                    <CardHeader tag="h5">
                                      Card {i + 1}
                                    </CardHeader>
                                    <CardBody>
                                      <Row>
                                        <Col sm="6">
                                          <FormGroup>
                                            <Label htmlFor="title">
                                              Title <span>*</span>
                                            </Label>
                                            <input
                                              className="form-control"
                                              name="title"
                                              value={x.title}
                                              // maxLength={100}
                                              required
                                              onChange={e => handleSupportChange(e, i)}
                                            />
                                            <Errors current_key={`supportData[${i}].title`} />
                                          </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                          <FormGroup>
                                            <Label htmlFor="email">
                                              Email Address <span>*</span>
                                            </Label>
                                            <input
                                              className="ml10 form-control"
                                              name="email"
                                              type='email'
                                              value={x.email}
                                              required
                                              // maxLength={100}
                                              onChange={e => handleSupportChange(e, i)}
                                            />
                                            <Errors current_key={`supportData[${i}].email`} />
                                          </FormGroup>
                                        </Col>
                                        {
                                          // i != 0 &&
                                          <Col sm="12" style={{ marginBottom: 32 }}>

                                            <Button type="reset" size="sm" color="danger" data-id={i} onClick={handleSupportRemoveClick}>
                                              <i className="fa fa-ban"></i> Remove
                                            </Button>
                                          </Col>
                                        }
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </div>
                              );
                            })}
                            <Row>
                              <Col sm="6">
                                <FormGroup>
                                  <br />
                                  <Button type="reset" size="sm" color="primary" onClick={handleSupportAddMore}>
                                    <i className="fa fa-dot-circle-o"></i> Add More..
                                  </Button>
                                </FormGroup>
                              </Col>
                            </Row>
                          </>
                        </CardBody>
                      </Card>
                    </div>
                  }
                  {((slug && !slugData.includes(slug)) || slug === '') &&
                    <>
                      {formData?.data?.map((x, i) => {
                        return (

                          <div key={i}>
                            <Card className="my-2">
                              <CardHeader tag="h5">
                                Section {i + 1}
                              </CardHeader>
                              <CardBody>
                                <Row>
                                  <Col sm="6">
                                    <FormGroup>
                                      <Label htmlFor="title">
                                        Title
                                      </Label>
                                      <input
                                        className="form-control"
                                        name="title"
                                        value={x.title}
                                        maxLength={100}
                                        onChange={e => handleCMSChange(e, i)}
                                      // invalid={errorList[`data[${i}].title`]}
                                      />
                                      {/* <Errors current_key={`data[${i}].title`} /> */}
                                    </FormGroup>
                                  </Col>
                                  <Col sm="6">
                                    <FormGroup>
                                      <Label htmlFor="subtitle">
                                        Sub Title
                                      </Label>
                                      <input
                                        className="ml10 form-control"
                                        name="subtitle"
                                        value={x.subtitle}
                                        maxLength={100}
                                        onChange={e => handleCMSChange(e, i)}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col sm="6">
                                    <FormGroup>
                                      <Label htmlFor="thumbnail">
                                        Images <span>(The images aspect ratio should be 16:9 and the maximum size allowed should be 20MB)</span>
                                      </Label>
                                      <Input
                                        type="file"
                                        accept="image/*"
                                        id={`image-${i}`}
                                        name="thumbnail"
                                        onChange={e => handleCMSChange(e, i)}
                                      />
                                      <Errors
                                        current_key={`data[${i}].selectedFile`}
                                        key={`data[${i}].selectedFile`}
                                      />
                                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        {formData.data[i].image != undefined && formData.data[i].image &&
                                          Object.values(formData.data[i].image)?.map(
                                            (imageItems, index) => {
                                              return (
                                                <div className="thumb" key={index}>
                                                  <Col key={`img-${formData.data[i].image}`}>
                                                    {imageItems ? (
                                                      <img
                                                        src={imageItems?.original ? imageItems?.original : imageItems}
                                                        className="preview-img"
                                                        alt="image_file"
                                                        style={{ height: "40px" }}
                                                      />
                                                    ) : null}
                                                  </Col>
                                                  <Button
                                                    title="Remove Image"
                                                    data-id={imageItems._id}
                                                    className="btn btn-sm btn-danger"
                                                    onClick={removeFile(index, i)}
                                                  >
                                                    <i className="fa fa-close"></i>
                                                  </Button>
                                                </div>
                                              )
                                            })}
                                      </div>
                                      {x?.images && <Errors current_key={`data[${i}].images`} key="image" />}
                                    </FormGroup>
                                  </Col>
                                  <Col sm="6">
                                    <FormGroup>
                                      <Label htmlFor="videoUrl">
                                        Upload Video (Video maximum size should be 500MB.)
                                      </Label>
                                      <Input
                                        type="file"
                                        accept="video/*"
                                        id={`videoUrl-${i}`}
                                        name="videoUrl"
                                        onChange={(e) => handleCMSChange(e, i)}
                                        invalid={errorList.videoUrl ? true : false}
                                      />
                                      {formData.data[i].videoUrl && formData.data[i].videoUrl !== '' &&
                                        <div className="thumb">
                                          <video
                                            controls={true}
                                            src={formData.data[i].videoUrl}
                                            className="preview-video"
                                            alt=""
                                          />
                                          <Button
                                            title="Remove Video"
                                            className="btn btn-sm btn-danger"
                                            style={{ height: "30px", borderRadius: "20px", textAlign: "center", paddingTop: "3px" }}
                                            onClick={(e) => removeVideoFile(e, i)}
                                          >
                                            <i className="fa fa-close"></i>
                                          </Button>
                                        </div>
                                      }
                                      <Errors current_key="videoUrl" key="videoUrl" />
                                    </FormGroup>
                                  </Col>
                                  {cms_id ?
                                    <Col sm="12">
                                      <FormGroup>
                                        <Label htmlFor="description">
                                          Description
                                        </Label>
                                        {description &&
                                          <CKEditor
                                            initData={x.description}
                                            id="description"
                                            name="description"
                                            config={CKEditorConfig}
                                            onChange={e => handelDescriptionChange(e, i)}
                                          />
                                        }
                                        {description == '' &&
                                          <CKEditor
                                            initData={x.description}
                                            id="description"
                                            name="description"
                                            config={CKEditorConfig}
                                            onChange={e => handelDescriptionChange(e, i)}
                                          />}
                                      </FormGroup>
                                    </Col>
                                    :
                                    <Col sm="12">
                                      <FormGroup>
                                        <Label htmlFor="description">
                                          Description
                                        </Label>
                                        <CKEditor
                                          initData={x.description}
                                          id="description"
                                          name="description"
                                          config={CKEditorConfig}
                                          onChange={e => handelDescriptionChange(e, i)}
                                        />
                                      </FormGroup>
                                    </Col>
                                  }
                                  <Col sm="6">
                                    <FormGroup>
                                      <Label htmlFor="button_Text">
                                        Button Text
                                      </Label>
                                      <Input
                                        type="button_Text"
                                        id="button_Text"
                                        name="button_Text"
                                        value={x.button_Text}
                                        onChange={e => handleCMSChange(e, i)}
                                      />
                                      <Errors
                                        current_key={`data[${i}].button_Text`}
                                        key={`data[${i}].button_Text`}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col sm="6">
                                    <FormGroup>
                                      <Label htmlFor="button_Link">
                                        Button Link
                                      </Label>
                                      <Input
                                        type="url"
                                        id="button_Link"
                                        name="button_Link"
                                        value={x.button_Link}
                                        onChange={e => handleCMSChange(e, i)}
                                      />
                                      <Errors
                                        current_key={`data[${i}].button_Link`}
                                        key={`data[${i}].button_Link`}
                                      />
                                    </FormGroup>
                                  </Col>
                                  {
                                    i != 0 &&
                                    <Col sm="12" style={{ marginBottom: 32 }}>

                                      <Button type="reset" size="sm" color="danger" data-id={i} onClick={handleCMSRemoveClick}>
                                        <i className="fa fa-ban"></i> Remove
                                      </Button>
                                    </Col>
                                  }
                                </Row>
                              </CardBody>
                            </Card>
                          </div>
                        );
                      })}
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <br />
                            <Button type="reset" size="sm" color="primary" onClick={handleCMSAddMore}>
                              <i className="fa fa-dot-circle-o"></i> Add More..
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  }
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary">
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Link to="/admin/cms">
                    <Button type="reset" size="sm" color="danger">
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

CreateCMS.propTypes = {
  getCMSById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  cms: PropTypes.object.isRequired,
  create: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  cms: state.cms,
  errorList: state.errors,
  loading: state.cms.loading,
});

export default connect(mapStateToProps, {
  create,
  getCMSById,
  edit,
})(CreateCMS);
