import {
    GEAR_CATEGORY_MODAL_LOADING,
    GEAR_CATEGORY_CREATED,
    GEAR_CATEGORY_CREATE_ERROR,
    GEAR_CATEGORY_FAIL,
    GEAR_CATEGORY_LOADING,
    GEAR_CATEGORY_UPDATED,
    GEAR_CATEGORY_UPDATE_ERROR,
    GEAR_CATEGORY_DELETED,
    GEAR_CATEGORY_DELETE_ERROR,
    GEAR_CATEGORY_LIST_UPDATED,
    GET_GEAR_CATEGORY_BY_ID,
    GEAR_CATEGORY_CHANGE_STATUS,
    GEAR_CATEGORY_CHANGE_STATUS_ERROR,
  } from "actions/types";
  
  const initalState = {
    gearCategoryList: {
      page: 1,
      data: [],
      count: 0,
    },
    gearCurrentCategory: {},
    loading: false,
    isSubLoading: false,
    modal_loading: false,
  };
  
  export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
      case GEAR_CATEGORY_MODAL_LOADING:
        return {
          ...state,
          modal_loading: true,
        };
      case GEAR_CATEGORY_CREATED:
        //state.gearCategoryList.data.pop();
        return {
          ...state,
          gearCategoryList: {
            ...state.gearCategoryList,
            data: [payload.data, ...state.gearCategoryList.data],
          },
          modal_loading: false,
        };
      case GEAR_CATEGORY_CREATE_ERROR:
        return {
          ...state,
          modal_loading: false,
        };
      case GEAR_CATEGORY_FAIL:
        return {
          ...state,
          modal_loading: false,
          loading: false,
          isSubLoading:false
        };
      case GEAR_CATEGORY_LOADING:
        return {
          ...state,
          gearCategoryList: payload.is_sub_element
            ? state.gearCategoryList
            : initalState.gearCategoryList,
          gearCurrentCategory: payload.is_sub_element
            ? state.gearCurrentCategory
            : initalState.gearCurrentCategory,
          loading: payload.is_loading ? payload.is_loading : false,
          isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
        };
      case GEAR_CATEGORY_UPDATED:
        return {
          ...state,
          gearCurrentCategory: {},
          loading: false,
        };
      case GEAR_CATEGORY_UPDATE_ERROR:
      case GEAR_CATEGORY_DELETED:
      case GEAR_CATEGORY_DELETE_ERROR:
        return {
          ...state,
          loading: false,
        };
      case GEAR_CATEGORY_LIST_UPDATED:
        return {
          ...state,
          gearCategoryList: {
            data: payload.data.gearCategoryList.data,
            page: payload.data.gearCategoryList.metadata[0].current_page,
            count: payload.data.gearCategoryList.metadata[0].totalRecord,
          },
          loading: false,
        };
      case GET_GEAR_CATEGORY_BY_ID:
        return {
          ...state,
          gearCurrentCategory: payload.data,
          loading: false,
        };
      case GEAR_CATEGORY_CHANGE_STATUS:
        return {
          ...state,
          gearCategoryList: {
            ...state.gearCategoryList,
            data: state.gearCategoryList.data.map((category) =>
              category._id === payload.data._id
                ? { ...category, status: payload.data.status }
                : category
            ),
          },
          loading: false,
          isSubLoading: false
        };
      case GEAR_CATEGORY_CHANGE_STATUS_ERROR:
        return {
          ...state,
          gearCategoryList: {
            ...state.gearCategoryList,
            data: state.gearCategoryList.data.map((category) =>
              category._id === payload.data
                ? { ...category, is_failed: true }
                : { ...category, is_failed: false }
            ),
          },
          loading: false,
          isSubLoading: false
        };
  
      default:
        return state;
    }
  }
  