import axios from 'axios';
import { updateFilterData } from 'actions/commonActions';
import { GET_PRODUCT_SELECTOR_BY_ID, PRODUCT_SELECTOR_CREATED, PRODUCT_SELECTOR_CREATED_ERROR, PRODUCT_SELECTOR_FAIL, PRODUCT_SELECTOR_LIST_UPDATED, PRODUCT_SELECTOR_LOADING, PRODUCT_SELECTOR_UPDATED, PRODUCT_SELECTOR_UPDATED_ERROR, SET_ERRORS_LIST } from "actions/types";

// Create Product Selector
export const create = (formData, history) => async (dispatch) => {
    try {
        dispatch({
            type: PRODUCT_SELECTOR_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const res = await axios.post('/api/admin/product/selector-question/create', formData, config);

        if (res.data.status === true) {
            dispatch({
                type: PRODUCT_SELECTOR_CREATED,
                payload: {
                    message: res.data.message,
                    alertType: 'success',
                    data: res.data.response,
                },
            });
            history('/admin/kinetixx-fit');
        } else {
            const errors = res.data.errors;
            let errorsList = {};
            if (errors) {
                await errors.forEach((error) => {
                    errorsList = { ...errorsList, [error.param]: error.msg };
                });
            }
            dispatch({
                type: PRODUCT_SELECTOR_CREATED_ERROR,
                payload: {
                    message: errors[0].msg,
                    alertType: 'danger',
                    errorsList,
                },
            });
            dispatch({
                type: SET_ERRORS_LIST,
                payload: { ...errorsList },
            });
            return { status: false, id: null };
        }
    } catch (err) {
        dispatch({
            type: PRODUCT_SELECTOR_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
        return { status: false, id: null };
    }
};

//Get Product Selector List
export const getProductsList = (productParams) => async (dispatch) => {
    try {
        dispatch({
            type: PRODUCT_SELECTOR_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        productParams = updateFilterData(productParams);
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: productParams,
        };

        const res = await axios.get(`/api/admin/product/selector-question`, config);

        dispatch({
            type: PRODUCT_SELECTOR_LIST_UPDATED,
            payload: {
                message: res.data.message,
                alertType: 'success',
                data: {
                    productParams: productParams,
                    newsList: res.data.response[0],
                },
            },
        });
        return res;
    } catch (err) {
        dispatch({
            type: PRODUCT_SELECTOR_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
    }
};

// Get Product by id
export const getProductById = (product_id) => async (dispatch) => {
    try {
        dispatch({
            type: PRODUCT_SELECTOR_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const res = await axios.get(`/api/admin/product/selector-question/${product_id}`, config);

        await dispatch({
            type: GET_PRODUCT_SELECTOR_BY_ID,
            payload: {
                message: res.data.message,
                alertType: 'success',
                data: res.data.response,
            },
        });
    } catch (err) {
        dispatch({
            type: PRODUCT_SELECTOR_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
    }
};

// Edit Product
export const edit = (formData, history, product_id) => async (dispatch) => {
    try {
        dispatch({
            type: PRODUCT_SELECTOR_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const res = await axios.post(
            `/api/admin/product/selector-question/${product_id}`,
            formData,
            config
        );
        if (res.data.status === true) {
            dispatch({
                type: PRODUCT_SELECTOR_UPDATED,
                payload: {
                    message: res.data.message,
                    alertType: 'success',
                    data: res.data.response,
                },
            });
            history('/admin/kinetixx-fit/edit/category');
        } else {
            const errors = res.data.errors;
            let errorsList = {};
            if (errors) {
                await errors.forEach((error) => {
                    errorsList = { ...errorsList, [error.param]: error.msg };
                });
            }
            dispatch({
                type: PRODUCT_SELECTOR_UPDATED_ERROR,
                payload: {
                    message: errors[0].msg,
                    alertType: 'danger',
                    errorsList,
                },
            });
            dispatch({
                type: SET_ERRORS_LIST,
                payload: { ...errorsList },
            });
        }
    } catch (err) {
        dispatch({
            type: PRODUCT_SELECTOR_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
    }
};