import {
    GEAR_ATTRIBUTE_LOADING,
    GEAR_ATTRIBUTE_CREATED,
    GEAR_ATTRIBUTE_CREATE_ERROR,
    GEAR_ATTRIBUTE_FAIL,
    GEAR_ATTRIBUTE_UPDATED,
    GEAR_ATTRIBUTE_UPDATE_ERROR,
    GEAR_ATTRIBUTE_DELETED,
    GEAR_ATTRIBUTE_DELETE_ERROR,
    GEAR_ATTRIBUTE_LIST_UPDATED,
    GET_GEAR_ATTRIBUTE_BY_ID,
    GEAR_ATTRIBUTE_CHANGE_STATUS,
    GEAR_ATTRIBUTE_CHANGE_STATUS_ERROR,
    GEAR_ATTRIBUTE_TAG_DELETED,
    GEAR_ATTRIBUTE_TAG_DELETE_ERROR,
} from "actions/types";

const initalState = {
    gearAttributesList: {
        page: 1,
        data: [],
        count: 0,
    },
    gearCurrentAttribute: {},
    loading: false,
    isSubLoading: false,
};

export default function (state = initalState, action) {
    const { type, payload } = action;
    
    switch (type) {
        case GEAR_ATTRIBUTE_LOADING:
            return {
                ...state,
                gearAttributesList: payload.is_sub_element
                    ? state.gearAttributesList
                    : initalState.gearAttributesList,
                gearCurrentAttribute: payload.is_sub_element
                    ? state.gearCurrentAttribute
                    : initalState.gearCurrentAttribute,

                loading: payload.is_loading ? payload.is_loading : false,
                isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
            };
        case GEAR_ATTRIBUTE_TAG_DELETE_ERROR:
        case GEAR_ATTRIBUTE_DELETED:
        case GEAR_ATTRIBUTE_DELETE_ERROR:
        case GEAR_ATTRIBUTE_UPDATE_ERROR:
        case GEAR_ATTRIBUTE_CREATE_ERROR:
        case GEAR_ATTRIBUTE_CREATED:
            return {
                ...state,
                loading: false,
            };
        case GEAR_ATTRIBUTE_FAIL:
            return {
                ...state,
                loading: false,
                isSubLoading: false,
            };
        case GEAR_ATTRIBUTE_UPDATED:
            return {
                ...state,
                gearCurrentAttribute: {},
                loading: false,
            };
        case GEAR_ATTRIBUTE_LIST_UPDATED:
            return {
                ...state,
                gearAttributesList: {
                    data: payload.data.attributeList.data,
                    page: payload.data.attributeList.metadata[0].current_page,
                    count: payload.data.attributeList.metadata[0].totalRecord,
                },
                loading: false,
            };
        case GET_GEAR_ATTRIBUTE_BY_ID:
            return {
                ...state,
                gearCurrentAttribute: payload.data,
                loading: false,
            };
        case GEAR_ATTRIBUTE_CHANGE_STATUS:
            return {
                ...state,
                gearAttributesList: {
                    ...state.gearAttributesList,
                    data: state.gearAttributesList.data.map((attribute) =>
                        attribute._id === payload.data._id
                            ? { ...attribute, status: payload.data.status }
                            : attribute
                    ),
                },
                loading: false,
                isSubLoading: false,
            };
        case GEAR_ATTRIBUTE_CHANGE_STATUS_ERROR:
            return {
                ...state,
                gearAttributesList: {
                    ...state.gearAttributesList,
                    data: state.gearAttributesList.data.map((attribute) =>
                        attribute._id === payload.data
                            ? { ...attribute, is_failed: true }
                            : { ...attribute, is_failed: false }
                    ),
                },
                loading: false,
                isSubLoading: false,
            };
        case GEAR_ATTRIBUTE_TAG_DELETED:
            return {
                ...state,
                gearCurrentAttribute: {
                    ...state.gearCurrentAttribute,
                    terms: state.gearCurrentAttribute.terms.filter(
                        (term) => term._id !== payload.data
                    ),
                },
                loading: false,
            };
        default:
            return state;
    }
}
