import {
  DEALER_ADDRESS_LOADING,
  DEALER_ADDRESS_CREATED,
  DEALER_ADDRESS_CREATE_ERROR,
  DEALER_ADDRESS_FAIL,
  DEALER_ADDRESS_UPDATED,
  DEALER_ADDRESS_UPDATE_ERROR,
  DEALER_ADDRESS_DELETED,
  DEALER_ADDRESS_DELETE_ERROR,
  DEALER_ADDRESS_LIST_UPDATED,
  GET_DEALER_ADDRESS_BY_ID,
  DEALER_ADDRESS_PRIMARY,
  DEALER_ADDRESS_PRIMARY_ERROR,
  DEALER_ADDRESS_CHANGE_STATUS,
  DEALER_ADDRESS_CHANGE_STATUS_ERROR,
  GET_DEALER_DETAILS_BY_ID
} from "actions/types";

const initalState = {
  dealersAddressList: {
    page: 1,
    data: [],
    count: 0,
  },
  loading: false,
  isSubLoading: false,
  currentDealerAddress: {},
  currentDealerDetails: {}
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case DEALER_ADDRESS_LOADING:
      return {
        ...state,
        dealersAddressList: payload.is_sub_element
          ? state.dealersAddressList
          : initalState.dealersAddressList,
        currentDealerAddress: payload.is_sub_element
          ? state.currentDealerAddress
          : initalState.currentDealerAddress,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case DEALER_ADDRESS_CREATED:
      return {
        ...state,
        loading: false,
      };
    case DEALER_ADDRESS_CREATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DEALER_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case DEALER_ADDRESS_UPDATED:
      return {
        ...state,
        currentDealerAddress: {},
        loading: false,
      };
    case DEALER_ADDRESS_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DEALER_ADDRESS_DELETED:
      return {
        ...state,
        loading: false,
      };
    case DEALER_ADDRESS_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DEALER_ADDRESS_LIST_UPDATED:
      return {
        ...state,
        dealersAddressList: {
          data: payload.data.dealerAddressList.data,
          page: payload.data.dealerAddressList.metadata[0].current_page,
          count: payload.data.dealerAddressList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_DEALER_ADDRESS_BY_ID:
      return {
        ...state,
        currentDealerAddress: payload.data,
        loading: false,
      };
    case DEALER_ADDRESS_PRIMARY:
      return {
        ...state,
        dealersAddressList: {
          ...state.dealersAddressList,
          data: state.dealersAddressList.data.map((dealer) =>
            dealer.address_id === payload.data.current // to mark current address as primary
              ? {
                  ...dealer,
                  is_primary: true,
                  last_edited: payload.data.current_last_edited,
                }
              : dealer.address_id === payload.data.previous // to remove pevious primary address as primary
              ? {
                  ...dealer,
                  is_primary: false,
                  last_edited: payload.data.previous_last_edited,
                }
              : dealer
          ),
        },
        loading: false,
      };
    case DEALER_ADDRESS_PRIMARY_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DEALER_ADDRESS_CHANGE_STATUS:
      return {
        ...state,
        dealersAddressList: {
          ...state.dealersAddressList,
          data: state.dealersAddressList.data.map((address) =>
            address.address_id === payload.data.address_id
              ? {
                  ...address,
                  status: payload.data.status,
                  last_edited: payload.data.last_edited,
                }
              : address
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case DEALER_ADDRESS_CHANGE_STATUS_ERROR:
      return {
        ...state,
        dealersAddressList: {
          ...state.dealersAddressList,
          data: state.dealersAddressList.data.map((address) =>
            address._id === payload.data
              ? { ...address, is_failed: true }
              : { ...address, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case GET_DEALER_DETAILS_BY_ID:
      return {
        ...state,
        currentDealerDetails: payload.data,
        loading: false,
      };
    default:
      return state;
  }
}
