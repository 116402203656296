/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMatch, useNavigate, useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Moment from 'react-moment';
import * as Constants from 'constants/index';
import { getOrderLogsByOrderId, getOrderById } from 'actions/admin/order';
import Spinner from 'views/Spinner';

const OrderLogs = ({
  getOrderLogsByOrderId,
  getOrderById,
  currentOrder,
  loader,
}) => {
  const match = useMatch('admin/orders/order-details/logs/:order_id');
  let i = 0;
  const { order_id: order_id } = useParams();


  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(async () => {
    setLoading(true);
    let res = await getOrderLogsByOrderId(order_id);
    if (res) {
      setData(res);
    }
    await getOrderById(match.params.order_id);
    setLoading(false);
  }, []);

  //################## END Set properties(options) of datatables ###################

  //#########################Colums defination start ###################

  return loader || loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn userTableList">
      <div className="label">
        <h3>Order Logs ({currentOrder.order_id})</h3>
      </div>
      <table className="table table-striped border">
        <thead>
          <tr>
            <th>#</th>
            <th>Activity</th>
          </tr>
        </thead>
        <tbody>
          {data.length ? (
            data.map((item, index) => {
              i++;
              if (item?.status_log?.edit_order_notes) {
                return (
                  <>
                    <tr>
                      <td>{i}</td>
                      <td>
                        <span>
                          <b>
                            {item.user_detail && item.user_detail[0]?.first_name
                              ? item.user_detail[0]?.first_name
                              : ''}{' '}
                            {item.user_detail && item.user_detail[0]?.last_name
                              ? item.user_detail[0]?.last_name
                              : ''}
                          </b>
                        </span>{' '}
                        <span style={{ marginBottom: "1px" }}>{item.status_log?.edit_order_notes}</span>
                        <br />
                        Date :
                        <span className="time_formatting">
                          <Moment
                            format={
                              process.env.REACT_APP_DISPLAY_DATE_FORMATE ??
                              'MM/DD/YYYY'
                            }
                            locale="de"
                          >
                            {item.status_changed_at}
                          </Moment>
                        </span>
                        <span className="time_formatting">
                          <Moment format="HH:mm" locale="de">{item.status_changed_at}</Moment>
                        </span>
                      </td>
                    </tr>
                  </>
                );
              } else if (item?.status_log?.previous_shipping_boxes || item?.status_log?.new_shipping_boxes) {
                return (
                  <>
                    <tr>
                      <td>{i}</td>
                      <td>
                        <span>
                          <b>
                            {item.user_detail && item.user_detail[0]?.first_name
                              ? item.user_detail[0]?.first_name
                              : ''}{' '}
                            {item.user_detail && item.user_detail[0]?.last_name
                              ? item.user_detail[0]?.last_name
                              : ''}
                          </b>
                        </span>{' '}
                        has changed shipping boxes
                        <br />
                        {item.status_log.previous_shipping_boxes ?
                          <>
                            <span>
                              {item.status_log.previous_shipping_boxes.split(",").length > 1 ? <b>Previous Shipping Boxes :</b> : <b>Previous Shipping Box :</b>}
                              <span>
                                {item.status_log.previous_shipping_boxes}
                              </span>

                            </span><br /></> : ""}
                        {item.status_log.new_shipping_boxes ?
                          <>
                            <span>
                              {item.status_log.new_shipping_boxes.split(",").length > 1 ? <b>New Shipping Boxes :</b> : <b>New Shipping Box :</b>}
                              <span>
                                {item.status_log.new_shipping_boxes}
                              </span>

                            </span><br /></> : ""}
                      </td>
                    </tr>
                  </>
                )
              } else if (item.status_log?.internal_notes) {
                return (
                  <>
                    <tr>
                      <td>{i}</td>
                      <td>
                        <span>
                          <b>
                            {item.user_detail && item.user_detail[0].first_name
                              ? item.user_detail[0].first_name
                              : ''}{' '}
                            {item.user_detail && item.user_detail[0].last_name
                              ? item.user_detail[0].last_name
                              : ''}
                          </b>
                        </span>{' '}
                        has added internal notes
                        <br />
                        Internal note :
                        <span>
                          {
                            item.status_log.internal_notes
                          }
                        </span>
                        <br />
                        Date :
                        <span className="time_formatting">
                          <Moment
                            format={
                              process.env.REACT_APP_DISPLAY_DATE_FORMATE ??
                              'MM/DD/YYYY'
                            }
                            locale="de"
                          >
                            {item.status_changed_at}
                          </Moment>
                        </span>
                        <span className="time_formatting">
                          <Moment format="HH:mm" locale="de">{item.status_changed_at}</Moment>
                        </span>
                      </td>
                    </tr>
                  </>
                );
              } else if (item?.status_log?.previous_order_total || item?.status_log?.new_order_total || item?.status_log?.products_before?.length > 0 || item.status_log?.products_after?.length > 0 || item.status_log?.previous_shipping_address || item.status_log?.previous_billing_address || item.status_log?.previous_service_type) {
                return (
                  <>
                    <tr>
                      <td>{i}</td>
                      <td>
                        <span>
                          <b>
                            {item.user_detail && item.user_detail[0].first_name
                              ? item.user_detail[0].first_name
                              : ''}{' '}
                            {item.user_detail && item.user_detail[0].last_name
                              ? item.user_detail[0].last_name
                              : ''}
                          </b>
                        </span>{' '}
                        has updated the Order
                        <br />
                        {item.status_log?.previous_order_total ?
                          <>
                            <span>
                              <b>Order Total :</b>
                              <span className="strikeit">
                                ${
                                  Number(item.status_log?.previous_order_total).toFixed(2)
                                }
                              </span>
                              &nbsp;&nbsp;
                              <span>
                                ${
                                  Number(item.status_log.new_order_total).toFixed(2)
                                }
                              </span>
                            </span><br /></> : null}

                        {item.status_log?.products_before.length > 0 || item.status_log?.products_after.length > 0 ?
                          <span>
                            <b>Updated Products:</b>
                            {item.status_log.products_before?.map((prod) => <p key={`${prod.product_title}`} className="strikeit" style={{ marginBottom: "1px" }}>
                              {
                                `Category : ${prod.category_name} - Product : ${prod.product_title} - Sku : ${prod.product_variation_sku} - Quantity : ${prod.quantity} ${prod.customised_shaft ? " - Customized Shaft" : ""}`
                              }
                            </p>)}
                            {item.status_log.products_after?.map((prod) => <p key={`${prod.product_title}`} style={{ marginBottom: "1px" }}>
                              {
                                `Category : ${prod.category_name} - Product : ${prod.product_title} - Sku : ${prod.product_variation_sku} - Quantity : ${prod.quantity} ${prod.customised_shaft ? " - Customized Shaft" : ""}`
                              }
                            </p>)}</span> : null}
                        {item.status_log?.previous_shipping_address || item.status_log?.new_shipping_address ?
                          <span><p style={{ marginBottom: "1px" }}><b>Previous Shipping Address:</b> {item.status_log?.previous_shipping_address}</p>
                            <p style={{ marginBottom: "1px" }}><b>New Shipping Address:</b> {item.status_log?.new_shipping_address}</p>
                          </span> : null
                        }
                        {item.status_log?.previous_billing_address || item.status_log?.new_billing_address ?
                          <span><p style={{ marginBottom: "1px" }}><b>Previous Billing Address:</b> {item.status_log?.previous_billing_address}</p>
                            <p style={{ marginBottom: "1px" }}><b>New Billing Address:</b> {item.status_log?.new_billing_address}</p>
                          </span> : null
                        }
                        {item.status_log?.previous_service_type || item.status_log?.new_service_type ?
                          <span><p style={{ marginBottom: "1px" }}><b>Previous Service Type:</b> {item.status_log?.previous_service_type}</p>
                            <p style={{ marginBottom: "1px" }}><b>New Service Type:</b> {item.status_log?.new_service_type}</p>
                          </span> : null
                        }
                        <b>Date :</b>
                        <span className="time_formatting">
                          <Moment
                            format={
                              process.env.REACT_APP_DISPLAY_DATE_FORMATE ??
                              'MM/DD/YYYY'
                            }
                            locale="de"
                          >
                            {item.status_changed_at}
                          </Moment>
                        </span>
                        <span className="time_formatting">
                          <Moment format="HH:mm" locale="de">{item.status_changed_at}</Moment>
                        </span>
                      </td>
                    </tr>
                  </>
                );
              } else if (item.status_log?.previous_order_payment_status && item.status_log?.new_order_payment_status) {
                return (
                  <>
                    <tr>
                      <td>{i}</td>
                      <td>
                        {item?.user_detail.length === 0 || !item.user_detail ? "" :
                          <span>
                            <b>
                              {item.user_detail && item.user_detail[0]?.first_name
                                ? item.user_detail[0].first_name
                                : ''}{' '}
                              {item.user_detail && item.user_detail[0]?.last_name
                                ? item.user_detail[0].last_name
                                : ''}
                            </b>
                          </span>}{' '}
                        {item?.user_detail.length === 0 || !item.user_detail ? "" : "has changed the status of order"}
                        {item?.user_detail.length === 0 || !item.user_detail ? "" : <br />}
                        Payment Status :
                        <span className="strikeit">
                          {
                            Constants.ORDER_LIST_PAYMENT_STATUS[
                            item.status_log.previous_order_payment_status
                            ]
                          }
                        </span>
                        &nbsp;&nbsp;
                        <span>
                          {
                            Constants.ORDER_LIST_PAYMENT_STATUS[
                            item.status_log.new_order_payment_status
                            ]
                          }
                        </span>
                        <br />
                        Date :
                        <span className="time_formatting">
                          <Moment
                            format={
                              process.env.REACT_APP_DISPLAY_DATE_FORMATE ??
                              'MM/DD/YYYY'
                            }
                            locale="de"
                          >
                            {item.status_changed_at}
                          </Moment>
                        </span>
                        <span className="time_formatting">
                          <Moment format="HH:mm" locale="de">{item.status_changed_at}</Moment>
                        </span>
                      </td>
                    </tr>
                  </>
                );
              } else
                return (
                  <>
                    <tr>
                      <td>{i}</td>
                      <td>
                        {item?.user_detail.length === 0 || !item.user_detail ? "" :
                          <span>
                            <b>
                              {item.user_detail && item.user_detail[0]?.first_name
                                ? item.user_detail[0].first_name
                                : ''}{' '}
                              {item.user_detail && item.user_detail[0]?.last_name
                                ? item.user_detail[0].last_name
                                : ''}
                            </b>
                          </span>}{' '}
                        {item?.user_detail.length === 0 || !item.user_detail ? "" : "has changed the status of order"}
                        {item?.user_detail.length === 0 || !item.user_detail ? "" : <br />}
                        Status :
                        <span className="strikeit">
                          {
                            Constants.VIEW_ORDER_LIST_ORDER_STATUS[
                            item?.status_log?.previous_order_status
                            ]
                          }
                        </span>
                        &nbsp;&nbsp;
                        <span>
                          {
                            Constants.VIEW_ORDER_LIST_ORDER_STATUS[
                            item?.status_log?.new_order_status
                            ]
                          }
                        </span>
                        <br />
                        Date :
                        <span className="time_formatting">
                          <Moment
                            format={
                              process.env.REACT_APP_DISPLAY_DATE_FORMATE ??
                              'MM/DD/YYYY'
                            }
                            locale="de"
                          >
                            {item.status_changed_at}
                          </Moment>
                        </span>
                        <span className="time_formatting">
                          <Moment format="HH:mm" locale="de">{item.status_changed_at}</Moment>
                        </span>
                      </td>
                    </tr>
                  </>
                );
            })
          ) : (
            <>
              <tr>
                <td colspan="2">No Data Found...</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      <div>
        <Link to="/admin/orders">
          <Button type="reset" size="sm" color="info">
            <i className="fa fa-arrow-left"></i> Back
          </Button>
        </Link>
      </div>
    </div>
  );
};

OrderLogs.propTypes = {
  getOrderById: PropTypes.func.isRequired,
  currentOrder: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  currentOrder: state.order.currentOrder,
  auth: state.auth,
  loader: state.order.order_loading,
});

export default connect(mapStateToProps, {
  getOrderLogsByOrderId,
  getOrderById,
})(OrderLogs);
