/* eslint-disable */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, Row, Input } from 'reactstrap';
import { PERMISSION_NAME } from 'constants';
import Moment from 'react-moment';
import Spinner from 'views/Spinner';
import CustomFilters from './CustomFilters';
import CustomExportToCSV from './CustomExportToCSV';
import BulkActions from './BulkActions';

import { getUsersList, deleteUser, changeStatus } from 'actions/admin/user';

import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import * as Constants from 'constants/index';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import PerPageSelect from '../PerPageSelect';

import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from '../../../utils/Loading';

//   PaginationProvider,
//   SizePerPageDropdownStandalone,
// } from "react-bootstrap-table2-paginator";

const renderName = (params) => {
  if (params.data === undefined) {
    return <Loading />;
  } else {
    return <div>{params.data?.name}</div>;
  }
};

const renderEmail = (params) => {
  return <div title={params.data?.email}>{params.data?.email}</div>;
};

const renderCreatedAt = (params) => {
  return (
    <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
      {params.data?.created_at}
    </Moment>
  );
};

const renderLastLogin = (params) => {
  return params.data?.last_login ? (
    <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
      {params.data?.last_login}
    </Moment>
  ) : (
    '---------------'
  );
};

const renderStatus = (params, changeCustomerStatus) => {
  return (
    <div>
      <Input
        type="select"
        name="status"
        id={params.data?._id}
        defaultValue={params.data?.status}
        onChange={(e, a) => {
          changeCustomerStatus(params.data?._id, e.target.value);
        }}
      >
        {/* <option value="0">Pending</option> */}
        <option value="1">Inactive</option>
        <option value="2">Active</option>
        {/* <option value="3">Blocked</option> */}
      </Input>
    </div>
  );
};

const renderActions = (params, deleteUser, updateCustomers, user) => {
  const customer = params.data?.name
    .replace(/[^a-zA-Z ]/g, '')
    .split(' ')
    .join('-')
    .toLowerCase();
  return (
    <div>
      <Link to={`/admin/customers/details/${params.data?._id}`}>
        <Button type="button" size="sm" color="info" title="View">
          <i className="fa fa-eye"></i>
        </Button>
      </Link>
      <Link to={`/admin/customers/edit/${params.data?._id}`}>
        <Button type="button" size="sm" color="success" title="Edit">
          <i className="fa fa-pencil"></i>
        </Button>
      </Link>
      <Button
        type="button"
        size="sm"
        color="danger"
        title="Delete"
        onClick={(e) => {
          if (
            window.confirm(
              `Are you sure, you want to delete ${params.data?.name} user?`
            )
          ) {
            deleteUser(params.data?._id).then((res) => {
              if (res) updateCustomers();
            });
          }
        }}
      >
        <i className="fa fa-trash"></i>
      </Button>
      <Link
        to={`/admin/customers/customer-address/${customer}/${params.data?._id}`}
      >
        <Button type="button" size="sm" color="info" title="Addresses">
          <i className="fa fa-map-marker"></i>
        </Button>
      </Link>
      {user?.permission?.includes(
        PERMISSION_NAME.CUSTOMER_MANAGEMENT
      ) ? null : (
        <Link
          to={{
            pathname: '/admin/order/create',
          }}
          state={{
            userId: params.data?._id,
            userType: params.data?.role[0]?.user_type,
          }}
        >
          <Button type="button" size="sm" color="info" title="Cart">
            <i className="fa fa-cart-plus" aria-hidden="true"></i>
          </Button>
        </Link>
      )}
    </div>
  );
};

const actions = (
  <div className="add-button-div">
    <Link to="/admin/customers/create" className="addNewElementClass">
      <Button color="primary ml-0" size="sm">
        <i className="fa fa-plus"></i> Add New Customer
      </Button>
    </Link>
  </div>
);

const UsersList = ({
  getUsersList,
  deleteUser,
  changeStatus,
  // history,
  userList: { data, count },
  loading,
  subLoading,
  auth: { user },
}) => {
  const location = useLocation();
  let history = useNavigate();
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
        limit: Constants.DEFAULT_PAGE_SIZE,
        page: 1,
        orderBy: 'created_at',
        ascending: 'desc',
        query: '',
        filter: {},
        isAll: 1,
        role: 2,
      }
  );

  //################## to get updated list ###################

  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);

  const updateCustomers = () => {
    gridApi.purgeInfiniteCache();
  };

  const changeCustomerStatus = async (id, value) => {
    await changeStatus(id, value);
    gridApi.purgeInfiniteCache();
  };

  //#########################Colums defination start ###################
  const columns = [
    {
      field: 'insensitiveName',
      headerName: 'Name',
      filter: 'agTextColumnFilter',
      width: 130,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      colSpan: (params) => {
        if (params.data === undefined) {
          return 7;
        } else {
          return 0;
        }
      },
      cellRenderer: (params) => renderName(params),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 180,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,

      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => renderEmail(params),
    },
    {
      field: 'order_till_date',
      headerName: 'Orders',
      width: 130,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,

      filter: 'agNumberColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 120,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agDateColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.DATE_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
        buttons: ['reset', 'apply'],
      },
      cellRenderer: (params) => renderCreatedAt(params),
    },
    {
      field: 'last_login',
      headerName: 'Last Login',
      width: 120,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agDateColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.DATE_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
        buttons: ['reset', 'apply'],
      },
      cellRenderer: (params) => renderLastLogin(params),
    },
    {
      field: 'status',
      headerName: 'Status',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      width: 140,
      sortable: true,
      cellRenderer: (params) => renderStatus(params, changeCustomerStatus),
    },
    {
      field: '_id',
      width: 220,
      headerName: 'Actions',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) =>
        renderActions(params, deleteUser, updateCustomers, user),
    },
  ];
  //#########################Colums defination end ###################

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };
          const customData = await getUsersList(customParams, history);
          if (customData?.data?.status) {
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });

  let delayTimer;
  const onSearch = (e) => {
    clearTimeout(delayTimer);
    const searchText = e.target.value.trim();
    delayTimer = setTimeout(() => {
      if (gridApi) {
        if (Object.keys(sortingParams.filter).length) {
          gridApi.setFilterModel(sortingParams.filter);
        }
        const dataSource = {
          getRows: async (params) => {
            const page = gridApi.paginationGetCurrentPage() + 1;
            const limit = gridApi.paginationGetPageSize();

            const sortModel = params.sortModel.length
              ? params.sortModel[0]
              : null;
            const filterModel = params.filterModel ? params.filterModel : {};
            const customParams = {
              ...sortingParams,
              query: searchText,
              limit,
              orderBy: sortModel?.colId,
              ascending: sortModel?.sort,
              filter: filterModel,
              page,
            };

            const customData = await getUsersList(customParams, history);
            if (customData?.data?.status) {
              params.successCallback(
                customData.data.response[0].data,
                customData.data.response[0].metadata[0].totalRecord
              );
              customParams.onLoad = false;
              setSortingParams({ ...customParams });
              history(location.pathname, { state: customParams });
            } else {
              params.successCallback([], 0);
            }
          },
        };
        gridApi.setDatasource(dataSource);
        gridApi.api?.sizeColumnsToFit();
      }
    }, 1000);
  };

  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                {actions}
                <div className="rightpaneltop">
                  <Input
                    type="text"
                    id="search-text-box"
                    placeholder="Search"
                    onKeyUp={(e) => {
                      onSearch(e);
                    }}
                  // onChange={onSearchBoxTextChanged}
                  />
                </div>
                <div className="me-3"></div>
                <div className="rightpaneltop">
                  User Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>
              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      defaultColDef={{
                        resizable: true,
                      }}
                      loadingCellRenderer={customLoadingCellRenderer}
                      loadingCellRendererParams={loadingCellRendererParams}
                      domLayout={'autoHeight'}
                      rowModelType={'infinite'}
                      columnDefs={columns}
                      pagination={true}
                      sizeColumnsToFit={true}
                      onFilterChanged={filterChanged}
                      onGridReady={onGridReady}
                      paginationPageSize={sortingParams.limit}
                      cacheBlockSize={sortingParams.limit}
                      cacheQuickFilter={true}
                      onFirstDataRendered={onFirstDataRendered}
                      onPaginationChanged={onPaginationChanged}
                      ref={gridRef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

UsersList.propTypes = {
  getUsersList: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  userList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  userList: state.user.usersList,
  loading: state.user.loading,
  subLoading: state.user.isSubLoading,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getUsersList,
  deleteUser,
  changeStatus,
})(UsersList);
