import React, {
    useEffect,
    useState,
    useRef,
    useMemo,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate, useMatch } from 'react-router-dom';

import { getShippingCancelations, downloadShippingCancelations } from 'actions/admin/order';
import * as Constants from 'constants/index';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import Spinner from 'views/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from '../../../utils/Loading';

import PerPageSelect from '../PerPageSelect';
import {
    Button,
    Table,
    Card,
    CardBody,
    Col,
    Row,
    CardFooter,
    CardHeader,
    Label
} from 'reactstrap';

const renderOrderId = (params) => {
    if (params?.data === undefined) {
        return <Loading />
    } else {
        return (<div>{params.data?.order_id}</div>)
    }
}

const renderBoxName = (params) => {
    return <div>{`${params.data?.box_name} ${params.data?.box_id ? `(KXS-${params.data?.box_id})` : ""}`}</div>
}
const ShippingCancelations = ({ auth: { user }, loading, getShippingCancelations }) => {
    const history = useNavigate();
    const location = useLocation();
    const [timeZone, setTimeZone] = useState('');

    useEffect(() => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setTimeZone(userTimeZone);
    }, []);

    const [sortingParams, setSortingParams] = useState(
        Object.keys(location?.state ?? {}).length
            ? { ...location.state, onLoad: true }
            : {
                limit: Constants.DEFAULT_PAGE_SIZE,
                page: 1,
                orderBy: 'created_at',
                ascending: 'desc',
                query: '',
                filter: {},
                onLoad: true,
            }
    );

    const [downloadData, setDownloadData] = useState([]);
    const gridRef = useRef();
    const [gridApi, setGridApi] = useState(null);

    const handleCSVDownload = useCallback(async (event) => {
        let data = await downloadShippingCancelations(sortingParams, timeZone);
        console.log(data);
        setDownloadData(data?.data);
        document.getElementById('downloadCsv').click();
    });

    let anyFilter = {
        displayName: 'ANY',
        displayKey: 'any',
        numberOfInputs: 0,
        predicate: () => { },
    };

    const columns = [
        {
            field: 'order_id',
            headerName: 'Order ID',
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            sortable: true,
            width: 150,
            filter: 'agTextColumnFilter',
            // pass in additional parameters to the text filter
            suppressSizeToFit: false,
            filterParams: {
                closeOnApply: true,
                filterOptions: Constants.TEXT_FILTER_OPTIONS,
                suppressAndOrCondition: true,
                debounceMs: 800,
            },
            colSpan: (params) => {
                if (params.data === undefined) {
                    return 8;
                } else {
                    return 0;
                }
            },
            cellRenderer: (params) => renderOrderId(params)
        },
        {
            field: 'box_name',
            headerName: 'Box Name',
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            sortable: true,
            width: 150,
            filter: 'agTextColumnFilter',
            // pass in additional parameters to the text filter
            suppressSizeToFit: false,
            filterParams: {
                closeOnApply: true,
                filterOptions: Constants.TEXT_FILTER_OPTIONS,
                suppressAndOrCondition: true,
                debounceMs: 800,
            },
            cellRenderer: (params) => renderBoxName(params)
        },
        {
            field: 'carrier',
            headerName: 'Carrier',
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            sortable: true,
            width: 150,
            filter: 'agTextColumnFilter',
            // pass in additional parameters to the text filter
            suppressSizeToFit: false,
            filterParams: {
                closeOnApply: true,
                filterOptions: Constants.TEXT_FILTER_OPTIONS,
                suppressAndOrCondition: true,
                debounceMs: 800,
            },
        },
        {
            field: 'service',
            headerName: 'Service Type',
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            sortable: true,
            width: 200,
            filter: 'agTextColumnFilter',
            // pass in additional parameters to the text filter
            suppressSizeToFit: false,
            filterParams: {
                closeOnApply: true,
                filterOptions: Constants.TEXT_FILTER_OPTIONS,
                suppressAndOrCondition: true,
                debounceMs: 800,
            },
        },
        {
            field: 'rate',
            headerName: 'Shipping Amount($)',
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            sortable: true,
            width: 180,
            filter: 'agTextColumnFilter',
            // pass in additional parameters to the text filter
            suppressSizeToFit: false,
            filterParams: {
                closeOnApply: true,
                filterOptions: Constants.TEXT_FILTER_OPTIONS,
                suppressAndOrCondition: true,
                debounceMs: 800,
            },
            cellRenderer: (params) => (<div>{params.value}</div>)
        },
        {
            field: 'created_at',
            headerName: 'Date',
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            sortable: true,
            width: 160,
            filter: 'agDateColumnFilter',
            // pass in additional parameters to the text filter
            suppressSizeToFit: false,
            filterParams: {
                closeOnApply: true,
                suppressAndOrCondition: true,
                filterOptions: Constants.DATE_FILTER_OPTIONS,
                debounceMs: 800,
                buttons: ['reset', 'apply'],
            },
            cellRenderer: (params) => (
                <>
                    <div className="primary-Outer">
                        <Moment
                            format={
                                process.env.REACT_APP_DISPLAY_DATE_FORMATE ?? 'MM/DD/YYYY'
                            }
                            locale="de"
                        >
                            {params.value}
                        </Moment>
                        <span>
                            <Moment format="HH:mm" locale="de">{params.value}</Moment>
                        </span>
                    </div>
                </>
            ),
        },
        {
            field: 'refund_status',
            headerName: 'Refund Status',
            width: 130,
            suppressSizeToFit: false,
            suppressMovable: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                closeOnApply: true,
                filterOptions: [
                    anyFilter,
                    {
                        displayName: 'PROCESSING',
                        displayKey: 'processing',
                        numberOfInputs: 0,
                        predicate: () => { },
                        value: 1,
                    },
                    {
                        displayName: 'VOIDED',
                        displayKey: 'voided',
                        numberOfInputs: 0,
                        predicate: () => { },
                        value: 2,
                    },
                    {
                        displayName: 'REJECTED',
                        displayKey: 'rejected',
                        numberOfInputs: 0,
                        predicate: () => { },
                        value: 3,
                    },
                ],
                suppressAndOrCondition: true,
            },
            cellClass: 'suppress-movable-col',
            sort: true,
            cellRenderer: (params) => (<div>{Constants.VOID_STATUS[params.value - 1]}</div>)
        },
        {
            field: 'label_url',
            headerName: "Ship Label",
            width: 200,
            suppressSizeToFit: false,
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            cellRenderer: (params) => (<a target="_blank" href={params?.value}>
                <Button
                    type="button"
                    className="btn-success btn-sm mr-05 mb-05"
                    title="Download Ship Label"
                >
                    <i className="fa fa-download" id="printLabel"></i>

                </Button>
            </a>)
        }

    ]

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    });


    const filterChanged = useCallback((params) => {
        const filterInstance = gridRef.current.api.getFilterInstance(
            params.columns[0].colId
        );
        if (filterInstance.appliedModel?.type === 'any') {
            gridRef.current.api.destroyFilter(params.columns[0].colId);
        }
    });

    const onPaginationChange = useCallback((pageSize) => {
        setSortingParams((initVals) => {
            return { ...initVals, limit: pageSize };
        });
        gridRef.current.api.gridOptionsWrapper.setProperty(
            'cacheBlockSize',
            pageSize
        );
        gridRef.current.api.infiniteRowModel.resetCache();
        gridRef.current.api.paginationSetPageSize(pageSize);
    });

    const customLoadingCellRenderer = useMemo(() => {
        return LoadingCellRenderer;
    }, []);
    const loadingCellRendererParams = useMemo(() => {
        return {
            loadingMessage: 'Loading....',
        };
    }, []);


    useEffect(() => {
        if (gridApi) {
            if (Object.keys(sortingParams.filter).length) {
                gridApi.setFilterModel(sortingParams.filter);
            }
            const dataSource = {
                getRows: async (params) => {
                    const page = gridApi.paginationGetCurrentPage() + 1;
                    const limit = gridApi.paginationGetPageSize();

                    const sortModel = params.sortModel.length
                        ? params.sortModel[0]
                        : null;
                    const filterModel = params.filterModel ? params.filterModel : {};
                    const customParams = {
                        ...sortingParams,
                        limit,
                        orderBy: sortModel?.colId,
                        ascending: sortModel?.sort,
                        filter: filterModel,
                        page,
                    };

                    const customData = await getShippingCancelations(customParams);
                    console.log(customData, "customdata")
                    if (customData?.data?.status) {
                        params.successCallback(
                            customData.data.response[0].data,
                            customData.data.response[0].metadata[0].totalRecord
                        );
                        customParams.onLoad = false;
                        setSortingParams({ ...customParams });
                        history(location.pathname, { state: customParams });
                    } else {
                        params.successCallback([], 0);
                    }
                },
            };
            gridApi.setDatasource(dataSource);
            gridApi.api?.sizeColumnsToFit();
        }
    }, [gridApi]);

    const onFirstDataRendered = useCallback(() => {
        setTimeout(() => {
            gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
        }, 1000);
    });

    const onPaginationChanged = useCallback((params) => {
        if (gridRef.current.api) {
            const page = gridApi?.paginationGetCurrentPage();
            setSortingParams({ ...sortingParams, page });
        }
    });

    return (
        <div className="animated fadeIn userTableList">
            <Row>
                <Col>
                    <Card>
                        <CardBody>

                            <div className="tabletopsec" style={{ display: "flex", justifyContent: "flex-end" }}>

                                <div className="add-button-div">
                                    <a
                                        href={downloadData}
                                        id="downloadCsv"
                                        download
                                        hidden
                                        className="hide"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    ></a>
                                </div>
                                {user?.isAdmin ||
                                    user?.permission?.includes(
                                        Constants.PERMISSION_NAME.ORDER_MANAGEMENT
                                    ) ? (
                                    <>
                                        <div className="rightpaneltop">
                                            {/*  {Object.keys(sortingParams.filter).length ? <Button onClick={resetButtonClick} className='btn btn-link btn-sm'>Reset Filters</Button> : null} */}
                                            <Button
                                                color="primary"
                                                size="sm"
                                                onClick={handleCSVDownload}
                                            >
                                                <i className="fa fa-download"></i> Export CSV
                                            </Button>
                                        </div>
                                    </>
                                ) : null}
                                <div className="rightpaneltop">
                                    Shipping Cancellations Per Page
                                    <PerPageSelect
                                        perPage={sortingParams.limit}
                                        onPaginationChange={onPaginationChange}
                                    />
                                </div>

                            </div>
                            <div className="card w-100 p-0 border-0">
                                <div className="card-body p-0 position-relative">
                                    <div className="ag-theme-alpine">
                                        <AgGridReact
                                            defaultColDef={{
                                                resizable: true,
                                            }}
                                            loadingCellRenderer={customLoadingCellRenderer}
                                            loadingCellRendererParams={loadingCellRendererParams}
                                            domLayout={'autoHeight'}
                                            rowModelType={'infinite'}
                                            columnDefs={columns}
                                            pagination={true}
                                            sizeColumnsToFit={true}
                                            onFilterChanged={filterChanged}
                                            onGridReady={onGridReady}
                                            paginationPageSize={sortingParams.limit}
                                            cacheBlockSize={sortingParams.limit}
                                            cacheQuickFilter={true}
                                            onFirstDataRendered={onFirstDataRendered}
                                            onPaginationChanged={onPaginationChanged}
                                            ref={gridRef}
                                        ></AgGridReact>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card></Col>
            </Row>
        </div>
    )
};

ShippingCancelations.propTypes = {
    getShippingCancelations: PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
    auth: state.auth,
    loading: state.shippingCancelations.loading
});

export default connect(mapStateToProps, {
    getShippingCancelations

})(ShippingCancelations);