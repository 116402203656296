/* eslint-disable */
import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import { CKEditor } from 'ckeditor4-react';

import {
  editEmailTemplate,
  getEmailTemplateById,
} from 'actions/admin/emailTemplate';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';

import { REMOVE_ERRORS } from 'actions/types';
import { CKEditorConfig } from 'constants';

const EditEmailTemplate = ({
  getEmailTemplateById,
  editEmailTemplate,
  emailTemplate: { currentEmailTemplate, loading },
  history,
  match,
  errorList,
}) => {
  /**************** use state for form data *********************/
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    subject: '',
    slug: '',
    description: '',
    dynamic_parameters: '',
  });

  // to resolve useEffect dependency error

  /**************** use memo to get current email template *********************/
  const {
    sectionId,
    emailTemplateCategory_id: emailTemplateCategory_id,
    emailTemplate_id: emailTemplate_id,
  } = useParams();
  useMemo(() => {
    getEmailTemplateById(emailTemplate_id);
  }, [emailTemplate_id]);

  /**************** use memo to set form data from current email template *********************/
  useMemo(() => {
    if (Object.keys(currentEmailTemplate).length > 0) {
      setFormData({
        subject: currentEmailTemplate.subject
          ? currentEmailTemplate.subject
          : '',
        slug: currentEmailTemplate.slug ? currentEmailTemplate.slug : '',
        dynamic_parameters: currentEmailTemplate.dynamic_parameters
          ? currentEmailTemplate.dynamic_parameters
          : '',
        description: currentEmailTemplate.description
          ? currentEmailTemplate.description
          : '',
      });
    }
  }, [currentEmailTemplate]);

  /**************** expload from data *********************/
  const { subject, slug, description, dynamic_parameters } = formData;

  /**************** use dispatch *********************/
  const dispatch = useDispatch();

  /**************** handle on change event *********************/
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    dispatch({ type: REMOVE_ERRORS });
  };

  const handelDescriptionChange = (event) => {
    setFormData((ev) => ({
      ...ev,
      description: event.editor.getData(),
    }));
    //setFormData({ ...formData, description: event.editor.getData() });
  };

  /**************** handle on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    editEmailTemplate(
      formData,
      navigate,
      emailTemplateCategory_id,
      emailTemplate_id,
      sectionId
    );
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="12">
          <Card>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="slug">
                        Email Template Slug <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="slug"
                        name="slug"
                        maxLength="100"
                        value={slug}
                        onChange={(e) => onChange(e)}
                        readOnly
                        invalid={errorList.slug ? true : false}
                        disabled
                      />
                      <Errors current_key="slug" key="slug" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="subject">
                        Subject <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="subject"
                        name="subject"
                        maxLength="100"
                        value={subject}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.subject ? true : false}
                      />
                      <Errors current_key="subject" key="subject" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="dynamic_parameters">
                        Dynamic Variables
                      </Label>
                      <Input
                        // readOnly
                        type="textarea"
                        id="dynamic_parameters"
                        name="dynamic_parameters"
                        maxLength="500"
                        value={dynamic_parameters}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.dynamic_parameters ? true : false}
                      />
                      <Errors
                        current_key="dynamic_parameters"
                        key="dynamic_parameters"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="description">
                        Body <span>*</span>
                      </Label>
                      <CKEditor
                        initData={description}
                        id="description"
                        name="description"
                        config={CKEditorConfig}
                        onChange={(event) => handelDescriptionChange(event)}
                      />

                      <Errors current_key="description" key="description" />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button
                  type="submit"
                  size="sm"
                  color="primary"
                  disabled={loading}
                >
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>

                <Button
                  type="reset"
                  size="sm"
                  color="danger"
                  disabled={loading}
                  onClick={() => navigate(-1)}
                  style={{ marginLeft: '10px' }}
                >
                  <i className="fa fa-ban"></i> Cancel
                </Button>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditEmailTemplate.propTypes = {
  getEmailTemplateById: PropTypes.func.isRequired,
  editEmailTemplate: PropTypes.func.isRequired,
  emailTemplate: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  emailTemplate: state.emailTemplate,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  getEmailTemplateById,
  editEmailTemplate,
})(EditEmailTemplate);
