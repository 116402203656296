import { Card, CardBody, CardHeader, Row, Col, Input, Label } from 'reactstrap';
const OrderType = ({ Ordertype, isDisabled, handleOrderType }) => {
    return (
        <Card>
            <CardHeader>
                <h6>{"Order Type"}</h6>
            </CardHeader>
            <CardBody>
                <Row >
                </Row>
                <Row >
                    <Col sm="12" md="6" lg="4">
                        <Input
                            type="radio"
                            name="order_category"
                            id="normal_order"
                            value={"normal_order"}
                            checked={Ordertype === "normal_order" ? true : false}
                            disabled={isDisabled}
                            onChange={handleOrderType}
                        />
                        <Label for="normal_order" className="label-tag ms-2">{"Normal Order"}</Label>

                    </Col>
                    <Col sm="12" md="6" lg="4">
                        <Input
                            type="radio"
                            name="order_category"
                            id="warranty_order"
                            value={"warranty_order"}
                            disabled={isDisabled}
                            checked={Ordertype === "warranty_order" ? true : false}
                            onChange={handleOrderType}
                        />
                        <Label for="warranty_order" className="label-tag ms-2">{"Warranty Order"}</Label>
                    </Col>
                    <Col sm="12" md="6" lg="4">
                        <Input
                            type="radio"
                            name="order_category"
                            id="promo_order"
                            value={"promo_order"}
                            disabled={isDisabled}
                            checked={Ordertype === "promo_order" ? true : false}
                            onChange={handleOrderType}
                        />
                        <Label for="promo_order" className="label-tag ms-2">{"Promo Order"}</Label>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}
export default OrderType