import { TAX_RATE_ERROR, TAX_RATE_LOADING, TAX_RATE_UPDATED } from "actions/types";

const initialState = {
    loading: false,
    taxRate: 0,
    taxRateErrors: [],
    isAlert: false,
    message: '',
}

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TAX_RATE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case TAX_RATE_ERROR:
            return {
                ...state,
                loading: false,
                taxRateErrors: payload.errorList,
                isAlert: true,
                message: payload.message
            }
        case TAX_RATE_UPDATED:
            return {
                ...state,
                loading: false,
                isAlert: payload.isAlert,
                message: payload.message,
                taxRate: Number(payload.data.value ?? 0)
            }
        default:
            return state;

    }

}