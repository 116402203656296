import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { CKEditor } from 'ckeditor4-react';

import { create } from 'actions/admin/smsTemplate';

import Errors from 'views/Notifications/Errors';
import Spinner from 'views/Spinner';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { REMOVE_ERRORS } from 'actions/types';
import { CKEditorConfig } from 'constants';

const CreateSmsTemplate = ({ create, history, errorList, loading }) => {
  /**************** use state for form data *********************/
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    // subject: '',
    slug: '',
    description: '',
    dynamic_parameters: '',
  });

  /**************** expload form data *********************/
  const { slug, description, dynamic_parameters } = formData;

  /**************** dispatch to remove error *********************/


  /**************** on Change event *********************/
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

  };

  const handelDescriptionChange = (event) => {
    setFormData((ev) => ({
      ...ev,
      description: event.editor.getData(),
    }));

    //setFormData({ ...formData, description: event.editor.getData() });

  };

  /**************** on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    create(formData, navigate);
  };

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="slug">
                          SMS Template Slug <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="slug"
                          name="slug"
                          maxLength="100"
                          value={slug}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.slug ? true : false}
                        />
                        <Errors current_key="slug" key="slug" />
                      </FormGroup>
                    </Col>
                    {/* <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="subject">
                          Subject <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="subject"
                          name="subject"
                          maxLength="100"
                          value={subject}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.subject ? true : false}
                        />
                        <Errors current_key="subject" key="subject" />
                      </FormGroup>
                    </Col> */}
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="dynamic_parameters">
                          Dynamic Variables
                        </Label>
                        <Input
                          type="textarea"
                          id="dynamic_parameters"
                          name="dynamic_parameters"
                          maxLength="500"
                          value={dynamic_parameters}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.dynamic_parameters ? true : false}
                        />
                        <Errors
                          current_key="dynamic_parameters"
                          key="dynamic_parameters"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="description">
                          Body <span>*</span>
                        </Label>
                        <CKEditor
                          data={description}
                          id="description"
                          name="description"
                          config={CKEditorConfig}
                          onChange={(event) => handelDescriptionChange(event)}
                        />
                        <Errors current_key="description" key="description" />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary" disabled={loading}>
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Link to="/admin/sms-templates">
                    <Button type="reset" size="sm" color="danger" disabled={loading}>
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

CreateSmsTemplate.propTypes = {
  create: PropTypes.func.isRequired,
  smsTemplate: PropTypes.object,
  errorList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  smsTemplate: state.smsTemplate,
  errorList: state.errors,
  loading: state.smsTemplate.loading,
});

export default connect(mapStateToProps, { create })(CreateSmsTemplate);
