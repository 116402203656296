/* eslint-disable */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';


//   PaginationProvider,
//   SizePerPageDropdownStandalone,
// } from 'react-bootstrap-table2-paginator';



import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import Moment from 'react-moment';

import {
  getSmsTemplateList,
  deleteSmsTemplate,
  changeStatus,
} from 'actions/admin/smsTemplate';

import * as Constants from 'constants/index';

import { Button, Card, CardBody, Col, Row, Input } from 'reactstrap';

import Spinner from 'views/Spinner';
import CustomFilters from './CustomFilters';
import PerPageSelect from "../PerPageSelect";

const actions = (
  <div className="add-button-div">
    <Link to="/admin/sms-templates/create" className="addNewElementClass">
      <Button color="primary" size="sm">
        <i className="fa fa-plus"></i> Create Sms Template
      </Button>
    </Link>
  </div>
);

const SmsTemplateList = ({
  getSmsTemplateList,
  deleteSmsTemplate,
  history,
  smsTemplateList: { data, count },
  changeStatus,
  loading,
  subLoading,
}) => {
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState({
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: 'created_at',
    ascending: 'desc',
    query: '',
  });

  // to resolve useEffect dependency error

  //################## to get updated list ###################
  useEffect(() => {

    getSmsTemplateList(sortingParams);
  }, [sortingParams]);


  //#########################Colums defination start ###################
  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      // filter: 'agTextColumnFilter',
      // // pass in additional parameters to the text filter
      // filterParams: {
      //   buttons: ['reset', 'apply'],
      //   debounceMs: 200,
      // },
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agDateColumnFilter',
      // pass in additional parameters to the text filter
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        debounceMs: 0,
        // inRangeFloatingFilterDateFormat: 'MM/D/YYYY',
        filterOptions: ['equals', 'inRange'],
        suppressAndOrCondition: true,
        inRangeInclusive: true,
        browserDatePicker: true,
        // provide comparator function
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          if (!cellValue) {
            return 0;
          }

          // In the example application, dates are stored as unix timestamp
          // We create a Date object for comparison against the filter date
          const date = new Date(cellValue);
          const year = date.getFullYear();
          const month = date.getMonth();
          const day = date.getDate();
          const cellDate = new Date(year, month, day);

          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
        cellRenderer: (params) => {
          return <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
            {params.data.created_at}
          </Moment>
        },
        headerStyle: {
          backgroundColor: Constants.TABLE_BORDER_COLOR,
        },
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      filterParams: {
        buttons: ['apply', 'reset'],
        debounceMs: 200,
        closeOnApply: true,
        filterOptions: ['equals'],
        suppressAndOrCondition: true,
        suppressFilterButton: true,
        trimInput: true,
        textMatcher: ({ value, filterText }) => {
          const filterTextLowerCase = filterText.toLowerCase();
          let filterTextNumberFormat;
          if (filterTextLowerCase.localeCompare('inactive') === 0) {
            filterTextNumberFormat = 0;
          }
          else if (filterTextLowerCase.localeCompare('active') === 0) {
            filterTextNumberFormat = 1;
          }
          return parseInt(value) === filterTextNumberFormat ? true : false;
        }
      },

      cellRenderer: (params) => {
        return (
          <div>
            <Input
              type="select"
              name="status"
              id={params._id}
              defaultValue={params.data.status}
              onChange={(e, a) => {
                changeStatus(params.data._id, e.target.value);
              }}
            >
              <option value="0">Inactive</option>
              <option value="1">Active</option>
            </Input>
          </div>
        );
      },
    },
    {
      field: '_id',
      headerName: 'Actions',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) => (
        <div>
          <Link to={`/admin/sms-templates/${params.data._id}`}>
            <Button type="button" size="sm" color="success">
              <i className="fa fa-pencil"></i>
            </Button>
          </Link>
          <Button
            type="button"
            size="sm"
            color="danger"
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure, you want to delete ${params.data.title} sms template?`
                )
              ) {
                deleteSmsTemplate(params.data._id, history).then((res) => {
                  if (res) getSmsTemplateList(sortingParams);
                });
              }
            }}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
  ];

  //#########################Colums defination end ###################

  //################## START Set properties(options) of datatables ###################



  const defaultSorted = [
    {
      field: 'created_at',
      order: 'desc',
    },
  ];





  const gridRef = useRef();


  //   setGridApi(params);
  //   // fetch()
  //   //   .then((resp) => resp.json())
  //   //   .then((resp) => {
  //   //     params.api.applyTransaction({ add: resp });
  //   //     params.api.paginationGoToPage(1);
  //   //   });
  // };
  const onSearchBoxTextChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById('search-text-box').value
    );
  }, []);

  const onPaginationChange = (pageSize) => {
    gridApi.api.paginationSetPageSize(pageSize);
  };

  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                {actions}
                <div className="rightpaneltop">
                  <Input
                    type="text"
                    id="search-text-box"
                    placeholder="Search title"
                    onInput={onSearchBoxTextChanged}
                  />
                </div>
                <div className="rightpaneltop">
                  Template Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>
              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      columnDefs={columns}
                      domLayout={'autoHeight'}
                      rowData={data}
                      pagination={true}
                      // floatingFilter={true}
                      // onGridReady={onGridReady}
                      paginationPageSize={20}
                      cacheQuickFilter={true}
                      ref={gridRef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

SmsTemplateList.propTypes = {
  getSmsTemplateList: PropTypes.func.isRequired,
  deleteSmsTemplate: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  smsTemplateList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  smsTemplateList: state.smsTemplate.smsTemplateList,
  loading: state.smsTemplate.loading,
  subLoading: state.smsTemplate.isSubLoading,
});

export default connect(mapStateToProps, {
  getSmsTemplateList,
  deleteSmsTemplate,
  changeStatus,
})(SmsTemplateList);
