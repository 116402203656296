import { createBrowserHistory } from 'history';

const onRequest = (config) => {
    //startLoading(true)
    return config
}

const onRequestError = (error) => {
    //startLoading(false)
    return Promise.reject(error)
}

const onResponse = (response) => {
    //startLoading(false)
    return response
}

const onResponseError = (error) => {
    if (error.response?.status === 401) {
        const history = createBrowserHistory();
        localStorage.removeItem("token")
        history.push("/")
        window.location.reload();
    }
    return Promise.reject(error)
}

export function setupInterceptorsTo(axiosInstance) {

    axiosInstance.interceptors.request.use(onRequest, onRequestError)
    axiosInstance.interceptors.response.use(onResponse, onResponseError)
    return axiosInstance
}
