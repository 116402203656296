import axios from "axios";

import {
  SOCIAL_SETTINGS_LOADING,
  SOCIAL_SETTING_UPDATED,
  SOCIAL_SETTING_UPDATE_ERROR,
  SOCIAL_SETTINGS_FAIL,
  GET_SOCIAL_SETTING,
  GET_SOCIAL_SETTING_ERROR,
  SOCIAL_SETTING_CHECKBOX_ERROR,
} from "actions/types";

import { SOCIAL_SETTINGS_KEY } from "constants/index";

// Edit Setting
export const edit = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: SOCIAL_SETTINGS_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(`/api/admin/social-setting`, formData, config);
    if (res.data.status === true) {
      let settings = await res.data.response;
      let key_value = await [];
      await SOCIAL_SETTINGS_KEY.map(async (set_key) => {
        key_value[set_key] = await {
          value: "",
          is_to_be_displayed: false,
        };
      });
      await settings.forEach((val) => {
        key_value[val.key].value = val.value;
        key_value[val.key].is_to_be_displayed = val.is_to_be_displayed;
      });
      dispatch({
        type: SOCIAL_SETTING_UPDATED,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: key_value,
        },
      });
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: SOCIAL_SETTING_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          errorsList,
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: SOCIAL_SETTINGS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
    return false;
  }
};

// Get Setting
export const getSetting = () => async (dispatch) => {
  try {
    dispatch({
      type: SOCIAL_SETTINGS_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/admin/social-setting/`, config);
    if (res.data.status === true) {
      let settings = await res.data.response;
      let key_value = await [];
      await SOCIAL_SETTINGS_KEY.map(async (set_key) => {
        key_value[set_key] = await {
          value: "",
          is_to_be_displayed: false,
        };
      });
      await settings.forEach((val) => {
        key_value[val.key].value = val.value;
        key_value[val.key].is_to_be_displayed = val.is_to_be_displayed;
      });
      dispatch({
        type: GET_SOCIAL_SETTING,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: key_value,
        },
      });

      return true;
    } else {
      dispatch({
        type: GET_SOCIAL_SETTING_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
        },
      });
    }
  } catch (err) {
    dispatch({
      type: SOCIAL_SETTINGS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
    return false;
  }
};


export const setCheckboxError = (errors) => async (dispatch) => {
  let errorsList = [];
  if (errors) {
    await errors.forEach((error) => {
      errorsList[error.param] = error.msg;
    });
  }
  dispatch({
    type: SOCIAL_SETTING_CHECKBOX_ERROR,
    payload: {
      message: "Errors! Please correct the following errors and submit again.",
      alertType: "danger",
      errorsList,
    },
  });
};
