import {
  CATEGORY_MODAL_LOADING,
  CATEGORY_CREATED,
  CATEGORY_CREATE_ERROR,
  CATEGORY_FAIL,
  CATEGORY_LOADING,
  CATEGORY_UPDATED,
  CATEGORY_UPDATE_ERROR,
  CATEGORY_DELETED,
  CATEGORY_DELETE_ERROR,
  CATEGORY_LIST_UPDATED,
  GET_CATEGORY_BY_ID,
  CATEGORY_CHANGE_STATUS,
  CATEGORY_CHANGE_STATUS_ERROR,
} from "actions/types";

const initalState = {
  categoryList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentCategory: {},
  loading: false,
  isSubLoading: false,
  modal_loading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case CATEGORY_MODAL_LOADING:
      return {
        ...state,
        modal_loading: true,
      };
    case CATEGORY_CREATED:
      state.categoryList.data.pop();
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          data: [payload.data, ...state.categoryList.data],
        },
        modal_loading: false,
      };
    case CATEGORY_CREATE_ERROR:
      return {
        ...state,
        modal_loading: false,
      };
    case CATEGORY_FAIL:
      return {
        ...state,
        modal_loading: false,
        loading: false,
        isSubLoading:false
      };
    case CATEGORY_LOADING:
      return {
        ...state,
        categoryList: payload.is_sub_element
          ? state.categoryList
          : initalState.categoryList,
        currentCategory: payload.is_sub_element
          ? state.currentCategory
          : initalState.currentCategory,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case CATEGORY_UPDATED:
      return {
        ...state,
        currentCategory: {},
        loading: false,
      };
    case CATEGORY_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CATEGORY_DELETED:
      return {
        ...state,
        loading: false,
      };
    case CATEGORY_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CATEGORY_LIST_UPDATED:
      return {
        ...state,
        categoryList: {
          data: payload.data.categoryList.data,
          page: payload.data.categoryList.metadata[0].current_page,
          count: payload.data.categoryList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_CATEGORY_BY_ID:
      return {
        ...state,
        currentCategory: payload.data,
        loading: false,
      };
    case CATEGORY_CHANGE_STATUS:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          data: state.categoryList.data.map((category) =>
            category._id === payload.data._id
              ? { ...category, status: payload.data.status }
              : category
          ),
        },
        loading: false,
        isSubLoading: false
      };
    case CATEGORY_CHANGE_STATUS_ERROR:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          data: state.categoryList.data.map((category) =>
            category._id === payload.data
              ? { ...category, is_failed: true }
              : { ...category, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false
      };

    default:
      return state;
  }
}
