/* eslint-disable */
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';

import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ORDER_LIST_PAYMENT_STATUS,
  ORDER_CREATE_FOR_USER_ROLE,
  USER_ROLE,
  SHIPPING_CATEGORY,
} from 'constants/index';
import Select from 'react-select';
import Moment from 'react-moment';
import {
  selectedVariations,
  getOrderById,
  getUsersList,
  applyCoupon,
  calculateShippingRates,
  getUserAddressList,
  addToCart,
  updateCart,
  getCartDetails,
  addToOrder,
  removeCoupon,
  getProductsList,
  getBuildSupplyServiceList,
  getTaxRates,
  addToCartOrderData,
  getProductAttributes,
  getMergeShippingOrder,
  mergeShippingAddtionalOrder,
  deleteMergeShippingOrder
} from 'actions/admin/order';
import './Order.css';
import CountryList from './Components/CountriesList';
import BillingDetail from 'views/Admin/Orders/Components/BillingDetail';
import ShippingRates from './Components/ShippingRates';
import SelectVariations from './SelectVariations';
import Spinner from 'views/Spinner';
import SelectVariation from './SelectVariation';
import CalculationDetail from './Components/CalculationDetail';
import { parcelsError, updateBuildSupply } from './Components/helper';
import PreviewForm from './Components/PreviewForm';
import CartDetails from './Components/CartDetails';
import { removeAlert } from 'actions/alert';
import { convertCarrierTypes, convertServiceTypes } from 'actions/commonActions';
import { amountDue, calculateGrandTotalAmount, productTotal } from './Components/OrderCalculation';
import BuildShopSupplyModel from './Components/BuildShopSupplyModel';
import BuildShopSupplyCart from './Components/BuildShopSupplyCart';
import OrderType from './Components/OrderTypes';
import ServiceModel from './Components/ServiceModel';
import ServiceCart from './Components/ServiceCart';

let cartDetailParams = {
  currentCart: [],
  addedProduct: [],
  cartId: 0,
  total: 0,
  productTotalAmount: 0,
  fittingTotalAmount: 0,
  cartTotal: 0,
};

let couponDetailFields = {
  couponDiscount: 0,
  total: 0,
  couponShippingDiscount: 0,
  isApplied: false,
  couponDetails: { coupon_code: '', coupon_id: '' },
};

let preLoadFields = {
  selectedBillingAddress: null,
  selectedShippingAddress: null,
  selectedShippingLabel: null,
  selectedBillingLabel: null,
  shippToDifferentAddressChecked: false,
  isGetShippingRates: false,
  editAddress: false,
  isEdit: false,
  shippingService: false,
  isCartUpdated: false,
  callGetCartApi: false,
  quantity: 1,
  orderId: "",
  shippingAddressData: null,
  billingAddressData: null,
  refundableAmount: 0,
  refundedAmount: 0,
  productVariatonDetails: {},
  order_category: "normal_order",
  buildShopSupply: [],
  service: [],
  addtional_quantity: 1,
  buildShopSupplyProduct: {},
  openBuildShopModel: false,
  buildShopSupplyDetail: [],

  service_quantity: 1,
  serviceProduct: {},
  serviceModel: false,
  serviceDetail: [],
  serviceTotalPrice: 0,

  mergeAddtionalList: [],
  shippingMergeId: "",
  wp_product_price: 0,
  wp_price: [],
  payment_mode: "",
  payment_comment: "",
  shippingServiceCategory: 1,
  comment: "",
  userType: 2,
  updateAddressEmailAndPhone: null,
  selectedOrderAddress: null,
};

const CreateUpdateOrder = ({
  selectedVariations,
  getOrderById,
  currentOrder,
  cartDetail,
  variationData,
  addToCart,
  getCartDetails,
  updateCart,
  getUsersList,
  applyCoupon,
  productsList,
  getBuildShopService,
  getProductsList,
  getBuildSupplyServiceList,
  usersList,
  user_list_loading,
  productListLoading,
  loading,
  getUserAddressList,
  calculateShippingRates,
  addToOrder,
  removeCoupon,
  addToCartOrderData,
  getProductAttributes,
  shippingRatesLoading,
  getMergeShippingOrder,
  mergeShippingAddtionalOrder,
  mergeShippingData,
  deleteMergeShippingOrder
}) => {
  const dispatch = useDispatch();
  let productListData = [];
  let buildShopSupplies = [];
  let services = [];
  const { order_id } = useParams();
  const navigate = useNavigate();
  //########## set userId not null if redirected from  customer/dealer list #############
  const location = useLocation();
  const { state } = location;

  const [userId, setUserId] = useState(() =>
    state !== null ? state.userId : ''
  );

  //set userType (customer/dealer) default-customer, if redirected - customer/dealer
  const [userType, setUserType] = useState(() =>
    state !== null ? state.userType : '2'
  );

  //useState
  const [customerName, setCustomerName] = useState('');
  const [addedProduct, setAddedProduct] = useState([]);
  const [productDetail, setProductDetail] = useState(cartDetailParams);
  const [couponDetail, setCouponDetail] = useState(couponDetailFields);
  const [productListValue, setProductListValue] = useState();
  const [cartId, setCartId] = useState(0);
  const [tempCart, setTempCart] = useState([]);

  const [quantity, setQuantity] = useState(1);
  const [customization, setCustomization] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [selectedFitting, setSelectedFitting] = useState([]);
  const [selectedFittingsData, setSelectedFittingsData] = useState();

  const [variation, setVariation] = useState({});
  const [variationModal, setOpenVariationModal] = useState(false);
  const [productVariatonDetails, setProductVariationDetails] = useState({});
  const [variationSelectErrorMsg, setVariationSelectErrorMsg] = useState('');
  const [notifyEmails, setNotifyEmails] = useState([]);
  const [inputEmail, setInputEmail] = useState("");
  const [error, setError] = useState(null);
  const [shippToDifferentAddressChecked, setShippToDifferentAddressChecked] =
    useState(false);

  const [stockQuantity, setStockQuantity] = useState(0);

  //const [isCartUpdated, setIsCartUpdated] = useState(false);

  const [is_submit, setIsSubmit] = useState(false);

  //set select user name value
  const [userValue, setUserValue] = useState([]);

  //usestate for billing address
  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
  //const [selectedBillingLabel, setSelectedBillingLabel] = useState(null);

  // usestate for shipping address
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);
  //const [selectedShippingLabel, setSelectedShippingLabel] = useState(null);

  const [isGetShippingRates, setIsGetShippingRates] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [shippingService, setShippingService] = useState(false);

  const [deliveryInstruction, setDeliveryInstruction] = useState('');

  const [selectedShippingMode, setSelectedShippingMode] = useState({});
  const [shippingPrice, setShippingPrice] = useState(0);
  const [taxData, setTaxData] = useState({});
  const [isMerge, setIsMerge] = useState(false);
  const [easyPostOrderId, setEasyPostOrderId] = useState('');
  const [shippingBox, setShippingBox] = useState([]);
  const [shippingRates, setShippingRates] = useState([]);
  const [taxApplied, setTaxApplied] = useState(0);
  const [totalBeforeTax, setTotalBeforeTax] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [groupedRatesByProvider, setGroupedRatesByProvider] = useState({});
  const [shippingMessages, setShippingMessages] = useState([]);
  const [payment_status, setPaymentStatus] = useState(1);
  const [paidAmount, setPaidAmount] = useState('');
  const [dueAmount, setDueAmount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [couponDiscount, setDiscountAmount] = useState(0);
  const [couponCode, setcouponCode] = useState('');
  const [isApplied, setIsApplied] = useState(false);
  const [showAddEditPreviewForm, setShowAddEditPreviewForm] = useState(true);
  const [callApplyCoupon, setCallApplyCoupon] = useState(true);
  /*  const [couponDetails, setCouponDetails] = useState({
         coupon_code: '',
         coupon_id: '',
     }); */
  const [shippingModeError, setShippingModeError] = useState(
    'Enter your full address to see shipping costs.'
  );
  const [carrierAccountError, setCarrierAccountError] = useState("");
  const [carrierZipcodeError, setCarrierZipcodeError] = useState("");

  const initialCustomerCarrierDetails = {
    customer_carrier_type: "fedex",
    customer_carrier_account: "",
    customer_carrier_country: { value: "US", label: "United States" },
    customer_carrier_country_code: "US",
    customer_carrier_zipcode: ""
  }

  const [inputCustomerCarrierDetails, setInputCustomerCarrierDetails] = useState(initialCustomerCarrierDetails);

  const [finalCustomerCarrierDetails, setFinalCustomerCarrierDetails] = useState({
    customer_carrier_type: "",
    customer_carrier_account: "",
    customer_carrier_country_code: "",
    customer_carrier_zipcode: ""
  })

  const handleCountryChange = selectedOption => {
    setInputCustomerCarrierDetails({ ...inputCustomerCarrierDetails, customer_carrier_country: selectedOption, customer_carrier_country_code: selectedOption?.value });
  };

  const onChangeCustomerCarrierDetails = (e) => {
    if (e.target.name === "customer_carrier_type") {
      setSelectedShippingMode({})
      setShippingPrice(0)
      setInputCustomerCarrierDetails({ ...inputCustomerCarrierDetails, [e.target.name]: e.target.value, customer_carrier_account: "", customer_carrier_zipcode: "" });
    } else {
      setInputCustomerCarrierDetails({ ...inputCustomerCarrierDetails, [e.target.name]: e.target.value });
    }
  }

  const onSubmitCustomerCarrierDetails = () => {
    setCarrierZipcodeError("");
    setCarrierAccountError("");
    if (inputCustomerCarrierDetails.customer_carrier_account === "") {
      setCarrierAccountError("Customer account number required.");
      return;
    }
    if (inputCustomerCarrierDetails.customer_carrier_zipcode === "") {
      setCarrierZipcodeError("Customer zipcode required.");
      return;
    }
    setFinalCustomerCarrierDetails({ ...inputCustomerCarrierDetails });
  }

  let productQuantity = 1;

  const groupRatesByProvider = (rate, key) => {
    return rate.reduce((reduceRate, reduceKey) => {
      (reduceRate[reduceKey[key]] = reduceRate[reduceKey[key]] ?? []).push(
        reduceKey
      );
      return reduceRate;
    }, {});
  };

  const isInList = (email) => {
    return notifyEmails.includes(email);
  };

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setError(error);

      return false;
    }

    return true;
  };

  const onKeyDown = (e) => {
    if (['Enter', 'Tab', ','].includes(e.key)) {
      e.preventDefault();

      let value = inputEmail.trim();

      if (value && isValid(value)) {
        addEmail([value]);
      }
    }
  };

  const onEmailChange = (e) => {
    setInputEmail(e.target.value);
    if (e.target.value === "") {
      setError(null);
    }
  };

  const onDelete = (currentEmail) => {
    setNotifyEmails(notifyEmails.filter(
      (email) => email !== currentEmail
    ))
  };

  const addEmail = (email) => {
    setNotifyEmails([...notifyEmails, ...email]);
    setInputEmail("");
    setError(null);
  }

  const onPaste = (e) => {
    e.preventDefault();

    let paste = e.clipboardData.getData('text');
    let emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      let toBeAdded = emails.filter((email) => !isInList(email));

      addEmail([...toBeAdded]);
    }
  };

  const clearEmails = () => {
    setNotifyEmails([]);
    setInputEmail("");
    setError(null);
  };

  useEffect(() => {
    setUserId('');
    variationData = {
      ...variationData,
      ...preLoadFields,
      orderId: order_id,
      buildShopSupplyDetail: [],
      serviceDetail: [],
      selectedShippingAddress: null
    };
    selectedVariations(variationData);
    setProductDetail(cartDetailParams);
    setSelectedShippingMode({});
    setCouponDetail(couponDetailFields);
    setTempCart([]);
    setcouponCode("");
    setGroupedRatesByProvider({});
    setShippingRates([]);
    setTaxData({});
    getUsersList(userType)
  }, [order_id]);

  useEffect(async () => {
    let tax = 0.0;
    if (taxData) {
      if (taxData?.applyTaxRate) {
        const taxRate = Number(taxData.tax.value);
        const totalAmount = Number((Number(productDetail.fittingTotalAmount ?? 0) + Number(productDetail.productTotalAmount ?? 0)).toFixed(2));
        let amount = 0.0;
        if (totalAmount >= Number(couponDetail.couponDiscount)) {
          amount =
            Number(productDetail.fittingTotalAmount) +
            Number(productDetail.productTotalAmount) -
            Number(couponDetail.couponDiscount);
        } else {
          amount =
            Number(productDetail.fittingTotalAmount) +
            Number(productDetail.productTotalAmount);
        }
        tax = (Number(amount) * taxRate) / 100;
      }
    }
    if (order_id !== undefined) {
      if (variationData.isEdit == false) {
        const updateOrderAddress = {
          ccode: currentOrder?.shipping_address?.ccode,
          contact_no: currentOrder?.shipping_address?.contact_no,
          email: currentOrder?.shipping_address?.email
        }
        variationData = {
          ...variationData,
          selectedShippingAddress: currentOrder?.shipping_address?.add_id,
          selectedBillingAddress: currentOrder?.billing_address?.add_id,
          shippToDifferentAddressChecked: currentOrder?.shipToDifferent,
          selectedOrderAddress: currentOrder?.shipping_address,
          updateAddressEmailAndPhone: (variationData.userType == 3) ? updateOrderAddress : null,
        };
        setSelectedShippingMode(currentOrder?.shipping_mode);

        tax = currentOrder?.tax_total;
        setTaxTotal(tax);
        setPaymentStatus(Number(currentOrder?.payment_status));
        setPaidAmount(Number(currentOrder?.paid_amount?.$numberDecimal ?? 0));
        setShippingPrice(currentOrder?.shipping_mode?.totalCharges);

        //dispatchEvent
        selectedVariations(variationData);
      } else {
        setDueAmount(
          amountDue(currentOrder?.grand_total?.$numberDecimal, currentOrder?.paid_amount?.$numberDecimal, currentOrder?.refunded_amount)
        );

        variationData = { ...variationData, shippingService: true, refundableAmount: 0 };
        tax = tax;
        setTaxTotal(tax);
        selectedVariations(variationData);
      }
    } else {
      setTaxTotal(tax);
    }

    let grandTotalAmount = 0;
    if ((Number(shippingPrice ?? 0) > 0) || ([2, 3, 4].includes(variationData.shippingServiceCategory))) {
      if (currentOrder !== undefined) {
        grandTotalAmount = Number(await calculateGrandTotalAmount(productDetail, shippingPrice, tax, couponDetail))
      }
      await manageDueAndRefund(currentOrder, grandTotalAmount)
    }
    if (variationData?.serviceTotalPrice !== undefined) {
      grandTotalAmount += variationData?.serviceTotalPrice ?? 0
    }
    setGrandTotal(grandTotalAmount);
  }, [
    productDetail.cartTotal,
    couponDetail.couponDiscount,
    productQuantity,
    shippingPrice,
    productDetail.addedProduct,
    couponDetail.couponDetails,
    variationData.isCartUpdated,
    isMerge
  ]);

  const manageDueAndRefund = (currentOrder, grandTotalAmount) => {
    let paid_amount = 0;
    let refund_amount = 0;
    let totalAmount = Number(variationData?.serviceTotalPrice) ?? 0;
    if (currentOrder !== undefined) {
      paid_amount = Number(currentOrder?.paid_amount?.$numberDecimal ?? 0);
      refund_amount = Number(currentOrder?.refunded_amount ?? 0)
      totalAmount = totalAmount + Number(grandTotalAmount + refund_amount ?? 0)

      variationData = {
        ...variationData,
        refundedAmount: Number(refund_amount),
      };

    }
    if (
      (totalAmount > 0 && paid_amount > 0) &&
      (totalAmount < paid_amount)
    ) {
      const refund = (paid_amount - totalAmount);
      variationData = {
        ...variationData,
        refundableAmount: Number(refund).toFixed(2),
      };
      selectedVariations(variationData);
      setDueAmount(0);
    }
    if ((totalAmount > 0 && paid_amount > 0) && (totalAmount > paid_amount)) {
      variationData = { ...variationData, refundableAmount: 0 };
      selectedVariations(variationData);
      setDueAmount((totalAmount - paid_amount).toFixed(2))
    }
  }

  //###################### on payment status change ####################
  const handleOnChange = (paymentStatus, prevOrder) => {
    variationData = { ...variationData, previous_order_status: prevOrder };
    selectedVariations(variationData);
    setPaymentStatus(Number(paymentStatus.value));
  };
  //########################### useEffect to on change of billing address##############################
  useEffect(() => {
    if (variationData.shippingServiceCategory == 2 && !finalCustomerCarrierDetails.customer_carrier_account) {
      return;
    }
    setEasyPostOrderId('');
    setShippingBox([]);
    setShippingModeError('');
    setShippingMessages([]);
    setShippingRates([]);
    setGroupedRatesByProvider({});
    //setSelectedShippingMode({});
    //setShippingPrice(0);

    if (
      variationData.selectedShippingAddress !== undefined &&
      !variationData.selectedShippingAddress !== null
    ) {
      let selectedAddress = /* [3].includes(variationData.shippingServiceCategory) ? null :  */variationData.selectedShippingAddress;
      if (
        variationData.userId !== null &&
        productDetail.addedProduct.length > 0
      ) {

        let buildShopSupply = [];
        if (variationData?.buildShopSupplyDetail?.length > 0) {
          variationData?.buildShopSupplyDetail.map((i) => {
            buildShopSupply.push({ ...i, order_type: "buildShopSupply", categoryType: "SHAFTS" })
          })
        }

        let copyProduct = (productDetail?.addedProduct ?? []).concat(buildShopSupply).map((o) => {
          return {
            ...o,
            price: (o.price > 0) ? o.price : 0.00,
          }
        });

        calculateShippingRates(
          selectedAddress,
          copyProduct,
          variationData.userId,
          variationData.shippingServiceCategory,
          finalCustomerCarrierDetails
        ).then(async (res) => {
          if (res.status) {
            setTaxData(res.taxData);
            setEasyPostOrderId(res.order_id);
            setShippingBox(res.data);
            setShippingRates(res.rate);
            setTaxApplied(res.taxData.applyTaxRate);
            if (res.rate.length)
              setGroupedRatesByProvider(
                groupRatesByProvider(res.rate, 'carrier')
              );
            if (res.message) {
              setShippingMessages(res.message);
            }
            setShippingModeError('');

            if (order_id !== undefined) {
              variationData = {
                ...variationData,
                order_category: currentOrder?.order_category,
                payment_mode: currentOrder?.payment_mode ?? "",
                payment_comment: currentOrder?.payment_comment ?? "",
                shippingServiceCategory: currentOrder?.shippingServiceCategory ?? "",
                shippingMergeId: currentOrder?.orderMergeShippingId ?? "",
                isEdit: true,
                //refundableAmount: 0
              };
              selectedVariations(variationData);
            }
            if (couponDetail.couponDetails.coupon_code /* && !finalCustomerCarrierDetails?.customer_carrier_account */) {
              setCallApplyCoupon(false);
            }
            if ([3, 4].includes(variationData.shippingServiceCategory)) {
              setIsMerge(res.taxData.applyTaxRate)
            }
          } else {
            if (res.message.__all__) {
              setShippingModeError(res.message.__all__[0]);
            } else if (res.message.parcels) {
              let error = parcelsError(res.message);
              setShippingModeError(error ?? 'Parcel Error');
            } else {
              setShippingModeError(res.message);
            }
            if ([3, 4].includes(variationData.shippingServiceCategory)) {
              setIsMerge(false)
            }
            setEasyPostOrderId('');
            setShippingBox([]);
            setShippingRates([]);
            setGroupedRatesByProvider({});
            setShippingMessages([]);
          }
        });
      }
    }
  }, [
    variationData.selectedShippingAddress,
    // variationData.shippingServiceCategory,
    // variationData.selectedShippingLabel,
    productDetail.addedProduct,
    variationData.isCartUpdated,
    productQuantity,
    finalCustomerCarrierDetails,
    variationData.shippingServiceCategory
  ]);

  useEffect(() => {
    if (order_id === undefined) {
      if (state !== null) {
        setUserId(state.userId);
        setUserType(state.userType);
        getUsersList(state.userType);
      }
    }
  }, [state, order_id]);

  //### set userId & userType and get userslist if redirected from customer/dealer ###
  useEffect(async () => {
    if (order_id === undefined) {
      if (userId !== undefined) {
        variationData = {
          ...variationData,
          userId: userId
        }
        selectedVariations(variationData)
      }
    }
    if (userId !== '' && userId !== undefined && order_id === undefined) {

      if ((userId !== '' && !productsList.length) || order_id !== undefined) {


        getProductsList();
      }
      variationData = {
        ...variationData,
        buildShopSupplyDetail: [],
        serviceDetail: [],
      }
      selectedVariations(variationData)
      await mergeAdditionalPro(userId);
      getBuildSupplyServiceList();
      callGetCartApi();
    }
  }, [userId, order_id]);

  const mergeAdditionalPro = async (userId) => {
    const productMergeList = await getMergeShippingOrder(userId);
    if (productMergeList.length > 0) {
      const values = productMergeList.map((i) => {
        return { label: i.order_id, value: i._id }
      })
      variationData = {
        ...variationData,
        mergeAddtionalList: values
      }
      selectedVariations(variationData)
    }
  }

  useEffect(() => {
    setUserValue(
      state !== null && usersList.length > 0
        ? usersList.filter((item, index) => {
          if (item.value == state.userId) return [item[index]];
        })
        : ''
    );
  }, [usersList]);

  useEffect(() => {
    if (userId !== undefined && userId !== '') {
      getUserAddressList(userId);
    }
  }, [userId]);

  const onEditAddress = (e) => {
    e.preventDefault();
    variationData = {
      ...variationData,
      editAddress: !variationData.editAddress,
      shippingService: true,
    };
    selectedVariations(variationData);
    /* setEditAddress(!editAddress);
        setShippingService(true); */
  };

  //################ get selected user's address list ####################
  useEffect(async () => {
    if (currentOrder?.user_id) {
      variationData = {
        ...variationData,
        userId: currentOrder?.user_id,
        comment: currentOrder?.comment
      };
      setCustomerName(currentOrder?.user_name);
      setInputCustomerCarrierDetails(currentOrder?.customer_carrier_details);
      setFinalCustomerCarrierDetails(currentOrder?.customer_carrier_details);
      if (order_id !== undefined) {
        if (currentOrder?.user_type) {
          variationData = {
            ...variationData,
            userType: currentOrder?.user_type[0]
          }
          setUserType(currentOrder?.user_type[0])
        }
        await mergeAdditionalPro(currentOrder?.user_id);
        getProductsList();
        getBuildSupplyServiceList()
      }

      //setAlreadyPaidAmount(currentOrder?.paid_amount['$numberDecimal']);
      getUserAddressList(currentOrder?.user_id).then(async (res) => {
        if (res && res.length > 0) {
          const addresses = await res.filter(
            (address) =>
              address.address_id ===
              currentOrder?.shipping_address.address_id &&
              address.address === currentOrder?.shipping_address.address
          );
          //setShippingAddress(addresses[0]._id);
        }
      });
      selectedVariations(variationData);
    }
  }, [currentOrder?.user_id]);

  const deleteProduct = async (cartId, cart_product_id) => {
    let is_delete = true;
    const productDetails = {
      cart_id: cartId,
      product_id: cart_product_id,
      quantity: productQuantity,
    };
    const orderId = order_id !== undefined ? order_id : variationData.userId;
    const user_type = order_id !== undefined ? userType : undefined;
    const current_userId =
      order_id !== undefined ? variationData.userId : undefined;

    const cart = await addToCart(
      is_delete,
      productDetails,
      orderId,
      variationData.order_category ?? "normal_order",
      cartId,
      cart_product_id,
      user_type,
      current_userId
    );

    if (cart.data.status) {
      callGetCartApi();
    }
    setTaxTotal(taxTotal);
  };

  useEffect(() => {
    if (productsList?.length > 0) {
      let filterProduct = productsList
      if (variationData.order_category !== "normal_order") {
        filterProduct = productsList.filter((product) => product.category === "SHAFTS")
      }
      filterProduct?.map((product, idx) => {
        productListData.push({
          _id: product._id,
          value: product._id,
          label: product.product_title,
          slug: product.slug,
          status: product.status,
          product_title: product.product_title,
        });
      });
      setProductListValue(productListData);
    }
  }, [productsList, variationData.order_category]);

  useEffect(() => {
    if (getBuildShopService?.buildShopSupply?.length > 0) {

      getBuildShopService?.buildShopSupply.map((buildShop, idx) => {
        buildShopSupplies.push({
          _id: buildShop._id,
          name: buildShop.name,
          product_title: buildShop.name,
          value: buildShop._id,
          label: buildShop.name,
          sku: buildShop.sku,
          description: buildShop.description,
          price: buildShop.price,
          stock_quantity: buildShop.stock_quantity,
          isEdit: false,
        })
      });
      variationData = {
        ...variationData,
        buildShopSupply: buildShopSupplies
      };
      selectedVariations(variationData);
    }

    if (getBuildShopService?.services?.length > 0) {
      //services
      getBuildShopService?.services.map((service, idx) => {

        services.push({
          _id: service._id,
          name: service.name,
          product_title: service.name,
          value: service._id,
          label: service.name,
          sku: service.sku,
          description: service.description,
          price: service.price,
          isEdit: false,
        })
      });
      //console.log(services, "yessssssss")
      variationData = {
        ...variationData,
        service: services
      };
      selectedVariations(variationData);
    }
  }, [getBuildShopService])

  //get users list on userType change
  useEffect(() => {
    if (order_id === undefined) {
      switch (userType) {
        case '2':
        case '3':
          variationData = {
            ...variationData,
            ...preLoadFields,
            orderId: order_id,
            userType
          };
          setProductDetail(cartDetailParams);
          setCouponDetail(couponDetailFields);
          selectedVariations(variationData);
          setUserId('');
          getUsersList(userType);

          break;
      }
    }
  }, [userType]);

  //################## on select user-type set userType state #################
  const handleOnSelect = (e) => {
    variationData = {
      ...variationData,
      userType: e.target.value
    };
    selectedVariations(variationData);
    setUserType(e.target.value);
  };

  //########### handel user change to load user data ####################
  const handleUserNameChange = (event) => {
    variationData = {
      ...variationData,
      userId: event.value,
    };

    setUserId(event.value);
    setUserValue([event]);
    setCustomerName(event.label);
    selectedVariations(variationData);
  };

  useEffect(async () => {
    let is_delete = false;
    if (
      productsList.length > 0 &&
      order_id !== undefined &&
      currentOrder?.products
    ) {
      let productAttributes = await getProductAttributes();
      if (productAttributes) {
        const cart = [];
        let tempArray = [];
        currentOrder?.products.map((item) => {
          return Object.keys(item.product_variation).map((iter) => {
            const attributeType =
              item.categoryType[0].toLowerCase() === 'other'
                ? item.categoryType[0].toLowerCase()
                : 'shaft';
            tempArray.push(
              productAttributes.find(
                (i) => i.name === iter && i.attribute_type === attributeType
              )
            );
          });
        });
        currentOrder?.products.map((product) => {
          const attributeTypeName =
            product.categoryType[0].toLowerCase() === 'other'
              ? product.categoryType[0].toLowerCase()
              : 'shaft';
          let attribute_list = {};
          for (const [key, value] of Object.entries(
            product.product_variation
          )) {
            let obj = tempArray.find(
              (i) =>
                i.name === `${key}` && i.attribute_type === attributeTypeName
            );

            let obj1 = obj.terms.find((iter) => iter.option === `${value}`);
            if (obj1 !== undefined) {
              attribute_list[obj._id] = obj1._id;
            }
          }

          const productDetails = {
            fitting_options: product.fitting_options
              ? product.fitting_options
              : [],
            product_id: product.product_id,
            product_slug: product.product_slug,
            product_variation: attribute_list,
            product_variation_sku: product.sku,
            product_variation_id: product.product_variation_id,
            quantity: product.quantity,
          };
          cart.push(productDetails);
        });
        variationData = {
          ...variationData,
          //buildShopSupplyDetail: currentOrder?.buildShopSupplyProduct,
          buildShopSupplyDetail: updateBuildSupply(variationData.buildShopSupply, currentOrder?.buildShopSupplyProduct),
          serviceDetail: currentOrder?.serviceProduct
        };
        selectedVariations(variationData);
        setTempCart(cart);
      }
    }
  }, [productsList]);

  // get the order details
  useEffect(() => {
    if (order_id !== undefined) {
      getOrderById(order_id);
      variationData = {
        ...variationData,
        isOrderUpdate: true,
      };
      selectedVariations(variationData);
    } else {
      variationData = {
        ...variationData,
        isOrderUpdate: false,
      };
      selectedVariations(variationData);
    }
  }, [order_id]);

  useEffect(() => {
    //setDiscountAmount(0);
    if (tempCart.length > 0) {
      addToCartOrderData(
        false,
        variationData.buildShopSupplyDetail,
        variationData.serviceDetail,
        tempCart,
        order_id,
        '',
        '',
        userType,
        currentOrder?.user_id
      ).then((res) => {
        if (res?.data?.status === true) {
          const updateOrderAddress = {
            ccode: currentOrder?.shipping_address?.ccode,
            contact_no: currentOrder?.shipping_address?.contact_no,
            email: currentOrder?.shipping_address?.email
          }
          variationData = {
            ...variationData,
            isEdit: false,
            selectedBillingAddress: currentOrder?.billing_address.add_id,
            selectedBillingLabel: currentOrder?.billing_address.address,
            selectedShippingAddress: currentOrder?.shipping_address.add_id,
            selectedShippingLabel: currentOrder?.shipping_address.address,
            shippToDifferentAddressChecked: currentOrder?.shipToDifferent,
            selectedOrderAddress: currentOrder?.shipping_address,
            updateAddressEmailAndPhone: (variationData.userType == 3) ? updateOrderAddress : null,
            editAddress: false,
            shippingService: false,
          };
          setDeliveryInstruction(currentOrder?.delivery_instruction);
          setPaymentStatus(Number(currentOrder?.payment_status));

          manageDueAndRefund(currentOrder, Number(currentOrder?.grand_total?.$numberDecimal))

          selectedVariations(variationData);
          pushCartDetail(res.data.response);
          //callGetCartApi();
        }
      });
    }
  }, [tempCart]);

  // get cart details
  useEffect(() => {
    const createOrUpdateId = order_id ? order_id : userId;
    if (Object.keys(cartDetail).length > 0 && createOrUpdateId) {
      pushCartDetail(cartDetail);
    } else {
      if (order_id === undefined) {
        variationData = {
          ...variationData,
          wp_price: []
        }
        selectedVariations(variationData);
        setProductDetail({
          ...productDetail,
          currentCart: [],
          addedProduct: [],
          cartId: 0,
          total: 0,
          productTotalAmount: 0,
          fittingTotalAmount: 0,
          cartTotal: 0,
        });
        setCouponDetail({
          ...couponDetail,
          couponDiscount: 0,
          total: 0,
          isApplied: false,
          couponDetails: {
            coupon_code: '',
            coupon_id: '',
          },
        });
        /* variationData = {
          ...variationData,
          buildShopSupply: []
        };
        selectedVariations(variationData); */
      }
    }
  }, [cartDetail]);

  useEffect(() => {
    return () => {

      variationData = {
        ...variationData,
        wp_price: []
      }
      selectedVariations(variationData);
      setProductDetail({
        ...productDetail,
        currentCart: [],
        addedProduct: [],
        cartId: 0,
        total: 0,
        productTotalAmount: 0,
        fittingTotalAmount: 0,
        cartTotal: 0,
      });
      setCouponDetail({
        ...couponDetail,
        couponDiscount: 0,
        total: 0,
        isApplied: false,
        couponDetails: {
          coupon_code: '',
          coupon_id: '',
        },
      });
    }
  }, [])

  //Open Colse Variations Model
  useEffect(() => {
    if (variationData?.selectedProduct?.product_title) {
      setOpenVariationModal(!variationModal);
    }
  }, [variationData?.selectedProduct?.product_title]);

  useEffect(async () => {
    if (variationData.callGetCartApi === true) {
      await callGetCartApi();
      variationData = {
        ...variationData,
        callGetCartApi: false,
        isCartUpdated: true,
      };
      selectedVariations(variationData);
    }
  }, [variationData.callGetCartApi]);

  const manageOrderCategory = useCallback((order_category) => {
    if (order_id !== undefined) {
      if (order_category) {
        return order_category;
      } else {
        return "normal_order"
      }
    } else {
      return variationData.order_category;
    }
  })

  const manageShippingCategory = useCallback((shippingCategory) => {
    if (order_id !== undefined) {
      if (shippingCategory) {
        return shippingCategory;
      } else {
        return 1
      }
    } else {
      return variationData.shippingServiceCategory;
    }
  });

  const pushCartDetail = (cartDetail) => {
    let cardTotal = cartDetail.totalProductAmount

    variationData = {
      ...variationData,
      //buildShopSupplyDetail: cartDetail?.build_shop_supply_products,
      buildShopSupplyDetail: updateBuildSupply(variationData.buildShopSupply, cartDetail?.build_shop_supply_products),
      serviceDetail: cartDetail?.service_products,
      order_category: manageOrderCategory(currentOrder?.order_category),
      shippingMergeId: currentOrder?.orderMergeShippingId ?? "",
      payment_mode: currentOrder?.payment_mode ?? "",
      payment_comment: currentOrder?.payment_comment ?? "",
      shippingServiceCategory: manageShippingCategory(currentOrder?.shippingServiceCategory),
    };


    if (cartDetail?.service_products?.length > 0) {
      let serviceTotalPrice = cartDetail?.service_products?.reduce((accumulator, currentValue) => accumulator + currentValue.total_price, 0);

      variationData = {
        ...variationData,
        serviceTotalPrice: serviceTotalPrice
      }
    }

    selectedVariations(variationData);

    let productItem = cartDetail?.products
    if (variationData.order_category !== "normal_order") {

      productItem = cartDetail?.products.filter((item) => item.categoryType !== "OTHER").map((i) => {
        let newPrice = 0.00;
        if (order_id === undefined) {
          newPrice = variationData?.wp_price.find((v) => v.sku === i.productVariationSku)?.price
        } else {
          const checkWpPrice = variationData.wp_price.find((w) => w.sku === i.productVariationSku)
          if (checkWpPrice === undefined) {
            variationData?.wp_price.push({ sku: i.productVariationSku, price: i.price })
          }
          newPrice = variationData?.wp_price.find((q) => q.sku === i.productVariationSku)?.price;
        }
        return {
          ...i,
          productFitting: [],
          price: newPrice ? newPrice : 0.00,
        }
      })
      cardTotal = productItem.reduce(
        (accumulator, currentValue) => accumulator + (currentValue.price * currentValue.quantity),
        0
      );
    }

    if (cartDetail?.build_shop_supply_products?.length > 0) {
      cardTotal += cartDetail?.build_shop_supply_products?.reduce((accumulator, currentValue) => accumulator + currentValue.total_price, 0)
    }


    setProductDetail({
      ...productDetail,
      currentCart: productItem,
      addedProduct: productItem ? productItem : [],
      cartId: cartDetail._id,
      total: cartDetail.totalCartAmount,
      productTotalAmount: cardTotal,
      fittingTotalAmount: cartDetail.totalFittingAmount,
      cartTotal: cardTotal, // cartDetail.totalCartAmount,
    });
    let coupon_code = '';
    let coupon_id = '';
    let couponShippingDiscount = 0;
    if (currentOrder?.coupon?.coupon_id) {
      coupon_code = currentOrder?.coupon.coupon_code;
      coupon_id = currentOrder?.coupon.coupon_id;
      couponShippingDiscount = currentOrder?.shipping_mode?.shipping_discount;
    }
    if (cartDetail.coupon?.coupon_id) {
      if (cartDetail.coupon?.applicable_for === 'shipping') {
        setCouponDetail({
          ...couponDetail,
          couponDiscount: 0,
          isApplied: true,
          couponDetails: {
            coupon_code: cartDetail.coupon.coupon_code,
            coupon_id: cartDetail.coupon.coupon_id,
          },
        });
      } else {
        setCouponDetail({
          ...couponDetail,
          couponDiscount: cartDetail.coupon.discounted_amount,
          total:
            cartDetail.totalProductAmount - cartDetail.coupon.discounted_amount,
          isApplied: true,
          couponDetails: {
            coupon_code: cartDetail.coupon.coupon_code,
            coupon_id: cartDetail.coupon.coupon_id,
          },
        });
      }
    } else {
      setCouponDetail({
        ...couponDetail,
        couponDiscount: 0,
        couponShippingDiscount: couponShippingDiscount,
        total: productDetail.total,
        isApplied: (couponShippingDiscount === 0) ? false : true,
        couponDetails: { coupon_code: coupon_code, coupon_id: coupon_id },
      });
    }
    if (order_id !== undefined && Object.keys(currentOrder ?? {}).length > 0) {
      setDeliveryInstruction(currentOrder?.delivery_instruction);
      if (
        Object.keys(currentOrder?.discounted_amount).length > 0 &&
        currentOrder?.discounted_amount.$numberDecimal > 0
      ) {
        setCouponDetail({
          ...couponDetail,
          couponDiscount: currentOrder?.discounted_amount.$numberDecimal,
          isApplied: true,
          couponDetails: {
            coupon_code: currentOrder?.coupon.coupon_code,
            coupon_id: currentOrder?.coupon.coupon_id,
          },
        });
      }



      if (currentOrder?.tax_total) {
        setTaxApplied(true);
        setTotalBeforeTax(currentOrder?.total_before_tax);
      }
    }

  };

  const callGetCartApi = async () => {
    const orderId = order_id !== undefined ? order_id : variationData.userId;
    let user_id = order_id !== undefined ? currentOrder?.user_id : '';
    getCartDetails(orderId, user_id);
  };

  const handleSelectProduct = (e) => {
    variationData = {
      ...variationData,
      quantity: 1,
      slug: e.slug,
      customization: false,
      selectedProduct: e,
      isEdit: false,
      selectedFitting: {},
      selectedFittingsData: {},
    };
    selectedVariations(variationData);
  };

  const handleAdditionalProduct = (product) => {

    variationData = {
      ...variationData,
      addtional_quantity: 1,
      buildShopSupplyProduct: product,
      openBuildShopModel: true
    };
    selectedVariations(variationData);
  };

  const handleService = (service) => {
    variationData = {
      ...variationData,
      service_quantity: 1,
      serviceProduct: service,
      serviceModel: true
    };
    selectedVariations(variationData);
  };

  const mergeAddtional = useCallback((mergeValue) => {
    variationData = {
      ...variationData,
      shippingMergeId: mergeValue.value,
    };
    selectedVariations(variationData);
    //mergeShippingAddtionalOrder(mergeValue.value, variationData?.buildShopSupplyDetail, productDetail.currentCart);
  })

  const toggleVariationModal = () => {
    setOpenVariationModal(!variationModal);
    variationData = {
      ...variationData,
      variation: {},
      productVariatonDetails: {},
      selectedFitting: {},
      selectedProduct: {},
      quantity: 1
    };
    selectedVariations(variationData);
  };

  const editProduct = (product) => {
    const setVariationData = {};
    product.productVariationDetail.map((variation) => {
      setVariationData[variation.attribute_id] = variation.term_id;
    });
    const fittingData = {};

    {
      product.productFitting &&
        product?.productFitting?.map((fittingOptions) => {
          if (fittingOptions.attribute_name == 'Adaptors') {
            fittingData['adaptor'] = {
              label: fittingOptions.option_name,
              value: fittingOptions.option_id,
            };
            fittingData['adaptor_option'] = {
              label: fittingOptions.sub_option_name,
              value: fittingOptions.sub_option_id,
            };
          }
          if (fittingOptions.attribute_name == 'Playing Length') {
            fittingData['playing'] = {
              label: (fittingOptions.attribute_value == 0) ? "Uncut" : fittingOptions.attribute_value,
              value: fittingOptions.option_id,
            };
          }
          if (fittingOptions.attribute_name == 'Grips') {
            fittingData['grip'] = {
              label: fittingOptions.option_name,
              value: fittingOptions.option_id,
            };
            fittingData['grip_option'] = {
              label: fittingOptions.sub_option_name,
              value: fittingOptions.sub_option_id,
            };
          }
          if (fittingOptions.attribute_name == 'Extra Wrap') {
            let dropList = { "1": 'None', '2': "1", "3": "2" }
            fittingData['extra_wrap'] = {
              label: dropList[fittingOptions.attribute_value],
              value: fittingOptions.attribute_value,
            };
          }
          if (fittingOptions.attribute_name == 'Grip Logo') {
            let gripLogo = { "N/A": "N/A", "up": "Up", "down": "Down" };
            fittingData['grip_logo'] = {
              label: gripLogo[fittingOptions.attribute_value],
              value: fittingOptions.attribute_value,
            };
          }
          if (fittingOptions.attribute_name == 'Product Customization') {
            fittingData['product_customization'] =
              fittingOptions.attribute_value;
          }
        });
      variationData = {
        ...variationData,
        selectedFittingsData: fittingData,
      };
      //setSelectedFittingsData(fittingData);
    }
    variationData = {
      ...variationData,
      variation: setVariationData,
      slug: product.product_slug,
      selectedProduct: product,
      quantity: product.quantity,
      isEdit: true,
      customization: product.productFitting?.length ? true : false,
    };
    selectedVariations(variationData);
  };

  const resetVariationModal = () => {
    variationData = {
      ...variationData,
      selectedProduct: {},
      variationSelectErrorMsg: '',
    };
    selectedVariations(variationData);
  };

  const onChangeCouponCode = (e) => {
    e.target.value = isNaN(e.target.value) ? e.target.value?.trim()?.replace(/[^a-zA-Z0-9]/g, '') : e.target.value?.trim()?.replace(/[^a-zA-Z0-9.]/g, '');
    setcouponCode(e.target.value?.trim());
  };

  const handleApplyCoupon = (e) => {
    e.preventDefault();
    const orderId = order_id !== undefined ? order_id : null;
    if (!couponCode) {
      return;
    }
    if (couponDetail.isApplied) {
      return false;
    }
    const isReapplyCoupon = false;
    let totalAmountDataWithFitting =
      productDetail.fittingTotalAmount + productDetail.productTotalAmount;
    let totalAmount = totalAmountDataWithFitting;
    if (couponCode && (!isNaN(couponCode) && Number(couponCode) > productTotal(Number(totalAmountDataWithFitting ?? 0), Number(0)))) {
      if (Number((grandTotal).toFixed(2)) >= Number((paidAmount))) {
        totalAmount = Number((Number((grandTotal).toFixed(2)) - Number((paidAmount))).toFixed(2));
      } else {
        totalAmount = Number((Number((paidAmount)) - Number((grandTotal).toFixed(2))).toFixed(2));
      }
    }
    applyCoupon(
      couponCode,
      totalAmount,
      shippingPrice,
      variationData.userId,
      orderId,
      isReapplyCoupon,
      variationData.shippingServiceCategory,
    ).then((res) => {
      console.log('apply coupon');
      if (res.status) {
        let totalAmountData =
          productDetail.fittingTotalAmount + productDetail.productTotalAmount;
        setProductDetail({ ...productDetail, total: totalAmountData });
        if (res.data.applicable_for === 'shipping') {
          let couponShippingDiscount = 0;
          //if (order_id === undefined) {
          couponShippingDiscount =
            res.data.discounted_amount < shippingPrice
              ? res.data.discounted_amount
              : shippingPrice;
          //}
          setCouponDetail({
            ...couponDetail,
            isApplied: true,
            couponShippingDiscount: couponShippingDiscount,
            couponDetails: {
              coupon_code: res.data.coupon_code,
              coupon_id: res.data.coupon_id,
            },
          });
        } else {
          setCouponDetail({
            ...couponDetail,
            isApplied: true,
            couponDetails: {
              coupon_code: res.data.coupon_code,
              coupon_id: res.data.coupon_id,
            },
            couponDiscount: res.data.discounted_amount,
          });
        }
      } else {
        setProductDetail({ ...productDetail, total: productDetail.total });
        setCouponDetail({
          ...couponDetail,
          isApplied: false,
          couponDetails: { coupon_code: '', coupon_id: '' },
          couponDiscount: 0,
        });
      }
    });
  };

  const reApplyCoupon = async (shippingPrice, isReApply = false) => {
    const orderId = order_id !== undefined ? order_id : null;

    if (callApplyCoupon === true && isReApply === true) {
      return false;
    }
    let totalAmountDataWithFitting =
      productDetail.fittingTotalAmount + productDetail.productTotalAmount;
    let totalAmount = totalAmountDataWithFitting;
    if (couponDetail.couponDetails.coupon_code && (!isNaN(couponDetail.couponDetails.coupon_code) && Number(couponDetail.couponDetails.coupon_code) > productTotal(Number(totalAmountDataWithFitting ?? 0), 0))) {
      if (Number((grandTotal).toFixed(2)) >= Number((paidAmount))) {
        totalAmount = Number((grandTotal).toFixed(2)) - Number((paidAmount).toFixed(2));
      } else {
        totalAmount = Number((paidAmount).toFixed(2)) - Number((grandTotal).toFixed(2));
      }
    }
    const isReapplyCoupon = true;
    const apply_coupon = await applyCoupon(
      couponDetail.couponDetails.coupon_code,
      totalAmount,
      shippingPrice,
      variationData.userId,
      orderId,
      isReapplyCoupon,
      variationData.shippingServiceCategory,
    );
    if (apply_coupon.status) {
      setCallApplyCoupon(true);
      removeAlert();
      let totalAmountData =
        productDetail.fittingTotalAmount +
        productDetail.productTotalAmount -
        apply_coupon.data.discounted_amount;
      setProductDetail({ ...productDetail, total: totalAmountData });
      if (apply_coupon.data.applicable_for === 'shipping') {
        setCouponDetail({
          ...couponDetail,
          isApplied: true,
          couponShippingDiscount:
            apply_coupon.data.discounted_amount < shippingPrice
              ? apply_coupon.data.discounted_amount
              : shippingPrice,
          couponDetails: {
            coupon_code: apply_coupon.data.coupon_code,
            coupon_id: apply_coupon.data.coupon_id,
          },
        });
      } else {
        setCouponDetail({
          ...couponDetail,
          isApplied: true,
          couponDetails: {
            coupon_code: apply_coupon.data.coupon_code,
            coupon_id: apply_coupon.data.coupon_id,
          },
          couponDiscount: apply_coupon.data.discounted_amount,
        });
      }
    } else {
      setProductDetail({ ...productDetail, total: productDetail.total });
      setCouponDetail({
        ...couponDetail,
        isApplied: false,
        couponDetails: { coupon_code: '', coupon_id: '' },
        couponDiscount: 0,
      });
    }

  };

  const onRemoveClick = () => {
    const orderId = order_id !== undefined ? order_id : null;

    removeCoupon(variationData.userId, orderId).then((res) => {
      if (res.status) {
        let totalAmountData =
          productDetail.fittingTotalAmount + productDetail.productTotalAmount;
        setProductDetail({ ...productDetail, total: totalAmountData });

        setCouponDetail({
          ...couponDetail,
          isApplied: false,
          couponShippingDiscount: 0,
          couponDetails: { coupon_code: '', coupon_id: '' },
          couponDiscount: 0,
        });
        setcouponCode('');
        if (order_id !== undefined) {
          //getOrderById(orderId);
        }
      }
    });
  };

  const handleOrderType = (e) => {
    variationData = {
      ...variationData,
      order_category: e.target.value,
    }
    selectedVariations(variationData)
    callGetCartApi();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      window.confirm(
        `Do you want to save this order and go to order preview page.`
      )
    ) {
      setShowAddEditPreviewForm(false);
      //setPreviewForm(true);
    }
  };
  const deleteMergedShippingOrder = useCallback(() => {
    let mergedSku = productDetail.currentCart.map((item) => {
      return { sku: item.productVariationSku, product_id: item.product_id }
    })
    variationData?.buildShopSupplyDetail.map((i) => {
      mergedSku.push({ sku: i.sku, product_id: i.build_shop_supply_id })
      return { sku: i.sku, product_id: i.build_shop_supply_id }
    })
    deleteMergeShippingOrder(mergedSku, variationData.shippingMergeId, order_id, currentOrder?.order_id).then((res) => {
      if (res.status === true) {
        variationData = {
          ...variationData,
          shippingMergeId: ""
        }
        selectedVariations(variationData)
      }
    })
  })

  const changeShippingService = useCallback((e) => {
    //if(SHIPPING_CATEGORY)
    variationData = {
      ...variationData,
      shippingServiceCategory: SHIPPING_CATEGORY[e.target.name]
    }

    if ([2, 3, 4].includes(SHIPPING_CATEGORY[e.target.name])) {
      setShippingPrice(0)
      if ([4].includes(SHIPPING_CATEGORY[e.target.name])) {
        setSelectedShippingMode({
          totalCharges: 0.00,
          carrier: "freight_shipment",
          currency: "USD",
          service: 'freight',
          shipping_discount: 0.00
        })
      }
      if ([3].includes(SHIPPING_CATEGORY[e.target.name])) {
        setSelectedShippingMode({
          totalCharges: 0.00,
          carrier: "merge_shipment",
          currency: "USD",
          service: 'merge',
          shipping_discount: 0.00
        })
      }
      if ([2].includes(SHIPPING_CATEGORY[e.target.name])) {
        setSelectedShippingMode({})
      }
    }

    if (SHIPPING_CATEGORY[e.target.name] === 3 && couponCode) {
      onRemoveClick();
    }

    selectedVariations(variationData)
  })

  return (
    <div className="animated fadeIn">
      {showAddEditPreviewForm && (
        <>
          <BuildShopSupplyModel />
          <ServiceModel />
          <SelectVariation
            setOpenVariationModal={setOpenVariationModal}
            orderId={order_id}
            toggle={toggleVariationModal}
            userType={userType}
            isOpen={variationModal}
            reset={resetVariationModal}
          />
          {(loading || shippingRatesLoading) ? (
            <Spinner />
          ) : <Form
            id="addEditForm"
            className="form-horizontal"
            onSubmit={(e) => onSubmit(e)}
          >
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <Button
                      onClick={() => navigate(-1)}
                      type="reset"
                      size="sm"
                      color="danger"
                    >
                      <i className="fa fa-arrow-left"></i> Back
                    </Button>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
            <Card>
              <CardHeader>
                {!order_id && <h5> Create Order </h5>}
                {order_id && (
                  <>
                    <Label className="label-tag-header">
                      {currentOrder?.order_id}
                    </Label>
                    (
                    <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
                      {currentOrder?.ordered_on}
                    </Moment>
                    <span className="time_formatting">
                      <Moment format="HH:mm" locale="de">{currentOrder?.ordered_on}</Moment>
                    </span>
                    )
                  </>
                )}
              </CardHeader>
              <CardBody>
                <Container fluid className="p-0">
                  {!order_id ? (
                    <Row className="orderaddedittable">
                      <Col sm="12">
                        <Row>
                          <Col sm="6">
                            <FormGroup>
                              <Label for="user_type">Customer Type</Label>
                              <Input
                                type="select"
                                name="user_type"
                                id="user_type"
                                value={userType}
                                onChange={(e) => handleOnSelect(e)}
                              >
                                {Object.keys(ORDER_CREATE_FOR_USER_ROLE).map(
                                  (key) => (
                                    <option value={key} key={key}>
                                      {ORDER_CREATE_FOR_USER_ROLE[key]}
                                    </option>
                                  )
                                )}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col sm="6">
                            <FormGroup>
                              <Label for="user_type">Name</Label>
                              <Select
                                value={userValue}
                                isLoading={user_list_loading}
                                isClearable={false}
                                options={usersList}
                                onChange={handleUserNameChange}
                                keepSelectedInList={false}
                                placeholder={
                                  userType === '2'
                                    ? 'Search customer name'
                                    : 'Search dealer name'
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <p>
                        <Label className="label-tag">
                          {currentOrder?.user_name}{' '}
                        </Label>
                        ({USER_ROLE[currentOrder?.user_type]})
                      </p>

                      {currentOrder?.payment_order_id && (
                        <p>
                          <Label className="label-tag">Payment ID</Label>(
                          {currentOrder?.payment_order_id} )
                        </p>
                      )}

                      <p>
                        <Label className="label-tag">Payment Status</Label>
                        {
                          ORDER_LIST_PAYMENT_STATUS[
                          currentOrder?.payment_status
                          ]
                        }
                      </p>
                      <hr />
                    </>
                  )}
                  {(userId !== '' || order_id !== undefined) && (
                    <div className="mt-20">
                      {loading ? (
                        <Spinner />
                      ) : (
                        <>
                          <OrderType
                            Ordertype={variationData.order_category}
                            isDisabled={order_id ? true : false}
                            handleOrderType={handleOrderType}
                          />


                        </>
                      )}
                    </div>
                  )}
                  {(userId !== '' || order_id !== undefined) && (
                    <>
                      <Card>
                        <CardHeader>
                          <h6>{"Products"}</h6>
                        </CardHeader>
                        <CardBody>
                          {productDetail?.addedProduct?.length > 0 && <CartDetails
                            productDetail={productDetail}
                            deleteProduct={deleteProduct}
                            editProduct={editProduct}
                            showAddEditPreviewForm={showAddEditPreviewForm}
                          />}
                          <Row className="mt-3">
                            <div className="couponcode mb-2">
                              <h6>Add Product</h6>
                            </div>
                            <Col sm="12">
                              <FormGroup>
                                <Select
                                  value={[]}
                                  isClearable={false}
                                  id="product"
                                  name="product"
                                  options={productListValue && productListValue}
                                  onChange={handleSelectProduct}
                                  keepSelectedInList={false}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardHeader>
                          <h6>{"Build Shop Supply"}</h6>
                        </CardHeader>
                        <CardBody>
                          {variationData?.buildShopSupplyDetail?.length > 0 && <>
                            {/* <h6>{"Build Shop Supply"}</h6> */}
                            <BuildShopSupplyCart showAction={true} />
                          </>}
                          <Row className="mt-3">
                            <div className="couponcode mb-2">
                              <h6>Add Build Shop Supply</h6>
                            </div>
                            {<Col sm="12">
                              <FormGroup>
                                <Select
                                  value={[]}
                                  isClearable={false}
                                  id="additional_product"
                                  name="additional_product"
                                  options={variationData.buildShopSupply}
                                  onChange={handleAdditionalProduct}
                                  keepSelectedInList={false}
                                />
                              </FormGroup>
                            </Col>}
                          </Row>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardHeader>
                          <h6>{"Services"}</h6>
                        </CardHeader>
                        <CardBody>
                          {variationData?.serviceDetail?.length > 0 && <>
                            {/* <h6>{"Build Shop Supply"}</h6> */}
                            <ServiceCart showAction={true} />
                          </>}
                          <Row className="mt-3">
                            <div className="couponcode mb-2">
                              <h6>Add Services</h6>
                            </div>
                            {<Col sm="12">
                              <FormGroup>
                                <Select
                                  value={[]}
                                  isClearable={false}
                                  id="services"
                                  name="services"
                                  options={variationData.service}
                                  onChange={handleService}
                                  keepSelectedInList={false}
                                />
                              </FormGroup>
                            </Col>}
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  )}

                  {order_id !== undefined &&
                    currentOrder !== undefined &&
                    Object.keys(currentOrder).length > 0 && (
                      <Card>
                        <CardBody>
                          <Row>
                            <Col className="address_box" sm="6">
                              <div>
                                <Label className="label-tag-header">
                                  Shipping Address
                                </Label>
                                <p className="data-ptag">
                                  <Label className="label-tag">Name :</Label>
                                  {currentOrder?.shipping_address?.name}
                                </p>
                                <p className="data-ptag">
                                  <Label className="label-tag">Email :</Label>
                                  {currentOrder?.shipping_address.email}
                                </p>
                                <p className="data-ptag">
                                  <Label className="label-tag">
                                    Contact No :
                                  </Label>
                                  {currentOrder?.shipping_address.ccode &&
                                    currentOrder?.shipping_address.ccode}
                                  {currentOrder?.shipping_address.contact_no}
                                </p>
                                <p className="data-ptag">
                                  <Label className="label-tag">Address :</Label>
                                  <span>
                                    {
                                      currentOrder?.shipping_address
                                        .address_line_1
                                    }
                                    <br />
                                    <div>
                                      {currentOrder?.shipping_address
                                        .address_line_2
                                        ? currentOrder?.shipping_address
                                          .address_line_2
                                        : ''}
                                    </div>
                                    {currentOrder?.shipping_city}{' '}
                                    {currentOrder?.shipping_state}{' '}
                                    {currentOrder?.shipping_zipcode} <br />
                                    {currentOrder?.shipping_country}
                                  </span>
                                </p>
                              </div>
                            </Col>
                            <Col className="address_box" sm="5">
                              <div>
                                <Label className="label-tag-header">
                                  Billing Address
                                </Label>
                                <p className="data-ptag">
                                  <Label className="label-tag">Name :</Label>
                                  {currentOrder?.billing_address.name}
                                </p>
                                <p className="data-ptag">
                                  <Label className="label-tag">Email :</Label>
                                  {currentOrder?.billing_address.email}
                                </p>
                                <p className="data-ptag">
                                  <Label className="label-tag">
                                    Contact No :
                                  </Label>
                                  {currentOrder?.billing_address.ccode &&
                                    currentOrder?.billing_address.ccode}
                                  {currentOrder?.billing_address.contact_no}
                                </p>
                                <p className="data-ptag">
                                  <Label className="label-tag">Address :</Label>
                                  <span>
                                    {
                                      currentOrder?.billing_address
                                        .address_line_1
                                    }
                                    <br />
                                    <div>
                                      {currentOrder?.billing_address
                                        .address_line_2.length > 0
                                        ? currentOrder?.billing_address
                                          .address_line_2
                                        : ''}
                                    </div>
                                    {currentOrder?.billing_city}{' '}
                                    {currentOrder?.billing_state}{' '}
                                    {currentOrder?.billing_zipcode} <br />
                                    {currentOrder?.billing_country}
                                  </span>
                                </p>
                              </div>
                            </Col>
                            <Col sm="1">
                              <Row>
                                <Col>
                                  <Button
                                    type="button"
                                    className="btn-success btn-sm"
                                    onClick={(e) => onEditAddress(e)}
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </Button>
                                </Col>
                                <Col></Col>
                              </Row>
                            </Col>
                          </Row>
                          {
                            variationData?.shippingServiceCategory === 3 && (
                              <Row className="mt-5">
                                <Col md="6">
                                  <Label>
                                    <strong>Merge With Existing Order </strong>
                                  </Label>
                                  <div className="col-md-6">
                                    <strong>Order Id : </strong>
                                    {`${variationData?.mergeAddtionalList?.find((i) => i.value === variationData?.shippingMergeId)?.label}`}
                                  </div>
                                </Col>
                              </Row>
                            )
                          }
                          {(currentOrder?.shipping_mode && variationData?.shippingServiceCategory !== 3) && (
                            <Row className="mt-5">
                              <Col md="6">
                                <FormGroup>
                                  <Label>
                                    <strong>Shipping Details </strong>
                                  </Label>

                                  <div className="col-md-6">
                                    <strong>Carrier : </strong>
                                    {convertCarrierTypes(currentOrder?.shipping_mode.carrier)}
                                  </div>
                                  <div className="col-md-6">
                                    <strong>Service : </strong>
                                    {convertServiceTypes(currentOrder?.shipping_mode.service)}
                                  </div>
                                  <div className="col-md-6">
                                    <strong>Total Charge : </strong>$
                                    {shippingPrice}
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          )}
                        </CardBody>
                      </Card>
                    )}

                  {((userId !== '' && order_id === undefined) ||
                    (order_id !== undefined &&
                      variationData.editAddress === true)) && (
                      <Card>
                        <CardHeader>
                          <h6>{"Addresses"}</h6>
                        </CardHeader>
                        <CardBody>
                          <BillingDetail
                            userId={variationData.userId}
                            setSelectedBillingAddress={setSelectedBillingAddress}
                            // setSelectedBillingLabel={setSelectedBillingLabel}
                            setSelectedShippingAddress={setSelectedShippingAddress}
                            //setSelectedShippingLabel={setSelectedShippingLabel}
                            shippToDifferentAddressChecked={
                              shippToDifferentAddressChecked
                            }
                            setShippToDifferentAddressChecked={
                              setShippToDifferentAddressChecked
                            }
                            editAddress={editAddress}
                          />
                        </CardBody>
                      </Card>
                    )}

                  {(userId !== '' &&
                    order_id === undefined &&
                    variationData.selectedShippingAddress !== null) ||
                    (variationData.shippingService &&
                      variationData.selectedShippingAddress != null) ? (

                    <div className="mt-20">
                      <Card>
                        <CardHeader>
                          <h6>Shipping Service </h6>
                        </CardHeader>
                        <CardBody>
                          <div className="order-margin">
                            <Row >

                              <Col sm="12" md="6" lg="4">
                                <Input
                                  type="radio"
                                  name="kinetixx_shipping_service"
                                  id="kinetixx_shipping_service"
                                  value={"kinetixx_shipping_service"}
                                  disabled={order_id ? true : false}
                                  checked={(variationData.shippingServiceCategory === 1) ? true : false}
                                  onChange={changeShippingService}
                                />
                                <Label for="kinetixx_shipping_service" className="label-tag ms-2">{"Kinetixx Shipping"}</Label>
                              </Col>
                              <Col sm="12" md="6" lg="4">
                                <Input
                                  type="radio"
                                  name="customer_shipping_service"
                                  id="customer_shipping_service"
                                  value={"customer_shipping_service"}
                                  disabled={order_id ? true : false}
                                  checked={(variationData.shippingServiceCategory === 2) ? true : false}
                                  onChange={changeShippingService}
                                />
                                <Label for="customer_shipping_service" className="label-tag ms-2">{"Customer Billing"}</Label>
                              </Col>
                              {(variationData.order_category !== "normal_order") && <Col sm="12" md="6" lg="4">
                                <Input
                                  type="radio"
                                  name="merge_shipping"
                                  id="merge_shipping"
                                  disabled={order_id ? true : false}
                                  checked={(variationData.shippingServiceCategory === 3) ? true : false}
                                  value={"merge_shipping"}
                                  onChange={changeShippingService}
                                />
                                <Label for="merge_shipping" className="label-tag ms-2">{"Merge With Existing Order"}</Label>

                              </Col>}
                              <Col sm="12" md="6" lg="4">
                                <Input
                                  type="radio"
                                  name="freight_shipment"
                                  id="freight_shipment"
                                  disabled={order_id ? true : false}
                                  checked={(variationData.shippingServiceCategory === 4) ? true : false}
                                  value={"freight_shipment"}
                                  onChange={changeShippingService}
                                />
                                <Label for="freight_shipment" className="label-tag ms-2">{"Freight Shipment"}</Label>

                              </Col>
                            </Row>
                            {(variationData.shippingServiceCategory === 1) && <ShippingRates
                              setSelectedShippingMode={setSelectedShippingMode}
                              selectedShippingMode={selectedShippingMode}
                              setShippingPrice={setShippingPrice}
                              selectedShippingPrice={shippingPrice}
                              shippingModeError={shippingModeError}
                              shippingMessages={shippingMessages}
                              groupedRatesByProvider={groupedRatesByProvider}
                              shippingRates={shippingRates}
                              reApplyCoupon={reApplyCoupon}
                              isApplied={couponDetail.isApplied}
                              callApplyCoupon={callApplyCoupon}
                              setDeliveryInstruction={setDeliveryInstruction}
                              delivery_instruction={deliveryInstruction}
                              shippingServiceCategory={variationData.shippingServiceCategory}
                            />}
                            {(variationData.shippingServiceCategory === 2) &&
                              <Card>
                                <CardBody className="details-view-outer">
                                  <Row >

                                    <Col sm="12" md="6" lg="4">
                                      <FormGroup>
                                        <Label for="customer_carrier_type" className="label-tag ms-2">{"Carrier Type"}</Label>
                                        <Input
                                          type="select"
                                          name="customer_carrier_type"
                                          id="customer_carrier_type"
                                          value={inputCustomerCarrierDetails.customer_carrier_type}
                                          onChange={onChangeCustomerCarrierDetails}
                                          required
                                        >
                                          <option value="fedex" key="fedex">FedEx</option>
                                          <option value="ups" key="ups">UPS</option>
                                          <option value="dhl" key="dhl">DHL</option>
                                        </Input>
                                      </FormGroup>
                                    </Col>

                                    <Col sm="12" md="6" lg="4">
                                      <FormGroup>
                                        <Label for="customer_carrier_account" className="label-tag ms-2">{"Customer Account Number"}</Label>
                                        <Input
                                          type="text"
                                          name="customer_carrier_account"
                                          id="customer_carrier_account"
                                          value={inputCustomerCarrierDetails.customer_carrier_account}
                                          onChange={onChangeCustomerCarrierDetails}
                                          placeholder="customer account number"
                                          required
                                          error={carrierAccountError ? true : false}
                                        />
                                        {carrierAccountError ? <p style={{ color: "red", margin: "0px" }}>{carrierAccountError}</p> : null}
                                      </FormGroup>
                                    </Col>
                                    <Col sm="12" md="6" lg="4">
                                      <FormGroup>
                                        <Label for="customer_carrier_country" className="label-tag ms-2">{"Customer Country"}</Label>
                                        <CountryList
                                          selectedCountry={inputCustomerCarrierDetails.customer_carrier_country}
                                          handleCountryChange={handleCountryChange}
                                          required
                                          placeholder="Select a Country"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col sm="12" md="6" lg="4">
                                      <FormGroup>
                                        <Label for="customer_carrier_zipcode" className="label-tag ms-2">{"Zipcode"}</Label>
                                        <Input
                                          type="text"
                                          name="customer_carrier_zipcode"
                                          id="customer_carrier_zipcode"
                                          placeholder="zipcode"
                                          value={inputCustomerCarrierDetails.customer_carrier_zipcode}
                                          onChange={onChangeCustomerCarrierDetails}
                                          error={carrierZipcodeError ? true : false}
                                          required
                                        />
                                        {carrierZipcodeError ? <p style={{ color: "red" }}>{carrierZipcodeError}</p> : null}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  {inputCustomerCarrierDetails.customer_carrier_type !== finalCustomerCarrierDetails.customer_carrier_type ||
                                    inputCustomerCarrierDetails.customer_carrier_account !== finalCustomerCarrierDetails.customer_carrier_account ||
                                    inputCustomerCarrierDetails.customer_carrier_country_code !== finalCustomerCarrierDetails.customer_carrier_country_code ||
                                    inputCustomerCarrierDetails.customer_carrier_zipcode !== finalCustomerCarrierDetails.customer_carrier_zipcode ?
                                    <Button type="button" color="primary" onClick={onSubmitCustomerCarrierDetails}>
                                      Submit
                                    </Button> : null}
                                  {(inputCustomerCarrierDetails.customer_carrier_type === finalCustomerCarrierDetails.customer_carrier_type &&
                                    inputCustomerCarrierDetails.customer_carrier_account === finalCustomerCarrierDetails.customer_carrier_account &&
                                    inputCustomerCarrierDetails.customer_carrier_country_code === finalCustomerCarrierDetails.customer_carrier_country_code &&
                                    inputCustomerCarrierDetails.customer_carrier_zipcode === finalCustomerCarrierDetails.customer_carrier_zipcode) ? <ShippingRates
                                    setSelectedShippingMode={setSelectedShippingMode}
                                    selectedShippingMode={selectedShippingMode}
                                    setShippingPrice={setShippingPrice}
                                    selectedShippingPrice={shippingPrice}
                                    shippingModeError={shippingModeError}
                                    shippingMessages={shippingMessages}
                                    groupedRatesByProvider={groupedRatesByProvider}
                                    shippingRates={shippingRates}
                                    reApplyCoupon={reApplyCoupon}
                                    isApplied={couponDetail.isApplied}
                                    callApplyCoupon={callApplyCoupon}
                                    setDeliveryInstruction={setDeliveryInstruction}
                                    delivery_instruction={deliveryInstruction}
                                    shippingServiceCategory={variationData.shippingServiceCategory}
                                  /> : null}
                                </CardBody>
                              </Card>
                            }
                            {(variationData.shippingServiceCategory === 3) && <div className="bg-light p-2 p-md-4 order-margin">
                              <Label> {!variationData.shippingMergeId ? "Select Order" : "Selected Order"} </ Label>
                              {(!currentOrder.orderMergeShippingId || !variationData.shippingMergeId) ? <>
                                <Col sm="6">
                                  <FormGroup>
                                    <Select
                                      value={variationData.mergeAddtionalList.filter((i) => i.value === variationData.shippingMergeId)}
                                      isClearable={false}
                                      id="additional_product"
                                      name="additional_product"
                                      options={variationData.mergeAddtionalList}
                                      onChange={mergeAddtional}
                                      keepSelectedInList={false}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="12">
                                  <FormGroup>
                                    <div className="couponcode">
                                      <Label for="deliveryInstruction">
                                        <strong>Delivery Instructions</strong>
                                      </Label>
                                    </div>
                                    <Input
                                      type="textarea"
                                      className="form-control text-left"
                                      id="deliveryInstruction"
                                      name="deliveryInstruction"
                                      maxLength="100"
                                      // placeholder="Delivery Instruction(max chars limit is 100)"
                                      defaultValue={deliveryInstruction}
                                      onChange={(e) => setDeliveryInstruction(e.target.value)}
                                    />
                                  </FormGroup>
                                </Col>
                              </> :
                                <Col sm="6">
                                  <Button type="button">{currentOrder?.mergeShippingOrder?.order_id}</Button>
                                  <Button outline color="danger" type="button" onClick={deleteMergedShippingOrder}>
                                    <i class="fa fa-trash-o" ></i>
                                  </Button>
                                </Col>
                              }
                            </div>
                            }
                            {(variationData.shippingServiceCategory === 4) &&
                              <Card>
                                <CardBody className="details-view-outer">
                                  <Row>
                                    <Col md="12">
                                      <FormGroup>
                                        <div className="couponcode">
                                          <Label for="deliveryInstruction">
                                            <strong>Delivery Instructions</strong>
                                          </Label>
                                        </div>
                                        <Input
                                          type="textarea"
                                          className="form-control text-left"
                                          id="deliveryInstruction"
                                          name="deliveryInstruction"
                                          maxLength="100"
                                          // placeholder="Delivery Instruction(max chars limit is 100)"
                                          defaultValue={deliveryInstruction}
                                          onChange={(e) => setDeliveryInstruction(e.target.value)}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            }
                          </div>
                        </CardBody>
                      </Card>
                      {/*  <h5>Shipping Service </h5>
                      <div className="order-margin">
                        <ShippingRates
                          setSelectedShippingMode={setSelectedShippingMode}
                          setShippingPrice={setShippingPrice}
                          selectedShippingPrice={shippingPrice}
                          shippingModeError={shippingModeError}
                          shippingMessages={shippingMessages}
                          groupedRatesByProvider={groupedRatesByProvider}
                          shippingRates={shippingRates}
                          reApplyCoupon={reApplyCoupon}
                          isApplied={couponDetail.isApplied}
                          callApplyCoupon={callApplyCoupon}
                        />
                      </div> */}
                    </div>
                  ) : null}

                  {(userId !== '' && order_id === undefined) && (<Card>
                    <CardHeader>
                      <Label className="label-tag-header">
                        {' '}
                        Additional emails for order notifications
                      </Label>
                    </CardHeader>
                    <CardBody>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {notifyEmails.map((email) => (
                          <div key={email} style={{ display: 'flex' }}>
                            {email}
                            <Button
                              type="button"
                              className="button"
                              onClick={() => onDelete(email)}
                              style={{
                                marginLeft: '3px',
                                marginRight: '5px',
                                height: '6px',
                                width: '1px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              &times;
                            </Button>
                          </div>
                        ))}
                      </div>

                      <div className='form-group mt-2'>
                        <input
                          value={inputEmail}
                          placeholder={'Type the email ID and hit "enter" or "return" key'}
                          onKeyDown={onKeyDown}
                          onChange={onEmailChange}
                          onPaste={onPaste}
                          className='form-control'

                        />
                      </div>
                      {error && (
                        <p className="error" style={{ color: 'red', marginBottom: '20px' }}>
                          {error}
                        </p>
                      )}
                    </CardBody>
                  </Card>)}
                  {(userId !== '' && order_id === undefined || order_id !== undefined) && <div className="mt-20">
                    <Card>
                      <CardHeader>
                        <h6>{"Comment"}</h6>
                      </CardHeader>
                      <CardBody>
                        <Input
                          type="textarea"
                          className="form-control text-left"
                          id="comment"
                          name="comment"
                          maxLength="100"
                          // placeholder="Delivery Instruction(max chars limit is 100)"
                          defaultValue={variationData.comment}
                          onChange={(e) => {
                            e.target.value
                            variationData = {
                              ...variationData,
                              comment: e.target.value
                            };
                            selectedVariations(variationData);
                          }}
                        />
                      </CardBody>
                    </Card>
                  </div>}
                  {((userId !== '' && order_id === undefined) ||
                    order_id !== undefined) && (
                      <div className="mt-20">
                        <CalculationDetail
                          handleOnChange={handleOnChange}
                          setDeliveryInstruction={setDeliveryInstruction}
                          couponDiscount={couponDetail.couponDiscount}
                          couponShippingDiscount={
                            couponDetail.couponShippingDiscount
                          }
                          isApplied={couponDetail.isApplied}
                          couponCode={couponDetail.couponDetails.coupon_code}
                          onChangeCouponCode={onChangeCouponCode}
                          handleApplyCoupon={handleApplyCoupon}
                          onRemoveClick={onRemoveClick}
                          payment_status={payment_status}
                          productDetail={productDetail}
                          taxTotal={taxTotal}
                          shippingPrice={shippingPrice}
                          grandTotal={grandTotal}
                          dataSet={{
                            payment_status,
                            productDetail,
                            taxTotal,
                            shippingPrice,
                            grandTotal,
                            currentOrder,
                          }}
                        />
                      </div>
                    )}
                </Container>
              </CardBody>
              {(((order_id === undefined &&
                variationData.selectedShippingAddress) || variationData.shippingServiceCategory === 3) ||
                order_id !== undefined) && (
                  <CardFooter>
                    <Button
                      className="mr-05"
                      type="submit"
                      size="sm"
                      color="primary"
                    >
                      <i className="fa fa-dot-circle-o"></i> Submit
                    </Button>
                    <Link to="/admin/orders" className="cancle-btn m-0">
                      <Button
                        type="reset"
                        size="sm"
                        color="danger"
                        className="m-0"
                      >
                        <i className="fa fa-ban"></i> Cancel
                      </Button>
                    </Link>
                  </CardFooter>
                )}
            </Card>
          </Form>}
        </>
      )}
      {!showAddEditPreviewForm && (
        <PreviewForm
          orderId={order_id}
          userType={userType}
          customerName={customerName}
          productDetail={productDetail}
          payment_status={payment_status}
          deliveryInstruction={deliveryInstruction}
          couponDetail={couponDetail}
          shippingPrice={shippingPrice}
          grandTotal={grandTotal}
          shippingBox={shippingBox}
          selectedShippingMode={selectedShippingMode}
          easyPostOrderId={easyPostOrderId}
          setShowAddEditPreviewForm={setShowAddEditPreviewForm}
          showAddEditPreviewForm={showAddEditPreviewForm}
          setIsSubmit={setIsSubmit}
          addToOrder={addToOrder}
          taxApplied={taxApplied}
          totalBeforeTax={totalBeforeTax}
          taxTotal={taxTotal}
          paid_amount={paidAmount}
          due_amount={dueAmount}
          finalCustomerCarrierDetails={finalCustomerCarrierDetails}
          notifyEmails={notifyEmails}
          clearEmails={clearEmails}
        />
      )}
    </div>
  );
};

CreateUpdateOrder.propTypes = {
  getOrderById: PropTypes.func.isRequired,
  currentOrder: PropTypes.object.isRequired,
  getUsersList: PropTypes.func.isRequired,
  getUserAddressList: PropTypes.func.isRequired,
  user_list_loading: PropTypes.bool.isRequired,
  calculateShippingRates: PropTypes.func.isRequired,
  addToOrder: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentOrder: state.order.currentOrder,
  cartDetail: state.order.cartDetail,
  variationData: state.order.variationData,
  usersList: state.order.usersList,
  productsList: state.order.productsList,
  user_list_loading: state.order.user_list_loading,
  productListLoading: state.order.productListLoading,
  loading: state.order.loading,
  productsAttributeList: state.order.productsAttributeList,
  shippingRatesLoading: state.order.shipping_rates_loading,
  getBuildShopService: state.order.getBuildShopService,
  mergeShippingData: state.order.mergeShippingData
});

export default connect(mapStateToProps, {
  selectedVariations,
  getOrderById,
  getProductsList,
  getBuildSupplyServiceList,
  getUsersList,
  applyCoupon,
  getCartDetails,
  addToCart,
  getUserAddressList,
  calculateShippingRates,
  addToOrder,
  updateCart,
  removeCoupon,
  getTaxRates,
  addToCartOrderData,
  getProductAttributes,
  getMergeShippingOrder,
  mergeShippingAddtionalOrder,
  deleteMergeShippingOrder
})(CreateUpdateOrder);
