import axios from 'axios';

import {
  DEALER_LOADING,
  DEALER_CREATED,
  DEALER_CREATE_ERROR,
  DEALER_FAIL,
  DEALER_MODAL_LOADING,
  DEALER_PASSWORD_UPDATED,
  DEALER_PASSWORD_UPDATE_ERROR,
  DEALER_UPDATED,
  DEALER_UPDATE_ERROR,
  DEALER_DELETED,
  DEALER_DELETE_ERROR,
  DEALER_LIST_UPDATED,
  GET_DEALER_BY_ID,
  DEALER_CHANGE_STATUS,
  DEALER_CHANGE_STATUS_ERROR,
  GET_DEALER_TYPES,
  DEALER_CHANGE_STATUS_BULK,
  DEALER_CHANGE_STATUS_BULK_ERROR,
  DEALER_FEATURED_BULK,
  DEALER_FEATURED_BULK_ERROR,
  DEALER_CHANGE_TYPE_BULK,
  DEALER_CHANGE_TYPE_BULK_ERROR,
  DEALER_MARK_FEATURED,
  DEALER_MARK_FEATURED_ERROR,
  GET_DEALER_BY_ID_ERROR,
  SET_ERRORS_LIST,
} from 'actions/types';

// Create Dealer
export const create = (formData, navigate) => async (dispatch) => {
  try {
    dispatch({
      type: DEALER_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let param = new window.FormData();
    param.append('file', formData.selectedFile); //image file
    param.append('first_name', formData.first_name);
    param.append('last_name', formData.last_name);
    param.append('email', formData.email);
    param.append('business_name', formData.business_name);
    param.append('business_email', formData.business_email);
    param.append('ccode', formData.ccode);
    param.append('contact_no', formData.contact_no);
    param.append('iute164_phone', formData.iute164_phone);
    param.append('website', formData.website);
    param.append('business_license_no', formData.business_license_no);
    param.append(
      'business_model_description',
      formData.business_model_description
    );
    param.append('annual_sales_volume', formData.annual_sales_volume);
    param.append(
      'shaft_clubhead_component_vendors',
      formData.shaft_clubhead_component_vendors
    );
    param.append('password', formData.password);
    param.append('image', formData.image);
    param.append('dealer_type_id', formData.dealer_type_id);
    param.append('latitute', formData.latitute);
    param.append('longitude', formData.longitude);
    param.append('address', formData.address);
    param.append('address_line_1', formData.address_line_1);
    param.append('address_line_2', formData.address_line_2);
    param.append('google_map_link', formData.google_map_link);
    param.append('fitting_studio', formData.fitting_studio);
    param.append('city', formData.city);
    param.append('zipcode', formData.zipcode);
    param.append('country', formData.country);
    param.append('state', formData.state);
    param.append('google_id', formData.google_id);
    param.append('google_place_id', formData.google_place_id);
    param.append('country_code', formData.country_code);
    param.append('facebook', formData.facebook);
    param.append('instagram', formData.instagram);
    param.append('twitter', formData.twitter);
    param.append('youtube', formData.youtube);
    param.append('snapchat', formData.snapchat);
    param.append('tiktok', formData.tiktok);

    const res = await axios.post('/api/admin/dealers/create', param, config);
    if (res.data.status === true) {
      dispatch({
        type: DEALER_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      navigate('/admin/dealers');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: DEALER_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
    }
  } catch (err) {
    dispatch({
      type: DEALER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit Password
export const editPassword = (formData, user_id) => async (dispatch) => {
  try {
    dispatch({
      type: DEALER_MODAL_LOADING,
      payload: true,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/dealers/edit/password/${user_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: DEALER_PASSWORD_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: DEALER_PASSWORD_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: DEALER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

// Edit Dealer
export const edit = (formData, navigate, user_id) => async (dispatch) => {
  try {
    dispatch({
      type: DEALER_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let param = new window.FormData();
    param.append('file', formData.selectedFile); //image file
    param.append('first_name', formData.first_name);
    param.append('last_name', formData.last_name);
    param.append('email', formData.email);
    param.append('business_name', formData.business_name);
    param.append('business_email', formData.business_email);
    param.append('ccode', formData.ccode);
    param.append('contact_no', formData.contact_no);
    param.append('iute164_phone', formData.iute164_phone);
    param.append('website', formData.website);
    param.append('business_license_no', formData.business_license_no);
    param.append(
      'business_model_description',
      formData.business_model_description
    );
    param.append('annual_sales_volume', formData.annual_sales_volume);
    param.append(
      'shaft_clubhead_component_vendors',
      formData.shaft_clubhead_component_vendors
    );
    param.append('password', formData.password);
    param.append('image', formData.image);
    param.append('oldImage', formData.oldImage); // old image name
    param.append('isNewFileSelected', formData.isNewFileSelected);
    param.append('dealer_type_id', formData.dealer_type_id);
    param.append('address', formData.address);
    param.append('latitute', formData.latitute);
    param.append('longitude', formData.longitude);
    param.append('address_line_1', formData.address_line_1);
    param.append('address_line_2', formData.address_line_2);
    param.append('google_map_link', formData.google_map_link);
    param.append('fitting_studio', formData.fitting_studio);
    param.append('city', formData.city);
    param.append('zipcode', formData.zipcode);
    param.append('country', formData.country);
    param.append('state', formData.state);
    param.append('google_id', formData.google_id);
    param.append('google_place_id', formData.google_place_id);
    param.append('country_code', formData.country_code);
    param.append('facebook', formData.facebook);
    param.append('instagram', formData.instagram);
    param.append('twitter', formData.twitter);
    param.append('youtube', formData.youtube);
    param.append('snapchat', formData.snapchat);
    param.append('tiktok', formData.tiktok);
    param.append('user_address_id', formData.user_address_id);
    param.append('dealer_status', parseInt(formData.dealer_status));

    const res = await axios.post(
      `/api/admin/dealers/${user_id}`,
      param,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: DEALER_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      navigate('/admin/dealers');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: DEALER_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
    }
  } catch (err) {
    dispatch({
      type: DEALER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete Dealer
export const deleteDealer = (user_id) => async (dispatch) => {
  try {
    dispatch({
      type: DEALER_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(`/api/admin/dealers/${user_id}`, config);
    if (res.data.status === true) {
      dispatch({
        type: DEALER_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: DEALER_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: DEALER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get dealers list
export const getDealersList = (userParams) => async (dispatch) => {
  try {
    dispatch({
      type: DEALER_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: { 'Content-Type': 'application/json' },
      params: userParams,
    };

    const res = await axios.get(`/api/admin/dealers/`, config);

    dispatch({
      type: DEALER_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          userParams: userParams,
          dealerList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    dispatch({
      type: DEALER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// get Dealer by id
export const getDealerById = (user_id, navigate) => async (dispatch) => {
  try {
    dispatch({
      type: DEALER_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/dealers/${user_id}`, config);
    if (res.data.status === true) {
      await dispatch({
        type: GET_DEALER_BY_ID,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors && errors.length > 0) {
        await errors?.forEach((error) => {
          errorsList = {...errorsList, [error?.param]: error?.msg};
        });
      }
      dispatch({
        type: GET_DEALER_BY_ID_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: {},
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: {
          ...errorsList,
        }
      })
      navigate('/admin/dealers');
    }
  } catch (err) {
    dispatch({
      type: DEALER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    navigate('/admin/dealers');
  }
};

//change status
export const dealerApproveDisapprove =
  (user_id, status) => async (dispatch) => {
    try {
      dispatch({
        type: DEALER_LOADING,
        payload: { is_loading: true, is_sub_element: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/dealers/change-status/${user_id}`,
        { status },
        config
      );

      if (res.data.status === true) {
        await dispatch({
          type: DEALER_CHANGE_STATUS,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
        return true;
      } else {
        //add a key is_failed=true else is_failed=false
        dispatch({
          type: DEALER_CHANGE_STATUS_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
            data: user_id,
          },
        });
        return false;
      }
    } catch (err) {
      dispatch({
        type: DEALER_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
      return false;
    }
  };

// get Dealer by id
export const getDealerTypes = () => async (dispatch) => {
  try {
    //   type: DEALER_LOADING,
    //   payload: { is_loading: true, is_sub_element: true },
    // });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.get('/api/admin/dealer-type/list/all', config);
    await dispatch({
      type: GET_DEALER_TYPES,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: DEALER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//change Bulk Dealer status
export const dealerApproveDisapproveInBulk =
  (statusFormData) => async (dispatch) => {
    try {
      dispatch({
        type: DEALER_LOADING,
        payload: { is_loading: true, is_sub_element: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/dealers/bulk-change-status`,
        { ...statusFormData },
        config
      );
      if (res.data.status === true) {
        await dispatch({
          type: DEALER_CHANGE_STATUS_BULK,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
      } else {
        //add a key is_failed=true else is_failed=false
        dispatch({
          type: DEALER_CHANGE_STATUS_BULK_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
          },
        });
      }
    } catch (err) {
      dispatch({
        type: DEALER_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

//Mark as featured on Bulk Dealer
export const markAsFeaturedInBulk = (ids) => async (dispatch) => {
  try {
    dispatch({
      type: DEALER_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/dealers/bulk-featured`,
      { ids },
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: DEALER_FEATURED_BULK,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: DEALER_FEATURED_BULK_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
    }
  } catch (err) {
    dispatch({
      type: DEALER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//Change Dealer Type of Bulk dealers
export const changeDealerTypeInBulk =
  (dealerTypeFormData) => async (dispatch) => {
    try {
      dispatch({
        type: DEALER_LOADING,
        payload: { is_loading: true, is_sub_element: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/dealers/bulk-change-dealer-type`,
        { ...dealerTypeFormData },
        config
      );
      if (res.data.status === true) {
        await dispatch({
          type: DEALER_CHANGE_TYPE_BULK,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
      } else {
        //add a key is_failed=true else is_failed=false
        dispatch({
          type: DEALER_CHANGE_TYPE_BULK_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
          },
        });
      }
    } catch (err) {
      dispatch({
        type: DEALER_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

//mark dealer as featured
export const markAsFeatured = (user_id, is_featured) => async (dispatch) => {
  try {
    dispatch({
      type: DEALER_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/dealers/featured/${user_id}`,
      { is_featured },
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: DEALER_MARK_FEATURED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: DEALER_MARK_FEATURED_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
    }
  } catch (err) {
    dispatch({
      type: DEALER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
