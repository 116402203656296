import axios from 'axios';

import {
  SET_ALERT,
  FITTING_LOADING,
  FITTING_LIST_UPDATED,
  FITTING_CHANGE_STATUS,
  FITTING_CHANGE_STATUS_ERROR,
  FITTING_VARIATION_MODAL_LOADING,
  FITTING_VARIATION_FAIL,
  FITTING_VARIATION_LOADING,
  GET_FITTING_VARIATION_BY_ID,
  FITTING_VARIATION_UPDATED,
  FITTING_VARIATION_UPDATE_ERROR,
  SET_ERRORS_LIST,
  FAILED_RESPONSE,
  SUCCESS_RESPONSE,
  LOADING_END,
  LOADING_START,
  ERROR_RESPONSE,
} from 'actions/types';
import { updateFilterData } from 'actions/commonActions';

//get Fitting attribute variation list
export const getFittingAttributeVariationList =
  (fittingParams, attribute_id) => async (dispatch) => {
    try {
      dispatch({
        type: FITTING_VARIATION_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: fittingParams,
      };

      const res = await axios.get(
        `/api/admin/fittings/${attribute_id}/`,
        config
      );
      dispatch({
        type: FITTING_LIST_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: {
            variationList: res.data.response[0],
          },
        },
      });
      return res;
    } catch (err) {
      dispatch({
        type: FITTING_VARIATION_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

// Get attribute by id
export const getVariationById = (variation_id) => async (dispatch) => {
  try {
    dispatch({
      type: FITTING_VARIATION_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/fittings/brands/${variation_id}`,
      config
    );
    await dispatch({
      type: GET_FITTING_VARIATION_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: FITTING_VARIATION_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit attribute variation
export const postFittingAttribute =
  (formData, attribute_id, variation_id = null) =>
    async (dispatch) => {
      try {
        dispatch({
          type: FITTING_VARIATION_MODAL_LOADING,
        });
        let res = '';
        let config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let param = new FormData();

        if (formData.includeAllFields) {
          param.append('file', formData.selectedFile);
          param.append('variation_weight', formData.variation_weight);
          param.append('price', formData.price);
          param.append('inventory', formData.inventory);
          param.append('part_no', formData.part_no);
          param.append('thumbnail', formData.thumbnail);
          param.append('old_image', formData.oldimage);
          param.append('image', formData.image);
          param.append('is_new_file_selected', formData.isNewFileSelected);
          param.append('include_all_fields', formData.includeAllFields);
        }

        if (formData.category_required) {
          param.append('category_required', formData.category_required);
        } else {
          param.append('category_required', false);
        }
        param.append('title', formData.title);
        param.append('category_ids', formData.categoryIds);
        param.append('attribute_id', attribute_id);
        param.append('option_id', formData.is_option);

        let url = '';
        if (variation_id) {
          url = `/${variation_id}`;
        }

        console.log(url, formData);
        res = await axios.post(
          `/api/admin/fitting-variation${url}`,
          param,
          config
        );

        if (res.data.status === true) {
          dispatch({
            type: FITTING_VARIATION_UPDATED,
            payload: {
              message: res.data.message,
              alertType: 'success',
              data: res.data.response,
            },
          });
          return true;
        } else {
          const errors = res.data.errors;
          let errorsList = {};
          if (errors) {
            await errors.forEach((error) => {
              errorsList = { ...errorsList, [error.param]: error.msg };
            });
          }
          dispatch({
            type: FITTING_VARIATION_UPDATE_ERROR,
            payload: {
              message: res.data.message,
              alertType: 'danger',
              errorsList,
            },
          });
          dispatch({
            type: SET_ERRORS_LIST,
            payload: { ...errorsList },
          });
          return false;
        }
      } catch (err) {
        dispatch({
          type: FITTING_VARIATION_FAIL,
          payload: {
            message:
              err.response && err.response.statusText
                ? err.response.statusText
                : 'Something went wrong.',
            alertType: 'danger',
            error: err,
          },
        });
        return false;
      }
    };

//change status
export const changeStatus = (variation_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: FITTING_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/fittings/change-status/${variation_id}`,
      { status },
      config
    );

    if (res.data.status === true) {
      await dispatch({
        type: FITTING_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: FITTING_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: variation_id,
        },
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: FITTING_VARIATION_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete Variation
export const deleteFitting = (variation_id, history) => async (dispatch) => {
  try {
    dispatch({
      type: FITTING_VARIATION_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(
      `/api/admin/fittings/${variation_id}`,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: SET_ALERT,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: SET_ALERT,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: FITTING_VARIATION_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

export const getInquiryForProductFitting = async (paramData) => {
  try {
    paramData = updateFilterData(paramData);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: paramData,
    };
    const res = await axios.get(`/api/admin/fittings/csv/download`, config);
    return res.data.response;
  } catch (err) {
    return {};
  }
};

export const uploadFittingInventory = (csvData, attribute_id) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_START,
      payload: { is_loading: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/fittings/csv/import?attribute_id=${attribute_id}`, csvData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: LOADING_END,
        payload: { is_loading: false },
      });
      dispatch({
        type: SUCCESS_RESPONSE,
        payload: {
          message: res.data.message,
          alertType: 'success',
        },
      });
      return res.data;
    } else {
      dispatch({
        //ADMIN_ORDER_CART_ERROR
        type: ERROR_RESPONSE,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return { status: false }
    }
  } catch (err) {
    dispatch({
      type: FAILED_RESPONSE,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return { status: false }
  }
};
