import {
  Additional_Product_LOADING,
  Additional_Product_CREATED,
  Additional_Product_CREATE_ERROR,
  Additional_Product_FAIL,
  Additional_Product_UPDATED,
  Additional_Product_UPDATE_ERROR,
  Additional_Product_DELETED,
  Additional_Product_DELETE_ERROR,
  Additional_Product_LIST_UPDATED,
  GET_Additional_Product_BY_ID,
} from "actions/types";

const initalState = {
  servicesList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentProduct: {},
  loading: false,
  isSubLoading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case Additional_Product_LOADING:
      return {
        ...state,
        servicesList: payload.is_sub_element ? state.servicesList : initalState.servicesList,
        currentProduct: payload.is_sub_element
          ? state.currentProduct
          : initalState.currentProduct,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case Additional_Product_CREATED:
      return {
        ...state,
        loading: false,
      };
    case Additional_Product_CREATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case Additional_Product_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false
      };
    case Additional_Product_UPDATED:
      return {
        ...state,
        currentProduct: {},
        loading: false,
      };
    case Additional_Product_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case Additional_Product_DELETED:
      return {
        ...state,
        loading: false,
      };
    case Additional_Product_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case Additional_Product_LIST_UPDATED:
      return {
        ...state,
        servicesList: {
          data: payload.data.servicesList.data,
          page: payload.data.servicesList.metadata[0].current_page,
          count: payload.data.servicesList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_Additional_Product_BY_ID:
      return {
        ...state,
        currentProduct: payload.data,
        loading: false,
      };
    default:
      return state;
  }
}
