/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddAddress from './AddAddress';
import Spinner from 'views/Spinner';
import { ADDRESS_TYPE } from 'constants/index';
import Select from 'react-select';
import { Button, Row, Col, FormGroup, Label, Input } from 'reactstrap';

const BillingDetail = ({
  userId,
  address_loading,
  currentAddressList,
  setSelectedBillingAddress,
  setSelectedBillingLabel,
  setSelectedShippingAddress,
  setSelectedShippingLabel,
  shippToDifferentAddressChecked,
  setShippToDifferentAddressChecked,
  editAddress,
  setEditAddress,
  setShippingService,
}) => {
  //##################### billing address list for logged in user #########################

  const [addressList, setAddressList] = useState([]);

  const onRestEditAddress = (e) => {
    e.preventDefault();
    setEditAddress(false);
    setShippingService(false);
  };


  useEffect(() => {
    setAddressList([]);
    setSelectedBillingAddress(null);
    //setSelectedShippingAddress(null);
    setShippToDifferentAddressChecked(false);
    if (userId && currentAddressList.length > 0) {
      setAddressList(currentAddressList);
    }
    // else {
    //   setAddressList([]);
    //   setSelectedBillingAddress(null)
    //   setSelectedShippingAddress(null)
    //   setShippToDifferentAddressChecked(false)
    // }
  }, [currentAddressList, userId]);

  //##################### selected addresss #########################

  //   useState(true);
  const [addressValueData, setAddressValueData] = useState();
  let addressValues = [];
  const [billingAddressValue, setBillingAddressValue] = useState();
  const [shippingAddressValue, setShippingAddressValue] = useState();

  useEffect(() => {
    // if (addressList && selectedBillingaddressByPrimary) {
    if (addressList) {
      let selected_address = addressList.filter((address) => {
        if (address.is_primary) return address;
        return false;
      });
      // if (selected_address.length > 0) {
      //   // setSelectedBillingaddressByPrimary(false);
      //   setSelectedBillingAddress(selected_address[0]._id);
      // }

      addressList.map((address, idx) => {
        addressValues.push({ value: address._id, label: address.address });
      });

      setAddressValueData(addressValues);
    }
  }, [addressList]);

  const handelOnChange = (e) => {
    //setSelectedBillingAddress(e.target.value);
  };

  //################ modal opened for ############################
  const [isFor, setIsFor] = useState(ADDRESS_TYPE.BILLING);

  //################ toggle add address modal ############################
  const [addressModal, setOpenAddressModal] = useState(false);
  const toggleAddressModal = (modalIsFor = ADDRESS_TYPE.BILLING) => {
    setIsFor(modalIsFor);
    setOpenAddressModal(!addressModal);
  };

  //##################### Billing address detail #########################
  const handleCheckedChange = (e) => {
    //if (!e.target.checked) setSelectedShippingAddress(null);

    setShippToDifferentAddressChecked(e.target.checked);
  };
  const [billingAddressId, setBillingAddressId] = useState('');
  const handleBillingAddressChange = (e, action) => {
    //console.log(e,'billing address data');
    if (action.action === 'select-option') {
      setBillingAddressValue([e.value]);
      setBillingAddressId(e.value);
      setSelectedBillingAddress(e.value);
      setSelectedBillingLabel(e.label);
    }
  };
  const billingAddressData = addressList?.find(
    (address) => address._id === billingAddressId
  );

  //##################### Shipping address detail #########################
  const [shippingAddressId, setShippingAddressId] = useState('');
  const handleShippingAddressChange = (e, action) => {
    if (action.action === 'select-option') {
      setShippingAddressValue([e.value]);
      setShippingAddressId(e.value);
      setSelectedShippingAddress(e.value);
      setSelectedShippingLabel(e.label);
    }
  };
  const shippingAddressData = addressList?.find(
    (address) => address._id === shippingAddressId
  );

  const handelOnChangeShipping = (e) => {
    //console.log(e.target.value,'value data');
    setSelectedShippingAddress(e.target.value);
  };

  //##################### Shipping address detail #########################
  const shippingLoggedIn = (
    // <div className="address bg-light p-2 p-md-4">
    <div className="address">
      <div className="couponcode mb-3 billing-heading">
        <h5>Addresses</h5>
      </div>
      {/* <div className="mb-4 billing-heading"><b>Shipping Address</b></div> */}
      <div className="couponcode mb-0">
        <Label for="payment_status">
          <strong>Shipping Address</strong>
        </Label>
      </div>

      {addressList.length > 0 ? (
        <div className="">
          {
            <Select
              //value={shippingAddressValue}
              //isLoading={user_list_loading}
              isClearable={false}
              id="shipping_address"
              name="selected_sihppinhg_address"
              options={addressValueData && addressValueData}
              onChange={(e, action = { action }) => {
                handleShippingAddressChange(e, action);
              }}
              // onCreateOption={(e) => {
              //   onCreateNewUser(e);
              // }}
              keepSelectedInList={false}
            />
          }
        </div>
      ) : (
        'No Address'
      )}
      <FormGroup className="mt-3">
        <span>
          {shippingAddressData && (
            <strong>
              <p>
                {shippingAddressData.name} ({shippingAddressData.email}{' '}
                {shippingAddressData.ccode}
                {shippingAddressData.contact_no})
              </p>
              <p>{shippingAddressData.address}</p>
            </strong>
          )}
        </span>
      </FormGroup>
      <FormGroup>
        <Button
          className="btn-primary d-inline-flex"
          onClick={(e) => toggleAddressModal(ADDRESS_TYPE.SHIPPING)}
        >
          Add Address
        </Button>
      </FormGroup>
      <FormGroup>
        <Label>
          <Input
            type="checkbox"
            id="new_shipping_address"
            name="new_shipping_address"
            checked={shippToDifferentAddressChecked}
            className="mr-2"
            onChange={(e) => handleCheckedChange(e)}
          />
          Bill to different address
        </Label>
      </FormGroup>
    </div>
  );
  // </div>

  const billingLoggedIn = (
    <div className="address billingADrs">
      
      <div className="mb-2 billing-heading"><b>Billing Address</b></div>
      {addressList.length > 0
        ?
        (<div className="">
          {
            <Select
              isClearable={false}
              id="billing_address"
              name="selected_address"
              options={addressValueData && addressValueData}
              onChange={(e, action = { action }) => {
                handleBillingAddressChange(e, action);
              }}
              keepSelectedInList={false}
            />
          }
        </div>
      ) : (
        'No Address'
      )}
      <FormGroup className="mt-3">
        <span>
          {billingAddressData && shippToDifferentAddressChecked && (
            <strong>
              <p>
                {billingAddressData.name} ({billingAddressData.email}{' '}
                {billingAddressData.ccode}
                {billingAddressData.contact_no})
              </p>
              <p>{billingAddressData.address}</p>
            </strong>
          )}
        </span>
      </FormGroup>
      <FormGroup>
        <Button
          className="btn-primary d-inline-flex"
          onClick={(e) => toggleAddressModal(ADDRESS_TYPE.BILLING)}
        >
          Add Address
        </Button>
      </FormGroup>
    </div>
  );
  // </div>

  return address_loading ? (
    <Spinner />
  ) : (
    <div className="bg-light p-2 p-md-4 setreset">
      <Row>
        {userId === '' ? (
          <Col md="12">
            {' '}
            Please select customer/dealer from search box to proceed....{' '}
          </Col>
        ) : (
          <>
            <Col md="6">{shippingLoggedIn}</Col>
            <Col md="6">
              {shippToDifferentAddressChecked && billingLoggedIn}
            </Col>
            {
              editAddress &&
            
            <Col md="1" className="reset-button-tab">
              <Button
                type="button"
                className="btn-danger btn-sm"
                onClick={(e) => onRestEditAddress(e)}
              >
                <i class="fa fa-times"></i>
              </Button>
            </Col>
          }
          </>
        )}
      </Row>
      <AddAddress
        userId={userId}
        isOpen={addressModal}
        toggle={toggleAddressModal}
        isFor={isFor}
        setSelectedBillingAddress={setSelectedBillingAddress}
        setSelectedShippingAddress={setSelectedShippingAddress}
        setShippToDifferentAddressChecked={setShippToDifferentAddressChecked}
      />
    </div>
  );
};

BillingDetail.propTypes = {
  address_loading: PropTypes.bool.isRequired,
  currentAddressList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  address_loading: state.order.address_loading,
  currentAddressList: state.order.currentAddressList,
});

export default connect(mapStateToProps)(BillingDetail);
