import { PAYMENT_METHOD } from 'constants';
import { useCallback, useEffect, useState } from 'react';
import {
    Input,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    Table,
    Form,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { changeOrderStatus } from 'actions/admin/order';
import { connect } from 'react-redux';

const DeliveredRemarkModel = ({ isOpen, setIsDeliveryRemarkModal, toggleDeliveredModal, deliveryRemark, changeOrderStatus }) => {
    const [deliveryRemarkValue, setDeliveryRemark] = useState(null)
    const submitForm = (e) => {
        e.preventDefault();
        deliveryRemark.delivery_remark = deliveryRemarkValue;

        changeOrderStatus(deliveryRemark).then((res) => {
            if (res.data.status === true) {
                toggleDeliveredModal(true)
            }
        });
        console.log(deliveryRemark, "selectedMethodselectedMethod")
    }
    return (
        <Modal isOpen={isOpen} toggle={toggleDeliveredModal} backdrop={false}>
            <Form onSubmit={submitForm} className="form-horizontal">
                <ModalHeader >
                    Delivery Remark
                </ModalHeader>
                <ModalBody>
                    {<Row className="mt-2">
                        <Col>
                            <Label className="p-0">
                                Remark <span>*</span>
                            </Label>
                            <Input
                                type="textarea"
                                name="delivery_remark"
                                placeholder="Delivery Remark"
                                onChange={(e) => setDeliveryRemark(e.target.value)}
                                required
                            ></Input>

                        </Col>
                    </Row>}
                </ModalBody>
                <ModalFooter className="refund-modal">
                    <div className='submit-close modalFooter'>
                        <Button
                            size="sm"
                            color="primary"
                            type="submit"
                        >
                            {"Submit"}
                        </Button>
                        <Button
                            size="sm"
                            color="danger"
                            onClick={toggleDeliveredModal}
                        >
                            Close
                        </Button>
                    </div>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    loading: state.order.loading
});

export default connect(mapStateToProps, {
    changeOrderStatus
})(DeliveredRemarkModel);