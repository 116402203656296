import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Input, FormGroup, Button } from 'reactstrap';
import { USER_APPROVED_STATUS } from 'constants/index';

const CustomFilters = (props) => {
  let query;
  let approved_status;

  const initialFilter = {
    query: '',
    approved_status: '',
  };

  const [filter, setFilter] = useState(initialFilter);

  const handleClick = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
    var tempFilters = { ...filter, [e.target.name]: e.target.value };

    let final_query = '';
    Object.keys(tempFilters).forEach(function (key) {
      final_query = `${final_query}&${key}=${tempFilters[key]}`;
    });
    props.onSearch(final_query);
  };

  const handelReset = (e) => {
    //reset values on text and dropdown
    query.value = '';
    approved_status.value = '';
    //reset submiting filters
    setFilter(initialFilter);
    //reset empty query
    props.onSearch('');
  };

  return (
    <div>
      <div className="reset_bt">
        <FormGroup check className="checkbox">
          <Input
            className="form-control"
            innerRef={(n) => (query = n)}
            type="text"
            placeholder="Search"
            name="query"
            id="query"
            onChange={(e) => handleClick(e)}
          />
        </FormGroup>

        <FormGroup check className="checkbox">
          <Input
            type="select"
            name="approved_status"
            id="approved_status"
            innerRef={(n) => (approved_status = n)}
            onChange={(e) => handleClick(e)}
          >
            <option value={''}> {'All Status'} </option>
            {Object.keys(USER_APPROVED_STATUS).map(function (key) {
              return (
                <option key={key} value={key}>
                  {USER_APPROVED_STATUS[key]}
                </option>
              );
            })}
          </Input>
        </FormGroup>
        <Button
          className="mr-05 reset-btn"
          size="sm"
          color="danger"
          onClick={(e) => handelReset(e)}
        >
          Reset Filters
        </Button>
      </div>
    </div>
  );
};
CustomFilters.propTypes = {
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  loading: state.user.loading,
});

export default connect(mapStateToProps)(CustomFilters);
