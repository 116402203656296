import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from 'views/Spinner';
import { Link } from 'react-router-dom';

import { upload } from 'actions/admin/imageGallery';
import Errors from 'views/Notifications/Errors';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { REMOVE_ERRORS } from 'actions/types';

const UploadImage = ({ upload, history, errorList, loading }) => {
  //**************** use state for form data *********************
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    selectedFile: '',
    image: '',
  });

  //**************** expload form data *********************
  const {
    title,
    description,
    // selectedFile, // image file
    image, // object url of image (image src)
  } = formData;

  //**************** dispatch to remove error *********************
  
  //**************** on Change event *********************
  const onChange = (e) => {
    if (e.target.name === 'thumbnail') {
      let image_file = e.target.files[0];

      let image_url = URL.createObjectURL(image_file);

      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        selectedFile: image_file,
        image: image_url,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    
  };

  //**************** on submit event *********************
  const onSubmit = (e) => {
    e.preventDefault();
    upload(formData, navigate);
  };

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <Form
                className="form-horizontal"
                onSubmit={(e) => onSubmit(e)}
                encType="multipart/form-data"
              >
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="title">
                          Title <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="title"
                          name="title"
                          maxLength="100"
                          value={title}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.title ? true : false}
                        />
                        <Errors current_key="title" key="title" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="description">
                          Description <span>*</span>
                        </Label>
                        <Input
                          type="textarea"
                          id="description"
                          name="description"
                          value={description}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.description ? true : false}
                        />
                        <Errors current_key="description" key="description" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="thumbnail">
                          Thumbnail<span>*</span>
                        </Label>
                        <Input
                          type="file"
                          id="thumbnail"
                          name="thumbnail"
                          onChange={(e) => onChange(e)}
                          multiple
                          invalid={errorList.image ? true : false}
                        />
                        <img src={image} className="preview-img" alt="" />
                        {/* {image_preview} */}
                        <Errors current_key="image" key="image" />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary">
                    {/* {loading?<Spinner/>: */}
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Link to="/admin/gallery-image">
                    <Button type="reset" size="sm" color="danger">
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

UploadImage.propTypes = {
  upload: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.imageGallery.loading,
});

export default connect(mapStateToProps, { upload })(UploadImage);
