/* eslint-disable */
import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import Moment from 'react-moment';
import {
  getProductsList,
  deleteProduct,
  removeProductFromCart,
  changeStatus,
  getCategories,
  getInventoriesToDownload,
  uploadInventoryForGear,
  jumpToVariation,
} from 'actions/admin/GearProduct/gearProduct';
import * as Constants from 'constants/index';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from '../../../../utils/Loading';

import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Input,
  Form,
  FormGroup,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import PerPageSelect from '../../PerPageSelect';
import Spinner from 'views/Spinner';

const actions = (
  <div className="add-button-div" style={{ marginTop: "9px" }}>
    <Link to="/admin/gear-products/create" className="addNewElementClass">
      <Button color="primary" size="sm">
        <i className="fa fa-plus"></i> Create Gear Product
      </Button>
    </Link>
  </div>
);

const renderProductName = (params) => {
  if (params.data === undefined) {
    return <Loading />;
  } else {
    return <div>{params.data?.product_title}</div>;
  }
};

const renderProductImage = (params) => {
  return !params.data.thumbnail ? (
    <></>
  ) : (
    <img
      src={
        //process.env.REACT_APP_AWS_STORAGE_URL +
        'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/product/' +
        params.data?.thumbnail
      }
      className="preview-img"
      alt=""
    />
  );
};

const renderLastModified = (params) => {
  return (
    <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
      {params.data?.updated_at}
    </Moment>
  );
};

const renderStatus = (params, changeProductStatus) => {
  return (
    <div>
      <Input
        type="select"
        name="status"
        id={params.data?._id}
        defaultValue={params.data?.status}
        onChange={(e, a) => {
          changeProductStatus(params.data?._id, e.target.value);
        }}
      >
        {Object.keys(Constants.ADMIN_PRODUCT_STATUS).map(function (key) {
          return params.data?.status !== 0 && key === 0 ? (
            ''
          ) : (
            <option key={key} value={key}>
              {Constants.ADMIN_PRODUCT_STATUS[key]}
            </option>
          );
        })}
      </Input>
    </div>
  );
};

const renderActions = (
  params,
  deleteProduct,
  updateProducts,
  removeProductFromCart
) => {
  const categoryName = params.data?.category
    .replace(/[^a-zA-Z ]/g, '')
    .split(' ')
    .join('-')
    .toLowerCase();
  const productName = params.data?.product_title
    .replace(/[^a-zA-Z ]/g, '')
    .split(' ')
    .join('-')
    .toLowerCase();
  return (
    <div>
      <Link
        to={
          params.data?.is_external === 1
            ? `/admin/gear-products/${categoryName}/${productName}/edit_external/${params.data?._id}`
            : `/admin/gear-products/${categoryName}/${productName}/edit/${params.data?._id}`
        }
      >
        <Button type="button" size="sm" color="success" title="Edit">
          <i className="fa fa-pencil"></i>
        </Button>
      </Link>
      <Button
        type="button"
        size="sm"
        color="danger"
        title="Delete"
        onClick={(e) => {
          if (
            window.confirm(
              `Are you sure, you want to delete ${params.data?.product_title} product?`
            )
          ) {
            deleteProduct({ product_id: params.data?._id }).then((res) => {
              if (res) {
                updateProducts();
              } else {
                Swal.fire({
                  title: 'Are you sure?',
                  text: 'This product is in the cart of some users or has variations in it',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, delete it!',
                  cancelButtonText: 'No, keep it',
                }).then((result) => {
                  if (result.value) {
                    // Delete Attribute from the product and its Variations
                    removeProductFromCart(params.data?._id).then((response) => {
                      if (response) {
                        deleteProduct({ product_id: params.data?._id, remove: true }).then((res) => {
                          if (res) {
                            updateProducts();
                          }
                        });
                      }
                    });
                    // For more information about handling dismissals please visit
                    // https://sweetalert2.github.io/#handling-dismissals
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire('Cancelled', 'Your product is safe :)', 'error');
                  }
                });
              }
            });
          }
        }}
      >
        <i className="fa fa-trash"></i>
      </Button>
    </div>
  );
};

const ProductsList = ({
  getProductsList,
  deleteProduct,
  changeStatus,
  gearProductsList: { data, count },
  loading,
  getCategories,
  removeProductFromCart,
  gearCategorySubCategoryList,
  subLoading,
  uploadInventoryForGear,
  getInventoriesToDownload,
  jumpToVariation,
}) => {
  const history = useNavigate();
  const location = useLocation();
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
        limit: Constants.DEFAULT_PAGE_SIZE,
        page: 1,
        orderBy: 'updated_at',
        ascending: 'desc',
        query: '',
        filter: {},
        onLoad: true,
      }
  );

  // to resolve useEffect dependency error

  //################## to get updated list ###################
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [invalidData, setInvalidData] = useState(null);
  const updateProducts = () => {
    gridApi.purgeInfiniteCache();
  };

  const changeProductStatus = async (id, value) => {
    await changeStatus(id, value);
    gridApi.purgeInfiniteCache();
  };

  const [downloadData, setDownloadData] = useState([]);
  const [jumpToVariationLoading, setJumpToVariationLoading] = useState(false);
  const [sku, setSku] = useState('');
  const [model, setModel] = useState(false);
  const onChangeSku = (e) => {
    e.preventDefault();
    setSku(e.target.value);
  };

  const onClickJumpToVariation = async (e) => {
    e.preventDefault();
    setJumpToVariationLoading(true);
    await jumpToVariation(sku, history);
    setJumpToVariationLoading(false);
  };

  //#########################Colums defination start ###################
  const columns = [
    {
      field: 'product_title',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      headerName: 'Product Name',
      width: 160,
      sortable: true,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      colSpan: (params) => {
        if (params.data === undefined) {
          return 6;
        } else {
          return 0;
        }
      },
      cellRenderer: (params) => renderProductName(params),
    },
    {
      field: 'thumbnail',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      headerName: 'Product Image',
      width: 145,
      sortable: true,
      cellRenderer: (params) => renderProductImage(params),
    },

    {
      field: 'category',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      headerName: 'Category',
      width: 200,
      sortable: true,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },

    {
      field: 'updated_at',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      headerName: 'Last Modified',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.DATE_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
        buttons: ['reset', 'apply'],
      },
      width: 140,
      sortable: true,
      filter: 'agDateColumnFilter',
      cellRenderer: (params) => renderLastModified(params),
    },
    {
      field: 'status',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      headerName: 'Status',
      width: 150,
      sortable: true,
      cellRenderer: (params) => renderStatus(params, changeProductStatus),
    },
    {
      field: '_id',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      headerName: 'Actions',
      width: 100,
      cellRenderer: (params) =>
        renderActions(
          params,
          deleteProduct,
          updateProducts,
          removeProductFromCart
        ),
    },
  ];

  //#########################Colums defination end ###################

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };

          const customData = await getProductsList(customParams);
          if (customData?.data?.status) {
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });

  const handleInventoryDownload = useCallback(async (event) => {
    let data = await getInventoriesToDownload(sortingParams);
    setDownloadData(data.data);
    document.getElementById('downloadGearInventory').click();
  });

  const toggleViewDetailModal = () => {
    setModel(!model);
  }

  const onChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = handleCSVConversion;
    reader.readAsText(file);
  };

  const handleCSVConversion = (event) => {
    const csvData = event.target.result;
    const lines = csvData.split('\n');
    const headers = lines[0].split(',');

    const jsonData = [];
    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(',');
      if (currentLine.length === headers.length) {
        const row = {};
        for (let j = 0; j < headers.length; j++) {
          row[headers[j]] = currentLine[j];
        }
        jsonData.push(row);
      }
    }
    setCsvData(jsonData);
  };

  const handleInventoryUpload = async (event) => {
    event.preventDefault();
    const inventoryData = await uploadInventoryForGear(csvData);
    if (inventoryData.status) {
      document.getElementById("file-input").value = '';
      setCsvData([]);
    }
    if (inventoryData?.response?.checkInventory?.length > 0) {
      setInvalidData(inventoryData?.response?.checkInventory);
      setModel(true);
    }
  };

  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                {actions}
                <Form onSubmit={onClickJumpToVariation}>
                  <div className="rightpaneltop">
                    <Input
                      type="text"
                      placeholder="Type SKU"
                      required
                      onChange={onChangeSku}
                      style={{
                        width: '160px',
                        height: '32px',
                        marginRight: '14px',
                        marginTop: '8px',
                      }}
                    />
                    <div style={{ marginTop: "9px" }}>
                      <Button
                        color="primary"
                        size="sm"
                        type="submit"
                        disabled={jumpToVariationLoading}
                      >
                        <i className="fa fa-upload"></i> Jump To Variation
                      </Button>
                    </div>
                  </div>
                </Form>
                <a
                  href={downloadData}
                  id="downloadGearInventory"
                  download
                  hidden
                  className="hide"
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
                <Form onSubmit={handleInventoryUpload}>
                  <div className="rightpaneltop">
                    <Input
                      type="file"
                      id="file-input"
                      accept=".csv*"
                      required
                      onChange={onChange}
                      style={{
                        width: '200px',
                        height: '32px',
                        paddingTop: "3px",
                        marginRight: '14px',
                        marginTop: '8px',
                      }}
                    />
                    <Button type="submit" size="sm" color="primary" className="modalFooter" disabled={loading}>
                      {loading ? (
                        <div class="loader-1 center"><span></span></div>
                      ) : (
                        <>
                          <i className="fa fa-upload"></i> Import Inventory
                        </>
                      )}
                    </Button>
                  </div>
                </Form>
                <div className="rightpaneltop">
                  <Button
                    color="primary"
                    size="sm"
                    onClick={handleInventoryDownload}
                  >
                    <i className="fa fa-download"></i> Export Inventory
                  </Button>
                </div>
                <div className="rightpaneltop">
                  Products Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>
              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      defaultColDef={{
                        resizable: true,
                      }}
                      loadingCellRenderer={customLoadingCellRenderer}
                      loadingCellRendererParams={loadingCellRendererParams}
                      domLayout={'autoHeight'}
                      rowModelType={'infinite'}
                      columnDefs={columns}
                      pagination={true}
                      sizeColumnsToFit={true}
                      onFilterChanged={filterChanged}
                      onGridReady={onGridReady}
                      paginationPageSize={sortingParams.limit}
                      cacheBlockSize={sortingParams.limit}
                      cacheQuickFilter={true}
                      onFirstDataRendered={onFirstDataRendered}
                      onPaginationChanged={onPaginationChanged}
                      ref={gridRef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={model} toggle={toggleViewDetailModal}>
        <ModalHeader toggle={toggleViewDetailModal}>Report of invalid data</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Table bordered striped responsive size="sm">
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Stock Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {invalidData && invalidData.map((item) => (
                    <tr key={item.id}>
                      {Object.values(item).map((val) => (
                        <td>{val}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="danger" onClick={toggleViewDetailModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

ProductsList.propTypes = {
  getProductsList: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  gearProductsList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  getCategories: PropTypes.func.isRequired,
  gearCategorySubCategoryList: PropTypes.array.isRequired,
  subLoading: PropTypes.bool.isRequired,
  getInventoriesToDownload: PropTypes.func.isRequired,
  jumpToVariation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.gearProduct.inventoryLoading,
  gearProductsList: state.gearProduct.gearProductsList,
  gearCategorySubCategoryList: state.gearProduct.gearCategorySubCategoryList,
  subLoading: state.product.isSubLoading,
  uploadInventoryForGear: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, {
  getProductsList,
  deleteProduct,
  changeStatus,
  getCategories,
  removeProductFromCart,
  getInventoriesToDownload,
  jumpToVariation,
  uploadInventoryForGear,
})(ProductsList);
