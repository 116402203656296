import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { getProductById } from 'actions/admin/product';

import BasicInfo from './BasicInfo';
import ProductImages from './ProductImages';
import ProductAttributes from './ProductAttributes';
import ProductVariations from './ProductVariations';
import Spinner from 'views/Spinner';

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Col,
  Row,
} from 'reactstrap';
import classnames from 'classnames';
import VariationImages from './VariationImages';
import StackableProduct from './StackableProduct';
import ProductSeoDetail from './ProductSeoDetail';

const EditProduct = ({
  match,
  getProductById,
  // loading,
  currentProduct,
  history,
}) => {
  const { product_id: product_id } = useParams();
  //################ insure that currentProduct should has new state  #######################
  const [newRecord, setNewRecord] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState({
    productTab: 'basic_info_tab',
  });
  useMemo(() => {
    getProductById(product_id).then((res) => {
      setNewRecord(true);
    });
  }, [product_id, getProductById]);

  //########################## set the initial active tab ##############################
  useEffect(() => {
    if (searchParams.get('variation')) {
      changeTab('variations_tab');
    }
  }, []);

  //########################## change the active tab ##############################
  const changeTab = (tab) => {
    if (activeTab.productTab !== tab)
      setActiveTab({ ...activeTab, productTab: tab });
  };

  return !newRecord || Object.keys(currentProduct).length === 0 ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn editProdBtn">
      <Row>
        <Col xs="12" sm="12">
          <Nav tabs className="productTabs">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab.productTab === 'basic_info_tab',
                })}
                onClick={() => {
                  changeTab('basic_info_tab');
                }}
              >
                Basic Info
              </NavLink>
            </NavItem>
            {}

            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab.productTab === 'attributes_tab',
                })}
                onClick={() => {
                  changeTab('attributes_tab');
                }}
              >
                Attributes
              </NavLink>
            </NavItem>
            {currentProduct.attributes &&
            currentProduct.attributes.length > 0 ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab.productTab === 'variations_tab',
                  })}
                  onClick={() => {
                    changeTab('variations_tab');
                  }}
                >
                  Variations
                </NavLink>
              </NavItem>
            ) : (
              ''
            )}
            {currentProduct.attributes &&
            currentProduct.attributes.length > 0 ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab.productTab === 'images_tab',
                  })}
                  onClick={() => {
                    changeTab('images_tab');
                  }}
                >
                  Images
                </NavLink>
              </NavItem>
            ) : (
              ''
            )}
            {currentProduct.productImages &&
            currentProduct.productImages.images.length > 0 ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab.productTab === 'attribute_images',
                  })}
                  onClick={() => {
                    changeTab('attribute_images');
                  }}
                >
                  Color
                </NavLink>
              </NavItem>
            ) : (
              ''
            )}

            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab.productTab === 'stackable_tab',
                })}
                onClick={() => {
                  changeTab('stackable_tab');
                }}
              >
                Stackable Product
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab.productTab === 'seo_tab',
                  disabled: product_id === null ? true : false,
                })}
                onClick={() => {
                  changeTab('seo_tab');
                }}
              >
                Seo Details
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab.productTab}>
            <TabPane tabId="basic_info_tab">
              <BasicInfo
                product_id={product_id}
                history={history}
                changeTab={changeTab}
              />
            </TabPane>
            <TabPane tabId="images_tab">
              <ProductImages
                product_id={product_id}
                history={history}
                changeTab={changeTab}
              />
            </TabPane>
            <TabPane tabId="attributes_tab" key="attributes_tab">
              <ProductAttributes
                history={history}
                product_id={product_id}
                changeTab={changeTab}
              />
            </TabPane>
            <TabPane tabId="variations_tab" key="variations_tab">
              <ProductVariations
                product_id={product_id}
                history={history}
                openvariation={searchParams.get('variation')}
              />
            </TabPane>
            <TabPane tabId="attribute_images" key="attribute_images">
              <VariationImages product_id={product_id} history={history} />
            </TabPane>
            <TabPane tabId="stackable_tab">
              <StackableProduct
                product_id={product_id}
                history={history}
                changeTab={changeTab}
              />
            </TabPane>
            <TabPane tabId="seo_tab">
              <ProductSeoDetail
                history={history}
                changeTab={changeTab}
                product_id={product_id}
              />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

EditProduct.propTypes = {
  getProductById: PropTypes.func.isRequired,
  currentProduct: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  currentProduct: state.product.currentProduct,
});

export default connect(mapStateToProps, { getProductById })(EditProduct);
