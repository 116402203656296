import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Input,
  FormText,
  Col,
  Row,
} from "reactstrap";

import { DISPLAYED_DEALER_APPROVED_STATUS } from "constants/index";

import {
  dealerApproveDisapproveInBulk,
  markAsFeaturedInBulk,
  changeDealerTypeInBulk,
} from "actions/admin/dealer";

const BulkActions = (props) => {
  //################ Dropdown ############################
  const [openDropdown, setOpenDropdown] = useState(false);
  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };
  const [isDisabled, setIsDisabled] = useState(false);
  //################ End Dropdown ############################

  //################ Status change Approve/Rejected ############################
  const [statusModal, setOpenStatusModal] = useState(false);
  const toggleStatusModal = () => {
    setOpenStatusModal(!statusModal);
  };

  let initialStatusFormData = {
    bulkIds: props.selectedRows,
    status: "",
  };
  const [statusFormData, setStatusFormData] = useState(initialStatusFormData);
  const resetStatusModal = () => {
    setStatusFormData(initialStatusFormData);
  };
  const [statusFormDataErrors, setStatusFormDataErrors] = useState(false);
  const handelOnChange = (e) => {
    setStatusFormData({
      ...statusFormData,
      status: e.target.value,
    });
  };
  const handelOnClick = (e) => {
    if (statusFormData.status) {
      if (
        window.confirm(
          `Are you sure, you want to ${DISPLAYED_DEALER_APPROVED_STATUS[statusFormData.status]
          } dealer(s)?`
        )
      ) {
        //2 for approve, 3 for disapprove
        props.dealerApproveDisapproveInBulk(statusFormData);
        setOpenStatusModal(false);
      }
    } else {
      setStatusFormDataErrors(true);
    }
  };
  //################ End Status change Approve/Rejected ############################

  //################ Mark as featured ############################
  const handelOnClickMarkAsFeatured = (e) => {
    if (window.confirm(`Are you sure, you want to mark dealer(s) as featured?`)) {
      //2 for approve, 3 for disapprove
      props.markAsFeaturedInBulk(props.selectedRows);
    }
  };
  //################ End Mark as featured ############################

  //################ Change dealer type ############################
  const [dealerTypeModal, setDealerTypeModal] = useState(false);
  const toggleDealerTypeModal = () => {
    setDealerTypeModal(!dealerTypeModal);
  };
  let initialDealerTypeFormData = {
    bulkIds: props.selectedRows,
    type: "",
  };
  const [dealerTypeFormData, setDealerTypeFormData] = useState(
    initialDealerTypeFormData
  );
  const resetDealerTypeModal = () => {
    setDealerTypeFormData(initialDealerTypeFormData);
  };
  const [dealerTypeFormDataErrors, setDealerTypeFormDataErrors] = useState(
    false
  );
  const handelDealerTypeOnChange = (e) => {
    setDealerTypeFormData({
      ...dealerTypeFormData,
      type: e.target.value,
    });
  };
  const handelDealerTypeOnClick = (e) => {
    if (dealerTypeFormData.type) {
      if (window.confirm(`Are you sure, you want to change dealer Type?`)) {
        props.changeDealerTypeInBulk(dealerTypeFormData);
        setDealerTypeModal(false);
      }
    } else {
      setDealerTypeFormDataErrors(true);
    }
  };
  //################ End Change dealer type ############################

  useEffect(() => {
    //disable bulk actions button if no row selected
    if (props.selectedRows.length > 0) setIsDisabled(false);
    else setIsDisabled(true);

    //set selected id's array whan row selection changed
    setStatusFormData({
      status: "",
      bulkIds: props.selectedRows,
    });

    //set selected id's array whan row selection changed
    setDealerTypeFormData({
      // ...initialDealerTypeFormData,
      type: "",
      bulkIds: props.selectedRows,
    });
  }, [setIsDisabled, props.selectedRows]);

  return (
    <>
      <Dropdown
        isOpen={openDropdown}
        toggle={toggleDropdown}
        size="sm"
        key="bulkDropdown"
      >
        <DropdownToggle caret>Bulk Actions</DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            disabled={isDisabled}
            onClick={handelOnClickMarkAsFeatured}
          >
            Mark As featured
          </DropdownItem>
          <DropdownItem onClick={toggleStatusModal} disabled={isDisabled}>
            Activate / deactivate
          </DropdownItem>
          <DropdownItem onClick={toggleDealerTypeModal} disabled={isDisabled}>
            Change dealer type
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <Modal
        isOpen={statusModal}
        toggle={toggleStatusModal}
        onClosed={resetStatusModal}
      >
        <ModalHeader toggle={toggleStatusModal}>Change Status</ModalHeader>
        <Form className="form-horizontal">
          <ModalBody>
            {Object.keys(DISPLAYED_DEALER_APPROVED_STATUS).map(function (key) {
              return key === 2 || key === 3 ? (
                <FormGroup check inline key={key}>
                  <Input
                    className="form-check-input"
                    type="radio"
                    id={key}
                    name="status"
                    value={key}
                    onChange={(e) => handelOnChange(e)}
                  />
                  <Label className="form-check-label" check htmlFor={key}>
                    {DISPLAYED_DEALER_APPROVED_STATUS[key]}
                  </Label>
                </FormGroup>
              ) : (
                ""
              );
            })}
            {statusFormDataErrors ? (
              <FormText className="invalid-feedback-text">
                Please select status
              </FormText>
            ) : (
              ""
            )}
          </ModalBody>
          <ModalFooter>
            <Button size="sm" color="primary" onClick={(e) => handelOnClick(e)}>
              <i className="fa fa-dot-circle-o"></i> Submit
            </Button>
            <Button size="sm" color="danger" onClick={toggleStatusModal}>
              Close
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={dealerTypeModal}
        toggle={toggleDealerTypeModal}
        onClosed={resetDealerTypeModal}
      >
        <ModalHeader toggle={toggleDealerTypeModal}>
          Change Dealer Type
        </ModalHeader>
        <Form className="form-horizontal">
          <ModalBody>
            <Row>
              <Col>
                <FormGroup check className="checkbox">
                  <Input
                    type="select"
                    name="bulk_dealer_type"
                    id="bulk_dealer_type"
                    // innerRef={n => (dealer_type_id = n)}
                    onChange={(e) => handelDealerTypeOnChange(e)}
                  >
                    <option value={""}> {"Select Dealer Type"} </option>
                    {props.dealerTypeList
                      ? props.dealerTypeList.map((fieldData, index) => {
                        return (
                          <option key={index} value={fieldData._id}>
                            {fieldData.name}
                          </option>
                        );
                      })
                      : ""}
                  </Input>
                </FormGroup>
                {dealerTypeFormDataErrors ? (
                  <FormText className="invalid-feedback-text">
                    Please select dealer type
                  </FormText>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              color="primary"
              onClick={(e) => handelDealerTypeOnClick(e)}
            >
              <i className="fa fa-dot-circle-o"></i> Submit
            </Button>
            <Button size="sm" color="danger" onClick={toggleDealerTypeModal}>
              Close
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

BulkActions.propTypes = {
  dealerApproveDisapproveInBulk: PropTypes.func.isRequired,
  markAsFeaturedInBulk: PropTypes.func.isRequired,
  changeDealerTypeInBulk: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {
  dealerApproveDisapproveInBulk,
  markAsFeaturedInBulk,
  changeDealerTypeInBulk,
})(BulkActions);
