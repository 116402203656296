/* eslint-disable */
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import {
  getInquiriesList,
  deleteInquiry,
  changeStatus,
  getInquiryToDownload,
} from 'actions/admin/inquiry';

import * as Constants from 'constants/index';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from 'utils/Loading';

import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from 'reactstrap';
import PerPageSelect from '../PerPageSelect';

const renderName = (params) => {
  if (params.data === undefined) {
    return <Loading />;
  } else {
    return <div>{params.data?.name}</div>;
  }
};

const actions = (
  <div className="add-button-div specify-width">
    {/* <Link to="/admin/inquiries/add" className="addNewElementClass">
      <Button color="primary" size="sm">
        <i className="fa fa-plus"></i> Add Inquiry
      </Button>
    </Link> */}
  </div>
);

const InquiriesList = ({
  getInquiriesList,
  deleteInquiry,
  changeStatus,
  history,
  inquiryList: { data, count },
  loading,
  subLoading,
}) => {
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
          limit: Constants.DEFAULT_PAGE_SIZE,
          page: 1,
          orderBy: 'status',
          ascending: 'desc',
          query: '',
          filter: {},
          onLoad: true,
        }
  );

  // to resolve useEffect dependency error

  //################## to get updated list ###################
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [downloadData, setDownloadData] = useState([]);
  const [isModel, setIsModel] = useState(false);
  const [inquiryData, setInquiryData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  //#########################Colums defination start ###################
  const columns = [
    {
      field: 'inquiry_ID',
      headerName: 'Inquiry Id',
      suppressMovable: true,
      width: 140,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'inquiry_type',
      headerName: 'Inquiry Type',
      width: 150,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      suppressMovable: true,
      width: 140,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      colSpan: (params) => {
        if (params.data === undefined) {
          return 5;
        } else {
          return 0;
        }
      },
      cellRenderer: (params) => renderName(params),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'phone',
      headerName: 'Phone',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      width: 150,
      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => (
        <p>
          {params.data?.phone && params.data?.ccode}
          {params.data?.phone}
        </p>
      ),
    },
    {
      field: 'created_at',
      headerName: 'Date',
      suppressSizeToFit: false,
      suppressMovable: true,
      width: 120,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agDateColumnFilter',
      // pass in additional parameters to the text filter
      filterParams: {
        closeOnApply: true,
        suppressAndOrCondition: true,
        filterOptions: Constants.DATE_FILTER_OPTIONS,
        debounceMs: 800,
        buttons: ['reset', 'apply'],
      },
      cellRenderer: (params) => (
        <>
          <div className="primary-Outer">
            <Moment
              format={
                process.env.REACT_APP_DISPLAY_DATE_FORMATE ?? 'MM/DD/YYYY'
              }
              locale="de"
            >
              {params.value}
            </Moment>
            <span>
              <Moment format="HH:mm" locale="de">{params.value}</Moment>
            </span>
          </div>
        </>
      ),
    },
    {
      field: '_id',
      headerName: 'Actions',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) => (
        <div>
          {params.data?.status === 1 && (
            <Button
              type="button"
              size="sm"
              color="info"
              title="View"
              onClick={toggleModel}
              data-id={params.data._id}
            >
              <i className="fa fa-eye"></i>
            </Button>
          )}
          <Button
            type="button"
            size="sm"
            color="danger"
            title="Delete"
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure, you want to delete ${params.data?.name} inquiry?`
                )
              ) {
                deleteInquiry(params.data?._id, history).then((res) => {
                  if (res) gridApi.purgeInfiniteCache();
                });
              }
            }}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
  ];
  //#########################Colums defination end ###################

  //################## START Set properties(options) of datatables ###################

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });
  const toggleModel = (e) => {
    if (e?.currentTarget?.getAttribute('data-id') == 'closeModel') {
      setIsModel(!isModel);
    } else {
      let prodId = e?.currentTarget?.getAttribute('data-id');
      const newFilterData = inquiryData.filter((item) => item._id === prodId);
      setFilteredData(newFilterData);
      setIsModel(!isModel);
    }
  };

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };

          const customData = await getInquiriesList(customParams);
          setInquiryData(customData.data.response[0].data);
          if (customData?.data?.status) {
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });

  const handleCSVDownload = useCallback(async (event) => {
    let data = await getInquiryToDownload(sortingParams);
    setDownloadData(data.data);
    document.getElementById('downloadCsv').click();
  });

  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                <div className="add-button-div">
                  <a
                    href={downloadData}
                    id="downloadCsv"
                    download
                    hidden
                    className="hide"
                    target="_blank"
                    rel="noopener noreferrer"
                  ></a>
                </div>
                {actions}
                {
                  <div className="rightpaneltop">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={handleCSVDownload}
                    >
                      <i className="fa fa-download"></i> Export CSV
                    </Button>
                  </div>
                }
                <div className="rightpaneltop">
                  Inquiries Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>
              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      defaultColDef={{
                        resizable: true,
                      }}
                      loadingCellRenderer={customLoadingCellRenderer}
                      loadingCellRendererParams={loadingCellRendererParams}
                      domLayout={'autoHeight'}
                      rowModelType={'infinite'}
                      columnDefs={columns}
                      pagination={true}
                      sizeColumnsToFit={true}
                      onFilterChanged={filterChanged}
                      onGridReady={onGridReady}
                      paginationPageSize={sortingParams.limit}
                      cacheBlockSize={sortingParams.limit}
                      cacheQuickFilter={true}
                      onFirstDataRendered={onFirstDataRendered}
                      onPaginationChanged={onPaginationChanged}
                      ref={gridRef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {filteredData && filteredData.length > 0 && (
        <Modal isOpen={isModel} toggle={toggleModel}>
          <ModalHeader toggle={toggleModel}>Inquiry Detail</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <Table bordered striped responsive size="sm">
                  <tbody>
                    <tr>
                      <td>Inquiry Id</td>
                      <td>{filteredData[0].inquiry_ID}</td>
                    </tr>
                    <tr>
                      <td>Inquiry Type</td>
                      <td>{filteredData[0].inquiry_type}</td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>{filteredData[0].name}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{filteredData[0].email}</td>
                    </tr>
                    <tr className="data-ptag">
                      <td>Contact Number</td>
                      <td>
                        {filteredData[0]?.phone
                          ? filteredData[0].ccode + '' + filteredData[0].phone
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td>Message</td>
                      <td>{filteredData[0].message}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              color="danger"
              data-id="closeModel"
              onClick={toggleModel}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

InquiriesList.propTypes = {
  getInquiriesList: PropTypes.func.isRequired,
  deleteInquiry: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  inquiryList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  inquiryList: state.inquiry.inquiriesList,
  loading: state.inquiry.loading,
  subLoading: state.inquiry.isSubLoading,
});

export default connect(mapStateToProps, {
  getInquiriesList,
  deleteInquiry,
  changeStatus,
})(InquiriesList);
