/* eslint-disable */
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    getAttributes,
    editProduct,
    deleteTag,
    addOption,
} from 'actions/admin/GearProduct/gearProduct';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';
import Select from 'react-select/creatable';
import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';


const GearProductAttributes = ({
    product_id,
    gearCurrentProduct,
    getAttributes,
    attributes_loading,
    gearAttributesValueList,
    changeTab,
    loading,
    editProduct,
    addOption,
    deleteTag,
    attributes_option_loading,
}) => {

    const [attributesValueListData, setAttributesValueListData] = useState([]);
    useEffect(() => {
        setAttributesValueListData(gearAttributesValueList?.attributeList ?? gearAttributesValueList);
    }, [gearAttributesValueList])


    //########################## enable disable save & next button ############################
    const [enableDisableSaveNext, setEnableDisableSaveNext] = useState(true);


    //########################## set loader to reload the select box ############################
    const [setLoader, setSetLoader] = useState(null);

    //########################## set already exist attributes list in form data ############################
    const [productAttributeDetail, setProductAttributeDetail] = useState(
        gearCurrentProduct.attributes ? gearCurrentProduct.attributes : []
    );

    useEffect(() => {
        let attri = attributesValueListData.filter((iter) => iter.name === 'Color')[0];
        //let attri1 = attributesValueListData.filter((iter) => iter.name === 'Flex')[0];

        if (attri && productAttributeDetail.length === 0) {

            let b = [
                ...productAttributeDetail,
                {
                    attributeId: {
                        _id: attri._id,
                        name: attri.name,
                    },
                    values: [],
                },
            ];


            let c = [...b];

            if (gearCurrentProduct.category_data?.length && gearCurrentProduct.category_data[0].slug.includes("iron")) {
                let attri2 = attributesValueListData.filter((iter) => iter.name === 'Iron #')[0];
                let attri3 = attributesValueListData.filter((iter) => iter.name === 'Tip Diameter')[0];
                let irons = [
                    ...productAttributeDetail,
                    {
                        attributeId: {
                            _id: attri2._id,
                            name: attri2.name,
                        },
                        values: [],
                    },
                ];

                let tip_diameteres = [
                    ...productAttributeDetail,
                    {
                        attributeId: {
                            _id: attri3._id,
                            name: attri3.name,
                        },
                        values: [],
                    },
                ];

                c = [...c, ...irons, ...tip_diameteres];

            }

            setProductAttributeDetail(c);
            setIsForFirstTime(true);
        }
    }, [changeTab]);

    useMemo(() => {
        getAttributes();
    }, [getAttributes]);

    //########### add attribute to product attributes on select of attributes from list ####################
    const onChange = (e) => {
        let newProductAttribute =
            productAttributeDetail.length > 0
                ? [
                    ...productAttributeDetail,
                    {
                        attributeId: {
                            _id: e.target.value,
                            name: e.target[e.target.selectedIndex].text,
                        },
                        values: [],
                    },
                ]
                : [
                    {
                        attributeId: {
                            _id: e.target.value,
                            name: e.target[e.target.selectedIndex].text,
                        },
                        values: [],
                    },
                ];
        setProductAttributeDetail(newProductAttribute);
        setIsForFirstTime(true);
    };

    //########### add values to attributes on select of values ####################
    const handleAttributeValueChange = async (e, action) => {
        setSetLoader(action.name);
        if (action.action === 'select-option') {
            let newSelectedAttributeOptionList = productAttributeDetail;
            await newSelectedAttributeOptionList.forEach((value) => {
                if (value.attributeId._id === action.name) {
                    value.values.push(action.option.value);
                    value.optionDetails.push(action.option);
                }
            });
            setProductAttributeDetail(newSelectedAttributeOptionList);
            setEnableDisableSaveNext(false);
            setSetLoader(null);
        } else if (
            action.action === 'remove-value' ||
            action.action === 'pop-value'
        ) {
            let isToRemove = true;
            if (product_id !== null) {
                isToRemove = await deleteTag(action.removedValue.value, product_id);
            }
            if (isToRemove) {
                let canSaveAndGoToNext = false;
                let newSelectedAttributeOptionList = productAttributeDetail;
                await newSelectedAttributeOptionList.map(async (value) => {
                    if (value.attributeId._id === action.name) {
                        let optionDetailIdx = await value.optionDetails.indexOf(
                            action.removedValue
                        );
                        await value.optionDetails.splice(optionDetailIdx, 1);
                        let selectedValuesIdx = await value.values.indexOf(
                            action.removedValue.value
                        );
                        await value.values.splice(selectedValuesIdx, 1);
                    }
                    if (value.values.length > 0) canSaveAndGoToNext = true;
                });
                setProductAttributeDetail(newSelectedAttributeOptionList);
                setEnableDisableSaveNext(!canSaveAndGoToNext);
                setSetLoader(null);
            } else setSetLoader(null);
        }
    };

    const onCreateOption = async (e, attribute_id) => {
        setSetLoader(attribute_id);
        await addOption(attribute_id, { option: e }).then(async (res) => {
            if (res.status) {
                let newSelectedAttributeOptionList = productAttributeDetail;
                await newSelectedAttributeOptionList.forEach((value) => {
                    if (value.attributeId._id === attribute_id) {
                        value.values.push(res.data._id);
                        value.optionDetails.push({
                            label: res.data.option,
                            value: res.data._id,
                        });
                        value.allValues.push({
                            label: res.data.option,
                            value: res.data._id,
                        });
                    }
                });
                setProductAttributeDetail(newSelectedAttributeOptionList);
                setEnableDisableSaveNext(false);
            }
            setSetLoader(null);
        });
    };

    //########### handel submit form  ########################
    const onSubmit = (e) => {
        e.preventDefault();
        if (productAttributeDetail.length > 0) {
            let cate_slug = gearCurrentProduct.category_data[0].slug;
            editProduct(product_id, productAttributeDetail, cate_slug).then((res) => {
                if (res === true) changeTab('variations_tab');
            });
        }
    };

    //########### useState to update product attribute options  ########################
    const [isForFirstTime, setIsForFirstTime] = useState(true);

    // to resolve useEffect dependency error

    useEffect(() => {

        let canSaveAndGoToNext = false;
        if (isForFirstTime && attributesValueListData.length > 0) {

            let list = productAttributeDetail;
            list.forEach((value) => {
                let attributeDetail = attributesValueListData.filter((attribute) =>
                    attribute._id.includes(value.attributeId._id)
                );
                let selectedOptions = [];
                value.values.forEach((optn) => {
                    canSaveAndGoToNext = true;
                    let optionDetail = attributeDetail[0].terms.filter((options) =>
                        options._id.includes(optn)
                    );
                    if (optionDetail.length > 0) {
                        selectedOptions.push({
                            label: optionDetail[0].option,
                            value: optn,
                        });
                    }
                });
                value.optionDetails = selectedOptions;

                value.allValues = [];
                attributeDetail[0].terms.map((optn) =>
                    value.allValues.push({
                        label: optn.option,
                        value: optn._id,
                    })
                );
            });
            setProductAttributeDetail(list);
            setIsForFirstTime(false);
            setEnableDisableSaveNext(!canSaveAndGoToNext);
        }
    }, [productAttributeDetail, attributesValueListData]);


    const deleteAttributeDiv = (divId, AttributeData) => {
        if (AttributeData.values.length > 0) {

            alert('Please remove nested detail first.');
            return false;
        } else {
            const deletedDiv = productAttributeDetail.filter((item) => item.attributeId._id !== divId);
            setProductAttributeDetail(deletedDiv);
        }

    };


    return loading ? (
        <Spinner />
    ) : (
        <>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
                <Row>
                    <Col xs="12" sm="12">
                        <Card>
                            <CardBody>
                                <FormGroup>
                                    <Label htmlFor="attribute_id">Attributes</Label>
                                    <Input
                                        type="select"
                                        name="attribute_id"
                                        id="attribute_id"
                                        onChange={(e) => onChange(e)}
                                        value={''}
                                    >
                                        <option value={''}> {'Select'} </option>
                                        {attributesValueListData &&
                                            attributesValueListData.length > 0 &&
                                            attributesValueListData.map((value, index) => (
                                                <option
                                                    key={index}
                                                    value={value._id}
                                                    label={value.name}
                                                    disabled={
                                                        productAttributeDetail.length > 0
                                                            ? productAttributeDetail.some(
                                                                (attribute) =>
                                                                    attribute.attributeId._id === value._id
                                                            )
                                                            : false
                                                    }
                                                >
                                                    {value.name}
                                                </option>
                                            ))}
                                    </Input>
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12">
                        <Row>
                            <Col sx="12" sm="12">
                                {

                                    productAttributeDetail &&
                                    productAttributeDetail.length > 0 &&
                                    productAttributeDetail.map((value, index) => (
                                        <>
                                            <Card>
                                                <CardHeader className="cardheadertab">
                                                    <>
                                                        <div>
                                                            {value.attributeId.name}
                                                            {(!['Color'].includes(value.attributeId.name)) &&
                                                                <span
                                                                    className="closetab"
                                                                    onClick={(e) => deleteAttributeDiv(value.attributeId._id, value)}
                                                                >
                                                                    X
                                                                </span>}
                                                        </div>
                                                    </>

                                                </CardHeader>
                                                <CardBody>
                                                    <FormGroup className="attribute-options">
                                                        <Label htmlFor="attribute_id">
                                                            Options <span>*</span>
                                                        </Label>
                                                        {setLoader === value.attributeId._id ? (
                                                            <Spinner />
                                                        ) : (
                                                            <>
                                                                <Select
                                                                    value={value.optionDetails}
                                                                    options={value.allValues}
                                                                    onChange={(e, action = { action }) => {
                                                                        handleAttributeValueChange(e, action);
                                                                    }}
                                                                    name={value.attributeId._id}
                                                                    onCreateOption={(e) => {
                                                                        onCreateOption(e, value.attributeId._id);
                                                                    }}
                                                                    isMulti={true}
                                                                    keepSelectedInList={false}
                                                                    isClearable={false}
                                                                    isLoading={
                                                                        attributes_loading || attributes_option_loading
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                <Errors current_key="name" key="name" />
                                                            </>
                                                        )}
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </>

                                    ))}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="Justify-flex-end">
                        <FormGroup key={2}>
                            <Button
                                className="mr-05"
                                type="submit"
                                size="sm"
                                color="primary"
                                disabled={enableDisableSaveNext}
                            >
                                <i className="fa fa-dot-circle-o"></i> Save & Next
                            </Button>
                            <Link to="/admin/gear-products">
                                <Button type="reset" size="sm" color="danger">
                                    <i className="fa fa-ban"></i> Cancel
                                </Button>
                            </Link>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

GearProductAttributes.defaultProps = {
    product_id: null,
};

GearProductAttributes.propTypes = {
    attributes_loading: PropTypes.bool.isRequired,
    gearCurrentProduct: PropTypes.object.isRequired,
    getAttributes: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    editProduct: PropTypes.func.isRequired,
    deleteTag: PropTypes.func.isRequired,
    addOption: PropTypes.func.isRequired,
    attributes_option_loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
    attributes_loading: state.gearProduct.attributes_loading,
    gearCurrentProduct: state.gearProduct.gearCurrentProduct,
    gearAttributesValueList: state.gearProduct.gearAttributesValueList,
    loading: state.gearProduct.loading,
    attributes_option_loading: state.gearProduct.attributes_option_loading,
});

export default connect(mapStateToProps, {
    getAttributes,
    editProduct,
    deleteTag,
    addOption,
})(GearProductAttributes);
