import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Multiselect } from 'multiselect-react-dropdown';
import {
    setImageForAttribute,
    removeImageForAttribute,
} from 'actions/admin/GearProduct/gearProduct';
import {
    Modal,
    ModalBody
} from 'reactstrap';
const GearVariationImages = ({
    product_id,
    currentProduct,
    setImageForAttribute,
    removeImageForAttribute,
}) => {

    const [options, setOptions] = useState([]);
    const [modalImage, setModalImage] = useState('');

    // useEffect(() => {
    //     if (Object.keys(currentProduct).length > 1 && currentProduct?.attributes) {
    //         const colors = currentProduct?.attributes.find(attr => attr.attributeId.name.toLowerCase() === 'color');
    //         setOptions(colors.branding_attribute);
    //     }
    // }, [currentProduct]);

    useEffect(() => {
        if (Object.keys(currentProduct).length > 1 && currentProduct?.variations) {
            const sku_list = currentProduct?.variations.map((vari) => {
                return { label: vari?.sku, value: vari?.sku, images: vari?.images }
            });
            setOptions(sku_list);
        }
    }, [currentProduct]);

    const checkSelectd = (img) => {
        let newArr = [];
        let arr = options.filter((iter) =>
            iter.images && iter.images.includes(img)
                ? newArr.push({ ...iter })
                : []
        );

        return newArr;
    };

    const onOpenModal = (src) => {
        setModalImage(src)
    }

    const onCloseModal = () => {
        setModalImage('')
    }

    const onSelect = (selectedList, selectedItem) => {
        setImageForAttribute(selectedList, product_id, selectedItem).then(
            (res) => res
        );
    };

    const onRemove = (removedItem, imageName) => {
        removeImageForAttribute(product_id, removedItem.value, imageName).then((res) => res);
    };

    const Image = ({ src, alt }) => {
        return (
            <div className="image-container">
                <div className='gallery-image-container' onClick={() => onOpenModal(src)}>
                    <img src={src} alt={alt} className="gallery-image" />
                </div>
                {/* <div className="modal">
                    <img src={src} alt={alt} className="modal-image" />
                </div> */}
            </div>
        );
    };

    return (
        <>
            <div style={{ width: '40rem' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Variations</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentProduct && currentProduct?.variations &&
                            currentProduct?.gearProductImages?.images.map((i, index) => {
                                return (
                                    <>
                                        <tr>
                                            {' '}
                                            <td>
                                                {/* <img
                                                src={
                                                    'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/product/' +
                                                    i.image
                                                }
                                                className="preview-img"
                                            /> */}
                                                <Image key={index} src={'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/product/' +
                                                    i.image} alt="product image" />
                                            </td>
                                            <td>
                                                <Multiselect
                                                    id={i.image}
                                                    options={options} // Options to display in the dropdown
                                                    selectedValues={checkSelectd(i.image)} // Preselected value to persist in dropdown
                                                    onSelect={(_list, item) => onSelect(item.value, i.image)} // Function will trigger on select event
                                                    onRemove={(_list, id) => onRemove(id, i.image)} // Function will trigger on remove event
                                                    displayValue="label" // Property name to display in the dropdown options
                                                />
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            <Modal isOpen={modalImage !== ""} toggle={onCloseModal} size="lg">
                <ModalBody toggle={onCloseModal}>
                    <img src={modalImage} alt="Image" className='modal-image' />
                </ModalBody>
            </Modal>
        </>
    );
};

GearVariationImages.defaultProps = {
    product_id: null,
};

const mapStateToProps = (state) => ({
    currentProduct: state.gearProduct.gearCurrentProduct,
    productImages: state.gearProduct.gearProductImages,
});

export default connect(mapStateToProps, {
    setImageForAttribute,
    removeImageForAttribute,
})(GearVariationImages);
