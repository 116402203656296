/* eslint-disable */
import React, { useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { edit, getDealerTypeById } from 'actions/admin/dealerType';

import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { REMOVE_ERRORS } from 'actions/types';

const EditDealerType = ({
  getDealerTypeById,
  edit,
  dealerType: { currentDealerType, loading },
  history,
  match,
  errorList,
}) => {
  /**************** use state for form data *********************/
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    discount: '',
    is_name_updated: false,
  });

  // to resolve useEffect dependency error

  /**************** use effect to get current category *********************/
  const { dealertype_id: dealertype_id } = useParams();
  useMemo(() => {
    getDealerTypeById(dealertype_id);
  }, [dealertype_id]);

  /**************** use effect to set form data from current category *********************/
  useMemo(() => {
    if (Object.keys(currentDealerType).length > 0) {
      setFormData({
        name: currentDealerType.name ? currentDealerType.name : '',
        discount:
          currentDealerType.discount || currentDealerType.discount === 0
            ? currentDealerType.discount
            : '',
        is_name_updated: false,
      });
    }
  }, [currentDealerType]);

  /**************** expload from data *********************/
  const { name, discount } = formData;

  /**************** use dispatch *********************/

  /**************** handle on change event *********************/
  useEffect(() => {
    let updated_name = name.replace(/[^a-zA-Z0-9\s]/gi, '');
    setFormData({ ...formData, name: updated_name });
  }, [name]);

  const onChange = (e) => {
    if (
      e.target.name === 'name' &&
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1) !==
        currentDealerType?.name
    ) {
      setFormData({
        ...formData,
        is_name_updated: true,
        [e.target.name]: e.target.value,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  /**************** handle on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    Object.keys(formData).forEach((k) => {
      typeof formData[k] === 'string'
        ? (formData[k] = formData[k]?.trim())
        : (formData[k] = formData[k]);
    });
    edit(formData, navigate, dealertype_id);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="12">
          <Card>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="name">
                        Name <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="name"
                        name="name"
                        maxLength="100"
                        // pattern={/^[a-zA-Z0-9]+$/}
                        value={name}
                        placeholder="Enter Name"
                        onChange={(e) => onChange(e)}
                        invalid={errorList.name ? true : false}
                      />
                      <Errors current_key="name" key="name" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="discount">
                        {' '}
                        Discount (in %) <span>*</span>
                      </Label>
                      <Input
                        type="number"
                        id="discount"
                        name="discount"
                        min={0}
                        max={100}
                        step={0.01}
                        // maxLength="100"
                        value={discount}
                        placeholder="Enter Discount"
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.discount ? true : false}
                      />
                      <Errors current_key="discount" key="discount" />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button
                  className="mr-05"
                  type="submit"
                  size="sm"
                  color="primary"
                  disabled={loading}
                >
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
                <Link to="/admin/dealer-types">
                  <Button type="reset" size="sm" color="danger">
                    <i className="fa fa-ban"></i> Cancel
                  </Button>
                </Link>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditDealerType.propTypes = {
  getDealerTypeById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  dealerType: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  dealerType: state.dealerType,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  getDealerTypeById,
  edit,
})(EditDealerType);
