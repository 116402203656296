/* eslint-disable */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from '../../../../utils/Loading';

import { Button, Card, CardBody, Col, Form, Input, Row } from 'reactstrap';

import Moment from 'react-moment';

import * as Constants from 'constants/index';

import {
  getFittingAttributeList,
  deleteFitting,
  jumpToProductFitting,
} from 'actions/admin/fittingAttribute';
import { addBreadcrumDetails } from 'actions/admin/breadcrum';

import CreateFittingAttribute from 'views/Admin/Fitting/Attribute/CreateFittingAttribute';
import PerPageSelect from 'views/Admin/PerPageSelect';
import Spinner from 'views/Spinner';

const renderFittingTittle = (params) => {
  if (params.data === undefined) {
    return <Loading />;
  } else {
    return <div>{params.data?.title}</div>;
  }
};

const renderTotalVariations = (params) => {
  let count = 0;
  if (params?.data?.total_variations) {
    params?.data?.total_variations?.forEach((item) => {
      if (item?.isDeleted && item?.isDeleted === true) {
        count = count;
      } else {
        count += 1;
      }
    });
  }


  return count > 0 ? (
    <Link
      to={`/admin/product/fittings/${params.data?._id}`}
      color="info"
      title="Fittings"
    >
      {count}
    </Link>
  ) : (
    <>{count}</>
  );
};

const renderCreatedAt = (params) => {
  return (
    <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
      {params.data?.created_at}
    </Moment>
  );
};

const renderActions = (params, addBreadcrumDetails) => {
  addBreadcrumDetails({ [params.data?._id]: params.data?.title });
  return (
    <div>
      <Link to={`/admin/product/fittings/${params.data?._id}`}>
        <Button
          type="button"
          size="sm"
          color="info"
          title="Fitting attribute variation"
        >
          <i className="fa fa-list"></i>
        </Button>
      </Link>
    </div>
  );
};

const FittingAttributeList = ({
  getFittingAttributeList,
  fittingAttributeList: { loading, data },
  addBreadcrumDetails,
  jumpToProductFitting,
}) => {
  const history = useNavigate();
  const location = useLocation();
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
        limit: Constants.DEFAULT_PAGE_SIZE,
        page: 1,
        orderBy: 'created_at',
        ascending: 'desc',
        query: '',
        filter: {},
        onLoad: true,
      }
  );

  //################## to get updated list ###################
  const gridRef = useRef();
  const [partNo, setPartNo] = useState('');
  const [jumpToVariationLoading, setJumpToVariationLoading] = useState(false);

  const [gridApi, setGridApi] = useState(null);

  //################ toggle add modal ############################
  const [attributeAddModal, setOpenAttributeAddModal] = useState(false);
  const toggleAttributeAddModal = () => {
    setOpenAttributeAddModal(() => !attributeAddModal);
  };

  //################ attribute id useState ############################
  const [attributeId, setAttributeId] = useState(null);

  //################ toogle edit modal ############################

  const [editModal, setOpenEditModal] = useState(false);

  const toggleEditModal = () => {
    if (editModal) setAttributeId(() => null);
    setOpenEditModal(() => !editModal);
  };

  //################ open popup as the attributeId is changed ############################

  useEffect(() => {
    if (attributeId) toggleEditModal();
  }, [attributeId]);

  //################ reload the list ############################
  const [isNewCreated, setIsNewCreated] = useState(false);
  useEffect(() => {
    if (isNewCreated) {
      getFittingAttributeList(sortingParams);
      setIsNewCreated(false);
    }
  }, [isNewCreated]);

  //#########################Colums defination start ###################
  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      colSpan: (params) => {
        if (params.data === undefined) {
          return 4;
        } else {
          return 0;
        }
      },
      cellRenderer: (params) => renderFittingTittle(params),
    },
    {
      field: 'total_variations',
      headerName: 'Fittings #',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agNumberColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => renderTotalVariations(params),
    },
    // {
    //   field: 'created_at',
    //   headerName: 'Created At',
    //   suppressMovable: true,
    //   cellClass: 'suppress-movable-col',
    //   sortable: true,
    //   filter: 'agDateColumnFilter',
    //   suppressSizeToFit: false,
    //   filterParams: {
    //     closeOnApply: true,
    //     filterOptions: Constants.DATE_FILTER_OPTIONS,
    //     suppressAndOrCondition: true,
    //     debounceMs: 800,
    //     buttons: ['reset', 'apply'],
    //   },
    //   cellRenderer: (params) => renderCreatedAt(params),
    // },
    {
      field: '_id',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      headerName: 'Actions',
      cellRenderer: (params) => renderActions(params, addBreadcrumDetails),
    },
  ];

  //################## START Set properties(options) of datatables ###################
  const actions = (
    <div className="add-button-div specify-width">
      <CreateFittingAttribute
        isOpen={attributeAddModal}
        toggle={toggleAttributeAddModal}
        setIsNewCreated={setIsNewCreated}
      />
      <CreateFittingAttribute
        isOpen={editModal}
        toggle={toggleEditModal}
        attributeId={attributeId}
        isEdit={true}
      />
    </div>
  );

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };

          const customData = await getFittingAttributeList(customParams);
          if (customData?.data?.status) {
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });

  const onChange = (e) => {
    e.preventDefault();
    setPartNo(e.target.value);
  };

  const onClickJumpToVariation = async (e) => {
    e.preventDefault();
    setJumpToVariationLoading(true);
    await jumpToProductFitting(partNo, history);
    setJumpToVariationLoading(false);
  };


  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                {actions}
                <Form onSubmit={onClickJumpToVariation}>
                  <div className="rightpaneltop">
                    <Input
                      type="text"
                      placeholder="Type Part Number"
                      required
                      onChange={onChange}
                      style={{
                        width: '160px',
                        height: '32px',
                        marginRight: '14px',
                        marginTop: '8px',
                      }}
                    />
                    <div style={{ marginTop: "9px" }}>
                      <Button
                        color="primary"
                        size="sm"
                        type="submit"
                        disabled={jumpToVariationLoading}
                      >
                        <i className="fa fa-upload"></i> Jump To Fitting Variation
                      </Button>
                    </div>
                  </div>
                </Form>
                <div className="rightpaneltop">
                  Fitting Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>
              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      defaultColDef={{
                        resizable: true,
                      }}
                      loadingCellRenderer={customLoadingCellRenderer}
                      loadingCellRendererParams={loadingCellRendererParams}
                      domLayout={'autoHeight'}
                      rowModelType={'infinite'}
                      columnDefs={columns}
                      pagination={true}
                      sizeColumnsToFit={true}
                      onFilterChanged={filterChanged}
                      onGridReady={onGridReady}
                      paginationPageSize={sortingParams.limit}
                      cacheBlockSize={sortingParams.limit}
                      cacheQuickFilter={true}
                      onFirstDataRendered={onFirstDataRendered}
                      onPaginationChanged={onPaginationChanged}
                      ref={gridRef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

FittingAttributeList.propTypes = {
  getFittingAttributeList: PropTypes.func.isRequired,
  fittingAttributeList: PropTypes.object.isRequired,
  deleteFitting: PropTypes.func.isRequired,
  addBreadcrumDetails: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
  jumpToProductFitting: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.fitting.loading,
  fittingAttributeList: state.fitting.attributeList,
  subLoading: state.fitting.isSubLoading,
});

export default connect(mapStateToProps, {
  getFittingAttributeList,
  deleteFitting,
  addBreadcrumDetails,
  jumpToProductFitting,
})(FittingAttributeList);
