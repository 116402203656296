/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { getImageList, deleteImage } from 'actions/admin/imageGallery';

import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Popover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';
import Spinner from 'views/Spinner';

const actions = (
  <Link to="/admin/gallery-image/upload" className="addNewElementClass">
    <Button color="primary" size="sm">
      <i className="fa fa-plus"></i> Add Image to Gallery
    </Button>
  </Link>
);

const ImageList = ({
  getImageList,
  deleteImage,
  imageList: { data },
  // sortingParams,
  loading,
  // isSubLoading,
}) => {
  //################## Initillise sorting searching parameters by default values ###################
  const [imageParams] = useState({
    // limit: Constants.DEFAULT_PAGE_SIZE,
    // page: 1,
    orderBy: 'created_at',
    ascending: 'desc',
    // query: "",
  });

  // to resolve useEffect dependency error

  //################## to get updated list ###################
  useEffect(() => {

    getImageList(imageParams);
  }, [imageParams]);

  const PopoverItem = (props) => {
    const { id, item } = props;
    const [popoverOpen, setPopoverOpen] = useState(false);

    const toggle = () => setPopoverOpen(!popoverOpen);

    return (
      <span>
        <ReactTooltip id={'Popover-' + id} aria-haspopup="true" />
        <img
          data-tip={item.title}
          data-for={'Popover-' + id}
          // onMouseOver = {toggle}
          // onMouseLeave = {toggle}
          id={'Popover-' + id}
          src={
            //process.env.REACT_APP_AWS_STORAGE_URL +
            'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/image-gallery/' +
            item.thumbnail
          }
          className="gallery-img"
          height="300"
          width="300"
          alt=""
        ></img>
        <Popover
          placement="right-end"
          isOpen={popoverOpen}
          target={'Popover-' + id}
          toggle={toggle}
        >
          <PopoverHeader>
            <div>
              <Link to={`/admin/gallery-image/${item._id}`}>
                <Button type="button" size="sm" color="success">
                  <i className="fa fa-pencil"></i>
                </Button>
              </Link>
              <Button
                type="button"
                size="sm"
                color="danger"
                onClick={(e) => {
                  if (
                    window.confirm(
                      `Are you sure, you want to delete ${item.title} image?`
                    )
                  ) {
                    deleteImage(item._id).then((res) => {
                      if (res) getImageList(imageParams);
                    });
                  }
                }}
              >
                <i className="fa fa-trash"></i>
              </Button>
            </div>
          </PopoverHeader>
          <PopoverBody>{item.description}</PopoverBody>
        </Popover>
      </span>
    );
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn ">
      <Row>
        <Col>
          <Card>
            <CardBody>
              {actions}
              {data.length > 0 ? (
                <div className="data-div">
                  <ul className="galleryItems">
                    {data.map((popover, i) => {
                      return (
                        <li key={i.thumbnail}>
                          <PopoverItem key={i} item={popover} id={i} />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <div className="no-data-div">
                  <h3>No Data Found</h3>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

ImageList.propTypes = {
  getImageList: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
  imageList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  // isSubLoading: PropTypes.bool.isRequired,
  sortingParams: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  imageList: state.imageGallery.imageList,
  loading: state.imageGallery.loading,
  // isSubLoading: state.imageGallery.isSubLoading,
  sortingParams: state.imageGallery.sortingParams,
});

export default connect(mapStateToProps, {
  getImageList,
  deleteImage,
})(ImageList);
