import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Input, FormText } from 'reactstrap';
import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa';
import { login } from 'actions/auth';
import Spinner from 'views/Spinner';

const Login = ({ login, previousPath, auth: { loading } }) => {
  let navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [message, setMessage] = useState(false);
  const [passwordId, setPasswordId] = useState('');
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value?.trim() });
    setMessage(false);
  };

  const togglePassword = (e) => {
    e.preventDefault();
    setPasswordId(e.currentTarget.getAttribute('data-id'));
    setPasswordShown(!passwordShown);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    const isChecked = regex.test(password);
    if (!isChecked) {
      setMessage(true);
    } else {
      login(email, password, navigate, previousPath);
      setMessage(false);
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="login_body">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-4">
              {/* <Alert /> */}
              <Form onSubmit={(e) => onSubmit(e)}>
                <div className="p-4 card">
                  <h1>Login</h1>
                  <p>Sign In to your account</p>
                  <div className="form-group">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="email"
                      autoComplete="email"
                      name="email"
                      maxLength="50"
                      value={email}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <Input
                      type={
                        passwordShown && passwordId == 'password'
                          ? 'text'
                          : 'password'
                      }
                      className="form-control"
                      placeholder="Password"
                      autoComplete="current-password"
                      name="password"
                      required
                      value={password}
                      onChange={(e) => onChange(e)}
                    />
                    <span
                      className="viewpass"
                      onClick={togglePassword}
                      data-id="password"
                    >
                      {passwordShown && passwordId == 'password' ? (
                        <FaRegEyeSlash />
                      ) : (
                        <FaRegEye />
                      )}
                    </span>
                    {message && password != '' && (
                      <div id="message">
                        <FormText
                          style={{ fontSize: '10px' }}
                          className="text-danger"
                        >
                          {' '}
                          Password requirements:
                        </FormText>
                        <FormText>
                          <li
                            style={{ fontSize: '10px' }}
                            className="text-danger"
                          >
                            Must contain at least 8 characters
                          </li>
                          <li
                            style={{ fontSize: '10px' }}
                            className="text-danger"
                          >
                            At least 1 uppercase letter
                          </li>
                          <li
                            style={{ fontSize: '10px' }}
                            className="text-danger"
                          >
                            At least 1 number
                          </li>
                          <li
                            style={{ fontSize: '10px' }}
                            className="text-danger"
                          >
                            At least 1 special character (@$!%*#?&.)
                          </li>
                        </FormText>
                      </div>
                    )}
                  </div>
                  <div
                    className="col-md-4"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <button
                      type="submit"
                      className="w-btn btn-primary loginbtn"
                    >
                      Log In
                    </button>

                    <Link
                      to="/forgot-password"
                      className="forgetps text-end"
                      style={{ color: '#6d2a5f' }}
                    >
                      Forgot Password
                    </Link>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Login.proTypes = {
  login: PropTypes.func.isRequired,
  previousPath: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  previousPath: state.commonActions.previousPath,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  login,
})(Login);
