import { ADD_BREADCRUM_ID_WITH_NAME, ADD_BREADCRUM_FAIL } from 'actions/types';

const initialState = {
  breadcrumDetails: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_BREADCRUM_ID_WITH_NAME:
      return {
        ...state,
        breadcrumDetails: { ...state.breadcrumDetails, ...payload },
      };
    case ADD_BREADCRUM_FAIL:
      return state;
    default:
      return state;
  }
}
