import axios from 'axios';
import {
  NOTIFY_LOADING,
  NOTIFY_CREATED,
  NOTIFY_CREATE_ERROR,
  NOTIFY_FAIL,
  NOTIFY_UPDATED,
  NOTIFY_UPDATE_ERROR,
  NOTIFY_DELETED,
  NOTIFY_DELETE_ERROR,
  NOTIFY_LIST_UPDATED,
  GET_NOTIFY_BY_ID,
  NOTIFY_CHANGE_STATUS,
  NOTIFY_CHANGE_STATUS_ERROR,
} from 'actions/types';

import { updateFilterData } from 'actions/commonActions';

// Create notify
export const add = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: NOTIFY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post('/api/admin/notifies/add', formData, config);
    if (res.data.status === true) {
      dispatch({
        type: NOTIFY_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/notifies');
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: NOTIFY_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: NOTIFY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit Notify me
export const edit = (formData, history, notify_id) => async (dispatch) => {
  try {
    dispatch({
      type: NOTIFY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/notifies/${notify_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: NOTIFY_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/notify-me');
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: NOTIFY_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: NOTIFY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Export CSV Notify Me
export const getNotifyMeToDownload = async (notifyMeParam) => {
  try {
    notifyMeParam = updateFilterData(notifyMeParam);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: notifyMeParam,
    };
    const res = await axios.get(`/api/admin/notifies/csv/download`, config);
    return res.data.response;
  } catch (err) {
    return {};
  }
};

// Delete Notify me
export const deleteNotify = (notify_id, history) => async (dispatch) => {
  try {
    dispatch({
      type: NOTIFY_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(`/api/admin/notifies/${notify_id}`, config);
    if (res.data.status === true) {
      dispatch({
        type: NOTIFY_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: NOTIFY_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: NOTIFY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get notify list

export const getNotifiesList = (notifyParams) => async (dispatch) => {
  try {
    dispatch({
      type: NOTIFY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    notifyParams = updateFilterData(notifyParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: notifyParams,
    };

    const res = await axios.get(`/api/admin/notifies`, config);

    dispatch({
      type: NOTIFY_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          notifyParams: notifyParams,
          notifyList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    dispatch({
      type: NOTIFY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete Notify me
export const getNotifyById = (notify_id) => async (dispatch) => {
  try {
    dispatch({
      type: NOTIFY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/notifies/${notify_id}`, config);
    await dispatch({
      type: GET_NOTIFY_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: NOTIFY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
//change status
export const changeStatus = (notify_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: NOTIFY_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/notifies/change-status/${notify_id}`,
      { status },
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: NOTIFY_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: NOTIFY_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: notify_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: NOTIFY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
