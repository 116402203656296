/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MultiSelect } from 'react-multi-select-component';

import Errors from 'views/Notifications/Errors';

import { getVariationById, postFittingAttribute } from 'actions/admin/fittings';
import { removeErrors } from 'actions/admin/removeErrors';
import { getCategories } from 'actions/admin/product';
import { ATTRIBUTE_WEIGHT_UNIT } from 'constants/index';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import Spinner from 'views/Spinner';

const AddEditFittings = ({
  toggle,
  isOpen,
  modal_loading,
  errorList,
  setIsNewCreated,
  create,
  isEdit,
  variationId,
  getVariationById,
  currentVariation,
  edit,
  getCategories,
  category_loading,
  categorySubCategoryList,
  attributeId,
  fittingType,
  update,
  removeErrors,
  breadcrumDetails,
}) => {
  const initialState = {
    title: '',
    part_no: '',
    price: '',
    inventory: '',
    variation_weight: '',
    thumbnail: '',
    image: '',
    selectedFile: '',
    oldimage: '',
    categoryIds: [],
    selectedCategoryIds: [],
    isNewFileSelected: 0,
    isPartNo: '',
    is_option: false,
    includeAllFields: false,
  };

  const [formData, setFormData] = useState(initialState);

  //########################## change event for all inputs ##############################
  const [requiredValue, setRequiredValue] = useState(false);
  const [includeAllFields, setIncludeAllFields] = useState(false);
  const [selected, setSelected] = useState([]); // multiselect selection state

  //###################### get the category and sub category list ########################
  const [categoryList, setCategoryList] = useState([]);

  //########################## set the submit state ##############################
  const [isSubmit, setIsSubmit] = useState(false);

  //########################## set the initial form data ##############################
  useEffect(() => {
    if (variationId != null) getVariationById(variationId);
  }, [variationId]);

  useMemo(() => {
    getCategories();
  }, [getCategories]);

  const onClickCancel = () => {
    toggle();
    removeErrors();
  };

  useEffect(() => {
    if (Object.keys(categorySubCategoryList).length > 0) {
      let tempCategoryList = [];
      categorySubCategoryList.forEach((catData, index) =>
        tempCategoryList.push({
          value: catData._id,
          label: catData.name,
        })
      );
      setCategoryList(tempCategoryList); // set category list in value and label form
    }
  }, [categorySubCategoryList]);

  useEffect(() => {
    let val;
    if (fittingType?.toLowerCase().includes('adaptor')) {
      val = true;
    } else {
      val = false;
    }
    setIncludeAllFields(val);
  }, [fittingType]);

  //########################## use effect to set form data from current attribute ##############################
  useEffect(() => {
    if (variationId != null && Object.keys(currentVariation).length > 0) {
      setFormData({
        ...formData,
        title: !currentVariation.title ? '' : currentVariation.title,
        part_no: !currentVariation.part_no ? '' : currentVariation.part_no,
        variation_weight: !currentVariation.variation_weight
          ? ''
          : currentVariation.variation_weight,
        price: !currentVariation.price ? 0 : currentVariation.price,
        inventory: !currentVariation.inventory
          ? ''
          : currentVariation.inventory,

        categoryIds: !currentVariation.categoryIds
          ? []
          : currentVariation.categoryIds,
        selectedCategoryIds: !currentVariation.selectedCategoryIds
          ? []
          : currentVariation.selectedCategoryIds,
        oldimage: !currentVariation.image ? '' : currentVariation.image,
        thumbnail: !currentVariation.image
          ? ''
          : 'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/fitting-variation/' +
          currentVariation.image,
      });
      // set category dropdown selected list
      let tempSelectedCategoryList = [];
      currentVariation.selectedCategoryIds.forEach((catData, index) =>
        tempSelectedCategoryList.push({
          value: catData.id,
          label: catData.name,
        })
      );
      setSelected(tempSelectedCategoryList);
    }
  }, [currentVariation]);

  //########################## reset for data when modal is closed ##############################
  const resetModal = () => {
    //reset the submit state
    setIsSubmit(false);
    //reset the form data to initial state
    setFormData({ ...initialState, categoryIds: [] });
    //reset multiselect dropdown
    setSelected([]);
  };

  const onChange = (e) => {
    if (e.target.name === 'image') {
      setRequiredValue(false);
      let image_file = e.target.files[0];
      let image_url = URL.createObjectURL(image_file);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        thumbnail: image_url,
        selectedFile: image_file,
        isNewFileSelected: 1,
      });
    } else if (e.target.name == 'part_no') {
      if (e.target.value > 0) {
        setRequiredValue(true);
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
          isPartNo: 1,
        });
      } else {
        setRequiredValue(false);
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  //############# use effect to set selected category list on dropdown selection #########
  useEffect(() => {
    let selectedCategories = [];
    selected.forEach((value) => {
      selectedCategories.push(value.value);
    });
    setFormData({
      ...formData,
      categoryIds: selectedCategories,
    });
  }, [selected]);

  //########################## submit form data ##############################
  const onSubmit = (e) => {
    e.preventDefault();

    setIsSubmit(true);
    if (variationId == null) {
      create(
        { ...formData, includeAllFields, category_required: true },
        attributeId
      ).then((res) => {
        if (res === true) {
          setIsNewCreated(true);
          toggle();
          update();
        } else {
          setIsSubmit(false);
        }
      });
    } else {
      edit(
        { ...formData, includeAllFields, category_required: true },
        attributeId,
        variationId,
        fittingType
      ).then((res) => {
        if (res === true) {
          setIsNewCreated(true);
          toggle();
        } else {
          setIsSubmit(false);
        }
      });
    }
  };
  const buttonName =
    breadcrumDetails[attributeId] === 'Grips' ||
      breadcrumDetails[attributeId] === 'Adaptors'
      ? breadcrumDetails[attributeId].slice(0, -1)
      : breadcrumDetails[attributeId];
  return (
    (!category_loading || categoryList !== null) && (
      <div className="animated fadeIn">
        <Modal isOpen={isOpen} toggle={toggle} onClosed={resetModal}>
          <ModalHeader toggle={toggle}>
            {isEdit ? 'Edit' : 'Add'} {buttonName}
          </ModalHeader>

          <Form className="form-horizontal" encType="multipart/form-data">
            <ModalBody>
              <>
                <FormGroup>
                  <Label htmlFor="title">
                    {buttonName} Title <span>*</span>
                  </Label>
                  <Input
                    type={
                      fittingType?.toLowerCase().includes('playing')
                        ? 'number'
                        : 'text'
                    }
                    id="title"
                    name="title"
                    maxLength={50}
                    min={0}
                    step={0.01}
                    value={formData.title}
                    placeholder={`Enter ${buttonName?.toLowerCase()}`}
                    required
                    onChange={(e) => onChange(e)}
                    invalid={errorList.title ? true : false}
                  />
                  <Errors current_key="title" key="title" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="categoryId">
                    Category <span>*</span>
                  </Label>
                  <MultiSelect
                    options={categoryList}
                    value={selected}
                    onChange={setSelected}
                    labelledBy="Select"
                    overrideStrings={{
                      selectSomeItems: 'Select categories...',
                      allItemsAreSelected: 'All categories are selected.',
                      selectAll: 'Select all',
                      search: 'Search category',
                    }}
                  />
                  <Errors current_key="category_ids" key="category_ids" />
                </FormGroup>
              </>
              {includeAllFields && (
                <>
                  <FormGroup>
                    <Label htmlFor="part_no">
                      Part No. <span>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="part_no"
                      name="part_no"
                      maxLength="100"
                      value={formData.part_no}
                      placeholder="Enter part number"
                      onChange={(e) => onChange(e)}
                      invalid={errorList.part_no ? true : false}
                    />
                    <Errors current_key="part_no" key="part_no" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="image"> Image</Label>
                    <Input
                      type="file"
                      id="image"
                      name="image"
                      onChange={(e) => onChange(e)}
                      invalid={errorList.image ? true : false}
                    />
                    <img
                      src={formData.thumbnail}
                      className="preview-img"
                      alt=""
                    />
                    <Input
                      type="hidden"
                      id="oldimage"
                      name="oldimage"
                      value={formData.oldimage}
                    ></Input>
                    <Errors current_key="image" key="image" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="variation_weight">
                      Weight {ATTRIBUTE_WEIGHT_UNIT} *
                    </Label>
                    <Input
                      type="number"
                      id="variation_weight"
                      name="variation_weight"
                      min={1}
                      step={0.01}
                      value={formData.variation_weight}
                      placeholder="Enter weight"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => onChange(e)}
                      invalid={errorList.variation_weight ? true : false}
                    />
                    <Errors
                      current_key="variation_weight"
                      key="variation_weight"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="price">
                      Price <span>*</span>
                    </Label>
                    <Input
                      type="number"
                      id="price"
                      name="price"
                      placeholder="Enter price"
                      min={0}
                      step={0.01}
                      value={formData.price}
                      required
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => onChange(e)}
                      invalid={errorList.price ? true : false}
                    />
                    <Errors current_key="price" key="price" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="inventory">
                      Inventory<span>*</span>
                    </Label>
                    <Input
                      type="number"
                      id="inventory"
                      name="inventory"
                      min={1}
                      placeholder="Enter inventory"
                      value={formData.inventory}
                      onChange={(e) => onChange(e)}
                      required={requiredValue}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      invalid={errorList.inventory ? true : false}
                    />
                    <Errors current_key="inventory" key="inventory" />
                  </FormGroup>
                </>
              )}
            </ModalBody>
            <ModalFooter className="modalFooter">
              {modal_loading ? (
                <Spinner />
              ) : (
                <>
                  <Button
                    className="mr-05"
                    type="submit"
                    size="sm"
                    color="primary"
                    onClick={(e) => onSubmit(e)}
                    disabled={isSubmit}
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Button
                    type="reset"
                    size="sm"
                    color="danger"
                    onClick={() => onClickCancel()}
                  >
                    <i className="fa fa-ban"></i> Cancel
                  </Button>
                </>
              )}
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    )
  );
};

AddEditFittings.defaultProps = {
  isOpen: false,
  setIsNewCreated: false,
  isEdit: false,
  variationId: null,
};

AddEditFittings.propTypes = {
  errorList: PropTypes.array.isRequired,
  modal_loading: PropTypes.bool.isRequired,
  create: PropTypes.func.isRequired,
  getVariationById: PropTypes.func.isRequired,
  currentVariation: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  category_loading: PropTypes.bool.isRequired,
  categorySubCategoryList: PropTypes.array.isRequired,
  removeErrors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  modal_loading: state.fittingVariation.modal_loading,
  currentVariation: state.fittingVariation.currentVariation,
  category_loading: state.product.category_loading,
  categorySubCategoryList: state.product.categorySubCategoryList,
  breadcrumDetails: state.breadcrum.breadcrumDetails,
});
export default connect(mapStateToProps, {
  create: postFittingAttribute,
  getVariationById,
  edit: postFittingAttribute,
  getCategories,
  removeErrors,
})(AddEditFittings);
