import {
  NEWS_LETTER_LOADING,
  NEWS_LETTER_FAIL,
  NEWS_LETTER_DELETED,
  NEWS_LETTER_DELETED_ERROR,
  NEWS_LETTER_UPDATED,
  } from "actions/types";
  
  const initalState = {
    newsList: {
      page: 1,
      data: [],
      count: 0,
    },
    currentNews: {},
    loading: false,
    isSubLoading: false,
  };
  
  export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
      case NEWS_LETTER_LOADING:
        return {
          ...state,
          newsList: payload.is_sub_element ? state.newsList : initalState.newsList,
          currentNews: payload.is_sub_element
            ? state.currentNews
            : initalState.currentNews,
          loading: payload.is_loading ? payload.is_loading : false,
          isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
        };
      case NEWS_LETTER_FAIL:
        return {
          ...state,
          loading: false,
          isSubLoading: false
        };
      case NEWS_LETTER_UPDATED:
        return {
          ...state,
          currentNews: {},
          loading: false,
        };
      case NEWS_LETTER_DELETED:
        return {
          ...state,
          loading: false,
        };
      case NEWS_LETTER_DELETED_ERROR:
        return {
          ...state,
          loading: false,
        };
      case NEWS_LETTER_UPDATED:
        return {
          ...state,
          newsList: {
            data: payload.data.newsList.data,
            page: payload.data.newsList.metadata[0].current_page,
            count: payload.data.newsList.metadata[0].totalRecord,
          },
          loading: false,
        };
      default:
        return state;
    }
  }
  