import {
  NOTIFY_LOADING,
  NOTIFY_CREATED,
  NOTIFY_CREATE_ERROR,
  NOTIFY_FAIL,
  NOTIFY_UPDATED,
  NOTIFY_UPDATE_ERROR,
  NOTIFY_DELETED,
  NOTIFY_DELETE_ERROR,
  NOTIFY_LIST_UPDATED,
  GET_NOTIFY_BY_ID,
  NOTIFY_CHANGE_STATUS,
  NOTIFY_CHANGE_STATUS_ERROR,
} from "actions/types";

const initalState = {
  notifiesList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentNotify: {},
  loading: false,
  isSubLoading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case NOTIFY_LOADING:
      return {
        ...state,
        notifiesList: payload.is_sub_element
          ? state.notifiesList
          : initalState.notifiesList,
        currentNotify: payload.is_sub_element
          ? state.currentnotify
          : initalState.currentNotify,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case NOTIFY_CREATED:
      return {
        ...state,
        loading: false,
      };
    case NOTIFY_CREATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case NOTIFY_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case NOTIFY_UPDATED:
      return {
        ...state,
        currentNotify: {},
        loading: false,
      };
    case NOTIFY_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case NOTIFY_DELETED:
      return {
        ...state,
        loading: false,
      };
    case NOTIFY_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case NOTIFY_LIST_UPDATED:
      return {
        ...state,
        notifiesList: {
          data: payload.data.notifyList.data,
          page: payload.data.notifyList.metadata[0].current_page,
          count: payload.data.notifyList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_NOTIFY_BY_ID:
      return {
        ...state,
        currentNotify: payload.data,
        loading: false,
      };
    case NOTIFY_CHANGE_STATUS:
      
      return {
        ...state,
        notifiesList: {
          ...state.notifiesList,
          data: state.notifiesList.data.map((notify) =>
            notify._id === payload.data._id
              ? { ...notify, status: payload.data.status }
              : notify
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case NOTIFY_CHANGE_STATUS_ERROR:
      return {
        ...state,
        notifiesList: {
          ...state.notifiesList,
          data: state.notifiesList.data.map((notify) =>
            notify._id === payload.data
              ? { ...notify, is_failed: true }
              : { ...notify, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    default:
      return state;
  }
}
