/* eslint-disable */
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from '../../../utils/Loading';

import { getSpecsList, deleteSpecs, changeStatus } from 'actions/admin/specs';
import * as Constants from 'constants/index';
import { Button, Card, CardBody, Col, Row, Input } from 'reactstrap';
import Spinner from 'views/Spinner';

import PerPageSelect from '../PerPageSelect';

const renderSpecType = (params) => {
  if (params.data === undefined) {
    return <Loading />;
  } else {
    return (
      <div>
        {params.data?.spec_type == 'fitting_spec' ? 'Fitting Specs' : ''}
        {params.data?.spec_type == 'technical_spec' ? 'Technical Specs' : ''}
      </div>
    );
  }
};

const renderLabel = (params) => {
  return (
    <div>
      {params.data?.spec_data.label != '' ? (
        <>{params.data?.spec_data.label}</>
      ) : (
        ''
      )}
    </div>
  );
};

const renderName = (params) => {
  const data = [];
  data.push(params.data?.spec_data?.data.map((item) => item.name));
  return (
    <div>
      <span className="specItem">{data.map((item) => item.join(', '))}</span>
    </div>
  );
};

const renderStatus = (params, changeSpecsStatus) => {
  return (
    <div>
      <Input
        type="select"
        name="status"
        id={params.data?._id}
        defaultValue={params.data?.status}
        onChange={(e, a) => {
          changeSpecsStatus(params.data?._id, e.target.value);
        }}
      >
        <option value="0">Inactive</option>
        <option value="1">Active</option>
      </Input>
    </div>
  );
};

const renderActions = (params, deleteSpecs, updateSpecs) => {
  return (
    <div>
      {params.data?.status == '1' && (
        <Link to={`/admin/specs/edit/${params.data?._id}`}>
          <Button type="button" size="sm" color="success" title="Edit">
            <i className="fa fa-pencil"></i>
          </Button>
        </Link>
      )}
      {params.data?.name === 'Color' ? null : (
        params.data?.spec_question == (false) &&
        <Button
          type="button"
          size="sm"
          color="danger"
          title="Delete"
          onClick={(e) => {
            if (
              window.confirm(`Are you sure, you want to delete this specs?`)
            ) {
              deleteSpecs(params.data?._id).then((res) => {
                if (res) updateSpecs();
              });
            }
          }}
        >
          <i className="fa fa-trash"></i>
        </Button>
      )}
    </div>
  );
};


const SpecsList = ({
  getSpecsList,
  deleteSpecs,
  changeStatus,
  specsList: { data, count },
  loading,
  subLoading,
}) => {
  const history = useNavigate();
  const location = useLocation();
  const [specId, setSpecId] = useState('all');
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
        limit: Constants.DEFAULT_PAGE_SIZE,
        page: 1,
        orderBy: 'updated_at',
        ascending: 'desc',
        query: '',
        filter: {},
        onLoad: true,
      }
  );
  const handleClick = (e) => {
    e.preventDefault();
    setSpecId(e?.currentTarget?.getAttribute('data-id'));
  }
  // to resolve useEffect dependency error

  //################## to get updated list ###################
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);

  const updateSpecs = () => {
    gridApi.purgeInfiniteCache();
  };

  const changeSpecsStatus = async (id, value) => {
    await changeStatus(id, value);
    gridApi.purgeInfiniteCache();
  };

  //#########################Colums defination start ###################

  const columns = [
    {
      field: 'spec_type',
      headerName: 'Spec Type',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      colSpan: (params) => {
        if (params.data === undefined) {
          return 5;
        } else {
          return 0;
        }
      },
      cellRenderer: (params) => renderSpecType(params),
    },
    {
      field: 'spec_data.label',
      headerName: 'Label',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      // sortable: true,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => renderLabel(params),
    },
    {
      field: 'spec_data.data.name',
      headerName: 'Name',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      // sortable: true,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => renderName(params),
    },
    {
      field: 'status',
      headerName: 'Status',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      cellRenderer: (params) => renderStatus(params, changeSpecsStatus),
    },
    {
      field: '_id',
      headerName: 'Actions',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) => renderActions(params, deleteSpecs, updateSpecs),
    },
  ];

  //#########################Colums defination end ###################

  //################## START Set properties(options) of datatables ###################

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };

          const customData = await getSpecsList(customParams, specId);
          if (customData?.data?.status) {
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi, specId]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });

  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                <div className="add-button-div specify-width">
                  <Link to="/admin/specs/add" className="addNewElementClass">
                    <Button color="primary" size="sm">
                      <i className="fa fa-plus"></i> Add Specs
                    </Button>
                  </Link>
                  <Button className={`btn btn-link ${specId == 'all' && 'active'}`} onClick={handleClick} data-id="all">
                    All
                  </Button>
                  <Button className={`btn btn-link ${specId == 'spec_question' && 'active'}`} onClick={handleClick} data-id="spec_question">
                    Spec Selector List
                  </Button>
                </div>
                <div className="rightpaneltop">
                  Specs Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>

              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      defaultColDef={{
                        resizable: true,
                      }}
                      loadingCellRenderer={customLoadingCellRenderer}
                      loadingCellRendererParams={loadingCellRendererParams}
                      domLayout={'autoHeight'}
                      rowModelType={'infinite'}
                      columnDefs={columns}
                      pagination={true}
                      sizeColumnsToFit={true}
                      onFilterChanged={filterChanged}
                      onGridReady={onGridReady}
                      paginationPageSize={sortingParams.limit}
                      cacheBlockSize={sortingParams.limit}
                      cacheQuickFilter={true}
                      onFirstDataRendered={onFirstDataRendered}
                      onPaginationChanged={onPaginationChanged}
                      ref={gridRef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

SpecsList.propTypes = {
  getSpecsList: PropTypes.func.isRequired,
  deleteSpecs: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  specsList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  specsList: state.specs.specsList,
  loading: state.specs.loading,
  subLoading: state.specs.isSubLoading,
});

export default connect(mapStateToProps, {
  getSpecsList,
  deleteSpecs,
  changeStatus,
})(SpecsList);
