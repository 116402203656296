/* eslint-disable */
import React, { useMemo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CKEditor } from 'ckeditor4-react';
import slugify from 'react-slugify';
import { Link } from 'react-router-dom';

import {
  getCategories,
  edit,
  create,
  setWholesaleError,
  setSaleError,
} from 'actions/admin/product';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';
import { ATTRIBUTE_WEIGHT_UNIT, CKEditorConfig } from 'constants/index';

const BasicInfo = ({
  product_id,
  currentProduct,
  getCategories,
  category_loading,
  errorList,
  categorySubCategoryList,
  history,
  edit,
  create,
  setWholesaleError,
  changeTab,
  setProductId,
  loading,
}) => {
  //########################## set the initial form data ##############################

  const [productBasicDetail, setProductBasicDetail] = useState({
    product_id: product_id ? product_id : '',
    product_title: product_id ? currentProduct.product_title : '',
    weight: product_id ? currentProduct.weight : '',
    product_length: product_id ? currentProduct.product_length : '',
    construction_technology: product_id ? currentProduct.construction_technology : '',
    meterial: product_id ? currentProduct.meterial : '',
    slug: product_id ? currentProduct.slug : '',
    categoryId: product_id ? currentProduct.categoryId : '',
    subCategoryId: product_id ? currentProduct.subCategoryId : '',
    // short_description: product_id ? currentProduct.short_description : "",
    msrp_price: product_id ? currentProduct.msrp_price : '',
    wholesale_price: product_id ? currentProduct.wholesale_price : '',
    sale_price: product_id ? currentProduct.sale_price : '',
    // specifications_desk: product_id ? currentProduct.specifications_desk : '',
    // fitting: product_id ? currentProduct.fitting : '',
    tech_design: product_id ? currentProduct.tech_design : '',
    external_url: product_id ? currentProduct.external_url : '',
    sku: product_id ? currentProduct.sku : '',
    installation_guidelines: product_id
      ? currentProduct.installation_guidelines
      : '',
  });

  const {
    product_title,
    weight,
    product_length,
    construction_technology,
    meterial,
    slug,
    categoryId,
    subCategoryId,
    // short_description,
    msrp_price,
    wholesale_price,
    sale_price,
    // specifications_desk,
    // fitting,
    tech_design,
    installation_guidelines,
    external_url,
    sku,
  } = productBasicDetail;

  //###################### get the category and sub category list ########################
  const [categoryList, setCategoryList] = useState(null);
  const [subCategoryList, setSubCategoryList] = useState(null);

  const changeSubCategory = (subCategory) => {
    let tempSubCategoryList = [];
    subCategory.forEach((catData, index) => {
      tempSubCategoryList[index] = {
        id: catData._id,
        name: catData.name,
      };
    });
    setSubCategoryList(tempSubCategoryList);
  };

  useMemo(() => {
    getCategories();
  }, [getCategories]);

  // to resolve useEffect dependency error

  useEffect(() => {

    if (Object.keys(categorySubCategoryList).length > 0) {
      let tempCategoryList = [];
      categorySubCategoryList.forEach((catData, index) => {
        tempCategoryList[index] = {
          id: catData._id,
          name: catData.name,
        };
        if (catData._id === categoryId) changeSubCategory(catData.childrens);
      });
      setCategoryList(tempCategoryList);
    }
  }, [categorySubCategoryList]);

  //########### change form data and sub category if category changed ########################
  const onChange = (e, dataName = '') => {
    let updateFormData = {};
    if (e.target) {
      updateFormData = { [e.target.name]: e.target.value };
      if (e.target.name === 'categoryId') {
        // categorySubCategoryList.filter((item) => {
        //   if (item._id === e.target.value) {
        //     return changeSubCategory(item.childrens);
        //   } else return null;
        // });

        // updateFormData = { ...updateFormData, subCategoryId: '' };
      } else if (e.target.name === 'product_title' && !product_id) {
        let new_slug = slugify(e.target.value);
        updateFormData = { ...updateFormData, slug: new_slug };
      }
    } else {
      updateFormData = { [dataName]: e.editor.getData() };
    }
    updateFormData = { ...updateFormData, is_external: 1 };
    setProductBasicDetail({
      ...productBasicDetail,
      ...updateFormData,
    });
  };

  //########### handel submit form  ########################
  const onSubmit = (e) => {
    e.preventDefault();
    if (msrp_price - wholesale_price < 0) {
      setWholesaleError(
        'MAP/MSRP Price must be higher than Wholesale Price',
        'wholesale_price'
      );
    } else if (msrp_price - sale_price < 0) {
      setSaleError(
        'MAP/MSRP Price must be higher than Sale Price',
        'sale_price'
      );
    } else {
      if (!product_id) {
        create(productBasicDetail).then((res) => {
          if (res.status === true) {

            setProductId(res.id);
            changeTab('images_tab');
          }
        });
      } else {
        edit(productBasicDetail, product_id).then((res) => {
          if (res === true) changeTab('images_tab');
        });
      }
    }
  };

  return loading || category_loading || categoryList === null ? (
    <Spinner />
  ) : (
    <>
      <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
        <Row>
          <Col xs="12" sm="8">
            <Card>
              <CardBody>
                <Row>
                  <Col xs="12" sm="12">
                    <FormGroup>
                      <Label htmlFor="product_title">
                        Product Name <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="product_title"
                        name="product_title"
                        maxLength="100"
                        minLength="3"
                        value={product_title}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.product_title ? true : false}
                      />
                      <Errors current_key="product_title" key="product_title" />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="external_url">
                        External URL <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="external_url"
                        name="external_url"
                        maxLength="100"
                        minLength="3"
                        value={external_url}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.external_url ? true : false}
                      />
                      <Errors current_key="external_url" key="external_url" />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="slug">
                        Product Slug <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="slug"
                        name="slug"
                        maxLength="100"
                        value={slug}
                        onChange={(e) => onChange(e)}
                        readOnly
                        invalid={errorList.slug ? true : false}
                        disabled
                      />
                      <Errors current_key="slug" key="slug" />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="sku">
                        SKU <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="sku"
                        name="sku"
                        maxLength="100"
                        minLength="3"
                        value={sku}
                        required
                        onChange={(e) => onChange(e)}
                      ////invalid={sku && errorList.sku ? true : false}
                      />
                      <Errors current_key="sku" key="sku" />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="categoryId">
                        Category <span>*</span>
                      </Label>
                      <Input
                        type="select"
                        name="categoryId"
                        id="categoryId"
                        value={categoryId}
                        onChange={(e) => onChange(e)}
                        required
                        invalid={errorList.categoryId ? true : false}
                      >
                        <option value={''}> {'Select'} </option>
                        {categoryList
                          ? categoryList.map((value, index) => {
                            return (
                              <option key={index} value={value.id}>
                                {value.name}
                              </option>
                            );
                          })
                          : ''}
                      </Input>
                      <Errors current_key="categoryId" key="categoryId" />
                    </FormGroup>
                    {/* <FormGroup>
                      <Label htmlFor="subCategoryId">
                        Sub category
                      </Label>
                      <Input
                        type="select"
                        name="subCategoryId"
                        id="subCategoryId"
                        value={subCategoryId}
                        onChange={(e) => onChange(e)}
                        required
                        invalid={errorList.subCategoryId ? true : false}
                      >
                        <option value={''}> {'Select'} </option>
                        {subCategoryList
                          ? subCategoryList.map((value, index) => {
                              return (
                                <option key={index} value={value.id}>
                                  {value.name}
                                </option>
                              );
                            })
                          : ''}
                      </Input>
                      <Errors current_key="subCategoryId" key="subCategoryId" />
                    </FormGroup> */}
                    <FormGroup>
                      <Label htmlFor="weight">
                        Weight {ATTRIBUTE_WEIGHT_UNIT} <span>*</span>
                      </Label>
                      <Input
                        type="number"
                        id="weight"
                        name="weight"
                        maxLength="100"
                        minLength="3"
                        value={weight}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.weight ? true : false}
                      />
                      <Errors current_key="weight" key="weight" />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="product_length">
                        Length {ATTRIBUTE_WEIGHT_UNIT} <span>*</span>
                      </Label>
                      <Input
                        type="number"
                        id="product_length"
                        name="product_length"
                        maxLength="100"
                        minLength="3"
                        value={product_length}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.product_length ? true : false}
                      />
                      <Errors
                        current_key="product_length"
                        key="product_length"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="construction_technology">
                        Construction Technology <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="construction_technology"
                        name="construction_technology"
                        maxLength="200"
                        minLength="3"
                        value={construction_technology}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.construction_technology ? true : false}
                      />
                      <Errors current_key="construction_technology" key="construction_technology" />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="meterial">
                        Meterial <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="meterial"
                        name="meterial"
                        maxLength="200"
                        minLength="3"
                        value={meterial}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.meterial ? true : false}
                      />
                      <Errors
                        current_key="meterial"
                        key="meterial"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="sub_title">
                        Sub Title <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="sub_title"
                        name="sub_title"
                        maxLength="150"
                        minLength="3"
                        value={sub_title}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.sub_title ? true : false}
                      />
                      <Errors current_key="sub_title" key="sub_title" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="sub_heading">
                        Sub Heading <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="sub_heading"
                        name="sub_heading"
                        maxLength="150"
                        minLength="3"
                        value={sub_heading}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.sub_heading ? true : false}
                      />
                      <Errors current_key="sub_heading" key="sub_heading" />
                    </FormGroup>
                  </Col>
                  {/* <Col xs="12" sm="6">
                    <FormGroup>
                      <Label htmlFor="short_description">
                        <strong>
                          {" "}
                          Short Description <span>*</span>
                        </strong>
                      </Label>
                      <CKEditor
                        editor={short_description}
                        data={short_description}
                        id="short_description"
                        name="short_description"
                        config={{
                          height: 170,
                          toolbar: [
                            ["Cut", "Copy", "Paste"],
                            ["Undo", "Redo"],
                            ["SpellChecker"],
                            ["Link", "Unlink", "Anchor"],
                            [
                              "Image",
                              "Table",
                              "Horizontal Line",
                              "Special Character",
                            ],
                            ["Maximize"],
                            ["Source"],
                            ["Bold", "Italic", "Strike"],
                            ["RemoveFormat"],
                            ["NumberedList", "BulletedList"],
                            ["DecreaseIndent", "IncreaseIndent"],
                            ["BlockQuote"],
                            ["Styles"],
                            ["Format"],
                            ["About"],
                          ],
                        }}
                        onChange={(event) =>
                          onChange(event, "short_description")
                        }
                        onBeforeLoad={(CKEDITOR) =>
                          (CKEDITOR.disableAutoInline = true)
                        }
                        invalid={errorList.short_description ? true : false}
                      />
                      <Errors
                        current_key="short_description"
                        key="short_description"
                      />
                    </FormGroup>
                  </Col> */}
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="4">
            <Card key={1}>
              <CardHeader>
                <h5>Prices</h5>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label htmlFor="regular_price">
                    MAP/MSRP Price <span>*</span>
                  </Label>
                  <Input
                    type="number"
                    id="msrp_price"
                    name="msrp_price"
                    maxLength="100"
                    value={msrp_price}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.msrp_price ? true : false}
                  />
                  <Errors current_key="msrp_price" key="msrp_price" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="wholesale_price">
                    Wholesale Price <span>*</span>
                  </Label>
                  <Input
                    type="number"
                    id="wholesale_price"
                    name="wholesale_price"
                    maxLength="100"
                    value={wholesale_price}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.wholesale_price ? true : false}
                  />
                  <Errors current_key="wholesale_price" key="wholesale_price" />
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>
                <h5>Description</h5>
              </CardHeader>
              <CardBody>
                {/* <Row>
                  <Col xs="12" sm="6">
                    <FormGroup>
                      <Label htmlFor="specifications_desk">
                        <strong>Specifications Desk</strong>
                      </Label>
                      <CKEditor
                        editor={specifications_desk}
                        initData={specifications_desk}
                        id="specifications_desk"
                        name="specifications_desk"
                        config={{
                          height: 170,
                          toolbar: [
                            ['Cut', 'Copy', 'Paste'],
                            ['Undo', 'Redo'],
                            ['SpellChecker'],
                            ['Link', 'Unlink', 'Anchor'],
                            [
                              'Image',
                              'Table',
                              'Horizontal Line',
                              'Special Character',
                            ],
                            ['Maximize'],
                            ['Source'],
                            ['Bold', 'Italic', 'Strike'],
                            ['RemoveFormat'],
                            ['NumberedList', 'BulletedList'],
                            ['DecreaseIndent', 'IncreaseIndent'],
                            ['BlockQuote'],
                            ['Styles'],
                            ['Format'],
                            ['About'],
                          ],
                        }}
                        onChange={(event) =>
                          onChange(event, 'specifications_desk')
                        }
                        onBeforeLoad={(CKEDITOR) =>
                          (CKEDITOR.disableAutoInline = true)
                        }
                        invalid={errorList.specifications_desk ? true : false}
                      />
                      <Errors
                        current_key="specifications_desk"
                        key="specifications_desk"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6">
                    <FormGroup>
                      <Label htmlFor="fitting">
                        <strong>Fitting</strong>
                      </Label>
                      <CKEditor
                        editor={fitting}
                        initData={fitting}
                        id="fitting"
                        name="fitting"
                        config={{
                          height: 170,
                          toolbar: [
                            ['Cut', 'Copy', 'Paste'],
                            ['Undo', 'Redo'],
                            ['SpellChecker'],
                            ['Link', 'Unlink', 'Anchor'],
                            [
                              'Image',
                              'Table',
                              'Horizontal Line',
                              'Special Character',
                            ],
                            ['Maximize'],
                            ['Source'],
                            ['Bold', 'Italic', 'Strike'],
                            ['RemoveFormat'],
                            ['NumberedList', 'BulletedList'],
                            ['DecreaseIndent', 'IncreaseIndent'],
                            ['BlockQuote'],
                            ['Styles'],
                            ['Format'],
                            ['About'],
                          ],
                        }}
                        onChange={(event) => onChange(event, 'fitting')}
                        onBeforeLoad={(CKEDITOR) =>
                          (CKEDITOR.disableAutoInline = true)
                        }
                        invalid={errorList.fitting ? true : false}
                      />
                      <Errors current_key="fitting" key="fitting" />
                    </FormGroup>
                  </Col>
                </Row> */}
                <Row>
                  <Col xs="12" sm="6">
                    <FormGroup>
                      <Label htmlFor="tech_design">
                        <strong>Technology/Design</strong>
                      </Label>
                      <CKEditor
                        editor={tech_design}
                        initData={tech_design}
                        id="tech_design"
                        name="tech_design"
                        config={CKEditorConfig}
                        onChange={(event) => onChange(event, 'tech_design')}
                        onBeforeLoad={(CKEDITOR) =>
                          (CKEDITOR.disableAutoInline = true)
                        }
                        invalid={errorList.tech_design ? true : false}
                      />
                      <Errors current_key="tech_design" key="tech_design" />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6">
                    <FormGroup>
                      <Label htmlFor="installation_guidelines">
                        <strong>Installation Guidelines</strong>
                      </Label>
                      <CKEditor
                        editor={installation_guidelines}
                        initData={installation_guidelines}
                        id="installation_guidelines"
                        name="installation_guidelines"
                        config={CKEditorConfig}
                        onChange={(event) =>
                          onChange(event, 'installation_guidelines')
                        }
                        onBeforeLoad={(CKEDITOR) =>
                          (CKEDITOR.disableAutoInline = true)
                        }
                        invalid={
                          errorList.installation_guidelines ? true : false
                        }
                      />
                      <Errors
                        current_key="installation_guidelines"
                        key="installation_guidelines"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xs="12" className="Justify-flex-end">
            <FormGroup key={2}>
              <Button className="mr-05" type="submit" size="sm" color="primary">
                <i className="fa fa-dot-circle-o"></i> Save & Next
              </Button>
              <Link to="/admin/products">
                <Button type="reset" size="sm" color="danger">
                  <i className="fa fa-ban"></i> Cancel
                </Button>
              </Link>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </>
  );
};

BasicInfo.defaultProps = {
  product_id: null,
};

BasicInfo.propTypes = {
  category_loading: PropTypes.bool.isRequired,
  currentProduct: PropTypes.object.isRequired,
  getCategories: PropTypes.func.isRequired,
  errorList: PropTypes.array.isRequired,
  categorySubCategoryList: PropTypes.array.isRequired,
  edit: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  setWholesaleError: PropTypes.func.isRequired,
  //setSaleError: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  category_loading: state.product.category_loading,
  currentProduct: state.product.currentProduct,
  errorList: state.errors,
  categorySubCategoryList: state.product.categorySubCategoryList,
  loading: state.product.loading,
});

export default connect(mapStateToProps, {
  getCategories,
  edit,
  create,
  setWholesaleError,
  setSaleError,
})(BasicInfo);
