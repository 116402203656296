/* eslint-disable */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { Button, Card, CardBody, Col, Row, Input } from 'reactstrap';
import ReactTooltip from 'react-tooltip';

import CustomFilters from './CustomFilters';
import Spinner from 'views/Spinner';

import {
  markAsPrimary,
  getUserAddressList,
  deleteUserAddress,
  addressApproveDisapprove,
} from 'actions/admin/userAddress';

import * as Constants from 'constants/index';

//   PaginationProvider,
//   SizePerPageDropdownStandalone,
// } from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import PerPageSelect from '../PerPageSelect';

import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from '../../../utils/Loading';

const renderName = (params) => {
  if (params.data === undefined) {
    return <Loading />;
  } else {
    return <div>{params.data?.name}</div>;
  }
};

const renderStatus = (params, changeAddressStatus) => {
  return (
    <div>
      <Input
        type="select"
        name="status"
        id={params.data?.address_id}
        defaultValue={params.data?.status}
        onChange={(e, a) => {
          changeAddressStatus(params.data?.address_id, e.target.value);
        }}
        disabled={params.data?.is_primary}
      >
        <option value="1">{Constants.USER_STATUS[0]}</option>
        <option value="0">{Constants.USER_STATUS[1]}</option>
      </Input>
    </div>
  );
};

const UserAddressList = ({
  markAsPrimary,
  getUserAddressList,
  deleteUserAddress,
  userAddressList: { data, count },
  loading,
  subLoading,
  match,
  addressApproveDisapprove,
}) => {
  const history = useNavigate();
  const location = useLocation();
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
        limit: Constants.DEFAULT_PAGE_SIZE,
        page: 1,
        orderBy: 'updated_at',
        ascending: 'desc',
        query: '',
        filter: {},
      }
  );

  // to resolve useEffect dependency error

  //################## to get updated list ###################

  const { customerName: customerName, customer_id: customer_id } = useParams();
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);

  const updateUserAddresses = () => {
    gridApi.purgeInfiniteCache();
  };

  const changeAddressStatus = async (id, value) => {
    await addressApproveDisapprove(id, value);
    gridApi.purgeInfiniteCache();
  };

  const changeMarkAsPrimary = async (id, value) => {
    await markAsPrimary(id, value);
    gridApi.purgeInfiniteCache();
  };

  // ################# Add User's Address ################
  const actions = (
    <div className="add-button-div">
      <Link to="/admin/customers">
        <Button type="reset" size="sm" color="info">
          <i className="fa fa-arrow-left"></i> Back
        </Button>
      </Link>
      <Link
        to={`/admin/customer-address/${customerName}/add/${customer_id}`}
        className="addNewElementClass"
      >
        <Button color="primary" size="sm">
          <i className="fa fa-plus"></i> Add Address
        </Button>
      </Link>
    </div>
  );
  const anyFilter = {
    displayName: 'ANY',
    displayKey: 'any',
    numberOfInputs: 0,
    predicate: () => { },
  };
  //#########################Colums defination start ###################
  const columns = [
    {
      field: 'insensitiveName',
      headerName: 'Name',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      width: 130,
      colSpan: (params) => {
        if (params.data === undefined) {
          return 8;
        } else {
          return 0;
        }
      },
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => renderName(params),
    },
    {
      field: 'city',
      headerName: 'Location',
      suppressMovable: true,
      width: 180,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => (
        <p>
          {params.data?.city + '/'}
          {params.data?.state ? params.data?.state + '/' : ''}
          {params.data?.country}
        </p>
      ),
    },
    {
      field: "insensitiveEmail",
      headerName: 'Email',
      width: 180,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => { return (<div>{params.data?.email}</div>) }
    },
    {
      field: 'contact_no',
      headerName: 'Contact No',
      width: 120,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => (
        <p>
          {params.data?.contact_no}
        </p>
      ),
    },
    {
      field: 'updated_at',
      headerName: 'Last Edited',
      width: 120,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agDateColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.DATE_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
        buttons: ['reset', 'apply'],
      },
      cellRenderer: (params) => (
        <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
          {params.data?.updated_at}
        </Moment>
      ),
    },

    // {
    //   field: 'status',
    //   width: 170,
    //   headerName: 'Status',
    //   suppressMovable: true,
    //   cellClass: 'suppress-movable-col',
    //   sortable: true,
    //   suppressSizeToFit: false,
    //   filter: 'agTextColumnFilter',
    //   filterParams: {
    //     closeOnApply: true,
    //     filterOptions: [
    //       anyFilter,
    //       {
    //         displayName: 'Approved',
    //         displayKey: 'typeApproved',
    //         numberOfInputs: 0,
    //         predicate: () => {},
    //       },
    //       {
    //         displayName: 'Rejected',
    //         displayKey: 'typeRejected',
    //         numberOfInputs: 0,
    //         predicate: () => {},
    //       },
    //     ],
    //     suppressAndOrCondition: true,
    //   },
    //   cellRenderer: (params) => renderStatus(params, changeAddressStatus),
    //   // {
    //   //   return (
    //   //     <div>
    //   //       <Input
    //   //         type="select"
    //   //         name="status"
    //   //         id={params.data?.address_id}
    //   //         defaultValue={params.data?.address_id}
    //   //         onChange={(e, a) => {
    //   //           addressApproveDisapprove(
    //   //             params.data?.address_id,
    //   //             e.target.value
    //   //           );
    //   //         }}
    //   //       >
    //   //         {' '}
    //   //         {params.data?.status === 0 ? (
    //   //           <option value="1">Pending</option>
    //   //         ) : (
    //   //           ''
    //   //         )}
    //   //         <option value="1">Approved</option>
    //   //         <option value="2">Rejected</option>
    //   //       </Input>
    //   //     </div>
    //   //   );
    //   // },
    // },
    {
      field: 'is_primary',
      headerName: 'is primary ',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      cellRenderer: (params) => {
        const displayContent = params.data?.is_primary ? (
          <div>
            <span
              className="featured-active-main"
              data-tip="Primary"
              data-for={'TooltipFeatured-' + params?.data}
            >
              Primary{' '}
              {/* <span className="featured-active">
                <i className="fa fa-check"></i>
              </span> */}
            </span>
            <ReactTooltip
              id={'TooltipFeatured-' + params?.data}
              aria-haspopup="true"
            />
          </div>
        ) : (
          <div>
            <Button
              type="button"
              size="sm"
              className="warning-featured-main"
              data-tip="Not Primary. Mark As Primary."
              data-for={'Tooltip-' + params?.data}
              onClick={(e) => {
                if (
                  window.confirm(
                    `Are you sure, you want to mark ${params.data?.city +
                    '/' +
                    params.data?.state +
                    '/' +
                    params.data?.country
                    } location as primary?`
                  )
                ) {
                  // markAsPrimary(customer_id, params.data?.address_id);
                  changeMarkAsPrimary(customer_id, params.data?.address_id);
                }
              }}
            >
              Mark As Primary{' '}
              {/* <span className="warning-featured">
                <i className="fa fa-exclamation-circle"></i>
              </span> */}
            </Button>
            <ReactTooltip id={'Tooltip-' + params?.data} aria-haspopup="true" />
          </div>
        );
        return displayContent;
      },
    },
    {
      field: 'address_id',
      headerName: 'Actions',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) => (
        <div>
          <Link
            to={`/admin/customer-address/${customerName}/edit/${params.data?.address_id}`}
          >
            <Button type="button" size="sm" color="success" title="Edit">
              <i className="fa fa-pencil"></i>
            </Button>
          </Link>
          <Button
            type="button"
            size="sm"
            color="danger"
            title="Delete"
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure, you want to delete ${params.data?.city +
                  '/' +
                  params.data?.state +
                  '/' +
                  params.data?.country
                  } location?`
                )
              ) {
                deleteUserAddress(params.data?.address_id).then((res) => {
                  if (res) updateUserAddresses();
                });
              }
            }}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
  ];
  //#########################Colums defination end ###################

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };

          const customData = await getUserAddressList(
            customParams,
            customer_id
          );
          if (customData?.data?.status) {
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });

  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                {actions}
                <div className="rightpaneltop">
                  Address Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>
              <div className="row w-100">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body position-relative">
                      <div
                        className="ag-theme-alpine"
                        style={{ height: '550px' }}
                      >
                        <AgGridReact
                          defaultColDef={{
                            resizable: true,
                          }}
                          loadingCellRenderer={customLoadingCellRenderer}
                          loadingCellRendererParams={loadingCellRendererParams}
                          domLayout={'autoHeight'}
                          rowModelType={'infinite'}
                          columnDefs={columns}
                          pagination={true}
                          sizeColumnsToFit={true}
                          onFilterChanged={filterChanged}
                          onGridReady={onGridReady}
                          paginationPageSize={sortingParams.limit}
                          cacheBlockSize={sortingParams.limit}
                          cacheQuickFilter={true}
                          onFirstDataRendered={onFirstDataRendered}
                          onPaginationChanged={onPaginationChanged}
                          ref={gridRef}
                        ></AgGridReact>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

UserAddressList.propTypes = {
  getUserAddressList: PropTypes.func.isRequired,
  markAsPrimary: PropTypes.func.isRequired,
  deleteUserAddress: PropTypes.func.isRequired,
  userAddressList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
  addressApproveDisapprove: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  userAddressList: state.userAddress.userAddressList,
  loading: state.userAddress.loading,
  subLoading: state.userAddress.isSubLoading,
});

export default connect(mapStateToProps, {
  getUserAddressList,
  deleteUserAddress,
  markAsPrimary,
  addressApproveDisapprove,
})(UserAddressList);
