/* eslint-disable */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setAlert } from 'actions/alert';
import { resetLink } from 'actions/auth';
import Errors from 'views/Notifications/Errors';

import PropTypes from 'prop-types';
import { Form, Input, Button } from 'reactstrap';

const ResetLink = ({ resetLink, errorList }) => {
  /********************Set Page Title/ and Sub header *****************/

  const [formData, setFormData] = useState({
    email: '',
  });
  const [messageText, setMessageText] = useState(false);

  const { email } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value?.trim() });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    var re = /\S+@\S+\.\S+/;
    if (re.test(email)) {
      resetLink(formData);
      setMessageText(false);
      setFormData({ email: '' });
    } else {
      setMessageText(true);
    }
  };

  return (
    <div className="login_body">
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            {/* <Alert /> */}
            <Form onSubmit={(e) => onSubmit(e)}>
              <div className="p-4 card">
                <h4 className="title">Forgot Password</h4>
                <div className="form-group">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    autoComplete="email"
                    name="email"
                    maxLength="50"
                    value={email}
                    onChange={(e) => onChange(e)}
                    required
                    invalid={errorList.email ? true : false}
                  />
                  <Errors current_key="email" key="email" />
                  {messageText != false && (
                    <span className="text-danger">
                      Enter a valid email address
                    </span>
                  )}
                </div>
                <div
                  className="form-group"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Button type="submit" className="btn btn-primary d-block">
                    Reset Password
                  </Button>
                  <Link
                    className="btn-label"
                    to="/"
                    style={{ color: '#6d2a5f' }}
                  >
                    Back to Log In
                  </Link>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

ResetLink.propTypes = {
  setAlert: PropTypes.func.isRequired,
  resetLink: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  errorList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  setAlert,
  resetLink,
})(ResetLink);
