import axios from 'axios';

import {
  Additional_Product_LOADING,
  Additional_Product_CREATED,
  Additional_Product_CREATE_ERROR,
  Additional_Product_FAIL,
  Additional_Product_UPDATED,
  Additional_Product_UPDATE_ERROR,
  Additional_Product_DELETED,
  Additional_Product_DELETE_ERROR,
  Additional_Product_LIST_UPDATED,
  GET_Additional_Product_BY_ID,
  SET_ERRORS_LIST,
} from 'actions/types';

import { updateFilterData } from 'actions/commonActions';

// Create NEWS
export const create = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: Additional_Product_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.post('/api/admin/services/create', formData, config);
    if (res.data.status === true) {
      dispatch({
        type: Additional_Product_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/services');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: Additional_Product_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
    }
  } catch (err) {
    dispatch({
      type: Additional_Product_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit NEWS
export const edit = (formData, history, product_id) => async (dispatch) => {
  try {
    dispatch({
      type: Additional_Product_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.post(
      `/api/admin/services/${product_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: Additional_Product_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/services');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: Additional_Product_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
    }
  } catch (err) {
    dispatch({
      type: Additional_Product_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete NEWS Page
export const deleteService = (product_id) => async (dispatch) => {
  try {
    dispatch({
      type: Additional_Product_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(`/api/admin/services/${product_id}`, config);

    if (res.data.status === true) {
      dispatch({
        type: Additional_Product_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: Additional_Product_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: Additional_Product_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

//get NEWS list
export const getServicesList = (newsParams) => async (dispatch) => {
  try {
    dispatch({
      type: Additional_Product_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    newsParams = updateFilterData(newsParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: newsParams,
    };
    // const query = newsParams.query ? newsParams.query : '&query=';

    const res = await axios.get(`/api/admin/services`, config);
    dispatch({
      type: Additional_Product_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          newsParams: newsParams,
          servicesList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    dispatch({
      type: Additional_Product_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Get NEWS by id
export const getAdditionalProductById = (product_id) => async (dispatch) => {
  try {
    dispatch({
      type: Additional_Product_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/services/${product_id}`, config);

    await dispatch({
      type: GET_Additional_Product_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: Additional_Product_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
