/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Errors from 'views/Notifications/Errors';
import Spinner from 'views/ButtonSpinner';

import { create, getAttributeById, edit } from 'actions/admin/fittingAttribute';

import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

const CreateFittingAttribute = ({
  toggle,
  isOpen,
  modal_loading,
  errorList,
  setIsNewCreated,
  create,
  isEdit,
  attributeId,
  getAttributeById,
  currentAttribute,
  edit,
}) => {
  // to resolve useEffect dependency error


  //#################### use effect to get current attribute #########################
  useEffect(() => {
    if (attributeId != null) getAttributeById(attributeId);
  }, [attributeId]);


  //########################## set the initial form data ###########################
  const initialState = {
    title: '',
    hasNoneAttribute: false,
  };

  const [formData, setFormData] = useState(initialState);

  //################# use effect to set form data from current attribute ##################
  useEffect(() => {

    if (attributeId != null && Object.keys(currentAttribute).length > 0) {
      setFormData({
        title: !currentAttribute.title ? '' : currentAttribute.title,
        hasNoneAttribute: !currentAttribute.hasNoneAttribute
          ? false
          : currentAttribute.hasNoneAttribute,
      });
    }
  }, [currentAttribute]);

  //#################### set the submit state ###############################

  const [isSubmit, setIsSubmit] = useState(false);

  //#################### reset for data when modal is closed ####################

  const resetModal = () => {

    //reset the submit state
    setIsSubmit(false);
    //reset the form data to initial state
    setFormData(initialState);
  };

  //########################## explod form data vaiables ######################
  const { title } = formData;

  //########################## change event for all inputs ######################
  const onChange = (e) => {
    if (e.target.name === 'title') {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    else if (e.target.name === 'hasNoneAttribute') {
      setFormData({ ...formData, [e.target.name]: e.target.checked });
    }

  };

  //########################## submit form data #############################
  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (attributeId == null) {
      create(formData).then((res) => {
        if (res === true) {
          setIsNewCreated(true);
          toggle();
        } else {
          setIsSubmit(false);
        }
      });
    } else {
      edit(formData, attributeId).then((res) => {
        if (res === true) {
          toggle();
        } else {
          setIsSubmit(false);
        }
      });
    }
  };

  return (
    <div className="animated fadeIn">
      <Modal isOpen={isOpen} toggle={toggle} onClosed={resetModal}>
        <ModalHeader toggle={toggle}>
          {isEdit ? 'Edit' : 'Add'} Fitting Attribute
        </ModalHeader>

        <Form className="form-horizontal">
          <ModalBody>
            <FormGroup>
              <Label htmlFor="title">
                Attribute Title <span>*</span>
              </Label>
              <Input
                type="text"
                id="title"
                name="title"
                maxLength="100"
                value={title}
                placeholder="Enter attribute title"
                required
                onChange={(e) => onChange(e)}
                invalid={errorList.title ? true : false}
              />
              <Errors current_key="title" key="title" />
            </FormGroup>
          </ModalBody>
          <ModalFooter className="modalFooter">
            {modal_loading ? (
              <Spinner />
            ) : (
              <Button
                className="mr-05"
                type="submit"
                size="sm"
                color="primary"
                onClick={(e) => onSubmit(e)}
                disabled={isSubmit}
              >
                <i className="fa fa-dot-circle-o"></i> Submit
              </Button>
            )}
            <Button type="reset" size="sm" color="danger" onClick={toggle}>
              <i className="fa fa-ban"></i> Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

CreateFittingAttribute.defaultProps = {
  isOpen: false,
  setIsNewCreated: false,
  isEdit: false,
  attributeId: null,
};

CreateFittingAttribute.propTypes = {
  errorList: PropTypes.array.isRequired,
  modal_loading: PropTypes.bool.isRequired,
  create: PropTypes.func.isRequired,
  getAttributeById: PropTypes.func.isRequired,
  currentAttribute: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  modal_loading: state.fitting.modal_loading,
  currentAttribute: state.fitting.currentAttribute,
});
export default connect(mapStateToProps, { create, getAttributeById, edit })(
  CreateFittingAttribute
);
