import {
  STAFF_LOADING,
  STAFF_CREATED,
  STAFF_CREATE_ERROR,
  STAFF_FAIL,
  STAFF_MODAL_LOADING,
  STAFF_PASSWORD_UPDATED,
  STAFF_PASSWORD_UPDATE_ERROR,
  STAFF_UPDATED,
  STAFF_UPDATE_ERROR,
  STAFF_DELETED,
  STAFF_DELETE_ERROR,
  STAFF_LIST_UPDATED,
  GET_STAFF_BY_ID,
  STAFF_CHANGE_STATUS,
  STAFF_CHANGE_STATUS_ERROR,
  GET_STAFF_BY_ID_ERROR,
} from "actions/types";

const initalState = {
  staffList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentStaff: {},
  loading: false,
  modal_loading: false,
  isSubLoading: false,
  error: {},
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case STAFF_LOADING:
      return {
        ...state,
        staffList: payload.is_sub_element
          ? state.staffList
          : initalState.staffList,
        currentStaff: payload.is_sub_element
          ? state.currentStaff
          : initalState.currentStaff,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case STAFF_CREATED:
      return {
        ...state,
        loading: false,
      };
    case STAFF_CREATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case STAFF_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case STAFF_MODAL_LOADING:
      return {
        ...state,
        modal_loading: payload,
      };
    case STAFF_PASSWORD_UPDATED:
      return {
        ...state,
        modal_loading: false,
      };
    case STAFF_PASSWORD_UPDATE_ERROR:
      return {
        ...state,
        modal_loading: false,
      };
    case STAFF_UPDATED:
      return {
        ...state,
        currentStaff: {},
        loading: false,
      };
    case STAFF_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case STAFF_DELETED:
      return {
        ...state,
        loading: false,
      };
    case STAFF_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case STAFF_LIST_UPDATED:
      return {
        ...state,
        staffList: {
          data: payload.data.staffList.data,
          page: payload.data.staffList.metadata[0].current_page,
          count: payload.data.staffList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_STAFF_BY_ID:
      return {
        ...state,
        currentStaff: payload.data,
        loading: false,
      };
    case GET_STAFF_BY_ID_ERROR:
      return {
        ...state,
        currentStaff: {},
        loading: false
      }
    case STAFF_CHANGE_STATUS:
      return {
        ...state,
        staffList: {
          ...state.staffList,
          data: state.staffList.data.map((staff) =>
            staff._id === payload.data._id
              ? { ...staff, status: payload.data.status }
              : staff
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case STAFF_CHANGE_STATUS_ERROR:
      return {
        ...state,
        staffList: {
          ...state.staffList,
          data: state.staffList.data.map((staff) =>
            staff._id === payload.data
              ? { ...staff, is_failed: true }
              : { ...staff, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    default:
      return state;
  }
}
