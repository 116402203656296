import { SHIPPING_CANCELATIONS_LOADING, SHIPPING_CANCELATIONS, SHIPPING_CANCELATIONS_FAILED } from "actions/types";

const initalState = {
    shippingCancelations: {
        page: 1,
        data: [],
        count: 0,
    },
    loading: false,
};

export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
        case SHIPPING_CANCELATIONS_LOADING:
            return {
                ...state,
                loading: true
            }
        case SHIPPING_CANCELATIONS:
            return {
                ...state,
                shippingCancelations: {
                    data: payload.data.shippingCancelations.data,
                    page: payload.data.shippingCancelations.metadata[0].current_page,
                    count: payload.data.shippingCancelations.metadata[0].totalRecord,
                },
                loading: false,
            }
        case SHIPPING_CANCELATIONS_FAILED:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}