/* eslint-disable */
import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { connect, useDispatch } from 'react-redux';
import { edit, getVideoById, notFound } from 'actions/admin/videoGallery';
import Spinner from 'views/Spinner';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import Errors from 'views/Notifications/Errors';
import { REMOVE_ERRORS } from 'actions/types';

const EditVideo = ({
  getVideoById,
  edit,
  video: { currentVideo, loading },
  history,
  match,
  errorList,
}) => {
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    // description: '',
    image: '',
    oldImage: '',
    oldVideo: '', // old video name (thumbnail)
    selectedFile: '', // video file
    video: '', // object url of video (video src)
    isNewFileSelected: 0,
  });

  // to resolve useEffect dependency error

  //**************** use memo to get current video *********************
  const { video_id: video_id } = useParams();
  useMemo(() => {

    getVideoById(video_id).then((res) => {
      if (res === undefined) {
        notFound(navigate); // page not found
      }
    });
  }, [getVideoById, video_id]);

  //**************** use memo to set form data from current video *********************
  useMemo(() => {
    if (Object.keys(currentVideo).length > 0) {
      setFormData({
        ...currentVideo,
        video: !currentVideo.thumbnail ? '' : currentVideo.thumbnail,
        oldImage: !currentVideo.image ? '' : currentVideo.image,
        oldVideo: !currentVideo.thumbnail ? '' : currentVideo.thumbnail,
        image: !currentVideo.image ? '' : currentVideo.image,
        isNewFileSelected: 0,
      });
    }
  }, [currentVideo]);

  //**************** expload form data *********************
  const {
    title,
    // description,
    image,
    // oldVideo,
    video,
  } = formData;

  //**************** use dispatch *********************


  //**************** handle on change event *********************
  const onChange = (e) => {
    if (e.target.name === 'image') {
      let image_file = e.target.files[0];

      let setFileUrl = URL.createObjectURL(image_file);
      const reader = new FileReader();
      reader.readAsDataURL(image_file);
      reader.onload = () => {
        // Use a regex to remove data url part
        const base64String = reader.result;
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
          selectedImgFile: base64String,
          image: setFileUrl,
        });
      };
      document.getElementById(`image`).value = '';
    } else if (e.target.name === 'thumbnail') {
      let video_file = e.target.files[0];

      let video_url = URL.createObjectURL(video_file);

      const reader = new FileReader();
      reader.readAsDataURL(video_file);
      reader.onload = () => {
        // Use a regex to remove data url part
        const base64String = reader.result;
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
          selectedFile: base64String,
          video: video_url,
          isNewFileSelected: 1,
        });
      };
      document.getElementById(`thumbnail`).value = '';
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value.trimLeft() });
    }
  };

  //**************** handle on submit event *********************
  const onSubmit = (e) => {
    e.preventDefault();
    edit(formData, navigate, video_id);
  };

  return loading || Object.keys(currentVideo).length === 0 ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="12">
          <Card>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="title">
                        Title <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="title"
                        name="title"
                        maxLength="100"
                        value={title}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.title ? true : false}
                      />
                      <Errors current_key="title" key="title" />
                    </FormGroup>
                  </Col>
                  {/* <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="description">
                        Description <span>*</span>
                      </Label>
                      <Input
                        type="textarea"
                        id="description"
                        name="description"
                        value={description}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.description ? true : false}
                      />
                      <Errors current_key="description" key="description" />
                    </FormGroup>
                  </Col> */}
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="thumbnail">
                        Image/thumbnail (Image ratio should be 16:9 for perfect fit)<span>*</span>
                      </Label>
                      <Input
                        type="file"
                        accept="image/*"
                        id="image"
                        name="image"
                        onChange={e => onChange(e)}
                        invalid={errorList.image ? true : false}
                      />
                      {image && <img
                        src={image}
                        className="preview-img" alt="" />
                      }
                      <Errors
                        current_key="image"
                        key="image"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="thumbnail">
                        Video <span>*</span>
                      </Label>
                      <Input
                        type="file"
                        id="thumbnail"
                        name="thumbnail"
                        onChange={(e) => onChange(e)}
                        multiple
                        invalid={errorList.video ? true : false}
                      />
                      {video && <video
                        controls={true}
                        src={video}
                        className="preview-video"
                        alt=""
                      />
                      }
                      <Errors current_key="video" key="video" />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button type="submit" size="sm" color="primary">
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
                <Link to="/admin/gallery-video">
                  <Button type="reset" size="sm" color="danger">
                    <i className="fa fa-ban"></i> Cancel
                  </Button>
                </Link>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditVideo.propTypes = {
  getVideoById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  notFound: PropTypes.func.isRequired,
  video: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  video: state.videoGallery,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  getVideoById,
  edit,
  notFound,
})(EditVideo);
