exports.parcelsError = (message) => {
    let error =
        Object.keys(message.parcels[0]).length > 0 ? (
            `<p>
                <span>
                    ${message.parcels[0].__all__
                ? message.parcels[0].__all__[0]
                : null}
                </span>
                <br></br>
                <span>
                    ${message.parcels[0].weight
                ? message.parcels[0].weight[0]
                : null}
                </span>
                <br></br>
                <span>
                    ${message.parcels[0].height
                ? message.parcels[0].height[0]
                : null}
                </span>
                <br></br>
                <span>
                    ${message.parcels[0].length
                ? message.parcels[0].length[0]
                : null}
                </span>
                <br></br>
                <span>
                    ${message.parcels[0].width
                ? message.parcels[0].width[0]
                : null}
                </span>
                <br></br>
            </p>`) : null;

    return error;
};

exports.updateBuildSupply = (primaryData, updateData) => {
    const buildSupplyData = updateData.map((i) => {
        let stock_quantity = primaryData.find((o) => i.build_shop_supply_id === o._id && o.sku === i.sku)?.stock_quantity;
        i["stock_quantity"] = stock_quantity;
        return i;
    })
    return buildSupplyData;

}