import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { getProductById } from 'actions/admin/product';

import BasicInfoExternal from './BasicInfoExternal';

import ProductImages from './ProductImages';
import Spinner from 'views/Spinner';

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Col,
  Row,
} from 'reactstrap';
import classnames from 'classnames';

const EditProduct = ({
  match,
  getProductById,
  // loading,
  currentProduct,
  history,
}) => {
  
  const { product_id: product_id } = useParams();
  //################ insure that currentProduct should has new state  #######################
  const [newRecord, setNewRecord] = useState(false);
  useMemo(() => {
    getProductById(product_id).then((res) => {
      setNewRecord(true);
    });
  }, [product_id, getProductById]);

  //########################## set the initial active tab ##############################
  const [activeTab, setActiveTab] = useState({
    productTab: 'basic_info_tab',
  });

  //########################## change the active tab ##############################
  const changeTab = (tab) => {
    if (activeTab.productTab !== tab)
      setActiveTab({ ...activeTab, productTab: tab });
  };

  return !newRecord || Object.keys(currentProduct).length === 0 ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="12">
          <Nav tabs className="productTabs">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab.productTab === 'basic_info_tab',
                })}
                onClick={() => {
                  changeTab('basic_info_tab');
                }}
              >
                Basic Info
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab.productTab === 'images_tab',
                })}
                onClick={() => {
                  changeTab('images_tab');
                }}
              >
                Images
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab.productTab}>
            <TabPane tabId="basic_info_tab">
              <BasicInfoExternal
                product_id={product_id}
                history={history}
                changeTab={changeTab}
              />
            </TabPane>
            <TabPane tabId="images_tab">
              <ProductImages
                product_id={product_id}
                history={history}
                changeTab={changeTab}
              />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

EditProduct.propTypes = {
  getProductById: PropTypes.func.isRequired,
  // loading: PropTypes.bool.isRequired,
  currentProduct: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  // loading: state.product.loading,
  currentProduct: state.product.currentProduct,
});

export default connect(mapStateToProps, { getProductById })(EditProduct);
