import axios from 'axios';

import {
  SHIPPING_BOX_MODAL_LOADING,
  SHIPPING_BOX_ADDED,
  SHIPPING_BOX_ADDED_ERROR,
  SHIPPING_BOX_FAIL,
  SHIPPING_BOX_LOADING,
  DELETE_SHIPPING_BOX,
  SHIPPING_BOX_LIST_UPDATED,
  CUSTOM_SHIPPING_BOX_LIST_UPDATED,
  CHANGE_SHIPPING_BOX_STATUS,
  CHANGE_SHIPPING_BOX_NATIONAL_INTERNATIONAL,
  GET_SHIPPING_BOX_BY_ID,
  SHIPPING_BOX_UPDATED,
  SHIPPING_BOX_UPDATED_ERROR,
  SET_ERRORS_LIST,
  SHIPPING_FLEET_REMARK_LOADING,
  SHIPPING_ADDED_FLEET_REMARK,
  SHIPPING_ADDED_FLEET_ERROR,
  SHIPPING_ADDED_FLEET_FAIL,
} from 'actions/types';

// Add Shipping box
export const add = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: SHIPPING_BOX_MODAL_LOADING,
    });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.post(
      '/api/admin/shipping-box/add',
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: SHIPPING_BOX_ADDED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });

      return { status: true, box: res.data.response };
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: SHIPPING_BOX_ADDED_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return { status: false };
    }
  } catch (err) {
    dispatch({
      type: SHIPPING_BOX_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete Shipping box
export const deleteShippingBox = (shipping_box_id) => async (dispatch) => {
  try {
    dispatch({
      type: SHIPPING_BOX_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(
      `/api/admin/shipping-box/${shipping_box_id}`,
      config
    );
    dispatch({
      type: DELETE_SHIPPING_BOX,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: shipping_box_id,
      },
    });
    return true;
  } catch (err) {
    dispatch({
      type: SHIPPING_BOX_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

//get Shipping box list

export const getShippingBoxList = (shippingBoxParams) => async (dispatch) => {
  try {
    dispatch({
      type: SHIPPING_BOX_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: shippingBoxParams,
    };

    const res = await axios.get(`/api/admin/shipping-box/`, config);

    dispatch({
      type: SHIPPING_BOX_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          shippingBoxParams: shippingBoxParams,
          shippingBoxList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    dispatch({
      type: SHIPPING_BOX_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get custom shipping box list
export const getCustomShippingBoxList = (shippingBoxParams) => async (dispatch) => {
  try {
    dispatch({
      type: SHIPPING_BOX_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.get(`/api/admin/shipping-box/custom`, config);
    console.log(res, "res");
    dispatch({
      type: CUSTOM_SHIPPING_BOX_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          customShippingBoxList: res.data.response,
        },
      },
    });
    return res;
  } catch (err) {
    dispatch({
      type: SHIPPING_BOX_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//change status
export const changeStatus = (shipping_box_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: SHIPPING_BOX_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/shipping-box/change-status/${shipping_box_id}`,
      { status },
      config
    );
    await dispatch({
      type: CHANGE_SHIPPING_BOX_STATUS,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
    return true;
  } catch (err) {
    dispatch({
      type: SHIPPING_BOX_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

//Mark international or national
export const markNationalInternational =
  (shipping_box_id, is_international) => async (dispatch) => {
    try {
      dispatch({
        type: SHIPPING_BOX_LOADING,
        payload: { is_loading: true, is_sub_element: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/shipping-box/national-international/${shipping_box_id}`,
        { is_international },
        config
      );
      await dispatch({
        type: CHANGE_SHIPPING_BOX_NATIONAL_INTERNATIONAL,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } catch (err) {
      dispatch({
        type: SHIPPING_BOX_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
      return false;
    }
  };

// Get shipping box  by id
export const getShippingBoxById = (shipping_box_id) => async (dispatch) => {
  try {
    dispatch({
      type: SHIPPING_BOX_MODAL_LOADING,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/shipping-box/${shipping_box_id}`,
      config
    );
    await dispatch({
      type: GET_SHIPPING_BOX_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
    return res.data.response;
  } catch (err) {
    dispatch({
      type: SHIPPING_BOX_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit shipping box
export const edit = (formData, shipping_box_id) => async (dispatch) => {
  try {
    dispatch({
      type: SHIPPING_BOX_MODAL_LOADING,
    });

    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.post(
      `/api/admin/shipping-box/${shipping_box_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: SHIPPING_BOX_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: SHIPPING_BOX_UPDATED_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: SHIPPING_BOX_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

// Add Shipping box
export const AddFleetRemark = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: SHIPPING_FLEET_REMARK_LOADING,
    });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.post(
      '/api/admin/shipping-box/add-freight-remark',
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: SHIPPING_ADDED_FLEET_REMARK,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });

      return { status: true, box: res.data.response };
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: SHIPPING_ADDED_FLEET_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return { status: false };
    }
  } catch (err) {
    dispatch({
      type: SHIPPING_ADDED_FLEET_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
