/* eslint-disable */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import randomstring from 'randomstring';

import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';

import {
  add,
  getUserSpecificOptionList,
  getCategoriesOptionList,
  getProductsOptionList,
  getSkuOptionList,
  getFittingAccessoriesOptionList,
} from 'actions/admin/coupon';

import { COUPON_DEFAULT_LENGTH, CUSTOMER_TYPE } from 'constants/index';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { REMOVE_ERRORS } from 'actions/types';
import Select from 'react-select';
import { useMemo } from 'react';
import AsyncSelect from 'react-select/async';

const AddCoupon = ({
  add,
  history,
  errorList,
  loading,
  getUserSpecificOptionList,
  userSpecificOptionsList,
  userSpecificOptionsListLoading,
  getCategoriesOptionList,
  applicableCategoriesOptionsList,
  getProductsOptionList,
  applicableProductsOptionsList,
  getSkuOptionList,
  applicableSkuOptionsList,
  getFittingAccessoriesOptionList,
  applicableFittingAccessoriesOptionsList,
}) => {
  /**************** random string generated for coupon *********************/

  //   randomstring.generate({
  //     length: COUPON_DEFAULT_LENGTH,
  //     charset: 'alphanumeric',
  //     capitalization: 'uppercase',
  //   })
  // );

  /**************** use state for form data *********************/

  let navigate = useNavigate();
  const coupon_code_str = Math.random()
    .toString(36)
    .substr(2, COUPON_DEFAULT_LENGTH);
  const [formData, setFormData] = useState({
    coupon_code: coupon_code_str.toUpperCase(),
    user_specific: [],
    consumer_type: CUSTOMER_TYPE.ALL,
    // discount: '',
    min_purchase: 0,
    max_discount: 0,
    usage_limit: '',
    expiry_date: '',
    comment_text: '',
    discountValueType: 'per',
    discountValue: 0,
    applicableFor: '',
    applicableCategories: [],
    applicableProducts: [],
    applicableSKUs: [],
    applicableFittingAccessories: [],
    selectedCategories: [],
    selectedProducts: [],
    selectedSKUs: [],
    selectedFittingAccessories: [],
    selectedUserSpecific: [],
  });
  const [singleUse, setSingleUse] = useState(false);
  const [isLoadingCategories, setLoadingCategories] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState([]);

  let timers = {
    categories: 0,
    products: 0,
    sku: 0,
    fittingAccessories: 0,
  };

  /**************** expload form data *********************/
  const {
    coupon_code,
    // user_specific,
    consumer_type,
    // discount,
    min_purchase,
    max_discount,
    usage_limit,
    expiry_date,
    comment_text,
    discountValueType,
    discountValue,
    applicableFor,
    // applicableCategories,
    selectedCategories,
    selectedProducts,
    selectedSKUs,
    selectedFittingAccessories,
    selectedUserSpecific,
  } = formData;

  //################## to get user list ###################
  useMemo(() => {
    getUserSpecificOptionList(consumer_type);
  }, [consumer_type]);

  //################## to get categories/products/sku/fitting accessories list ###################
  useEffect(() => {
    setFormData({
      ...formData,
      applicableCategories: [],
      applicableProducts: [],
      applicableSKUs: [],
      applicableFittingAccessories: [],
      selectedCategories: [],
      selectedProducts: [],
      selectedSKUs: [],
      selectedFittingAccessories: [],
    });
    if (applicableFor) {
      switch (applicableFor) {
        case 'shaftCategories':
          getCategoriesOptionList('SHAFTS');
          break;
        case 'shaftProducts':
          getProductsOptionList({ product_id: null, type: 'SHAFTS' });
          break;
        case 'shaftSku':
          getSkuOptionList('SHAFTS');
          break;
        case 'shaftFittingAccessories':
          getFittingAccessoriesOptionList('SHAFTS');
          break;
        case 'gearCategories':
          getCategoriesOptionList('OTHER');
          break;
        case 'gearProducts':
          getProductsOptionList({ product_id: null, type: 'OTHER' });
          break;
        case 'gearSku':
          getSkuOptionList('OTHER');
          break;
        case 'gearFittingAccessories':
          getFittingAccessoriesOptionList('OTHER');
          break;
      }
    }
  }, [applicableFor]);

  /**************** dispatch to remove error *********************/

  /**************** on Change event *********************/
  useEffect(() => {
    if (discountValueType === 'fixed') {
      setFormData({ ...formData, max_discount: 0 });
    }
  }, [discountValueType]);

  useEffect(() => {
    if (parseInt(consumer_type) !== CUSTOMER_TYPE.ALL) {
      setFormData({
        ...formData,
        user_specific: [],
        selectedUserSpecific: [],
      });
    }
  }, [consumer_type]);

  useEffect(() => {
    let updated_code = coupon_code.replace(/[^A-Z0-9]+/gi, '_').toUpperCase();
    setFormData({ ...formData, coupon_code: updated_code });
  }, [coupon_code]);

  const onChange = (e) => {
    if (
      e.target.name === 'discountValueType' &&
      e.target.value?.trim() === 'fixed'
    ) {
      setFormData({ ...formData, max_discount: 0 });
    }
    if (
      e.target.name === 'consumer_type' &&
      parseInt(e.target.value?.trim()) !== CUSTOMER_TYPE.ALL
    ) {
      setFormData({
        ...formData,
        user_specific: [],
        selectedUserSpecific: [],
      });
    }
    if (e.target.name === 'applicableFor') {
      setFormData({
        ...formData,
        applicableCategories: [],
        applicableProducts: [],
        applicableSKUs: [],
        applicableFittingAccessories: [],
        selectedCategories: [],
        selectedProducts: [],
        selectedSKUs: [],
        selectedFittingAccessories: [],
      });
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });

    // switch (e.target.name) {
    //   case "applicableFor":
    //     if (e.target.value === "categories") {
    //       setFormData({
    //         ...formData,
    //         [e.target.name]: e.target.value,
    //         selectedProducts: [],
    //         applicableProducts: [],
    //         selectedSKUs: [],
    //         applicableSKUs: [],
    //         selectedFittingAccessories: [],
    //         applicableFittingAccessories: [],
    //       });
    //     } else if (e.target.value === "products") {
    //       setFormData({
    //         ...formData,
    //         [e.target.name]: e.target.value,
    //         selectedCategories: [],
    //         applicableCategories: [],
    //         selectedSKUs: [],
    //         applicableSKUs: [],
    //         selectedFittingAccessories: [],
    //         applicableFittingAccessories: [],
    //       });
    //     }
    //     if (e.target.value === "sku") {
    //       setFormData({
    //         ...formData,
    //         [e.target.name]: e.target.value,
    //         selectedCategories: [],
    //         applicableCategories: [],
    //         selectedProducts: [],
    //         applicableProducts: [],
    //         selectedFittingAccessories: [],
    //         applicableFittingAccessories: [],
    //       });
    //     } else if (e.target.value === "fittingAccessories") {
    //       setFormData({
    //         ...formData,
    //         [e.target.name]: e.target.value,
    //         selectedCategories: [],
    //         applicableCategories: [],
    //         selectedProducts: [],
    //         applicableProducts: [],
    //         selectedSKUs: [],
    //         applicableSKUs: [],
    //       });
    //     } else {
    //       setFormData({
    //         ...formData,
    //         [e.target.name]: e.target.value,
    //       });
    //     }
    //     break;
    //   default:
    //     setFormData({ ...formData, [e.target.name]: e.target.value });
    //
    // }
  };
  const onChangeCouponCode = (e) => {
    e.target.value = e.target.value?.trim()?.replace(/[^a-zA-Z0-9]/g, '');
    setFormData({ ...formData, [e.target.name]: e.target.value?.trim() });
  };

  const onChangeSingleUse = (e) => {
    setSingleUse(!singleUse);
  };

  const handleUserSpecific = (userSpecificOptions) => {
    let userSpecificIds = [];
    userSpecificOptions.map((item, index) => {
      userSpecificIds.push(item.value);
    });
    setFormData({
      ...formData,
      user_specific: userSpecificIds,
      selectedUserSpecific: userSpecificOptions,
    });
  };

  const selectAll = () => {
    switch (applicableFor) {
      case 'shaftCategories':
        let applicableShaftCategoriesIds = [];
        applicableCategoriesOptionsList.map((item, index) => {
          applicableShaftCategoriesIds.push(item.value);
        });
        setFormData({
          ...formData,
          applicableCategories: applicableShaftCategoriesIds,
          selectedCategories: applicableCategoriesOptionsList,
          applicableProducts: [],
          applicableSKUs: [],
          applicableFittingAccessories: [],
          selectedProducts: [],
          selectedSKUs: [],
          selectedFittingAccessories: [],
        });
        break;
      case 'shaftProducts':
        let applicableShaftProductsIds = [];
        applicableProductsOptionsList.map((item, index) => {
          applicableShaftProductsIds.push(item.value);
        });
        setFormData({
          ...formData,
          applicableProducts: applicableShaftProductsIds,
          selectedProducts: applicableProductsOptionsList,
          applicableCategories: [],
          applicableSKUs: [],
          applicableFittingAccessories: [],
          selectedCategories: [],
          selectedSKUs: [],
          selectedFittingAccessories: [],
        });
        break;
      case 'shaftSku':
        let applicableShaftSkuIds = [];
        applicableSkuOptionsList.map((item, index) => {
          applicableShaftSkuIds.push(item.value);
        });
        setFormData({
          ...formData,
          applicableSKUs: applicableShaftSkuIds,
          selectedSKUs: applicableSkuOptionsList,
          applicableCategories: [],
          applicableProducts: [],
          applicableFittingAccessories: [],
          selectedCategories: [],
          selectedProducts: [],
          selectedFittingAccessories: [],
        });
        break;
      case 'gearCategories':
        let applicableGearCategoriesIds = [];
        applicableCategoriesOptionsList.map((item, index) => {
          applicableGearCategoriesIds.push(item.value);
        });
        setFormData({
          ...formData,
          applicableCategories: applicableGearCategoriesIds,
          selectedCategories: applicableCategoriesOptionsList,
          applicableProducts: [],
          applicableSKUs: [],
          applicableFittingAccessories: [],
          selectedProducts: [],
          selectedSKUs: [],
          selectedFittingAccessories: [],
        });
        break;
      case 'gearProducts':
        let applicableGearProductsIds = [];
        applicableProductsOptionsList.map((item, index) => {
          applicableGearProductsIds.push(item.value);
        });
        setFormData({
          ...formData,
          applicableProducts: applicableGearProductsIds,
          selectedProducts: applicableProductsOptionsList,
          applicableCategories: [],
          applicableSKUs: [],
          applicableFittingAccessories: [],
          selectedCategories: [],
          selectedSKUs: [],
          selectedFittingAccessories: [],
        });
        break;
      case 'gearSku':
        let applicableGearSkuIds = [];
        applicableSkuOptionsList.map((item, index) => {
          applicableGearSkuIds.push(item.value);
        });
        setFormData({
          ...formData,
          applicableSKUs: applicableGearSkuIds,
          selectedSKUs: applicableSkuOptionsList,
          applicableCategories: [],
          applicableProducts: [],
          applicableFittingAccessories: [],
          selectedCategories: [],
          selectedProducts: [],
          selectedFittingAccessories: [],
        });
        break;
    }
  };

  const handleApplicableForCategories = (applicableCategoriesOptions) => {
    let applicableCategoriesIds = [];
    applicableCategoriesOptions.map((item, index) => {
      applicableCategoriesIds.push(item.value);
    });
    setFormData({
      ...formData,
      applicableCategories: applicableCategoriesIds,
      selectedCategories: applicableCategoriesOptions,
      applicableProducts: [],
      applicableSKUs: [],
      applicableFittingAccessories: [],
      selectedProducts: [],
      selectedSKUs: [],
      selectedFittingAccessories: [],
    });
  };

  const handleApplicableForProducts = (applicableProductsOptions) => {
    let applicableProductsIds = [];
    applicableProductsOptions.map((item, index) => {
      applicableProductsIds.push(item.value);
    });
    setFormData({
      ...formData,
      applicableProducts: applicableProductsIds,
      selectedProducts: applicableProductsOptions,
      applicableCategories: [],
      applicableSKUs: [],
      applicableFittingAccessories: [],
      selectedCategories: [],
      selectedSKUs: [],
      selectedFittingAccessories: [],
    });
  };

  const handleApplicableForSku = (applicableSkuOptions) => {
    let applicableSkuIds = [];
    applicableSkuOptions.map((item, index) => {
      applicableSkuIds.push(item.value);
    });
    setFormData({
      ...formData,
      applicableSKUs: applicableSkuIds,
      selectedSKUs: applicableSkuOptions,
      applicableCategories: [],
      applicableProducts: [],
      applicableFittingAccessories: [],
      selectedCategories: [],
      selectedProducts: [],
      selectedFittingAccessories: [],
    });
  };

  const handleApplicableForFittingAccessories = (
    applicableFittingAccessoriesOptions
  ) => {
    let applicableFittingAccessoriesIds = [];
    applicableFittingAccessoriesOptions.map((item, index) => {
      applicableFittingAccessoriesIds.push(item.value);
    });
    setFormData({
      ...formData,
      applicableFittingAccessories: applicableFittingAccessoriesIds,
      selectedFittingAccessories: applicableFittingAccessoriesOptions,
      applicableCategories: [],
      applicableProducts: [],
      applicableSKUs: [],
      selectedCategories: [],
      selectedProducts: [],
      selectedSKUs: [],
    });
  };

  //########################## handle async select ##############################
  const handleSelect = (name) => (value, action) => {
    let fieldValue = [];
    if (value) {
      if (value.length) {
        fieldValue = value.map((item) => item.value);
      } else if (value.value) {
        fieldValue = value.value;
      }
    }
    switch (name) {
      case 'applicableCategories':
        setFormData({
          ...formData,
          [name]: fieldValue,
          selectedCategories: value,
        });
        break;
      case 'applicableProducts':
        setFormData((form) => ({
          ...form,
          [name]: fieldValue,
          selectedProducts: value,
        }));
        break;
      case 'applicableSKUs':
        setFormData({
          ...formData,
          [name]: fieldValue,
          selectedSKUs: value,
        });
        break;
      case 'applicableFittingAccessories':
        setFormData((form) => ({
          ...form,
          [name]: fieldValue,
          selectedFittingAccessories: value,
        }));
        break;
    }
  };

  const loadCategoriesOptions = () => {
    setLoadingCategories(true);
    return getUserSpecificOptionList(1).then((res) => {
      setLoadingCategories(false);
      if (res.status) {
        const { data } = res;
        return data.map((customer) => ({
          value: customer.user._id,
          label: `${customer.user.name} - (${customer.user.contact})`,
        }));
      }
    });
  };

  const execWithDelay =
    (cb, input, type) =>
    (delay = 750) => {
      clearTimeout(timers[type]);
      return new Promise((resolve) => {
        timers[type] = setTimeout(() => {
          resolve(cb(input));
        }, delay);
      });
    };

  const clearApplicableFor = () => {
    setFormData({
      ...formData,
      applicableFor: '',
      applicableCategories: [],
      applicableProducts: [],
      applicableSKUs: [],
      applicableFittingAccessories: [],
      selectedCategories: [],
      selectedProducts: [],
      selectedSKUs: [],
      selectedFittingAccessories: [],
    });
  };

  const disablePastDate = () => {
    const today = new Date();

    const mm = ('0' + (today.getMonth() + 1)).slice(-2); // get month and convert to 2 digits
    const dd = ('0' + today.getDate()).slice(-2); // get date and convert to 2 digits
    const yyyy = today.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  };

  /**************** on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    Object.keys(formData).forEach((k) => {
      typeof formData[k] === 'string'
        ? (formData[k] = formData[k]?.trim())
        : (formData[k] = formData[k]);
    });
    add({ ...formData, single_use: singleUse }, navigate);
  };

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="coupon_code">
                          Coupon code <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="coupon_code"
                          name="coupon_code"
                          maxLength={50}
                          value={coupon_code}
                          required
                          onChange={(e) => onChangeCouponCode(e)}
                          invalid={errorList.coupon_code ? true : false}
                        />
                        <Errors current_key="coupon_code" key="coupon_code" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="consumer_type">
                          Consumer type <span>*</span>
                        </Label>
                        <Input
                          type="select"
                          id="consumer_type"
                          name="consumer_type"
                          value={consumer_type}
                          defaultValue={CUSTOMER_TYPE.ALL}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.consumer_type ? true : false}
                        >
                          {/* <option value="">{'Select'}</option> */}
                          <option value={CUSTOMER_TYPE.ALL}>{'All'}</option>
                          <option value={CUSTOMER_TYPE.DEALER}>
                            {'Dealer'}
                          </option>
                          <option value={CUSTOMER_TYPE.CUSTOMER}>
                            {'Customer'}
                          </option>
                        </Input>
                        <Errors
                          current_key="consumer_type"
                          key="consumer_type"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="user_specific">User specific</Label>
                        {/* <Input
                          type="text"
                          id="user_specific"
                          name="user_specific"
                          value={user_specific}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.user_specific ? true : false}
                        /> */}
                        <Select
                          isMulti
                          id="user_specific"
                          name="user_specific"
                          options={userSpecificOptionsList}
                          isClearable={true}
                          isSearchable={true}
                          onChange={(e) => handleUserSpecific(e)}
                          value={selectedUserSpecific}
                          // className="basic-multi-select"
                          // classNamePrefix="select"
                        />
                        <Errors
                          current_key="user_specific"
                          key="user_specific"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      {/* <FormGroup>
                        <Label htmlFor="discount">
                          Discount (in %) <span>*</span>{' '}
                        </Label>
                        <Input
                          type="number"
                          id="discount"
                          name="discount"
                          min={0}
                          required
                          value={discount}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.discount ? true : false}
                        />
                        <Errors current_key="discount" key="discount" />
                      </FormGroup> */}
                      <FormGroup>
                        <Label htmlFor="discount">
                          Discount in<span>*</span>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <Input
                            type="radio"
                            name="discountValueType"
                            onChange={(e) => onChange(e)}
                            value="per"
                            checked={discountValueType === 'per'}
                          />
                          Percentage (%) &nbsp;&nbsp;&nbsp;&nbsp;
                          <Input
                            type="radio"
                            name="discountValueType"
                            onChange={(e) => onChange(e)}
                            value="fixed"
                            checked={discountValueType === 'fixed'}
                          />
                          Value ($) <span> {'    '}</span>
                        </Label>
                        <Input
                          type="number"
                          id="discountValue"
                          name="discountValue"
                          min={0}
                          step={0.01}
                          value={discountValue}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.discountValue ? true : false}
                        />
                        <Errors
                          current_key="discountValue"
                          key="discountValue"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="min_purchase">Minimum purchase</Label>
                        <Input
                          type="number"
                          id="min_purchase"
                          name="min_purchase"
                          min={0}
                          step={0.01}
                          value={min_purchase}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.min_purchase ? true : false}
                        />
                        <Errors current_key="min_purchase" key="min_purchase" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="max_discount">
                          Maximum discount <span>*</span>{' '}
                        </Label>
                        <Input
                          type="number"
                          id="max_discount"
                          name="max_discount"
                          min={0}
                          step={0.01}
                          required
                          value={max_discount}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.max_discount ? true : false}
                          disabled={discountValueType === 'fixed'}
                        />
                        <Errors current_key="max_discount" key="max_discount" />
                      </FormGroup>
                    </Col>

                    <FormGroup tag="fieldset">
                      <Label>Coupon applicable for</Label>
                      <Button
                        color="link"
                        disabled={!applicableFor}
                        onClick={(e) => {
                          clearApplicableFor(e);
                        }}
                      >
                        Clear
                      </Button>
                      <Row>
                        <FormGroup>
                          <Row>
                            <Col sm={6} md={4}>
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="applicableFor"
                                    value="shaftCategories"
                                    checked={
                                      applicableFor === 'shaftCategories'
                                    }
                                    onChange={(e) => onChange(e)}
                                  />{' '}
                                  Shaft Categories
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col sm={6} md={4}>
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="applicableFor"
                                    value="shaftProducts"
                                    checked={applicableFor === 'shaftProducts'}
                                    onChange={(e) => onChange(e)}
                                  />{' '}
                                  Shaft Products
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col sm={6} md={4}>
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="applicableFor"
                                    value="shaftSku"
                                    checked={applicableFor === 'shaftSku'}
                                    onChange={(e) => onChange(e)}
                                  />{' '}
                                  Shaft SKU
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                        {applicableFor === 'shaftCategories' && (
                          <FormGroup>
                            <Label htmlFor="applicableCategories">
                              Applicable Categories <span>*</span>{' '}
                              <Button
                                onClick={() => selectAll()}
                                style={{
                                  background: 'transparent',
                                  padding: '1px',
                                  border: 'none',
                                }}
                              >
                                Select All
                              </Button>
                            </Label>
                            <Select
                              isMulti
                              id="applicableCategories"
                              name="applicableCategories"
                              options={applicableCategoriesOptionsList}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(e) => handleApplicableForCategories(e)}
                              value={selectedCategories}
                              // className="basic-multi-select"
                              // classNamePrefix="select"
                            />
                            <Errors
                              current_key="applicableCategories"
                              key="applicableCategories"
                            />
                          </FormGroup>
                        )}
                        {applicableFor === 'shaftProducts' && (
                          <FormGroup>
                            <Label htmlFor="applicableProducts">
                              Applicable Products <span>*</span>{' '}
                              <Button
                                onClick={() => selectAll()}
                                style={{
                                  background: 'transparent',
                                  padding: '1px',
                                  border: 'none',
                                }}
                              >
                                Select All
                              </Button>
                            </Label>
                            <Select
                              isMulti
                              id="applicableProducts"
                              name="applicableProducts"
                              options={applicableProductsOptionsList}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(e) => handleApplicableForProducts(e)}
                              value={selectedProducts}
                              // className="basic-multi-select"
                              // classNamePrefix="select"
                            />
                            <Errors
                              current_key="applicableProducts"
                              key="applicableProducts"
                            />
                          </FormGroup>
                        )}
                        {applicableFor === 'shaftSku' && (
                          <FormGroup>
                            <Label htmlFor="applicableSKUs">
                              Applicable SKU <span>*</span>{' '}
                              <Button
                                onClick={() => selectAll()}
                                style={{
                                  background: 'transparent',
                                  padding: '1px',
                                  border: 'none',
                                }}
                              >
                                Select All
                              </Button>
                            </Label>
                            <Select
                              isMulti
                              id="applicableSKUs"
                              name="applicableSKUs"
                              options={applicableSkuOptionsList}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(e) => handleApplicableForSku(e)}
                              value={selectedSKUs}
                              // className="basic-multi-select"
                              // classNamePrefix="select"
                            />
                            <Errors
                              current_key="applicableSKUs"
                              key="applicableSKUs"
                            />
                          </FormGroup>
                        )}
                        {applicableFor === 'shaftFittingAccessories' && (
                          <FormGroup>
                            <Label htmlFor="applicableFittingAccessories">
                              Applicable Fitting Accessories <span>*</span>
                            </Label>
                            <Select
                              isMulti
                              id="applicableFittingAccessories"
                              name="applicableFittingAccessories"
                              options={applicableFittingAccessoriesOptionsList}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(e) =>
                                handleApplicableForFittingAccessories(e)
                              }
                              value={selectedFittingAccessories}
                              // className="basic-multi-select"
                              // classNamePrefix="select"
                            />
                            <Errors
                              current_key="applicableFittingAccessories"
                              key="applicableFittingAccessories"
                            />
                          </FormGroup>
                        )}
                        <FormGroup>
                          <Row>
                            <Col sm={6} md={4}>
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="applicableFor"
                                    value="gearCategories"
                                    checked={applicableFor === 'gearCategories'}
                                    onChange={(e) => onChange(e)}
                                  />{' '}
                                  Gear Categories
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col sm={6} md={4}>
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="applicableFor"
                                    value="gearProducts"
                                    checked={applicableFor === 'gearProducts'}
                                    onChange={(e) => onChange(e)}
                                  />{' '}
                                  Gear Products
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col sm={6} md={4}>
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="applicableFor"
                                    value="gearSku"
                                    checked={applicableFor === 'gearSku'}
                                    onChange={(e) => onChange(e)}
                                  />{' '}
                                  Gear SKU
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                        {applicableFor === 'gearCategories' && (
                          <FormGroup>
                            <Label htmlFor="applicableCategories">
                              Applicable Categories <span>*</span>{' '}
                              <Button
                                onClick={() => selectAll()}
                                style={{
                                  background: 'transparent',
                                  padding: '1px',
                                  border: 'none',
                                }}
                              >
                                Select All
                              </Button>
                            </Label>
                            <Select
                              isMulti
                              id="applicableCategories"
                              name="applicableCategories"
                              options={applicableCategoriesOptionsList}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(e) => handleApplicableForCategories(e)}
                              value={selectedCategories}
                              // className="basic-multi-select"
                              // classNamePrefix="select"
                            />
                            <Errors
                              current_key="applicableCategories"
                              key="applicableCategories"
                            />
                          </FormGroup>
                        )}
                        {applicableFor === 'gearProducts' && (
                          <FormGroup>
                            <Label htmlFor="applicableProducts">
                              Applicable Products <span>*</span>{' '}
                              <Button
                                onClick={() => selectAll()}
                                style={{
                                  background: 'transparent',
                                  padding: '1px',
                                  border: 'none',
                                }}
                              >
                                Select All
                              </Button>
                            </Label>
                            <Select
                              isMulti
                              id="applicableProducts"
                              name="applicableProducts"
                              options={applicableProductsOptionsList}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(e) => handleApplicableForProducts(e)}
                              value={selectedProducts}
                              // className="basic-multi-select"
                              // classNamePrefix="select"
                            />
                            <Errors
                              current_key="applicableProducts"
                              key="applicableProducts"
                            />
                          </FormGroup>
                        )}
                        {applicableFor === 'gearSku' && (
                          <FormGroup>
                            <Label htmlFor="applicableSKUs">
                              Applicable SKU <span>*</span>{' '}
                              <Button
                                onClick={() => selectAll()}
                                style={{
                                  background: 'transparent',
                                  padding: '1px',
                                  border: 'none',
                                }}
                              >
                                Select All
                              </Button>
                            </Label>
                            <Select
                              isMulti
                              id="applicableSKUs"
                              name="applicableSKUs"
                              options={applicableSkuOptionsList}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(e) => handleApplicableForSku(e)}
                              value={selectedSKUs}
                              // className="basic-multi-select"
                              // classNamePrefix="select"
                            />
                            <Errors
                              current_key="applicableSKUs"
                              key="applicableSKUs"
                            />
                          </FormGroup>
                        )}
                        {applicableFor === 'gearFittingAccessories' && (
                          <FormGroup>
                            <Label htmlFor="applicableFittingAccessories">
                              Applicable Fitting Accessories <span>*</span>
                            </Label>
                            <Select
                              isMulti
                              id="applicableFittingAccessories"
                              name="applicableFittingAccessories"
                              options={applicableFittingAccessoriesOptionsList}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(e) =>
                                handleApplicableForFittingAccessories(e)
                              }
                              value={selectedFittingAccessories}
                              // className="basic-multi-select"
                              // classNamePrefix="select"
                            />
                            <Errors
                              current_key="applicableFittingAccessories"
                              key="applicableFittingAccessories"
                            />
                          </FormGroup>
                        )}
                        <Col sm={6} md={4}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="applicableFor"
                                value="shipping"
                                checked={applicableFor === 'shipping'}
                                onChange={(e) => onChange(e)}
                              />{' '}
                              Shipping
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Errors current_key="applicableFor" key="applicableFor" />
                    </FormGroup>

                    <Col sm="5">
                      <FormGroup>
                        <Label htmlFor="usage_limit">
                          Usage limit <span>*</span>
                        </Label>
                        <Input
                          type="number"
                          id="usage_limit"
                          name="usage_limit"
                          min={0}
                          value={usage_limit}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.usage_limit ? true : false}
                        />
                        <Errors current_key="usage_limit" key="usage_limit" />
                      </FormGroup>
                    </Col>
                    <Col sm="1">
                      <FormGroup>
                        <Label htmlFor="singleUse">Single Use {'  '}</Label>
                        {/* <Input
                          type="number"
                          id="limit_per_user"
                          name="limit_per_user"
                          min={1}
                          value={limit_per_user}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.limit_per_user ? true : false}
                        /> */}
                        <Input
                          type="checkbox"
                          id="singleUse"
                          name="singleUse"
                          className="singleUse"
                          value={singleUse}
                          checked={singleUse}
                          onChange={(e) => onChangeSingleUse(e)}
                          style={{
                            display: 'block',
                            height: '30px',
                            width: '30px',
                            margin: '2px 0px 0px 0px',
                          }}
                        />
                        <Errors current_key="singleUse" key="singleUse" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="expiry_date">Expiry date</Label>
                        <Input
                          type="date"
                          id="expiry_date"
                          name="expiry_date"
                          value={expiry_date}
                          min={disablePastDate()}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.expiry_date ? true : false}
                        />
                        <Errors current_key="expiry_date" key="expiry_date" />
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="comment_text">Comment</Label>
                        <Input
                          type="textarea"
                          id="comment_text"
                          name="comment_text"
                          value={comment_text}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.comment_text ? true : false}
                        />
                        <Errors current_key="comment_text" key="comment_text" />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button
                    type="submit"
                    size="sm"
                    color="primary"
                    disabled={loading}
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Link to="/admin/coupons">
                    <Button type="reset" size="sm" color="danger">
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

AddCoupon.propTypes = {
  add: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errorList: PropTypes.array.isRequired,
  getUserSpecificOptionList: PropTypes.func.isRequired,
  userSpecificOptionsList: PropTypes.array.isRequired,
  userSpecificOptionsListLoading: PropTypes.bool.isRequired,
  getCategoriesOptionList: PropTypes.func.isRequired,
  applicableCategoriesOptionsList: PropTypes.array.isRequired,
  getProductsOptionList: PropTypes.func.isRequired,
  applicableProductsOptionsList: PropTypes.array.isRequired,
  getSkuOptionList: PropTypes.func.isRequired,
  applicableSkuOptionsList: PropTypes.array.isRequired,
  getFittingAccessoriesOptionList: PropTypes.func.isRequired,
  applicableFittingAccessoriesOptionsList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.coupon.loading,
  userSpecificOptionsListLoading: state.coupon.userSpecificOptionsListLoading,
  userSpecificOptionsList: state.coupon.userSpecificOptionsList,
  applicableCategoriesOptionsList: state.coupon.applicableCategoriesOptionsList,
  applicableProductsOptionsList: state.coupon.applicableProductsOptionsList,
  applicableSkuOptionsList: state.coupon.applicableSkuOptionsList,
  applicableFittingAccessoriesOptionsList:
    state.coupon.applicableFittingAccessoriesOptionsList,
});

export default connect(mapStateToProps, {
  add,
  getUserSpecificOptionList,
  getCategoriesOptionList,
  getProductsOptionList,
  getSkuOptionList,
  getFittingAccessoriesOptionList,
})(AddCoupon);
