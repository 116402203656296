import axios from 'axios';

import {
  DEALER_TYPE_MODAL_LOADING,
  DEALER_TYPE_CREATED,
  DEALER_TYPE_CREATE_ERROR,
  DEALER_TYPE_FAIL,
  DEALER_TYPE_LOADING,
  DEALER_TYPE_UPDATED,
  DEALER_TYPE_UPDATE_ERROR,
  DEALER_TYPE_LIST_UPDATED,
  GET_DEALER_TYPE_BY_ID,
  DEALER_TYPE_CHANGE_STATUS,
  DEALER_TYPE_CHANGE_STATUS_ERROR,
  DEALER_TYPE_DELETED,
  DEALER_TYPE_DELETE_ERROR,
  SET_ERRORS_LIST,
} from 'actions/types';

import { updateFilterData } from 'actions/commonActions';

// Create DealerType
export const create = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: DEALER_TYPE_MODAL_LOADING,
    });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.post(
      '/api/admin/dealer-type/create',
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: DEALER_TYPE_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: DEALER_TYPE_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: DEALER_TYPE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit DealerType
export const edit = (formData, history, dealerType_id) => async (dispatch) => {
  try {
    dispatch({
      type: DEALER_TYPE_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.post(
      `/api/admin/dealer-type/${dealerType_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: DEALER_TYPE_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/dealer-types');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: DEALER_TYPE_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: DEALER_TYPE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//Get DealerType List
export const getDealerTypeList = (dealerTypeParams) => async (dispatch) => {
  try {
    dispatch({
      type: DEALER_TYPE_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    dealerTypeParams = updateFilterData(dealerTypeParams);
    const config = {
      headers: { 'Content-Type': 'application/json' },
      params: dealerTypeParams,
    };

    const res = await axios.get(`/api/admin/dealer-type`, config);
    dispatch({
      type: DEALER_TYPE_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          dealerTypeParams: dealerTypeParams,
          dealerTypeList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    dispatch({
      type: DEALER_TYPE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Get DealerType by id
export const getDealerTypeById = (dealerType_id) => async (dispatch) => {
  try {
    dispatch({
      type: DEALER_TYPE_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.get(
      `/api/admin/dealer-type/${dealerType_id}`,
      config
    );
    await dispatch({
      type: GET_DEALER_TYPE_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: DEALER_TYPE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//change status
export const changeStatus = (dealerType_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: DEALER_TYPE_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.post(
      `/api/admin/dealer-type/change-status/${dealerType_id}`,
      { status },
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: DEALER_TYPE_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: DEALER_TYPE_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: dealerType_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: DEALER_TYPE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete DealerType Page
export const deleteDealerType = (dealerType_id) => async (dispatch) => {
  try {
    dispatch({
      type: DEALER_TYPE_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(
      `/api/admin/dealer-type/${dealerType_id}`,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: DEALER_TYPE_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: DEALER_TYPE_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: DEALER_TYPE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
