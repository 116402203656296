/* eslint-disable */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import CustomNoRowsOverlay from 'utils/CustomNoRowsOverlay';
import Loading from '../../../../utils/Loading';

import { Button, Card, CardBody, Col, Row, Input } from 'reactstrap';

import * as Constants from 'constants/index';

import {
  getFittingAttributeVariationList,
  deleteFitting,
  changeStatus,
  getInquiryForProductFitting,
  uploadFittingInventory,
} from 'actions/admin/fittings';
import { addBreadcrumDetails } from 'actions/admin/breadcrum';

import AddEditFittings from 'views/Admin/Fitting/Fittings/AddEditFittings';
import PerPageSelect from 'views/Admin/PerPageSelect';
import { RESET_FITTING_PART_DETAILS, SUCCESS_RESPONSE } from 'actions/types';

const renderFittingTitle = (params) => {
  if (params.data === undefined) {
    return <Loading />;
  } else {
    return <div>{params.data?.title}</div>;
  }
};

const renderStatus = (params, changeFittingStatus) => {
  return (
    <div>
      <Input
        type="select"
        name="status"
        id={params.data?._id}
        defaultValue={params.data?.status}
        onChange={(e, a) => {
          changeFittingStatus(params.data?._id, e.target.value);
        }}
      >
        <option value="0">Inactive</option>
        <option value="1">Active</option>
      </Input>
    </div>
  );
};

const renderActions = (
  params,
  openModal,
  deleteFitting,
  updateFittings,
  addBreadcrumDetails
) => {
  addBreadcrumDetails({ [params.data?._id]: params.data?.title });
  return (
    <div>
      {params.data?.attribute_title.toLowerCase() !== 'playing length' && (
        <Link
          to={`/admin/product/fittings/${params.data?.attribute_title.toLowerCase()}/${params.data?._id
            }/variations`}
        >
          <Button
            type="button"
            size="sm"
            color="info"
            title="Fitting attribute variations"
          >
            <i className="fa fa-list"></i>
          </Button>
        </Link>
      )}
      <Button
        type="button"
        size="sm"
        color="success"
        title="Edit"
        onClick={(e) => openModal(params.data?._id)}
      >
        <i className="fa fa-pencil"></i>
      </Button>
      <Button
        type="button"
        size="sm"
        color="danger"
        title="Delete"
        onClick={(e) => {
          if (
            window.confirm(
              `Are you sure, you want to delete ${params.data?.title} fitting?`
            )
          ) {
            deleteFitting(params.data?._id, history).then((res) => {
              if (res) updateFittings();
            });
          }
        }}
      >
        <i className="fa fa-trash"></i>
      </Button>
    </div>
  );
};

const FittingList = ({
  getFittingAttributeVariationList,
  deleteFitting,
  fitting_part_detail,
  fittingAttributeFittingList: { loading, data },
  changeStatus,
  addBreadcrumDetails,
  breadcrumDetails,
  uploadFittingInventory
}) => {
  let datas = {};

  const { attribute_id: attribute_id } = useParams();

  //################## Initillise sorting searching parameters by default values ###################
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
        limit: Constants.DEFAULT_PAGE_SIZE,
        page: 1,
        orderBy: 'created_at',
        ascending: 'desc',
        query: '',
        filter: {},
        onLoad: true,
      }
  );

  //################## to get updated list ###################
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);

  const [downloadData, setDownloadData] = useState([]);

  const updateFittings = () => {
    gridApi.purgeInfiniteCache();
  };

  const changeFittingStatus = async (id, value) => {
    await changeStatus(id, value);
    gridApi.purgeInfiniteCache();
  };

  //################ toggle add modal ############################
  const [variationAddModal, setOpenVariationAddModal] = useState(false);
  const toggleVariationAddModal = () => {
    setOpenVariationAddModal(!variationAddModal);
  };

  //################ attribute id useState ############################
  const [variationId, setVariationId] = useState(null);

  //################ toogle edit modal ############################

  const [editModal, setOpenEditModal] = useState(false);
  const toggleEditModal = () => {
    if (editModal) setVariationId(() => null);
    setOpenEditModal(() => !editModal);
  };

  //################ change attributeId when the edit button is clicked ############################
  const openModal = (variation_id) => {
    setVariationId(variation_id);
  };

  //################ open popup as the attributeId is changed ############################

  useEffect(() => {
    if (variationId) toggleEditModal();
  }, [variationId]);

  useEffect(() => {
    if (fitting_part_detail !== null) {
      const fitting_part_data = fitting_part_detail?.fitting_part_detail;
      setVariationId(fitting_part_data?._id);
      dispatch({
        type: RESET_FITTING_PART_DETAILS,
        payload: true
      });
    }
  }, [])

  // to resolve useEffect dependency error
  //################ reload the list ############################
  const [isNewCreated, setIsNewCreated] = useState(false);
  useEffect(() => {
    if (isNewCreated) {
      updateFittings();
      setIsNewCreated(false);
    }
  }, [isNewCreated]);

  //################################ category modal ####################

  const [category, setCategory] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [modal, setModal] = useState(false);
  const [adaptor, setAdaptor] = useState();

  const toggle = () => {
    if (modal) setCategory(() => null);
    setModal(() => !modal);
  };

  useEffect(() => {
    if (category) toggle();
  }, [category]);

  const titleColumn = {
    field: 'title',
    headerName: 'Title',
    suppressMovable: true,
    cellClass: 'suppress-movable-col',
    sortable: true,
    width: 230,
    colSpan: (params) => {
      if (params.data === undefined) {
        return 8;
      } else {
        return 0;
      }
    },
    filter: 'agTextColumnFilter',
    suppressSizeToFit: false,
    filterParams: {
      closeOnApply: true,
      filterOptions: Constants.TEXT_FILTER_OPTIONS,
      suppressAndOrCondition: true,
      debounceMs: 800,
    },
    cellRenderer: (params) => renderFittingTitle(params),
  };

  const statusColumn = {
    field: 'status',
    headerName: 'Status',
    suppressMovable: true,
    width: 200,
    cellClass: 'suppress-movable-col',
    sortable: true,
    cellRenderer: (params) => renderStatus(params, changeFittingStatus),
  };

  const actionColumn = {
    field: '_id',
    headerName: 'Actions',
    suppressMovable: true,
    width: 130,
    cellClass: 'suppress-movable-col',
    cellRenderer: (params) =>
      renderActions(
        params,
        openModal,
        deleteFitting,
        updateFittings,
        addBreadcrumDetails
      ),
  };

  //#########################Colums defination start ###################
  const grips = [titleColumn, statusColumn, actionColumn];

  const adaptors = [
    titleColumn,
    {
      field: 'inSensitivePartNumber',
      headerName: 'Part No.',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      width: 100,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => {
        return <div>{params.data?.part_no}</div>
      }
    },
    {
      field: 'variation_weight',
      headerName: 'Weight',
      suppressMovable: true,
      width: 100,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agNumberColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'price',
      headerName: 'Price $',
      width: 100,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agNumberColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => {
        return params.data?.price ?? 0;
      },
    },
    {
      field: 'inventory',
      headerName: 'Inventory',
      suppressMovable: true,
      width: 100,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agNumberColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'image',
      headerName: 'Image',
      suppressMovable: true,
      width: 140,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) => {
        return !params.data?.image ? (
          '-'
        ) : (
          <img
            src={
              'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/fitting-variation/' +
              params.data?.image
            }
            className="preview-img"
            alt=""
          />
        );
      },
    },
    statusColumn,
    actionColumn,
  ];
  const playingLength = grips;
  const buttonName =
    breadcrumDetails[attribute_id] === 'Grips' ||
      breadcrumDetails[attribute_id] === 'Adaptors'
      ? breadcrumDetails[attribute_id].slice(0, -1)
      : breadcrumDetails[attribute_id];
  const actions = (
    <div className="add-button-div">
      <Link to="/admin/product/fittings">
        <Button type="reset" size="sm" color="info">
          <i className="fa fa-arrow-left"></i> Back
        </Button>
      </Link>
      <Button color="primary" size="sm" onClick={toggleVariationAddModal}>
        <i className="fa fa-plus"></i> ADD {buttonName}
      </Button>
      <AddEditFittings
        isOpen={variationAddModal}
        toggle={toggleVariationAddModal}
        setIsNewCreated={setIsNewCreated}
        attributeId={attribute_id}
        fittingType={adaptor && adaptor.attribute_title}
        update={updateFittings}
      />
    </div>
  );

  const [tableFields, setTableFields] = useState(null);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });
  const types = ['grips', 'adaptors', 'playing length'];

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  const noRowsOverlayComponent = useMemo(() => {
    return CustomNoRowsOverlay;
  }, []);

  const noRowsTemplate = useMemo(() => {
    return {
      noRowsMessageFunc: () => 'no rows to show',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };

          const customData = await getFittingAttributeVariationList(
            customParams,
            attribute_id
          );
          if (
            customData &&
            types.includes(
              customData?.data?.response[0].data[0].attribute_title.toLowerCase()
            )
          ) {
            switch (
            customData?.data?.response[0].data[0].attribute_title.toLowerCase()
            ) {
              case 'grips':
                setTableFields(grips);
                break;
              case 'adaptors':
                setTableFields(adaptors);
                break;
              case 'playing length':
                setTableFields(playingLength);
                break;
              default:
                setTableFields(grips);
            }
          }
          if (customData?.data?.status) {
            datas = customData?.data?.response[0].data[0];
            setAdaptor(datas);
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });

  const handleCSVDownload = useCallback(async (event) => {
    let paramdata = { ...sortingParams, attribute_id: attribute_id }
    let data = await getInquiryForProductFitting(paramdata);
    setDownloadData(data.data);
    document.getElementById('downloadCsv').click();
  });

  const uploadCsv = (event) => {
    const file = event.target.files[0];
    if (file.type === "text/csv") {
      const reader = new FileReader();
      reader.onload = handleCSVConversion;
      reader.readAsText(file);
    } else {
      dispatch({
        type: SUCCESS_RESPONSE,
        payload: {
          message: "You have not imported CSV files, please try again with CSV file.",
          alertType: 'danger',
        },
      });
      document.getElementById("upload").value = '';
    }
  };

  const handleCSVConversion = async (event) => {
    const csvData = event.target.result;
    const lines = csvData.split('\n');
    const headers = lines[0].split(',');

    const jsonData = [];
    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(',');
      if (currentLine.length === headers.length) {
        const row = {};
        for (let j = 0; j < headers.length; j++) {
          const heading = headers[j] && headers[j].match(
            /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map(s => s.toLowerCase())
            .join('_');
          row[heading] = currentLine[j];
        }
        jsonData.push(row);
      }
    }

    const inventoryData = await uploadFittingInventory(jsonData, attribute_id);
    if (inventoryData?.status === true) {
      document.getElementById("upload").value = '';
      updateFittings()
    }
    setCsvData(jsonData);
  };

  return (
    <>
      <div className="animated fadeIn userTableList">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="tabletopsec">
                  {actions}
                  <div className="add-button-div">
                    <a
                      href={downloadData}
                      id="downloadCsv"
                      download
                      hidden
                      className="hide"
                      target="_blank"
                      rel="noopener noreferrer"
                    ></a>
                  </div>


                  <div className="rightpaneltop">
                    <input type="file" id="upload" onChange={uploadCsv} accept=".csv" hidden />
                    <label className='btn-primary' style={{
                      fontWeight: 600,
                      textTransform: "uppercase",
                      padding: "5px 8px",
                      borderRadius: '0.3rem',
                      cursor: 'pointer',
                    }} for="upload"><i class="fa fa-upload" aria-hidden="true"></i>  Import Inventory</label>

                  </div>
                  <div className="rightpaneltop">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={handleCSVDownload}
                    >
                      <i className="fa fa-download"></i> Export CSV
                    </Button>
                  </div>
                  <div className="rightpaneltop">
                    Fittings Per Page
                    <PerPageSelect
                      perPage={sortingParams.limit}
                      onPaginationChange={onPaginationChange}
                    />
                  </div>
                </div>
                <div className="w-100">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body position-relative">
                          <div className="ag-theme-alpine">
                            <AgGridReact
                              defaultColDef={{
                                resizable: true,
                              }}
                              loadingCellRenderer={customLoadingCellRenderer}
                              loadingCellRendererParams={
                                loadingCellRendererParams
                              }
                              domLayout={'autoHeight'}
                              rowModelType={'infinite'}
                              columnDefs={tableFields}
                              pagination={true}
                              sizeColumnsToFit={true}
                              onFilterChanged={filterChanged}
                              onGridReady={onGridReady}
                              paginationPageSize={sortingParams.limit}
                              cacheBlockSize={sortingParams.limit}
                              cacheQuickFilter={true}
                              onFirstDataRendered={onFirstDataRendered}
                              onPaginationChanged={onPaginationChanged}
                              noRowsOverlayComponent={noRowsOverlayComponent}
                              noRowsOverlayComponentParams={noRowsTemplate}
                              ref={gridRef}
                            ></AgGridReact>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div >

      <AddEditFittings
        isOpen={editModal}
        toggle={toggleEditModal}
        setIsNewCreated={setIsNewCreated}
        variationId={variationId}
        isEdit={true}
        attributeId={attribute_id}
        fittingType={adaptor && adaptor.attribute_title}
        update={updateFittings}
      />
    </>
  );
};

FittingList.propTypes = {
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
  getFittingAttributeVariationList: PropTypes.func.isRequired,
  deleteFitting: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  addBreadcrumDetails: PropTypes.func.isRequired,
  fitting_part_detail: PropTypes.object.isRequired,
  uploadFittingInventory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.fittingVariation.loading,
  subLoading: state.fittingVariation.isSubLoading,
  fittingAttributeFittingList: state.fittingVariation.fittingList,
  fitting_part_detail: state.fitting.fitting_part_detail,
  breadcrumDetails: state.breadcrum.breadcrumDetails,
});

export default connect(mapStateToProps, {
  getFittingAttributeVariationList,
  deleteFitting,
  changeStatus,
  addBreadcrumDetails,
  uploadFittingInventory
})(FittingList);
