import axios from 'axios';

import {
  NEWS_LETTER_LOADING,
  NEWS_LETTER_FAIL,
  NEWS_LETTER_DELETED,
  NEWS_LETTER_DELETED_ERROR,
  NEWS_LETTER_UPDATED,
} from 'actions/types';

import { updateFilterData } from 'actions/commonActions';

//get News letter list
export const getNewsLetterList = (newsParams) => async (dispatch) => {
  try {
    dispatch({
      type: NEWS_LETTER_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    newsParams = updateFilterData(newsParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: newsParams,
    };
    // const query = newsParams.query ? newsParams.query : '&query=';

    const res = await axios.get(`/api/admin/news/newsletter`, config);
    dispatch({
      type: NEWS_LETTER_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          newsParams: newsParams,
          newsList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    dispatch({
      type: NEWS_LETTER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete News letter Page
export const deleteNewsLetter = (news_id) => async (dispatch) => {
  try {
    dispatch({
      type: NEWS_LETTER_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(`/api/admin/news/newsletter/${news_id}`, config);

    if (res.data.status === true) {
      dispatch({
        type: NEWS_LETTER_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: NEWS_LETTER_DELETED_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: NEWS_LETTER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

// Export CSV News Letter Subsribers
export const getSubscribersToDownload = async (subsribersParam) => {
  try {
    subsribersParam = updateFilterData(subsribersParam);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: subsribersParam,
    };
    const res = await axios.get(`/api/admin/news/newsletter/csv/download`, config);
    return res.data.response;
  } catch (err) {
    return {};
  }
};
