/* eslint-disable */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Input,
  CardFooter,
} from 'reactstrap';
import Moment from 'react-moment';
import Spinner from 'views/Spinner';
import { USER_ROLE, DEALER_APPROVED_STATUS } from 'constants/index';
import { getDealerById } from 'actions/admin/dealer';

const ViewDealerDetails = ({ getDealerById, match, currentUser, loading }) => {
  let navigate = useNavigate();
  //################## Get Details of Dealer by id ###################
  const { user_id: user_id } = useParams();
  useMemo(() => {
    getDealerById(user_id, navigate);
  }, [getDealerById, user_id]);

  return loading || currentUser === null || currentUser._id !== user_id ? (
    <Spinner />
  ) : (
    <Row>
      <Col md="12">
        <Card>
          <CardBody className="alldetailsuser">
            <div>
              <div>
                <div>
                  <h4>
                    {currentUser.first_name + ' ' + currentUser.last_name}
                  </h4>
                  <h5>{currentUser.email}</h5>
                  <h5>Contact No.: {currentUser?.phone !== undefined ? (currentUser?.ccode !== undefined ? currentUser?.ccode : '') + currentUser?.phone : 'Not Available'}</h5>
                  {/* <h5>{currentUser.dealer_info.contact_no}</h5> <hr/> */}
                  <h5>
                    Role:{' '}
                    {currentUser.role[0].user_type === 1
                      ? USER_ROLE[1]
                      : currentUser.role[0].user_type === 2
                        ? USER_ROLE[2]
                        : USER_ROLE[3]}
                  </h5>
                  <h5>
                    Status:{' '}
                    {currentUser.status === 0
                      ? DEALER_APPROVED_STATUS[0]
                      : currentUser.status === 2
                        ? DEALER_APPROVED_STATUS[2]
                        : DEALER_APPROVED_STATUS[3]}
                  </h5>
                  <h5>
                    Account created on:{' '}
                    <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
                      {currentUser.created_at}
                    </Moment>
                  </h5>
                  <h5>
                    Account details updated on:{' '}
                    <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
                      {currentUser.updated_at}
                    </Moment>
                  </h5>
                  <h5>
                    Last login:{' '}
                    {currentUser.last_login === undefined ? (
                      '-'
                    ) : (
                      <Moment
                        format={process.env.REACT_APP_DISPLAY_DATE_FORMATE}
                        locale="de"
                      >
                        {currentUser.last_login}
                      </Moment>
                    )}
                  </h5>
                </div>
              </div>
              <hr />
              <Row>
                <Col>
                  <p>
                    Featured:{' '}
                    {currentUser?.dealer_info?.is_featured ? 'Yes' : 'No'}{' '}
                  </p>
                  {/* <p>
                    Published:{' '}
                    {currentUser.dealer_info.is_published ? 'Yes' : 'No'}{' '}
                  </p> */}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <div>
                    <p>
                      Business Name: {currentUser.dealer_info.business_name}
                    </p>
                    <p>
                      License No: {currentUser.dealer_info.business_license_no}
                    </p>
                    <p>
                      Business Model: {currentUser.dealer_info.business_model_description}
                    </p>
                    <p>
                      Annual Sales: {currentUser.dealer_info.annual_sales_volume}
                    </p>
                    <p>
                      Primary shaft and clubhead component suppliers/vendors: {currentUser.dealer_info.shaft_clubhead_component_vendors}
                    </p>
                  </div>
                </Col>
                <Col>
                  {currentUser.dealer_address !== undefined ? (
                    <div>
                      <p>Name: {currentUser?.first_name} {currentUser?.last_name}</p>
                      <p>Email: {currentUser.dealer_address.email}</p>
                      <p>Contact No: {currentUser.dealer_address.ccode && currentUser.dealer_address.ccode}{currentUser.dealer_address.contact_no}</p>
                      <p>Address: {currentUser.dealer_address.address}</p>
                      <p>City: {currentUser.dealer_address.city}</p>
                      <p>State: {currentUser.dealer_address.state}</p>
                      <p>Country: {currentUser.dealer_address.country}</p>
                      <p>zipcode: {currentUser.dealer_address.zipcode}</p>
                    </div>
                  ) : (
                    <div>
                      {' '}
                      <p> Address is not provided.... </p>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </CardBody>
          <CardFooter>
            <Link to="/admin/dealers">
              <Button type="reset" size="sm" color="primary">
                <i className="fa fa-arrow-left"></i> Back
              </Button>
            </Link>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
};

ViewDealerDetails.propTypes = {
  getDealerById: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  currentUser: state.dealer.currentUser,
  loading: state.dealer.loading,
});

export default connect(mapStateToProps, {
  getDealerById,
})(ViewDealerDetails);
