/* eslint-disable */
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';

import {
  getNotifiesList,
  deleteNotify,
  changeStatus,
  getNotifyMeToDownload,
} from 'actions/admin/notifyMe';

import * as Constants from 'constants/index';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from 'utils/Loading';

import { Button, Card, CardBody, Col, Row, Input } from 'reactstrap';
import Spinner from 'views/Spinner';
import CustomFilters from './CustomFilters';
import PerPageSelect from '../PerPageSelect';

const renderEmail = (params) => {
  if (params.data === undefined) {
    return <Loading />;
  } else {
    return <div>{params.data?.email}</div>;
  }
};

const actions = (
  // <div className="add-button-div specify-width">
  //   <Link to="/admin/notifies/add" className="addNewElementClass">
  //     <Button color="primary" size="sm">
  //       <i className="fa fa-plus"></i> Add Notifies
  //     </Button>
  //   </Link>
  // </div>
  <>&nbsp;</>
);

const NotifiesList = ({
  getNotifiesList,
  deleteNotify,
  changeStatus,
  notifyList: { data, count },
  loading,
  subLoading,
}) => {
  const history = useNavigate();
  const location = useLocation();
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
          limit: Constants.DEFAULT_PAGE_SIZE,
          page: 1,
          orderBy: 'created_at',
          ascending: 'desc',
          query: '',
          filter: {},
          onLoad: true,
        }
  );

  // to resolve useEffect dependency error

  //################## to get updated list ###################
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [downloadData, setDownloadData] = useState([]);
  const changeNotifyStatus = async (id, value) => {
    await changeStatus(id, value);
    gridApi.purgeInfiniteCache();
  };

  //#########################Colums defination start ###################
  const columns = [
    {
      field: 'email',
      headerName: 'Email',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      colSpan: (params) => {
        if (params.data === undefined) {
          return 7;
        } else {
          return 0;
        }
      },
      cellRenderer: (params) => renderEmail(params),
    },
    {
      field: 'category',
      headerName: 'Category',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'product',
      headerName: 'Product',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'insensitiveSku',
      headerName: 'Model No',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => {
        return <div>{params.data?.sku}</div>;
      },
    },
    {
      field: 'created_at',
      headerName: 'Notify Me Date',
      width: 200,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      cellRenderer: (params) => (
        <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
          {params.data?.created_at}
        </Moment>
      ),
      filter: 'agDateColumnFilter',
      suppressSizeToFit: false,
      // pass in additional parameters to the text filter
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.DATE_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
        buttons: ['reset', 'apply'],
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div>
            <Input
              type="select"
              name="status"
              id={params.data?._id}
              defaultValue={params.data?.status}
              onChange={(e, a) => {
                changeNotifyStatus(params.data?._id, e.target.value);
              }}
            >
              <option value="0">Closed</option>
              <option value="1">Open</option>
            </Input>
          </div>
        );
      },
    },
    {
      field: '_id',
      headerName: 'Actions',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) => (
        <div>
          {/* {params.data.status === 1 &&
            <Link to={`/admin/notifies/${params.data._id}`}>
              <Button type="button" size="sm" color="success" title="Edit">
                <i className="fa fa-pencil"></i>
              </Button>
            </Link>
          } */}
          <Button
            type="button"
            size="sm"
            color="danger"
            title="Delete"
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure, you want to delete ${params.data?.name} notify?`
                )
              ) {
                deleteNotify(params.data?._id, history).then((res) => {
                  if (res) gridApi.purgeInfiniteCache();
                });
              }
            }}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
  ];
  //#########################Colums defination end ###################

  //################## START Set properties(options) of datatables ###################

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };

          const customData = await getNotifiesList(customParams);
          if (customData?.data?.status) {
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });

  const handleCSVDownload = useCallback(async (event) => {
    let data = await getNotifyMeToDownload(sortingParams);
    setDownloadData(data.data);
    document.getElementById('downloadCsv').click();
  });

  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                <div className="add-button-div">
                  <a
                    href={downloadData}
                    id="downloadCsv"
                    download
                    hidden
                    className="hide"
                    target="_blank"
                    rel="noopener noreferrer"
                  ></a>
                </div>
                {actions}
                {
                  <div className="rightpaneltop">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={handleCSVDownload}
                    >
                      <i className="fa fa-download"></i> Export CSV
                    </Button>
                  </div>
                }
                <div className="rightpaneltop">
                  Notifies Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>
              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      defaultColDef={{
                        resizable: true,
                      }}
                      loadingCellRenderer={customLoadingCellRenderer}
                      loadingCellRendererParams={loadingCellRendererParams}
                      domLayout={'autoHeight'}
                      rowModelType={'infinite'}
                      columnDefs={columns}
                      pagination={true}
                      sizeColumnsToFit={true}
                      onFilterChanged={filterChanged}
                      onGridReady={onGridReady}
                      paginationPageSize={sortingParams.limit}
                      cacheBlockSize={sortingParams.limit}
                      cacheQuickFilter={true}
                      onFirstDataRendered={onFirstDataRendered}
                      onPaginationChanged={onPaginationChanged}
                      ref={gridRef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

NotifiesList.propTypes = {
  getNotifiesList: PropTypes.func.isRequired,
  deleteNotify: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  notifyList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  notifyList: state.notify.notifiesList,
  loading: state.notify.loading,
  subLoading: state.notify.isSubLoading,
});

export default connect(mapStateToProps, {
  getNotifiesList,
  deleteNotify,
  changeStatus,
})(NotifiesList);
