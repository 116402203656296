/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getProductDetail, updateCart, addToCart, selectedVariations } from 'actions/admin/order';
import Spinner from 'views/Spinner';
import ButtonSpinner from 'views/ButtonSpinner';
import { Modal, ModalHeader, Button, ModalBody, Alert } from 'reactstrap';
import Variations from './Variations';
import Variation from './Variation';
import { ModalFooter } from 'react-bootstrap';

const SelectVariation = ({
    variationData,
    selectedVariations,
    updateCart,
    addToCart,
    loading,
    openVariationModal,
    setOpenVariationModal,
    isOpen,
    toggle,
    reset,
    currentProduct,
    productLoading,
    getProductDetail,
    orderId,
    userType
}) => {
    //########################## use dispatch ##############################

    useEffect(() => {
        if (isOpen && variationData?.userId) {
            getProductDetail(variationData.slug, variationData.userId);
        }
    }, [variationData.slug, isOpen, variationData.userId]);

    const [isProductLoading, setIsProductLoading] = useState(false);

    const handleValidVariation = () => {
        alert('Please select a valid variation.'); return false;
    };

    const handleVariationSelect = () => {
        variationData.selectedProduct['product_variation'] = variationData.variation;
        let fts = {};
        const fittings = variationData.selectedFitting
        for (const key in fittings) {
            if (Object.hasOwnProperty.call(fittings, key)) {
                fts = { ...fts, [key]: key === "product_customization" ? fittings[key] : fittings[key].value }
            }
        }

        if (variationData?.order_category && variationData.order_category !== "normal_order") {
            const checkWpPrice = variationData.wp_price.find((i) => i.sku === variationData.productVariatonDetails.product_variation_sku)

            if (checkWpPrice === undefined) {
                console.log("Please")
                variationData?.wp_price.push({ sku: variationData.productVariatonDetails.product_variation_sku, price: variationData.wp_product_price })
                selectedVariations(variationData);
            } else {
                const index = variationData?.wp_price.findIndex((i) => i.sku === variationData.productVariatonDetails.product_variation_sku);
                variationData.wp_price[index].price = variationData.wp_product_price;

                selectedVariations(variationData);
            }
        }
        const productDetails = {
            fitting: fts,
            product_id: variationData.selectedProduct._id,
            product_slug: variationData.selectedProduct.slug,
            product_variation: variationData.selectedProduct.product_variation,
            product_variation_sku: variationData.productVariatonDetails.product_variation_sku,
            product_variation_id: variationData.productVariatonDetails.product_variation_id,
            quantity: variationData.quantity,
            customization: variationData.customization,
            isOrderUpdate: variationData.isOrderUpdate,
        };
        let is_delete = false;
        setIsProductLoading(true);
        if (variationData?.isEdit && variationData?.isEdit === true) {
            let cart_user_id = orderId !== undefined ? orderId : variationData.userId;
            let updatesCart = (cart_user_id === variationData.userId) ?
                updateCart(is_delete, productDetails, variationData.userId) :
                updateCart(is_delete, productDetails, cart_user_id, orderId)
            updatesCart.then((res) => {
                if (res?.data && res?.data.status) {
                    variationData = {
                        ...variationData,
                        variation: {},
                        quantity: 1,
                        productVariatonDetails: {},
                        selectedFitting: {},
                        selectedProduct: {},
                        callGetCartApi: true,
                    }
                    delete variationData.stock_quantity;
                    selectedVariations(variationData)
                    setOpenVariationModal(!isOpen);
                }
                setIsProductLoading(false);
            })
        } else {
            let cart_user_id = orderId !== undefined ? orderId : variationData.userId;
            let addCart = (cart_user_id === variationData.userId) ?
                addToCart(is_delete, productDetails, variationData.userId, variationData.order_category) :
                addToCart(is_delete, productDetails, cart_user_id, variationData.order_category, '', '', userType, variationData.userId)
            addCart.then((res) => {
                if (res?.data && res?.data.status == true) {
                    variationData = {
                        ...variationData,
                        productVariatonDetails: {},
                        selectedFitting: {},
                        selectedProduct: {},
                        callGetCartApi: true,
                        quantity: 1,
                        isEdit: true
                    }
                    delete variationData.stock_quantity;
                    if (orderId !== undefined) {
                        variationData.shippingService = true
                    }
                    setOpenVariationModal(!isOpen);
                } else {

                    setOpenVariationModal(true);
                }
                setIsProductLoading(false);
                selectedVariations(variationData)
            })
        }
    };

    //########################## set the initial form data ##############################
    return (
        <div className="animated fadeIn">
            <Modal className="VariationsModal" isOpen={isOpen} toggle={toggle} onClosed={reset} backdrop="static" >
                {productLoading ? (
                    <Spinner />
                ) : (
                    <>
                        <ModalHeader toggle={toggle}>
                            Select Variations {currentProduct.product_title} ({currentProduct.categoryId && currentProduct?.categoryId?.name})
                        </ModalHeader>
                        <ModalBody className="pb-0">
                            {variationData.variationSelectErrorMsg && <Alert style={{ padding: "0.5rem" }} color="danger">
                                {variationData.variationSelectErrorMsg}
                            </Alert>}
                            <Variation
                                currentProduct={currentProduct}
                            />
                        </ModalBody>
                    </>

                )}
                <ModalFooter>
                    <div className="modalFooter">
                        {variationData.variationSelectErrorMsg ?
                            <>

                                <Button
                                    className="mr-05"
                                    size="sm"
                                    color="danger"
                                    onClick={handleValidVariation}
                                >
                                    <i className="fa fa-dot-circle-o"></i>
                                    Submit
                                </Button>
                                <Button type="reset" size="sm" color="danger" onClick={toggle}>
                                    <i className="fa fa-ban"></i> Close
                                </Button>
                            </>

                            :
                            <>
                                {isProductLoading ? <ButtonSpinner /> : <Button
                                    className="mr-05"
                                    size="sm"
                                    color="primary"
                                    onClick={handleVariationSelect}
                                >
                                    <i className="fa fa-dot-circle-o"></i> Submit
                                </Button>}
                                <Button type="reset" size="sm" color="danger" onClick={toggle}>
                                    <i className="fa fa-ban"></i> Close
                                </Button>
                            </>

                        }
                    </div>
                </ModalFooter>

            </Modal>
        </div>
    );
};

SelectVariation.defaultProps = {
    isOpen: false,
};

const mapStateToProps = (state) => ({
    errorList: state.errors,
    productLoading: state.order.productLoading,
    loading: state.order.loading,
    currentProduct: state.order.currentProduct,
    variationData: state.order.variationData,
});

export default connect(mapStateToProps, {
    getProductDetail,
    updateCart,
    addToCart,
    selectedVariations
})(SelectVariation);
