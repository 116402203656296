import axios from 'axios';

import {
  DEALER_ADDRESS_LOADING,
  DEALER_ADDRESS_CREATED,
  DEALER_ADDRESS_CREATE_ERROR,
  DEALER_ADDRESS_FAIL,
  DEALER_ADDRESS_UPDATED,
  DEALER_ADDRESS_UPDATE_ERROR,
  DEALER_ADDRESS_DELETED,
  DEALER_ADDRESS_DELETE_ERROR,
  DEALER_ADDRESS_LIST_UPDATED,
  GET_DEALER_ADDRESS_BY_ID,
  DEALER_ADDRESS_PRIMARY,
  DEALER_ADDRESS_PRIMARY_ERROR,
  DEALER_ADDRESS_CHANGE_STATUS,
  DEALER_ADDRESS_CHANGE_STATUS_ERROR,
  GET_DEALER_DETAILS_BY_ID,
  SET_ERRORS_LIST,
} from 'actions/types';

// Add dealer address
export const add =
  (formData, businessName, dealer_id, history) => async (dispatch) => {
    try {
      dispatch({
        type: DEALER_ADDRESS_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      const config = { headers: { 'Content-Type': 'application/json' } };
      const res = await axios.post(
        `/api/admin/user-address/add/${dealer_id}`,
        formData,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: DEALER_ADDRESS_CREATED,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
        history(`/admin/dealers/${businessName}/dealer-address/${dealer_id}`);
      } else {
        const errors = res.data.errors;
        let errorsList = {};
        if (errors) {
          await errors.forEach((error) => {
            errorsList = { ...errorsList, [error.param]: error.msg };
          });
        }
        dispatch({
          type: DEALER_ADDRESS_CREATE_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
            errorsList,
          },
        });
        dispatch({
          type: SET_ERRORS_LIST,
          payload: { ...errorsList }
        })
      }
    } catch (err) {
      dispatch({
        type: DEALER_ADDRESS_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

// Edit dealer address
export const edit =
  (formData, businessName, dealer_address_id, user_id, history) =>
    async (dispatch) => {
      try {
        dispatch({
          type: DEALER_ADDRESS_LOADING,
          payload: { is_loading: true, is_sub_element: false },
        });

        const config = { headers: { 'Content-Type': 'application/json' } };
        const res = await axios.post(
          `/api/admin/user-address/edit/${dealer_address_id}`,
          formData,
          config
        );
        if (res.data.status === true) {
          dispatch({
            type: DEALER_ADDRESS_UPDATED,
            payload: {
              message: res.data.message,
              alertType: 'success',
              data: res.data.response,
            },
          });
          history(`/admin/dealers/${businessName}/dealer-address/${user_id}`);
        } else {
          const errors = res.data.errors;
          let errorsList = {};
          if (errors) {
            await errors.forEach((error) => {
              errorsList = { ...errorsList, [error.param]: error.msg };
            });
          }
          dispatch({
            type: DEALER_ADDRESS_UPDATE_ERROR,
            payload: {
              message: res.data.message,
              alertType: 'danger',
              errorsList,
            },
          });
          dispatch({
            type: SET_ERRORS_LIST,
            payload: { ...errorsList }
          })
        }
      } catch (err) {
        dispatch({
          type: DEALER_ADDRESS_FAIL,
          payload: {
            message:
              err.response && err.response.statusText
                ? err.response.statusText
                : 'Something went wrong.',
            alertType: 'danger',
            error: err,
          },
        });
      }
    };

// Delete Dealer address  by dealer_address_id
export const deleteDealersAddress = (dealer_address_id) => async (dispatch) => {
  try {
    dispatch({
      type: DEALER_ADDRESS_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(
      `/api/admin/user-address/${dealer_address_id}`,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: DEALER_ADDRESS_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: DEALER_ADDRESS_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: DEALER_ADDRESS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get dealers address list by dealer_id
export const getDealersAddressList =
  (dealersAddressParams, dealer_id) => async (dispatch) => {
    try {
      dispatch({
        type: DEALER_ADDRESS_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      const config = {
        headers: { 'Content-Type': 'application/json' },
        params: dealersAddressParams,
      };

      const res = await axios.get(
        `/api/admin/user-address/${dealer_id}/`,
        config
      );
      dispatch({
        type: DEALER_ADDRESS_LIST_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: {
            dealersAddressParams: dealersAddressParams,
            dealerAddressList: res.data.response[0],
          },
        },
      });
      return res;
    } catch (err) {
      dispatch({
        type: DEALER_ADDRESS_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

// Get Dealer address by dealer_address_id
export const getDealersAddressById =
  (dealer_address_id) => async (dispatch) => {
    try {
      dispatch({
        type: DEALER_ADDRESS_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.get(
        `/api/admin/user-address/getById/${dealer_address_id}`,
        config
      );
      await dispatch({
        type: GET_DEALER_ADDRESS_BY_ID,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } catch (err) {
      dispatch({
        type: DEALER_ADDRESS_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

//mark address as primary by dealer_address_id
export const markAsPrimary =
  (dealer_id, dealer_address_id) => async (dispatch) => {
    try {
      dispatch({
        type: DEALER_ADDRESS_LOADING,
        payload: { is_loading: true, is_sub_element: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/user-address/markPrimary/${dealer_id}/${dealer_address_id}`,
        {},
        config
      );
      if (res.data.status === true) {
        await dispatch({
          type: DEALER_ADDRESS_PRIMARY,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
      } else {
        //add a key is_failed=true else is_failed=false
        dispatch({
          type: DEALER_ADDRESS_PRIMARY_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
          },
        });
      }
    } catch (err) {
      dispatch({
        type: DEALER_ADDRESS_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

//change status
export const addressApproveDisapprove =
  (dealer_address_id, status) => async (dispatch) => {
    try {
      dispatch({
        type: DEALER_ADDRESS_LOADING,
        payload: { is_loading: true, is_sub_element: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/user-address/change-status/${dealer_address_id}`,
        { status },
        config
      );
      if (res.data.status === true) {
        await dispatch({
          type: DEALER_ADDRESS_CHANGE_STATUS,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
      } else {
        //add a key is_failed=true else is_failed=false
        dispatch({
          type: DEALER_ADDRESS_CHANGE_STATUS_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
            data: dealer_address_id,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: DEALER_ADDRESS_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

// get dealer by id
export const getDealerDetailsById = (dealer_id) => async (dispatch) => {
  try {
    dispatch({
      type: DEALER_ADDRESS_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/user-address/user-details/${dealer_id}`,
      config
    );
    await dispatch({
      type: GET_DEALER_DETAILS_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: DEALER_ADDRESS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
