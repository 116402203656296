import axios from 'axios';

import {
  SET_ALERT,
  ORDER_LOADING,
  ORDER_FAIL,
  ORDER_LIST_UPDATED,
  ORDER_CHANGE_STATUS,
  ORDER_CHANGE_STATUS_ERROR,
  GET_ORDER_BY_ID,
  ORDER_CREATED,
  ORDER_CREATE_ERROR,
  ORDER_MODAL_LOADING,
  ORDER_USER_LIST_LOADING,
  ORDER_USER_LIST_UPDATED,
  ORDER_ADDRESS_LOADER,
  ORDER_ADDRESS_LIST,
  ORDER_ADDRESS_ADD_LOADER,
  ORDER_ADDRESS_ADDED,
  ORDER_ADDRESS_ADDED_ERROR,
  ADMIN_ORDER_COUPON_LOADER,
  ADMIN_ORDER_COUPON_FAIL,
  ADMIN_ORDER_APPLY_COUPON_RESULT,
  ADMIN_ORDER_APPLY_COUPON_RESULT_ERROR,
  ORDER_SHIPPING_RATES_LIST,
  ORDER_SHIPPING_RATES_LIST_ERROR,
  ORDER_SHIPPING_LOADER,
  ORDER_SHIPPING_FAIL,
  ORDER_CREATE_LOADER,
  ORDER_CREATE_RESULT,
  ORDER_CREATE_RESULT_ERROR,
  ORDER_CREATE_FAIL,
  ORDER_LABEL_FAIL,
  ORDER_LABEL_CREATE_ERROR,
  ORDER_LABEL_CREATED,
  ORDER_LABEL_LOADING,
  SHIPPING_ORDER_LOADING,
  GET_SHIPPING_ORDER_BY_ID,
  SHIPPING_ORDER_FAIL,
  LABEL_PRINT_ORDER_SHIPPING_RATES_LIST,
  LABEL_PRINT_ORDER_SHIPPING_RATES_LIST_ERROR,
  LABEL_PRINT_ORDER_SHIPPING_LOADER,
  LABEL_PRINT_ORDER_SHIPPING_FAIL,
  ORDER_ADDRESS_LIST_ERROR,
  ORDER_USER_LIST_UPDATE_ERROR,
  ADMIN_ORDER_PRODUCT_DETAIL_LOADING,
  ADMIN_ORDER_PRODUCT_DETAIL,
  ADMIN_ORDER_PRODUCT_DETAIL_ERROR,
  ADMIN_ORDER_PRODUCT_DETAIL_FAIL,
  ADMIN_PRODUCT_FAIL,
  ADMIN_SET_PRODUCT_ATTRIBUTE_LOADER,
  ADMIN_ORDER_PRODUCT_LOADING,
  ADMIN_ORDER_PRODUCT_LIST_UPDATED,
  ADMIN_ORDER_PRODUCT_FAIL,
  ADMIN_ORDER_REMOVE_COUPON_RESULT,
  ADMIN_ORDER_REMOVE_COUPON_RESULT_ERROR,
  ADMIN_ORDER_CART_ERROR,
  ADMIN_ORDER_CART_SUCCESS,
  INTERNAL_NOTES_CREATE_LOADER,
  INTERNAL_NOTES_CREATE_RESULT,
  INTERNAL_NOTES_CREATE_ERROR,
  INTERNAL_NOTES_CREATE_FAIL,
  INTERNAL_NOTES_LOADING,
  INTERNAL_NOTES_DELETED,
  INTERNAL_NOTES_DELETE_ERROR,
  INTERNAL_NOTES_FAIL,
  BUILD_SHOP_NOTES_FAIL,
  ORDER_INVOICE_FAIL,
  ORDER_INVOICE_CREATE_ERROR,
  ORDER_INVOICE_CREATED,
  ORDER_INVOICE_LOADING,
  SEND_EMAIL_LOADING,
  SEND_EMAIL_CREATED,
  SEND_EMAIL_CREATE_ERROR,
  SEND_EMAIL_FAIL,
  CART_LOADING,
  GET_ORDER_CART,
  SET_VARIATIONS,
  ADMIN_PRODUCT_PRODUCT_LIST,
  SET_ERRORS_LIST,
  RETURN_INITIATE_LOADING,
  GET_RETURN_INITIATE,
  RETURN_INITIATE_FAIL,
  LOADING_SHIPPING_BOX,
  GET_SHIPPING_BOX,
  SHIPPING_BOX_ERROR,
  CREATE_RETURN_INITIATE,
  CHANGE_RETURN_INITIATE_STATUS,
  ORDER_SHIPPING_BOXES_LOADING,
  ORDER_SHIPPING_BOXES,
  ORDER_SHIPPING_BOXES_FAILED,
  EDITED_SHIPPING_BOX_RATES_LOADING,
  EDITED_SHIPPING_BOX_RATES,
  EDITED_SHIPPING_BOX_RATES_FAILED,
  EDITED_SHIPPING_BOX_SUBMIT_LOADING,
  EDITED_SHIPPING_BOX_SUCCESS,
  EDITED_SHIPPING_BOX_SUBMIT_FAILED,
  SHIPPING_ADDITIONALS_LOADING,
  SHIPPING_ADDITIONALS,
  SHIPPING_ADDITIONALS_FAILED,
  VOID_SHIPPING_LABEL_LOADING,
  VOID_SHIPPING_LABEL_FAILED,
  VOID_SHIPPING_LABEL_SUCCESS,
  SHIPPING_CANCELATIONS_LOADING,
  SHIPPING_CANCELATIONS,
  SHIPPING_CANCELATIONS_FAILED,
  ADMIN_ORDER_RE_APPLY_COUPON_RESULT,
  Additional_Product_LOADING,
  Additional_Product_LIST_UPDATED,
  Additional_Product_FAIL,
  GET_BUILD_SUPPLY_SERVICE_LIST,
  ADDITIONAL_PRODUCT_CART_SUCCESS,
  ADDITIONAL_PRODUCT_CART_ERROR,
  ORDER_PAYMENTS_LOADING,
  ORDER_PAYMENTS,
  ORDER_PAYMENTS_FAIL,
  MERGE_SHIPPING_LOADING,
  MERGE_SHIPPING_SUCCESS,
  MERGE_SHIPPING_ERROR,
  MERGE_SHIPPING_FAIL,
  DELETE_MERGE_SHIPPING_SUCCESS,
  DELETE_MERGE_SHIPPING_ERROR,
  DELETE_MERGE_SHIPPING_FAIL,
  SUCCESS_RESPONSE,
  ERROR_RESPONSE,
  FAILED_RESPONSE,
  LOADING_START,
  LOADING_END
} from 'actions/types';

import { updateFilterData } from 'actions/commonActions';

export const selectedVariations =
  (variation, variationParams) => async (dispatch) => {
    //variation = { ...variation, variationParams }
    dispatch({
      type: SET_VARIATIONS,
      payload: variation,
    });
  };

//get orders list
export const getOrdersList = (orderParams) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });

    orderParams = updateFilterData(orderParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: orderParams,
    };
    const res = await axios.get(`/api/admin/order/`, config);
    dispatch({
      type: ORDER_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          orderParams: orderParams,
          orderList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    //console.log(err, 'errr daata');
    dispatch({
      type: ORDER_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return err;
  }
};

export const getOrdersListStaff =
  (orderParams, userRole) => async (dispatch) => {
    try {
      dispatch({
        type: ORDER_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: orderParams,
      };
      const res = await axios.get(`/api/admin/order/staff/${userRole}`, config);
      dispatch({
        type: ORDER_LIST_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: {
            orderParams: orderParams,
            orderList: res.data.response[0],
          },
        },
      });
      return res;
    } catch (err) {
      dispatch({
        type: ORDER_FAIL,
        payload: {
          message: err.response?.data?.message ?? 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
      return err;
    }
  };

//apply coupon
export const applyCoupon =
  (couponCode, productTotalAmount, shippingPrice, userId, orderId = null, isReapplyCoupon, shippingServiceCategory,) =>
    async (dispatch) => {
      try {
        dispatch({
          type: ADMIN_ORDER_COUPON_LOADER,
          payload: true,
        });
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };

        const body = JSON.stringify({
          couponCode,
          productTotalAmount,
          shippingPrice,
          userId,
          orderId,
          shippingServiceCategory
        });

        const res = await axios.post(
          `/api/admin/order/coupon/apply-coupon`,
          body,
          config
        );

        if (res.data.status) {
          if (isReapplyCoupon !== true) {
            await dispatch({
              type: ADMIN_ORDER_APPLY_COUPON_RESULT,
              // payload: res.data.response,
              payload: {
                message: res.data.message,
                alertType: 'success',
                data: res.data.response,
              },
            });
          } else {
            await dispatch({
              type: ADMIN_ORDER_RE_APPLY_COUPON_RESULT,
              payload: false
            });
          }
          return { status: true, data: res.data.response };
        } else {
          await dispatch({
            type: ADMIN_ORDER_APPLY_COUPON_RESULT_ERROR,
            payload: {
              message: res.data.message,
              alertType: 'danger',
            },
          });
          return { status: false };
        }
      } catch (err) {
        dispatch({
          type: ADMIN_ORDER_COUPON_FAIL,
          payload: {
            message: err.response?.data?.message ?? 'Something went wrong.',
            alertType: 'danger',
            error: err,
          },
        });
        return { status: false };
      }
    };

//change status
export const changeStatus = (order_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/order/change-status/${order_id}`,
      { status },
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: ORDER_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: ORDER_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: order_id,
        },
      });

      return true;
    }
  } catch (err) {
    dispatch({
      type: ORDER_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

export const changeOrderStatus =
  (formData, navigate = null) =>
    async (dispatch) => {
      //console.log(formData); return false;
      try {
        dispatch({
          type: ORDER_LOADING,
          payload: { is_loading: true, is_sub_element: true },
        });
        const status = formData.new_order_status;
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        //let order_id = formData.order_id ? formData.order_id : formData;
        const res = await axios.post(
          `/api/admin/order/change-order-status/${formData.order_id}`,
          { status, formData },
          config
        );
        if (res.data.status === true) {
          dispatch({
            type: SET_ALERT,
            payload: {
              message: res.data.message,
              alertType: 'success',
              data: res.data.response,
            },
          });
          return res;
        } else {
          dispatch({
            type: ORDER_FAIL,
            payload: {
              message: res.data?.errors[0]?.msg ?? 'Something went wrong.',
              alertType: 'danger',
            },
          });
          return false;
        }
      } catch (err) {
        console.log(err, 'eeeeee');
        dispatch({
          type: ORDER_FAIL,
          payload: {
            message: err.response?.data?.message ?? 'Something went wrong.',
            alertType: 'danger',
            error: err,
          },
        });
        return false;
      }
    };

// get order by id
export const getOrderById = (_id) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/order/${_id}`, config);

    await dispatch({
      type: GET_ORDER_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: ORDER_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

export const getCartDetails = (orderId, userId) => async (dispatch) => {
  try {
    dispatch({
      type: CART_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let user_id = '';
    if (userId !== '' && userId !== undefined) {
      user_id = `?userId=${userId}`;
    }
    const res = await axios.get(
      `/api/admin/order/get-cart-details/${orderId}${user_id}`,
      config
    );
    if (!res.data.status) {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: ADMIN_ORDER_CART_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
    } else {
      await dispatch({
        type: GET_ORDER_CART,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    }
    return res;
  } catch (err) {
    dispatch({
      type: ORDER_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

export const updateCart =
  (is_delete = false, productDetails, userId, orderId) =>
    async (dispatch) => {
      //console.log(productDetails,'productDetails edit data');return false;
      try {
        //const body = { is_delete, productDetails, userId };
        const body = { is_delete, productDetails, userId, orderId };
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const res = await axios.post(
          `/api/admin/order/update-cart`,
          body,
          config
        );
        if (res.data.status == true) {
          await dispatch({
            type: ADMIN_ORDER_CART_SUCCESS,
            // payload: res.data.response,
            payload: {
              message: res.data.message,
              alertType: 'success',
              data: [],
            },
          });
        } else {
          dispatch({
            type: ADMIN_ORDER_CART_ERROR,
            payload: {
              message: res.data.message,
              alertType: 'danger',
            },
          });
        }
        // await dispatch({
        //     type: SET_ALERT,
        //     payload: {
        //         message: res.data.message,
        //         alertType: 'success',
        //         //data: res.data.response,
        //     },
        // });
        return res;
      } catch (err) {
        dispatch({
          type: ORDER_FAIL,
          payload: {
            message:
              err.response && err.response.statusText
                ? err.response.statusText
                : 'Something went wrong.',
            alertType: 'danger',
            error: err,
          },
        });
      }
    };

export const addToCart =
  (
    is_delete = false,
    productDetails,
    userId,
    order_category,
    cartId,
    cart_product_id,
    userType,
    user_id,
  ) =>
    async (dispatch) => {
      //console.log(productDetails,'productDetails data');return false;
      try {
        const body = {
          is_delete,
          productDetails,
          userId,
          order_category,
          cartId,
          cart_product_id,
          userType,
          user_id,
        };
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const res = await axios.post(
          `/api/admin/order/add-to-cart`,
          body,
          config
        );
        //console.log(res,'res test data here');return false;
        if (res.data.status == true) {
          await dispatch({
            type: ADMIN_ORDER_CART_SUCCESS,
            // payload: res.data.response,
            payload: {
              message: res.data.message,
              alertType: 'success',
              data: [],
            },
          });
        } else {
          dispatch({
            type: ADMIN_ORDER_CART_ERROR,
            payload: {
              message: res.data.message,
              alertType: 'danger',
            },
          });
        }
        return res;
      } catch (err) {
        let messageText = "";
        if (err?.response?.data?.status === false) {
          messageText = err.response.data.message;
        } else {
          messageText = err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.';
        }
        dispatch({
          type: ORDER_FAIL,
          payload: {
            message: messageText,
            alertType: 'danger',
            error: err,
          },
        });
      }
    };

export const addToCartOrderData =
  (
    is_delete = false,
    build_shop_supply_products,
    serviceDetail,
    productDetails,
    userId,
    cartId,
    cart_product_id,
    userType,
    orderUserId
  ) =>
    async (dispatch) => {
      //console.log(userId,'productDetails data');return false;
      try {
        const body = {
          is_delete,
          build_shop_supply_products,
          serviceDetail,
          productDetails,
          userId,
          cartId,
          cart_product_id,
          userType,
          orderUserId,
        };
        dispatch({
          type: CART_LOADING,
          payload: { is_loading: true, },
        });
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        //console.log(productDetails,'hello data 123');
        const res = await axios.post(
          `/api/admin/order/add-to-cart-order-data`,
          body,
          config
        );
        //console.log(res,'res test data here');return false;
        if (res.data.status == true) {
          await dispatch({
            type: ADMIN_ORDER_CART_SUCCESS,
            // payload: res.data.response,
            payload: {
              message: res.data.message,
              alertType: 'success',
              data: [],
            },
          });
        } else {
          dispatch({
            type: ADMIN_ORDER_CART_ERROR,
            payload: {
              message: res.data.message,
              alertType: 'danger',
            },
          });
        }
        return res;
      } catch (err) {
        console.log(err);
        dispatch({
          type: ORDER_FAIL,
          payload: {
            message:
              err.response && err.response.statusText
                ? err.response.statusText
                : 'Something went wrong.',
            alertType: 'danger',
            error: err,
          },
        });
      }
    };

//create order
export const createOrder = () => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_MODAL_LOADING,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(`/api/admin/order/create`, config);
    if (res.data.status === true) {
      await dispatch({
        type: ORDER_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: ORDER_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });

      return false;
    }
  } catch (err) {
    dispatch({
      type: ORDER_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get users list
export const getUsersList = (user_type) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_USER_LIST_LOADING,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/order/users-list/${user_type}`,
      config
    );
    if (res.data.status) {
      await dispatch({
        type: ORDER_USER_LIST_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      await dispatch({
        type: ORDER_USER_LIST_UPDATE_ERROR,
        payload: [],
      });
    }
  } catch (err) {
    dispatch({
      type: ORDER_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Remove Cart entries
export const clearCart = (userId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/order/clearCart/${userId}`, config);
    return res.data.response;
  } catch (err) {
    console.log(err);
    return false;
  }
};

//get users addresss by userId
export const getUserAddressList = (userId, order_type = "normal") => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_ADDRESS_LOADER,
      payload: true,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let apiUrl = `/api/admin/order/get-address/${userId}`
    if (order_type !== "normal") {
      apiUrl = `/api/admin/order/get-address/${userId}?order_type=${order_type}`
    }
    const res = await axios.get(apiUrl, config);
    if (res.data.status) {
      await dispatch({
        type: ORDER_ADDRESS_LIST,
        payload: res.data.response,
      });
    } else {
      await dispatch({
        type: ORDER_ADDRESS_LIST_ERROR,
        payload: [],
      });
    }
  } catch (err) {
    dispatch({
      type: ORDER_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

//add new address by userId
export const addAddress = (formData, userId) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_ADDRESS_ADD_LOADER,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/order/add-address/${userId}`,
      formData,
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: ORDER_ADDRESS_ADDED,
        payload: res.data.response,
      });
      return { status: true, data: res.data.response };
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: ORDER_ADDRESS_ADDED_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: ORDER_ADDRESS_ADD_LOADER,
        payload: { is_loading: false, is_sub_element: false },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });

      return { status: false };
    }
  } catch (err) {
    dispatch({
      type: ORDER_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

// calculate the service shipping rate
export const calculateServiceShippingRates = (toAddress, currentCart, userId, shippingServiceCategory, serviceShipping, finalCustomerCarrierDetails) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_SHIPPING_LOADER,
      payload: true,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = {
      toAddress: toAddress,
      currentCart: currentCart,
      userId: userId,
      shippingServiceCategory: shippingServiceCategory,
      serviceShipping,
      finalCustomerCarrierDetails
    };
    const res = await axios.post(
      `/api/admin/order/calculate-service-shipping-rates`,
      body,
      config
    );

    if (res.data.status) {
      await dispatch({
        type: ORDER_SHIPPING_RATES_LIST,
        payload: res.data.response.finalRateList,
      });

      return {
        status: true,
        data: res.data.response.packedBoxes,
        rate: res.data.response.finalRateList,
        order_id: res.data.response.order_id,
        message: res.data.response.messages,
        taxData: res.data.response.taxData,
      };
    } else {
      await dispatch({
        type: ORDER_SHIPPING_RATES_LIST_ERROR,
        payload: res.data.message,
      });
      return { status: false, message: res.data.message };
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: ORDER_SHIPPING_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return { status: false, message: 'Something went wrong.' };
  }
}

//calculate shipping rates
export const calculateShippingRates =
  (toAddress, currentCart, userId, shippingServiceCategory, customerCarrierDetails) => async (dispatch) => {
    try {
      dispatch({
        type: ORDER_SHIPPING_LOADER,
        payload: true,
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const body = {
        toAddress: toAddress,
        currentCart: currentCart,
        userId: userId,
        shippingServiceCategory: shippingServiceCategory,
        customerCarrierDetails: customerCarrierDetails
      };
      const res = await axios.post(
        `/api/admin/order/calculate-shipping-rates`,
        body,
        config
      );

      if (res.data.status) {
        await dispatch({
          type: ORDER_SHIPPING_RATES_LIST,
          payload: res.data.response.finalRateList,
        });

        return {
          status: true,
          data: res.data.response.packedBoxes,
          rate: res.data.response.finalRateList,
          order_id: res.data.response.order_id,
          message: res.data.response.messages,
          taxData: res.data.response.taxData,
        };
      } else {
        await dispatch({
          type: ORDER_SHIPPING_RATES_LIST_ERROR,
          payload: res.data.message,
        });
        return { status: false, message: res.data.message };
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: ORDER_SHIPPING_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
      return { status: false, message: 'Something went wrong.' };
    }
  };

//add to service order with status pending
export const addToServiceOrder = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_CREATE_LOADER,
      payload: true,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/order/add-service-order`,
      formData,
      config
    );
    if (res.data.status) {
      console.log(res);
      dispatch({
        type: ORDER_CREATE_RESULT,
        payload: {
          message: res.data.message,
          alertType: 'success',
        },
      });
      return {
        status: true,
        data: res.data.response,
        message: res.data.message,
      };
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors.length) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      await dispatch({
        type: ORDER_CREATE_RESULT_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      return { status: false };
    }
  } catch (err) {
    //console.log(err);
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return { status: false };
  }
};


//add to order with status pending
export const addToOrder = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_CREATE_LOADER,
      payload: true,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/order/add-order`,
      formData,
      config
    );
    if (res.data.status) {
      console.log(res);
      dispatch({
        type: ORDER_CREATE_RESULT,
        payload: {
          message: res.data.message,
          alertType: 'success',
        },
      });
      return {
        status: true,
        data: res.data.response,
        message: res.data.message,
      };
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors.length) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      await dispatch({
        type: ORDER_CREATE_RESULT_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      return { status: false };
    }
  } catch (err) {
    //console.log(err);
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return { status: false };
  }
};

//add to order with status pending
export const addToProducts =
  (product, otherDetails, id) => async (dispatch) => {
    try {
      const body = { product, otherDetails };
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/order/add-to-products/${id}`,
        body,
        config
      );
      if (res.data.status) {
        await dispatch({
          type: ORDER_CREATE_RESULT,
          payload: res.data.response.order_id,
        });
        return { status: true, data: res.data.response };
      } else {
        const errors = res.data.errors;
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }
        await dispatch({
          type: ORDER_CREATE_RESULT_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
            errorsList,
          },
        });
        return { status: false };
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: ORDER_CREATE_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
      return { status: false };
    }
  };

//add to order with status pending
export const removeFromOrder = (product, userId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.post(
      `/api/admin/order/remove-products`,
      product,
      config
    );
    if (res.data.status) {
      await dispatch({
        type: ORDER_CREATE_RESULT,
        payload: res.data.response.order_id,
      });
      return { status: true, data: res.data.response };
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      await dispatch({
        type: ORDER_CREATE_RESULT_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      return { status: false };
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return { status: false };
  }
};

//create order
export const printLabel = (order_id, shipping_box_id, formData, toAddress, isEdited, deliveryAddressEdited) => async (dispatch) => {
  //console.log(formData, 'request data'); return false;
  try {
    dispatch({
      type: ORDER_LABEL_LOADING,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = { order_id: order_id, shipping_box_id: shipping_box_id, ...formData, toAddress, isEdited, deliveryAddressEdited };
    const res = await axios.post(`/api/admin/order/print-label`, body, config);
    if (res.data.status === true) {
      await dispatch({
        type: ORDER_LABEL_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          // data: res.data.response,
        },
      });
      return res.data.response;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: ORDER_LABEL_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });

      return false;
    }
  } catch (err) {
    dispatch({
      type: ORDER_LABEL_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

// get order for shipping label  by id
export const getShippingOrderById = (order_id, shipping_box_id) => async (dispatch) => {
  try {
    dispatch({
      type: SHIPPING_ORDER_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/order/for-shipping-label/${order_id}/${shipping_box_id}`,
      config
    );
    await dispatch({
      type: GET_SHIPPING_ORDER_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: SHIPPING_ORDER_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//calculate shipping rates at the time of label print
export const calculateShippingRatesForLabelPrint =
  (order_id, shipping_box_id, toAddress, currentShippoDetails) => async (dispatch) => {
    try {
      dispatch({
        type: LABEL_PRINT_ORDER_SHIPPING_LOADER,
        payload: true,
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const body = {
        order_id: order_id,
        shipping_box_id: shipping_box_id,
        toAddress: toAddress,
        currentShippoDetails: currentShippoDetails,
      };
      const res = await axios.post(
        `/api/admin/order/calculate-shipping-rates-for-label-print`,
        body,
        config
      );

      if (res.data.status) {
        await dispatch({
          type: LABEL_PRINT_ORDER_SHIPPING_RATES_LIST,
          payload: res.data.response.finalRateList,
        });

        return {
          status: true,
          rate: res.data.response.finalRateList,
          order_id: res.data.response.order_id,
          message: res.data.response.messages,
        };
      } else {
        await dispatch({
          type: LABEL_PRINT_ORDER_SHIPPING_RATES_LIST_ERROR,
          payload: res.data.message,
        });
        return { status: false, message: res.data.message };
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: LABEL_PRINT_ORDER_SHIPPING_FAIL,
        payload: {
          message: err.response?.data?.message ?? 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
      return { status: false, message: 'Something went wrong.' };
    }
  };

export const getOrdersToDownload = async (orderParams) => {
  try {
    orderParams = updateFilterData(orderParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: orderParams,
    };
    const res = await axios.get(`/api/admin/order/csv/download`, config);
    return res.data.response;
  } catch (err) {
    return {};
  }
};

export const downloadShippingAdditionals = async (sortingParams, timeZone) => {
  try {
    sortingParams = updateFilterData(sortingParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: { ...sortingParams, timeZone },
    };
    const res = await axios.get(`/api/admin/order/shipping-additionals/csv/download`, config);
    return res.data.response;
  } catch (err) {
    return {};
  }
};

export const downloadShippingCancelations = async (sortingParams, timeZone) => {
  try {
    sortingParams = updateFilterData(sortingParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: { ...sortingParams, timeZone },
    };
    const res = await axios.get(`/api/admin/order/shipping-cancelations/csv/download`, config);
    return res.data.response;
  } catch (err) {
    return {};
  }
};
//get product detail by slug
export const getProductDetail = (slug, userId) => async (dispatch) => {
  dispatch({
    type: ADMIN_ORDER_PRODUCT_DETAIL_LOADING,
    payload: true,
  });
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.get(
      `/api/admin/order/user/${userId}/product/${slug}`,
      config
    );
    if (res.data.status) {
      await dispatch({
        type: ADMIN_ORDER_PRODUCT_DETAIL,
        payload: res.data.response,
      });
    } else {
      await dispatch({
        type: ADMIN_ORDER_PRODUCT_DETAIL_ERROR,
      });
    }
  } catch (err) {
    dispatch({
      type: ADMIN_ORDER_PRODUCT_DETAIL_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get attributes list
export const getProductAttributes = () => async (dispatch) => {
  dispatch({
    type: ADMIN_SET_PRODUCT_ATTRIBUTE_LOADER,
    payload: true,
  });
  try {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.get('/api/front/product/attributes/list', config); //update api before use, as this is for front panel

    if (res.data.status === true) {
      dispatch({
        type: LOADING_END,
        payload: { is_loading: false },
      });
      return res.data.response;
    }

  } catch (err) {
    dispatch({
      type: ADMIN_PRODUCT_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//Get Product List
export const getProductsList = (productParams) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_ORDER_PRODUCT_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/order/product/list`, config);

    dispatch({
      type: ADMIN_ORDER_PRODUCT_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: ADMIN_ORDER_PRODUCT_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//remove coupon
export const removeCoupon = (userId, orderId) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_ORDER_COUPON_LOADER,
      payload: true,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ userId, orderId });
    const res = await axios.post(
      `/api/admin/order/coupon/remove-coupon`,
      body,
      config
    );

    if (res.data.status) {
      await dispatch({
        type: ADMIN_ORDER_REMOVE_COUPON_RESULT,
        payload: {
          message: res.data.message,
          alertType: 'success',
        },
      });
      return { status: true, data: res.data.response };
    } else {
      await dispatch({
        type: ADMIN_ORDER_REMOVE_COUPON_RESULT_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return { status: false };
    }
  } catch (err) {
    dispatch({
      type: ADMIN_ORDER_COUPON_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return { status: false };
  }
};

//add to order with status pending
export const addInternalNotes =
  (user_id, order_id, internal_notes) => async (dispatch) => {
    try {
      const body = { user_id, order_id, internal_notes };
      //console.log(body,'body data');return false;
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/order/add-to-internal-notes/`,
        body,
        config
      );
      //console.log(res,'result data');return false;
      if (res.data.status) {
        await dispatch({
          type: INTERNAL_NOTES_CREATE_RESULT,
          payload: res.data.response.order_id,
        });
        return { status: true, data: res.data.response };
      } else {
        const errors = res.data.errors;
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }
        await dispatch({
          type: INTERNAL_NOTES_CREATE_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
            errorsList,
          },
        });
        return { status: false };
      }
    } catch (err) {
      //console.log(err);
      dispatch({
        type: INTERNAL_NOTES_CREATE_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
      return { status: false };
    }
  };

export const getAllInternalNotesByOrderId = (order_id) => async (dispatch) => {
  try {
    const body = { order_id };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/order/internal-notes-by-order-id/${order_id}`,
      config
    );

    if (res.data.response) {
      await dispatch({
        type: INTERNAL_NOTES_CREATE_RESULT,
        payload: res.data.response.order_id,
      });
      return res.data.response;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      await dispatch({
        type: INTERNAL_NOTES_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      return { status: false };
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: INTERNAL_NOTES_CREATE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return { status: false };
  }
};

export const deleteInternalNotes = (notes_id) => async (dispatch) => {
  try {
    dispatch({
      type: INTERNAL_NOTES_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(
      `/api/admin/order/delete-notes-by-id/${notes_id}`,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: INTERNAL_NOTES_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: INTERNAL_NOTES_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: INTERNAL_NOTES_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

export const addBuildShopNotes =
  (order_id, product_id, build_shop_notes) => async (dispatch) => {
    try {
      const body = { order_id, product_id, build_shop_notes };
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/order/build-shop/add-notes`,
        body,
        config
      );

      return res;
    } catch (err) {
      dispatch({
        type: BUILD_SHOP_NOTES_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

export const deleteBuildShopNotes =
  (order_id, product_id) => async (dispatch) => {
    try {
      const body = { order_id, product_id };
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/order/build-shop/delete-notes`,
        body,
        config
      );

      return res;
    } catch (err) {
      dispatch({
        type: BUILD_SHOP_NOTES_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

export const getOrderLogsByOrderId = (order_id) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/order/order-details/logs/${order_id}`,
      config
    );
    //console.log(res, 'res data'); return false;
    if (res.data.status === true) {
      await dispatch({
        type: GET_ORDER_BY_ID,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return res.data.response;
    } else {
      await dispatch({
        type: ORDER_FAIL,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    console.log(err, 'err dtaa');
    dispatch({
      type: ORDER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};


export const downloadInvoice = (order_id) => async (dispatch) => {
  //console.log(order_id, 'data found for download invoice'); return false;
  try {
    dispatch({
      type: ORDER_INVOICE_LOADING,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = { order_id: order_id };
    const res = await axios.post(
      `/api/admin/order/download-invoice`,
      body,
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: ORDER_INVOICE_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response.data,
        },
      });
      return res.data.response;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: ORDER_INVOICE_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });

      return false;
    }
  } catch (err) {
    dispatch({
      type: ORDER_INVOICE_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

export const downloadFreightInvoice = (order_id, shipping_box_id) => async (dispatch) => {
  //console.log(order_id, 'data found for download invoice'); return false;
  try {
    dispatch({
      type: ORDER_INVOICE_LOADING,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = { order_id: order_id, shipping_box_id: shipping_box_id };
    const res = await axios.post(
      `/api/admin/order/download-freight-invoice`,
      body,
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: ORDER_INVOICE_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response.data,
        },
      });
      return res.data.response;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: ORDER_INVOICE_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });

      return false;
    }
  } catch (err) {
    dispatch({
      type: ORDER_INVOICE_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

export const sendEmailToServer = (order_id) => async (dispatch) => {
  //console.log(order_id, 'data found for download invoice'); return false;
  try {
    dispatch({
      type: SEND_EMAIL_LOADING,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = { order_id: order_id };
    const res = await axios.post(
      `/api/admin/order/send-email`,
      body,
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: SEND_EMAIL_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response.data,
        },
      });
      return res.data.response;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: SEND_EMAIL_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });

      return false;
    }
  } catch (err) {
    dispatch({
      type: SEND_EMAIL_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

// get shipping rates
export const getTaxRates = (address) => async (dispatch) => {
  //console.log(address, 'adsadsadadsadada'); return false;
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/order/tax-rates/${address}`,
      config
    );
    //console.log(res, 'res data'); return false;
    return res.data.response;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getShippingBoxDetails = (order_id) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_SHIPPING_BOX,
      payload: { is_loading: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/order/get-shipping-box/${order_id}/`,
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: GET_SHIPPING_BOX,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return res.data.response;
    } else {
      await dispatch({
        type: SHIPPING_BOX_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    console.log(err, 'err dtaa');
    dispatch({
      type: SHIPPING_BOX_ERROR,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
}

export const getReturnInitiates = (order_id, initiatesParams) => async (dispatch) => {
  try {
    dispatch({
      type: RETURN_INITIATE_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    //initiatesParams = updateFilterData(initiatesParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: initiatesParams,
    };
    let url = (order_id !== undefined) ? `/api/admin/order/return-initiate/${order_id}/` : `/api/admin/order/return-initiate/returns/`;
    const res = await axios.get(
      url,
      config
    );
    //console.log(res, 'res data'); return false;
    if (res.data.status === true) {
      await dispatch({
        type: GET_RETURN_INITIATE,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return res;
    } else {
      await dispatch({
        type: RETURN_INITIATE_FAIL,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    console.log(err, 'err dtaa');
    dispatch({
      type: RETURN_INITIATE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

export const changeReturnStatus = (formData, navigate = null) => async (dispatch) => {

  try {
    dispatch({
      type: ORDER_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/order/change-return-status`,
      formData,
      config
    );
    if (res.data.status) {
      dispatch({
        type: CHANGE_RETURN_INITIATE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
        },
      });
      return {
        status: true,
        data: res.data.response,
        message: res.data.message,
      };
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors.length) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      await dispatch({
        type: RETURN_INITIATE_FAIL,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      return { status: false };
    }
  } catch (err) {
    dispatch({
      type: RETURN_INITIATE_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return { status: false };
  }
};

export const createReturnInitiator = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: RETURN_INITIATE_LOADING,
      payload: { is_loading: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/order/create-return-initiate`,
      formData,
      config
    );
    if (res.data.status) {
      dispatch({
        type: CREATE_RETURN_INITIATE,
        payload: {
          message: res.data.message,
          alertType: 'success',
        },
      });
      return {
        status: true,
        data: res.data.response,
        message: res.data.message,
      };
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors.length) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      await dispatch({
        type: RETURN_INITIATE_FAIL,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      return { status: false };
    }
  } catch (err) {
    dispatch({
      type: RETURN_INITIATE_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return { status: false };
  }

}

export const getOrderShippingBoxDetails =
  (order_id) => async (dispatch) => {
    try {
      dispatch({
        type: ORDER_SHIPPING_BOXES_LOADING,
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.get(`/api/admin/order/shipping-boxes/${order_id}`, config);
      dispatch({
        type: ORDER_SHIPPING_BOXES,
        payload: res.data.response
      });
      return res;
    } catch (err) {
      dispatch({
        type: ORDER_SHIPPING_BOXES_FAILED,
        payload: {
          message: err.response?.data?.message ?? 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
      return err;
    }
  };

export const getRatesForEditedShippingBoxes = (order_id, boxes) => async (dispatch) => {
  try {
    dispatch({
      type: EDITED_SHIPPING_BOX_RATES_LOADING
    });
    const config = {
      headers: {
        "Content-Type": 'application/json'
      },
    };
    const body = { editedShippingBoxes: boxes }
    const res = await axios.post(`/api/admin/order/edited-shipping-boxes-rates/${order_id}`, body, config);
    if (res.status) {
      dispatch({
        type: EDITED_SHIPPING_BOX_RATES,
        payload: res.data?.response
      });
      return { status: true, response: res.data?.response }
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: EDITED_SHIPPING_BOX_RATES_FAILED,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return { status: false, response: {} };
    }
  } catch (err) {
    dispatch({
      type: EDITED_SHIPPING_BOX_RATES_FAILED,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return err;
  }
}

export const saveEditedShippingBoxes = (order_id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDITED_SHIPPING_BOX_SUBMIT_LOADING
    });
    const config = {
      headers: {
        "Content-Type": 'application/json'
      },
    };
    const res = await axios.post(`/api/admin/order/save-edited-shipping-boxes/${order_id}`, formData, config);
    if (res.status) {
      dispatch({
        type: EDITED_SHIPPING_BOX_SUCCESS,
        payload: res.data?.response
      });
      return { status: true, response: res.data?.response }
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: EDITED_SHIPPING_BOX_SUBMIT_FAILED,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return { status: false, response: {} };
    }
  } catch (err) {
    dispatch({
      type: EDITED_SHIPPING_BOX_SUBMIT_FAILED,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return err;
  }
}

export const getShippingAdditionals = (sortingParams) => async (dispatch) => {
  try {
    dispatch({
      type: SHIPPING_ADDITIONALS_LOADING
    });
    sortingParams = updateFilterData(sortingParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: sortingParams,
    };
    const res = await axios.get(`/api/admin/order/get-shipping-additionals`, config);
    dispatch({
      type: SHIPPING_ADDITIONALS,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          sortingParams: sortingParams,
          shippingAdditionals: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    dispatch({
      type: SHIPPING_ADDITIONALS_FAILED,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return err;
  }
}

export const getShippingCancelations = (sortingParams) => async (dispatch) => {
  try {
    dispatch({
      type: SHIPPING_CANCELATIONS_LOADING
    });
    sortingParams = updateFilterData(sortingParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: sortingParams,
    };
    const res = await axios.get(`/api/admin/order/get-shipping-cancelations`, config);
    dispatch({
      type: SHIPPING_CANCELATIONS,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          sortingParams: sortingParams,
          shippingCancelations: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    dispatch({
      type: SHIPPING_CANCELATIONS_FAILED,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return err;
  }
}

export const voidReturnInitiateShippingLabel = (box_id) => async (dispatch) => {
  try {
    dispatch({
      type: VOID_SHIPPING_LABEL_LOADING
    });
    const config = {
      headers: {
        "Content-Type": 'application/json'
      },
    };
    const res = await axios.put(`/api/admin/order/void-return-shipping-label/${box_id}`, config);
    if (res.status) {
      dispatch({
        type: VOID_SHIPPING_LABEL_SUCCESS,
        payload: { message: res.data?.message, alertType: "success" }
      });
      return { status: true, response: res.data?.response }
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: VOID_SHIPPING_LABEL_FAILED,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return { status: false, response: {} };
    }
  } catch (err) {
    dispatch({
      type: VOID_SHIPPING_LABEL_FAILED,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return err;
  }
}


export const voidShippingLabel = (box_id) => async (dispatch) => {
  try {
    dispatch({
      type: VOID_SHIPPING_LABEL_LOADING
    });
    const config = {
      headers: {
        "Content-Type": 'application/json'
      },
    };
    const res = await axios.put(`/api/admin/order/void-shipping-label/${box_id}`, config);
    if (res.status) {
      dispatch({
        type: VOID_SHIPPING_LABEL_SUCCESS,
        payload: { message: res.data?.message, alertType: "success" }
      });
      return { status: true, response: res.data?.response }
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: VOID_SHIPPING_LABEL_FAILED,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return { status: false, response: {} };
    }
  } catch (err) {
    dispatch({
      type: VOID_SHIPPING_LABEL_FAILED,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return err;
  }
}

export const getOrderPaymentsByOrderId = (order_id) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_PAYMENTS_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/order/order-details/payments/${order_id}`,
      config
    );
    //console.log(res, 'res data'); return false;
    if (res.data.status === true) {
      await dispatch({
        type: ORDER_PAYMENTS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return res.data.response;
    } else {
      await dispatch({
        type: ORDER_PAYMENTS_FAIL,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    console.log(err, 'err dtaa');
    dispatch({
      type: ORDER_PAYMENTS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

export const getBuildSupplyServiceList = () => async (dispatch) => {
  try {
    dispatch({
      type: Additional_Product_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/order/get-build-supply-serives`, config);

    dispatch({
      type: GET_BUILD_SUPPLY_SERVICE_LIST,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {

    dispatch({
      type: Additional_Product_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

export const deleteService = (deleteId, orderId, type) => async (dispatch) => {
  try {
    dispatch({
      type: Additional_Product_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(`/api/admin/order/delete-additional-production-to-cart/${deleteId}`, { data: { orderId, type } }, config);

    if (res.data.status === true) {
      dispatch({
        type: ADDITIONAL_PRODUCT_CART_SUCCESS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return res.data;
    } else {
      dispatch({
        //ADMIN_ORDER_CART_ERROR
        type: ADDITIONAL_PRODUCT_CART_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
    }
  } catch (error) {

  }
}
// editBuildShopSupplyAddToCart
export const editBuildShopSupplyAddToCart = (body) => async (dispatch) => {
  try {
    dispatch({
      type: Additional_Product_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(`/api/admin/order/edit-build-shop-supply-add-to-cart`, body, config);
    if (res.data.status === true) {
      dispatch({
        type: ADDITIONAL_PRODUCT_CART_SUCCESS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return res.data;
    } else {
      dispatch({
        //ADMIN_ORDER_CART_ERROR
        type: ADDITIONAL_PRODUCT_CART_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
    }
  } catch (err) {
    dispatch({
      type: Additional_Product_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
// buildShopSupplyAddToCart
export const buildShopSupplyAddToCart = (body) => async (dispatch) => {
  try {
    dispatch({
      type: Additional_Product_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(`/api/admin/order/build-shop-supply-add-to-cart`, body, config);

    if (res.data.status === true) {
      dispatch({
        type: ADDITIONAL_PRODUCT_CART_SUCCESS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return res.data;
    } else {
      dispatch({
        //ADMIN_ORDER_CART_ERROR
        type: ADDITIONAL_PRODUCT_CART_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
    }
  } catch (err) {
    dispatch({
      type: Additional_Product_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

export const getMergeShippingOrder = (user_id) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_START,
      payload: { is_loading: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/order/get-merge-shipping-order/${user_id}`, config);

    if (res.data.status === true) {
      dispatch({
        type: LOADING_END,
        payload: { is_loading: false },
      });
      return res.data.response;
    } else {
      dispatch({
        //ADMIN_ORDER_CART_ERROR
        type: ADDITIONAL_PRODUCT_CART_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
    }
  } catch (err) {
    dispatch({
      type: Additional_Product_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

export const mergeShippingAddtionalOrder = (order_id, mergeData, tempCart) => async (dispatch) => {
  try {
    dispatch({
      type: MERGE_SHIPPING_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const data = {
      order_id,
      additionalProduct: mergeData,
      products: tempCart
    }
    const res = await axios.post(`/api/admin/order/merge-shipping-order`, data, config);

    if (res.data.status === true) {
      dispatch({
        type: MERGE_SHIPPING_SUCCESS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return res.data;
    } else {
      dispatch({
        //ADMIN_ORDER_CART_ERROR
        type: MERGE_SHIPPING_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
    }
  } catch (err) {
    dispatch({
      type: MERGE_SHIPPING_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

export const deleteMergeShippingOrder = (mergedSku, shippingMergeId, order_id, mergeOrderId) => async (dispatch) => {
  try {
    dispatch({
      type: MERGE_SHIPPING_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        mergedSku: mergedSku,
        shippingMergeId,
        order_id,
        mergeOrderId
      }
    };
    const res = await axios.delete(`/api/admin/order/delete-merge-shipping-order`, config);
    if (res.data.status === true) {
      dispatch({
        type: DELETE_MERGE_SHIPPING_SUCCESS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: [],
        },
      });
      return res.data;
    } else {
      dispatch({
        //ADMIN_ORDER_CART_ERROR
        type: DELETE_MERGE_SHIPPING_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
    }
    return res.data;
  } catch (err) {
    dispatch({
      type: DELETE_MERGE_SHIPPING_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
}

export const changePaymentStatus = (data) => async (dispatch) => {
  try {
    /*  dispatch({
       type: MERGE_SHIPPING_LOADING,
       payload: { is_loading: true, is_sub_element: false },
     }); */
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    //const data = data
    const res = await axios.post(`/api/admin/order/change-payment-status`, data, config);

    if (res.data.status === true) {
      dispatch({
        type: SUCCESS_RESPONSE,
        payload: {
          message: res.data.message,
          alertType: 'success',
        },
      });
      return res.data;
    } else {
      dispatch({
        //ADMIN_ORDER_CART_ERROR
        type: ERROR_RESPONSE,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return { status: false }
    }
  } catch (err) {
    dispatch({
      type: FAILED_RESPONSE,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return { status: false }
  }
};