import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  FormText
} from "reactstrap";

import { DISPLAYED_USER_APPROVED_STATUS } from "constants/index";

import { userChangeStatusInBulk } from "actions/admin/user";

const BulkActions = props => {
  //################ Dropdown ############################
  const [openDropdown, setOpenDropdown] = useState(false);
  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };
  const [isDisabled, setIsDisabled] = useState(false);
  //################ End Dropdown ############################

  //################ Status change ############################
  const [statusModal, setOpenStatusModal] = useState(false);
  const toggleStatusModal = () => {
    setOpenStatusModal(!statusModal);
  };

  let initialStatusFormData = {
    bulkIds: props.selectedRows,
    status: ""
  };
  const [statusFormData, setStatusFormData] = useState(initialStatusFormData);
  const resetStatusModal = () => {
    setStatusFormData(initialStatusFormData);
  };
  const [statusFormDataErrors, setStatusFormDataErrors] = useState(false);
  const handelOnChange = e => {
    setStatusFormData({
      ...statusFormData,
      status: e.target.value
    });
  };
  const handelOnClick = e => {
    if (statusFormData.status) {
      if (
        window.confirm(
          `Are you sure, you want to ${DISPLAYED_USER_APPROVED_STATUS[statusFormData.status]
          } user(s)?`
        )
      ) {
        //2 for approve, 3 for disapprove
        props.userChangeStatusInBulk(statusFormData);
        setOpenStatusModal(false);
      }
    } else {
      setStatusFormDataErrors(true);
    }
  };
  //################ End Status change ############################

  useEffect(() => {
    //disable bulk actions button if no row selected
    if (props.selectedRows.length > 0) setIsDisabled(false);
    else setIsDisabled(true);

    //set selected id's array whan row selection changed
    setStatusFormData({
      // ...initialStatusFormData,
      status: "",
      bulkIds: props.selectedRows
    });
  }, [setIsDisabled, props.selectedRows]);

  return (
    <>
      <Dropdown
        isOpen={openDropdown}
        toggle={toggleDropdown}
        size="sm"
        key="bulkDropdown"
      >
        <DropdownToggle caret>Bulk Actions</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={toggleStatusModal} disabled={isDisabled}>
            Change Status
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <Modal
        isOpen={statusModal}
        toggle={toggleStatusModal}
        onClosed={resetStatusModal}
      >
        <ModalHeader toggle={toggleStatusModal}>Change Status</ModalHeader>
        <Form className="form-horizontal">
          <ModalBody>
            <FormGroup check inline>
              <Input
                type="select"
                name="status"
                id="status"
                onChange={e => handelOnChange(e)}
              >
                <option value={""}> {"Select Status"} </option>
                {Object.keys(DISPLAYED_USER_APPROVED_STATUS).map(key => {
                  return key !== 0 ? (
                    <option key={key} value={key}>
                      {DISPLAYED_USER_APPROVED_STATUS[key]}
                    </option>
                  ) : (
                    ""
                  );
                })}
              </Input>
            </FormGroup>

            {statusFormDataErrors ? (
              <FormText className="invalid-feedback-text">
                Please select status
              </FormText>
            ) : (
              ""
            )}
          </ModalBody>
          <ModalFooter>
            <Button size="sm" color="primary" onClick={e => handelOnClick(e)}>
              <i className="fa fa-dot-circle-o"></i> Submit
            </Button>
            <Button size="sm" color="danger" onClick={toggleStatusModal}>
              Close
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

BulkActions.propTypes = {
  userChangeStatusInBulk: PropTypes.func.isRequired
};

const mapStateToProps = state => ({});
export default connect(mapStateToProps, {
  userChangeStatusInBulk
})(BulkActions);
