/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';

import {
  getOrderById,
  getAllInternalNotesByOrderId,

} from 'actions/admin/order';
import {
  Card,
  CardBody,

} from 'reactstrap';

const ViewInternalNotes = ({
  getAllInternalNotesByOrderId,
  order_id
}) => {
  //##################### billing address list for logged in user #########################
  //console.log(selectedShippingMode.carrier,'selectedShippingMode data here');

  const [internalNotesData, setInternalNotesData] = useState();
  useEffect(async () => {
    let notesData = await getAllInternalNotesByOrderId(order_id);
    console.log(notesData, 'internalNotesData data');
    setInternalNotesData(notesData);

  }, []);



  return (
    <>

      {
        internalNotesData &&
          internalNotesData.length > 0 ?
          internalNotesData.map((item) => (
            <>
              <div className='flex-outer'>
                <div>
                  <p>{item.first_name}{item.last_name && item.last_name != null ? ' ' + item.last_name : ''} : {item.notes}</p>
                  <p>
                    (<span className='time_formatting'>

                      <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE ?? 'MM/DD/YYYY'} locale="de">
                        {item.created_at}
                      </Moment>
                    </span>
                    <span className='time_formatting'>
                      <Moment format="kk:mm" locale="de">
                        {item.created_at}
                      </Moment>
                    </span>
                    )

                  </p>
                </div>
                {/* <div>
                  <Button className="mt-2" onClick={async () => { await handleDeleteInternalNotes(item._id, order_id) }} type="button" size="sm" color="danger">
                    <i className="fa fa-trash"></i>
                  </Button>
                </div> */}
              </div>
              <hr />
            </>


          ))
          :
          <>
            <div><span>Internal Notes are not available</span></div>
          </>
      }
    </>
  );
};

ViewInternalNotes.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps,
  {
    getOrderById,
    getAllInternalNotesByOrderId,
  }
)(ViewInternalNotes);
