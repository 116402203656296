import axios from "axios";

import {
  PROFILE_LOADING,
  USER_PROFILE_UPDATED,
  USER_PROFILE_UPDATED_ERROR,
  USER_PROFILE_FAIL,
  USER_PASSWORD_UPDATED,
  USER_PASSWORD_UPDATE_ERROR,
  USER_PROFILE,
  USER_PROFILE_ERROR,
  SET_ERRORS_LIST,
} from "actions/types";

// Edit Profile
export const edit = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post("/api/admin/profile", formData, config);
    if (res.data.status === true) {
      dispatch({
        type: USER_PROFILE_UPDATED,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: USER_PROFILE_UPDATED_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          errorsList,
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
    return false;
  }
};

// Edit Password
export const editPassword = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      "/api/admin/profile/password",
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: USER_PASSWORD_UPDATED,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });

      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: USER_PASSWORD_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
    return false;
  }
};

// Get Product by id
export const getLoggedInUser = () => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const res = await axios.get("/api/auth");
    if (res.data.status === true) {
      dispatch({
        type: USER_PROFILE,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: res.data.response,
        },
      });
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch({
          type: USER_PROFILE_ERROR,
          payload: {
            message: res.data.message,
            alertType: "danger",
          },
        });
      }
    }
  } catch (err) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
  }
};
