import React, {
    useEffect,
    useState,
    useRef,
    useMemo,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate, useMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { getOrderShippingBoxDetails, voidShippingLabel, downloadFreightInvoice } from 'actions/admin/order';
import { convertCarrierTypes, convertServiceTypes } from 'actions/commonActions';
import Spinner from 'views/Spinner';
import {
    Button,
    Table,
    Card,
    CardBody,
    Col,
    Row,
    CardFooter,
    CardHeader,
    Label
} from 'reactstrap';
import { SHIPPING_BOX_STATUS } from 'constants/index';
import order from 'reducers/admin/order';
import MarkAsFleetShipping from './Models/MarkAsFleetShipping';


const OrderShippingBoxes = ({ getOrderShippingBoxDetails, auth: { user }, loading, OrderShippingBoxDetails, voidShippingLabel, downloadFreightInvoice }) => {
    const fleetReceipt = "https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/fleet_receipt"
    const match = useMatch('/admin/orders/shipping-boxes/:order_id');
    const [shippingBoxEditable, setShippingBoxEditable] = useState(true);
    const [orderDetails, setOrderDetails] = useState({ order_id: "", order_status: "", order_type: "", _id: "", shippingServiceCategory: "" });
    const [voidShippingLoading, setVoidShippingLoading] = useState(false);
    const [isOpenFleetModal, setIsOpenFleetModal] = useState(false);
    const [fleetModalRes, setFleetModalRes] = useState(false);
    const [downloadInvoiceURL, setDownloadInvoiceURL] = useState('');
    const [boxData, setBoxId] = useState({});


    useEffect(() => {
        getOrderShippingBoxDetails(match.params.order_id)
    }, [getOrderShippingBoxDetails, match.params.order_id])

    useEffect(() => {
        if (fleetModalRes === true) {
            getOrderShippingBoxDetails(match.params.order_id)
            setFleetModalRes(false)
        }
    }, [fleetModalRes])



    useEffect(() => {
        const shippingBoxes = OrderShippingBoxDetails?.orderShippingBoxes;
        setOrderDetails(OrderShippingBoxDetails?.orderDetails)
        if (!shippingBoxes) {
            return;
        }
        shippingBoxes.map((box) => {
            if (box.shipping_box_status >= 2) {
                setShippingBoxEditable(false)
            }
        })
    }, [OrderShippingBoxDetails, getOrderShippingBoxDetails])

    const onDownloadFreightInvoice = async (shippingBoxId) => {
        const orderId = match.params.order_id;
        let invoiceRespose = await downloadFreightInvoice(orderId, shippingBoxId);
        if (invoiceRespose) {
            setDownloadInvoiceURL(invoiceRespose.data);
            document.getElementById('downloadFreightInvoice').click();
        }
    }

    const onClickVoidShipLabel = async (box_id) => {
        const confirmed = window.confirm('Are you sure you want to void the ship label?');
        if (confirmed) {
            setVoidShippingLoading(true);
            const response = await voidShippingLabel(box_id);
            if (response.status) {
                setVoidShippingLoading(false);
                getOrderShippingBoxDetails(match.params.order_id)
            }
            setVoidShippingLoading(false);
        } else {
            setVoidShippingLoading(false);
            return;
        }
        setVoidShippingLoading(false);
    }

    const openFleetModel = useCallback((data) => {
        setBoxId(data);
        setIsOpenFleetModal(!isOpenFleetModal)
    });

    return loading || voidShippingLoading ? <Spinner /> : (
        <>
            <div className="viewOrderDetailstab">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <Link to={"/admin/orders"}>
                                    <Button
                                        type="reset"
                                        size="sm"
                                        color="danger"
                                    >
                                        <i className="fa fa-arrow-left"></i> Back
                                    </Button>
                                </Link>
                            </CardHeader>
                        </Card>
                    </Col>
                    <Col md="12">
                        <Card>
                            <CardBody className="label-tag-header text-center">
                                <Label>
                                    {OrderShippingBoxDetails?.orderDetails ? <div>Order ID - ({OrderShippingBoxDetails?.orderDetails?.order_id})</div> : null}
                                </Label>

                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="12">
                        <Card>
                            <CardHeader style={{ display: "flex", justifyContent: "space-between" }}>
                                Order Shipping Box Details
                                {orderDetails?.order_status === 1 || orderDetails?.order_status === 2 || orderDetails?.order_status === 4 || orderDetails?.order_status === 7 ? <div style={{ color: "red" }}>Please mark order first in processing state to generate shipping labels</div> : null}
                                {orderDetails?.shippingServiceCategory !== 4 && orderDetails?.order_category !== "service_order" ?
                                    <Link to={`/admin/orders/edit-shipping-boxes/${match.params.order_id}`}>
                                        <Button type="button" color="primary">
                                            Edit Shipping Boxes
                                        </Button>
                                    </Link> : null}
                                <div className="add-button-div">
                                    <a
                                        href={downloadInvoiceURL}
                                        id="downloadFreightInvoice"
                                        hidden
                                        className="hide"
                                        target="_blank"
                                        download
                                    ></a>
                                </div>

                            </CardHeader>
                            <CardBody>

                                <Table responsive className='shippingboxestable' border="1">
                                    <thead>
                                        <tr>
                                            <th rowSpan="2">Box Name</th>
                                            <th colSpan="4" className="shippingproductdetails">Product Details</th>
                                            <th rowSpan="2">Shipping Address</th>
                                            <th rowSpan="2">Carrier</th>
                                            <th rowSpan="2">Service Type</th>
                                            {orderDetails?.shippingServiceCategory === 4 && <th rowSpan="2">Freight Remark</th>}
                                            <th rowSpan="2">Status</th>
                                            <th rowSpan="2">Actions</th>
                                        </tr>
                                        <tr>
                                            <th>Order-ID</th>
                                            <th>Product</th>
                                            <th>SKU</th>
                                            <th>Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            OrderShippingBoxDetails?.orderShippingBoxes?.map((item, index) => {
                                                return (
                                                    item?.items?.length > 0 ? item?.items?.map((prod, ind) => {
                                                        if (ind === 0) {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td rowSpan={item.items?.length > 0 ? `${item.items?.length}` : "1"}>
                                                                            <div><span style={{ fontWeight: "bold" }}>Name -</span> {item.box?.name}{" "} (KXS-{item?.boxId})</div>
                                                                            <div><span style={{ fontWeight: "bold" }}>Length -</span> {item.box?.length} <span>(In)</span></div>
                                                                            <div><span style={{ fontWeight: "bold" }}>Width -</span> {item.box?.width} <span>(In)</span></div>
                                                                            <div><span style={{ fontWeight: "bold" }}>Height -</span> {item.box?.height} <span>(In)</span></div>
                                                                            <div><span style={{ fontWeight: "bold" }}>Weight -</span> {item.box?.weight} <span>(Lb)</span></div>
                                                                        </td>
                                                                        <td><div>{prod?.order_id ?? orderDetails?.order_id}</div></td>
                                                                        <td>
                                                                            <div style={{ fontWeight: "bold" }}>{`${prod.product_title} (${prod.category_name})`}</div>
                                                                            {prod.fitting_options && prod.fitting_options.length > 0
                                                                                ? prod.fitting_options.map((value) => {
                                                                                    if (value.attribute_name == 'Playing Length') {
                                                                                        return (
                                                                                            <>
                                                                                                <div>
                                                                                                    <label className="label-tag">
                                                                                                        {' '}
                                                                                                        Playing Length :
                                                                                                    </label>{' '}
                                                                                                    {(value?.attribute_value == 0) ? "Uncut" : value?.attribute_value}
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    }

                                                                                    if (value.attribute_name == 'Extra Wrap') {
                                                                                        return (
                                                                                            <>
                                                                                                <div>
                                                                                                    <label className="label-tag">
                                                                                                        {' '}
                                                                                                        Extra Wraps :
                                                                                                    </label>
                                                                                                    {value.attribute_value &&
                                                                                                        value.attribute_value == 1
                                                                                                        ? 'None'
                                                                                                        : value.attribute_value == 2
                                                                                                            ? '1'
                                                                                                            : value.attribute_value == 3
                                                                                                                ? '2'
                                                                                                                : 'None'}
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                    if (value.attribute_name == 'Grip Logo') {
                                                                                        return (
                                                                                            <>
                                                                                                <div>
                                                                                                    <label className="label-tag">
                                                                                                        {' '}
                                                                                                        Grip Logo :
                                                                                                    </label>
                                                                                                    {value.attribute_value &&
                                                                                                        value.attribute_value}
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    }

                                                                                    if (value.attribute_name == 'Adaptors') {
                                                                                        return (
                                                                                            <>
                                                                                                <div>
                                                                                                    <label className="label-tag">
                                                                                                        {' '}
                                                                                                        Adaptor :
                                                                                                    </label>{' '}
                                                                                                    {value.option_name} (${value.price})
                                                                                                </div>
                                                                                                <div>
                                                                                                    <label className="label-tag">
                                                                                                        {' '}
                                                                                                        Adaptor Setting :{' '}
                                                                                                    </label>
                                                                                                    {value.sub_option_name}
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                    if (value.attribute_name == 'Grips') {
                                                                                        return (
                                                                                            <>
                                                                                                <div>
                                                                                                    <label className="label-tag">
                                                                                                        {' '}
                                                                                                        Grips Brand :{' '}
                                                                                                    </label>
                                                                                                    {value.option_name}
                                                                                                </div>
                                                                                                <div>
                                                                                                    <label className="label-tag">
                                                                                                        {' '}
                                                                                                        Grip Model :{' '}
                                                                                                    </label>
                                                                                                    {value.sub_option_name} (${value.price})
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                })
                                                                                : null}
                                                                            {/* {item.items?.length > 0 && ind + 1 !== item.items?.length ? <hr /> : null} */}
                                                                        </td>

                                                                        <td><div>{prod.product_variation_sku}</div></td>
                                                                        <td>
                                                                            <div>{prod.quantity}</div></td>
                                                                        <td rowSpan={item.items?.length > 0 ? `${item.items?.length}` : "1"}><div><p>{`${item?.shipping_address?.address_line_1} ${item?.shipping_address?.address_line_2},`}</p><p>{`${item?.shipping_address?.city} ${item?.shipping_address?.state}`}</p><p>{`${item?.shipping_address?.zipcode} ${item?.shipping_address?.country}`}</p></div></td>
                                                                        <td rowSpan={item.items?.length > 0 ? `${item.items?.length}` : "1"}>
                                                                            <div>{convertCarrierTypes(item?.shipping_mode?.carrier)}</div>
                                                                        </td>
                                                                        <td rowSpan={item?.items?.length > 0 ? `${item?.items?.length}` : "1"}>
                                                                            <div>{convertServiceTypes(item?.shipping_mode?.service)} - $({item?.shipping_mode?.totalCharges})</div>
                                                                        </td>
                                                                        {(orderDetails?.shippingServiceCategory === 4) && <td rowSpan={item.items?.length > 0 ? `${item.items?.length}` : "1"}>
                                                                            <div>{item?.remarks ? item?.remarks : "---"}</div>
                                                                        </td>}
                                                                        <td rowSpan={item.items?.length > 0 ? `${item.items?.length}` : "1"}>
                                                                            <div>{SHIPPING_BOX_STATUS[item?.shipping_box_status - 1]}</div>
                                                                        </td>
                                                                        <td rowSpan={item.items?.length > 0 ? `${item.items?.length}` : "1"}>
                                                                            {item?.shipping_box_status === 1 ?
                                                                                ![1, 2, 4, 7].includes(orderDetails?.order_status) ?
                                                                                    <>
                                                                                        {(orderDetails?.shippingServiceCategory !== 4) && <Link to={`/admin/orders/print-label/${match.params.order_id}/${item._id}`}>
                                                                                            <Button
                                                                                                type="button"
                                                                                                className="btn-success btn-sm mr-05"
                                                                                                title="Print Label"
                                                                                                disabled={orderDetails?.order_status !== 1 && orderDetails?.order_status !== 2 && orderDetails?.order_status !== 4 && orderDetails?.order_status !== 7 ? false : true}
                                                                                            >
                                                                                                <i className="fa fa-print" id="printLabel"></i>

                                                                                            </Button>
                                                                                        </Link>}
                                                                                        {(orderDetails?.shippingServiceCategory === 4) && <Button
                                                                                            type="button"
                                                                                            className="btn-success btn-sm mr-05"
                                                                                            title="Freight Shipping"
                                                                                            onClick={() => openFleetModel(item)}
                                                                                            disabled={orderDetails?.order_status !== 1 && orderDetails?.order_status !== 2 && orderDetails?.order_status !== 4 && orderDetails?.order_status !== 7 ? false : true}
                                                                                        >
                                                                                            <i className="fa fa-print" id="printLabel"></i>

                                                                                        </Button>}
                                                                                    </>
                                                                                    : <Button
                                                                                        type="button"
                                                                                        className="btn-success btn-sm mr-05"
                                                                                        title="Print Label"
                                                                                        disabled={orderDetails?.order_status !== 1 && orderDetails?.order_status !== 2 && orderDetails?.order_status !== 4 && orderDetails?.order_status !== 7 ? false : true}
                                                                                    >
                                                                                        <i className="fa fa-print" id="printLabel"></i>

                                                                                    </Button> : null}
                                                                            {item?.shipping_box_status === 2 ?
                                                                                <>
                                                                                    {(orderDetails?.shippingServiceCategory !== 4) && <>
                                                                                        {item?.easy_post_shipments && <a target="_blank" href={`${item?.easy_post_shipments?.length !== undefined ? item?.easy_post_shipments[0]?.tracking_url : item?.easy_post_shipments?.tracking_url}`}>
                                                                                            <Button
                                                                                                type="button"
                                                                                                className="btn-success btn-sm mr-05 mb-05"
                                                                                                title="Track Order"
                                                                                            >
                                                                                                <i className="fa fa-truck" id="printLabel"></i>

                                                                                            </Button>
                                                                                        </a>}
                                                                                        <a target="_blank" href={item?.label_url}>
                                                                                            <Button
                                                                                                type="button"
                                                                                                className="btn-success btn-sm mr-05 mb-05"
                                                                                                title="Download Invoice & Packing Slip"
                                                                                            >
                                                                                                <i className="fa fa-clipboard" id="printLabel"></i>

                                                                                            </Button>
                                                                                        </a>
                                                                                        <a target="_blank" href={item?.easy_post_shipments?.length !== undefined ? item?.easy_post_shipments[0]?.label_url : item?.easy_post_shipments?.label_url}>
                                                                                            <Button
                                                                                                type="button"
                                                                                                className="btn-success btn-sm mr-05 mb-05"
                                                                                                title="Download Ship Label"
                                                                                            >
                                                                                                <i className="fa fa-download" id="printLabel"></i>

                                                                                            </Button>
                                                                                        </a>
                                                                                        <Button
                                                                                            type="button"
                                                                                            className="btn-success btn-sm mr-05 mb-05"
                                                                                            title="Void ship Label"
                                                                                            onClick={() => onClickVoidShipLabel(item._id)}
                                                                                        >
                                                                                            <i className="fa fa-ban" id="printLabel"></i>

                                                                                        </Button>
                                                                                    </>}

                                                                                    {(orderDetails?.shippingServiceCategory === 4) && <>
                                                                                        {!item?.fleet_receipt && !item?.shipping_box_status == 2 ? <Button
                                                                                            type="button"
                                                                                            className="btn-success btn-sm mr-05"
                                                                                            title="Freight Shipping"
                                                                                            onClick={() => openFleetModel(item)}
                                                                                            disabled={orderDetails?.order_status !== 1 && orderDetails?.order_status !== 2 && orderDetails?.order_status !== 4 && orderDetails?.order_status !== 7 ? false : true}
                                                                                        >
                                                                                            <i className="fa fa-print" id="printLabel"></i>

                                                                                        </Button> : null
                                                                                        }
                                                                                        {item?.fleet_receipt && <a target="_blank" href={`${fleetReceipt}/${item?.fleet_receipt}`}>
                                                                                            <Button
                                                                                                type="button"
                                                                                                className="btn-success btn-sm mr-05 mb-05"
                                                                                                title="Download Freight Receipt"
                                                                                            >
                                                                                                <i className="fa fa-download" id="fleetReceipt"></i>

                                                                                            </Button>
                                                                                        </a>}

                                                                                        {<Button
                                                                                            type="button"
                                                                                            className="btn-success btn-sm mr-05 mb-05"
                                                                                            title="Download Invoice"
                                                                                            onClick={() => onDownloadFreightInvoice(item?._id)}
                                                                                        >
                                                                                            <i className="fa fa-download" id="printLabel"></i>

                                                                                        </Button>}

                                                                                    </>}
                                                                                </> : null}
                                                                            {item?.shipping_box_status === 3 ? <>
                                                                                {(orderDetails?.shippingServiceCategory !== 4) && <>
                                                                                    {item?.easy_post_shipments && <a target="_blank" href={`${item?.easy_post_shipments?.length !== undefined ? item?.easy_post_shipments[0]?.tracking_url : item?.easy_post_shipments?.tracking_url}`}>
                                                                                        <Button
                                                                                            type="button"
                                                                                            className="btn-success btn-sm mr-05 mb-05"
                                                                                            title="Track Order"
                                                                                        >
                                                                                            <i className="fa fa-truck" id="printLabel"></i>

                                                                                        </Button>
                                                                                    </a>}
                                                                                    <a target="_blank" href={item?.label_url}>
                                                                                        <Button
                                                                                            type="button"
                                                                                            className="btn-success btn-sm mr-05 mb-05"
                                                                                            title="Download Invoice & Packing Slip"
                                                                                        >
                                                                                            <i className="fa fa-clipboard" id="printLabel"></i>

                                                                                        </Button>
                                                                                    </a>
                                                                                    <a target="_blank" href={item?.easy_post_shipments?.length !== undefined ? item?.easy_post_shipments[0]?.label_url : item?.easy_post_shipments?.label_url}>
                                                                                        <Button
                                                                                            type="button"
                                                                                            className="btn-success btn-sm mr-05 mb-05"
                                                                                            title="Download Ship Label"
                                                                                        >
                                                                                            <i className="fa fa-download" id="printLabel"></i>

                                                                                        </Button>
                                                                                    </a>
                                                                                </>}
                                                                                {(orderDetails?.shippingServiceCategory === 4) && <a target="_blank" href={`${fleetReceipt}/${item?.fleet_receipt}`}>
                                                                                    <Button
                                                                                        type="button"
                                                                                        className="btn-success btn-sm mr-05 mb-05"
                                                                                        title="Download Freight Receipt"
                                                                                    >
                                                                                        <i className="fa fa-download" id="fleetReceipt"></i>

                                                                                    </Button>
                                                                                </a>}
                                                                            </> : null}
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        } return (
                                                            <>
                                                                <tr>
                                                                    <td><div>{prod?.order_id ?? orderDetails?.order_id}</div></td>
                                                                    <td>
                                                                        <div style={{ fontWeight: "bold" }}>{`${prod.product_title} (${prod.category_name})`}</div>
                                                                        {prod.fitting_options && prod.fitting_options.length > 0
                                                                            ? prod.fitting_options.map((value) => {
                                                                                if (value.attribute_name == 'Playing Length') {
                                                                                    return (
                                                                                        <>
                                                                                            <div>
                                                                                                <label className="label-tag">
                                                                                                    {' '}
                                                                                                    Playing Length :
                                                                                                </label>{' '}
                                                                                                {(value?.attribute_value == 0) ? "Uncut" : value?.attribute_value}
                                                                                            </div>
                                                                                        </>
                                                                                    );
                                                                                }

                                                                                if (value.attribute_name == 'Extra Wrap') {
                                                                                    return (
                                                                                        <>
                                                                                            <div>
                                                                                                <label className="label-tag">
                                                                                                    {' '}
                                                                                                    Extra Wraps :
                                                                                                </label>
                                                                                                {value.attribute_value &&
                                                                                                    value.attribute_value == 1
                                                                                                    ? 'None'
                                                                                                    : value.attribute_value == 2
                                                                                                        ? '1'
                                                                                                        : value.attribute_value == 3
                                                                                                            ? '2'
                                                                                                            : 'None'}
                                                                                            </div>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                                if (value.attribute_name == 'Grip Logo') {
                                                                                    return (
                                                                                        <>
                                                                                            <div>
                                                                                                <label className="label-tag">
                                                                                                    {' '}
                                                                                                    Grip Logo :
                                                                                                </label>
                                                                                                {value.attribute_value &&
                                                                                                    value.attribute_value}
                                                                                            </div>
                                                                                        </>
                                                                                    );
                                                                                }

                                                                                if (value.attribute_name == 'Adaptors') {
                                                                                    return (
                                                                                        <>
                                                                                            <div>
                                                                                                <label className="label-tag">
                                                                                                    {' '}
                                                                                                    Adaptor :
                                                                                                </label>{' '}
                                                                                                {value.option_name} (${value.price})
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="label-tag">
                                                                                                    {' '}
                                                                                                    Adaptor Setting :{' '}
                                                                                                </label>
                                                                                                {value.sub_option_name}
                                                                                            </div>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                                if (value.attribute_name == 'Grips') {
                                                                                    return (
                                                                                        <>
                                                                                            <div>
                                                                                                <label className="label-tag">
                                                                                                    {' '}
                                                                                                    Grips Brand :{' '}
                                                                                                </label>
                                                                                                {value.option_name}
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className="label-tag">
                                                                                                    {' '}
                                                                                                    Grip Model :{' '}
                                                                                                </label>
                                                                                                {value.sub_option_name} (${value.price})
                                                                                            </div>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            })
                                                                            : null}
                                                                    </td>
                                                                    <td><div>{prod.product_variation_sku}</div></td>
                                                                    <td>
                                                                        <div>{prod.quantity}</div></td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }) : null
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            {/* isOpenFleetModal */}
            <MarkAsFleetShipping isOpen={isOpenFleetModal} isClose={setIsOpenFleetModal} boxData={boxData} setFleetModalRes={setFleetModalRes} />
        </>
    )
};


OrderShippingBoxes.propTypes = {
    getOrderShippingBoxDetails: PropTypes.func.isRequired,
    voidShippingLabel: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    OrderShippingBoxDetails: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    downloadFreightInvoice: PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
    loading: state.orderShippingBoxes.OrderShippingBoxesLoading,
    OrderShippingBoxDetails: state.orderShippingBoxes.OrderShippingBoxDetails,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    getOrderShippingBoxDetails,
    voidShippingLabel,
    downloadFreightInvoice
})(OrderShippingBoxes);
