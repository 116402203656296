import { ADD_BREADCRUM_ID_WITH_NAME, ADD_BREADCRUM_FAIL } from 'actions/types';

export const addBreadcrumDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_BREADCRUM_ID_WITH_NAME,
      payload: data,
    });
    return true;
  } catch (err) {
    dispatch({
      type: ADD_BREADCRUM_FAIL,
      payload: {
        message: 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};
