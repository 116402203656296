/* eslint-disable */
import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from '../../../utils/Loading';
import {
    Button,
    Card,
    CardBody,
    Col,
    Row,
} from 'reactstrap';

import Moment from 'react-moment';

import * as Constants from 'constants/index';
import {
    getProductSelectorList,
    getInquiryForProductSelector,
    deleteProductSelector,
} from 'actions/admin/product';

import PerPageSelect from '../PerPageSelect';

const renderName = (params) => {
    if (params.data === undefined) {
        // when no node id, display the spinner image
        return <Loading />;
    } else {
        // otherwise just display node ID (or whatever you wish for this column)
        return <div>{params?.data?.userDetail}</div>;
    }
};

const ProductSelectorList = ({
    getProductSelectorList,
    deleteProductSelector,
}) => {
    const history = useNavigate();
    const location = useLocation();
    //################## Initillise sorting searching parameters by default values ###################
    const [sortingParams, setSortingParams] = useState(
        Object.keys(location?.state ?? {}).length
            ? { ...location.state, onLoad: true }
            : {
                limit: Constants.DEFAULT_PAGE_SIZE,
                page: 1,
                orderBy: 'created_at',
                ascending: 'desc',
                query: '',
                filter: {},
                onLoad: true,
            }
    );

    const gridRef = useRef();
    const [downloadData, setDownloadData] = useState([]);
    const [gridApi, setGridApi] = useState(null);

    //#########################Colums defination start ###################
    const columns = [
        {
            field: 'userDetail',
            headerName: 'User Name',
            suppressSizeToFit: false,
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            sortable: true,
            filter: 'agTextColumnFilter',
            width: 150,
            // pass in additional parameters to the text filter
            filterParams: {
                closeOnApply: true,
                filterOptions: Constants.TEXT_FILTER_OPTIONS,
                suppressAndOrCondition: true,
                debounceMs: 800,
            },
            colSpan: (params) => {
                if (params.data === undefined) {
                    return 6;
                } else {
                    return 0;
                }
            },
            cellRenderer: (params) => renderName(params)
        },
        {
            field: 'firstProduct',
            headerName: 'Product Match',
            suppressMovable: true,
            suppressSizeToFit: false,
            cellClass: 'suppress-movable-col',
            sortable: true,
            filter: 'agNumberColumnFilter',
            width: 200,
            // pass in additional parameters to the text filter
            filterParams: {
                closeOnApply: true,
                filterOptions: Constants.TEXT_FILTER_OPTIONS,
                suppressAndOrCondition: true,
                debounceMs: 800,
            },
        },
        // {
        //     field: 'secondProduct',
        //     headerName: 'Second Recommendation',
        //     suppressSizeToFit: false,
        //     suppressMovable: true,
        //     cellClass: 'suppress-movable-col',
        //     sortable: true,
        //     filter: 'agTextColumnFilter',
        //     width: 200,
        //     // pass in additional parameters to the text filter
        //     filterParams: {
        //         closeOnApply: true,
        //         filterOptions: Constants.TEXT_FILTER_OPTIONS,
        //         suppressAndOrCondition: true,
        //         debounceMs: 800,
        //     },
        // },
        // {
        //     field: 'thirdProduct',
        //     headerName: 'Third Recommendation',
        //     suppressSizeToFit: false,
        //     suppressMovable: true,
        //     cellClass: 'suppress-movable-col',
        //     sortable: true,
        //     filter: 'agTextColumnFilter',
        //     width: 200,
        //     // pass in additional parameters to the text filter
        //     filterParams: {
        //         closeOnApply: true,
        //         filterOptions: Constants.TEXT_FILTER_OPTIONS,
        //         suppressAndOrCondition: true,
        //         debounceMs: 800,
        //     },
        // },
        {
            field: 'category',
            headerName: 'Category',
            suppressSizeToFit: false,
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            sortable: true,
            filter: 'agTextColumnFilter',
            width: 200,
            // pass in additional parameters to the text filter
            filterParams: {
                closeOnApply: true,
                filterOptions: Constants.TEXT_FILTER_OPTIONS,
                suppressAndOrCondition: true,
                debounceMs: 800,
            },
        },
        {
            field: 'created_at',
            headerName: 'Date',
            suppressSizeToFit: false,
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            sortable: true,
            filter: 'agDateColumnFilter',
            width: 150,
            // pass in additional parameters to the text filter
            filterParams: {
                closeOnApply: true,
                filterOptions: Constants.DATE_FILTER_OPTIONS,
                suppressAndOrCondition: true,
                debounceMs: 800,
                buttons: ['reset', 'apply'],
            },
            // headerSortingStyle,
            cellRenderer: (params) => (
                <>
                    <div className="primary-Outer">
                        <Moment
                            format={
                                process.env.REACT_APP_DISPLAY_DATE_FORMATE ?? 'MM/DD/YYYY'
                            }
                            locale="de"
                        >
                            {params.value}
                        </Moment>
                    </div>
                </>
            ),
        },
        {
            field: 'sale_closed',
            headerName: 'Sale Closed',
            suppressSizeToFit: false,
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            sortable: true,
            filter: 'agTextColumnFilter',
            width: 200,
            // pass in additional parameters to the text filter
            filterParams: {
                closeOnApply: true,
                filterOptions: Constants.TEXT_FILTER_OPTIONS,
                suppressAndOrCondition: true,
                debounceMs: 800,
            },
        },
        {
            field: '_id',
            headerName: 'Actions',
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            cellRenderer: (params) => (
                <div>
                    {
                        <Link to={`/admin/product-selector/details/${params.data?._id}`}>
                            <Button type="button" size="sm" color="info" title="View">
                                <i className="fa fa-eye"></i>
                            </Button>
                        </Link>
                    }
                    <Button
                        type="button"
                        size="sm"
                        color="danger"
                        title="Delete"
                        onClick={(e) => {
                            if (
                                window.confirm(
                                    `Are you sure, you want to delete ${params.data?.userDetail} product selector?`
                                )
                            ) {
                                deleteProductSelector(params.data?._id, history).then((res) => {
                                    if (res) gridApi.purgeInfiniteCache();
                                });
                            }
                        }}
                    >
                        <i className="fa fa-trash"></i>
                    </Button>
                </div>
            ),
        },
    ];

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    });

    const filterChanged = useCallback((params) => {
        const filterInstance = gridRef.current.api.getFilterInstance(
            params.columns[0].colId
        );
        if (filterInstance.appliedModel?.type === 'any') {
            setActiveClass('');
            gridRef.current.api.destroyFilter(params.columns[0].colId);
        }
    });

    const onPaginationChange = useCallback((pageSize) => {
        setSortingParams((initVals) => {
            return { ...initVals, limit: pageSize };
        });
        gridRef.current.api.gridOptionsWrapper.setProperty(
            'cacheBlockSize',
            pageSize
        );
        gridRef.current.api.infiniteRowModel.resetCache();
        gridRef.current.api.paginationSetPageSize(pageSize);
    });

    const customLoadingCellRenderer = useMemo(() => {
        return LoadingCellRenderer;
    }, []);
    const loadingCellRendererParams = useMemo(() => {
        return {
            loadingMessage: 'Loading....',
        };
    }, []);

    useEffect(() => {
        if (gridApi) {
            if (Object.keys(sortingParams.filter).length) {
                gridApi.setFilterModel(sortingParams.filter);
            }
            const dataSource = {
                getRows: async (params) => {
                    const page = gridApi.paginationGetCurrentPage() + 1;
                    const limit = gridApi.paginationGetPageSize();

                    const sortModel = params.sortModel.length
                        ? params.sortModel[0]
                        : null;
                    const filterModel = params.filterModel ? params.filterModel : {};
                    const customParams = {
                        ...sortingParams,
                        limit,
                        orderBy: sortModel?.colId,
                        ascending: sortModel?.sort,
                        filter: filterModel,
                        page,
                    };

                    const customData = await getProductSelectorList(customParams);
                    if (customData?.data?.status) {
                        params.successCallback(
                            customData.data.response[0].data,
                            customData.data.response[0].metadata[0].totalRecord
                        );
                        customParams.onLoad = false;
                        setSortingParams({ ...customParams });
                        history(location.pathname, { state: customParams });
                    } else {
                        params.successCallback([], 0);
                    }
                },
            };
            gridApi.setDatasource(dataSource);
            gridApi.api?.sizeColumnsToFit();
        }
    }, [gridApi]);

    const onFirstDataRendered = useCallback(() => {
        setTimeout(() => {
            gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
        }, 1000);
    });

    const onPaginationChanged = useCallback((params) => {
        if (gridRef.current.api) {
            const page = gridApi?.paginationGetCurrentPage();
            setSortingParams({ ...sortingParams, page });
        }
    });

    const handleCSVDownload = useCallback(async (event) => {
        let data = await getInquiryForProductSelector(sortingParams);
        setDownloadData(data.data);
        document.getElementById('downloadCsv').click();
    });

    return (
        <div className="animated fadeIn userTableList">
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <div className="tabletopsec">
                                <div className="add-button-div">
                                    <a
                                        href={downloadData}
                                        id="downloadCsv"
                                        download
                                        hidden
                                        className="hide"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    ></a>
                                </div>
                                {/* {actions} */}
                                {
                                    <div className="rightpaneltop">
                                        <Button
                                            color="primary"
                                            size="sm"
                                            onClick={handleCSVDownload}
                                        >
                                            <i className="fa fa-download"></i> Export CSV
                                        </Button>
                                    </div>
                                }
                                <div className="rightpaneltop">
                                    Products Selector Per Page
                                    <PerPageSelect
                                        perPage={sortingParams.limit}
                                        onPaginationChange={onPaginationChange}
                                    />
                                </div>
                            </div>
                            <div className="card w-100 p-0 border-0">
                                <div className="card-body p-0 position-relative">
                                    <div className="ag-theme-alpine">
                                        <AgGridReact
                                            defaultColDef={{
                                                resizable: true,
                                            }}
                                            loadingCellRenderer={customLoadingCellRenderer}
                                            loadingCellRendererParams={loadingCellRendererParams}
                                            domLayout={'autoHeight'}
                                            rowModelType={'infinite'}
                                            columnDefs={columns}
                                            pagination={true}
                                            sizeColumnsToFit={true}
                                            onFilterChanged={filterChanged}
                                            onGridReady={onGridReady}
                                            paginationPageSize={sortingParams.limit}
                                            cacheBlockSize={sortingParams.limit}
                                            cacheQuickFilter={true}
                                            onFirstDataRendered={onFirstDataRendered}
                                            onPaginationChanged={onPaginationChanged}
                                            ref={gridRef}
                                        ></AgGridReact>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

ProductSelectorList.propTypes = {
    getProductSelectorList: PropTypes.func.isRequired,
    deleteProductSelector: PropTypes.func.isRequired,
    reviewList: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    subLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    loading: state.product.loading,
    reviewList: state.product.reviewList,
    subLoading: state.product.isSubLoading,
});

export default connect(mapStateToProps, {
    getProductSelectorList,
    deleteProductSelector,
})(ProductSelectorList);
