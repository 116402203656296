/* eslint-disable */
import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { Button, Card, CardBody, Col, Row, Input } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import Spinner from 'views/Spinner';
import CustomFilters from './CustomFilters';
import CustomExportToCSV from './CustomExportToCSV';
import BulkActions from './BulkActions';
import { DISPLAYED_DEALER_APPROVED_STATUS } from 'constants/index';

import {
  getDealersList,
  deleteDealer,
  dealerApproveDisapprove,
  getDealerTypes,
  markAsFeatured,
} from 'actions/admin/dealer';

import * as Constants from 'constants/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from '../../../utils/Loading';
import PerPageSelect from '../PerPageSelect';

//   PaginationProvider,
//   SizePerPageDropdownStandalone,
// } from 'react-bootstrap-table2-paginator';

// const renderName = (params) => {
//   if (params.data === undefined) {
//     return <Loading />;
//   } else {
//     return <div>{params.data?.name}</div>;
//   }
// };

const renderEmail = (params) => {
  if (params.data === undefined) {
    return <Loading />;
  } else {
    return <div>{params.data?.email}</div>;
  }
};

const renderBusinessName = (params) => {
  if (params.data === undefined) {
    return <Loading />;
  } else {
    return <div>{params.data?.dealer.business_name}</div>;
  }
};

const renderDealerType = (params) => {
  return <div>{params.data.dealer?.dealer_type?.name}</div>;
};

const renderLastLogin = (params) => {
  return params.data.last_login !== undefined ? (
    <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
      {params.data.last_login}
    </Moment>
  ) : (
    <p>-</p>
  );
};

const renderStatus = (params, changeDealerStatus, history) => {
  let displayButton = '';
  if (params.data?.status === 0) {
    displayButton = (
      <div>
        <Button
          type="button"
          size="sm"
          color="danger"
          className="shownew"
          data-tip="Pending! Edit and approve."
          data-for={'TooltipPending-' + params.data?.status}
          onClick={(e) => {
            history(`/admin/dealers/edit/${params.data?._id}`);
          }}
        >
          <span>{DISPLAYED_DEALER_APPROVED_STATUS[params.data?.status]} </span>
          <span className="iSpan">
            <i className="fa fa-info"></i>
          </span>
        </Button>
        <ReactTooltip
          id={'TooltipPending-' + params.data?.status}
          aria-haspopup="true"
        />
      </div>
    );
  } else if (params.data?.status === 2) {
    displayButton = (
      <div>
        <Button
          type="button"
          size="sm"
          color="danger"
          className="shownew actives"
          data-tip="Active! Change status to deactivated."
          data-for={'TooltipDeactivated-' + params.data?.status}
          disabled={params.data?.dealer.is_featured}
          onClick={(e) => {
            if (
              window.confirm(
                `Are you sure, you want to deactivate ${params.data?.name} dealer?`
              )
            ) {
              changeDealerStatus(params.data?._id, 1);
            }
          }}
        >
          <span>{DISPLAYED_DEALER_APPROVED_STATUS[params.data?.status]} </span>
          <span className="iSpan">
            <i className="fa fa-check"></i>
          </span>
        </Button>
        <ReactTooltip
          id={'TooltipDeactivated-' + params.data?.status}
          aria-haspopup="true"
        />
      </div>
    );
  } else if (params.data?.status === 3) {
    displayButton = (
      <div>
        <Button
          type="button"
          size="sm"
          color="success"
          className="shownew blocked"
          data-tip="Rejected! Edit and approve."
          data-for={'TooltipRejected-' + params.data?.status}
          onClick={(e) => {
            history(`/admin/dealers/edit/${params.data?._id}`);
          }}
        >
          <span>{DISPLAYED_DEALER_APPROVED_STATUS[params.data?.status]} </span>
          <span className="iSpan">
            <i className="fa fa-close"></i>
          </span>
        </Button>
        <ReactTooltip
          id={'TooltipRejected-' + params.data?.status}
          aria-haspopup="true"
        />
      </div>
    );
  } else if (params.data?.status === 1) {
    displayButton = (
      <div>
        <Button
          type="button"
          size="sm"
          color="success"
          className="shownew blocked"
          data-tip="Deactivated! Change status to active."
          data-for={'TooltipDeactivated-' + params.data?.status}
          onClick={(e) => {
            if (
              window.confirm(
                `Are you sure, you want to activate ${params.data?.name} dealer?`
              )
            ) {
              changeDealerStatus(params.data?._id, 2);
            }
          }}
        >
          <span>{DISPLAYED_DEALER_APPROVED_STATUS[params.data?.status]} </span>
          <span className="iSpan">
            <i className="fa fa-close"></i>
          </span>
        </Button>
        <ReactTooltip
          id={'TooltipDeactivated-' + params.data?.status}
          aria-haspopup="true"
        />
      </div>
    );
  } else {
    displayButton = <></>;
  }
  return displayButton;
};

const renderActions = (params, deleteDealer, updateDealersList) => {
  const businessName = params.data?.dealer.business_name
    .replace(/[^a-zA-Z ]/g, '')
    .split(' ')
    .join('-')
    .toLowerCase();
  return (
    <div>
      <Link to={`/admin/dealers/details/${params.data?._id}`}>
        <Button type="button" size="sm" color="info" title="View">
          <i className="fa fa-eye"></i>
        </Button>
      </Link>
      <Link to={`/admin/dealers/edit/${params.data?._id}`}>
        <Button type="button" size="sm" color="success" title="Edit">
          <i className="fa fa-pencil"></i>
        </Button>
      </Link>
      <Button
        type="button"
        size="sm"
        color="danger"
        title="Delete"
        onClick={(e) => {
          if (
            window.confirm(
              `Are you sure, you want to delete ${params.data?.name} dealer?`
            )
          ) {
            deleteDealer(params.data?._id).then((res) => {
              if (res) updateDealersList();
            });
          }
        }}
      >
        <i className="fa fa-trash"></i>
      </Button>
      <Link
        to={`/admin/dealers/${businessName}/dealer-address/${params.data?._id}`}
      >
        <Button type="button" size="sm" color="info" title="Addresses">
          <i className="fa fa-map-marker"></i>
        </Button>
      </Link>
      {/* <Link
            to={{
              pathname: '/admin/orders/create',
              state: {
                userId: params.data._id,
                userType: params.data.role[0].user_type,
              },
            }}
          >
            <Button type="button" size="sm" color="info">
              <i className="fa fa-cart-plus" aria-hidden="true"></i>
            </Button>
          </Link> */}
    </div>
  );
};

const actions = (
  <div className="add-button-div">
    <Link to="/admin/dealers/create" className="addNewElementClass">
      <Button color="primary" size="sm">
        <i className="fa fa-plus"></i> Add New Dealer
      </Button>
    </Link>
  </div>
);

const DealersList = ({
  getDealersList,
  deleteDealer,
  dealerApproveDisapprove,
  userList: { data, count },
  loading,
  subLoading,
  getDealerTypes,
  markAsFeatured,
  dealerTypeList,
}) => {
  const history = useNavigate();
  const location = useLocation();
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
        limit: Constants.DEFAULT_PAGE_SIZE,
        page: 1,
        orderBy: 'created_at',
        ascending: 'desc',
        query: '',
        filter: {},
        isAll: 1,
      }
  );

  // to resolve useEffect dependency error

  //################## to get updated list ###################
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);

  const updateDealersList = () => {
    gridApi.purgeInfiniteCache();
  };

  const changeDealerStatus = async (id, value) => {
    await dealerApproveDisapprove(id, value);
    gridApi.purgeInfiniteCache();
  };

  //################## Get dealers Type ###################
  useMemo(() => {
    getDealerTypes();
  }, [getDealerTypes]);

  //#########################Colums defination start ###################
  const columns = [
    // {
    //   field: 'name',
    //   headerName: 'Name',
    //   suppressMovable: true,
    //   width: 140,
    //   cellClass: 'suppress-movable-col',
    //   sortable: true,
    //   filter: 'agTextColumnFilter',
    //   suppressSizeToFit: false,
    //   filterParams: {
    //     closeOnApply: true,
    //     filterOptions: Constants.TEXT_FILTER_OPTIONS,
    //     suppressAndOrCondition: true,
    //     debounceMs: 800,
    //   },
    //   colSpan: (params) => {
    //     if (params.data === undefined) {
    //       return 8;
    //     } else {
    //       return 0;
    //     }
    //   },
    //   cellRenderer: (params) => renderName(params),
    // },
    {
      field: 'insensitiveBusinessName',
      headerName: 'Business Name',
      suppressMovable: true,
      width: 160,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      colSpan: (params) => {
        if (params.data === undefined) {
          return 6;
        } else {
          return 0;
        }
      },
      cellRenderer: (params) => renderBusinessName(params),
    },
    {
      field: 'dealer.dealer_type.name',
      headerName: 'Dealer Type',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      width: 120,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => renderDealerType(params),
    },
    {
      field: 'email',
      headerName: 'Owner Email',
      width: 160,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => renderEmail(params),
    },

    {
      field: 'last_login',
      headerName: 'Last Login',
      width: 120,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agDateColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.DATE_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
        buttons: ['reset', 'apply'],
      },
      cellRenderer: (params) => renderLastLogin(params),
    },
    {
      field: 'status',
      width: 100,
      headerName: 'Status',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      cellRenderer: (params) =>
        renderStatus(params, changeDealerStatus, history),
    },
    // {
    //   field: 'dealer.is_featured',
    //   headerName: 'Corporate',
    //   suppressMovable: true,
    //   width: 140,
    //   cellClass: 'suppress-movable-col',
    //   sortable: true,
    //   cellRenderer: (params) => {
    //     const displayContent = params.data.dealer.is_featured ? (
    //       <div>
    //         <Button
    //           type="button"
    //           size="sm"
    //           className="warning-featured-main"
    //           data-tip="Featured! Mark As Not Featured."
    //           data-for={'TooltipFeatured-' + params.data.dealer.is_featured}
    //           onClick={(e) => {
    //             if (
    //               window.confirm(
    //                 `Are you sure, you want to mark ${params.data.name} as not featured?`
    //               )
    //             ) {
    //               markAsFeatured(params.data._id, false);
    //               updateDealersList();
    //             }
    //           }}
    //         >
    //           <span
    //             className="featured-active-main"
    //             // data-tip="Featured! Mark As Not Featured."
    //             // data-for={'TooltipFeatured-' + params.data.dealer.is_featured}
    //           >
    //             Featured
    //           </span>
    //         </Button>
    //         <ReactTooltip
    //           id={'TooltipFeatured-' + params.data.dealer.is_featured}
    //           aria-haspopup="true"
    //         />
    //       </div>
    //     ) : (
    //       <div>
    //         <Button
    //           type="button"
    //           size="sm"
    //           className="warning-featured-main"
    //           data-tip="Not Featured! Mark As Featured."
    //           data-for={'Tooltip-' + params.data.dealer.is_featured}
    //           disabled={params.data.status !== 2}
    //           onClick={(e) => {
    //             if (
    //               window.confirm(
    //                 `Are you sure, you want to mark ${params.data.name} as featured?`
    //               )
    //             ) {
    //               markAsFeatured(params.data._id, true);
    //               updateDealersList();
    //             }
    //           }}
    //         >
    //           Mark As Featured
    //         </Button>
    //         <ReactTooltip
    //           id={'Tooltip-' + params.data.dealer.is_featured}
    //           aria-haspopup="true"
    //         />
    //       </div>
    //     );
    //     return displayContent;
    //   },
    //   // csvFormatter: (cell, row, rowIndex) => {
    //   //   const displayContent = row.dealer.is_featured ? 'Featured' : '-';
    //   //   return displayContent;
    //   // },
    // },
    {
      field: '_id',
      width: 220,
      headerName: 'Actions',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) =>
        renderActions(params, deleteDealer, updateDealersList),
    },
  ];
  //#########################Colums defination end ###################

  //################## END Set properties(options) of datatables ###################

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };

          const customData = await getDealersList(customParams);
          if (customData?.data?.status) {
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });
  let delayTimer;
  const onSearch = (e) => {
    clearTimeout(delayTimer);
    const searchText = e.target.value.trim();
    delayTimer = setTimeout(() => {
      if (gridApi) {
        if (Object.keys(sortingParams.filter).length) {
          gridApi.setFilterModel(sortingParams.filter);
        }
        const dataSource = {
          getRows: async (params) => {
            const page = gridApi.paginationGetCurrentPage() + 1;
            const limit = gridApi.paginationGetPageSize();

            const sortModel = params.sortModel.length
              ? params.sortModel[0]
              : null;
            const filterModel = params.filterModel ? params.filterModel : {};
            const customParams = {
              ...sortingParams,
              query: searchText,
              limit,
              orderBy: sortModel?.colId,
              ascending: sortModel?.sort,
              filter: filterModel,
              page,
            };

            const customData = await getDealersList(customParams);
            if (customData?.data?.status) {
              params.successCallback(
                customData.data.response[0].data,
                customData.data.response[0].metadata[0].totalRecord
              );
              customParams.onLoad = false;
              setSortingParams({ ...customParams });
              history(location.pathname, { state: customParams });
            } else {
              params.successCallback([], 0);
            }
          },
        };
        gridApi.setDatasource(dataSource);
        gridApi.api?.sizeColumnsToFit();
      }
    }, 1000);
  };

  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                {actions}
                <div className="rightpaneltop">
                  <Input
                    type="text"
                    id="search-text-box"
                    placeholder="Search"
                    onKeyUp={(e) => {
                      onSearch(e);
                    }}
                  // onChange={onSearchBoxTextChanged}
                  />
                </div>
                <div className="me-3"></div>
                <div className="rightpaneltop">
                  Dealers Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>
              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      defaultColDef={{
                        resizable: true,
                      }}
                      loadingCellRenderer={customLoadingCellRenderer}
                      loadingCellRendererParams={loadingCellRendererParams}
                      domLayout={'autoHeight'}
                      rowModelType={'infinite'}
                      columnDefs={columns}
                      pagination={true}
                      sizeColumnsToFit={true}
                      onFilterChanged={filterChanged}
                      onGridReady={onGridReady}
                      paginationPageSize={sortingParams.limit}
                      cacheBlockSize={sortingParams.limit}
                      cacheQuickFilter={true}
                      onFirstDataRendered={onFirstDataRendered}
                      onPaginationChanged={onPaginationChanged}
                      ref={gridRef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

DealersList.propTypes = {
  getDealersList: PropTypes.func.isRequired,
  deleteDealer: PropTypes.func.isRequired,
  dealerApproveDisapprove: PropTypes.func.isRequired,
  getDealerTypes: PropTypes.func.isRequired,
  userList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
  markAsFeatured: PropTypes.func.isRequired,
  dealerTypeList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  userList: state.dealer.usersList,
  loading: state.dealer.loading,
  subLoading: state.dealer.isSubLoading,
  dealerTypeList: state.dealer.dealerTypeList,
});

export default connect(mapStateToProps, {
  getDealersList,
  deleteDealer,
  dealerApproveDisapprove,
  getDealerTypes,
  markAsFeatured,
})(DealersList);
