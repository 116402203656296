import {
  FlexCode_LOADING,
  FlexCode_CREATED,
  FlexCode_CREATE_ERROR,
  FlexCode_FAIL,
  FlexCode_UPDATED,
  FlexCode_UPDATE_ERROR,
  FlexCode_DELETED,
  FlexCode_DELETE_ERROR,
  FlexCode_LIST_UPDATED,
  GET_FlexCode_BY_ID,
} from "actions/types";

const initalState = {
  flexCodeList: {},
  currentFlexCode: {},
  loading: false,
  isSubLoading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case FlexCode_LOADING:
      return {
        ...state,
        flexCodeList: payload.is_sub_element ? state.flexCodeList : initalState.flexCodeList,
        currentFlexCode: payload.is_sub_element
          ? state.currentFlexCode
          : initalState.currentFlexCode,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case FlexCode_CREATED:
      return {
        ...state,
        loading: false,
      };
    case FlexCode_CREATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FlexCode_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false
      };
    case FlexCode_UPDATED:
      return {
        ...state,
        currentFlexCode: {},
        loading: false,
      };
    case FlexCode_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FlexCode_DELETED:
      return {
        ...state,
        loading: false,
      };
    case FlexCode_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FlexCode_LIST_UPDATED:
      return {
        ...state,
        flexCodeList: payload.data.flexCodeList,
        loading: false,
      };
    case GET_FlexCode_BY_ID:
      return {
        ...state,
        currentFlexCode: payload.data,
        loading: false,
      };

    default:
      return state;
  }
}
