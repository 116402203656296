/* eslint-disable */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from '../../../../utils/Loading';

import {
  getAttributesList,
  deleteAttribute,
  changeStatus,
} from 'actions/admin/GearProduct/gearAttribute';
import * as Constants from 'constants/index';
import { Button, Card, CardBody, Col, Row, Input } from 'reactstrap';
import PerPageSelect from '../../PerPageSelect';

const renderName = (params) => {
  if (params.data === undefined) {
    return <Loading />;
  } else {
    return <div>{params.data?.name}</div>;
  }
};

// const renderStatus = (params, changeAttributeStatus) => {
//   return (
//     <div>
//       <Input
//         type="select"
//         name="status"
//         id={params.data?._id}
//         defaultValue={params.data?.status}
//         onChange={(e, a) => {
//           changeAttributeStatus(params.data?._id, e.target.value);
//         }}
//       >
//         <option value="0">Inactive</option>
//         <option value="1">Active</option>
//       </Input>
//     </div>
//   );
// };

const renderActions = (params, deleteAttribute, updateAttributes) => {
  return (
    <div>
      <Link to={`/admin/gear-attributes/edit/${params.data?._id}`}>
        <Button type="button" size="sm" color="success" title="Edit">
          <i className="fa fa-pencil"></i>
        </Button>
      </Link>
      {params.data?.name.includes('Color') ||
      params.data?.name.includes('Flex') ||
      params.data?.name.includes('Iron #') ||
      params.data?.name.includes('Size') ||
      params.data?.name.includes('Tip') ? null : (
        <Button
          type="button"
          size="sm"
          color="danger"
          title="Delete"
          onClick={(e) => {
            if (
              window.confirm(`Are you sure, you want to delete this attribute?`)
            ) {
              deleteAttribute(params.data?._id).then((res) => {
                if (res) updateAttributes();
              });
            }
          }}
        >
          <i className="fa fa-trash"></i>
        </Button>
      )}
    </div>
  );
};

const actions = (
  <div className="add-button-div specify-width">
    <Link to="/admin/gear-attributes/add" className="addNewElementClass">
      <Button color="primary" size="sm">
        <i className="fa fa-plus"></i> Add Gear Attribute
      </Button>
    </Link>
  </div>
);

const GearAttributesList = ({
  getAttributesList,
  deleteAttribute,
  changeStatus,
  gearAttributesList: { data, count },
  loading,
  subLoading,
}) => {
  const history = useNavigate();
  const location = useLocation();
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
          limit: Constants.DEFAULT_PAGE_SIZE,
          page: 1,
          orderBy: 'created_at',
          ascending: 'desc',
          query: '',
          filter: {},
          onLoad: true,
        }
  );

  // to resolve useEffect dependency error

  //################## to get updated list ###################
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);

  const updateAttributes = () => {
    gridApi.purgeInfiniteCache();
  };

  const changeAttributeStatus = async (id, value) => {
    await changeStatus(id, value);
    gridApi.purgeInfiniteCache();
  };
  //#########################Colums defination start ###################
  const columns = [
    {
      field: 'insensitiveName',
      headerName: 'Name',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      colSpan: (params) => {
        if (params.data === undefined) {
          return 3;
        } else {
          return 0;
        }
      },
      cellRenderer: renderName,
    },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   suppressMovable: true,
    //   cellClass: 'suppress-movable-col',
    //   sortable: true,
    //   cellRenderer: (params) => renderStatus(params, changeAttributeStatus),
    // },
    {
      field: '_id',
      headerName: 'Actions',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) =>
        renderActions(params, deleteAttribute, updateAttributes),
    },
  ];

  //#########################Colums defination end ###################

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };

          const customData = await getAttributesList(customParams);
          if (customData?.data?.status) {
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });

  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                {actions}
                <div className="rightpaneltop">
                  Gear Attribute Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>

              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      defaultColDef={{
                        resizable: true,
                      }}
                      loadingCellRenderer={customLoadingCellRenderer}
                      loadingCellRendererParams={loadingCellRendererParams}
                      domLayout={'autoHeight'}
                      rowModelType={'infinite'}
                      columnDefs={columns}
                      pagination={true}
                      sizeColumnsToFit={true}
                      onFilterChanged={filterChanged}
                      onGridReady={onGridReady}
                      paginationPageSize={sortingParams.limit}
                      cacheBlockSize={sortingParams.limit}
                      cacheQuickFilter={true}
                      onFirstDataRendered={onFirstDataRendered}
                      onPaginationChanged={onPaginationChanged}
                      ref={gridRef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

GearAttributesList.propTypes = {
  getAttributesList: PropTypes.func.isRequired,
  deleteAttribute: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  gearAttributesList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  gearAttributesList: state.gearAttribute.gearAttributesList,
  loading: state.gearAttribute.loading,
  subLoading: state.gearAttribute.isSubLoading,
});

export default connect(mapStateToProps, {
  getAttributesList,
  deleteAttribute,
  changeStatus,
})(GearAttributesList);
