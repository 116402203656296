import { PAYMENT_METHOD } from 'constants';
import { useCallback, useEffect, useState } from 'react';
import {
    Input,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    Table,
    Form,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { changePaymentStatus } from 'actions/admin/order';
import { connect } from 'react-redux';
import ButtonSpinner from 'views/ButtonSpinner';

const PaymentTypeModel = ({ isPaymentModel, togglePaymentTypeModel, paymentStatusDetail, changePaymentStatus }) => {
    const [selectedMethod, setSelectedMethod] = useState(null)
    const [otherPaymentMethod, setOtherPaymentMethod] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const submitForm = (e) => {
        e.preventDefault();
        paymentStatusDetail.selectedMethod = selectedMethod;
        paymentStatusDetail.payment_comment = otherPaymentMethod;
        setIsLoading(true);
        changePaymentStatus(paymentStatusDetail).then((res) => {
            if (res.status === true) {
                togglePaymentTypeModel(true)
            }
            setIsLoading(false);
        });
    }
    return (
        <Modal isOpen={isPaymentModel} toggle={togglePaymentTypeModel} backdrop={false}>
            <Form onSubmit={submitForm} className="form-horizontal">
                <ModalHeader >
                    Change Payment Status
                </ModalHeader>
                <ModalBody>
                    <Row className="mt-2">
                        <Col>
                            <Label className="p-0">
                                Payment status <span>*</span>
                            </Label>
                            <Input
                                type="select"
                                name="payment_status"
                                onChange={(e) => setSelectedMethod(e.target.value)}
                                required
                            >
                                <option
                                    value={""}
                                >
                                    {"Select Payment Mode"}
                                </option>
                                {
                                    PAYMENT_METHOD.map((paymentStatus) => (
                                        <option
                                            value={paymentStatus.key}
                                            key={paymentStatus.key}
                                        >
                                            {paymentStatus.value}
                                        </option>

                                    ))
                                }
                            </Input>
                        </Col>

                    </Row>
                    {(selectedMethod > 0) && <Row className="mt-2">
                        <Col>
                            <Label className="p-0">
                                Other Payment Mode Detail <span>*</span>
                            </Label>
                            <Input
                                type="textarea"
                                name="payment_comment"
                                placeholder="Other Payment Mode"
                                onChange={(e) => setOtherPaymentMethod(e.target.value)}
                                required
                            ></Input>

                        </Col>
                    </Row>}
                </ModalBody>
                <ModalFooter className="refund-modal">
                    <div className='submit-close modalFooter'>
                        {isLoading ? <ButtonSpinner /> : <Button
                            size="sm"
                            color="primary"
                            type="submit"
                        >
                            {"Submit"}
                        </Button>}
                        <Button
                            disabled={isLoading}
                            size="sm"
                            color="danger"
                            onClick={togglePaymentTypeModel}
                        >
                            Close
                        </Button>
                    </div>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    loading: state.order.loading
});

export default connect(mapStateToProps, {
    changePaymentStatus
})(PaymentTypeModel);