import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from 'actions/auth';
import logo from '../../../assets/cssImages/logo.svg';

import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
} from 'reactstrap';

import {
  //CAsideToggler,
  CNavbarBrand,
  CSidebarToggler,
} from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import ChangePassword from '../Settings/ChangePassword';
const DefaultHeader = ({ auth: { user }, logout }) => {
  let navigate = useNavigate();
  const onLogoutClick = (e) => {
    logout(navigate);
  };
  const firstName = user.first_name ?? '';
  const lastName = user.last_name ?? '';
  //################ toggle change password modal ############################
  const [changePasswordModal, setChangePasswordModalModal] = useState(false);
  const togglePasswordModal = () => {
    setChangePasswordModalModal(!changePasswordModal);
  };

  return (
    <Fragment>
      <CSidebarToggler className="d-lg-none" display="md" />
      <CNavbarBrand>
        <Link to="/admin/">
          <img
            // src="https://kinetixx180422.s3.us-east-2.amazonaws.com/assets/img/brand/logo.svg"
            // src="src/assets/cssImages/logo.svg"
            src={logo}
            height="35"
            width="220"
          />
        </Link>
        {/* <FaBars onClick={handleToggle} />
        {toggle && <Sidebar close={() => setToggle(false)} />} */}
      </CNavbarBrand>
      <Nav className="ml-auto" navbar>
        <UncontrolledDropdown nav direction="down">
          <DropdownToggle nav>
            <div style={{ display: 'flex', margin: '0px' }}>
              <h3
                style={{
                  margin: '0px 10px 0px 0px',
                  fontSize: '24px',
                  color: '#6d2a5f',
                }}
              >
                {(firstName + ' ' + lastName).toUpperCase()}
              </h3>
              <img
                src={
                  //process.env.REACT_C_AWS_STORAGE_URL +
                  'https://kinetixx180422.s3.us-east-2.amazonaws.com/assets/img/avatars/user.png'
                }
                width="30"
                height="30"
                className="img-avatar"
                alt={user && user.first_name}
              />
            </div>
          </DropdownToggle>
          <DropdownMenu end>
            {/* <DropdownItem>
            <Link to="/admin/profile" className="addNewElementClass">
              <i className="fa fa-user"></i> Profile
            </Link>
          </DropdownItem> */}
            <DropdownItem onClick={(e) => onLogoutClick(e)}>
              <i className="fa fa-lock"></i> Logout
            </DropdownItem>
            <DropdownItem onClick={togglePasswordModal}>
              <i className="fa fa-key"></i> Change Password
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <ChangePassword
          isOpen={changePasswordModal}
          toggle={togglePasswordModal}
        ></ChangePassword>
      </Nav>
    </Fragment>
  );
};

DefaultHeader.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logout })(DefaultHeader);
