import {
  CMS_LOADING,
  CMS_CREATED,
  CMS_CREATE_ERROR,
  CMS_FAIL,
  CMS_UPDATED,
  CMS_UPDATE_ERROR,
  CMS_DELETED,
  CMS_DELETE_ERROR,
  CMS_LIST_UPDATED,
  GET_CMS_BY_ID,
  CMS_CHANGE_STATUS,
  CMS_CHANGE_STATUS_ERROR,
} from "actions/types";

const initalState = {
  cmsList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentCMS: {},
  loading: false,
  isSubLoading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case CMS_LOADING:
      return {
        ...state,
        cmsList: payload.is_sub_element ? state.cmsList : initalState.cmsList,
        currentCMS: payload.is_sub_element
          ? state.currentCMS
          : initalState.currentCMS,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case CMS_CREATED:
      return {
        ...state,
        loading: false,
      };
    case CMS_CREATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CMS_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false
      };
    case CMS_UPDATED:
      return {
        ...state,
        currentCMS: {},
        loading: false,
      };
    case CMS_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CMS_DELETED:
      return {
        ...state,
        loading: false,
      };
    case CMS_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CMS_LIST_UPDATED:
      return {
        ...state,
        cmsList: {
          data: payload.data.cmsList.data,
          page: payload.data.cmsList.metadata[0].current_page,
          count: payload.data.cmsList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_CMS_BY_ID:
      return {
        ...state,
        currentCMS: payload.data,
        loading: false,
      };
    case CMS_CHANGE_STATUS:
      return {
        ...state,
        cmsList: {
          ...state.cmsList,
          data: state.cmsList.data.map((cms) =>
            cms._id === payload.data._id
              ? { ...cms, status: payload.data.status }
              : cms
          ),
        },
        loading: false,
        isSubLoading: false
      };
    case CMS_CHANGE_STATUS_ERROR:
      return {
        ...state,
        cmsList: {
          ...state.cmsList,
          data: state.cmsList.data.map((cms) =>
            cms._id === payload.data
              ? { ...cms, is_failed: true }
              : { ...cms, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false
      };

    default:
      return state;
  }
}
