/* eslint-disable */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getProductDetail } from 'actions/admin/order';
import Spinner from 'views/Spinner';
import { Modal, ModalHeader, Button, ModalBody } from 'reactstrap';
import Variations from './Variations';

const SelectVariations = ({
  handleVariationSelect,
  isOpen,
  slug,
  variation,
  setVariation,
  userId,
  userType,
  setProductVariationDetails,
  selectedFitting,
  setSelectedFitting,
  quantity,
  setVariationSelectErrorMsg,
  variationSelectErrorMsg,
  selectedFittingsData,
  setQuantity,
  isEdit,
  selectedProduct,
  setSelectedFittingsData,
  setCustomization,
  setStockQuantity,
  stockQuantity,
  isOrderUpdate,
  toggle,
  reset,
  currentProduct,
  productLoading,
  getProductDetail
}) => {
  //########################## use dispatch ##############################

  useEffect(() => {
    if (isOpen && userId)
      getProductDetail(slug, userId);
  }, [slug, isOpen, userId]);


  const handleValidVariation = () => {
    alert('Please select a valid variation.'); return false;
  };

  //########################## set the initial form data ##############################
  return (
    <div className="animated fadeIn">
      <Modal className="VariationsModal" isOpen={isOpen} toggle={toggle} onClosed={reset} backdrop="static" >
        {productLoading ? (
          <Spinner />
        ) : (
          <>
            <ModalHeader toggle={toggle}>
              Select Variations {currentProduct.product_title} ({currentProduct.categoryId && currentProduct.categoryId.name})
            </ModalHeader>
            <ModalBody className="pb-0">
              <Variations
                currentProduct={currentProduct}
                variation={variation}
                setVariation={setVariation}
                selectedFitting={selectedFitting}
                setSelectedFitting={setSelectedFitting}
                setQuantity={setQuantity}
                quantity={quantity}
                userType={userType}
                setProductVariationDetails={setProductVariationDetails}
                setVariationSelectErrorMsg={setVariationSelectErrorMsg}
                fittingData={currentProduct.fittingData}
                selectedFittingsData={selectedFittingsData}
                isEdit={isEdit}
                selectedProduct={selectedProduct}
                setSelectedFittingsData={setSelectedFittingsData}
                setCustomization={setCustomization}
                setStockQuantity={setStockQuantity}
                stockQuantity={stockQuantity}
                isOrderUpdate={isOrderUpdate}
              />
            </ModalBody>
          </>

        )}

        {variationSelectErrorMsg ?
          <>
            <div className="form-group"><span className="variation_popup_error text-danger">{variationSelectErrorMsg}</span></div>
            <Button
              className="mr-05"
              size="sm"
              color="danger"
              onClick={handleValidVariation}
            >
              <i className="fa fa-dot-circle-o"></i>
              Submit
            </Button>
          </>

          :
          <>
            <Button
              className="mr-05"
              size="sm"
              color="primary"
              onClick={handleVariationSelect}
            >
              <i className="fa fa-dot-circle-o"></i> Submit
            </Button>
          </>

        }

      </Modal>
    </div>
  );
};

SelectVariations.defaultProps = {
  isOpen: false,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  productLoading: state.order.productLoading,
  currentProduct: state.order.currentProduct,
});

export default connect(mapStateToProps, {
  getProductDetail,
})(SelectVariations);
