import {
    ORDER_SHIPPING_BOXES_LOADING,
    ORDER_SHIPPING_BOXES,
    ORDER_SHIPPING_BOXES_FAILED,
    EDITED_SHIPPING_BOX_RATES_LOADING,
    EDITED_SHIPPING_BOX_RATES,
    EDITED_SHIPPING_BOX_RATES_FAILED
} from "actions/types";

const initialState = {
    OrderShippingBoxesLoading: false,
    OrderShippingBoxDetails: {}
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ORDER_SHIPPING_BOXES_LOADING:
        case EDITED_SHIPPING_BOX_RATES_LOADING:
            return {
                ...state,
                OrderShippingBoxesLoading: true
            }
        case ORDER_SHIPPING_BOXES:
            return {
                ...state,
                OrderShippingBoxDetails: payload,
                OrderShippingBoxesLoading: false
            }
        case ORDER_SHIPPING_BOXES_FAILED:
        case EDITED_SHIPPING_BOX_RATES_FAILED:
        case EDITED_SHIPPING_BOX_RATES:
            return {
                ...state,
                OrderShippingBoxesLoading: false
            }
        default:
            return state;
    }
}