import axios from 'axios';

import {
  BLOG_LOADING,
  BLOG_CREATED,
  BLOG_CREATE_ERROR,
  BLOG_FAIL,
  BLOG_UPDATED,
  BLOG_UPDATE_ERROR,
  BLOG_DELETED,
  BLOG_DELETE_ERROR,
  BLOG_LIST_UPDATED,
  GET_BLOG_BY_ID,
  BLOG_CHANGE_STATUS,
  BLOG_CHANGE_STATUS_ERROR,
} from 'actions/types';

import { updateFilterData } from 'actions/commonActions';

// Create blog
export const create = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: BLOG_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    };
    let param = new window.FormData();
    param.append('file', formData.selectedFile); //image file
    param.append('slug', formData.slug);
    param.append('blog_title', formData.blog_title);
    param.append('blog_header', formData.blog_header);
    param.append('meta_description', formData.meta_description);
    param.append('description', formData.description);
    param.append('image', formData.image); //object url of image (image src)
    console.log(formData);

    const res = await axios.post('/api/admin/blog/create', param, config);
    if (res.data.status === true) {
      dispatch({
        type: BLOG_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/blogs');
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: BLOG_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: BLOG_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit Blog
export const edit = (formData, history, blog_id) => async (dispatch) => {
  try {
    dispatch({
      type: BLOG_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    };
    let param = new window.FormData();
    param.append('file', formData.selectedFile); // image file
    param.append('slug', formData.slug);
    param.append('blog_title', formData.blog_title);
    param.append('blog_header', formData.blog_header);
    param.append('meta_description', formData.meta_description);
    param.append('description', formData.description);
    param.append('image', formData.image); // object url of image (image src)
    param.append('oldImage', formData.oldImage); // old image name
    param.append('isNewFileSelected', formData.isNewFileSelected);

    const res = await axios.post(`/api/admin/blog/${blog_id}`, param, config);
    if (res.data.status === true) {
      dispatch({
        type: BLOG_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/blogs');
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: BLOG_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: BLOG_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete Blog
export const deleteBlog = (blog_id) => async (dispatch) => {
  try {
    dispatch({
      type: BLOG_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(`/api/admin/blog/${blog_id}`, config);

    if (res.data.status === true) {
      dispatch({
        type: BLOG_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: BLOG_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: BLOG_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get blog list

export const getBlogList = (blogParams) => async (dispatch) => {
  try {
    dispatch({
      type: BLOG_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    blogParams = updateFilterData(blogParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: blogParams,
    };

    const res = await axios.get(`/api/admin/blog`, config);
    dispatch({
      type: BLOG_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          blogParams: blogParams,
          blogList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    dispatch({
      type: BLOG_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Get blog by id
export const getBlogById = (blog_id) => async (dispatch) => {
  try {
    dispatch({
      type: BLOG_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/blog/${blog_id}`, config);

    await dispatch({
      type: GET_BLOG_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: BLOG_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//change status
export const changeStatus = (blog_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: BLOG_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/blog/change-status/${blog_id}`,
      { status },
      config
    );

    if (res.data.status === true) {
      await dispatch({
        type: BLOG_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: BLOG_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: blog_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: BLOG_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
