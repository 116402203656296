
import {
    Input,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    Table,
    Form,
    FormText,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
} from 'reactstrap';
import Select from 'react-select';
import * as Constants from 'constants/index';
import PerPageSelect from '../PerPageSelect';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import { useEffect, useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getReturnInitiates, getShippingBoxDetails, createReturnInitiator, changeReturnStatus, voidReturnInitiateShippingLabel } from 'actions/admin/order';
import Spinner from 'views/Spinner';
import ButtonSpinner from 'views/ButtonSpinner';
import { Checkbox } from 'react-bootstrap';
import Moment from 'react-moment';
import RefundModel from './Models/RefundModel';
import { BsChevronBarLeft, BsChevronBarRight, BsChevronLeft, BsChevronRight } from "react-icons/bs";

const RenderActions = (params) => {
    let resendPrintLabels = false;
    let markAsReturned = false;
    let refund = false;
    let cancel = false;

    let status = params?.return_status == 9 ? params?.return_print_ship_status : params?.return_status
    switch (status) {
        case 0:
            resendPrintLabels = true;
            markAsReturned = true;
            refund = true;
            cancel = true;
            break;
        case 1:
            resendPrintLabels = false;
            markAsReturned = false;
            refund = true;
            cancel = true;
            break;
        case 10:
            resendPrintLabels = true;
            markAsReturned = true;
            refund = true;
            cancel = true;
            break;
        case 11:
            resendPrintLabels = true;
            markAsReturned = true;
            refund = true;
            cancel = true;
            break;
        default:
    }
    const onRefund = () => {
        params.setRefunData(params)
        params.setIsRefundModal(true);
    }

    return (<>
        {(resendPrintLabels && params?.return_status == 9 && params?.shipping_paid_by === "kinetixx") && ((params.loader && params.indexId === params.rowIndex) ? (<div className="action "><ButtonSpinner /></div>) : <Button data-id={params.rowIndex} type="button" size="sm" color="info" title="Resend Ship Label" onClick={() => params.changeStatus(params?._id, 1, params.rowIndex)}>
            <small> Resend Ship Label</small>
        </Button>)}
        {(!resendPrintLabels && params?.return_status == 9 && params?.shipping_paid_by === "kinetixx") && ((params.loader && params.indexId === params.rowIndex) ? (<div className="action "><ButtonSpinner /></div>) : <Button type="button" size="sm" color="info" title="Void Ship Label" onClick={() => params.changeStatus(params?._id, 0, params.rowIndex)}>
            <small> Void Ship Label</small>
        </Button>)}
        {(!resendPrintLabels && params?.return_status == 9 && params?.shipping_paid_by === "kinetixx" && params?.label_url !== undefined) && ((params.loader && params.indexId === params.rowIndex) ? (<div className="action "><ButtonSpinner /></div>) : (<a target="_blank" href={params?.label_url[0] ?? ""}>
            <Button
                type="button"
                size="sm"
                color="info"
                title="Download Ship Label"
            >
                <small>Download Ship Label</small>

            </Button>
        </a>))}
        {((params?.return_print_ship_status === 1 && params?.return_status == 9) || (params?.shipping_paid_by !== "kinetixx" && ![4, 11, 10].includes(params?.return_status))) && <Button type="button" size="sm" color="info" title="Mark As Returned" onClick={() => params.changeStatus(params?._id, 10)}>
            <small>Mark As Returned</small>
        </Button>}
        {(params?.return_status == 10) && <Button type="button" size="sm" color="info" title="Refund" onClick={() => onRefund()}>
            <small>Refund</small>
        </Button>}
        <Button disabled={[4, 11, 10].includes(params?.return_status) ? true : false} type="button" size="sm" color="info" title="Cancel The Return Request" onClick={() => params.changeStatus(params?._id, 4)}>
            <small>Cancel</small>
        </Button>
    </>)
}

const ReturnInitiate = ({ getReturnInitiates, getShippingBoxDetails, createReturnInitiator, shippingBoxList, loading, changeReturnStatus, voidReturnInitiateShippingLabel, returnInitiateList }) => {
    const history = useNavigate();
    const location = useLocation();
    const { orderId } = useParams();

    const gridRef = useRef();
    const [gridApi, setGridApi] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [returnProducts, setReturnProducts] = useState([]);
    const [isRefundModal, setIsRefundModal] = useState(false);
    const [refunData, setRefunData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [indexId, setIndexId] = useState(null);
    const [isopen, setIsopen] = useState(false);
    const [shippingPaidOption, setShippingPaidOption] = useState("");
    const [shippingServiceCategory, setShippingServiceCategory] = useState(1);
    const [returnInitiateData, setReturnInitiateData] = useState([]);

    const [sortingParams, setSortingParams] = useState({
        limit: Constants.DEFAULT_PAGE_SIZE,
        page: returnInitiateList?.page ?? 1,
        totalRecord: 0,
        totalPage: 0,
        orderBy: 'date_of_return',
        ascending: 'desc',
        query: '',
        filter: {},
    });

    const alertToggle = () => {
        setIsopen(!isopen)
    }

    const changeStatus = useCallback(async (returnInitiateId, status, index = null) => {
        if (index !== null) {
            setIndexId(index)
            setLoader(true)
        }
        if (status === 0) {
            const confirmed = window.confirm('Are you sure you want to void the ship label?');
            if (confirmed) {
                const response = await voidReturnInitiateShippingLabel(returnInitiateId);
                if (response.status === true) {
                    getReturnInitiates(orderId, sortingParams);
                    if (index !== null) {
                        setIndexId(null);
                        setLoader(false)
                    }
                }
                setLoader(false)
            }
        } else {
            changeReturnStatus({ returnInitiateId, status }).then(async (res) => {
                if (res.status === true) {
                    getReturnInitiates(orderId, sortingParams);
                }
                if (index !== null) {
                    setIndexId(null);
                    setLoader(false)
                }
            });
        }
    });

    const onChangeShippingPaidBy = useCallback((event) => {
        setShippingPaidOption(event.target.value);
    });

    const productTitle = (params, proItem) => {
        if (params?.products_category.length > 0) {
            const categoryName = params?.products_category?.find((item) => item.product_id === proItem.product_id)?.categoryName;
            return `${proItem.product_title} - ${categoryName}`
        }
        return "-";

    }
    const changePage = (pageName, jumpStartEnd = 0) => {
        //Math.ceil(5.1)
        let pageValue = jumpStartEnd;
        if (jumpStartEnd === 0) {
            pageValue = pageName === "forward" ? Number(sortingParams.page) + 1 : Number(sortingParams.page) - 1;
        }
        setSortingParams({ ...sortingParams, page: pageValue })
        getReturnInitiates(orderId, { ...sortingParams, page: pageValue });

    }

    useEffect(() => {
        getReturnInitiates(orderId, sortingParams);
        //setReturnInitiateData(customData.data.response.data)
    }, [orderId])

    useEffect(() => {
        if (returnInitiateList.data) {
            setSortingParams({
                ...sortingParams,
                page: returnInitiateList.page,
                totalRecord: returnInitiateList.totalRecord,
                totalPage: Math.ceil(Number(returnInitiateList.totalRecord) / Number(sortingParams.limit))
            })
            setReturnInitiateData(returnInitiateList.data)
        }
    }, [returnInitiateList])

    useEffect(() => {
        if (isOpen === true) {
            getShippingBoxDetails(orderId/* , "648036b54d4e8c88ccddfcd4" */);
        }
    }, [isOpen])

    useEffect(() => {
        if (shippingBoxList.length > 0) {

            const boxData = shippingBoxList.map(shippingBox => {

                setShippingServiceCategory(shippingBox.shippingServiceCategory)

                return {
                    ...shippingBox,
                    products: shippingBox.items.filter((i) => !["Build Shop Supply Product", "Services", "services", "service"].includes(i.category_name))
                }

            })
            setSelectedProducts(boxData)
            setReturnProducts(boxData)
        }

    }, [shippingBoxList]);

    let delayTimer;

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    const changePageLimit = useCallback((data) => {
        setSortingParams({ ...sortingParams, limit: data.value })
        getReturnInitiates(orderId, { ...sortingParams, limit: data.value });
    }, [sortingParams])

    const onChange = (event, box, boxIndex, ItemIndex, item) => {
        if (event.target.name === "quantity") {
            const initiate_detail = box.items.find(proItem => item.product_variation_sku === proItem.product_variation_sku && proItem?.fitting_options.length === item.fitting_options.length && item._id === proItem._id)?.quantity

            let quantity = initiate_detail >= event.target.value ? event.target.value : initiate_detail
            let index = box.items.findIndex((o) => item.product_variation_sku === o.product_variation_sku && o?.fitting_options.length === item.fitting_options.length && item._id === o._id)
            selectedProducts[boxIndex].items[index] = {
                ...selectedProducts[boxIndex].items[index],
                selected_quantity: quantity,
                isInitiated: quantity > 0 ? true : false
            }
            setSelectedProducts([...selectedProducts])
        } else {
            selectedProducts[boxIndex].items[ItemIndex] = {
                ...selectedProducts[boxIndex].items[ItemIndex],
                isInitiated: event.target.checked
            }
            setSelectedProducts([...selectedProducts])
        }
    };

    const submitForm = (event) => {
        event.preventDefault();
        let isSelectedValue = false;
        setIsopen(false);
        selectedProducts.filter(r => {
            r.items.map(p => {
                if (p.selected_quantity > 0) {
                    isSelectedValue = true;
                }
            })
        })
        if (isSelectedValue === false) {
            setIsopen(true);
            return false;
        }

        createReturnInitiator({
            returnProducts: selectedProducts,
            shippingPaidOption
        }).then((res) => {
            if (res.status === true) {
                toggle();
                getReturnInitiates(orderId, sortingParams);
            }
        })
    }

    const toggleRefundModal = (isReloadData = false) => {
        setIsRefundModal(!isRefundModal);
        if (isReloadData === true) {
            getReturnInitiates(orderId, sortingParams);
        }
    };

    const getQuantity = (items, proItem, isSelected = 1) => {

        let selectedQuantity = items.find(item => item.product_variation_sku === proItem.product_variation_sku && item.fitting_options.length === proItem.fitting_options.length && item._id === proItem._id);
        if (isSelected === 1) {
            return selectedQuantity?.quantity ?? 0;
        } else {
            return selectedQuantity?.selected_quantity ?? 0;
        }
    }


    const productType = useCallback((type, fitting_options) => {
        if (type === "OTHER") {
            return "Gear"
        }
        return fitting_options.length > 0 ? "Customize Shaft" : "Raw Shaft"
    })

    return (
        <>
            {loading ? <Spinner /> : <div className="animated fadeIn userTableList">
                <Row>
                    <Col>
                        <Card>
                            <CardHeader style={{ display: "block" }} tag="h5">Return List</CardHeader>
                            <CardBody>
                                <div className="tabletopsec">
                                    <div className="">
                                        <Link to="/admin/orders" >
                                            <Button
                                                color="info"
                                                size="sm"
                                            >

                                                <i class="fa fa-arrow-left" ></i> Back

                                            </Button>
                                        </Link>
                                        {(orderId !== undefined) && <Button
                                            color="primary"
                                            size="sm"
                                            onClick={toggle}
                                        >
                                            <i className="fa fa-undo"></i> Initiate New Return
                                        </Button>}
                                    </div>
                                    {(Number(sortingParams.totalPage) > 1) && <div className="rightpaneltop d-flex gap-2 order-per-Pages" >
                                        <Label htmlFor="user_specific mt-5" >{"Returns Per Page "}</Label>

                                        <Select

                                            id="user_specific"
                                            name="user_specific"
                                            options={[
                                                { value: 20, label: '20' },
                                                { value: 50, label: '50' },
                                                { value: 100, label: '100' },
                                                { value: 500, label: '500' }
                                            ]}
                                            onChange={(e) => changePageLimit(e)}
                                            value={[{ value: sortingParams.limit, label: (sortingParams.limit).toString() }]}
                                            className="per-page-select"
                                        />
                                    </div>}
                                </div>
                                <div className="card w-100 p-0 border-0">
                                    <Table responsive className='shippingboxestable' border="1" style={{ minWidth: '800px' }}>
                                        <thead>
                                            <tr>
                                                {(orderId !== undefined) && <th rowSpan="2">Box Name</th>}
                                                <th rowSpan="2">RMA ID</th>
                                                <th rowSpan="2">Date Of Return</th>
                                                <th colSpan="3" className="shippingproductdetails">Product Items</th>
                                                <th rowSpan="2">Status</th>
                                                <th rowSpan="2">Shipping Paid By</th>
                                                <th rowSpan="2">Action</th>
                                            </tr>
                                            <tr>
                                                <th>Product</th>
                                                <th>SKU</th>
                                                <th>Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                returnInitiateData.map((items, ind) => (
                                                    items.product_item.map((value, index) => (<>
                                                        {(index === 0) && <tr key={items.date_of_return}>
                                                            {(orderId !== undefined) && <td rowSpan={items.product_item?.length > 0 ? `${items.product_item?.length}` : "1"}>{items.box_name}</td>}
                                                            <td rowSpan={items.product_item?.length > 0 ? `${items.product_item?.length}` : "1"}>{items?.rmaId ?? ""}</td>
                                                            <td rowSpan={items.product_item?.length > 0 ? `${items.product_item?.length}` : "1"}>
                                                                <Moment
                                                                    format={
                                                                        process.env.REACT_APP_DISPLAY_DATE_FORMATE ?? 'MM/DD/YYYY'
                                                                    }
                                                                    locale="de"
                                                                >
                                                                    {items.date_of_return}
                                                                </Moment>
                                                                <span>
                                                                    <Moment format="HH:mm" locale="de">{items.date_of_return}</Moment>
                                                                </span>
                                                            </td>
                                                            <td >{productTitle(items, value)}</td>
                                                            <td >{value.product_sku}</td>
                                                            <td >{value.product_quantity}</td>
                                                            <td rowSpan={items.product_item?.length > 0 ? `${items.product_item?.length}` : "1"}>{Constants.VIEW_ORDER_LIST_ORDER_STATUS[items.return_status]}</td>
                                                            <td rowSpan={items.product_item?.length > 0 ? `${items.product_item?.length}` : "1"}>{(items?.shipping_paid_by === "kinetixx") ? "Kinetixx" : "Customer"}</td>
                                                            <td rowSpan={items.product_item?.length > 0 ? `${items.product_item?.length}` : "1"}>{
                                                                <RenderActions
                                                                    {...items}
                                                                    rowIndex={ind}
                                                                    setIsRefundModal={setIsRefundModal}
                                                                    isRefundModal={isRefundModal}
                                                                    toggleRefundModal={toggleRefundModal}
                                                                    changeStatus={(id, status, index) => changeStatus(id, status, index)}
                                                                    setRefunData={setRefunData}
                                                                    loader={loader}
                                                                    indexId={indexId}
                                                                />
                                                            }</td>
                                                        </tr>}
                                                        {(index > 0) && <tr key={items.date_of_return}>
                                                            <td >{productTitle(items, value)}</td>
                                                            <td >{value.product_sku}</td>
                                                            <td >{value.product_quantity}</td>
                                                        </tr>}
                                                    </>
                                                    ))
                                                ))
                                            }
                                            {returnInitiateData.length === 0 && <tr>
                                                <td colSpan={9} style={{ textAlign: "center", fontWeight: "700" }}><span >{"No Record Found."}</span></td>
                                            </tr>}
                                        </tbody>
                                    </Table>
                                    {(Number(sortingParams.totalPage) > 1) && <Col sm="12" className='text-left' style={{
                                        display: "flex",
                                        alignItems: "right",
                                        justifyContent: "right",
                                        flex: 1,
                                        height: "100%",
                                    }}>
                                        <div style={{ marginTop: "7px" }}>
                                            <span style={{ margin: "0px 10px" }}>
                                                <span style={{ fontWeight: 700, marginRight: '4px' }}>{(Number(sortingParams?.page) - 1) * Number(sortingParams?.limit) + 1}</span>
                                                <span style={{ marginRight: '4px' }}>to</span>
                                                <span style={{ fontWeight: 700, marginRight: '4px' }}>{Math.min(Number(sortingParams?.page) * Number(sortingParams?.limit), sortingParams?.totalRecord)}</span>
                                                <span style={{ marginRight: '4px' }} >of</span>
                                                <span style={{ fontWeight: 700 }}>{sortingParams?.totalRecord}</span>
                                            </span>
                                        </div>
                                        <Button active={Number(sortingParams.page) === 1} color="link" disabled={Number(sortingParams.page) === 1} onClick={() => changePage("back", 1)}>
                                            <BsChevronBarLeft />
                                        </Button>
                                        <Button active={Number(sortingParams.page) === 1} disabled={Number(sortingParams.page) === 1} color="link" onClick={() => changePage("back")}>
                                            <BsChevronLeft />
                                        </Button>
                                        <span className='mt-1'>{`Page ${sortingParams.page} of ${sortingParams.totalPage}`}</span>
                                        <Button active={Number(sortingParams.page) > 1} disabled={Number(sortingParams.page) === Number(sortingParams.totalPage)} color="link" onClick={() => changePage("forward")}>
                                            <BsChevronRight />
                                        </Button>
                                        <Button active={Number(sortingParams.page) > 1} color="link" disabled={Number(sortingParams.page) === Number(sortingParams.totalPage)} onClick={() => changePage("back", sortingParams.totalPage)}>
                                            <BsChevronBarRight />
                                        </Button>
                                    </Col>}
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {
                    isRefundModal === true && <RefundModel
                        isopen={isRefundModal}
                        setIsRefundModal={setIsRefundModal}
                        toggleRefundModal={toggleRefundModal}
                        refundFormData={{
                            ...refunData,
                            grand_total: Number(refunData?.order?.grand_total?.$numberDecimal),
                            order_id: refunData?.order?.order_id,
                            previous_order_status: 10,
                            new_order_status: 11
                        }}
                        orderTotalAmount={Number(refunData?.order?.grand_total?.$numberDecimal).toFixed(2)}
                        isReturnInitiate={true}
                    />
                }



                <Modal size="lg" isOpen={isOpen}>
                    <Form onSubmit={submitForm} className="form-horizontal">
                        <ModalHeader toggle={toggle} >Initiate Return</ModalHeader>
                        {loading ? <Spinner /> : (<ModalBody className="initiate_return">
                            <Alert color="danger" isOpen={isopen} toggle={alertToggle}>
                                You have not entered any product quantity, Please enter the quantity.
                            </Alert>
                            {selectedProducts.length > 0 && selectedProducts.map((item, boxIndex) => (<Row key={item._id}>
                                <Col sm="12">
                                    <div className='returnModal'>
                                        <div className='returnModal-inner'>
                                            <h5>Box {item.box.name} (KXS-{item?.boxId})</h5>
                                            <Row className='repet-box'>
                                                <Table borderless>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Product
                                                            </th>
                                                            <th>
                                                                Product Type
                                                            </th>
                                                            <th>
                                                                SKU
                                                            </th>
                                                            <th>
                                                                Available Quantity
                                                            </th>
                                                            <th>
                                                                Return Quantity
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.products.length > 0 && item.products.map((proItem, itemIndex) => (
                                                            <tr key={proItem._id}>
                                                                <td >
                                                                    {proItem.product_title + " - " + proItem.category_name}
                                                                </td>
                                                                <td >
                                                                    {productType(proItem.categoryType, proItem.fitting_options)}
                                                                </td>
                                                                <td>
                                                                    {proItem.product_variation_sku}
                                                                </td>
                                                                <td>
                                                                    {getQuantity(selectedProducts[boxIndex]?.items, proItem)}
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        disabled={(getQuantity(selectedProducts[boxIndex]?.items, proItem) === 0) ? true : false}
                                                                        type='number'
                                                                        min={0}
                                                                        max={getQuantity(selectedProducts[boxIndex]?.items, proItem)}
                                                                        title='Quantity'
                                                                        value={getQuantity(selectedProducts[boxIndex]?.items, proItem, 0)}
                                                                        data-id={proItem.quantity}
                                                                        name={"quantity"}
                                                                        placeholder='quantity'
                                                                        className=''
                                                                        style={{ width: "45%" }}
                                                                        onChange={(event) => onChange(event, item, boxIndex, itemIndex, proItem)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>

                            </Row>))}
                            {selectedProducts.length > 0 && <Row>
                                <Col sm="12">
                                    <Label>{"Shipping Paid By"}</Label>
                                    <Input
                                        type="select"
                                        name="shipping_paid_by"
                                        onChange={(event) => onChangeShippingPaidBy(event)}
                                        required
                                    //value={params.data?.shipping_paid_by}
                                    >
                                        <option value={""} >Select</option>
                                        {(![2, 4].includes(shippingServiceCategory)) && <option value={"kinetixx"} >Kinetixx</option>}
                                        <option value={"customer"} >Customer</option>
                                    </Input>
                                </Col>
                            </Row>}
                            {(selectedProducts.length === 0 || shippingBoxList?.order_type === "service_order") && <Row style={{ textAlign: "center" }}>
                                <Col>
                                    {shippingBoxList?.order_type !== "service_order" ? <Label>No initiate return record found. Please wait for the shipping box to be delivered.</Label> : <Label style={{ color: "red" }}>{"Refunds are not allowed for service orders"}.</Label>}
                                </Col>
                            </Row>}
                        </ModalBody>)}
                        <ModalFooter>
                            {selectedProducts.length > 0 && <Button
                                size="sm"
                                color="primary"
                                type="submit"
                                disabled={loading}
                            >
                                Submit
                            </Button>}
                            <Button
                                size="sm"
                                color="danger"
                                onClick={toggle}
                                disabled={loading}
                            >
                                Close
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </div >}
        </>
    );
}
ReturnInitiate.propTypes = {
    returnInitiateList: PropTypes.func.isRequired,
    voidReturnInitiateShippingLabel: PropTypes.func.isRequired,
    shippingBoxList: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
    returnInitiateList: state.order.returnInitiateList,
    shippingBoxList: state.order.shippingBoxList,
    loading: state.order.loading,
});

export default connect(mapStateToProps, {
    getReturnInitiates,
    getShippingBoxDetails,
    createReturnInitiator,
    changeReturnStatus,
    voidReturnInitiateShippingLabel
})(ReturnInitiate);
//export default ReturnInitiate;