import { SET_PREVIOUS_PATH, SET_GOOGLE_ADDRESS_FORM_DATA } from './types';

export const setPreviousPath = (path) => async (dispatch) => {
  await dispatch({
    type: SET_PREVIOUS_PATH,
    payload: path,
  });
};

export const setGoogleAddressFormData = (formData) => async (dispatch) => {
  await dispatch({
    type: SET_GOOGLE_ADDRESS_FORM_DATA,
    payload: formData,
  });
};

export const updateFilterData = (params) => {
  Object.keys(params.filter).forEach((key) => {
    if (params.filter[key].filterType === 'date') {
      if (params.filter[key].dateFrom) {
        params.filter[key].dateFrom = new Date(
          params.filter[key].dateFrom
        ).toString();
      }
      if (params.filter[key].dateTo) {
        params.filter[key].dateTo = new Date(
          new Date(params.filter[key].dateTo).setHours(23, 59, 59, 999)
        ).toString();
      }
    }
  });
  return params;
};


export const convertServiceTypes = (inputString) => {
  if (inputString?.includes("_")) {
    return inputString
  }
  // Use a regular expression to insert spaces before capital letters
  const spacedString = inputString?.replace(/([A-Z])/g, ' $1').trim();
  // Separate "AM" from other words by inserting a space before and after "AM"
  let finalString = spacedString?.replace(/\bA M\b/g, 'AM');
  finalString = finalString?.replace(/\bU P S\b/g, 'UPS');
  return finalString;
}

export const convertCarrierTypes = (inputString) => {
  if (inputString === "UPSDAP") {
    return "UPS"
  }
  if (inputString === "FedExDefault") {
    return "FedEx Default"
  }
  if (inputString === "DHLExpress") {
    return "DHL Express"
  }
  if (inputString === "freight_shipment") {
    return "Freight Shipment"
  }
  return inputString
}