import {
  COUPON_LOADING,
  COUPON_CREATED,
  COUPON_CREATE_ERROR,
  COUPON_FAIL,
  COUPON_DELETED,
  COUPON_DELETE_ERROR,
  COUPON_LIST_UPDATED,
  GET_COUPON_BY_ID,
  COUPON_UPDATED,
  COUPON_UPDATE_ERROR,
  COUPON_CHANGE_STATUS,
  COUPON_CHANGE_STATUS_ERROR,
  COUPON_USER_SPECIFIC_LIST_LOADING,
  COUPON_USER_SPECIFIC_LIST_UPDATED,
  COUPON_CATEGORIES_OPTIONS_LIST_LOADING,
  COUPON_CATEGORIES_OPTIONS_LIST_UPDATED,
  COUPON_PRODUCTS_OPTIONS_LIST_LOADING,
  COUPON_PRODUCTS_OPTIONS_LIST_UPDATED,
  COUPON_SKU_OPTIONS_LIST_LOADING,
  COUPON_SKU_OPTIONS_LIST_UPDATED,
  COUPON_FITTING_ACCESSORIES_OPTIONS_LIST_LOADING,
  COUPON_FITTING_ACCESSORIESS_OPTIONS_LIST_UPDATED,
  GET_COUPON_BY_ID_ERROR
} from "actions/types";

const initalState = {
  couponList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentCoupon: {},
  loading: false,
  isSubLoading: false,
  userSpecificOptionsList: [], //[{ value: '', label: '' }],
  userSpecificOptionsListLoading: false,
  applicableCategoriesOptionsList: [],
  applicableCategoriesOptionsListLoading: false,
  applicableProductsOptionsList: [],
  applicableProductsOptionsListLoading: false,
  applicableSkuOptionsList: [],
  applicableSkuOptionsListLoading: false,
  applicableFittingAccessoriesOptionsList: [],
  applicableFittingAccessoriesOptionsListLoading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case COUPON_LOADING:
      return {
        ...state,
        couponList: payload.is_sub_element
          ? state.couponList
          : initalState.couponList,
        currentCoupon: payload.is_sub_element
          ? state.currentCoupon
          : initalState.currentCoupon,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case COUPON_CREATED:
      return {
        ...state,
        loading: false,
      };
    case COUPON_CREATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case COUPON_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
        userSpecificOptionsListLoading: false,
      };
    case COUPON_DELETED:
      return {
        ...state,
        loading: false,
      };
    case COUPON_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case COUPON_LIST_UPDATED:
      return {
        ...state,
        couponList: {
          data: payload.data.couponList.data,
          page: payload.data.couponList.metadata[0].current_page,
          count: payload.data.couponList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_COUPON_BY_ID:
      return {
        ...state,
        currentCoupon: payload.data,
        loading: false,
      };
    case GET_COUPON_BY_ID_ERROR:
      return {
        ...state,
        currentCoupon: {},
        loading: false
      }
    case COUPON_UPDATED:
      return {
        ...state,
        currentCoupon: {},
        loading: false,
      };
    case COUPON_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case COUPON_CHANGE_STATUS:
      return {
        ...state,
        couponList: {
          ...state.couponList,
          data: state.couponList.data.map((coupon) =>
            coupon._id === payload.data._id
              ? { ...coupon, status: payload.data.status }
              : coupon
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case COUPON_CHANGE_STATUS_ERROR:
      return {
        ...state,
        couponList: {
          ...state.couponList,
          data: state.couponList.data.map((coupon) =>
            coupon._id === payload.data
              ? { ...coupon, is_failed: true }
              : { ...coupon, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case COUPON_USER_SPECIFIC_LIST_LOADING:
      return {
        ...state,
        userSpecificOptionsListLoading: true,
      };
    case COUPON_USER_SPECIFIC_LIST_UPDATED:
      return {
        ...state,
        userSpecificOptionsList: payload.data,
        userSpecificOptionsListLoading: false,
      };
    case COUPON_CATEGORIES_OPTIONS_LIST_LOADING:
      return {
        ...state,
        applicableCategoriesOptionsListLoading: true,
      };
    case COUPON_CATEGORIES_OPTIONS_LIST_UPDATED:
      return {
        ...state,
        applicableCategoriesOptionsList: payload.data,
        applicableProductsOptionsList: [],
        applicableSkuOptionsList: [],
        applicableFittingAccessoriesOptionsList: [],
        applicableCategoriesOptionsListLoading: false,
      };
    case COUPON_PRODUCTS_OPTIONS_LIST_LOADING:
      return {
        ...state,
        applicableCategoriesOptionsListLoading: true,
      };
    case COUPON_PRODUCTS_OPTIONS_LIST_UPDATED:
      return {
        ...state,
        applicableProductsOptionsList: payload.data,
        applicableCategoriesOptionsList: [],
        applicableSkuOptionsList: [],
        applicableFittingAccessoriesOptionsList: [],
        applicableCategoriesOptionsListLoading: false,
      };
    case COUPON_SKU_OPTIONS_LIST_LOADING:
      return {
        ...state,
        applicableSkuOptionsListLoading: true,
      };
    case COUPON_SKU_OPTIONS_LIST_UPDATED:
      return {
        ...state,
        applicableSkuOptionsList: payload.data,
        applicableCategoriesOptionsList: [],
        applicableFittingAccessoriesOptionsList: [],
        applicableProductsOptionsList: [],
        applicableSkuOptionsListLoading: false,
      };
    case COUPON_FITTING_ACCESSORIES_OPTIONS_LIST_LOADING:
      return {
        ...state,
        applicableFittingAccessoriesOptionsListLoading: true,
      };
    case COUPON_FITTING_ACCESSORIESS_OPTIONS_LIST_UPDATED:
      return {
        ...state,
        applicableFittingAccessoriesOptionsList: payload.data,
        applicableCategoriesOptionsList: [],
        applicableSkuOptionsList: [],
        applicableProductsOptionsList: [],
        applicableFittingAccessoriesOptionsListLoading: false,
      };

    default:
      return state;
  }
}
