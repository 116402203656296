/* eslint-disable */

import axios from 'axios';

import {
  COUPON_LOADING,
  COUPON_CREATED,
  COUPON_CREATE_ERROR,
  COUPON_FAIL,
  COUPON_DELETED,
  COUPON_DELETE_ERROR,
  COUPON_LIST_UPDATED,
  GET_COUPON_BY_ID,
  COUPON_UPDATED,
  COUPON_UPDATE_ERROR,
  COUPON_CHANGE_STATUS,
  COUPON_CHANGE_STATUS_ERROR,
  COUPON_USER_SPECIFIC_LIST_LOADING,
  COUPON_USER_SPECIFIC_LIST_UPDATED,
  COUPON_CATEGORIES_OPTIONS_LIST_LOADING,
  COUPON_CATEGORIES_OPTIONS_LIST_UPDATED,
  COUPON_PRODUCTS_OPTIONS_LIST_LOADING,
  COUPON_PRODUCTS_OPTIONS_LIST_UPDATED,
  COUPON_SKU_OPTIONS_LIST_LOADING,
  COUPON_SKU_OPTIONS_LIST_UPDATED,
  COUPON_FITTING_ACCESSORIES_OPTIONS_LIST_LOADING,
  COUPON_FITTING_ACCESSORIESS_OPTIONS_LIST_UPDATED,
  GET_COUPON_BY_ID_ERROR,
  SET_ERRORS_LIST
} from 'actions/types';

import { updateFilterData } from 'actions/commonActions';
import { removeErrors } from './removeErrors';

// #################### Add coupon ######################
export const add = (formData, navigate) => async (dispatch) => {
  try {
    dispatch({
      type: COUPON_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post('/api/admin/coupon/add', formData, config);
    if (res.data.status === true) {
      dispatch({
        type: COUPON_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      navigate('/admin/coupons');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg }
        });
      }
      dispatch({
        type: COUPON_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList }
      })
    }
  } catch (err) {
    dispatch({
      type: COUPON_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// ##################### Delete coupon ##################
export const deleteCoupon = (coupon_id) => async (dispatch) => {
  try {
    dispatch({
      type: COUPON_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(`/api/admin/coupon/${coupon_id}`, config);

    if (res.data.status === true) {
      dispatch({
        type: COUPON_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: COUPON_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: COUPON_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//################# get coupon list ######################

export const getCouponList = (couponParams) => async (dispatch) => {
  try {
    dispatch({
      type: COUPON_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    couponParams = updateFilterData(couponParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: couponParams,
    };

    const res = await axios.get(`/api/admin/coupon/`, config);
    dispatch({
      type: COUPON_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          couponParams: couponParams,
          couponList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    dispatch({
      type: COUPON_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//######################### Get coupon by id #######################
export const getCouponById = (coupon_id, navigate) => async (dispatch) => {
  try {
    dispatch({
      type: COUPON_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    removeErrors();
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/coupon/${coupon_id}`, config);

    if (res.data.status === true) {
      await dispatch({
        type: GET_COUPON_BY_ID,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      dispatch({
        type: GET_COUPON_BY_ID_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: {},
        },
      });
      navigate('/admin/coupons');
    }
  } catch (err) {
    dispatch({
      type: COUPON_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// #################### Edit Coupon ###################
export const edit = (formData, navigate, coupon_id) => async (dispatch) => {
  try {
    dispatch({
      type: COUPON_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/coupon/${coupon_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: COUPON_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      navigate('/admin/coupons');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg }
        });
      }
      dispatch({
        type: COUPON_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList }
      })
    }
  } catch (err) {
    dispatch({
      type: COUPON_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//change status
export const changeStatus = (coupon_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: COUPON_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/coupon/change-status/${coupon_id}`,
      { status },
      config
    );

    if (res.data.status === true) {
      await dispatch({
        type: COUPON_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: COUPON_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: coupon_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: COUPON_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get users list
export const getUserSpecificOptionList =
  (consumer_type) => async (dispatch) => {
    try {
      dispatch({
        type: COUPON_USER_SPECIFIC_LIST_LOADING,
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.get(
        `/api/admin/coupon/user-specific-option-list/${consumer_type}`,
        config
      );
      dispatch({
        type: COUPON_USER_SPECIFIC_LIST_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } catch (err) {
      dispatch({
        type: COUPON_FAIL,
        payload: {
          message: err.response?.data?.message ?? 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

//get categories list
export const getCategoriesOptionList = (type) => async (dispatch) => {
  try {
    dispatch({
      type: COUPON_CATEGORIES_OPTIONS_LIST_LOADING,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: { type },
    };
    const res = await axios.get(
      `/api/admin/coupon/categories/option-list`,
      config
    );
    dispatch({
      type: COUPON_CATEGORIES_OPTIONS_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: COUPON_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get products list
export const getProductsOptionList =
  ({ product_id, type }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: COUPON_PRODUCTS_OPTIONS_LIST_LOADING,
        });
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
          params: { type },
        };
        let res = '';
        if (product_id) {
          res = await axios.get(
            `/api/admin/coupon/products/option-list/${product_id}`,
            config
          );
        } else {
          res = await axios.get(`/api/admin/coupon/products/option-list`, config);
        }

        dispatch({
          type: COUPON_PRODUCTS_OPTIONS_LIST_UPDATED,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
      } catch (err) {
        dispatch({
          type: COUPON_FAIL,
          payload: {
            message: err.response?.data?.message ?? 'Something went wrong.',
            alertType: 'danger',
            error: err,
          },
        });
      }
    };

//get products list
export const getGearProductsOptionList =
  ({ product_id, type }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: COUPON_PRODUCTS_OPTIONS_LIST_LOADING,
        });
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
          params: { type },
        };
        let res = '';
        if (product_id) {
          res = await axios.get(
            `/api/admin/coupon/gear-products/option-list/${product_id}`,
            config
          );
        } else {
          res = await axios.get(`/api/admin/coupon/gear-products/option-list`, config);
        }

        dispatch({
          type: COUPON_PRODUCTS_OPTIONS_LIST_UPDATED,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
      } catch (err) {
        dispatch({
          type: COUPON_FAIL,
          payload: {
            message: err.response?.data?.message ?? 'Something went wrong.',
            alertType: 'danger',
            error: err,
          },
        });
      }
    };

//get sku list
export const getSkuOptionList = (type) => async (dispatch) => {
  try {
    dispatch({
      type: COUPON_SKU_OPTIONS_LIST_LOADING,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: { type },
    };
    const res = await axios.get(
      `/api/admin/coupon/product-variation/sku-option-list`,
      config
    );
    dispatch({
      type: COUPON_SKU_OPTIONS_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: COUPON_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get fitting accessories list
export const getFittingAccessoriesOptionList = () => async (dispatch) => {
  try {
    dispatch({
      type: COUPON_FITTING_ACCESSORIES_OPTIONS_LIST_LOADING,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/coupon/fitting-accessories/option-list`,
      config
    );
    dispatch({
      type: COUPON_FITTING_ACCESSORIESS_OPTIONS_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: COUPON_FAIL,
      payload: {
        message: err.response?.data?.message ?? 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
