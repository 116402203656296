import { Outlet } from 'react-router-dom';

import Dashboard from 'views/Admin/Dashboard';
import CreateUser from 'views/Admin/Users/CreateUser';
import UsersList from 'views/Admin/Users/UsersList';
import EditUser from 'views/Admin/Users/EditUser';
import ViewUserDetails from 'views/Admin/Users/ViewUserDetails';
import UserAddressList from 'views/Admin/UserAddress/AddressList';
import AddUserAddress from 'views/Admin/UserAddress/AddAddress';
import EditUserAddress from 'views/Admin/UserAddress/EditAddress';

import ProductsList from 'views/Admin/Products/ProductsList';
import CreateProduct from 'views/Admin/Products/CreateProduct';
import CreateProductExternal from 'views/Admin/Products/CreateProductExternal';

import EditProduct from 'views/Admin/Products/EditProduct';
import EditProductExternal from 'views/Admin/Products/EditProductExternal';

import CreateEmailTemplate from 'views/Admin/EmailTemplate/CreateEmailTemplate';
import EmailTemplateList from 'views/Admin/EmailTemplate/EmailTemplateList';
import EditEmailTemplate from 'views/Admin/EmailTemplate/EditEmailTemplate';

import EmailTemplateSectionList from 'views/Admin/EmailTemplates/EmailTemplateSections/EmailTemplateSectionList';
import EmailTemplateCategoryList from 'views/Admin/EmailTemplates/EmailTemplateCategories/EmailTemplateCategoryList';
import EmailsUnderCategory from 'views/Admin/EmailTemplates/EmailsUnderCategory';
import CreateEmailTemplateUnderCategory from 'views/Admin/EmailTemplates/CreateEmailTemplate';
import EditEmailTemplateUnderCategory from 'views/Admin/EmailTemplates/EditEmailTemplate';

import CreateCMS from 'views/Admin/CMS/CreateCMS';
import CMSList from 'views/Admin/CMS/CMSList';
import EditCMS from 'views/Admin/CMS/EditCMS';

import AddEditNews from 'views/Admin/News/AddEditNews';
import NewsList from 'views/Admin/News/NewsList';

import AddInquiry from 'views/Admin/Inquiries/AddInquiry';
import InquiriesList from 'views/Admin/Inquiries/InquiriesList';
import EditInquiry from 'views/Admin/Inquiries/EditInquiry';

import AddAttribute from 'views/Admin/Attributes/AddAttribute';
import AttributesList from 'views/Admin/Attributes/AttributesList';
import EditAttribute from 'views/Admin/Attributes/EditAttribute';

import SpecsList from 'views/Admin/Specs/SpecsList';
import EditSpecs from 'views/Admin/Specs/EditSpecs';

import Settings from 'views/Admin/Settings/Settings';

import CategoryList from 'views/Admin/Category/CategoryList';
import EditCategory from 'views/Admin/Category/EditCategory';
import SubCategoryList from 'views/Admin/SubCategory/SubCategoryList';
import EditSubCategory from 'views/Admin/SubCategory/EditSubCategory';

import DealerTypeList from 'views/Admin/DealerType/DealerTypeList';
import CreateDealerType from 'views/Admin/DealerType/CreateDealerType';
import EditDealerType from 'views/Admin/DealerType/EditDealerType';
import CreateDealer from 'views/Admin/Dealers/CreateDealer';
import DealersList from 'views/Admin/Dealers/DealersList';
import EditDealer from 'views/Admin/Dealers/EditDealer';
import ViewDealerDetails from 'views/Admin/Dealers/ViewDealerDetails';
import DealerAddressList from 'views/Admin/DealerAddress/AddressList';
import AddDealerAddress from 'views/Admin/DealerAddress/AddAddress';
import EditDealerAddress from 'views/Admin/DealerAddress/EditAddress';

import EditBlog from 'views/Admin/Blogs/EditBlog';
import CreateBlog from 'views/Admin/Blogs/CreateBlog';
import BlogsList from 'views/Admin/Blogs/BlogsList';

import AddCoupon from 'views/Admin/Coupon/AddCoupon';
import CouponList from 'views/Admin/Coupon/CouponList';
import EditCoupon from 'views/Admin/Coupon/EditCoupon';

import ImageGallery from 'views/Admin/ImageGallery/Gallery';
import ImageUpload from 'views/Admin/ImageGallery/Upload';
import ImageEdit from 'views/Admin/ImageGallery/Edit';

import VideoGallery from 'views/Admin/VideoGallery/Gallery';
import VideoUpload from 'views/Admin/VideoGallery/Upload';
import VideoEdit from 'views/Admin/VideoGallery/Edit';

import ShippingBoxList from 'views/Admin/ShippingBox/ShippingBoxList';
import EditShippingBox from 'views/Admin/ShippingBox/EditShippingBox';

import GearShippingBoxList from 'views/Admin/GearShippingBox/GearShippingBoxList';
import EditGearShippingBox from 'views/Admin/GearShippingBox/EditGearShippingBox';

import OrderList from 'views/Admin/Orders/OrderList';
import ViewOrderDetails from 'views/Admin/Orders/ViewOrderDetails';
import CreateOrder from 'views/Admin/Orders/CreateOrder';
import EditOrder from 'views/Admin/Orders/EditOrder';
import OrderLogs from 'views/Admin/Orders/OrderLogs';

import FittingAttributeList from 'views/Admin/Fitting/Attribute/FittingAttributeList';
import Fittings from 'views/Admin/Fitting/Fittings/FittingList';
import FittingAttributeVariationList from 'views/Admin/Fitting/Variation/VariationList';

import ShippingLabel from 'views/Admin/Orders/ShippingLabel';
import PackingSlipList from 'views/Admin/PackingSlip/PackingSlipList';
import CreatePackingSlip from 'views/Admin/PackingSlip/CreatePackingSlip';
import EditPackingSlip from 'views/Admin/PackingSlip/EditPackingSlip';
import OrderShippingBoxes from 'views/Admin/Orders/OrderShippingBoxes';
import EditOrderShippingBoxes from 'views/Admin/Orders/EditOrderShippingBoxes';

import StaffList from 'views/Admin/Staff/StaffList';
import CreateStaff from 'views/Admin/Staff/CreateStaff';
import EditStaff from 'views/Admin/Staff/EditStaff';

import CreateSmsTemplate from 'views/Admin/SmsTemplate/CreateSmsTemplate';
import SmsTemplateList from 'views/Admin/SmsTemplate/SmsTemplateList';
import EditSmsTemplate from 'views/Admin/SmsTemplate/EditSmsTemplate';

import NotifiesList from 'views/Admin/Notifies/NotifiesList';
import TaxRates from 'views/Admin/Products/TaxRates';
import ReviewList from 'views/Admin/Review/ReviewList';
import GearAttributesList from 'views/Admin/GearProducts/Attributes/GearAttributesList';
import GearCategoryList from 'views/Admin/GearProducts/Category/GearCategoryList';
import AddGearAttribute from 'views/Admin/GearProducts/Attributes/AddGearAttribute';
import EditGearAttribute from 'views/Admin/GearProducts/Attributes/EditGearAttribute';
import EditGearCategory from 'views/Admin/GearProducts/Category/EditGearCategory';
import GearProductsList from 'views/Admin/GearProducts/Products/GearProductsList';
import GearCreateProduct from 'views/Admin/GearProducts/Products/GearCreateProduct';
import GearEditProduct from 'views/Admin/GearProducts/Products/GearEditProduct';
import CreateUpdateOrder from 'views/Admin/Orders/CreateUpdateOrder';
import AddEditHome from 'views/Admin/Home/AddEditHome';
import HomeList from 'views/Admin/Home/HomeList';
import { setupInterceptorsTo } from './ Interceptors';
import axios from 'axios';
import ReturnInitiate from 'views/Admin/Orders/ReturnInitiate';
import ProductSelectorList from 'views/Admin/ProductSelector/ProductSelectorList';
import ViewProductSelector from 'views/Admin/ProductSelector/ViewProductSelector';
import ShippingAdditionals from 'views/Admin/Orders/ShippingAdditionals';
import ShippingCancelations from 'views/Admin/Orders/ShippingCancelations';
import NewsLetterList from 'views/Admin/NewsLetter/NewsLetterList';
import Service from 'views/Admin/Services/Service';
import ServiceList from 'views/Admin/Services/ServiceList';
import BuildShopSupply from 'views/Admin/BuildShopSupply/BuildShopSupply';
import BuildShopSupplyList from 'views/Admin/BuildShopSupply/BuildShopSupplyList';
import OrderPayments from 'views/Admin/Orders/OrderPayments';
import AddEditSelectorQuestion from 'views/Admin/ProductSelector/SelectorQuestions/AddEditSelectorQuestion';
import SelectorQuestionList from 'views/Admin/ProductSelector/SelectorQuestions/SelectorQuestionList';
import CreateUpdateServiceOrder from 'views/Admin/Orders/CreateUpdateServiceOrder';
import ResourcesList from 'views/Admin/Resources/ResourcesList';
import AddEditResources from 'views/Admin/Resources/AddEditResources';
import Announcement from 'views/Admin/Announcements/Announcement';
import AnnouncementsList from 'views/Admin/Announcements/AnnouncementsList';
import FlexCodeNomenclature from 'views/Admin/FlexCodeNomenclature';
import AddEditFlexCode from 'views/Admin/FlexCodeNomenclature/AddEditFlexCode';
import IronNumberNomenclature from 'views/Admin/IronNumberNomenclature';
import AddEditIronNomenclature from 'views/Admin/IronNumberNomenclature/AddEditIronNomenclature';
import FlexCodeEditor from 'views/Admin/FlexCodeNomenclature/FlexCodeEditor';
import IronNomenclatureEditor from 'views/Admin/IronNumberNomenclature/IronNomenclatureEditor';
setupInterceptorsTo(axios)
const AdminRoutes = [
  {
    path: '',
    name: 'Dashboard',
    element: <Dashboard />,
    index: true,
  },
  {
    path: 'staff-members',
    name: 'Staff List',
    element: <StaffList />,
  },
  {
    path: 'customers',
    name: 'Customer List',
    element: <UsersList />,
    index: true,
  },
  {
    path: 'staff-members/create',
    name: 'Create Staff',
    element: <CreateStaff />,
  },
  {
    path: 'customers/create',
    name: 'Create User',
    element: <CreateUser />,
  },
  {
    path: 'customers/details/:user_id',
    name: 'Customer Details',
    element: <ViewUserDetails />,
  },
  {
    path: 'customers/edit/:user_id',
    name: 'Edit Customer',
    element: <EditUser />,
  },
  {
    path: 'staff-member/edit/:staff_id',
    name: 'Edit Staff',
    element: <EditStaff />,
  },
  {
    path: 'customers/customer-address/:customerName/:customer_id',
    name: 'Customer Address',
    element: <UserAddressList />,
  },
  {
    path: 'customer-address/:customerName/add/:customer_id',
    name: 'Add Customer Address',
    element: <AddUserAddress />,
  },
  {
    path: 'customer-address/:customerName/edit/:customer_address_id',
    name: 'Edit Customer Address',
    element: <EditUserAddress />,
  },
  {
    path: 'dealers',
    name: 'Dealers',
    element: <DealersList />,
  },
  {
    path: 'dealers/create',
    name: 'Create Dealer',
    element: <CreateDealer />,
  },

  {
    path: 'dealers/details/:user_id',
    name: 'Dealer Details',
    element: <ViewDealerDetails />,
  },
  {
    path: 'dealers/edit/:user_id',
    name: 'Edit Dealer',
    element: <EditDealer />,
  },
  {
    path: 'dealer-types',
    name: 'Dealer Types',
    element: <DealerTypeList />,
  },
  {
    path: 'dealer-types/create',
    name: 'Create Dealer Type',
    element: <CreateDealerType />,
  },
  {
    path: 'dealer-types/edit/:dealertype_id',
    name: 'Edit Dealer Type',
    element: <EditDealerType />,
  },
  {
    path: 'dealers/:businessName/dealer-address/:dealer_id',
    name: 'Dealer Address',
    element: <DealerAddressList />,
  },
  {
    path: ':businessName/dealer-address/add/:dealer_id',
    name: 'Add Dealer Address',
    element: <AddDealerAddress />,
  },
  {
    path: ':businessName/dealer-address/edit/:dealer_address_id',
    name: 'Edit Dealer Address',
    element: <EditDealerAddress />,
  },
  {
    path: 'product/fittings',
    name: 'Fitting Attributes',
    element: <FittingAttributeList />,
  },
  {
    path: 'product/fittings/:attribute_id',
    name: 'Fittings',
    element: <Fittings />,
  },
  {
    path: 'product/fittings/:fitting_name/:attribute_id/variations',
    name: "Fitting Attribute's Variation",
    element: <FittingAttributeVariationList />,
  },

  {
    path: 'products',
    name: 'Products List',
    element: <ProductsList />,
  },
  {
    path: 'products/create',
    name: 'Create Product',
    element: <CreateProduct />,
  },
  {
    path: 'products/create_external',
    name: 'Create External Product',
    element: <CreateProductExternal />,
  },
  {
    path: 'products/:categoryName/:productName/edit/:product_id',
    name: 'Edit Product',
    exact: true,
    element: <EditProduct />,
  },
  {
    path: 'products/edit_external/:product_id',
    name: 'Edit Product',
    element: <EditProductExternal />,
  },
  {
    path: 'tax-rates',
    name: 'California Tax Rate',
    element: <TaxRates />,
  },
  {
    path: 'email-templates',
    name: 'Email Template List',
    element: <EmailTemplateList />,
  },
  {
    path: 'email-templates/create',
    name: 'Create Email Template',
    element: <CreateEmailTemplate />,
  },
  {
    path: 'email-templates/:emailTemplate_id',
    name: 'Edit Email Template',
    element: <EditEmailTemplate />,
  },
  {
    path: 'email-template-sections',
    name: 'Email Template Section List',
    element: <EmailTemplateSectionList />,
  },
  {
    path: ':sectionId/email-template-categories',
    name: 'Email Template Category List',
    element: <EmailTemplateCategoryList />,
  },
  {
    path: ':sectionId/:emailTemplateCategory_id/emails',
    name: 'Email Templates',
    element: <EmailsUnderCategory />,
  },
  {
    path: ':sectionId/:emailTemplateCategory_id/create',
    name: 'Create Email Template',
    element: <CreateEmailTemplateUnderCategory />,
  },
  {
    path: ':sectionId/:emailTemplateCategory_id/:emailTemplate_id/edit',
    name: 'Edit Email Template',
    element: <EditEmailTemplateUnderCategory />,
  },
  {
    path: 'coupons',
    //exact: true,
    name: 'Shaft Coupon List',
    element: <CouponList />,
  },
  {
    path: 'coupons/add',
    name: 'Add Coupon',
    element: <AddCoupon />,
  },
  {
    path: 'coupons/edit/:coupon_id',
    name: 'Edit Coupon',
    element: <EditCoupon />,
  },
  {
    path: 'home',
    name: 'Home List',
    element: <HomeList />,
  },
  {
    path: 'home/create',
    name: 'Create Home Page',
    element: <AddEditHome />,
  },
  {
    path: 'home/edit/:home_id',
    name: 'Edit Home Page',
    element: <AddEditHome />,
  },
  {
    path: 'cms',
    name: 'CMS List',
    element: <CMSList />,
  },
  {
    path: 'cms/create',
    name: 'Create CMS Page',
    element: <CreateCMS />,
  },
  {
    path: 'cms/edit/:cms_id',
    name: 'Edit CMS Page',
    element: <CreateCMS />,
  },
  {
    path: 'news',
    name: 'News List',
    element: <NewsList />,
  },
  {
    path: 'news/create',
    name: 'Create News',
    element: <AddEditNews />,
  },
  {
    path: 'news/edit/:news_id',
    name: 'Edit News',
    element: <AddEditNews />,
  },
  {
    path: 'newsletter',
    name: 'Newsletter List',
    element: <NewsLetterList />
  },
  {
    path: 'blogs',
    name: 'Blogs List',
    element: <BlogsList />,
  },
  {
    path: 'blogs/create',
    name: 'Create Blog',
    element: <CreateBlog />,
  },
  {
    path: 'blogs/:blog_id',
    name: 'Edit Blog',
    element: <EditBlog />,
  },
  {
    path: 'gallery-image',
    name: 'Image Gallery',
    element: <ImageGallery />,
  },
  {
    path: 'gallery-image/upload',
    name: 'Upload Image',
    element: <ImageUpload />,
  },
  {
    path: 'gallery-image/:image_id',
    name: 'Edit',
    element: <ImageEdit />,
  },
  {
    path: 'gallery-video',
    exact: true,
    name: 'Video Gallery',
    element: <VideoGallery />,
  },
  {
    path: 'gallery-video/upload',
    exact: true,
    name: 'Upload Video',
    element: <VideoUpload />,
  },
  {
    path: 'gallery-video/:video_id',
    name: 'Edit',
    element: <VideoEdit />,
  },
  {
    path: 'inquiries',
    name: 'Inquiries List',
    element: <InquiriesList />,
  },
  {
    path: 'inquiries/add',
    name: 'Add Inquiry',
    element: <AddInquiry />,
  },
  {
    path: 'inquiries/edit/:inquiry_id',
    name: 'Edit Inquiry',
    element: <EditInquiry />,
  },
  {
    path: 'notifies',
    name: 'Notify Me List',
    element: <NotifiesList />,
  },

  {
    path: 'attributes',
    name: 'Attributes List',
    element: <AttributesList />,
  },
  {
    path: 'attributes/add',
    name: 'Add Attribute',
    element: <AddAttribute />,
  },
  {
    path: 'attributes/edit/:attribute_id',
    name: 'Edit Attribute',
    element: <EditAttribute />,
  },
  {
    path: 'specs',
    name: 'Specs List',
    element: <SpecsList />,
  },
  {
    path: 'specs/add',
    name: 'Add Specs',
    element: <EditSpecs />,
  },
  {
    path: 'specs/edit/:specs_id',
    name: 'Edit Specs',
    element: <EditSpecs />,
  },

  {
    path: 'settings',
    name: 'Settings',
    element: <Settings />,
  },

  {
    path: 'categories',
    name: 'Categories List',
    element: <CategoryList />,
  },
  {
    path: 'categories/edit/:category_id',
    name: 'Edit Category',
    element: <EditCategory />,
  },
  {
    path: 'categories/subcategories/:category_id',
    name: 'Sub Categories List',
    element: <SubCategoryList />,
  },
  {
    path: 'categories/subcategory/edit/:subcategory_id',
    name: 'Edit Sub Category',
    element: <EditSubCategory />,
  },
  {
    path: 'shipping-boxes',
    name: 'Shipping Box List',
    element: <ShippingBoxList />,
  },
  {
    path: 'shipping-boxes/edit/:shipping_box_id',
    name: 'Edit Shipping Box',
    element: <EditShippingBox />,
  },
  {
    path: 'gear/shipping-boxes',
    name: 'Gear Shipping Box List',
    element: <GearShippingBoxList />,
  },
  {
    path: 'gear/shipping-boxes/edit/:shipping_box_id',
    name: 'Edit Gear Shipping Box',
    element: <EditGearShippingBox />,
  },
  {
    path: 'orders',
    name: 'Orders',
    element: <OrderList />,
  },
  {
    path: 'orders/order-details/logs/:order_id',
    name: 'Order Logs',
    element: <OrderLogs />,
  },
  {
    path: 'orders/order-details/payments/:order_id',
    name: 'Order Payments',
    element: <OrderPayments />,
  },
  {
    path: 'create-order',
    name: 'Create Order',
    element: <CreateOrder />,
  },
  {
    path: 'order/create',
    name: 'Create Order',
    element: <CreateUpdateOrder />,
  },
  {
    path: 'order/edit/:order_id',
    name: 'Update Order',
    element: <CreateUpdateOrder />,
  },
  {
    path: 'service/create',
    name: 'Create Service',
    element: <CreateUpdateServiceOrder />,
  },
  {
    path: 'service/edit/:order_id',
    name: 'Edit Service',
    element: <CreateUpdateServiceOrder />,
  },
  {
    path: 'orders/edit/:order_id',
    name: 'Edit Order',
    element: <EditOrder />,
  },
  {
    path: 'orders/details/:order_id',
    name: 'Order Details',
    element: <ViewOrderDetails />,
  },
  {
    path: 'orders/print-label/:order_id/:shipping_box_id',
    name: 'Print Label',
    element: <ShippingLabel />,
  },
  {
    path: 'orders/shipping-boxes/:order_id',
    name: 'Order Shipping Boxes',
    element: <OrderShippingBoxes />
  },
  {
    path: 'orders/edit-shipping-boxes/:order_id',
    name: 'Edit Order Shipping Boxes',
    element: <EditOrderShippingBoxes />
  },
  {
    path: 'orders/shipping-additionals',
    name: "Shipping Additionals",
    element: <ShippingAdditionals />
  },
  {
    path: 'orders/shipping-cancelations',
    name: "Shipping Cancelations",
    element: <ShippingCancelations />
  },
  {
    path: 'returns',
    name: "Returns",
    element: <ReturnInitiate />
  },
  {
    path: 'product-selector',
    name: 'Product Selector',
    element: <ProductSelectorList />
  },
  {
    path: 'product-selector/details/:product_id',
    name: 'Product Selector Details',
    element: <ViewProductSelector />
  },
  {
    path: 'packing-slips',
    name: 'Packing Slips List',
    element: <PackingSlipList />,
  },
  {
    path: 'packing-slip/create',
    name: 'Create Packing Slip',
    element: <CreatePackingSlip />,
  },
  {
    path: 'packing-slip/:packing_slip_id',
    name: 'Edit Packing Slip',
    element: <EditPackingSlip />,
  },
  {
    path: 'sms-templates',
    name: 'SMS Template List',
    element: <SmsTemplateList />,
  },
  {
    path: 'sms-templates/create',
    name: 'Create SMS Template',
    element: <CreateSmsTemplate />,
  },
  {
    path: 'sms-templates/:sms_template_id',
    name: 'Edit SMS Template',
    element: <EditSmsTemplate />,
  },
  {
    path: 'reviews',
    name: 'Reviews',
    element: <ReviewList />,
  },

  // Product Gear Routing
  {
    path: 'gear-attributes',
    name: 'Gear Attributes',
    element: <GearAttributesList />,
  },
  {
    path: 'gear-attributes/add',
    name: 'Add Gear Attributes',
    element: <AddGearAttribute />,
  },
  {
    path: 'gear-attributes/edit/:attribute_id',
    name: 'Edit Gear Attributes',
    element: <EditGearAttribute />,
  },
  {
    path: 'gear-categories',
    name: 'Gear Categories',
    element: <GearCategoryList />,
  },
  {
    path: 'gear-categories/edit/:category_id',
    name: 'Edit Gear Categories',
    element: <EditGearCategory />,
  },
  {
    path: 'gear-products',
    name: 'Gear Products List',
    element: <GearProductsList />,
  },
  {
    path: 'gear-products/create',
    name: 'Create Gear Products',
    element: <GearCreateProduct />,
  },
  {
    path: 'gear-products/:categoryName/:productName/edit/:product_id',
    name: 'Edit Gear Products',
    element: <GearEditProduct />,
  },
  {
    path: 'return-initiate/:orderId',
    name: 'Return Initiate',
    element: <ReturnInitiate />,
  },

  // Flex Code Nomenclature
  {
    path: 'flex-code-nomenclature',
    name: 'Flex Code Nomenclature',
    element: <FlexCodeEditor />,
  },
  {
    path: 'flex-code-nomenclature/create',
    name: 'Add Flex Code Nomenclature',
    element: <FlexCodeEditor />,
  },
  {
    path: 'flex-code-nomenclature/edit/:id',
    name: 'Edit Flex Code Nomenclature',
    element: <FlexCodeEditor />,
  },
  // Iron Number Nomenclature
  {
    path: 'iron-number-nomenclature',
    name: 'Iron Number Nomenclature',
    element: <IronNomenclatureEditor />,
  },
  {
    path: 'iron-number-nomenclature/create',
    name: 'Add Iron Code Nomenclature',
    element: <IronNomenclatureEditor />,
  },
  {
    path: 'iron-number-nomenclature/edit/:id',
    name: 'Edit Iron Code Nomenclature',
    element: <IronNomenclatureEditor />,
  },

  // Additional Products
  {
    path: 'services',
    name: 'Services List',
    element: <ServiceList />,
  },
  {
    path: 'services/create',
    name: 'Create Service',
    element: <Service />,
  },
  {
    path: 'services/edit/:product_id',
    name: 'Edit Service',
    element: <Service />,
  },
  {
    path: 'build-shop-supply',
    name: "Build Shop Supply List",
    element: <BuildShopSupplyList />
  },
  {
    path: 'build-shop-supply/create',
    name: "Create Build Shop Supply",
    element: <BuildShopSupply />
  },
  {
    path: 'build-shop-supply/edit/:product_id',
    name: "Edit Build Shop Supply",
    element: <BuildShopSupply />
  },
  // Product Selector Questions Percentages
  {
    path: 'kinetixx-fit/create',
    name: 'Create News',
    element: <AddEditSelectorQuestion />,
  },
  {
    path: 'kinetixx-fit/edit/:news_id',
    name: 'Edit News',
    element: <AddEditSelectorQuestion />,
  },
  {
    path: 'kinetixx-fit',
    name: 'SelectorQuestions',
    element: <SelectorQuestionList />,
  },
  {
    path: 'resources',
    name: 'Resources List',
    element: <ResourcesList />
  },
  {
    path: 'resources/create',
    name: 'Create Resource',
    element: <AddEditResources />,
  },
  {
    path: 'resources/edit/:resource_id',
    name: 'Edit Resource',
    element: <AddEditResources />,
  },
  {
    path: 'announcements',
    name: 'Announcement',
    element: <Announcement />
  },
  {
    path: 'announcements-list',
    name: 'Announcement',
    element: <AnnouncementsList />
  }
];

export default AdminRoutes;
