import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { CKEditor } from 'ckeditor4-react';

import { connect } from 'react-redux';
import { edit, getPackingSlipById } from 'actions/admin/packingSlip';
import Spinner from 'views/Spinner';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import Errors from 'views/Notifications/Errors';
import { REMOVE_ERRORS } from 'actions/types';
import { CKEditorConfig } from 'constants';

const EditPackingSlip = ({
  getPackingSlipById,
  edit,
  packingSlip: { currentPackingSlip, loading },
  history,
  match,
  errorList,
}) => {
  /**************** use state for form data *********************/

  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    // slug: '',
    description: '',
  });

  /**************** use memo to get current packing slip *********************/
  const { packing_slip_id: packing_slip_id } = useParams();
  useMemo(() => {
    getPackingSlipById(packing_slip_id);
  }, [getPackingSlipById, packing_slip_id]);

  /**************** use memo to set form data from current packing slip *********************/
  useMemo(() => {
    if (Object.keys(currentPackingSlip).length > 0) {
      setFormData({
        ...currentPackingSlip,
      });
    }
  }, [currentPackingSlip]);

  /**************** expload from data *********************/
  const {
    name,
    // slug,
    description,
  } = formData;

  /**************** use dispatch *********************/


  /**************** handle on change event *********************/
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

  };

  const handelDescriptionChange = (event) => {
    setFormData((ev) => ({
      ...ev,
      description: event.editor.getData(),
    }));

  };

  /**************** handle on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    edit(formData, navigate, packing_slip_id, '/admin/packing-slips');
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="12">
          <Card>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                <Row>
                  {/* <Col md="6" sm="12">
                    <FormGroup>
                      <Label htmlFor="slug">
                        Slug <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="slug"
                        name="slug"
                        maxLength="100"
                        value={slug}
                        onChange={(e) => onChange(e)}
                        readOnly
                        invalid={errorList.slug ? true : false}
                        disabled
                      />
                      <Errors current_key="slug" key="slug" />
                    </FormGroup>
                  </Col> */}
                  <Col md="12" sm="12">
                    <FormGroup>
                      <Label htmlFor="name">
                        Name <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="name"
                        name="name"
                        maxLength="100"
                        value={name}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.name ? true : false}
                      />
                      <Errors current_key="name" key="name" />
                    </FormGroup>
                  </Col>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="description">
                        Body <span>*</span>
                      </Label>
                      <CKEditor
                        initData={description}
                        id="description"
                        name="description"
                        config={CKEditorConfig}
                        onChange={(event) => handelDescriptionChange(event)}
                        invalid={errorList.description ? true : false}
                      />
                      <Errors current_key="description" key="description" />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button type="submit" size="sm" color="primary">
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
                <Link to="/admin/packing-slips">
                  <Button type="reset" size="sm" color="danger">
                    <i className="fa fa-ban"></i> Cancel
                  </Button>
                </Link>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditPackingSlip.propTypes = {
  getPackingSlipById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  packingSlip: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  packingSlip: state.packingSlip,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  getPackingSlipById,
  edit,
})(EditPackingSlip);
