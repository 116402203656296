import {
  SHIPPING_BOX_MODAL_LOADING,
  SHIPPING_BOX_ADDED,
  SHIPPING_BOX_ADDED_ERROR,
  SHIPPING_BOX_FAIL,
  SHIPPING_BOX_LOADING,
  DELETE_SHIPPING_BOX,
  SHIPPING_BOX_LIST_UPDATED,
  CUSTOM_SHIPPING_BOX_LIST_UPDATED,
  CHANGE_SHIPPING_BOX_STATUS,
  CHANGE_SHIPPING_BOX_NATIONAL_INTERNATIONAL,
  GET_SHIPPING_BOX_BY_ID,
  SHIPPING_BOX_UPDATED,
  SHIPPING_BOX_UPDATED_ERROR,
  SHIPPING_FLEET_REMARK_LOADING,
  SHIPPING_ADDED_FLEET_REMARK,
  SHIPPING_ADDED_FLEET_ERROR,
  SHIPPING_ADDED_FLEET_FAIL,
} from 'actions/types';

const initalState = {
  shippingBoxList: {
    page: 1,
    data: [],
    count: 0,
  },
  customShippingBoxList: [],
  currentShippingBox: {},
  loading: true,
  modal_loading: false,
  isSubLoading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case SHIPPING_FLEET_REMARK_LOADING:
    case SHIPPING_BOX_MODAL_LOADING:
      return {
        ...state,
        modal_loading: true,
      };
    case SHIPPING_BOX_ADDED:
      // state.sortingParams.limit == state.shippingBoxList.count
      //   ? state.shippingBoxList.data.pop()
      //   : "";
      return {
        ...state,
        shippingBoxList: {
          ...state.shippingBoxList,
          data: [payload.data, ...state.shippingBoxList.data],
        },
        modal_loading: false,
      };
    case SHIPPING_ADDED_FLEET_REMARK:
    case SHIPPING_ADDED_FLEET_ERROR:
    case SHIPPING_BOX_ADDED_ERROR:
      return {
        ...state,
        modal_loading: false,
      };
    case SHIPPING_ADDED_FLEET_FAIL:
    case SHIPPING_BOX_FAIL:
      return {
        ...state,
        modal_loading: false,
        loading: false,
        isSubLoading: false,
      };
    case SHIPPING_BOX_LOADING:
      return {
        ...state,
        shippingBoxList: payload.is_sub_element
          ? state.shippingBoxList
          : initalState.shippingBoxList,
        currentShippingBox: payload.is_sub_element
          ? state.currentShippingBox
          : initalState.currentShippingBox,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case DELETE_SHIPPING_BOX:
      return {
        ...state,
        loading: false,
      };
    case SHIPPING_BOX_LIST_UPDATED:
      return {
        ...state,
        shippingBoxList: {
          data: payload.data.shippingBoxList.data,
          page: payload.data.shippingBoxList.metadata[0].current_page,
          count: payload.data.shippingBoxList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case CUSTOM_SHIPPING_BOX_LIST_UPDATED:
      return {
        ...state,
        customShippingBoxList: [
          ...payload.data.customShippingBoxList
        ],
        loading: false,
      };
    case CHANGE_SHIPPING_BOX_STATUS:
      return {
        ...state,
        shippingBoxList: {
          ...state.shippingBoxList,
          data: state.shippingBoxList.data.map((box) =>
            box._id === payload.data._id
              ? { ...box, status: payload.data.status }
              : box
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case CHANGE_SHIPPING_BOX_NATIONAL_INTERNATIONAL:
      return {
        ...state,
        shippingBoxList: {
          data: state.shippingBoxList.data.map((shippingBox) =>
            shippingBox._id === payload.data._id
              ? {
                ...shippingBox,
                is_international: payload.data.is_international,
              }
              : shippingBox
          ),
        },
        loading: false,
      };
    case GET_SHIPPING_BOX_BY_ID:
      return {
        ...state,
        currentShippingBox: payload.data,
        modal_loading: false,
      };
    case SHIPPING_BOX_UPDATED:
      return {
        ...state,
        currentShippingBox: {},
        shippingBoxList: {
          data: state.shippingBoxList.data.map((shippingBox) =>
            shippingBox._id === payload.data._id
              ? { ...payload.data }
              : shippingBox
          ),
        },
        modal_loading: false,
      };
    case SHIPPING_BOX_UPDATED_ERROR:
      return {
        ...state,
        modal_loading: false,
      };

    default:
      return state;
  }
}
