import {
  VIDEO_GALLERY_UPLOADED,
  VIDEO_GALLERY_UPLOAD_ERROR,
  VIDEO_GALLERY_FAIL,
  VIDEO_GALLERY_DELETE,
  VIDEO_GALLERY_DELETE_ERROR,
  VIDEO_GALLERY_UPDATED,
  VIDEO_GALLERY_UPDATE_ERROR,
  VIDEO_GALLERY_LIST_UPDATED,
  VIDEO_GALLERY_GET_VIDEO_BY_ID,
  VIDEO_GALLERY_LOADING,
  } from "actions/types";
  
  const initalState = {
    videoList: {
      page: 1,
      data: [],
      count: 0
    },
    currentVideo: {},
    loading: false,
    isSubLoading: false,
  };
  
  export default function(state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
      case VIDEO_GALLERY_LOADING:
      return {
        ...state,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
      case VIDEO_GALLERY_UPLOADED:
        return {
          ...state,
          loading: false
        };
      case VIDEO_GALLERY_UPLOAD_ERROR:
        return {
          ...state,
          loading: false,
        };
      case VIDEO_GALLERY_UPDATED:
        return {
          ...state,
          currentVideo: {},
          loading: false
        };
      case VIDEO_GALLERY_UPDATE_ERROR:
        return {
          ...state,
          loading: false,
      };
      case VIDEO_GALLERY_FAIL:
        return {
          ...state,
          loading: false
        };
      case VIDEO_GALLERY_DELETE:
        return {
          ...state,
          loading: false
        };
      case VIDEO_GALLERY_DELETE_ERROR:
        return {
          ...state,
          loading: false,
      };
      case VIDEO_GALLERY_GET_VIDEO_BY_ID:
        return {
          ...state,
          currentVideo: payload.data,
          loading: false
        };
      case VIDEO_GALLERY_LIST_UPDATED:
        return {
          ...state,
          videoList: {
            data: payload.data.videoList.data,
            // page: payload.data.videoList.metadata[0].current_page,
            count: payload.data.videoList.metadata[0].totalRecord
          },
          loading: false
        };
      default:
        return state;
    }
  }
