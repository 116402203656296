/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { addAddress } from 'actions/admin/order';
import Errors from 'views/Notifications/Errors';
import Spinner from 'views/ButtonSpinner';
import { REMOVE_ERRORS, SET_GOOGLE_ADDRESS_FORM_DATA } from 'actions/types';
import { setGoogleAddressFormData } from "actions/commonActions";
import { ADDRESS_TYPE } from 'constants/index';
import Autocomplete from 'react-google-autocomplete';
import { GOOGLE_MAPS_API_KEY } from '../../../config';
import 'react-phone-number-input/style.css';
import PhoneInput, { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import extractNumber from "utils/extractNumber";
import { getCountryShort } from "utils/countryCode";


import {
  Input,
  ModalFooter,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Button,
} from 'reactstrap';

const AddAddress = ({
  userId,
  addAddress,
  errorList,
  toggle,
  isOpen,
  modal_loading,
  setSelectedShippingAddress,
  setSelectedBillingAddress,
  isFor,
  googleAddressFormData,
  setGoogleAddressFormData,
  setShippToDifferentAddressChecked,
}) => {
  //########################## use dispatch ##############################
  const dispatch = useDispatch();

  //########################## set the initial form data ##############################

  const initialState = {
    name: '',
    email: '',
    ccode: "+1",
    contact_no: '',
    iute164_phone: '',
    address: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    zipcode: '',
    country: '',
    state: '',
    google_id: '',
    google_place_id: '',
    country_code: '',
    // location: geometry,
  };
  const [formData, setFormData] = useState(initialState);
  const [autocompleteValue, setAutocompleteValue] = useState('');

  //########################## set the submit state ##############################
  const [isSubmit, setIsSubmit] = useState(false);

  //########################## reset for data when modal is closed ##############################
  const resetModal = () => {
    dispatch({ type: REMOVE_ERRORS });
    //reset the submit stacte
    setIsSubmit(false);
    //reset the form data to initial state
    setFormData(initialState);
  };

  //########################## explod form data vaiables ##############################
  const {
    address,
    name,
    email,
    ccode,
    contact_no,
    iute164_phone,
    address_line_1,
    address_line_2,
    city,
    zipcode,
    country,
    state,
    // location: geometry,
  } = formData;

  //########################## change event for all inputs ##############################
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    dispatch({ type: REMOVE_ERRORS });
  };

  //########################## change event for phone number ##############################
  const onPhoneChange = number => {
    const contact_no = extractNumber(number, ccode);
    setFormData((form) => ({
      ...form,
      "contact_no": contact_no,
      "iute164_phone": number ?? '',
    }));
  }

  const onCountryChange = code => {
    setFormData((form) => ({
      ...form,
      "ccode": "+" + getCountryCallingCode(code ?? getCountryShort(ccode)),
    }));
  }

  //########################## submit form data ##############################
  const onSubmit = (e) => {
    setIsSubmit(true);
    addAddress(formData, userId).then((res) => {
      setIsSubmit(false);
      if (res.status === true) {
        setShippToDifferentAddressChecked(true);
        if (isFor === ADDRESS_TYPE.BILLING) setSelectedBillingAddress(res.data);
        else if (isFor === ADDRESS_TYPE.SHIPPING)
          setSelectedShippingAddress(res.data);
        toggle();
      }
    });
  };

  // to resolve useEffect dependency error



  //#################### update formdata after changes in google address  #################
  useEffect(() => {



    if (Object.keys(googleAddressFormData).length > 0) {
      setFormData({
        ...formData,
        // ...googleAddressFormData,
        name: googleAddressFormData.name,
        email: googleAddressFormData.email,
        ccode: googleAddressFormData.ccode,
        contact_no: googleAddressFormData.contact_no,
        iute164_phone: googleAddressFormData.iute164_phone
      });
    }
    dispatch({
      type: SET_GOOGLE_ADDRESS_FORM_DATA,
      payload: {},
    });
  }, [address]);

  const setGMapPlace = (location) => {
    const google_id = location.id;
    const google_place_id = location.place_id;
    const address = location.formatted_address;
    const addresses = location.address_components;
    let address_line_1 = '';
    let address_line_2 = '';
    let city = '';
    let state = '';
    let country = '';
    let country_code = '';
    let zipcode = '';
    if (addresses)
      addresses.forEach((e) => {
        switch (e.types[0]) {
          case 'street_number':
          case 'route':
            address_line_1 += (address_line_1 ? " " : "") + e.long_name;

            break;
          case 'sublocality_level_2':
          case 'sublocality_level_1':
            address_line_2 += (address_line_2 ? " " : "") + e.long_name;
            break;
          case 'locality':
          case 'sublocality_level_1':
          case 'administrative_area_level_3':
          case "administrative_area_level_2":
          case 'postal_town':
            if (city == "") {
              city = e.long_name;
            }
            break;
          case 'administrative_area_level_1':
            state = e.long_name;
            break;
          case 'postal_code':
          case 'postal_code_prefix':
            zipcode = e.long_name;
            break;
          case 'country':
            country = e.long_name;
            country_code = e.short_name;
            break;
          default:
            break;
        }
      });
    setFormData({
      ...formData,
      address,
      address_line_1,
      address_line_2,
      city,
      google_id,
      google_place_id,
      state,
      country,
      country_code,
      zipcode,
      // location: geometry,
    });
    setAutocompleteValue('');
  };
  const handleInputChange = (event) => {
    setAutocompleteValue(event.target.value);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} onClosed={resetModal} size={'lg'}>
      <ModalHeader toggle={toggle}>Add New Address</ModalHeader>
      <Form className="billing-form ">
        <ModalBody>
          <div className="row align-items-end">
            <div>
              <h5>Personal information</h5>
            </div>
            <div className="col-md-12">
              <FormGroup>
                <Label htmlFor="name">Name*</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={name}
                  required
                  onChange={(e) => onChange(e)}
                  invalid={errorList.name ? true : false}
                />
                <Errors current_key="name" key="name" />
              </FormGroup>
            </div>
            <div className="w-100"></div>
            <div className="col-md-6">
              <FormGroup>
                <Label htmlFor="email">Email *</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  value={email}
                  required
                  onChange={(e) => onChange(e)}
                  invalid={errorList.email ? true : false}
                />
                <Errors current_key="email" key="email" />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup>
                <Label htmlFor="contact_no">Contact No. *</Label>
                {/* <Input
                  type="text"
                  className="form-control"
                  id="contact_no"
                  name="contact_no"
                  maxLength={14}
                  value={contact_no}
                  required
                  onChange={(e) => onChange(e)}
                  invalid={errorList.contact_no ? true : false}
                /> */}
                <PhoneInput
                  className=""
                  autoComplete="tel"
                  withCountryCallingCode={true}
                  countryCallingCodeEditable={false}
                  smartCaret={true}
                  international={true}
                  defaultCountry="US"
                  value={iute164_phone}
                  name="contact_no"
                  addInternationalOption={false}
                  onChange={onPhoneChange}
                  onCountryChange={onCountryChange}
                />
                <Errors current_key="contact_no" key="contact_no" />
              </FormGroup>
            </div>
            <div className="w-100"></div>
          </div>
          <div className="row align-items-end">
            <div>
              <h5>Address information</h5>
            </div>
            <div className="col-md-12">
              <FormGroup>
                {/* <GMaps
                  label="Location"
                  onChange={setGMapPlace}
                  placeholder={"Search your address"}
                  types={"address"}
                  formData={formData}
                /> */}
                <Autocomplete
                  apiKey={GOOGLE_MAPS_API_KEY}
                  onPlaceSelected={(place) => {
                    {
                      setGMapPlace(place);
                    }
                  }}
                  options={{
                    types: ['address'],
                  }}
                  onFocus={() => setGoogleAddressFormData(formData)}
                  value={autocompleteValue}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </FormGroup>
            </div>

            {/* <div className="w-100"></div> */}

            <div className="col-md-12">
              <FormGroup>
                <Label htmlFor="address">Address *</Label>
                <Input
                  className="form-control"
                  type="textarea"
                  id="address"
                  name="address"
                  value={address}
                  required
                  onChange={(e) => onChange(e)}
                  invalid={errorList.address ? true : false}
                />
                <Errors current_key="address" key="address" />
              </FormGroup>
            </div>


            <div className="col-md-6">
              <FormGroup>
                <Label htmlFor="city">Address Line 1</Label>
                <input
                  type="text"
                  className="form-control"
                  id="address_line_1"
                  name="address_line_1"
                  maxLength="100"
                  required
                  value={address_line_1}
                  onChange={(e) => onChange(e)}
                  invalid={errorList.address_line_1 ? true : false}
                />

              </FormGroup>
            </div>

            <div className="col-md-6">
              <FormGroup>
                <Label htmlFor="city">Address Line 2</Label>
                <input
                  type="text"
                  className="form-control"
                  id="address_line_2"
                  name="address_line_2"
                  maxLength="100"
                  value={address_line_2}
                  //disabled={true}
                  //readOnly={true}
                  onChange={(e) => onChange(e)}
                  invalid={errorList.address_line_2 ? true : false}
                />

              </FormGroup>
            </div>
            <div className="w-100"></div>
            <div className="col-md-6">
              <FormGroup>
                <Label htmlFor="city">City *</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="city"
                  name="city"
                  maxLength="100"
                  value={city}
                  onChange={(e) => onChange(e)}
                  required
                  invalid={errorList.city ? true : false}
                />
                <Errors current_key="city" key="city" />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup>
                <Label htmlFor="state">State</Label>
                <Input
                  type="text"
                  className="form-control"
                  name="state"
                  id="state"
                  value={state}
                  onChange={(e) => onChange(e)}
                  invalid={errorList.state ? true : false}
                />
                <Errors current_key="state" key="state" />
              </FormGroup>
            </div>
            <div className="w-100"></div>
            <div className="col-md-6">
              <FormGroup>
                <Label htmlFor="country">Country *</Label>
                <Input
                  type="text"
                  className="form-control"
                  name="country"
                  id="country"
                  value={country}
                  required
                  onChange={(e) => onChange(e)}
                  invalid={errorList.country ? true : false}
                />
                <Errors current_key="country" key="country" />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup>
                <Label htmlFor="zipcode">Zip/Postal Code *</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="zipcode"
                  name="zipcode"
                  maxLength="10"
                  // minLength="3"
                  value={zipcode}
                  onChange={(e) => onChange(e)}
                  required
                  invalid={errorList.zipcode ? true : false}
                />
                <Errors current_key="zipcode" key="zipcode" />
              </FormGroup>
            </div>
            <div className="w-100"></div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="reset"
            className="btn-sm btn-secondary"
            onClick={toggle}
          >
            Cancel
          </Button>
          {modal_loading ? (
            <Spinner />
          ) : (
            <Button
              type="button"
              className="btn-sm btn-primary "
              onClick={(e) => onSubmit(e)}
              disabled={isSubmit}
            >
              Add Address
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

AddAddress.defaultProps = {
  isOpen: false,
};

AddAddress.propTypes = {
  addAddress: PropTypes.func.isRequired,
  modal_loading: PropTypes.bool.isRequired,
  errorList: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  googleAddressFormData: PropTypes.object.isRequired,
  setGoogleAddressFormData: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  modal_loading: state.order.modal_loading,
  googleAddressFormData: state.commonActions.googleAddressFormData,
});
export default connect(mapStateToProps, {
  addAddress,
  setGoogleAddressFormData
})(AddAddress);
