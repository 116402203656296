import axios from 'axios';

import {
  EMAIL_TEMPLATE_CATEGORY_LOADING,
  EMAIL_TEMPLATE_CATEGORY_CREATED,
  EMIAL_TEMPLATE_CATEGORY_CREATE_ERROR,
  EMAIL_TEMPLATE_CATEGORY_FAIL,
  EMAIL_TEMPLATE_CATEGORY_UPDATED,
  EMIAL_TEMPLATE_CATEGORY_UPDATE_ERROR,
  EMAIL_TEMPLATE_CATEGORY_DELETED,
  EMAIL_TEMPLATE_CATEGORY_DELETE_ERROR,
  EMAIL_TEMPLATE_CATEGORY_LIST_UPDATED,
  GET_EMAIL_TEMPLATE_CATEGORY_BY_ID,
  EMAIL_TEMPLATE_CATEGORY_CHANGE_STATUS,
  EMAIL_TEMPLATE_CATEGORY_CHANGE_STATUS_ERROR,
} from 'actions/types';

// Create Email Template Category
export const create = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: EMAIL_TEMPLATE_CATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      '/api/admin/emailTemplateCategory/create',
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: EMAIL_TEMPLATE_CATEGORY_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: EMIAL_TEMPLATE_CATEGORY_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: EMAIL_TEMPLATE_CATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit Email Template Category
export const edit =
  (formData, emailTemplateCategory_id) => async (dispatch) => {
    try {
      dispatch({
        type: EMAIL_TEMPLATE_CATEGORY_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/emailTemplateCategory/${emailTemplateCategory_id}`,
        formData,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: EMAIL_TEMPLATE_CATEGORY_UPDATED,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
        return true;
      } else {
        const errors = res.data.errors;
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }
        dispatch({
          type: EMIAL_TEMPLATE_CATEGORY_UPDATE_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
            errorsList,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: EMAIL_TEMPLATE_CATEGORY_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

// Delete Email Template Category
export const deleteEmailTemplateCategory =
  (emailTemplateCategory_id) => async (dispatch) => {
    try {
      dispatch({
        type: EMAIL_TEMPLATE_CATEGORY_LOADING,
        payload: { is_loading: true, is_sub_element: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.delete(
        `/api/admin/emailTemplateCategory/${emailTemplateCategory_id}`,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: EMAIL_TEMPLATE_CATEGORY_DELETED,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
        return true;
      } else {
        dispatch({
          type: EMAIL_TEMPLATE_CATEGORY_DELETE_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
          },
        });
        return false;
      }
    } catch (err) {
      dispatch({
        type: EMAIL_TEMPLATE_CATEGORY_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

//get Email Template Category list

export const getEmailTemplateCategoryList =
  (emailTemplateParams) => async (dispatch) => {
    try {
      dispatch({
        type: EMAIL_TEMPLATE_CATEGORY_LOADING,
        payload: { is_loading: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: emailTemplateParams,
      };
      const res = await axios.get(`/api/admin/emailTemplateCategory/`, config);
      dispatch({
        type: EMAIL_TEMPLATE_CATEGORY_LIST_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: {
            emailTemplateParams: emailTemplateParams,
            emailTemplateCategoryList: res.data.response[0],
          },
        },
      });
      return res;
    } catch (err) {
      dispatch({
        type: EMAIL_TEMPLATE_CATEGORY_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

export const getEmailTemplateCategoryById =
  (emailTemplateCategory_id) => async (dispatch) => {
    try {
      dispatch({
        type: EMAIL_TEMPLATE_CATEGORY_LOADING,
        payload: { is_loading: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.get(
        `/api/admin/emailTemplateCategory/${emailTemplateCategory_id}`,
        config
      );
      await dispatch({
        type: GET_EMAIL_TEMPLATE_CATEGORY_BY_ID,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return res;
    } catch (err) {
      dispatch({
        type: EMAIL_TEMPLATE_CATEGORY_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };
