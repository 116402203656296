import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddAddress from './AddAddress';
import Spinner from 'views/Spinner';
import { ADDRESS_TYPE } from 'constants/index';
import Select from 'react-select';
import { Button, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { selectedVariations } from 'actions/admin/order';
import ShippingLoggedIn from './ShippingLoggedIn';

const BillingDetail = ({
    variationData,
    selectedVariations,
    userId,
    address_loading,
    currentAddressList,
    //setSelectedBillingAddress,
    //setSelectedShippingAddress,
    setShippToDifferentAddressChecked,
    editAddress,
}) => {
    let addressValues = [];
    const [addressList, setAddressList] = useState([]);
    const [addressValueData, setAddressValueData] = useState();
    const [isFor, setIsFor] = useState(ADDRESS_TYPE.BILLING);
    const [addressModal, setOpenAddressModal] = useState(false);

    const onRestEditAddress = (e) => {
        e.preventDefault();
        variationData = { ...variationData, editAddress: false, shippingService: false }
        selectedVariations(variationData)
    };

    useEffect(() => {
        setAddressList([]);
        /*  if (variationData.orderId === undefined) {
             variationData = { ...variationData, selectedBillingAddress: null, selectedShippingAddress: null, shippToDifferentAddressChecked: false }
             selectedVariations(variationData);
         } */

        if (currentAddressList.length > 0) {
            if (variationData.orderId !== undefined) {
                const getShippingAddress = currentAddressList?.find(
                    (address) => address._id == variationData.selectedShippingAddress
                );
                const getBillingAddress = currentAddressList?.find(
                    (address) => address._id == variationData.selectedBillingAddress
                );
                let updatedAddressEmailAndPhone = {};
                if (getShippingAddress !== undefined && getShippingAddress !== null) {
                    if ((getShippingAddress.email).toLowerCase() !== (variationData.selectedOrderAddress.email).toLowerCase()) {
                        getShippingAddress.email = variationData.selectedOrderAddress.email;
                        updatedAddressEmailAndPhone["email"] = variationData.selectedOrderAddress.email;
                    }
                    if (getShippingAddress.contact_no !== variationData.selectedOrderAddress.contact_no) {
                        getShippingAddress.ccode = variationData.selectedOrderAddress.ccode;
                        getShippingAddress.contact_no = variationData.selectedOrderAddress.contact_no;
                        updatedAddressEmailAndPhone["ccode"] = variationData.selectedOrderAddress.ccode;
                        updatedAddressEmailAndPhone["contact_no"] = variationData.selectedOrderAddress.contact_no;
                    }
                }

                if (Object.keys(updatedAddressEmailAndPhone).length === 0) {
                    updatedAddressEmailAndPhone = null
                }
                variationData = { ...variationData, billingAddressData: getBillingAddress, shippingAddressData: getShippingAddress, updateAddressEmailAndPhone: updatedAddressEmailAndPhone }
                selectedVariations(variationData)

            }
            setAddressList(currentAddressList);
        }
    }, [currentAddressList, variationData.userId]);

    useEffect(() => {
        if (addressList) {

            addressList.map((address, idx) => {
                addressValues.push({ value: address._id, label: address.address });
            });

            setAddressValueData(addressValues);
        }
    }, [addressList]);



    const toggleAddressModal = (modalIsFor = ADDRESS_TYPE.BILLING) => {
        setIsFor(modalIsFor);
        setOpenAddressModal(!addressModal);
    };

    //##################### Billing address detail #########################
    const handleCheckedChange = (e) => {
        variationData = { ...variationData, shippToDifferentAddressChecked: e.target.checked }
        selectedVariations(variationData)
    };

    const handleBillingAddressChange = (e) => {
        const billingAddress = addressList?.find(
            (address) => address._id === e.value
        );
        variationData = { ...variationData, billingAddressData: billingAddress, selectedBillingAddress: e.value, selectedBillingLabel: e.label }
        selectedVariations(variationData)
    };

    const handleShippingAddressChange = (e) => {
        const shippingAddress = addressList?.find(
            (address) => address._id === e.value
        );
        variationData = { ...variationData, shippingAddressData: shippingAddress, selectedShippingAddress: e.value, selectedShippingLabel: e.label }
        selectedVariations(variationData);
    };

    return address_loading ? (
        <Spinner />
    ) : (
        <div className="bg-light p-2 p-md-4 setreset">
            <Row>
                {variationData.userId === '' ? (
                    <Col md="12">
                        {' '}
                        Please select customer/dealer from search box to proceed....{' '}
                    </Col>
                ) : (
                    <>
                        <Col md="6"><ShippingLoggedIn
                            title={"Shipping Address"}
                            addressList={addressList}
                            addressValueData={addressValueData}
                            handleCheckedChange={handleCheckedChange}
                            handleShippingAddressChange={handleShippingAddressChange}
                            toggleAddressModal={toggleAddressModal}
                        />
                        </Col>
                        <Col md="6">
                            {variationData.shippToDifferentAddressChecked && <ShippingLoggedIn
                                title={"Billing Address"}
                                addressList={addressList}
                                addressValueData={addressValueData}
                                handleBillingAddressChange={handleBillingAddressChange}
                                toggleAddressModal={toggleAddressModal}
                            />}
                        </Col>
                        {
                            variationData.editAddress &&

                            <Col md="1" className="reset-button-tab">
                                <Button
                                    type="button"
                                    className="btn-danger btn-sm"
                                    onClick={(e) => onRestEditAddress(e)}
                                >
                                    <i className="fa fa-times"></i>
                                </Button>
                            </Col>
                        }
                    </>
                )}
            </Row>
            <AddAddress
                userId={userId}
                isOpen={addressModal}
                toggle={toggleAddressModal}
                isFor={isFor}
                setShippToDifferentAddressChecked={setShippToDifferentAddressChecked}
            />
        </div>
    );
}

BillingDetail.propTypes = {
    address_loading: PropTypes.bool.isRequired,
    currentAddressList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    address_loading: state.order.address_loading,
    currentAddressList: state.order.currentAddressList,
    variationData: state.order.variationData,
});

export default connect(mapStateToProps, {
    selectedVariations
})(BillingDetail);