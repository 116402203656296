/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';

import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  ORDER_LIST_PAYMENT_STATUS,
  ORDER_CREATE_FOR_USER_ROLE,
} from 'constants/index';
import Select from 'react-select';
import {
  getUsersList,
  applyCoupon,
  calculateShippingRates,
  getUserAddressList,
  addToCart,
  updateCart,
  getCartDetails,
  addToOrder,
  removeCoupon,
  getProductsList,
  getTaxRates,
} from 'actions/admin/order';
import './Order.css';
import BillingDetail from 'views/Admin/Orders/BillingDetail';
import PreviewForm from 'views/Admin/Orders/PreviewForm';
import ShippingRates from './ShippingRates';
import SelectVariations from './SelectVariations';
import { removeAlert } from 'actions/alert';

const CreateOrder = ({
  addToCart,
  getCartDetails,
  updateCart,
  getUsersList,
  applyCoupon,
  productsList,
  getProductsList,
  usersList,
  user_list_loading,
  getUserAddressList,
  calculateShippingRates,
  addToOrder,
  removeCoupon,
}) => {
  const [isOrderUpdate, setIsOrderUpdate] = useState(false);
  const [cartId, setCartId] = useState(0);
  const [total, setTotal] = useState(0);
  const [productTotalAmount, setProductTotalAmount] = useState(0);
  const [fittingTotalAmount, setFittingTotalAmount] = useState(0);
  const [variation, setVariation] = useState({});
  const [currentCart, setcurrentCart] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [addedProduct, setAddedProduct] = useState([]);
  const [couponCode, setcouponCode] = useState('');
  const [couponDiscount, setDiscountAmount] = useState(0);
  const [couponShippingDiscount, setCouponShippingDiscount] = useState(0);
  const [slug, setSlug] = useState('');
  const [productVariatonDetails, setProductVariationDetails] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [showAddEditForm, setShowAddEditForm] = useState(true);
  const [showPreviewForm, setPreviewForm] = useState(false);
  const [customerName, setCustomerName] = useState();
  const [customization, setCustomization] = useState(false);
  const [isCartUpdated, setIsCartUpdated] = useState(false);

  const counter = useRef(0);
  counter.current++;

  let productQuantity = 1;

  const navigate = useNavigate();
  //########## set userId not null if redirected from  customer/dealer list #############
  const location = useLocation();
  const { state } = location;

  const [userId, setUserId] = useState(() =>
    state !== null ? state.userId : ''
  );

  const [variationModal, setOpenVariationModal] = useState(false);

  //####################### set true if order created #######################
  const [is_submit, setIsSubmit] = useState(false);

  //########################### usestate for billing address ##############################
  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
  const [selectedBillingLabel, setSelectedBillingLabel] = useState(null);

  //########################### usestate for shipping address ##############################
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);
  const [selectedShippingLabel, setSelectedShippingLabel] = useState(null);

  //##################### ship to different address checkbox #########################
  const [shippToDifferentAddressChecked, setShippToDifferentAddressChecked] =
    useState(false);

  //########################### usestate for selected shipping method ##############################
  const [selectedShippingMode, setSelectedShippingMode] = useState({});

  //########################### usestate shipping mode error ##############################
  const [shippingModeError, setShippingModeError] = useState(
    'Enter your full address to see shipping costs.'
  );

  //########################### usestate for shipping box ##############################
  const [shippingBox, setShippingBox] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  //### set userType (customer/dealer) default-customer, if redirected - customer/dealer ###
  const [userType, setUserType] = useState(() =>
    state !== null ? state.userType : '2'
  );

  //################# set select user name value ################
  const [userValue, setUserValue] = useState('');

  //########################### usestate for cart total ##############################
  const [cartTotal, setCartTotal] = useState(0);

  //########################### usestate for Grand total ##############################
  const [grandTotal, setGrandTotal] = useState(0);

  //########################### usestate for EasyPost Order Id ##############################
  const [easyPostOrderId, setEasyPostOrderId] = useState('');

  //########################### usestate for shipping rates ##############################
  const [shippingPrice, setShippingPrice] = useState(0);
  const [shippingMessages, setShippingMessages] = useState([]);
  const [shippingRates, setShippingRates] = useState([]);
  const [groupedRatesByProvider, setGroupedRatesByProvider] = useState({});

  const [taxApplied, setTaxApplied] = useState(0);
  const [totalBeforeTax, setTotalBeforeTax] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [taxData, setTaxData] = useState({});

  //########################### usestate for payment status ##############################
  const [payment_status, setPaymentStatus] = useState(1);

  //################## useState for selected fitting  ##############################
  const [selectedFitting, setSelectedFitting] = useState([]);

  const [variationSelectErrorMsg, setVariationSelectErrorMsg] = useState('');

  //################## to get updated list ###################
  const [productListValue, setProductListValue] = useState();

  const [stockQuantity, setStockQuantity] = useState(0);
  const [carrier, setCarrier] = useState('');
  const [service, setService] = useState('');
  const [shippingCharges, setShippingCharges] = useState('');

  const handleVariationSelect = () => {
    selectedProduct.product_variation = variation;
    const productDetails = {
      fitting: selectedFitting,
      product_id: selectedProduct._id,
      product_slug: selectedProduct.slug,
      product_variation: selectedProduct.product_variation,
      product_variation_sku: productVariatonDetails.product_variation_sku,
      product_variation_id: productVariatonDetails.product_variation_id,
      quantity: quantity,
      customization: customization,
      isOrderUpdate: isOrderUpdate,
    };
    //console.log(customization,'cccccccccccccccccccccccccccc');return false;
    let is_delete = false;
    {
      isEdit && isEdit == true
        ? updateCart(is_delete, productDetails, userId).then((res) => {
            if (res?.data && res?.data.status) {
              setIsCartUpdated(true);
              callGetCartApi();
              setVariation({});
              setOpenVariationModal(!variationModal);

              setProductVariationDetails({});
              setSelectedFitting([]);
            }
          })
        : addToCart(is_delete, productDetails, userId).then((res) => {
            if (res?.data && res?.data.status == true) {
              callGetCartApi();
              setOpenVariationModal(!variationModal);

              setProductVariationDetails({});
              setSelectedFitting([]);
            } else {
              setOpenVariationModal(true);
              setCustomization(true);
            }
          });
    }
  };

  const callGetCartApi = () => {
    let discount_amount = 0;
    if (userId) {
      getCartDetails(userId).then((res) => {
        if (res?.data && res?.data.status) {
          setcurrentCart(res.data.response.products);
          setAddedProduct(
            res.data.response.products ? res.data.response.products : []
          );
          setCartId(res.data.response._id);
          setTotal(res.data.response.totalCartAmount);
          setProductTotalAmount(res.data.response.totalProductAmount);
          setFittingTotalAmount(res.data.response.totalFittingAmount);
          setCartTotal(res.data.response.totalCartAmount);
          if (res.data.response.coupon?.coupon_id) {
            setDiscountAmount(res.data.response.coupon.discounted_amount);
            setTotal(
              res.data.response.totalProductAmount -
                res.data.response.coupon.discounted_amount
            );
            setIsApplied(true);
            setCouponDetails({
              coupon_code: res.data.response.coupon.coupon_code,
              coupon_id: res.data.response.coupon.coupon_id,
            });
          } else {
            setDiscountAmount(0);
            setCouponShippingDiscount(0);
            setTotal(total);
            setIsApplied(false);
            setCouponDetails({ coupon_code: '', coupon_id: '' });
          }
        } else {
          setcurrentCart([]);
          setAddedProduct([]);
          setTotal(0);
          setCartTotal(0);
          setDiscountAmount(0);
          setIsApplied(false);
          setCouponDetails({ coupon_code: '', coupon_id: '' });
        }
      });
    }
  };

  const handleSelectProduct = (e, action) => {
    setQuantity(1);
    setCustomization(false);
    if (action.action === 'select-option') {
      setSlug(e.slug);
      setSelectedProduct(e);
    }

    setIsEdit(false);
    setSelectedFitting({});
    setSelectedFittingsData({});
  };

  const deleteProduct = (cartId, cart_product_id) => {
    let is_delete = true;
    const productDetails = {
      cart_id: cartId,
      product_id: cart_product_id,
      quantity: productQuantity,
    };

    addToCart(is_delete, productDetails, userId, cartId, cart_product_id).then(
      (res) => {
        if (res.data.status) {
          callGetCartApi();
        }
      }
    );
    setTaxTotal(taxTotal);
  };

  const [selectedFittingsData, setSelectedFittingsData] = useState();
  const editProduct = (product) => {
    const variationData = {};
    product.productVariationDetail.map((variation) => {
      variationData[variation.attribute_id] = variation.term_id;
    });
    const fittingData = {};

    {
      product.productFitting &&
        product.productFitting.map((fittingOptions) => {
          if (fittingOptions.attribute_name == 'Adaptors') {
            fittingData['adaptor'] = fittingOptions.option_id;
            fittingData['adaptor_option'] = fittingOptions.sub_option_id;
          }
          if (fittingOptions.attribute_name == 'Playing Length') {
            fittingData['playing'] = fittingOptions.option_id;
          }
          if (fittingOptions.attribute_name == 'Grips') {
            fittingData['grip'] = fittingOptions.option_id;
            fittingData['grip_option'] = fittingOptions.sub_option_id;
          }
          if (fittingOptions.attribute_name == 'Extra Wrap') {
            fittingData['extra_wrap'] = fittingOptions.attribute_value;
          }
          if (fittingOptions.attribute_name == 'Grip Logo') {
            fittingData['grip_logo'] = fittingOptions.attribute_value;
          }
          if (fittingOptions.attribute_name == 'Product Customization') {
            fittingData['product_customization'] =
              fittingOptions.attribute_value;
          }
        });
      setSelectedFittingsData(fittingData);
    }
    setVariation(variationData);
    setSlug(product.product_slug);
    setSelectedProduct(product);
    setQuantity(product.quantity);
    setIsEdit(true);
    setCustomization(product.productFitting?.length);
  };

  const toggleVariationModal = () => {
    setVariation({});
    setOpenVariationModal(!variationModal);
    setProductVariationDetails({});
    setSelectedFitting([]);
  };

  const resetVariationModal = () => {
    setSelectedProduct('');
    setVariationSelectErrorMsg('');
  };

  let productListData = [];
  useEffect(() => {
    if (userId !== '' && !productsList.length) getProductsList();
  }, [userId]);

  // Get Selected User Cart Details
  useEffect(() => {
    if (userId) {
      return callGetCartApi();
    }
  }, [userId, is_submit]);

  useEffect(() => {
    if (selectedProduct?.product_title) {
      setOpenVariationModal(!variationModal);
    }
  }, [selectedProduct?.product_title]);

  //########################### useEffect to on change of billing address##############################
  useEffect(() => {
    setSelectedShippingMode({});
    setShippingPrice(0);

    if (!selectedShippingAddress !== null) {
      let selectedAddress = selectedShippingAddress;
      if (userId !== null && addedProduct.length > 0)
        calculateShippingRates(selectedAddress, addedProduct, userId).then(
          async (res) => {
            if (res.status) {
              setTaxData(res.taxData);
              setEasyPostOrderId(res.order_id);
              setShippingBox(res.data);
              setShippingRates(res.rate);
              setTaxApplied(res.taxData.applyTaxRate);
              if (res.rate.length)
                setGroupedRatesByProvider(
                  groupRatesByProvider(res.rate, 'carrier')
                );
              if (res.message) {
                setShippingMessages(res.message);
              }
              setShippingModeError('');
            } else {
              if (res.message.__all__) {
                setShippingModeError(res.message.__all__[0]);
              } else if (res.message.parcels) {
                let error =
                  Object.keys(res.message.parcels[0]).length > 0 ? (
                    <p>
                      <span>
                        {res.message.parcels[0].__all__
                          ? res.message.parcels[0].__all__[0]
                          : null}
                      </span>
                      <br></br>
                      <span>
                        {res.message.parcels[0].weight
                          ? res.message.parcels[0].weight[0]
                          : null}
                      </span>
                      <br></br>
                      <span>
                        {res.message.parcels[0].height
                          ? res.message.parcels[0].height[0]
                          : null}
                      </span>
                      <br></br>
                      <span>
                        {res.message.parcels[0].length
                          ? res.message.parcels[0].length[0]
                          : null}
                      </span>
                      <br></br>
                      <span>
                        {res.message.parcels[0].width
                          ? res.message.parcels[0].width[0]
                          : null}
                      </span>
                      <br></br>
                    </p>
                  ) : null;

                setShippingModeError(error ?? 'Parcel Error');
              } else {
                setShippingModeError(res.message);
              }
              setEasyPostOrderId('');
              setShippingBox([]);
              setShippingRates([]);
              setGroupedRatesByProvider({});
              setShippingMessages([]);
            }
          }
        );
    }
  }, [
    selectedShippingAddress,
    selectedShippingLabel,
    addedProduct,
    isCartUpdated,
  ]);

  const groupRatesByProvider = (rate, key) => {
    return rate.reduce((reduceRate, reduceKey) => {
      (reduceRate[reduceKey[key]] = reduceRate[reduceKey[key]] ?? []).push(
        reduceKey
      );
      return reduceRate;
    }, {});
  };

  //### set userId & userType and get userslist if redirected from customer/dealer ###
  useEffect(() => {
    if (state !== null) {
      setUserId(state.userId);
      setUserType(state.userType);
      getUsersList(state.userType);
    }
  }, [state]);

  //############## get users list on userType change #################
  useEffect(() => {
    switch (userType) {
      case '2':
      case '3':
        setUserId('');
        setSelectedBillingAddress(null);
        setSelectedShippingAddress(null);
        getUsersList(userType);
        break;
    }
  }, [userType]);

  //########## set select user name to customer/dealer name redirected from ##########
  useEffect(() => {
    setUserValue(
      state !== null && usersList.length > 0
        ? usersList.filter((item, index) => {
            if (item.value == state.userId) return [item[index]];
          })
        : ''
    );
  }, [usersList]);

  //################ get selected user's address list ####################
  useEffect(() => {
    if (userId !== '') getUserAddressList(userId);
  }, [userId]);

  //########################### useEffect redirect if cart is empty ##############################
  useEffect(() => {
    if (is_submit) navigate('/admin/orders');
  }, [cartTotal, is_submit]);

  //########################### useEffect to set grand total ##############################
  useEffect(() => {
    let tax = 0.0;
    if (taxData) {
      if (taxData?.applyTaxRate) {
        const taxRate = Number(taxData.tax.value);
        let amount =
          Number(fittingTotalAmount) +
          Number(productTotalAmount) -
          Number(couponDiscount);
        tax = (Number(amount) * taxRate) / 100;
      }
    }
    setTaxTotal(tax);
    setGrandTotal(
      Number(fittingTotalAmount) +
        Number(productTotalAmount) +
        Number(shippingPrice) +
        Number(tax) -
        Number(couponDiscount) -
        Number(couponShippingDiscount)
    );
  }, [
    cartTotal,
    couponDiscount,
    productQuantity,
    shippingPrice,
    couponShippingDiscount,
    addedProduct,
    couponDetails,
  ]);

  //################## on select user-type set userType state #################
  const handleOnSelect = (e) => {
    setUserType(e.target.value);
  };

  //########### handel user change to load user data ####################
  const handleUserNameChange = (e, action) => {
    if (action.action === 'select-option') {
      setUserId(e.value);
      setUserValue([e]);
      setCustomerName(e.label);
    }
  };
  //###################### on payment status change ####################
  const handleOnChange = (e) => {
    if (e.target.name === 'payment_status') {
      setPaymentStatus(e.target.value);
    }
  };

  //########################### usestate for is coupon applied ##############################
  const [isApplied, setIsApplied] = useState(false);
  const [couponDetails, setCouponDetails] = useState({
    coupon_code: '',
    coupon_id: '',
  });

  const onRemoveClick = () => {
    removeCoupon(userId).then((res) => {
      if (res.status) {
        setDiscountAmount(0);
        setCouponShippingDiscount(0);
        let totalAmountData = fittingTotalAmount + productTotalAmount;
        setTotal(totalAmountData);
        setIsApplied(false);
        setCouponDetails({ coupon_code: '', coupon_id: '' });
      }
    });
  };
  const onChangeCouponCode = (e) => {
    e.target.value = e.target.value?.trim()?.replace(/[^a-zA-Z0-9]/g, '');
    setcouponCode(e.target.value?.trim());
  };

  const handleApplyCoupon = async () => {
    console.log('START');
    const res = await applyCoupon(
      couponCode,
      productTotalAmount,
      shippingPrice,
      userId
    );
    console.log('RES', res);
    if (res.status) {
      if (res.data.applicable_for === 'shipping') {
        setCouponShippingDiscount(
          res.data.discounted_amount < shippingPrice
            ? res.data.discounted_amount
            : shippingPrice
        );
        setIsApplied(true);
        setCouponDetails({
          coupon_code: res.data.coupon_code,
          coupon_id: res.data.coupon_id,
        });
      } else {
        console.log('CULPRIT');
        setDiscountAmount(res.data.discounted_amount);
        let totalAmountData =
          fittingTotalAmount + productTotalAmount - res.data.discounted_amount;
        setTotal(totalAmountData);
        setIsApplied(true);
        setCouponDetails({
          coupon_code: res.data.coupon_code,
          coupon_id: res.data.coupon_id,
        });
      }
    } else {
      setDiscountAmount(0);
      setTotal(total);
      setIsApplied(false);
      setCouponDetails({ coupon_code: '', coupon_id: '' });
    }
  };

  const reApplyCoupon = (shippingPrice) => {
    applyCoupon(couponCode, productTotalAmount, shippingPrice, userId).then(
      (res) => {
        removeAlert();
        if (res.status) {
          removeAlert();
          if (res.data.applicable_for === 'shipping') {
            setCouponShippingDiscount(
              res.data.discounted_amount < shippingPrice
                ? res.data.discounted_amount
                : shippingPrice
            );
            setIsApplied(true);
            setCouponDetails({
              coupon_code: res.data.coupon_code,
              coupon_id: res.data.coupon_id,
            });
          } else {
            setDiscountAmount(res.data.discounted_amount);
            let totalAmountData =
              fittingTotalAmount +
              productTotalAmount -
              res.data.discounted_amount;
            setTotal(totalAmountData);
            setIsApplied(true);
            setCouponDetails({
              coupon_code: res.data.coupon_code,
              coupon_id: res.data.coupon_id,
            });
          }
        } else {
          setDiscountAmount(0);
          setTotal(total);
          setIsApplied(false);
          setCouponDetails({ coupon_code: '', coupon_id: '' });
        }
      }
    );
  };

  //########################### usestate for delivery instruction ##############################
  const [deliveryInstruction, setDeliveryInstruction] = useState('');

  //########################## on submit create order ##############################

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      window.confirm(
        `Do you want to save this order and go to order preview page.`
      )
    ) {
      setShowAddEditForm(false);
      setPreviewForm(true);
    }
  };

  useEffect(() => {
    productsList &&
      productsList.map((product, idx) => {
        productListData.push({
          _id: product._id,
          value: product._id,
          label: product.product_title,
          slug: product.slug,
          status: product.status,
          product_title: product.product_title,
        });
      });
    setProductListValue(productListData);
  }, [productsList]);

  useEffect(() => {
    setCustomerName(userValue[0]?.label);
  }, [userValue])

  return (
    <>
      <div className="animated fadeIn">
        <SelectVariations
          slug={slug}
          toggle={toggleVariationModal}
          variation={variation}
          setVariation={setVariation}
          handleVariationSelect={handleVariationSelect}
          userId={userId}
          userType={userType}
          isOpen={variationModal}
          setProductVariationDetails={setProductVariationDetails}
          selectedFitting={selectedFitting}
          setSelectedFitting={setSelectedFitting}
          setVariationSelectErrorMsg={setVariationSelectErrorMsg}
          variationSelectErrorMsg={variationSelectErrorMsg}
          selectedFittingsData={selectedFittingsData}
          setQuantity={setQuantity}
          quantity={quantity}
          isEdit={isEdit}
          selectedProduct={selectedProduct}
          setSelectedFittingsData={setSelectedFittingsData}
          setCustomization={setCustomization}
          setStockQuantity={setStockQuantity}
          stockQuantity={stockQuantity}
          reset={resetVariationModal}
        ></SelectVariations>
        {showAddEditForm && (
          <Form
            id="addEditForm"
            className="form-horizontal"
            onSubmit={(e) => onSubmit(e)}
          >
            <Card>
              <CardHeader>
                {' '}
                <h5>Create Order </h5>
              </CardHeader>
              <CardBody>
                <Container fluid className="p-0">
                  <Row className="orderaddedittable">
                    <Col sm="12">
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <Label for="user_type">Customer Type</Label>
                            <Input
                              type="select"
                              name="user_type"
                              id="user_type"
                              value={userType}
                              onChange={(e) => handleOnSelect(e)}
                            >
                              {Object.keys(ORDER_CREATE_FOR_USER_ROLE).map(
                                (key) => (
                                  <option value={key} key={key}>
                                    {ORDER_CREATE_FOR_USER_ROLE[key]}
                                  </option>
                                )
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label for="user_type">Name</Label>
                            <Select
                              value={userValue}
                              isLoading={user_list_loading}
                              isClearable={false}
                              options={usersList}
                              onChange={(e, action = { action }) => {
                                handleUserNameChange(e, action);
                              }}
                              keepSelectedInList={false}
                              placeholder={
                                userType === '2'
                                  ? 'Search customer name'
                                  : 'Search dealer name'
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {userId ? (
                    <div className="mt-20">
                      <div>
                        <table className="orderaddedittable">
                          <thead>
                            {/* <tr>
                              <th colSpan="5">
                                <h5>Products </h5>
                              </th>
                            </tr> */}
                            <tr>
                              <th>Product</th>
                              <th>Cost</th>
                              <th>Quantity</th>
                              <th>Total</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {addedProduct.map((item, index) => {
                              let fittingTotalPrice = 0;
                              return (
                                <>
                                  <tr key={index} className="bg-light">
                                    <td>
                                      <table>
                                        <tr>
                                          <td className="productheadings">
                                            <strong>
                                              {item.product_title}-
                                              {item.category_name}
                                            </strong>
                                          </td>
                                        </tr>
                                        {item.productVariationDetail.length >
                                        0 ? (
                                          <>
                                            <tr className="product-SKU">
                                              <td>
                                                <p className="mb-0">
                                                  <span className="table-label">
                                                    SKU:
                                                  </span>
                                                  {''}
                                                  {item.productVariationSku}
                                                </p>
                                              </td>
                                            </tr>
                                            <tr className="product-infromation">
                                              <td>
                                                <div className="">
                                                  {item.productVariationDetail.map(
                                                    (variation, key) => {
                                                      return (
                                                        <p
                                                          className="mb-0"
                                                          key={key}
                                                        >
                                                          <span className="table-label">
                                                            {' '}
                                                            {
                                                              variation.attribute_name
                                                            }{' '}
                                                            :
                                                          </span>{' '}
                                                          {
                                                            variation.term_option
                                                          }
                                                        </p>
                                                      );
                                                    }
                                                  )}
                                                </div>

                                                {item.productFitting &&
                                                item.productFitting.length > 0
                                                  ? item.productFitting.map(
                                                      (fittingOptions) => {
                                                        if (
                                                          fittingOptions.attribute_name ==
                                                          'Playing Length'
                                                        ) {
                                                          return (
                                                            <>
                                                              <div>
                                                                <span className="table-label">
                                                                  {' '}
                                                                  Playing Length
                                                                  :{' '}
                                                                </span>{' '}
                                                                {
                                                                  fittingOptions.attribute_value
                                                                }
                                                              </div>
                                                            </>
                                                          );
                                                        }

                                                        if (
                                                          fittingOptions.attribute_name ==
                                                          'Extra Wrap'
                                                        ) {
                                                          return (
                                                            <>
                                                              <div>
                                                                <span className="table-label">
                                                                  {' '}
                                                                  Extra Wraps :
                                                                </span>
                                                                {fittingOptions.attribute_value &&
                                                                fittingOptions.attribute_value ==
                                                                  1
                                                                  ? 'None'
                                                                  : fittingOptions.attribute_value ==
                                                                    2
                                                                  ? '1'
                                                                  : fittingOptions.attribute_value ==
                                                                    3
                                                                  ? '2'
                                                                  : 'None'}
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                        if (
                                                          fittingOptions.attribute_name ==
                                                          'Grip Logo'
                                                        ) {
                                                          return (
                                                            <>
                                                              <div>
                                                                <span className="table-label">
                                                                  {' '}
                                                                  Grip Logo :
                                                                </span>
                                                                {fittingOptions.attribute_value &&
                                                                  fittingOptions.attribute_value}
                                                              </div>
                                                            </>
                                                          );
                                                        }

                                                        if (
                                                          fittingOptions.attribute_name ==
                                                          'Adaptors'
                                                        ) {
                                                          return (
                                                            <>
                                                              <div>
                                                                <span className="table-label">
                                                                  {' '}
                                                                  Adaptor :{' '}
                                                                </span>
                                                                {
                                                                  fittingOptions.option_name
                                                                }
                                                              </div>
                                                              <div>
                                                                <span className="table-label">
                                                                  {' '}
                                                                  Adaptor
                                                                  Setting :
                                                                </span>{' '}
                                                                {
                                                                  fittingOptions.sub_option_name
                                                                }
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                        if (
                                                          fittingOptions.attribute_name ==
                                                          'Grips'
                                                        ) {
                                                          return (
                                                            <>
                                                              <div>
                                                                <span className="table-label">
                                                                  {' '}
                                                                  Grips Brand :
                                                                </span>{' '}
                                                                {
                                                                  fittingOptions.option_name
                                                                }
                                                              </div>
                                                              <div>
                                                                <span className="table-label">
                                                                  {' '}
                                                                  Grip Model :
                                                                </span>{' '}
                                                                {
                                                                  fittingOptions.sub_option_name
                                                                }
                                                              </div>
                                                            </>
                                                          );
                                                        }

                                                        if (
                                                          fittingOptions.attribute_name ==
                                                          'Product Customization'
                                                        ) {
                                                          return (
                                                            <>
                                                              <div className="product_customization_notes">
                                                                <span className="table-label">
                                                                  {' '}
                                                                  Product
                                                                  Customization
                                                                  :
                                                                </span>{' '}
                                                                {
                                                                  fittingOptions.attribute_value
                                                                }
                                                              </div>
                                                            </>
                                                          );
                                                        }

                                                        {
                                                          <div>
                                                            <span className="table-label">
                                                              {' '}
                                                              Quantity :
                                                            </span>{' '}
                                                            1
                                                          </div>;
                                                        }
                                                      }
                                                    )
                                                  : ''}
                                              </td>
                                            </tr>
                                          </>
                                        ) : null}
                                      </table>
                                    </td>
                                    <td>
                                      <table>
                                        <tr>
                                          <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                          <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            ${item.price}
                                            {item.productVariationDetail.map(
                                              (variation, key) => {
                                                return (
                                                  <p className="mb-0" key={key}>
                                                    &nbsp;
                                                  </p>
                                                );
                                              }
                                            )}
                                            {item.productFitting &&
                                            item.productFitting.length > 0
                                              ? item.productFitting.map(
                                                  (fittingOptions) => {
                                                    if (
                                                      fittingOptions.attribute_name ==
                                                      'Adaptors'
                                                    ) {
                                                      return (
                                                        <>
                                                          <div>
                                                            $
                                                            {Number(
                                                              fittingOptions.price
                                                            ).toFixed(2)}
                                                          </div>
                                                          <div>&nbsp;</div>
                                                        </>
                                                      );
                                                    }
                                                    if (
                                                      fittingOptions.attribute_name ==
                                                      'Grips'
                                                    ) {
                                                      return (
                                                        <>
                                                          <div>&nbsp;</div>
                                                          <div>
                                                            $
                                                            {Number(
                                                              fittingOptions.price
                                                            ).toFixed(2)}
                                                          </div>
                                                        </>
                                                      );
                                                    }

                                                    if (
                                                      fittingOptions.attribute_name ==
                                                      'Playing Length'
                                                    ) {
                                                      return (
                                                        <>
                                                          <div>&nbsp;</div>
                                                        </>
                                                      );
                                                    }

                                                    if (
                                                      fittingOptions.attribute_name ==
                                                      'Extra Wrap'
                                                    ) {
                                                      return (
                                                        <>
                                                          <div>&nbsp;</div>
                                                        </>
                                                      );
                                                    }
                                                    if (
                                                      fittingOptions.attribute_name ==
                                                      'Grip Logo'
                                                    ) {
                                                      return (
                                                        <>
                                                          <div>&nbsp;</div>
                                                        </>
                                                      );
                                                    }
                                                  }
                                                )
                                              : ''}
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                    <td>
                                      <table>
                                        <tr>
                                          <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                          <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                          <td>{item.quantity}</td>
                                        </tr>
                                      </table>
                                    </td>
                                    <td>
                                      <table>
                                        <tr>
                                          <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                          <td>&nbsp;</td>
                                        </tr>
                                        <tr></tr>
                                        {item.productFitting.length > 0
                                          ? item.productFitting.map(
                                              (fitting, idx) => {
                                                fitting.price &&
                                                fitting.price > 0
                                                  ? (fittingTotalPrice = Number(
                                                      parseFloat(
                                                        fittingTotalPrice
                                                      ) +
                                                        parseFloat(
                                                          fitting.price
                                                        )
                                                    ).toFixed(2))
                                                  : '';
                                              }
                                            )
                                          : null}
                                        <tr>
                                          <td>
                                            $
                                            {Number(
                                              parseFloat(item.price) *
                                                parseFloat(item.quantity) +
                                                parseFloat(fittingTotalPrice)
                                            ).toFixed(2)}
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                    <td>
                                      <table>
                                        <tr>
                                          <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                          <td>&nbsp;</td>
                                        </tr>
                                        <tr></tr>

                                        <tr>
                                          <td>
                                            <Button
                                              type="button"
                                              className="btn-success btn-sm mr-05"
                                              onClick={() => {
                                                editProduct(item);
                                              }}
                                            >
                                              <i className="fa fa-pencil"></i>
                                            </Button>

                                            <Button
                                              type="button"
                                              className="btn-danger btn-sm"
                                              onClick={() => {
                                                if (
                                                  window.confirm(
                                                    `Are you sure, you want to delete ${item.product_title} product?`
                                                  )
                                                ) {
                                                  deleteProduct(
                                                    cartId,
                                                    item._id
                                                  );
                                                }
                                              }}
                                            >
                                              <i className="fa fa-trash"></i>
                                            </Button>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>

                      <Row className="mt-3">
                        <div className="couponcode mb-2">
                          <h5>Add Product</h5>
                        </div>

                        <Col sm="12">
                          <FormGroup>
                            <Select
                              value={[]}
                              isClearable={false}
                              id="product"
                              name="product"
                              options={productListValue && productListValue}
                              onChange={(e, action = { action }) => {
                                handleSelectProduct(e, action);
                              }}
                              keepSelectedInList={false}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <BillingDetail
                        userId={userId}
                        setSelectedBillingAddress={setSelectedBillingAddress}
                        setSelectedBillingLabel={setSelectedBillingLabel}
                        setSelectedShippingAddress={setSelectedShippingAddress}
                        setSelectedShippingLabel={setSelectedShippingLabel}
                        shippToDifferentAddressChecked={
                          shippToDifferentAddressChecked
                        }
                        setShippToDifferentAddressChecked={
                          setShippToDifferentAddressChecked
                        }
                        editAddress={''}
                      />

                      {(selectedBillingAddress != null ||
                        selectedShippingAddress != null) &&
                      addedProduct.length ? (
                        <div className="mt-20">
                          <h5>Shipping Service </h5>
                          <div className="order-margin">
                            <ShippingRates
                              setSelectedShippingMode={setSelectedShippingMode}
                              setShippingPrice={setShippingPrice}
                              shippingModeError={shippingModeError}
                              shippingMessages={shippingMessages}
                              groupedRatesByProvider={groupedRatesByProvider}
                              shippingRates={shippingRates}
                              reApplyCoupon={reApplyCoupon}
                              isApplied={isApplied}
                            />
                          </div>
                        </div>
                      ) : null}

                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="couponcode">
                              <Label for="payment_status">
                                <strong>Payment status</strong>
                              </Label>
                            </div>
                            <Input
                              type="select"
                              name="payment_status"
                              id="payment_status"
                              defaultValue={1}
                              onChange={(e) => handleOnChange(e)}
                            >
                              {Object.keys(ORDER_LIST_PAYMENT_STATUS).map(
                                (key) => (
                                  <option value={key} key={key}>
                                    {ORDER_LIST_PAYMENT_STATUS[key]}
                                  </option>
                                )
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="couponcode">
                              <Label for="deliveryInstruction">
                                <strong>Delivery Instructions</strong>
                              </Label>
                            </div>
                            <Input
                              type="text"
                              className="form-control text-left"
                              id="deliveryInstruction"
                              name="deliveryInstruction"
                              maxLength="100"
                              // placeholder="Delivery Instruction(max chars limit is 100)"
                              value={deliveryInstruction}
                              onChange={(e) =>
                                setDeliveryInstruction(e.target.value)
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {(userId &&
                        addedProduct.length &&
                        Object.keys(selectedShippingMode).length) ||
                      couponShippingDiscount !== 0 ||
                      couponDiscount !== 0 ? (
                        <div className="mt-20">
                          <div className="couponcode">
                            <strong>Coupon Codes </strong>
                          </div>
                          {isApplied ? (
                            <>
                              <strong className="coupon-display">
                                {couponDetails?.coupon_code
                                  ? couponDetails?.coupon_code
                                  : ''}
                              </strong>
                              <span
                                onClick={(e) => onRemoveClick(e)}
                                className="remove-coupon-span"
                              >
                                <i className="fa fa-close"></i>
                              </span>
                            </>
                          ) : (
                            <Row>
                              <Col>
                                <Input
                                  id="coupon"
                                  type="text"
                                  name="coupon"
                                  maxLength={50}
                                  autoComplete="false"
                                  placeholder="Coupon Code"
                                  onChange={(e) => onChangeCouponCode(e)}
                                  className="form-control text-left"
                                  disabled={couponDiscount > 0 ? true : false}
                                />
                              </Col>
                              {couponDiscount <= 0 ? (
                                <Col>
                                  <Button
                                    className="mr-05"
                                    size="sm"
                                    color="primary"
                                    onClick={handleApplyCoupon}
                                  >
                                    Apply
                                  </Button>
                                </Col>
                              ) : null}
                            </Row>
                          )}
                        </div>
                      ) : null}
                      <div>
                        {addedProduct.length ? (
                          <div className="mt-20">
                            <Row className="couponcodelist priceSummary">
                              <Col>
                                {productTotalAmount &&
                                productTotalAmount > 0 ? (
                                  <div className="couponcode">
                                    <strong>Product(s) Total : </strong>$
                                    {Number(productTotalAmount).toFixed(2)}
                                  </div>
                                ) : null}
                                {couponDiscount !== 0 ? (
                                  <div className="couponcode">
                                    <strong>Discount : </strong> - ($
                                    {Number(couponDiscount).toFixed(2)})
                                  </div>
                                ) : null}
                                {fittingTotalAmount &&
                                fittingTotalAmount > 0 ? (
                                  <div className="couponcode">
                                    <strong>Build Parameter(s) total :</strong>{' '}
                                    ${Number(fittingTotalAmount).toFixed(2)}
                                  </div>
                                ) : null}
                                {taxTotal && taxTotal > 0 ? (
                                  <>
                                    <div className="couponcode">
                                      <strong>Total before Tax :</strong> $
                                      {(
                                        Number(fittingTotalAmount) +
                                        Number(productTotalAmount) -
                                        Number(couponDiscount)
                                      ).toFixed(2)}
                                    </div>
                                    <div className="couponcode">
                                      <strong>Sales Tax :</strong> $
                                      {Number(taxTotal).toFixed(2)}
                                    </div>
                                  </>
                                ) : null}

                                {shippingPrice !== 0 ? (
                                  <div className="couponcode">
                                    <strong>Shipping :</strong> $
                                    {Number(shippingPrice).toFixed(2)}
                                  </div>
                                ) : null}
                                {couponShippingDiscount !== 0 ? (
                                  <div className="couponcode">
                                    <strong>Shipping Discount : </strong> - ($
                                    {Number(couponShippingDiscount).toFixed(2)})
                                  </div>
                                ) : null}
                                <div className="couponcode">
                                  <strong>Grand Total : </strong>$
                                  {Number(grandTotal).toFixed(2)}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </Container>
              </CardBody>
              {Object.keys(selectedShippingMode).length ? (
                <CardFooter>
                  <Button
                    className="mr-05"
                    type="submit"
                    size="sm"
                    color="primary"
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Link to="/admin/orders" className="cancle-btn m-0">
                    <Button
                      type="reset"
                      size="sm"
                      color="danger"
                      className="m-0"
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </CardFooter>
              ) : null}
            </Card>
          </Form>
        )}
        {showPreviewForm && (
          <PreviewForm
            orderId={''}
            userType={userType}
            customerName={customerName}
            userId={userId}
            addedProduct={addedProduct}
            selectedBillingAddress={selectedBillingAddress}
            selectedShippingAddress={selectedShippingAddress}
            selectedBillingLabel={selectedBillingLabel}
            selectedShippingLabel={selectedShippingLabel}
            payment_status={payment_status}
            deliveryInstruction={deliveryInstruction}
            isApplied={isApplied}
            couponDetails={couponDetails}
            productTotalAmount={productTotalAmount}
            fittingTotalAmount={fittingTotalAmount}
            shippingPrice={shippingPrice}
            couponDiscount={couponDiscount}
            couponShippingDiscount={couponShippingDiscount}
            grandTotal={grandTotal}
            currentCart={currentCart}
            cartTotal={cartTotal}
            shippToDifferentAddressChecked={shippToDifferentAddressChecked}
            shippingBox={shippingBox}
            selectedShippingMode={selectedShippingMode}
            easyPostOrderId={easyPostOrderId}
            setShowAddEditForm={setShowAddEditForm}
            setPreviewForm={setPreviewForm}
            setIsSubmit={setIsSubmit}
            addToOrder={addToOrder}
            taxApplied={taxApplied}
            totalBeforeTax={totalBeforeTax}
            taxTotal={taxTotal}
            isEdit={false}
          />
        )}
      </div>
    </>
  );
};

CreateOrder.propTypes = {
  getUsersList: PropTypes.func.isRequired,
  applyCoupon: PropTypes.func.isRequired,
  getUserAddressList: PropTypes.func.isRequired,
  user_list_loading: PropTypes.bool.isRequired,
  calculateShippingRates: PropTypes.func.isRequired,
  addToOrder: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  usersList: state.order.usersList,
  productsList: state.order.productsList,
  user_list_loading: state.order.user_list_loading,
});

export default connect(mapStateToProps, {
  getProductsList,
  getUsersList,
  applyCoupon,
  getCartDetails,
  addToCart,
  getUserAddressList,
  calculateShippingRates,
  addToOrder,
  updateCart,
  removeCoupon,
  getTaxRates,
})(CreateOrder);
