import axios from 'axios';

import {
  SPECS_LOADING,
  SPECS_CREATED,
  SPECS_CREATE_ERROR,
  SPECS_FAIL,
  SPECS_UPDATED,
  SPECS_UPDATE_ERROR,
  SPECS_DELETED,
  SPECS_DELETE_ERROR,
  SPECS_LIST_UPDATED,
  GET_SPECS_BY_ID,
  SPECS_CHANGE_STATUS,
  SPECS_CHANGE_STATUS_ERROR,
  SPECS_TAG_DELETED,
  SPECS_TAG_DELETE_ERROR,
  SET_ERRORS_LIST,
  REMOVE_ERRORS
} from 'actions/types';
import { removeErrors } from './removeErrors';

// Create specs
export const add = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: SPECS_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post('/api/admin/specs/add', formData, config);
    if (res.data.status === true) {
      dispatch({
        type: SPECS_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/specs');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg }
        });
      }
      dispatch({
        type: SPECS_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList }
      })
    }
  } catch (err) {
    dispatch({
      type: SPECS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit Specs
export const edit = (formData, history, specs_id) => async (dispatch) => {
  try {
    dispatch({
      type: SPECS_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/specs/${specs_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: SPECS_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/specs');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      let errorMsg = "";
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg }
        });
      }
      dispatch({
        type: SPECS_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList }
      })
    }
  } catch (err) {
    dispatch({
      type: SPECS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete Specs
export const deleteSpecs = (specs_id) => async (dispatch) => {
  try {
    dispatch({
      type: SPECS_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(`/api/admin/specs/${specs_id}`, config);
    if (res.data.status === true) {
      dispatch({
        type: SPECS_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: SPECS_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: SPECS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get specs list

export const getSpecsList = (specsParams, specId) => async (dispatch) => {
  try {
    dispatch({
      type: SPECS_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: specsParams,
    };

    const res = await axios.get( specId == 'all' ? `/api/admin/specs` : `/api/admin/specs/spec_question`, config);

    dispatch({
      type: SPECS_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          specsParams: specsParams,
          specsList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    dispatch({
      type: SPECS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete specs
export const getSpecsById = (specs_id) => async (dispatch) => {
  try {
    dispatch({
      type: SPECS_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    dispatch({
      type: REMOVE_ERRORS,
    })
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/specs/${specs_id}`, config);

    await dispatch({
      type: GET_SPECS_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: SPECS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//change status
export const changeStatus = (specs_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: SPECS_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/specs/change-status/${specs_id}`,
      { status },
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: SPECS_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: SPECS_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: specs_id,
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: SPECS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

export const deleteSpecsAndVariations = (tagId) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const res = await axios.delete(
    `/api/admin/specs/removeVariations/${tagId}`,
    config
  );
  if (res.data.status) {
    await dispatch({
      type: SPECS_TAG_DELETED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: tagId,
      },
    });
    return { status: true };
  } else {
    dispatch({
      type: SPECS_TAG_DELETE_ERROR,
      payload: {
        message: res.data.message,
        alertType: 'danger',
      },
    });
  }
};

// Delete SpecsTag
export const deleteTag = (tagId) => async (dispatch) => {
  try {
    dispatch({
      type: SPECS_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(`/api/admin/specs/tag/${tagId}`, config);
    if (res.data.status === true) {
      await dispatch({
        type: SPECS_TAG_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: tagId,
        },
      });
      return { status: true };
    } else {
      return { status: false, message: res.data.message };
    }
  } catch (err) {
    dispatch({
      type: SPECS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};
