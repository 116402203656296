import {
  PROFILE_LOADING,
  USER_PROFILE_UPDATED,
  USER_PROFILE_UPDATED_ERROR,
  USER_PROFILE_FAIL,
  USER_PASSWORD_UPDATED,
  USER_PASSWORD_UPDATE_ERROR,
  USER_PROFILE,
  USER_PROFILE_ERROR,
} from "actions/types";

const initalState = {
  isSubLoading: false,
  loading: false,
  error: {},
  user: null,
  modal_loading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case PROFILE_LOADING:
      return {
        ...state,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case USER_PROFILE_UPDATED:
      return {
        ...state,
        user: payload.data,
        loading: false,
        modal_loading: false,
      };
    case USER_PROFILE_UPDATED_ERROR:
      return {
        ...state,
        loading: false,
        modal_loading: false,
      };
    case USER_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        modal_loading: false,
      };
    case USER_PASSWORD_UPDATED:
      return {
        ...state,
        modal_loading: false,
        loading: false,
      };
    case USER_PASSWORD_UPDATE_ERROR:
      return {
        ...state,
        modal_loading: false,
        loading: false,
      };
    case USER_PROFILE:
      return {
        ...state,
        user: payload.data,
        loading: false,
      };
    case USER_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
