import {
  PACKING_SLIP_LOADING,
  PACKING_SLIP_CREATED,
  PACKING_SLIP_CREATE_ERROR,
  PACKING_SLIP_FAIL,
  PACKING_SLIP_UPDATED,
  PACKING_SLIP_UPDATE_ERROR,
  PACKING_SLIP_DELETED,
  PACKING_SLIP_DELETE_ERROR,
  PACKING_SLIP_LIST_UPDATED,
  GET_PACKING_SLIP_BY_ID,
} from "actions/types";

const initalState = {
  packingSlipList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentPackingSlip: {},
  loading: false,
  isSubLoading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case PACKING_SLIP_LOADING:
      return {
        ...state,
        packingSlipList: payload.is_sub_element
          ? state.packingSlipList
          : initalState.packingSlipList,
        currentPackingSlip: payload.is_sub_element
          ? state.currentPackingSlip
          : initalState.currentPackingSlip,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case PACKING_SLIP_CREATED:
      return {
        ...state,
        loading: false,
      };
    case PACKING_SLIP_CREATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case PACKING_SLIP_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case PACKING_SLIP_UPDATED:
      return {
        ...state,
        currentPackingSlip: {},
        loading: false,
      };
    case PACKING_SLIP_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case PACKING_SLIP_DELETED:
      return {
        ...state,
        loading: false,
      };
    case PACKING_SLIP_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case PACKING_SLIP_LIST_UPDATED:
      return {
        ...state,
        packingSlipList: {
          data: payload.data.packingSlipList.data,
          page: payload.data.packingSlipList.metadata[0].current_page,
          count: payload.data.packingSlipList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_PACKING_SLIP_BY_ID:
      return {
        ...state,
        currentPackingSlip: payload.data,
        loading: false,
      };
    default:
      return state;
  }
}
