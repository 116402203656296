import axios from 'axios';

import {
  PACKING_SLIP_LOADING,
  PACKING_SLIP_CREATED,
  PACKING_SLIP_CREATE_ERROR,
  PACKING_SLIP_FAIL,
  PACKING_SLIP_UPDATED,
  PACKING_SLIP_UPDATE_ERROR,
  PACKING_SLIP_DELETED,
  PACKING_SLIP_DELETE_ERROR,
  PACKING_SLIP_LIST_UPDATED,
  GET_PACKING_SLIP_BY_ID,
} from 'actions/types';

// Create packing slip
export const create = (formData, navigate) => async (dispatch) => {
  try {
    dispatch({
      type: PACKING_SLIP_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'content-type': 'application/json',
      },
    };
    const res = await axios.post('/api/admin/packing-slip/create', formData, config);
    if (res.data.status === true) {
      dispatch({
        type: PACKING_SLIP_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      navigate('/admin/packing-slips');
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: PACKING_SLIP_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: PACKING_SLIP_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit Packing Slip
export const edit = (formData, navigate, packing_slip_id, navigateUrl) => async (dispatch) => {
  try {
    dispatch({
      type: PACKING_SLIP_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'content-type': 'application/json',
      },
    };

    const res = await axios.post(`/api/admin/packing-slip/${packing_slip_id}`, formData, config);
    if (res.data.status === true) {
      dispatch({
        type: PACKING_SLIP_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      navigate(navigateUrl);
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: PACKING_SLIP_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: PACKING_SLIP_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete Packing Slip
export const deletePackingSlip = (packing_slip_id) => async (dispatch) => {
  try {
    dispatch({
      type: PACKING_SLIP_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(`/api/admin/packing-slip/${packing_slip_id}`, config);

    if (res.data.status === true) {
      dispatch({
        type: PACKING_SLIP_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: PACKING_SLIP_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: PACKING_SLIP_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get packing slip list

export const getPackingSlipList = (packingSlipParams) => async (dispatch) => {
  try {
    dispatch({
      type: PACKING_SLIP_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    
    const query = packingSlipParams.query ? packingSlipParams.query : '&query=';
    const res = await axios.get(
      `/api/admin/packing-slip?limit=${packingSlipParams.limit}&page=${packingSlipParams.page}&orderBy=${packingSlipParams.orderBy}&ascending=${packingSlipParams.ascending}${query}`,
      config
    );
    dispatch({
      type: PACKING_SLIP_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          packingSlipParams: packingSlipParams,
          packingSlipList: res.data.response[0],
        },
      },
    });
  } catch (err) {
    dispatch({
      type: PACKING_SLIP_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Get packing slip by id
export const getPackingSlipById = (packing_slip_id) => async (dispatch) => {
  try {
    dispatch({
      type: PACKING_SLIP_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/packing-slip/${packing_slip_id}`, config);

    await dispatch({
      type: GET_PACKING_SLIP_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: PACKING_SLIP_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
