import axios from 'axios';

import {
  VIDEO_GALLERY_UPLOADED,
  VIDEO_GALLERY_UPLOAD_ERROR,
  VIDEO_GALLERY_FAIL,
  VIDEO_GALLERY_DELETE,
  VIDEO_GALLERY_DELETE_ERROR,
  VIDEO_GALLERY_UPDATED,
  VIDEO_GALLERY_UPDATE_ERROR,
  VIDEO_GALLERY_LIST_UPDATED,
  VIDEO_GALLERY_GET_VIDEO_BY_ID,
  VIDEO_GALLERY_LOADING,
  SET_ERRORS_LIST
} from 'actions/types';

// Upload Immage
export const upload = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: VIDEO_GALLERY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    // let param = new window.FormData();
    // param.append('file', formData.selectedFile);
    // param.append('title', formData.title);
    // param.append('description', formData.description);
    // param.append('video', formData.video);
    const res = await axios.post(
      '/api/admin/video-gallery/upload',
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: VIDEO_GALLERY_UPLOADED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/gallery-video');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = {...errorsList, [error.param]: error.msg};
        });
      }
      dispatch({
        type: VIDEO_GALLERY_UPLOAD_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: {
          ...errorsList,
        }
      })
    }
  } catch (err) {
    dispatch({
      type: VIDEO_GALLERY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit Video data
export const edit = (formData, history, video_id) => async (dispatch) => {
  try {
    dispatch({
      type: VIDEO_GALLERY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    // let param = new window.FormData();
    // param.append('file', formData.selectedFile);
    // param.append('title', formData.title);
    // param.append('description', formData.description);
    // param.append('oldVideo', formData.oldVideo);
    // param.append('video', formData.video);
    // param.append('isNewFileSelected', formData.isNewFileSelected);
    const res = await axios.post(
      `/api/admin/video-gallery/${video_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: VIDEO_GALLERY_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/gallery-video');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = {...errorsList, [error.param]: error.msg};
        });
      }
      dispatch({
        type: VIDEO_GALLERY_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: {
          ...errorsList,
        }
      })
    }
  } catch (err) {
    dispatch({
      type: VIDEO_GALLERY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete Video
export const deleteVideo = (video_id) => async (dispatch) => {
  try {
    dispatch({
      type: VIDEO_GALLERY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'content-type': 'application/json;',
      },
    };
    const res = await axios.delete(
      `/api/admin/video-gallery/${video_id}`,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: VIDEO_GALLERY_DELETE,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: VIDEO_GALLERY_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: VIDEO_GALLERY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get video list

export const getVideoList = (videoParams) => async (dispatch) => {
  try {
    dispatch({
      type: VIDEO_GALLERY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: videoParams,
    };
    
    const res = await axios.get(
      `/api/admin/video-gallery`,
      config
    );
    dispatch({
      type: VIDEO_GALLERY_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          videoParams: videoParams,
          videoList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    
    dispatch({
      type: VIDEO_GALLERY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Get video by id
export const getVideoById = (video_id) => async (dispatch) => {
  try {
    dispatch({
      type: VIDEO_GALLERY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'content-type': 'application/json;',
      },
    };
    const res = await axios.get(`/api/admin/video-gallery/${video_id}`, config);

    await dispatch({
      type: VIDEO_GALLERY_GET_VIDEO_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
    
  } catch (err) {
    dispatch({
      type: VIDEO_GALLERY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// page not found
export const notFound = (history) => async (dispatch) => {
  history('/admin/page-not-found');
};
