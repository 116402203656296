import axios from 'axios';

import {
  USER_ADDRESS_LOADING,
  USER_ADDRESS_CREATED,
  USER_ADDRESS_CREATE_ERROR,
  USER_ADDRESS_FAIL,
  USER_ADDRESS_UPDATED,
  USER_ADDRESS_UPDATE_ERROR,
  USER_ADDRESS_DELETED,
  USER_ADDRESS_DELETE_ERROR,
  USER_ADDRESS_LIST_UPDATED,
  GET_USER_ADDRESS_BY_ID,
  USER_ADDRESS_PRIMARY,
  USER_ADDRESS_PRIMARY_ERROR,
  USER_ADDRESS_CHANGE_STATUS,
  USER_ADDRESS_CHANGE_STATUS_ERROR,
  GET_USER_DETAILS_BY_ID,
  SET_ERRORS_LIST,
} from 'actions/types';

import { updateFilterData } from 'actions/commonActions';

// Add customer address
export const add =
  (formData, customerName, customer_id, history) => async (dispatch) => {
    try {
      dispatch({
        type: USER_ADDRESS_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      const config = { headers: { 'Content-Type': 'application/json' } };
      const res = await axios.post(
        `/api/admin/user-address/add/${customer_id}`,
        formData,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: USER_ADDRESS_CREATED,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
        history(
          `/admin/customers/customer-address/${customerName}/${customer_id}`
        );
      } else {
        const errors = res.data.errors;
        let errorsList = {};
        if (errors) {
          await errors.forEach((error) => {
            errorsList = { ...errorsList, [error.param]: error.msg };
          });
        }
        dispatch({
          type: USER_ADDRESS_CREATE_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
            errorsList,
          },
        });
        dispatch({
          type: SET_ERRORS_LIST,
          payload: { ...errorsList }
        })
      }
    } catch (err) {
      dispatch({
        type: USER_ADDRESS_FAIL,
        payload: {
          message: err.response?.data?.message ?? 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

// Edit customer address
export const edit =
  (formData, customerName, customer_address_id, user_id, history) =>
    async (dispatch) => {
      try {
        dispatch({
          type: USER_ADDRESS_LOADING,
          payload: { is_loading: true, is_sub_element: false },
        });
        const config = { headers: { 'Content-Type': 'application/json' } };
        const res = await axios.post(
          `/api/admin/user-address/edit/${customer_address_id}`,
          formData,
          config
        );
        if (res.data.status === true) {
          dispatch({
            type: USER_ADDRESS_UPDATED,
            payload: {
              message: res.data.message,
              alertType: 'success',
              data: res.data.response,
            },
          });
          history(`/admin/customers/customer-address/${customerName}/${user_id}`);
        } else {
          const errors = res.data.errors;
          let errorsList = {};

          if (errors) {
            await errors.forEach((error) => {
              errorsList = { ...errorsList, [error.param]: error.msg };
            });
          }
          dispatch({
            type: USER_ADDRESS_UPDATE_ERROR,
            payload: {
              message: res.data.message,
              alertType: 'danger',
              errorsList,
            },
          });
          dispatch({
            type: SET_ERRORS_LIST,
            payload: { ...errorsList }
          })
        }
      } catch (err) {
        dispatch({
          type: USER_ADDRESS_FAIL,
          payload: {
            message: err.response?.data?.message ?? 'Something went wrong.',
            alertType: 'danger',
            error: err,
          },
        });
      }
    };

// Delete Customer address  by customer_address_id
export const deleteUserAddress = (customer_address_id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_ADDRESS_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(
      `/api/admin/user-address/${customer_address_id}`,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: USER_ADDRESS_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: USER_ADDRESS_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: USER_ADDRESS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get customer address list by customer_id
export const getUserAddressList =
  (userAddressParams, customer_id) => async (dispatch) => {
    try {
      dispatch({
        type: USER_ADDRESS_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      userAddressParams = updateFilterData(userAddressParams);
      const config = {
        headers: { 'Content-Type': 'application/json' },
        params: userAddressParams,
      };

      const res = await axios.get(
        `/api/admin/user-address/${customer_id}/`,
        config
      );
      dispatch({
        type: USER_ADDRESS_LIST_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: {
            userAddressParams: userAddressParams,
            userAddressList: res.data.response[0],
          },
        },
      });
      return res;
    } catch (err) {
      dispatch({
        type: USER_ADDRESS_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

// Get Customer address by customer_address_id
export const getUserAddressById = (customer_address_id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_ADDRESS_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/user-address/getById/${customer_address_id}`,
      config
    );
    await dispatch({
      type: GET_USER_ADDRESS_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: USER_ADDRESS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//mark address as primary by customer_address_id
export const markAsPrimary =
  (customer_id, customer_address_id) => async (dispatch) => {
    try {
      dispatch({
        type: USER_ADDRESS_LOADING,
        payload: { is_loading: true, is_sub_element: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/user-address/markPrimary/${customer_id}/${customer_address_id}`,
        {},
        config
      );
      if (res.data.status === true) {
        await dispatch({
          type: USER_ADDRESS_PRIMARY,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
      } else {
        //add a key is_failed=true else is_failed=false
        dispatch({
          type: USER_ADDRESS_PRIMARY_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
          },
        });
      }
    } catch (err) {
      dispatch({
        type: USER_ADDRESS_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

//change status
export const addressApproveDisapprove =
  (customer_address_id, status) => async (dispatch) => {
    try {
      dispatch({
        type: USER_ADDRESS_LOADING,
        payload: { is_loading: true, is_sub_element: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/user-address/change-status/${customer_address_id}`,
        { status },
        config
      );
      if (res.data.status === true) {
        await dispatch({
          type: USER_ADDRESS_CHANGE_STATUS,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
      } else {
        //add a key is_failed=true else is_failed=false
        dispatch({
          type: USER_ADDRESS_CHANGE_STATUS_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
            data: customer_address_id,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: USER_ADDRESS_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

// get User by id
export const getUserDetailsById = (user_id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_ADDRESS_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/user-address/user-details/${user_id}`,
      config
    );
    await dispatch({
      type: GET_USER_DETAILS_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: USER_ADDRESS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
