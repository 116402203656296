/* eslint-disable */
import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import {
  Label,
  FormText
} from 'reactstrap';
import {
  getProductImages,
  deleteProductImage,
  primaryImage,
  uploadImages,
} from 'actions/admin/GearProduct/gearProduct';

import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Row,
  FormGroup,
  Input,
  Modal,
  ModalBody
} from 'reactstrap';
import {
  IMAGE_MAX_SIZE,
  ACCEPTED_IMAGE_TYPES,
  ACCEPTED_IMAGE_TYPES_ARRAY,
  PRODUCT_IMAGE_LIMIT,
} from 'constants/index';

import Spinner from 'views/Spinner';

const GearProductImages = ({
  product_id,
  uploadImages,
  history,
  changeTab,
  getProductImages,
  deleteProductImage,
  // gearProductImages: { images, count },
  primaryImage,
  gearCurrentProduct,
  loading,
}) => {
  const initialFileValue = {
    images_arr: [],
    initial_count: 0,
    count: 0,
  };
  let validationMesaage = [];
  const [files, setFiles] = useState(initialFileValue);
  const [showMessage, setShowMessage] = useState(false);
  const [validationMsg, setValidationMsg] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [modalImage, setModalImage] = useState('');
  const [selectedVideoFile, setSelectedVideoFile] = useState('');
  const [videoSizeCheck, setVideoSizeCheck] = useState(false);
  const [deleteVideoFile, setDeleteVideoFile] = useState('');

  useMemo(() => {
    if (product_id) getProductImages(product_id);
  }, []);

  useEffect(() => {
    if (
      gearCurrentProduct.gearProductImages &&
      gearCurrentProduct.gearProductImages.count > 0
    ) {
      setFiles({
        ...files,
        count: gearCurrentProduct.gearProductImages.count,
        images: gearCurrentProduct.gearProductImages.images,
      });
    }
    if (gearCurrentProduct.gearProductVideoUrl) {
      setVideoUrl(gearCurrentProduct.gearProductVideoUrl);
      setVideoSizeCheck(false);
    }
  }, [gearCurrentProduct]);

  const onOpenModal = (src) => {
    setModalImage(src)
  }

  const onCloseModal = () => {
    setModalImage('')
  }


  //Show preloaded images preview
  const Image = ({ src, alt, key }) => {
    return (
      <div className="image-container" key={key}>
        <div className='gallery-image-container' onClick={() => onOpenModal(src)}>
          <img src={src} alt={alt} className="gallery-image" />
        </div>
        {/* <div className="modal">
          <img src={src} alt={alt} className="modal-image" />
        </div> */}
      </div>
    );
  };


  const preLoadedThumbs =
    files.count > 0
      ? files.images.map((file, index) => {
        let primary = file.is_primary ? 'primary' : '';
        let title = file.is_primary ? 'Primary Image' : '';
        return (
          <div title={title} className={`thumb ${primary}`} key={file._id}>
            <div className="thumbInner">
              {/* <img
                src={
                  'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/product/' +
                  file.image
                }
                className="preview-img"
                alt=""
              /> */}
              <Image key={index} src={'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/product/' +
                file.image} alt="product image" />
            </div>
            {!file.is_primary ? (
              <div>
                <Button
                  title="Delete Image"
                  className="btn btn-sm btn-danger"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        `Are you sure to delete this product image?`
                      )
                    ) {
                      deleteProductImage(product_id, file._id, history);
                    }
                  }}
                >
                  <i className="fa fa-close"></i>
                </Button>
                <Button
                  title="Click to make primary image"
                  className="btn btn-sm btn-success"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        `Are you sure you want to set this image as primary?`
                      )
                    ) {
                      primaryImage(product_id, file._id, history);
                    }
                  }}
                >
                  <i className="fa fa-check"></i>
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
        );
      })
      : '';

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    name: 'myfiles',
    accept: ACCEPTED_IMAGE_TYPES_ARRAY,
    maxSize: IMAGE_MAX_SIZE,
  });

  //Show rejected file msg
  const rejectedFilesItems = fileRejections.forEach((file) => {
    if (file?.file.size > IMAGE_MAX_SIZE) {
      return (
        <li key={file?.file.path}>
          {file?.file.path} - {file?.file.size} bytes. File size is too large.
        </li>
      );
    } else if (!Object.keys(ACCEPTED_IMAGE_TYPES_ARRAY)?.includes(file?.file.type) && validationMsg) {
      validationMesaage.push(file?.errors[0].message)
      return (
        <li key={file?.file.path}>
          {file?.file.path} - {file?.file.type} file type is not allowed.
        </li>
      );
    }
  });

  useEffect(() => {
    if (fileRejections.length > 0) {
      setValidationMsg(true);
    } else {
      setValidationMsg(false);
    }
  }, [isDragActive, fileRejections])

  //remove file
  var removeFile = (index) => () => {
    let all_files = files.images_arr;
    all_files.splice(index, 1);
    setFiles({
      ...files,
      images_arr: all_files,
      initial_count: files.initial_count - 1,
    });
  };
  useEffect(() => {
    let newImageArray = files.images_arr;
    let ct = files.initial_count;
    acceptedFiles.forEach((file, index) => {
      if (PRODUCT_IMAGE_LIMIT <= files.count + ct) {
        acceptedFiles.splice(index, 1);
      } else {
        ct += 1;
        let temp = Object.assign(file, { preview: URL.createObjectURL(file) });
        newImageArray.push(temp);
      }
    });
    setShowMessage(false);
    validationMesaage = [];
    setFiles({
      ...files,
      images_arr: newImageArray,
      initial_count: ct,
    });
  }, [acceptedFiles]);

  //Show file preview

  acceptedFiles.forEach((file, index) => {
    if (index >= PRODUCT_IMAGE_LIMIT - files.count) {
      acceptedFiles.splice(index, 1);
    }
  });

  const removeVideoFile = (e) => {
    e.preventDefault();
    if (gearCurrentProduct?.gearProductVideoUrl !== '') {
      setDeleteVideoFile(videoUrl);
    }
    setVideoUrl('');
    setSelectedVideoFile('');
  };

  const handleVideoChange = (e) => {
    let video_file = e.target.files[0];

    let video_url = URL.createObjectURL(video_file);

    const reader = new FileReader();
    setSelectedVideoFile(video_file);
    setVideoUrl(video_url);
    if (video_file?.size > 500000000) {
      setVideoSizeCheck(true);
    } else {
      setVideoSizeCheck(false);
    }
    document.getElementById(`videoUrl`).value = '';
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (files.images_arr.length != 0 || files?.images?.length > 0) {
      if (!videoSizeCheck) {
        let sendValues = files;
        validationMesaage = [];
        setValidationMsg(false)
        setFiles(initialFileValue);
        uploadImages(sendValues, product_id, selectedVideoFile, videoUrl, deleteVideoFile).then((res) => {
          if (res === true) {
            setDeleteVideoFile('');
            acceptedFiles.splice(0, acceptedFiles.length);
            if (gearCurrentProduct.is_external === 1) {
              history.push('/admin/gear-products');
            } else {
              changeTab('attribute_images');
            }
          } else setFiles(sendValues);
        });
        setShowMessage(false);
        setVideoSizeCheck(false);
      }
    } else {
      setShowMessage(true);
    }
  };

  //###############handle unsaved images status display ##########
  const [showStatus] = useState(false);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Form
        className="form-horizontal"
        onSubmit={onSubmit}
        encType="multipart/form-data"
      >
        <Row>
          {/* <Prompt
            when={showStatus}
            message="You have unsaved images , are you sure you want to leave?"
          /> */}
          <Col xs="12" sm="12">
            <Card>
              <CardBody>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  )}
                  <em>1. Only *.jpeg, *.jpg and *.png images will be accepted.</em>
                  <em>
                    2. Valid maximum image size is {IMAGE_MAX_SIZE} bytes.{' '}
                  </em>
                  <em>
                    3. You have uploaded {files.count + files.initial_count}{' '}
                    images out of {PRODUCT_IMAGE_LIMIT} images.{' '}
                  </em>
                  <em>
                    4. If you have changed the primary image or delete any image
                    then there is no need to submitting.
                  </em>
                  <em>
                    5. Please Upload atleast 980*500 Aspect Ratio Images for Better UI in Customer Panel
                  </em>
                </div>
                <aside className="thumbsContainer">
                  {preLoadedThumbs}
                  {files.images_arr.map((file, index) => {
                    return (
                      <div className="thumb" key={index}>
                        <div className="thumbInner">
                          {/* <img
                            className="preview-img"
                            src={file.preview}
                            alt=""
                          /> */}
                          <Image key={index} src={file.preview} alt="product image" />
                        </div>
                        <Button
                          title="Remove Image"
                          className="btn btn-sm btn-danger"
                          onClick={removeFile(index)}
                        >
                          <i className="fa fa-close"></i>
                        </Button>
                      </div>
                    );
                  })}
                  {showMessage == true ? <span className='text-danger'>Atleast one image is required</span> : ""}
                </aside>
                <span className='text-danger'> {validationMesaage[0]}</span>
                <small className="invalid-feedback-text form-text">
                  {rejectedFilesItems}
                </small>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          {/* <Col xs="12" className="justify-flex-end">
            <FormGroup>
              <Input
                type="url"
                placeholder="Enter Video URL"
                onChange={(e) => setVideoUrl(e.target.value)}
                value={videoUrl}
              />
            </FormGroup>
          </Col> */}
          <Col sm="12" className="justify-flex-end">
            <FormGroup>
              <Label htmlFor="videoUrl">
                Upload Video (Video maximum size should be 500MB.)
              </Label>
              <Input
                type="file"
                accept="video/*"
                id="videoUrl"
                placeholder="Upload Video"
                name="videoUrl"
                onChange={(e) => handleVideoChange(e)}
              />
              <Col sm="12">
                {selectedVideoFile?.size > 500000000 && videoSizeCheck &&
                  <FormText className="invalid-feedback-text text-danger preview-video">
                    Video maximum size should be 500MB.
                  </FormText>
                }
              </Col>
              {videoUrl !== '' &&
                <div className="thumb">
                  <video
                    controls={true}
                    src={videoUrl}
                    className="preview-video"
                    alt=""
                  />
                  <Button
                    title="Remove Video"
                    className="btn btn-sm btn-danger"
                    style={{ height: "30px", borderRadius: "20px", textAlign: "center", paddingTop: "3px" }}
                    onClick={(e) => removeVideoFile(e)}
                  >
                    <i className="fa fa-close"></i>
                  </Button>
                </div>
              }
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="Justify-flex-end">
            <FormGroup>
              <Button
                className="mr-05"
                type="submit"
                size="sm"
                color="primary"
                disabled={loading}
              >
                <i className="fa fa-dot-circle-o"></i> Save & Next
              </Button>
              <Link to="/admin/gear-products">
                <Button type="reset" size="sm" color="danger">
                  <i className="fa fa-ban"></i> Cancel
                </Button>
              </Link>
            </FormGroup>
          </Col>
        </Row>
      </Form>
      <Modal isOpen={modalImage !== ""} toggle={onCloseModal} size="lg">
        <ModalBody toggle={onCloseModal}>
          <img src={modalImage} alt="Image" className='modal-image' />
        </ModalBody>
      </Modal>
    </>
  );
};

GearProductImages.propTypes = {
  uploadImages: PropTypes.func.isRequired,
  getProductImages: PropTypes.func.isRequired,
  deleteProductImage: PropTypes.func.isRequired,
  primaryImage: PropTypes.func.isRequired,
  gearCurrentProduct: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  gearCurrentProduct: state.gearProduct.gearCurrentProduct,
  loading: state.gearProduct.loading,
});

export default connect(mapStateToProps, {
  uploadImages,
  getProductImages,
  deleteProductImage,
  primaryImage,
})(GearProductImages);
