/* eslint-disable */
import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { edit, getLoggedInUser } from 'actions/admin/profile';
import Errors from 'views/Notifications/Errors';
import Spinner from 'views/Spinner';

import ChangePassword from './ChangePassword';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
} from 'reactstrap';
import { REMOVE_ERRORS } from 'actions/types';

const ProfileSettings = ({
  edit,
  getLoggedInUser,
  user,
  errorList,
  profile_loading,
}) => {
  //################ set initial form data ############################
  const [initialFormData, setInitialFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
  });

  useMemo(() => {
    if (user != null) {
      setInitialFormData({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
      });
    }
  }, [user]);

  //################ set form data ############################
  const [formData, setFormData] = useState(initialFormData);

  const { first_name, last_name, email } = formData;

  useMemo(() => {
    setFormData(initialFormData);
  }, [initialFormData]);

  // to resolve useEffect dependency error

  //################ get current profile ############################
  useMemo(() => {

    getLoggedInUser();
  }, []);

  //################ toggle change password modal ############################
  const [changePasswordModal, setChangePasswordModalModal] = useState(false);
  const togglePasswordModal = () => {
    setChangePasswordModalModal(!changePasswordModal);
  };

  //################ onChange event ############################
  const dispatch = useDispatch();

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value?.trim() });
    dispatch({ type: REMOVE_ERRORS });
  };

  //################ on cancle save ############################
  const onClickCancle = (e) => {
    e.preventDefault();
    setFormData(initialFormData);
  };

  //########################## set the submit state ##############################
  const [isSubmit, setIsSubmit] = useState(false);

  //################ on form submit ############################
  const onProfileSubmit = (e) => {
    setIsSubmit(true);
    e.preventDefault();
    edit(formData).then((res) => {
      setIsSubmit(false);
    });
  };

  return (
    <div className="animated fadeIn">
      {user == null ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xl="12">
                    <h4> Profile Setting</h4>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={togglePasswordModal}
                    >
                      <i className="fa fa-dot-circle-o"></i> Change Password
                    </Button>
                    <ChangePassword
                      isOpen={changePasswordModal}
                      toggle={togglePasswordModal}
                    ></ChangePassword>
                  </Col>
                </Row>
              </CardHeader>
              <Form
                className="form-horizontal"
                onSubmit={(e) => onProfileSubmit(e)}
              >
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="first_name">
                          First Name <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="first_name"
                          name="first_name"
                          maxLength="25"
                          value={first_name}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.first_name ? true : false}
                        />
                        <Errors current_key="first_name" key="first_name" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="last_name">Last Name</Label>
                        <Input
                          type="text"
                          id="last_name"
                          name="last_name"
                          maxLength="25"
                          value={last_name}
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="email">
                          Email <span>*</span>
                        </Label>
                        <Input
                          type="email"
                          id="email"
                          name="email"
                          maxLength="50"
                          value={email}
                          required
                          readOnly
                          disabled
                          onChange={(e) => onChange(e)}
                          invalid={errorList.email ? true : false}
                        />
                        <Errors current_key="email" key="email" />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  {profile_loading ? (
                    <div className="modalFooter">
                      <Spinner />
                    </div>
                  ) : (
                    <div className="modalFooter">
                      <Button
                        type="reset"
                        size="sm"
                        className="canclebtn"
                        color="danger"
                        onClick={onClickCancle}
                      >
                        <i className="fa fa-ban"></i> Cancel
                      </Button>
                      <Button
                        type="submit"
                        size="sm"
                        color="primary"
                        disabled={isSubmit}
                      >
                        <i className="fa fa-dot-circle-o"></i> Update
                      </Button>
                    </div>
                  )}
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

ProfileSettings.propTypes = {
  edit: PropTypes.func.isRequired,
  errorList: PropTypes.array.isRequired,
  user: PropTypes.object,
  profile_loading: PropTypes.bool.isRequired,
  getLoggedInUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  errorList: state.errors,
  user: state.profile.user,
  profile_loading: state.profile.loading,
});

export default connect(mapStateToProps, {
  edit,
  getLoggedInUser,
})(ProfileSettings);
