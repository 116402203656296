import axios from 'axios';
import { removeAlert } from './alert';

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  RESET_LINK_SUCCESS,
  RESET_LINK_GET_DETAIL_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_LINK_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_FAIL,
  REMOVE_ERRORS,
  REMOVE_ALERT,
  AUTH_LOADING,
  AUTH_FAIL,
  LOGIN_SUCCESS_ALERT,
  NO_USER_LOADED,
  SET_ERRORS_LIST,
} from './types';

import setAuthToken from '../utils/setAuthToken';
import { PERMISSION_NAME } from './../constants';


//Load User
export const loadUser =
  ({ history, loading = true }) =>
    async (dispatch) => {
      try {
        dispatch(removeAlert());
        dispatch({ type: REMOVE_ERRORS });
        if (loading === true) {
          dispatch({
            type: AUTH_LOADING,
            payload: true,
          });
        }
        if (localStorage.token) {
          setAuthToken(localStorage.token);
          const res = await axios.get('/api/auth');
          if (res.data.status === true) {
            dispatch({
              type: USER_LOADED,
              payload: res.data.response,
            });
          } else {
            const errors = res.data.errors;
            let errorsList = {};
            if (errors) {
              await errors.forEach((error) => {
                errorsList = { ...errorsList, [error.param]: error.msg }
              });
            }
            dispatch({
              type: SET_ERRORS_LIST,
              payload: { ...errorsList }
            })
          }
        } else {
          dispatch({
            type: NO_USER_LOADED,
            payload: false,
          });
          setAuthToken('');
        }
      } catch (err) {
        setAuthToken('');
        dispatch({
          type: AUTH_FAIL,
          payload: {
            message:
              err.response && err.response?.data?.message
                ? err.response?.data?.message
                : 'Something went wrong.',
            error: err,
          },
        });
      }
    };

//Register User
export const register =
  ({ first_name, last_name, email, password }, history, previousPath) =>
    async (dispatch) => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const body = JSON.stringify({ first_name, last_name, email, password });

      try {
        dispatch(removeAlert());
        dispatch({ type: REMOVE_ERRORS });
        const res = await axios.post('/api/register', body, config);
        if (res.data.status === true) {
          dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data.response,
          });
          dispatch(login(email, password, history, previousPath));
          //history.push("/")
        } else {
          const errors = res.data.errors;
          let errorsList = [];
          if (errors) {
            await errors.forEach((error) => {
              errorsList[error.param] = error.msg;
            });
          }
          dispatch({
            type: AUTH_ERROR,
            payload: {
              message: res.data.message,
              alertType: 'danger',
              errorsList,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: REGISTER_FAIL,
          payload: {
            msg: err.response?.data?.message,
            status: err.response.status,
          },
        });
      }
    };

//Logout / Clear Profile
export const logout = (history) => (dispatch) => {
  setAuthToken('');
  dispatch({
    type: LOGOUT,
    payload: {
      message: 'Cart updated',
      alertType: 'success',
      data: { count: 0, products: {} },
    },
  });
  history('/');
};

//Login User
export const login =
  (email, password, history, previousPath) => async (dispatch) => {
    try {
      dispatch(removeAlert());

      dispatch({
        type: AUTH_LOADING,
        payload: true,
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const body = JSON.stringify({ email, password });
      const res = await axios.post('/api/auth', body, config);
      if (res.data.status === true) {
        await localStorage.setItem('token', res.data.response.token);
        await setAuthToken(localStorage.token);
        let lastName = res.data.response.user.last_name
          ? res.data.response.user.last_name
          : '';


        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data.response,
        });
        dispatch({
          type: LOGIN_SUCCESS_ALERT,
          payload: {
            message: `${res.data.response.user.first_name + ' ' + lastName
              } logIn success`,
            alertType: 'success',
          },
        });

        if (previousPath === '/') {
          if (res.data?.response?.user?.permission) {
            const permission = res.data?.response?.user?.permission;
            if (permission.includes(PERMISSION_NAME.ORDER_MANAGEMENT) || permission.includes(PERMISSION_NAME.SHIPPING_MANAGEMENT) || permission.includes(PERMISSION_NAME.BUILD_SHOP_MANAGEMENT)) {
              history("/admin/orders");

            }
            if (permission.includes(PERMISSION_NAME.PRODUCT_MANAGEMENT)) {
              history("/admin/attributes");
            }
            if (permission.includes(PERMISSION_NAME.CMS_MANAGEMENT)) {
              history(`/admin/home/edit/home`);
            }
            if (permission.includes(PERMISSION_NAME.CUSTOMER_MANAGEMENT)) {
              history('/admin/customers');
            }
          }
          else {
            history('/admin');
          }
        } else {
          history(previousPath);
        }

      } else {
        const errors = res.data.errors;
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }
        dispatch({
          type: LOGIN_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
            errorsList,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: {
          message:
            err.response && err.response?.data?.message
              ? err.response?.data?.message
              : 'Something went wrong.',
          alertType: 'danger',
        },
      });
    }
  };

// Generate Reset Link
export const resetLink = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.post(
      '/api/admin/forget-password',
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: RESET_LINK_SUCCESS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }

      dispatch({
        type: AUTH_ERROR,
        payload: {
          message: errors[0].msg,
          alertType: 'danger',
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: RESET_LINK_FAIL,
      payload: {
        message:
          err.response && err.response?.data?.message
            ? err.response?.data?.message
            : 'Something went wrong.',
        alertType: 'danger',
      },
    });
  }
};

// Get email by id
export const getEmailById = (id, token) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/forget-password/${id}/${token}`,
      config
    );
    if (res.data.response.status !== 403) {
      await dispatch({
        type: RESET_LINK_GET_DETAIL_SUCCESS,
        payload: res.data.response,
      });
      return res.data;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: AUTH_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: RESET_LINK_FAIL,
      payload: {
        message:
          err.response && err.response?.data?.message
            ? err.response?.data?.message
            : 'Something went wrong.',
        alertType: 'danger',
      },
    });
  }
};

// Token invalid
export const tokenInvalid = (history, msg) => async (dispatch) => {
  // history('/forgot-password');
  // console.log(msg, 'Forgot Password')
  if (msg == '') {
    dispatch({
      type: LOGIN_ERROR,
      payload: {
        message: 'This password reset token is invalid.',
        alertType: 'danger',
      },
    });
  }
};

export const setPasswordError = (msg, param) => async (dispatch) => {
  let errorsList = [];
  errorsList[param] = msg;
  dispatch({
    type: AUTH_ERROR,
    payload: {
      message: 'Errors! Please correct the following errors and submit again.',
      alertType: 'danger',
      errorsList,
    },
  });
};

// Reset Password
export const resetPassword =
  (password, confirm_password, email, id, history) => async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const body = JSON.stringify({ password, confirm_password, email });
      const res = await axios.post(
        `/api/admin/forget-password/${id}`,
        body,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
        history('/');
      } else {
        const errors = res.data.errors;
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }
        dispatch({
          type: AUTH_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
            errorsList,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: RESET_LINK_FAIL,
        payload: {
          message:
            err.response && err.response?.data?.message
              ? err.response?.data?.message
              : 'Something went wrong.',
          alertType: 'danger',
        },
      });
    }
  };

// Redirect to Login screen
export const loginRedirect = (history) => async (dispatch) => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
};
