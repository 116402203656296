/* eslint-disable */
import React, { useState, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BasicInfoExternal from "./BasicInfoExternal";
import ProductImages from "./ProductImages";

import { resetCurrentProduct } from "actions/admin/product";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Col,
  Row
} from "reactstrap";
import classnames from "classnames";

const CreateProduct = ({ history, currentProduct, resetCurrentProduct }) => {
  // to resolve useEffect dependency error
  
  //########################## reset currentProduct  state ##############################
  useMemo(() => {
    
    resetCurrentProduct();
  }, []);

  //########################## set the initial active tab ##############################
  const [activeTab, setActiveTab] = useState({
    productTab: "basic_info_tab"
  });

  //########################## change the active tab ##############################
  const changeTab = tab => {
    if (activeTab.productTab !== tab)
      setActiveTab({ ...activeTab, productTab: tab });
  };

  //########################## set product id ##############################
  const [productId, setProductId] = useState(null);

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="12">
          <Nav tabs className="productTabs">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab.productTab === "basic_info_tab"
                })}
                onClick={() => {
                  changeTab("basic_info_tab");
                }}
              >
                Basic Info
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab.productTab === "images_tab",
                  disabled: productId === null ? true : false
                })}
                onClick={() => {
                  changeTab("images_tab");
                }}
              >
                Images
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab.productTab}>
            <TabPane tabId="basic_info_tab">
              <BasicInfoExternal
                history={history}
                changeTab={changeTab}
                setProductId={setProductId}
                product_id={productId}
              />
            </TabPane>
            <TabPane tabId="images_tab">
              <ProductImages
                history={history}
                changeTab={changeTab}
                product_id={productId}
              />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

CreateProduct.propTypes = {
  currentProduct: PropTypes.object,
  resetCurrentProduct: PropTypes.func.isRequired
  // loading: PropTypes.bool.isRequired
};
const mapStateToProps = state => ({
  currentProduct: state.product.currentProduct
  // loading: state.product.loading
});

export default connect(mapStateToProps, { resetCurrentProduct })(CreateProduct);
