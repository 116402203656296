import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

import ProfileSettings from './ProfileSettings';
import WebsiteSettings from './WebsiteSettings';
import SocialSettings from './SocialSettings';
import ShippingCarriers from './ShippingCarriers';

const SettingTabs = () => {
  //########################## set the initial active tab ##############################
  const [activeTab, setActiveTab] = useState({
    productTab: 'profile',
  });

  //########################## change the active tab ##############################
  const changeTab = (tab) => {
    if (activeTab.productTab !== tab)
      setActiveTab({ ...activeTab, productTab: tab });
  };
  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab.productTab === 'profile',
            })}
            onClick={() => {
              changeTab('profile');
            }}
          >
            Profile Setting
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab.productTab === 'website',
            })}
            onClick={() => {
              changeTab('website');
            }}
          >
            Website Setting
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab.productTab === 'social',
            })}
            onClick={() => {
              changeTab('social');
            }}
          >
            Social Setting
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab.productTab === 'carrier',
            })}
            onClick={() => {
              changeTab('carrier');
            }}
          >
            Carrier Setting
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab.productTab}>
        <TabPane tabId="profile">
          <ProfileSettings />
        </TabPane>
        <TabPane tabId="website">
          <WebsiteSettings />
        </TabPane>
        <TabPane tabId="social">
          <SocialSettings />
        </TabPane>
        <TabPane tabId="carrier">
          <ShippingCarriers />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default SettingTabs;
