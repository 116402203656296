/* eslint-disable */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from '../../../utils/Loading';

import Moment from 'react-moment';
import {
  getDealerTypeList,
  deleteDealerType,
  changeStatus,
} from 'actions/admin/dealerType';

import * as Constants from 'constants/index';

import { Button, Card, CardBody, Col, Row, Input } from 'reactstrap';
import Spinner from 'views/Spinner';

import CustomFilters from './CustomFilters';
import CreateDealerType from './CreateDealerType';
import PerPageSelect from '../PerPageSelect';

const renderName = (params) => {
  if (params.data === undefined) {
    return <Loading />;
  } else {
    return <div>{params.data?.name}</div>;
  }
};

const renderCreatedAt = (params) => {
  return (
    <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
      {params.data?.created_at}
    </Moment>
  );
};

const renderStatus = (params, changeDealerTypeStatus) => {
  return (
    <div>
      <Input
        type="select"
        name="status"
        id={params.data?.status}
        defaultValue={params.data?.status}
        onChange={(e, a) => {
          changeDealerTypeStatus(params.data?._id, e.target.value);
        }}
      >
        <option value="0">Inactive</option>
        <option value="1">Active</option>
      </Input>
    </div>
  );
};

const renderActions = (params, deleteDealerType, updateDealerTypes) => {
  return (
    <div>
      <Link to={`/admin/dealer-types/edit/${params?.data._id}`}>
        <Button type="button" size="sm" color="success" title="Edit">
          <i className="fa fa-pencil"></i>
        </Button>
      </Link>
      <Button
        type="button"
        size="sm"
        color="danger"
        title="Delete"
        onClick={(e) => {
          if (
            window.confirm(
              `Are you sure, you want to  delete ${params?.data.name} dealer type?`
            )
          ) {
            deleteDealerType(params.data?._id, history).then((res) => {
              if (res) updateDealerTypes();
            });
          }
        }}
      >
        <i className="fa fa-trash"></i>
      </Button>
    </div>
  );
};

const DealerTypeList = ({
  getDealerTypeList,
  deleteDealerType,
  changeStatus,
  loading,
  subLoading,
  dealerTypeList: { data, count },
}) => {
  const history = useNavigate();
  const location = useLocation();
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
          limit: Constants.DEFAULT_PAGE_SIZE,
          page: 1,
          orderBy: 'created_at',
          ascending: 'desc',
          query: '',
          filter: {},
        }
  );

  //################## to get updated list ###################
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);

  const updateDealerTypes = () => {
    gridApi.purgeInfiniteCache();
  };

  const changeDealerTypeStatus = async (id, value) => {
    await changeStatus(id, value);
    gridApi.purgeInfiniteCache();
  };

  //#########################Colums defination start ###################
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 120,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      colSpan: (params) => {
        if (params.data === undefined) {
          return 5;
        } else {
          return 0;
        }
      },
      cellRenderer: (params) => renderName(params),
    },
    {
      field: 'discount',
      headerName: 'Discount (in %)',
      suppressMovable: true,
      width: 150,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agNumberColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 120,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agDateColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.DATE_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
        buttons: ['reset', 'apply'],
      },
      cellRenderer: (params) => renderCreatedAt(params),
    },
    {
      field: 'status',
      width: 140,
      headerName: 'Status',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      cellRenderer: (params) => renderStatus(params, changeDealerTypeStatus),
    },
    {
      field: '_id',
      width: 120,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      headerName: 'Actions',
      cellRenderer: (params) =>
        renderActions(params, deleteDealerType, updateDealerTypes),
    },
  ];
  //#########################Colums defination end ###################

  //################ toggle category add/edit modal ############################
  const [modal, setOpenModal] = useState(false);
  const toggleModal = () => {
    setOpenModal(!modal);
  };
  //################## START Set properties(options) of datatables ###################
  const actions = (
    <div className="add-button-div specify-width">
      <Button color="primary" size="sm" onClick={toggleModal}>
        <i className="fa fa-plus"></i> Add New Dealer Type
      </Button>
      <CreateDealerType
        isOpen={modal}
        toggle={toggleModal}
        update={updateDealerTypes}
      ></CreateDealerType>
    </div>
  );

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };

          const customData = await getDealerTypeList(customParams);
          if (customData?.data?.status) {
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });

  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                {actions}
                <div className="rightpaneltop">
                  Dealer Type Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>
              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      defaultColDef={{
                        resizable: true,
                      }}
                      loadingCellRenderer={customLoadingCellRenderer}
                      loadingCellRendererParams={loadingCellRendererParams}
                      domLayout={'autoHeight'}
                      rowModelType={'infinite'}
                      columnDefs={columns}
                      pagination={true}
                      sizeColumnsToFit={true}
                      onFilterChanged={filterChanged}
                      onGridReady={onGridReady}
                      paginationPageSize={sortingParams.limit}
                      cacheBlockSize={sortingParams.limit}
                      cacheQuickFilter={true}
                      onFirstDataRendered={onFirstDataRendered}
                      onPaginationChanged={onPaginationChanged}
                      ref={gridRef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

DealerTypeList.propTypes = {
  getDealerTypeList: PropTypes.func.isRequired,
  deleteDealerType: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  dealerTypeList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  dealerTypeList: state.dealerType.dealerTypeList,
  loading: state.dealerType.loading,
  subLoading: state.dealerType.isSubLoading,
});

export default connect(mapStateToProps, {
  getDealerTypeList,
  deleteDealerType,
  changeStatus,
})(DealerTypeList);
