import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import slugify from 'react-slugify';
import { create } from 'actions/admin/GearProduct/gearCategory';

import Errors from 'views/Notifications/Errors';
import Spinner from 'views/ButtonSpinner';

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  ModalFooter,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

const CreateGearCategory = ({
  create,
  errorList,
  toggle,
  isOpen,
  modal_loading,
  update,
}) => {
  //########################## use dispatch ##############################

  //########################## set the initial form data ##############################

  const initialState = {
    name: '',
    slug: '',
    childrens: [{ name: '', slug: '' }],
    thumbnail: '',
    image: '',
    customization: false,
    selectedFile: '',
    categoryType: 'SHAFTS',
  };
  const [formData, setFormData] = useState(initialState);

  //########################## set the submit state ##############################
  const [isSubmit, setIsSubmit] = useState(false);

  //########################## reset for data when modal is closed ##############################
  const resetModal = () => {
    //reset the submit state
    setIsSubmit(false);
    //reset the form data to initial state
    setFormData(initialState);
  };

  //########################## explod form data vaiables ##############################
  const {
    name,
    slug,
    thumbnail,
    // customization
    // image
  } = formData;

  //########################## change event for all inputs ##############################
  const onChange = (e) => {
    if (e.target.name === 'name') {
      let new_slug = slugify(e.target.value);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.replace(/[^a-z0-9]/gi, '')
          ? e.target.value.trimLeft()
          : e.target.value.trim(),
        slug: new_slug,
      });
    } else if (e.target.name === 'image') {
      let image_file = e.target.files[0];
      let image_url = URL.createObjectURL(image_file);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        thumbnail: image_url,
        selectedFile: image_file,
      });
    } else if (e.target.name === 'customization') {
      console.log('Customization', e.target.checked);
      setFormData({ ...formData, [e.target.name]: e.target.checked });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  //########################## submit form data ##############################
  const onSubmit = (e) => {
    setIsSubmit(true);
    create(formData).then((res) => {
      if (res === true) {
        toggle();
        update();
      } else {
        setIsSubmit(false);
      }
    });
  };

  return (
    <div className="animated fadeIn">
      <Modal isOpen={isOpen} toggle={toggle} onClosed={resetModal}>
        <ModalHeader toggle={toggle}>Add New Gear Category</ModalHeader>

        <Form className="form-horizontal">
          <ModalBody>
            <Row>
              <Col xs="12" sm="12">
                <Row>
                  <Col sm="12">
                    <FormGroup hidden={true}>
                      <Label htmlFor="name">
                        Slug <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="slug"
                        name="slug"
                        maxLength="100"
                        value={slug}
                        required
                        readOnly
                        invalid={errorList.slug ? true : false}
                      />
                      <Errors current_key="slug" key="slug" />
                    </FormGroup>
                  </Col>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="name">
                        Name <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="name"
                        name="name"
                        maxLength="100"
                        value={name}
                        placeholder="Enter Name"
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.name ? true : false}
                      />
                      <Errors current_key="name" key="name" />
                    </FormGroup>
                  </Col>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="image">Image </Label>
                      <Input
                        type="file"
                        id="image"
                        name="image"
                        //value={image}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.image ? true : false}
                      />
                      {thumbnail ? (
                        <img src={thumbnail} className="preview-img" alt="" />
                      ) : null}
                      <Errors current_key="image" key="image" />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="modalFooter">
            {modal_loading ? (
              <Spinner />
            ) : (
              <Button
                className="mr-05"
                type="submit"
                size="sm"
                color="primary"
                onClick={(e) => onSubmit(e)}
                disabled={modal_loading}
              >
                <i className="fa fa-dot-circle-o"></i> Submit
              </Button>
            )}
            <Button type="reset" size="sm" color="danger" onClick={toggle}>
              <i className="fa fa-ban"></i> Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

CreateGearCategory.defaultProps = {
  isOpen: false,
};

CreateGearCategory.propTypes = {
  create: PropTypes.func.isRequired,
  modal_loading: PropTypes.bool.isRequired,
  errorList: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  modal_loading: state.gearCategory.modal_loading,
});
export default connect(mapStateToProps, {
  create,
})(CreateGearCategory);
