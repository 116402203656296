import React from 'react';

export default (props) => {
    console.log(props);
  return (
    <div
      className="ag-custom-loading-cell"
      style={{ backgroundColor: 'black', paddingLeft: '10px', lineHeight: '25px' }}
    >
     <h1>HELLO</h1>
    </div>
  );
};