/* eslint-disable */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import Moment from 'react-moment';

import { Button, Card, CardBody, Col, Row, Input } from 'reactstrap';

import { getIronNumberList, deleteIronNumber } from 'actions/admin/ironNumber';
import * as Constants from 'constants/index';

import PerPageSelect from '../PerPageSelect';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from 'utils/Loading';

const actions = (
  <div className="add-button-div specify-width">
    <Link to="/admin/iron-number-nomenclature/create" className="addNewElementClass">
      <Button color="primary" size="sm">
        <i className="fa fa-plus"></i> Create Iron Number Nomenclature
      </Button>
    </Link>
  </div>
);

const renderIronNumber = (params) => {
  if (params.data === undefined) {
    return (
      <center>
        <span>Loading.......</span>
      </center>
    );
  } else {
    return <div>{params.data?.iron_type}</div>;
  }
};

const IronNumberNomenclature = ({
  getIronNumberList,
  deleteIronNumber,
  ironNumberList: { data, count },
}) => {
  const history = useNavigate();
  const location = useLocation();
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState({
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: 'created_at',
    ascending: 'desc',
    query: '',
    filter: {},
  });

  //#########################Colums defination start ###################
  const columns = [
    {
      field: 'iron_type',
      headerName: 'Iron # / Iron Type',
      suppressMovable: true,
      width: 150,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      colSpan: (params) => {
        if (params.data === undefined) {
          return 4;
        } else {
          return 0;
        }
      },
      cellRenderer: renderIronNumber
      // headerSortingStyle
    },
    {
      field: 'shaft_length',
      headerName: 'Raw Shaft Length Recommendation',
      suppressMovable: true,
      width: 150,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      // headerSortingStyle
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 140,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agDateColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.DATE_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
        buttons: ['reset', 'apply'],
      },
      // headerSortingStyle,
      cellRenderer: (params) => (
        <>
          <Moment
            format={
              process.env.REACT_APP_DISPLAY_DATE_FORMATE ?? 'MM/DD/YYYY'
            }
            locale="de"
          >
            {params.value}
          </Moment>

        </>
      ),
    },
    {
      field: '_id',
      headerName: 'Actions',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) => (
        <div>
          <Link to={`/admin/iron-number-nomenclature/edit/${params.data?._id}`}>
            <Button type="button" size="sm" color="success" title="Edit">
              <i className="fa fa-pencil"></i>
            </Button>
          </Link>
          <Button
            type="button"
            size="sm"
            color="danger"
            title="Delete"
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure, you want to delete ${params.data?.iron_type ?? ''} page?`
                )
              ) {
                deleteIronNumber(params.data?._id).then((res) => {
                  if (res) {
                    getIronNumberList(sortingParams);
                    gridApi.purgeInfiniteCache();
                  }
                });
              }
            }}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
  ];
  //#########################Colums defination end ###################

  //################## START Set properties(options) of datatables ###################

  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef?.current.api.destroyFilter(params.columns[0].colId);
    }
  });

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};

          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page: page,
          };

          const customData = await getIronNumberList(customParams);

          if (customData?.data?.status) {
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi?.api?.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef?.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });

  let delayTimer;
  const onSearch = (e) => {
    clearTimeout(delayTimer);
    const searchText = e.target.value.trim();

    delayTimer = setTimeout(() => {
      if (gridApi) {
        if (Object.keys(sortingParams.filter).length) {
          gridApi.setFilterModel(sortingParams.filter);
        }
        const dataSource = {
          getRows: async (params) => {
            const page = gridApi.paginationGetCurrentPage() + 1;
            const limit = gridApi.paginationGetPageSize();

            const sortModel = params.sortModel.length
              ? params.sortModel[0]
              : null;
            const filterModel = params.filterModel ? params.filterModel : {};
            const customParams = {
              ...sortingParams,
              query: searchText,
              limit,
              orderBy: sortModel?.colId,
              ascending: sortModel?.sort,
              filter: filterModel,
              page,
            };

            const customData = await getIronNumberList(customParams);

            if (customData?.data?.status) {
              params.successCallback(
                customData.data.response[0].data,
                customData.data.response[0].metadata[0].totalRecord
              );
              customParams.onLoad = false;
              setSortingParams({ ...customParams });
              history(location.pathname, { state: customParams });
            } else {
              params.successCallback([], 0);
            }
          },
        };
        gridApi.setDatasource(dataSource);
        gridApi.api?.sizeColumnsToFit();
      }
    }, 1000);
  };

  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                {actions}
                <div className="rightpaneltop">
                  <Input
                    type="text"
                    id="search-text-box"
                    placeholder="Search by Iron Number"
                    onKeyUp={(e) => {
                      onSearch(e);
                    }}
                  />
                </div>
                <div className="rightpaneltop">
                  Iron Number Nomenclature Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>
              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      defaultColDef={{
                        resizable: true,
                      }}
                      loadingCellRenderer={customLoadingCellRenderer}
                      loadingCellRendererParams={loadingCellRendererParams}
                      domLayout={'autoHeight'}
                      rowModelType={'infinite'}
                      columnDefs={columns}
                      pagination={true}
                      sizeColumnsToFit={true}
                      // floatingFilter={true}
                      onFilterChanged={filterChanged}
                      onGridReady={onGridReady}
                      paginationPageSize={sortingParams.limit}
                      cacheBlockSize={sortingParams.limit}
                      cacheQuickFilter={true}
                      onFirstDataRendered={onFirstDataRendered}
                      onPaginationChanged={onPaginationChanged}
                      ref={gridRef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

IronNumberNomenclature.propTypes = {
  getIronNumberList: PropTypes.func.isRequired,
  deleteIronNumber: PropTypes.func.isRequired,
  ironNumberList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  ironNumberList: state.ironNumber.ironNumberList,
  loading: state.ironNumber.loading,
  subLoading: state.ironNumber.isSubLoading,
});

export default connect(mapStateToProps, {
  getIronNumberList,
  deleteIronNumber,
})(IronNumberNomenclature);
