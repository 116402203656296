/* eslint-disable */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from '../../../../utils/Loading';

import Moment from 'react-moment';

import {
  getEmailTemplateCategoryList,
  deleteEmailTemplateCategory,
} from 'actions/admin/emailTemplateCategories';
import { addBreadcrumDetails } from 'actions/admin/breadcrum';

import * as Constants from 'constants/index';

import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Input,
  CardFooter,
} from 'reactstrap';
import CreateEmailTemplateCategory from './CreateEmailTemplateCategory';
import PerPageSelect from '../../PerPageSelect';

const renderTitle = (params) => {
  if (params.data === undefined) {
    return <Loading />;
  } else {
    return <div>{params.data?.title}</div>;
  }
};

const renderCreatedAt = (params) => {
  return (
    <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
      {params.data?.created_at}
    </Moment>
  );
};

const renderActions = (
  params,
  openModal,
  sectionId,
  deleteEmailTemplateCategory,
  updateEmailTemplateCategoryList,
  addBreadcrumDetails
) => {
  addBreadcrumDetails({ [params.data?._id]: params.data?.title });
  return (
    <div>
      <Link to={`/admin/${sectionId}/${params.data?._id}/emails`}>
        <Button type="button" size="sm" color="info" title="View Emails">
          <i className="fa fa-list"></i>
        </Button>
      </Link>

      <Button
        type="button"
        size="sm"
        color="success"
        title="Edit"
        onClick={() => openModal(params.data?._id)}
      >
        <i className="fa fa-pencil"></i>
      </Button>

      {/* <Button
        type="button"
        size="sm"
        color="danger"
        title="Delete"
        onClick={(e) => {
          if (
            window.confirm(
              `Are you sure, you want to delete ${params.data.title} email template?`
            )
          ) {
            deleteEmailTemplateCategory(params.data._id, history).then(
              (res) => {
                if (res) updateEmailTemplateCategoryList();
              }
            );
          }
        }}
      >
        <i className="fa fa-trash"></i>
      </Button> */}
    </div>
  );
};

// const actions = (
//   <div className="add-button-div">
//     <Link
//       to="/admin/email-template-category/create"
//       className="addNewElementClass"
//     >
//       <Button color="primary" size="sm">
//         <i className="fa fa-plus"></i> Create Email Template Category
//       </Button>
//     </Link>
//   </div>
// );

const EmailTemplateList = ({
  getEmailTemplateCategoryList,
  deleteEmailTemplateCategory,
  emailTemplateCategoryList: { data, count },
  loading,
  subLoading,
  addBreadcrumDetails,
}) => {
  const history = useNavigate();
  const { sectionId } = useParams();
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
        limit: Constants.DEFAULT_PAGE_SIZE,
        page: 1,
        orderBy: 'created_at',
        ascending: 'desc',
        query: '',
        filter: {},
        onLoad: true,
      }
  );

  // to resolve useEffect dependency error

  //################## to get updated list ###################
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);

  const updateEmailTemplateCategoryList = () => {
    gridApi.purgeInfiniteCache();
  };

  const [categoryAddModal, setOpenCategoryAddModal] = useState(false);
  const toggleCategoryAddModal = () => {
    setOpenCategoryAddModal(() => !categoryAddModal);
  };

  const [categoryId, setCategoryId] = useState(null);

  const [editModal, setOpenEditModal] = useState(false);
  const toggleEditModal = () => {
    if (editModal) setCategoryId(() => null);
    setOpenEditModal(() => !editModal);
  };

  const openModal = (category_id) => {
    setCategoryId(category_id);
  };

  useEffect(() => {
    if (categoryId !== null) toggleEditModal();
  }, [categoryId]);

  const [isNewCreated, setIsNewCreated] = useState(false);
  useEffect(() => {
    if (isNewCreated) {
      updateEmailTemplateCategoryList();
      setIsNewCreated(false);
    }
  }, [isNewCreated]);

  //#########################Colums defination start ###################
  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      width: 280,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      colSpan: (params) => {
        if (params.data === undefined) {
          return 3;
        } else {
          return 0;
        }
      },
      cellRenderer: (params) => renderTitle(params),
    },
    // {
    //   field: 'created_at',
    //   headerName: 'Created At',
    //   suppressMovable: true,
    //   cellClass: 'suppress-movable-col',
    //   sortable: true,
    //   filter: 'agDateColumnFilter',
    //   // pass in additional parameters to the text filter
    //   filterParams: {
    //     buttons: ['apply', 'reset'],
    //     closeOnApply: true,
    //     filterOptions: Constants.DATE_FILTER_OPTIONS,
    //     suppressAndOrCondition: true,
    //     debounceMs: 800,
    //   },
    //   cellRenderer: (params) => renderCreatedAt(params),
    // },
    {
      field: '_id',
      headerName: 'Actions',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) =>
        renderActions(
          params,
          openModal,
          sectionId,
          deleteEmailTemplateCategory,
          updateEmailTemplateCategoryList,
          addBreadcrumDetails
        ),
    },
  ];

  //#########################Colums defination end ###################

  //################## START Set properties(options) of datatables ###################

  const actions = (
    <div className="add-button-div">
      {/* <Button color="primary" size="sm" onClick={toggleCategoryAddModal}>
        <i className="fa fa-plus"></i> Add Category
      </Button>
      <CreateEmailTemplateCategory
        isOpen={categoryAddModal}
        toggle={toggleCategoryAddModal}
        sectionId={sectionId}
        setIsNewCreated={setIsNewCreated}
        update={updateEmailTemplateCategoryList}
      /> */}
    </div>
  );

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };

          const customData = await getEmailTemplateCategoryList({
            ...customParams,
            sectionId,
          });
          if (customData?.data?.status) {
            params.successCallback(
              customData.data?.response[0].data,
              customData.data?.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });

  let delayTimer;
  const onSearch = (e) => {
    clearTimeout(delayTimer);
    const searchText = e.target.value.trim();
    delayTimer = setTimeout(() => {
      if (gridApi) {
        if (Object.keys(sortingParams.filter).length) {
          gridApi.setFilterModel(sortingParams.filter);
        }
        const dataSource = {
          getRows: async (params) => {
            const page = gridApi.paginationGetCurrentPage() + 1;
            const limit = gridApi.paginationGetPageSize();

            const sortModel = params.sortModel.length
              ? params.sortModel[0]
              : null;
            const filterModel = params.filterModel ? params.filterModel : {};
            const customParams = {
              ...sortingParams,
              query: searchText,
              limit,
              orderBy: sortModel?.colId,
              ascending: sortModel?.sort,
              filter: filterModel,
              page,
            };

            const customData = await getEmailTemplateCategoryList({
              ...customParams,
              sectionId,
            });
            if (customData?.data?.status) {
              params.successCallback(
                customData.data.response[0].data,
                customData.data.response[0].metadata[0].totalRecord
              );
              customParams.onLoad = false;
              setSortingParams({ ...customParams });
              history(location.pathname, { state: customParams });
            } else {
              params.successCallback([], 0);
            }
          },
        };
        gridApi.setDatasource(dataSource);
        gridApi.api?.sizeColumnsToFit();
      }
    }, 1000);
  };

  return (
    <>
      <div className="animated fadeIn userTableList">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="tabletopsec">
                  {actions}
                  <div className="rightpaneltop">
                    <Input
                      type="text"
                      id="search-text-box"
                      placeholder="Search title"
                      onKeyUp={(e) => {
                        onSearch(e);
                      }}
                    />
                  </div>
                  <div className="rightpaneltop">
                    Template Category Per Page
                    <PerPageSelect
                      perPage={sortingParams.limit}
                      onPaginationChange={onPaginationChange}
                    />
                  </div>
                </div>
                <div className="card w-100 p-0 border-0">
                  <div className="card-body p-0 position-relative">
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        defaultColDef={{
                          resizable: true,
                        }}
                        loadingCellRenderer={customLoadingCellRenderer}
                        loadingCellRendererParams={loadingCellRendererParams}
                        domLayout={'autoHeight'}
                        rowModelType={'infinite'}
                        columnDefs={columns}
                        pagination={true}
                        sizeColumnsToFit={true}
                        onFilterChanged={filterChanged}
                        onGridReady={onGridReady}
                        paginationPageSize={sortingParams.limit}
                        cacheBlockSize={sortingParams.limit}
                        cacheQuickFilter={true}
                        onFirstDataRendered={onFirstDataRendered}
                        onPaginationChanged={onPaginationChanged}
                        ref={gridRef}
                      ></AgGridReact>
                    </div>
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <Link to={'/admin/email-template-sections'}>
                  <Button
                    type="reset"
                    size="sm"
                    color="danger"
                    disabled={loading}
                    style={{ marginLeft: '10px' }}
                  >
                    <i className="fa"></i> Back
                  </Button>
                </Link>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
      <CreateEmailTemplateCategory
        isOpen={editModal}
        toggle={toggleEditModal}
        setIsNewCreated={setIsNewCreated}
        categoryId={categoryId}
        isEdit={true}
        update={updateEmailTemplateCategoryList}
      />
    </>
  );
};

EmailTemplateList.propTypes = {
  getEmailTemplateCategoryList: PropTypes.func.isRequired,
  deleteEmailTemplateCategory: PropTypes.func.isRequired,
  addBreadcrumDetails: PropTypes.func.isRequired,
  emailTemplateCategoryList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  emailTemplateCategoryList:
    state.emailTemplateCategories.emailTemplateCategoryList,
  loading: state.emailTemplate.loading,
  subLoading: state.emailTemplate.isSubLoading,
});

export default connect(mapStateToProps, {
  getEmailTemplateCategoryList,
  deleteEmailTemplateCategory,
  addBreadcrumDetails,
})(EmailTemplateList);
