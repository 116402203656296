import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { edit, getGearShippingBoxById } from 'actions/admin/gearShippingBox';
import Errors from 'views/Notifications/Errors';

import Spinner from 'views/Spinner';

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  ModalFooter,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

import {
  SHIPPING_BOX_SIZE_UNIT,
  SHIPPING_BOX_WEIGHT_UNIT,
} from 'constants/index';

const EditShippingBox = ({
  edit,
  getGearShippingBoxById,
  currentGearShippingBox,
  errorList,
  toggle,
  isOpen,
  shipping_box_id,
  modal_loading,
  update,
}) => {
  //########################## use dispatch ##############################

  //########################## set the initial form data ##############################
  const [initialFormData, setInitialFormData] = useState({
    name: '',
    length: '',
    width: '',
    height: '',
    weight: '',
    min_quantity: '',
    max_quantity: '',
  });

  //########################## set the form data ##############################

  const [formData, setFormData] = useState(initialFormData);

  //########################## set the submit state ##############################
  const [isSubmit, setIsSubmit] = useState(false);

  //########################## reset form data when modal is closed ##############################
  const resetModal = () => {
    //reset the submit state
    setIsSubmit(false);
    //reset the form data to currentGearShippingBox
    setFormData(initialFormData);
  };

  //########################## explod form data vaiables ##############################
  const { name, length, width, height, weight, min_quantity, max_quantity } =
    formData;

  // ######################### get shipping box by id ############################
  useMemo(() => {
    console.log("Triggered")
    getGearShippingBoxById(shipping_box_id);
  }, [getGearShippingBoxById, shipping_box_id]);

  // ######################### currentGearShippingBox ###########################
  useMemo(() => {
    if (Object.keys(currentGearShippingBox).length > 0) {
      let tempFormData = {
        ...currentGearShippingBox,
        name: !currentGearShippingBox.name ? '' : currentGearShippingBox.name,
        length: !currentGearShippingBox.length
          ? ''
          : currentGearShippingBox.length,
        height: !currentGearShippingBox.height
          ? ''
          : currentGearShippingBox.height,
        width: !currentGearShippingBox.width
          ? ''
          : currentGearShippingBox.width,
        weight: !currentGearShippingBox.weight
          ? ''
          : currentGearShippingBox.weight,
        min_quantity: !currentGearShippingBox.min_quantity
          ? ''
          : currentGearShippingBox.min_quantity,
        max_quantity: !currentGearShippingBox.max_quantity
          ? ''
          : currentGearShippingBox.max_quantity,
      };
      setFormData(tempFormData);
      setInitialFormData(tempFormData);
    }
  }, [currentGearShippingBox]);

  //########################## change event for all inputs ##############################
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //########################## submit form data ##############################
  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    edit(formData, shipping_box_id).then((res) => {
      if (res === true) {
        toggle();
        update();
        getGearShippingBoxById(shipping_box_id)
      } else {
        setIsSubmit(false);
      }
    });
  };

  return (
    <div className="animated fadeIn">
      <Modal isOpen={isOpen} toggle={toggle} onClosed={resetModal}>
        <ModalHeader toggle={toggle}>Edit Shipping Box</ModalHeader>
        <Form
          className="form-horizontal"
          autoComplete="false"
          onSubmit={(e) => onSubmit(e)}
        >
          <ModalBody>
            <Row>
              <Col xs="12" sm="12">
                <FormGroup>
                  <Label htmlFor="name">
                    Name <span>*</span>
                  </Label>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    maxLength="100"
                    value={name}
                    required
                    onChange={(e) => onChange(e)}
                    invalid={errorList.name ? true : false}
                  />
                  <Errors current_key="name" key="name" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="length">
                    Length {SHIPPING_BOX_SIZE_UNIT} <span>*</span>
                  </Label>
                  <Input
                    type="number"
                    id="length"
                    name="length"
                    value={length}
                    min="1"
                    step={0.01}
                    max="999"
                    required
                    onChange={(e) => onChange(e)}
                    invalid={errorList.length ? true : false}
                  />
                  <Errors key="length" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="width">
                    Width {SHIPPING_BOX_SIZE_UNIT} <span>*</span>
                  </Label>
                  <Input
                    type="number"
                    id="width"
                    name="width"
                    value={width}
                    min="1"
                    step={0.01}
                    max="999"
                    required
                    onChange={(e) => onChange(e)}
                    invalid={errorList.width ? true : false}
                  />
                  <Errors current_key="width" key="width" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="height">
                    Height {SHIPPING_BOX_SIZE_UNIT} <span>*</span>
                  </Label>
                  <Input
                    type="number"
                    id="height"
                    name="height"
                    min="1"
                    max="999"
                    step={0.01}
                    value={height}
                    required
                    onChange={(e) => onChange(e)}
                    invalid={errorList.height ? true : false}
                  />
                  <Errors current_key="height" key="height" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="weight">
                    Weight {SHIPPING_BOX_WEIGHT_UNIT} <span>*</span>
                  </Label>
                  <Input
                    type="number"
                    id="weight"
                    name="weight"
                    min="1"
                    step={0.01}
                    max="999"
                    value={weight}
                    required
                    onChange={(e) => onChange(e)}
                    invalid={errorList.weight ? true : false}
                  />
                  <Errors current_key="weight" key="weight" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="min_quantity">
                    Min Quantity <span>*</span>
                  </Label>
                  <Input
                    type="number"
                    id="min_quantity"
                    name="min_quantity"
                    value={min_quantity}
                    min="1"
                    max="999"
                    required
                    onChange={(e) => onChange(e)}
                    invalid={errorList.min_quantity ? true : false}
                  />
                  <Errors current_key="min_quantity" key="min_quantity" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="max_quantity">
                    Max Quantity <span>*</span>
                  </Label>
                  <Input
                    type="number"
                    id="max_quantity"
                    name="max_quantity"
                    value={max_quantity}
                    min="1"
                    max="999"
                    required
                    onChange={(e) => onChange(e)}
                    invalid={errorList.max_quantity ? true : false}
                  />
                  <Errors current_key="max_quantity" key="max_quantity" />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="modalFooter">
              {modal_loading ? (
                <Spinner />
              ) : (
                <Button
                  className="mr-05"
                  type="submit"
                  size="sm"
                  color="primary"
                  // onClick={(e) => onSubmit(e)}
                  disabled={modal_loading}
                >
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
              )}
              <Button type="reset" size="sm" color="danger" onClick={toggle}>
                <i className="fa fa-ban"></i> Cancel
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

EditShippingBox.defaultProps = {
  isOpen: false,
};

EditShippingBox.propTypes = {
  edit: PropTypes.func.isRequired,
  getGearShippingBoxById: PropTypes.func.isRequired,
  currentGearShippingBox: PropTypes.object.isRequired,
  modal_loading: PropTypes.bool.isRequired,
  errorList: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  modal_loading: state.shippingBox.modal_loading,
  currentGearShippingBox: state.gearShippingBox.currentGearShippingBox,
});
export default connect(mapStateToProps, {
  edit,
  getGearShippingBoxById,
})(EditShippingBox);
