import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { loadUser } from 'actions/auth';
import setAuthToken from 'utils/setAuthToken';
import NotFoundPage from 'views/NotFound';
import Login from 'views/Auth/Login';

import AdminRoutes from './AdminRoutes';
import {
  OrderManagerRoutes,
  ProductManagerRoutes,
  ShippingManagerRoutes,
  CmsManagerRoutes,
  CustomerManagerRoutes,
  BuildShopManagerRoutes
} from './StaffRoutes';
import Layout from 'views/Admin/Layout';
import { PERMISSION_NAME } from 'constants';
import Spinner from 'views/Spinner';
import 'react-notifications/lib/notifications.css';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import ForgotPassword from 'views/Auth/ForgotPassword/ForgotPassword';
import ResetPassword from 'views/Auth/ForgotPassword/ResetPassword';

const RoutesPath = ({ loadUser, alerts, auth: { user, session, loading } }) => {
  const isLoggedIn = !!user?._id;

  const [accessibleRoutes, setAccessibleRoutes] = useState([]);

  const mergeUniquely = (navStaff) => {
    const uniqueObject = {};
    const uniqueArray = [];
    navStaff.forEach(nav => {
      if (!uniqueObject[nav['name']]) {
        uniqueArray.push(nav);
        uniqueObject[nav['name']] = 1;
      };
    });
    return uniqueArray;
  };

  const createNotification = (type, message) => {
    switch (type) {
      case 'info':
        return NotificationManager.info(message, '', 10000);
      case 'success':
        return NotificationManager.success(message, '', 10000);
      case 'warning':
        return NotificationManager.warning(message, '', 10000);
      case 'danger':
        return NotificationManager.error(message, '', 10000);
      default:
        break;
    }
  };

  useEffect(() => {
    alerts !== null &&
      alerts.length > 0 &&
      alerts.map((alert, idx) => {
        //setActive(!isActive);
        createNotification(`${alert.alertType}`, alert.message);
      });
  }, [alerts]);

  useEffect(() => {
    if (user?.isAdmin) {
      setAccessibleRoutes(AdminRoutes);
    } else if (user?.isStaff) {
      let staffRoutes = [];
      if (user?.permission.includes(PERMISSION_NAME.SUPER_ADMIN)) {
        setAccessibleRoutes(AdminRoutes);
      } else {
        if (user?.permission.includes(PERMISSION_NAME.ORDER_MANAGEMENT) || user?.permission.includes(PERMISSION_NAME.SHIPPING_MANAGEMENT)) {
          staffRoutes = staffRoutes.concat(OrderManagerRoutes);
        }
        if (user?.permission.includes(PERMISSION_NAME.BUILD_SHOP_MANAGEMENT)) {
          staffRoutes = staffRoutes.concat(BuildShopManagerRoutes)
        }
        if (user?.permission.includes(PERMISSION_NAME.PRODUCT_MANAGEMENT)) {
          staffRoutes = staffRoutes.concat(ProductManagerRoutes);
        }
        if (user?.permission.includes(PERMISSION_NAME.SHIPPING_MANAGEMENT)) {
          staffRoutes = staffRoutes.concat(ShippingManagerRoutes);
        }
        if (user?.permission.includes(PERMISSION_NAME.CUSTOMER_MANAGEMENT)) {
          staffRoutes = staffRoutes.concat(CustomerManagerRoutes);
        }
        if (user?.permission.includes(PERMISSION_NAME.CMS_MANAGEMENT)) {
          staffRoutes = staffRoutes.concat(CmsManagerRoutes);
        }
        setAccessibleRoutes(mergeUniquely(staffRoutes));
      }
    }
  }, [user]);

  useEffect(() => {
    let subscribe = true;
    async function asyncCall() {
      if (subscribe) {
        if (localStorage.session) {
          setAuthToken(localStorage.session);
          loadUser();
        }
      }
    }
    asyncCall();
    return () => {
      subscribe = false;
    };
    //eslint react-hooks/exhaustive-deps
  }, []);
  let defaultRoute = "/admin";
  if (user?.permission) {
    const permission = user?.permission;
    console.log(permission, 'permission')
    if (permission.includes(PERMISSION_NAME.ORDER_MANAGEMENT) || permission.includes(PERMISSION_NAME.SHIPPING_MANAGEMENT) || permission.includes(PERMISSION_NAME.BUILD_SHOP_MANAGEMENT)) {
      defaultRoute = "/admin/orders";

    }
    if (permission.includes(PERMISSION_NAME.PRODUCT_MANAGEMENT)) {
      defaultRoute = "/admin/products";
    }
    if (permission.includes(PERMISSION_NAME.CMS_MANAGEMENT)) {
      defaultRoute = `/admin/cms`;
    }
    if (permission.includes(PERMISSION_NAME.CUSTOMER_MANAGEMENT)) {
      defaultRoute = '/admin/customers';
    }
    if(permission.includes(PERMISSION_NAME.SUPER_ADMIN)) {
      defaultRoute = '/admin';
    }
  }
  return (
    loading ? <Spinner /> :
      <Router>
        <Container fluid>
          <NotificationContainer />
        </Container>
        <Routes>
          {/* <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} /> */}
          {isLoggedIn ? (
            <>
              <Route path="/admin" element={<Layout />}>
                {accessibleRoutes.map((route, idx) => (
                  <Route
                    path={route.path}
                    element={route.element}
                    name={route.name}
                    index={route.index}
                    key={idx}
                  />
                ))}
              </Route>
              <Route path="/" element={<Navigate to={defaultRoute} />} />
              <Route path="/login" element={<Navigate to={defaultRoute} />} />
              <Route exact path="*" element={<NotFoundPage />} />
            </>
          )
            : <>
              {/* <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} /> */}
              <Route path="*" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:id/:token" element={<ResetPassword />} />
              {/* <Route path="*" element={<NotFoundPage />} /> */}
            </>}
          {/* {<Route path="*" element={<NotFoundPage />} />} */}
        </Routes>
      </Router>
  );
};

RoutesPath.prototype = {
  auth: PropTypes.object.isRequired,
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  alerts: state.alert,
});

export default connect(mapStateToProps, {
  loadUser,
})(RoutesPath);
