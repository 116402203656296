export const refundCalculation = () => {
    return 100;
}

export const calculatePaidAmount = (paidAmount, otherAmount) => {
    let amount = paidAmount > otherAmount ? (paidAmount - otherAmount) : (otherAmount - paidAmount);
    return Number(amount).toFixed(2);
}

export const calculateDueAmount = (grandTotal, paidAmount) => {
    let dueAmount = grandTotal - paidAmount;
    console.log(grandTotal, paidAmount, 'due amount');
    return Number(dueAmount).toFixed(2);
}

export const calculateGrandTotalAmount = (productDetail, shippingPrice, tax, couponDetail, refundableAmount = 0) => {
    let grandTotalAmount =
        Number(productDetail?.fittingTotalAmount ?? 0) +
        Number(productDetail?.productTotalAmount ?? 0) +
        Number(shippingPrice ?? 0) +
        (Number(tax) -
            Number(couponDetail?.couponDiscount ?? 0) -
            Number(couponDetail?.couponShippingDiscount ?? 0));
    return Number((grandTotalAmount).toFixed(2))
}

export const commanCalculateAmount = (currentAmount, refundedAmount) => {
    let amount = currentAmount > refundedAmount ? (currentAmount - refundedAmount) : (refundedAmount - currentAmount);
    return Number(amount).toFixed(2);
}

export const amountDue = (grandTotal, paidAmount, refundedAmount = 0) => {
    const refunded = (Number(grandTotal ?? 0) + Number(refundedAmount ?? 0))

    if (Number(refunded) > Number(paidAmount)) {
        return (Number(refunded ?? 0) - Number(paidAmount ?? 0)).toFixed(2)
    }
    return Number(0).toFixed(2);
}

export const totalBeforeTax = (couponCode, fittingTotalAmount, productTotalAmount, couponDiscount) => {
    let productTotal = Number((fittingTotalAmount + productTotalAmount).toFixed(2));
    if (isNaN(couponCode) || (Number(couponDiscount) <= productTotal)) {
        let productAmount = Number(((fittingTotalAmount + productTotalAmount) - couponDiscount).toFixed(2));
        return (productAmount).toFixed(2);
    }
    return Number((productTotal).toFixed(2));
}
export const productTotal = (productTotalAmount, fittingTotalAmount) => {
    let productTotal = Number((fittingTotalAmount + productTotalAmount).toFixed(2));
    return productTotal;
}