import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';

import {
  edit,
  editStackableProduct,
  create,
  setWholesaleError,
} from 'actions/admin/product';
import { getProductsOptionList } from 'actions/admin/coupon';

import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';

const StackableProduct = ({
  product_id,
  currentProduct,
  changeTab,
  editStackableProduct,
  getProductsOptionList,
  applicableProductsOptionsList,
  loading,
}) => {
  //########################## set the initial form data ##############################
  let navigate = useNavigate();
  const [productBasicDetail, setProductBasicDetail] = useState({
    categoryId: product_id ? currentProduct?.categoryId : '',
  });
  
  const { categoryId = '' } = productBasicDetail;

  useEffect(() => {
    if (product_id !== null) {
      getProductsOptionList(product_id, 'SHAFTS')
    }
  }, [product_id])

  useEffect(() => {
    setProductBasicDetail({ ...productBasicDetail, categoryId: currentProduct?.categoryId })
  }, [currentProduct])
  //###################### get the category and sub category list ########################
  const [formData, setFormData] = useState({
    stackableProduct: [],
    selectedProducts: currentProduct?.stackableProducts,
  });

  const handleApplicableForProducts = (applicableProductsOptions) => {
    let applicableProductsIds = [];

    applicableProductsOptions.map((item, index) => {
      applicableProductsIds.push(item.value);
    });

    setFormData({
      ...formData,
      stackableProducts: applicableProductsIds,
      selectedProducts: applicableProductsOptions,
    });

    setSelectedStackableProduct(applicableProductsOptions);
  };

  let [selectedStackableProduct, setSelectedStackableProduct] = useState([]);
  useEffect(() => {
    if (product_id) {
      let dataArray = [];
      applicableProductsOptionsList.map((item, index) => {
        currentProduct?.stackableProducts?.map((stackItem, stackIndex) => {
          if (item.value === stackItem) {
            dataArray.push({ value: item.value, label: item.label });
          }
        });
      });
      setSelectedStackableProduct(dataArray);
    }
  }, [applicableProductsOptionsList]);

  let allProductList = '';
  useEffect(async () => {
    allProductList = await getProductsOptionList(currentProduct?._id);
  }, []);

  //########### handel submit form  ########################

  const onSubmit = (e) => {
    e.preventDefault();
    const mergedData = { ...productBasicDetail, ...formData };
    setProductBasicDetail(mergedData);

    editStackableProduct(mergedData, product_id).then((res) => {
      if (res === true) {
        changeTab('seo_tab');
      }
    });
  };

  return (
    <>
      <Form onSubmit={(e) => onSubmit(e)}>
        <Row>
          <Col xs="12">
            <FormGroup key={2}>
              <Label>Add Stackable Products</Label>
              <Select
                isMulti
                id="stackableProducts"
                name="stackableProducts"
                options={applicableProductsOptionsList}
                isClearable={true}
                isSearchable={true}
                onChange={(e) => handleApplicableForProducts(e)}
                value={selectedStackableProduct}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="Justify-flex-end">
            <FormGroup key={2}>
              <Button
                className="mr-05"
                type="submit"
                size="sm"
                color="primary"
                disabled={loading}
              >
                <i className="fa fa-dot-circle-o"></i> Save & Next
              </Button>
              <Link to="/admin/products">
                <Button type="reset" size="sm" color="danger">
                  <i className="fa fa-ban"></i> Cancel
                </Button>
              </Link>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </>
  );
};

StackableProduct.defaultProps = {
  product_id: null,
};

StackableProduct.propTypes = {
  category_loading: PropTypes.bool.isRequired,
  currentProduct: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
  categorySubCategoryList: PropTypes.array.isRequired,
  edit: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  setWholesaleError: PropTypes.func.isRequired,
  applicableProductsOptionsList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  category_loading: state.product.category_loading,
  currentProduct: state.product.currentProduct,
  errorList: state.errors,
  categorySubCategoryList: state.product.categorySubCategoryList,
  applicableProductsOptionsList: state.coupon.applicableProductsOptionsList,
  loading: state.product.loading,
});

export default connect(mapStateToProps, {
  edit,
  editStackableProduct,
  create,
  setWholesaleError,
  getProductsOptionList,
})(StackableProduct);
