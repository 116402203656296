import { event } from 'jquery';
import { useCallback, useEffect, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import {
    Input,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    Table,
    Form,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert
} from 'reactstrap';
import { getPackingSlipList } from 'actions/admin/packingSlip';
import {
    getShippingOrderById,
    calculateShippingRatesForLabelPrint,
    changeOrderStatus,
    downloadInvoice,
} from 'actions/admin/order';
import { connect } from 'react-redux';
import * as Constants from 'constants/index';
import Spinner from 'views/ButtonSpinner';

const RefundModel = (
    {
        getShippingOrderById,
        currentShippingOrder,
        calculateShippingRatesForLabelPrint,
        getPackingSlipList,
        packingSlipList,
        changeOrderStatus,
        isopen,
        setIsRefundModal,
        toggleRefundModal,
        refundFormData,
        orderTotalAmount,
        downloadInvoice,
        isReturnInitiate
    }
) => {
    const [sortingParams, setSortingParams] = useState({
        limit: Constants.DEFAULT_PAGE_SIZE,
        page: 1,
        orderBy: 'created_at',
        ascending: 'desc',
        query: '',
    });

    const [validate, setValidate] = useState(false);
    const [refundAmount, setRefundAmount] = useState({
        refunded_amount: '',
        previous_order_status: '',
        new_order_status: 11,
        refund_notes: '',
        selectedProducts: [{ productId: "", quantity: "", isReturned: false }]
    });
    const [submitType, setSubmitType] = useState(true);
    const [error, setError] = useState({ refunded_amount: "", refund_notes: "" });
    const [showPreview, setPreview] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [toAddress, setToAddress] = useState(null);
    const [customItems, setCustomItems] = useState([]);
    const [shippoPackages, setShippoPackages] = useState([]);
    const [currentShippoDetails, setCurrentShippoDetails] = useState(null);
    const [formData, setFormData] = useState({
        description: '',
        shippingRate: {},
        isUpdatePackingSlip: false,
        packingSlipId: '',
    });

    const [shippoWeightUnit, setShippoWeightUnit] = useState('lb');
    const [shippoLengthUnit, setShippoLengthUnit] = useState('in');
    const [shippingMode, setShippingMode] = useState({});
    const [downloadInvoiceURL, setDownloadInvoiceURL] = useState('');

    useEffect(() => {
        if (!isopen) {
            setRefundAmount({
                refunded_amount: '',
                previous_order_status: '',
                new_order_status: 11,
                refund_notes: '',
                selectedProducts: [{ productId: "", quantity: "", isReturned: false }]
            })
            setPreview(false);
        }
    }, [isopen])

    useEffect(() => {
        if (refundFormData) {
            const productList = refundFormData?.product?.map((item) => {
                return { productId: item._id, quantity: item.quantity, isReturned: false }
            })
            setRefundAmount({ ...refundAmount, ...refundFormData, selectedProducts: productList });
            //getShippingOrderById(refundFormData.order_id);
        }
    }, [refundFormData])

    const handleRefundChange = useCallback((event) => {
        setRefundAmount({ ...refundAmount, [event.target.name]: event.target.value });
    }, [refundAmount])

    /* const selectedProduct = useCallback((event, id, index) => {
        let selectedValue = [...refundAmount.selectedProducts]
        if (event.target.name === "quantity") {

            selectedValue[index] = {
                ...selectedValue[index],
                quantity: event.target.value
            }
            setRefundAmount(
                {
                    ...refundAmount,
                    selectedProducts: selectedValue
                }
            );
        }
        if (event.target.name === 'isReturned') {

            selectedValue[index] = {
                ...selectedValue[index],
                isReturned: event.target.checked
            }
            setRefundAmount(
                {
                    ...refundAmount,
                    selectedProducts: selectedValue
                }
            );
        }

    }) */
    const handleValidations = (type, value) => {
        if (value === "") {
            setError((errorData) => ({ ...errorData, [type]: "" }))
        }
        if (value !== "" && type === "refunded_amount") {
            setError((errorData) => ({ ...errorData, refunded_amount: "" }))
        }
    }

    const formValidation = (e) => {
        let isError = false;
        Object.keys(refundAmount).filter((refund) => ["refunded_amount", "refund_notes"].includes(refund)).map((item) => {
            if (refundAmount[item] === "") {
                setError((errorData) => ({ ...errorData, [item]: "Field is required." }))
                isError = true;
            }
            if ((refundAmount[item] !== "") && (Number(refundAmount.refunded_amount) > Number(refundAmount.grand_total))) {
                setError((errorData) => ({ ...errorData, refunded_amount: "Refund amount should less than grand total amount." }))
                isError = true;
            }
        })
        return isError;
    }
    const handleDownloadInvoice = async (order_id) => {
        let invoiceRespose = await downloadInvoice(order_id);
        if (invoiceRespose) {
            setDownloadInvoiceURL(invoiceRespose.data);
            document.getElementById('downloadInvoices').click();
        }
        // params.setDownloadInvoiceStatus(order_id);
    };

    const submitForm = async (event) => {
        event.preventDefault();
        if (event?.currentTarget?.getAttribute('data-id') == 'previewModel') {
            const validated = formValidation(event);
            if (validated) {
                return false;
            }
            setPreview(true);
        } else {
            setIsLoading(true);
            refundAmount.isReturnInitiate = isReturnInitiate
            const res = await changeOrderStatus(refundAmount).then((res) => {
                setIsLoading(false);
                if (res.data.status == true) {
                    toggleRefundModal(true);
                }
            });
        }

    }
    return (
        <Modal isOpen={isopen} className="refund-modaL" toggle={toggleRefundModal} backdrop={false}>
            <Form onSubmit={submitForm} data-id={!showPreview ? "previewModel" : "closeModel"} className="form-horizontal">
                <ModalHeader toggle={isLoading ? false : toggleRefundModal}>
                    Refund Amount
                </ModalHeader>
                <ModalBody>
                    <Alert color="danger" isOpen={true} >
                        <b>Note</b>: This order was marked paid manually, so kindly process the refund amount manually before marking this as refunded here.
                    </Alert>
                    <Row >
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    Invoice Details
                                    <a

                                        onClick={() => handleDownloadInvoice(refundFormData.order_id)}
                                    //className={downloadInvoiceDisabled == true ? 'd-none' : ''}
                                    >
                                        <Button type="button" size="sm" color="info" title="Download Invoice">

                                            <svg
                                                id="Layer_1"
                                                data-name="Layer 1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 309 402"
                                            >
                                                <path d="m29.87,395.75c-2.97-1.23-6.16-2.11-8.87-3.76-7.21-4.41-10.86-11.09-11.32-19.52-.04-.75-.02-1.5-.02-2.24,0-110.75,0-221.51,0-332.26,0-13.18,7.69-22.82,20.02-25.09,1.7-.31,3.47-.36,5.21-.36,50.89-.02,101.77,0,152.66-.05,4.53,0,8.15,1.42,11.36,4.64,31.09,31.17,62.22,62.31,93.4,93.39,3.42,3.41,4.84,7.23,4.83,12.03-.07,81.44-.05,162.89-.05,244.33,0,16.92-4.4,23.2-20.21,28.9H29.87ZM177.33,36.72H33.92v334.86h238.97v-239.32c-1.61,0-2.96,0-4.32,0-22.2,0-44.41.05-66.61-.02-14.22-.04-24.54-10.37-24.61-24.57-.06-14.1-.01-28.19-.02-42.29,0-9.47,0-18.94,0-28.67Zm24.21,17.84v53.47h53.47c-17.82-17.82-35.65-35.65-53.47-53.47Z" />
                                                <path d="m93.5,85.66c0-4.44-.06-8.68.01-12.91.11-7.05,5.2-12.29,11.88-12.33,6.66-.04,11.9,5.2,12.04,12.18.09,4.35.02,8.7.02,12.07,6.67,3.41,13,6.08,18.71,9.73,6.5,4.14,7.76,11.57,3.77,17.2-4.12,5.8-11.34,6.63-17.82,2.05-9.67-6.83-22.8-7.14-32.36-.77-10.98,7.31-10.91,19.63.17,26.88,5.3,3.47,11.23,4.34,17.46,4.59,15.2.62,28.18,6.26,37.74,18.38,14.52,18.41,9.49,43.94-11.28,56.9-4.91,3.06-10.49,5.04-16.37,7.8,0,3.47.04,7.67,0,11.88-.08,7.33-5.11,12.67-11.9,12.7-6.79.04-11.94-5.32-12.03-12.58-.05-4.11,0-8.22,0-12.58-1.06-.37-2.13-.62-3.08-1.09-5.45-2.68-11.16-4.95-16.22-8.23-6.01-3.89-7.04-11.27-3.15-16.72,3.99-5.59,11.23-6.63,17.2-2.48,10.66,7.41,23.83,7.72,33.58.78,10.01-7.12,10.1-18.65.13-25.83-4.91-3.54-10.5-5.04-16.52-5.1-9.67-.09-18.84-2.28-27.05-7.44-13.78-8.66-22.1-20.83-20.58-37.56,1.6-17.58,12.56-28.42,28.25-35.02,2.28-.96,4.71-1.6,7.44-2.5Z" />
                                                <path d="m153.21,275.98c27.69,0,55.38-.02,83.08.01,9.19.01,15.22,8.02,12.1,16.09-1.89,4.88-5.61,7.56-10.85,7.8-3.61.17-7.23.04-10.85.04-51.89,0-103.78-.02-155.67.03-5.49,0-9.79-1.86-12.25-6.9-3.82-7.84,1.76-16.84,10.63-17.03,8.35-.18,16.71-.04,25.07-.04,19.58,0,39.17,0,58.75,0Z" />
                                                <path d="m153.75,323.89c27.57,0,55.13-.01,82.7.01,6.21,0,10.74,3.37,12.31,8.93,1.4,4.97-.69,10.74-5.42,13.14-2.43,1.23-5.46,1.79-8.22,1.79-51.14.1-102.28.07-153.43.07-3.37,0-6.74,0-10.1,0-8.6-.01-14.07-4.74-14.01-12.08.06-7.18,5.51-11.86,13.85-11.86,27.44,0,54.88,0,82.33,0Z" />
                                                <path d="m213.08,204.12c-7.61,0-15.22.03-22.82,0-7.61-.04-12.96-5.07-12.92-12.04.04-6.94,5.42-11.88,13.06-11.89,15.21-.03,30.43-.03,45.64,0,7.65.01,13.06,4.94,13.12,11.85.06,7.06-5.4,12.06-13.27,12.09-7.61.03-15.21,0-22.82,0Z" />
                                                <path d="m213.25,228.08c7.73,0,15.46-.04,23.2.01,7.32.05,12.67,5.09,12.72,11.87.05,6.8-5.29,12.02-12.54,12.05-15.59.06-31.18.06-46.77,0-7.29-.03-12.57-5.22-12.52-12.06.05-6.8,5.38-11.81,12.72-11.86,7.73-.05,15.46-.01,23.2-.01Z" />
                                                {' '}
                                            </svg>
                                        </Button>
                                    </a>
                                </CardHeader>
                                <CardBody>
                                    <Row className="mt-2" >
                                        <Col md={12}>
                                            <Label >{"Product(s) Total"}</Label> : ${refundAmount.order?.product_total?.$numberDecimal}
                                        </Col>
                                        {refundAmount.order?.discount?.$numberDecimal > 0 && <Col md={12}>
                                            <Label>{"Discount"}</Label> : ${refundAmount.order?.discount?.$numberDecimal}
                                        </Col>}
                                        {refundAmount.order?.fitting_total?.$numberDecimal > 0 && <Col md={12}>
                                            <Label>{"Build Parameter(s) total"}</Label> : ${refundAmount.order?.fitting_total?.$numberDecimal}
                                        </Col>}
                                        <Col md={12}>
                                            <Label>{"Total Before Tax"}</Label> : ${
                                                Number(refundAmount.order?.fitting_total?.$numberDecimal) +
                                                (Number(refundAmount.order?.product_total?.$numberDecimal) -
                                                    Number(refundAmount.order?.discount?.$numberDecimal))
                                            }
                                        </Col>
                                        {refundAmount.order?.tax_total?.$numberDecimal > 0 && <Col md={12}>
                                            <Label>{"Sales Tax"}</Label> : ${refundAmount.order?.tax_total?.$numberDecimal}
                                        </Col>}
                                        {refundAmount.order?.shipping_mode?.$numberDecimal > 0 && <Col md={12}>
                                            <Label>{"Shipping"}</Label> : ${refundAmount.order?.shipping_mode?.$numberDecimal}
                                        </Col>}
                                        <Col md={12}>
                                            <Label>{"Paid Amount"}</Label> : ${refundAmount.order?.paid_amount?.$numberDecimal}
                                        </Col>
                                        {(Number(refundAmount.order?.grand_total?.$numberDecimal) - Number(refundAmount.order?.paid_amount?.$numberDecimal)) > 0 && <Col md={12}>
                                            <Label>{"Due Amount"}</Label> : ${(Number(refundAmount.order?.grand_total?.$numberDecimal ?? 0) - Number(refundAmount.order?.paid_amount?.$numberDecimal ?? 0)).toFixed(2)}
                                        </Col>}
                                        <Col md={12}>
                                            <Label>{"Grand Total"}</Label> : ${Number(refundAmount.order?.grand_total?.$numberDecimal)}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col >
                            <div className="add-button-div">
                                <a
                                    href={downloadInvoiceURL}
                                    id="downloadInvoices"
                                    hidden
                                    className="hide"
                                    target="_blank"
                                    download
                                ></a>
                            </div>

                            {showPreview && <Table bordered striped responsive size="sm" className="mt-2">
                                {refundAmount && (
                                    <tbody>
                                        <tr>
                                            <td>Total Amount</td>
                                            <td>${refundAmount.grand_total}</td>
                                        </tr>
                                        <tr>
                                            <td>Refund Amount</td>
                                            <td>${refundAmount.refunded_amount}</td>
                                        </tr>
                                        <tr className="data-ptag">
                                            <td>Remaining Amount</td>
                                            <td>
                                                $
                                                {(
                                                    refundAmount.grand_total -
                                                    refundAmount.refunded_amount
                                                ).toFixed(2)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Refund Note</td>
                                            <td>{refundAmount.refund_notes}</td>
                                        </tr>
                                    </tbody>
                                )}
                            </Table>
                            }
                        </Col>
                    </Row>
                    {!showPreview &&
                        <>
                            <Row className="mt-2">
                                <Col>
                                    <Label className="p-0">
                                        Refund Amount <span>*</span>
                                    </Label>
                                    <Input
                                        type="number"
                                        name="refunded_amount"
                                        id="refunded_amount"
                                        value={refundAmount.refunded_amount}
                                        placeholder="Please enter refund amount"
                                        min=""
                                        step=".01"
                                        max={orderTotalAmount}
                                        onChange={(e) => handleRefundChange(e)}
                                        onKeyUp={(event) => handleValidations(event.target.name, event.target.value)}
                                    ></Input>
                                    <small className="text-danger">{error.refunded_amount}</small>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Label className="p-0">Refund notes for the customer <span>*</span></Label>
                                    <Input
                                        type="textarea"
                                        name="refund_notes"
                                        value={refundAmount.refund_notes}
                                        id="refund_notes"
                                        placeholder="Please enter refund notes"
                                        minLength={0.1}
                                        maxLength={255}
                                        onChange={(e) => handleRefundChange(e)}
                                        onKeyUp={(event) => handleValidations(event.target.name, event.target.value)}
                                    ></Input>
                                    <small className="text-danger">{error.refund_notes}</small>
                                </Col>
                            </Row>
                        </>
                    }
                </ModalBody>
                <ModalFooter className="refund-modal">
                    {showPreview && <div className='back-button'><Button
                        size="sm"
                        color="primary"
                        type="button"
                        disabled={isLoading}
                        onClick={() => { setPreview(false) }}
                    >
                        Back
                    </Button></div>}
                    <div className='submit-close modalFooter '>
                        {isLoading ? <Spinner /> : (<Button
                            size="sm"
                            color="primary"
                            type="submit"
                            disabled={isLoading}
                        >
                            {showPreview ? "Submit" : "Preview"}
                        </Button>)}

                        <Button
                            size="sm"
                            color="danger"
                            disabled={isLoading}
                            data-id="closeRefundModel"
                            onClick={toggleRefundModal}
                        >
                            Close
                        </Button>
                    </div>
                </ModalFooter>
            </Form>
        </Modal >
    )
}

const mapStateToProps = (state) => ({
    packingSlipList: state.packingSlip.packingSlipList.data,
    packingSlipLoading: state.packingSlip.loading,
    packingSlipSubLoading: state.packingSlip.isSubLoading,
    currentShippingOrder: state.order.currentShippingOrder,
    shippingOrderLoading: state.order.shippingOrderLoading,
    shippingRatesLoading: state.order.labelPrintShippingRatesLoading,
    shippingLabelLoading: state.order.orderLabelLoading,
    loading: state.order.loading,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    changeOrderStatus,
    calculateShippingRatesForLabelPrint,
    getShippingOrderById,
    getPackingSlipList,
    downloadInvoice,
})(RefundModel);