/* eslint-disable */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, Row, CardFooter } from 'reactstrap';
import Moment from 'react-moment';
import Spinner from 'views/Spinner';
import { USER_ROLE, USER_APPROVED_STATUS } from 'constants/index';
import { getUserById } from 'actions/admin/user';

const ViewUserDetails = ({
  getUserById,
  currentUser,
  loading,
}) => {
  const navigate = useNavigate(); // make const(history deprecated)
  //################## Get Details of user by id ###################
  const { user_id: user_id } = useParams();

  useMemo(() => {
    getUserById(user_id, navigate);
  }, [getUserById, user_id]);

  //################## On back ###################
  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(navigate);
  };

  return loading || currentUser === null || currentUser._id !== user_id ? (
    <Spinner />
  ) : (
    <div>
      <Row>
        <Col md="12">
          <Card>
            <CardBody className="alldetailsuser">
              <div>
                <div>
                  <div>
                    <h4>
                      {currentUser.first_name + ' ' + currentUser.last_name}
                    </h4>
                    <h5>{currentUser.email}</h5>
                    <h5>Contact No.: {currentUser?.phone !== undefined ? (currentUser?.ccode !== undefined ? currentUser?.ccode : '') + currentUser?.phone : 'Not Available'}</h5>
                    <h5>
                      Role:{' '}
                      {/* {currentUser.role[0].user_type === 1? */}
                      {USER_ROLE[currentUser.role[0].user_type]}
                      {/* : currentUser.role[0].user_type === 2
                        ? USER_ROLE[2]
                        : USER_ROLE[3]} */}
                    </h5>
                    <h5>
                      Status:{' '}
                      {currentUser.status === 0
                        ? USER_APPROVED_STATUS[0]
                        : currentUser.status === 1
                          ? USER_APPROVED_STATUS[1]
                          : currentUser.status === 2
                            ? USER_APPROVED_STATUS[2]
                            : USER_APPROVED_STATUS[3]}
                    </h5>
                    <h5>
                      Account created on:{' '}
                      <Moment
                        format={process.env.REACT_APP_DISPLAY_DATE_FORMATE}
                        locale="de"
                      >
                        {currentUser.created_at}
                      </Moment>
                    </h5>
                    <h5>
                      Account details updated on:{' '}
                      <Moment
                        format={process.env.REACT_APP_DISPLAY_DATE_FORMATE}
                        locale="de"
                      >
                        {currentUser.updated_at}
                      </Moment>
                    </h5>
                    <h5>
                      Last login:{' '}
                      {currentUser.last_login === undefined ? (
                        '-'
                      ) : (
                        <Moment
                          format={process.env.REACT_APP_DISPLAY_DATE_FORMATE}
                          locale="de"
                        >
                          {currentUser.last_login}
                        </Moment>
                      )}
                    </h5>
                  </div>
                </div>
                <hr />
                <Row>
                  <Col>
                    {currentUser.customer_address !== undefined ? (
                      <div>
                        <p>Name: {currentUser.customer_address.name}</p>
                        <p>Email: {currentUser.customer_address.email}</p>
                        <p>
                          Contact No: {currentUser.customer_address.ccode && currentUser.customer_address.ccode}{currentUser.customer_address.contact_no}
                        </p>
                        <p>Address: {currentUser.customer_address.address}</p>
                        <p>City: {currentUser.customer_address.city}</p>
                        <p>State: {currentUser.customer_address.state}</p>
                        <p>Country: {currentUser.customer_address.country}</p>
                        <p>zipcode: {currentUser.customer_address.zipcode}</p>
                      </div>
                    ) : (
                      <div>
                        {' '}
                        <p> Address is not provided.... </p>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </CardBody>
            <CardFooter>
              <Link to="/admin/customers">
                <Button type="reset" size="sm" color="primary">
                  <i className="fa fa-arrow-left"></i> Back
                </Button>
              </Link>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

ViewUserDetails.propTypes = {
  getUserById: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  loading: state.user.loading,
});

export default connect(mapStateToProps, {
  getUserById,
})(ViewUserDetails);
