import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CKEditor } from 'ckeditor4-react';

import { sendAnnouncement } from 'actions/admin/emailTemplate';

import Errors from 'views/Notifications/Errors';
import Spinner from 'views/Spinner';

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';

import { REMOVE_ERRORS } from 'actions/types';
import { CKEditorConfig } from 'constants';

const Announcement = ({
    sendAnnouncement,
    history,
    errorList
}) => {
    /**************** use state for form data *********************/
    let navigate = useNavigate();
    const [formData, setFormData] = useState({
        sendTo: "Customers",
        subject: '',
        slug: 'announcement',
        description: `<table align="center" cellspacing="0" style="border-collapse:collapse; width:100%">
        <tbody>
            <tr>
                <td>
                <table cellspacing="0" style="border-collapse:collapse; width:100%">
                    <tbody>
                        <tr>
                            <td style="text-align:center">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<a href="#"><img alt="Logo" src="https://admin.qa.kinetixxgolf.com/static/media/logo.2d078b80684ed74d7e229fc3a69be400.svg" style="height:100px; width:200px" /></a> &nbsp;&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="text-align:left">
                            <p>Hi</p>
    
                            <p><br />
                            &nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align:left">
                            <p>Thank You,<br />
                            Team Kinetixx Golf</p>
                            &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;</td>
                        </tr>
                        <tr>
                            <td style="background-color:#682158; text-align:center">
                            <p style="color:#fff;">&copy; ${new Date().getFullYear()} All Rights Reserved. <a href="#" style="color:#fff;"> https://kinetixxgolf.com/</a></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </td>
            </tr>
        </tbody>
    </table>
    
        `,
        dynamic_parameters: '{{first_name}},{{business_name}},{{app_name}}',
    });

    const [loading, setLoading] = useState(false);

    /**************** expload form data *********************/
    const { subject, slug, description, dynamic_parameters } = formData;
    const dynamic_variables = [
        'app_name',
        'app_url',
        'copyright_year',
        'link',
        'name',
        'full_name',
        'password',
        'price',
        'email',
    ];

    /**************** dispatch to remove error *********************/

    /**************** on Change event *********************/
    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handelDescriptionChange = (event) => {
        setFormData((ev) => ({
            ...ev,
            description: event.editor.getData(),
        }));

        //setFormData({ ...formData, description: event.editor.getData() });
    };

    /**************** on submit event *********************/
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const result = sendAnnouncement(
            formData, navigate
        );
        setTimeout(() => {
            setLoading(false);
        }, 3000)
    };

    return (
        <div className="animated fadeIn">
            {loading ? (
                <Spinner />
            ) : (
                <Row>
                    <Col xs="12" sm="12">
                        <Card>
                            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
                                <CardHeader>
                                    <h1>Announcements</h1>
                                    <div className="radio-container">
                                        <label>Send To - </label>
                                        <div>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="Customers"
                                                    name="sendTo"
                                                    checked={formData?.sendTo === 'Customers'}
                                                    onChange={onChange}
                                                />
                                                Customers
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="Dealers"
                                                    name="sendTo"
                                                    checked={formData?.sendTo === 'Dealers'}
                                                    onChange={onChange}
                                                />
                                                Dealers
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="All"
                                                    name="sendTo"
                                                    checked={formData?.sendTo === 'All'}
                                                    onChange={onChange}
                                                />
                                                All
                                            </label>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>

                                        <Col sm="6">
                                            <FormGroup>
                                                <Label htmlFor="slug">
                                                    Email Template Slug <span>*</span>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    id="slug"
                                                    name="slug"
                                                    maxLength="100"
                                                    value={slug}
                                                    required
                                                    onChange={(e) => onChange(e)}
                                                    invalid={errorList.slug ? true : false}
                                                    disabled
                                                />
                                                <Errors current_key="slug" key="slug" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label htmlFor="subject">
                                                    Subject <span>*</span>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    id="subject"
                                                    name="subject"
                                                    maxLength="100"
                                                    value={subject}
                                                    required
                                                    onChange={(e) => onChange(e)}
                                                    invalid={errorList.subject ? true : false}
                                                />
                                                <Errors current_key="subject" key="subject" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label htmlFor="dynamic_parameters">
                                                    Dynamic Variables -
                                                </Label>
                                                <span style={{ marginLeft: '5px', fontSize: '10px' }}>
                                                    <span>Sample Dynamic Variables - </span>
                                                    {dynamic_variables.map((variable) => (
                                                        <span style={{ marginRight: '5px' }}>
                                                            {`{{${variable}}}`}
                                                            {','}
                                                        </span>
                                                    ))}
                                                </span>
                                                <Input
                                                    type="textarea"
                                                    id="dynamic_parameters"
                                                    name="dynamic_parameters"
                                                    maxLength="500"
                                                    value={dynamic_parameters}
                                                    onChange={(e) => onChange(e)}
                                                    invalid={errorList.dynamic_parameters ? true : false}
                                                    disabled
                                                />
                                                <Errors
                                                    current_key="dynamic_parameters"
                                                    key="dynamic_parameters"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label htmlFor="description">
                                                    Body <span>*</span>
                                                </Label>
                                                <CKEditor
                                                    initData={description}
                                                    id="description"
                                                    name="description"
                                                    config={CKEditorConfig}
                                                    onChange={(event) => handelDescriptionChange(event)}
                                                />
                                                <Errors current_key="description" key="description" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <Button
                                        type="submit"
                                        size="sm"
                                        color="primary"
                                        disabled={loading}
                                    >
                                        <i className="fa fa-dot-circle-o"></i> Send
                                    </Button>

                                    <Button
                                        type="reset"
                                        size="sm"
                                        color="danger"
                                        disabled={loading}
                                        style={{ marginLeft: '10px' }}
                                        onClick={() => navigate("/admin/announcements-list")}
                                    >
                                        <i className="fa fa-ban"></i> Cancel
                                    </Button>
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            )}
        </div>
    );
};

Announcement.propTypes = {
    sendAnnouncement: PropTypes.func.isRequired,
    errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
    errorList: state.errors,
});

export default connect(mapStateToProps, { sendAnnouncement })(
    Announcement
);
