import React, { memo, useCallback, useState } from 'react'
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import PhoneInput, { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import extractNumber from "utils/extractNumber";
import { getCountryShort } from "utils/countryCode";
import ButtonSpinner from 'views/ButtonSpinner';
import { connect } from 'react-redux';
import { selectedVariations } from 'actions/admin/order';

const ReplaceAddress = ({ variationData, selectedVariations, isOpen, toggleModel }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: variationData?.shippingAddressData.email,
        ccode: variationData?.shippingAddressData.ccode,
        contact_no: variationData?.shippingAddressData.contact_no,
        iute164_phone: `${variationData?.shippingAddressData.ccode}${variationData?.shippingAddressData.contact_no}`
    });
    const [defaultCountry, setDefaultCountry] = useState('US');
    const [defaultCountryCode, setDefaultCountryCode] = useState('+1');
    const [errors, setErrors] = useState({});

    const onPhoneChange = useCallback((number) => {
        const contact_no = extractNumber(number, defaultCountryCode);
        setFormData((form) => ({
            ...form,
            contact_no,
            iute164_phone: number
        }));
    }, [formData])

    const onCountryChange = (code) => {
        setFormData((form) => ({
            ...form,
            "ccode": "+" + getCountryCallingCode(code ?? getCountryShort(defaultCountryCode)),
        }));
        setDefaultCountryCode('+' + getCountryCallingCode(code ?? getCountryShort(defaultCountryCode)));
    }

    // Validate form data
    const validateForm = useCallback(() => {
        let formErrors = {};
        if (!formData.email) {
            formErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = 'Email is invalid';
        }

        if (!formData.contact_no) {
            formErrors.contact_no = 'Phone number is required';
        }
        return formErrors;
    });

    const submitForm = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Stop form submission if there are validation errors
        }
        setErrors({}); // Clear errors if validation passes

        variationData = {
            ...variationData,
            shippingAddressData: {
                ...variationData.shippingAddressData,
                email: formData.email,
                contact_no: formData.contact_no,
                ccode: formData.ccode
            },
            updateAddressEmailAndPhone: formData
        }
        selectedVariations(variationData)
        toggleModel()
    }
    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggleModel} backdrop={false}>
                <Form onSubmit={submitForm} className="form-horizontal">
                    <ModalHeader >
                        Change Email & Phone Number
                    </ModalHeader>
                    <ModalBody>
                        <Row className="mt-2">
                            <Col>
                                <Label className="p-0">
                                    Email <span>*</span>
                                </Label>
                                <Input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    placeholder="Email"
                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                    required
                                ></Input>
                                {errors.email && <small class="invalid-feedback-text text-danger form-text text-muted">{errors.email}</small>}
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <Label className="p-0">
                                    Contact No <span>*</span>
                                </Label>
                                <PhoneInput
                                    className=""
                                    autoComplete="tel"
                                    withCountryCallingCode={true}
                                    countryCallingCodeEditable={false}
                                    smartCaret={true}
                                    international={true}
                                    defaultCountry={defaultCountry}
                                    value={formData.iute164_phone}
                                    name="contact_no"
                                    addInternationalOption={false}
                                    onChange={onPhoneChange}
                                    onCountryChange={onCountryChange}
                                />
                                {errors.contact_no && <small class="invalid-feedback-text text-danger form-text text-muted">{errors.contact_no}</small>}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="refund-modal">
                        <div className='submit-close modalFooter'>
                            {isLoading ? <ButtonSpinner /> : <Button
                                size="sm"
                                color="primary"
                                type="button"
                                onClick={submitForm}
                            >
                                {"Submit"}
                            </Button>}
                            <Button
                                disabled={isLoading}
                                size="sm"
                                color="danger"
                                onClick={toggleModel}
                            >
                                Close
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    )
}
const mapStateToProps = (state) => ({
    variationData: state.order.variationData,
});
export default connect(mapStateToProps, {
    selectedVariations
})(ReplaceAddress);