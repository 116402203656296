/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';


//   PaginationProvider,
//   SizePerPageDropdownStandalone,
// } from 'react-bootstrap-table2-paginator';



import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import {
  getSubCategoryList,
  deleteSubCategory,
  changeStatus,
} from 'actions/admin/subCategory';

import * as Constants from 'constants/index';

import { Button, Card, CardBody, Col, Row, Input, Label } from 'reactstrap';

import Spinner from 'views/Spinner';
import CreateSubCategory from 'views/Admin/SubCategory/CreateSubCategory';
import CustomFilters from './CustomFilters';
import noImagae from '../../../assets/productdummy.png';
import PerPageSelect from "../PerPageSelect";

const SubCategoryList = ({
  getSubCategoryList,
  deleteSubCategory,
  changeStatus,
  subCategoryList: { data, count, parentName },
  loading,
  subLoading,
  match,
}) => {
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState({
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: 'name',
    ascending: 'desc',
    query: '',
  });

  const { category_id: category_id } = useParams();
  // to resolve useEffect dependency error


  //################## to get updated list ###################
  useEffect(() => {


    getSubCategoryList(category_id, sortingParams);
  }, [sortingParams]);


  //#########################Colums defination start ###################
  const columns = [
    {
      field: 'image',
      headerName: 'Image',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
      cellRenderer: (params) => {
        return !params.data.image ? (
          <img className="" height="70" src={noImagae} alt="" />
        ) : (
          <img
            src={
              //process.env.REACT_APP_AWS_STORAGE_URL +
              'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/category/' +
              params.data.image
            }
            className="preview-img"
            alt=""
          />
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
    {
      field: 'total_products',
      headerName: 'Total Products',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },

    {
      field: 'status',
      headerName: 'Status',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sort: true,
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },

      cellRenderer: (params) => {
        return (
          <div>
            <Input
              type="select"
              name="status"
              id={params.data._id}
              defaultValue={params.data.status}
              onChange={(e, a) => {
                changeStatus(params.data._id, e.target.value);
              }}
            >
              <option value="0">Inactive</option>
              <option value="1">Active</option>
            </Input>
          </div>
        );
      },
    },
    {
      field: '_id',
      headerName: 'Actions',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) => (
        <div>
          <Link to={`/admin/categories/subcategory/edit/${params.data._id}`}>
            <Button type="button" size="sm" color="success" title="Edit">
              <i className="fa fa-pencil"></i>
            </Button>
          </Link>

          <Button
            type="button"
            size="sm"
            color="danger"
            title="Delete"
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure, you want to delete ${params.data.name} sub category?`
                )
              ) {
                deleteSubCategory(params.data._id).then((res) => {
                  if (res) getSubCategoryList(category_id, sortingParams);
                });
              }
            }}
          >
            {' '}
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
      headerStyle: {
        backgroundColor: Constants.TABLE_BORDER_COLOR,
      },
    },
  ];
  //#########################Colums defination end ###################

  //################ toggle category add/edit modal ############################
  const [subCategoryModal, setOpenSubCategoryModal] = useState(false);
  const toggleSubCategoryModal = () => {
    setOpenSubCategoryModal(!subCategoryModal);
  };

  //################## START Set properties(options) of datatables ###################

  const actions = (
    <div className="add-button-div">
      <Link to="/admin/categories">
        <Button type="reset" color="primary">
          <i className="fa fa-arrow-left"></i> Back
        </Button>
      </Link>
      <Button color="primary" size="sm" onClick={toggleSubCategoryModal}>
        <i className="fa fa-plus"></i> Add New Sub Category
      </Button>
      <CreateSubCategory
        isOpen={subCategoryModal}
        toggle={toggleSubCategoryModal}
        category_id={category_id}
      ></CreateSubCategory>
    </div>
  );



  const defaultSorted = [
    {
      dataField: 'created_at',
      order: 'desc',
    },
  ];





  const [gridApi, setGridApi] = useState(null);
  const onGridReady = (params) => {
    setGridApi(params);

  };

  const onPaginationChange = (pageSize) => {
    gridApi.api.paginationSetPageSize(pageSize);
  };

  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                {actions}
                <div className="rightpaneltop">
                  Category Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>
              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      columnDefs={columns}
                      domLayout={'autoHeight'}
                      rowData={data}
                      pagination={true}
                      floatingFilter={true}
                      onGridReady={onGridReady}
                      paginationPageSize={20}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
SubCategoryList.propTypes = {
  getSubCategoryList: PropTypes.func.isRequired,
  deleteSubCategory: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  subCategoryList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.subcategory.loading,
  subLoading: state.subcategory.isSubLoading,
  subCategoryList: state.subcategory.subCategoryList,
});

export default connect(mapStateToProps, {
  getSubCategoryList,
  deleteSubCategory,
  changeStatus,
})(SubCategoryList);
