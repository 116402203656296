import axios from 'axios';

import {
  SET_ALERT,
  FITTING_VARIATION_FAIL,
  FITTING_VARIATION_LOADING,
  FITTING_VARIATION_LIST_UPDATED,
  GET_FITTING_VARIATION_BY_ID,
  FITTING_VARIATION_CHANGE_STATUS,
  FITTING_VARIATION_CHANGE_STATUS_ERROR,
} from 'actions/types';

//get Fitting attribute variation list
export const getFittingAttributeVariationList =
  (fittingVariationParams, attribute_id) => async (dispatch) => {
    try {
      dispatch({
        type: FITTING_VARIATION_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: fittingVariationParams,
      };

      const res = await axios.get(
        `/api/admin/fitting-variation/${attribute_id}/`,
        config
      );
      dispatch({
        type: FITTING_VARIATION_LIST_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: {
            variationList: res.data.response[0],
          },
        },
      });
      return res;
    } catch (err) {
      dispatch({
        type: FITTING_VARIATION_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

// Get attribute by id
export const getVariationById = (variation_id) => async (dispatch) => {
  try {
    dispatch({
      type: FITTING_VARIATION_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/fitting-variation/variation/${variation_id}`,
      config
    );

    await dispatch({
      type: GET_FITTING_VARIATION_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: FITTING_VARIATION_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//change status
export const changeStatus = (variation_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: FITTING_VARIATION_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/fitting-variation/change-status/${variation_id}`,
      { status },
      config
    );

    if (res.data.status === true) {
      await dispatch({
        type: FITTING_VARIATION_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: FITTING_VARIATION_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: variation_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: FITTING_VARIATION_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete Variation
export const deleteFittingAttributeVariation =
  (variation_id, history) => async (dispatch) => {
    try {
      dispatch({
        type: FITTING_VARIATION_LOADING,
        payload: { is_loading: true, is_sub_element: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.delete(
        `/api/admin/fitting-variation/${variation_id}`,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: SET_ALERT,
          payload: {
            message: res.data.message,
            alertType: 'success',
          },
        });
        return true;
      } else {
        dispatch({
          type: SET_ALERT,
          payload: {
            message: res.data.message,
            alertType: 'danger',
          },
        });
        return false;
      }
    } catch (err) {
      dispatch({
        type: FITTING_VARIATION_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };
