import {
  FITTING_VARIATION_MODAL_LOADING,
  FITTING_VARIATION_CREATED,
  FITTING_VARIATION_CREATE_ERROR,
  FITTING_VARIATION_FAIL,
  FITTING_VARIATION_LOADING,
  FITTING_VARIATION_LIST_UPDATED,
  GET_FITTING_VARIATION_BY_ID,
  FITTING_VARIATION_UPDATED,
  FITTING_VARIATION_UPDATE_ERROR,
  FITTING_VARIATION_CHANGE_STATUS,
  FITTING_VARIATION_CHANGE_STATUS_ERROR,
  FITTING_LIST_UPDATED,
} from "actions/types";

const initalState = {
  variationList: {
    page: 1,
    data: [],
    count: 0,
    attributeTitle: "",
  },
  fittingList: {
    page: 1,
    data: [],
    count: 0,
    attributeTitle: "",
  },
  currentVariation: {},
  loading: false,
  isSubLoading: false,
  modal_loading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case FITTING_VARIATION_LOADING:
      return {
        ...state,
        currentVariation: payload.is_sub_element
          ? state.currentVariation
          : initalState.currentVariation,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case FITTING_VARIATION_LIST_UPDATED:
      console.log("LEVEL 2", payload.data.variationList.data)
      return {
        ...state,
        variationList: {
          data: payload.data.variationList.data,
          attributeTitle:
            payload.data.variationList.data.length > 0
              ? payload.data.variationList.data[0].attribute_title
              : payload.data.variationList.attribute_title,
          page: payload.data.variationList.metadata[0].current_page,
          count: payload.data.variationList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case FITTING_LIST_UPDATED:
      console.log("LEVEL 1", payload.data.variationList.data)

      return {
        ...state,
        fittingList: {
          data: payload.data.variationList.data,
          attributeTitle:
            payload.data.variationList.data.length > 0
              ? payload.data.variationList.data[0].attribute_title
              : payload.data.variationList.attribute_title,
          page: payload.data.variationList.metadata[0].current_page,
          count: payload.data.variationList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case FITTING_VARIATION_FAIL:
      return {
        ...state,
        modal_loading: false,
        loading: false,
        isSubLoading: false,
      };
    case FITTING_VARIATION_MODAL_LOADING:
      return {
        ...state,
        modal_loading: true,
      };
    case FITTING_VARIATION_CREATED:
      return {
        ...state,
        modal_loading: false,
      };
    case FITTING_VARIATION_CREATE_ERROR:
      return {
        ...state,
        modal_loading: false,
      };
    case GET_FITTING_VARIATION_BY_ID:
      return {
        ...state,
        currentVariation: payload.data,
        loading: false,
        isSubLoading: false,
      };
    case FITTING_VARIATION_UPDATED:
      return {
        ...state,
        variationList: {
          ...state.variationList,
          data: state.variationList.data.map((variation) =>
            variation._id === payload.data._id ? payload.data : variation
          ),
        },
        currentVariation: {},
        modal_loading: false,
      };
    case FITTING_VARIATION_UPDATE_ERROR:
      return {
        ...state,
        modal_loading: false,
      };
    case FITTING_VARIATION_CHANGE_STATUS:
      return {
        ...state,
        variationList: {
          ...state.variationList,
          data: state.variationList.data.map((variation) =>
            variation._id === payload.data._id
              ? { ...variation, status: payload.data.status }
              : variation
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case FITTING_VARIATION_CHANGE_STATUS_ERROR:
      return {
        ...state,
        variationList: {
          ...state.variationList,
          data: state.variationList.data.map((variation) =>
            variation._id === payload.data
              ? { ...variation, is_failed: true }
              : { ...variation, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    default:
      return state;
  }
}
