import axios from 'axios';

import {
  CATEGORY_MODAL_LOADING,
  CATEGORY_CREATED,
  CATEGORY_CREATE_ERROR,
  CATEGORY_FAIL,
  CATEGORY_LOADING,
  CATEGORY_UPDATED,
  CATEGORY_UPDATE_ERROR,
  CATEGORY_DELETED,
  CATEGORY_DELETE_ERROR,
  CATEGORY_LIST_UPDATED,
  GET_CATEGORY_BY_ID,
  CATEGORY_CHANGE_STATUS,
  CATEGORY_CHANGE_STATUS_ERROR,
  SET_ERRORS_LIST,
} from 'actions/types';
import { updateFilterData } from 'actions/commonActions';

// Create Category
export const create = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: CATEGORY_MODAL_LOADING,
    });

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const param = new FormData();
    param.append('name', formData.name);
    param.append('slug', formData.slug);
    param.append('categoryType', formData.categoryType);
    param.append('file', formData.selectedFile);
    param.append('image', formData.image);
    param.append('customization', formData.customization);

    if (formData.image) param.append('isFileSelected', 1);
    else param.append('isFileSelected', 0);
    const res = await axios.post('/api/admin/category/create', param, config);

    if (res.data.status === true) {
      dispatch({
        type: CATEGORY_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: CATEGORY_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });

      return false;
    }
  } catch (err) {
    dispatch({
      type: CATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

// Edit Category
export const edit = (formData, history, category_id) => async (dispatch) => {
  try {
    dispatch({
      type: CATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });

    let param = new window.FormData();
    param.append('file', formData.selectedFile);
    param.append('slug', formData.slug);
    param.append('name', formData.name);
    param.append('customization', formData.customization);
    param.append('thumbnail', formData.thumbnail);
    param.append('image', formData.image);
    param.append('oldimage', formData.oldimage);
    if (formData.image) param.append('isFileSelected', 1);
    else param.append('isFileSelected', 0);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const res = await axios.post(
      `/api/admin/category/${category_id}`,
      param,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: CATEGORY_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/categories');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: CATEGORY_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: CATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

// Delete Category Page
export const deleteCategory = (category_id) => async (dispatch) => {
  try {
    dispatch({
      type: CATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(
      `/api/admin/category/${category_id}`,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: CATEGORY_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: CATEGORY_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: CATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

//get Category list

export const getCategoryList = (categoryParams) => async (dispatch) => {
  try {
    dispatch({
      type: CATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    categoryParams = updateFilterData(categoryParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: categoryParams,
    };

    const res = await axios.get(`/api/admin/category/`, config);
    dispatch({
      type: CATEGORY_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          categoryParams: categoryParams,
          categoryList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    dispatch({
      type: CATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Get CATEGORY by id
export const getCategoryById = (category_id) => async (dispatch) => {
  try {
    dispatch({
      type: CATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/category/${category_id}`, config);
    await dispatch({
      type: GET_CATEGORY_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: CATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//change status
export const changeStatus = (category_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: CATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/category/change-status/${category_id}`,
      { status },
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: CATEGORY_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: CATEGORY_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: category_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: CATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
