import axios from 'axios';

import {
  FITTING_ATTRIBUTE_LOADING,
  FITTING_ATTRIBUTE_LIST_UPDATED,
  FITTING_ATTRIBUTE_FAIL,
  FITTING_ATTRIBUTE_MODAL_LOADING,
  FITTING_ATTRIBUTE_CREATED,
  FITTING_ATTRIBUTE_CREATE_ERROR,
  GET_FITTING_ATTRIBUTE_BY_ID,
  FITTING_ATTRIBUTE_UPDATED,
  FITTING_ATTRIBUTE_DELETED,
  FITTING_ATTRIBUTE_ERROR,
  FITTING_ATTRIBUTE_UPDATE_ERROR,
  SET_ERRORS_LIST,
  FITTING_PART_DETAILS,
} from 'actions/types';

import { updateFilterData } from 'actions/commonActions';

//get Fitting attribute list
export const getFittingAttributeList =
  (fittingAttributeParams) => async (dispatch) => {
    try {
      dispatch({
        type: FITTING_ATTRIBUTE_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      fittingAttributeParams = updateFilterData(fittingAttributeParams);
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: fittingAttributeParams,
      };

      const res = await axios.get(`/api/admin/fitting-attribute/`, config);
      dispatch({
        type: FITTING_ATTRIBUTE_LIST_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: {
            attributeList: res.data.response[0],
          },
        },
      });
      return res;
    } catch (err) {
      dispatch({
        type: FITTING_ATTRIBUTE_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

// Create fitting attribute
export const create = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: FITTING_ATTRIBUTE_MODAL_LOADING,
    });
    const config = { headers: { 'Content-Type': 'application/json' } };

    const res = await axios.post(
      '/api/admin/fitting-attribute/create',
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: FITTING_ATTRIBUTE_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: FITTING_ATTRIBUTE_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: FITTING_ATTRIBUTE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

// Get attribute by id
export const getAttributeById = (attribute_id) => async (dispatch) => {
  try {
    dispatch({
      type: FITTING_ATTRIBUTE_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/fitting-attribute/${attribute_id}`,
      config
    );
    await dispatch({
      type: GET_FITTING_ATTRIBUTE_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: FITTING_ATTRIBUTE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit attribute
export const edit = (formData, attribute_id) => async (dispatch) => {
  try {
    dispatch({
      type: FITTING_ATTRIBUTE_MODAL_LOADING,
    });

    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.post(
      `/api/admin/fitting-attribute/${attribute_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: FITTING_ATTRIBUTE_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      const errors = res.data.errors;

      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: FITTING_ATTRIBUTE_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: FITTING_ATTRIBUTE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

// Delete fitting
export const deleteFitting = (attribute_id, history) => async (dispatch) => {
  try {
    dispatch({
      type: FITTING_ATTRIBUTE_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(
      `/api/admin/fitting-attribute/${attribute_id}`,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: FITTING_ATTRIBUTE_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: FITTING_ATTRIBUTE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: FITTING_ATTRIBUTE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

export const jumpToProductFitting = (part_no, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/fitting-attribute/jumpToVariation/${part_no}`,
      config
    );

    if (res.data.status) {
      const data = res.data.response;
      dispatch({
        type: FITTING_PART_DETAILS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: {
            fitting_part_detail: data,
          },
        },
      });
      if (data.attribute_title === 'Grips') {
        history(
          `/admin/product/fittings/grips/${data._id}/variations`
        );
      } else {
        history(
          `/admin/product/fittings/${data.attribute_id}`
        );
      }
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors?.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: FITTING_ATTRIBUTE_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return false;
    }
  } catch (err) {
    return false;
  }
};

