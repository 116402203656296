import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


import { CKEditor } from 'ckeditor4-react';

import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';

import { create } from 'actions/admin/packingSlip';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { REMOVE_ERRORS } from 'actions/types';
import { useNavigate } from 'react-router-dom';
import { CKEditorConfig } from 'constants';

const CreatePackingSlip = ({ create, history, errorList, loading }) => {
  let navigate = useNavigate();
  /**************** use state for form data *********************/
  const [formData, setFormData] = useState({
    name: '',
    // slug: '',
    description: '',
  });

  /**************** expload form data *********************/
  const {
    name,
    // slug,
    description,
  } = formData;

  /**************** dispatch to remove error *********************/


  /**************** on Change event *********************/
  const onChange = (e) => {
    // if (e.target.name === 'name') {
    //   let new_slug = slugify(e.target.value);
    //   setFormData({
    //     ...formData,
    //     [e.target.name]: e.target.value,
    //     slug: new_slug,
    //   });
    // } else {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // }

  };

  const handelDescriptionChange = (event) => {
    setFormData((ev) => ({
      ...ev,
      description: event.editor.getData(),
    }));
    //setFormData({ ...formData, description: event.editor.getData() });

  };

  /**************** on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    create(formData, navigate);
  };

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <Form
                className="form-horizontal"
                onSubmit={(e) => onSubmit(e)}
                encType="multipart/form-data"
              >
                <CardBody>
                  <Row>
                    {/* <Col md="6" sm="12">
                      <FormGroup>
                        <Label htmlFor="slug">
                          Slug <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="slug"
                          name="slug"
                          maxLength="100"
                          value={slug}
                          required
                          readOnly
                          invalid={errorList.slug ? true : false}
                        />
                        <Errors current_key="slug" key="slug" />
                      </FormGroup>
                    </Col> */}
                    <Col lg="12" sm="12">
                      <FormGroup>
                        <Label htmlFor="name">
                          Name <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="name"
                          name="name"
                          maxLength="100"
                          value={name}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.name ? true : false}
                        />
                        <Errors current_key="name" key="name" />
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="description">
                          Body <span>*</span>
                        </Label>
                        <CKEditor
                          data={description}
                          id="description"
                          name="description"
                          config={CKEditorConfig}
                          onChange={(event) => handelDescriptionChange(event)}
                          invalid={errorList.description ? true : false}
                        />
                        <Errors current_key="description" key="description" />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary">
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Link to="/admin/packing-slips">
                    <Button type="reset" size="sm" color="danger">
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

CreatePackingSlip.propTypes = {
  create: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.packingSlip.loading,
});

export default connect(mapStateToProps, { create })(CreatePackingSlip);
