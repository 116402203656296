import axios from 'axios';

import {
    BUILD_SHOP_SUPPLY_LOADING,
    BUILD_SHOP_SUPPLY_CREATED,
    BUILD_SHOP_SUPPLY_CREATE_ERROR,
    BUILD_SHOP_SUPPLY_FAIL,
    BUILD_SHOP_SUPPLY_UPDATED,
    BUILD_SHOP_SUPPLY_UPDATE_ERROR,
    BUILD_SHOP_SUPPLY_DELETED,
    BUILD_SHOP_SUPPLY_DELETE_ERROR,
    BUILD_SHOP_SUPPLY_LIST_UPDATED,
    GET_BUILD_SHOP_SUPPLY_BY_ID,
    BUILD_SHOP_INVENTORY_LOADING,
    BUILD_SHOP_INVENTORY_UPLOADED,
    BUILD_SHOP_INVENTORY_ERROR,
    BUILD_SHOP_INVENTORY_FAIL,
    SET_ERRORS_LIST,
} from 'actions/types';

import { updateFilterData } from 'actions/commonActions';


export const create = (formData, history) => async (dispatch) => {
    try {
        dispatch({
            type: BUILD_SHOP_SUPPLY_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const res = await axios.post('/api/admin/build-shop-supply/create', formData, config);
        if (res.data.status === true) {
            dispatch({
                type: BUILD_SHOP_SUPPLY_CREATED,
                payload: {
                    message: res.data.message,
                    alertType: 'success',
                    data: res.data.response,
                },
            });
            history('/admin/build-shop-supply');
        } else {
            const errors = res.data.errors;
            let errorsList = {};
            if (errors) {
                await errors.forEach((error) => {
                    errorsList = { ...errorsList, [error.param]: error.msg };
                });
            }
            dispatch({
                type: BUILD_SHOP_SUPPLY_CREATE_ERROR,
                payload: {
                    message: res.data.message,
                    alertType: 'danger',
                    errorsList,
                },
            });
            dispatch({
                type: SET_ERRORS_LIST,
                payload: { ...errorsList },
            });
        }
    } catch (err) {
        dispatch({
            type: BUILD_SHOP_SUPPLY_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
    }
};


export const edit = (formData, history, product_id) => async (dispatch) => {
    try {
        dispatch({
            type: BUILD_SHOP_SUPPLY_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const res = await axios.post(
            `/api/admin/build-shop-supply/${product_id}`,
            formData,
            config
        );
        if (res.data.status === true) {
            dispatch({
                type: BUILD_SHOP_SUPPLY_UPDATED,
                payload: {
                    message: res.data.message,
                    alertType: 'success',
                    data: res.data.response,
                },
            });
            history('/admin/build-shop-supply');
        } else {
            const errors = res.data.errors;
            let errorsList = {};
            if (errors) {
                await errors.forEach((error) => {
                    errorsList = { ...errorsList, [error.param]: error.msg };
                });
            }
            dispatch({
                type: BUILD_SHOP_SUPPLY_UPDATE_ERROR,
                payload: {
                    message: res.data.message,
                    alertType: 'danger',
                    errorsList,
                },
            });
            dispatch({
                type: SET_ERRORS_LIST,
                payload: { ...errorsList },
            });
        }
    } catch (err) {
        dispatch({
            type: BUILD_SHOP_SUPPLY_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
    }
};


export const deleteBuildShopSupply = (product_id) => async (dispatch) => {
    try {
        dispatch({
            type: BUILD_SHOP_SUPPLY_LOADING,
            payload: { is_loading: true, is_sub_element: true },
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const res = await axios.delete(`/api/admin/build-shop-supply/${product_id}`, config);

        if (res.data.status === true) {
            dispatch({
                type: BUILD_SHOP_SUPPLY_DELETED,
                payload: {
                    message: res.data.message,
                    alertType: 'success',
                    data: res.data.response,
                },
            });
            return true;
        } else {
            dispatch({
                type: BUILD_SHOP_SUPPLY_DELETE_ERROR,
                payload: {
                    message: res.data.message,
                    alertType: 'danger',
                },
            });
            return false;
        }
    } catch (err) {
        dispatch({
            type: BUILD_SHOP_SUPPLY_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
        return false;
    }
};


export const getBuildShopSupplyList = (newsParams) => async (dispatch) => {
    try {
        dispatch({
            type: BUILD_SHOP_SUPPLY_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        newsParams = updateFilterData(newsParams);
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: newsParams,
        };
        // const query = newsParams.query ? newsParams.query : '&query=';

        const res = await axios.get(`/api/admin/build-shop-supply`, config);
        dispatch({
            type: BUILD_SHOP_SUPPLY_LIST_UPDATED,
            payload: {
                message: res.data.message,
                alertType: 'success',
                data: {
                    newsParams: newsParams,
                    buildShopSupplyList: res.data.response[0],
                },
            },
        });
        return res;
    } catch (err) {
        dispatch({
            type: BUILD_SHOP_SUPPLY_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
    }
};


export const getBuildShopSupplyById = (product_id) => async (dispatch) => {
    try {
        dispatch({
            type: BUILD_SHOP_SUPPLY_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const res = await axios.get(`/api/admin/build-shop-supply/${product_id}`, config);

        await dispatch({
            type: GET_BUILD_SHOP_SUPPLY_BY_ID,
            payload: {
                message: res.data.message,
                alertType: 'success',
                data: res.data.response,
            },
        });
    } catch (err) {
        dispatch({
            type: BUILD_SHOP_SUPPLY_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
    }
};


export const downloadbuildShopSupply = async (sortingParams, timeZone) => {
    try {
        sortingParams = updateFilterData(sortingParams);
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: { ...sortingParams, timeZone },
        };
        const res = await axios.get(`/api/admin/build-shop-supply/csv/download`, config);
        return res.data.response;
    } catch (err) {
        return {};
    }
};

export const uploadBuildShopSupply = (csvData) => async (dispatch) => {
    try {
        dispatch({
            type: BUILD_SHOP_INVENTORY_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const res = await axios.post(
            `/api/admin/build-shop-supply/inventory/upload`, csvData,
            config
        );
        if (res.data.status === true) {
            dispatch({
                type: BUILD_SHOP_INVENTORY_UPLOADED,
                payload: {
                    message: res.data.message,
                    alertType: 'success',
                    data: res.data.response,
                },
            });

            return res.data;
        } else {
            const errors = res.data.errors;
            let errorsList = [];
            if (errors) {
                await errors.forEach((error) => {
                    errorsList[error.param] = error.msg;
                });
            }
            dispatch({
                type: BUILD_SHOP_INVENTORY_FAIL,
                payload: {
                    message: res.data.message,
                    alertType: 'danger',
                    errorsList,
                },
            });
            return false;
        }
    } catch (err) {
        console.log(err);
        dispatch({
            type: BUILD_SHOP_INVENTORY_ERROR,
            payload: {
                message:
                    err.response && err.response.message
                        ? err.response.message
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
        return false;
    }
};