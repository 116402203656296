import {
  FITTING_ATTRIBUTE_LOADING,
  FITTING_ATTRIBUTE_LIST_UPDATED,
  FITTING_PART_DETAILS,
  RESET_FITTING_PART_DETAILS,
  FITTING_ATTRIBUTE_FAIL,
  FITTING_ATTRIBUTE_MODAL_LOADING,
  FITTING_ATTRIBUTE_CREATED,
  FITTING_ATTRIBUTE_CREATE_ERROR,
  GET_FITTING_ATTRIBUTE_BY_ID,
  FITTING_ATTRIBUTE_ERROR,
  FITTING_ATTRIBUTE_UPDATED,
  FITTING_ATTRIBUTE_UPDATE_ERROR,
  FITTING_IMPORT_INVENTORY_LOADING,
  FITTING_IMPORT_INVENTORY_UPLOADED
} from "actions/types";

const initalState = {
  attributeList: {
    page: 1,
    data: [],
    count: 0,
  },
  fitting_part_detail: null,
  currentAttribute: {},
  loading: false,
  isSubLoading: false,
  modal_loading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case FITTING_ATTRIBUTE_LOADING:
      return {
        ...state,
        attributeList: payload.is_sub_element
          ? state.attributeList
          : initalState.attributeList,
        currentAttribute: payload.is_sub_element
          ? state.currentAttribute
          : initalState.currentAttribute,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case FITTING_ATTRIBUTE_LIST_UPDATED:
      return {
        ...state,
        attributeList: {
          data: payload.data.attributeList.data,
          page: payload.data.attributeList.metadata[0].current_page,
          count: payload.data.attributeList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case FITTING_PART_DETAILS:
      return {
        ...state,
        fitting_part_detail: payload.data,
      };
    case FITTING_IMPORT_INVENTORY_LOADING:
    case FITTING_ATTRIBUTE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case RESET_FITTING_PART_DETAILS:
      if (payload) {
        return {
          ...state,
          fitting_part_detail: null,
        };
      }
    case FITTING_ATTRIBUTE_FAIL:
      return {
        ...state,
        modal_loading: false,
        loading: false,
        isSubLoading: false,
      };
    case FITTING_ATTRIBUTE_MODAL_LOADING:
      return {
        ...state,
        modal_loading: true,
      };
    case FITTING_ATTRIBUTE_CREATED:
      return {
        ...state,
        modal_loading: false,
      };
    case FITTING_ATTRIBUTE_CREATE_ERROR:
      return {
        ...state,
        modal_loading: false,
      };

    case GET_FITTING_ATTRIBUTE_BY_ID:
      return {
        ...state,
        currentAttribute: payload.data,
        loading: false,
        isSubLoading: false,
      };
    case FITTING_ATTRIBUTE_UPDATED:
      return {
        ...state,
        attributeList: {
          ...state.attributeList,
          data: state.attributeList.data.map((attribute) =>
            attribute._id === payload.data._id
              ? { ...attribute, title: payload.data.title }
              : attribute
          ),
        },
        currentAttribute: {},
        modal_loading: false,
      };
    case FITTING_ATTRIBUTE_UPDATE_ERROR:
      return {
        ...state,
        modal_loading: false,
      };
    default:
      return state;
  }
}
