import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import Errors from 'views/Notifications/Errors';
import Spinner from 'views/ButtonSpinner';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import { editPassword } from 'actions/admin/profile';

import {
  Button,
  Input,
  ModalFooter,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  //InputGroupAddon,
  InputGroupText,
  Form,
  FormText,
} from 'reactstrap';
import { REMOVE_ERRORS } from 'actions/types';
import { useNavigate } from 'react-router-dom';
import { logout } from 'actions/auth';

const ChangePassword = ({
  errorList,
  toggle,
  isOpen,
  editPassword,
  modal_loading,
  logout,
  props
}) => {
  let navigate = useNavigate();
  const inputRef = useRef(null);
  //########################## use dispatch ##############################
  const dispatch = useDispatch();

  //########################## set the initial form data ##############################

  const initialState = {
    current_password: '',
    password: '',
    confirm_password: '',
  };
  const [passwordFormData, setPasswordFormData] = useState(initialState);
  const [currPasswordShown, setCurrPasswordShown] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [passwordId, setPasswordId] = useState("");
  const [checkPassword, setCheckPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  //########################## set the submit state ##############################
  const [isPasswordSubmit, setIsPasswordSubmit] = useState(false);

  //########################## reset for data when modal is closed ##############################
  const resetModal = () => {
    dispatch({ type: REMOVE_ERRORS });
    //reset the submit state
    setIsPasswordSubmit(false);
    //reset the form data to initial state
    setPasswordFormData(initialState);
  };

  //########################## explod form data vaiables ##############################
  const { current_password, password, confirm_password } = passwordFormData;

  //########################## change event for all inputs ##############################
  const onChange = (e) => {
    setPasswordFormData({
      ...passwordFormData,
      [e.target.name]: e.target.value?.trim(),
    });
    setCheckPassword(false);
    setErrorMessage('');
    dispatch({ type: REMOVE_ERRORS });
  };

  //########################## submit form data ##############################
  const onPasswordSubmit = (e) => {
    e.preventDefault();
    setIsPasswordSubmit(true);
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
    const isChecked = regex.test(password);
    // if (password !== confirm_password) {
    //   setErrorMessage("Password do not match.");
    // } else if (!isChecked) {
    //   setCheckPassword(true);
    //   setErrorMessage('')
    // } else {
    editPassword(passwordFormData).then((res) => {
      if (res === true) {
        toggle();
        logout(navigate);
      } else {
        setIsPasswordSubmit(false);
      }
    });
    setErrorMessage('');
    setCheckPassword(false);
    // }
  };

  const togglePassword = (e) => {
    e.preventDefault();
    if (e.currentTarget.getAttribute("data-id") == "password") {
      setPasswordShown(!passwordShown);
      setConfirmPassword(confirmPassword);
      setCurrPasswordShown(currPasswordShown);
    }
    if (e.currentTarget.getAttribute("data-id") == "confirm_password") {
      setPasswordShown(passwordShown);
      setConfirmPassword(!confirmPassword);
      setCurrPasswordShown(currPasswordShown);
    }
    if (e.currentTarget.getAttribute("data-id") == "current_password") {
      setCurrPasswordShown(!currPasswordShown);
      setPasswordShown(passwordShown);
      setConfirmPassword(confirmPassword);
    }
  };

  return (
    <div className="animated fadeIn">
      <Modal isOpen={isOpen} toggle={toggle} onClosed={resetModal} backdrop="static">
        {!props ?
          <ModalHeader toggle={toggle}>Change Password</ModalHeader>
          : <ModalHeader>Change Password</ModalHeader>
        }
        <Form onSubmit={(e) => onPasswordSubmit(e)}>
          <ModalBody>
            <div className="mb-3">
              <InputGroup>
                <InputGroupText>
                  <i className="icon-lock"></i>
                </InputGroupText>
                {current_password == '' &&
                  <ReactTooltip id="title required1" efect="solid" place="bottom">
                    Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                  </ReactTooltip>
                }
                <Input
                  type={(currPasswordShown) ? "text" : "password"}
                  placeholder="Current Password"
                  autoComplete="current_password"
                  name="current_password"
                  id="current_password"
                  ref={inputRef}
                  data-tip
                  data-for="title required1"
                  // required
                  value={current_password}
                  onChange={(e) => onChange(e)}
                  invalid={errorList.current_password ? true : false}
                />
                <InputGroupText onClick={togglePassword} data-id="current_password" >{(currPasswordShown) ? <FaRegEyeSlash /> : <FaRegEye />}</InputGroupText>
              </InputGroup>
              <Errors current_key="current_password" key="current_password" />
            </div>
            <div className="mb-3">
              <InputGroup>
                <InputGroupText>
                  <i className="icon-lock"></i>
                </InputGroupText>
                {password == '' &&
                  <ReactTooltip id="title required2" efect="solid" place="bottom">
                    Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                  </ReactTooltip>
                }
                <Input
                  type={(passwordShown) ? "text" : "password"}
                  placeholder="Password"
                  autoComplete="password"
                  name="password"
                  id="password"
                  ref={inputRef}
                  data-tip
                  data-for="title required2"
                  // required
                  value={password}
                  onChange={(e) => onChange(e)}
                  invalid={errorList.password ? true : false}
                />
                <InputGroupText onClick={togglePassword} data-id="password" >{(passwordShown) ? <FaRegEyeSlash /> : <FaRegEye />}</InputGroupText>
              </InputGroup>
              <Errors current_key="password" key="password" />
              {(checkPassword && password != "") &&
                <div id="message">
                  <FormText style={{ fontSize: "10px" }} className='text-danger'> Password requirements:</FormText>
                  <FormText>
                    <li style={{ fontSize: "10px" }} className='text-danger'>Must contain at least 8 characters</li>
                    <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 uppercase letter</li>
                    <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 number</li>
                    <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 special character (@$!%*#?&.)</li>
                  </FormText>
                </div>
              }
            </div>
            <div className="mb-3">
              <InputGroup>
                <InputGroupText>
                  <i className="icon-lock"></i>
                </InputGroupText>
                {confirm_password == '' &&
                  <ReactTooltip id="title required3" efect="solid" place="bottom">
                    Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                  </ReactTooltip>
                }
                <Input
                  type={(confirmPassword) ? "text" : "password"}
                  placeholder="Confirm Password"
                  autoComplete="confirm_password"
                  name="confirm_password"
                  id="confirm_password"
                  // required
                  ref={inputRef}
                  data-tip
                  data-for="title required3"
                  value={confirm_password}
                  onChange={(e) => onChange(e)}
                  invalid={errorList.confirm_password ? true : false}
                />
                <InputGroupText onClick={togglePassword} data-id="confirm_password" >{(confirmPassword) ? <FaRegEyeSlash /> : <FaRegEye />}</InputGroupText>
              </InputGroup>
              <Errors current_key="confirm_password" key="confirm_password" />
              {errorMessage && <span className='text-danger'>{errorMessage}</span>}
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="modalFooter">
              {/* {modal_loading ? (
                <Spinner />
              ) : ( */}
              <Button
                className="mr-05"
                type="submit"
                size="sm"
                color="primary"
              // onClick={e => onPasswordSubmit(e)}
              // disabled={isPasswordSubmit}
              >
                <i className="fa fa-dot-circle-o"></i> Save
              </Button>
              {/* )} */}
              {!props &&
                <Button type="reset" size="sm" color="danger" onClick={toggle}>
                  <i className="fa fa-ban"></i> Cancel
                </Button>
              }
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

ChangePassword.defaultProps = {
  isOpen: false,
};

ChangePassword.propTypes = {
  //   create: PropTypes.func.isRequired,
  modal_loading: PropTypes.bool.isRequired,
  errorList: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  editPassword: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  modal_loading: state.profile.modal_loading,
});
export default connect(mapStateToProps, { editPassword, logout })(ChangePassword);
