import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { editProductSeo } from 'actions/admin/product';

import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import Errors from 'views/Notifications/Errors';

const ProductSeoDetail = ({
  product_id,
  currentProduct,
  errorList,
  editProductSeo,
  loading,
}) => {
  //########################## set the initial form data ##############################

  const [formData, setFormData] = useState({
    product_id: product_id ? product_id : '',
    meta_title: product_id ? currentProduct.meta_title : '',
    meta_description: product_id ? currentProduct.meta_description : '',
  });
  const { meta_title, meta_description } = formData;
  let navigate = useNavigate();

  //########### change form data and sub category if category changed ########################
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setFormData({ ...formData, product_id: product_id });
    if (currentProduct && currentProduct?.product_seo?.length > 0) {
      setFormData(currentProduct.product_seo[0]);
    }
  }, [product_id, currentProduct]);

  //########### handel submit form  ########################
  const onSubmit = (e) => {
    e.preventDefault();
    editProductSeo(formData, product_id, navigate).then((res) => {});
  };

  return (
    <>
      <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <CardBody>
                <Row>
                  <Col xs="12" sm="12">
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="meta_title">
                            Page Title <span>*</span>
                          </Label>
                          <Input
                            type="text"
                            id="meta_title"
                            name="meta_title"
                            maxLength="100"
                            minLength="3"
                            value={meta_title}
                            required
                            onChange={(e) => onChange(e)}
                            invalid={errorList.meta_title ? true : false}
                          />
                          <Errors current_key="meta_title" key="meta_title" />
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="meta_description">
                            Meta Description <span>*</span>
                          </Label>
                          <Input
                            type="textarea"
                            id="meta_description"
                            name="meta_description"
                            maxLength="100"
                            required
                            value={meta_description}
                            onChange={(e) => onChange(e)}
                            invalid={errorList.meta_description ? true : false}
                          />
                          <Errors
                            current_key="meta_description"
                            key="meta_description"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="Justify-flex-end">
            <FormGroup key={2}>
              <Button
                className="mr-05"
                type="submit"
                size="sm"
                color="primary"
                disabled={loading}
              >
                <i className="fa fa-dot-circle-o"></i> Save
              </Button>
              <Link to="/admin/products">
                <Button type="reset" size="sm" color="danger">
                  <i className="fa fa-ban"></i> Cancel
                </Button>
              </Link>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </>
  );
};

ProductSeoDetail.defaultProps = {
  product_id: null,
};

ProductSeoDetail.propTypes = {
  currentProduct: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
  editProductSeo: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  currentProduct: state.product.currentProduct,
  errorList: state.errors,
  loading: state.product.loading,
});

export default connect(mapStateToProps, {
  editProductSeo,
})(ProductSeoDetail);
