import {
  ATTRIBUTE_LOADING,
  ATTRIBUTE_CREATED,
  ATTRIBUTE_CREATE_ERROR,
  ATTRIBUTE_FAIL,
  ATTRIBUTE_UPDATED,
  ATTRIBUTE_UPDATE_ERROR,
  ATTRIBUTE_DELETED,
  ATTRIBUTE_DELETE_ERROR,
  ATTRIBUTE_LIST_UPDATED,
  GET_ATTRIBUTE_BY_ID,
  ATTRIBUTE_CHANGE_STATUS,
  ATTRIBUTE_CHANGE_STATUS_ERROR,
  ATTRIBUTE_TAG_DELETED,
  ATTRIBUTE_TAG_DELETE_ERROR,
  CANCEL_REQUEST,
} from "actions/types";

const initalState = {
  attributesList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentAttribute: {},
  loading: false,
  isSubLoading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case ATTRIBUTE_LOADING:
      return {
        ...state,
        attributesList: payload.is_sub_element
          ? state.attributesList
          : initalState.attributesList,
        currentAttribute: payload.is_sub_element
          ? state.currentAttribute
          : initalState.currentAttribute,

        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case CANCEL_REQUEST:
        return {
          ...state,
          loading: false,
        }
    case ATTRIBUTE_CREATED:
      return {
        ...state,
        loading: false,
      };
    case ATTRIBUTE_CREATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ATTRIBUTE_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case ATTRIBUTE_UPDATED:
      return {
        ...state,
        currentAttribute: {},
        loading: false,
      };
    case ATTRIBUTE_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ATTRIBUTE_DELETED:
      return {
        ...state,
        loading: false,
      };
    case ATTRIBUTE_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ATTRIBUTE_LIST_UPDATED:
      return {
        ...state,
        attributesList: {
          data: payload.data.attributeList.data,
          page: payload.data.attributeList.metadata[0].current_page,
          count: payload.data.attributeList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_ATTRIBUTE_BY_ID:
      return {
        ...state,
        currentAttribute: payload.data,
        loading: false,
      };
    case ATTRIBUTE_CHANGE_STATUS:
      return {
        ...state,
        attributesList: {
          ...state.attributesList,
          data: state.attributesList.data.map((attribute) =>
            attribute._id === payload.data._id
              ? { ...attribute, status: payload.data.status }
              : attribute
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case ATTRIBUTE_CHANGE_STATUS_ERROR:
      return {
        ...state,
        attributesList: {
          ...state.attributesList,
          data: state.attributesList.data.map((attribute) =>
            attribute._id === payload.data
              ? { ...attribute, is_failed: true }
              : { ...attribute, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case ATTRIBUTE_TAG_DELETED:
      return {
        ...state,
        currentAttribute: {
          ...state.currentAttribute,
          terms: state.currentAttribute.terms.filter(
            (term) => term._id !== payload.data
          ),
        },
        loading: false,
      };
    case ATTRIBUTE_TAG_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
