/* eslint-disable */
import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import {
    edit,
    getSetting,
} from 'actions/admin/carrierSetting';
import Errors from 'views/Notifications/Errors';
import Spinner from 'views/Spinner';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    Form,
    Input,
    InputGroup,
    //InputGroupAddon,
    InputGroupText,
    Row,
    Label
} from 'reactstrap';
import { REMOVE_ERRORS } from 'actions/types';
import setting from 'reducers/admin/setting';

const CarrierSettings = ({
    getSetting,
    edit,
    carrierSetting: { currentSetting, loading },
    settings,
    errorList,
}) => {
    // to resolve useEffect dependency error

    //################ get current website settings ############################
    useMemo(() => {
        getSetting();
    }, []);

    //################ set initial form data ############################
    const [initialFormData, setInitialFormData] = useState({
        ups: '',
        fedex: '',
        dhl: '',
    });

    useMemo(() => {
        if (Object.keys(currentSetting).length > 0) {
            setInitialFormData({
                ups: {
                    value: currentSetting.ups.value,
                    is_to_be_displayed: currentSetting.ups.is_to_be_displayed,
                },
                fedex: {
                    value: currentSetting.fedex.value,
                    is_to_be_displayed: currentSetting.fedex.is_to_be_displayed,
                },
                dhl: {
                    value: currentSetting.dhl.value,
                    is_to_be_displayed: currentSetting.dhl.is_to_be_displayed,
                },
            });
        }
    }, [currentSetting]);
    //################ set form data ############################

    const [formData, setFormData] = useState(initialFormData);

    useMemo(() => {
        setFormData(initialFormData);
    }, [initialFormData]);

    const { ups, fedex, dhl } = formData;

    //################ onChange event ############################

    const dispatch = useDispatch();

    const onChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: {
                ...formData[e.target.name],
                value: e.target.value,
            },
        });

        dispatch({ type: REMOVE_ERRORS });
    };

    //########################## set the submit state ##############################
    const [isSubmit, setIsSubmit] = useState(false);

    //################ on form submit ############################
    const onSubmit = (e) => {
        setIsSubmit(true);
        e.preventDefault();
        edit(formData).then((res) => {
            setIsSubmit(false);
        });

    };

    //################ on cancle save ############################
    const onClickHandel = (e) => {
        e.preventDefault();
        setFormData(initialFormData);
    };

    return (
        <div className="animated fadeIn">
            <Row>
                <Col xs="12" sm="12">
                    <Card>
                        <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
                            <CardBody>
                                <h4>Carrier account setting</h4>
                                <br></br>
                                <Row>
                                    <Col lg="12">
                                        <InputGroup size="lg">
                                            <div style={{ display: "flex", alignItems: "center", width: "350px", marginBottom: "15px" }}>
                                                <Label for="ups" className="label-tag ms-2" style={{ width: "120px" }}>UPS Account</Label>
                                                <Input
                                                    placeholder="UPS Account"
                                                    id="ups"
                                                    name="ups"
                                                    value={ups.value}
                                                    onChange={(e) => onChange(e)}
                                                    invalid={errorList.ups ? true : false}
                                                />
                                            </div>
                                            <Errors current_key="ups" key="ups" />
                                        </InputGroup>
                                    </Col>
                                    <Col lg="12">
                                        <InputGroup size="lg">
                                            <div style={{ display: "flex", alignItems: "center", width: "350px", marginBottom: "15px" }}>
                                                <Label for="fedex" className="label-tag ms-2" style={{ width: "120px" }}>FedEx Account</Label>
                                                <Input
                                                    placeholder="FedEx Account"
                                                    id="fedex"
                                                    name="fedex"
                                                    value={fedex.value}
                                                    onChange={(e) => onChange(e)}
                                                    invalid={errorList.fedex ? true : false}
                                                />
                                            </div>
                                            <Errors current_key="fedex" key="fedex" />
                                        </InputGroup>
                                    </Col>
                                    <Col lg="12">
                                        <div style={{ display: "flex", alignItems: "center", width: "350px", marginBottom: "15px" }}>
                                            <Label for="dhl" className="label-tag ms-2" style={{ width: "120px" }}>DHL Account</Label>
                                            <Input
                                                placeholder="DHL Account"
                                                id="dhl"
                                                name="dhl"
                                                value={dhl.value}
                                                onChange={(e) => onChange(e)}
                                                invalid={errorList.dhl ? true : false}
                                            />
                                        </div>
                                        <Errors current_key="dhl" key="dhl" />

                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                {loading ? (
                                    <div className="modalFooter">
                                        <Spinner />
                                    </div>
                                ) : (
                                    <div className="modalFooter">
                                        <Button
                                            type="reset"
                                            size="sm"
                                            className="canclebtn"
                                            color="danger"
                                            onClick={onClickHandel}
                                        >
                                            <i className="fa fa-ban"></i> Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            size="sm"
                                            color="primary"
                                            disabled={isSubmit}
                                        >
                                            <i className="fa fa-dot-circle-o"></i> Update
                                        </Button>
                                    </div>
                                )}
                            </CardFooter>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

CarrierSettings.propTypes = {
    getSetting: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    carrierSetting: PropTypes.object.isRequired,
    errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
    carrierSetting: state.carrierSetting,
    settings: state.carrierSetting,
    errorList: state.errors,
});

export default connect(mapStateToProps, {
    edit,
    getSetting,
})(CarrierSettings);
