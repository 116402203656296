import {
    GEAR_PRODUCT_LOADING,
    GEAR_PRODUCT_CREATED,
    GEAR_PRODUCT_CREATE_ERROR,
    GEAR_PRODUCT_FAIL,
    SET_GEAR_CATEGORY_LOADER,
    CATEGORY_LIST_GEAR_PRODUCT,
    SET_GEAR_ATTRIBUTE_LOADER,
    SET_GEAR_SPECS_LOADER,
    ATTRIBUTES_LIST_GEAR_PRODUCT,
    GEAR_PRODUCT_UPDATED,
    GEAR_SPEC_ADD,
    GEAR_PRODUCT_UPDATE_ERROR,
    GEAR_PRODUCT_LIST_UPDATED,
    GET_GEAR_PRODUCT_BY_ID,
    CHANGE_GEAR_PRODUCT_STATUS,
    CHANGE_GEAR_PRODUCT_STATUS_ERROR,
    DELETE_GEAR_PRODUCT,
    DELETE_GEAR_PRODUCT_ERROR,
    GET_GEAR_PRODUCT_IMAGES,
    DELETE_GEAR_PRODUCT_IMAGE,
    GEAR_PRIMARY_IMAGE_UPDATED,
    GEAR_WHOLESALE_PRICE_ERROR,
    GEAR_SALE_PRICE_ERROR,
    UPDATE_GEAR_PRODUCT_ATTRIBUTES,
    UPDATE_GEAR_PRODUCT_ATTRIBUTES_OPTIONS,
    UPDATE_GEAR_PRODUCT_ATTRIBUTES_ERROR,
    INVENTORY_LOADING,
    INVENTORY_UPLOADED,
    GEAR_VARIATION_UPDATED,
    GEAR_VARIATION_UPDATED_ERROR,
    RESET_CURRENT_GEAR_PRODUCT,
    CHANGE_GEAR_PRODUCT_STATUS_BULK,
    GEAR_INVENTORY_AND_SHIPPING_UPDATED,
    GEAR_INVENTORY_AND_SHIPPING_UPDATED_ERROR,
    GEAR_IMAGE_UPLOAD_ERROR,
    UPDATE_GEAR_PRODUCT_IMAGES,
    UPDATE_GEAR_PRODUCT_IMAGES_ERROR,
    GEAR_PRODUCT_ATTRIBUTE_TAG_DELETE,
    GEAR_PRODUCT_ATTRIBUTE_TAG_DELETE_ERROR,
    GEAR_PRODUCT_ATTRIBUTE_OPTION_CREATED,
    GEAR_PRODUCT_ATTRIBUTE_OPTION_CREATE_ERROR,
    SET_GEAR_PRODUCT_ATTRIBUTE_OPTION_LOADER,
    ADMIN_GEAR_PRODUCT_FAIL,
    ADMIN_SET_GEAR_PRODUCT_ATTRIBUTE_LOADER,
    GEAR_REVIEW_FAIL,
    GEAR_REVIEW_UPDATED,
} from "actions/types";

const initalState = {

    gearProductsList: {
        page: 1,
        data: [],
        count: 0,
    },
    // reviewList: {
    //     page: 1,
    //     data: [],
    //     count: 0,
    // },
    gearCurrentProduct: {},
    gearCurrentAttributes: {
        attributes: [],
        count: 0,
    },
    gearCurrentVariations: {
        variations: [],
        count: 0,
    },
    gearProductImages: {
        images: [],
        count: 0,
    },
    gearProductVideoUrl: '',
    loading: false,
    inventoryLoading: false,
    isSubLoading: false,
    gearCategorySubCategoryList: [],
    category_loading: false,
    gearAttributesValueList: [],
    attributes_loading: false,
    variations_loading: false,
    attributes_option_loading: false,
    gearCurrentAttributeOption: {},
};

export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
        case GEAR_PRODUCT_LOADING:
            return {
                ...state,
                loading: payload.is_loading ? payload.is_loading : false,
                isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
            };
        case GEAR_PRODUCT_CREATED:
        case GET_GEAR_PRODUCT_BY_ID:

            return {
                ...state,
                gearCurrentProduct: payload.data,
                loading: false,
            };
        case GEAR_REVIEW_FAIL:
        case GEAR_PRODUCT_CREATE_ERROR:
        case ADMIN_GEAR_PRODUCT_FAIL:
        case GEAR_PRODUCT_UPDATE_ERROR:
        case GEAR_WHOLESALE_PRICE_ERROR:
        case GEAR_SALE_PRICE_ERROR:
            return {
                ...state,
                loading: false,
            };
        case ADMIN_SET_GEAR_PRODUCT_ATTRIBUTE_LOADER:
            return {
                ...state,
                loading: payload,
            };
        case GEAR_PRODUCT_FAIL:
            return {
                ...state,
                loading: false,
                isSubLoading: false,
                category_loading: false,
                attributes_loading: false,
                variations_loading: false,
                attributes_option_loading: false,
            };
        case SET_GEAR_CATEGORY_LOADER:
            return {
                ...state,
                category_loading: payload,
            };
        case CATEGORY_LIST_GEAR_PRODUCT:
            return {
                ...state,
                gearCategorySubCategoryList: payload.data,
                category_loading: false,
            };

        case SET_GEAR_ATTRIBUTE_LOADER:
            return {
                ...state,
                attributes_loading: payload,
            };
        case ATTRIBUTES_LIST_GEAR_PRODUCT:
            return {
                ...state,
                gearAttributesValueList: payload.data,
                attributes_loading: false,
            };
        case GEAR_REVIEW_UPDATED:
            return {
                ...state,
                reviewList: payload.data,
                review_loading: false,
            };
        case GEAR_PRODUCT_UPDATED:
            return {
                ...state,
                gearCurrentProduct: { ...state.gearCurrentProduct, ...payload.data },
                loading: false,
            };

        case GEAR_SPEC_ADD:
            return {
                ...state,
                gearCurrentProduct: { ...state.gearCurrentProduct, ...payload.data },
                loading: false,
                isSubLoading: false,
            };
        case GEAR_REVIEW_UPDATED:
            return {
                ...state,
                reviewList: {
                    data: payload.data.reviewList.data,
                    page: payload.data.reviewList.metadata[0].current_page,
                    count: payload.data.reviewList.metadata[0].totalRecord,
                }
            }
        case GEAR_PRODUCT_LIST_UPDATED:
            return {
                ...state,
                gearProductsList: {
                    data: payload.data.productList.data,
                    page: payload.data.productList.metadata[0].current_page,
                    count: payload.data.productList.metadata[0].totalRecord,
                },
                loading: false,
            };
        case CHANGE_GEAR_PRODUCT_STATUS:
            return {
                ...state,
                gearProductsList: {
                    ...state.gearProductsList,
                    data: state.gearProductsList.data.map((product) =>
                        product._id === payload.data._id
                            ? { ...product, status: payload.data.status }
                            : product
                    ),
                },
                loading: false,
                isSubLoading: false,
            };

        case CHANGE_GEAR_PRODUCT_STATUS_ERROR:
        case DELETE_GEAR_PRODUCT:
        case DELETE_GEAR_PRODUCT_ERROR:
        case GEAR_VARIATION_UPDATED_ERROR:
        case GEAR_INVENTORY_AND_SHIPPING_UPDATED_ERROR:
        case GEAR_IMAGE_UPLOAD_ERROR:
        case UPDATE_GEAR_PRODUCT_IMAGES_ERROR:
        case GEAR_SPEC_ADD:
            return {
                ...state,
                loading: false,
                isSubLoading: false,
            };
        case GEAR_PRIMARY_IMAGE_UPDATED:
        case GET_GEAR_PRODUCT_IMAGES:
            return {
                ...state,
                gearCurrentProduct: {
                    ...state.gearCurrentProduct,
                    gearProductImages: {
                        images: payload.data.count > 0 ? payload.data.data.images : [],
                        count: payload.data.count,
                    },
                    gearProductVideoUrl: payload.data.videoUrl
                },

                loading: false,
                isSubLoading: false,
            };
        case DELETE_GEAR_PRODUCT_IMAGE:
            return {
                ...state,
                gearCurrentProduct: {
                    ...state.gearCurrentProduct,
                    gearProductImages: {
                        images: state.gearCurrentProduct.gearProductImages.images.filter(
                            (img) => img._id !== payload.data
                        ),
                        count: state.gearCurrentProduct.gearProductImages.count - 1,
                    },
                },

                loading: false,
                isSubLoading: false,
            };
        case UPDATE_GEAR_PRODUCT_ATTRIBUTES:
            return {
                ...state,
                gearCurrentProduct: {
                    ...state.gearCurrentProduct,
                    variations: payload.data.length > 0 ? payload.data : [],
                },
                attributes_loading: false,
                loading: false,
            };
        case UPDATE_GEAR_PRODUCT_ATTRIBUTES_OPTIONS:
            return {
                ...state,
                gearCurrentProduct: {
                    ...state.gearCurrentProduct,
                    attributes: payload.data.length > 0 ? payload.data : [],
                },
                attributes_loading: false,
                loading: false,
            };
        case UPDATE_GEAR_PRODUCT_ATTRIBUTES_ERROR:
            return {
                ...state,
                attributes_loading: false,
                loading: false,
            };
        case GEAR_VARIATION_UPDATED:
            return {
                ...state,
                gearCurrentProduct: {
                    ...state.gearCurrentProduct,
                    variations: payload.data,
                },
                loading: false,
                isSubLoading: false,
            };
        case RESET_CURRENT_GEAR_PRODUCT:
            return {
                ...state,
                gearCurrentProduct: {},
            };
        case CHANGE_GEAR_PRODUCT_STATUS_BULK:
            return {
                ...state,
                gearProductsList: {
                    ...state.gearProductsList,
                    data: state.gearProductsList.data.map((product) =>
                        payload.data._id.includes(product._id)
                            ? { ...product, status: parseInt(payload.data.status) }
                            : product
                    ),
                },
            };
        case INVENTORY_LOADING:
            return {
                ...state,
                inventoryLoading: payload.is_loading ? payload.is_loading : false,
                isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
            };
        case INVENTORY_UPLOADED:
            return {
                ...state,
                currentProduct: { ...state.gearCurrentProduct, ...payload.data },
                inventoryLoading: false,
            };
        case GEAR_INVENTORY_AND_SHIPPING_UPDATED:
            return {
                ...state,
                gearCurrentProduct: {
                    ...state.gearCurrentProduct,
                    inventory_and_shipping: payload.data,
                },
                loading: false,
                isSubLoading: false,
            };
        case UPDATE_GEAR_PRODUCT_IMAGES:
            return {
                ...state,
                gearCurrentProduct: {
                    ...state.gearCurrentProduct,
                    gearProductImages: {
                        images: payload.data.newProductImagesData?.images,
                        count: payload.data.newProductImagesData?.images.length,
                    },
                    gearProductVideoUrl: payload.data.productUpdate.videoLink ?? ''
                },
                loading: false,
                isSubLoading: false,
            };
        case SET_GEAR_PRODUCT_ATTRIBUTE_OPTION_LOADER:
            return {
                ...state,
                gearCurrentAttributeOption: payload.is_sub_element
                    ? state.gearCurrentAttributeOption
                    : initalState.gearCurrentAttributeOption,
                attributes_loading: payload.is_sub_element
                    ? payload.is_sub_element
                    : false,
                attributes_option_loading: payload.is_sub_element
                    ? payload.is_sub_element
                    : false,
            };
        case GEAR_PRODUCT_ATTRIBUTE_TAG_DELETE:
        case GEAR_PRODUCT_ATTRIBUTE_TAG_DELETE_ERROR:
        case GEAR_PRODUCT_ATTRIBUTE_OPTION_CREATE_ERROR:
            return {
                ...state,
                attributes_option_loading: false,
                attributes_loading: false,
            };
        case GEAR_PRODUCT_ATTRIBUTE_OPTION_CREATED:
            return {
                ...state,
                gearAttributesValueList: {
                    ...state.gearAttributesValueList,
                    attributeList: state.gearAttributesValueList?.attributeList?.map((attribute) =>
                        attribute._id === payload.data.attribute_id
                            ? {
                                ...attribute,
                                terms: [...attribute.terms, payload.data.option],
                            }
                            : attribute
                    )
                },
                gearCurrentAttributeOption: payload.data,
                attributes_option_loading: false,
                attributes_loading: false,
            };

        default:
            return state;
    }
}
