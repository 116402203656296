import {
  IMAGE_GALLERY_UPLOADED,
  IMAGE_GALLERY_UPLOAD_ERROR,
  IMAGE_GALLERY_FAIL,
  IMAGE_GALLERY_DELETE,
  IMAGE_GALLERY_DELETE_ERROR,
  IMAGE_GALLERY_UPDATED,
  IMAGE_GALLERY_UPDATE_ERROR,
  IMAGE_GALLERY_LIST_UPDATED,
  IMAGE_GALLERY_GET_IMAGE_BY_ID,
  IMAGE_GALLERY_LOADING,
} from "actions/types";

const initalState = {
  imageList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentImage: {},
  loading: false,
  isSubLoading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case IMAGE_GALLERY_LOADING:
      return {
        ...state,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case IMAGE_GALLERY_UPLOADED:
      return {
        ...state,
        loading: false,
      };
    case IMAGE_GALLERY_UPLOAD_ERROR:
      return {
        ...state,
        loading: false,
      };
    case IMAGE_GALLERY_UPDATED:
      return {
        ...state,
        currentImage: {},
        loading: false,
      };
    case IMAGE_GALLERY_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case IMAGE_GALLERY_FAIL:
      return {
        ...state,
        loading: false,
      };
    case IMAGE_GALLERY_DELETE:
      return {
        ...state,
        loading: false,
      };
    case IMAGE_GALLERY_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case IMAGE_GALLERY_GET_IMAGE_BY_ID:
      return {
        ...state,
        currentImage: payload.data,
        loading: false,
      };
    case IMAGE_GALLERY_LIST_UPDATED:
      return {
        ...state,
        imageList: {
          data: payload.data.imageList.data,
          // page: payload.data.imageList.metadata[0].current_page,
          count: payload.data.imageList.metadata[0].totalRecord,
        },
        loading: false,
      };
    default:
      return state;
  }
}
