import axios from 'axios';

import {
  ATTRIBUTE_LOADING,
  ATTRIBUTE_CREATED,
  ATTRIBUTE_CREATE_ERROR,
  ATTRIBUTE_FAIL,
  ATTRIBUTE_UPDATED,
  ATTRIBUTE_UPDATE_ERROR,
  ATTRIBUTE_DELETED,
  ATTRIBUTE_DELETE_ERROR,
  ATTRIBUTE_LIST_UPDATED,
  GET_ATTRIBUTE_BY_ID,
  ATTRIBUTE_CHANGE_STATUS,
  ATTRIBUTE_CHANGE_STATUS_ERROR,
  ATTRIBUTE_TAG_DELETED,
  ATTRIBUTE_TAG_DELETE_ERROR,
  SET_ERRORS_LIST,
} from 'actions/types';

// Create attribute
export const add = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: ATTRIBUTE_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post('/api/admin/attribute/add', formData, config);
    if (res.data.status === true) {
      dispatch({
        type: ATTRIBUTE_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/attributes');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: ATTRIBUTE_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
    }
  } catch (err) {
    dispatch({
      type: ATTRIBUTE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit Attribute
export const edit = (formData, history, attribute_id) => async (dispatch) => {
  try {
    dispatch({
      type: ATTRIBUTE_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/attribute/${attribute_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: ATTRIBUTE_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/attributes');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: ATTRIBUTE_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
    }
  } catch (err) {
    dispatch({
      type: ATTRIBUTE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete Attribute
export const deleteAttribute = (attribute_id) => async (dispatch) => {
  try {
    dispatch({
      type: ATTRIBUTE_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(
      `/api/admin/attribute/${attribute_id}`,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: ATTRIBUTE_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: ATTRIBUTE_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: ATTRIBUTE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get attributes list

export const getAttributesList = (attributeParams) => async (dispatch) => {
  try {
    dispatch({
      type: ATTRIBUTE_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: attributeParams,
    };

    const res = await axios.get(`/api/admin/attribute/`, config);
    const resData = res.data.response[0].data;
    res.data.response[0].data = resData;

    dispatch({
      type: ATTRIBUTE_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          attributeParams: attributeParams,
          attributeList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    dispatch({
      type: ATTRIBUTE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete attribute
export const getAttributeById = (attribute_id) => async (dispatch) => {
  try {
    dispatch({
      type: ATTRIBUTE_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/attribute/${attribute_id}`, config);

    await dispatch({
      type: GET_ATTRIBUTE_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: ATTRIBUTE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//change status
export const changeStatus = (attribute_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: ATTRIBUTE_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/attribute/change-status/${attribute_id}`,
      { status },
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: ATTRIBUTE_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: ATTRIBUTE_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: attribute_id,
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: ATTRIBUTE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

export const deleteAttributeAndVariations = (tagId) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const res = await axios.delete(
    `/api/admin/attribute/removeVariations/${tagId}`,
    config
  );
  if (res.data.status) {
    await dispatch({
      type: ATTRIBUTE_TAG_DELETED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: tagId,
      },
    });
    return { status: true };
  } else {
    dispatch({
      type: ATTRIBUTE_TAG_DELETE_ERROR,
      payload: {
        message: res.data.message,
        alertType: 'danger',
      },
    });
  }
};

// Delete AttributeTag
export const deleteTag = (tagId) => async (dispatch) => {
  try {
    dispatch({
      type: ATTRIBUTE_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(`/api/admin/attribute/tag/${tagId}`, config);
    if (res.data.status === true) {
      await dispatch({
        type: ATTRIBUTE_TAG_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: tagId,
        },
      });
      return { status: true };
    } else {
      //   type: ATTRIBUTE_TAG_DELETE_ERROR,
      //   payload: {
      //     message: res.data.message,
      //     alertType: "danger",
      //   },
      // });
      return { status: false, message: res.data.message };
    }
  } catch (err) {
    dispatch({
      type: ATTRIBUTE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};
