import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// sidebar nav config
import {
  navAdmin,
  navOrderManager,
  navProductManager,
  navShippingManager,
  navCmsManager,
  navCustomerManager,
  navBuildShopManager
} from './_nav';
import { PERMISSION_NAME } from 'constants';

const Sidebar = ({ auth, loading }) => {
  const mergeUniquely = (navStaff) => {
    const uniqueObject = {};
    const uniqueArray = [];
    navStaff.forEach((nav) => {
      if (!uniqueObject[nav['name']]) {
        uniqueArray.push(nav);
        uniqueObject[nav['name']] = 1;
      }
    });
    return uniqueArray;
  };

  const location = useLocation();
  const [accessibleNav, setAccessibleNav] = useState([]);
  const [orderCount, setOrderCount] = useState(0);
  const [pendingDealers, setPendingDealers] = useState(0);

  useEffect(() => {
    if (auth?.user?.isAdmin) {
      setAccessibleNav(navAdmin);
    } else if (auth?.user?.isStaff) {
      let navStaff = [];
      if (auth?.user?.permission.includes(PERMISSION_NAME.SUPER_ADMIN)) {
        setAccessibleNav(navAdmin);
      } else {
        if (
          auth?.user?.permission.includes(PERMISSION_NAME.CUSTOMER_MANAGEMENT)
        ) {
          navStaff = navStaff.concat(navCustomerManager);
        }
        if (
          auth?.user?.permission.includes(PERMISSION_NAME.PRODUCT_MANAGEMENT)
        ) {
          navStaff = navStaff.concat(navProductManager);
        }
        if (
          auth?.user?.permission.includes(PERMISSION_NAME.ORDER_MANAGEMENT)
        ) {
          navStaff = navStaff.concat(navOrderManager);
        }
        if (auth?.user?.permission.includes(PERMISSION_NAME.BUILD_SHOP_MANAGEMENT)) {
          navStaff = navStaff.concat(navBuildShopManager);

        }
        if (
          auth?.user?.permission.includes(PERMISSION_NAME.SHIPPING_MANAGEMENT)
        ) {
          navStaff = navStaff.concat(navShippingManager);
        }
        if (auth?.user?.permission.includes(PERMISSION_NAME.CMS_MANAGEMENT)) {
          navStaff = navStaff.concat(navCmsManager);
        }
        setAccessibleNav(mergeUniquely(navStaff));
      }
    }
    if (auth?.user?.order_count?.length > 0) {
      const order_count = auth?.user?.order_count.reduce(
        (x, y) => x + y.count,
        0
      );
      setOrderCount(order_count);
    }
    if (auth?.user?.pending_dealers_count?.length > 0) {
      const pending_dealers = auth?.user?.pending_dealers_count.reduce(
        (x, y) => x + y.count,
        0
      );
      setPendingDealers(pending_dealers);
    }
  }, [auth?.user]);

  const [previousClassList, setPreviousClassList] = useState('');
  const [currentTarget, setCurrentTarget] = useState('');

  useEffect(
    (e) => {
      setCurrentTarget(location.pathname);
    },
    [location]
  );

  const addClass = useCallback(
    (e) => {
      // let previousClassListData;
      // if (!e.currentTarget?.classList?.contains('active')) {
      //   e.currentTarget?.classList?.add('active');
      //   previousClassListData = e.currentTarget;
      //   setPreviousClassList(e.currentTarget);
      // }

      // if (previousClassList != e.currentTarget) {
      //   previousClassList?.classList?.remove('active');
      //   if (previousClassListData === undefined) {
      //     previousClassList?.classList?.add('active');
      //   }
      // }
      if (e.target?.parentElement?.classList?.contains('active')) {
        e.target?.parentElement?.classList?.remove('active');
      } else {
        e.target?.parentElement?.classList?.add('active');
      }
    },
    [previousClassList]
  );

  return (
    <>
      <nav className="sidebar">
        <ul className="sidebar-nav">
          {accessibleNav.map((item) => (
            <li
              className={`nav-item ${(item.children?.length &&
                item.children?.find(
                  (childItem) => childItem.to === currentTarget
                )) ||
                item.to === currentTarget
                ? 'active'
                : ''
                }`}
              key={item.name}
              onClick={addClass}
            >
              {item.children?.length ? (
                <>
                  <span className="nav-group-toggle">
                    <item.icon /> {item.name}
                    {item.name === 'Orders' && orderCount > 0 && (
                      <span className="badge bg-danger "></span>
                    )}
                    {item.name === 'Dealers' && pendingDealers > 0 && (
                      <span className="badge bg-danger "></span>
                    )}
                  </span>
                  <ul
                    className="nav-group-items"
                    key={item.name}
                    style={{ marginLeft: '20px' }}
                  >
                    {item.children.map((subItem) => (
                      <li
                        key={subItem.name}
                        className={`nav-item ${currentTarget === subItem.to ? 'active' : ''
                          }`}
                      >
                        <Link className="nav-link" to={subItem.to}>
                          {subItem.icon}
                          <span className="menu_name">{subItem.name}</span>
                          {subItem.name === 'Order List' && orderCount > 0 && (
                            <span className="badge bg-danger ">
                              {orderCount}
                            </span>
                          )}
                          {subItem.name === 'Dealers' && pendingDealers > 0 && (
                            <span className="badge bg-danger ">
                              {pendingDealers}
                            </span>
                          )}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <Link className="nav-link" to={item.to}>
                  <item.icon /> {item.name}
                </Link>
              )}
            </li>
          ))}
        </ul>
        <span className={loading ? 'blackOverlay' : ""}></span>
      </nav>
    </>
  );
};

Sidebar.prototype = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loading: state.Loader.loading
});

export default connect(mapStateToProps)(Sidebar);
