/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from 'views/Spinner';
import { ADDRESS_TYPE } from 'constants/index';
import Select from 'react-select';
import {
  getUsersList,
  applyCoupon,
  calculateShippingRates,
  getUserAddressList,
  addToCart,
  updateCart,
  getCartDetails,
  addToOrder,
  removeCoupon,
  getProductsList,
} from 'actions/admin/order';
import {
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';

const PreviewForm = ({
  userType,
  customerName,
  userId,
  addedProduct,
  selectedBillingAddress,
  selectedShippingAddress,
  selectedBillingLabel,
  selectedShippingLabel,
  payment_status,
  deliveryInstruction,
  isApplied,
  couponDetails,
  productTotalAmount,
  fittingTotalAmount,
  shippingPrice,
  couponDiscount,
  couponShippingDiscount,
  grandTotal,
  currentCart,
  cartTotal,
  shippToDifferentAddressChecked,
  shippingBox,
  selectedShippingMode,
  easyPostOrderId,
  setShowAddEditForm,
  setPreviewForm,

  addToOrder,
  setIsSubmit,
  taxApplied,
  totalBeforeTax,
  taxTotal,
  orderId,
  isEdit,
  paid_amount,
  due_amount,
}) => {
  //##################### billing address list for logged in user #########################
  const saveOrder = () => {
    if (orderId) {
      orderId = orderId;
    } else {
      orderId = '';
    }
    addToOrder({
      orderId,
      userId,
      currentCart,
      cartTotal,
      couponDiscount,
      couponShippingDiscount,
      couponDetails,
      grandTotal,
      selectedBillingAddress,
      selectedShippingAddress,
      shippToDifferentAddressChecked,
      shippingBox,
      selectedShippingMode,
      payment_status,
      deliveryInstruction,
      easy_post_order_id: easyPostOrderId,
      fittingTotalAmount,
      productTotalAmount,
      taxApplied,
      totalBeforeTax,
      taxTotal,
      paid_amount,
      due_amount,
    }).then((res) => (res.status ? setIsSubmit(true) : setIsSubmit(false)));
  };

  const cancelOrder = () => {
    setShowAddEditForm(true);
    setPreviewForm(false);
  };

  return (
    <div id="previewForm">
      <Form className="form-horizontal">
        <Card>
          <CardHeader>
            <h5>Order Preview </h5>
          </CardHeader>
          <CardBody>
            <Container fluid className="p-0">
              <Row>
                <Col sm="12">
                  <Row>
                    <Col sm="6">
                      <FormGroup className="orderpreviewlabel">
                        <Label for="user_type">
                          <span className="table-label"> User Type :</span>{' '}
                        </Label>
                        {userType && userType
                          ? userType == 2
                            ? 'Customer'
                            : 'Dealer'
                          : 'N/A'}
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup className="orderpreviewlabel">
                        <Label for="user_type">
                          <span className="table-label"> Name :</span>{' '}
                        </Label>
                        {customerName && customerName != '' ? (
                          <> {customerName} </>
                        ) : (
                          'N/A'
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {userId ? (
                <div>
                  <div>
                    <table className="orderpreviewtable">
                      <thead>
                        {/* <tr>
                                <th>
                                  <h5>Products </h5>
                                </th>
                              </tr> */}
                        <tr>
                          <th>Product</th>
                          <th>Cost</th>
                          <th>Quantity</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {addedProduct.map((item, index) => {
                          let fittingTotalPrice = 0;
                          return (
                            <>
                              <tr key={index} className="bg-light">
                                <td>
                                  <table>
                                    <tr>
                                      <td className="productheadings">
                                        <strong>
                                          {item.product_title}-
                                          {item.category_name}
                                        </strong>
                                      </td>
                                    </tr>
                                    {item.productVariationDetail.length > 0 ? (
                                      <>
                                        <tr>
                                          <td>
                                            <p>
                                              <span className="table-label">
                                                SKU:
                                              </span>
                                              {''}
                                              {item.productVariationSku}
                                            </p>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>
                                            <div className="mt-2 mb-2">
                                              {item.productVariationDetail.map(
                                                (variation, key) => {
                                                  //console.log(item, 'item data')
                                                  return (
                                                    <p
                                                      className="mb-0"
                                                      key={key}
                                                    >
                                                      <span className="table-label">
                                                        {' '}
                                                        {
                                                          variation.attribute_name
                                                        }{' '}
                                                        :
                                                      </span>{' '}
                                                      {variation.term_option}
                                                    </p>
                                                  );
                                                }
                                              )}
                                            </div>

                                            {item.productFitting &&
                                              item.productFitting.length > 0
                                              ? item.productFitting.map(
                                                (fittingOptions) => {
                                                  if (
                                                    fittingOptions.attribute_name ==
                                                    'Playing Length'
                                                  ) {
                                                    return (
                                                      <>
                                                        <div>
                                                          <span className="table-label">
                                                            {' '}
                                                            Playing Length :
                                                          </span>{' '}
                                                          {
                                                            (fittingOptions?.attribute_value == 0) ? "Uncut" : fittingOptions?.attribute_value
                                                          }
                                                        </div>
                                                      </>
                                                    );
                                                  }

                                                  if (
                                                    fittingOptions.attribute_name ==
                                                    'Extra Wrap'
                                                  ) {
                                                    return (
                                                      <>
                                                        <div>
                                                          <span className="table-label">
                                                            {' '}
                                                            Extra Wraps :
                                                          </span>
                                                          {fittingOptions.attribute_value &&
                                                            fittingOptions.attribute_value ==
                                                            1
                                                            ? 'None'
                                                            : fittingOptions.attribute_value ==
                                                              2
                                                              ? '1'
                                                              : fittingOptions.attribute_value ==
                                                                3
                                                                ? '2'
                                                                : 'None'}
                                                        </div>
                                                      </>
                                                    );
                                                  }
                                                  if (
                                                    fittingOptions.attribute_name ==
                                                    'Grip Logo'
                                                  ) {
                                                    return (
                                                      <>
                                                        <div>
                                                          <span className="table-label">
                                                            {' '}
                                                            Grip Logo :
                                                          </span>
                                                          {fittingOptions.attribute_value &&
                                                            fittingOptions.attribute_value}
                                                        </div>
                                                      </>
                                                    );
                                                  }

                                                  if (
                                                    fittingOptions.attribute_name ==
                                                    'Adaptors'
                                                  ) {
                                                    return (
                                                      <>
                                                        <div>
                                                          <span className="table-label">
                                                            {' '}
                                                            Adaptor :
                                                          </span>{' '}
                                                          {
                                                            fittingOptions.option_name
                                                          }
                                                        </div>
                                                        <div>
                                                          <span className="table-label">
                                                            {' '}
                                                            Adaptor Setting :{' '}
                                                          </span>
                                                          {
                                                            fittingOptions.sub_option_name
                                                          }
                                                        </div>
                                                      </>
                                                    );
                                                  }
                                                  if (
                                                    fittingOptions.attribute_name ==
                                                    'Grips'
                                                  ) {
                                                    return (
                                                      <>
                                                        <div>
                                                          <span className="table-label">
                                                            {' '}
                                                            Grips Brand :{' '}
                                                          </span>
                                                          {
                                                            fittingOptions.option_name
                                                          }
                                                        </div>
                                                        <div>
                                                          <span className="table-label">
                                                            {' '}
                                                            Grip Model :{' '}
                                                          </span>
                                                          {
                                                            fittingOptions.sub_option_name
                                                          }
                                                        </div>
                                                      </>
                                                    );
                                                  }

                                                  if (
                                                    fittingOptions.attribute_name ==
                                                    'Product Customization'
                                                  ) {
                                                    return (
                                                      <>
                                                        <div className="product_customization_notes">
                                                          <span className="table-label">
                                                            {' '}
                                                            Product
                                                            Customization :
                                                          </span>{' '}
                                                          {
                                                            fittingOptions.attribute_value
                                                          }
                                                        </div>
                                                      </>
                                                    );
                                                  }
                                                }
                                              )
                                              : ''}
                                          </td>
                                        </tr>
                                      </>
                                    ) : null}
                                  </table>
                                </td>
                                <td>
                                  <table>
                                    <tr>
                                      <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td>&nbsp;</td>
                                    </tr>

                                    <tr>
                                      <td>
                                        ${item.price}
                                        {item.productVariationDetail.map(
                                          (variation, key) => {
                                            //console.log(item, 'item data')
                                            return (
                                              <p className="mb-0" key={key}>
                                                &nbsp;
                                              </p>
                                            );
                                          }
                                        )}
                                        {item.productFitting &&
                                          item.productFitting.length > 0
                                          ? item.productFitting.map(
                                            (fittingOptions) => {
                                              if (
                                                fittingOptions.attribute_name ==
                                                'Playing Length'
                                              ) {
                                                return (
                                                  <>
                                                    <div>&nbsp;</div>
                                                  </>
                                                );
                                              }

                                              if (
                                                fittingOptions.attribute_name ==
                                                'Extra Wrap'
                                              ) {
                                                return (
                                                  <>
                                                    <div>&nbsp;</div>
                                                  </>
                                                );
                                              }
                                              if (
                                                fittingOptions.attribute_name ==
                                                'Grip Logo'
                                              ) {
                                                return (
                                                  <>
                                                    <div>&nbsp;</div>
                                                  </>
                                                );
                                              }

                                              if (
                                                fittingOptions.attribute_name ==
                                                'Adaptors'
                                              ) {
                                                return (
                                                  <>
                                                    <div>
                                                      ${fittingOptions.price}
                                                    </div>
                                                    <div>&nbsp;</div>
                                                  </>
                                                );
                                              }
                                              if (
                                                fittingOptions.attribute_name ==
                                                'Grips'
                                              ) {
                                                return (
                                                  <>
                                                    <div>&nbsp;</div>
                                                    <div>
                                                      ${fittingOptions.price}
                                                    </div>
                                                  </>
                                                );
                                              }
                                            }
                                          )
                                          : ''}
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                                <td>
                                  <table>
                                    <tr>
                                      <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td>{item.quantity}</td>
                                    </tr>
                                  </table>
                                </td>
                                <td>
                                  <table>
                                    <tr>
                                      <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td>&nbsp;</td>
                                    </tr>
                                    <tr></tr>
                                    {item.productFitting.length > 0
                                      ? item.productFitting.map(
                                        (fitting, idx) => {
                                          fitting.price && fitting.price > 0
                                            ? (fittingTotalPrice = Number(
                                              parseFloat(
                                                fittingTotalPrice
                                              ) + parseFloat(fitting.price)
                                            ).toFixed(2))
                                            : '';
                                        }
                                      )
                                      : null}
                                    <tr>
                                      <td>
                                        $
                                        {Number(
                                          parseFloat(item.price) *
                                          parseFloat(item.quantity) +
                                          parseFloat(fittingTotalPrice)
                                        ).toFixed(2)}
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {
                    <>
                      <Row className="couponcodelist">
                        <div className="col-md-6">
                          <strong>Shipping Address : </strong>
                          {selectedShippingLabel}
                        </div>
                        <div className="col-md-6">
                          <strong>Billing Address :</strong>{' '}
                          {selectedBillingLabel && selectedBillingLabel != ''
                            ? selectedBillingLabel
                            : selectedShippingLabel}
                        </div>
                      </Row>
                    </>
                  }

                  {(selectedBillingAddress != null ||
                    selectedShippingAddress != null) &&
                    addedProduct.length ? (
                    <Row className="couponcodelist">
                      <Col md="6">
                        <FormGroup>
                          <Label for="payment_status">
                            <strong>Payment status : </strong>
                          </Label>
                          {payment_status && payment_status
                            ? payment_status == 1
                              ? 'PENDING'
                              : 'PAID'
                            : 'N/A'}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label for="deliveryInstruction">
                            <strong>Delivery Instructions : </strong>
                          </Label>
                          {deliveryInstruction && deliveryInstruction
                            ? deliveryInstruction
                            : ''}
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}

                  {selectedShippingMode && (
                    <Row className="couponcodelist">
                      <Col md="6">
                        <FormGroup>
                          <Label>
                            <strong>Shipping Details </strong>
                          </Label>

                          <div className="col-md-6">
                            <strong>Carrier : </strong>
                            {selectedShippingMode.carrier}
                          </div>
                          <div className="col-md-6">
                            <strong>Service : </strong>
                            {selectedShippingMode.service}
                          </div>
                          <div className="col-md-6">
                            <strong>Total Charge : </strong>$
                            {Number(shippingPrice).toFixed(2)}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  {userId && addedProduct.length ? (
                    <div className="mb-10">
                      {isApplied ? (
                        <>
                          <div className="couponcode">
                            <strong>Coupon Code : </strong>
                            <strong className="coupon-display">
                              {couponDetails?.coupon_code
                                ? couponDetails?.coupon_code
                                : ''}
                            </strong>
                          </div>
                          {/* <span
                                onClick={(e) => onRemoveClick(e)}
                                className="remove-coupon-span"
                              >
                                <i className="fa fa-close"></i>
                              </span> */}
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : null}
                  <div>
                    {addedProduct.length ? (
                      <div className="mt-20">
                        <Row className="couponcodelist priceSummary">
                          <Col>
                            {productTotalAmount && productTotalAmount > 0 ? (
                              <div className="couponcode">
                                <strong>Product(s) Total : </strong>$
                                {Number(productTotalAmount).toFixed(2)}
                              </div>
                            ) : null}
                            {couponDiscount !== 0 ? (
                              <div className="couponcode">
                                <strong>Discount : </strong> - ($
                                {Number(couponDiscount).toFixed(2)})
                              </div>
                            ) : null}
                            {fittingTotalAmount && fittingTotalAmount > 0 ? (
                              <div className="couponcode">
                                <strong>Build Parameter(s) total :</strong> $
                                {Number(fittingTotalAmount).toFixed(2)}
                              </div>
                            ) : null}
                            {taxTotal && taxTotal > 0 ? (
                              <>
                                <div className="couponcode">
                                  <strong>Total before Tax :</strong> $
                                  {(
                                    Number(fittingTotalAmount) +
                                    Number(productTotalAmount) -
                                    Number(couponDiscount)
                                  ).toFixed(2)}
                                </div>
                                <div className="couponcode">
                                  <strong>Sales Tax :</strong> $
                                  {Number(taxTotal).toFixed(2)}
                                </div>
                              </>
                            ) : null}

                            {shippingPrice !== 0 ? (
                              <div className="couponcode">
                                <strong>Shipping :</strong> $
                                {Number(shippingPrice).toFixed(2)}
                              </div>
                            ) : null}
                            {couponShippingDiscount !== 0 ? (
                              <div className="couponcode">
                                <strong>Shipping Discount : </strong> - ($
                                {Number(couponShippingDiscount).toFixed(2)})
                              </div>
                            ) : null}
                            {isEdit ? (
                              <>
                                <div className="couponcode">
                                  <strong>Paid Amount : </strong>$
                                  {Number(paid_amount).toFixed(2)}
                                </div>

                                <div className="couponcode">
                                  <strong>Due Amount : </strong>$
                                  {(
                                    Number(grandTotal).toFixed(2) -
                                    Number(paid_amount).toFixed(2)
                                  ).toFixed(2)}
                                </div>
                              </>
                            ) : null}
                            <div className="couponcode">
                              <strong>Grand Total : </strong>$
                              {Number(grandTotal).toFixed(2)}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </Container>
          </CardBody>
        </Card>
        <CardFooter>
          <Button
            className="mr-05"
            size="sm"
            color="primary"
            onClick={() => {
              saveOrder();
            }}
          >
            <i className="fa fa-dot-circle-o"></i> Place Order
          </Button>

          <Button
            type="reset"
            size="sm"
            color="danger"
            className="m-0"
            onClick={() => {
              cancelOrder();
            }}
          >
            <i className="fa fa-arrow-left"></i> Back
          </Button>
        </CardFooter>
      </Form>
    </div>
  );
};

PreviewForm.propTypes = {
  //address_loading: PropTypes.bool.isRequired,
  //currentAddressList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  //address_loading: state.order.address_loading,
  //currentAddressList: state.order.currentAddressList,
  addToOrder: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { addToOrder })(PreviewForm);
