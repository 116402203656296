/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { edit, getCategoryById } from 'actions/admin/category';

import Errors from 'views/Notifications/Errors';
import Spinner from 'views/Spinner';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { REMOVE_ERRORS } from 'actions/types';

const EditCategory = ({
  getCategoryById,
  edit,
  category: { loading, currentCategory },
  history,
  //match,
  errorList,
}) => {
  /**************** use state for form data *********************/
  const [formData, setFormData] = useState({
    name: '',
    slug: '',
    thumbnail: '',
    selectedFile: '',
    image: '',
    customization: true,
    childrens: [],
    oldimage: '',
  });

  /**************** expload from data *********************/
  const {
    name,
    slug,
    customization,
    thumbnail,
    // image,
    childrens,
    oldimage,
  } = formData;

  // to resolve useEffect dependency error

  /**************** use effect to get current category *********************/
  const { category_id: category_id } = useParams();
  useEffect(() => {
    if (category_id) getCategoryById(category_id);
  }, [category_id]);

  /**************** use effect to set form data from current category *********************/
  let navigate = useNavigate();
  useEffect(() => {
    if (Object.keys(currentCategory).length > 0) {
      setFormData({
        name: !currentCategory.name ? '' : currentCategory.name,
        customization: !currentCategory.customization
          ? false
          : currentCategory.customization,
        slug: !currentCategory.slug ? '' : currentCategory.slug,
        oldimage: !currentCategory.image ? '' : currentCategory.image,
        thumbnail: !currentCategory.image
          ? ''
          : //process.env.REACT_APP_AWS_STORAGE_URL +
            'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/category/' +
            currentCategory.image,
        childrens: currentCategory.childrens,
      });
    }
  }, [currentCategory]);

  /**************** use dispatch *********************/

  /**************** handle on change event *********************/
  const onChange = (e) => {
    if (e.target.name === 'image') {
      let image_file = e.target.files[0];
      let image_url = URL.createObjectURL(image_file);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        selectedFile: image_file,
        thumbnail: image_url,
      });
    } else if (e.target.name === 'customization') {
      console.log(e.target.name, e.target.value);
      setFormData({ ...formData, [e.target.name]: !customization });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  /**************** handle on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    edit(formData, navigate, category_id);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="12">
          <Card>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="name">
                        Name <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter name"
                        maxLength="100"
                        value={name}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.name ? true : false}
                      />
                      <Errors current_key="name" key="name" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="name">
                        Slug <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="slug"
                        name="slug"
                        maxLength="100"
                        value={slug}
                        readOnly
                        onChange={(e) => onChange(e)}
                        invalid={errorList.slug ? true : false}
                      />
                      <Errors current_key="slug" key="slug" />
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup>
                  <Label htmlFor="image"> Image</Label>
                  <Input
                    type="file"
                    id="image"
                    name="image"
                    // value={image}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.image ? true : false}
                  />
                  {thumbnail ? (
                    <img src={thumbnail} className="preview-img" alt="" />
                  ) : null}
                  <Input
                    type="hidden"
                    id="oldimage"
                    name="oldimage"
                    value={oldimage}
                  ></Input>
                  <Errors current_key="image" key="image" />
                </FormGroup>
                <FormGroup switch>
                  <Input
                    type="switch"
                    id="customization"
                    name="customization"
                    label="Customization"
                    checked={customization}
                    onChange={(e) => onChange(e)}
                  />
                  <Label check>Customization </Label>{' '}
                  <i>(Enable/Disable customization option for product)</i>
                  <Errors current_key="customization" key="customization" />
                </FormGroup>

                {childrens?.length > 0 ? (
                  <FormGroup>
                    <Label for="SubCategories">Available Sub Categories</Label>
                    <table>
                      <tr>
                        <th>Name</th>
                        <th>Slug</th>
                      </tr>
                      {childrens.map((val) => (
                        <tr>
                          <td>{val.name}</td>
                          <td>{val.slug}</td>
                        </tr>
                      ))}
                    </table>
                  </FormGroup>
                ) : null}
              </CardBody>
              <CardFooter>
                <Button
                  className="mr-05"
                  type="submit"
                  size="sm"
                  color="primary"
                  disabled={loading}
                >
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
                <Link to="/admin/categories">
                  <Button type="reset" size="sm" color="danger">
                    <i className="fa fa-ban"></i> Cancel
                  </Button>
                </Link>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditCategory.propTypes = {
  getCategoryById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  category: state.category,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  getCategoryById,
  edit,
})(EditCategory);
