/* eslint-disable */
import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';

import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';

import { edit, getSubCategoryById } from 'actions/admin/subCategory';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { REMOVE_ERRORS } from 'actions/types';

const EditSubCategory = ({
  getSubCategoryById,
  edit,
  category: { loading, currentSubCategory },
  history,
  match,
  errorList,
}) => {
  /**************** use state for form data *********************/
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    slug: '',
    category_id: '',
    thumbnail: '',
    selectedFile: '',
    image: '',
    oldimage: '',
  });

  // to resolve useEffect dependency error
  
  /**************** use memo to get current sub category *********************/
  const { subcategory_id: subcategory_id } = useParams();
  useMemo(() => {
    
    getSubCategoryById(subcategory_id);
  }, [subcategory_id]);

  /**************** use effect to set form data from current sub category *********************/
  useEffect(() => {
    if (currentSubCategory) {
      setFormData({
        name: !currentSubCategory.name ? '' : currentSubCategory.name,
        slug: !currentSubCategory.slug ? '' : currentSubCategory.slug,
        category_id: !currentSubCategory.parent_id
          ? ''
          : currentSubCategory.parent_id,
        oldimage: !currentSubCategory.image ? '' : currentSubCategory.image,
        thumbnail: !currentSubCategory.image
          ? ''
          : //process.env.REACT_APP_AWS_STORAGE_URL +
            'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/category/' +
            currentSubCategory.image,
      });
    }
  }, [currentSubCategory]);

  /**************** expload from data *********************/
  const {
    name,
    slug,
    category_id,
    thumbnail,
    // image,
    oldimage,
  } = formData;

  /**************** use dispatch *********************/
  

  /**************** handle on change event *********************/
  const onChange = (e) => {
    if (e.target.name === 'image') {
      let image_file = e.target.files[0];
      let image_url = URL.createObjectURL(image_file);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        selectedFile: image_file,
        thumbnail: image_url,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    
  };

  /**************** handle on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    edit(formData, navigate, subcategory_id, category_id);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="12">
          <Card>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="name">
                        Name <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="name"
                        name="name"
                        maxLength="100"
                        value={name}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.name ? true : false}
                      />
                      <Errors current_key="name" key="name" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="name">
                        Slug <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="slug"
                        name="slug"
                        maxLength="100"
                        value={slug}
                        readOnly
                        onChange={(e) => onChange(e)}
                        invalid={errorList.slug ? true : false}
                      />
                      <Errors current_key="slug" key="slug" />
                    </FormGroup>
                  </Col>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="image"> Image</Label>
                      <Input
                        type="file"
                        id="image"
                        name="image"
                        // value={image}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.image ? true : false}
                      />
                      {thumbnail ? (
                        <img src={thumbnail} className="preview-img" alt="" />
                      ) : null}
                      <Input
                        type="hidden"
                        id="oldimage"
                        name="oldimage"
                        value={oldimage}
                      ></Input>
                      <Errors current_key="image" key="image" />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button
                  className="mr-05"
                  type="submit"
                  size="sm"
                  color="primary"
                >
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
                <Link to={`/admin/categories/subcategories/${category_id}`}>
                  <Button type="reset" size="sm" color="danger">
                    <i className="fa fa-ban"></i> Cancel
                  </Button>
                </Link>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditSubCategory.propTypes = {
  getSubCategoryById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  category: state.subcategory,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  getSubCategoryById,
  edit,
})(EditSubCategory);
