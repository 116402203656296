import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import slugify from 'react-slugify';
import { CKEditor } from 'ckeditor4-react';

import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';

import { create, edit, getHomeById } from 'actions/admin/home';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import { CKEditorConfig } from 'constants/index';

const AddEditHome = ({
  create,
  history,
  errorList,
  loading,
  getHomeById,
  edit,
  cms: { currentCMS },
}) => {
  let navigate = useNavigate();
  const [onCancel, setOnCancel] = useState(false);
  const [formData, setFormData] = useState({
    page_name: '',
    slug: '',
    meta_title: '',
    meta_description: '',
    section_1: [{
      title: '',
      subtitle: '',
      description: '',
      images: '',
      deleteFile: '',
      selectedFile: '',
      deleteImage: '',
      button1_text: '',
      button1_Link: '',
      button2_text: '',
      button2_Link: '',
    }],
    section_2: {
      card_1: {
        title: '',
        subtitle: '',
        description: '',
        image: '',
        selectedFile: '',
        link: '',
      },
      card_2: {
        title: '',
        subtitle: '',
        description: '',
        image: '',
        selectedFile: '',
        link: '',
      },
      card_3: {
        title: '',
        subtitle: '',
        description: '',
        image: '',
        selectedFile: '',
        link: '',
      },
    },
    section_3: {
      title: '',
      subtitle: '',
      description: '',
      column_1: '',
      column_2: '',
    },
    section_4: {
      title: '',
      subtitle: '',
      description: '',
    },
    section_5: [{
      title: '',
      subtitle: '',
      description: '',
      images: '',
      deleteFile: '',
      selectedFile: '',
      deleteImage: '',
      button1_text: '',
      button1_Link: '',
      button2_text: '',
      button2_Link: '',
    }],
    section_6: {
      title: '',
      subtitle: '',
      description: '',
      image: '',
      selectedFile: '',
      button_text: '',
      button_Link: '',
    }
  });

  const [card1_des, setCard1_des] = useState(null);
  const [card2_des, setCard2_des] = useState(null);
  const [card3_des, setCard3_des] = useState(null);
  const [isLoad, setIsLoad] = useState(false);

  const { home_id: home_id } = useParams();
  useEffect(() => {
    if (home_id) getHomeById(home_id);
  }, [home_id, onCancel]);

  useEffect(() => {
    if (Object.keys(currentCMS).length > 0 && home_id) {
      setIsLoad(true);
      let sec2Data = { ...currentCMS.section_2 };
      let card1 = { ...currentCMS.section_2?.card_1 };
      let card2 = { ...currentCMS.section_2?.card_2 };
      let card3 = { ...currentCMS.section_2?.card_3 };
      card1 = { ...currentCMS.section_2?.card_1, selectedFile: currentCMS.section_2?.card_1?.images }
      card2 = { ...currentCMS.section_2?.card_2, selectedFile: currentCMS.section_2?.card_2?.images }
      card3 = { ...currentCMS.section_2?.card_3, selectedFile: currentCMS.section_2?.card_3?.images }
      sec2Data = { ...sec2Data, card_1: card1 }
      sec2Data = { ...sec2Data, card_2: card2 }
      sec2Data = { ...sec2Data, card_3: card3 }
      setFormData({
        ...currentCMS,
        section_1: currentCMS.section_1.map((item) => {
          return {
            ...item,
            image: item.images,
            selectedFile: item.images
          }
        }),
        section_2: sec2Data,
        section_5: currentCMS.section_5.map((item) => {
          return {
            ...item,
            image: item.images,
            selectedFile: item.images
          }
        }),
        section_6: { ...currentCMS.section_6, selectedFile: currentCMS?.section_6?.images },
      });
      setCard1_des({ ...card1_des, description: currentCMS?.section_2?.card_1?.description })
      setCard2_des({ ...card2_des, description: currentCMS?.section_2?.card_2?.description })
      setCard3_des({ ...card3_des, description: currentCMS?.section_2?.card_3?.description })

    }
    return () => {
      // setIsLoad(false);
    }
  }, [currentCMS])

  /**************** on Change event *********************/
  const onChange = (e) => {
    if (e.target.name === 'page_name') {
      let new_slug = slugify(e.target.value);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        slug: new_slug,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value.trimStart() });
    }
  };

  const onChangeSectionOne = (e, index) => {
    let formSectionOneData = [...formData.section_1];
    if (e?.target?.name === 'images') {
      const prevImag = URL.createObjectURL(e.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        // Use a regex to remove data url part
        let base64String = reader.result;
        formSectionOneData[index] = {
          ...formData.section_1[index],
          selectedFile: base64String, images: prevImag,
        }
        setFormData((data1) => ({
          ...data1,
          section_1: formSectionOneData
        }));
      };
      document.getElementById(`images-${index}`).value = '';
    } else {
      formSectionOneData[index] = { ...formData.section_1[index], [e?.target?.name]: e?.target?.value.trimStart() };
      setFormData((data1) => ({
        ...data1,
        section_1: formSectionOneData
      }));
    }
  };

  const onChangeSectionTwo = (e, id) => {
    let sectionTwoData = { ...formData?.section_2 };
    if (id === '0') {
      let formSectionOneData = { ...formData.section_2?.card_1 };
      if (e?.target?.name === 'image') {
        const prevImag = URL.createObjectURL(e.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
          // Use a regex to remove data url part
          let base64String = reader.result;
          formSectionOneData = {
            ...formData.section_2?.card_1, selectedFile: base64String, image: prevImag,
          }
          sectionTwoData = { ...formData.section_2, card_1: formSectionOneData }
          setFormData({
            ...formData,
            section_2: sectionTwoData
          });
        };
        document.getElementById(`0`).value = '';
      } else if (e?.editor?.name === 'description') {
        // formSectionOneData = { ...formData.section_2?.card_1, ['description']: e?.editor.getData() };
        // sectionTwoData = { ...formData.section_2, card_1: formSectionOneData }
        // setFormData((data1) => ({
        //   ...data1,
        //   section_2: sectionTwoData
        // }));
        setCard1_des({ ...card1_des, description: e?.editor?.getData() })
      } else {
        formSectionOneData = { ...formData.section_2?.card_1, [e?.target?.name]: e?.target?.value.trimStart() }
        sectionTwoData = { ...formData.section_2, card_1: formSectionOneData }
        setFormData({
          ...formData,
          section_2: sectionTwoData
        });
      }
    }
    if (id === '1') {
      let formSectionOneData = { ...formData.section_2?.card_2 };
      if (e?.target?.name === 'image') {
        const prevImag = URL.createObjectURL(e.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
          // Use a regex to remove data url part
          let base64String = reader.result;
          formSectionOneData = {
            ...formData.section_2?.card_2, selectedFile: base64String, image: prevImag,
          }
          sectionTwoData = { ...formData.section_2, card_2: formSectionOneData }
          setFormData({
            ...formData,
            section_2: sectionTwoData
          });
        };
        document.getElementById(`1`).value = '';
      } else if (e?.editor?.name === 'description') {
        // formSectionOneData = { ...formData.section_2?.card_2, ['description']: e?.editor.getData() };
        // sectionTwoData = { ...formData.section_2, card_2: formSectionOneData }
        // setFormData((data1) => ({
        //   ...data1,
        //   section_2: sectionTwoData
        // }));
        setCard2_des({ ...card2_des, description: e?.editor?.getData() })
      } else {
        formSectionOneData = { ...formData.section_2?.card_2, [e?.target?.name]: e?.target?.value.trimStart() };
        sectionTwoData = { ...formData.section_2, card_2: formSectionOneData }
        setFormData((data1) => ({
          ...data1,
          section_2: sectionTwoData
        }));
      }
    }
    if (id === '2') {
      let formSectionOneData = { ...formData.section_2?.card_3 };
      if (e?.target?.name === 'image') {
        const prevImag = URL.createObjectURL(e.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
          // Use a regex to remove data url part
          let base64String = reader.result;
          formSectionOneData = {
            ...formData.section_2?.card_3, selectedFile: base64String, image: prevImag,
          }
          sectionTwoData = { ...formData.section_2, card_3: formSectionOneData }
          setFormData({
            ...formData,
            section_2: sectionTwoData
          });
        };
        document.getElementById(`2`).value = '';
      } else if (e?.editor?.name === 'description') {
        // formSectionOneData = { ...formData.section_2?.card_3, ['description']: e?.editor.getData() };
        // sectionTwoData = { ...formData.section_2, card_3: formSectionOneData }
        // setFormData((data1) => ({
        //   ...data1,
        //   section_2: sectionTwoData
        // }));
        setCard3_des({ ...card3_des, description: e?.editor?.getData() })
      } else {
        formSectionOneData = { ...formData.section_2?.card_3, [e?.target?.name]: e?.target?.value.trimStart() };
        sectionTwoData = { ...formData.section_2, card_3: formSectionOneData }
        setFormData((data1) => ({
          ...data1,
          section_2: sectionTwoData
        }));
      }
    }
  };

  const onChangeSectionThird = (e) => {
    let editorField = ["description", "title", "column_1", "column_2"];
    if (editorField.includes(e?.editor?.name)) {
      setFormData((data1) => ({
        ...data1,
        section_3: { ...data1.section_3, [e?.editor?.name]: e?.editor.getData() }
      }));
    } else {
      // formSectionOneData = { ...formData.section_3, [e?.target?.name]: e?.target?.value.trimStart() }
      setFormData((data1) => ({
        ...data1,
        section_3: { ...data1.section_3, [e?.target?.name]: e?.target?.value.trimStart() }
      }));
    }
  };

  const onChangeSectionFour = (e) => {
    let formSectionOneData = { ...formData.section_4 };
    formSectionOneData = { ...formData.section_4, [e?.target?.name]: e?.target?.value.trimStart() };
    setFormData((data1) => ({
      ...data1,
      section_4: formSectionOneData
    }));
  };

  const onChangeSectionFive = (e, index) => {
    let formSectionOneData = [...formData.section_5];
    if (e?.target?.name === 'images') {
      const prevImag = URL.createObjectURL(e.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        // Use a regex to remove data url part
        let base64String = reader.result;
        formSectionOneData[index] = {
          ...formData.section_5[index],
          selectedFile: base64String, images: prevImag,
        }
        setFormData((data1) => ({
          ...data1,
          section_5: formSectionOneData
        }));
      };
      document.getElementById(`image-${index}`).value = '';
    } else {
      formSectionOneData[index] = { ...formData.section_5[index], [e?.target?.name]: e?.target?.value.trimStart() };
      setFormData((data1) => ({
        ...data1,
        section_5: formSectionOneData
      }));
    }
  };

  const onChangeSectionSix = (e) => {
    let formSectionOneData = { ...formData.section_6 };
    if (e?.target?.name === 'image') {
      const prevImag = URL.createObjectURL(e.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        // Use a regex to remove data url part
        let base64String = reader.result;
        formSectionOneData = {
          ...formData.section_6,
          selectedFile: base64String, image: prevImag,
        }
        setFormData((data1) => ({
          ...data1,
          section_6: formSectionOneData
        }));
      };
      document.getElementById(`image`).value = '';
    } else {
      formSectionOneData = { ...formData.section_6, [e?.target?.name]: e?.target?.value.trimStart() };
      setFormData((data1) => ({
        ...data1,
        section_6: formSectionOneData
      }));
    }
  };

  // handle click event of the Remove button
  const handleCMSRemoveClick = (e) => {
    const index = e.currentTarget.getAttribute("data-id");
    const cmsData = [
      ...formData.section_1
    ];

    cmsData.splice(index, 1);
    setFormData((data1) => ({
      ...data1,
      section_1: cmsData
    }));
  };

  // handle click event of the Add More button
  const handleCMSAddMore = (e) => {
    e.preventDefault();
    const cmsData = [
      ...formData.section_1
    ];
    let i = cmsData.length;
    cmsData[i] = {
      ...cmsData[i], title: "", subtitle: "", images: '', description: '', selectedFile: '',
    }

    setFormData((data1) => ({
      ...data1,
      section_1: cmsData
    }));

    i++;
  };

  // handle click event of the Remove button
  const handleCMSRemoveClickSec5 = (e) => {
    const index = e.currentTarget.getAttribute("data-id");
    const cmsData = [
      ...formData.section_5
    ];

    cmsData.splice(index, 1);
    setFormData((data1) => ({
      ...data1,
      section_5: cmsData
    }));
  };

  // handle click event of the Add More button
  const handleCMSAddMoreSec5 = (e) => {
    e.preventDefault();
    const cmsData = [
      ...formData.section_5
    ];
    let i = cmsData.length;
    cmsData[i] = {
      ...cmsData[i], title: "", subtitle: "", images: '', description: '', selectedFile: '',
    }

    setFormData((data1) => ({
      ...data1,
      section_5: cmsData
    }));

    i++;
  };

  const handleCancel = (event) => {
    event.preventDefault();
    setOnCancel(!onCancel);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    let form = {
      ...formData?.section_2,
      card_1: { ...formData?.section_2?.card_1, description: card1_des?.description },
      card_2: { ...formData?.section_2?.card_2, description: card2_des?.description },
      card_3: { ...formData?.section_2?.card_3, description: card3_des?.description }
    }
    let formState = { ...formData, section_2: form }
    if (home_id) {
      edit(formState, navigate, 'home');
    } else {
      create(formState, navigate);
    }
  };

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <Form
                className="form-horizontal"
                onSubmit={(e) => onSubmit(e)}
                encType="multipart/form-data"
              >
                <CardBody>
                  <Card className="my-2">
                    <CardHeader tag="h5">Page Information</CardHeader>
                    <CardBody>
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="page_name">
                              Page Name <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="page_name"
                              name="page_name"
                              maxLength="100"
                              value={formData.page_name}
                              // required
                              // readOnly
                              onChange={onChange}
                              invalid={errorList.page_name ? true : false}
                            />
                            <Errors current_key="page_name" key="page_name" />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="slug">
                              Page Slug <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="slug"
                              name="slug"
                              maxLength="100"
                              value={formData.slug}
                              // required
                              readOnly
                              invalid={errorList.slug ? true : false}
                            />
                            <Errors current_key="slug" key="slug" />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <Card className="my-2">
                    <CardHeader tag="h5">SEO Information</CardHeader>
                    <CardBody>
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="meta_title">
                              Title
                            </Label>
                            <Input
                              type="text"
                              id="meta_title"
                              name="meta_title"
                              value={formData.meta_title}
                              onChange={onChange}
                              invalid={errorList.meta_title ? true : false}
                            />
                            <Errors
                              current_key="meta_title"
                              key="meta_title"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="meta_description">
                              Meta Description
                            </Label>
                            <Input
                              type="textarea"
                              id="meta_description"
                              name="meta_description"
                              value={formData.meta_description}
                              onChange={onChange}
                              invalid={errorList.meta_description ? true : false}
                            />
                            <Errors
                              current_key="meta_description"
                              key="meta_description"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card className="my-2">
                    <CardHeader tag="h5">Section 1</CardHeader>
                    {formData.section_1.map((x, i) => {
                      return (
                        <Card key={i} className="mx-2 my-2">
                          <CardHeader tag="h6">Slider {i + 1}</CardHeader>
                          <CardBody>
                            <Row>
                              <Col sm="6">
                                <FormGroup>
                                  <Label htmlFor="title">
                                    Title <span>*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    id="title"
                                    name="title"
                                    value={x.title}
                                    onChange={e => onChangeSectionOne(e, i)}
                                    invalid={errorList.title ? true : false}
                                  />
                                  <Errors
                                    current_key={`section_1[${i}].title`}
                                    key={`section_1[${i}].title`}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm="6">
                                <FormGroup>
                                  <Label htmlFor="subtitle">
                                    Subtitle <span>*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    id="subtitle"
                                    name="subtitle"
                                    value={x.subtitle}
                                    onChange={e => onChangeSectionOne(e, i)}
                                    invalid={errorList.subtitle ? true : false}
                                  />
                                  <Errors
                                    current_key={`section_1[${i}].subtitle`}
                                    key={`section_1[${i}].subtitle`}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm="6">
                                <FormGroup>
                                  <Label htmlFor="description">
                                    Description <span>*</span>
                                  </Label>
                                  <Input
                                    type="textarea"
                                    id="description"
                                    name="description"
                                    value={x.description}
                                    onChange={e => onChangeSectionOne(e, i)}
                                    invalid={errorList.description ? true : false}
                                  />
                                  <Errors
                                    current_key={`section_1[${i}].description`}
                                    key={`section_1[${i}].description`}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm="6">
                                <FormGroup>
                                  <Label htmlFor="images">
                                    Images (Image aspect ratio should be 2200x923px for perfect fit) <span>*</span>
                                  </Label>
                                  <Input
                                    type="file"
                                    accept="image/*"
                                    id={`images-${i}`}
                                    name="images"
                                    onChange={e => onChangeSectionOne(e, i)}
                                  />
                                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {x.images != undefined && x.images &&
                                      <div className="thumb" key={i}>
                                        <Col key={`img-${x.images}`}>
                                          {x.images && (
                                            <img
                                              src={x.images[0]?.original ? x.images[0]?.original : x.images}
                                              className="preview-img"
                                              alt="image_file"
                                              style={{ height: "40px" }}
                                            />
                                          )}
                                        </Col>
                                      </div>
                                    }
                                  </div>
                                  {x.selectedFile && <Errors current_key={`selectedFile`} key="selectedFile" />}
                                  <Errors
                                    current_key={`section_1[${i}].selectedFile`}
                                    key={`section_1[${i}].selectedFile`}
                                  />
                                </FormGroup>
                              </Col>
                              <Card className="my-2">
                                <CardHeader tag="h6">Button 1</CardHeader>
                                <CardBody>
                                  <Row>
                                    <Col sm="6">
                                      <FormGroup>
                                        <Label htmlFor="button1_text">
                                          Text
                                        </Label>
                                        <Input
                                          type="button1_text"
                                          id="button1_text"
                                          name="button1_text"
                                          value={x.button1_text}
                                          onChange={e => onChangeSectionOne(e, i)}
                                          invalid={errorList.button - 1 ? true : false}
                                        />
                                        <Errors
                                          current_key={`section_1[${i}].button1_text`}
                                          key={`section_1[${i}].button1_text`}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                      <FormGroup>
                                        <Label htmlFor="button1_Link">
                                          Link
                                        </Label>
                                        <Input
                                          type="url"
                                          id="button1_Link"
                                          name="button1_Link"
                                          value={x.button1_Link}
                                          onChange={e => onChangeSectionOne(e, i)}
                                          invalid={errorList.button - 1 ? true : false}
                                        />
                                        <Errors
                                          current_key={`section_1[${i}].button1_Link`}
                                          key={`section_1[${i}].button1_Link`}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                              <Card className="my-2">
                                <CardHeader tag="h6">Button 2</CardHeader>
                                <CardBody>
                                  <Row>
                                    <Col sm="6">
                                      <FormGroup>
                                        <Label htmlFor="button2_text">
                                          Text
                                        </Label>
                                        <Input
                                          type="button2_text"
                                          id="button2_text"
                                          name="button2_text"
                                          value={x.button2_text}
                                          onChange={e => onChangeSectionOne(e, i)}
                                          invalid={errorList.button - 1 ? true : false}
                                        />
                                        <Errors
                                          current_key={`section_1[${i}].button2_text`}
                                          key={`section_1[${i}].button2_text`}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                      <FormGroup>
                                        <Label htmlFor="button2_Link">
                                          Link
                                        </Label>
                                        <Input
                                          type="url"
                                          id="button2_Link"
                                          name="button2_Link"
                                          value={x.button2_Link}
                                          onChange={e => onChangeSectionOne(e, i)}
                                          invalid={errorList.button - 1 ? true : false}
                                        />
                                        <Errors
                                          current_key={`section_1[${i}].button2_Link`}
                                          key={`section_1[${i}].button2_Link`}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Row>
                            {
                              i != 0 &&
                              <Col sm="12" style={{ marginBottom: 32 }}>

                                <Button type="reset" size="sm" color="danger" data-id={i} onClick={handleCMSRemoveClick}>
                                  <i className="fa fa-ban"></i> Remove
                                </Button>
                              </Col>
                            }
                          </CardBody>
                        </Card>
                      );
                    })}
                    <Row className="mx-1 my-2">
                      <Col sm="6">
                        <FormGroup>
                          <Button type="reset" size="sm" color="primary" onClick={handleCMSAddMore}>
                            <i className="fa fa-dot-circle-o"></i> Add More..
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Card>
                  <Card className="my-2">
                    <CardHeader tag="h5">Section 2</CardHeader>
                    <CardBody>
                      <Card>
                        <CardHeader tag="h5">Card 1</CardHeader>
                        <CardBody>
                          <Row>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="title">
                                  Title <span>*</span>
                                </Label>
                                <Input
                                  type="text"
                                  id="0"
                                  name="title"
                                  value={formData?.section_2?.card_1.title}
                                  onChange={(e) => onChangeSectionTwo(e, '0')}
                                  invalid={errorList.title ? true : false}
                                />
                                <Errors
                                  current_key="section_2.card_1.title"
                                  key="section_2.card_1.title"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="subtitle">
                                  Subtitle <span>*</span>
                                </Label>
                                <Input
                                  type="text"
                                  id="0"
                                  name="subtitle"
                                  value={formData?.section_2?.card_1.subtitle}
                                  onChange={(e) => onChangeSectionTwo(e, '0')}
                                  invalid={errorList.subtitle ? true : false}
                                />
                                <Errors
                                  current_key="section_2.card_1.subtitle"
                                  key="section_2.card_1.subtitle"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="image">
                                  Image (Image aspect ratio should be 1201x2220px for perfect fit)<span>*</span>
                                </Label>
                                <Input
                                  type="file"
                                  accept="image/*"
                                  id="0"
                                  name="image"
                                  // value={formData?.section_2?.card_1?.image}
                                  onChange={(e) => onChangeSectionTwo(e, '0')}
                                // invalid={errorList.images ? true : false}
                                />
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                  {formData.section_2.card_1?.images != undefined && formData.section_2.card_1.images &&
                                    Object.values(formData.section_2.card_1?.images).map(
                                      (imageItems, index) => {
                                        return (
                                          <div className="thumb" key={index}>
                                            <Col key={`img-${formData.section_1.card_1?.images}`}>
                                              {imageItems ? (
                                                <img
                                                  src={formData.section_2.card_1?.image == undefined && imageItems?.original ? imageItems?.original : formData.section_2.card_1?.image}
                                                  className="preview-img"
                                                  alt="image_file"
                                                  style={{ height: "40px" }}
                                                />
                                              ) : null}
                                            </Col>
                                          </div>
                                        )
                                      })}
                                </div>
                                <Errors
                                  current_key="section_2.card_1.selectedFile"
                                  key="section_2.card_1.selectedFile"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="link">
                                  Link <span>*</span>
                                </Label>
                                <Input
                                  type="url"
                                  id="0"
                                  name="link"
                                  value={formData.section_2.card_1.link}
                                  onChange={(e) => onChangeSectionTwo(e, '0')}
                                  invalid={errorList.button - 1 ? true : false}
                                />
                                <Errors
                                  current_key="section_2.card_1.link"
                                  key="section_2.card_1.link"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="12">
                              <FormGroup>
                                <Label htmlFor="description">
                                  Description <span>*</span>
                                </Label>
                                {card1_des?.description &&
                                  <CKEditor
                                    initData={card1_des?.description}
                                    id="2"
                                    name="description"
                                    config={CKEditorConfig}
                                    onChange={(e) => onChangeSectionTwo(e, '0')}
                                  />
                                }
                                <Errors
                                  current_key="section_2.card_1.description"
                                  key="section_2.card_1.description"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </CardBody>
                    <CardBody>
                      <Card>
                        <CardHeader tag="h5">Card 2</CardHeader>
                        <CardBody>
                          <Row>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="title">
                                  Title <span>*</span>
                                </Label>
                                <Input
                                  type="text"
                                  id="1"
                                  name="title"
                                  value={formData?.section_2?.card_2?.title}
                                  onChange={(e) => onChangeSectionTwo(e, '1')}
                                  invalid={errorList.title ? true : false}
                                />
                                <Errors
                                  current_key="section_2.card_2.title"
                                  key="section_2.card_2.title"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="subtitle">
                                  Subtitle <span>*</span>
                                </Label>
                                <Input
                                  type="text"
                                  id="1"
                                  name="subtitle"
                                  value={formData?.section_2?.card_2?.subtitle}
                                  onChange={(e) => onChangeSectionTwo(e, '1')}
                                  invalid={errorList.subtitle ? true : false}
                                />
                                <Errors
                                  current_key="section_2.card_2.subtitle"
                                  key="section_2.card_2.subtitle"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="image">
                                  Image (Image aspect ratio should be 1201x2220px for perfect fit)<span>*</span>
                                </Label>
                                <Input
                                  type="file"
                                  accept="image/*"
                                  id="1"
                                  name="image"
                                  // value={formData?.section_2?.card_2?.image}
                                  onChange={(e) => onChangeSectionTwo(e, '1')}
                                // invalid={errorList.images ? true : false}
                                />
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                  {formData.section_2.card_2?.images != undefined && formData.section_2.card_2.images &&
                                    Object.values(formData.section_2.card_2?.images).map(
                                      (imageItems, index) => {
                                        return (
                                          <div className="thumb" key={index}>
                                            <Col key={`img-${formData.section_1.card_2?.images}`}>
                                              {imageItems ? (
                                                <img
                                                  src={formData.section_2.card_2?.image == undefined && imageItems?.original ? imageItems?.original : formData.section_2.card_2?.image}
                                                  className="preview-img"
                                                  alt="image_file"
                                                  style={{ height: "40px" }}
                                                />
                                              ) : null}
                                            </Col>
                                          </div>
                                        )
                                      })}
                                </div>
                                <Errors
                                  current_key="section_2.card_2.selectedFile"
                                  key="section_2.card_2.selectedFile"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="link">
                                  Link <span>*</span>
                                </Label>
                                <Input
                                  type="url"
                                  id="1"
                                  name="link"
                                  value={formData.section_2.card_2.link}
                                  onChange={(e) => onChangeSectionTwo(e, '1')}
                                  invalid={errorList.button - 1 ? true : false}
                                />
                                <Errors
                                  current_key="section_2.card_2.link"
                                  key="section_2.card_2.link"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="12">
                              <FormGroup>
                                <Label htmlFor="description">
                                  Description <span>*</span>
                                </Label>
                                {card2_des?.description &&
                                  <CKEditor
                                    initData={card2_des?.description}
                                    id="2"
                                    name="description"
                                    config={CKEditorConfig}
                                    onChange={(e) => onChangeSectionTwo(e, '1')}
                                  />
                                }
                                <Errors
                                  current_key="section_2.card_2.description"
                                  key="section_2.card_2.description"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </CardBody>
                    <CardBody>
                      <Card>
                        <CardHeader tag="h5">Card 3</CardHeader>
                        <CardBody>
                          <Row>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="title">
                                  Title <span>*</span>
                                </Label>
                                <Input
                                  type="text"
                                  id="2"
                                  name="title"
                                  value={formData?.section_2?.card_3?.title}
                                  onChange={(e) => onChangeSectionTwo(e, '2')}
                                  invalid={errorList.title ? true : false}
                                />
                                <Errors
                                  current_key="section_2.card_3.title"
                                  key="section_2.card_3.title"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="subtitle">
                                  Subtitle <span>*</span>
                                </Label>
                                <Input
                                  type="text"
                                  id="2"
                                  name="subtitle"
                                  value={formData?.section_2?.card_3?.subtitle}
                                  onChange={(e) => onChangeSectionTwo(e, '2')}
                                  invalid={errorList.subtitle ? true : false}
                                />
                                <Errors
                                  current_key="section_2.card_3.subtitle"
                                  key="section_2.card_3.subtitle"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="image">
                                  Image (Image aspect ratio should be 1201x2220px for perfect fit)<span>*</span>
                                </Label>
                                <Input
                                  type="file"
                                  accept="image/*"
                                  id="2"
                                  name="image"
                                  // value={formData?.section_2?.card_3?.image}
                                  onChange={(e) => onChangeSectionTwo(e, '2')}
                                // invalid={errorList.images ? true : false}
                                />
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                  {formData.section_2.card_3?.images != undefined && formData.section_2.card_3.images &&
                                    Object.values(formData.section_2.card_3?.images).map(
                                      (imageItems, index) => {
                                        return (
                                          <div className="thumb" key={index}>
                                            <Col key={`img-${formData.section_1.card_3?.images}`}>
                                              {imageItems ? (
                                                <img
                                                  src={(formData.section_2.card_3?.image == undefined && imageItems?.original) ? imageItems?.original : formData.section_2.card_3?.image}
                                                  className="preview-img"
                                                  alt="image_file"
                                                  style={{ height: "40px" }}
                                                />
                                              ) : null}
                                            </Col>
                                          </div>
                                        )
                                      })}
                                </div>
                                <Errors
                                  current_key="section_2.card_3.selectedFile"
                                  key="section_2.card_3.selectedFile"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="link">
                                  Link <span>*</span>
                                </Label>
                                <Input
                                  type="url"
                                  id="2"
                                  name="link"
                                  value={formData.section_2.card_3.link}
                                  onChange={(e) => onChangeSectionTwo(e, '2')}
                                  invalid={errorList.button - 1 ? true : false}
                                />
                                <Errors
                                  current_key="section_2.card_3.link"
                                  key="section_2.card_3.link"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="12">
                              <FormGroup>
                                <Label htmlFor="description">
                                  Description <span>*</span>
                                </Label>
                                {/* <Input
                                  type="textarea"
                                  id="2"
                                  name="description"
                                  value={formData?.section_2?.card_3?.description}
                                  onChange={(e) => onChangeSectionTwo(e, '2')}
                                  invalid={errorList.description ? true : false}
                                /> */}
                                {card3_des?.description &&
                                  <CKEditor
                                    initData={card3_des?.description}
                                    id="2"
                                    name="description"
                                    config={CKEditorConfig}
                                    onChange={(e) => onChangeSectionTwo(e, '2')}
                                  />
                                }
                                <Errors
                                  current_key="section_2.card_3.description"
                                  key="section_2.card_3.description"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </CardBody>
                  </Card>
                  <Card className="my-2">
                    <CardHeader tag="h5">Section 3</CardHeader>
                    <CardBody>
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="title">
                              Title <span>*</span>
                            </Label>
                            {/* <Input
                              type="text"
                              id="title"
                              name="title"
                              value={formData.section_3.title}
                              onChange={onChangeSectionThird}
                              invalid={errorList.title ? true : false}
                            /> */}
                            {formData?.section_3.title &&
                              <CKEditor
                                initData={formData?.section_3.title}
                                id="0"
                                name="title"
                                config={CKEditorConfig}
                                onChange={(e) => onChangeSectionThird(e)}
                              />
                            }
                            <Errors
                              current_key="section_3.title"
                              key="section_3.title"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="subtitle">
                              Subtitle <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="subtitle"
                              name="subtitle"
                              value={formData.section_3.subtitle}
                              onChange={onChangeSectionThird}
                              invalid={errorList.subtitle ? true : false}
                            />
                            <Errors
                              current_key="section_3.subtitle"
                              key="section_3.subtitle"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup>
                            <Label htmlFor="description">
                              Description <span>*</span>
                            </Label>
                            {/* <Input
                              type="textarea"
                              id="description"
                              name="description"
                              value={formData.section_3.description}
                              onChange={onChangeSectionThird}
                              invalid={errorList.description ? true : false}
                            /> */}
                            {isLoad &&
                              <CKEditor
                                initData={formData.section_3?.description ?? ""}
                                id="description"
                                name="description"
                                config={CKEditorConfig}
                                onChange={(e) => onChangeSectionThird(e)}
                              />
                            }
                            <Errors
                              current_key="section_3.description"
                              key="section_3.description"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup>
                            <Label htmlFor="column_1">
                              Column 1
                            </Label>
                            {isLoad &&
                              <CKEditor
                                initData={formData?.section_3?.column_1 ?? ""}
                                id="column_1"
                                name="column_1"
                                config={CKEditorConfig}
                                onChange={(e) => onChangeSectionThird(e)}
                              />
                            }
                            <Errors
                              current_key="section_3.column_1"
                              key="section_3.column_1"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup>
                            <Label htmlFor="column_2">
                              Column 2
                            </Label>
                            {isLoad &&
                              <CKEditor
                                initData={formData?.section_3?.column_2 ?? ""}
                                id="column_2"
                                name="column_2"
                                config={CKEditorConfig}
                                onChange={(e) => onChangeSectionThird(e)}
                              />
                            }
                            <Errors
                              current_key="section_3.column_2"
                              key="section_3.column_2"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card className="my-2">
                    <CardHeader tag="h5">Section 4</CardHeader>
                    <CardBody>
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="title">
                              Title <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="title"
                              name="title"
                              value={formData.section_4.title}
                              onChange={onChangeSectionFour}
                              invalid={errorList.title ? true : false}
                            />
                            <Errors
                              current_key="section_4.title"
                              key="section_4.title"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="subtitle">
                              Subtitle <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="subtitle"
                              name="subtitle"
                              value={formData.section_4.subtitle}
                              onChange={onChangeSectionFour}
                              invalid={errorList.subtitle ? true : false}
                            />
                            <Errors
                              current_key="section_4.subtitle"
                              key="section_4.subtitle"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup>
                            <Label htmlFor="description">
                              Description <span>*</span>
                            </Label>
                            <Input
                              type="textarea"
                              id="description"
                              name="description"
                              value={formData.section_4.description}
                              onChange={onChangeSectionFour}
                              invalid={errorList.description ? true : false}
                            />
                            <Errors
                              current_key="section_4.description"
                              key="section_4.description"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card className="my-2">
                    <CardHeader tag="h5">Section 5</CardHeader>
                    {formData.section_5.map((x, i) => {
                      return (
                        <Card key={i} className="mx-2 my-2">
                          <CardHeader tag="h6">Slider {i + 1}</CardHeader>
                          <CardBody>
                            <Row>
                              <Col sm="6">
                                <FormGroup>
                                  <Label htmlFor="title">
                                    Title <span>*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    id="title"
                                    name="title"
                                    value={x.title}
                                    onChange={e => onChangeSectionFive(e, i)}
                                    invalid={errorList.title ? true : false}
                                  />
                                  <Errors
                                    current_key={`section_5[${i}].title`}
                                    key={`section_5[${i}].title`}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm="6">
                                <FormGroup>
                                  <Label htmlFor="subtitle">
                                    Subtitle <span>*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    id="subtitle"
                                    name="subtitle"
                                    value={x.subtitle}
                                    onChange={e => onChangeSectionFive(e, i)}
                                    invalid={errorList.subtitle ? true : false}
                                  />
                                  <Errors
                                    current_key={`section_5[${i}].subtitle`}
                                    key={`section_5[${i}].subtitle`}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm="6">
                                <FormGroup>
                                  <Label htmlFor="description">
                                    Description <span>*</span>
                                  </Label>
                                  <Input
                                    type="textarea"
                                    id="description"
                                    name="description"
                                    value={x.description}
                                    onChange={e => onChangeSectionFive(e, i)}
                                    invalid={errorList.description ? true : false}
                                  />
                                  <Errors
                                    current_key={`section_5[${i}].description`}
                                    key={`section_5[${i}].description`}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm="6">
                                <FormGroup>
                                  <Label htmlFor="images">
                                    Images (Image aspect ratio should be 2200x923px for perfect fit)<span>*</span>
                                  </Label>
                                  <Input
                                    type="file"
                                    accept="image/*"
                                    id={`image-${i}`}
                                    name="images"
                                    onChange={e => onChangeSectionFive(e, i)}
                                  />
                                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {x.images != undefined && x.images &&
                                      <div className="thumb" key={i}>
                                        <Col key={`img-${x.images}`}>
                                          {x.images && (
                                            <img
                                              src={x.images[0]?.original ? x.images[0]?.original : x.images}
                                              className="preview-img"
                                              alt="image_file"
                                              style={{ height: "40px" }}
                                            />
                                          )}
                                        </Col>
                                      </div>
                                    }
                                  </div>
                                  {x.selectedFile && <Errors current_key={`selectedFile`} key="selectedFile" />}
                                  <Errors
                                    current_key={`section_5[${i}].selectedFile`}
                                    key={`section_5[${i}].selectedFile`}
                                  />
                                </FormGroup>
                              </Col>
                              <Card className="my-2">
                                <CardHeader tag="h6">Button 1</CardHeader>
                                <CardBody>
                                  <Row>
                                    <Col sm="6">
                                      <FormGroup>
                                        <Label htmlFor="button1_text">
                                          Text
                                        </Label>
                                        <Input
                                          type="button1_text"
                                          id="button1_text"
                                          name="button1_text"
                                          value={x.button1_text}
                                          onChange={e => onChangeSectionFive(e, i)}
                                          invalid={errorList.button - 1 ? true : false}
                                        />
                                        <Errors
                                          current_key={`ection_5[${i}].button1_text`}
                                          key={`section_5[${i}].button1_text`}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                      <FormGroup>
                                        <Label htmlFor="button1_Link">
                                          Link
                                        </Label>
                                        <Input
                                          type="url"
                                          id="button1_Link"
                                          name="button1_Link"
                                          value={x.button1_Link}
                                          onChange={e => onChangeSectionFive(e, i)}
                                          invalid={errorList.button - 1 ? true : false}
                                        />
                                        <Errors
                                          current_key={`section_5[${i}].button1_Link`}
                                          key={`section_5[${i}].button1_Link`}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                              <Card className="my-2">
                                <CardHeader tag="h6">Button 2</CardHeader>
                                <CardBody>
                                  <Row>
                                    <Col sm="6">
                                      <FormGroup>
                                        <Label htmlFor="button2_text">
                                          Text
                                        </Label>
                                        <Input
                                          type="button2_text"
                                          id="button2_text"
                                          name="button2_text"
                                          value={x.button2_text}
                                          onChange={e => onChangeSectionFive(e, i)}
                                          invalid={errorList.button - 1 ? true : false}
                                        />
                                        <Errors
                                          current_key={`section_5[${i}].button2_text`}
                                          key={`section_5[${i}].button2_text`}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                      <FormGroup>
                                        <Label htmlFor="button2_Link">
                                          Link
                                        </Label>
                                        <Input
                                          type="url"
                                          id="button2_Link"
                                          name="button2_Link"
                                          value={x.button2_Link}
                                          onChange={e => onChangeSectionFive(e, i)}
                                          s invalid={errorList.button - 1 ? true : false}
                                        />
                                        <Errors
                                          current_key={`section_5[${i}].button2_Link`}
                                          key={`section_5[${i}].button2_Link`}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Row>
                            {
                              i != 0 &&
                              <Col sm="12" style={{ marginBottom: 32 }}>

                                <Button type="reset" size="sm" color="danger" data-id={i} onClick={handleCMSRemoveClickSec5}>
                                  <i className="fa fa-ban"></i> Remove
                                </Button>
                              </Col>
                            }
                          </CardBody>
                        </Card>
                      );
                    })}
                    <Row className="mx-1 my-2">
                      <Col sm="6">
                        <FormGroup>
                          <Button type="reset" size="sm" color="primary" onClick={handleCMSAddMoreSec5}>
                            <i className="fa fa-dot-circle-o"></i> Add More..
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Card>
                  <Card className="my-2">
                    <CardHeader tag="h5">Section 6</CardHeader>
                    <CardBody>
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="title">
                              Title <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="title"
                              name="title"
                              value={formData.section_6.title}
                              onChange={onChangeSectionSix}
                              invalid={errorList.title ? true : false}
                            />
                            <Errors
                              current_key="section_6.title"
                              key="section_6.title"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="subtitle">
                              Subtitle <span>*</span>
                            </Label>
                            <Input
                              type="text"
                              id="subtitle"
                              name="subtitle"
                              value={formData.section_6.subtitle}
                              onChange={onChangeSectionSix}
                              invalid={errorList.subtitle ? true : false}
                            />
                            <Errors
                              current_key="section_6.subtitle"
                              key="section_6.subtitle"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="description">
                              Description <span>*</span>
                            </Label>
                            <Input
                              type="textarea"
                              id="description"
                              name="description"
                              value={formData.section_6.description}
                              onChange={onChangeSectionSix}
                              invalid={errorList.description ? true : false}
                            />
                            <Errors
                              current_key="section_6.description"
                              key="section_6.description"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="image">
                              Image (Image aspect ratio should be 1201x2220px for perfect fit)<span>*</span>
                            </Label>
                            <Input
                              type="file"
                              accept="image/*"
                              id="image"
                              name="image"
                              // value={formData.section_6.image}
                              onChange={onChangeSectionSix}
                            // invalid={errorList.images ? true : false}
                            />
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {formData.section_6?.images != undefined && formData.section_6?.images &&
                                Object.values(formData.section_6?.images).map(
                                  (imageItems, index) => {
                                    return (
                                      <div className="thumb" key={index}>
                                        <Col key={`img-${formData.section_6?.images}`}>
                                          {imageItems ? (
                                            <img
                                              src={(formData.section_6?.image == undefined && imageItems?.original) ? imageItems?.original : formData.section_6?.image}
                                              className="preview-img"
                                              alt="image_file"
                                              style={{ height: "40px" }}
                                            />
                                          ) : null}
                                        </Col>
                                      </div>
                                    )
                                  })}
                            </div>
                            <Errors
                              current_key="section_6.selectedFile"
                              key="section_6.selectedFile"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="button_text">
                              Button Text
                            </Label>
                            <Input
                              type="button_text"
                              id="button_text"
                              name="button_text"
                              value={formData.section_6.button_text}
                              onChange={onChangeSectionSix}
                              invalid={errorList.button - 1 ? true : false}
                            />
                            <Errors
                              current_key="section_6.button_text"
                              key="section_6.button_text"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="button_Link">
                              Button Link
                            </Label>
                            <Input
                              type="url"
                              id="button_Link"
                              name="button_Link"
                              value={formData.section_6.button_Link}
                              onChange={onChangeSectionSix}
                              invalid={errorList.button - 1 ? true : false}
                            />
                            <Errors
                              current_key="section_6.button_Link"
                              key="section_6.button_Link"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary">
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <a onClick={handleCancel}>
                    <Button type="reset" size="sm" color="danger">
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </a>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

AddEditHome.propTypes = {
  getHomeById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  cms: PropTypes.object.isRequired,
  create: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  cms: state.home,
  errorList: state.errors,
  loading: state.home.loading,
});

export default connect(mapStateToProps, {
  create,
  getHomeById,
  edit,
})(AddEditHome);
