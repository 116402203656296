import {
    PRODUCT_SELECTOR_LOADING,
    PRODUCT_SELECTOR_CREATED,
    PRODUCT_SELECTOR_CREATED_ERROR,
    PRODUCT_SELECTOR_FAIL,
    PRODUCT_SELECTOR_UPDATED,
    PRODUCT_SELECTOR_UPDATED_ERROR,
    PRODUCT_SELECTOR_DELETED,
    PRODUCT_SELECTOR_DELETE_ERROR,
    PRODUCT_SELECTOR_LIST_UPDATED,
    GET_PRODUCT_SELECTOR_BY_ID,
    PRODUCT_SELECTOR_CHANGE_STATUS,
    PRODUCT_SELECTOR_CHANGE_STATUS_ERROR,
  } from "actions/types";
  
  const initalState = {
    newsList: {
      page: 1,
      data: [],
      count: 0,
    },
    currentNews: {},
    loading: false,
    isSubLoading: false,
  };
  
  export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
      case PRODUCT_SELECTOR_LOADING:
        return {
          ...state,
          newsList: payload.is_sub_element ? state.newsList : initalState.newsList,
          currentNews: payload.is_sub_element
            ? state.currentNews
            : initalState.currentNews,
          loading: payload.is_loading ? payload.is_loading : false,
          isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
        };
      case PRODUCT_SELECTOR_CREATED:
        return {
          ...state,
          loading: false,
        };
      case PRODUCT_SELECTOR_CREATED_ERROR:
        return {
          ...state,
          loading: false,
        };
      case PRODUCT_SELECTOR_FAIL:
        return {
          ...state,
          loading: false,
          isSubLoading: false
        };
      case PRODUCT_SELECTOR_UPDATED:
        return {
          ...state,
          currentNews: {},
          loading: false,
        };
      case PRODUCT_SELECTOR_UPDATED_ERROR:
        return {
          ...state,
          loading: false,
        };
      case PRODUCT_SELECTOR_DELETED:
        return {
          ...state,
          loading: false,
        };
      case PRODUCT_SELECTOR_DELETE_ERROR:
        return {
          ...state,
          loading: false,
        };
      case PRODUCT_SELECTOR_LIST_UPDATED:
        return {
          ...state,
          newsList: {
            data: payload.data.newsList.data,
            page: payload.data.newsList.metadata[0].current_page,
            count: payload.data.newsList.metadata[0].totalRecord,
          },
          loading: false,
        };
      case GET_PRODUCT_SELECTOR_BY_ID:
        return {
          ...state,
          currentNews: payload.data,
          loading: false,
        };
      case PRODUCT_SELECTOR_CHANGE_STATUS:
        return {
          ...state,
          newsList: {
            ...state.newsList,
            data: state.newsList.data.map((news) =>
              news._id === payload.data._id
                ? { ...news, status: payload.data.status }
                : news
            ),
          },
          loading: false,
          isSubLoading: false
        };
      case PRODUCT_SELECTOR_CHANGE_STATUS_ERROR:
        return {
          ...state,
          newsList: {
            ...state.newsList,
            data: state.newsList.data.map((news) =>
              news._id === payload.data
                ? { ...news, is_failed: true }
                : { ...news, is_failed: false }
            ),
          },
          loading: false,
          isSubLoading: false
        };
  
      default:
        return state;
    }
  }
  