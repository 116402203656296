/* eslint-disable */
import React, { useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { edit, getStaffById, editPassword } from 'actions/admin/staff';
import Spinner from 'views/Spinner';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  //InputGroupAddon,
  InputGroupText,
  FormText,
} from 'reactstrap';
import Errors from 'views/Notifications/Errors';
import { REMOVE_ERRORS } from 'actions/types';
import { useNavigate } from 'react-router-dom';
import { UI_PERMISSION_NAME, PERMISSION_NAME } from 'constants/index';

const EditStaff = ({
  getStaffById,
  edit,
  editPassword,
  staff: { currentStaff, loading },
  // history,
  // match,
  errorList,
}) => {
  const navigate = useNavigate(); // make const(history deprecated)
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const inputRef = useRef(null);

  /*************************form data********************/
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    permission: []
  });

  /*************************explode form data********************/
  const { first_name, last_name, email, permission } = formData;

  /*************************get dealer details********************/
  const { staff_id: staff_id } = useParams();
  useMemo(() => {
    getStaffById(staff_id, navigate);
  }, [staff_id]);

  /*************************set form data********************/
  useMemo(() => {
    if (currentStaff) {
      if (Object.keys(currentStaff).length > 0) {
        if (currentStaff.permission.includes(PERMISSION_NAME['SUPER_ADMIN'])) {
          setIsSuperAdmin(true);
        } else {
          setIsSuperAdmin(false);
        }
        setFormData({
          first_name: currentStaff.first_name ? currentStaff.first_name : '',
          last_name: currentStaff.last_name ? currentStaff.last_name : '',
          email: currentStaff.email ? currentStaff.email : '',
          permission: currentStaff.permission ? currentStaff.permission : [],
        });
      }
    }
  }, [currentStaff]);

  useEffect(() => {
    ReactTooltip.show(inputRef.current);
  }, []);

  /*************************use dispatch********************/
  const dispatch = useDispatch();

  /*************************on change event********************/
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    dispatch({ type: REMOVE_ERRORS });
  };

  const handlePermissionCheckedChange = (e) => {
    let permissionArray = formData['permission'];
    if (parseInt(e.target.name?.trim()) === PERMISSION_NAME['SUPER_ADMIN']) {
      if (e.target.checked) {
        permissionArray = [1, 2, 3, 4, 5, 6];
        setIsSuperAdmin(true);
      } else {
        permissionArray = [];
        setIsSuperAdmin(false);
      }
    } else {
      setIsSuperAdmin(false);
      if (e.target.checked) {
        if (!permissionArray.length) permissionArray.push(parseInt(e.target.name?.trim()))
        else {
          permissionArray = permissionArray.filter((item, index) => {
            return item !== parseInt(e.target.name?.trim());
          })
          permissionArray.push(parseInt(e.target.name?.trim()));
        }
      } else {
        if (permissionArray.length) {
          permissionArray = permissionArray.filter((item, index) => {
            return item !== parseInt(e.target.name?.trim());
          });
        }
      }
    }
    setFormData({ ...formData, permission: permissionArray });
  };

  /*************************on submit event********************/
  const onSubmit = (e) => {
    e.preventDefault();
    Object.keys(formData).forEach(k => {
      typeof (formData[k]) === 'string' ? formData[k] = formData[k]?.trim() : formData[k] = formData[k]
    });
    edit(formData, navigate, staff_id);
  };

  /*************************password form initiall********************/
  const initialPasswordFormData = {
    password: '',
    confirm_password: '',
  };

  /*************************set password form data********************/
  const [passwordFormData, setPasswordFormData] = useState(
    initialPasswordFormData
  );
  const [checkPassword, setCheckPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  /*************************explode form data********************/
  const { password, confirm_password } = passwordFormData;

  /*************************modal show and hide********************/
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  /*************************reset modal form data********************/
  const reset = () => {
    dispatch({ type: REMOVE_ERRORS });
    setPasswordFormData(initialPasswordFormData);
  };

  /*************************on change password form data********************/
  const onPasswordChange = (e) => {
    setPasswordFormData({
      ...passwordFormData,
      [e.target.name]: e.target.value?.trim(),
    });
    setCheckPassword(false);
    setErrorMessage('');
    dispatch({ type: REMOVE_ERRORS });
  };

  /*************************on submit password form data********************/
  const onModelSubmit = (e) => {
    e.preventDefault();
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
    const isChecked = regex.test(password);
    if (password !== confirm_password) {
      setErrorMessage("Password do not match.");
    } else if (!isChecked) {
      setCheckPassword(true);
      setErrorMessage('')
    } else {
      setErrorMessage('');
      setCheckPassword(false);
      editPassword(passwordFormData, staff_id).then((res) => {
        if (res) setModal(!modal);
      });
    }
  };

  const togglePassword = (e) => {
    e.preventDefault();
    if (e.currentTarget.getAttribute("data-id") == "password") {
      setPasswordShown(!passwordShown);
      setConfirmPassword(confirmPassword);
    }
    if (e.currentTarget.getAttribute("data-id") == "confirm_password") {
      setPasswordShown(passwordShown);
      setConfirmPassword(!confirmPassword);
    }
  };


  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader className="staff-info">
                <h5>Basic Information</h5>
                <Button size="sm" color="primary" onClick={toggle}>
                  <i className="fa fa-dot-circle-o"></i> Change Password
                </Button>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="first_name">
                        First Name <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="first_name"
                        name="first_name"
                        maxLength="25"
                        value={first_name}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.first_name ? true : false}
                      />
                      <Errors current_key="first_name" key="first_name" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="last_name">Last Name</Label>
                      <Input
                        type="text"
                        id="last_name"
                        name="last_name"
                        maxLength="25"
                        value={last_name}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.last_name ? true : false}
                      />
                      <Errors current_key="last_name" key="last_name" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="email">
                        Email <span>*</span>
                      </Label>
                      <Input
                        type="email"
                        id="email"
                        name="email"
                        maxLength="50"
                        value={email}
                        readOnly
                        onChange={(e) => onChange(e)}
                        invalid={errorList.email ? true : false}
                      />
                      <Errors current_key="email" key="email" />
                    </FormGroup>
                  </Col>
                  <Col sm="12">
                    <Label htmlFor="permission">
                      Permissions <span>*</span>
                    </Label>
                    <Row>
                      {Object.entries(UI_PERMISSION_NAME).map(([key, value]) => {
                        return <Col>
                          <FormGroup className="checkouter">
                            <Input
                              type="checkbox"
                              id={key}
                              name={key}
                              checked={permission.includes(parseInt(key))}
                              className="mr-2"
                              onChange={(e) => handlePermissionCheckedChange(e)}
                              disabled={isSuperAdmin && parseInt(key) !== PERMISSION_NAME['SUPER_ADMIN']}
                            />
                            {' '}<Label for={key}> {value}</Label>
                          </FormGroup>
                        </Col>
                      })}
                    </Row>
                    <Errors current_key="permission" key="permission" />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardFooter>
                <Button
                  className="mr-05"
                  type="submit"
                  size="sm"
                  color="primary"
                >
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
                <Link to="/admin/staff-members">
                  <Button type="reset" size="sm" color="danger">
                    <i className="fa fa-ban"></i> Cancel
                  </Button>
                </Link>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Form>
      <Modal isOpen={modal} toggle={toggle} onClosed={reset}>
        <ModalHeader toggle={toggle}>Change Password</ModalHeader>
        <Form className="form-horizontal" onSubmit={(e) => onModelSubmit(e)}>
          <ModalBody>
            <div className="mb-3">
              <InputGroup>
                <InputGroupText>
                  <i className="icon-lock"></i>
                </InputGroupText>
                {password == '' &&
                  <ReactTooltip id="title required1" efect="solid" place="bottom">
                    Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                  </ReactTooltip>
                }
                <Input
                  type={(passwordShown) ? "text" : "password"}
                  placeholder="Password"
                  autoComplete="password"
                  ref={inputRef}
                  data-tip
                  data-for="title required1"
                  name="password"
                  id="password"
                  required
                  value={password}
                  onChange={(e) => onPasswordChange(e)}
                  invalid={errorList.password ? true : false}
                />
                <InputGroupText onClick={togglePassword} data-id="password" >{(passwordShown) ? <FaRegEyeSlash /> : <FaRegEye />}</InputGroupText>
              </InputGroup>
              <Errors current_key="password" key="password" />
              {(checkPassword && password != "") &&
                <div id="message">
                  <FormText style={{ fontSize: "10px" }} className='text-danger'> Password requirements:</FormText>
                  <FormText>
                    <li style={{ fontSize: "10px" }} className='text-danger'>Must contain at least 8 characters</li>
                    <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 uppercase letter</li>
                    <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 number</li>
                    <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 special character (@$!%*#?&.)</li>
                  </FormText>
                </div>
              }
            </div>
            <div className="mb-3">
              <InputGroup >
                <InputGroupText>
                  <i className="icon-lock"></i>
                </InputGroupText>
                {confirm_password == '' &&
                  <ReactTooltip id="title required2" efect="solid" place="bottom">
                    Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                  </ReactTooltip>
                }
                <Input
                  type={(confirmPassword) ? "text" : "password"}
                  placeholder="Confirm Password"
                  autoComplete="confirm_password"
                  name="confirm_password"
                  id="confirm_password"
                  ref={inputRef}
                  data-tip
                  data-for="title required2"
                  required
                  value={confirm_password}
                  onChange={(e) => onPasswordChange(e)}
                  invalid={errorList.confirm_password ? true : false}
                />
                <InputGroupText onClick={togglePassword} data-id="confirm_password" >{(confirmPassword) ? <FaRegEyeSlash /> : <FaRegEye />}</InputGroupText>
              </InputGroup>
              <Errors current_key="confirm_password" key="confirm_password" />
              {errorMessage && <span className='text-danger'>{errorMessage}</span>}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" color="primary" type="submit">
              <i className="fa fa-dot-circle-o"></i> Submit
            </Button>
            <Button type="reset" size="sm" color="danger" onClick={toggle}>
              <i className="fa fa-ban"></i> Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

EditStaff.propTypes = {
  getStaffById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  staff: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
  editPassword: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  staff: state.staff,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  getStaffById,
  edit,
  editPassword,
})(EditStaff);
