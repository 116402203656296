import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { CKEditor } from 'ckeditor4-react';

import { connect } from 'react-redux';
import { edit, getBlogById } from 'actions/admin/blog';
import Spinner from 'views/Spinner';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import Errors from 'views/Notifications/Errors';
import { REMOVE_ERRORS } from 'actions/types';
import { CKEditorConfig } from 'constants';

const EditBlog = ({
  getBlogById,
  edit,
  blog: { currentBlog, loading },
  history,
  match,
  errorList,
}) => {
  /**************** use state for form data *********************/

  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    blog_title: '',
    blog_header: '',
    slug: '',
    description: '',
    meta_description: '',
    selectedFile: '',
    image: '',
    oldImage: '',
    isNewFileSelected: 0,
  });

  /**************** use memo to get current blog *********************/
  const { blog_id: blog_id } = useParams();
  useMemo(() => {
    getBlogById(blog_id);
  }, [getBlogById, blog_id]);

  /**************** use memo to set form data from current blog *********************/
  useMemo(() => {
    if (Object.keys(currentBlog).length > 0) {
      setFormData({
        ...currentBlog,
        image: !currentBlog.thumbnail
          ? ''
          : //process.env.REACT_APP_AWS_STORAGE_URL +
          'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/blog/' +
          currentBlog.thumbnail,
        oldImage: !currentBlog.thumbnail ? '' : currentBlog.thumbnail,
        isNewFileSelected: 0,
      });
    }
  }, [currentBlog]);

  /**************** expload from data *********************/
  const {
    blog_title,
    blog_header,
    slug,
    description,
    meta_description,
    image,
    // oldImage,
  } = formData;

  /**************** use dispatch *********************/

  /**************** handle on change event *********************/
  const onChange = (e) => {
    if (e.target.name === 'thumbnail') {
      let image_file = e.target.files[0];
      let image_url = URL.createObjectURL(image_file);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        selectedFile: image_file,
        image: image_url,
        isNewFileSelected: 1,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handelDescriptionChange = (event) => {
    setFormData({ ...formData, description: event.editor.getData() });
  };

  /**************** handle on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    edit(formData, navigate, blog_id);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="12">
          <Card>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="slug">
                        Blog Slug <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="slug"
                        name="slug"
                        maxLength="100"
                        value={slug}
                        onChange={(e) => onChange(e)}
                        readOnly
                        invalid={errorList.slug ? true : false}
                        disabled
                      />
                      <Errors current_key="slug" key="slug" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="blog_title">
                        Subject <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="blog_title"
                        name="blog_title"
                        maxLength="100"
                        value={blog_title}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.blog_title ? true : false}
                      />
                      <Errors current_key="blog_title" key="blog_title" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="blog_header">
                        Blog Header <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="blog_header"
                        name="blog_header"
                        maxLength="100"
                        required
                        value={blog_header}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.blog_header ? true : false}
                      />
                      <Errors current_key="blog_header" key="blog_header" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="meta_description">Meta Description</Label>
                      <Input
                        type="textarea"
                        id="meta_description"
                        name="meta_description"
                        value={meta_description}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.meta_description ? true : false}
                      />
                      <Errors
                        current_key="meta_description"
                        key="meta_description"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="thumbnail">
                        Thumbnail<span>*</span>
                      </Label>
                      <Input
                        type="file"
                        id="thumbnail"
                        name="thumbnail"
                        onChange={(e) => onChange(e)}
                        multiple
                        invalid={errorList.image ? true : false}
                      />
                      <img src={image} className="preview-img" alt="" />
                      <Errors current_key="image" key="image" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="description">
                        Body <span>*</span>
                      </Label>
                      <CKEditor
                        initData={description}
                        id="description"
                        name="description"
                        config={CKEditorConfig}
                        // onChange={(event) => {
                        //   handelDescriptionChange(event);
                        // }}
                        invalid={errorList.description ? true : false}
                      />
                      <Errors current_key="description" key="description" />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button
                  type="submit"
                  size="sm"
                  color="primary"
                  disabled={loading}
                >
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
                <Link to="/admin/blogs">
                  <Button type="reset" size="sm" color="danger">
                    <i className="fa fa-ban"></i> Cancel
                  </Button>
                </Link>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditBlog.propTypes = {
  getBlogById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  blog: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  blog: state.blog,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  getBlogById,
  edit,
})(EditBlog);
