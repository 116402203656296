import {
  DEALER_LOADING,
  DEALER_CREATED,
  DEALER_CREATE_ERROR,
  DEALER_FAIL,
  DEALER_MODAL_LOADING,
  DEALER_PASSWORD_UPDATED,
  DEALER_PASSWORD_UPDATE_ERROR,
  DEALER_UPDATED,
  DEALER_UPDATE_ERROR,
  DEALER_DELETED,
  DEALER_DELETE_ERROR,
  DEALER_LIST_UPDATED,
  GET_DEALER_BY_ID,
  DEALER_CHANGE_STATUS,
  DEALER_CHANGE_STATUS_ERROR,
  GET_DEALER_TYPES,
  DEALER_CHANGE_STATUS_BULK,
  DEALER_CHANGE_STATUS_BULK_ERROR,
  DEALER_FEATURED_BULK,
  DEALER_FEATURED_BULK_ERROR,
  DEALER_CHANGE_TYPE_BULK,
  DEALER_CHANGE_TYPE_BULK_ERROR,
  DEALER_MARK_FEATURED,
  DEALER_MARK_FEATURED_ERROR,
  GET_DEALER_BY_ID_ERROR,
} from "actions/types";


const initalState = {
  usersList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentUser: {},
  loading: false,
  modal_loading: false,
  isSubLoading: false,
  dealerTypeList: [],
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case DEALER_LOADING:
      return {
        ...state,
        usersList: payload.is_sub_element
          ? state.usersList
          : initalState.usersList,
        currentUser: payload.is_sub_element
          ? state.currentUser
          : initalState.currentUser,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case DEALER_CREATED:
      return {
        ...state,
        loading: false,
      };
    case DEALER_CREATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DEALER_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case DEALER_MODAL_LOADING:
      return {
        ...state,
        modal_loading: payload,
      };
    case DEALER_PASSWORD_UPDATED:
      return {
        ...state,
        modal_loading: false,
      };
    case DEALER_PASSWORD_UPDATE_ERROR:
      return {
        ...state,
        modal_loading: false,
      };
    case DEALER_UPDATED:
      return {
        ...state,
        currentUser: {},
        loading: false,
      };
    case DEALER_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DEALER_DELETED:
      return {
        ...state,
        loading: false,
      };
    case DEALER_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DEALER_LIST_UPDATED:
      return {
        ...state,
        // sortingParams: { ...payload.data.userParams },
        usersList: {
          data: payload.data.dealerList.data,
          page: payload.data.dealerList.metadata[0].current_page,
          count: payload.data.dealerList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_DEALER_BY_ID:
      return {
        ...state,
        currentUser: payload.data,
        loading: false,
      };
    case GET_DEALER_BY_ID_ERROR:
      return {
        ...state,
        currentUser: {},
        loading: false
      }
    case DEALER_CHANGE_STATUS:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          data: state.usersList.data.map((user) =>
            user._id === payload.data._id
              ? { ...user, status: payload.data.status }
              : user
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case DEALER_CHANGE_STATUS_ERROR:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          data: state.usersList.data.map((user) =>
            user._id === payload.data
              ? { ...user, is_failed: true }
              : { ...user, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case GET_DEALER_TYPES:
      return {
        ...state,
        dealerTypeList: payload.data,
        isSubLoading: false,
        loading: false,
      };
    case DEALER_CHANGE_STATUS_BULK:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          data: state.usersList.data.map((user) =>
            payload.data._id.includes(user._id)
              ? { ...user, status: parseInt(payload.data.status) }
              : user
          ),
        },
        isSubLoading: false,
        loading: false,
      };
    case DEALER_CHANGE_STATUS_BULK_ERROR:
      return {
        ...state,
        isSubLoading: false,
        loading: false,
      };
    case DEALER_FEATURED_BULK:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          data: state.usersList.data.map((user) =>
            payload.data._id.includes(user._id)
              ? {
                ...user,
                dealer: {
                  ...user.dealer,
                  is_featured: true,
                },
              }
              : user
          ),
        },
        isSubLoading: false,
        loading: false,
      };
    case DEALER_FEATURED_BULK_ERROR:
      return {
        ...state,
        isSubLoading: false,
        loading: false,
      };
    case DEALER_CHANGE_TYPE_BULK:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          data: state.usersList.data.map((user) =>
            payload.data._id.includes(user._id)
              ? {
                ...user,
                dealer: {
                  ...user.dealer,
                  dealer_type_id: payload.type,
                  dealer_type: {
                    0: {
                      ...user.dealer.dealer_type[0],
                      name: payload.data.dealerTypeName,
                    },
                  },
                },
              }
              : user
          ),
        },
        isSubLoading: false,
        loading: false,
      };
    case DEALER_CHANGE_TYPE_BULK_ERROR:
      return {
        ...state,
        isSubLoading: false,
        loading: false,
      };
    case DEALER_MARK_FEATURED:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          data: state.usersList.data.map((user) =>
            user._id === payload.data._id
              ? {
                ...user,
                dealer: {
                  ...user.dealer,
                  is_featured: payload.data.is_featured
                  // is_featured: true,
                },
              }
              : user
          ),
        },
        isSubLoading: false,
        loading: false,
      };
    case DEALER_MARK_FEATURED_ERROR:
      return {
        ...state,
        isSubLoading: false,
        loading: false,
      };
    default:
      return state;
  }
}
