import {
  USER_ADDRESS_LOADING,
  USER_ADDRESS_CREATED,
  USER_ADDRESS_CREATE_ERROR,
  USER_ADDRESS_FAIL,
  USER_ADDRESS_UPDATED,
  USER_ADDRESS_UPDATE_ERROR,
  USER_ADDRESS_DELETED,
  USER_ADDRESS_DELETE_ERROR,
  USER_ADDRESS_LIST_UPDATED,
  GET_USER_ADDRESS_BY_ID,
  USER_ADDRESS_PRIMARY,
  USER_ADDRESS_PRIMARY_ERROR,
  USER_ADDRESS_CHANGE_STATUS,
  USER_ADDRESS_CHANGE_STATUS_ERROR,
  GET_USER_DETAILS_BY_ID
} from "actions/types";

const initalState = {
  userAddressList: {
    page: 1,
    data: [],
    count: 0,
  },
  loading: false,
  isSubLoading: false,
  error: {},
  currentUserAddress: {},
  currentUserDetails: {}
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_ADDRESS_LOADING:
      return {
        ...state,
        userAddressList: payload.is_sub_element
          ? state.userAddressList
          : initalState.userAddressList,
        currentUserAddress: payload.is_sub_element
          ? state.currentUserAddress
          : initalState.currentUserAddress,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case USER_ADDRESS_CREATED:
      return {
        ...state,
        loading: false,
      };
    case USER_ADDRESS_CREATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case USER_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case USER_ADDRESS_UPDATED:
      return {
        ...state,
        currentUserAddress: {},
        loading: false,
      };
    case USER_ADDRESS_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case USER_ADDRESS_DELETED:
      return {
        ...state,
        loading: false,
      };
    case USER_ADDRESS_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case USER_ADDRESS_LIST_UPDATED:
      return {
        ...state,
        userAddressList: {
          data: payload.data.userAddressList.data,
          page: payload.data.userAddressList.metadata[0].current_page,
          count: payload.data.userAddressList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_USER_ADDRESS_BY_ID:
      return {
        ...state,
        currentUserAddress: payload.data,
        loading: false,
      };
    case USER_ADDRESS_PRIMARY:
      return {
        ...state,
        userAddressList: {
          ...state.userAddressList,
          data: state.userAddressList.data.map((user) =>
            user.address_id === payload.data.current // to mark current address as primary
              ? {
                  ...user,
                  is_primary: true,
                  last_edited: payload.data.current_last_edited,
                }
              : user.address_id === payload.data.previous // to remove pevious primary address as primary
              ? {
                  ...user,
                  is_primary: false,
                  last_edited: payload.data.previous_last_edited,
                }
              : user
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case USER_ADDRESS_PRIMARY_ERROR:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case USER_ADDRESS_CHANGE_STATUS:
      return {
        ...state,
        userAddressList: {
          ...state.userAddressList,
          data: state.userAddressList.data.map((address) =>
            address.address_id === payload.data.address_id
              ? {
                  ...address,
                  status: payload.data.status,
                  last_edited: payload.data.last_edited,
                }
              : address
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case USER_ADDRESS_CHANGE_STATUS_ERROR:
      return {
        ...state,
        userAddressList: {
          ...state.userAddressList,
          data: state.userAddressList.data.map((address) =>
            address._id === payload.data
              ? { ...address, is_failed: true }
              : { ...address, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case GET_USER_DETAILS_BY_ID:
      return {
        ...state,
        currentUserDetails: payload.data,
        loading: false,
      };
    default:
      return state;
  }
}
