import {
    NEWS_LOADING,
    NEWS_CREATED,
    NEWS_CREATE_ERROR,
    NEWS_FAIL,
    NEWS_UPDATED,
    NEWS_UPDATE_ERROR,
    NEWS_DELETED,
    NEWS_DELETE_ERROR,
    NEWS_LIST_UPDATED,
    GET_NEWS_BY_ID,
    NEWS_CHANGE_STATUS,
    NEWS_CHANGE_STATUS_ERROR,
  } from "actions/types";
  
  const initalState = {
    newsList: {
      page: 1,
      data: [],
      count: 0,
    },
    currentNews: {},
    loading: false,
    isSubLoading: false,
  };
  
  export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
      case NEWS_LOADING:
        return {
          ...state,
          newsList: payload.is_sub_element ? state.newsList : initalState.newsList,
          currentNews: payload.is_sub_element
            ? state.currentNews
            : initalState.currentNews,
          loading: payload.is_loading ? payload.is_loading : false,
          isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
        };
      case NEWS_CREATED:
        return {
          ...state,
          loading: false,
        };
      case NEWS_CREATE_ERROR:
        return {
          ...state,
          loading: false,
        };
      case NEWS_FAIL:
        return {
          ...state,
          loading: false,
          isSubLoading: false
        };
      case NEWS_UPDATED:
        return {
          ...state,
          currentNews: {},
          loading: false,
        };
      case NEWS_UPDATE_ERROR:
        return {
          ...state,
          loading: false,
        };
      case NEWS_DELETED:
        return {
          ...state,
          loading: false,
        };
      case NEWS_DELETE_ERROR:
        return {
          ...state,
          loading: false,
        };
      case NEWS_LIST_UPDATED:
        return {
          ...state,
          newsList: {
            data: payload.data.newsList.data,
            page: payload.data.newsList.metadata[0].current_page,
            count: payload.data.newsList.metadata[0].totalRecord,
          },
          loading: false,
        };
      case GET_NEWS_BY_ID:
        return {
          ...state,
          currentNews: payload.data,
          loading: false,
        };
      case NEWS_CHANGE_STATUS:
        return {
          ...state,
          newsList: {
            ...state.newsList,
            data: state.newsList.data.map((news) =>
              news._id === payload.data._id
                ? { ...news, status: payload.data.status }
                : news
            ),
          },
          loading: false,
          isSubLoading: false
        };
      case NEWS_CHANGE_STATUS_ERROR:
        return {
          ...state,
          newsList: {
            ...state.newsList,
            data: state.newsList.data.map((news) =>
              news._id === payload.data
                ? { ...news, is_failed: true }
                : { ...news, is_failed: false }
            ),
          },
          loading: false,
          isSubLoading: false
        };
  
      default:
        return state;
    }
  }
  