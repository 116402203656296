/* eslint-disable */
import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import Moment from 'react-moment';

import { Button, Card, CardBody, Col, Row, Input, Form, Table, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { getBuildShopSupplyList, deleteBuildShopSupply, downloadbuildShopSupply, uploadBuildShopSupply } from 'actions/admin/buildShopSupply';
import * as Constants from 'constants/index';

import Spinner from 'views/Spinner';
import PerPageSelect from '../PerPageSelect';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from 'utils/Loading';

const renderTitle = (params) => {
    if (params.data === undefined) {
        return <Loading />;
    } else {
        return <div>{params.data?.title}</div>;
    }
};

const actions = (
    <div className="add-button-div specify-width">
        <Link to="/admin/build-shop-supply/create" className="addNewElementClass">
            <Button color="primary" size="sm">
                <i className="fa fa-plus"></i> Add Item
            </Button>
        </Link>
    </div>
);

const renderName = (params) => {
    if (params.data === undefined) {
        // when no node id, display the spinner image
        return (
            <center>
                <span>Loading.......</span>
            </center>
        );
    } else {
        // otherwise just display node ID (or whatever you wish for this column)
        return <div>{params.data?.name}</div>;
    }
}

const BuildShopSupplyList = ({
    getBuildShopSupplyList,
    deleteBuildShopSupply,
    buildShopSupplyList: { data, count },
    loading,
    subLoading,
    uploadBuildShopSupply
}) => {
    const history = useNavigate();
    const location = useLocation();
    const [timeZone, setTimeZone] = useState('');

    useEffect(() => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setTimeZone(userTimeZone);
    }, []);

    //################## Initillise sorting searching parameters by default values ###################
    const [sortingParams, setSortingParams] = useState({
        limit: Constants.DEFAULT_PAGE_SIZE,
        page: 1,
        orderBy: 'created_at',
        ascending: 'desc',
        query: '',
        filter: {},
    });

    const [downloadData, setDownloadData] = useState([]);

    //#########################Colums defination start ###################
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            width: 150,
            sortable: true,
            filter: 'agTextColumnFilter',
            suppressSizeToFit: false,
            // pass in additional parameters to the text filter
            filterParams: {
                closeOnApply: true,
                filterOptions: Constants.TEXT_FILTER_OPTIONS,
                suppressAndOrCondition: true,
                debounceMs: 800,
            },
            colSpan: (params) => {
                if (params.data === undefined) {
                    return 6;
                } else {
                    return 0;
                }
            },
            // headerSortingStyle
            cellRenderer: renderName
        },
        {
            field: 'sku',
            headerName: 'SKU',
            suppressMovable: true,
            width: 150,
            cellClass: 'suppress-movable-col',
            sortable: true,
            filter: 'agTextColumnFilter',
            // pass in additional parameters to the text filter
            suppressSizeToFit: false,
            filterParams: {
                closeOnApply: true,
                filterOptions: Constants.TEXT_FILTER_OPTIONS,
                suppressAndOrCondition: true,
                debounceMs: 800,
            },
            // headerSortingStyle
        },
        {
            field: 'price',
            headerName: 'Price',
            suppressMovable: true,
            width: 150,
            cellClass: 'suppress-movable-col',
            sortable: true,
            filter: 'agNumberColumnFilter',
            // pass in additional parameters to the text filter
            suppressSizeToFit: false,
            filterParams: {
                closeOnApply: true,
                filterOptions: Constants.NUMBER_FILTER_OPTIONS,
                suppressAndOrCondition: true,
                debounceMs: 800,
            },
            // headerSortingStyle
        },
        {
            field: 'stock_quantity',
            headerName: 'Stock',
            suppressMovable: true,
            width: 150,
            cellClass: 'suppress-movable-col',
            sortable: true,
            filter: 'agNumberColumnFilter',
            // pass in additional parameters to the text filter
            suppressSizeToFit: false,
            filterParams: {
                closeOnApply: true,
                filterOptions: Constants.NUMBER_FILTER_OPTIONS,
                suppressAndOrCondition: true,
                debounceMs: 800,
            },
            // headerSortingStyle
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 140,
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            sortable: true,
            filter: 'agDateColumnFilter',
            // pass in additional parameters to the text filter
            filterParams: {
                closeOnApply: true,
                filterOptions: Constants.DATE_FILTER_OPTIONS,
                suppressAndOrCondition: true,
                debounceMs: 800,
            },
            cellRenderer: (params) => (
                <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
                    {params.value}
                </Moment>
            ),
        },
        {
            field: '_id',
            headerName: 'Actions',
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            cellRenderer: (params) => (
                <div>
                    <Link to={`/admin/build-shop-supply/edit/${params.data?._id}`}>
                        <Button type="button" size="sm" color="success" title="Edit">
                            <i className="fa fa-pencil"></i>
                        </Button>
                    </Link>
                    <Button
                        type="button"
                        size="sm"
                        color="danger"
                        title="Delete"
                        onClick={(e) => {
                            if (
                                window.confirm(
                                    `Are you sure, you want to delete ${params.data.name}?`
                                )
                            ) {
                                deleteBuildShopSupply(params.data?._id).then((res) => {
                                    if (res) {
                                        getBuildShopSupplyList(sortingParams);
                                        gridApi.purgeInfiniteCache();
                                    }
                                });
                            }
                        }}
                    >
                        <i className="fa fa-trash"></i>
                    </Button>
                </div>
            ),
            headerStyle: {
                backgroundColor: Constants.TABLE_BORDER_COLOR,
            },
        },
    ];
    //#########################Colums defination end ###################

    //################## START Set properties(options) of datatables ###################

    const gridRef = useRef();
    const [gridApi, setGridApi] = useState(null);
    const [csvData, setCsvData] = useState(null);
    const [invalidData, setInvalidData] = useState(null);
    const [model, setModel] = useState(false);
    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    });

    const handleCSVDownload = useCallback(async (event) => {
        let data = await downloadbuildShopSupply(sortingParams, timeZone);
        setDownloadData(data?.data);
        document.getElementById('downloadCsv').click();
    });

    const toggleViewDetailModal = () => {
        setModel(!model);
    }


    const onChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = handleCSVConversion;
        reader.readAsText(file);
    };

    const handleCSVConversion = (event) => {
        const csvData = event.target.result;
        const lines = csvData.split('\n');
        const headers = lines[0].split(',');

        const jsonData = [];
        for (let i = 1; i < lines.length; i++) {
            const currentLine = lines[i].split(',');
            if (currentLine.length === headers.length) {
                const row = {};
                for (let j = 0; j < headers.length; j++) {
                    row[headers[j]] = currentLine[j];
                }
                jsonData.push(row);
            }
        }
        setCsvData(jsonData);
    };

    const handleInventoryUpload = async (event) => {
        event.preventDefault();
        const inventoryData = await uploadBuildShopSupply(csvData);
        if (inventoryData.status) {
            document.getElementById("file-input").value = '';
            setCsvData([]);
            getBuildShopSupplyList(sortingParams);
            gridApi.purgeInfiniteCache();
        }
        if (inventoryData?.response?.checkInventory?.length > 0) {
            setInvalidData(inventoryData?.response?.checkInventory);
            setModel(true);
        }
    };

    const filterChanged = useCallback((params) => {
        const filterInstance = gridRef.current.api.getFilterInstance(
            params.columns[0].colId
        );
        if (filterInstance.appliedModel?.type === 'any') {
            setActiveClass('');
            gridRef?.current.api.destroyFilter(params.columns[0].colId);
        }
    });

    const onPaginationChange = useCallback((pageSize) => {
        setSortingParams((initVals) => {
            return { ...initVals, limit: pageSize };
        });
        gridRef.current.api.gridOptionsWrapper.setProperty(
            'cacheBlockSize',
            pageSize
        );
        gridRef.current.api.infiniteRowModel.resetCache();
        gridRef.current.api.paginationSetPageSize(pageSize);
    });

    const customLoadingCellRenderer = useMemo(() => {
        return LoadingCellRenderer;
    }, []);
    const loadingCellRendererParams = useMemo(() => {
        return {
            loadingMessage: 'Loading....',
        };
    }, []);

    useEffect(() => {
        if (gridApi) {
            if (Object.keys(sortingParams.filter).length) {
                gridApi.setFilterModel(sortingParams.filter);
            }
            const dataSource = {
                getRows: async (params) => {
                    const page = gridApi.paginationGetCurrentPage() + 1;
                    const limit = gridApi.paginationGetPageSize();

                    const sortModel = params.sortModel.length
                        ? params.sortModel[0]
                        : null;
                    const filterModel = params.filterModel ? params.filterModel : {};

                    const customParams = {
                        ...sortingParams,
                        limit,
                        orderBy: sortModel?.colId,
                        ascending: sortModel?.sort,
                        filter: filterModel,
                        page: page,
                    };

                    const customData = await getBuildShopSupplyList(customParams);

                    if (customData?.data?.status) {
                        params.successCallback(
                            customData.data.response[0].data,
                            customData.data.response[0].metadata[0].totalRecord
                        );
                        customParams.onLoad = false;
                        setSortingParams({ ...customParams });
                        history(location.pathname, { state: customParams });
                    } else {
                        params.successCallback([], 0);
                    }
                },
            };
            gridApi.setDatasource(dataSource);
            gridApi?.api?.sizeColumnsToFit();
        }
    }, [gridApi]);

    const onFirstDataRendered = useCallback(() => {
        setTimeout(() => {
            gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
        }, 1000);
    });

    const onPaginationChanged = useCallback((params) => {
        if (gridRef?.current.api) {
            const page = gridApi?.paginationGetCurrentPage();
            setSortingParams({ ...sortingParams, page });
        }
    });

    let delayTimer;
    const onSearch = (e) => {
        clearTimeout(delayTimer);
        const searchText = e.target.value.trim();

        delayTimer = setTimeout(() => {
            if (gridApi) {
                if (Object.keys(sortingParams.filter).length) {
                    gridApi.setFilterModel(sortingParams.filter);
                }
                const dataSource = {
                    getRows: async (params) => {
                        const page = gridApi.paginationGetCurrentPage() + 1;
                        const limit = gridApi.paginationGetPageSize();

                        const sortModel = params.sortModel.length
                            ? params.sortModel[0]
                            : null;
                        const filterModel = params.filterModel ? params.filterModel : {};
                        const customParams = {
                            ...sortingParams,
                            query: searchText,
                            limit,
                            orderBy: sortModel?.colId,
                            ascending: sortModel?.sort,
                            filter: filterModel,
                            page,
                        };

                        const customData = await getBuildShopSupplyList(customParams);

                        if (customData?.data?.status) {
                            params.successCallback(
                                customData.data.response[0].data,
                                customData.data.response[0].metadata[0].totalRecord
                            );
                            customParams.onLoad = false;
                            setSortingParams({ ...customParams });
                            history(location.pathname, { state: customParams });
                        } else {
                            params.successCallback([], 0);
                        }
                    },
                };
                gridApi.setDatasource(dataSource);
                gridApi.api?.sizeColumnsToFit();
            }
        }, 1000);
    };

    return (
        <div className="animated fadeIn userTableList">
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <div className="tabletopsec">

                                {actions}
                                <div className="rightpaneltop">
                                    <div className="add-button-div">
                                        <a
                                            href={downloadData}
                                            id="downloadCsv"
                                            download
                                            hidden
                                            className="hide"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        ></a>
                                    </div>
                                    <Form onSubmit={handleInventoryUpload}>
                                        <div className="rightpaneltop">
                                            <Input
                                                type="file"
                                                id="file-input"
                                                accept=".csv*"
                                                required
                                                onChange={onChange}
                                                style={{
                                                    width: '200px',
                                                    height: '32px',
                                                    paddingTop: "3px",
                                                    marginRight: '14px',
                                                    marginTop: '8px',
                                                }}
                                            />
                                            <Button type="submit" size="sm" color="primary" className="build-shop-export" disabled={loading}>
                                                {loading ? (
                                                    <div class="loader-1 center"><span></span></div>
                                                ) : (
                                                    <>
                                                        <i className="fa fa-upload"></i> Import CSV
                                                    </>
                                                )}
                                            </Button>
                                        </div>
                                    </Form>
                                    <Button
                                        color="primary"
                                        size="sm"
                                        className="build-shop-export"
                                        onClick={handleCSVDownload}
                                        style={{ marginBottom: "0px !important;" }}
                                    >
                                        <i className="fa fa-download"></i> Export CSV
                                    </Button>

                                    <Input
                                        type="text"
                                        id="search-text-box"
                                        placeholder="Search by Name"
                                        onKeyUp={(e) => {
                                            onSearch(e);
                                        }}
                                    />
                                </div>
                                <div className="rightpaneltop">
                                    Build Shop Supply Per Page
                                    <PerPageSelect
                                        perPage={sortingParams.limit}
                                        onPaginationChange={onPaginationChange}
                                    />
                                </div>
                            </div>
                            <div className="card w-100 p-0 border-0">
                                <div className="card-body p-0 position-relative">
                                    <div className="ag-theme-alpine">
                                        <AgGridReact
                                            defaultColDef={{
                                                resizable: true,
                                            }}
                                            loadingCellRenderer={customLoadingCellRenderer}
                                            loadingCellRendererParams={loadingCellRendererParams}
                                            domLayout={'autoHeight'}
                                            rowModelType={'infinite'}
                                            columnDefs={columns}
                                            pagination={true}
                                            sizeColumnsToFit={true}
                                            // floatingFilter={true}
                                            onFilterChanged={filterChanged}
                                            onGridReady={onGridReady}
                                            paginationPageSize={sortingParams.limit}
                                            cacheBlockSize={sortingParams.limit}
                                            cacheQuickFilter={true}
                                            onFirstDataRendered={onFirstDataRendered}
                                            onPaginationChanged={onPaginationChanged}
                                            ref={gridRef}
                                        ></AgGridReact>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal isOpen={model} toggle={toggleViewDetailModal}>
                <ModalHeader toggle={toggleViewDetailModal}>Report of invalid data</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Table bordered striped responsive size="sm">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>SKU</th>
                                        <th>Price</th>
                                        <th>Stock Quantity</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invalidData && invalidData.map((item) => (
                                        <tr key={item.id}>
                                            {Object.values(item).map((val) => (
                                                <td>{val}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={toggleViewDetailModal}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

BuildShopSupplyList.propTypes = {
    getBuildShopSupplyList: PropTypes.func.isRequired,
    deleteBuildShopSupply: PropTypes.func.isRequired,
    uploadBuildShopSupply: PropTypes.func.isRequired,
    buildShopSupplyList: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    subLoading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
    buildShopSupplyList: state.buildShopSupply.buildShopSupplyList,
    loading: state.buildShopSupply.loading,
    subLoading: state.buildShopSupply.isSubLoading,
});

export default connect(mapStateToProps, {
    getBuildShopSupplyList,
    deleteBuildShopSupply,
    uploadBuildShopSupply
})(BuildShopSupplyList);
