import React, { useEffect } from 'react';
import axios from 'axios';
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import Routes from './views/Routing/Routes';
import 'scss/style.scss';
import { setupInterceptorsTo } from 'Interceptor/Interceptor';
if (localStorage.token) {
  setAuthToken(localStorage.token);
}
setupInterceptorsTo(axios);
const App = (history) => {
  useEffect(() => {
    store.dispatch(loadUser({ history }));
  }, []);
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
};
export default App;
