import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Errors from 'views/Notifications/Errors';
import Spinner from 'views/Spinner';

import { edit, add, getSpecsById } from 'actions/admin/specs';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
} from 'reactstrap';

const EditSpecs = ({
  edit,
  add,
  specs: { currentSpecs, loading },
  errorList,
  getSpecsById,
}) => {
  /**************** use state for form data *********************/
  let navigate = useNavigate();

  const [formData, setFormData] = useState({
    label: '',
    name: '',
    nestedCheck: false,
    spec_question: false,
    type: 'fitting_spec',
    data: [
      {
        name: '',
        terms: [],
      },
    ],
  });

  /**************** use effect to get current attribute *********************/
  const { specs_id: specs_id } = useParams();
  const [nestedChecked, setNestedChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState(null);

  const [termValue, setTermValue] = useState(null);
  const [message, setMessage] = useState(false);

  /**************** handle tags  *********************/
  useEffect(() => {
    if (specs_id) getSpecsById(specs_id);
  }, [specs_id]);

  /**************** on submit event *********************/
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      formData['is_label'] = nestedChecked;

      if (formData?.nestedCheck == false) {
        formData.label = '';
      }
      if (specs_id) {
        edit(formData, navigate, specs_id);
      } else {
        add(formData, navigate);
      }
    },
    [formData, nestedChecked]
  );

  const handleNestedClick = useCallback(() => {
    setNestedChecked(!nestedChecked);
    setFormData({ ...formData, nestedCheck: !formData?.nestedCheck });
  }, [formData, nestedChecked]);

  const handleSpecQuestionClick = useCallback(() => {
    setFormData({ ...formData, spec_question: !formData?.spec_question });
  }, [formData]);

  /**************** on Change event *********************/
  const onChange = useCallback(
    (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    },
    [formData]
  );

  // handle input change
  const handleFittingChange = useCallback(
    (e, index) => {
      const { name, value } = e.target;

      const fittingData = [...formData.data];

      fittingData[index] = {
        ...fittingData[index],
        [name]: value,
      };
      setFormData((data1) => ({
        ...data1,
        data: fittingData,
      }));
    },
    [formData]
  );

  // handle click event of the Remove button
  const handleFittingRemoveClick = useCallback(
    (index) => {
      const fittingData = [...formData.data];

      fittingData.splice(index, 1);
      setFormData((data1) => ({
        ...data1,
        data: fittingData,
      }));
    },
    [formData]
  );

  // handle click event of the Add button
  const handleFittingAddClick = useCallback(
    (e) => {
      e.preventDefault();
      // setTagValue = [];
      const fittingData = [...formData.data];
      const i = fittingData.length;
      fittingData[i] = {
        ...formData[i],
        name: '',
        terms: [],
      };

      setFormData((data1) => ({
        ...data1,
        data: fittingData,
      }));

      i++;
    },
    [formData]
  );

  const handleChangeValues = useCallback((e) => {
    setIndex(e.currentTarget.getAttribute("data-id"));
    setShowModal(!showModal);
    setMessage(false);
  }, [showModal, index]);

  const Tag = useCallback(({ onDeleteTag, value }) => {
    var tag = (
      <div className="tag-item">
        { formData.spec_question == false &&
          <span className="tag-tagStyle" onClick={(e) => onDeleteTag(e, value)}>
            &#x2716;
          </span>
        }
        {value}
      </div>
    );
    return <React.Fragment>{tag}</React.Fragment>;
  });

  const TagList = useCallback(
    ({ onDeleteTag, index }) => {
      if (
        formData.data[index]?.terms !== undefined &&
        formData.data[index]?.terms?.length !== 0
      ) {
        let tagsUI = formData.data[index]?.terms.map((tag) => (
          <Tag
            onDeleteTag={() => onDeleteTag(tag, index)}
            key={tag}
            value={tag}
          />
        ));
        return <div className="tag-list">{tagsUI}</div>;
      } else {
        return <div className="tag-list"> </div>;
      }
    },
    [formData]
  );

  /**************** on add tags  *********************/

  const onAddTag = useCallback(
    (tag, index) => {
      const correntTags = [...(formData.data[index].terms ?? [])];
      if (!correntTags.includes(tag)) {
        correntTags.push(tag);
        const newFormData = { ...formData };
        newFormData.data[index].terms = correntTags;
        setFormData(newFormData);
      } else {
        alert('Tags already exist');
      }
    },
    [formData]
  );
  /**************** on delete tags  *********************/

  const onDeleteTag = useCallback(
    (tag, index) => {
      if (window.confirm(`Are you sure, you want to delete ${tag} tag? `)) {
        let remainingTags = formData.data[index].terms.filter((t) => {
          return t !== tag;
        });
        const newFormData = { ...formData };
        newFormData.data[index].terms = remainingTags;
        setFormData(newFormData);
      }
    },
    [formData]
  );
  /**************** on input tags  *********************/

  const InputTag = (onAddTag, onDeleteTag, index, placeholder, showModal) => {

    const onKeyUp = (e) => {
      e.preventDefault();
      // if (e.which === 13) {
      let input = e.target.value.trim().split();

      if (input.length === 0 || input[0] === '') {
        setTermValue(null);
        return;
      } else {
        setTermValue(input[0])
        setMessage(false);
      }
      // }
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      if (termValue !== ('' || undefined || null)) {
        onAddTag(termValue, index);
        setTermValue('');
        setMessage(false);
        setShowModal(!showModal);
      } else {
        setMessage(true)
      }
    }

    return (
      <div>
        {!showModal &&
          <TagList onDeleteTag={onDeleteTag} index={index} />
        }
        <Col md="12">
          <Input
            onChange={(e) => onKeyUp(e)}
            type="textarea"
            required
            placeholder={placeholder}
            invalid={errorList[`data[${index}].terms`] ? true : false}
          />
          {message && <span className='text-danger'>Please enter the value</span>}
        </Col>
        <Button
          size="sm"
          color="primary"
          type="button"
          onClick={handleSubmit}
          style={{ marginTop: "10px", marginBottom: "-20px" }}
        >
          Submit
        </Button>
      </div>
    );
  };

  /**************** use effect to set form data from current attribute *********************/
  useEffect(() => {
    if (Object.keys(currentSpecs).length > 0 && specs_id) {
      setFormData({ ...currentSpecs });
      setNestedChecked(currentSpecs.data.length > 1);
    }
  }, [currentSpecs]);

  return (
    <>
      <div className="animated fadeIn">
        {loading ? (
          <Spinner />
        ) : (
          <Row>
            <Col xs="12" sm="12">
              <Card>
                <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
                  <CardBody>
                    <Row>
                      <Col sm="12" md="3" xl="2">
                        <FormGroup>
                          <strong>Specs Type :</strong>
                        </FormGroup>
                      </Col>
                      <Col sm="6" md="3" xl="1">
                        <FormGroup>
                          <input
                            type="radio"
                            name="type"
                            value="fitting_spec"
                            checked={formData.type === 'fitting_spec'}
                            onClick={onChange}
                          />{' '}
                          Fitting Specs
                        </FormGroup>
                      </Col>
                      <Col sm="6" md="3" xl="2">
                        <FormGroup>
                          <input
                            type="radio"
                            name="type"
                            value="technical_spec"
                            checked={formData.type === 'technical_spec'}
                            onClick={onChange}
                          />{' '}
                          Technical Specs
                        </FormGroup>
                      </Col>
                    </Row>
                    <>
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <input
                              onClick={handleNestedClick}
                              checked={formData?.nestedCheck}
                              type="checkbox"
                            />
                            Nested{' '}
                            {formData.type === 'technical_spec'
                              ? 'Technical'
                              : 'Fitting'}{' '}
                            Specs
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <input
                              onClick={handleSpecQuestionClick}
                              checked={formData?.spec_question}
                              type="checkbox"
                            />
                            Spec Question
                          </FormGroup>
                        </Col>
                      </Row>
                      <CardBody className="p-0">
                        <CardHeader className="border-0">
                          <strong className="mb-2">
                            {formData.type === 'technical_spec'
                              ? 'Technical'
                              : 'Fitting'}{' '}
                            Specs{' '}
                          </strong>
                          {formData?.nestedCheck && (
                            <Row>
                              <Col sm="12">
                                <FormGroup>
                                  <Label htmlFor="label">
                                    Label <span>*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    id="label"
                                    name="label"
                                    maxLength="50"
                                    value={formData.label}
                                    required
                                    onChange={(e) => onChange(e)}
                                    invalid={errorList.label ? true : false}
                                  />
                                  <Errors current_key="label" key="label" />
                                </FormGroup>
                              </Col>
                            </Row>
                          )}
                          {formData?.data?.map((x, i) => {
                            return (
                              <div key={i}>
                                <Row>
                                  <Col sm="4">
                                    <FormGroup>
                                      <Label htmlFor="Name">
                                        Name <span>*</span>
                                      </Label>
                                      <input
                                        className="form-control"
                                        name="name"
                                        required
                                        placeholder="Enter fitting spec name"
                                        value={x.name}
                                        onChange={(e) =>
                                          handleFittingChange(e, i)
                                        }
                                        invalid={errorList[`data[${i}].name`]}
                                      />
                                      <Errors current_key={`data[${i}].name`} />
                                    </FormGroup>
                                  </Col>
                                  <Col sm="8">
                                    <div >
                                      <Label htmlFor="terms" >
                                        Value <span>*</span>
                                      </Label>
                                      <div >
                                        <Button onClick={handleChangeValues} data-id={i} style={{ marginBottom: "5px" }}>
                                          Add Value
                                        </Button>
                                      </div>
                                    </div>
                                    <TagList onDeleteTag={onDeleteTag} index={i} />
                                    <Errors
                                      current_key={`data[${i}].terms`}
                                      key={`data[${i}].terms`}
                                    />
                                  </Col>
                                  {/* <Col sm="8">
                                    <FormGroup>
                                      <Label htmlFor="terms">
                                        Values <span>*</span>
                                      </Label>
                                      {InputTag(
                                        onAddTag,
                                        onDeleteTag,
                                        i,
                                        'Please press enter button after desired value.'
                                      )}
                                      <Errors
                                        current_key={`data[${i}].terms`}
                                        key={`data[${i}].terms`}
                                      />
                                    </FormGroup>
                                  </Col> */}
                                  {i != 0 && (
                                    <Col sm="3" style={{ marginBottom: 32 }}>
                                      <Button
                                        type="reset"
                                        size="sm"
                                        color="danger"
                                        onClick={() =>
                                          handleFittingRemoveClick(i)
                                        }
                                      >
                                        <i className="fa fa-ban"></i> Remove
                                      </Button>
                                    </Col>
                                  )}
                                </Row>
                              </div>
                            );
                          })}
                          {formData.nestedCheck && (
                            <Row>
                              <Col sm="6">
                                <FormGroup>
                                  <br />
                                  <Button
                                    type="reset"
                                    size="sm"
                                    color="primary"
                                    onClick={handleFittingAddClick}
                                  >
                                    <i className="fa fa-dot-circle-o"></i> Add
                                    More..
                                  </Button>
                                </FormGroup>
                              </Col>
                            </Row>
                          )}
                        </CardHeader>
                      </CardBody>
                    </>
                  </CardBody>

                  <CardFooter>
                    <Button
                      type="submit"
                      size="sm"
                      color="primary"
                      disabled={loading}
                    >
                      <i className="fa fa-dot-circle-o"></i> Submit
                    </Button>
                    <Link to="/admin/specs">
                      <Button type="reset" size="sm" color="danger">
                        <i className="fa fa-ban"></i> Cancel
                      </Button>
                    </Link>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Row>
        )}
      </div>

      {/*###########################  modal for add specs   ####################################*/}
      <Modal isOpen={showModal}>
        <Form className="form-horizontal">
          <ModalHeader toggle={handleChangeValues}>Add Value</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="terms">
                    Value <span>*</span>
                  </Label>
                  {InputTag(
                    onAddTag,
                    onDeleteTag,
                    index,
                    'Please enter value.',
                    showModal,
                  )}
                  <Errors
                    current_key={`data[${index}].terms`}
                    key={`data[${index}].terms`}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
        </Form>
      </Modal>
    </>
  );
};

EditSpecs.propTypes = {
  edit: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
  specs: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getSpecsById: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  specs: state.specs,
  errorList: state.errors,
  loading: state.attribute.loading,
});

export default connect(mapStateToProps, {
  edit,
  add,
  getSpecsById,
})(EditSpecs);
