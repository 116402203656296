import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CKEditor } from 'ckeditor4-react';
import slugify from 'react-slugify';
import { Link } from 'react-router-dom';

import {
  getCategories,
  edit,
  create,
  setWholesaleError,
  setSaleError,
} from 'actions/admin/GearProduct/gearProduct';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';
import { ATTRIBUTE_WEIGHT_UNIT, CKEditorConfig, SHIPPING_BOX_SIZE_UNIT } from 'constants/index';

const GearBasicInfo = ({
  product_id,
  gearCurrentProduct,
  getCategories,
  category_loading,
  errorList,
  categorySubCategoryList,
  edit,
  create,
  setWholesaleError,
  setSaleError,
  changeTab,
  setProductId,
  loading,
}) => {
  //########################## set the initial form data ##############################

  const [productBasicDetail, setProductBasicDetail] = useState({
    product_id: product_id ? product_id : '',
    product_title: product_id ? gearCurrentProduct.product_title : '',
    meterial: product_id ? gearCurrentProduct.meterial : '',
    sub_title: product_id ? gearCurrentProduct.sub_title : '',
    sub_heading: product_id ? gearCurrentProduct.sub_heading : '',
    slug: product_id ? gearCurrentProduct.slug : '',
    categoryId: product_id ? gearCurrentProduct.categoryId : '',
    // subCategoryId: product_id ? gearCurrentProduct.subCategoryId : '',
    wholesale_price: product_id ? gearCurrentProduct.wholesale_price : '',
    sale_price: product_id ? gearCurrentProduct.sale_price : '',
    tech_design: product_id ? gearCurrentProduct.tech_design : '',
    sort_description: product_id ? gearCurrentProduct.sort_description : '',
    status: product_id ? gearCurrentProduct?.status : 2,
  });

  const {
    product_title = '',
    meterial = '',
    sub_title = '',
    sub_heading = '',
    slug = '',
    categoryId = '',
    //subCategoryId = '',
    tech_design = '',
    status = 2
  } = productBasicDetail;

  //###################### get the category and sub category list ########################
  const [categoryList, setCategoryList] = useState(null);
  const [subCategoryList, setSubCategoryList] = useState(null);

  const changeSubCategory = (subCategory) => {
    let tempSubCategoryList = [];
    subCategory.forEach((catData, index) => {
      tempSubCategoryList[index] = {
        id: catData._id,
        name: catData.name,
      };
    });
    setSubCategoryList(tempSubCategoryList);
  };

  useMemo(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    if (Object.keys(categorySubCategoryList).length > 0) {
      let tempCategoryList = [];
      categorySubCategoryList.forEach((catData, index) => {
        tempCategoryList[index] = {
          id: catData._id,
          name: catData.name,
        };
        //if (catData._id === categoryId) changeSubCategory(catData.childrens);
      });
      setCategoryList(tempCategoryList);
    }
  }, [categorySubCategoryList, categoryId]);

  //########### change form data and sub category if category changed ########################
  const onChange = (e, dataName = '') => {
    let updateFormData = {};
    if (e.target.name !== "status") {
      updateFormData = { [e.target.name]: e.target.value };
      if (e.target.name === 'product_title' && !product_id) {
        let new_slug = slugify(e.target.value);
        updateFormData = { ...updateFormData, slug: new_slug };
      }
    } else {
      updateFormData = { [e.target.name]: e.target.checked ? 4 : 2 };
    }
    setProductBasicDetail({
      ...productBasicDetail,
      ...updateFormData,
    });
  };

  const handelTechDesignChange = (event) => {
    setProductBasicDetail((ev) => ({
      ...ev,
      tech_design: event.editor.getData(),
    }));
  };

  const handelSortDescriptionChange = (event) => {
    setProductBasicDetail((ev) => ({
      ...ev,
      sort_description: event.editor.getData(),
    }));
  };

  //########### handel submit form  ########################
  const onSubmit = (e) => {
    e.preventDefault();
    if (!product_id) {
      create(productBasicDetail).then((res) => {
        if (res.status === true) {
          setProductId(res.id);
          changeTab('attributes_tab');
        }
      });
    } else {
      edit(productBasicDetail, product_id).then((res) => {
        if (res === true) changeTab('attributes_tab');
      });
    }
  };

  return loading ? (
    <Spinner />
  ) : (

    <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
      <Row>
        <Col xs="12" sm="12">
          <Card>
            <CardBody>
              <Row>
                <Col xs="12" sm="12">
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="product_title">
                          Product Name <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="product_title"
                          name="product_title"
                          placeholder="Enter product name"
                          maxLength="100"
                          minLength="3"
                          value={productBasicDetail.product_title}
                          // required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.product_title && product_title.length < 3 ? true : false}
                        />
                        {errorList.product_title && product_title.length < 3 ?
                          <Errors
                            current_key="product_title"
                            key="product_title"
                          /> : null}
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="slug">
                          Product Slug <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="slug"
                          name="slug"
                          maxLength="100"
                          value={productBasicDetail.slug}
                          onChange={(e) => onChange(e)}
                          readOnly
                          invalid={errorList.slug ? true : false}
                          disabled
                        />
                        <Errors current_key="slug" key="slug" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="categoryId">
                          Category <span>*</span>
                        </Label>
                        <Input
                          type="select"
                          name="categoryId"
                          id="categoryId"
                          value={productBasicDetail.categoryId}
                          onChange={(e) => onChange(e)}
                          // required
                          invalid={errorList.categoryId && categoryId.length === 0 ? true : false}
                        >
                          <option value={''}> {'Select'} </option>
                          {categoryList
                            ? categoryList.map((value, index) => {
                              return (
                                <option key={value.name} value={value.id}>
                                  {value.name}
                                </option>
                              );
                            })
                            : ''}
                        </Input>
                        {errorList.categoryId && categoryId.length === 0 ?
                          <Errors current_key="categoryId" key="categoryId" /> : null}
                      </FormGroup>
                    </Col>

                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="meterial">
                          Materials <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="meterial"
                          name="meterial"
                          placeholder="Enter Material"
                          maxLength="200"
                          minLength="3"
                          value={productBasicDetail.meterial}
                          // required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.meterial && meterial.length < 3 ? true : false}
                        />
                        {errorList.meterial && meterial.length < 3 ? <Errors current_key="meterial" key="meterial" /> : null}
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="sub_title">
                          Sub Title <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="sub_title"
                          name="sub_title"
                          placeholder="Enter sub title"
                          maxLength="150"
                          minLength="3"
                          value={productBasicDetail.sub_title}
                          // required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.sub_title && sub_title.length < 3 ? true : false}
                        />
                        {errorList.sub_title && sub_title.length < 3 ? <Errors current_key="sub_title" key="sub_title" /> : null}
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="sub_heading">
                          Sub Heading <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="sub_heading"
                          name="sub_heading"
                          maxLength="150"
                          placeholder="Enter sub heading"
                          minLength="3"
                          value={productBasicDetail.sub_heading}
                          // required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.sub_heading && sub_heading.length < 3 ? true : false}
                        />
                        {errorList.sub_heading && sub_heading.length < 3 ? <Errors current_key="sub_heading" key="sub_heading" /> : null}
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="status">
                          Internal Product
                        </Label>
                        <Input
                          type="checkbox"
                          id="status "
                          name="status"
                          checked={status === 4 ? true : false}
                          // required
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xs="12" sm="12">
          <Card>
            <CardHeader>
              <h5>Description</h5>
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs="12" sm="6">
                  <FormGroup>
                    <Label htmlFor="sort_description">
                      <strong>Short Description</strong>
                    </Label>
                    <CKEditor
                      editor={productBasicDetail.sort_description}
                      initData={productBasicDetail.sort_description}
                      placeholder="Enter Short Description."
                      id="sort_description"
                      name="sort_description"
                      config={CKEditorConfig}
                      // onChange={(event) =>
                      //   onChange(event, 'sort_description')
                      // }

                      onChange={(event) => handelSortDescriptionChange(event)}
                      onBeforeLoad={(CKEDITOR) =>
                        (CKEDITOR.disableAutoInline = true)
                      }
                      invalid={errorList.sort_description ? true : false}
                    />
                    <Errors
                      current_key="sort_description"
                      key="sort_description"
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="6">
                  <FormGroup>
                    <Label htmlFor="tech_design">
                      <strong>Description</strong>
                    </Label>
                    <CKEditor
                      editor={productBasicDetail.tech_design}
                      initData={productBasicDetail.tech_design}
                      placeholder="Enter Technology/Design."
                      id="tech_design"
                      name="tech_design"
                      config={CKEditorConfig}
                      // onChange={(event) => onChange(event, 'tech_design')}
                      onChange={(event) => handelTechDesignChange(event)}
                      onBeforeLoad={(CKEDITOR) =>
                        (CKEDITOR.disableAutoInline = true)
                      }
                      invalid={errorList.tech_design ? true : false}
                    />
                    <Errors current_key="tech_design" key="tech_design" />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xs="12" className="Justify-flex-end">
          <FormGroup key={2}>
            <Button
              className="mr-05"
              type="submit"
              size="sm"
              color="primary"
              disabled={loading}
            >
              <i className="fa fa-dot-circle-o"></i> Save & Next
            </Button>
            <Link to="/admin/gear-products">
              <Button type="reset" size="sm" color="danger">
                <i className="fa fa-ban"></i> Cancel
              </Button>
            </Link>
          </FormGroup>
        </Col>
      </Row>
    </Form>

  );
};

GearBasicInfo.defaultProps = {
  product_id: null,
};

GearBasicInfo.propTypes = {
  category_loading: PropTypes.bool.isRequired,
  gearCurrentProduct: PropTypes.object.isRequired,
  getCategories: PropTypes.func.isRequired,
  errorList: PropTypes.array.isRequired,
  categorySubCategoryList: PropTypes.array.isRequired,
  edit: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  category_loading: state.gearProduct.category_loading,
  gearCurrentProduct: state.gearProduct.gearCurrentProduct,
  errorList: state.errors,
  categorySubCategoryList: state.gearProduct.gearCategorySubCategoryList,
  loading: state.gearProduct.loading,
});

export default connect(mapStateToProps, {
  getCategories,
  edit,
  create,
})(GearBasicInfo);
