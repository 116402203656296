import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Spinner from 'views/Spinner';
import { Link, useNavigate } from 'react-router-dom';

import { upload } from 'actions/admin/videoGallery';
import Errors from 'views/Notifications/Errors';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { REMOVE_ERRORS } from 'actions/types';

const UploadVideo = ({ upload, history, errorList, loading }) => {
  //**************** use state for form data *********************
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    // description: '',
    image: '',
    selectedFile: '',
    video: '',
  });

  //**************** expload form data *********************
  const {
    title,
    description,
    image,
    // selectedFile, // video file
    video, // object url of video (video src)
  } = formData;

  //**************** dispatch to remove error *********************

  //**************** on Change event *********************
  const onChange = (e) => {
    if (e.target.name === 'image') {
      let image_file = e.target.files[0];

      let setFileUrl = URL.createObjectURL(image_file);
      const reader = new FileReader();
      reader.readAsDataURL(image_file);
      reader.onload = () => {
        // Use a regex to remove data url part
        const base64String = reader.result;
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
          selectedImgFile: base64String,
          image: setFileUrl,
        });
      };
      document.getElementById(`image`).value = '';
    } else if (e.target.name === 'thumbnail') {
      let video_file = e.target.files[0];

      let video_url = URL.createObjectURL(video_file);

      const reader = new FileReader();
      reader.readAsDataURL(video_file);
      reader.onload = () => {
        // Use a regex to remove data url part
        const base64String = reader.result;
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
          selectedFile: base64String,
          video: video_url,
        });
      };
      document.getElementById(`thumbnail`).value = '';
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value.trimLeft() });
    }
    //patch({ type: REMOVE_ERRORS });
  };
  //**************** on submit event *********************
  const onSubmit = (e) => {
    e.preventDefault();
    upload(formData, navigate);
  };
  
  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <Form
                className="form-horizontal"
                onSubmit={(e) => onSubmit(e)}
                encType="multipart/form-data"
              >
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="title">
                          Title <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="title"
                          name="title"
                          maxLength="100"
                          value={title}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.title ? true : false}
                        />
                        <Errors current_key="title" key="title" />
                      </FormGroup>
                    </Col>
                    {/* <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="description">
                          Description <span>*</span>
                        </Label>
                        <Input
                          type="textarea"
                          id="description"
                          name="description"
                          value={description}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.description ? true : false}
                        />
                        <Errors current_key="description" key="description" />
                      </FormGroup>
                    </Col> */}
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="thumbnail">
                          Image/thumbnail (Image ratio should be 16:9 for perfect fit)<span>*</span>
                        </Label>
                        <Input
                          type="file"
                          accept="image/*"
                          id="image"
                          name="image"
                          onChange={e => onChange(e)}
                          invalid={errorList.image ? true : false}
                        />
                        {image && <img
                          src={image}
                          className="preview-img" alt="" />
                        }
                        <Errors
                          current_key="image"
                          key="image"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="thumbnail">
                          Video <span>*</span>
                        </Label>
                        <Input
                          type="file"
                          accept="video/*"
                          id="thumbnail"
                          name="thumbnail"
                          onChange={(e) => onChange(e)}
                          multiple
                          invalid={errorList.video ? true : false}
                        />
                        {video && <video
                          controls={true}
                          src={video}
                          className="preview-video"
                          alt=""
                        />
                        }
                        <Errors current_key="video" key="video" />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary">
                    {/* {loading?<Spinner/>: */}
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Link to="/admin/gallery-video">
                    <Button type="reset" size="sm" color="danger">
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

UploadVideo.propTypes = {
  upload: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.videoGallery.loading,
});

export default connect(mapStateToProps, { upload })(UploadVideo);
