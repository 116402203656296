import axios from 'axios';

import {
  EMAIL_TEMPLATE_LOADING,
  EMAIL_TEMPLATE_CREATED,
  EMIAL_TEMPLATE_CREATE_ERROR,
  EMAIL_TEMPLATE_FAIL,
  EMAIL_TEMPLATE_UPDATED,
  EMIAL_TEMPLATE_UPDATE_ERROR,
  EMAIL_TEMPLATE_DELETED,
  EMAIL_TEMPLATE_DELETE_ERROR,
  EMAIL_TEMPLATE_LIST_UPDATED,
  GET_EMAIL_TEMPLATE_BY_ID,
  EMAIL_TEMPLATE_CHANGE_STATUS,
  EMAIL_TEMPLATE_CHANGE_STATUS_ERROR,
  ANNOUNCEMENT_SENDING,
  ANNOUNCEMENT_SENT,
  ANNOUNCEMENT_SENDING_FAIL,
  ANNOUNCEMENT_LIST_LOADING,
  ANNOUNCEMENT_LIST_UPDATED,
  ANNOUNCEMENT_LIST_FAIL,
  ANNOUNCEMENT_DELETED,
  ANNOUNCEMENT_DELETE_ERROR
} from 'actions/types';

// Create Email Template
export const create = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: EMAIL_TEMPLATE_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      '/api/admin/emailTemplate/create',
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: EMAIL_TEMPLATE_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/email-templates');
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: EMIAL_TEMPLATE_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: EMAIL_TEMPLATE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit Email Template
export const edit =
  (formData, history, emailTemplate_id) => async (dispatch) => {
    try {
      dispatch({
        type: EMAIL_TEMPLATE_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/emailTemplate/${emailTemplate_id}`,
        formData,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: EMAIL_TEMPLATE_UPDATED,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
        history('/admin/email-templates');
      } else {
        const errors = res.data.errors;
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }
        dispatch({
          type: EMIAL_TEMPLATE_UPDATE_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
            errorsList,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: EMAIL_TEMPLATE_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

// Delete Email Template
export const deleteEmailTemplate =
  (emailTemplate_id, history) => async (dispatch) => {
    try {
      dispatch({
        type: EMAIL_TEMPLATE_LOADING,
        payload: { is_loading: true, is_sub_element: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.delete(
        `/api/admin/emailTemplate/${emailTemplate_id}`,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: EMAIL_TEMPLATE_DELETED,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
        return true;
      } else {
        dispatch({
          type: EMAIL_TEMPLATE_DELETE_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
          },
        });
        return false;
      }
    } catch (err) {
      dispatch({
        type: EMAIL_TEMPLATE_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

//get Email Template list

export const getEmailTemplateList =
  (emailTemplateParams) => async (dispatch) => {
    try {
      dispatch({
        type: EMAIL_TEMPLATE_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const query = emailTemplateParams.query
        ? emailTemplateParams.query
        : '&query=';

      const res = await axios.get(
        `/api/admin/emailTemplate?limit=${emailTemplateParams.limit}&page=${emailTemplateParams.page}&orderBy=${emailTemplateParams.orderBy}&ascending=${emailTemplateParams.ascending}${query}`,
        config
      );

      dispatch({
        type: EMAIL_TEMPLATE_LIST_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: {
            emailTemplateParams: emailTemplateParams,
            emailTemplateList: res.data.response[0],
          },
        },
      });
    } catch (err) {
      dispatch({
        type: EMAIL_TEMPLATE_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

// Get email template by id
export const getEmailTemplateById = (emailTemplate_id) => async (dispatch) => {
  try {
    dispatch({
      type: EMAIL_TEMPLATE_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/emailTemplate/${emailTemplate_id}`,
      config
    );
    await dispatch({
      type: GET_EMAIL_TEMPLATE_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: EMAIL_TEMPLATE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//change status
export const changeStatus = (emailTemplate_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: EMAIL_TEMPLATE_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/emailTemplate/change-status/${emailTemplate_id}`,
      { status },
      config
    );

    if (res.data.status === true) {
      await dispatch({
        type: EMAIL_TEMPLATE_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: EMAIL_TEMPLATE_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: emailTemplate_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: EMAIL_TEMPLATE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

export const getEmailListFromCategory =
  (emailTemplateParams) => async (dispatch) => {
    try {
      dispatch({
        type: EMAIL_TEMPLATE_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: emailTemplateParams,
      };

      const res = await axios.get(`/api/admin/emailTemplate/emails`, config);

      dispatch({
        type: EMAIL_TEMPLATE_LIST_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: {
            emailTemplateParams: emailTemplateParams,
            emailTemplateList: res.data.response[0],
          },
        },
      });
      return res;
    } catch (err) {
      dispatch({
        type: EMAIL_TEMPLATE_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

export const createEmailTemplate =
  (formData, history, emailTemplateCategory_id, sectionId) =>
    async (dispatch) => {
      try {
        dispatch({
          type: EMAIL_TEMPLATE_LOADING,
          payload: { is_loading: true, is_sub_element: false },
        });
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const res = await axios.post(
          '/api/admin/emailTemplate/create',
          { ...formData, emailTemplateCategory_id },
          config
        );
        if (res.data.status === true) {
          dispatch({
            type: EMAIL_TEMPLATE_CREATED,
            payload: {
              message: res.data.message,
              alertType: 'success',
              data: res.data.response,
            },
          });
          history(`/admin/${sectionId}/${emailTemplateCategory_id}/emails`);
        } else {
          const errors = res.data.errors;
          let errorsList = [];
          if (errors) {
            await errors.forEach((error) => {
              errorsList[error.param] = error.msg;
            });
          }
          dispatch({
            type: EMIAL_TEMPLATE_CREATE_ERROR,
            payload: {
              message: res.data.message,
              alertType: 'danger',
              errorsList,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: EMAIL_TEMPLATE_FAIL,
          payload: {
            message:
              err.response && err.response.statusText
                ? err.response.statusText
                : 'Something went wrong.',
            alertType: 'danger',
            error: err,
          },
        });
      }
    };

export const sendAnnouncement = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: ANNOUNCEMENT_SENDING });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      '/api/admin/emailTemplate/send-announcement',
      formData,
      config
    );
    if (res.data.status) {
      dispatch({
        type: ANNOUNCEMENT_SENT, payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/announcements-list');
      return true
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: ANNOUNCEMENT_SENDING_FAIL,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      })
      return false;
    }
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENT_SENDING_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
}

export const getAnnouncementsList =
  (announcementParams) => async (dispatch) => {
    try {
      dispatch({
        type: ANNOUNCEMENT_LIST_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: announcementParams,
      };

      const res = await axios.get(`/api/admin/emailTemplate/announcements-list`, config);

      dispatch({
        type: ANNOUNCEMENT_LIST_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: {
            announcementsList: res.data.response[0],
          },
        },
      });
      return res;
    } catch (err) {
      dispatch({
        type: ANNOUNCEMENT_LIST_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

export const deleteAnnouncement =
  (announcement_id) => async (dispatch) => {
    try {
      dispatch({
        type: ANNOUNCEMENT_LIST_LOADING,
        payload: { is_loading: true, is_sub_element: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.delete(
        `/api/admin/emailTemplate/announcement/${announcement_id}`,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: ANNOUNCEMENT_DELETED,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
        return true;
      } else {
        dispatch({
          type: ANNOUNCEMENT_DELETE_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
          },
        });
        return false;
      }
    } catch (err) {
      dispatch({
        type: ANNOUNCEMENT_LIST_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

export const editEmailTemplate =
  (formData, history, emailTemplateCategory_id, emailTemplate_id, sectionId) =>
    async (dispatch) => {
      try {
        dispatch({
          type: EMAIL_TEMPLATE_LOADING,
          payload: { is_loading: true, is_sub_element: false },
        });
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const res = await axios.post(
          `/api/admin/emailTemplate/${emailTemplate_id}`,
          formData,
          config
        );
        if (res.data.status === true) {
          dispatch({
            type: EMAIL_TEMPLATE_UPDATED,
            payload: {
              message: res.data.message,
              alertType: 'success',
              data: res.data.response,
            },
          });
          history(`/admin/${sectionId}/${emailTemplateCategory_id}/emails`);
        } else {
          const errors = res.data.errors;
          let errorsList = [];
          if (errors) {
            await errors.forEach((error) => {
              errorsList[error.param] = error.msg;
            });
          }
          dispatch({
            type: EMIAL_TEMPLATE_UPDATE_ERROR,
            payload: {
              message: res.data.message,
              alertType: 'danger',
              errorsList,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: EMAIL_TEMPLATE_FAIL,
          payload: {
            message:
              err.response && err.response.statusText
                ? err.response.statusText
                : 'Something went wrong.',
            alertType: 'danger',
            error: err,
          },
        });
      }
    };
