import { taxRateApi } from "actions/admin/taxRates";
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import { connect } from "react-redux";
import Spinner from "views/Spinner";
import { METHOD } from "../../../constants";

const TaxRates = ({
    taxRate,
    taxRateErrors,
    taxRateApi,
    loading
}) => {

    const [rate, setRate] = useState(0);

    //########### Handel Input Change  ########################
    const onInputChange = (e) => {
        setRate(e.target.value);
    }

    useEffect(() => {
        setRate(taxRate);
    }, [taxRate]);

    useEffect(() => {
        taxRateApi(METHOD.GET);
    }, []);

    //########### Handel Submit Form  ########################
    const onSubmit = (e) => {
        e.preventDefault();
        taxRateApi(METHOD.POST, { rate });
    };

    return <>
        {loading ? (
            <Spinner />
        ) :
            <Form onSubmit={(e) => onSubmit(e)}>
                <Row>
                    <Col md="4"  >
                        <FormGroup key={2}>
                            <Label>California Tax Rate</Label>
                            <InputGroup>
                                <Input
                                    type="number"
                                    min={0}
                                    id="cal-rates"
                                    name="rate"
                                    onChange={(e) => onInputChange(e)}
                                    value={rate}
                                    step="any"
                                    required
                                />
                                <InputGroupText>
                                    %
                                </InputGroupText>
                            </InputGroup>

                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="4" className="Justify-flex-end">
                        <FormGroup key={2}>
                            <Button className="mr-05" type="submit" size="sm" color="primary">
                                <i className="fa fa-dot-circle-o"></i> Save
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        }
    </>
}


TaxRates.propTypes = {
    taxRate: PropTypes.number.isRequired,
    taxRateApi: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
    loading: state.taxRate.loading,
    taxRate: state.taxRate.taxRate,
    taxRateErrors: state.taxRate.taxRateErrors,
});

export default connect(mapStateToProps, {
    taxRateApi
})(TaxRates);
