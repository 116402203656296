import axios from 'axios';

import {
  USER_LOADING,
  USER_CREATED,
  USER_CREATE_ERROR,
  USER_FAIL,
  USER_MODAL_LOADING,
  USER_DETAIL_PASSWORD_UPDATED,
  USER_DETAIL_PASSWORD_UPDATE_ERROR,
  USER_UPDATED,
  USER_UPDATE_ERROR,
  USER_DELETED,
  USER_DELETE_ERROR,
  USER_LIST_UPDATED,
  GET_USER_BY_ID,
  USER_CHANGE_STATUS,
  USER_CHANGE_STATUS_ERROR,
  USER_CHANGE_STATUS_BULK,
  USER_CHANGE_STATUS_BULK_ERROR,
  GET_USER_BY_ID_ERROR,
  SET_ERRORS_LIST,
} from 'actions/types';

import { updateFilterData } from 'actions/commonActions';
import setAuthToken from 'utils/setAuthToken';
// Create User
export const create = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.post('/api/admin/user/create', formData, config);
    if (res.data.status === true) {
      dispatch({
        type: USER_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/customers');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: USER_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: USER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit Password
export const editPassword = (formData, user_id) => async (dispatch) => {
  dispatch({
    type: USER_MODAL_LOADING,
    payload: true,
  });
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.post(
      `/api/admin/user/edit/password/${user_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: USER_DETAIL_PASSWORD_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: USER_DETAIL_PASSWORD_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: USER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit User
export const edit = (formData, navigate, user_id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.post(
      `/api/admin/user/${user_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: USER_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      navigate('/admin/customers');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: USER_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
    }
  } catch (err) {
    dispatch({
      type: USER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete User
export const deleteUser = (user_id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(`/api/admin/user/${user_id}`, config);
    if (res.data.status === true) {
      await dispatch({
        type: USER_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: USER_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: USER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get users list

export const getUsersList = (userParams, history) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    userParams = updateFilterData(userParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: userParams,
    };

    const res = await axios.get(`/api/admin/user/`, config);
    if (!res.data.status && res.data.message === 'Token is not valid.') {
      console.log('users list false', res?.data);
      setAuthToken('');
      history('/');
      return res;
    }
    dispatch({
      type: USER_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          userParams: userParams,
          userList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    console.log('users list err', err);
    dispatch({
      type: USER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// get User by id
export const getUserById = (user_id, navigate) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/user/${user_id}`, config);
    if (res.data.status === true) {
      await dispatch({
        type: GET_USER_BY_ID,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors && errors.length > 0) {
        await errors?.forEach((error) => {
          errorsList = {...errorsList, [error?.param]: error?.msg};
        });
      }
      dispatch({
        type: GET_USER_BY_ID_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: {},
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: {
          ...errorsList,
        }
      })
      navigate('/admin/customers');
    }
  } catch (err) {
    dispatch({
      type: USER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    navigate('/admin/customers');
  }
};

//change status
export const changeStatus = (user_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/user/change-status/${user_id}`,
      { status },
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: USER_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: USER_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: user_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: USER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//change Bulk user status
export const userChangeStatusInBulk = (statusFormData) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/user/bulk-change-status`,
      { ...statusFormData },
      config
    );

    if (res.data.status === true) {
      await dispatch({
        type: USER_CHANGE_STATUS_BULK,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: USER_CHANGE_STATUS_BULK_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
    }
  } catch (err) {
    dispatch({
      type: USER_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
