import axios from 'axios';
import { setAlert } from 'actions/alert';
import { setErrorsList } from 'actions/errors';

import {
  IMAGE_GALLERY_UPLOADED,
  IMAGE_GALLERY_UPLOAD_ERROR,
  IMAGE_GALLERY_FAIL,
  IMAGE_GALLERY_DELETE,
  IMAGE_GALLERY_DELETE_ERROR,
  IMAGE_GALLERY_UPDATED,
  IMAGE_GALLERY_UPDATE_ERROR,
  IMAGE_GALLERY_LIST_UPDATED,
  IMAGE_GALLERY_GET_IMAGE_BY_ID,
  IMAGE_GALLERY_LOADING,
  // INITIAL_LOADING,
  // LOADING_ON_SUBMIT,
} from 'actions/types';

// Upload Immage
export const upload = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: IMAGE_GALLERY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    let param = new window.FormData();
    param.append('file', formData.selectedFile);
    param.append('title', formData.title);
    param.append('description', formData.description);
    param.append('image', formData.image);
    const res = await axios.post(
      '/api/admin/image-gallery/upload',
      param,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: IMAGE_GALLERY_UPLOADED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/gallery-image');
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: IMAGE_GALLERY_UPLOAD_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: IMAGE_GALLERY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit Image data
export const edit = (formData, history, image_id) => async (dispatch) => {
  try {
    dispatch({
      type: IMAGE_GALLERY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    };
    let param = new window.FormData();
    param.append('file', formData.selectedFile);
    param.append('title', formData.title);
    param.append('description', formData.description);
    param.append('oldImage', formData.oldImage);
    param.append('image', formData.image);
    param.append('isNewFileSelected', formData.isNewFileSelected);
    const res = await axios.post(
      `/api/admin/image-gallery/${image_id}`,
      param,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: IMAGE_GALLERY_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/gallery-image');
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: IMAGE_GALLERY_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: IMAGE_GALLERY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete Image
export const deleteImage = (image_id) => async (dispatch) => {
  try {
    dispatch({
      type: IMAGE_GALLERY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'content-type': 'application/json;',
      },
    };
    const res = await axios.delete(
      `/api/admin/image-gallery/${image_id}`,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: IMAGE_GALLERY_DELETE,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: IMAGE_GALLERY_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: IMAGE_GALLERY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get image list

export const getImageList = (imageParams) => async (dispatch) => {
  try {
    dispatch({
      type: IMAGE_GALLERY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'content-type': 'application/json;',
      },
    };
    
    
    //   `/api/admin/image-gallery?limit=${imageParams.limit}&page=${imageParams.page}&query=${query}&orderBy=${imageParams.orderBy}&ascending=${imageParams.ascending}`,
    //   config
    // );
    const res = await axios.get(
      `/api/admin/image-gallery?&orderBy=${imageParams.orderBy}&ascending=${imageParams.ascending}`,
      config
    );
    dispatch({
      type: IMAGE_GALLERY_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          imageParams: imageParams,
          imageList: res.data.response[0],
        },
      },
    });
  } catch (err) {
    
    dispatch({
      type: IMAGE_GALLERY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Get image by id
export const getImageById = (image_id) => async (dispatch) => {
  try {
    dispatch({
      type: IMAGE_GALLERY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'content-type': 'application/json;',
      },
    };
    const res = await axios.get(`/api/admin/image-gallery/${image_id}`, config);

    await dispatch({
      type: IMAGE_GALLERY_GET_IMAGE_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
    
  } catch (err) {
    dispatch({
      type: IMAGE_GALLERY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// page not found
export const notFound = (history) => async (dispatch) => {
  history.push('/admin/page-not-found');
};
