import {
  IronNumber_LOADING,
  IronNumber_CREATED,
  IronNumber_CREATE_ERROR,
  IronNumber_FAIL,
  IronNumber_UPDATED,
  IronNumber_UPDATE_ERROR,
  IronNumber_DELETED,
  IronNumber_DELETE_ERROR,
  IronNumber_LIST_UPDATED,
  GET_IronNumber_BY_ID,
} from "actions/types";

const initalState = {
  ironNumberList: {},
  currentIronNumber: {},
  loading: false,
  isSubLoading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case IronNumber_LOADING:
      return {
        ...state,
        ironNumberList: payload.is_sub_element ? state.ironNumberList : initalState.ironNumberList,
        currentIronNumber: payload.is_sub_element
          ? state.currentIronNumber
          : initalState.currentIronNumber,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case IronNumber_CREATED:
      return {
        ...state,
        loading: false,
      };
    case IronNumber_CREATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case IronNumber_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false
      };
    case IronNumber_UPDATED:
      return {
        ...state,
        currentIronNumber: {},
        loading: false,
      };
    case IronNumber_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case IronNumber_DELETED:
      return {
        ...state,
        loading: false,
      };
    case IronNumber_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case IronNumber_LIST_UPDATED:
      return {
        ...state,
        ironNumberList: payload?.data?.ironNumberList,
        loading: false,
        isSubLoading: false
      };
    case GET_IronNumber_BY_ID:
      return {
        ...state,
        currentIronNumber: payload.data,
        loading: false,
      };

    default:
      return state;
  }
}
