import { LOADING } from "actions/types";
const initalState = {
    loading: false,
}
export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOADING:
            return {
                ...state,
                loading: payload,
            }
        default:
            return state;
    }
}