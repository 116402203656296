import {
  USER_LOADING,
  USER_CREATED,
  USER_CREATE_ERROR,
  USER_FAIL,
  USER_MODAL_LOADING,
  USER_PASSWORD_UPDATED,
  USER_PASSWORD_UPDATE_ERROR,
  USER_UPDATED,
  USER_UPDATE_ERROR,
  USER_DELETED,
  USER_DELETE_ERROR,
  USER_LIST_UPDATED,
  GET_USER_BY_ID,
  USER_CHANGE_STATUS,
  USER_CHANGE_STATUS_ERROR,
  USER_CHANGE_STATUS_BULK,
  USER_CHANGE_STATUS_BULK_ERROR,
  GET_USER_BY_ID_ERROR,
} from "actions/types";

const initalState = {
  usersList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentUser: {},
  loading: false,
  modal_loading: false,
  isSubLoading: false,
  error: {},
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADING:
      return {
        ...state,
        usersList: payload.is_sub_element
          ? state.usersList
          : initalState.usersList,
        currentUser: payload.is_sub_element
          ? state.currentUser
          : initalState.currentUser,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case USER_CREATED:
      return {
        ...state,
        loading: false,
      };
    case USER_CREATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case USER_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case USER_MODAL_LOADING:
      return {
        ...state,
        modal_loading: payload,
      };
    case USER_PASSWORD_UPDATED:
      return {
        ...state,
        modal_loading: false,
      };
    case USER_PASSWORD_UPDATE_ERROR:
      return {
        ...state,
        modal_loading: false,
      };
    case USER_UPDATED:
      return {
        ...state,
        currentUser: {},
        loading: false,
      };
    case USER_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case USER_DELETED:
      return {
        ...state,
        loading: false,
      };
    case USER_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case USER_LIST_UPDATED:
      return {
        ...state,
        usersList: {
          data: payload.data.userList.data,
          page: payload.data.userList.metadata[0].current_page,
          count: payload.data.userList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_USER_BY_ID:
      return {
        ...state,
        currentUser: payload.data,
        loading: false,
      };
    case GET_USER_BY_ID_ERROR:
      return {
        ...state,
        currentUser: {},
        loading: false
      }
    case USER_CHANGE_STATUS:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          data: state.usersList.data.map((user) =>
            user._id === payload.data._id
              ? { ...user, status: payload.data.status }
              : user
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case USER_CHANGE_STATUS_ERROR:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          data: state.usersList.data.map((user) =>
            user._id === payload.data
              ? { ...user, is_failed: true }
              : { ...user, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case USER_CHANGE_STATUS_BULK:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          data: state.usersList.data.map((user) =>
            payload.data._id.includes(user._id)
              ? { ...user, status: parseInt(payload.data.status) }
              : user
          ),
        },
        loading: false,
      };
    case USER_CHANGE_STATUS_BULK_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
