import axios from "axios";

import {
  SETTINGS_LOADING,
  SETTING_UPDATED,
  SETTING_UPDATE_ERROR,
  SETTINGS_FAIL,
  GET_SETTING,
  GET_SETTING_ERROR,
} from "actions/types";

import { SETTINGS_KEY } from "constants/index";

// Edit Setting
export const edit = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: SETTINGS_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(`/api/admin/setting`, formData, config);
    if (res.data.status === true) {
      let settings = await res.data.response;
      let key_value = await [];
      await SETTINGS_KEY.map(async (set_key) => {
        key_value[set_key] = "";
      });
      await settings.forEach((val) => {
        key_value[val.key] = val.value;
      });
      dispatch({
        type: SETTING_UPDATED,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: key_value,
        },
      });

      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: SETTING_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
          errorsList,
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: SETTINGS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
    return false;
  }
};

// Get Setting
export const getScript = () => async (dispatch) => {
  try {
    dispatch({
      type: SETTINGS_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/admin/setting/`, config);
    if (res.data.status === true) {
      let settings = await res.data.response;
      let key_value = await [];
      await SETTINGS_KEY.map(async (set_key) => {
        key_value[set_key] = "";
      });
      await settings.forEach((val) => {
        key_value[val.key] = val.value;
      });
      dispatch({
        type: GET_SETTING,
        payload: {
          message: res.data.message,
          alertType: "success",
          data: key_value,
        },
      });

      return true;
    } else {
      dispatch({
        type: GET_SETTING_ERROR,
        payload: {
          message: res.data.message,
          alertType: "danger",
        },
      });
    }
  } catch (err) {
    dispatch({
      type: SETTINGS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : "Something went wrong.",
        alertType: "danger",
        error: err,
      },
    });
    return false;
  }
};
