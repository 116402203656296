import {
  PRODUCT_LOADING,
  INVENTORY_LOADING,
  PRODUCT_CREATED,
  PRODUCT_CREATE_ERROR,
  PRODUCT_FAIL,
  SET_CATEGORY_LOADER,
  CATEGORY_LIST_PRODUCT,
  SET_ATTRIBUTE_LOADER,
  SET_SPECS_LOADER,
  ATTRIBUTES_LIST_PRODUCT,
  PRODUCT_UPDATED,
  INVENTORY_UPLOADED,
  SPEC_ADD,
  PRODUCT_UPDATE_ERROR,
  PRODUCT_LIST_UPDATED,
  GET_PRODUCT_BY_ID,
  CHANGE_PRODUCT_STATUS,
  CHANGE_PRODUCT_STATUS_ERROR,
  DELETE_PRODUCT,
  DELETE_PRODUCT_ERROR,
  GET_PRODUCT_IMAGES,
  DELETE_PRODUCT_IMAGE,
  PRIMARY_IMAGE_UPDATED,
  WHOLESALE_PRICE_ERROR,
  SALE_PRICE_ERROR,
  UPDATE_PRODUCT_ATTRIBUTES,
  UPDATE_PRODUCT_ATTRIBUTES_ERROR,
  VARIATION_UPDATED,
  VARIATION_UPDATED_ERROR,
  RESET_CURRENT_PRODUCT,
  CHANGE_PRODUCT_STATUS_BULK,
  INVENTORY_UPLOADED_ERROR,
  INVENTORY_AND_SHIPPING_UPDATED,
  INVENTORY_AND_SHIPPING_UPDATED_ERROR,
  IMAGE_UPLOAD_ERROR,
  UPDATE_PRODUCT_IMAGES,
  UPDATE_PRODUCT_IMAGES_ERROR,
  PRODUCT_ATTRIBUTE_TAG_DELETE,
  PRODUCT_ATTRIBUTE_TAG_DELETE_ERROR,
  PRODUCT_ATTRIBUTE_OPTION_CREATED,
  PRODUCT_ATTRIBUTE_OPTION_CREATE_ERROR,
  SET_PRODUCT_ATTRIBUTE_OPTION_LOADER,
  ADMIN_PRODUCT_FAIL,
  ADMIN_SET_PRODUCT_ATTRIBUTE_LOADER,
  REVIEW_FAIL,
  REVIEW_UPDATED,
  UPDATE_PRODUCT_ATTRIBUTES_OPTIONS,
} from 'actions/types';

const initalState = {
  productsList: {
    page: 1,
    data: [],
    count: 0,
  },
  reviewList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentProduct: {},
  currentAttributes: {
    attributes: [],
    count: 0,
  },
  currentVariations: {
    variations: [],
    count: 0,
  },
  productImages: {
    images: [],
    count: 0,
  },
  productVideoUrl: '',
  loading: false,
  inventoryLoading: false,
  isSubLoading: false,
  categorySubCategoryList: [],
  category_loading: false,
  attributesValueList: [],
  attributes_loading: false,
  variations_loading: false,
  attributes_option_loading: false,
  currentAttributeOption: {},
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_LOADING:
      return {
        ...state,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case PRODUCT_CREATED:
    case GET_PRODUCT_BY_ID:
      return {
        ...state,
        currentProduct: payload.data,
        loading: false,
      };
    case REVIEW_FAIL:
    case PRODUCT_CREATE_ERROR:
    case ADMIN_PRODUCT_FAIL:
    case PRODUCT_UPDATE_ERROR:
    case INVENTORY_UPLOADED_ERROR:
    case WHOLESALE_PRICE_ERROR:
    case SALE_PRICE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ADMIN_SET_PRODUCT_ATTRIBUTE_LOADER:
      return {
        ...state,
        loading: payload,
      };
    case PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
        category_loading: false,
        attributes_loading: false,
        variations_loading: false,
        attributes_option_loading: false,
      };
    case SET_CATEGORY_LOADER:
      return {
        ...state,
        category_loading: payload,
      };
    case CATEGORY_LIST_PRODUCT:
      return {
        ...state,
        categorySubCategoryList: payload.data,
        category_loading: false,
      };

    case SET_ATTRIBUTE_LOADER:
      return {
        ...state,
        attributes_loading: payload,
      };
    case ATTRIBUTES_LIST_PRODUCT:
      return {
        ...state,
        attributesValueList: payload.data,
        attributes_loading: false,
      };
    // case REVIEW_UPDATED:
    //   return {
    //     ...state,
    //     reviewList: payload.data,
    //     review_loading: false,
    //   };
    case PRODUCT_UPDATED:
      return {
        ...state,
        currentProduct: { ...state.currentProduct, ...payload.data },
        loading: false,
      };
    case INVENTORY_LOADING:
      return {
        ...state,
        inventoryLoading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case INVENTORY_UPLOADED:
      return {
        ...state,
        currentProduct: { ...state.currentProduct, ...payload.data },
        inventoryLoading: false,
      };
    case SPEC_ADD:
      return {
        ...state,
        currentProduct: { ...state.currentProduct, ...payload.data },
        loading: false,
        isSubLoading: false,
      };
    case REVIEW_UPDATED:
      return {
        ...state,
        reviewList: {
          data: payload.data.data,
          page: payload.data.metadata[0].current_page,
          count: payload.data.metadata[0].totalRecord,
        },
        loading: false,
      };
    case PRODUCT_LIST_UPDATED:
      return {
        ...state,
        productsList: {
          data: payload.data.productList.data,
          page: payload.data.productList.metadata[0].current_page,
          count: payload.data.productList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case CHANGE_PRODUCT_STATUS:
      return {
        ...state,
        productsList: {
          ...state.productsList,
          data: state.productsList.data.map((product) =>
            product._id === payload.data._id
              ? { ...product, status: payload.data.status }
              : product
          ),
        },
        loading: false,
        isSubLoading: false,
      };

    case CHANGE_PRODUCT_STATUS_ERROR:
    case DELETE_PRODUCT:
    case DELETE_PRODUCT_ERROR:
    case VARIATION_UPDATED_ERROR:
    case INVENTORY_AND_SHIPPING_UPDATED_ERROR:
    case IMAGE_UPLOAD_ERROR:
    case UPDATE_PRODUCT_IMAGES_ERROR:
    case SPEC_ADD:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case PRIMARY_IMAGE_UPDATED:
    case GET_PRODUCT_IMAGES:
      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          productImages: {
            images: payload.data.count > 0 ? payload.data.data.images : [],
            count: payload.data.count,
          },
          productVideoUrl: payload.data.videoUrl,
        },

        loading: false,
        isSubLoading: false,
      };
    case DELETE_PRODUCT_IMAGE:
      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          productImages: {
            images: state.currentProduct.productImages.images.filter(
              (img) => img._id !== payload.data
            ),
            count: state.currentProduct.productImages.count - 1,
          },
        },

        loading: false,
        isSubLoading: false,
      };
    case UPDATE_PRODUCT_ATTRIBUTES:
      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          variations: payload.data.length > 0 ? payload.data : [],
        },
        attributes_loading: false,
        loading: false,
      };
    case UPDATE_PRODUCT_ATTRIBUTES_OPTIONS:
      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          attributes: payload.data.length > 0 ? payload.data : [],
        },
        attributes_loading: false,
        loading: false,
      };
    case UPDATE_PRODUCT_ATTRIBUTES_ERROR:
      return {
        ...state,
        attributes_loading: false,
        loading: false,
      };
    case VARIATION_UPDATED:
      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          variations: payload.data,
        },
        loading: false,
        isSubLoading: false,
      };
    case RESET_CURRENT_PRODUCT:
      return {
        ...state,
        currentProduct: {},
      };
    case CHANGE_PRODUCT_STATUS_BULK:
      return {
        ...state,
        productsList: {
          ...state.productsList,
          data: state.productsList.data.map((product) =>
            payload.data._id.includes(product._id)
              ? { ...product, status: parseInt(payload.data.status) }
              : product
          ),
        },
      };

    case INVENTORY_AND_SHIPPING_UPDATED:
      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          inventory_and_shipping: payload.data,
        },
        loading: false,
        isSubLoading: false,
      };
    case UPDATE_PRODUCT_IMAGES:
      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          productImages: {
            images: payload.data.newProductImagesData?.images,
            count: payload.data.newProductImagesData?.images.length,
          },
          productVideoUrl: payload.data.productUpdate.videoLink ?? '',
        },
        loading: false,
        isSubLoading: false,
      };
    case SET_PRODUCT_ATTRIBUTE_OPTION_LOADER:
      return {
        ...state,
        currentAttributeOption: payload.is_sub_element
          ? state.currentAttributeOption
          : initalState.currentAttributeOption,
        attributes_loading: payload.is_sub_element
          ? payload.is_sub_element
          : false,
        attributes_option_loading: payload.is_sub_element
          ? payload.is_sub_element
          : false,
      };
    case PRODUCT_ATTRIBUTE_TAG_DELETE:
    case PRODUCT_ATTRIBUTE_TAG_DELETE_ERROR:
    case PRODUCT_ATTRIBUTE_OPTION_CREATE_ERROR:
      return {
        ...state,
        attributes_option_loading: false,
        attributes_loading: false,
      };
    case PRODUCT_ATTRIBUTE_OPTION_CREATED:
      return {
        ...state,
        attributesValueList: {
          ...state.attributesValueList,
          attributeList: state.attributesValueList?.attributeList?.map(
            (attribute) =>
              attribute._id === payload.data.attribute_id
                ? {
                  ...attribute,
                  terms: [...attribute.terms, payload.data.option],
                }
                : attribute
          ),
        },
        currentAttributeOption: payload.data,
        attributes_option_loading: false,
        attributes_loading: false,
      };

    default:
      return state;
  }
}
