import { SHIPPING_ADDITIONALS_LOADING, SHIPPING_ADDITIONALS, SHIPPING_ADDITIONALS_FAILED } from "actions/types";

const initalState = {
    shippingAdditionals: {
        page: 1,
        data: [],
        count: 0,
    },
    loading: false,
};

export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
        case SHIPPING_ADDITIONALS_LOADING:
            return {
                ...state,
                loading: true
            }
        case SHIPPING_ADDITIONALS:
            return {
                ...state,
                shippingAdditionals: {
                    data: payload.data.shippingAdditionals.data,
                    page: payload.data.shippingAdditionals.metadata[0].current_page,
                    count: payload.data.shippingAdditionals.metadata[0].totalRecord,
                },
                loading: false,
            }
        case SHIPPING_ADDITIONALS_FAILED:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}