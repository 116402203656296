/* eslint-disable */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from '../../../../utils/Loading';
import CustomNoRowsOverlay from 'utils/CustomNoRowsOverlay';

import { Button, Card, CardBody, Col, Row, Input } from 'reactstrap';

import * as Constants from 'constants/index';

import {
  getFittingAttributeVariationList,
  deleteFittingAttributeVariation,
  changeStatus,
} from 'actions/admin/fittingVariation';

import AddEditFittingAttributeVariation from 'views/Admin/Fitting/Variation/AddEditFittingAttributeVariation';
import PerPageSelect from 'views/Admin/PerPageSelect';
import { RESET_FITTING_PART_DETAILS } from 'actions/types';

const renderVariationTitle = (params) => {
  if (params.data === undefined) {
    return <Loading />;
  } else {
    return <div>{params.data?.title}</div>;
  }
};

const renderStatus = (params, changeFittingStatus) => {
  return (
    <div>
      <Input
        type="select"
        name="status"
        id={params.data?._id}
        defaultValue={params.data?.status}
        onChange={(e, a) => {
          changeFittingStatus(params.data?._id, e.target.value);
        }}
      >
        <option value="0">Inactive</option>
        <option value="1">Active</option>
      </Input>
    </div>
  );
};

const renderActions = (
  params,
  openModal,
  deleteFittingAttributeVariation,
  updateFittingVariations
) => {
  return (
    <div>
      <Button
        type="button"
        size="sm"
        color="success"
        title="Edit"
        onClick={(e) => openModal(params.data._id)}
      >
        <i className="fa fa-pencil"></i>
      </Button>
      <Button
        type="button"
        size="sm"
        color="danger"
        title="Delete"
        onClick={(e) => {
          if (
            window.confirm(
              `Are you sure, you want to delete ${params.data?.title} fitting?`
            )
          ) {
            deleteFittingAttributeVariation(params.data?._id, history).then(
              (res) => {
                if (res) updateFittingVariations();
              }
            );
          }
        }}
      >
        <i className="fa fa-trash"></i>
      </Button>
    </div>
  );
};

const renderImage = (params) => {
  return !params.data?.image ? (
    <></>
  ) : (
    <img
      src={
        'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/fitting-variation/' +
        params.data?.image
      }
      className="preview-img"
      alt=""
    />
  );
};

const VariationList = ({
  getFittingAttributeVariationList,
  deleteFittingAttributeVariation,
  fittingAttributeVariationList: { data },
  fitting_part_detail,
  loading,
  changeStatus
}) => {
  const { fitting_name: fittingType, attribute_id: attribute_id } = useParams();
  //################## Initillise sorting searching parameters by default values ###################
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
        limit: Constants.DEFAULT_PAGE_SIZE,
        page: 1,
        orderBy: 'created_at',
        ascending: 'desc',
        query: '',
        filter: {},
        onLoad: true,
      }
  );

  //################## to get updated list ###################
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);

  const updateFittingVariations = () => {
    gridApi.purgeInfiniteCache();
  };

  const changeFittingVariationStatus = async (id, value) => {
    await changeStatus(id, value);
    gridApi.purgeInfiniteCache();
  };

  const types = ['grips', 'adaptors', 'playing length'];
  //################ toggle add modal ############################
  const [variationAddModal, setOpenVariationAddModal] = useState(false);
  const toggleVariationAddModal = () => {
    setOpenVariationAddModal(!variationAddModal);
  };

  //################ attribute id useState ############################
  const [variationId, setVariationId] = useState(null);

  //################ toogle edit modal ############################

  const [editModal, setOpenEditModal] = useState(false);
  const toggleEditModal = () => {
    if (editModal) setVariationId(() => null);
    setOpenEditModal(() => !editModal);
  };

  //################ change attributeId when the edit button is clicked ############################
  const openModal = (variation_id) => {
    setVariationId(variation_id);
  };

  //################ open popup as the attributeId is changed ############################

  useEffect(() => {
    if (variationId) toggleEditModal();
  }, [variationId]);


  useEffect(() => {
    if (fitting_part_detail !== null) {
      const fitting_part_data = fitting_part_detail?.fitting_part_detail;
      setVariationId(fitting_part_data?.options_id);
      dispatch({
        type: RESET_FITTING_PART_DETAILS,
        payload: true
      });
    }
  }, [])

  // to resolve useEffect dependency error

  //################ reload the list ############################
  const [isNewCreated, setIsNewCreated] = useState(false);
  useEffect(() => {
    if (isNewCreated) {
      updateFittingVariations();
      setIsNewCreated(false);
    }
  }, [isNewCreated]);

  //#########################Colums defination start ###################

  const columnTitle = {
    field: 'title',
    headerName: 'Title',
    suppressMovable: true,
    cellClass: 'suppress-movable-col',
    sortable: true,
    width: 230,
    colSpan: (params) => {
      if (params.data === undefined) {
        return 8;
      } else {
        return 0;
      }
    },
    filter: 'agTextColumnFilter',
    // pass in additional parameters to the text filter
    suppressSizeToFit: false,
    filterParams: {
      closeOnApply: true,
      filterOptions: Constants.TEXT_FILTER_OPTIONS,
      suppressAndOrCondition: true,
      debounceMs: 800,
    },
    cellRenderer: (params) => renderVariationTitle(params),
  };

  const columnAction = {
    field: '_id',
    headerName: 'Actions',
    suppressMovable: true,
    width: 130,
    cellClass: 'suppress-movable-col',
    cellRenderer: (params) =>
      renderActions(
        params,
        openModal,
        deleteFittingAttributeVariation,
        updateFittingVariations
      ),
  };

  const adaptors = [columnTitle, columnAction];

  const grips = [
    columnTitle,
    {
      field: 'inSensitivePartNumber',
      headerName: 'Part No.',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      width: 130,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => {
        return params.data?.part_no;
      },
    },
    {
      field: 'variation_weight',
      headerName: 'Weight',
      suppressMovable: true,
      width: 100,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agNumberColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => {
        return params.data?.variation_weight;
      },
    },
    {
      field: 'price',
      headerName: 'Price $',
      width: 100,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agNumberColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => {
        return params.data?.price;
      },
    },
    {
      field: 'inventory',
      headerName: 'Inventory',
      suppressMovable: true,
      width: 130,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agNumberColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'image',
      headerName: 'Image',
      suppressMovable: true,
      width: 140,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) => renderImage(params),
    },
    {
      field: 'status',
      headerName: 'Status',
      suppressMovable: true,
      width: 160,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) => renderStatus(params, changeFittingVariationStatus)
    },
    columnAction,
  ];

  const [tableFields, setTableFields] = useState([]);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  const noRowsOverlayComponent = useMemo(() => {
    return CustomNoRowsOverlay;
  }, []);

  const noRowsTemplate = useMemo(() => {
    return {
      noRowsMessageFunc: () => 'no rows to show',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };

          const customData = await getFittingAttributeVariationList(
            customParams,
            attribute_id
          );
          if (fittingType && types.includes(fittingType)) {
            if (fittingType === 'grips') {
              setTableFields(grips);
            } else {
              setTableFields(adaptors);
            }
          }
          if (customData?.data?.status) {
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi]);
  const buttonName = fittingType === 'grips' ? 'Grip Model' : 'Adaptor Setting';
  const actions = (
    <div className="add-button-div">
      <Button onClick={() => history(-2)} type="reset" size="sm" color="info">
        <i className="fa fa-arrow-left"></i> Back
      </Button>
      <Button color="primary" size="sm" onClick={toggleVariationAddModal}>
        <i className="fa fa-plus"></i> Add {buttonName}
      </Button>
      <AddEditFittingAttributeVariation
        isOpen={variationAddModal}
        toggle={toggleVariationAddModal}
        setIsNewCreated={setIsNewCreated}
        attributeId={attribute_id}
        fittingType={fittingType}
        update={updateFittingVariations}
      />
    </div>
  );

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current?.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });

  return (
    <>
      <div className="animated fadeIn userTableList">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="tabletopsec">
                  {actions}
                  <div className="rightpaneltop">
                    Variation Per Page
                    <PerPageSelect
                      perPage={sortingParams.limit}
                      onPaginationChange={onPaginationChange}
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body position-relative">
                        <div className="ag-theme-alpine">
                          <AgGridReact
                            defaultColDef={{
                              resizable: true,
                            }}
                            loadingCellRenderer={customLoadingCellRenderer}
                            loadingCellRendererParams={
                              loadingCellRendererParams
                            }
                            domLayout={'autoHeight'}
                            rowModelType={'infinite'}
                            columnDefs={tableFields}
                            pagination={true}
                            sizeColumnsToFit={true}
                            onFilterChanged={filterChanged}
                            onGridReady={onGridReady}
                            paginationPageSize={sortingParams.limit}
                            cacheBlockSize={sortingParams.limit}
                            cacheQuickFilter={true}
                            onFirstDataRendered={onFirstDataRendered}
                            onPaginationChanged={onPaginationChanged}
                            noRowsOverlayComponent={noRowsOverlayComponent}
                            noRowsOverlayComponentParams={noRowsTemplate}
                            ref={gridRef}
                          ></AgGridReact>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <AddEditFittingAttributeVariation
        isOpen={editModal}
        toggle={toggleEditModal}
        variationId={variationId}
        setIsNewCreated={setIsNewCreated}
        isEdit={true}
        attributeId={attribute_id}
        fittingType={fittingType}
        update={updateFittingVariations}
      />
    </>
  );
};

VariationList.propTypes = {
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
  getFittingAttributeVariationList: PropTypes.func.isRequired,
  deleteFittingAttributeVariation: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  fitting_part_detail: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.fittingVariation.loading,
  subLoading: state.fittingVariation.isSubLoading,
  fittingAttributeVariationList: state.fittingVariation.variationList,
  fitting_part_detail: state.fitting.fitting_part_detail,
});

export default connect(mapStateToProps, {
  getFittingAttributeVariationList,
  deleteFittingAttributeVariation,
  changeStatus,
})(VariationList);
