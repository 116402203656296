/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import convert from 'convert-units';
import { getProductSelectorById } from 'actions/admin/product';
import Spinner from 'views/Spinner';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  CardFooter,
  CardHeader,
  Tooltip,
  Label,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';

const ViewProductSelector = ({
  getProductSelectorById,
  loading,
  currentProduct
}) => {
  const { product_id: product_id } = useParams();
  const navigate = useNavigate();
  const [swingSpeed, setSwingSpeed] = useState(null);
  const [checkSwingSpeed, setCheckSwingSpeed] = useState(null);
  const [productDetail, setProductDetail] = useState(null);

  let tipCategory = ["tip", 'tip diameter'];
  let ironCategory = ["iron", 'iron #'];

  useEffect(() => {
    getProductSelectorById(product_id);
  }, [product_id]);

  useEffect(() => {
    if (Object.keys(currentProduct).length > 0 && product_id) {
      setProductDetail(currentProduct);
    }
  }, [currentProduct]);

  useEffect(() => {
    if (productDetail !== (undefined || null)) {
      if (productDetail?.answers?.firstMatch?.product?.category_details?.slug === 'iron') {
        if (productDetail?.questions?.metric) {
          const [lowerBound, upperBound] = productDetail.questions.swing_speed?.split("-").map(value => parseInt(value)); 
          setSwingSpeed(`${Math.trunc(convert(lowerBound).from('m/h').to('km/h'))} - ${Math.trunc(convert(upperBound).from('m/h').to('km/h'))} km/h`)
        } else {
          setSwingSpeed(productDetail.questions.swing_speed);
        }
        if (productDetail?.questions?.metricCarry) {
          const data = parseInt(productDetail.questions.check_swing_speed);
          setCheckSwingSpeed(Math.trunc(convert(data).from('yd').to('m')) + ' ' + 'Meter')
        } else {
          setCheckSwingSpeed(productDetail.questions.swing_speed);
        }
      } else {
        if (productDetail?.questions?.metric) {
          const data = parseInt(productDetail.questions.swing_speed);
          setSwingSpeed(Math.trunc(convert(data).from('m/h').to('km/h')) + ' ' + 'km/h')
        } else {
          setSwingSpeed(productDetail.questions.swing_speed);
        }
      }

    }
  }, [productDetail])
console.log(swingSpeed, ';;;;')
  return loading ||
    currentProduct === null || productDetail == null ? (
    <Spinner />
  ) : (
    (productDetail &&
      <div className="viewOrderDetailstab">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Button
                  onClick={() => navigate(-1)}
                  type="reset"
                  size="sm"
                  color="danger"
                >
                  <i className="fa fa-arrow-left"></i> Back
                </Button>
              </CardHeader>
            </Card>
          </Col>
          <Col md="12">
            <Card>
              <CardHeader className="label-tag-header">
                User Details
              </CardHeader>
              <CardBody >
                <p className="data-ptag">
                  <Label className="label-tag">Name: </Label>
                  {productDetail?.userDetail?.first_name + productDetail?.userDetail?.last_name}
                </p>
                <p className="data-ptag">
                  <Label className="label-tag">Email: </Label>
                  {productDetail?.userDetail?.email ? productDetail?.userDetail?.email : "Not Available"}
                </p>
                <p className="data-ptag">
                  <Label className="label-tag">Contact Number: </Label>
                  {productDetail?.userDetail?.phone ? productDetail?.userDetail?.phone : "Not Available"}
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col md="12">
            <Card>
              <CardHeader className="label-tag-header">
                Question List
              </CardHeader>
              <CardBody >
                <>
                  {(productDetail?.answers?.firstMatch.product?.category_details?.slug) == 'iron'
                    ?
                    (<Row className="col-bottom">
                      <Col md="12">
                        <Col>
                          <p className="data-ptag">
                            <Label className="label-tag">Set Configuration :</Label>
                            {productDetail?.questions?.config}
                          </p>
                        </Col>
                      </Col>
                      <Col md="12">
                        <Col>
                          <p className="data-ptag">
                            <Label className="label-tag">Average Driver Swing Speed :</Label>
                            {swingSpeed}
                          </p>
                        </Col>
                      </Col>
                      <Col md="12">
                        <Col>
                          <p className="data-ptag">
                            <Label className="label-tag">Carry Distance :</Label>
                            {checkSwingSpeed}
                          </p>
                        </Col>
                      </Col>
                      <Col md="12">
                        <Col>
                          <p className="data-ptag">
                            <Label className="label-tag">Current Driver Shaft weight :</Label>
                            {productDetail?.questions?.shaft_weight}
                          </p>
                        </Col>
                      </Col>
                      <Col md="12">
                        <Col>
                          <p className="data-ptag">
                            <Label className="label-tag">Current Iron Shaft weight :</Label>
                            {productDetail?.questions?.iron_shaft_weight}
                          </p>
                        </Col>
                      </Col>
                      <Col md="12">
                        <Col>
                          <p className="data-ptag">
                            <Label className="label-tag">Iron Attack Angle :</Label>
                            {productDetail?.questions?.attack_angle}
                          </p>
                        </Col>
                      </Col>
                      <Col md="12">
                        <Col>
                          <p className="data-ptag">
                            <Label className="label-tag">Preferred Ball Flight :</Label>
                            {productDetail?.questions?.ball_flight}
                          </p>
                        </Col>
                      </Col>
                    </Row>)
                    :
                    (<Row className="col-bottom">
                      <Col md="12">
                        <Col>
                          <p className="data-ptag">
                            <Label className="label-tag">Average Swing Speed :</Label>
                            {swingSpeed}
                          </p>
                        </Col>
                      </Col>
                      <Col md="12">
                        <Col>
                          <p className="data-ptag">
                            <Label className="label-tag">Desired Launch Angle :</Label>
                            {productDetail?.questions?.launch_angle}
                          </p>
                        </Col>
                      </Col>
                      <Col md="12">
                        <Col>
                          <p className="data-ptag">
                            <Label className="label-tag">Desired Spin :</Label>
                            {productDetail?.questions?.spin}
                          </p>
                        </Col>
                      </Col>
                      <Col md="12">
                        <Col>
                          <p className="data-ptag">
                            <Label className="label-tag">Desired Trajectory :</Label>
                            {productDetail?.questions?.trajectory}
                          </p>
                        </Col>
                      </Col>
                      <Col md="12">
                        <Col>
                          <p className="data-ptag">
                            <Label className="label-tag">Desired Shaft Weight :</Label>
                            {productDetail?.questions?.shaft_weight}
                          </p>
                        </Col>
                      </Col>
                      <Col md="12">
                        <Col>
                          <p className="data-ptag">
                            <Label className="label-tag">Current Driver Shaft Weight :</Label>
                            {productDetail?.questions?.current_Weight}
                          </p>
                        </Col>
                      </Col>
                      <Col md="12">
                        <Col>
                          <p className="data-ptag">
                            <Label className="label-tag">Desired Tempo fit :</Label>
                            {productDetail?.questions?.tempo}
                          </p>
                        </Col>
                      </Col>
                      <Col md="12">
                        <Col>
                          <p className="data-ptag">
                            <Label className="label-tag">Attack of Angle :</Label>
                            {productDetail?.questions?.attack_angle}
                          </p>
                        </Col>
                      </Col>
                    </Row>)
                  }
                </>
              </CardBody>
            </Card>
          </Col>
          <Col md="12">
            <Card>
              <CardHeader className="label-tag-header">
                Answer List
              </CardHeader>
              <CardBody>
                <Row className="col-bottom">
                  <Col md="5">
                    <Col>
                      <Label className="label-tag-header">
                        {productDetail?.answers?.firstMatch.product?.product_details?.product_title} ({productDetail?.answers?.firstMatch.product?.category_details?.name})
                      </Label>
                      <div>
                        <Label className="label-tag">SKU :</Label>
                        {productDetail?.answers?.firstMatch.product?.sku}
                      </div>
                      <div>
                        <Label className="label-tag">Product Price :</Label>
                        ${(productDetail?.answers?.firstMatch?.product?.msrp_price)?.toFixed(2)}
                      </div>
                      {(productDetail?.answers?.firstMatch.product?.category_details?.slug) == 'iron'
                        ? (<div>
                          <Label className="label-tag">Iron # :</Label>
                          {productDetail?.answers?.firstMatch.product?.variation?.map(item => ironCategory.includes(item.attribute_name.toLowerCase()) && item.option_term)}
                        </div>)
                        : ''}
                      <div>
                        <Label className="label-tag">Flex :</Label>
                        {productDetail?.answers?.firstMatch.product?.variation?.map(item => item.attribute_name.toLowerCase() == "flex" && item.option_term)}
                      </div>
                      <div>
                        <Label className="label-tag">Color :</Label>
                        {productDetail?.answers?.firstMatch.product?.variation?.map(item => item.attribute_name.toLowerCase() == "color" && item.option_term)}
                      </div>
                      {(productDetail?.answers?.firstMatch.product?.category_details?.slug) == 'iron'
                        ? (<div>
                          <Label className="label-tag">Tip Diameter :</Label>
                          {productDetail?.answers?.firstMatch.product?.variation?.map(item => tipCategory.includes(item.attribute_name.toLowerCase()) && item.option_term)}
                        </div>)
                        : ''}
                    </Col>
                  </Col>
                  <Col md="5">
                    <Col>
                      <Label className="label-tag-header">
                        Product Match Count
                      </Label>
                      {productDetail?.answers?.firstMatch?.product_match_count}%
                    </Col>
                  </Col>
                </Row>
                <Row className="col-bottom">
                  <Col md="5">
                    <Col>
                      <Label className="label-tag-header">
                        {productDetail?.answers?.secondMatch.product?.product_details?.product_title} ({productDetail?.answers?.secondMatch.product?.category_details?.name})
                      </Label>
                      <div>
                        <Label className="label-tag">SKU :</Label>
                        {productDetail?.answers?.secondMatch.product?.sku}
                      </div>
                      <div>
                        <Label className="label-tag">Product Price :</Label>
                        ${(productDetail?.answers?.secondMatch?.product?.msrp_price)?.toFixed(2)}
                      </div>
                      {(productDetail?.answers?.firstMatch.product?.category_details?.slug) == 'iron'
                        ? (<div>
                          <Label className="label-tag">Iron # :</Label>
                          {productDetail?.answers?.secondMatch.product?.variation?.map(item => ironCategory.includes(item.attribute_name.toLowerCase()) && item.option_term)}
                        </div>)
                        : ''}
                      <div>
                        <Label className="label-tag">Flex :</Label>
                        {productDetail?.answers?.secondMatch.product?.variation?.map(item => item.attribute_name.toLowerCase() == "flex" && item.option_term)}
                      </div>
                      <div>
                        <Label className="label-tag">Color :</Label>
                        {productDetail?.answers?.secondMatch.product?.variation?.map(item => item.attribute_name.toLowerCase() == "color" && item.option_term)}
                      </div>
                      {(productDetail?.answers?.firstMatch.product?.category_details?.slug) == 'iron'
                        ? (<div>
                          <Label className="label-tag">Tip Diameter :</Label>
                          {productDetail?.answers?.secondMatch.product?.variation?.map(item => tipCategory.includes(item.attribute_name.toLowerCase()) && item.option_term)}
                        </div>)
                        : ''}
                    </Col>
                  </Col>
                  <Col md="5">
                    <Col>
                      <Label className="label-tag-header">
                        Product Match Count
                      </Label>
                      {productDetail?.answers?.secondMatch?.product_match_count}%
                    </Col>
                  </Col>
                </Row>
                <Row className="col-bottom">
                  <Col md="5">
                    <Col>
                      <Label className="label-tag-header">
                        {productDetail?.answers?.thirdMatch.product?.product_details?.product_title} ({productDetail?.answers?.thirdMatch.product?.category_details?.name})
                      </Label>
                      <div>
                        <Label className="label-tag">SKU :</Label>
                        {productDetail?.answers?.thirdMatch.product?.sku}
                      </div>
                      <div>
                        <Label className="label-tag">Product Price :</Label>
                        ${(productDetail?.answers?.thirdMatch?.product?.msrp_price)?.toFixed(2)}
                      </div>
                      {(productDetail?.answers?.firstMatch.product?.category_details?.slug) == 'iron'
                        ? (<div>
                          <Label className="label-tag">Iron # :</Label>
                          {productDetail?.answers?.thirdMatch.product?.variation?.map(item => ironCategory.includes(item.attribute_name.toLowerCase()) && item.option_term)}
                        </div>)
                        : ''}
                      <div>
                        <Label className="label-tag">Flex :</Label>
                        {productDetail?.answers?.thirdMatch.product?.variation?.map(item => item.attribute_name.toLowerCase() == "flex" && item.option_term)}
                      </div>
                      <div>
                        <Label className="label-tag">Color :</Label>
                        {productDetail?.answers?.thirdMatch.product?.variation?.map(item => item.attribute_name.toLowerCase() == "color" && item.option_term)}
                      </div>
                      {(productDetail?.answers?.firstMatch.product?.category_details?.slug) == 'iron'
                        ? (<div>
                          <Label className="label-tag">Tip Diameter :</Label>
                          {productDetail?.answers?.thirdMatch.product?.variation?.map(item => tipCategory.includes(item.attribute_name.toLowerCase()) && item.option_term)}
                        </div>)
                        : ''}
                    </Col>
                  </Col>
                  <Col md="5">
                    <Col>
                      <Label className="label-tag-header">
                        Product Match Count
                      </Label>
                      {productDetail?.answers?.thirdMatch?.product_match_count}%
                    </Col>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button
                  onClick={() => navigate(-1)}
                  type="reset"
                  size="sm"
                  color="danger"
                >
                  <i className="fa fa-arrow-left"></i> Back
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    )
  )
};


ViewProductSelector.propTypes = {
  getProductSelectorById: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  currentProduct: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  currentProduct: state.product.currentProduct,
  loading: state.product.loading,
});

export default connect(mapStateToProps, {
  getProductSelectorById,
})(ViewProductSelector);