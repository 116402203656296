import axios from 'axios';

import {
  FlexCode_LOADING,
  FlexCode_CREATED,
  FlexCode_CREATE_ERROR,
  FlexCode_FAIL,
  FlexCode_UPDATED,
  FlexCode_UPDATE_ERROR,
  FlexCode_DELETED,
  FlexCode_DELETE_ERROR,
  FlexCode_LIST_UPDATED,
  GET_FlexCode_BY_ID,
  SET_ERRORS_LIST
} from 'actions/types';

import { updateFilterData } from 'actions/commonActions';

//get Flex Code list
export const getFlexCodeList = () => async (dispatch) => {
  try {
    dispatch({
      type: FlexCode_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    // newsParams = updateFilterData(newsParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      // params: newsParams,
    };
    // const query = newsParams.query ? newsParams.query : '&query=';

    const res = await axios.get(`/api/admin/flex-code-nomenclature`, config);

    dispatch({
      type: FlexCode_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          // newsParams: newsParams,
          flexCodeList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    dispatch({
      type: FlexCode_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Create Flex Code
export const create = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: FlexCode_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.post('/api/admin/flex-code-nomenclature/create', formData, config);
    if (res.data.status === true) {
      dispatch({
        type: FlexCode_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });

    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: FlexCode_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
    }
  } catch (err) {
    dispatch({
      type: FlexCode_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit Flex Code
export const edit = (formData, history, id) => async (dispatch) => {
  try {
    dispatch({
      type: FlexCode_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.post(
      `/api/admin/flex-code-nomenclature/${id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: FlexCode_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/flex-code-nomenclature');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: FlexCode_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
    }
  } catch (err) {
    dispatch({
      type: FlexCode_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete Flex Code Page
export const deleteFlexCode = (id) => async (dispatch) => {
  try {
    dispatch({
      type: FlexCode_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(`/api/admin/flex-code-nomenclature/${id}`, config);

    if (res.data.status === true) {
      dispatch({
        type: FlexCode_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: FlexCode_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: FlexCode_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

// Get Flex Code by id
export const getFlexCodeById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: FlexCode_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/flex-code-nomenclature/${id}`, config);

    await dispatch({
      type: GET_FlexCode_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: FlexCode_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
