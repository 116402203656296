/* eslint-disable */
import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import {
  edit,
  getSetting,
  setCheckboxError,
} from 'actions/admin/socialSetting';
import Errors from 'views/Notifications/Errors';
import Spinner from 'views/Spinner';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  Input,
  InputGroup,
  //InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import { REMOVE_ERRORS } from 'actions/types';

const SocialSettings = ({
  getSetting,
  edit,
  setCheckboxError,
  socialSetting: { currentSetting, loading },
  errorList,
}) => {
  // to resolve useEffect dependency error

  //################ get current website settings ############################
  useMemo(() => {

    getSetting();
  }, []);

  //################ set initial form data ############################
  const [initialFormData, setInitialFormData] = useState({
    facebook: '',
    instagram: '',
    linkedIn: '',
    twitter: '',
  });

  useMemo(() => {
    if (Object.keys(currentSetting).length > 0) {
      setInitialFormData({
        facebook: {
          value: currentSetting.facebook.value,
          is_to_be_displayed: currentSetting.facebook.is_to_be_displayed,
        },
        instagram: {
          value: currentSetting.instagram.value,
          is_to_be_displayed: currentSetting.instagram.is_to_be_displayed,
        },
        linkedIn: {
          value: currentSetting.linkedIn.value,
          is_to_be_displayed: currentSetting.linkedIn.is_to_be_displayed,
        },
        twitter: {
          value: currentSetting.twitter.value,
          is_to_be_displayed: currentSetting.twitter.is_to_be_displayed,
        },
        // facebookCheck: currentSetting.facebook.is_to_be_displayed,
        // instagramCheck: currentSetting.instagram.is_to_be_displayed,
        // linkedInCheck: currentSetting.linkedIn.is_to_be_displayed,
        // twitterCheck: currentSetting.twitter.is_to_be_displayed
      });
    }
  }, [currentSetting]);

  //################ set form data ############################

  const [formData, setFormData] = useState(initialFormData);

  useMemo(() => {
    setFormData(initialFormData);
  }, [initialFormData]);

  const { facebook, instagram, linkedIn, twitter } = formData;

  //################ onChange event ############################

  const dispatch = useDispatch();

  const onChange = (e) => {
    if (e.target.getAttribute('custom-ischeckbox')) {
      setFormData({
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          is_to_be_displayed: e.target.checked,
        },
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          value: e.target.value,
        },
      });
    }

    dispatch({ type: REMOVE_ERRORS });
  };

  //########################## set the submit state ##############################
  const [isSubmit, setIsSubmit] = useState(false);

  //################ on form submit ############################
  const onSubmit = (e) => {
    setIsSubmit(true);
    e.preventDefault();
    let new_error_list = [];
    Object.keys(formData).forEach((data) => {
      if (
        formData[data].is_to_be_displayed === true &&
        formData[data].value === ''
      )
        new_error_list.push({
          msg: `${data} link is required.`,
          param: data,
        });
    });
    if (new_error_list.length > 0) {
      setCheckboxError(new_error_list).then((res) => {
        setIsSubmit(false);
      });
    } else {
      edit(formData).then((res) => {
        setIsSubmit(false);
      });
    }
  };

  //################ on cancle save ############################
  const onClickHandel = (e) => {
    e.preventDefault();
    setFormData(initialFormData);
  };

  return Object.keys(currentSetting).length === 0 ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="12">
          <Card>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                <h4>Social account setting</h4>
                <br></br>
                <Row>
                  <Col lg="3">
                    <InputGroup size="lg">
                      <InputGroupText>
                        <Input
                          addon
                          type="checkbox"
                          name="facebook"
                          custom-ischeckbox="yes"
                          checked={facebook.is_to_be_displayed}
                          onChange={(e) => onChange(e)}
                        />
                      </InputGroupText>

                      <Input
                        placeholder="Facebook Link"
                        id="facebook"
                        name="facebook"
                        value={facebook.value}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.facebook ? true : false}
                      />

                      <InputGroupText>
                        <i className="fa fa-facebook"></i>
                      </InputGroupText>

                      <Errors current_key="facebook" key="facebook" />
                    </InputGroup>
                  </Col>
                  <Col lg="3">
                    <InputGroup size="lg">
                      <InputGroupText>
                        <Input
                          addon
                          type="checkbox"
                          custom-ischeckbox="yes"
                          name="instagram"
                          checked={instagram.is_to_be_displayed}
                          onChange={(e) => onChange(e)}
                        />
                      </InputGroupText>

                      <Input
                        placeholder="Instagram Link"
                        id="instagram"
                        name="instagram"
                        value={instagram.value}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.instagram ? true : false}
                      />

                      <InputGroupText>
                        <i className="fa fa-instagram"></i>
                      </InputGroupText>

                      <Errors current_key="instagram" key="instagram" />
                    </InputGroup>
                  </Col>
                  <Col lg="3">
                    <InputGroup size="lg">
                      <InputGroupText>
                        <Input
                          addon
                          type="checkbox"
                          custom-ischeckbox="yes"
                          name="linkedIn"
                          checked={linkedIn.is_to_be_displayed}
                          onChange={(e) => onChange(e)}
                        />
                      </InputGroupText>

                      <Input
                        placeholder="LinkenIn Link"
                        id="linkedIn"
                        name="linkedIn"
                        value={linkedIn.value}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.linkedIn ? true : false}
                      />

                      <InputGroupText>
                        <i className="fa fa-linkedin"></i>
                      </InputGroupText>

                      <Errors current_key="linkedIn" key="linkedIn" />
                    </InputGroup>
                  </Col>
                  <Col lg="3">
                    <InputGroup size="lg">
                      <InputGroupText>
                        <Input
                          addon
                          type="checkbox"
                          custom-ischeckbox="yes"
                          name="twitter"
                          checked={twitter.is_to_be_displayed}
                          onChange={(e) => onChange(e)}
                        />
                      </InputGroupText>

                      <Input
                        placeholder="Twitter Link"
                        id="twitter"
                        name="twitter"
                        value={twitter.value}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.twitter ? true : false}
                      />

                      <InputGroupText>
                        <i className="fa fa-twitter"></i>
                      </InputGroupText>

                      <Errors current_key="twitter" key="twitter" />
                    </InputGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                {loading ? (
                  <div className="modalFooter">
                    <Spinner />
                  </div>
                ) : (
                  <div className="modalFooter">
                    <Button
                      type="reset"
                      size="sm"
                      className="canclebtn"
                      color="danger"
                      onClick={onClickHandel}
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                    <Button
                      type="submit"
                      size="sm"
                      color="primary"
                      disabled={isSubmit}
                    >
                      <i className="fa fa-dot-circle-o"></i> Update
                    </Button>
                  </div>
                )}
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

SocialSettings.propTypes = {
  getSetting: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  setCheckboxError: PropTypes.func.isRequired,
  socialSetting: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  socialSetting: state.socialSetting,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  edit,
  getSetting,
  setCheckboxError,
})(SocialSettings);
