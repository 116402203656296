import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CKEditor } from 'ckeditor4-react';
import slugify from 'react-slugify';
import { Link } from 'react-router-dom';

import {
  getCategories,
  edit,
  create,
  setWholesaleError,
  setSaleError,
} from 'actions/admin/product';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';
import { ATTRIBUTE_WEIGHT_UNIT, CKEditorConfig, SHIPPING_BOX_SIZE_UNIT } from 'constants/index';

const BasicInfo = ({
  product_id,
  currentProduct,
  getCategories,
  category_loading,
  errorList,
  categorySubCategoryList,
  edit,
  create,
  setWholesaleError,
  setSaleError,
  changeTab,
  setProductId,
  loading,
}) => {
  //########################## set the initial form data ##############################

  const [productBasicDetail, setProductBasicDetail] = useState({
    product_id: product_id ? product_id : '',
    product_title: product_id ? currentProduct.product_title : '',
    weight: product_id ? currentProduct.weight : '',
    product_length: product_id ? currentProduct.product_length : '',
    construction_technology: product_id
      ? currentProduct.construction_technology
      : '',
    meterial: product_id ? currentProduct.meterial : '',
    sub_title: product_id ? currentProduct.sub_title : '',
    sub_heading: product_id ? currentProduct.sub_heading : '',
    slug: product_id ? currentProduct.slug : '',
    categoryId: product_id ? currentProduct.categoryId : '',
    wholesale_price: product_id ? currentProduct.wholesale_price : '',
    sale_price: product_id ? currentProduct.sale_price : '',
    tech_design: product_id ? currentProduct.tech_design : '',
    short_description: product_id ? currentProduct?.short_description : '',
    installation_guidelines: product_id
      ? currentProduct.installation_guidelines
      : '',
    status: product_id ? currentProduct?.status : 2,
    shaft_weight_category: product_id ? currentProduct.shaft_weight_category : '',
  });
  const [shortDescriptionError, setShortDescriptionError] = useState(false);
  const {
    product_title = '',
    weight = '',
    product_length = '',
    construction_technology = '',
    meterial = '',
    sub_title = '',
    sub_heading = '',
    slug = '',
    categoryId = '',
    //subCategoryId = '',
    tech_design = '',
    short_description = '',
    installation_guidelines = '',
    shaft_weight_category = '',
    status = 2
  } = productBasicDetail;

  //###################### get the category and sub category list ########################
  const [categoryList, setCategoryList] = useState(null);
  const [subCategoryList, setSubCategoryList] = useState(null);

  const changeSubCategory = (subCategory) => {
    let tempSubCategoryList = [];
    subCategory.forEach((catData, index) => {
      tempSubCategoryList[index] = {
        id: catData._id,
        name: catData.name,
      };
    });
    setSubCategoryList(tempSubCategoryList);
  };

  useMemo(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    if (Object.keys(categorySubCategoryList).length > 0) {
      let tempCategoryList = [];
      categorySubCategoryList.forEach((catData, index) => {
        tempCategoryList[index] = {
          id: catData._id,
          name: catData.name,
        };
        //if (catData._id === categoryId) changeSubCategory(catData.childrens);
      });
      setCategoryList(tempCategoryList);
    }
  }, [categorySubCategoryList, categoryId]);

  //########### change form data and sub category if category changed ########################
  const onChange = (e, dataName = '') => {
    let updateFormData = {};
    if (e.target.name !== "status") {
      updateFormData = { [e.target.name]: e.target.value };
      if (e.target.name === 'product_title' && !product_id) {
        let new_slug = slugify(e.target.value);
        updateFormData = { ...updateFormData, slug: new_slug };
      }
    } else {
      updateFormData = { [e.target.name]: e.target.checked ? 4 : 2 };
    }
    setProductBasicDetail({
      ...productBasicDetail,
      ...updateFormData,
    });
  };

  const handelTechDesignChange = (event) => {
    setProductBasicDetail((ev) => ({
      ...ev,
      tech_design: event.editor.getData(),
    }));
  };

  const handelShortDescriptionChange = (event) => {
    setShortDescriptionError(false);
    setProductBasicDetail((ev) => ({
      ...ev,
      short_description: event.editor.getData(),
    }));
  };

  const handelGuidlinesChange = (event) => {
    setProductBasicDetail((ev) => ({
      ...ev,
      installation_guidelines: event.editor.getData(),
    }));
  };

  //########### handel submit form  ########################
  const onSubmit = (e) => {
    e.preventDefault();
    setShortDescriptionError(false);
    const tempElement = document.createElement('div');
    tempElement.innerHTML = productBasicDetail?.short_description;

    const plainText = tempElement.textContent;
    if (plainText?.length > 101) {
      setShortDescriptionError(true);
      return;
    }
    if (!product_id) {
      create(productBasicDetail).then((res) => {
        if (res.status === true) {
          setProductId(res.id);
          changeTab('attributes_tab');
        }
      });
    } else {
      edit(productBasicDetail, product_id).then((res) => {
        if (res === true) changeTab('attributes_tab');
      });
    }
  };

  return (
    <>
      <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <CardBody>
                <Row>
                  <Col xs="12" sm="12">
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="product_title">
                            Product Name <span>*</span>
                          </Label>
                          <Input
                            type="text"
                            id="product_title"
                            name="product_title"
                            maxLength="100"
                            minLength="3"
                            value={product_title}
                            // required
                            onChange={(e) => onChange(e)}
                            invalid={errorList.product_title && product_title.length < 3 ? true : false}
                          />
                          {errorList.product_title && product_title.length < 3 ? <Errors
                            current_key="product_title"
                            key="product_title"
                          /> : null}
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="slug">
                            Product Slug <span>*</span>
                          </Label>
                          <Input
                            type="text"
                            id="slug"
                            name="slug"
                            maxLength="100"
                            value={slug}
                            onChange={(e) => onChange(e)}
                            readOnly
                            invalid={errorList.slug ? true : false}
                            disabled
                          />
                          <Errors current_key="slug" key="slug" />
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="categoryId">
                            Category <span>*</span>
                          </Label>
                          <Input
                            type="select"
                            name="categoryId"
                            id="categoryId"
                            value={categoryId}
                            onChange={(e) => onChange(e)}
                            // required
                            invalid={errorList.categoryId && categoryId.length === 0 ? true : false}
                          >
                            <option value={''}> {'Select'} </option>
                            {categoryList
                              ? categoryList.map((value, index) => {
                                return (
                                  <option key={index} value={value.id}>
                                    {value.name}
                                  </option>
                                );
                              })
                              : ''}
                          </Input>
                          {errorList.categoryId && categoryId.length === 0 ? <Errors current_key="categoryId" key="categoryId" /> : null}
                        </FormGroup>
                      </Col>
                      {/* <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="subCategoryId">
                            Sub category
                          </Label>
                          <Input
                            type="select"
                            name="subCategoryId"
                            id="subCategoryId"
                            value={subCategoryId}
                            onChange={(e) => onChange(e)}

                            invalid={errorList.subCategoryId ? true : false}
                          >
                            <option value={''}> {'Select'} </option>
                            {subCategoryList
                              ? subCategoryList.map((value, index) => {
                                return (
                                  <option key={index} value={value.id}>
                                    {value.name}
                                  </option>
                                );
                              })
                              : ''}
                          </Input>
                          <Errors
                            current_key="subCategoryId"
                            key="subCategoryId"
                          />
                        </FormGroup>
                      </Col> */}
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="weight">
                            Weight {ATTRIBUTE_WEIGHT_UNIT}
                            <span>*</span>
                          </Label>
                          <Input
                            type="number"
                            id="weight"
                            name="weight"
                            min="0.00"
                            step="0.001"
                            presicion={2}
                            value={weight}
                            // required
                            onChange={(e) => onChange(e)}
                            invalid={errorList.weight && weight <= 0 ? true : false}
                          />
                          {errorList.weight && weight <= 0 ? <Errors current_key="weight" key="weight" /> : null}
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="product_length">
                            Length {SHIPPING_BOX_SIZE_UNIT}
                            <span>*</span>
                          </Label>
                          <Input
                            type="number"
                            id="product_length"
                            name="product_length"
                            value={product_length}
                            min="0.00"
                            step="0.001"
                            presicion={2}
                            // required
                            onChange={(e) => onChange(e)}
                            invalid={errorList.product_length && product_length <= 0 ? true : false}
                          />
                          {errorList.product_length && product_length <= 0 ? <Errors
                            current_key="product_length"
                            key="product_length"
                          /> : null}
                        </FormGroup>
                      </Col>

                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="construction_technology">
                            Construction Technology <span>*</span>
                          </Label>
                          <Input
                            type="text"
                            id="construction_technology"
                            name="construction_technology"
                            maxLength="200"
                            minLength="3"
                            value={construction_technology}
                            // required
                            onChange={(e) => onChange(e)}
                            invalid={
                              errorList.construction_technology && construction_technology.length < 3 ? true : false
                            }
                          />
                          {
                            errorList.construction_technology && construction_technology.length < 3 ? <Errors
                              current_key="construction_technology"
                              key="construction_technology"
                            /> : null}
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="meterial">
                            Materials <span>*</span>
                          </Label>
                          <Input
                            type="text"
                            id="meterial"
                            name="meterial"
                            maxLength="200"
                            minLength="3"
                            value={meterial}
                            // required
                            onChange={(e) => onChange(e)}
                            invalid={errorList.meterial && meterial.length < 3 ? true : false}
                          />
                          {errorList.meterial && meterial.length < 3 ? <Errors current_key="meterial" key="meterial" /> : null}
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="sub_title">
                            Heading <span>*</span>
                          </Label>
                          <Input
                            type="text"
                            id="sub_title"
                            name="sub_title"
                            maxLength="150"
                            minLength="3"
                            value={sub_title}
                            // required
                            onChange={(e) => onChange(e)}
                            invalid={errorList.sub_title && sub_title.length < 3 ? true : false}
                          />
                          {errorList.sub_title && sub_title.length < 3 ? <Errors current_key="sub_title" key="sub_title" /> : null}
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="sub_heading">
                            Sub Heading <span>*</span>
                          </Label>
                          <Input
                            type="text"
                            id="sub_heading"
                            name="sub_heading"
                            maxLength="150"
                            minLength="3"
                            value={sub_heading}
                            // required
                            onChange={(e) => onChange(e)}
                            invalid={errorList.sub_heading && sub_heading.length < 3 ? true : false}
                          />
                          {errorList.sub_heading && sub_heading.length < 3 ? <Errors current_key="sub_heading" key="sub_heading" /> : null}
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label htmlFor="status">
                            Internal Product
                          </Label>
                          <Input
                            type="checkbox"
                            id="status "
                            name="status"
                            checked={status === 4 ? true : false}
                            // required
                            onChange={(e) => onChange(e)}
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="sub_heading">
                            Shaft Weight Category
                          </Label>
                          <Input
                            type="select"
                            id="shaft_weight_category"
                            name="shaft_weight_category"
                            value={shaft_weight_category}
                            onChange={(e) => onChange(e)}
                          >
                            <option value="">Choose an Option</option>
                            <option value="Light">Light</option>
                            <option value="Mid">Mid</option>
                            <option value="Heavy">Heavy</option>
                            <option value="Ultra Light">Ultra Light</option>
                          </Input>
                        </FormGroup>
                      </Col> */}
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xs="12" sm="12">
            <Card>
              <CardHeader>
                <h5>Description</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" sm="6">
                    <FormGroup>
                      <Label htmlFor="short_description">
                        <strong>Short Description</strong> (Max Length - 100 Characters Allowed) | Note:- Displayed in Fitting Results
                      </Label>
                      <CKEditor
                        editor={short_description}
                        initData={short_description}

                        id="short_description"
                        name="short_description"
                        config={CKEditorConfig}
                        // onChange={(event) => onChange(event, 'short_description')}
                        onChange={(event) => handelShortDescriptionChange(event)}
                        onBeforeLoad={(CKEDITOR) =>
                          (CKEDITOR.disableAutoInline = true)
                        }
                        invalid={errorList.short_description ? true : false}
                      />
                      <Errors current_key="short_description" key="short_description" />
                      {shortDescriptionError ? <p style={{ color: "red" }}>Max Length 100 Characters allowed</p> : null}
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6">
                    <FormGroup>
                      <Label htmlFor="tech_design">
                        <strong>Description</strong>
                      </Label>
                      <CKEditor
                        editor={tech_design}
                        initData={tech_design}
                        id="tech_design"
                        name="tech_design"
                        config={CKEditorConfig}
                        // onChange={(event) => onChange(event, 'tech_design')}
                        onChange={(event) => handelTechDesignChange(event)}
                        onBeforeLoad={(CKEDITOR) =>
                          (CKEDITOR.disableAutoInline = true)
                        }
                        invalid={errorList.tech_design ? true : false}
                      />
                      <Errors current_key="tech_design" key="tech_design" />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6">
                    <FormGroup>
                      <Label htmlFor="installation_guidelines">
                        <strong>Installation Guidelines</strong>
                      </Label>
                      <CKEditor
                        editor={installation_guidelines}
                        initData={installation_guidelines}
                        id="installation_guidelines"
                        name="installation_guidelines"
                        config={CKEditorConfig}
                        // onChange={(event) =>
                        //   onChange(event, 'installation_guidelines')
                        // }

                        onChange={(event) => handelGuidlinesChange(event)}
                        onBeforeLoad={(CKEDITOR) =>
                          (CKEDITOR.disableAutoInline = true)
                        }
                        invalid={
                          errorList.installation_guidelines ? true : false
                        }
                      />
                      <Errors
                        current_key="installation_guidelines"
                        key="installation_guidelines"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xs="12" className="Justify-flex-end">
            <FormGroup key={2}>
              <Button
                className="mr-05"
                type="submit"
                size="sm"
                color="primary"
                disabled={loading}
              >
                <i className="fa fa-dot-circle-o"></i> Save & Next
              </Button>
              <Link to="/admin/products">
                <Button type="reset" size="sm" color="danger">
                  <i className="fa fa-ban"></i> Cancel
                </Button>
              </Link>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </>
  );
};

BasicInfo.defaultProps = {
  product_id: null,
};

BasicInfo.propTypes = {
  category_loading: PropTypes.bool.isRequired,
  currentProduct: PropTypes.object.isRequired,
  getCategories: PropTypes.func.isRequired,
  errorList: PropTypes.array.isRequired,
  categorySubCategoryList: PropTypes.array.isRequired,
  edit: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  category_loading: state.product.category_loading,
  currentProduct: state.product.currentProduct,
  errorList: state.errors,
  categorySubCategoryList: state.product.categorySubCategoryList,
  loading: state.product.loading,
});

export default connect(mapStateToProps, {
  getCategories,
  edit,
  create,
})(BasicInfo);
