import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { add } from 'actions/admin/shippingBox';
import Errors from 'views/Notifications/Errors';
import Spinner from 'views/ButtonSpinner';

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  ModalFooter,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

import {
  SHIPPING_BOX_SIZE_UNIT,
  SHIPPING_BOX_WEIGHT_UNIT,
} from 'constants/index';

const AddShippingBox = ({
  add,
  errorList,
  toggle,
  isOpen,
  modal_loading,
  update,
}) => {
  //########################## use dispatch ##############################

  //########################## set the initial form data ##############################

  const initialState = {
    name: '',
    length: '',
    width: '',
    height: '',
    weight: '',
    min_quantity: 0,
    max_quantity: 0,
    is_customised: true
  };
  const [formData, setFormData] = useState(initialState);

  //########################## set the submit state ##############################
  const [isSubmit, setIsSubmit] = useState(false);

  //########################## reset for data when modal is closed ##############################
  const resetModal = () => {
    //reset the submit stacte
    setIsSubmit(false);
    //reset the form data to initial state
    setFormData(initialState);
  };

  //########################## explod form data vaiables ##############################
  const { name, length, width, height, weight } =
    formData;

  //########################## change event for all inputs ##############################
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //########################## submit form data ##############################
  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    add(formData).then((res) => {
      if (res.status === true) {
        toggle();
        update(res.box);
      } else {
        setIsSubmit(false);
      }
    });
  };

  return (
    <div className="animated fadeIn">
      <Modal isOpen={isOpen} toggle={toggle} onClosed={resetModal}>
        <ModalHeader toggle={toggle}>Add New Custom Shipping Box</ModalHeader>

        <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
          <ModalBody>
            <Row>
              <Col xs="12" sm="12">
                <FormGroup>
                  <Label htmlFor="name">
                    Name <span>*</span>
                  </Label>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    maxLength="100"
                    value={name}
                    placeholder={'Enter name'}
                    required
                    onChange={(e) => onChange(e)}
                    invalid={errorList.name ? true : false}
                  />
                  <Errors current_key="name" key="name" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="length">
                    Length {SHIPPING_BOX_SIZE_UNIT} <span>*</span>
                  </Label>
                  <Input
                    type="number"
                    id="length"
                    name="length"
                    value={length}
                    step={0.01}
                    min="1"
                    max="999"
                    placeholder={'Enter length'}
                    required
                    onChange={(e) => onChange(e)}
                    invalid={errorList.length ? true : false}
                  />
                  <Errors key="length" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="width">
                    Width {SHIPPING_BOX_SIZE_UNIT} <span>*</span>
                  </Label>
                  <Input
                    type="number"
                    id="width"
                    name="width"
                    value={width}
                    step={0.01}
                    min="1"
                    max="999"
                    placeholder={'Enter width'}
                    required
                    onChange={(e) => onChange(e)}
                    invalid={errorList.width ? true : false}
                  />
                  <Errors current_key="width" key="width" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="height">
                    Height {SHIPPING_BOX_SIZE_UNIT} <span>*</span>
                  </Label>
                  <Input
                    type="number"
                    id="height"
                    name="height"
                    value={height}
                    step={0.01}
                    min="1"
                    max="999"
                    placeholder={'Enter height'}
                    required
                    onChange={(e) => onChange(e)}
                    invalid={errorList.height ? true : false}
                  />
                  <Errors current_key="height" key="height" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="weight">
                    Weight {SHIPPING_BOX_WEIGHT_UNIT} <span>*</span>
                  </Label>
                  <Input
                    type="number"
                    id="weight"
                    name="weight"
                    value={weight}
                    step={0.01}
                    min="1"
                    max="999"
                    placeholder={'Enter weight'}
                    required
                    onChange={(e) => onChange(e)}
                    invalid={errorList.weight ? true : false}
                  />
                  <Errors current_key="weight" key="weight" />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="modalFooter Shippingmodalfooter">
              {modal_loading ? (
                <Spinner />
              ) : (
                <Button
                  className="mr-05"
                  type="submit"
                  size="sm"
                  color="primary"
                  // onClick={e => onSubmit(e)}
                  disabled={modal_loading}
                >
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
              )}
              <Button type="reset" size="sm" color="danger" onClick={toggle}>
                <i className="fa fa-ban"></i> Cancel
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

AddShippingBox.defaultProps = {
  isOpen: false,
};

AddShippingBox.propTypes = {
  add: PropTypes.func.isRequired,
  modal_loading: PropTypes.bool.isRequired,
  errorList: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  modal_loading: state.shippingBox.modal_loading,
});
export default connect(mapStateToProps, {
  add,
})(AddShippingBox);
