import { Col } from 'react-bootstrap';
import { Label } from 'reactstrap';
const AddressFormat = ({ address }) => {
    return (
        <>
            <Col className="data-ptag" style={{ display: 'flex' }}>
                <Label className="label-tag">Address :</Label>
                <span>
                    {address?.addressData?.address_line_1}
                    <br />
                    <div>
                        {address?.addressData?.address_line_2 ? address?.addressData?.address_line_2 : ''}
                    </div>
                    {address?.city}{', '}
                    {address?.state}{' - '}
                    {address?.zipcode} <br />
                    {address?.country}
                </span>
            </Col>
        </>
    )
}

export default AddressFormat;