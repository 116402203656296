import {
  SPECS_LOADING,
  SPECS_CREATED,
  SPECS_CREATE_ERROR,
  SPECS_FAIL,
  SPECS_UPDATED,
  SPECS_UPDATE_ERROR,
  SPECS_DELETED,
  SPECS_DELETE_ERROR,
  SPECS_LIST_UPDATED,
  GET_SPECS_BY_ID,
  SPECS_CHANGE_STATUS,
  SPECS_CHANGE_STATUS_ERROR,
  SPECS_TAG_DELETED,
  SPECS_TAG_DELETE_ERROR,
  CANCEL_REQUEST,
} from "actions/types";

const initalState = {
  specsList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentSpecs: {},
  loading: false,
  isSubLoading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case SPECS_LOADING:
      return {
        ...state,
        specsList: payload.is_sub_element
          ? state.specsList
          : initalState.specsList,
        currentSpecs: payload.is_sub_element
          ? state.currentSpecs
          : initalState.currentSpecs,

        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case CANCEL_REQUEST:
        return {
          ...state,
          loading: false,
        }
    case SPECS_CREATED:
      return {
        ...state,
        loading: false,
      };
    case SPECS_CREATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SPECS_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case SPECS_UPDATED:
      return {
        ...state,
        currentSpecs: {},
        loading: false,
      };
    case SPECS_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SPECS_DELETED:
      return {
        ...state,
        loading: false,
      };
    case SPECS_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SPECS_LIST_UPDATED:
      return {
        ...state,
        specsList: {
          data: payload.data.specsList.data,
          page: payload.data.specsList.metadata[0].current_page,
          count: payload.data.specsList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_SPECS_BY_ID:
      return {
        ...state,
        currentSpecs: payload.data,
        loading: false,
      };
    case SPECS_CHANGE_STATUS:
      return {
        ...state,
        specsList: {
          ...state.specsList,
          data: state.specsList.data.map((specs) =>
            specs._id === payload.data._id
              ? { ...specs, status: payload.data.status }
              : specs
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case SPECS_CHANGE_STATUS_ERROR:
      return {
        ...state,
        specsList: {
          ...state.specsList,
          data: state.specsList.data.map((specs) =>
            specs._id === payload.data
              ? { ...specs, is_failed: true }
              : { ...specs, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case SPECS_TAG_DELETED:
      return {
        ...state,
        currentSpecs: {
          ...state.currentspecs,
          terms: state.currentSpecs.terms.filter(
            (term) => term._id !== payload.data
          ),
        },
        loading: false,
      };
    case SPECS_TAG_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
