/* eslint-disable */
import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
    useMemo,
  } from 'react';
  import PropTypes from 'prop-types';
  import { Link, useLocation, useNavigate } from 'react-router-dom';
  import { connect } from 'react-redux';
  
  import 'bootstrap/dist/css/bootstrap.min.css';
  import { AgGridReact } from 'ag-grid-react';
  import 'ag-grid-community/dist/styles/ag-grid.css';
  import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
  
  import Moment from 'react-moment';
  
  import { Button, Card, CardBody, Col, Row, Input } from 'reactstrap';
  
  import { getHomeList, deleteCMS, changeStatus } from 'actions/admin/home';
  import * as Constants from 'constants/index';
  import PerPageSelect from '../PerPageSelect';
  import LoadingCellRenderer from 'views/LoadingCellRenderer';
  import Loading from 'utils/Loading';
  
  const renderName = (params) => {
    if (params.data === undefined) {
      return <Loading />;
    } else {
      return <div>{params.data?.page_name}</div>;
    }
  };
  
  const actions = (
    <div className="add-button-div specify-width">
      {/* <Link to="/admin/cms/create" className="addNewElementClass">
        <Button color="primary" size="sm">
          <i className="fa fa-plus"></i> Create Page
        </Button>
      </Link> */}
    </div>
  );
  
  const HomeList = ({
    getHomeList,
    deleteCMS,
    changeStatus,
    cmsList: { data, count },
    loading,
    subLoading,
  }) => {
    const history = useNavigate();
    const location = useLocation();
    //################## Initillise sorting searching parameters by default values ###################
    const [sortingParams, setSortingParams] = useState({
      limit: Constants.DEFAULT_PAGE_SIZE,
      page: 1,
      orderBy: 'created_at',
      ascending: 'desc',
      query: '',
      filter: {},
    });
  
    //#########################Colums defination start ###################
    const columns = [
      {
        field: 'page_name',
        headerName: 'Page Name',
        suppressMovable: true,
        width: 150,
        cellClass: 'suppress-movable-col',
        sortable: true,
        filter: 'agTextColumnFilter',
        // pass in additional parameters to the text filter
        suppressSizeToFit: false,
        filterParams: {
          closeOnApply: true,
          filterOptions: Constants.TEXT_FILTER_OPTIONS,
          suppressAndOrCondition: true,
          debounceMs: 800,
        },
        colSpan: (params) => {
          if (params.data === undefined) {
            return 3;
          } else {
            return 0;
          }
        },
        cellRenderer: (params) => renderName(params),
      },
      {
        field: 'meta_title',
        headerName: 'Meta Title',
        suppressMovable: true,
        width: 150,
        cellClass: 'suppress-movable-col',
        sortable: true,
        filter: 'agTextColumnFilter',
        // pass in additional parameters to the text filter
        suppressSizeToFit: false,
        filterParams: {
          closeOnApply: true,
          filterOptions: Constants.TEXT_FILTER_OPTIONS,
          suppressAndOrCondition: true,
          debounceMs: 800,
        },
      },
      {
        field: 'meta_description',
        headerName: 'Meta description',
        suppressMovable: true,
        cellClass: 'suppress-movable-col',
        width: 150,
        sortable: true,
        filter: 'agTextColumnFilter',
        suppressSizeToFit: false,
        // pass in additional parameters to the text filter
        filterParams: {
          closeOnApply: true,
          filterOptions: Constants.TEXT_FILTER_OPTIONS,
          suppressAndOrCondition: true,
          debounceMs: 800,
        },
        // headerSortingStyle
      },
      {
        field: 'updated_at',
        headerName: 'Updated At',
        width: 140,
        suppressMovable: true,
        cellClass: 'suppress-movable-col',
        sortable: true,
        filter: 'agDateColumnFilter',
        // pass in additional parameters to the text filter
        filterParams: {
          buttons: ['reset', 'apply'],
          debounceMs: 200,
        },
        // headerSortingStyle,
        cellRenderer: (cellContent, row) => (
          // <Moment format="YYYY/MM/DD" withTitle>{new Date(row.created_at*1).toLocaleDateString()}</Moment>
          <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
            {row.updated_at}
          </Moment>
        ),
      },
      {
        field: '_id',
        headerName: 'Actions',
        suppressMovable: true,
        cellClass: 'suppress-movable-col',
        cellRenderer: (params) => (
          <div>
            <Link to={`/admin/home/edit/${params.data?._id}`}>
              <Button type="button" size="sm" color="success" title="Edit">
                <i className="fa fa-pencil"></i>
              </Button>
            </Link>
            {/* <Button
              type="button"
              size="sm"
              color="danger"
              title="Delete"
              onClick={(e) => {
                if (
                  window.confirm(
                    `Are you sure, you want to delete ${params.data.page_title} page?`
                  )
                ) {
                  deleteCMS(params.data?._id).then((res) => {
                    if (res) {
                      getHomeList(sortingParams);
                      gridApi.purgeInfiniteCache();
                    }
                  });
                }
              }}
            >
              <i className="fa fa-trash"></i>
            </Button> */}
          </div>
        ),
        headerStyle: {
          backgroundColor: Constants.TABLE_BORDER_COLOR,
        },
      },
    ];
    //#########################Colums defination end ###################
  
    //################## START Set properties(options) of datatables ###################
  
    const gridRef = useRef();
    const [gridApi, setGridApi] = useState(null);
  
    const onGridReady = useCallback((params) => {
      setGridApi(params.api);
    });
  
    const filterChanged = useCallback((params) => {
      const filterInstance = gridRef.current.api.getFilterInstance(
        params.columns[0].colId
      );
      if (filterInstance.appliedModel?.type === 'any') {
        setActiveClass('');
        gridRef?.current.api.destroyFilter(params.columns[0].colId);
      }
    });
  
    const onPaginationChange = useCallback((pageSize) => {
      setSortingParams((initVals) => {
        return { ...initVals, limit: pageSize };
      });
      gridRef.current.api.gridOptionsWrapper.setProperty(
        'cacheBlockSize',
        pageSize
      );
      gridRef.current.api.infiniteRowModel.resetCache();
      gridRef.current.api.paginationSetPageSize(pageSize);
    });
  
    const customLoadingCellRenderer = useMemo(() => {
      return LoadingCellRenderer;
    }, []);
    const loadingCellRendererParams = useMemo(() => {
      return {
        loadingMessage: 'Loading....',
      };
    }, []);
  
    useEffect(() => {
      if (gridApi) {
        if (Object.keys(sortingParams.filter).length) {
          gridApi.setFilterModel(sortingParams.filter);
        }
        const dataSource = {
          getRows: async (params) => {
            const page = gridApi.paginationGetCurrentPage() + 1;
            const limit = gridApi.paginationGetPageSize();
  
            const sortModel = params.sortModel.length
              ? params.sortModel[0]
              : null;
            const filterModel = params.filterModel ? params.filterModel : {};
  
            const customParams = {
              ...sortingParams,
              limit,
              orderBy: sortModel?.colId,
              ascending: sortModel?.sort,
              filter: filterModel,
              page: page,
            };
  
            const customData = await getHomeList(customParams);
  
            if (customData?.data?.status) {
              params.successCallback(
                customData.data.response[0].data,
                customData.data.response[0].metadata[0].totalRecord
              );
              customParams.onLoad = false;
              setSortingParams({ ...customParams });
              history(location.pathname, { state: customParams });
            } else {
              params.successCallback([], 0);
            }
          },
        };
        gridApi.setDatasource(dataSource);
        gridApi?.api?.sizeColumnsToFit();
      }
    }, [gridApi]);
  
    const onFirstDataRendered = useCallback(() => {
      setTimeout(() => {
        gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
      }, 1000);
    });
  
    const onPaginationChanged = useCallback((params) => {
      if (gridRef?.current.api) {
        const page = gridApi?.paginationGetCurrentPage();
        setSortingParams({ ...sortingParams, page });
      }
    });
  
    let delayTimer;
    const onSearch = (e) => {
      clearTimeout(delayTimer);
      const searchText = e.target.value.trim();
  
      delayTimer = setTimeout(() => {
        if (gridApi) {
          if (Object.keys(sortingParams.filter).length) {
            gridApi.setFilterModel(sortingParams.filter);
          }
          const dataSource = {
            getRows: async (params) => {
              const page = gridApi.paginationGetCurrentPage() + 1;
              const limit = gridApi.paginationGetPageSize();
  
              const sortModel = params.sortModel.length
                ? params.sortModel[0]
                : null;
              const filterModel = params.filterModel ? params.filterModel : {};
              const customParams = {
                ...sortingParams,
                query: searchText,
                limit,
                orderBy: sortModel?.colId,
                ascending: sortModel?.sort,
                filter: filterModel,
                page,
              };
  
              const customData = await getHomeList(customParams);
  
              if (customData?.data?.status) {
                params.successCallback(
                  customData.data.response[0].data,
                  customData.data.response[0].metadata[0].totalRecord
                );
                customParams.onLoad = false;
                setSortingParams({ ...customParams });
                history(location.pathname, { state: customParams });
              } else {
                params.successCallback([], 0);
              }
            },
          };
          gridApi.setDatasource(dataSource);
          gridApi.api?.sizeColumnsToFit();
        }
      }, 1000);
    };
  
    return (
      <div className="animated fadeIn userTableList">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="tabletopsec">
                  {actions}
                  {/* <div className="rightpaneltop">
                    <Input
                      type="text"
                      id="search-text-box"
                      placeholder="Search by page name"
                      onKeyUp={(e) => {
                        onSearch(e);
                      }}
                    />
                  </div> */}
                  <div className="rightpaneltop">
                    Home Per Page
                    <PerPageSelect
                      perPage={sortingParams.limit}
                      onPaginationChange={onPaginationChange}
                    />
                  </div>
                </div>
                <div className="card w-100 p-0 border-0">
                  <div className="card-body p-0 position-relative">
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        defaultColDef={{
                          resizable: true,
                        }}
                        loadingCellRenderer={customLoadingCellRenderer}
                        loadingCellRendererParams={loadingCellRendererParams}
                        domLayout={'autoHeight'}
                        rowModelType={'infinite'}
                        columnDefs={columns}
                        pagination={true}
                        sizeColumnsToFit={true}
                        // floatingFilter={true}
                        onFilterChanged={filterChanged}
                        onGridReady={onGridReady}
                        paginationPageSize={sortingParams.limit}
                        cacheBlockSize={sortingParams.limit}
                        cacheQuickFilter={true}
                        onFirstDataRendered={onFirstDataRendered}
                        onPaginationChanged={onPaginationChanged}
                        ref={gridRef}
                      ></AgGridReact>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  };
  
  HomeList.propTypes = {
    getHomeList: PropTypes.func.isRequired,
    deleteCMS: PropTypes.func.isRequired,
    changeStatus: PropTypes.func.isRequired,
    cmsList: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    subLoading: PropTypes.bool.isRequired,
  };
  const mapStateToProps = (state) => ({
    cmsList: state.cms.cmsList,
    loading: state.cms.loading,
    subLoading: state.cms.isSubLoading,
  });
  
  export default connect(mapStateToProps, {
    getHomeList,
    deleteCMS,
    changeStatus,
  })(HomeList);
  