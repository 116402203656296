/* eslint-disable */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from '../../../../utils/Loading';
import { Button, Card, CardBody, Col, Row, Input } from 'reactstrap';

import Moment from 'react-moment';

import * as Constants from 'constants/index';
import noImagae from '../../../../assets/productdummy.png';
import {
  getCategoryList,
  deleteCategory,
  changeStatus,
} from 'actions/admin/GearProduct/gearCategory';

import PerPageSelect from '../../PerPageSelect';
import CreateGearCategory from './CreateGearCategory';

const imageComponent = (params) => {
  if (params.data === undefined) {
    return <Loading />;
  } else {
    return (
      <img
        src={
          params.data.image
            ? process.env.REACT_APP_AWS_STORAGE_URL +
              '/uploads/category/' +
              params.data.image
            : noImagae
        }
        className="preview-img"
        alt=""
      />
    );
  }
};

const dateRender = (params) => (
  <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
    {params.data?.created_at}
  </Moment>
);

const renderStatus = (params, changeCategoryStatus) => {
  return (
    <div>
      <Input
        type="select"
        name="status"
        id={params.data?._id}
        defaultValue={params.data?.status}
        onChange={(e, _a) => {
          changeCategoryStatus(params.data?._id, e.target.value);
        }}
      >
        <option value="0">Inactive</option>
        <option value="1">Active</option>
      </Input>
    </div>
  );
};

const renderActions = (params, deleteCategory, updateCategories) => {
  return (
    <div>
      <Link to={`/admin/gear-categories/edit/${params.data?._id}`}>
        <Button type="button" size="sm" color="success" title="Edit">
          <i className="fa fa-pencil"></i>
        </Button>
      </Link>
      <Button
        type="button"
        size="sm"
        color="danger"
        title="Delete"
        onClick={(_e) => {
          if (
            window.confirm(
              `Are you sure, you want to delete ${params.data?.name} category?`
            )
          ) {
            deleteCategory(params.data?._id).then((res) => {
              if (res) updateCategories();
            });
          }
        }}
      >
        {' '}
        <i className="fa fa-trash"></i>
      </Button>
    </div>
  );
};

const GearCategoryList = ({
  getCategoryList,
  deleteCategory,
  changeStatus,
  gearCategoryList: { data, count },
  loading,
  subLoading,
}) => {
  const history = useNavigate();
  const location = useLocation();
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
          limit: Constants.DEFAULT_PAGE_SIZE,
          page: 1,
          orderBy: 'created_at',
          ascending: 'desc',
          query: '',
          filter: {},
          onLoad: true,
        }
  );

  // to resolve useEffect dependency error

  //################## to get updated list ###################
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);

  const [categoryModal, setOpenCategoryModal] = useState(false);
  const toggleCategoryModal = useCallback(() => {
    setOpenCategoryModal(!categoryModal);
  });

  const updateCategories = () => {
    gridApi.purgeInfiniteCache();
  };
  //################ toggle category add/edit modal ############################
  const actions = (
    <div className="add-button-div specify-width">
      <Button color="primary" size="sm" onClick={toggleCategoryModal}>
        <i className="fa fa-plus"></i> Add New Gear Category
      </Button>
      <CreateGearCategory
        isOpen={categoryModal}
        toggle={toggleCategoryModal}
        update={updateCategories}
      ></CreateGearCategory>
    </div>
  );

  const changeCategoryStatus = async (id, value) => {
    await changeStatus(id, value);
    gridApi.purgeInfiniteCache();
  };
  //#########################Colums defination start ###################
  const columns = [
    {
      field: 'image',
      headerName: 'Image',
      suppressMovable: true,
      width: 110,
      cellClass: 'suppress-movable-col',
      sortable: true,
      // pass in additional parameters to the text filter
      colSpan: (params) => {
        if (params.data === undefined) {
          return 6;
        } else {
          return 0;
        }
      },
      cellRenderer: imageComponent,
    },
    {
      field: 'name',
      headerName: 'Name',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'total_products',
      headerName: 'Total Products',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      width: 160,
      filter: 'agNumberColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      width: 150,
      filter: 'agDateColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.DATE_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
        buttons: ['reset', 'apply'],
      },
      cellRenderer: dateRender,
    },
    {
      field: 'status',
      headerName: 'Status',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      // pass in additional parameters to the text filter
      cellRenderer: (params) => renderStatus(params, changeCategoryStatus),
    },
    {
      field: '_id',
      headerName: 'Actions',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) =>
        renderActions(params, deleteCategory, updateCategories),
    },
  ];
  //#########################Colums defination end ###################

  //################## START Set properties(options) of datatables ###################

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };

          const customData = await getCategoryList(customParams);

          if (customData?.data?.status) {
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });

  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                {actions}
                <div className="rightpaneltop">
                  Gear Category Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>
              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      defaultColDef={{
                        resizable: true,
                      }}
                      domLayout={'autoHeight'}
                      rowModelType={'infinite'}
                      columnDefs={columns}
                      pagination={true}
                      sizeColumnsToFit={true}
                      onFilterChanged={filterChanged}
                      onGridReady={onGridReady}
                      paginationPageSize={sortingParams.limit}
                      cacheBlockSize={sortingParams.limit}
                      cacheQuickFilter={true}
                      onFirstDataRendered={onFirstDataRendered}
                      onPaginationChanged={onPaginationChanged}
                      loadingCellRenderer={customLoadingCellRenderer}
                      loadingCellRendererParams={loadingCellRendererParams}
                      ref={gridRef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

GearCategoryList.propTypes = {
  getCategoryList: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  gearCategoryList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.gearCategory.loading,
  gearCategoryList: state.gearCategory.gearCategoryList,
  subLoading: state.gearCategory.isSubLoading,
});

export default connect(mapStateToProps, {
  getCategoryList,
  deleteCategory,
  changeStatus,
})(GearCategoryList);
