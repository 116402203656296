/* eslint-disable */
import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from '../../../utils/Loading';

import Moment from 'react-moment';

import {
    getAnnouncementsList,
    deleteAnnouncement,
} from 'actions/admin/emailTemplate';

import * as Constants from 'constants/index';

import {
    Button,
    Card,
    CardBody,
    Col,
    Row,
    Input,
    CardFooter,
} from 'reactstrap';

import PerPageSelect from '../PerPageSelect';

const renderTitle = (params) => {
    if (params.data === undefined) {
        return <Loading />;
    } else {
        return <div>{params.data?.sent_to}</div>;
    }
};

const renderCreatedAt = (params) => {
    return (
        <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
            {params.data?.created_at}
        </Moment>
    );
};

const renderActions = (
    params,
    deleteAnnouncement,
    updateAnnouncementsList,
) => {
    return (
        <div>
            <Button
                type="button"
                size="sm"
                color="danger"
                title="Delete"
                onClick={(e) => {
                    if (
                        window.confirm(
                            `Are you sure, you want to delete?`
                        )
                    ) {
                        deleteAnnouncement(params.data?._id, history).then((res) => {
                            if (res) updateAnnouncementsList();
                        });
                    }
                }}
            >
                <i className="fa fa-trash"></i>
            </Button>
        </div>
    );
};


const Actions = () => (
    <div className="add-button-div">
        <Link
            to={`/admin/announcements`}
            className="addNewElementClass"
        >
            <Button color="primary" size="sm">
                <i className="fa fa-plus"></i> Send Announcements
            </Button>
        </Link>
    </div>
);

const AnnouncementsList = ({
    getAnnouncementsList,
    deleteAnnouncement,
    announcementsList: { data, count },
    loading,
    subLoading,
}) => {
    const history = useNavigate();
    //################## Initillise sorting searching parameters by default values ###################
    const [sortingParams, setSortingParams] = useState(
        Object.keys(location?.state ?? {}).length
            ? { ...location.state, onLoad: true }
            : {
                limit: Constants.DEFAULT_PAGE_SIZE,
                page: 1,
                orderBy: 'created_at',
                ascending: 'desc',
                query: '',
                filter: {},
                onLoad: true,
            }
    );

    // to resolve useEffect dependency error

    //################## to get updated list ###################
    const gridRef = useRef();
    const [gridApi, setGridApi] = useState(null);

    const updateAnnouncementsList = () => {
        gridApi.purgeInfiniteCache();
    };


    //#########################Colums defination start ###################
    const columns = [
        {
            field: 'sent_to',
            headerName: 'Sent To',
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            sortable: true,
            filter: 'agTextColumnFilter',
            width: 150,
            filterParams: {
                closeOnApply: true,
                filterOptions: Constants.TEXT_FILTER_OPTIONS,
                suppressAndOrCondition: true,
                debounceMs: 800,
            },
            colSpan: (params) => {
                if (params.data === undefined) {
                    return 4;
                } else {
                    return 0;
                }
            },
            cellRenderer: (params) => renderTitle(params),
        },
        {
            field: 'subject',
            headerName: 'Subject',
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            sortable: true,
            filter: 'agTextColumnFilter',
            width: 300,
            filterParams: {
                closeOnApply: true,
                filterOptions: Constants.TEXT_FILTER_OPTIONS,
                suppressAndOrCondition: true,
                debounceMs: 800,
            },
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            sortable: true,
            filter: 'agDateColumnFilter',
            width: 150,
            // pass in additional parameters to the text filter
            filterParams: {
                buttons: ['apply', 'reset'],
                closeOnApply: true,
                filterOptions: Constants.DATE_FILTER_OPTIONS,
                suppressAndOrCondition: true,
                debounceMs: 800,
            },
            cellRenderer: (params) => renderCreatedAt(params),
        },
        {
            field: '_id',
            headerName: 'Actions',
            suppressMovable: true,
            cellClass: 'suppress-movable-col',
            cellRenderer: (params) =>
                renderActions(
                    params,
                    deleteAnnouncement,
                    updateAnnouncementsList,
                ),
        },
    ];

    //#########################Colums defination end ###################

    //################## START Set properties(options) of datatables ###################

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    });


    const filterChanged = useCallback((params) => {
        const filterInstance = gridRef.current.api.getFilterInstance(
            params.columns[0].colId
        );
        if (filterInstance.appliedModel?.type === 'any') {
            setActiveClass('');
            gridRef.current.api.destroyFilter(params.columns[0].colId);
        }
    });

    const onPaginationChange = useCallback((pageSize) => {
        setSortingParams((initVals) => {
            return { ...initVals, limit: pageSize };
        });
        gridRef.current.api.gridOptionsWrapper.setProperty(
            'cacheBlockSize',
            pageSize
        );
        gridRef.current.api.infiniteRowModel.resetCache();
        gridRef.current.api.paginationSetPageSize(pageSize);
    });

    const customLoadingCellRenderer = useMemo(() => {
        return LoadingCellRenderer;
    }, []);
    const loadingCellRendererParams = useMemo(() => {
        return {
            loadingMessage: 'Loading....',
        };
    }, []);

    useEffect(() => {
        if (gridApi) {
            if (Object.keys(sortingParams.filter).length) {
                gridApi.setFilterModel(sortingParams.filter);
            }
            const dataSource = {
                getRows: async (params) => {
                    const page = gridApi.paginationGetCurrentPage() + 1;
                    const limit = gridApi.paginationGetPageSize();

                    const sortModel = params.sortModel.length
                        ? params.sortModel[0]
                        : null;
                    const filterModel = params.filterModel ? params.filterModel : {};
                    const customParams = {
                        ...sortingParams,
                        limit,
                        orderBy: sortModel?.colId,
                        ascending: sortModel?.sort,
                        filter: filterModel,
                        page,
                    };

                    const customData = await getAnnouncementsList({
                        ...customParams
                    });
                    if (customData?.data?.status) {
                        params.successCallback(
                            customData.data?.response[0].data,
                            customData.data?.response[0].metadata[0].totalRecord
                        );
                        customParams.onLoad = false;
                        setSortingParams({ ...customParams });
                        history(location.pathname, { state: customParams });
                    } else {
                        params.successCallback([], 0);
                    }
                },
            };
            gridApi.setDatasource(dataSource);
            gridApi.api?.sizeColumnsToFit();
        }
    }, [gridApi]);

    const onFirstDataRendered = useCallback(() => {
        setTimeout(() => {
            gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
        }, 1000);
    });

    const onPaginationChanged = useCallback((params) => {
        if (gridRef.current.api) {
            const page = gridApi?.paginationGetCurrentPage();
            setSortingParams({ ...sortingParams, page });
        }
    });

    let delayTimer;
    const onSearch = (e) => {
        clearTimeout(delayTimer);
        const searchText = e.target.value.trim();
        delayTimer = setTimeout(() => {
            if (gridApi) {
                if (Object.keys(sortingParams.filter).length) {
                    gridApi.setFilterModel(sortingParams.filter);
                }
                const dataSource = {
                    getRows: async (params) => {
                        const page = gridApi.paginationGetCurrentPage() + 1;
                        const limit = gridApi.paginationGetPageSize();

                        const sortModel = params.sortModel.length
                            ? params.sortModel[0]
                            : null;
                        const filterModel = params.filterModel ? params.filterModel : {};
                        const customParams = {
                            ...sortingParams,
                            query: searchText,
                            limit,
                            orderBy: sortModel?.colId,
                            ascending: sortModel?.sort,
                            filter: filterModel,
                            page,
                        };

                        const customData = await getAnnouncementsList({
                            ...customParams
                        });
                        if (customData?.data?.status) {
                            params.successCallback(
                                customData.data.response[0].data,
                                customData.data.response[0].metadata[0].totalRecord
                            );
                            customParams.onLoad = false;
                            setSortingParams({ ...customParams });
                            history(location.pathname, { state: customParams });
                        } else {
                            params.successCallback([], 0);
                        }
                    },
                };
                gridApi.setDatasource(dataSource);
                gridApi.api?.sizeColumnsToFit();
            }
        }, 1000);
    };

    return (
        <div className="animated fadeIn userTableList">
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <div className="tabletopsec">
                                <Actions
                                />
                                {/* <div className="rightpaneltop">
                                    <Input
                                        type="text"
                                        id="search-text-box"
                                        placeholder="Search title"
                                        onKeyUp={(e) => {
                                            onSearch(e);
                                        }}
                                    />
                                </div> */}
                                <div className="rightpaneltop">
                                    Announcements Per Page
                                    <PerPageSelect
                                        perPage={sortingParams.limit}
                                        onPaginationChange={onPaginationChange}
                                    />
                                </div>
                            </div>
                            <div className="card w-100 p-0 border-0">
                                <div className="card-body p-0 position-relative">
                                    <div className="ag-theme-alpine">
                                        <AgGridReact
                                            defaultColDef={{
                                                resizable: true,
                                            }}
                                            loadingCellRenderer={customLoadingCellRenderer}
                                            loadingCellRendererParams={loadingCellRendererParams}
                                            domLayout={'autoHeight'}
                                            rowModelType={'infinite'}
                                            columnDefs={columns}
                                            pagination={true}
                                            sizeColumnsToFit={true}
                                            onFilterChanged={filterChanged}
                                            onGridReady={onGridReady}
                                            paginationPageSize={sortingParams.limit}
                                            cacheBlockSize={sortingParams.limit}
                                            cacheQuickFilter={true}
                                            onFirstDataRendered={onFirstDataRendered}
                                            onPaginationChanged={onPaginationChanged}
                                            ref={gridRef}
                                        ></AgGridReact>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

AnnouncementsList.propTypes = {
    getAnnouncementsList: PropTypes.func.isRequired,
    deleteAnnouncement: PropTypes.func.isRequired,
    announcementsList: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    subLoading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
    announcementsList: state.emailTemplate.announcementsList,
    loading: state.emailTemplate.loading,
    subLoading: state.emailTemplate.isSubLoading,
});

export default connect(mapStateToProps, {
    getAnnouncementsList,
    deleteAnnouncement,
})(AnnouncementsList);
