import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';

import { create, edit, getFlexCodeById } from 'actions/admin/flexCode';

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';

import { useNavigate } from 'react-router-dom';

const AddEditFlexCode = ({
    create,
    errorList,
    loading,
    getFlexCodeById,
    edit,
    flexCode: { currentFlexCode },
}) => {
    let navigate = useNavigate();
    /**************** use state for form data *********************/
    const [formData, setFormData] = useState({
        flex_code: '',
        flex_equivalent: '',
    });

    const { id: id } = useParams();

    useEffect(() => {
        if (id) getFlexCodeById(id);
    }, [id]);

    /**************** expload form data *********************/
    const {
        flex_code,
        flex_equivalent,
    } = formData;

    useEffect(() => {
        if (Object.keys(currentFlexCode).length > 0 && id) {
            setFormData(currentFlexCode);
        }
    }, [currentFlexCode]);

    // handle input change
    const handleChange = (e) => {
        setFormData((data1) => ({
            ...data1,
            [e?.target?.name]: e?.target?.value.trimLeft()
        }));
    };

    /**************** on submit event *********************/
    const onSubmit = (e) => {
        e.preventDefault();
        if (id) {
            edit(formData, navigate, id);
        } else {
            create(formData, navigate);
        }
    };

    return (
        <div className="animated fadeIn">
            {loading ? (
                <Spinner />
            ) : (
                <Row>
                    <Col xs="12" sm="12">
                        <Card>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => onSubmit(e)}
                                encType="multipart/form-data"
                            >
                                <CardBody>
                                    <div>
                                        <Card className="my-2">
                                            <CardHeader tag="h5">{id ? 'Edit' : 'Add'} the Flex Code Nomenclature</CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <Label htmlFor="flex_code">
                                                                Shaft Flex-Code <span>*</span>
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                id="flex_code"
                                                                name="flex_code"
                                                                value={flex_code}
                                                                required
                                                                onChange={handleChange}
                                                                invalid={errorList.flex_code ? true : false}
                                                            />
                                                            <Errors
                                                                current_key="flex_code"
                                                                key="flex_code"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <Label htmlFor="flex_equivalent">
                                                                Shaft Flex Equivalent <span>*</span>
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                id="flex_equivalent"
                                                                name="flex_equivalent"
                                                                value={flex_equivalent}
                                                                required
                                                                onChange={handleChange}
                                                                invalid={errorList.flex_equivalent ? true : false}
                                                            />
                                                            <Errors
                                                                current_key="flex_equivalent"
                                                                key="flex_equivalent"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <Button type="submit" size="sm" color="primary" disabled={loading}>
                                        <i className="fa fa-dot-circle-o"></i> Submit
                                    </Button>
                                    <Link to="/admin/flex-code-nomenclature">
                                        <Button type="reset" size="sm" color="danger">
                                            <i className="fa fa-ban"></i> Cancel
                                        </Button>
                                    </Link>
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            )}
        </div>
    );
};

AddEditFlexCode.propTypes = {
    getFlexCodeById: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    flexCode: PropTypes.object.isRequired,
    create: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
    flexCode: state.flexCode,
    errorList: state.errors,
    loading: state.flexCode.loading,
});

export default connect(mapStateToProps, {
    create,
    getFlexCodeById,
    edit,
})(AddEditFlexCode);
