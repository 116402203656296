import {
  DEALER_TYPE_MODAL_LOADING,
  DEALER_TYPE_CREATED,
  DEALER_TYPE_CREATE_ERROR,
  DEALER_TYPE_FAIL,
  DEALER_TYPE_LOADING,
  DEALER_TYPE_UPDATED,
  DEALER_TYPE_UPDATE_ERROR,
  DEALER_TYPE_LIST_UPDATED,
  GET_DEALER_TYPE_BY_ID,
  DEALER_TYPE_CHANGE_STATUS,
  DEALER_TYPE_CHANGE_STATUS_ERROR,
  DEALER_TYPE_DELETED,
  DEALER_TYPE_DELETE_ERROR,
} from "actions/types";

const initalState = {
  dealerTypeList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentDealerType: {},
  loading: false,
  isSubLoading: false,
  modal_loading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case DEALER_TYPE_MODAL_LOADING:
      return {
        ...state,
        modal_loading: true,
      };
    case DEALER_TYPE_CREATED:
      return {
        ...state,
        dealerTypeList: {
          ...state.dealerTypeList,
          data: [payload.data, ...state.dealerTypeList.data],
        },
        modal_loading: false,
      };
    case DEALER_TYPE_CREATE_ERROR:
      return {
        ...state,
        modal_loading: false,
      };
    case DEALER_TYPE_FAIL:
      return {
        ...state,
        modal_loading: false,
        loading: false,
        isSubLoading: false,
      };
    case DEALER_TYPE_LOADING:
      return {
        ...state,
        dealerTypeList: payload.is_sub_element
          ? state.dealerTypeList
          : initalState.dealerTypeList,
        currentDealerType: payload.is_sub_element
          ? state.currentDealerType
          : initalState.currentDealerType,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case DEALER_TYPE_UPDATED:
      return {
        ...state,
        currentDealerType: {},
        loading: false,
      };
    case DEALER_TYPE_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DEALER_TYPE_LIST_UPDATED:
      return {
        ...state,
        dealerTypeList: {
          data: payload.data.dealerTypeList.data,
          page: payload.data.dealerTypeList.metadata[0].current_page,
          count: payload.data.dealerTypeList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_DEALER_TYPE_BY_ID:
      return {
        ...state,
        currentDealerType: payload.data,
        loading: false,
      };
    case DEALER_TYPE_CHANGE_STATUS:
      return {
        ...state,
        dealerTypeList: {
          ...state.dealerTypeList,
          data: state.dealerTypeList.data.map((type) =>
            type._id === payload.data._id
              ? { ...type, status: payload.data.status }
              : type
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case DEALER_TYPE_CHANGE_STATUS_ERROR:
      return {
        ...state,
        dealerTypeList: {
          ...state.dealerTypeList,
          data: state.dealerTypeList.data.map((type) =>
            type._id === payload.data
              ? { ...type, is_failed: true }
              : { ...type, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case DEALER_TYPE_DELETED:
      return {
        ...state,
        loading: false,
      };
    case DEALER_TYPE_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
