/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';

import {
  getOrderById,
  addBuildShopNotes,
  deleteBuildShopNotes,
} from 'actions/admin/order';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';

const BuildShopNotes = ({
  order_id,
  product_id,
  index,
  getOrderById,
  addBuildShopNotes,
  notesValue,
  buildShopNoteEdit,
  setBuildShopNoteEdit,
  getUpdatedOrder,
}) => {
  //##################### billing address list for logged in user #########################

  const handleBuildShopNotes = async (order_id, product_id, note_box_id) => {
    let build_shop_notes = document.getElementById(note_box_id).value;
    addBuildShopNotes(order_id, product_id, build_shop_notes);
    await getOrderById(order_id);
    setBuildShopNoteEdit(false);
    await getUpdatedOrder();
  };

  return (
    <>
      <Form>
        <FormGroup>
          <Label>
            <strong>Build Shop Notes</strong>
          </Label>
          <Input
            type="textarea"
            name={'build_shop_notes_' + index}
            id={'build_shop_notes_' + index}
            defaultValue={notesValue}
            rows={12}
            cols={50}
          />
          <Button
            className="mt-2"
            onClick={async () => {
              await handleBuildShopNotes(
                order_id,
                product_id,
                'build_shop_notes_' + index
              );
            }}
            type="button"
            size="sm"
            color="primary"
          >
            Save Build Shop notes
          </Button>
        </FormGroup>
      </Form>
    </>
  );
};

BuildShopNotes.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  getOrderById,
  addBuildShopNotes,
})(BuildShopNotes);
