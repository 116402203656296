import axios from 'axios';

import {
  GEAR_SHIPPING_BOX_MODAL_LOADING,
  GEAR_SHIPPING_BOX_ADDED,
  GEAR_SHIPPING_BOX_ADDED_ERROR,
  GEAR_SHIPPING_BOX_FAIL,
  GEAR_SHIPPING_BOX_LOADING,
  DELETE_GEAR_SHIPPING_BOX,
  GEAR_SHIPPING_BOX_LIST_UPDATED,
  CHANGE_GEAR_SHIPPING_BOX_STATUS,
  CHANGE_GEAR_SHIPPING_BOX_NATIONAL_INTERNATIONAL,
  GET_GEAR_SHIPPING_BOX_BY_ID,
  GEAR_SHIPPING_BOX_UPDATED,
  GEAR_SHIPPING_BOX_UPDATED_ERROR,
  SET_ERRORS_LIST,
  REMOVE_ERRORS,
} from 'actions/types';
import { removeErrors } from './removeErrors';

// Add Shipping box
export const add = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: GEAR_SHIPPING_BOX_MODAL_LOADING,
    });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.post(
      '/api/admin/gear-shipping-box/add',
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: GEAR_SHIPPING_BOX_ADDED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });

      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: GEAR_SHIPPING_BOX_ADDED_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: GEAR_SHIPPING_BOX_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete Shipping box
export const deleteGearShippingBox = (shipping_box_id) => async (dispatch) => {
  try {
    dispatch({
      type: GEAR_SHIPPING_BOX_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(
      `/api/admin/gear-shipping-box/${shipping_box_id}`,
      config
    );
    if (res.data.status) {
      dispatch({
        type: DELETE_GEAR_SHIPPING_BOX,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: shipping_box_id,
        },
      });
      return { status: true, errors: {} };
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: GEAR_SHIPPING_BOX_ADDED_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return { status: false, errors: res.data.errors };
    }
  } catch (err) {
    dispatch({
      type: GEAR_SHIPPING_BOX_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

//get Shipping box list

export const getGearShippingBoxList =
  (shippingBoxParams) => async (dispatch) => {
    try {
      dispatch({
        type: GEAR_SHIPPING_BOX_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      removeErrors();
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: shippingBoxParams,
      };

      const res = await axios.get(`/api/admin/gear-shipping-box/`, config);

      dispatch({
        type: GEAR_SHIPPING_BOX_LIST_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: {
            gearShippingBoxParams: shippingBoxParams,
            gearShippingBoxList: res.data.response[0],
          },
        },
      });
      return res;
    } catch (err) {
      dispatch({
        type: GEAR_SHIPPING_BOX_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

//change status
export const changeStatus = (shipping_box_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: GEAR_SHIPPING_BOX_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/gear-shipping-box/change-status/${shipping_box_id}`,
      { status },
      config
    );
    if (res.data.status) {
      await dispatch({
        type: CHANGE_GEAR_SHIPPING_BOX_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return { status: true, errors: {} };
    } else {
      await dispatch({
        type: GEAR_SHIPPING_BOX_FAIL,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: res.data.response,
        },
      });
      return { status: false, errors: res.data.errors };
    }
  } catch (err) {
    dispatch({
      type: GEAR_SHIPPING_BOX_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

//Mark international or national
export const markNationalInternational =
  (shipping_box_id, is_international) => async (dispatch) => {
    try {
      dispatch({
        type: GEAR_SHIPPING_BOX_LOADING,
        payload: { is_loading: true, is_sub_element: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/gear-shipping-box/national-international/${shipping_box_id}`,
        { is_international },
        config
      );
      await dispatch({
        type: CHANGE_GEAR_SHIPPING_BOX_NATIONAL_INTERNATIONAL,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } catch (err) {
      dispatch({
        type: GEAR_SHIPPING_BOX_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
      return false;
    }
  };

// Get shipping box  by id
export const getGearShippingBoxById = (shipping_box_id) => async (dispatch) => {
  try {
    dispatch({
      type: GEAR_SHIPPING_BOX_MODAL_LOADING,
    });
    dispatch({
      type: REMOVE_ERRORS,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/gear-shipping-box/${shipping_box_id}`,
      config
    );
    await dispatch({
      type: GET_GEAR_SHIPPING_BOX_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
    return res.data.response;
  } catch (err) {
    dispatch({
      type: GEAR_SHIPPING_BOX_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit shipping box
export const edit = (formData, shipping_box_id) => async (dispatch) => {
  try {
    dispatch({
      type: GEAR_SHIPPING_BOX_MODAL_LOADING,
    });

    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.post(
      `/api/admin/gear-shipping-box/${shipping_box_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: GEAR_SHIPPING_BOX_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: GEAR_SHIPPING_BOX_UPDATED_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: GEAR_SHIPPING_BOX_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};
