/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import Errors from 'views/Notifications/Errors';
import { Row, Col, Input } from 'reactstrap';

const Variations = ({
  currentProduct,
  variation,
  setVariation,
  quantity,
  isEdit,
  setSelectedFitting,
  setProductVariationDetails,
  setVariationSelectErrorMsg,
  selectedFittingsData,
  setQuantity,
  selectedProduct,
  setSelectedFittingsData,
  setCustomization,
  setStockQuantity,
}) => {
  const gripOption = 'grip_option';
  const adaptor = 'adaptor';
  const [fittingList, setFittingList] = useState({});
  const fittingAttributeList = currentProduct.fittingData;
  const onFittingChange = (id, value) => {
    if (id == adaptor || id == gripOption) {
      const fittingsG = getFittings(
        id === gripOption ? 'grip' : id,
        id === gripOption
      ).find((e) => e._id == value);

    }
    if (id == adaptor) {
      setFittingList((st) => ({ ...st, adaptor_option: '' }));
      setSelectedFitting((st) => ({ ...st, adaptor_option: '' }));
      setSelectedFittingsData((st) => ({ ...st, adaptor_option: '' }));
    }
    if (id == 'grip') {
      setFittingList((st) => ({ ...st, grip_option: '' }));
      setSelectedFitting((st) => ({ ...st, grip_option: '' }));
      setSelectedFittingsData((st) => ({ ...st, grip_option: '' }));
    }

    setFittingList((st) => ({ ...st, [id]: value }));
    setSelectedFitting((st) => ({ ...st, [id]: value }));
  };
  const getFittings = (title, isOption = false) => {
    const fitting = fittingAttributeList?.find((ftt) =>
      ftt.title.toLowerCase().includes(title)
    )?.fittings?.filter(ft => ft.categoryIds.includes(currentProduct.categoryId._id));;
    if (isOption && fitting) {
      if (fittingList[title]) {
        return fitting.find((ft) => ft._id === fittingList[title])?.options;
      } else if (selectedFittingsData && selectedFittingsData[title]) {
        return fitting.find((ft) => ft._id === selectedFittingsData[title])
          ?.options;
      }
      return [];
    }

    return fitting ?? [];
  };

  let selected_varitions = {};
  let selectedStockQuentity = 0;

  const handelOnChange = (e) => {
    selected_varitions = { ...variation };
    selected_varitions[e.target.id] = e.target.value;
    let message = false;

    if (
      Object.keys(selected_varitions).length ===
      currentProduct.attributes.length
    ) {
      let variationIsFound = false;
      currentProduct.variations.forEach((variationDetail) => {
        let variationCount = variationDetail.variation.length;
        let totalCount = 0;
        variationDetail.variation.forEach((val) => {
          if (
            selected_varitions[val.attribute_id] &&
            selected_varitions[val.attribute_id] === val.option_id
          )
            totalCount += 1;
        });

        if (totalCount === variationCount) {

          variationIsFound = true;
          setStockQuantity(variationDetail.stock_quantity);
          selectedStockQuentity = variationDetail.stock_quantity;
          setProductVariationDetails({
            product_id: variationDetail.productId,
            product_variation_sku: variationDetail.sku,
            product_variation_id: variationDetail._id,
            quantity: quantity,
          });
          message = '';
        }
      });

      //if variation does not matches then reset it
      if (!variationIsFound) {
        setProductVariationDetails({});
        message = 'Selected combination is not available.';
      } else {
        if (selectedStockQuentity <= 0) {
          setProductVariationDetails({});
          message = 'Just ran out of stock. Please select a different option.';
        } else {
          message = '';
        }
      }
    }
    setVariationSelectErrorMsg(message);
    setVariation(selected_varitions);
  };

  const [customShaftChecked, setCustomShaftChecked] = useState(true);

  const handelQuantityChange = (e) => {
    setQuantity(e.target.value);
  };
  useEffect(() => {
    selectedFittingsData &&
      selectedFittingsData.adaptor &&
      setCustomShaftChecked(false);

    if (currentProduct && currentProduct.variations.length) {

      const variationData = {};

      if (isEdit) {

        Object.keys(selectedFittingsData).length > 0
          ? setCustomization(true)
          : setCustomization(false);
        Object.keys(selectedFittingsData).length > 0
          ? setSelectedFitting(selectedFittingsData)
          : setSelectedFitting({});
        setProductVariationDetails({
          product_id: selectedProduct.product_id,
          product_variation_sku: selectedProduct.productVariationSku,
          product_variation_id: selectedProduct.product_id,
          quantity: selectedProduct.quantity,
        });

      } else {
        setCustomShaftChecked(true);
        setSelectedFittingsData({});
        currentProduct.variations.map((item, index) => {
          if (item.isDefault === true) {
            if (item.stock_quantity <= 0) {
              setProductVariationDetails({});
              setVariationSelectErrorMsg(
                'Just ran out of stock. Please select a different option.'
              );
            } else {
              setVariationSelectErrorMsg('');
            }

            item.variation.map((variation) => {
              variationData[variation.attribute_id] = variation.option_id;
            });

            setVariation(variationData);
            setProductVariationDetails({
              product_id: item.productId,
              product_variation_sku: item.sku,
              product_variation_id: item._id,
              quantity: quantity,
            });
          }
        });
      }
    }
  }, [isEdit]);

  const handelCustomShaftChange = useCallback((e) => {
    if (e.target.checked) {

      setQuantity(1);
    } else {

      setSelectedFitting([]);
      setSelectedFittingsData({});
    }
    setCustomization(e.target.checked);
    setCustomShaftChecked(!customShaftChecked);
  }, [customShaftChecked]);

  console.log(currentProduct, 'currentProduct')

  return currentProduct.attributes && currentProduct.attributes.length > 0 ? (
    <>
      <div className="quantity-tab">
        <b>Quantity : </b>
        {customShaftChecked && customShaftChecked == true ? (
          <>
            <div className="quantitytab">
              <button
                disabled={quantity === 1}
                className="btn"
                onClick={() => setQuantity((qty) => (qty == 1 ? qty : qty - 1))}
              >
                <AiOutlineMinus />
              </button>
              <input
                type="number"
                className="form-control"
                value={quantity}
                onChange={(e) => handelQuantityChange(e)}
              />

              <button
                className="btn"
                onClick={() => setQuantity((qty) => qty + 1)}
              >
                <AiOutlinePlus />
              </button>
            </div>
          </>
        ) : (
          <>
            <span>{quantity}</span>
          </>
        )}
      </div>
      {currentProduct.attributes.map((item, index) => {
        return (
          <div className="form-group" key={item.attribute_id}>
            <label htmlFor={item.attribute_name} className="variation-heading">
              {item.attribute_name && item.attribute_name == 'Colors'
                ? 'Color'
                : item.attribute_name}
            </label>
            <div className="">
              <Input
                type="select"
                name={item.attribute_name}
                id={item.attribute_id}
                onChange={handelOnChange}
                required={true}
                value={variation[item.attribute_id]}
              >
                {item.attributes.map((val, index) => {
                  return (
                    <option
                      value={val.option_id}
                      key={val.option_id}

                    >
                      {val.option_term}
                    </option>
                  );
                })}
              </Input>
              <Errors
                current_key={item.attribute_name}
                key={item.attribute_name}
              />
            </div>
          </div>
        );
      })}
      {currentProduct.categoryId.customization && (
        <div className="form-group mt-3">
          <input
            type="checkbox"
            name="customize_shaft"
            checked={
              !customShaftChecked
            }
            id="customize_shaft"
            onClick={handelCustomShaftChange}
            onChange={() => { }}
          />
          <label className="padding-left">Customize Shaft</label>
        </div>
      )}

      {customShaftChecked && customShaftChecked == true ? (
        ''
      ) : (
        <div className="selectcolor">
          <div className="selectproductcolor">
            <Row className="mt-3">
              <Col md="12">
                <Row>
                  <Col md="6">
                    <div className="form-group">
                      <label>Clubhead Make and Model</label>
                      <Input
                        type="select"
                        name="adaptor"
                        onChange={(e) => {
                          onFittingChange(adaptor, e.target.value);
                        }}
                        required
                      >
                        <option value="">Select Model</option>``
                        {selectedFittingsData && selectedFittingsData.adaptor
                          ? getFittings(adaptor)?.map((ftt) => (
                            <option
                              key={ftt._id}
                              value={ftt._id}
                              selected={
                                ftt._id == selectedFittingsData.adaptor
                              }
                            >
                              {ftt.title} / ${ftt.price}
                            </option>
                          ))
                          : getFittings(adaptor)?.map((ftt) => (
                            <option key={ftt._id} value={ftt._id}>
                              {ftt.title} / ${ftt.price}
                            </option>
                          ))}
                      </Input>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label>Adaptor Setting</label>
                      <Input
                        type="select"
                        name="adaptor_setting"
                        onChange={(e) => {
                          onFittingChange('adaptor_option', e.target.value);
                        }}
                        required
                      >
                        <option value="">Select Setting</option>
                        {selectedFittingsData &&
                          selectedFittingsData.adaptor_option
                          ? getFittings(adaptor, true)?.map((ftt) => (
                            <option
                              key={ftt._id}
                              value={ftt._id}
                              selected={
                                ftt._id == selectedFittingsData.adaptor_option
                              }
                            >
                              {ftt.title}
                            </option>
                          ))
                          : getFittings(adaptor, true)?.map((ftt) => (
                            <option key={ftt._id} value={ftt._id}>
                              {ftt.title}
                            </option>
                          ))}
                      </Input>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md="12" className="mt-3">
                <Row>
                  <Col md="6">
                    <div className="form-group">
                      <label>Playing Length</label>
                      <Input
                        type="select"
                        name="playing_length"
                        onChange={(e) => {
                          onFittingChange('playing', e.target.value);
                        }}
                        required
                      >
                        <option value="">Select Length</option>
                        {selectedFittingsData && selectedFittingsData.playing
                          ? getFittings('playing')?.map((ftt) => (
                            <option
                              key={ftt._id}
                              value={ftt._id}
                              selected={
                                ftt._id == selectedFittingsData.playing
                              }
                            >
                              {ftt.title}
                            </option>
                          ))
                          : getFittings('playing')?.map((ftt) => (
                            <option key={ftt._id} value={ftt._id}>
                              {ftt.title}
                            </option>
                          ))}
                      </Input>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md="12" className="mt-3">
                <Row>
                  <Col md="6">
                    <div className="form-group">
                      <label>Grip: Select Brand</label>
                      <Input
                        type="select"
                        name="grip"
                        onChange={(e) => {
                          onFittingChange('grip', e.target.value);
                        }}
                        required
                      >
                        <option value="">Select Brand</option>
                        {selectedFittingsData && selectedFittingsData.grip
                          ? getFittings('grip')?.map((ftt) => (
                            <option
                              key={ftt._id}
                              value={ftt._id}
                              selected={ftt._id == selectedFittingsData.grip}
                            >
                              {ftt.title}
                            </option>
                          ))
                          : getFittings('grip')?.map((ftt) => (
                            <option key={ftt._id} value={ftt._id}>
                              {ftt.title}
                            </option>
                          ))}
                      </Input>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label>Grip: Select Model</label>
                      <Input
                        type="select"
                        name="grip_model"
                        onChange={(e) => {
                          onFittingChange(gripOption, e.target.value);
                        }}
                        required
                      >
                        <option value="">Select Model</option>
                        {selectedFittingsData &&
                          selectedFittingsData.grip_option
                          ? getFittings('grip', true)?.map((ftt) => (
                            <option
                              key={ftt._id}
                              value={ftt._id}
                              selected={
                                ftt._id == selectedFittingsData.grip_option
                              }
                            >
                              {ftt.title}
                            </option>
                          ))
                          : getFittings('grip', true)?.map((ftt) => (
                            <option key={ftt._id} value={ftt._id}>
                              {ftt.title}
                            </option>
                          ))}
                      </Input>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md="12" className="mt-3">
                <Row>
                  <Col md="6">
                    <div className="form-group">
                      <label>Extra Wraps</label>
                      <Input
                        type="select"
                        name="extra_wraps"
                        onChange={(e) => {
                          onFittingChange('extra_wrap', e.target.value);
                        }}
                        required
                      >
                        <option value="">Select Wraps</option>
                        {selectedFittingsData &&
                          selectedFittingsData.extra_wrap ? (
                          <>
                            <option
                              value="1"
                              selected={1 == selectedFittingsData.extra_wrap}
                            >
                              None
                            </option>
                            <option
                              value="2"
                              selected={2 == selectedFittingsData.extra_wrap}
                            >
                              1
                            </option>
                            <option
                              value="3"
                              selected={3 == selectedFittingsData.extra_wrap}
                            >
                              2
                            </option>
                          </>
                        ) : (
                          <>
                            <option value="1">None</option>
                            <option value="2">1</option>
                            <option value="3">2</option>
                          </>
                        )}
                      </Input>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label>Grip Logo</label>
                      <Input
                        type="select"
                        name="grip_logo"
                        onChange={(e) => {
                          onFittingChange('grip_logo', e.target.value);
                        }}
                        required
                      >
                        {selectedFittingsData &&
                          selectedFittingsData.grip_logo ? (
                          <>
                            <option value="">Select Grip Logo</option>
                            <option
                              value="up"
                              selected={'up' == selectedFittingsData.grip_logo}
                            >
                              Up
                            </option>
                            <option
                              value="down"
                              selected={
                                'down' == selectedFittingsData.grip_logo
                              }
                            >
                              Down
                            </option>
                          </>
                        ) : (
                          <>
                            <option>Select Grip Logo</option>
                            <option value="up">Up</option>
                            <option value="down">Down</option>
                          </>
                        )}
                      </Input>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md="12" className="mt-3">
                <Row>
                  <Col md="12">
                    <div className="form-group">
                      <label>Product Customization Notes</label>
                      <Input
                        type="textarea"
                        minLength={0}
                        maxLength={255}
                        name="product_customization"
                        defaultValue={
                          selectedFittingsData
                            ? selectedFittingsData.product_customization
                            : ''
                        }
                        onChange={(e) => {
                          onFittingChange(
                            'product_customization',
                            e.target.value
                          );
                        }}
                      ></Input>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  ) : (
    <div>Out of stock OR not allowed to purchase</div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Variations);
