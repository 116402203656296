import React from 'react';

import {
  FaHouseUser,
  FaUserAlt,
  FaListAlt,
  FaUsers,
  FaUserShield,
  FaUserTie,
  FaPhotoVideo,
  FaSolarPanel,
  FaTasks,
  FaBoxes,
  FaBacon,
  FaBoxOpen,
  FaTags,
  FaClipboardList,
  FaClipboard,
  FaImage,
  FaVideo,
  FaPager,
  FaBlogger,
  FaRegShareSquare,
  FaQuestionCircle,
  FaSms,
  FaPercent,
  FaStar,
  FaChevronCircleUp,
  FaStoreAltSlash,
  FaUndo
} from 'react-icons/fa';

import { FiSettings } from 'react-icons/fi';
import { AiOutlineNotification } from 'react-icons/ai';

export const navAdmin = [
  {
    name: 'Dashboard',
    to: '/admin',
    icon: FaHouseUser,
  },
  {
    name: 'Staff',
    to: '/admin/staff-members',
    icon: FaUsers,
  },
  {
    name: 'Customers',
    to: '/admin/customers',
    icon: FaUserAlt,
  },
  {
    name: 'Dealers',
    to: '/admin/dealers',
    icon: FaUserShield,
    children: [
      {
        name: 'Dealers',
        to: '/admin/dealers',
        icon: <FaUserTie />,
      },
      {
        name: 'Dealer Types',
        to: '/admin/dealer-types',
        icon: <FaUserShield />,
      },
    ],
  },
  {
    name: 'Products (Shafts)',
    icon: FaListAlt,

    children: [
      {
        name: 'Attributes',
        to: '/admin/attributes',
        icon: <FaSolarPanel />,
      },
      {
        name: 'Categories',
        to: '/admin/categories',
        icon: <FaTasks />,
      },
      {
        name: 'Product Fitting',
        to: '/admin/product/fittings',
        icon: <FaBoxes />,
      },
      {
        name: 'Products',
        to: '/admin/products',
        icon: <FaBacon />,
      },
      {
        name: 'Services',
        to: '/admin/services',
        icon: <FaBacon />,
      },
      {
        name: 'Build Shop Supply',
        to: '/admin/build-shop-supply',
        icon: <FaBoxes />,
      },
      {
        name: 'Shipping Boxes',
        to: '/admin/shipping-boxes',
        icon: <FaBoxOpen />,
      },
      {
        name: 'Specifications',
        to: '/admin/specs',
        icon: <FaSolarPanel />,
      },
      {
        name: 'Flex Code Nomenclature',
        to: '/admin/flex-code-nomenclature',
        icon: <FaBacon />,
      },
      {
        name: 'Iron Number Nomenclature',
        to: '/admin/iron-number-nomenclature',
        icon: <FaBacon />,
      },
    ],
  },
  {
    name: 'Products (Gear)',
    icon: FaListAlt,
    children: [
      {
        name: 'Attributes',
        to: '/admin/gear-attributes',
        icon: <FaSolarPanel />,
      },
      {
        name: 'Categories',
        to: '/admin/gear-categories',
        icon: <FaTasks />,
      },
      {
        name: 'Products',
        to: '/admin/gear-products',
        icon: <FaBacon />,
      },
      {
        name: 'Shipping Boxes',
        to: '/admin/gear/shipping-boxes',
        icon: <FaBoxOpen />,
      },
    ],
  },
  {
    name: 'Orders',
    to: '/admin/orders',
    icon: FaListAlt,

    children: [
      {
        name: 'Order List',
        to: '/admin/orders',
        icon: <FaClipboardList />,
      },
      {
        name: 'Create Product Order',
        to: '/admin/order/create',
        icon: <FaClipboard />,
      },
      {
        name: 'Create Service Order',
        to: '/admin/service/create',
        icon: <FaClipboard />,
      },
      {
        name: 'Notify Me',
        to: '/admin/notifies',
        icon: <FaRegShareSquare />,
      },
      {
        name: "Product Selector Results",
        to: '/admin/product-selector',
        icon: <FaClipboardList />,
      },
      {
        name: "Shipping Additionals",
        to: '/admin/orders/shipping-additionals',
        icon: <FaChevronCircleUp />,
      },
      {
        name: "Shipping Cancelations",
        to: '/admin/orders/shipping-cancelations',
        icon: <FaStoreAltSlash />
      },
      {
        name: "Returns",
        to: '/admin/returns',
        icon: <FaUndo />
      }
    ],
  },
  {
    name: "Kinetixx Fit",
    to: '/admin/kinetixx-fit/edit/category',
    icon: FaClipboardList,
  },
  // {
  //   name: 'Videos',
  //   to: '/admin/gallery-video',
  //   icon: FaVideo,
  //   // children: [
  //   //   // {
  //   //   //   name: 'Images',
  //   //   //   to: '/admin/gallery-image',
  //   //   //   icon: <FaImage />,
  //   //   // },
  //   //   {
  //   //     name: 'Videos',
  //   //     to: '/admin/gallery-video',
  //   //     icon: <FaVideo />,
  //   //   },
  //   // ],
  // },
  {
    name: 'CMS',
    icon: FaListAlt,
    children: [
      {
        name: 'Home Page',
        to: `/admin/home/edit/home`,
        icon: <FaPager />,
      },
      {
        name: 'Other Pages',
        to: '/admin/cms',
        icon: <FaPager />,
      },
      {
        name: 'News & Media Articles',
        to: '/admin/news',
        icon: <FaBlogger />,
      },
      {
        name: 'NewsLetter Subscribers',
        to: '/admin/newsletter',
        icon: <FaBlogger />,
      },
      {
        name: 'Videos',
        to: '/admin/gallery-video',
        icon: <FaVideo />,
      },
      {
        name: 'Resources',
        to: '/admin/resources',
        icon: <FaListAlt />
      },
      // {
      //   name: 'Blogs',
      //   to: '/admin/blogs',
      //   icon: <FaBlogger />,
      // },
    ],
  },
  {
    name: 'Coupons',
    to: '/admin/coupons',
    icon: FaTags,
  },
  {
    name: 'Reviews',
    to: '/admin/reviews',
    icon: FaStar,
  },
  // {
  //   name: 'Notifications',
  //   icon: AiOutlineNotification,
  //   children: [
  //     {
  //       name: 'Email Notifications NEW',
  //       to: '/admin/email-template-sections',
  //       icon: <FaRegShareSquare />,
  //     },
  //     {
  //       name: 'Email Notifications',
  //       to: '/admin/email-templates',
  //       icon: <FaRegShareSquare />,
  //     },
  //     {
  //       name: 'SMS Notifications',
  //       to: '/admin/sms-templates',
  //       icon: <FaSms />,
  //     },
  //   ],
  // },
  {
    name: 'Notifications',
    to: '/admin/email-template-sections',
    icon: AiOutlineNotification,
  },
  {
    name: 'Announcements',
    to: '/admin/announcements-list',
    icon: AiOutlineNotification
  },
  {
    name: 'Inquiries',
    to: '/admin/inquiries',
    icon: FaQuestionCircle,
  },
  {
    name: 'California Tax Rate',
    to: '/admin/tax-rates',
    icon: FaPercent,
  },
  {
    name: 'Settings',
    to: '/admin/settings',
    icon: FiSettings,
  },
];

export const navOrderManager = [
  // {
  //   name: 'Dashboard',
  //   to: '/admin',
  //   icon: FaHouseUser,
  // },
  {
    name: 'Orders',
    to: '/admin/orders',
    icon: FaListAlt,
    children: [
      {
        name: 'Order List',
        to: '/admin/orders',
        icon: <FaClipboardList />,
      },
      {
        name: 'Create Product Order',
        to: '/admin/order/create',
        icon: <FaClipboard />,
      },
      {
        name: 'Create Service Order',
        to: '/admin/service/create',
        icon: <FaClipboard />,
      },
    ],
  },
];

export const navBuildShopManager = [
  {
    name: 'Orders',
    to: '/admin/orders',
    icon: FaListAlt,
    children: [
      {
        name: 'Order List',
        to: '/admin/orders',
        icon: <FaClipboardList />,
      },
    ],
  },
]

export const navProductManager = [
  // {
  //   name: 'Dashboard',
  //   to: '/admin',
  //   icon: FaHouseUser,
  // },
  {
    name: 'Products (Shaft)',
    icon: FaListAlt,
    children: [
      {
        name: 'Attributes',
        to: '/admin/attributes',
        icon: <FaSolarPanel />,
      },
      {
        name: 'Categories',
        to: '/admin/categories',
        icon: <FaTasks />,
      },
      {
        name: 'Product Fitting',
        to: '/admin/product/fittings',
        icon: <FaBoxes />,
      },
      {
        name: 'Products',
        to: '/admin/products',
        icon: <FaBacon />,
      },
      {
        name: 'Shipping Boxes',
        to: '/admin/shipping-boxes',
        icon: <FaBoxOpen />,
      },
      {
        name: 'Specifications',
        to: '/admin/specs',
        icon: <FaSolarPanel />,
      },
      {
        name: 'Flex Code Nomenclature',
        to: '/admin/flex-code-nomenclature',
        icon: <FaBacon />,
      },
      {
        name: 'Iron Number Nomenclature',
        to: '/admin/iron-number-nomenclature',
        icon: <FaBacon />,
      },
    ],
  },
  {
    name: 'Products (Gear)',
    icon: FaListAlt,
    children: [
      {
        name: 'Attributes',
        to: '/admin/gear-attributes',
        icon: <FaSolarPanel />,
      },
      {
        name: 'Categories',
        to: '/admin/gear-categories',
        icon: <FaTasks />,
      },
      {
        name: 'Products',
        to: '/admin/gear-products',
        icon: <FaBacon />,
      },
      {
        name: 'Shipping Boxes',
        to: '/admin/gear/shipping-boxes',
        icon: <FaBoxOpen />,
      },
    ],
  },
  {
    name: 'Coupon code',
    to: '/admin/coupons',
    icon: FaTags,
  },
  {
    name: 'Reviews',
    to: '/admin/reviews',
    icon: FaStar,
  },
];

export const navShippingManager = [
  // {
  //   name: 'Dashboard',
  //   to: '/admin',
  //   icon: FaHouseUser,
  // },
  {
    name: 'Orders',
    to: '/admin/orders',
    icon: FaListAlt,

    children: [
      {
        name: 'Order List',
        to: '/admin/orders',
        icon: <FaClipboardList />,
      },
    ],
  },
];

export const navCmsManager = [
  // {
  //   name: 'Dashboard',
  //   to: '/admin',
  //   icon: FaHouseUser,
  // },
  // {
  //   name: 'Videos',
  //   to: '/admin/gallery-video',
  //   icon: FaVideo,
  //   // children: [
  //   //   // {
  //   //   //   name: 'Images',
  //   //   //   to: '/admin/gallery-image',
  //   //   //   icon: <FaImage />,
  //   //   // },
  //   //   {
  //   //     name: 'Videos',
  //   //     to: '/admin/gallery-video',
  //   //     icon: <FaVideo />,
  //   //   },
  //   // ],
  // },
  {
    name: 'CMS',
    icon: FaListAlt,
    children: [
      {
        name: 'Home Page',
        to: `/admin/home/edit/home`,
        icon: <FaPager />,
      },
      {
        name: 'Other Pages',
        to: '/admin/cms',
        icon: <FaPager />,
      },
      {
        name: 'News & Media Articles',
        to: '/admin/news',
        icon: <FaBlogger />,
      },
      {
        name: 'Videos',
        to: '/admin/gallery-video',
        icon: <FaVideo />,
      },
      {
        name: 'NewsLetter Subscribers',
        to: '/admin/newsletter',
        icon: <FaBlogger />,
      },
    ],
  },
  {
    name: 'Notifications',
    to: '/admin/email-template-sections',
    icon: AiOutlineNotification,
  },
  {
    name: 'Inquiries',
    to: '/admin/inquiries',
    icon: FaQuestionCircle,
  },
];

export const navCustomerManager = [
  // {
  //   name: 'Dashboard',
  //   to: '/admin',
  //   icon: FaHouseUser,
  // },
  {
    name: 'Customers',
    to: '/admin/customers',
    icon: FaUserAlt,
  },
  {
    name: 'Dealers',
    to: '/admin/dealers',
    icon: FaUserShield,
    children: [
      {
        name: 'Dealer Types',
        to: '/admin/dealer-types',
        icon: <FaUserShield />,
      },
      {
        name: 'Dealers',
        to: '/admin/dealers',
        icon: <FaUserTie />,
      },
    ],
  },
];
