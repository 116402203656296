//alert constants
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

//loading constant
export const INITIAL_LOADING = 'INITIAL_LOADING';
export const LOADING_ON_SUBMIT = 'LOADING_ON_SUBMIT';

//errors constnats
export const SET_ERRORS_LIST = 'SET_ERRORS_LIST';
export const REMOVE_ERRORS = 'REMOVE_ERRORS';

//login/logout/register, auth, profile constnats
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_ERROR = 'AUTH_ERROR';
export const NO_USER_LOADED = 'NO_USER_LOADED';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_SUCCESS_ALERT = 'LOGIN_SUCCESS_ALERT';

export const LOGOUT = 'LOGOUT';

export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';

export const USER_LOADED = 'USER_LOADED';

export const GET_PROFILE = 'GET_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const LOADING_ON_PASSWORD_MODAL_SUBMIT =
  'LOADING_ON_PASSWORD_MODAL_SUBMIT';
export const RESET_PASSWORD_MODAL_LOADING = 'RESET_PASSWORD_MODAL_LOADING';
export const RESET_LINK_GET_DETAIL_SUCCESS = 'RESET_LINK_GET_DETAIL_SUCCESS';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

//Users constants
export const USER_LOADING = 'USER_LOADING';
export const USER_CREATED = 'USER_CREATED';
export const USER_CREATE_ERROR = 'USER_CREATE_ERROR';
export const USER_FAIL = 'USER_FAIL';
export const USER_MODAL_LOADING = 'USER_MODAL_LOADING';
export const USER_DETAIL_PASSWORD_UPDATED = 'USER_DETAIL_PASSWORD_UPDATED';
export const USER_DETAIL_PASSWORD_UPDATE_ERROR =
  'USER_DETAIL_PASSWORD_UPDATE_ERROR';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';
export const USER_DELETED = 'USER_DELETED';
export const USER_DELETE_ERROR = 'USER_DELETE_ERROR';
export const USER_LIST_UPDATED = 'USER_LIST_UPDATED';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const USER_CHANGE_STATUS = 'USER_CHANGE_STATUS';
export const USER_CHANGE_STATUS_ERROR = 'USER_CHANGE_STATUS_ERROR';
export const USER_CHANGE_STATUS_BULK = 'USER_CHANGE_STATUS_BULK';
export const USER_CHANGE_STATUS_BULK_ERROR = 'USER_CHANGE_STATUS_BULK_ERROR';
export const GET_USER_BY_ID_ERROR = 'GET_USER_BY_ID_ERROR';

// Users address constants
export const USER_ADDRESS_LOADING = 'USER_ADDRESS_LOADING';
export const USER_ADDRESS_CREATED = 'USER_ADDRESS_CREATED';
export const USER_ADDRESS_CREATE_ERROR = 'USER_ADDRESS_CREATE_ERROR';
export const USER_ADDRESS_FAIL = 'USER_ADDRESS_FAIL';
export const USER_ADDRESS_UPDATED = 'USER_ADDRESS_UPDATED';
export const USER_ADDRESS_UPDATE_ERROR = 'USER_ADDRESS_UPDATE_ERROR';
export const USER_ADDRESS_DELETED = 'USER_ADDRESS_DELETED';
export const USER_ADDRESS_DELETE_ERROR = 'USER_ADDRESS_DELETE_ERROR';
export const USER_ADDRESS_LIST_UPDATED = 'USER_ADDRESS_LIST_UPDATED';
export const GET_USER_ADDRESS_BY_ID = 'GET_USER_ADDRESS_BY_ID';
export const USER_ADDRESS_PRIMARY = 'USER_ADDRESS_PRIMARY';
export const USER_ADDRESS_PRIMARY_ERROR = 'USER_ADDRESS_PRIMARY_ERROR';
export const USER_ADDRESS_CHANGE_STATUS = 'USER_ADDRESS_CHANGE_STATUS';
export const USER_ADDRESS_CHANGE_STATUS_ERROR =
  'USER_ADDRESS_CHANGE_STATUS_ERROR';
export const GET_USER_DETAILS_BY_ID = 'GET_USER_DETAILS_BY_ID';

//PRODUCT Constants
export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const INVENTORY_LOADING = 'INVENTORY_LOADING';
export const PRODUCT_CREATED = 'PRODUCT_CREATED';
export const ADDED_DEFAULT_VARIATION = 'ADDED_DEFAULT_VARIATION';
export const PRODUCT_CREATE_ERROR = 'PRODUCT_CREATE_ERROR';
export const PRODUCT_FAIL = 'PRODUCT_FAIL';
export const SET_CATEGORY_LOADER = 'SET_CATEGORY_LOADER';
export const CATEGORY_LIST_PRODUCT = 'CATEGORY_LIST_PRODUCT';
export const SET_ATTRIBUTE_LOADER = 'SET_ATTRIBUTE_LOADER';
export const ATTRIBUTES_LIST_PRODUCT = 'ATTRIBUTES_LIST_PRODUCT';

export const SET_SPECS_LOADER = 'SET_SPECS_LOADER';
export const SPECS_LIST_PRODUCT = 'SPECS_LIST_PRODUCT';

export const PRODUCT_UPDATED = 'PRODUCT_UPDATED';
export const INVENTORY_UPLOADED = 'INVENTORY_UPLOADED';
export const SPEC_ADD = 'SPEC_ADD';

export const PRODUCT_UPDATE_ERROR = 'PRODUCT_UPDATE_ERROR';
export const INVENTORY_UPLOADED_ERROR = 'INVENTORY_UPLOADED_ERROR';
export const PRODUCT_LIST_UPDATED = 'PRODUCT_LIST_UPDATED';
export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID';
export const CHANGE_PRODUCT_STATUS = 'CHANGE_PRODUCT_STATUS';
export const CHANGE_PRODUCT_STATUS_ERROR = 'CHANGE_PRODUCT_STATUS_ERROR';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR';
export const GET_PRODUCT_IMAGES = 'GET_PRODUCT_IMAGES';
export const DELETE_PRODUCT_IMAGE = 'DELETE_PRODUCT_IMAGE';
export const PRIMARY_IMAGE_UPDATED = 'PRIMARY_IMAGE_UPDATED';
export const WHOLESALE_PRICE_ERROR = 'WHOLESALE_PRICE_ERROR';
export const SALE_PRICE_ERROR = 'SALE_PRICE_ERROR';
export const UPDATE_PRODUCT_ATTRIBUTES = 'UPDATE_PRODUCT_ATTRIBUTES';
export const UPDATE_PRODUCT_ATTRIBUTES_OPTIONS = 'UPDATE_PRODUCT_ATTRIBUTES_OPTIONS';
export const UPDATE_PRODUCT_ATTRIBUTES_ERROR =
  'UPDATE_PRODUCT_ATTRIBUTES_ERROR';

export const UPDATE_PRODUCT_SPECS = 'UPDATE_PRODUCT_SPECS';
export const UPDATE_PRODUCT_SPECS_ERROR = 'UPDATE_PRODUCT_SPECS_ERROR';
export const VARIATION_UPDATED = 'VARIATION_UPDATED';
export const VARIATION_UPDATED_ERROR = 'VARIATION_UPDATED_ERROR';
export const RESET_CURRENT_PRODUCT = 'RESET_CURRENT_PRODUCT';
export const CHANGE_PRODUCT_STATUS_BULK = 'CHANGE_PRODUCT_STATUS_BULK';
export const INVENTORY_AND_SHIPPING_UPDATED = 'INVENTORY_AND_SHIPPING_UPDATED';
export const INVENTORY_AND_SHIPPING_UPDATED_ERROR =
  'INVENTORY_AND_SHIPPING_UPDATED_ERROR';
export const IMAGE_UPLOAD_ERROR = 'IMAGE_UPLOAD_ERROR';
export const UPDATE_PRODUCT_IMAGES = 'UPDATE_PRODUCT_IMAGES';
export const VARIATION_IMAGE_UPDATED = 'VARIATION_IMAGE_UPDATED';
export const VARIATION_IMAGE_ERROR = 'VARIATION_IMAGE_ERROR';
export const UPDATE_PRODUCT_IMAGES_ERROR = 'UPDATE_PRODUCT_IMAGES_ERROR';
export const PRODUCT_ATTRIBUTE_TAG_DELETE = 'PRODUCT_ATTRIBUTE_TAG_DELETE';
export const PRODUCT_ATTRIBUTE_TAG_DELETE_ERROR =
  'PRODUCT_ATTRIBUTE_TAG_DELETE_ERROR';
export const PRODUCT_ATTRIBUTE_OPTION_CREATED =
  'PRODUCT_ATTRIBUTE_OPTION_CREATED';
export const PRODUCT_ATTRIBUTE_OPTION_CREATE_ERROR =
  'PRODUCT_ATTRIBUTE_OPTION_CREATE_ERROR';
export const SET_PRODUCT_ATTRIBUTE_OPTION_LOADER =
  'SET_PRODUCT_ATTRIBUTE_OPTION_LOADER';

export const PRODUCT_SPECS_TAG_DELETE_ERROR = 'PRODUCT_SPECS_TAG_DELETE_ERROR';
export const PRODUCT_SPECS_OPTION_CREATED = 'PRODUCT_SPECS_OPTION_CREATED';
export const PRODUCT_SPECS_OPTION_CREATE_ERROR =
  'PRODUCT_SPECS_OPTION_CREATE_ERROR';
export const SET_PRODUCT_SPECS_OPTION_LOADER =
  'SET_PRODUCT_SPECS_OPTION_LOADER';

//Fitting attribute constatns
export const FITTING_PART_DETAILS = 'FITTING_PART_DETAILS';
export const RESET_FITTING_PART_DETAILS = 'RESET_FITTING_PART_DETAILS';
export const FITTING_ATTRIBUTE_LOADING = 'FITTING_ATTRIBUTE_LOADING';
export const FITTING_ATTRIBUTE_LIST_UPDATED = 'FITTING_ATTRIBUTE_LIST_UPDATED';
export const FITTING_ATTRIBUTE_FAIL = 'FITTING_ATTRIBUTE_FAIL';
export const FITTING_ATTRIBUTE_MODAL_LOADING =
  'FITTING_ATTRIBUTE_MODAL_LOADING';
export const FITTING_ATTRIBUTE_CREATED = 'FITTING_ATTRIBUTE_CREATED';
export const FITTING_ATTRIBUTE_CREATE_ERROR = 'FITTING_ATTRIBUTE_CREATE_ERROR';
export const GET_FITTING_ATTRIBUTE_BY_ID = 'GET_FITTING_ATTRIBUTE_BY_ID';
export const FITTING_ATTRIBUTE_UPDATED = 'FITTING_ATTRIBUTE_UPDATED';
export const FITTING_ATTRIBUTE_UPDATE_ERROR = 'FITTING_ATTRIBUTE_UPDATE_ERROR';

export const FITTING_ATTRIBUTE_DELETED = 'FITTING_ATTRIBUTE_DELETED';
export const FITTING_ATTRIBUTE_ERROR = 'FITTING_ATTRIBUTE_ERROR';
export const FITTING_IMPORT_INVENTORY_LOADING = 'FITTING_IMPORT_INVENTORY_LOADING';
export const FITTING_IMPORT_INVENTORY_UPLOADED = 'FITTING_IMPORT_INVENTORY_UPLOADED';

//Fitting SPECS constatns
export const FITTING_SPECS_LOADING = 'FITTING_SPECS_LOADING';
export const FITTING_SPECS_LIST_UPDATED = 'FITTING_SPECS_LIST_UPDATED';
export const FITTING_SPECS_FAIL = 'FITTING_SPECS_FAIL';
export const FITTING_SPECS_MODAL_LOADING = 'FITTING_SPECS_MODAL_LOADING';
export const FITTING_SPECS_CREATED = 'FITTING_SPECS_CREATED';
export const FITTING_SPECS_CREATE_ERROR = 'FITTING_SPECS_CREATE_ERROR';
export const GET_FITTING_SPECS_BY_ID = 'GET_FITTING_SPECS_BY_ID';
export const FITTING_SPECS_UPDATED = 'FITTING_SPECS_UPDATED';
export const FITTING_SPECS_UPDATE_ERROR = 'FITTING_SPECS_UPDATE_ERROR';

export const FITTING_SPECS_DELETED = 'FITTING_SPECS_DELETED';
export const FITTING_SPECS_ERROR = 'FITTING_SPECS_ERROR';

//Fitting attribute variation
export const FITTING_VARIATION_MODAL_LOADING =
  'FITTING_VARIATION_MODAL_LOADING';
export const FITTING_VARIATION_CREATED = 'FITTING_VARIATION_CREATED';
export const FITTING_VARIATION_CREATE_ERROR = 'FITTING_VARIATION_CREATE_ERROR';
export const FITTING_VARIATION_FAIL = 'FITTING_VARIATION_FAIL';
export const FITTING_VARIATION_LOADING = 'FITTING_VARIATION_LOADING';
export const FITTING_VARIATION_LIST_UPDATED = 'FITTING_VARIATION_LIST_UPDATED';
export const GET_FITTING_VARIATION_BY_ID = 'GET_FITTING_VARIATION_BY_ID';
export const FITTING_VARIATION_UPDATED = 'FITTING_VARIATION_UPDATED';
export const FITTING_VARIATION_UPDATE_ERROR = 'FITTING_VARIATION_UPDATE_ERROR';
export const FITTING_VARIATION_CHANGE_STATUS =
  'FITTING_VARIATION_CHANGE_STATUS';
export const FITTING_VARIATION_CHANGE_STATUS_ERROR =
  'FITTING_VARIATION_CHANGE_STATUS_ERROR';

export const FITTING_VARIATION_DELETED = 'FITTING_VARIATION_DELETED';
export const FITTING_VARIATION_ERROR = 'FITTING_VARIATION_ERROR';

//Email Template Constants
export const EMAIL_TEMPLATE_LOADING = 'EMAIL_TEMPLATE_LOADING';
export const EMAIL_TEMPLATE_CREATED = 'EMAIL_TEMPLATE_CREATED';
export const EMIAL_TEMPLATE_CREATE_ERROR = 'EMIAL_TEMPLATE_CREATE_ERROR';
export const EMAIL_TEMPLATE_FAIL = 'EMAIL_TEMPLATE_FAIL';
export const EMAIL_TEMPLATE_UPDATED = 'EMAIL_TEMPLATE_UPDATED';
export const EMIAL_TEMPLATE_UPDATE_ERROR = 'EMIAL_TEMPLATE_UPDATE_ERROR';
export const EMAIL_TEMPLATE_DELETED = 'EMAIL_TEMPLATE_DELETED';
export const EMAIL_TEMPLATE_DELETE_ERROR = 'EMAIL_TEMPLATE_DELETE_ERROR';
export const EMAIL_TEMPLATE_LIST_UPDATED = 'EMAIL_TEMPLATE_LIST_UPDATED';
export const GET_EMAIL_TEMPLATE_BY_ID = 'GET_EMAIL_TEMPLATE_BY_ID';
export const EMAIL_TEMPLATE_CHANGE_STATUS = 'EMAIL_TEMPLATE_CHANGE_STATUS';
export const EMAIL_TEMPLATE_CHANGE_STATUS_ERROR =
  'EMAIL_TEMPLATE_CHANGE_STATUS_ERROR';
export const ANNOUNCEMENT_SENDING = "ANNOUNCEMENT_SENDING";
export const ANNOUNCEMENT_SENT = "ANNOUNCEMENT_SENT";
export const ANNOUNCEMENT_SENDING_FAIL = "ANNOUNCEMENT_SENDING_FAIL";

export const ANNOUNCEMENT_LIST_LOADING = "ANNOUNCEMENT_LIST_LOADING";
export const ANNOUNCEMENT_LIST_UPDATED = "ANNOUNCEMENT_LIST_UPDATED";
export const ANNOUNCEMENT_LIST_FAIL = "ANNOUNCEMENT_LIST_FAIL";
export const ANNOUNCEMENT_DELETED = "ANNOUNCEMENT_DELETED";
export const ANNOUNCEMENT_DELETE_ERROR = "ANNOUNCEMENT_DELETE_ERROR";

//Email Template Category Constants
export const EMAIL_TEMPLATE_CATEGORY_LOADING =
  'EMAIL_TEMPLATE_CATEGORY_LOADING';
export const EMAIL_TEMPLATE_CATEGORY_CREATED =
  'EMAIL_TEMPLATE_CATEGORY_CREATED';
export const EMIAL_TEMPLATE_CATEGORY_CREATE_ERROR =
  'EMIAL_TEMPLATE_CATEGORY_CREATE_ERROR';
export const EMAIL_TEMPLATE_CATEGORY_FAIL = 'EMAIL_TEMPLATE_CATEGORY_FAIL';
export const EMAIL_TEMPLATE_CATEGORY_UPDATED =
  'EMAIL_TEMPLATE_CATEGORY_UPDATED';
export const EMIAL_TEMPLATE_CATEGORY_UPDATE_ERROR =
  'EMIAL_TEMPLATE_CATEGORY_UPDATE_ERROR';
export const EMAIL_TEMPLATE_CATEGORY_DELETED =
  'EMAIL_TEMPLATE_CATEGORY_DELETED';
export const EMAIL_TEMPLATE_CATEGORY_DELETE_ERROR =
  'EMAIL_TEMPLATE_CATEGORY_DELETE_ERROR';
export const EMAIL_TEMPLATE_CATEGORY_LIST_UPDATED =
  'EMAIL_TEMPLATE_CATEGORY_LIST_UPDATED';
export const GET_EMAIL_TEMPLATE_CATEGORY_BY_ID =
  'GET_EMAIL_TEMPLATE_CATEGORY_BY_ID';
export const EMAIL_TEMPLATE_CATEGORY_CHANGE_STATUS =
  'EMAIL_TEMPLATE_CATEGORY_CHANGE_STATUS';
export const EMAIL_TEMPLATE_CATEGORY_CHANGE_STATUS_ERROR =
  'EMAIL_TEMPLATE_CATEGORY_CHANGE_STATUS_ERROR';

//Email Template Section Constants
export const EMAIL_TEMPLATE_SECTION_LOADING = 'EMAIL_TEMPLATE_SECTION_LOADING';
export const EMAIL_TEMPLATE_SECTION_CREATED = 'EMAIL_TEMPLATE_SECTION_CREATED';
export const EMIAL_TEMPLATE_SECTION_CREATE_ERROR =
  'EMIAL_TEMPLATE_SECTION_CREATE_ERROR';
export const EMAIL_TEMPLATE_SECTION_FAIL = 'EMAIL_TEMPLATE_SECTION_FAIL';
export const EMAIL_TEMPLATE_SECTION_UPDATED = 'EMAIL_TEMPLATE_SECTION_UPDATED';
export const EMIAL_TEMPLATE_SECTION_UPDATE_ERROR =
  'EMIAL_TEMPLATE_SECTION_UPDATE_ERROR';
export const EMAIL_TEMPLATE_SECTION_DELETED = 'EMAIL_TEMPLATE_SECTION_DELETED';
export const EMAIL_TEMPLATE_SECTION_DELETE_ERROR =
  'EMAIL_TEMPLATE_SECTION_DELETE_ERROR';
export const EMAIL_TEMPLATE_SECTION_LIST_UPDATED =
  'EMAIL_TEMPLATE_SECTION_LIST_UPDATED';
export const GET_EMAIL_TEMPLATE_SECTION_BY_ID =
  'GET_EMAIL_TEMPLATE_SECTION_BY_ID';
export const EMAIL_TEMPLATE_SECTION_CHANGE_STATUS =
  'EMAIL_TEMPLATE_SECTION_CHANGE_STATUS';
export const EMAIL_TEMPLATE_SECTION_CHANGE_STATUS_ERROR =
  'EMAIL_TEMPLATE_SECTION_CHANGE_STATUS_ERROR';


//HOME Constants
export const HOME_LOADING = 'HOME_LOADING';
export const HOME_CREATED = 'HOME_CREATED';
export const HOME_CREATE_ERROR = 'HOME_CREATE_ERROR';
export const HOME_FAIL = 'HOME_FAIL';
export const HOME_UPDATED = 'HOME_UPDATED';
export const HOME_UPDATE_ERROR = 'HOME_UPDATE_ERROR';
export const HOME_DELETED = 'HOME_DELETED';
export const HOME_DELETE_ERROR = 'HOME_DELETE_ERROR';
export const HOME_LIST_UPDATED = 'HOME_LIST_UPDATED';
export const GET_HOME_BY_ID = 'GET_HOME_BY_ID';
export const HOME_CHANGE_STATUS = 'HOME_CHANGE_STATUS';
export const HOME_CHANGE_STATUS_ERROR = 'HOME_CHANGE_STATUS_ERROR';

//CMS Constants
export const CMS_LOADING = 'CMS_LOADING';
export const CMS_CREATED = 'CMS_CREATED';
export const CMS_CREATE_ERROR = 'CMS_CREATE_ERROR';
export const CMS_FAIL = 'CMS_FAIL';
export const CMS_UPDATED = 'CMS_UPDATED';
export const CMS_UPDATE_ERROR = 'CMS_UPDATE_ERROR';
export const CMS_DELETED = 'CMS_DELETED';
export const CMS_DELETE_ERROR = 'CMS_DELETE_ERROR';
export const CMS_LIST_UPDATED = 'CMS_LIST_UPDATED';
export const GET_CMS_BY_ID = 'GET_CMS_BY_ID';
export const CMS_CHANGE_STATUS = 'CMS_CHANGE_STATUS';
export const CMS_CHANGE_STATUS_ERROR = 'CMS_CHANGE_STATUS_ERROR';

//NEWS Constants
export const NEWS_LOADING = 'NEWS_LOADING';
export const NEWS_CREATED = 'NEWS_CREATED';
export const NEWS_CREATE_ERROR = 'NEWS_CREATE_ERROR';
export const NEWS_FAIL = 'NEWS_FAIL';
export const NEWS_UPDATED = 'NEWS_UPDATED';
export const NEWS_UPDATE_ERROR = 'NEWS_UPDATE_ERROR';
export const NEWS_DELETED = 'NEWS_DELETED';
export const NEWS_DELETE_ERROR = 'NEWS_DELETE_ERROR';
export const NEWS_LIST_UPDATED = 'NEWS_LIST_UPDATED';
export const GET_NEWS_BY_ID = 'GET_NEWS_BY_ID';
export const NEWS_CHANGE_STATUS = 'NEWS_CHANGE_STATUS';
export const NEWS_CHANGE_STATUS_ERROR = 'NEWS_CHANGE_STATUS_ERROR';

//FlexCode Constants
export const FlexCode_LOADING = 'FlexCode_LOADING';
export const FlexCode_CREATED = 'FlexCode_CREATED';
export const FlexCode_CREATE_ERROR = 'FlexCode_CREATE_ERROR';
export const FlexCode_FAIL = 'FlexCode_FAIL';
export const FlexCode_UPDATED = 'FlexCode_UPDATED';
export const FlexCode_UPDATE_ERROR = 'FlexCode_UPDATE_ERROR';
export const FlexCode_DELETED = 'FlexCode_DELETED';
export const FlexCode_DELETE_ERROR = 'FlexCode_DELETE_ERROR';
export const FlexCode_LIST_UPDATED = 'FlexCode_LIST_UPDATED';
export const GET_FlexCode_BY_ID = 'GET_FlexCode_BY_ID';

//Iron Number Constants
export const IronNumber_LOADING = 'IronNumber_LOADING';
export const IronNumber_CREATED = 'IronNumber_CREATED';
export const IronNumber_CREATE_ERROR = 'IronNumber_CREATE_ERROR';
export const IronNumber_FAIL = 'IronNumber_FAIL';
export const IronNumber_UPDATED = 'IronNumber_UPDATED';
export const IronNumber_UPDATE_ERROR = 'IronNumber_UPDATE_ERROR';
export const IronNumber_DELETED = 'IronNumber_DELETED';
export const IronNumber_DELETE_ERROR = 'IronNumber_DELETE_ERROR';
export const IronNumber_LIST_UPDATED = 'IronNumber_LIST_UPDATED';
export const GET_IronNumber_BY_ID = 'GET_IronNumber_BY_ID';

//Resource Constants
export const RESOURCE_LOADING = 'RESOURCE_LOADING';
export const RESOURCE_CREATED = 'RESOURCE_CREATED';
export const RESOURCE_CREATE_ERROR = 'RESOURCE_CREATE_ERROR';
export const RESOURCE_FAIL = 'RESOURCE_FAIL';
export const RESOURCE_UPDATED = 'RESOURCE_UPDATED';
export const RESOURCE_UPDATE_ERROR = 'RESOURCE_UPDATE_ERROR';
export const RESOURCE_DELETED = 'RESOURCE_DELETED';
export const RESOURCE_DELETE_ERROR = 'RESOURCE_DELETE_ERROR';
export const RESOURCE_LIST_UPDATED = 'RESOURCE_LIST_UPDATED';
export const GET_RESOURCE_BY_ID = 'GET_RESOURCE_BY_ID';
export const RESOURCE_CHANGE_STATUS = 'RESOURCE_CHANGE_STATUS';
export const RESOURCE_CHANGE_STATUS_ERROR = 'RESOURCE_CHANGE_STATUS_ERROR';

// PRODUCT SELECTOR Constants
export const PRODUCT_SELECTOR_LOADING = 'PRODUCT_SELECTOR_LOADING';
export const PRODUCT_SELECTOR_CREATED = 'PRODUCT_SELECTOR_CREATED';
export const PRODUCT_SELECTOR_CREATED_ERROR = 'PRODUCT_SELECTOR_CREATED_ERROR';
export const PRODUCT_SELECTOR_FAIL = 'PRODUCT_SELECTOR_FAIL';
export const PRODUCT_SELECTOR_UPDATED = 'PRODUCT_SELECTOR_UPDATED';
export const PRODUCT_SELECTOR_UPDATED_ERROR = 'PRODUCT_SELECTOR_UPDATED_ERROR';
export const PRODUCT_SELECTOR_LIST_UPDATED = 'PRODUCT_SELECTOR_LIST_UPDATED';
export const GET_PRODUCT_SELECTOR_BY_ID = 'GET_PRODUCT_SELECTOR_BY_ID';
export const PRODUCT_SELECTOR_DELETED = 'PRODUCT_SELECTOR_DELETED';
export const PRODUCT_SELECTOR_DELETE_ERROR = 'PRODUCT_SELECTOR_DELETE_ERROR';
export const PRODUCT_SELECTOR_CHANGE_STATUS = 'PRODUCT_SELECTOR_CHANGE_STATUS';
export const PRODUCT_SELECTOR_CHANGE_STATUS_ERROR = 'PRODUCT_SELECTOR_CHANGE_STATUS_ERROR';


// News letter
export const NEWS_LETTER_LOADING = 'NEWS_LETTER_LOADING';
export const NEWS_LETTER_FAIL = 'NEWS_LETTER_FAIL';
export const NEWS_LETTER_DELETED = 'NEWS_LETTER_DELETED';
export const NEWS_LETTER_DELETED_ERROR = 'NEWS_LETTER_DELETED_ERROR';
export const NEWS_LETTER_UPDATED = 'NEWS_LETTER_UPDATED';

//Inquiry Constants
export const INQUIRY_LOADING = 'INQUIRY_LOADING';
export const INQUIRY_CREATED = 'INQUIRY_CREATED';
export const INQUIRY_CREATE_ERROR = 'INQUIRY_CREATE_ERROR';
export const INQUIRY_FAIL = 'INQUIRY_FAIL';
export const INQUIRY_UPDATED = 'INQUIRY_UPDATED';
export const INQUIRY_UPDATE_ERROR = 'INQUIRY_UPDATE_ERROR';
export const INQUIRY_DELETED = 'INQUIRY_DELETED';
export const INQUIRY_DELETE_ERROR = 'INQUIRY_DELETE_ERROR';
export const INQUIRY_LIST_UPDATED = 'INQUIRY_LIST_UPDATED';
export const GET_INQUIRY_BY_ID = 'GET_INQUIRY_BY_ID';
export const INQUIRY_CHANGE_STATUS = 'INQUIRY_CHANGE_STATUS';
export const INQUIRY_CHANGE_STATUS_ERROR = 'INQUIRY_CHANGE_STATUS_ERROR';

//Attribute Constants
export const ATTRIBUTE_LOADING = 'ATTRIBUTE_LOADING';
export const ATTRIBUTE_CREATED = 'ATTRIBUTE_CREATED';
export const ATTRIBUTE_CREATE_ERROR = 'ATTRIBUTE_CREATE_ERROR';
export const ATTRIBUTE_FAIL = 'ATTRIBUTE_FAIL';
export const ATTRIBUTE_UPDATED = 'ATTRIBUTE_UPDATED';
export const ATTRIBUTE_UPDATE_ERROR = 'ATTRIBUTE_UPDATE_ERROR';
export const ATTRIBUTE_DELETED = 'ATTRIBUTE_DELETED';
export const ATTRIBUTE_DELETE_ERROR = 'ATTRIBUTE_DELETE_ERROR';
export const ATTRIBUTE_LIST_UPDATED = 'ATTRIBUTE_LIST_UPDATED';
export const GET_ATTRIBUTE_BY_ID = 'GET_ATTRIBUTE_BY_ID';
export const ATTRIBUTE_CHANGE_STATUS = 'ATTRIBUTE_CHANGE_STATUS';
export const ATTRIBUTE_CHANGE_STATUS_ERROR = 'ATTRIBUTE_CHANGE_STATUS_ERROR';
export const ATTRIBUTE_TAG_DELETED = 'ATTRIBUTE_TAG_DELETED';
export const ATTRIBUTE_TAG_DELETE_ERROR = 'ATTRIBUTE_TAG_DELETE_ERROR';
export const CANCEL_REQUEST = 'CANCEL_REQUEST';

//Specs Constants
export const SPECS_LOADING = 'SPECS_LOADING';
export const SPECS_CREATED = 'SPECS_CREATED';
export const SPECS_CREATE_ERROR = 'SPECS_CREATE_ERROR';
export const SPECS_FAIL = 'SPECS_FAIL';
export const SPECS_UPDATED = 'SPECS_UPDATED';
export const SPECS_UPDATE_ERROR = 'SPECS_UPDATE_ERROR';
export const SPECS_DELETED = 'SPECS_DELETED';
export const SPECS_DELETE_ERROR = 'SPECS_DELETE_ERROR';
export const SPECS_LIST_UPDATED = 'SPECS_LIST_UPDATED';
export const GET_SPECS_BY_ID = 'GET_SPECS_BY_ID';
export const SPECS_CHANGE_STATUS = 'SPECS_CHANGE_STATUS';
export const SPECS_CHANGE_STATUS_ERROR = 'SPECS_CHANGE_STATUS_ERROR';
export const SPECS_TAG_DELETED = 'SPECS_TAG_DELETED';
export const SPECS_TAG_DELETE_ERROR = 'SPECS_TAG_DELETE_ERROR';
export const SPECS_CANCEL_REQUEST = 'SPECS_CANCEL_REQUEST';

//Setting Constants
export const SETTINGS_LOADING = 'SETTINGS_LOADING';
export const SETTING_UPDATED = 'SETTING_UPDATED';
export const SETTING_UPDATE_ERROR = 'SETTING_UPDATE_ERROR';
export const SETTINGS_FAIL = 'SETTINGS_FAIL';
export const GET_SETTING = 'GET_SETTING';
export const GET_SETTING_ERROR = 'GET_SETTING_ERROR';

//Social settings constants
export const SOCIAL_SETTINGS_LOADING = 'SOCIAL_SETTINGS_LOADING';
export const SOCIAL_SETTING_UPDATED = 'SOCIAL_SETTING_UPDATED';
export const SOCIAL_SETTING_UPDATE_ERROR = 'SOCIAL_SETTING_UPDATE_ERROR';
export const SOCIAL_SETTINGS_FAIL = 'SOCIAL_SETTINGS_FAIL';
export const GET_SOCIAL_SETTING = 'GET_SOCIAL_SETTING';
export const GET_SOCIAL_SETTING_ERROR = 'GET_SOCIAL_SETTING_ERROR';
export const SOCIAL_SETTING_CHECKBOX_ERROR = 'SOCIAL_SETTING_CHECKBOX_ERROR';

//Carrier settings constants
export const CARRIER_SETTINGS_LOADING = "CARRIER_SETTINGS_LOADING";
export const CARRIER_SETTING_UPDATED = "CARRIER_SETTING_UPDATED";
export const CARRIER_SETTING_UPDATE_ERROR = "CARRIER_SETTING_UPDATE_ERROR";
export const CARRIER_SETTING_FAIL = "CARRIER_SETTING_FAIL";
export const GET_CARRIER_SETTING = "CARRIER_SETTING_FAIL";
export const GET_CARRIER_SETTING_ERROR = "GET_CARRIER_SETTING_ERROR";

//Profile Constants
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const USER_PROFILE_UPDATED = 'USER_PROFILE_UPDATED';
export const USER_PROFILE_UPDATED_ERROR = 'USER_PROFILE_UPDATED_ERROR';
export const USER_PROFILE_FAIL = 'USER_PROFILE_FAIL';
export const USER_PASSWORD_UPDATED = 'USER_PASSWORD_UPDATED';
export const USER_PASSWORD_UPDATE_ERROR = 'USER_PASSWORD_UPDATE_ERROR';
export const USER_PROFILE = 'USER_PROFILE';
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR';

//Forgot Password Constants
export const RESET_LINK_SUCCESS = 'RESET_LINK_SUCCESS';
export const RESET_LINK_FAIL = 'RESET_LINK_FAIL';

//Category Constants
export const CATEGORY_MODAL_LOADING = 'CATEGORY_MODAL_LOADING';
export const CATEGORY_CREATED = 'CATEGORY_CREATED';
export const CATEGORY_CREATE_ERROR = 'CATEGORY_CREATE_ERROR';
export const CATEGORY_FAIL = 'CATEGORY_FAIL';
export const CATEGORY_LOADING = 'CATEGORY_LOADING';
export const CATEGORY_UPDATED = 'CATEGORY_UPDATED';
export const CATEGORY_UPDATE_ERROR = 'CATEGORY_UPDATE_ERROR';
export const CATEGORY_DELETED = 'CATEGORY_DELETED';
export const CATEGORY_DELETE_ERROR = 'CATEGORY_DELETE_ERROR';
export const CATEGORY_LIST_UPDATED = 'CATEGORY_LIST_UPDATED';
export const GET_CATEGORY_BY_ID = 'GET_CATEGORY_BY_ID';
export const CATEGORY_CHANGE_STATUS = 'CATEGORY_CHANGE_STATUS';
export const CATEGORY_CHANGE_STATUS_ERROR = 'CATEGORY_CHANGE_STATUS_ERROR';

//Sub Category Constants
export const SUB_CATEGORY_MODAL_LOADING = 'SUB_CATEGORY_MODAL_LOADING';
export const SUB_CATEGORY_CREATED = 'SUB_CATEGORY_CREATED';
export const SUB_CATEGORY_CREATE_ERROR = 'SUB_CATEGORY_CREATE_ERROR';
export const SUB_CATEGORY_FAIL = 'SUB_CATEGORY_FAIL';
export const SUB_CATEGORY_LOADING = 'SUB_CATEGORY_LOADING';
export const SUB_CATEGORY_UPDATED = 'SUB_CATEGORY_UPDATED';
export const SUB_CATEGORY_UPDATE_ERROR = 'SUB_CATEGORY_UPDATE_ERROR';
export const SUB_CATEGORY_DELETED = 'SUB_CATEGORY_DELETED';
export const SUB_CATEGORY_DELETE_ERROR = 'SUB_CATEGORY_DELETE_ERROR';
export const SUB_CATEGORY_LIST_UPDATED = 'SUB_CATEGORY_LIST_UPDATED';
export const GET_SUB_CATEGORY_BY_ID = 'GET_SUB_CATEGORY_BY_ID';
export const SUB_CATEGORY_CHANGE_STATUS = 'SUB_CATEGORY_CHANGE_STATUS';
export const SUB_CATEGORY_CHANGE_STATUS_ERROR =
  'SUB_CATEGORY_CHANGE_STATUS_ERROR';

//Sub Dealer Type Constants
export const DEALER_TYPE_MODAL_LOADING = 'DEALER_TYPE_MODAL_LOADING';
export const DEALER_TYPE_CREATED = 'DEALER_TYPE_CREATED';
export const DEALER_TYPE_CREATE_ERROR = 'DEALER_TYPE_CREATE_ERROR';
export const DEALER_TYPE_FAIL = 'DEALER_TYPE_FAIL';
export const DEALER_TYPE_LOADING = 'DEALER_TYPE_LOADING';
export const DEALER_TYPE_UPDATED = 'DEALER_TYPE_UPDATED';
export const DEALER_TYPE_UPDATE_ERROR = 'DEALER_TYPE_UPDATE_ERROR';
export const DEALER_TYPE_LIST_UPDATED = 'DEALER_TYPE_LIST_UPDATED';
export const GET_DEALER_TYPE_BY_ID = 'GET_DEALER_TYPE_BY_ID';
export const DEALER_TYPE_CHANGE_STATUS = 'DEALER_TYPE_CHANGE_STATUS';
export const DEALER_TYPE_CHANGE_STATUS_ERROR =
  'DEALER_TYPE_CHANGE_STATUS_ERROR';
export const DEALER_TYPE_DELETED = 'DEALER_TYPE_DELETED';
export const DEALER_TYPE_DELETE_ERROR = 'DEALER_TYPE_DELETE_ERROR';
export const GET_DEALER_DETAILS_BY_ID = 'GET_DEALER_DETAILS_BY_ID';

//Dealer constants

export const DEALER_LOADING = 'DEALER_LOADING';
export const DEALER_CREATED = 'DEALER_CREATED';
export const DEALER_CREATE_ERROR = 'DEALER_CREATE_ERROR';
export const DEALER_FAIL = 'DEALER_FAIL';
export const DEALER_MODAL_LOADING = 'DEALER_MODAL_LOADING';
export const DEALER_PASSWORD_UPDATED = 'DEALER_PASSWORD_UPDATED';
export const DEALER_PASSWORD_UPDATE_ERROR = 'DEALER_PASSWORD_UPDATE_ERROR';
export const DEALER_UPDATED = 'DEALER_UPDATED';
export const DEALER_UPDATE_ERROR = 'DEALER_UPDATE_ERROR';
export const DEALER_DELETED = 'DEALER_DELETED';
export const DEALER_DELETE_ERROR = 'DEALER_DELETE_ERROR';
export const DEALER_LIST_UPDATED = 'DEALER_LIST_UPDATED';
export const GET_DEALER_BY_ID = 'GET_DEALER_BY_ID';
export const DEALER_CHANGE_STATUS = 'DEALER_CHANGE_STATUS';
export const DEALER_CHANGE_STATUS_ERROR = 'DEALER_CHANGE_STATUS_ERROR';
export const GET_DEALER_TYPES = 'GET_DEALER_TYPES';
export const DEALER_CHANGE_STATUS_BULK = 'DEALER_CHANGE_STATUS_BULK';
export const DEALER_CHANGE_STATUS_BULK_ERROR =
  'DEALER_CHANGE_STATUS_BULK_ERROR';
export const DEALER_FEATURED_BULK = 'DEALER_FEATURED_BULK';
export const DEALER_FEATURED_BULK_ERROR = 'DEALER_FEATURED_BULK_ERROR';
export const DEALER_CHANGE_TYPE_BULK = 'DEALER_CHANGE_TYPE_BULK';
export const DEALER_CHANGE_TYPE_BULK_ERROR = 'DEALER_CHANGE_TYPE_BULK_ERROR';
export const DEALER_MARK_FEATURED = 'DEALER_MARK_FEATURED';
export const DEALER_MARK_FEATURED_ERROR = 'DEALER_MARK_FEATURED_ERROR';
export const GET_DEALER_BY_ID_ERROR = 'GET_DEALER_BY_ID_ERROR';

// dealer address constants
export const DEALER_ADDRESS_LOADING = 'DEALER_ADDRESS_LOADING';
export const DEALER_ADDRESS_CREATED = 'DEALER_ADDRESS_CREATED';
export const DEALER_ADDRESS_CREATE_ERROR = 'DEALER_ADDRESS_CREATE_ERROR';
export const DEALER_ADDRESS_FAIL = 'DEALER_ADDRESS_FAIL';
export const DEALER_ADDRESS_UPDATED = 'DEALER_ADDRESS_UPDATED';
export const DEALER_ADDRESS_UPDATE_ERROR = 'DEALER_ADDRESS_UPDATE_ERROR';
export const DEALER_ADDRESS_DELETED = 'DEALER_ADDRESS_DELETED';
export const DEALER_ADDRESS_DELETE_ERROR = 'DEALER_ADDRESS_DELETE_ERROR';
export const DEALER_ADDRESS_LIST_UPDATED = 'DEALER_ADDRESS_LIST_UPDATED';
export const GET_DEALER_ADDRESS_BY_ID = 'GET_DEALER_ADDRESS_BY_ID';
export const DEALER_ADDRESS_PRIMARY = 'DEALER_ADDRESS_PRIMARY';
export const DEALER_ADDRESS_PRIMARY_ERROR = 'DEALER_ADDRESS_PRIMARY_ERROR';
export const DEALER_ADDRESS_CHANGE_STATUS = 'DEALER_ADDRESS_CHANGE_STATUS';
export const DEALER_ADDRESS_CHANGE_STATUS_ERROR =
  'DEALER_ADDRESS_CHANGE_STATUS_ERROR';

// //Product attributes section under products
// export const UPDATE_PRODUCT_ATTRIBUTES = "UPDATE_PRODUCT_ATTRIBUTES";
// export const UPDATE_PRODUCT_VARIATIONS = "UPDATE_PRODUCT_VARIATIONS";

// //Product variation section under products
// export const GET_PRODUCT_ATTRIBUTES = "GET_PRODUCT_ATTRIBUTES";
// export const GET_PRODUCT_VARIATIONS = "GET_PRODUCT_VARIATIONS";
// export const VARIATION_UPDATED = "VARIATION_UPDATED";

//Blog Constants
export const BLOG_LOADING = 'BLOG_LOADING';
export const BLOG_CREATED = 'BLOG_CREATED';
export const BLOG_CREATE_ERROR = 'BLOG_CREATE_ERROR';
export const BLOG_FAIL = 'BLOG_FAIL';
export const BLOG_UPDATED = 'BLOG_UPDATED';
export const BLOG_UPDATE_ERROR = 'BLOG_UPDATE_ERROR';
export const BLOG_DELETED = 'BLOG_DELETED';
export const BLOG_DELETE_ERROR = 'BLOG_DELETE_ERROR';
export const BLOG_LIST_UPDATED = 'BLOG_LIST_UPDATED';
export const GET_BLOG_BY_ID = 'GET_BLOG_BY_ID';
export const BLOG_CHANGE_STATUS = 'BLOG_CHANGE_STATUS';
export const BLOG_CHANGE_STATUS_ERROR = 'BLOG_CHANGE_STATUS_ERROR';

// Coupon Constants
export const COUPON_LOADING = 'COUPON_LOADING';
export const COUPON_CREATED = 'COUPON_CREATED';
export const COUPON_CREATE_ERROR = 'COUPON_CREATE_ERROR';
export const COUPON_FAIL = 'COUPON_FAIL';
export const COUPON_LIST_UPDATED = 'COUPON_LIST_UPDATED';
export const COUPON_DELETED = 'COUPON_DELETED';
export const COUPON_DELETE_ERROR = 'COUPON_DELETE_ERROR';
export const GET_COUPON_BY_ID = 'GET_COUPON_BY_ID';
export const COUPON_UPDATED = 'COUPON_UPDATED';
export const COUPON_UPDATE_ERROR = 'COUPON_UPDATE_ERROR';
export const COUPON_CHANGE_STATUS = 'COUPON_CHANGE_STATUS';
export const COUPON_CHANGE_STATUS_ERROR = 'COUPON_CHANGE_STATUS_ERROR';
export const COUPON_QUANTITY_INCRESED = 'COUPON_QUANTITY_INCRESED';
export const COUPON_USER_SPECIFIC_LIST_LOADING =
  'COUPON_USER_SPECIFIC_LIST_LOADING';
export const COUPON_USER_SPECIFIC_LIST_UPDATED =
  'COUPON_USER_SPECIFIC_LIST_UPDATED';
export const COUPON_CATEGORIES_OPTIONS_LIST_LOADING =
  'COUPON_CATEGORIES_OPTIONS_LIST_LOADING';
export const COUPON_CATEGORIES_OPTIONS_LIST_UPDATED =
  'COUPON_CATEGORIES_OPTIONS_LIST_UPDATED';
export const COUPON_PRODUCTS_OPTIONS_LIST_LOADING =
  'COUPON_PRODUCTS_OPTIONS_LIST_LOADING';
export const COUPON_PRODUCTS_OPTIONS_LIST_UPDATED =
  'COUPON_PRODUCTS_OPTIONS_LIST_UPDATED';
export const COUPON_SKU_OPTIONS_LIST_LOADING =
  'COUPON_SKU_OPTIONS_LIST_LOADING';
export const COUPON_SKU_OPTIONS_LIST_UPDATED =
  'COUPON_SKU_OPTIONS_LIST_UPDATED';
export const COUPON_FITTING_ACCESSORIES_OPTIONS_LIST_LOADING =
  'COUPON_FITTING_ACCESSORIES_OPTIONS_LIST_LOADING';
export const COUPON_FITTING_ACCESSORIESS_OPTIONS_LIST_UPDATED =
  'COUPON_FITTING_ACCESSORIESS_OPTIONS_LIST_UPDATED';
export const GET_COUPON_BY_ID_ERROR = 'GET_COUPON_BY_ID_ERROR';

// Image Gallery constants
export const IMAGE_GALLERY_UPLOADED = 'IMAGE_GALLERY_UPLOADED';
export const IMAGE_GALLERY_FAIL = 'IMAGE_GALLERY_FAIL';
export const IMAGE_GALLERY_DELETE = 'IMAGE_GALLERY_DELETE';
export const IMAGE_GALLERY_UPDATED = 'IMAGE_GALLERY_UPDATED';
export const IMAGE_GALLERY_LIST_UPDATED = 'IMAGE_GALLERY_LIST_UPDATED';
export const IMAGE_GALLERY_GET_IMAGE_BY_ID = 'IMAGE_GALLERY_GET_IMAGE_BY_ID';
export const IMAGE_GALLERY_LOADING = 'IMAGE_GALLERY_LOADING';
export const IMAGE_GALLERY_UPLOAD_ERROR = 'IMAGE_GALLERY_UPLOAD_ERROR';
export const IMAGE_GALLERY_UPDATE_ERROR = 'IMAGE_GALLERY_UPDATE_ERROR';
export const IMAGE_GALLERY_DELETE_ERROR = 'IMAGE_GALLERY_DELETE_ERROR';

// Video Gallery constants
export const VIDEO_GALLERY_UPLOADED = 'VIDEO_GALLERY_UPLOADED';
export const VIDEO_GALLERY_FAIL = 'VIDEO_GALLERY_FAIL';
export const VIDEO_GALLERY_DELETE = 'VIDEO_GALLERY_DELETE';
export const VIDEO_GALLERY_UPDATED = 'VIDEO_GALLERY_UPDATED';
export const VIDEO_GALLERY_LIST_UPDATED = 'VIDEO_GALLERY_LIST_UPDATED';
export const VIDEO_GALLERY_GET_VIDEO_BY_ID = 'VIDEO_GALLERY_GET_VIDEO_BY_ID';
export const VIDEO_GALLERY_LOADING = 'VIDEO_GALLERY_LOADING';
export const VIDEO_GALLERY_UPLOAD_ERROR = 'VIDEO_GALLERY_UPLOAD_ERROR';
export const VIDEO_GALLERY_UPDATE_ERROR = 'VIDEO_GALLERY_UPDATE_ERROR';
export const VIDEO_GALLERY_DELETE_ERROR = 'VIDEO_GALLERY_DELETE_ERROR';

// Freight Remarks
export const SHIPPING_FLEET_REMARK_LOADING = 'SHIPPING_FLEET_REMARK_LOADING';
export const SHIPPING_ADDED_FLEET_REMARK = 'SHIPPING_ADDED_FLEET_REMARK';
export const SHIPPING_ADDED_FLEET_ERROR = 'SHIPPING_ADDED_FLEET_ERROR';
export const SHIPPING_ADDED_FLEET_FAIL = 'SHIPPING_ADDED_FLEET_FAIL';

// Shipping box constants
export const SHIPPING_BOX_MODAL_LOADING = 'SHIPPING_BOX_MODAL_LOADING';
export const SHIPPING_BOX_ADDED = 'SHIPPING_BOX_ADDED';
export const SHIPPING_BOX_ADDED_ERROR = 'SHIPPING_BOX_ADDED_ERROR';
export const SHIPPING_BOX_FAIL = 'SHIPPING_BOX_FAIL';
export const SHIPPING_BOX_LOADING = 'SHIPPING_BOX_LOADING';
export const DELETE_SHIPPING_BOX = 'DELETE_SHIPPING_BOX';
export const SHIPPING_BOX_LIST_UPDATED = 'SHIPPING_BOX_LIST_UPDATED';
export const CUSTOM_SHIPPING_BOX_LIST_UPDATED = 'CUSTOM_SHIPPING_BOX_LIST_UPDATED';
export const CHANGE_SHIPPING_BOX_STATUS = 'CHANGE_SHIPPING_BOX_STATUS';
export const CHANGE_SHIPPING_BOX_NATIONAL_INTERNATIONAL =
  'CHANGE_SHIPPING_BOX_NATIONAL_INTERNATIONAL';
export const GET_SHIPPING_BOX_BY_ID = 'GET_SHIPPING_BOX_BY_ID';
export const SHIPPING_BOX_UPDATED = 'SHIPPING_BOX_UPDATED';
export const SHIPPING_BOX_UPDATED_ERROR = 'SHIPPING_BOX_UPDATED_ERROR';

// Gear Shipping box constants
export const GEAR_SHIPPING_BOX_MODAL_LOADING =
  'GEAR_SHIPPING_BOX_MODAL_LOADING';
export const GEAR_SHIPPING_BOX_ADDED = 'GEAR_SHIPPING_BOX_ADDED';
export const GEAR_SHIPPING_BOX_ADDED_ERROR = 'GEAR_SHIPPING_BOX_ADDED_ERROR';
export const GEAR_SHIPPING_BOX_FAIL = 'GEAR_SHIPPING_BOX_FAIL';
export const GEAR_SHIPPING_BOX_LOADING = 'GEAR_SHIPPING_BOX_LOADING';
export const DELETE_GEAR_SHIPPING_BOX = 'DELETE_GEAR_SHIPPING_BOX';
export const GEAR_SHIPPING_BOX_LIST_UPDATED = 'GEAR_SHIPPING_BOX_LIST_UPDATED';
export const CHANGE_GEAR_SHIPPING_BOX_STATUS =
  'CHANGE_GEAR_SHIPPING_BOX_STATUS';
export const CHANGE_GEAR_SHIPPING_BOX_NATIONAL_INTERNATIONAL =
  'CHANGE_GEAR_SHIPPING_BOX_NATIONAL_INTERNATIONAL';
export const GET_GEAR_SHIPPING_BOX_BY_ID = 'GET_GEAR_SHIPPING_BOX_BY_ID';
export const GEAR_SHIPPING_BOX_UPDATED = 'GEAR_SHIPPING_BOX_UPDATED';
export const GEAR_SHIPPING_BOX_UPDATED_ERROR =
  'GEAR_SHIPPING_BOX_UPDATED_ERROR';

// Return Initiate
export const CHANGE_RETURN_INITIATE_STATUS = 'CHANGE_RETURN_INITIATE_STATUS';
export const CREATE_RETURN_INITIATE = 'CREATE_RETURN_INITIATE';
export const LOADING_SHIPPING_BOX = "LOADING_SHIPPING_BOX";
export const GET_SHIPPING_BOX = "GET_SHIPPING_BOX";
export const SHIPPING_BOX_ERROR = "SHIPPING_BOX_ERROR";
export const RETURN_INITIATE_LOADING = 'RETURN_INITIATE_LOADING';
export const GET_RETURN_INITIATE = 'GET_RETURN_INITIATE';
export const RETURN_INITIATE_FAIL = 'RETURN_INITIATE_FAIL';

// order constants

export const GET_ORDER_CART = 'GET_ORDER_CART';
export const CART_LOADING = 'CART_LOADING';
export const ORDER_LOADING = 'ORDER_LOADING';
export const ORDER_FAIL = 'ORDER_FAIL';
export const ORDER_LIST_UPDATED = 'ORDER_LIST_UPDATED';
export const ORDER_CHANGE_STATUS = 'ORDER_CHANGE_STATUS';
export const ORDER_CHANGE_STATUS_ERROR = 'ORDER_CHANGE_STATUS_ERROR';
export const GET_ORDER_BY_ID = 'GET_ORDER_BY_ID';
export const ORDER_CREATED = 'ORDER_CREATED';
export const ORDER_CREATE_ERROR = 'ORDER_CREATE_ERROR';
export const ORDER_MODAL_LOADING = 'ORDER_MODAL_LOADING';
export const ORDER_USER_LIST_LOADING = 'ORDER_USER_LIST_LOADING';
export const ORDER_USER_LIST_UPDATED = 'ORDER_USER_LIST_UPDATED';
export const ORDER_ADDRESS_LOADER = 'ORDER_ADDRESS_LOADER';
export const ORDER_ADDRESS_LIST = 'ORDER_ADDRESS_LIST';
export const ORDER_ADDRESS_ADD_LOADER = 'ORDER_ADDRESS_ADD_LOADER';
export const ORDER_ADDRESS_ADDED = 'ORDER_ADDRESS_ADDED';
export const ORDER_ADDRESS_ADDED_ERROR = 'ORDER_ADDRESS_ADDED_ERROR';
export const ORDER_SHIPPING_RATES_LIST = 'ORDER_SHIPPING_RATES_LIST';
export const ORDER_SHIPPING_RATES_LIST_ERROR =
  'ORDER_SHIPPING_RATES_LIST_ERROR';
export const ORDER_SHIPPING_LOADER = 'ORDER_SHIPPING_LOADER';
export const ORDER_SHIPPING_FAIL = 'ORDER_SHIPPING_FAIL';
export const ORDER_CREATE_LOADER = 'ORDER_CREATE_LOADER';
export const ORDER_CREATE_RESULT = 'ORDER_CREATE_RESULT';
export const ORDER_CREATE_RESULT_ERROR = 'ORDER_CREATE_RESULT_ERROR';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';
export const ORDER_LABEL_LOADING = 'ORDER_LABEL_LOADING';
export const ORDER_LABEL_CREATED = 'ORDER_LABEL_CREATED';
export const ORDER_LABEL_CREATE_ERROR = 'ORDER_LABEL_CREATE_ERROR';
export const ORDER_LABEL_FAIL = 'ORDER_LABEL_FAIL';
export const LABEL_PRINT_ORDER_SHIPPING_RATES_LIST =
  'LABEL_PRINT_ORDER_SHIPPING_RATES_LIST';
export const LABEL_PRINT_ORDER_SHIPPING_RATES_LIST_ERROR =
  'LABEL_PRINT_ORDER_SHIPPING_RATES_LIST_ERROR';
export const LABEL_PRINT_ORDER_SHIPPING_LOADER =
  'LABEL_PRINT_ORDER_SHIPPING_LOADER';
export const LABEL_PRINT_ORDER_SHIPPING_FAIL =
  'LABEL_PRINT_ORDER_SHIPPING_FAIL';

export const SHIPPING_ORDER_LOADING = 'SHIPPING_ORDER_LOADING';
export const GET_SHIPPING_ORDER_BY_ID = 'GET_SHIPPING_ORDER_BY_ID';
export const SHIPPING_ORDER_FAIL = 'SHIPPING_ORDER_FAIL';

export const ORDER_USER_LIST_UPDATE_ERROR = 'ORDER_USER_LIST_UPDATE_ERROR';
export const ORDER_ADDRESS_LIST_ERROR = 'ORDER_ADDRESS_LIST_ERROR';

export const ADMIN_ORDER_PRODUCT_DETAIL = 'ADMIN_ORDER_PRODUCT_DETAIL';
export const ADMIN_ORDER_PRODUCT_DETAIL_ERROR =
  'ADMIN_ORDER_PRODUCT_DETAIL_ERROR';
export const ADMIN_ORDER_PRODUCT_DETAIL_FAIL =
  'ADMIN_ORDER_PRODUCT_DETAIL_FAIL';
export const ADMIN_ORDER_PRODUCT_DETAIL_LOADING =
  'ADMIN_ORDER_PRODUCT_DETAIL_LOADING';
export const ADMIN_ORDER_PRODUCT_LOADING = 'ADMIN_ORDER_PRODUCT_LOADING';
export const ADMIN_ORDER_PRODUCT_LIST_UPDATED =
  'ADMIN_ORDER_PRODUCT_LIST_UPDATED';
export const ADMIN_ORDER_PRODUCT_FAIL = 'ADMIN_ORDER_PRODUCT_FAIL';

export const ADMIN_ORDER_COUPON_LOADER = 'ADMIN_ORDER_APPLY_COUPON_LOADER';
export const ADMIN_ORDER_APPLY_COUPON_RESULT =
  'ADMIN_ORDER_APPLY_COUPON_RESULT';
export const ADMIN_ORDER_RE_APPLY_COUPON_RESULT =
  'ADMIN_ORDER_RE_APPLY_COUPON_RESULT';
export const ADMIN_ORDER_APPLY_COUPON_RESULT_ERROR =
  'ADMIN_ORDER_APPLY_COUPON_RESULT_ERROR';
export const ADMIN_ORDER_COUPON_FAIL = 'ADMIN_ORDER_COUPON_FAIL';
export const ADMIN_ORDER_REMOVE_COUPON_RESULT =
  'ADMIN_ORDER_REMOVE_COUPON_RESULT';
export const ADMIN_ORDER_REMOVE_COUPON_RESULT_ERROR =
  'ADMIN_ORDER_REMOVE_COUPON_RESULT_ERROR';
export const ADMIN_ORDER_CART_ERROR = 'ADMIN_ORDER_CART_ERROR';
export const ADMIN_ORDER_CART_SUCCESS = 'ADMIN_ORDER_CART_SUCCESS';

export const ORDER_SHIPPING_BOXES_LOADING = 'ORDER_SHIPPING_BOXES_LOADING';
export const ORDER_SHIPPING_BOXES = 'ORDER_SHIPPING_BOXES';
export const ORDER_SHIPPING_BOXES_FAILED = 'ORDER_SHIPPING_BOXES_FAILED';

export const EDITED_SHIPPING_BOX_RATES_LOADING = "EDITED_SHIPPING_BOX_RATES_LOADING";
export const EDITED_SHIPPING_BOX_RATES = "EDITED_SHIPPING_BOX_RATES";
export const EDITED_SHIPPING_BOX_RATES_FAILED = "EDITED_SHIPPING_BOX_RATES_FAILED";

export const EDITED_SHIPPING_BOX_SUBMIT_LOADING = "EDITED_SHIPPING_BOX_SUBMIT_LOADING";
export const EDITED_SHIPPING_BOX_SUCCESS = "EDITED_SHIPPING_BOX_SUCCESS";
export const EDITED_SHIPPING_BOX_SUBMIT_FAILED = "EDITED_SHIPPING_BOX_SUBMIT_FAILED";

export const SHIPPING_ADDITIONALS_LOADING = "SHIPPING_ADDITIONALS_LOADING";
export const SHIPPING_ADDITIONALS = "SHIPPING_ADDITIONALS";
export const SHIPPING_ADDITIONALS_FAILED = "SHIPPING_ADDITIONALS_FAILED";

export const SHIPPING_CANCELATIONS_LOADING = "SHIPPING_CANCELATIONS_LOADING";
export const SHIPPING_CANCELATIONS = "SHIPPING_CANCELATIONS";
export const SHIPPING_CANCELATIONS_FAILED = "SHIPPING_CANCELATIONS_FAILED";

export const VOID_SHIPPING_LABEL_LOADING = "VOID_SHIPPING_LABEL_LOADING";
export const VOID_SHIPPING_LABEL_SUCCESS = "VOID_SHIPPING_LABEL_SUCCESS";
export const VOID_SHIPPING_LABEL_FAILED = "VOID_SHIPPING_LABEL_FAILED";
/*********************front ui constants*************************/

//products constants
export const FRONT_PRODUCT_LIST_UPDATED = 'FRONT_PRODUCT_LIST_UPDATED';
export const FRONT_PRODUCT_FAIL = 'FRONT_PRODUCT_FAIL';
export const FRONT_SET_PRODUCT_LOADER = 'FRONT_SET_PRODUCT_LOADER';
export const FRONT_SET_PRODUCT_ATTRIBUTE_LOADER =
  'FRONT_SET_PRODUCT_ATTRIBUTE_LOADER';
export const FRONT_SET_PRODUCT_SPECS_LOADER = 'FRONT_SET_PRODUCT_SPECS_LOADER';
export const FRONT_UPDATE_CURRENT_PRODUCT = 'FRONT_UPDATE_CURRENT_PRODUCT';
export const FRONT_UPDATE_CURRENT_PRODUCT_ERROR =
  'FRONT_UPDATE_CURRENT_PRODUCT_ERROR';
export const FRONT_PRODUCT_RATING_LOADER = 'FRONT_PRODUCT_RATING_LOADER';
export const FRONT_PRODUCT_RATING_CREATED = 'FRONT_PRODUCT_RATING_CREATED';
export const FRONT_PRODUCT_RATING_CREATE_ERROR =
  'FRONT_PRODUCT_RATING_CREATE_ERROR';
export const FRONT_PRODUCT_RATING_LIST_UPDATED =
  'FRONT_PRODUCT_RATING_LIST_UPDATED';
export const FRONT_PRODUCT_RATING_FAIL = 'FRONT_PRODUCT_RATING_FAIL';
export const FRONT_PRODUCT_WISHLIST_LOADER = 'FRONT_PRODUCT_WISHLIST_LOADER';
export const FRONT_PRODUCT_WISHLIST_UPDATED = 'FRONT_PRODUCT_WISHLIST_UPDATED';
export const FRONT_PRODUCT_WISHLIST_UPDATE_ERROR =
  'FRONT_PRODUCT_WISHLIST_UPDATE_ERROR';
export const FRONT_PRODUCT_WISHLIST_FAIL = 'FRONT_PRODUCT_WISHLIST_FAIL';
export const FRONT_PRODUCT_LIST_PRICE_RANGE = 'FRONT_PRODUCT_LIST_PRICE_RANGE';
export const FRONT_PRODUCT_LIST_PRICE_RANGE_LOADING =
  'FRONT_PRODUCT_LIST_PRICE_RANGE_LOADING';
export const ADMIN_PRODUCT_PRODUCT_LIST = 'ADMIN_PRODUCT_PRODUCT_LIST';

//cart constants
export const FRONT_ADD_TO_CART_LOADER = 'FRONT_ADD_TO_CART_LOADER';
export const FRONT_ADD_TO_CART_SUB_LOADER = 'FRONT_ADD_TO_CART_SUB_LOADER';
export const FRONT_CART_FAIL = 'FRONT_CART_FAIL';
export const FRONT_UPDATE_CART = 'FRONT_UPDATE_CART';
export const FRONT_UPDATE_CART_ERROR = 'FRONT_UPDATE_CART_ERROR';
export const FRONT_UPDATE_CART_COUNT = 'FRONT_UPDATE_CART_COUNT';
export const FRONT_GET_CART_DETAIL = 'FRONT_GET_CART_DETAIL';
export const FRONT_RESET_CART = 'FRONT_RESET_CART';

//Users constants
export const FRONT_ADDRESS_LOADER = 'FRONT_ADDRESS_LOADER';
export const FRONT_ADDRESS_LIST = 'FRONT_ADDRESS_LIST';
export const FRONT_DEALER_FAIL = 'FRONT_DEALER_FAIL';
export const FRONT_ADDRESS_ADD_LOADER = 'FRONT_ADDRESS_ADD_LOADER';
export const FRONT_ADDRESS_ADDED = 'FRONT_ADDRESS_ADDED';
export const FRONT_ADDRESS_ADDED_ERROR = 'FRONT_ADDRESS_ADDED_ERROR';

//Front coupon constants
export const FRONT_COUPON_LOADER = 'FRONT_APPLY_COUPON_LOADER';
export const FRONT_APPLY_COUPON_RESULT = 'FRONT_APPLY_COUPON_RESULT';
export const FRONT_APPLY_COUPON_RESULT_ERROR =
  'FRONT_APPLY_COUPON_RESULT_ERROR';
export const FRONT_COUPON_FAIL = 'FRONT_COUPON_FAIL';
export const FRONT_REMOVE_COUPON_RESULT = 'FRONT_REMOVE_COUPON_RESULT';
export const FRONT_REMOVE_COUPON_RESULT_ERROR =
  'FRONT_REMOVE_COUPON_RESULT_ERROR';

// Admin coupon Constants
export const ADMIN_COUPON_APPLIED = 'ADMIN_COUPON_APPLIED';

//Front order constants
export const FRONT_ORDER_LOADER = 'FRONT_ORDER_LOADER';
export const FRONT_ORDER_RESULT = 'FRONT_ORDER_RESULT';
export const FRONT_ORDER_RESULT_ERROR = 'FRONT_ORDER_RESULT_ERROR';
export const FRONT_ORDER_FAIL = 'FRONT_ORDER_FAIL';
export const FRONT_PAYMENT_LOADER = 'FRONT_PAYMENT_LOADER';
export const FRONT_PAYMENT_SUCCESS = 'FRONT_PAYMENT_SUCCESS';
export const FRONT_PAYMENT_FAIL = 'FRONT_PAYMENT_FAIL';
export const FRONT_PAYMENT_ERROR = 'FRONT_PAYMENT_ERROR';
export const FRONT_PAYMENT_CANCLED = 'FRONT_PAYMENT_CANCLED';
export const FRONT_PAYPAL_LOADER = 'FRONT_PAYPAL_LOADER';

//front shipping TAX_RATEcalculate constants
export const TAX_RATET_SHIPPING_LOADER = 'FRONT_SHIPPING_LOADER';
export const FRONT_SHIPPING_FAIL = 'FRONT_SHIPPING_FAIL';
export const FRONT_SHIPPING_RATES_LIST = 'FRONT_SHIPPING_RATES_LIST';
export const FRONT_SHIPPING_RATES_LIST_ERROR =
  'FRONT_SHIPPING_RATES_LIST_ERROR';

//front categories constants
export const FRONT_CATEGORY_FAIL = 'FRONT_CATEGORY_FAIL';
export const FRONT_CATEGORY_LOADING = 'FRONT_CATEGORY_LOADING';
export const FRONT_CATEGORY_LIST_UPDATED = 'FRONT_CATEGORY_LIST_UPDATED';

//front video gallery constants
export const FRONT_VIDEO_GALLERY_FAIL = 'FRONT_VIDEO_GALLERY_FAIL';
export const FRONT_VIDEO_GALLERY_LIST_UPDATED =
  'FRONT_VIDEO_GALLERY_LIST_UPDATED';
export const FRONT_VIDEO_GALLERY_LOADING = 'FRONT_VIDEO_GALLERY_LOADING';

//front orders constants
export const FRONT_ORDERS_LOADER = 'FRONT_ORDERS_LOADER';
export const FRONT_ORDERS_FAIL = 'FRONT_ORDERS_FAIL';
export const FRONT_ORDER_LIST_UPDATED = 'FRONT_ORDER_LIST_UPDATED';
export const FRONT_UPDATE_CURRENT_ORDER = 'FRONT_UPDATE_CURRENT_ORDER';
export const FRONT_UPDATE_CURRENT_ORDER_ERROR =
  'FRONT_UPDATE_CURRENT_ORDER_ERROR';

// set path constants
export const SET_PREVIOUS_PATH = 'SET_PREVIOUS_PATH';

//set google address
export const SET_GOOGLE_ADDRESS_FORM_DATA = 'SET_GOOGLE_ADDRESS_FORM_DATA';

export const ADMIN_PRODUCT_FAIL = 'ADMIN_PRODUCT_FAIL';
export const ADMIN_GEAR_PRODUCT_FAIL = 'ADMIN_GEAR_PRODUCT_FAIL';
export const ADMIN_SET_PRODUCT_ATTRIBUTE_LOADER =
  'ADMIN_SET_PRODUCT_ATTRIBUTE_LOADER';
export const ADMIN_SET_GEAR_PRODUCT_ATTRIBUTE_LOADER =
  'ADMIN_SET_GEAR_PRODUCT_ATTRIBUTE_LOADER';
export const ADMIN_CART_UPDATED = 'ADMIN_CART_UPDATED';

//Packing Slip Constants
export const PACKING_SLIP_LOADING = 'PACKING_SLIP_LOADING';
export const PACKING_SLIP_CREATED = 'PACKING_SLIP_CREATED';
export const PACKING_SLIP_CREATE_ERROR = 'PACKING_SLIP_CREATE_ERROR';
export const PACKING_SLIP_FAIL = 'PACKING_SLIP_FAIL';
export const PACKING_SLIP_UPDATED = 'PACKING_SLIP_UPDATED';
export const PACKING_SLIP_UPDATE_ERROR = 'PACKING_SLIP_UPDATE_ERROR';
export const PACKING_SLIP_DELETED = 'PACKING_SLIP_DELETED';
export const PACKING_SLIP_DELETE_ERROR = 'PACKING_SLIP_DELETE_ERROR';
export const PACKING_SLIP_LIST_UPDATED = 'PACKING_SLIP_LIST_UPDATED';
export const GET_PACKING_SLIP_BY_ID = 'GET_PACKING_SLIP_BY_ID';

//Staff constants
export const STAFF_LOADING = 'STAFF_LOADING';
export const STAFF_CREATED = 'STAFF_CREATED';
export const STAFF_CREATE_ERROR = 'STAFF_CREATE_ERROR';
export const STAFF_FAIL = 'STAFF_FAIL';
export const STAFF_MODAL_LOADING = 'STAFF_MODAL_LOADING';
export const STAFF_DETAIL_PASSWORD_UPDATED = 'STAFF_DETAIL_PASSWORD_UPDATED';
export const STAFF_DETAIL_PASSWORD_UPDATE_ERROR =
  'STAFF_DETAIL_PASSWORD_UPDATE_ERROR';
export const STAFF_UPDATED = 'STAFF_UPDATED';
export const STAFF_UPDATE_ERROR = 'STAFF_UPDATE_ERROR';
export const STAFF_DELETED = 'STAFF_DELETED';
export const STAFF_DELETE_ERROR = 'STAFF_DELETE_ERROR';
export const STAFF_LIST_UPDATED = 'STAFF_LIST_UPDATED';
export const GET_STAFF_BY_ID = 'GET_STAFF_BY_ID';
export const STAFF_CHANGE_STATUS = 'STAFF_CHANGE_STATUS';
export const STAFF_CHANGE_STATUS_ERROR = 'STAFF_CHANGE_STATUS_ERROR';
export const STAFF_PASSWORD_UPDATED = 'STAFF_PASSWORD_UPDATED';
export const STAFF_PASSWORD_UPDATE_ERROR = 'STAFF_PASSWORD_UPDATE_ERROR';
export const GET_STAFF_BY_ID_ERROR = 'GET_STAFF_BY_ID_ERROR';

//Sms Template Constants
export const SMS_TEMPLATE_LOADING = 'SMS_TEMPLATE_LOADING';
export const SMS_TEMPLATE_CREATED = 'SMS_TEMPLATE_CREATED';
export const SMS_TEMPLATE_CREATE_ERROR = 'SMS_TEMPLATE_CREATE_ERROR';
export const SMS_TEMPLATE_FAIL = 'SMS_TEMPLATE_FAIL';
export const SMS_TEMPLATE_UPDATED = 'SMS_TEMPLATE_UPDATED';
export const SMS_TEMPLATE_UPDATE_ERROR = 'SMS_TEMPLATE_UPDATE_ERROR';
export const SMS_TEMPLATE_DELETED = 'SMS_TEMPLATE_DELETED';
export const SMS_TEMPLATE_DELETE_ERROR = 'SMS_TEMPLATE_DELETE_ERROR';
export const SMS_TEMPLATE_LIST_UPDATED = 'SMS_TEMPLATE_LIST_UPDATED';
export const GET_SMS_TEMPLATE_BY_ID = 'GET_SMS_TEMPLATE_BY_ID';
export const SMS_TEMPLATE_CHANGE_STATUS = 'SMS_TEMPLATE_CHANGE_STATUS';
export const SMS_TEMPLATE_CHANGE_STATUS_ERROR =
  'SMS_TEMPLATE_CHANGE_STATUS_ERROR';

//Notify Constants
export const NOTIFY_LOADING = 'NOTIFY_LOADING';
export const NOTIFY_CREATED = 'NOTIFY_CREATED';
export const NOTIFY_CREATE_ERROR = 'NOTIFY_CREATE_ERROR';
export const NOTIFY_FAIL = 'NOTIFY_FAIL';
export const NOTIFY_UPDATED = 'NOTIFY_UPDATED';
export const NOTIFY_UPDATE_ERROR = 'NOTIFY_UPDATE_ERROR';
export const NOTIFY_DELETED = 'NOTIFY_DELETED';
export const NOTIFY_DELETE_ERROR = 'NOTIFY_DELETE_ERROR';
export const NOTIFY_LIST_UPDATED = 'NOTIFY_LIST_UPDATED';
export const GET_NOTIFY_BY_ID = 'GET_NOTIFY_BY_ID';
export const NOTIFY_CHANGE_STATUS = 'NOTIFY_CHANGE_STATUS';
export const NOTIFY_CHANGE_STATUS_ERROR = 'NOTIFY_CHANGE_STATUS_ERROR';

//Fitting constatns
//Fitting attribute variation
export const FITTING_MODAL_LOADING = 'FITTING_MODAL_LOADING';
export const FITTING_CREATED = 'FITTING_CREATED';
export const FITTING_CREATE_ERROR = 'FITTING_CREATE_ERROR';
export const FITTING_FAIL = 'FITTING_FAIL';
export const FITTING_LOADING = 'FITTING_LOADING';
export const FITTING_LIST_UPDATED = 'FITTING_LIST_UPDATED';
export const GET_FITTING_BY_ID = 'GET_FITTING_BY_ID';
export const FITTING_UPDATED = 'FITTING_UPDATED';
export const FITTING_UPDATE_ERROR = 'FITTING_UPDATE_ERROR';
export const FITTING_CHANGE_STATUS = 'FITTING_CHANGE_STATUS';
export const FITTING_CHANGE_STATUS_ERROR = 'FITTING_CHANGE_STATUS_ERROR';

export const FITTING_DELETED = 'FITTING_DELETED';
export const FITTING_ERROR = 'FITTING_ERROR';

//Tax Rate

export const TAX_RATE_LOADING = 'TAX_RATE_LOADING';
export const TAX_RATE_UPDATED = 'RATES_UPDATED';
export const TAX_RATE_ERROR = 'RATES_ERROR';
export const INTERNAL_NOTES_CREATE_LOADER = 'INTERNAL_NOTES_CREATE_LOADER';
export const INTERNAL_NOTES_CREATE_RESULT = 'INTERNAL_NOTES_CREATE_RESULT';
export const INTERNAL_NOTES_CREATE_ERROR = 'INTERNAL_NOTES_CREATE_ERROR';
export const INTERNAL_NOTES_CREATE_FAIL = 'INTERNAL_NOTES_CREATE_FAIL';

export const INTERNAL_NOTES_LOADING = 'INTERNAL_NOTES_LOADING';
export const INTERNAL_NOTES_DELETED = 'INTERNAL_NOTES_DELETED';
export const INTERNAL_NOTES_DELETE_ERROR = 'INTERNAL_NOTES_DELETE_ERROR';
export const INTERNAL_NOTES_FAIL = 'INTERNAL_NOTES_FAIL';

export const BUILD_SHOP_NOTES_LOADING = 'BUILD_SHOP_NOTES_LOADING';
export const BUILD_SHOP_NOTES_UPDATED = 'BUILD_SHOP_NOTES_UPDATED';
export const BUILD_SHOP_NOTES_DELETED = 'BUILD_SHOP_NOTES_DELETED';
export const BUILD_SHOP_NOTES_DELETE_ERROR = 'BUILD_SHOP_NOTES_DELETE_ERROR';
export const BUILD_SHOP_NOTES_FAIL = 'BUILD_SHOP_NOTES_FAIL';

//DOWNLAOD INVOICE
export const ORDER_INVOICE_LOADING = 'ORDER_INVOICE_LOADING';
export const ORDER_INVOICE_CREATED = 'ORDER_INVOICE_CREATED';
export const ORDER_INVOICE_CREATE_ERROR = 'ORDER_INVOICE_CREATE_ERROR';
export const ORDER_INVOICE_FAIL = 'ORDER_INVOICE_FAIL';

// SEND EMAIL
//DOWNLAOD INVOICE
export const SEND_EMAIL_LOADING = 'SEND_EMAIL_LOADING';
export const SEND_EMAIL_CREATED = 'SEND_EMAIL_CREATED';
export const SEND_EMAIL_CREATE_ERROR = 'SEND_EMAIL_CREATE_ERROR';
export const SEND_EMAIL_FAIL = 'SEND_EMAIL_FAIL';

//REVIEWS Constants
export const REVIEW_MODAL_LOADING = 'REVIEW_MODAL_LOADING';
export const REVIEW_CREATED = 'REVIEW_CREATED';
export const REVIEW_CREATE_ERROR = 'REVIEW_CREATE_ERROR';
export const REVIEW_FAIL = 'REVIEW_FAIL';
export const REVIEW_LOADING = 'REVIEW_LOADING';
export const REVIEW_UPDATED = 'REVIEW_UPDATED';
export const REVIEW_UPDATE_ERROR = 'REVIEW_UPDATE_ERROR';
export const REVIEW_DELETED = 'REVIEW_DELETED';
export const REVIEW_DELETE_ERROR = 'REVIEW_DELETE_ERROR';
export const REVIEW_LIST_UPDATED = 'REVIEW_LIST_UPDATED';
export const GET_REVIEW_BY_ID = 'GET_REVIEW_BY_ID';
export const REVIEW_CHANGE_STATUS = 'REVIEW_CHANGE_STATUS';
export const REVIEW_CHANGE_STATUS_ERROR = 'REVIEW_CHANGE_STATUS_ERROR';
export const REVIEW_LIST = 'REVIEW_LIST';
export const SET_REVIEW_LOADER = 'SET_REVIEW_LOADER';

//GEAR Attribute Constants
export const GEAR_ATTRIBUTE_LOADING = 'GEAR_ATTRIBUTE_LOADING';
export const GEAR_ATTRIBUTE_CREATED = 'GEAR_ATTRIBUTE_CREATED';
export const GEAR_ATTRIBUTE_CREATE_ERROR = 'GEAR_ATTRIBUTE_CREATE_ERROR';
export const GEAR_ATTRIBUTE_FAIL = 'GEAR_ATTRIBUTE_FAIL';
export const GEAR_ATTRIBUTE_UPDATED = 'GEAR_ATTRIBUTE_UPDATED';
export const GEAR_ATTRIBUTE_UPDATE_ERROR = 'GEAR_ATTRIBUTE_UPDATE_ERROR';
export const GEAR_ATTRIBUTE_DELETED = 'GEAR_ATTRIBUTE_DELETED';
export const GEAR_ATTRIBUTE_DELETE_ERROR = 'GEAR_ATTRIBUTE_DELETE_ERROR';
export const GEAR_ATTRIBUTE_LIST_UPDATED = 'GEAR_ATTRIBUTE_LIST_UPDATED';
export const GET_GEAR_ATTRIBUTE_BY_ID = 'GET_GEAR_ATTRIBUTE_BY_ID';
export const GEAR_ATTRIBUTE_CHANGE_STATUS = 'GEAR_ATTRIBUTE_CHANGE_STATUS';
export const GEAR_ATTRIBUTE_CHANGE_STATUS_ERROR =
  'GEAR_ATTRIBUTE_CHANGE_STATUS_ERROR';
export const GEAR_ATTRIBUTE_TAG_DELETED = 'GEAR_ATTRIBUTE_TAG_DELETED';
export const GEAR_ATTRIBUTE_TAG_DELETE_ERROR =
  'GEAR_ATTRIBUTE_TAG_DELETE_ERROR';

//Gear Category Constants
export const GEAR_CATEGORY_MODAL_LOADING = 'GEAR_CATEGORY_MODAL_LOADING';
export const GEAR_CATEGORY_CREATED = 'GEAR_CATEGORY_CREATED';
export const GEAR_CATEGORY_CREATE_ERROR = 'GEAR_CATEGORY_CREATE_ERROR';
export const GEAR_CATEGORY_FAIL = 'GEAR_CATEGORY_FAIL';
export const GEAR_CATEGORY_LOADING = 'GEAR_CATEGORY_LOADING';
export const GEAR_CATEGORY_UPDATED = 'GEAR_CATEGORY_UPDATED';
export const GEAR_CATEGORY_UPDATE_ERROR = 'GEAR_CATEGORY_UPDATE_ERROR';
export const GEAR_CATEGORY_DELETED = 'GEAR_CATEGORY_DELETED';
export const GEAR_CATEGORY_DELETE_ERROR = 'GEAR_CATEGORY_DELETE_ERROR';
export const GEAR_CATEGORY_LIST_UPDATED = 'GEAR_CATEGORY_LIST_UPDATED';
export const GET_GEAR_CATEGORY_BY_ID = 'GET_GEAR_CATEGORY_BY_ID';
export const GEAR_CATEGORY_CHANGE_STATUS = 'GEAR_CATEGORY_CHANGE_STATUS';
export const GEAR_CATEGORY_CHANGE_STATUS_ERROR =
  'GEAR_CATEGORY_CHANGE_STATUS_ERROR';

//Gear PRODUCT Constants
export const GEAR_PRODUCT_LOADING = 'GEAR_PRODUCT_LOADING';
export const GEAR_PRODUCT_CREATED = 'GEAR_PRODUCT_CREATED';
export const ADDED_DEFAULT_GEAR_VARIATION = 'ADDED_DEFAULT_GEAR_VARIATION';
export const GEAR_PRODUCT_CREATE_ERROR = 'GEAR_PRODUCT_CREATE_ERROR';
export const GEAR_PRODUCT_FAIL = 'GEAR_PRODUCT_FAIL';
export const SET_GEAR_CATEGORY_LOADER = 'SET_GEAR_CATEGORY_LOADER';
export const CATEGORY_LIST_GEAR_PRODUCT = 'CATEGORY_LIST_GEAR_PRODUCT';
export const SET_GEAR_ATTRIBUTE_LOADER = 'SET_GEAR_ATTRIBUTE_LOADER';
export const ATTRIBUTES_LIST_GEAR_PRODUCT = 'ATTRIBUTES_LIST_GEAR_PRODUCT';

export const SET_GEAR_SPECS_LOADER = 'SET_GEAR_SPECS_LOADER';
export const SPECS_LIST_GEAR_PRODUCT = 'SPECS_LIST_GEAR_PRODUCT';

export const GEAR_PRODUCT_UPDATED = 'GEAR_PRODUCT_UPDATED';
export const GEAR_SPEC_ADD = 'GEAR_SPEC_ADD';

export const GEAR_PRODUCT_UPDATE_ERROR = 'GEAR_PRODUCT_UPDATE_ERROR';
export const GEAR_PRODUCT_LIST_UPDATED = 'GEAR_PRODUCT_LIST_UPDATED';
export const GET_GEAR_PRODUCT_BY_ID = 'GET_GEAR_PRODUCT_BY_ID';
export const CHANGE_GEAR_PRODUCT_STATUS = 'CHANGE_GEAR_PRODUCT_STATUS';
export const CHANGE_GEAR_PRODUCT_STATUS_ERROR =
  'CHANGE_GEAR_PRODUCT_STATUS_ERROR';
export const DELETE_GEAR_PRODUCT = 'DELETE_GEAR_PRODUCT';
export const DELETE_GEAR_PRODUCT_ERROR = 'DELETE_GEAR_PRODUCT_ERROR';
export const GET_GEAR_PRODUCT_IMAGES = 'GET_GEAR_PRODUCT_IMAGES';
export const DELETE_GEAR_PRODUCT_IMAGE = 'DELETE_GEAR_PRODUCT_IMAGE';
export const GEAR_PRIMARY_IMAGE_UPDATED = 'GEAR_PRIMARY_IMAGE_UPDATED';
export const GEAR_WHOLESALE_PRICE_ERROR = 'GEAR_WHOLESALE_PRICE_ERROR';
export const GEAR_SALE_PRICE_ERROR = 'GEAR_SALE_PRICE_ERROR';
export const UPDATE_GEAR_PRODUCT_ATTRIBUTES = 'UPDATE_GEAR_PRODUCT_ATTRIBUTES';
export const UPDATE_GEAR_PRODUCT_ATTRIBUTES_OPTIONS = 'UPDATE_GEAR_PRODUCT_ATTRIBUTES_OPTIONS';
export const UPDATE_GEAR_PRODUCT_ATTRIBUTES_ERROR =
  'UPDATE_GEAR_PRODUCT_ATTRIBUTES_ERROR';

export const UPDATE_GEAR_PRODUCT_SPECS = 'UPDATE_GEAR_PRODUCT_SPECS';
export const UPDATE_GEAR_PRODUCT_SPECS_ERROR =
  'UPDATE_GEAR_PRODUCT_SPECS_ERROR';
export const GEAR_VARIATION_UPDATED = 'GEAR_VARIATION_UPDATED';
export const GEAR_VARIATION_UPDATED_ERROR = 'GEAR_VARIATION_UPDATED_ERROR';
export const RESET_CURRENT_GEAR_PRODUCT = 'RESET_CURRENT_GEAR_PRODUCT';
export const CHANGE_GEAR_PRODUCT_STATUS_BULK =
  'CHANGE_GEAR_PRODUCT_STATUS_BULK';
export const GEAR_INVENTORY_AND_SHIPPING_UPDATED =
  'GEAR_INVENTORY_AND_SHIPPING_UPDATED';
export const GEAR_INVENTORY_AND_SHIPPING_UPDATED_ERROR =
  'GEAR_INVENTORY_AND_SHIPPING_UPDATED_ERROR';
export const GEAR_IMAGE_UPLOAD_ERROR = 'GEAR_IMAGE_UPLOAD_ERROR';
export const UPDATE_GEAR_PRODUCT_IMAGES = 'UPDATE_GEAR_PRODUCT_IMAGES';
//export const VARIATION_IMAGE_UPDATED = 'VARIATION_IMAGE_UPDATED';
export const GEAR_VARIATION_IMAGE_ERROR = 'GEAR_VARIATION_IMAGE_ERROR';
export const UPDATE_GEAR_PRODUCT_IMAGES_ERROR =
  'UPDATE_GEAR_PRODUCT_IMAGES_ERROR';
export const GEAR_PRODUCT_ATTRIBUTE_TAG_DELETE =
  'GEAR_PRODUCT_ATTRIBUTE_TAG_DELETE';
export const GEAR_PRODUCT_ATTRIBUTE_TAG_DELETE_ERROR =
  'GEAR_PRODUCT_ATTRIBUTE_TAG_DELETE_ERROR';
export const GEAR_PRODUCT_ATTRIBUTE_OPTION_CREATED =
  'GEAR_PRODUCT_ATTRIBUTE_OPTION_CREATED';
export const GEAR_PRODUCT_ATTRIBUTE_OPTION_CREATE_ERROR =
  'GEAR_PRODUCT_ATTRIBUTE_OPTION_CREATE_ERROR';
export const SET_GEAR_PRODUCT_ATTRIBUTE_OPTION_LOADER =
  'SET_GEAR_PRODUCT_ATTRIBUTE_OPTION_LOADER';

export const GEAR_PRODUCT_SPECS_TAG_DELETE_ERROR =
  'GEAR_PRODUCT_SPECS_TAG_DELETE_ERROR';
export const GEAR_PRODUCT_SPECS_OPTION_CREATED =
  'GEAR_PRODUCT_SPECS_OPTION_CREATED';
export const GEAR_PRODUCT_SPECS_OPTION_CREATE_ERROR =
  'GEAR_PRODUCT_SPECS_OPTION_CREATE_ERROR';
export const SET_GEAR_PRODUCT_SPECS_OPTION_LOADER =
  'SET_GEAR_PRODUCT_SPECS_OPTION_LOADER';

//GEAR REVIEWS Constants
export const GEAR_REVIEW_FAIL = 'GEAR_REVIEW_FAIL';
export const GEAR_REVIEW_LOADING = 'GEAR_REVIEW_LOADING';
export const GEAR_REVIEW_UPDATED = 'GEAR_REVIEW_UPDATED';
export const GEAR_REVIEW_CHANGE_STATUS = 'GEAR_REVIEW_CHANGE_STATUS';
export const GEAR_REVIEW_CHANGE_STATUS_ERROR =
  'GEAR_REVIEW_CHANGE_STATUS_ERROR';

// Variations
export const SET_VARIATIONS = 'SET_VARIATIONS';

//breadcrum
export const ADD_BREADCRUM_ID_WITH_NAME = 'ADD_BREADCRUM_ID_WITH_NAME';
export const ADD_BREADCRUM_FAIL = 'ADD_BREADCRUM_FAIL';

// Additional Products

export const Additional_Product_LOADING = 'Additional_Product_LOADING';
export const Additional_Product_CREATED = 'Additional_Product_CREATED';
export const Additional_Product_CREATE_ERROR = 'Additional_Product_CREATE_ERROR';
export const Additional_Product_FAIL = 'Additional_Product_FAIL';
export const Additional_Product_UPDATED = 'Additional_Product_UPDATED';
export const Additional_Product_UPDATE_ERROR = 'Additional_Product_UPDATE_ERROR';
export const Additional_Product_DELETED = 'Additional_Product_DELETED';
export const Additional_Product_DELETE_ERROR = 'Additional_Product_DELETE_ERROR';
export const Additional_Product_LIST_UPDATED = 'Additional_Product_LIST_UPDATED';
export const GET_Additional_Product_BY_ID = 'GET_Additional_Product_BY_ID';
export const GET_BUILD_SUPPLY_SERVICE_LIST = 'GET_BUILD_SUPPLY_SERVICE_LIST';

// Build Shop Supply

export const BUILD_SHOP_SUPPLY_LOADING = "BUILD_SHOP_SUPPLY_LOADING";
export const BUILD_SHOP_SUPPLY_CREATED = "BUILD_SHOP_SUPPLY_CREATED";
export const BUILD_SHOP_SUPPLY_CREATE_ERROR = "BUILD_SHOP_SUPPLY_CREATE_ERROR";
export const BUILD_SHOP_SUPPLY_FAIL = "BUILD_SHOP_SUPPLY_FAIL";
export const BUILD_SHOP_SUPPLY_UPDATED = "BUILD_SHOP_SUPPLY_UPDATED";
export const BUILD_SHOP_SUPPLY_UPDATE_ERROR = "BUILD_SHOP_SUPPLY_UPDATE_ERROR";
export const BUILD_SHOP_SUPPLY_DELETED = "BUILD_SHOP_SUPPLY_DELETED";
export const BUILD_SHOP_SUPPLY_DELETE_ERROR = "BUILD_SHOP_SUPPLY_DELETE_ERROR";
export const BUILD_SHOP_SUPPLY_LIST_UPDATED = "BUILD_SHOP_SUPPLY_LIST_UPDATED";
export const GET_BUILD_SHOP_SUPPLY_BY_ID = "GET_BUILD_SHOP_SUPPLY_BY_ID";
export const BUILD_SHOP_INVENTORY_LOADING = "BUILD_SHOP_INVENTORY_LOADING";
export const BUILD_SHOP_INVENTORY_UPLOADED = "BUILD_SHOP_INVENTORY_UPLOADED";
export const BUILD_SHOP_INVENTORY_ERROR = "BUILD_SHOP_INVENTORY_ERROR";
export const BUILD_SHOP_INVENTORY_FAIL = "BUILD_SHOP_INVENTORY_FAIL";

// Additional Product
export const ADDITIONAL_PRODUCT_CART_SUCCESS = 'ADDITIONAL_PRODUCT_CART_SUCCESS';
export const ADDITIONAL_PRODUCT_CART_ERROR = 'ADDITIONAL_PRODUCT_CART_ERROR';

export const MERGE_SHIPPING_LOADING = 'MERGE_SHIPPING_LOADING';
export const MERGE_SHIPPING_SUCCESS = 'MERGE_SHIPPING_SUCCESS';
export const MERGE_SHIPPING_ERROR = 'MERGE_SHIPPING_ERROR';
export const MERGE_SHIPPING_FAIL = 'MERGE_SHIPPING_FAIL';

export const DELETE_MERGE_SHIPPING_SUCCESS = 'DELETE_MERGE_SHIPPING_SUCCESS';
export const DELETE_MERGE_SHIPPING_ERROR = 'DELETE_MERGE_SHIPPING_ERROR';
export const DELETE_MERGE_SHIPPING_FAIL = 'DELETE_MERGE_SHIPPING_FAIL';


export const ORDER_PAYMENTS_LOADING = 'ORDER_PAYMENTS_LOADING';
export const ORDER_PAYMENTS = 'ORDER_PAYMENTS';
export const ORDER_PAYMENTS_FAIL = 'ORDER_PAYMENTS_FAIL';

export const SUCCESS_RESPONSE = 'SUCCESS_RESPONSE';
export const ERROR_RESPONSE = 'ERROR_RESPONSE';
export const FAILED_RESPONSE = 'FAILED_RESPONSE';

export const LOADING_START = 'LOADING_START';
export const LOADING_END = 'LOADING_END';
export const LOADING = 'LOADING';