/* eslint-disable */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';

import { Button, Card, CardBody, Col, Row, Input } from 'reactstrap';

//   PaginationProvider,
//   SizePerPageDropdownStandalone,
// } from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from '../../../utils/Loading';

import ReactTooltip from 'react-tooltip';

import CustomFilters from './CustomFilters';
import Spinner from 'views/Spinner';

import {
  markAsPrimary,
  getDealersAddressList,
  deleteDealersAddress,
  addressApproveDisapprove,
} from 'actions/admin/dealerAddress';

import * as Constants from 'constants/index';
import PerPageSelect from '../PerPageSelect';
import { changeStatus } from 'actions/admin/user';

const renderLocation = (params) => {
  if (params.data === undefined) {
    return <Loading />;
  } else {
    return (
      <p>
        {params.data?.city + '/'}
        {params.data?.state ? params.data?.state + '/' : ''}
        {params.data?.country}
      </p>
    );
  }
};

const renderContactNumber = (params) => {
  return (
    <p>
      {params.data?.ccode}
      {params.data?.contact_no}
    </p>
  );
};

const renderLastEdited = (params) => {
  return (
    <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
      {params.data?.updated_at}
    </Moment>
  );
};

const renderStatus = (params, changeStatus) => {
  return (
    <div>
      <Input
        type="select"
        name="status"
        id={params.data?._id}
        defaultValue={params.data?.status}
        onChange={(e, a) => {
          changeStatus(params.data?.address_id, e.target.value);
        }}
        disabled={params.data?.is_primary}
      >
        {/* <option value="0">{Constants.ADDRESS_STATUS[0]}</option> */}
        <option value="1">{Constants.ADDRESS_STATUS[1]}</option>
        <option value="2">{Constants.ADDRESS_STATUS[2]}</option>
      </Input>
    </div>
  );
};

const renderActions = (
  params,
  businessName,
  deleteDealersAddress,
  updateAddresses
) => {
  return (
    <div>
      <Link
        to={`/admin/${businessName}/dealer-address/edit/${params.data?.address_id}`}
      >
        <Button type="button" size="sm" color="success" title="Edit">
          <i className="fa fa-pencil"></i>
        </Button>
      </Link>
      <Button
        type="button"
        size="sm"
        color="danger"
        title="Delete"
        onClick={(e) => {
          if (
            window.confirm(
              `Are you sure, you want to  delete ${
                params.data?.city +
                '/' +
                params.data?.state +
                '/' +
                params.data?.country
              } location?`
            )
          ) {
            deleteDealersAddress(params.data?.address_id).then((res) => {
              if (res) updateAddresses();
            });
          }
        }}
      >
        <i className="fa fa-trash"></i>
      </Button>
    </div>
  );
};

const DealersAddressList = ({
  markAsPrimary,
  getDealersAddressList,
  deleteDealersAddress,
  dealersAddressList: { data, count },
  loading,
  subLoading,
  match,
  addressApproveDisapprove,
}) => {
  const { businessName: businessName, dealer_id: dealer_id } = useParams();
  const history = useNavigate();
  const location = useLocation();
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
          limit: Constants.DEFAULT_PAGE_SIZE,
          page: 1,
          orderBy: 'updated_at',
          ascending: 'desc',
          query: '',
          filter: {},
        }
  );

  // to resolve useEffect dependency error

  //################## to get updated list ###################
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);

  const updateAddresses = () => {
    gridApi.purgeInfiniteCache();
  };

  const changeStatus = async (id, value) => {
    await addressApproveDisapprove(id, value);
    gridApi.purgeInfiniteCache();
  };

  const changeMarkAsPrimary = async (id, value) => {
    await markAsPrimary(id, value);
    gridApi.purgeInfiniteCache();
  };

  // ################# Add Dealer's Address ################
  const actions = (
    <div className="add-button-div">
      <Link to="/admin/dealers">
        <Button type="reset" size="sm" color="info">
          <i className="fa fa-arrow-left"></i> Back
        </Button>
      </Link>
      <Link
        to={`/admin/${businessName}/dealer-address/add/${dealer_id}`}
        className="addNewElementClass"
      >
        <Button color="primary" size="sm">
          <i className="fa fa-plus"></i> Add Address
        </Button>
      </Link>
    </div>
  );
  const anyFilter = {
    displayName: 'ANY',
    displayKey: 'any',
    numberOfInputs: 0,
    predicate: () => {},
  };
  //#########################Colums defination start ###################
  const columns = [
    {
      field: 'city',
      headerName: 'Location',
      width: 170,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      colSpan: (params) => {
        if (params.data === undefined) {
          return 8;
        } else {
          return 0;
        }
      },
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => renderLocation(params),
    },
    {
      field: 'insensitiveName',
      headerName: 'Contact Name',
      width: 140,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => {
        return <div>{params.data?.name}</div>;
      },
    },

    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'contact_no',
      width: 120,
      headerName: 'Phone',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => renderContactNumber(params),
    },
    {
      field: 'updated_at',
      width: 120,
      headerName: 'Last Edited',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agDateColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.DATE_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
        buttons: ['reset', 'apply'],
      },
      cellRenderer: (params) => renderLastEdited(params),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      suppressSizeToFit: false,
      filter: 'agTextColumnFilter',
      filterParams: {
        closeOnApply: true,
        filterOptions: [
          anyFilter,
          {
            displayName: 'Active',
            displayKey: 'typeActive',
            numberOfInputs: 0,
            predicate: () => {},
          },
          {
            displayName: 'Inactive',
            displayKey: 'typeInactive',
            numberOfInputs: 0,
            predicate: () => {},
          },
          {
            displayName: 'Pending',
            displayKey: 'typePending',
            numberOfInputs: 0,
            predicate: () => {},
          },
        ],
        suppressAndOrCondition: true,
      },

      cellRenderer: (params) => renderStatus(params, changeStatus),
    },
    {
      field: 'is_primary',
      headerName: 'Is Primary ',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      width: 140,
      cellRenderer: (params) => {
        const displayContent = params.data?.is_primary ? (
          <div>
            <span
              className="featured-active-main"
              data-tip="Primary"
              data-for={'TooltipFeatured-' + params.data?.is_primary}
            >
              {' '}
              Primary{' '}
              {/* <span className="featured-active">
                <i className="fa fa-check"></i>
              </span> */}
            </span>
            <ReactTooltip
              id={'TooltipFeatured-' + params.data?.is_primary}
              aria-haspopup="true"
            />
          </div>
        ) : (
          <div>
            <Button
              type="button"
              size="sm"
              className="warning-featured-main"
              data-tip="Not Primary. Mark As Primary."
              data-for={'Tooltip-' + params.data?.is_primary}
              disabled={params.data?.status !== 1}
              onClick={(e) => {
                if (
                  window.confirm(
                    `Are you sure, you want to  mark ${
                      params.data?.city +
                      '/' +
                      params.data?.state +
                      '/' +
                      params.data?.country
                    } location as primary?`
                  )
                ) {
                  // markAsPrimary(dealer_id, params.data?.address_id);
                  // updateAddresses();
                  changeMarkAsPrimary(dealer_id, params.data?.address_id);
                }
              }}
            >
              Mark As Primary
            </Button>
            <ReactTooltip
              id={'Tooltip-' + params.data?.address_id}
              aria-haspopup="true"
            />
          </div>
        );
        return displayContent;
      },
    },
    {
      field: 'address_id',
      headerName: 'Actions',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) =>
        renderActions(
          params,
          businessName,
          deleteDealersAddress,
          updateAddresses
        ),
    },
  ];
  //#########################Colums defination end ###################

  //################## END Set properties(options) of datatables ###################
  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };

          const customData = await getDealersAddressList(
            customParams,
            dealer_id
          );
          if (customData?.data?.status) {
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });

  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                {actions}
                <div className="rightpaneltop">
                  Address Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>

              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      defaultColDef={{
                        resizable: true,
                      }}
                      loadingCellRenderer={customLoadingCellRenderer}
                      loadingCellRendererParams={loadingCellRendererParams}
                      domLayout={'autoHeight'}
                      rowModelType={'infinite'}
                      columnDefs={columns}
                      pagination={true}
                      sizeColumnsToFit={true}
                      onFilterChanged={filterChanged}
                      onGridReady={onGridReady}
                      paginationPageSize={sortingParams.limit}
                      cacheBlockSize={sortingParams.limit}
                      cacheQuickFilter={true}
                      onFirstDataRendered={onFirstDataRendered}
                      onPaginationChanged={onPaginationChanged}
                      ref={gridRef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

DealersAddressList.propTypes = {
  getDealersAddressList: PropTypes.func.isRequired,
  markAsPrimary: PropTypes.func.isRequired,
  deleteDealersAddress: PropTypes.func.isRequired,
  dealersAddressList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
  addressApproveDisapprove: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  dealersAddressList: state.dealerAddress.dealersAddressList,
  loading: state.dealerAddress.loading,
  subLoading: state.dealerAddress.isSubLoading,
});

export default connect(mapStateToProps, {
  getDealersAddressList,
  deleteDealersAddress,
  markAsPrimary,
  addressApproveDisapprove,
})(DealersAddressList);
