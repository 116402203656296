import {
  INQUIRY_LOADING,
  INQUIRY_CREATED,
  INQUIRY_CREATE_ERROR,
  INQUIRY_FAIL,
  INQUIRY_UPDATED,
  INQUIRY_UPDATE_ERROR,
  INQUIRY_DELETED,
  INQUIRY_DELETE_ERROR,
  INQUIRY_LIST_UPDATED,
  GET_INQUIRY_BY_ID,
  INQUIRY_CHANGE_STATUS,
  INQUIRY_CHANGE_STATUS_ERROR,
} from "actions/types";

const initalState = {
  inquiriesList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentInquiry: {},
  loading: false,
  isSubLoading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case INQUIRY_LOADING:
      return {
        ...state,
        inquiriesList: payload.is_sub_element
          ? state.inquiriesList
          : initalState.inquiriesList,
        currentInquiry: payload.is_sub_element
          ? state.currentInquiry
          : initalState.currentInquiry,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case INQUIRY_CREATED:
      return {
        ...state,
        loading: false,
      };
    case INQUIRY_CREATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case INQUIRY_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case INQUIRY_UPDATED:
      return {
        ...state,
        currentInquiry: {},
        loading: false,
      };
    case INQUIRY_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case INQUIRY_DELETED:
      return {
        ...state,
        loading: false,
      };
    case INQUIRY_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case INQUIRY_LIST_UPDATED:
      return {
        ...state,
        inquiriesList: {
          data: payload.data.inquiryList.data,
          page: payload.data.inquiryList.metadata[0].current_page,
          count: payload.data.inquiryList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_INQUIRY_BY_ID:
      return {
        ...state,
        currentInquiry: payload.data,
        loading: false,
      };
    case INQUIRY_CHANGE_STATUS:
      return {
        ...state,
        inquiriesList: {
          ...state.inquiriesList,
          data: state.inquiriesList.data.map((inquiry) =>
            inquiry._id === payload.data._id
              ? { ...inquiry, status: payload.data.status }
              : inquiry
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case INQUIRY_CHANGE_STATUS_ERROR:
      return {
        ...state,
        inquiriesList: {
          ...state.inquiriesList,
          data: state.inquiriesList.data.map((inquiry) =>
            inquiry._id === payload.data
              ? { ...inquiry, is_failed: true }
              : { ...inquiry, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    default:
      return state;
  }
}
