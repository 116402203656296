import { useCallback, useEffect, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
    Input,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    Table,
    Form,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';

import {
    selectedVariations,
    buildShopSupplyAddToCart,
    editBuildShopSupplyAddToCart
} from 'actions/admin/order';
import SetProductQuantity from './SetProductQuantity';
import ButtonSpinner from 'views/ButtonSpinner';

const ServiceModel = ({
    selectedVariations,
    variationData,
    buildShopSupplyAddToCart,
    editBuildShopSupplyAddToCart,
    buildShopSupplyCart
}) => {
    const [isProductLoading, setIsProductLoading] = useState(false);
    useEffect(() => {
        if (variationData?.serviceModel === true) {
            delete variationData.stock_quantity;
            selectedVariations(variationData)
        }
    }, [variationData?.serviceModel])
    const toggle = () => {
        variationData = {
            ...variationData,
            serviceModel: !variationData.serviceModel
        }

        if (variationData?.serviceModel === false) {
            variationData = {
                ...variationData,
                serviceProduct: {},
                quantity: 1,
            }

        }
        selectedVariations(variationData)
    };

    const handleOnChange = useCallback((event) => {
        const { value } = event.target;
        variationData = {
            ...variationData,
            serviceProduct: {
                ...variationData.serviceProduct,
                price: value
            }
        }
        selectedVariations(variationData)
    });

    const submitForm = (event) => {
        event.preventDefault();
        let selectedPro = { ...variationData.serviceProduct, price: variationData.serviceProduct.price ? variationData.serviceProduct.price : 0 };
        selectedPro.quantity = variationData.quantity;
        selectedPro.type = "service";
        if (selectedPro.isEdit === true) {
            selectedPro.quantity = variationData.serviceProduct?.quantity
        }

        setIsProductLoading(true)
        selectedPro.orderId = variationData.orderId !== undefined ? variationData.orderId : variationData.userId
        let addEditCard = selectedPro.isEdit === true ? editBuildShopSupplyAddToCart(selectedPro) : buildShopSupplyAddToCart(selectedPro)
        addEditCard.then((res) => {
            if (res.status === true) {
                variationData = {
                    ...variationData,
                    // serviceProduct: {},
                    quantity: 1,
                    callGetCartApi: true,
                    serviceModel: false
                }

            } else {
                variationData = {
                    ...variationData,
                    serviceModel: true
                }
            }
            selectedVariations(variationData)
            setIsProductLoading(false)
        })
    }
    return (
        <>
            <Modal isOpen={variationData.serviceModel} toggle={toggle}>
                <ModalHeader toggle={toggle}>Services ({variationData?.serviceProduct?.product_title})</ModalHeader>
                <Form onSubmit={submitForm} className="form-horizontal">
                    <ModalBody>
                        <Row>
                            <Col sm="12">
                                <SetProductQuantity
                                    customShaftChecked={true}
                                />
                            </Col>
                            <Col sm="12" className={"mt-2"}>
                                <FormGroup>
                                    <Label for={"price"} ><b>Price</b></Label>
                                    <Input id="price" name="price" value={variationData?.serviceProduct?.price} type="number" min={0.00} step="0.01" onChange={handleOnChange} />
                                </FormGroup>
                            </Col>
                            <Col sm="12" className={"mt-2"}>
                                {/* <FormGroup>
                                    <Label ><b>Additional Product</b> : <span>{variationData?.serviceProduct?.name}</span></Label>
                                </FormGroup> */}
                                <FormGroup>
                                    <Label ><b> SKU</b> : <span>{variationData?.serviceProduct?.sku}</span></Label>
                                </FormGroup>
                                {variationData?.serviceProduct?.description && <FormGroup>
                                    <Label ><b>Description</b> : <span dangerouslySetInnerHTML={{
                                        __html: (variationData?.serviceProduct?.description),
                                    }} /></Label>
                                </FormGroup>}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <div className="modalFooter">
                            {isProductLoading ? <ButtonSpinner /> : <Button type="submit" color="primary">
                                Submit
                            </Button>}{' '}
                            <Button disabled={isProductLoading} color="danger" onClick={toggle}>
                                Cancel
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => ({
    variationData: state.order.variationData,
    buildShopSupplyCart: state.order.buildShopSupplyCart
});

export default connect(mapStateToProps, {
    selectedVariations,
    buildShopSupplyAddToCart,
    editBuildShopSupplyAddToCart
})(ServiceModel)