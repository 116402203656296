/* eslint-disable */
import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PhoneNumber from 'react-number-format';

import { edit, getInquiryById } from 'actions/admin/inquiry';

import { useNavigate } from 'react-router-dom';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';

import { REMOVE_ERRORS } from 'actions/types';
import 'react-phone-number-input/style.css';
import PhoneInput, { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import extractNumber from "utils/extractNumber";
import { getCountryShort } from "utils/countryCode";

const EditInquiry = ({
  getInquiryById,
  edit,
  inquiry: { currentInquiry, loading },
  history,
  match,
  errorList,
}) => {
  /**************** use state for form data *********************/
  const [formData, setFormData] = useState({
    name: '',
    ccode: "+1",
    phone: '',
    iute164_phone: '',
    email: '',
    message: '',
    status: '',
  });

  // to resolve useEffect dependency error
  
  /**************** use memo to get current inquiry *********************/
  const { inquiry_id: inquiry_id } = useParams();
  let navigate = useNavigate();
  useMemo(() => {
    
    getInquiryById(inquiry_id);
  }, [inquiry_id]);

  
  /**************** use memo to set form data from current inquiry *********************/
  useMemo(() => {
    if (Object.keys(currentInquiry).length >= 0) {
      let var_ccode = !currentInquiry.ccode
        ? '+1'
        : currentInquiry.ccode;
      let var_phone = !currentInquiry.phone
        ? ''
        : currentInquiry.phone;
      let var_iute164_phone = var_ccode + var_phone;

      setFormData({
        name: currentInquiry.name ? currentInquiry.name : '',
        phone: var_phone,
        ccode: var_ccode,
        iute164_phone: var_iute164_phone,
        email: currentInquiry.email ? currentInquiry.email : '',
        message: currentInquiry.message ? currentInquiry.message : '',
        status: currentInquiry.status ? currentInquiry.status : '',
      });
    }
  }, [currentInquiry]);

  

  /**************** expload from data *********************/
  const { name, ccode, phone, iute164_phone, email, message, status } = formData;

  /**************** use dispatch *********************/
  

  /**************** handle on change event *********************/
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    
  };

  //########################## change event for phone number ##############################
  const onPhoneChange = number => {
    const phone = extractNumber(number, ccode);
    setFormData((form) => ({
      ...form,
      "phone": phone,
      "iute164_phone": number ?? '',
    }));
  }

  const onCountryChange = code => {
    setFormData((form) => ({
      ...form,
      "ccode": "+" + getCountryCallingCode(code ?? getCountryShort(ccode)),
    }));
  }

  /**************** handle on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    edit(formData, navigate, inquiry_id);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="12">
          <Card>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardHeader>
                <strong>Inquiry</strong> Form
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="name">
                        Name <span>*</span>
                      </Label>
                      <Input
                        required
                        type="text"
                        id="name"
                        name="name"
                        maxLength="50"
                        value={name}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.name ? true : false}
                      />
                      <Errors current_key="name" key="name" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="email">
                        Email <span>*</span>
                      </Label>
                      <Input
                        required
                        type="email"
                        id="email"
                        name="email"
                        maxLength="50"
                        value={email}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.email ? true : false}
                      />
                      <Errors current_key="email" key="email" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="phone">
                        Phone <span>*</span>
                      </Label>
                      {iute164_phone &&
                        (<PhoneInput
                          className=""
                          autoComplete="tel"
                          withCountryCallingCode={true}
                          countryCallingCodeEditable={false}
                          smartCaret={true}
                          international={true}
                          defaultCountry="US"
                          value={iute164_phone}
                          // countrySelectProps={{ unicodeFlags: true }}
                          name="phone"
                          addInternationalOption={false}
                          onChange={onPhoneChange}
                          onCountryChange={onCountryChange}
                        />)
                      }
                      <Errors current_key="phone" key="phone" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="message">
                        Message <span>*</span>
                      </Label>
                      <Input
                        required
                        type="textarea"
                        id="message"
                        name="message"
                        maxLength="500"
                        value={message}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.message ? true : false}
                      />
                      <Errors current_key="message" key="message" />
                      {/* <FormFeedback>Houston, we have a problem...</FormFeedback> */}
                    </FormGroup>
                  </Col>
                  {/* <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="status">Select</Label>
                      <Input
                        type="select"
                        name="status"
                        id="status"
                        value={status}
                        onChange={(e) => onChange(e)}
                      >
                        <option value="0">Closed</option>
                        <option value="1">Open</option>
                      </Input>
                    </FormGroup>
                  </Col> */}
                </Row>
              </CardBody>
              <CardFooter>
                <Button type="submit" size="sm" color="primary">
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
                <Link to="/admin/inquiries">
                  <Button type="reset" size="sm" color="danger">
                    <i className="fa fa-ban"></i> Cancel
                  </Button>
                </Link>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditInquiry.propTypes = {
  getInquiryById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  inquiry: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  inquiry: state.inquiry,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  getInquiryById,
  edit,
})(EditInquiry);
