/* eslint-disable */
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from '../../../utils/Loading';

import {
  getCouponList,
  deleteCoupon,
  changeStatus,
} from 'actions/admin/coupon';
import * as Constants from 'constants/index';

import { Button, Card, CardBody, Col, Row, Input } from 'reactstrap';

import PerPageSelect from '../PerPageSelect';

const renderCouponCode = (params) => {
  if (params.data === undefined) {
    return <Loading />;
  } else {
    return <div>{params.data?.coupon_code}</div>;
  }
};

const renderCustomerType = (params) => {
  return (
    <p>
      {params.data?.consumer_type === Constants.CUSTOMER_TYPE.ALL
        ? 'All'
        : params.data?.consumer_type === Constants.CUSTOMER_TYPE.DEALER
          ? 'Dealer'
          : ' Customer'}
    </p>
  );
};

const renderExpiryDate = (params) => {
  return params.data?.expiry_date !== null ? (
    <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE} locale="de">
      {params.data?.expiry_date}
    </Moment>
  ) : (
    <p> - </p>
  );
};

const renderStatus = (params, changeCouponStatus) => {
  return (
    <div>
      <Input
        type="select"
        name="status"
        id={params.data?._id}
        defaultValue={params.data?.status}
        onChange={(e, a) => {
          changeCouponStatus(params.data?._id, e.target.value);
        }}
      >
        <option value="0">Inactive</option>
        <option value="1">Active</option>
      </Input>
    </div>
  );
};

const renderActions = (params, deleteCoupon, updateCouponsList) => {
  return (
    <div>
      <Link to={`/admin/coupons/edit/${params.data?._id}`}>
        <Button type="button" size="sm" color="success" title="Edit">
          <i className="fa fa-pencil"></i>
        </Button>
      </Link>
      <Button
        type="button"
        size="sm"
        color="danger"
        title="Delete"
        onClick={(e) => {
          if (
            window.confirm(
              `Are you sure, you want to  delete ${params.data?.coupon_code} coupon?`
            )
          ) {
            deleteCoupon(params.data?._id).then((res) => {
              if (res) updateCouponsList();
            });
          }
        }}
      >
        <i className="fa fa-trash"></i>
      </Button>
    </div>
  );
};

const actions = (
  <div className="add-button-div specify-width">
    <Link to="/admin/coupons/add" className="addNewElementClass">
      <Button color="primary" size="sm">
        <i className="fa fa-plus"></i> Add Coupon
      </Button>
    </Link>
  </div>
);

const CouponList = ({
  getCouponList,
  deleteCoupon,
  changeStatus,
  couponList: { data, count },
  loading,
  subLoading,
}) => {
  const history = useNavigate();
  const location = useLocation();
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
        limit: Constants.DEFAULT_PAGE_SIZE,
        page: 1,
        orderBy: 'created_at',
        ascending: 'desc',
        query: '',
        filter: {},
        onLoad: true,
      }
  );

  // to resolve useEffect dependency error

  //################## to get updated list ###################
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);

  const updateCouponsList = () => {
    gridApi.purgeInfiniteCache();
  };
  const changeCouponStatus = async (id, value) => {
    await changeStatus(id, value);
    gridApi.purgeInfiniteCache();
  };
  //#########################Colums defination start ###################

  const anyFilter = {
    displayName: 'ANY',
    displayKey: 'any',
    numberOfInputs: 0,
    predicate: () => { },
  };

  const columns = [
    {
      field: 'coupon_code',
      headerName: 'Coupon Code',
      width: 140,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      colSpan: (params) => {
        if (params.data === undefined) {
          return 7;
        } else {
          return 0;
        }
      },
      cellRenderer: (params) => renderCouponCode(params),
    },
    {
      field: 'consumer_type',
      headerName: 'Consumer Type',
      width: 150,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filter: 'agTextColumnFilter',
      filterParams: {
        closeOnApply: true,
        filterOptions: [
          anyFilter,
          {
            displayName: 'All',
            displayKey: 'typeAllCoupon',
            numberOfInputs: 0,
            predicate: () => { },
          },
          {
            displayName: 'Customer',
            displayKey: 'typeCustomerCoupon',
            numberOfInputs: 0,
            predicate: () => { },
          },
          {
            displayName: 'Dealer',
            displayKey: 'typeDealerCoupon',
            numberOfInputs: 0,
            predicate: () => { },
          },
        ],
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => renderCustomerType(params),
    },
    {
      field: 'discount_percent',
      headerName: 'Discount (%)',
      width: 150,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agNumberColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'discount_amount',
      headerName: 'Discount (Amount)',
      width: 160,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agNumberColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'expiry_date',
      headerName: 'Expiry Date',
      width: 150,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agDateColumnFilter',
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.DATE_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
        buttons: ['reset', 'apply'],
      },
      cellRenderer: (params) => renderExpiryDate(params),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 140,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      // pass in additional parameters to the text filter
      cellRenderer: (params) => renderStatus(params, changeCouponStatus),
    },
    {
      field: '_id',
      headerName: 'Actions',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) =>
        renderActions(params, deleteCoupon, updateCouponsList),
    },
  ];

  //#########################Colums defination end ###################

  //################## START Set properties(options) of datatables ###################

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };

          const customData = await getCouponList(customParams);
          if (customData?.data?.status) {
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });
  let delayTimer;
  const onSearch = (e) => {
    clearTimeout(delayTimer);
    const searchText = e.target.value.trim();
    delayTimer = setTimeout(() => {
      if (gridApi) {
        if (Object.keys(sortingParams.filter).length) {
          gridApi.setFilterModel(sortingParams.filter);
        }
        const dataSource = {
          getRows: async (params) => {
            const page = gridApi.paginationGetCurrentPage() + 1;
            const limit = gridApi.paginationGetPageSize();

            const sortModel = params.sortModel.length
              ? params.sortModel[0]
              : null;
            const filterModel = params.filterModel ? params.filterModel : {};
            const customParams = {
              ...sortingParams,
              query: searchText,
              limit,
              orderBy: sortModel?.colId,
              ascending: sortModel?.sort,
              filter: filterModel,
              page,
            };

            const customData = await getCouponList(customParams);
            if (customData?.data?.status) {
              params.successCallback(
                customData.data.response[0].data,
                customData.data.response[0].metadata[0].totalRecord
              );
              customParams.onLoad = false;
              setSortingParams({ ...customParams });
              history(location.pathname, { state: customParams });
            } else {
              params.successCallback([], 0);
            }
          },
        };
        gridApi.setDatasource(dataSource);
        gridApi.api?.sizeColumnsToFit();
      }
    }, 1000);
  };

  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                {actions}
                <div className="rightpaneltop">
                  <Input
                    type="text"
                    id="search-text-box"
                    placeholder="Search by coupon code"
                    onKeyUp={(e) => {
                      onSearch(e);
                    }}
                  // onChange={onSearchBoxTextChanged}
                  />
                </div>
                <div className="me-3"></div>
                <div className="rightpaneltop">
                  Coupons Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>
              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      defaultColDef={{
                        resizable: true,
                      }}
                      loadingCellRenderer={customLoadingCellRenderer}
                      loadingCellRendererParams={loadingCellRendererParams}
                      domLayout={'autoHeight'}
                      rowModelType={'infinite'}
                      columnDefs={columns}
                      pagination={true}
                      sizeColumnsToFit={true}
                      onFilterChanged={filterChanged}
                      onGridReady={onGridReady}
                      paginationPageSize={sortingParams.limit}
                      cacheBlockSize={sortingParams.limit}
                      cacheQuickFilter={true}
                      onFirstDataRendered={onFirstDataRendered}
                      onPaginationChanged={onPaginationChanged}
                      ref={gridRef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

CouponList.propTypes = {
  getCouponList: PropTypes.func.isRequired,
  deleteCoupon: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  couponList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  couponList: state.coupon.couponList,
  loading: state.coupon.loading,
  subLoading: state.coupon.isSubLoading,
});

export default connect(mapStateToProps, {
  getCouponList,
  deleteCoupon,
  changeStatus,
})(CouponList);
