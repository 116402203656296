/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { setAlert } from 'actions/alert';
import {
  resetPassword,
  setPasswordError,
  getEmailById,
  tokenInvalid,
} from 'actions/auth';
import Errors from 'views/Notifications/Errors';
import ReactTooltip from 'react-tooltip';
import { Link, useParams, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Form, Input, Button, FormText } from 'reactstrap';
import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa';
import { REMOVE_ERRORS, REMOVE_ALERT } from 'actions/types';

const ResetPassword = ({
  setPasswordError,
  resetPassword,
  getEmailById,
  tokenInvalid,
  history,
}) => {
  const [formData, setFormData] = useState({
    password: '',
    confirm_password: '',
    email: '',
  });
  const { id, token } = useParams();
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [checkPassword, setCheckPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  let navigate = useNavigate();
  const inputRef = useRef(null);

  const { password, confirm_password, email } = formData;
  useEffect(() => {
    getEmailById(id, token).then((data) => {
      data &&
        data.status === true &&
        setFormData({
          ...formData,
          email: data.response.email,
        });
      // : tokenInvalid(navigate, errorMessage);
    });
  }, []);

  useEffect(() => {
    ReactTooltip.show(inputRef.current);
  }, []);

  const togglePassword = (e) => {
    e.preventDefault();
    if (e.currentTarget.getAttribute('data-id') == 'password') {
      setPasswordShown(!passwordShown);
      setConfirmPassword(confirmPassword);
    }
    if (e.currentTarget.getAttribute('data-id') == 'confirm_password') {
      setPasswordShown(passwordShown);
      setConfirmPassword(!confirmPassword);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value?.trim() });
    setCheckPassword(false);
    setErrorMessage('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    const isChecked = regex.test(password);
    if (password !== confirm_password) {
      setErrorMessage('Password do not match.');
    } else if (!isChecked) {
      setCheckPassword(true);
      setErrorMessage('');
    } else {
      setErrorMessage('');
      setCheckPassword(false);
      resetPassword(password, confirm_password, email, id, navigate);
    }
  };

  return (
    <div className="login_body">
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <Form onSubmit={onSubmit}>
              <div className="p-4 card">
                <h4 className="title">Reset Password</h4>
                <div className="form-group">
                  {password == '' && (
                    <ReactTooltip
                      id="title required1"
                      efect="solid"
                      place="bottom"
                    >
                      Password must contain at least 8 characters, including
                      Upper/lowercase, numbers and special characters
                    </ReactTooltip>
                  )}
                  <Input
                    type={passwordShown ? 'text' : 'password'}
                    className="form-control"
                    placeholder="Password"
                    autoComplete="password"
                    name="password"
                    ref={inputRef}
                    data-tip
                    data-for="title required1"
                    required
                    value={password}
                    onChange={(e) => onChange(e)}
                    // invalid={errorList.password ? true : false}
                  />
                  <span
                    className="viewpass"
                    onClick={togglePassword}
                    data-id="password"
                  >
                    {passwordShown ? <FaRegEyeSlash /> : <FaRegEye />}
                  </span>
                  {/* <Errors current_key="password" key="password" /> */}
                  {checkPassword && password != '' && (
                    <div id="message">
                      <FormText
                        style={{ fontSize: '10px' }}
                        className="text-danger"
                      >
                        {' '}
                        Password requirements:
                      </FormText>
                      <FormText>
                        <li
                          style={{ fontSize: '10px' }}
                          className="text-danger"
                        >
                          Must contain at least 8 characters
                        </li>
                        <li
                          style={{ fontSize: '10px' }}
                          className="text-danger"
                        >
                          At least 1 uppercase letter
                        </li>
                        <li
                          style={{ fontSize: '10px' }}
                          className="text-danger"
                        >
                          At least 1 number
                        </li>
                        <li
                          style={{ fontSize: '10px' }}
                          className="text-danger"
                        >
                          At least 1 special character (@$!%*#?&.)
                        </li>
                      </FormText>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  {confirm_password == '' && (
                    <ReactTooltip
                      id="title required2"
                      efect="solid"
                      place="bottom"
                    >
                      Password must contain at least 8 characters, including
                      Upper/lowercase, numbers and special characters
                    </ReactTooltip>
                  )}
                  <Input
                    type={confirmPassword ? 'text' : 'password'}
                    className="form-control"
                    placeholder="Confirm password"
                    autoComplete="confirm_password"
                    name="confirm_password"
                    ref={inputRef}
                    data-tip
                    data-for="title required2"
                    required
                    value={confirm_password}
                    onChange={(e) => onChange(e)}
                    // invalid={errorList.confirm_password ? true : false}
                  />
                  <span
                    className="viewpass"
                    onClick={togglePassword}
                    data-id="confirm_password"
                  >
                    {confirmPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                  </span>
                  {errorMessage && (
                    <span className="text-danger">{errorMessage}</span>
                  )}
                  <Errors
                    current_key="confirm_password"
                    key="confirm_password"
                  />
                </div>
                <div
                  className="form-group"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Button
                    type="submit"
                    className="btn btn-primary d-block loginbtn"
                  >
                    Submit
                  </Button>
                  <Link
                    className="btn-label"
                    to="/"
                    style={{ color: '#6d2a5f' }}
                  >
                    Back to Login
                  </Link>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  setPasswordError: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  getEmailById: PropTypes.func.isRequired,
  tokenInvalid: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  errorList: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  errorList: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setAlert,
  setPasswordError,
  resetPassword,
  getEmailById,
  tokenInvalid,
})(ResetPassword);
