import axios from 'axios';

import {
    IronNumber_LOADING,
    IronNumber_CREATED,
    IronNumber_CREATE_ERROR,
    IronNumber_FAIL,
    IronNumber_UPDATED,
    IronNumber_UPDATE_ERROR,
    IronNumber_DELETED,
    IronNumber_DELETE_ERROR,
    IronNumber_LIST_UPDATED,
    GET_IronNumber_BY_ID,
    SET_ERRORS_LIST
} from 'actions/types';

import { updateFilterData } from 'actions/commonActions';

//get Flex Code list
export const getIronNumberList = () => async (dispatch) => {
    try {
        dispatch({
            type: IronNumber_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        // newsParams = updateFilterData(newsParams);
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            // params: newsParams,
        };
        // const query = newsParams.query ? newsParams.query : '&query=';

        const res = await axios.get(`/api/admin/iron-number-nomenclature`, config);

        dispatch({
            type: IronNumber_LIST_UPDATED,
            payload: {
                message: res.data.message,
                alertType: 'success',
                data: {
                    // newsParams: newsParams,
                    ironNumberList: res.data.response[0],
                },
            },
        });
        return res;
    } catch (err) {
        dispatch({
            type: IronNumber_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
    }
};

// Create Flex Code
export const create = (formData, history) => async (dispatch) => {
    try {
        dispatch({
            type: IronNumber_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const res = await axios.post('/api/admin/iron-number-nomenclature/create', formData, config);
        if (res.data.status === true) {
            dispatch({
                type: IronNumber_CREATED,
                payload: {
                    message: res.data.message,
                    alertType: 'success',
                    data: res.data.response,
                },
            });

        } else {
            const errors = res.data.errors;
            let errorsList = {};
            if (errors) {
                await errors.forEach((error) => {
                    errorsList = { ...errorsList, [error.param]: error.msg };
                });
            }
            dispatch({
                type: IronNumber_CREATE_ERROR,
                payload: {
                    message: res.data.message,
                    alertType: 'danger',
                    errorsList,
                },
            });
            dispatch({
                type: SET_ERRORS_LIST,
                payload: { ...errorsList },
            });
        }
    } catch (err) {
        dispatch({
            type: IronNumber_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
    }
};

// Edit Flex Code
export const edit = (formData, history, id) => async (dispatch) => {
    try {
        dispatch({
            type: IronNumber_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const res = await axios.post(
            `/api/admin/iron-number-nomenclature/${id}`,
            formData,
            config
        );
        if (res.data.status === true) {
            dispatch({
                type: IronNumber_UPDATED,
                payload: {
                    message: res.data.message,
                    alertType: 'success',
                    data: res.data.response,
                },
            });
            history('/admin/iron-number-nomenclature');
        } else {
            const errors = res.data.errors;
            let errorsList = {};
            if (errors) {
                await errors.forEach((error) => {
                    errorsList = { ...errorsList, [error.param]: error.msg };
                });
            }
            dispatch({
                type: IronNumber_UPDATE_ERROR,
                payload: {
                    message: res.data.message,
                    alertType: 'danger',
                    errorsList,
                },
            });
            dispatch({
                type: SET_ERRORS_LIST,
                payload: { ...errorsList },
            });
        }
    } catch (err) {
        dispatch({
            type: IronNumber_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
    }
};

// Delete Flex Code Page
export const deleteIronNumber = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IronNumber_LOADING,
            payload: { is_loading: true, is_sub_element: true },
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const res = await axios.delete(`/api/admin/iron-number-nomenclature/${id}`, config);

        if (res.data.status === true) {
            dispatch({
                type: IronNumber_DELETED,
                payload: {
                    message: res.data.message,
                    alertType: 'success',
                    data: res.data.response,
                },
            });
            return true;
        } else {
            dispatch({
                type: IronNumber_DELETE_ERROR,
                payload: {
                    message: res.data.message,
                    alertType: 'danger',
                },
            });
            return false;
        }
    } catch (err) {
        dispatch({
            type: IronNumber_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
        return false;
    }
};

// Get Flex Code by id
export const getIronNumberById = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IronNumber_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const res = await axios.get(`/api/admin/iron-number-nomenclature/${id}`, config);

        await dispatch({
            type: GET_IronNumber_BY_ID,
            payload: {
                message: res.data.message,
                alertType: 'success',
                data: res.data.response,
            },
        });
    } catch (err) {
        dispatch({
            type: IronNumber_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : 'Something went wrong.',
                alertType: 'danger',
                error: err,
            },
        });
    }
};
