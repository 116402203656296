import axios from 'axios';
import {
  INQUIRY_LOADING,
  INQUIRY_CREATED,
  INQUIRY_CREATE_ERROR,
  INQUIRY_FAIL,
  INQUIRY_UPDATED,
  INQUIRY_UPDATE_ERROR,
  INQUIRY_DELETED,
  INQUIRY_DELETE_ERROR,
  INQUIRY_LIST_UPDATED,
  GET_INQUIRY_BY_ID,
  INQUIRY_CHANGE_STATUS,
  INQUIRY_CHANGE_STATUS_ERROR,
} from 'actions/types';

import { updateFilterData } from 'actions/commonActions';

// Create inquiry
export const add = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: INQUIRY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post('/api/admin/inquiry/add', formData, config);
    if (res.data.status === true) {
      dispatch({
        type: INQUIRY_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/inquiries');
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: INQUIRY_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: INQUIRY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit Inquiry
export const edit = (formData, history, inquiry_id) => async (dispatch) => {
  try {
    dispatch({
      type: INQUIRY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/inquiry/${inquiry_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: INQUIRY_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/inquiries');
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: INQUIRY_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: INQUIRY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

export const getInquiryToDownload = async (inquiryParams) => {
  try {
    inquiryParams = updateFilterData(inquiryParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: inquiryParams,
    };
    const res = await axios.get(`/api/admin/inquiry/csv/download`, config);
    return res.data.response;
  } catch (err) {
    return {};
  }
};

// Delete Inquiry
export const deleteInquiry = (inquiry_id, history) => async (dispatch) => {
  try {
    dispatch({
      type: INQUIRY_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(`/api/admin/inquiry/${inquiry_id}`, config);
    if (res.data.status === true) {
      dispatch({
        type: INQUIRY_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: INQUIRY_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: INQUIRY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get inquiries list

export const getInquiriesList = (inquiryParams) => async (dispatch) => {
  try {
    dispatch({
      type: INQUIRY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    inquiryParams = updateFilterData(inquiryParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: inquiryParams,
    };

    const res = await axios.get(`/api/admin/inquiry`, config);
    dispatch({
      type: INQUIRY_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          inquiryParams: inquiryParams,
          inquiryList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    dispatch({
      type: INQUIRY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete Inquiry
export const getInquiryById = (inquiry_id) => async (dispatch) => {
  try {
    dispatch({
      type: INQUIRY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/inquiry/${inquiry_id}`, config);
    await dispatch({
      type: GET_INQUIRY_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: INQUIRY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//change status
export const changeStatus = (inquiry_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: INQUIRY_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/inquiry/change-status/${inquiry_id}`,
      { status },
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: INQUIRY_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: INQUIRY_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: inquiry_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: INQUIRY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
