import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    selectedVariations
} from 'actions/admin/order';

import {
    Button,
    Table
} from 'reactstrap';

const CartDetails = ({ productDetail, deleteProduct, editProduct, showAddEditPreviewForm }) => {

    const totalPrice = (priceItem) => {
        let fittingTotalPrice = 0;
        if (priceItem.productFitting.length > 0) {
            priceItem.productFitting.map((fitting, idx) => {
                if (fitting.price && fitting.price > 0) {
                    fittingTotalPrice = Number(parseFloat(fittingTotalPrice) + parseFloat(fitting.price)).toFixed(2)
                }
            })
        }
        return Number((parseFloat(priceItem.price) * parseFloat(priceItem.quantity)) + parseFloat(fittingTotalPrice)).toFixed(2);
    }
    return (
        <Table responsive className='orderaddedittable'>
            <thead>
                <tr>
                    <th>Product</th>
                    <th>Cost</th>
                    <th>Quantity</th>
                    <th>Total</th>
                    {showAddEditPreviewForm && <th>Action</th>}
                </tr>
            </thead>
            <tbody>
                {
                    productDetail.addedProduct.map((item, index) => {
                        return (
                            <tr key={item.productVariationSku} index={index} className='bg-light'>
                                <td>
                                    <table>
                                        <tbody>
                                            <tr><td className='productheadings'><strong>{item.product_title}-{item.category_name}</strong></td></tr>
                                            {item.productVariationDetail.length > 0 ? (
                                                <>
                                                    <tr className='product-SKU'>
                                                        <td>
                                                            <p className="mb-0">
                                                                <span className='table-label'>SKU:</span>
                                                                {""}
                                                                {item.productVariationSku}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr className='product-infromation'>
                                                        <td>
                                                            <div className="">
                                                                {
                                                                    item.productVariationDetail.map((variation, key) => {
                                                                        return (
                                                                            <p className="mb-0" key={variation.attribute_name}>
                                                                                <span className='table-label'> {variation.attribute_name} :</span>
                                                                                {" "}
                                                                                {variation.term_option}
                                                                            </p>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                            {
                                                                item.productFitting && item.productFitting.length > 0 ?
                                                                    item.productFitting.map((fittingOptions) => {
                                                                        if (fittingOptions.attribute_name == "Playing Length") {
                                                                            return (
                                                                                <div key={fittingOptions.attribute_value}>
                                                                                    <span className='table-label'> Playing Length : </span> {(Number(fittingOptions.attribute_value) === 0) ? "Uncut" : fittingOptions.attribute_value}
                                                                                </div>
                                                                            )
                                                                        }
                                                                        if (fittingOptions.attribute_name == "Extra Wrap") {
                                                                            return (
                                                                                <>
                                                                                    <div key={fittingOptions.attribute_name}>
                                                                                        <span className='table-label'> Extra Wraps :</span>
                                                                                        {
                                                                                            fittingOptions.attribute_value && fittingOptions.attribute_value == 1 ?
                                                                                                'None'
                                                                                                :
                                                                                                fittingOptions.attribute_value == 2 ?
                                                                                                    '1'
                                                                                                    :
                                                                                                    fittingOptions.attribute_value == 3 ?
                                                                                                        '2'
                                                                                                        :
                                                                                                        'None'
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                        if (fittingOptions.attribute_name == "Grip Logo") {
                                                                            return (
                                                                                <>
                                                                                    <div key={fittingOptions.attribute_name}>
                                                                                        <span className='table-label'> Grip Logo :</span>
                                                                                        {fittingOptions.attribute_value && fittingOptions.attribute_value}
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                        if (fittingOptions.attribute_name == "Adaptors") {
                                                                            return (
                                                                                <>
                                                                                    <div key={fittingOptions.attribute_name}>
                                                                                        <span className='table-label'> Adaptor : </span>{fittingOptions.option_name}
                                                                                    </div>
                                                                                    <div key={fittingOptions.sub_option_name}>
                                                                                        <span className='table-label'> Adaptor Setting :</span> {fittingOptions.sub_option_name}
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                        if (fittingOptions.attribute_name == "Grips") {
                                                                            return (
                                                                                <>
                                                                                    <div key={fittingOptions.attribute_name}>
                                                                                        <span className='table-label'> Grips Brand :</span> {fittingOptions.option_name}
                                                                                    </div>
                                                                                    <div key={fittingOptions.sub_option_name}>
                                                                                        <span className='table-label'> Grip Model :</span> {fittingOptions.sub_option_name}
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                        if (fittingOptions.attribute_name == "Product Customization") {
                                                                            return (
                                                                                <>
                                                                                    <div className="product_customization_notes" key={fittingOptions.attribute_name}>
                                                                                        <span className='table-label'> Product Customization :</span> {fittingOptions.attribute_value}
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            <div key={`${fittingOptions.attribute_name}1`}>
                                                                                <span className='table-label'> Quantity :</span> 1
                                                                            </div>
                                                                        }
                                                                    })
                                                                    :
                                                                    ''
                                                            }
                                                        </td>
                                                    </tr>
                                                </>
                                            ) : null}
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <table>
                                        <tbody>
                                            <tr><td>&nbsp;</td></tr>
                                            <tr><td>&nbsp;</td></tr>
                                            <tr>
                                                <td>
                                                    ${item.price}
                                                    {

                                                        item.productVariationDetail.map((variation, key) => {

                                                            return (
                                                                <p className="mb-0" key={key}>
                                                                    &nbsp;
                                                                </p>
                                                            );
                                                        })}
                                                    {
                                                        item.productFitting && item.productFitting.length > 0 ?
                                                            item.productFitting.map((fittingOptions) => {
                                                                if (fittingOptions.attribute_name == "Adaptors") {
                                                                    return (
                                                                        <>
                                                                            <div key={`${fittingOptions.attribute_name}00`}>
                                                                                ${Number(fittingOptions.price).toFixed(2)}
                                                                            </div>
                                                                            <div key={`${fittingOptions.attribute_name}11`}>
                                                                                &nbsp;
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                                if (fittingOptions.attribute_name == "Grips") {
                                                                    return (
                                                                        <>
                                                                            <div key={`${fittingOptions.attribute_name}22`}>
                                                                                &nbsp;
                                                                            </div>
                                                                            <div key={`${fittingOptions.attribute_name}33`}>
                                                                                ${Number(fittingOptions.price).toFixed(2)}
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                                if (fittingOptions.attribute_name == "Playing Length") {
                                                                    return (
                                                                        <>
                                                                            <div key={`${fittingOptions.attribute_name}`}>
                                                                                &nbsp;
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                                if (fittingOptions.attribute_name == "Extra Wrap") {
                                                                    return (
                                                                        <>
                                                                            <div key={`${fittingOptions.attribute_name}`}>
                                                                                &nbsp;
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                                if (fittingOptions.attribute_name == "Grip Logo") {
                                                                    return (
                                                                        <>
                                                                            <div key={`${fittingOptions.attribute_name}`}>
                                                                                &nbsp;
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                            })
                                                            :
                                                            ''
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <table>
                                        <tbody>
                                            <tr><td>&nbsp;</td></tr>
                                            <tr><td>&nbsp;</td></tr>
                                            <tr>
                                                <td>
                                                    {item.quantity}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <table>
                                        <tbody>
                                            <tr><td>&nbsp;</td></tr>
                                            <tr><td>&nbsp;</td></tr>
                                            <tr ><td >${totalPrice(item)}</td></tr>
                                        </tbody>
                                    </table>
                                </td>
                                {showAddEditPreviewForm && <td>
                                    <table>
                                        <tbody>
                                            <tr><td>&nbsp;</td></tr>
                                            <tr><td>&nbsp;</td></tr>
                                            <tr>
                                            </tr>
                                            <tr><td>
                                                <Button
                                                    type="button"
                                                    className="btn-success btn-sm mr-05"
                                                    onClick={() => {
                                                        editProduct(item);
                                                    }}
                                                >
                                                    <i className="fa fa-pencil"></i>
                                                </Button>
                                                <Button
                                                    type="button"
                                                    className="btn-danger btn-sm"
                                                    onClick={() => {
                                                        if (
                                                            window.confirm(
                                                                `Are you sure, you want to delete ${item.product_title} product?`
                                                            )
                                                        ) {
                                                            deleteProduct(
                                                                productDetail.cartId,
                                                                item._id
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </Button>
                                            </td></tr>
                                        </tbody>
                                    </table>
                                </td>}
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
    )
}


const mapStateToProps = (state) => ({
    variationData: state.order.variationData,
});

export default connect(mapStateToProps, {
    selectedVariations
})(CartDetails);