import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Spinner from 'views/Spinner';
import { CKEditor } from 'ckeditor4-react';
import { create, getIronNumberList } from 'actions/admin/ironNumber';

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    Form,
    FormGroup,
    Label,
    Row,
} from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import Errors from 'views/Notifications/Errors';
import { IronNumber_LOADING } from 'actions/types';
import ironNumber from 'reducers/admin/ironNumber';
import { CKEditorConfig } from 'constants';

const IronNomenclatureEditor = ({
    getIronNumberList,
    create,
    errorList,
    loading,
    ironNumber,
}) => {
    let navigate = useNavigate();


    /**************** use state for form data *********************/
    const [formData, setFormData] = useState({
        description: '',
    });

    /**************** expload form data *********************/


    useEffect(async () => {
        await getIronNumberList();
    }, [])

    useMemo(() => {
        if (Object.keys(ironNumber).length > 0) {
            setFormData({
                description: ironNumber?.description
            })
        }
    }, [ironNumber])

    // handle input change
    const handleChange = (event) => {
        setFormData((data1) => ({
            ...data1,
            description: event.editor.getData()
        }));
    };
    /**************** on submit event *********************/
    const onSubmit = (e) => {
        e.preventDefault();
        create(formData, navigate);
    };


    const {
        description
    } = formData;

    return (
        <div className="animated fadeIn">
            {loading ? (
                <Spinner />
            ) : (
                <Row>
                    <Col xs="12" sm="12">
                        <Card>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => onSubmit(e)}
                                encType="multipart/form-data"
                            >
                                <CardBody>
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label htmlFor="description">
                                                    Iron Number Nomenclature
                                                </Label>
                                                {<CKEditor

                                                    initData={description}
                                                    id="description"
                                                    name="description"
                                                    config={CKEditorConfig}
                                                    invalid={errorList.description ? true : false}
                                                    onChange={handleChange}
                                                />}
                                                <Errors
                                                    current_key="description"
                                                    key="description"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <Button type="submit" size="sm" color="primary" disabled={loading}>
                                        <i className="fa fa-dot-circle-o"></i> Submit
                                    </Button>
                                    <Link to="/admin/flex-code-nomenclature">
                                        <Button type="reset" size="sm" color="danger">
                                            <i className="fa fa-ban"></i> Cancel
                                        </Button>
                                    </Link>
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            )}
        </div>
    );
};

IronNomenclatureEditor.propTypes = {
    getIronNumberList: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
    ironNumber: state.ironNumber.ironNumberList,
    loading: state.ironNumber.loading,
    errorList: state.errors,
});

export default connect(mapStateToProps, {
    getIronNumberList,
    create,
})(IronNomenclatureEditor);
