import {
    TAX_RATE_ERROR,
    TAX_RATE_LOADING,
    TAX_RATE_UPDATED
} from "actions/types";
import axios from "axios";
import {
    ALERT,
    METHOD
} from "../../constants";

const config = {
    headers: {
        'Content-Type': 'application/json',
    },
};

const setLoading = (dispatch, loading) => {
    dispatch({
        type: TAX_RATE_LOADING,
        payload: { loading },
    });
}

const setError = (dispatch, error, message) => {

    dispatch({
        type: TAX_RATE_ERROR,
        payload: {
            message: message,
            errorsList: error,
            alertType: ALERT.DANGER,
            isAlert: true
        },
    });
}

// update stackeable Product
export const taxRateApi = (method, formData = {}) => async (dispatch) => {
    try {
        setLoading(dispatch, true);
        const res = await axios({
            data: formData,
            method: method,
            url: "/api/admin/tax-rates",
        })

        if (res.data.status === true) {
            dispatch({
                type: TAX_RATE_UPDATED,
                payload: {
                    data: res.data.response,
                    message: res.data.message,
                    isAlert: method == METHOD.POST,
                    alertType: ALERT.SUCCESS
                }
            });

            return true;

        } else {

            const errors = res.data.errors;
            let errorsList = [];

            if (errors) {
                await errors.forEach((error) => {
                    errorsList[error.param] = error.msg;
                });
            }
            setError(dispatch, res.data.message, errorsList);
            return false;
        }
    } catch (err) {
        setError(dispatch, err.message, []);
        return false;
    }
};