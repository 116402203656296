/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { Outlet } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from 'react-router-dom';
import { Container } from 'reactstrap';
import 'react-notifications/lib/notifications.css';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { FaBars } from 'react-icons/fa';

import { CHeader, CFooter, CBreadcrumb, CBreadcrumbItem } from '@coreui/react';
// sidebar nav config

import Sidebar from './Sidebar';

// routes config
import AdminRoutes from '../../Routing/AdminRoutes';
import DefaultHeader from './DefaultHeader';
import DefaultFooter from './DefaultFooter';
import ChangePassword from '../Settings/ChangePassword';

const index = ({ auth, alerts, props, history, breadcrumDetails }) => {
  const [userType, setUserType] = useState(auth.user?.userType);

  useEffect(() => {
    if (auth.user?.userType) {
      setUserType(auth.user?.userType);
      let newRoutes = AdminRoutes.filter(
        (route) => route.user && route.user.includes(auth.user?.userType)
      );
    }
    if (auth?.user?.first_Login) {
      setChangePasswordModalModal(true);
    }
  }, [auth.user?.userType]);

  const location = useLocation();
  const path = location.pathname;
  const newapth = path.replace('/admin/', '').split('/');
  const modifiedPath = newapth.map((id) => {
    if (breadcrumDetails[id]) {
      return breadcrumDetails[id].toLowerCase();
    }
    return id;
  });
  let strRegex = new RegExp(/\d/);
  console.log(modifiedPath);
  // match the regex with the string
  let result = strRegex.test(modifiedPath[modifiedPath.length - 1]);
  let newUrl;
  if (result) {
    const newpath = modifiedPath.pop();
    newUrl = modifiedPath.join('/');
  } else {
    newUrl = modifiedPath.join('/');
  }
  const [toggle, setToggle] = useState(true);

  const [isActive, setActive] = useState(false);

  const [changePasswordModal, setChangePasswordModalModal] = useState(true);

  const goToChangePassword = () => {
    setChangePasswordModalModal(false);
  }
  const toggleClass = () => {
    setActive(!isActive);
  };

  const [isZindex, setZindex] = useState(false);
  const toggleOverlayClass = () => {
    setActive(!isActive);
    setZindex(!isZindex);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location]);

  return (
    <>
      {userType ? (
        <div
          className={
            isActive ? 'app admin-panel logo-resizer' : 'app admin-panel'
          }
        >
          <CHeader>
            <DefaultHeader history={history} />
          </CHeader>

          <div className="app-body">
            <main className={isActive ? 'main opensidebar' : 'main'}>
              <button className="btn togglebars">
                <FaBars onClick={toggleClass} />
              </button>
              {toggle && <Sidebar close={() => setToggle(false)} />}
              <span className="overlap" onClick={toggleOverlayClass}></span>
              <div className="rightpanel">
                <CBreadcrumb>
                  <CBreadcrumbItem href="/admin">Dashboard</CBreadcrumbItem>
                  {newUrl !== '/admin' && (
                    <CBreadcrumbItem href="">{newUrl}</CBreadcrumbItem>
                  )}
                </CBreadcrumb>

                <Container fluid>
                  <Outlet />
                </Container>
              </div>
            </main>
          </div>
          <CFooter>
            <DefaultFooter />
          </CFooter>
          {!auth?.user?.first_login &&
            <ChangePassword
              isOpen={changePasswordModal}
              toggle={goToChangePassword}
              props={!auth?.user?.first_login}
            ></ChangePassword>
          }
        </div>
      ) : localStorage.getItem('token') ? (
        <Navigate to="/admin" />
      ) : (
        <Navigate to="" />
      )}
    </>
  );
};

index.prototype = {
  auth: PropTypes.object.isRequired,
  breadcrumDetails: PropTypes.object.isRequired,
  // alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  breadcrumDetails: state.breadcrum.breadcrumDetails,
  // alerts: state.alert,
});

export default connect(mapStateToProps)(index);
