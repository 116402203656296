import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

import { create } from 'actions/admin/dealerType';

import { REMOVE_ERRORS } from 'actions/types';

import Errors from 'views/Notifications/Errors';
import Spinner from 'views/ButtonSpinner';

const CreateDealerType = ({
  create,
  errorList,
  toggle,
  isOpen,
  modal_loading,
  update,
}) => {
  //########################## set the initial form data ##############################
  const initialState = {
    name: '',
    discount: '',
  };
  const [formData, setFormData] = useState(initialState);

  //########################## explod form data vaiables ##############################
  const { name, discount } = formData;

  //########################## use dispatch ##############################
  const dispatch = useDispatch();

  useEffect(() => {
    let updated_name = name.replace(/[^a-zA-Z0-9\s]/gi, '');
    setFormData({ ...formData, name: updated_name });
  }, [name]);

  //########################## change event for all inputs ##############################
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //########################## submit form data ##############################
  const onSubmit = (e) => {
    e.preventDefault();
    Object.keys(formData).forEach((k) => {
      typeof formData[k] === 'string'
        ? (formData[k] = formData[k]?.trim())
        : (formData[k] = formData[k]);
    });
    create(formData).then((res) => {
      if (res === true) {
        toggle();
        update();
      } else {
        setIsSubmit(false);
      }
    });
  };
  //########################## set the submit state ##############################
  const [isSubmit, setIsSubmit] = useState(false);

  //########################## reset for data when modal is closed ##############################
  const resetModal = () => {
    dispatch({ type: REMOVE_ERRORS });
    //reset the submit state
    setIsSubmit(false);
    //reset the form data to initial state
    setFormData(initialState);
  };

  return (
    <div className="animated fadeIn">
      <Modal isOpen={isOpen} toggle={toggle} onClosed={resetModal}>
        <ModalHeader toggle={toggle}>Add New Dealer Type</ModalHeader>
        <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
          <ModalBody>
            <Row>
              <Col xs="12" sm="12">
                <FormGroup>
                  <Label htmlFor="name">
                    Name <span>*</span>
                  </Label>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    maxLength="100"
                    value={name}
                    placeholder="Enter Name"
                    required
                    onChange={(e) => onChange(e)}
                    invalid={errorList.name ? true : false}
                  />
                  <Errors current_key="name" key="name" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="discount">
                    {' '}
                    Discount (in %) <span>*</span>
                  </Label>
                  <Input
                    type="number"
                    id="discount"
                    name="discount"
                    min={0}
                    max={100}
                    step={0.01}
                    // maxLength="100"
                    value={discount}
                    placeholder="Enter Discount"
                    required
                    onChange={(e) => onChange(e)}
                    invalid={errorList.discount ? true : false}
                  />
                  <Errors current_key="discount" key="discount" />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="modalFooter">
              {modal_loading ? (
                <Spinner />
              ) : (
                <Button
                  className="mr-05"
                  type="submit"
                  size="sm"
                  color="primary"
                  // onClick={(e) => onSubmit(e)}
                  disabled={modal_loading}
                >
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
              )}
              <Button type="reset" size="sm" color="danger" onClick={toggle}>
                <i className="fa fa-ban"></i> Cancel
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};
CreateDealerType.defaultProps = {
  isOpen: false,
};

CreateDealerType.propTypes = {
  create: PropTypes.func.isRequired,
  modal_loading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  errorList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  modal_loading: state.dealerType.modal_loading,
});
export default connect(mapStateToProps, { create })(CreateDealerType);
