import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import slugify from 'react-slugify';
import { CKEditor } from 'ckeditor4-react';

import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';

import { create } from 'actions/admin/blog';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { REMOVE_ERRORS } from 'actions/types';
import { useNavigate } from 'react-router-dom';
import { CKEditorConfig } from 'constants';

const CreateBlog = ({ create, history, errorList, loading }) => {
  let navigate = useNavigate();
  /**************** use state for form data *********************/
  const [formData, setFormData] = useState({
    blog_title: '',
    blog_header: '',
    slug: '',
    description: '',
    meta_description: '',
    selectedFile: '',
    image: '',
  });

  /**************** expload form data *********************/
  const {
    blog_title,
    blog_header,
    slug,
    description,
    meta_description,
    // selectedFile, // image file
    image, // object url of image (images src)
  } = formData;

  /**************** dispatch to remove error *********************/

  /**************** on Change event *********************/
  const onChange = (e) => {
    if (e.target.name === 'blog_title') {
      let new_slug = slugify(e.target.value);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        slug: new_slug,
      });
    } else if (e.target.name === 'thumbnail') {
      let image_file = e.target.files[0];

      let image_url = URL.createObjectURL(image_file);

      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        selectedFile: image_file,
        image: image_url,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handelDescriptionChange = (event) => {
    setFormData((ev) => ({
      ...ev,
      description: event.editor.getData(),
    }));
    //setFormData({ ...formData, description: event.editor.getData() });
  };

  /**************** on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    create(formData, navigate);
  };

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <Form
                className="form-horizontal"
                onSubmit={(e) => onSubmit(e)}
                encType="multipart/form-data"
              >
                <CardBody>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="slug">
                          Blog Slug <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="slug"
                          name="slug"
                          maxLength="100"
                          value={slug}
                          required
                          readOnly
                          invalid={errorList.slug ? true : false}
                        />
                        <Errors current_key="slug" key="slug" />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="blog_title">
                          Blog Title <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="blog_title"
                          name="blog_title"
                          maxLength="100"
                          value={blog_title}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.blog_title ? true : false}
                        />
                        <Errors current_key="blog_title" key="blog_title" />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="blog_header">
                          Blog Header <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="blog_header"
                          name="blog_header"
                          maxLength="100"
                          value={blog_header}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.blog_header ? true : false}
                        />
                        <Errors current_key="blog_header" key="blog_header" />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="meta_description">
                          Meta Description
                        </Label>
                        <Input
                          type="textarea"
                          id="meta_description"
                          name="meta_description"
                          value={meta_description}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.meta_description ? true : false}
                        />
                        <Errors
                          current_key="meta_description"
                          key="meta_description"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="thumbnail">
                          Thumbnail<span>*</span>
                        </Label>
                        <Input
                          type="file"
                          id="thumbnail"
                          name="thumbnail"
                          onChange={(e) => onChange(e)}
                          multiple
                          invalid={errorList.image ? true : false}
                        />
                        <img src={image} className="preview-img" alt="" />
                        <Errors current_key="image" key="image" />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="description">
                          Body <span>*</span>
                        </Label>
                        <CKEditor
                          data={description}
                          id="description"
                          name="description"
                          config={CKEditorConfig}
                          onChange={(event) => handelDescriptionChange(event)}
                          invalid={errorList.description ? true : false}
                        />
                        <Errors current_key="description" key="description" />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button
                    type="submit"
                    size="sm"
                    color="primary"
                    disabled={loading}
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Link to="/admin/blogs">
                    <Button type="reset" size="sm" color="danger">
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

CreateBlog.propTypes = {
  create: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.blog.loading,
});

export default connect(mapStateToProps, { create })(CreateBlog);
