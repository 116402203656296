import axios from 'axios';

import {
  CMS_LOADING,
  CMS_CREATED,
  CMS_CREATE_ERROR,
  CMS_FAIL,
  CMS_UPDATED,
  CMS_UPDATE_ERROR,
  CMS_DELETED,
  CMS_DELETE_ERROR,
  CMS_LIST_UPDATED,
  GET_CMS_BY_ID,
  CMS_CHANGE_STATUS,
  CMS_CHANGE_STATUS_ERROR,
  SET_ERRORS_LIST,
} from 'actions/types';

import { updateFilterData } from 'actions/commonActions';

// Create CMS
export const create = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: CMS_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    // for (const item of formData.data[0].selectedFile) {
    // var base64String = formData.data[0].selectedFile[0];
    // var applyPaddingsRules = true;

    // // Remove MIME-type from the base64 if exists
    // let length = base64String.AsSpan().Slice(base64String.IndexOf(',') + 1).Length;

    // var fileSizeInByte = Math.Ceiling(length / 4) * 3;
    // console.log(fileSizeInByte, "fileSizeInByte")

    // if (applyPaddingsRules && base64Length >= 2) {
    //   var paddings = base64String[^ 2..];
    //   fileSizeInByte = paddings.Equals("==") ? fileSizeInByte - 2 : paddings[1].Equals('=') ? fileSizeInByte - 1 : fileSizeInByte;
    // }

    const res = await axios.post('/api/admin/cms/create', formData, config);
    if (res.data.status === true) {
      dispatch({
        type: CMS_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/cms');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: CMS_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
    }
  } catch (err) {
    dispatch({
      type: CMS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit CMS
export const edit = (formData, history, cms_id) => async (dispatch) => {
  try {
    dispatch({
      type: CMS_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.post(`/api/admin/cms/${cms_id}`, formData, config);
    if (res.data.status === true) {
      dispatch({
        type: CMS_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/cms');
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: CMS_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
    }
  } catch (err) {
    dispatch({
      type: CMS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete CMS Page
export const deleteCMS = (cms_id) => async (dispatch) => {
  try {
    dispatch({
      type: CMS_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(`/api/admin/cms/${cms_id}`, config);

    if (res.data.status === true) {
      dispatch({
        type: CMS_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: CMS_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: CMS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

//get CMS list
export const getCMSList = (cmsParams) => async (dispatch) => {
  try {
    dispatch({
      type: CMS_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    cmsParams = updateFilterData(cmsParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: cmsParams,
    };
    // const query = cmsParams.query ? cmsParams.query : '&query=';

    const res = await axios.get(`/api/admin/cms/`, config);
    dispatch({
      type: CMS_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          cmsParams: cmsParams,
          cmsList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    dispatch({
      type: CMS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Get CMS by id
export const getCMSById = (cms_id) => async (dispatch) => {
  try {
    dispatch({
      type: CMS_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(`/api/admin/cms/${cms_id}`, config);

    await dispatch({
      type: GET_CMS_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: CMS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//change status
export const changeStatus = (cms_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: CMS_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/cms/change-status/${cms_id}`,
      { status },
      config
    );

    if (res.data.status === true) {
      await dispatch({
        type: CMS_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: CMS_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: cms_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: CMS_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
