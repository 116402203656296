import React from 'react';
import Select from 'react-select';
import { countries } from 'countries-list'; // Import country data

const CountryList = ({ selectedCountry, handleCountryChange }) => {
    const countryOptions = Object.keys(countries).map(countryCode => ({
        value: countryCode,
        label: countries[countryCode].name
    }));

    return (
        <Select
            options={countryOptions}
            value={selectedCountry}
            onChange={handleCountryChange}
            placeholder="Select a country"
            required
        />
    );
};

export default CountryList;
