/* eslint-disable */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from 'views/Spinner';
import { ADDRESS_TYPE, ORDERS_PAYMENT_STATUS, ORDER_LIST_PAYMENT_METHOD, SHIPPING_CATEGORY_NAME } from 'constants/index';
import Select from 'react-select';
import {
  getUsersList,
  applyCoupon,
  calculateShippingRates,
  getUserAddressList,
  addToCart,
  updateCart,
  getCartDetails,
  addToOrder,
  removeCoupon,
  getProductsList,
  selectedVariations,
} from 'actions/admin/order';
import {
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import CartDetails from './CartDetails';
import ButtonSpinner from 'views/ButtonSpinner';
import { amountDue, productTotal, totalBeforeTax as beforeTax } from './OrderCalculation';
import OrderType from './OrderTypes';
import BuildShopSupplyCart from './BuildShopSupplyCart';
import ServiceCart from './ServiceCart';

const PreviewForm = ({
  variationData,
  selectedVariations,
  productDetail,
  couponDetail,
  userType,
  customerName,
  payment_status,
  deliveryInstruction,
  productTotalAmount,
  shippingPrice,
  grandTotal,
  shippingBox,
  selectedShippingMode,
  easyPostOrderId,

  setShowAddEditPreviewForm,
  showAddEditPreviewForm,

  addToOrder,
  setIsSubmit,
  taxApplied,
  totalBeforeTax,
  taxTotal,
  orderId,
  isEdit,
  paid_amount,
  due_amount,
  finalCustomerCarrierDetails,
  notifyEmails,
  clearEmails
}) => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  //##################### billing address list for logged in user #########################
  const saveOrder = () => {
    if (orderId) {
      orderId = orderId;
    } else {
      orderId = '';
    }
    setIsLoading(true);
    addToOrder({
      orderId,
      userId: variationData.userId,
      currentCart: productDetail.currentCart,
      cartTotal: productDetail.cartTotal,
      couponDiscount: couponDetail.couponDiscount,
      couponShippingDiscount: couponDetail.couponShippingDiscount,
      couponDetails: couponDetail.couponDetails,
      grandTotal,
      selectedBillingAddress: variationData.selectedBillingAddress,
      selectedShippingAddress: variationData.selectedShippingAddress,
      shippToDifferentAddressChecked:
        variationData.shippToDifferentAddressChecked,
      shippingBox,
      selectedShippingMode,
      payment_status,
      deliveryInstruction,
      easy_post_order_id: easyPostOrderId,
      fittingTotalAmount: productDetail.fittingTotalAmount,
      productTotalAmount: productDetail.productTotalAmount,
      taxApplied,
      totalBeforeTax,
      taxTotal,
      paid_amount,
      due_amount,
      finalCustomerCarrierDetails,
      previous_order_status: variationData.previous_order_status ?? 0,
      refundable_amount: variationData?.refundableAmount ?? 0,
      buildShopSupplyProduct: variationData?.buildShopSupplyDetail ?? "",
      serviceProduct: variationData?.serviceDetail ?? "",

      order_category: variationData?.order_category ?? "",
      shippingMergeId: variationData?.shippingMergeId ?? "",
      payment_mode: variationData?.payment_mode ?? "",
      payment_comment: variationData?.payment_comment ?? "",
      shippingServiceCategory: variationData?.shippingServiceCategory ?? "",
      notifyEmails,
      comment: variationData?.comment ?? "",
      updateAddressEmailAndPhone: variationData?.updateAddressEmailAndPhone ?? null,
    }).then((res) => {

      res.status ? navigate('/admin/orders') : ''
      clearEmails();
      setIsLoading(false);

    }
    );
  };
  const paymentStatus = useMemo(() => {
    return ORDERS_PAYMENT_STATUS.find((i) => i.key === payment_status)?.value;
  }, [payment_status])

  const cancelOrder = () => {
    setShowAddEditPreviewForm(true);
  };
  return (
    <div id="previewForm">
      <Form className="form-horizontal">
        <Card>
          <CardHeader>
            <h5>Order Preview </h5>
          </CardHeader>
          <CardBody>
            <Container fluid className="p-0">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col sm="6">
                          <FormGroup className="orderpreviewlabel">
                            <Label for="user_type">
                              <span className="table-label">
                                {' '}
                                Customer Type :
                              </span>{' '}
                            </Label>
                            {userType && userType
                              ? userType == 2
                                ? 'Customer'
                                : 'Dealer'
                              : 'N/A'}
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="orderpreviewlabel">
                            <Label for="user_type">
                              <span className="table-label"> Name :</span>{' '}
                            </Label>
                            {customerName && customerName != '' ? (
                              <> {customerName} </>
                            ) : (
                              'N/A'
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <OrderType Ordertype={variationData.order_category} isDisabled={true} />
              {variationData.userId ? (
                <div>
                  <Card>
                    <CardHeader><h6>{"Products Detail"}</h6></CardHeader>
                    <CardBody>
                      <CartDetails
                        productDetail={productDetail}
                        showAddEditPreviewForm={showAddEditPreviewForm}
                      />
                    </CardBody>
                  </Card>
                  {variationData?.buildShopSupplyDetail.length > 0 && <Card>
                    <CardHeader><h6>{"Build Shop Supply"}</h6></CardHeader>
                    <CardBody>
                      <BuildShopSupplyCart showAction={false} />
                    </CardBody>
                  </Card>}
                  {variationData?.serviceDetail.length > 0 && <Card>
                    <CardHeader><h6>{"Service"}</h6></CardHeader>
                    <CardBody>
                      <ServiceCart showAction={false} />
                    </CardBody>
                  </Card>}
                  {
                    <Card>
                      <CardBody>
                        <Row className="couponcodelist">
                          <div className="col-md-6">
                            <strong>Shipping Address : </strong>
                            {variationData.selectedShippingLabel}
                          </div>
                          <div className="col-md-6">
                            <strong>Billing Address :</strong>{' '}
                            {variationData.shippToDifferentAddressChecked == true
                              ? variationData.selectedBillingLabel
                              : variationData.selectedShippingLabel}
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  }

                  {(variationData.selectedBillingAddress != null ||
                    variationData.selectedShippingAddress != null) &&
                    productDetail?.addedProduct.length ? (
                    <Card>
                      <CardBody>
                        <Row className="couponcodelist">
                          <Col md="4">
                            <FormGroup>
                              <Label for="payment_status">
                                <strong>Payment status : </strong>
                              </Label>
                              {paymentStatus}
                            </FormGroup>
                          </Col>
                          {ORDER_LIST_PAYMENT_METHOD[variationData?.payment_mode] && <Col md="4">
                            <FormGroup>
                              <Label for="payment_status">
                                <strong>Payment Mode : </strong>
                              </Label>
                              {ORDER_LIST_PAYMENT_METHOD[variationData?.payment_mode]}
                            </FormGroup>
                          </Col>}
                          <Col md="4">
                            <FormGroup>
                              <Label for="deliveryInstruction">
                                <strong>Delivery Instructions : </strong>
                              </Label>
                              {deliveryInstruction && deliveryInstruction
                                ? deliveryInstruction
                                : ''}
                            </FormGroup>
                          </Col>
                          {variationData?.payment_comment && <Col md="12">
                            <FormGroup>
                              <Label for="payment_status">
                                <strong>Payment Comment : </strong>
                              </Label>
                              {variationData?.payment_comment}
                            </FormGroup>
                          </Col>}
                        </Row>
                      </CardBody>
                    </Card>
                  ) : (
                    ''
                  )}

                  {selectedShippingMode && (
                    <Card>
                      <CardBody>
                        <Row className="couponcodelist">
                          <Col md="12">
                            <FormGroup>
                              <Label>
                                <strong>Shipping Details </strong>
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md={[3, 4].includes(variationData?.shippingServiceCategory) ? 8 : 3} xl={[3, 4].includes(variationData?.shippingServiceCategory) ? 6 : 3} sm={[3, 4].includes(variationData?.shippingServiceCategory) ? 12 : 3}>
                            <FormGroup>
                              <Label for="total_changes">
                                <strong>Shipping Service: </strong>
                              </Label>
                              {`${SHIPPING_CATEGORY_NAME[variationData?.shippingServiceCategory]} `}{(variationData?.shippingServiceCategory === 3 && `(${variationData?.mergeAddtionalList?.find((i) => i.value === variationData?.shippingMergeId)?.label})`)}
                            </FormGroup>
                          </Col>
                          {![3, 4].includes(variationData?.shippingServiceCategory) && <><Col md="3">
                            <FormGroup>
                              <Label for="carrier">
                                <strong>Carrier: </strong>
                              </Label>
                              {selectedShippingMode.carrier}
                            </FormGroup>
                          </Col>
                            <Col md="3">
                              <FormGroup>
                                <Label for="service">
                                  <strong>Service: </strong>
                                </Label>
                                {selectedShippingMode.service}
                              </FormGroup>
                            </Col></>}
                          <Col md={variationData?.shippingServiceCategory === 3 ? 4 : 3} xl={variationData?.shippingServiceCategory === 3 ? 6 : 3} sm={variationData?.shippingServiceCategory === 3 ? 12 : 3}>
                            <FormGroup>
                              <Label for="total_changes">
                                <strong>Total Charge: </strong>
                              </Label>
                              {Number(shippingPrice).toFixed(2)}
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}
                  {notifyEmails.length > 0 && (
                    <Card>
                      <CardHeader>
                        <Label className="label-tag-header">
                          {' '}
                          Additional emails for order notifications
                        </Label>
                      </CardHeader>
                      <CardBody>
                        {notifyEmails.map((email, indx) => {
                          if (notifyEmails.length - 1 === indx) {
                            return (
                              <p key={email} style={{ display: 'inline' }}>
                                {`${email}`}
                              </p>
                            );
                          }
                          return (
                            <p key={email} style={{ display: 'inline' }}>
                              {`${email}  |  `}
                            </p>
                          );
                        })}
                      </CardBody>
                    </Card>
                  )}
                  {variationData.comment && <Card>
                    <CardBody>
                      <div className="mb-10">
                        <div className="couponcode">
                          <strong>Comment : </strong>
                          {variationData.comment}
                        </div>
                      </div>
                    </CardBody>
                  </Card>}
                  {variationData.userId &&
                    productDetail?.addedProduct.length && couponDetail?.isApplied && (
                      <Card>
                        <CardBody>
                          <div className="mb-10">
                            {couponDetail.isApplied ? (
                              <>
                                <div className="couponcode">
                                  <strong>Coupon Code : </strong>
                                  <strong className="coupon-display">
                                    {isNaN(couponDetail?.couponDetails?.coupon_code) ? "" : "$"}
                                    {couponDetail?.couponDetails?.coupon_code
                                      ? couponDetail?.couponDetails?.coupon_code
                                      : ''}
                                  </strong>
                                </div>
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </CardBody>
                      </Card>
                    )}
                  <div>
                    {productDetail?.addedProduct.length ? (
                      <Card>
                        <CardBody>
                          <div className="mt-20">
                            <Row className="couponcodelist priceSummary">
                              <Col md="12">
                                <FormGroup>
                                  <Label>
                                    <strong>Payment Details </strong>
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                {productDetail?.productTotalAmount > 0 ? (
                                  <div className="couponcode">
                                    <strong>Product(s) Total : </strong>$
                                    {Number(
                                      productDetail.productTotalAmount
                                    ).toFixed(2)}
                                  </div>
                                ) : null}

                                {productDetail.fittingTotalAmount &&
                                  productDetail.fittingTotalAmount > 0 ? (
                                  <div className="couponcode">
                                    <strong>Build Parameter(s) total :</strong>{' '}
                                    $
                                    {Number(
                                      productDetail.fittingTotalAmount
                                    ).toFixed(2)}
                                  </div>
                                ) : null}
                                {(couponDetail.couponDiscount !== 0 && (!couponDetail?.couponDetails?.coupon_code || (isNaN(couponDetail?.couponDetails?.coupon_code) || Number(couponDetail.couponDiscount) <= productTotal(Number(productDetail.fittingTotalAmount ?? 0), Number(productDetail.productTotalAmount ?? 0))))) && (
                                  <div className="couponcode">
                                    <strong>Discount : </strong> - ($
                                    {Number(
                                      couponDetail.couponDiscount
                                    ).toFixed(2)}
                                    )
                                  </div>
                                )}
                                {taxTotal && taxTotal > 0 ? (
                                  <>
                                    <div className="couponcode">
                                      <strong>Total before Tax :</strong> $
                                      {beforeTax(couponDetail?.couponDetails?.coupon_code, Number(productDetail.fittingTotalAmount ?? 0), Number(productDetail.productTotalAmount ?? 0), Number(couponDetail.couponDiscount ?? 0))}
                                    </div>
                                    <div className="couponcode">
                                      <strong>Sales Tax :</strong> $
                                      {Number(taxTotal).toFixed(2)}
                                    </div>
                                  </>
                                ) : null}
                                {variationData?.serviceTotalPrice > 0 && <div className="couponcode">
                                  <strong>Service Price :</strong> $
                                  {Number(variationData?.serviceTotalPrice).toFixed(2)}
                                </div>}
                                {shippingPrice !== 0 ? (
                                  <div className="couponcode">
                                    <strong>Shipping :</strong> $
                                    {Number(shippingPrice).toFixed(2)}
                                  </div>
                                ) : null}
                                {couponDetail.couponShippingDiscount !== 0 ? (
                                  <div className="couponcode">
                                    <strong>Shipping Discount : </strong> - ($
                                    {Number(
                                      couponDetail.couponShippingDiscount
                                    ).toFixed(2)}
                                    )
                                  </div>
                                ) : null}
                                {couponDetail.couponShippingDiscount !== 0 ? (
                                  <div className="couponcode">
                                    <strong>Net Shipping Amount : </strong> $
                                    {(
                                      Number(shippingPrice) -
                                      Number(
                                        couponDetail.couponShippingDiscount
                                      )
                                    ).toFixed(2)}
                                  </div>
                                ) : null}
                                {variationData.isEdit ? (
                                  <>
                                    <div className="couponcode">
                                      <strong>Paid Amount : </strong>$
                                      {Number(paid_amount).toFixed(2)}
                                    </div>

                                    {(variationData.refundableAmount === 0 && amountDue(grandTotal, paid_amount, variationData.refundedAmount) >= 0) && (
                                      <div className="couponcode">
                                        <strong>Due Amount : </strong>$
                                        {amountDue(grandTotal, paid_amount, variationData.refundedAmount)}
                                      </div>
                                    )}
                                    {variationData.refundedAmount > 0 && <div className="couponcode">
                                      <strong>Refuned Amount : </strong> -($
                                      {Number(variationData.refundedAmount).toFixed(2)})
                                    </div>}
                                    {variationData.refundableAmount > 0 && <div className="couponcode">
                                      <strong>Refundable Amount : </strong>$
                                      {variationData.refundableAmount}
                                    </div>
                                    }
                                  </>
                                ) : null}
                                {(couponDetail.couponDiscount !== 0 && (couponDetail?.couponDetails?.coupon_code && (!isNaN(couponDetail?.couponDetails?.coupon_code) && Number(couponDetail.couponDiscount) > productTotal(Number(productDetail.fittingTotalAmount ?? 0), Number(productDetail.productTotalAmount ?? 0))))) && (
                                  <div className="couponcode">
                                    <strong>Discount : </strong> - ($
                                    {Number(
                                      couponDetail.couponDiscount
                                    ).toFixed(2)}
                                    )
                                  </div>
                                )}
                                <div className="couponcode">
                                  <strong>Grand Total : </strong>$
                                  {Number(grandTotal).toFixed(2)}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </Container>
          </CardBody>
        </Card>
        <CardFooter className="modalFooter">
          {isLoading ? <ButtonSpinner /> : <Button
            className="mr-05"
            size="sm"
            color="primary"
            onClick={() => {
              saveOrder();
            }}
          >
            <i className="fa fa-dot-circle-o"></i> Place Order
          </Button>}

          <Button
            type="reset"
            size="sm"
            color="danger"
            className="m-0"
            disabled={isLoading}
            onClick={() => {
              cancelOrder();
            }}
          >
            <i className="fa fa-arrow-left"></i> Back
          </Button>
        </CardFooter>
      </Form>
    </div>
  );
};

PreviewForm.propTypes = {
  //address_loading: PropTypes.bool.isRequired,
  //currentAddressList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  //address_loading: state.order.address_loading,
  //currentAddressList: state.order.currentAddressList,
  variationData: state.order.variationData,
  addToOrder: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { addToOrder, selectedVariations })(
  PreviewForm
);
