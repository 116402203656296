import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { add } from 'actions/admin/GearProduct/gearAttribute';
import Errors from 'views/Notifications/Errors';
import Spinner from 'views/Spinner';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

const AddGearAttribute = ({ add, history, errorList, loading }) => {
  /**************** use state for form data *********************/
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    terms: [],
  });
  /**************** expload form data *********************/
  const { name, terms } = formData;

  /**************** dispatch to remove error *********************/

  /**************** on Change event *********************/
  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.replace(/[^a-z0-9]/gi, '')
        ? e.target.value.trimLeft()
        : e.target.value.trim(),
    });
  };

  /**************** on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    formData['terms'] = tags;
    add(formData, navigate);
  };

  /**************** handle tags  *********************/
  const [tags, setTags] = useState(terms);

  const Tag = ({ onDeleteTag, value }) => {
    var tag = (
      <div className="tag-item">
        <span className="tag-tagStyle" onClick={(e) => onDeleteTag(e, value)}>
          &#x2716;
        </span>
        {value}
      </div>
    );
    return <React.Fragment>{tag}</React.Fragment>;
  };

  const TagList = ({ tags, onDeleteTag }) => {
    if (tags.length) {
      let tagsUI = tags.map((tag) => (
        <Tag onDeleteTag={() => onDeleteTag(tag)} key={tag} value={tag} />
      ));
      return <div className="tag-list">{tagsUI}</div>;
    } else {
      return <div className="tag-list"> </div>;
    }
  };

  /**************** on add tags  *********************/

  const onAddTag = (tag) => {
    if (!tags.includes(tag)) {
      let setTag = tag.replace(/[^a-z0-9]/gi, '') ? tag.trimLeft() : tag.trim();
      setTags([...tags, setTag]);
    } else {
      alert('Tags already exist');
    }
  };

  /**************** on delete tags  *********************/

  const onDeleteTag = (tag) => {
    if (window.confirm(`Are you sure, you want to delete ${tag} tag? `)) {
      let remainingTags = tags.filter((t) => {
        return t !== tag;
      });
      setTags([...remainingTags]);
    }
  };

  /**************** on input tags  *********************/

  const InputTag = (onAddTag, onDeleteTag, defaultTags, placeholder) => {
    const onKeyUp = (e) => {
      if (e.which === 188 || e.which === 13) {
        let input = e.target.value.trim().split(',');
        if (input.length === 0 || input[0] === '') return;
        onAddTag(input[0]);
        e.target.value = '';
      }
    };

    return (
      <div>
        <TagList tags={defaultTags} onDeleteTag={onDeleteTag} />
        <Input
          onKeyUp={(e) => onKeyUp(e)}
          type="textarea"
          placeholder={placeholder}
          invalid={errorList.terms ? true : false}
        />
      </div>
    );
  };
  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="Name">
                          Name <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Enter name"
                          maxLength="50"
                          value={name}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.name ? true : false}
                        />
                        <Errors current_key="name" key="name" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="terms">
                          Terms <span>*</span>
                        </Label>
                        {InputTag(
                          onAddTag,
                          onDeleteTag,
                          tags,
                          'Enter terms and use comma after enter desired terms'
                        )}
                        <Errors current_key="terms" key="terms" />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button
                    type="submit"
                    size="sm"
                    color="primary"
                    disabled={loading}
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Link to="/admin/gear-attributes">
                    <Button type="reset" size="sm" color="danger">
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

AddGearAttribute.propTypes = {
  add: PropTypes.func.isRequired,
  attribute: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  attribute: state.gearAttribute,
  errorList: state.errors,
  loading: state.gearAttribute.loading,
});

export default connect(mapStateToProps, { add })(AddGearAttribute);
