import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';

import { create, edit, getProductById } from 'actions/admin/productSelector';

import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Col,
    Form,
    Input,
    Row,
} from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const AddEditSelectorQuestion = ({
    create,
    history,
    errorList,
    loading,
    getProductById,
    edit,
    news: { currentNews },
}) => {
    let navigate = useNavigate();
    /**************** use state for form data *********************/
    let [formData, setFormData] = useState({
        category: '',
        swing_speed: '',
        launch_angle: '',
        spin: '',
        trajectory: '',
        desired_shaft_weight: '',
        current_weight: '',
        tempo: '',
        attack_angle: '',
    });

    const [ironFormData, setIronFormData] = useState({
        category: '',
        swing_speed: '',
        launch_angle: '',
        spin: '',
        trajectory: '',
        desired_shaft_weight: '',
        current_weight: '',
        tempo: '',
        attack_angle: '',
    });

    const { news_id: news_id } = useParams();
    useEffect(() => {
        if (news_id) getProductById(news_id);
    }, [news_id]);

    useEffect(() => {
        if (Object.keys(currentNews).length > 0 && news_id) {
            for (let item of Object.values(currentNews)) {
                if (item?.category === 'iron') {
                    setIronFormData({ ...item })
                } else {
                    setFormData({ ...item });
                }
            }
        }
    }, [currentNews]);

    const handleChange = (e) => {
        setFormData((data1) => ({
            ...data1,
            [e?.target?.name]: (e?.target?.value.trimLeft())
        }));
    };

    const handleIronChange = (e) => {
        setIronFormData((data1) => ({
            ...data1,
            [e?.target?.name]: (e?.target?.value.trimLeft())
        }));
    };

    const handleCancel = (event) => {
        event.preventDefault();
        const id = event.currentTarget.getAttribute("data-id");
        if (Object.keys(currentNews).length > 0 && news_id) {
            for (let item of Object.values(currentNews)) {
                if (item?.category === 'iron' && id === 'iron') {
                    setIronFormData({ ...item })
                }
                if (item?.category === 'driver' && id === 'driver') {
                    setFormData({ ...item });
                }
            }
        }
    }

    /**************** on submit event *********************/
    const onSubmit = (e) => {
        e.preventDefault();
        let percentage_count = 0;
        for (let data of Object.values(formData)) {
            if (data !== 'driver') {
                percentage_count += Number(data);
            }
        }
        let form = {
            category: Number(formData?.category),
            swing_speed: Number(formData?.swing_speed),
            launch_angle: Number(formData?.launch_angle),
            spin: Number(formData?.spin),
            trajectory: Number(formData?.trajectory),
            desired_shaft_weight: Number(formData?.desired_shaft_weight),
            current_weight: Number(formData?.current_weight),
            tempo: Number(formData?.tempo),
            attack_angle: Number(formData?.attack_angle),
            percentage_count: Number(percentage_count)
        }
        // if (news_id) {
        edit(form, navigate, 'driver');
        // } else {
        //     create(form, navigate);
        // }
    };

    const onSubmitIron = (e) => {
        e.preventDefault();
        let percentage_count = 0;
        for (let data of Object.values(ironFormData)) {
            if (data !== 'iron') {
                percentage_count += Number(data);
            }
        }
        let form = {
            category: Number(ironFormData?.category),
            swing_speed: Number(ironFormData?.swing_speed),
            launch_angle: Number(ironFormData?.launch_angle),
            spin: Number(ironFormData?.spin),
            trajectory: Number(ironFormData?.trajectory),
            desired_shaft_weight: Number(ironFormData?.desired_shaft_weight),
            current_weight: Number(ironFormData?.current_weight),
            tempo: Number(ironFormData?.tempo),
            attack_angle: Number(ironFormData?.attack_angle),
            percentage_count: Number(percentage_count)
        }
        // if (news_id) {
        edit(form, navigate, 'iron');
        // } else {
        //     create(form, navigate);
        // }
    };
    return (
        <div className="animated fadeIn">
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <Row>
                        <Col xs="12" sm="12">
                            <Card>
                                <CardHeader>
                                    <h3 style={{ textTransform: "capitalize" }} > {formData?.category == 'driver' && formData?.category + ', Fairway, Hybrid'}</h3>
                                </CardHeader>
                                <Form
                                    className="form-horizontal"
                                    onSubmit={(e) => onSubmit(e)}
                                    encType="multipart/form-data"
                                >
                                    <CardBody>
                                        <Row>
                                            <Col sm="12">
                                                <div>
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th>SR. </th>
                                                                <th>Questions</th>
                                                                <th>Weightage (%)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <h5>1.</h5>
                                                                </td>
                                                                <td style={{ width: "90%" }}>
                                                                    <h5 htmlFor="swing_speed">
                                                                        Average Swing Speed?
                                                                    </h5>
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        type="number"
                                                                        id="swing_speed"
                                                                        style={{ width: "200px" }}
                                                                        name="swing_speed"
                                                                        value={formData?.swing_speed}
                                                                        min="0"
                                                                        step={0.01}
                                                                        max={100}
                                                                        onChange={handleChange}
                                                                        invalid={errorList.swing_speed ? true : false}
                                                                    />
                                                                    <Errors
                                                                        current_key="swing_speed"
                                                                        key="swing_speed"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <h5>2.</h5>
                                                                </td>
                                                                <td style={{ width: "90%" }}>
                                                                    <h5 htmlFor="launch_angle">
                                                                        Desired Launch Angle/Trajectory?
                                                                    </h5>
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        type="number"
                                                                        id="launch_angle"
                                                                        name="launch_angle"
                                                                        value={formData?.launch_angle}
                                                                        min="0"
                                                                        step={0.01}
                                                                        max={100}
                                                                        onChange={handleChange}
                                                                        invalid={errorList.launch_angle ? true : false}
                                                                    />
                                                                    <Errors
                                                                        current_key="launch_angle"
                                                                        key="launch_angle"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <h5>3.</h5>
                                                                </td>
                                                                <td style={{ width: "90%" }}>
                                                                    <h5 htmlFor="spin">
                                                                        Desired Spin?
                                                                    </h5>
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        type="number"
                                                                        id="spin"
                                                                        name="spin"
                                                                        value={formData?.spin}
                                                                        min="0"
                                                                        step={0.01}
                                                                        max={100}
                                                                        onChange={handleChange}
                                                                        invalid={errorList.spin ? true : false}
                                                                    />
                                                                    <Errors
                                                                        current_key="spin"
                                                                        key="spin"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            {/* <tr>
                                                                <td>
                                                                    <h5>4.</h5>
                                                                </td>
                                                                <td style={{ width: "90%" }}>
                                                                    <h5 htmlFor="trajectory">
                                                                        Desired Trajectory?
                                                                    </h5>
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        type="number"
                                                                        id="trajectory"
                                                                        name="trajectory"
                                                                        value={formData?.trajectory}
                                                                        min="0"
                                                                        step={0.01}
                                                                        max={100}
                                                                        onChange={handleChange}
                                                                        invalid={errorList.trajectory ? true : false}
                                                                    />
                                                                    <Errors
                                                                        current_key="trajectory"
                                                                        key="trajectory"
                                                                    />
                                                                </td>
                                                            </tr> */}
                                                            <tr>
                                                                <td>
                                                                    <h5>4.</h5>
                                                                </td>
                                                                <td style={{ width: "90%" }}>
                                                                    <h5 htmlFor="desired_shaft_weight">
                                                                        Desired Shaft Weight?
                                                                    </h5>
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        type="number"
                                                                        id="desired_shaft_weight"
                                                                        name="desired_shaft_weight"
                                                                        value={formData?.desired_shaft_weight}
                                                                        min="0"
                                                                        step={0.01}
                                                                        max={100}
                                                                        onChange={handleChange}
                                                                        invalid={errorList.desired_shaft_weight ? true : false}
                                                                    />
                                                                    <Errors
                                                                        current_key="desired_shaft_weight"
                                                                        key="desired_shaft_weight"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <h5>5.</h5>
                                                                </td>
                                                                <td style={{ width: "90%" }}>
                                                                    <h5 htmlFor="current_weight">
                                                                        Current Shaft Weight?
                                                                    </h5>
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        type="number"
                                                                        id="current_weight"
                                                                        name="current_weight"
                                                                        value={formData?.current_weight}
                                                                        min="0"
                                                                        step={0.01}
                                                                        max={100}
                                                                        onChange={handleChange}
                                                                        invalid={errorList.current_weight ? true : false}
                                                                    />
                                                                    <Errors
                                                                        current_key="current_weight"
                                                                        key="current_weight"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <h5>6.</h5>
                                                                </td>
                                                                <td style={{ width: "90%" }}>
                                                                    <h5 htmlFor="tempo">
                                                                        Tempo?
                                                                    </h5>
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        type="number"
                                                                        id="tempo"
                                                                        name="tempo"
                                                                        value={formData?.tempo}
                                                                        min="0"
                                                                        step={0.01}
                                                                        max={100}
                                                                        onChange={handleChange}
                                                                        invalid={errorList.tempo ? true : false}
                                                                    />
                                                                    <Errors
                                                                        current_key="tempo"
                                                                        key="tempo"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <h5>7.</h5>
                                                                </td>
                                                                <td style={{ width: "90%" }}>
                                                                    <h5 htmlFor="attack_angle">
                                                                        Attack Angle?
                                                                    </h5>
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        type="number"
                                                                        id="attack_angle"
                                                                        name="attack_angle"
                                                                        value={formData?.attack_angle}
                                                                        min="0"
                                                                        step={0.01}
                                                                        max={100}
                                                                        onChange={handleChange}
                                                                        invalid={errorList.attack_angle ? true : false}
                                                                    />
                                                                    <Errors
                                                                        current_key="attack_angle"
                                                                        key="attack_angle"
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <Button type="submit" size="sm" color="primary" disabled={loading}>
                                            <i className="fa fa-dot-circle-o"></i> Submit
                                        </Button>
                                        <a onClick={handleCancel} data-id="driver">
                                            <Button type="reset" size="sm" className="btn btn-danger btn-sm">
                                                <i className="fa fa-ban"></i> Cancel
                                            </Button>
                                        </a>
                                    </CardFooter>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="12">
                            <Card>
                                <CardHeader>
                                    <h3 style={{ textTransform: "capitalize" }} > {ironFormData?.category}</h3>
                                </CardHeader>
                                <Form
                                    className="form-horizontal"
                                    onSubmit={(e) => onSubmitIron(e)}
                                    encType="multipart/form-data"
                                >
                                    <CardBody>
                                        <Row>
                                            <Col sm="12">
                                                <div>
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th>SR. </th>
                                                                <th>Questions</th>
                                                                <th>Weightage (%)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <h5>1.</h5>
                                                                </td>
                                                                <td style={{ width: "90%" }}>
                                                                    <h5 htmlFor="swing_speed">
                                                                        Average Driver Swing Speed?
                                                                    </h5>
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        type="number"
                                                                        id="swing_speed"
                                                                        style={{ width: "200px" }}
                                                                        name="swing_speed"
                                                                        value={ironFormData?.swing_speed}
                                                                        min="0"
                                                                        step={0.01}
                                                                        max={100}
                                                                        onChange={handleIronChange}
                                                                        invalid={errorList.swing_speed ? true : false}
                                                                    />
                                                                    <Errors
                                                                        current_key="swing_speed"
                                                                        key="swing_speed"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <h5>2.</h5>
                                                                </td>
                                                                <td style={{ width: "90%" }}>
                                                                    <h5 htmlFor="current_weight">
                                                                        Current Driver Shaft weight?
                                                                    </h5>
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        type="number"
                                                                        id="current_weight"
                                                                        name="current_weight"
                                                                        value={ironFormData?.current_weight}
                                                                        min="0"
                                                                        step={0.01}
                                                                        max={100}
                                                                        onChange={handleIronChange}
                                                                        invalid={errorList.current_weight ? true : false}
                                                                    />
                                                                    <Errors
                                                                        current_key="current_weight"
                                                                        key="current_weight"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <h5>3.</h5>
                                                                </td>
                                                                <td style={{ width: "90%" }}>
                                                                    <h5 htmlFor="desired_shaft_weight">
                                                                        Current Iron Shaft weight?
                                                                    </h5>
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        type="number"
                                                                        id="desired_shaft_weight"
                                                                        name="desired_shaft_weight"
                                                                        value={ironFormData?.desired_shaft_weight}
                                                                        min="0"
                                                                        step={0.01}
                                                                        max={100}
                                                                        onChange={handleIronChange}
                                                                        invalid={errorList.desired_shaft_weight ? true : false}
                                                                    />
                                                                    <Errors
                                                                        current_key="desired_shaft_weight"
                                                                        key="desired_shaft_weight"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <h5>4.</h5>
                                                                </td>
                                                                <td style={{ width: "90%" }}>
                                                                    <h5 htmlFor="attack_angle">
                                                                        Iron Attack Angle?
                                                                    </h5>
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        type="number"
                                                                        id="attack_angle"
                                                                        name="attack_angle"
                                                                        value={ironFormData?.attack_angle}
                                                                        min="0"
                                                                        step={0.01}
                                                                        max={100}
                                                                        onChange={handleIronChange}
                                                                        invalid={errorList.attack_angle ? true : false}
                                                                    />
                                                                    <Errors
                                                                        current_key="attack_angle"
                                                                        key="attack_angle"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <h5>5.</h5>
                                                                </td>
                                                                <td style={{ width: "90%" }}>
                                                                    <h5 htmlFor="trajectory">
                                                                        Preferred Ball Flight?
                                                                    </h5>
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        type="number"
                                                                        id="trajectory"
                                                                        name="trajectory"
                                                                        value={ironFormData?.trajectory}
                                                                        min="0"
                                                                        step={0.01}
                                                                        max={100}
                                                                        onChange={handleIronChange}
                                                                        invalid={errorList.trajectory ? true : false}
                                                                    />
                                                                    <Errors
                                                                        current_key="trajectory"
                                                                        key="trajectory"
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <Button type="submit" size="sm" color="primary" disabled={loading}>
                                            <i className="fa fa-dot-circle-o"></i> Submit
                                        </Button>
                                        <a onClick={handleCancel} data-id="iron">
                                            <Button type="reset" size="sm" className="btn btn-danger btn-sm">
                                                <i className="fa fa-ban"></i> Cancel
                                            </Button>
                                        </a>
                                    </CardFooter>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </>
            )}
        </div >
    );
};

AddEditSelectorQuestion.propTypes = {
    getProductById: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    news: PropTypes.object.isRequired,
    create: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
    news: state.productSelector,
    errorList: state.errors,
    loading: state.productSelector.loading,
});

export default connect(mapStateToProps, {
    create,
    getProductById,
    edit,
})(AddEditSelectorQuestion);
