import {
    CARRIER_SETTINGS_LOADING,
    CARRIER_SETTING_UPDATED,
    CARRIER_SETTING_UPDATE_ERROR,
    CARRIER_SETTING_FAIL,
    GET_CARRIER_SETTING,
    GET_CARRIER_SETTING_ERROR,
} from "actions/types";

const initalState = {
    currentSetting: {},
    loading: true,
    isSubLoading: false,
};

export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
        case CARRIER_SETTINGS_LOADING:
            return {
                ...state,
                loading: payload.is_loading ? payload.is_loading : false,
                isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
            };
        case CARRIER_SETTING_UPDATED:
        case GET_CARRIER_SETTING:
            return {
                ...state,
                currentSetting: payload.data,
                loading: false,
            };
        case CARRIER_SETTING_UPDATE_ERROR:
        case CARRIER_SETTING_FAIL:
        case GET_CARRIER_SETTING_ERROR:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}
