import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import slugify from 'react-slugify';

import { create } from 'actions/admin/subCategory';

import Errors from 'views/Notifications/Errors';

import Spinner from 'views/Spinner';

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  ModalFooter,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { REMOVE_ERRORS } from 'actions/types';

const CreateSubCategory = ({
  create,
  errorList,
  toggle,
  category_id,
  isOpen,
  modal_loading,
}) => {
  //########################## use dispatch ##############################
  

  //########################## set the initial form data ##############################
  const initialState = {
    name: '',
    slug: '',
    // category_id: match.params.category_id,
    category_id: category_id,
    childrens: [{ name: '', slug: '' }],
    thumbnail: '',
    image: '',
    selectedFile: '',
  };
  const [formData, setFormData] = useState(initialState);

  //########################## set the submit state ##############################
  const [isSubmit, setIsSubmit] = useState(false);

  //########################## reset for data when modal is closed ##############################
  const resetModal = () => {
    
    //reset the submit state
    setIsSubmit(false);
    //reset the form data to initial state
    setFormData(initialState);
  };

  //########################## explod form data vaiables ##############################
  const {
    name,
    slug,
    thumbnail,
    // image
  } = formData;

  //########################## change event for all inputs ##############################
  const onChange = (e) => {
    if (e.target.name === 'name') {
      let new_slug = slugify(e.target.value);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        slug: new_slug,
      });
    } else if (e.target.name === 'image') {
      let image_file = e.target.files[0];
      let image_url = URL.createObjectURL(image_file);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        thumbnail: image_url,
        selectedFile: image_file,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    
  };

  //########################## submit form data ##############################

  const onSubmit = (e) => {
    setIsSubmit(true);
    create(formData).then((res) => {
      if (res === true) {
        toggle();
      } else {
        setIsSubmit(false);
      }
    });
  };
  return (
    <div className="animated fadeIn">
      <Modal isOpen={isOpen} toggle={toggle} onClosed={resetModal}>
        <ModalHeader toggle={toggle}>Add New Category</ModalHeader>

        <Form className="form-horizontal">
          <ModalBody>
            <Row>
              <Col xs="12" sm="12">
                <FormGroup hidden={true}>
                  <Label htmlFor="name">
                    Slug <span>*</span>
                  </Label>
                  <Input
                    type="text"
                    id="slug"
                    name="slug"
                    maxLength="100"
                    value={slug}
                    required
                    readOnly
                    invalid={errorList.slug ? true : false}
                  />
                  <Input
                    type="hidden"
                    id="category_id"
                    name="category_id"
                    maxLength="100"
                    value={category_id}
                    required
                  />
                  <Errors current_key="slug" key="slug" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="name">
                    Name <span>*</span>
                  </Label>
                  <InputGroup className="mb-3">
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      maxLength="100"
                      value={name}
                      required
                      onChange={(e) => onChange(e)}
                      invalid={errorList.name ? true : false}
                    />
                    
                  </InputGroup>
                  <Errors current_key="name" key="name" />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="image">Image </Label>
                  <Input
                    type="file"
                    id="image"
                    name="image"
                    //   value={image}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.image ? true : false}
                  />
                  {thumbnail ? (
                    <img src={thumbnail} className="preview-img" alt="" />
                  ) : null}
                  <Errors current_key="image" key="image" />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="modalFooter">
              {modal_loading ? (
                <Spinner />
              ) : (
                <Button
                  className="mr-05"
                  type="submit"
                  size="sm"
                  color="primary"
                  onClick={(e) => onSubmit(e)}
                  disabled={isSubmit}
                >
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
              )}
              <Button type="reset" size="sm" color="danger" onClick={toggle}>
                <i className="fa fa-ban"></i> Cancel
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

CreateSubCategory.defaultProps = {
  isOpen: false,
  category_id: null,
};

CreateSubCategory.propTypes = {
  create: PropTypes.func.isRequired,
  modal_loading: PropTypes.bool.isRequired,
  errorList: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  category_id: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  modal_loading: state.subcategory.modal_loading,
});
export default connect(mapStateToProps, { create })(CreateSubCategory);
