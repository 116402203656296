import {
    RESOURCE_LOADING,
    RESOURCE_CREATED,
    RESOURCE_CREATE_ERROR,
    RESOURCE_FAIL,
    RESOURCE_UPDATED,
    RESOURCE_UPDATE_ERROR,
    RESOURCE_DELETED,
    RESOURCE_DELETE_ERROR,
    RESOURCE_LIST_UPDATED,
    GET_RESOURCE_BY_ID,
    RESOURCE_CHANGE_STATUS,
    RESOURCE_CHANGE_STATUS_ERROR,
} from 'actions/types';

const initalState = {
    resourceList: {
        page: 1,
        data: [],
        count: 0,
    },
    currentResource: {},
    loading: false,
    isSubLoading: false,
};

export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
        case RESOURCE_LOADING:
            return {
                ...state,
                resourceList: payload.is_sub_element ? state.resourceList : initalState.resourceList,
                currentResource: payload.is_sub_element
                    ? state.currentResource
                    : initalState.currentResource,
                loading: payload.is_loading ? payload.is_loading : false,
                isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
            };
        case RESOURCE_CREATED:
            return {
                ...state,
                loading: false,
            };
        case RESOURCE_CREATE_ERROR:
            return {
                ...state,
                loading: false,
            };
        case RESOURCE_FAIL:
            return {
                ...state,
                loading: false,
                isSubLoading: false
            };
        case RESOURCE_UPDATED:
            return {
                ...state,
                currentResource: {},
                loading: false,
            };
        case RESOURCE_UPDATE_ERROR:
            return {
                ...state,
                loading: false,
            };
        case RESOURCE_DELETED:
            return {
                ...state,
                loading: false,
            };
        case RESOURCE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
            };
        case RESOURCE_LIST_UPDATED:
            return {
                ...state,
                resourceList: {
                    data: payload.data.resourceList.data,
                    page: payload.data.resourceList.metadata[0].current_page,
                    count: payload.data.resourceList.metadata[0].totalRecord,
                },
                loading: false,
            };
        case GET_RESOURCE_BY_ID:
            return {
                ...state,
                currentResource: payload.data,
                loading: false,
            };
        case RESOURCE_CHANGE_STATUS:
            return {
                ...state,
                resourceList: {
                    ...state.resourceList,
                    data: state.resourceList.data.map((news) =>
                        news._id === payload.data._id
                            ? { ...news, status: payload.data.status }
                            : news
                    ),
                },
                loading: false,
                isSubLoading: false
            };
        case RESOURCE_CHANGE_STATUS_ERROR:
            return {
                ...state,
                resourceList: {
                    ...state.resourceList,
                    data: state.resourceList.data.map((news) =>
                        news._id === payload.data
                            ? { ...news, is_failed: true }
                            : { ...news, is_failed: false }
                    ),
                },
                loading: false,
                isSubLoading: false
            };

        default:
            return state;
    }
}
