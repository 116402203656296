import axios from 'axios';

import {
  GEAR_PRODUCT_LOADING,
  GEAR_PRODUCT_CREATED,
  ADDED_DEFAULT_GEAR_VARIATION,
  GEAR_PRODUCT_CREATE_ERROR,
  GEAR_PRODUCT_FAIL,
  SET_GEAR_CATEGORY_LOADER,
  CATEGORY_LIST_GEAR_PRODUCT,
  SET_GEAR_ATTRIBUTE_LOADER,
  SET_GEAR_SPECS_LOADER,
  ATTRIBUTES_LIST_GEAR_PRODUCT,
  SPECS_LIST_GEAR_PRODUCT,
  GEAR_PRODUCT_UPDATED,
  GEAR_PRODUCT_UPDATE_ERROR,
  GEAR_PRODUCT_LIST_UPDATED,
  GET_GEAR_PRODUCT_BY_ID,
  CHANGE_GEAR_PRODUCT_STATUS,
  CHANGE_GEAR_PRODUCT_STATUS_ERROR,
  DELETE_GEAR_PRODUCT,
  DELETE_GEAR_PRODUCT_ERROR,
  GET_GEAR_PRODUCT_IMAGES,
  DELETE_GEAR_PRODUCT_IMAGE,
  GEAR_PRIMARY_IMAGE_UPDATED,
  GEAR_WHOLESALE_PRICE_ERROR,
  GEAR_SALE_PRICE_ERROR,
  UPDATE_GEAR_PRODUCT_ATTRIBUTES,
  UPDATE_GEAR_PRODUCT_ATTRIBUTES_OPTIONS,
  UPDATE_GEAR_PRODUCT_ATTRIBUTES_ERROR,
  GEAR_VARIATION_UPDATED,
  GEAR_VARIATION_UPDATED_ERROR,
  RESET_CURRENT_GEAR_PRODUCT,
  CHANGE_GEAR_PRODUCT_STATUS_BULK,
  GEAR_INVENTORY_AND_SHIPPING_UPDATED,
  GEAR_INVENTORY_AND_SHIPPING_UPDATED_ERROR,
  UPDATE_GEAR_PRODUCT_IMAGES,
  UPDATE_GEAR_PRODUCT_IMAGES_ERROR,
  GEAR_VARIATION_IMAGE_ERROR,
  GEAR_PRODUCT_ATTRIBUTE_TAG_DELETE,
  GEAR_PRODUCT_ATTRIBUTE_TAG_DELETE_ERROR,
  GEAR_PRODUCT_ATTRIBUTE_OPTION_CREATED,
  GEAR_PRODUCT_ATTRIBUTE_OPTION_CREATE_ERROR,
  SET_GEAR_PRODUCT_ATTRIBUTE_OPTION_LOADER,
  GEAR_SPEC_ADD,
  SET_ALERT,
  GEAR_REVIEW_FAIL,
  GEAR_REVIEW_LOADING,
  GEAR_REVIEW_UPDATED,
  GEAR_REVIEW_CHANGE_STATUS,
  GEAR_REVIEW_CHANGE_STATUS_ERROR,
  SET_ERRORS_LIST,
  INVENTORY_UPLOADED,
  INVENTORY_UPLOADED_ERROR,
  INVENTORY_LOADING,
} from 'actions/types';

import { updateFilterData } from 'actions/commonActions';

// Create Product
export const create = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: GEAR_PRODUCT_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      '/api/admin/gear-product/create',
      formData,
      config
    );

    if (res.data.status === true) {
      dispatch({
        type: GEAR_PRODUCT_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return { status: true, id: res.data.response._id };
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: GEAR_PRODUCT_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return { status: false, id: null };
    }
  } catch (err) {
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return { status: false, id: null };
  }
};

export const getCategories = () => async (dispatch) => {
  try {
    await dispatch({
      type: SET_GEAR_CATEGORY_LOADER,
      payload: true,
    });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.get('/api/admin/gear-category/list/all', config);
    await dispatch({
      type: CATEGORY_LIST_GEAR_PRODUCT,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

export const getAttributes = () => async (dispatch) => {
  try {
    await dispatch({
      type: SET_GEAR_ATTRIBUTE_LOADER,
      payload: true,
    });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.get('/api/admin/gear-attribute/list/all', config);

    await dispatch({
      type: ATTRIBUTES_LIST_GEAR_PRODUCT,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

export const getFittingSpecsList = () => async (dispatch) => {
  try {
    await dispatch({
      type: SET_GEAR_SPECS_LOADER,
      payload: true,
    });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.get('/api/admin/specs/list/all', config);

    await dispatch({
      type: SPECS_LIST_GEAR_PRODUCT,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit Product
export const edit = (formData, product_id) => async (dispatch) => {
  try {
    dispatch({
      type: GEAR_PRODUCT_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/gear-product/${product_id}`,
      formData,
      config
    );

    if (res.data.status === true) {
      dispatch({
        type: GEAR_PRODUCT_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });

      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: GEAR_PRODUCT_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

// Edit Gear Product Seo
export const editGearProductSeo =
  (formData, product_id, history) => async (dispatch) => {
    try {
      dispatch({
        type: GEAR_PRODUCT_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const res = await axios.post(
        `/api/admin/product/seo/${product_id}`,
        {
          formData,
        },
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: GEAR_PRODUCT_UPDATED,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response.attributes,
          },
        });
        history('/admin/gear-products');
        return true;
      } else {
        const errors = res.data.errors;
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
            dispatch({
              type: GEAR_PRODUCT_UPDATE_ERROR,
              payload: {
                message: error.msg,
                alertType: 'danger',
              },
            });
          });
        }
        return false;
      }
    } catch (err) {
      dispatch({
        type: GEAR_PRODUCT_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
      return false;
    }
  };

// update stackeable Product
export const editStackableProduct =
  (formData, product_id) => async (dispatch) => {
    try {
      dispatch({
        type: GEAR_PRODUCT_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/gear-product/stackable/${product_id}`,
        formData,
        config
      );

      if (res.data.status === true) {
        dispatch({
          type: GEAR_PRODUCT_UPDATED,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });

        return true;
      } else {
        const errors = res.data.errors;
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }
        dispatch({
          type: GEAR_PRODUCT_UPDATE_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
            errorsList,
          },
        });
        return false;
      }
    } catch (err) {
      dispatch({
        type: GEAR_PRODUCT_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
      return false;
    }
  };

export const getInventoriesToDownload = (productParams) => async (dispatch) => {
  try {
    productParams = updateFilterData(productParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: productParams,
    };
    const res = await axios.get(
      `/api/admin/gear-product/inventory/download`,
      config
    );
    return res.data.response;
  } catch (err) {
    return {};
  }
};

export const uploadInventoryForGear = (csvData) => async (dispatch) => {
  try {
    dispatch({
      type: INVENTORY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/product/inventory/upload`, csvData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: INVENTORY_UPLOADED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });

      return res.data;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: INVENTORY_UPLOADED_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

export const jumpToVariation = (sku, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/gear-product/find-variation/${sku}`,
      config
    );
    if (res.data.status) {
      const data = res.data.response[0];
      history(
        `/admin/gear-products/${data.category}/${data.product}/edit/${data.productId}?variation=${sku}`
      );
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: GEAR_PRODUCT_FAIL,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return false;
    }
  } catch (err) {
    return false;
  }
};

//Get Product List
export const getProductsList = (productParams) => async (dispatch) => {
  try {
    dispatch({
      type: GEAR_PRODUCT_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    productParams = updateFilterData(productParams);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: productParams,
    };

    const res = await axios.get(`/api/admin/gear-product/`, config);

    dispatch({
      type: GEAR_PRODUCT_LIST_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: {
          productParams: productParams,
          productList: res.data.response[0],
        },
      },
    });
    return res;
  } catch (err) {
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Get Product by id
export const getProductById = (product_id) => async (dispatch) => {
  try {
    dispatch({
      type: GEAR_PRODUCT_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/gear-product/${product_id}`,
      config
    );

    await dispatch({
      type: GET_GEAR_PRODUCT_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//change status
export const changeStatus = (product_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: GEAR_PRODUCT_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/gear-product/change-status/${product_id}`,
      { status },
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: CHANGE_GEAR_PRODUCT_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: CHANGE_GEAR_PRODUCT_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: product_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Remove Product from cart on product delete
export const removeProductFromCart = (product_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(
      `/api/admin/gear-product/remove-cart/${product_id}`,
      config
    );

    if (res.data.status === true) {
      dispatch({
        type: DELETE_GEAR_PRODUCT,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: product_id,
        },
      });
      return true;
    } else {
      dispatch({
        type: DELETE_GEAR_PRODUCT_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

// Delete Product Page
export const deleteProduct = ({ product_id, remove = false }) => async (dispatch) => {
  try {
    dispatch({
      type: GEAR_PRODUCT_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      data: { remove: remove }
    };
    const res = await axios.delete(
      `/api/admin/gear-product/${product_id}`,
      config
    );

    if (res.data.status === true) {
      dispatch({
        type: DELETE_GEAR_PRODUCT,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: product_id,
        },
      });
      return true;
    } else {
      dispatch({
        type: DELETE_GEAR_PRODUCT_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

// Get Product Images
export const getProductImages = (product_id) => async (dispatch) => {
  try {
    dispatch({
      type: GEAR_PRODUCT_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: { 'Content-Type': 'application/json' },
    };
    const res = await axios.get(
      `/api/admin/gear-product/product-images/${product_id}`,
      config
    );

    await dispatch({
      type: GET_GEAR_PRODUCT_IMAGES,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
    return res.data.response;
  } catch (err) {
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete Product Image
export const deleteProductImage =
  (product_id, image_id, history) => async (dispatch) => {
    try {
      dispatch({
        type: GEAR_PRODUCT_LOADING,
        payload: { is_loading: true, is_sub_element: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.delete(
        `/api/admin/gear-product/image/${image_id}`,
        config
      );
      dispatch({
        type: DELETE_GEAR_PRODUCT_IMAGE,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: image_id,
        },
      });
      return true;
    } catch (err) {
      dispatch({
        type: GEAR_PRODUCT_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

// Delete Product Image
export const primaryImage =
  (product_id, image_id, history) => async (dispatch) => {
    try {
      dispatch({
        type: GEAR_PRODUCT_LOADING,
        payload: { is_loading: true, is_sub_element: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.get(
        `/api/admin/gear-product/primary-image/${product_id}/${image_id}`,
        config
      );
      dispatch({
        type: GEAR_PRIMARY_IMAGE_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } catch (err) {
      dispatch({
        type: GEAR_PRODUCT_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

export const setWholesaleError = (msg, param) => async (dispatch) => {
  let errorsList = [];
  errorsList[param] = msg;
  dispatch({
    type: GEAR_WHOLESALE_PRICE_ERROR,
    payload: {
      message: 'Errors! Please correct the following errors and submit again.',
      alertType: 'danger',
      errorsList,
    },
  });
};

export const setSaleError = (msg, param) => async (dispatch) => {
  let errorsList = [];
  errorsList[param] = msg;
  dispatch({
    type: GEAR_SALE_PRICE_ERROR,
    payload: {
      message: 'Errors! Please correct the following errors and submit again.',
      alertType: 'danger',
      errorsList,
    },
  });
};

// Edit Product
export const editProduct =
  (product_id, productAttributeDetail, cate_slug = null) =>
    async (dispatch) => {
      try {
        dispatch({
          type: GEAR_PRODUCT_LOADING,
          payload: { is_loading: true, is_sub_element: false },
        });
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };

        const res = await axios.post(
          `/api/admin/gear-product/attribute/${product_id}`,
          {
            productAttributeDetail,
            cate_slug,
          },
          config
        );

        if (res.data.status === true) {
          dispatch({
            type: UPDATE_GEAR_PRODUCT_ATTRIBUTES_OPTIONS,
            payload: {
              message: res.data.message,
              alertType: 'success',
              data: res.data.response.attributes,
            },
          });
          return true;
        } else {
          const errors = res.data.errors;
          let errorsList = [];
          if (errors) {
            await errors.forEach((error) => {
              errorsList[error.param] = error.msg;
              dispatch({
                type: UPDATE_GEAR_PRODUCT_ATTRIBUTES_ERROR,
                payload: {
                  message: error.msg,
                  alertType: 'danger',
                },
              });
            });
          }

          return false;
        }
      } catch (err) {
        dispatch({
          type: GEAR_PRODUCT_FAIL,
          payload: {
            message:
              err.response && err.response.statusText
                ? err.response.statusText
                : 'Something went wrong.',
            alertType: 'danger',
            error: err,
          },
        });
        return false;
      }
    };

// Edit Product
export const saveVariation = (product_id, allVariation) => async (dispatch) => {
  try {
    dispatch({
      type: GEAR_PRODUCT_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/gear-product/variations/${product_id}`,
      allVariation,
      config
    );

    if (res.data.status === true) {
      dispatch({
        type: GEAR_VARIATION_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = {};
      if (errors) {
        await errors.forEach((error) => {
          errorsList = { ...errorsList, [error.param]: error.msg };
        });
      }
      dispatch({
        type: GEAR_VARIATION_UPDATED_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      dispatch({
        type: SET_ERRORS_LIST,
        payload: { ...errorsList },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.data?.message
            ? err.response.data?.message
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

// Get All Variations
export const getAllVariations = (product_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/gear-product/variations/${product_id}`,
      config
    );
    if (res.data.status === true) {
      return { status: true, variations: res.data.response };
    }
  } catch (err) {
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

// Make product as default
export const markDefault = (variation, product_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/gear-product/mark-default/${product_id}`,
      variation,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: ADDED_DEFAULT_GEAR_VARIATION,
        payload: {
          message: res.data.message,
          alertType: 'success',
        },
      });
      return { status: true, id: res.data.response };
    }
  } catch (err) {
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

// Remove Attribute Image
export const removeImageForAttribute =
  (productId, id, imageName) => async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/gear-product/removeImage/attribute/${productId}/${id}`,
        {
          image_name: imageName,
        },
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: UPDATE_GEAR_PRODUCT_ATTRIBUTES,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
        return { status: true, id: res.data.response };
      }
    } catch (err) {
      dispatch({
        type: GEAR_VARIATION_IMAGE_ERROR,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
      return false;
    }
  };

// Set Image for Attribute
export const setImageForAttribute =
  (attribute, product_id, imageName) => async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/gear-product/addImage/attribute/${product_id}/${imageName}`,
        { color_id: attribute },
        config
      );

      if (res.data.status === true) {
        dispatch({
          type: UPDATE_GEAR_PRODUCT_ATTRIBUTES,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
        return { status: true, id: res.data.response };
      }
    } catch (err) {
      dispatch({
        type: GEAR_VARIATION_IMAGE_ERROR,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
      return false;
    }
  };

export const removeVariation = (sku, product_id) => async (dispatch) => {
  try {
    dispatch({
      type: GEAR_PRODUCT_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/gear-product/variations/remove/${sku}/${product_id}`,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: GEAR_VARIATION_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: GEAR_VARIATION_UPDATED_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

export const resetCurrentProduct = () => async (dispatch) => {
  await dispatch({ type: RESET_CURRENT_GEAR_PRODUCT });
};

//change status of product in Bulk
export const changeStatusInBulk = (statusFormData) => async (dispatch) => {
  try {
    dispatch({
      type: GEAR_PRODUCT_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/gear-product/bulk-change-status`,
      { ...statusFormData },
      config
    );

    await dispatch({
      type: CHANGE_GEAR_PRODUCT_STATUS_BULK,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// add inventory and shipping
export const addInventoryAndShipping1 =
  (formData, product_id) => async (dispatch) => {
    try {
      dispatch({
        type: GEAR_PRODUCT_LOADING,
        payload: { is_loading: true, is_sub_element: true },
      });
      const config = {
        headers: {
          'content-type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/gear-product/inventory-and-shipping/${product_id}`,
        formData,
        config
      );

      if (res.data.status === true) {
        dispatch({
          type: GEAR_INVENTORY_AND_SHIPPING_UPDATED,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response[0].data,
          },
        });
        return true;
      } else {
        const errors = res.data.errors;
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }
        dispatch({
          type: GEAR_INVENTORY_AND_SHIPPING_UPDATED_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
            errorsList,
          },
        });
        return false;
      }
    } catch (err) {
      dispatch({
        type: GEAR_PRODUCT_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
      return false;
    }
  };

// upload images
export const uploadImages =
  (formData, product_id, videoUrl, bucketVideoUrl, deleteVideoFile) => async (dispatch) => {
    dispatch({
      type: GEAR_PRODUCT_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    let acceptedFiles = formData.images_arr;
    acceptedFiles = [...acceptedFiles, videoUrl];
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data;',
        },
      };

      const param = new FormData();

      param.append('bucketVideoUrl', bucketVideoUrl);
      param.append('deleteVideoFile', deleteVideoFile);

      for (let i = 0; i < acceptedFiles.length; i += 1) {
        param.append('file', acceptedFiles[i]);
      }

      const res = await axios.post(
        `/api/admin/gear-product/upload-images/${product_id}`,
        param,
        config
      );

      if (res.data.status === true) {
        dispatch({
          type: UPDATE_GEAR_PRODUCT_IMAGES,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
        return true;
      } else {
        const errors = res.data.errors;
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }
        dispatch({
          type: UPDATE_GEAR_PRODUCT_IMAGES_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
            errorsList,
          },
        });
        return false;
      }
    } catch (err) {
      dispatch({
        type: GEAR_PRODUCT_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
      return false;
    }
    // }
  };

// Delete AttributeTag from product-attribute
export const deleteTag = (tag_id, product_id) => async (dispatch) => {
  try {
    dispatch({
      type: SET_GEAR_PRODUCT_ATTRIBUTE_OPTION_LOADER,
      payload: { is_loading: false, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.delete(
      `/api/admin/gear-product/attribute/delete/${product_id}/${tag_id}`,
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: GEAR_PRODUCT_ATTRIBUTE_TAG_DELETE,
        payload: {
          message: res.data.message,
          alertType: 'success',
          // tag_id: tag_id,
          // attribute_id: attribute_id
        },
      });
      return true;
    } else {
      dispatch({
        type: GEAR_PRODUCT_ATTRIBUTE_TAG_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

// Create attribute
export const addOption = (attribute_id, option) => async (dispatch) => {
  try {
    dispatch({
      type: SET_GEAR_PRODUCT_ATTRIBUTE_OPTION_LOADER,
      payload: { is_loading: false, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/gear-product/attribute/add-option/${attribute_id}`,
      option,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: GEAR_PRODUCT_ATTRIBUTE_OPTION_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: { option: res.data.response, attribute_id: attribute_id },
        },
      });
      return { status: true, data: res.data.response };
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });

        dispatch({
          type: GEAR_PRODUCT_ATTRIBUTE_OPTION_CREATE_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
            // errorsList,
          },
        });
        return { status: false };
      }
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return { status: false };
  }
};

export const addNewOption = (formData) => async (dispatch) => {
  //console.log(formData, 'hello dtaaa'); return false;
  try {
    // dispatch({
    //   type: GEAR_PRODUCT_LOADING,
    //   payload: { is_loading: true, is_sub_element: false },
    // });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let attribute_id = formData.spec_id;
    const res = await axios.post(
      `/api/admin/gear-product/attribute/add-new-option/${attribute_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: GEAR_SPEC_ADD,
        payload: {
          message: res.data.message,
          alertType: 'success',
        },
      });
      return { status: true };
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: GEAR_PRODUCT_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      return { status: false, id: null };
    }
  } catch (err) {
    dispatch({
      type: GEAR_PRODUCT_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return { status: false, id: null };
  }
};

export const getAllReviews = () => async (dispatch) => {
  try {
    // await dispatch({
    //     type: SET_REVIEW_LOADER,
    //     payload: true,
    // });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.get(
      '/api/admin/gear-product/reviews/all-reviews',
      config
    );
    //console.log(res.data.response, 'res data'); return false;
    await dispatch({
      type: GEAR_REVIEW_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GEAR_REVIEW_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

export const getReviewDetailById = (reviewId) => async (dispatch) => {
  //console.log(reviewId,'review id in action page');return false;

  try {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.get(
      `/api/admin/gear-product/reviews/single-reviews/${reviewId}`,
      config
    );
    await dispatch({
      type: GEAR_REVIEW_UPDATED,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GEAR_REVIEW_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//change status
export const changeReviewStatus = (review_id, status) => async (dispatch) => {
  //console.log(review_id, 'dasdsdasdsadsadsaddsa'); return false;
  try {
    dispatch({
      type: GEAR_REVIEW_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/gear-product/reviews/change-status/${review_id}`,
      { status },
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: SET_ALERT,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: GEAR_REVIEW_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: review_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: GEAR_REVIEW_CHANGE_STATUS_ERROR,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
