import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';

import {
  editStackableProduct,
} from 'actions/admin/GearProduct/gearProduct';
import { getGearProductsOptionList } from 'actions/admin/coupon';

import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';

const GearStackableProduct = ({
  product_id,
  gearCurrentProduct,
  changeTab,
  editStackableProduct,
  getGearProductsOptionList,
  applicableProductsOptionsList,
  loading,
}) => {
  //########################## set the initial form data ##############################
  let navigate = useNavigate();
  const [productList, setProductList] = useState(null);
  const [productBasicDetail, setProductBasicDetail] = useState({
    categoryId: product_id ? gearCurrentProduct?.categoryId : '',
  });
  const { categoryId = '' } = productBasicDetail;

  useEffect(() => {
    if (product_id !== null) {
      getGearProductsOptionList(product_id, 'SHAFTS')
    }
  }, [product_id])

  useEffect(() => {
    setProductBasicDetail({ ...productBasicDetail, categoryId: gearCurrentProduct?.categoryId })
  }, [gearCurrentProduct])
  
  //###################### get the category and sub category list ########################
  const [formData, setFormData] = useState({
    stackableProduct: [],
    selectedProducts: gearCurrentProduct?.stackableProducts,
  });

  const handleApplicableForProducts = (applicableProductsOptions) => {
    let applicableProductsIds = [];

    applicableProductsOptions.map((item, index) => {
      applicableProductsIds.push(item.value);
    });

    setFormData({
      ...formData,
      stackableProducts: applicableProductsIds,
      selectedProducts: applicableProductsOptions,
    });

    setSelectedStackableProduct(applicableProductsOptions);
  };

  let [selectedStackableProduct, setSelectedStackableProduct] = useState([]);

  useEffect(() => {
    if (product_id) {
      let dataArray = [];
      applicableProductsOptionsList.map((item, index) => {
        gearCurrentProduct?.stackableProducts?.map((stackItem, stackIndex) => {
          if (item.value === stackItem) {
            dataArray.push({ value: item.value, label: item.label });
          }
        });
      });
      setSelectedStackableProduct(dataArray);
      setProductList(applicableProductsOptionsList)
    }
  }, [applicableProductsOptionsList]);

  let allProductList = '';
  useEffect(async () => {
    if (gearCurrentProduct) {
      allProductList = await getGearProductsOptionList(gearCurrentProduct?._id);
    }
  }, []);
  //########### handel submit form  ########################

  const onSubmit = (e) => {
    e.preventDefault();
    const mergedData = { ...productBasicDetail, ...formData };
    setProductBasicDetail(mergedData);
    editStackableProduct(mergedData, product_id).then((res) => {
      if (res === true) {
        changeTab('seo_tab');
      }
    });
  };

  return (
    <>
      <Form onSubmit={(e) => onSubmit(e)}>
        <Row>
          <Col xs="12">
            <FormGroup key={2}>
              <Label>Add Stackable Products</Label>
              <Select
                isMulti
                id="stackableProducts"
                name="stackableProducts"
                options={applicableProductsOptionsList?.length > 0 ? applicableProductsOptionsList : []}
                isClearable={true}
                isSearchable={true}
                onChange={(e) => handleApplicableForProducts(e)}
                value={selectedStackableProduct?.length > 0 ? selectedStackableProduct : []}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="Justify-flex-end">
            <FormGroup key={2}>
              <Button
                className="mr-05"
                type="submit"
                size="sm"
                color="primary"
                disabled={loading}
              >
                <i className="fa fa-dot-circle-o"></i> Save & Next
              </Button>
              <Link to="/admin/gear-products">
                <Button type="reset" size="sm" color="danger">
                  <i className="fa fa-ban"></i> Cancel
                </Button>
              </Link>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </>
  );
};

GearStackableProduct.defaultProps = {
  product_id: null,
};

GearStackableProduct.propTypes = {
  category_loading: PropTypes.bool.isRequired,
  gearCurrentProduct: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  category_loading: state.gearProduct.category_loading,
  gearCurrentProduct: state.gearProduct.gearCurrentProduct,
  errorList: state.errors,
  applicableProductsOptionsList: state.coupon.applicableProductsOptionsList,
  loading: state.gearProduct.loading,
});

export default connect(mapStateToProps, {
  editStackableProduct,
  getGearProductsOptionList,
})(GearStackableProduct);
