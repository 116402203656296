import {
  SOCIAL_SETTINGS_LOADING,
  SOCIAL_SETTING_UPDATED,
  SOCIAL_SETTING_UPDATE_ERROR,
  SOCIAL_SETTINGS_FAIL,
  GET_SOCIAL_SETTING,
  GET_SOCIAL_SETTING_ERROR,
  SOCIAL_SETTING_CHECKBOX_ERROR,
} from "actions/types";

const initalState = {
  currentSetting: {},
  loading: true,
  isSubLoading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case SOCIAL_SETTINGS_LOADING:
      return {
        ...state,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case SOCIAL_SETTING_UPDATED:
      return {
        ...state,
        currentSetting: payload.data,
        loading: false,
      };
    case SOCIAL_SETTING_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SOCIAL_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SOCIAL_SETTING:
      return {
        ...state,
        currentSetting: payload.data,
        loading: false,
      };
    case GET_SOCIAL_SETTING_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SOCIAL_SETTING_CHECKBOX_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
