/* eslint-disable */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getShippingBoxList,
  deleteShippingBox,
  changeStatus,
  markNationalInternational,
} from 'actions/admin/shippingBox';
import * as Constants from 'constants/index';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from '../../../utils/Loading';
import { Button, Card, CardBody, Col, Row, Input } from 'reactstrap';
import AddShippingBox from 'views/Admin/ShippingBox/AddShippingBox';
import EditShippingBox from 'views/Admin/ShippingBox/EditShippingBox';
import {
  SHIPPING_BOX_SIZE_UNIT,
  SHIPPING_BOX_WEIGHT_UNIT,
} from 'constants/index';
import PerPageSelect from '../PerPageSelect';

const renderName = (params) => {
  if (params.data === undefined) {
    // when no node id, display the spinner image
    return <Loading />;
  } else {
    // otherwise just display node ID (or whatever you wish for this column)
    return <div>{`${params.data?.name} ${params.data?.is_customised ? "(custom)" : ""}`}</div>;
  }
};

const renderStatus = (params, changeShippingBoxStatus) => {
  return (
    <div>
      <Input
        type="select"
        name="status"
        id={params.data?._id}
        value={params.data?.status}
        onChange={async (e, a) => {
          e.preventDefault();
          changeShippingBoxStatus(params.data?._id, e.target.value);
        }}
      >
        <option value="0">Inactive</option>
        <option value="1">Active</option>
      </Input>
    </div>
  );
};

const renderActions = (
  params,
  toggleEditShippingBoxModal,
  updateShippingBoxDetails,
  deleteShippingBox,
  setId
) => {
  return (
    <div>
      <Button
        type="button"
        size="sm"
        color="success"
        title="Edit"
        onClick={toggleEditShippingBoxModal}
        onClickCapture={() => {
          setId(params.data._id);
        }}
      >
        <i className="fa fa-pencil"></i>
      </Button>
      <Button
        type="button"
        size="sm"
        color="danger"
        title="Delete"
        onClick={(e) => {
          if (
            window.confirm(
              `Are you sure, you want to delete ${params.data.name} shipping box?`
            )
          ) {
            deleteShippingBox(params.data._id).then((res) => {
              if (res) updateShippingBoxDetails();
            });
          }
        }}
      >
        {' '}
        <i className="fa fa-trash"></i>
      </Button>
    </div>
  );
};

const ShippingBoxList = ({
  getShippingBoxList,
  deleteShippingBox,
  changeStatus,
  markNationalInternational,
  shippingBoxList: { data, count },
  loading,
  subLoading,
}) => {
  const history = useNavigate();
  const location = useLocation();

  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
        limit: Constants.DEFAULT_PAGE_SIZE,
        page: 1,
        orderBy: 'created_at',
        ascending: 'desc',
        query: '',
        filter: {},
        onLoad: true,
      }
  );

  // to resolve useEffect dependency error

  //################## to get updated list ###################

  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);

  // ######################### set shipping_box_id on edit ##########################
  const [shippingBoxId, setShippingBoxId] = useState('');
  const setId = (id) => {
    setShippingBoxId(id);
  };

  //################ toggle shipping-box add modal ############################
  const [shippingBoxModal, setOpenShippingBoxModal] = useState(false);
  const toggleShippingBoxModal = () => {
    setOpenShippingBoxModal(!shippingBoxModal);
  };

  //################ toggle shipping-box edit modal ############################
  const [editShippingBoxModal, setOpenEditShippingBoxModal] = useState(true);
  const toggleEditShippingBoxModal = () => {
    setOpenEditShippingBoxModal(!editShippingBoxModal);
  };

  const updateShippingBoxDetails = () => {
    gridApi.purgeInfiniteCache();
  };

  const actions = (
    <div className="add-button-div specify-width">
      <Button color="primary" size="sm" onClick={toggleShippingBoxModal}>
        <i className="fa fa-plus"></i> Add New Shipping Box
      </Button>

      <AddShippingBox
        isOpen={shippingBoxModal}
        toggle={toggleShippingBoxModal}
        update={updateShippingBoxDetails}
      ></AddShippingBox>

      {shippingBoxId !== '' ? (
        <EditShippingBox
          isOpen={editShippingBoxModal}
          toggle={toggleEditShippingBoxModal}
          shipping_box_id={shippingBoxId}
          update={updateShippingBoxDetails}
        ></EditShippingBox>
      ) : (
        ''
      )}
    </div>
  );

  const changeShippingBoxStatus = async (id, value) => {
    await changeStatus(id, value);
    gridApi.purgeInfiniteCache();
  };

  const columns = [
    {
      field: 'inSensitiveName',
      headerName: 'Name',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      width: 180,
      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      colSpan: (params) => {
        if (params.data === undefined) {
          return 9;
        } else {
          return 0;
        }
      },
      cellRenderer: renderName,
    },
    {
      field: 'length',
      headerName: 'Length ' + SHIPPING_BOX_SIZE_UNIT,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      width: 110,
      filter: 'agNumberColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'width',
      headerName: 'Width ' + SHIPPING_BOX_SIZE_UNIT,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      width: 110,
      filter: 'agNumberColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'height',
      headerName: 'Height ' + SHIPPING_BOX_SIZE_UNIT,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      width: 110,
      filter: 'agNumberColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },

    {
      field: 'weight',
      headerName: 'Box Weight ' + SHIPPING_BOX_WEIGHT_UNIT,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      width: 140,
      filter: 'agNumberColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'min_quantity',
      headerName: 'Min Quantity',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      width: 140,
      filter: 'agNumberColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => {
        if (params.data.is_customised) {
          return <div>-</div>
        } return <div>{params.data.min_quantity}</div>
      }
    },
    {
      field: 'max_quantity',
      headerName: 'Max Quantity',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      width: 140,
      filter: 'agNumberColumnFilter',
      // pass in additional parameters to the text filter
      suppressSizeToFit: false,
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => {
        if (params.data.is_customised) {
          return <div>-</div>
        } return <div>{params.data.max_quantity}</div>
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      width: 150,
      // pass in additional parameters to the text filter
      cellRenderer: (params) => renderStatus(params, changeShippingBoxStatus),
    },
    {
      field: '_id',
      headerName: 'Actions',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) =>
        renderActions(
          params,
          toggleEditShippingBoxModal,
          updateShippingBoxDetails,
          deleteShippingBox,
          setId
        ),
    },
  ];

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };

          const customData = await getShippingBoxList(customParams);
          if (customData?.data?.status) {
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });

  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                {actions}
                <div className="rightpaneltop">
                  Box Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>
              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      defaultColDef={{
                        resizable: true,
                      }}
                      loadingCellRenderer={customLoadingCellRenderer}
                      loadingCellRendererParams={loadingCellRendererParams}
                      domLayout={'autoHeight'}
                      rowModelType={'infinite'}
                      columnDefs={columns}
                      pagination={true}
                      sizeColumnsToFit={true}
                      onFilterChanged={filterChanged}
                      onGridReady={onGridReady}
                      paginationPageSize={sortingParams.limit}
                      cacheBlockSize={sortingParams.limit}
                      cacheQuickFilter={true}
                      onFirstDataRendered={onFirstDataRendered}
                      onPaginationChanged={onPaginationChanged}
                      ref={gridRef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

ShippingBoxList.propTypes = {
  getShippingBoxList: PropTypes.func.isRequired,
  deleteShippingBox: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  markNationalInternational: PropTypes.func.isRequired,
  shippingBoxList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.shippingBox.loading,
  subLoading: state.shippingBox.isSubLoading,
  shippingBoxList: state.shippingBox.shippingBoxList,
});

export default connect(mapStateToProps, {
  getShippingBoxList,
  deleteShippingBox,
  changeStatus,
  markNationalInternational,
})(ShippingBoxList);
