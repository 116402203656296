import axios from 'axios';

import {
  SUB_CATEGORY_MODAL_LOADING,
  SUB_CATEGORY_CREATED,
  SUB_CATEGORY_CREATE_ERROR,
  SUB_CATEGORY_FAIL,
  SUB_CATEGORY_LOADING,
  SUB_CATEGORY_UPDATED,
  SUB_CATEGORY_UPDATE_ERROR,
  SUB_CATEGORY_DELETED,
  SUB_CATEGORY_DELETE_ERROR,
  SUB_CATEGORY_LIST_UPDATED,
  GET_SUB_CATEGORY_BY_ID,
  SUB_CATEGORY_CHANGE_STATUS,
  SUB_CATEGORY_CHANGE_STATUS_ERROR,
} from 'actions/types';

// Create Category
export const create = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: SUB_CATEGORY_MODAL_LOADING,
    });
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let param = new window.FormData();
    param.append('file', formData.selectedFile);
    param.append('slug', formData.slug);
    param.append('name', formData.name);
    param.append('thumbnail', formData.thumbnail);
    param.append('image', formData.image);
    param.append('category_id', formData.category_id);
    if (formData.image) param.append('isFileSelected', 1);
    else param.append('isFileSelected', 0);
    const res = await axios.post(
      '/api/admin/subcategory/create',
      param,
      config
    );

    if (res.data.status === true) {
      dispatch({
        type: SUB_CATEGORY_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: SUB_CATEGORY_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: SUB_CATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
    return false;
  }
};

// Edit SUB_CATEGORY_UPDATED
export const edit =
  (formData, history, subcategory_id, category_id) => async (dispatch) => {
    try {
      dispatch({
        type: SUB_CATEGORY_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      // delete formData.categoryList;
      let param = new window.FormData();
      param.append('file', formData.selectedFile);
      param.append('name', formData.name);
      param.append('thumbnail', formData.thumbnail);
      param.append('image', formData.image);
      param.append('oldimage', formData.oldimage);
      param.append('category_id', formData.category_id);
      if (formData.image) param.append('isFileSelected', 1);
      else param.append('isFileSelected', 0);

      const res = await axios.post(
        `/api/admin/subcategory/${subcategory_id}`,
        param,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: SUB_CATEGORY_UPDATED,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
        history(`/admin/categories/subcategories/${category_id}`);
      } else {
        const errors = res.data.errors;
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }
        dispatch({
          type: SUB_CATEGORY_UPDATE_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
            errorsList,
          },
        });
        return false;
      }
    } catch (err) {
      dispatch({
        type: SUB_CATEGORY_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

// Delete Sub Category Page
export const deleteSubCategory = (subcategory_id) => async (dispatch) => {
  try {
    dispatch({
      type: SUB_CATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.delete(
      `/api/admin/subcategory/${subcategory_id}`,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: SUB_CATEGORY_DELETED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      dispatch({
        type: SUB_CATEGORY_DELETE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
        },
      });
      return false;
    }
  } catch (err) {
    dispatch({
      type: SUB_CATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//get Sub Category list
export const getSubCategoryList =
  (category_id, subcategoryParams) => async (dispatch) => {
    try {
      dispatch({
        type: SUB_CATEGORY_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      
      const query = subcategoryParams.query
        ? subcategoryParams.query
        : '&query=';

      const res = await axios.get(
        `/api/admin/subcategory/parent/${category_id}?limit=${subcategoryParams.limit}&page=${subcategoryParams.page}&orderBy=${subcategoryParams.orderBy}&ascending=${subcategoryParams.ascending}${query}`,
        config
      );

      dispatch({
        type: SUB_CATEGORY_LIST_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: {
            subcategoryParams: subcategoryParams,
            subCategoryList: res.data.response[0],
          },
        },
      });
    } catch (err) {
      dispatch({
        type: SUB_CATEGORY_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

// Get SUB_SUB_CATEGORY by id
export const getSubCategoryById = (subcategory_id) => async (dispatch) => {
  try {
    dispatch({
      type: SUB_CATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/subcategory/${subcategory_id}`,
      config
    );
    await dispatch({
      type: GET_SUB_CATEGORY_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: SUB_CATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//change status
export const changeStatus = (subcategory_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: SUB_CATEGORY_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/subcategory/change-status/${subcategory_id}`,
      { status },
      config
    );

    if (res.data.status === true) {
      await dispatch({
        type: SUB_CATEGORY_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: SUB_CATEGORY_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: subcategory_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: SUB_CATEGORY_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
