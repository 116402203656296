import {
  SET_ALERT,
  REMOVE_ALERT,
  LOGOUT,
  RESET_LINK_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  ATTRIBUTE_LOADING,
  ATTRIBUTE_CREATED,
  ATTRIBUTE_CREATE_ERROR,
  ATTRIBUTE_FAIL,
  ATTRIBUTE_UPDATED,
  ATTRIBUTE_UPDATE_ERROR,
  ATTRIBUTE_DELETED,
  ATTRIBUTE_DELETE_ERROR,
  ATTRIBUTE_CHANGE_STATUS,
  ATTRIBUTE_CHANGE_STATUS_ERROR,
  ATTRIBUTE_TAG_DELETED,
  ATTRIBUTE_TAG_DELETE_ERROR,
  GEAR_ATTRIBUTE_LOADING,
  GEAR_ATTRIBUTE_CREATED,
  GEAR_ATTRIBUTE_CREATE_ERROR,
  GEAR_ATTRIBUTE_FAIL,
  GEAR_ATTRIBUTE_UPDATED,
  GEAR_ATTRIBUTE_UPDATE_ERROR,
  GEAR_ATTRIBUTE_DELETED,
  GEAR_ATTRIBUTE_DELETE_ERROR,
  GEAR_ATTRIBUTE_CHANGE_STATUS,
  GEAR_ATTRIBUTE_CHANGE_STATUS_ERROR,
  GEAR_ATTRIBUTE_TAG_DELETED,
  GEAR_ATTRIBUTE_TAG_DELETE_ERROR,
  CATEGORY_MODAL_LOADING,
  CATEGORY_CREATED,
  CATEGORY_CREATE_ERROR,
  CATEGORY_FAIL,
  CATEGORY_LOADING,
  CATEGORY_UPDATED,
  CATEGORY_UPDATE_ERROR,
  CATEGORY_DELETED,
  CATEGORY_DELETE_ERROR,
  CATEGORY_CHANGE_STATUS,
  CATEGORY_CHANGE_STATUS_ERROR,
  GEAR_CATEGORY_MODAL_LOADING,
  GEAR_CATEGORY_CREATED,
  GEAR_CATEGORY_CREATE_ERROR,
  GEAR_CATEGORY_FAIL,
  GEAR_CATEGORY_LOADING,
  GEAR_CATEGORY_UPDATED,
  GEAR_CATEGORY_UPDATE_ERROR,
  GEAR_CATEGORY_DELETED,
  GEAR_CATEGORY_DELETE_ERROR,
  GEAR_CATEGORY_CHANGE_STATUS,
  GEAR_CATEGORY_CHANGE_STATUS_ERROR,
  SET_GEAR_CATEGORY_LOADER,
  CMS_LOADING,
  CMS_CREATED,
  CMS_CREATE_ERROR,
  CMS_FAIL,
  CMS_UPDATED,
  CMS_UPDATE_ERROR,
  CMS_DELETED,
  CMS_DELETE_ERROR,
  CMS_CHANGE_STATUS,
  CMS_CHANGE_STATUS_ERROR,
  HOME_LOADING,
  HOME_CREATED,
  HOME_CREATE_ERROR,
  HOME_FAIL,
  HOME_UPDATED,
  HOME_UPDATE_ERROR,
  HOME_DELETED,
  HOME_DELETE_ERROR,
  HOME_CHANGE_STATUS,
  HOME_CHANGE_STATUS_ERROR,
  Additional_Product_LOADING,
  Additional_Product_CREATED,
  Additional_Product_CREATE_ERROR,
  Additional_Product_FAIL,
  Additional_Product_UPDATED,
  Additional_Product_UPDATE_ERROR,
  Additional_Product_DELETED,
  Additional_Product_DELETE_ERROR,
  BUILD_SHOP_SUPPLY_LOADING,
  BUILD_SHOP_SUPPLY_CREATED,
  BUILD_SHOP_SUPPLY_CREATE_ERROR,
  BUILD_SHOP_SUPPLY_FAIL,
  BUILD_SHOP_SUPPLY_UPDATED,
  BUILD_SHOP_SUPPLY_UPDATE_ERROR,
  BUILD_SHOP_SUPPLY_DELETED,
  BUILD_SHOP_SUPPLY_DELETE_ERROR,
  NEWS_LOADING,
  NEWS_CREATED,
  NEWS_CREATE_ERROR,
  NEWS_FAIL,
  NEWS_UPDATED,
  NEWS_UPDATE_ERROR,
  NEWS_DELETED,
  NEWS_DELETE_ERROR,
  NEWS_LIST_UPDATED,
  GET_NEWS_BY_ID,
  NEWS_CHANGE_STATUS,
  NEWS_CHANGE_STATUS_ERROR,
  NEWS_LETTER_LOADING,
  NEWS_LETTER_FAIL,
  NEWS_LETTER_DELETED,
  NEWS_LETTER_DELETED_ERROR,

  FlexCode_LOADING,
  FlexCode_CREATED,
  FlexCode_CREATE_ERROR,
  FlexCode_FAIL,
  FlexCode_UPDATED,
  FlexCode_UPDATE_ERROR,
  FlexCode_DELETED,
  FlexCode_DELETE_ERROR,
  FlexCode_LIST_UPDATED,
  GET_FlexCode_BY_ID,

  IronNumber_LOADING,
  IronNumber_CREATED,
  IronNumber_CREATE_ERROR,
  IronNumber_FAIL,
  IronNumber_UPDATED,
  IronNumber_UPDATE_ERROR,
  IronNumber_DELETED,
  IronNumber_DELETE_ERROR,

  PRODUCT_SELECTOR_LOADING,
  PRODUCT_SELECTOR_CREATED,
  PRODUCT_SELECTOR_CREATED_ERROR,
  PRODUCT_SELECTOR_FAIL,
  PRODUCT_SELECTOR_UPDATED,
  PRODUCT_SELECTOR_UPDATED_ERROR,
  COUPON_LOADING,
  COUPON_CREATED,
  COUPON_CREATE_ERROR,
  COUPON_FAIL,
  COUPON_DELETED,
  COUPON_DELETE_ERROR,
  COUPON_UPDATED,
  COUPON_UPDATE_ERROR,
  COUPON_CHANGE_STATUS,
  COUPON_CHANGE_STATUS_ERROR,
  RESOURCE_LOADING,
  RESOURCE_CREATED,
  RESOURCE_UPDATED,
  RESOURCE_CREATE_ERROR,
  RESOURCE_UPDATE_ERROR,
  DEALER_LOADING,
  DEALER_CREATED,
  RESOURCE_DELETED,
  RESOURCE_DELETE_ERROR,
  DEALER_CREATE_ERROR,
  RESOURCE_FAIL,
  DEALER_FAIL,
  DEALER_MODAL_LOADING,
  DEALER_PASSWORD_UPDATED,
  DEALER_PASSWORD_UPDATE_ERROR,
  DEALER_UPDATED,
  DEALER_UPDATE_ERROR,
  DEALER_DELETED,
  DEALER_DELETE_ERROR,
  DEALER_CHANGE_STATUS,
  DEALER_CHANGE_STATUS_ERROR,
  DEALER_CHANGE_STATUS_BULK,
  DEALER_CHANGE_STATUS_BULK_ERROR,
  DEALER_FEATURED_BULK,
  DEALER_FEATURED_BULK_ERROR,
  DEALER_CHANGE_TYPE_BULK,
  DEALER_CHANGE_TYPE_BULK_ERROR,
  DEALER_MARK_FEATURED,
  DEALER_MARK_FEATURED_ERROR,
  DEALER_ADDRESS_LOADING,
  DEALER_ADDRESS_CREATED,
  DEALER_ADDRESS_CREATE_ERROR,
  DEALER_ADDRESS_FAIL,
  DEALER_ADDRESS_UPDATED,
  DEALER_ADDRESS_UPDATE_ERROR,
  DEALER_ADDRESS_DELETED,
  DEALER_ADDRESS_DELETE_ERROR,
  DEALER_ADDRESS_PRIMARY,
  DEALER_ADDRESS_PRIMARY_ERROR,
  DEALER_ADDRESS_CHANGE_STATUS,
  DEALER_ADDRESS_CHANGE_STATUS_ERROR,
  DEALER_TYPE_MODAL_LOADING,
  DEALER_TYPE_CREATED,
  DEALER_TYPE_CREATE_ERROR,
  DEALER_TYPE_FAIL,
  DEALER_TYPE_LOADING,
  DEALER_TYPE_UPDATED,
  DEALER_TYPE_UPDATE_ERROR,
  DEALER_TYPE_CHANGE_STATUS,
  DEALER_TYPE_CHANGE_STATUS_ERROR,
  DEALER_TYPE_DELETED,
  DEALER_TYPE_DELETE_ERROR,
  EMAIL_TEMPLATE_LOADING,
  EMAIL_TEMPLATE_CREATED,
  EMIAL_TEMPLATE_CREATE_ERROR,
  EMAIL_TEMPLATE_FAIL,
  EMAIL_TEMPLATE_UPDATED,
  EMIAL_TEMPLATE_UPDATE_ERROR,
  EMAIL_TEMPLATE_DELETED,
  EMAIL_TEMPLATE_DELETE_ERROR,
  EMAIL_TEMPLATE_CHANGE_STATUS,
  EMAIL_TEMPLATE_CHANGE_STATUS_ERROR,
  INQUIRY_LOADING,
  INQUIRY_CREATED,
  INQUIRY_CREATE_ERROR,
  INQUIRY_FAIL,
  INQUIRY_UPDATED,
  INQUIRY_UPDATE_ERROR,
  INQUIRY_DELETED,
  INQUIRY_DELETE_ERROR,
  INQUIRY_CHANGE_STATUS,
  INQUIRY_CHANGE_STATUS_ERROR,
  SETTINGS_LOADING,
  SETTING_UPDATED,
  SETTING_UPDATE_ERROR,
  SETTINGS_FAIL,
  GET_SETTING_ERROR,
  PROFILE_LOADING,
  USER_PROFILE_UPDATED,
  USER_PROFILE_UPDATED_ERROR,
  USER_PROFILE_FAIL,
  USER_PASSWORD_UPDATED,
  USER_PASSWORD_UPDATE_ERROR,
  SOCIAL_SETTINGS_LOADING,
  SOCIAL_SETTING_UPDATED,
  CARRIER_SETTING_UPDATED,
  SOCIAL_SETTING_UPDATE_ERROR,
  SOCIAL_SETTINGS_FAIL,
  GET_SOCIAL_SETTING_ERROR,
  SOCIAL_SETTING_CHECKBOX_ERROR,
  SUB_CATEGORY_MODAL_LOADING,
  SUB_CATEGORY_CREATED,
  SUB_CATEGORY_CREATE_ERROR,
  SUB_CATEGORY_FAIL,
  SUB_CATEGORY_LOADING,
  SUB_CATEGORY_UPDATED,
  SUB_CATEGORY_UPDATE_ERROR,
  SUB_CATEGORY_DELETED,
  SUB_CATEGORY_DELETE_ERROR,
  SUB_CATEGORY_CHANGE_STATUS,
  SUB_CATEGORY_CHANGE_STATUS_ERROR,
  USER_LOADING,
  USER_CREATED,
  USER_CREATE_ERROR,
  USER_FAIL,
  USER_MODAL_LOADING,
  USER_DETAIL_PASSWORD_UPDATED,
  USER_DETAIL_PASSWORD_UPDATE_ERROR,
  USER_UPDATED,
  USER_UPDATE_ERROR,
  USER_DELETED,
  USER_DELETE_ERROR,
  USER_CHANGE_STATUS,
  USER_CHANGE_STATUS_ERROR,
  USER_CHANGE_STATUS_BULK,
  USER_CHANGE_STATUS_BULK_ERROR,
  USER_ADDRESS_LOADING,
  USER_ADDRESS_CREATED,
  USER_ADDRESS_CREATE_ERROR,
  USER_ADDRESS_FAIL,
  USER_ADDRESS_UPDATED,
  USER_ADDRESS_UPDATE_ERROR,
  USER_ADDRESS_DELETED,
  USER_ADDRESS_DELETE_ERROR,
  USER_ADDRESS_PRIMARY,
  USER_ADDRESS_PRIMARY_ERROR,
  USER_ADDRESS_CHANGE_STATUS,
  USER_ADDRESS_CHANGE_STATUS_ERROR,
  BLOG_LOADING,
  BLOG_CREATED,
  BLOG_CREATE_ERROR,
  BLOG_FAIL,
  BLOG_UPDATED,
  BLOG_UPDATE_ERROR,
  BLOG_DELETED,
  BLOG_DELETE_ERROR,
  BLOG_CHANGE_STATUS,
  BLOG_CHANGE_STATUS_ERROR,
  GEAR_PRODUCT_CREATED,
  GEAR_PRODUCT_CREATE_ERROR,
  GEAR_PRODUCT_FAIL,
  SET_GEAR_ATTRIBUTE_LOADER,
  GEAR_PRODUCT_UPDATED,
  GEAR_PRODUCT_UPDATE_ERROR,
  CHANGE_GEAR_PRODUCT_STATUS,
  CHANGE_GEAR_PRODUCT_STATUS_ERROR,
  DELETE_GEAR_PRODUCT,
  DELETE_GEAR_PRODUCT_ERROR,
  DELETE_GEAR_PRODUCT_IMAGE,
  GEAR_PRIMARY_IMAGE_UPDATED,
  UPDATE_GEAR_PRODUCT_ATTRIBUTES,
  UPDATE_GEAR_PRODUCT_ATTRIBUTES_ERROR,
  GEAR_VARIATION_UPDATED,
  GEAR_VARIATION_UPDATED_ERROR,
  CHANGE_GEAR_PRODUCT_STATUS_BULK,
  GEAR_INVENTORY_AND_SHIPPING_UPDATED,
  GEAR_INVENTORY_AND_SHIPPING_UPDATED_ERROR,
  GEAR_IMAGE_UPLOAD_ERROR,
  UPDATE_GEAR_PRODUCT_IMAGES,
  UPDATE_GEAR_PRODUCT_IMAGES_ERROR,
  RESET_CURRENT_GEAR_PRODUCT,
  GEAR_PRODUCT_ATTRIBUTE_TAG_DELETE,
  GEAR_PRODUCT_ATTRIBUTE_TAG_DELETE_ERROR,
  SET_GEAR_PRODUCT_ATTRIBUTE_OPTION_LOADER,
  GEAR_PRODUCT_ATTRIBUTE_OPTION_CREATED,
  GEAR_PRODUCT_ATTRIBUTE_OPTION_CREATE_ERROR,
  PRODUCT_LOADING,
  PRODUCT_CREATED,
  FITTING_ATTRIBUTE_ERROR,
  ADDED_DEFAULT_VARIATION,
  PRODUCT_CREATE_ERROR,
  PRODUCT_FAIL,
  SET_CATEGORY_LOADER,
  SET_ATTRIBUTE_LOADER,
  SET_SPECS_LOADER,
  PRODUCT_UPDATED,
  INVENTORY_LOADING,
  INVENTORY_UPLOADED,
  BUILD_SHOP_INVENTORY_UPLOADED,
  INVENTORY_UPLOADED_ERROR,
  BUILD_SHOP_INVENTORY_ERROR,
  BUILD_SHOP_INVENTORY_FAIL,
  SPEC_ADD,
  PRODUCT_UPDATE_ERROR,
  CHANGE_PRODUCT_STATUS,
  CHANGE_PRODUCT_STATUS_ERROR,
  DELETE_PRODUCT,
  DELETE_PRODUCT_ERROR,
  DELETE_PRODUCT_IMAGE,
  PRIMARY_IMAGE_UPDATED,
  WHOLESALE_PRICE_ERROR,
  SALE_PRICE_ERROR,
  UPDATE_PRODUCT_ATTRIBUTES,
  UPDATE_PRODUCT_ATTRIBUTES_ERROR,
  UPDATE_PRODUCT_SPECS,
  UPDATE_PRODUCT_SPECS_ERROR,
  VARIATION_UPDATED,
  VARIATION_UPDATED_ERROR,
  CHANGE_PRODUCT_STATUS_BULK,
  INVENTORY_AND_SHIPPING_UPDATED,
  INVENTORY_AND_SHIPPING_UPDATED_ERROR,
  IMAGE_UPLOAD_ERROR,
  UPDATE_PRODUCT_IMAGES,
  UPDATE_PRODUCT_IMAGES_ERROR,
  VARIATION_IMAGE_UPDATED,
  VARIATION_IMAGE_ERROR,
  RESET_CURRENT_PRODUCT,
  PRODUCT_ATTRIBUTE_TAG_DELETE_ERROR,
  SET_PRODUCT_ATTRIBUTE_OPTION_LOADER,
  PRODUCT_ATTRIBUTE_OPTION_CREATED,
  PRODUCT_ATTRIBUTE_OPTION_CREATE_ERROR,
  PRODUCT_SPECS_TAG_DELETE_ERROR,
  SET_PRODUCT_SPECS_OPTION_LOADER,
  PRODUCT_SPECS_OPTION_CREATED,
  PRODUCT_SPECS_OPTION_CREATE_ERROR,
  SHIPPING_BOX_MODAL_LOADING,
  SHIPPING_BOX_ADDED,
  SHIPPING_BOX_ADDED_ERROR,
  SHIPPING_BOX_FAIL,
  SHIPPING_BOX_LOADING,
  DELETE_SHIPPING_BOX,
  CHANGE_SHIPPING_BOX_STATUS,
  CHANGE_SHIPPING_BOX_NATIONAL_INTERNATIONAL,
  SHIPPING_BOX_UPDATED,
  SHIPPING_BOX_UPDATED_ERROR,
  IMAGE_GALLERY_LOADING,
  IMAGE_GALLERY_UPLOADED,
  IMAGE_GALLERY_UPLOAD_ERROR,
  IMAGE_GALLERY_UPDATED,
  IMAGE_GALLERY_UPDATE_ERROR,
  IMAGE_GALLERY_DELETE,
  IMAGE_GALLERY_FAIL,
  IMAGE_GALLERY_DELETE_ERROR,
  VIDEO_GALLERY_LOADING,
  VIDEO_GALLERY_UPLOADED,
  VIDEO_GALLERY_UPLOAD_ERROR,
  VIDEO_GALLERY_UPDATED,
  VIDEO_GALLERY_UPDATE_ERROR,
  VIDEO_GALLERY_DELETE,
  VIDEO_GALLERY_FAIL,
  VIDEO_GALLERY_DELETE_ERROR,
  ORDER_LOADING,
  ORDER_FAIL,
  ORDER_CHANGE_STATUS,
  ORDER_CHANGE_STATUS_ERROR,
  FITTING_ATTRIBUTE_LOADING,
  FITTING_ATTRIBUTE_FAIL,
  FITTING_ATTRIBUTE_MODAL_LOADING,
  FITTING_ATTRIBUTE_CREATED,
  FITTING_ATTRIBUTE_CREATE_ERROR,
  FITTING_ATTRIBUTE_UPDATED,
  FITTING_ATTRIBUTE_UPDATE_ERROR,
  FITTING_SPECS_LOADING,
  FITTING_SPECS_FAIL,
  FITTING_SPECS_MODAL_LOADING,
  FITTING_SPECS_CREATED,
  FITTING_SPECS_CREATE_ERROR,
  FITTING_SPECS_UPDATED,
  FITTING_SPECS_UPDATE_ERROR,
  FITTING_VARIATION_MODAL_LOADING,
  FITTING_VARIATION_CREATED,
  FITTING_VARIATION_CREATE_ERROR,
  FITTING_VARIATION_FAIL,
  FITTING_VARIATION_LOADING,
  FITTING_VARIATION_UPDATED,
  FITTING_VARIATION_UPDATE_ERROR,
  FITTING_VARIATION_CHANGE_STATUS,
  FITTING_VARIATION_CHANGE_STATUS_ERROR,
  FITTING_MODAL_LOADING,
  FITTING_CREATED,
  FITTING_CREATE_ERROR,
  FITTING_FAIL,
  FITTING_LOADING,
  FITTING_UPDATED,
  FITTING_UPDATE_ERROR,
  FITTING_CHANGE_STATUS,
  FITTING_CHANGE_STATUS_ERROR,
  LOGIN_SUCCESS_ALERT,
  COUPON_QUANTITY_INCRESED,
  ADMIN_COUPON_APPLIED,
  ADMIN_CART_UPDATED,
  ORDER_CREATE_RESULT_ERROR,
  ORDER_CREATE_FAIL,
  SPECS_LOADING,
  SPECS_CREATED,
  SPECS_CREATE_ERROR,
  SPECS_FAIL,
  SPECS_UPDATED,
  SPECS_UPDATE_ERROR,
  SPECS_DELETED,
  SPECS_DELETE_ERROR,
  SPECS_CHANGE_STATUS,
  SPECS_CHANGE_STATUS_ERROR,
  SPECS_TAG_DELETED,
  SPECS_TAG_DELETE_ERROR,
  PACKING_SLIP_LOADING,
  PACKING_SLIP_CREATED,
  PACKING_SLIP_CREATE_ERROR,
  PACKING_SLIP_FAIL,
  PACKING_SLIP_UPDATED,
  PACKING_SLIP_UPDATE_ERROR,
  PACKING_SLIP_DELETED,
  PACKING_SLIP_DELETE_ERROR,
  ORDER_LABEL_FAIL,
  ORDER_LABEL_CREATE_ERROR,
  ORDER_LABEL_CREATED,
  ORDER_LABEL_LOADING,
  NOTIFY_CHANGE_STATUS,
  STAFF_LOADING,
  STAFF_CREATED,
  STAFF_CREATE_ERROR,
  STAFF_FAIL,
  STAFF_UPDATED,
  STAFF_UPDATE_ERROR,
  STAFF_DETAIL_PASSWORD_UPDATED,
  STAFF_DETAIL_PASSWORD_UPDATE_ERROR,
  STAFF_DELETE_ERROR,
  STAFF_DELETED,
  STAFF_CHANGE_STATUS,
  STAFF_CHANGE_STATUS_ERROR,
  SMS_TEMPLATE_LOADING,
  SMS_TEMPLATE_CREATED,
  SMS_TEMPLATE_CREATE_ERROR,
  SMS_TEMPLATE_FAIL,
  SMS_TEMPLATE_UPDATED,
  SMS_TEMPLATE_UPDATE_ERROR,
  SMS_TEMPLATE_DELETED,
  SMS_TEMPLATE_DELETE_ERROR,
  SMS_TEMPLATE_CHANGE_STATUS,
  SMS_TEMPLATE_CHANGE_STATUS_ERROR,
  LOGIN_ERROR,
  LOGIN_FAIL,
  ORDER_ADDRESS_ADDED,
  ORDER_ADDRESS_ADDED_ERROR,
  ADMIN_ORDER_COUPON_LOADER,
  ADMIN_ORDER_COUPON_FAIL,
  ADMIN_ORDER_APPLY_COUPON_RESULT,
  ADMIN_ORDER_APPLY_COUPON_RESULT_ERROR,
  ADMIN_ORDER_REMOVE_COUPON_RESULT,
  ADMIN_ORDER_REMOVE_COUPON_RESULT_ERROR,
  ADMIN_ORDER_CART_ERROR,
  ADMIN_ORDER_CART_SUCCESS,
  INTERNAL_NOTES_CREATE_ERROR,
  INTERNAL_NOTES_LOADING,
  INTERNAL_NOTES_DELETED,
  INTERNAL_NOTES_DELETE_ERROR,
  INTERNAL_NOTES_FAIL,
  BUILD_SHOP_NOTES_LOADING,
  BUILD_SHOP_NOTES_UPDATED,
  BUILD_SHOP_NOTES_DELETED,
  BUILD_SHOP_NOTES_DELETE_ERROR,
  BUILD_SHOP_NOTES_FAIL,
  ORDER_CREATE_RESULT,
  ORDER_INVOICE_FAIL,
  ORDER_INVOICE_CREATE_ERROR,
  ORDER_INVOICE_CREATED,
  ORDER_INVOICE_LOADING,
  SEND_EMAIL_LOADING,
  SEND_EMAIL_CREATED,
  SEND_EMAIL_CREATE_ERROR,
  SEND_EMAIL_FAIL,
  GEAR_VARIATION_IMAGE_ERROR,
  GEAR_SHIPPING_BOX_ADDED_ERROR,
  GEAR_SHIPPING_BOX_FAIL,
  CHANGE_GEAR_SHIPPING_BOX_STATUS,
  GET_USER_BY_ID_ERROR,
  GET_DEALER_BY_ID_ERROR,
  CREATE_RETURN_INITIATE,
  RETURN_INITIATE_FAIL,
  CHANGE_RETURN_INITIATE_STATUS,
  VOID_SHIPPING_LABEL_SUCCESS,
  VOID_SHIPPING_LABEL_FAILED,
  ADDITIONAL_PRODUCT_CART_ERROR,
  ADDITIONAL_PRODUCT_CART_SUCCESS,
  MERGE_SHIPPING_SUCCESS,
  MERGE_SHIPPING_ERROR,
  MERGE_SHIPPING_FAIL,
  DELETE_MERGE_SHIPPING_SUCCESS,
  DELETE_MERGE_SHIPPING_ERROR,
  DELETE_MERGE_SHIPPING_FAIL,
  SUCCESS_RESPONSE,
  ERROR_RESPONSE,
  FAILED_RESPONSE,
  SHIPPING_FLEET_REMARK_LOADING,
  SHIPPING_ADDED_FLEET_REMARK,
  SHIPPING_ADDED_FLEET_ERROR,
  SHIPPING_ADDED_FLEET_FAIL,
  ANNOUNCEMENT_SENDING,
  ANNOUNCEMENT_SENDING_FAIL,
  ANNOUNCEMENT_SENT,
  ANNOUNCEMENT_DELETED,
  ANNOUNCEMENT_DELETE_ERROR
} from 'actions/types';

const initialState = [];

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ALERT:
      return [payload];
    case LOGOUT:
      return initialState;
    case REMOVE_ALERT:
      return [];
    case RESET_LINK_SUCCESS:
      return [payload];
    case NOTIFY_CHANGE_STATUS:
    case ATTRIBUTE_LOADING:
    case SPECS_LOADING:
    case CATEGORY_MODAL_LOADING:
    case GEAR_CATEGORY_MODAL_LOADING:
    case CATEGORY_LOADING:
    case GEAR_CATEGORY_LOADING:
    case CMS_LOADING:
    case HOME_LOADING:
    case Additional_Product_LOADING:
    case BUILD_SHOP_SUPPLY_LOADING:
    case NEWS_LOADING:
    case NEWS_LETTER_LOADING:
    case PRODUCT_SELECTOR_LOADING:
    case COUPON_LOADING:
    case RESOURCE_LOADING:
    case DEALER_LOADING:
    case DEALER_MODAL_LOADING:
    case DEALER_ADDRESS_LOADING:
    case DEALER_TYPE_MODAL_LOADING:
    case DEALER_TYPE_LOADING:
    case EMAIL_TEMPLATE_LOADING:
    case INQUIRY_LOADING:
    case SETTINGS_LOADING:
    case PROFILE_LOADING:
    case SOCIAL_SETTINGS_LOADING:
    case SUB_CATEGORY_MODAL_LOADING:
    case SUB_CATEGORY_LOADING:
    case USER_LOADING:
    case USER_MODAL_LOADING:
    case USER_ADDRESS_LOADING:
    case BLOG_LOADING:

    case SET_GEAR_ATTRIBUTE_LOADER:

    case GEAR_PRODUCT_UPDATE_ERROR:
    case CHANGE_GEAR_PRODUCT_STATUS:
    case CHANGE_GEAR_PRODUCT_STATUS_ERROR:
    case DELETE_GEAR_PRODUCT:
    case DELETE_GEAR_PRODUCT_ERROR:
    case DELETE_GEAR_PRODUCT_IMAGE:
    case GEAR_PRIMARY_IMAGE_UPDATED:

    case GEAR_INVENTORY_AND_SHIPPING_UPDATED:
    case GEAR_INVENTORY_AND_SHIPPING_UPDATED_ERROR:
    case GEAR_IMAGE_UPLOAD_ERROR:
    case RESET_CURRENT_GEAR_PRODUCT:
    case SET_GEAR_PRODUCT_ATTRIBUTE_OPTION_LOADER:

    case PRODUCT_LOADING:
    case INVENTORY_LOADING:
    case SET_CATEGORY_LOADER:
    case SET_GEAR_CATEGORY_LOADER:
    case SET_ATTRIBUTE_LOADER:
    case SET_PRODUCT_ATTRIBUTE_OPTION_LOADER:
    case SET_SPECS_LOADER:
    case SET_PRODUCT_SPECS_OPTION_LOADER:
    case RESET_CURRENT_PRODUCT:
    case SHIPPING_BOX_MODAL_LOADING:
    case SHIPPING_FLEET_REMARK_LOADING:
    case SHIPPING_BOX_LOADING:
    case IMAGE_GALLERY_LOADING:
    case VIDEO_GALLERY_LOADING:
    case ORDER_LOADING:
    case FITTING_ATTRIBUTE_LOADING:
    case FITTING_ATTRIBUTE_MODAL_LOADING:
    case FITTING_SPECS_LOADING:
    case FITTING_SPECS_MODAL_LOADING:
    case FITTING_VARIATION_MODAL_LOADING:
    case FITTING_VARIATION_LOADING:
    case PACKING_SLIP_LOADING:
    case ORDER_LABEL_LOADING:
    case ORDER_INVOICE_LOADING:
    case SEND_EMAIL_LOADING:
    case STAFF_LOADING:
    case SMS_TEMPLATE_LOADING:
    case ADMIN_ORDER_COUPON_LOADER:
    case ANNOUNCEMENT_SENDING:
    case ANNOUNCEMENT_DELETED:
      return [];
    case VOID_SHIPPING_LABEL_SUCCESS:
    case VOID_SHIPPING_LABEL_FAILED:
    case ATTRIBUTE_CREATED:
    case GET_USER_BY_ID_ERROR:
    case GET_DEALER_BY_ID_ERROR:
    case ATTRIBUTE_CREATE_ERROR:
    case ATTRIBUTE_FAIL:
    case ATTRIBUTE_UPDATED:
    case ATTRIBUTE_UPDATE_ERROR:
    case ATTRIBUTE_DELETED:
    case ATTRIBUTE_DELETE_ERROR:
    case ATTRIBUTE_CHANGE_STATUS:
    case ATTRIBUTE_CHANGE_STATUS_ERROR:
    case ATTRIBUTE_TAG_DELETED:
    case ATTRIBUTE_TAG_DELETE_ERROR:

    case GEAR_ATTRIBUTE_LOADING:
    case GEAR_ATTRIBUTE_CREATED:
    case GEAR_ATTRIBUTE_CREATE_ERROR:
    case GEAR_ATTRIBUTE_FAIL:
    case GEAR_ATTRIBUTE_UPDATED:
    case GEAR_ATTRIBUTE_UPDATE_ERROR:
    case GEAR_ATTRIBUTE_DELETED:
    case GEAR_ATTRIBUTE_DELETE_ERROR:
    case GEAR_ATTRIBUTE_CHANGE_STATUS:
    case GEAR_ATTRIBUTE_CHANGE_STATUS_ERROR:
    case GEAR_ATTRIBUTE_TAG_DELETED:
    case GEAR_ATTRIBUTE_TAG_DELETE_ERROR:

    /////
    case GEAR_PRODUCT_UPDATED:
    case UPDATE_GEAR_PRODUCT_ATTRIBUTES_ERROR:
    case UPDATE_GEAR_PRODUCT_ATTRIBUTES:
    case GEAR_VARIATION_UPDATED:
    case GEAR_VARIATION_UPDATED_ERROR:
    case GEAR_PRODUCT_FAIL:
    case GEAR_VARIATION_IMAGE_ERROR:
    case CHANGE_GEAR_PRODUCT_STATUS_BULK:
    case UPDATE_GEAR_PRODUCT_IMAGES:
    case UPDATE_GEAR_PRODUCT_IMAGES_ERROR:
    case GEAR_PRODUCT_ATTRIBUTE_TAG_DELETE:
    case GEAR_PRODUCT_ATTRIBUTE_TAG_DELETE_ERROR:
    case GEAR_PRODUCT_ATTRIBUTE_OPTION_CREATED:
    case GEAR_PRODUCT_ATTRIBUTE_OPTION_CREATE_ERROR:
    case GEAR_PRODUCT_CREATED:
    case GEAR_PRODUCT_CREATE_ERROR:

    case ORDER_CREATE_RESULT:
    case CREATE_RETURN_INITIATE:
    case CHANGE_RETURN_INITIATE_STATUS:
    case SPECS_CREATED:
    case SPECS_CREATE_ERROR:
    case SPECS_FAIL:
    case SPECS_UPDATED:
    case SPECS_UPDATE_ERROR:
    case SPECS_DELETED:
    case SPECS_DELETE_ERROR:
    case SPECS_CHANGE_STATUS:
    case SPECS_CHANGE_STATUS_ERROR:
    case SPECS_TAG_DELETED:
    case SPECS_TAG_DELETE_ERROR:
    case CATEGORY_CREATED:
    case CATEGORY_CREATE_ERROR:
    case CATEGORY_FAIL:
    case CATEGORY_UPDATED:
    case CATEGORY_UPDATE_ERROR:
    case CATEGORY_DELETED:
    case CATEGORY_DELETE_ERROR:
    case CATEGORY_CHANGE_STATUS:
    case CATEGORY_CHANGE_STATUS_ERROR:

    case GEAR_CATEGORY_CREATED:
    case GEAR_CATEGORY_CREATE_ERROR:
    case GEAR_CATEGORY_FAIL:
    case GEAR_CATEGORY_UPDATED:
    case GEAR_CATEGORY_UPDATE_ERROR:
    case GEAR_CATEGORY_DELETED:
    case GEAR_CATEGORY_DELETE_ERROR:
    case GEAR_CATEGORY_CHANGE_STATUS:
    case GEAR_CATEGORY_CHANGE_STATUS_ERROR:

    case PRODUCT_SELECTOR_CREATED:
    case PRODUCT_SELECTOR_CREATED_ERROR:
    case PRODUCT_SELECTOR_FAIL:
    case PRODUCT_SELECTOR_UPDATED:
    case PRODUCT_SELECTOR_UPDATED_ERROR:

    case CMS_CREATED:
    case CMS_CREATE_ERROR:
    case CMS_FAIL:
    case CMS_UPDATED:
    case CMS_UPDATE_ERROR:
    case CMS_DELETED:
    case CMS_DELETE_ERROR:
    case CMS_CHANGE_STATUS:
    case CMS_CHANGE_STATUS_ERROR:

    case HOME_CREATED:
    case HOME_CREATE_ERROR:
    case HOME_FAIL:
    case HOME_UPDATED:
    case HOME_UPDATE_ERROR:
    case HOME_DELETED:
    case HOME_DELETE_ERROR:
    case HOME_CHANGE_STATUS:
    case HOME_CHANGE_STATUS_ERROR:

    case Additional_Product_CREATED:
    case BUILD_SHOP_SUPPLY_CREATED:
    case Additional_Product_CREATE_ERROR:
    case BUILD_SHOP_SUPPLY_CREATE_ERROR:
    case Additional_Product_FAIL:
    case BUILD_SHOP_SUPPLY_FAIL:
    case DELETE_MERGE_SHIPPING_FAIL:
    case MERGE_SHIPPING_FAIL:
    case FAILED_RESPONSE:
    case Additional_Product_UPDATED:
    case BUILD_SHOP_SUPPLY_UPDATED:
    case Additional_Product_UPDATE_ERROR:
    case BUILD_SHOP_SUPPLY_UPDATE_ERROR:
    case Additional_Product_DELETED:
    case BUILD_SHOP_SUPPLY_DELETED:
    case Additional_Product_DELETE_ERROR:
    case BUILD_SHOP_SUPPLY_DELETE_ERROR:

    case NEWS_CREATED:
    case NEWS_CREATE_ERROR:
    case NEWS_FAIL:
    case NEWS_UPDATED:
    case NEWS_UPDATE_ERROR:
    case NEWS_DELETED:
    case NEWS_DELETE_ERROR:
    case NEWS_CHANGE_STATUS:
    case NEWS_CHANGE_STATUS_ERROR:

    case FlexCode_LOADING:
    case FlexCode_CREATED:
    case FlexCode_CREATE_ERROR:
    case FlexCode_FAIL:
    case FlexCode_UPDATED:
    case FlexCode_UPDATE_ERROR:
    case FlexCode_DELETED:
    case FlexCode_DELETE_ERROR:
    // case FlexCode_LIST_UPDATED:
    // case GET_FlexCode_BY_ID:


    case IronNumber_LOADING:
    case IronNumber_CREATED:
    case IronNumber_CREATE_ERROR:
    case IronNumber_FAIL:
    case IronNumber_UPDATED:
    case IronNumber_UPDATE_ERROR:
    case IronNumber_DELETED:
    case IronNumber_DELETE_ERROR:

    case NEWS_LETTER_FAIL:
    case NEWS_LETTER_DELETED:
    case NEWS_LETTER_DELETED_ERROR:

    case COUPON_CREATED:
    case COUPON_CREATE_ERROR:
    case COUPON_FAIL:
    case COUPON_DELETED:
    case COUPON_DELETE_ERROR:
    case COUPON_UPDATED:
    case COUPON_UPDATE_ERROR:
    case COUPON_CHANGE_STATUS:
    case COUPON_CHANGE_STATUS_ERROR:
    case RESOURCE_UPDATED:
    case RESOURCE_CREATED:
    case RESOURCE_CREATE_ERROR:
    case RESOURCE_UPDATE_ERROR:
    case DEALER_CREATED:
    case RESOURCE_DELETED:
    case RESOURCE_DELETE_ERROR:
    case DEALER_CREATE_ERROR:
    case RESOURCE_FAIL:
    case DEALER_FAIL:
    case DEALER_PASSWORD_UPDATED:
    case DEALER_PASSWORD_UPDATE_ERROR:
    case DEALER_UPDATED:
    case DEALER_UPDATE_ERROR:
    case DEALER_DELETED:
    case DEALER_DELETE_ERROR:
    case DEALER_CHANGE_STATUS:
    case DEALER_CHANGE_STATUS_ERROR:
    case DEALER_CHANGE_STATUS_BULK:
    case DEALER_CHANGE_STATUS_BULK_ERROR:
    case DEALER_FEATURED_BULK:
    case DEALER_FEATURED_BULK_ERROR:
    case DEALER_CHANGE_TYPE_BULK:
    case DEALER_CHANGE_TYPE_BULK_ERROR:
    case DEALER_MARK_FEATURED:
    case DEALER_MARK_FEATURED_ERROR:
    case DEALER_ADDRESS_CREATED:
    case DEALER_ADDRESS_CREATE_ERROR:
    case DEALER_ADDRESS_FAIL:
    case DEALER_ADDRESS_UPDATED:
    case DEALER_ADDRESS_UPDATE_ERROR:
    case DEALER_ADDRESS_DELETED:
    case DEALER_ADDRESS_DELETE_ERROR:
    case DEALER_ADDRESS_PRIMARY:
    case DEALER_ADDRESS_PRIMARY_ERROR:
    case DEALER_ADDRESS_CHANGE_STATUS:
    case DEALER_ADDRESS_CHANGE_STATUS_ERROR:
    case DEALER_TYPE_CREATED:
    case DEALER_TYPE_CREATE_ERROR:
    case DEALER_TYPE_FAIL:
    case DEALER_TYPE_UPDATED:
    case DEALER_TYPE_UPDATE_ERROR:
    case DEALER_TYPE_CHANGE_STATUS:
    case DEALER_TYPE_CHANGE_STATUS_ERROR:
    case DEALER_TYPE_DELETED:
    case DEALER_TYPE_DELETE_ERROR:
    case EMAIL_TEMPLATE_CREATED:
    case EMIAL_TEMPLATE_CREATE_ERROR:
    case EMAIL_TEMPLATE_FAIL:
    case EMAIL_TEMPLATE_UPDATED:
    case EMIAL_TEMPLATE_UPDATE_ERROR:
    case EMAIL_TEMPLATE_DELETED:
    case EMAIL_TEMPLATE_DELETE_ERROR:
    case EMAIL_TEMPLATE_CHANGE_STATUS:
    case EMAIL_TEMPLATE_CHANGE_STATUS_ERROR:
    case INQUIRY_CREATED:
    case INQUIRY_CREATE_ERROR:
    case INQUIRY_FAIL:
    case INQUIRY_UPDATED:
    case INQUIRY_UPDATE_ERROR:
    case INQUIRY_DELETED:
    case INQUIRY_DELETE_ERROR:
    case INQUIRY_CHANGE_STATUS:
    case INQUIRY_CHANGE_STATUS_ERROR:
    case SETTING_UPDATED:
    case SETTING_UPDATE_ERROR:
    case SETTINGS_FAIL:
    case GET_SETTING_ERROR:
    case USER_PROFILE_UPDATED:
    case USER_PROFILE_UPDATED_ERROR:
    case USER_PROFILE_FAIL:
    case USER_PASSWORD_UPDATED:
    case USER_PASSWORD_UPDATE_ERROR:
    case SOCIAL_SETTING_UPDATED:
    case CARRIER_SETTING_UPDATED:
    case SOCIAL_SETTING_UPDATE_ERROR:
    case SOCIAL_SETTINGS_FAIL:
    case GET_SOCIAL_SETTING_ERROR:
    case SOCIAL_SETTING_CHECKBOX_ERROR:
    case SUB_CATEGORY_CREATED:
    case SUB_CATEGORY_CREATE_ERROR:
    case SUB_CATEGORY_FAIL:
    case SUB_CATEGORY_UPDATED:
    case SUB_CATEGORY_UPDATE_ERROR:
    case SUB_CATEGORY_DELETED:
    case SUB_CATEGORY_DELETE_ERROR:
    case SUB_CATEGORY_CHANGE_STATUS:
    case SUB_CATEGORY_CHANGE_STATUS_ERROR:
    case USER_CREATED:
    case USER_CREATE_ERROR:
    case USER_FAIL:
    case USER_DETAIL_PASSWORD_UPDATED:
    case USER_DETAIL_PASSWORD_UPDATE_ERROR:
    case USER_UPDATED:
    case USER_UPDATE_ERROR:
    case USER_DELETED:
    case USER_DELETE_ERROR:
    case USER_CHANGE_STATUS:
    case USER_CHANGE_STATUS_ERROR:
    case USER_CHANGE_STATUS_BULK:
    case USER_CHANGE_STATUS_BULK_ERROR:
    case USER_ADDRESS_CREATED:
    case USER_ADDRESS_CREATE_ERROR:
    case USER_ADDRESS_FAIL:
    case USER_ADDRESS_UPDATED:
    case USER_ADDRESS_UPDATE_ERROR:
    case USER_ADDRESS_DELETED:
    case USER_ADDRESS_DELETE_ERROR:
    case USER_ADDRESS_PRIMARY:
    case USER_ADDRESS_PRIMARY_ERROR:
    case USER_ADDRESS_CHANGE_STATUS:
    case USER_ADDRESS_CHANGE_STATUS_ERROR:
    case BLOG_CREATED:
    case BLOG_CREATE_ERROR:
    case BLOG_FAIL:
    case BLOG_UPDATED:
    case BLOG_UPDATE_ERROR:
    case BLOG_DELETED:
    case BLOG_DELETE_ERROR:
    case BLOG_CHANGE_STATUS:
    case BLOG_CHANGE_STATUS_ERROR:
    case PRODUCT_CREATED:
    case ADDED_DEFAULT_VARIATION:
    case PRODUCT_CREATE_ERROR:
    case PRODUCT_FAIL:
    case PRODUCT_UPDATED:
    case INVENTORY_UPLOADED:
    case BUILD_SHOP_INVENTORY_UPLOADED:
    case PRODUCT_UPDATE_ERROR:
    case INVENTORY_UPLOADED_ERROR:
    case BUILD_SHOP_INVENTORY_ERROR:
    case BUILD_SHOP_INVENTORY_FAIL:
    case CHANGE_PRODUCT_STATUS:
    case CHANGE_PRODUCT_STATUS_ERROR:
    case DELETE_PRODUCT:
    case DELETE_PRODUCT_ERROR:
    case DELETE_PRODUCT_IMAGE:
    case PRIMARY_IMAGE_UPDATED:
    case WHOLESALE_PRICE_ERROR:
    case SALE_PRICE_ERROR:
    case UPDATE_PRODUCT_ATTRIBUTES:
    case UPDATE_PRODUCT_ATTRIBUTES_ERROR:
    case UPDATE_PRODUCT_SPECS:
    case UPDATE_PRODUCT_SPECS_ERROR:
    case VARIATION_UPDATED:
    case VARIATION_UPDATED_ERROR:
    case CHANGE_PRODUCT_STATUS_BULK:
    case INVENTORY_AND_SHIPPING_UPDATED:
    case INVENTORY_AND_SHIPPING_UPDATED_ERROR:
    case IMAGE_UPLOAD_ERROR:
    case UPDATE_PRODUCT_IMAGES:
    case UPDATE_PRODUCT_IMAGES_ERROR:
    case VARIATION_IMAGE_UPDATED:
    case VARIATION_IMAGE_ERROR:
    case PRODUCT_ATTRIBUTE_TAG_DELETE_ERROR:
    case PRODUCT_ATTRIBUTE_OPTION_CREATED:
    case PRODUCT_ATTRIBUTE_OPTION_CREATE_ERROR:
    case PRODUCT_SPECS_TAG_DELETE_ERROR:
    case PRODUCT_SPECS_OPTION_CREATED:
    case PRODUCT_SPECS_OPTION_CREATE_ERROR:
    case SHIPPING_BOX_ADDED:
    case SHIPPING_ADDED_FLEET_REMARK:
    case SHIPPING_BOX_ADDED_ERROR:
    case SHIPPING_ADDED_FLEET_ERROR:
    case SHIPPING_BOX_FAIL:
    case SHIPPING_ADDED_FLEET_FAIL:
    case DELETE_SHIPPING_BOX:
    case CHANGE_SHIPPING_BOX_STATUS:
    case CHANGE_SHIPPING_BOX_NATIONAL_INTERNATIONAL:
    case SHIPPING_BOX_UPDATED:
    case SHIPPING_BOX_UPDATED_ERROR:
    case IMAGE_GALLERY_UPLOADED:
    case IMAGE_GALLERY_UPLOAD_ERROR:
    case IMAGE_GALLERY_UPDATED:
    case IMAGE_GALLERY_UPDATE_ERROR:
    case IMAGE_GALLERY_DELETE:
    case IMAGE_GALLERY_DELETE_ERROR:
    case IMAGE_GALLERY_FAIL:
    case VIDEO_GALLERY_UPLOADED:
    case VIDEO_GALLERY_UPLOAD_ERROR:
    case VIDEO_GALLERY_UPDATED:
    case VIDEO_GALLERY_UPDATE_ERROR:
    case VIDEO_GALLERY_DELETE:
    case VIDEO_GALLERY_DELETE_ERROR:
    case VIDEO_GALLERY_FAIL:
    case ORDER_FAIL:
    case ORDER_CHANGE_STATUS:
    case ORDER_CHANGE_STATUS_ERROR:
    case FITTING_ATTRIBUTE_FAIL:
    case FITTING_ATTRIBUTE_CREATED:
    case FITTING_ATTRIBUTE_CREATE_ERROR:
    case FITTING_ATTRIBUTE_UPDATED:
    case FITTING_ATTRIBUTE_UPDATE_ERROR:
    case FITTING_SPECS_FAIL:
    case FITTING_SPECS_CREATED:
    case FITTING_SPECS_CREATE_ERROR:
    case FITTING_SPECS_UPDATED:
    case FITTING_SPECS_UPDATE_ERROR:
    case FITTING_VARIATION_CREATED:
    case FITTING_VARIATION_CREATE_ERROR:
    case FITTING_VARIATION_FAIL:
    case FITTING_VARIATION_UPDATED:
    case FITTING_VARIATION_UPDATE_ERROR:
    case FITTING_VARIATION_CHANGE_STATUS:
    case COUPON_QUANTITY_INCRESED:
    case ADMIN_COUPON_APPLIED:
    case ADMIN_CART_UPDATED:
    case FITTING_VARIATION_CHANGE_STATUS_ERROR:
    case ORDER_CREATE_RESULT_ERROR:
    case RETURN_INITIATE_FAIL:
    case ORDER_CREATE_FAIL:
    case PACKING_SLIP_CREATED:
    case PACKING_SLIP_CREATE_ERROR:
    case PACKING_SLIP_DELETED:
    case PACKING_SLIP_DELETE_ERROR:
    case PACKING_SLIP_FAIL:
    case PACKING_SLIP_UPDATED:
    case PACKING_SLIP_UPDATE_ERROR:
    case ORDER_LABEL_CREATED:
    case ORDER_LABEL_CREATE_ERROR:
    case ORDER_LABEL_FAIL:
    case ORDER_INVOICE_CREATED:
    case SEND_EMAIL_CREATED:
    case ORDER_INVOICE_CREATE_ERROR:
    case SEND_EMAIL_CREATE_ERROR:
    case ORDER_INVOICE_FAIL:
    case SEND_EMAIL_FAIL:
    case STAFF_CREATED:
    case STAFF_CREATE_ERROR:
    case STAFF_FAIL:
    case STAFF_UPDATED:
    case STAFF_UPDATE_ERROR:
    case STAFF_DETAIL_PASSWORD_UPDATED:
    case STAFF_DETAIL_PASSWORD_UPDATE_ERROR:
    case STAFF_DELETED:
    case STAFF_DELETE_ERROR:
    case STAFF_CHANGE_STATUS:
    case SMS_TEMPLATE_FAIL:
    case STAFF_CHANGE_STATUS_ERROR:
    case SMS_TEMPLATE_CREATE_ERROR:
    case SMS_TEMPLATE_DELETE_ERROR:
    case SMS_TEMPLATE_UPDATE_ERROR:
    case SMS_TEMPLATE_CHANGE_STATUS_ERROR:
    case SMS_TEMPLATE_CREATED:
    case SMS_TEMPLATE_UPDATED:
    case SMS_TEMPLATE_CHANGE_STATUS:
    case SMS_TEMPLATE_DELETED:
    case ORDER_ADDRESS_ADDED:
    case ORDER_ADDRESS_ADDED_ERROR:
    case ADMIN_ORDER_APPLY_COUPON_RESULT:
    case ADMIN_ORDER_APPLY_COUPON_RESULT_ERROR:
    case ADMIN_ORDER_REMOVE_COUPON_RESULT:
    case ADMIN_ORDER_REMOVE_COUPON_RESULT_ERROR:
    case ADMIN_ORDER_CART_ERROR:
    case ADDITIONAL_PRODUCT_CART_ERROR:
    case DELETE_MERGE_SHIPPING_ERROR:
    case MERGE_SHIPPING_ERROR:
    case ERROR_RESPONSE:
    case ADMIN_ORDER_CART_SUCCESS:
    case ADDITIONAL_PRODUCT_CART_SUCCESS:
    case DELETE_MERGE_SHIPPING_SUCCESS:
    case MERGE_SHIPPING_SUCCESS:
    case SUCCESS_RESPONSE:
    case ADMIN_ORDER_COUPON_FAIL:
    case GEAR_SHIPPING_BOX_FAIL:
    case GEAR_SHIPPING_BOX_ADDED_ERROR:
    case ANNOUNCEMENT_SENT:
    case ANNOUNCEMENT_SENDING_FAIL:
    case ANNOUNCEMENT_DELETE_ERROR:
      return [payload];
    case CHANGE_GEAR_SHIPPING_BOX_STATUS:
    case LOGIN_SUCCESS_ALERT:
    case LOGIN_ERROR:
    case FITTING_MODAL_LOADING:
    case FITTING_LOADING:
    case FITTING_CREATED:
    case FITTING_CREATE_ERROR:
    case FITTING_FAIL:
    case FITTING_ATTRIBUTE_ERROR:
    case FITTING_UPDATED:
    case FITTING_UPDATE_ERROR:
    case FITTING_CHANGE_STATUS:
    case FITTING_CHANGE_STATUS_ERROR:
    case LOGIN_FAIL:
    case INTERNAL_NOTES_CREATE_ERROR:
    case INTERNAL_NOTES_LOADING:
    case INTERNAL_NOTES_DELETED:
    case INTERNAL_NOTES_DELETE_ERROR:
    case INTERNAL_NOTES_FAIL:
    case BUILD_SHOP_NOTES_LOADING:
    case BUILD_SHOP_NOTES_UPDATED:
    case BUILD_SHOP_NOTES_DELETED:
    case BUILD_SHOP_NOTES_DELETE_ERROR:
    case BUILD_SHOP_NOTES_FAIL:
    case RESET_PASSWORD_SUCCESS:
      return [payload];
    default:
      if (payload?.isAlert) {
        return [
          {
            message: payload.message,
            alertType: payload.alertType,
          },
        ];
      }
      return state;
  }
}
