/* eslint-disable */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';

import {
  edit,
  getCouponById,
  getUserSpecificOptionList,
  getCategoriesOptionList,
  getProductsOptionList,
  getSkuOptionList,
  getFittingAccessoriesOptionList,
} from 'actions/admin/coupon';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { REMOVE_ERRORS } from 'actions/types';
import { CUSTOMER_TYPE } from 'constants/index';
import Select from 'react-select';

const EditCoupon = ({
  edit,
  history,
  match,
  // coupon: { currentCoupon, loading },
  currentCoupon,
  loading,
  errorList,
  getCouponById,
  getUserSpecificOptionList,
  userSpecificOptionsList,
  userSpecificOptionsListLoading,
  getCategoriesOptionList,
  applicableCategoriesOptionsList,
  getProductsOptionList,
  applicableProductsOptionsList,
  getSkuOptionList,
  applicableSkuOptionsList,
  getFittingAccessoriesOptionList,
  applicableFittingAccessoriesOptionsList,
}) => {
  /**************** use state for form data *********************/
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    coupon_code: '',
    user_specific: [],
    consumer_type: CUSTOMER_TYPE.ALL,
    // discount: '',
    min_purchase: 0,
    max_discount: 0,
    usage_limit: '',
    expiry_date: '',
    comment_text: '',
    discountValueType: 'per',
    discountValue: 0,
    applicableFor: '',
    applicableCategories: [],
    applicableProducts: [],
    applicableSKUs: [],
    applicableFittingAccessories: [],
    selectedCategories: [],
    selectedProducts: [],
    selectedSKUs: [],
    selectedFittingAccessories: [],
    selectedUserSpecific: [],
  });
  const [singleUse, setSingleUse] = useState(false);
  // to resolve useEffect dependency error

  /**************** use effect to get current attribute *********************/
  const { coupon_id: coupon_id } = useParams();
  useMemo(() => {
    if (coupon_id) getCouponById(coupon_id, navigate, 'SHAFT');
  }, [coupon_id]);

  /**************** use effect to set form data from current attribute *********************/
  useMemo(() => {
    if (Object.keys(currentCoupon).length > 0) {
      let expiry_date = new Date(currentCoupon?.expiry_date); // timestamp to date
      let year = expiry_date.getFullYear(); // get year
      let month = ('0' + (expiry_date.getMonth() + 1)).slice(-2); // get month and convert to 2 digits
      let date = ('0' + expiry_date.getDate()).slice(-2); // get date and convert to 2 digits

      expiry_date = year + '-' + month + '-' + date; // Input date format "yyyy-mm-dd"

      let userSpecificIds = [];
      currentCoupon?.selectedUserSpecific.map((item, index) => {
        userSpecificIds.push(item.value);
      });

      let applicableCategoriesIds = [];
      currentCoupon?.selectedCategories.map((item, index) => {
        applicableCategoriesIds.push(item.value);
      });

      let applicableProductsIds = [];
      currentCoupon?.selectedProducts.map((item, index) => {
        applicableProductsIds.push(item.value);
      });

      let applicableSkuIds = [];
      currentCoupon?.selectedSKUs.map((item, index) => {
        applicableSkuIds.push(item.value);
      });

      let applicableFittingAccessoriesIds = [];
      currentCoupon?.selectedFittingAccessories.map((item, index) => {
        applicableFittingAccessoriesIds.push(item.value);
      });

      setFormData({
        ...currentCoupon,
        expiry_date:
          !currentCoupon?.expiry_date || currentCoupon?.expiry_date === ''
            ? ''
            : expiry_date,
        user_specific: userSpecificIds,
        applicableCategories: applicableCategoriesIds,
        applicableProducts: applicableProductsIds,
        applicableSKUs: applicableSkuIds,
        applicableFittingAccessories: applicableFittingAccessoriesIds,
      });
      setSingleUse(
        currentCoupon?.single_use ? currentCoupon?.single_use : false
      );
    }
  }, [currentCoupon]);

  /**************** expload from data *********************/
  const {
    coupon_code,
    // user_specific,
    consumer_type,
    // discount,
    min_purchase,
    max_discount,
    usage_limit,
    expiry_date,
    comment_text,
    discountValueType,
    discountValue,
    applicableFor,
    // applicableCategories,
    selectedCategories,
    selectedProducts,
    selectedSKUs,
    selectedFittingAccessories,
    selectedUserSpecific,
  } = formData;

  //################## to get user list ###################
  useMemo(() => {
    getUserSpecificOptionList(consumer_type);
  }, [consumer_type]);

  //################## to get categories/products/sku/fitting accessories list ###################
  useEffect(() => {
    setFormData({
      ...formData,
      applicableCategories: [],
      applicableProducts: [],
      applicableSKUs: [],
      applicableFittingAccessories: [],
      selectedCategories: [],
      selectedProducts: [],
      selectedSKUs: [],
      selectedFittingAccessories: [],
    });
    if (applicableFor) {
      switch (applicableFor) {
        case 'shaftCategories':
          getCategoriesOptionList('SHAFTS');
          break;
        case 'shaftProducts':
          getProductsOptionList({ product_id: null, type: 'SHAFTS' });
          break;
        case 'shaftSku':
          getSkuOptionList('SHAFTS');
          break;
        case 'shaftFittingAccessories':
          getFittingAccessoriesOptionList('SHAFTS');
          break;
        case 'gearCategories':
          getCategoriesOptionList('OTHER');
          break;
        case 'gearProducts':
          getProductsOptionList({ product_id: null, type: 'OTHER' });
          break;
        case 'gearSku':
          getSkuOptionList('OTHER');
          break;
        case 'gearFittingAccessories':
          getFittingAccessoriesOptionList('OTHER');
          break;
      }
    }
  }, [applicableFor]);

  /**************** use dispatch *********************/
  const dispatch = useDispatch();

  /**************** handle on change event *********************/
  useEffect(() => {
    if (parseInt(consumer_type) !== CUSTOMER_TYPE.ALL) {
      setFormData({
        ...formData,
        user_specific: [],
        selectedUserSpecific: [],
      });
    }
  }, [consumer_type]);

  useEffect(() => {
    if (discountValueType === 'fixed') {
      setFormData({ ...formData, max_discount: 0 });
    }
  }, [discountValueType]);

  useEffect(() => {
    let updated_code = coupon_code.replace(/[^A-Z0-9]+/gi, '_').toUpperCase();
    setFormData({ ...formData, coupon_code: updated_code });
  }, [coupon_code]);

  const onChange = (e) => {
    if (
      e.target.name === 'discountValueType' &&
      e.target.value?.trim() === 'fixed'
    ) {
      setFormData({ ...formData, max_discount: 0 });
    }
    if (
      e.target.name === 'consumer_type' &&
      parseInt(e.target.value?.trim()) !== CUSTOMER_TYPE.ALL
    ) {
      setFormData({
        ...formData,
        user_specific: [],
        selectedUserSpecific: [],
      });
    }
    if (e.target.name === 'applicableFor') {
      setFormData({
        ...formData,
        applicableCategories: [],
        applicableProducts: [],
        applicableSKUs: [],
        applicableFittingAccessories: [],
        selectedCategories: [],
        selectedProducts: [],
        selectedSKUs: [],
        selectedFittingAccessories: [],
      });
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
    dispatch({ type: REMOVE_ERRORS });
  };

  const onChangeSingleUse = (e) => {
    setSingleUse(!singleUse);
  };

  const handleUserSpecific = (userSpecificOptions) => {
    let userSpecificIds = [];
    userSpecificOptions.map((item, index) => {
      userSpecificIds.push(item.value);
    });
    setFormData({
      ...formData,
      user_specific: userSpecificIds,
      selectedUserSpecific: userSpecificOptions,
    });
  };

  const selectAll = () => {
    switch (applicableFor) {
      case 'shaftCategories':
        let applicableShaftCategoriesIds = [];
        applicableCategoriesOptionsList.map((item, index) => {
          applicableShaftCategoriesIds.push(item.value);
        });
        setFormData({
          ...formData,
          applicableCategories: applicableShaftCategoriesIds,
          selectedCategories: applicableCategoriesOptionsList,
          applicableProducts: [],
          applicableSKUs: [],
          applicableFittingAccessories: [],
          selectedProducts: [],
          selectedSKUs: [],
          selectedFittingAccessories: [],
        });
        break;
      case 'shaftProducts':
        let applicableShaftProductsIds = [];
        applicableProductsOptionsList.map((item, index) => {
          applicableShaftProductsIds.push(item.value);
        });
        setFormData({
          ...formData,
          applicableProducts: applicableShaftProductsIds,
          selectedProducts: applicableProductsOptionsList,
          applicableCategories: [],
          applicableSKUs: [],
          applicableFittingAccessories: [],
          selectedCategories: [],
          selectedSKUs: [],
          selectedFittingAccessories: [],
        });
        break;
      case 'shaftSku':
        let applicableShaftSkuIds = [];
        applicableSkuOptionsList.map((item, index) => {
          applicableShaftSkuIds.push(item.value);
        });
        setFormData({
          ...formData,
          applicableSKUs: applicableShaftSkuIds,
          selectedSKUs: applicableSkuOptionsList,
          applicableCategories: [],
          applicableProducts: [],
          applicableFittingAccessories: [],
          selectedCategories: [],
          selectedProducts: [],
          selectedFittingAccessories: [],
        });
        break;
      case 'gearCategories':
        let applicableGearCategoriesIds = [];
        applicableCategoriesOptionsList.map((item, index) => {
          applicableGearCategoriesIds.push(item.value);
        });
        setFormData({
          ...formData,
          applicableCategories: applicableGearCategoriesIds,
          selectedCategories: applicableCategoriesOptionsList,
          applicableProducts: [],
          applicableSKUs: [],
          applicableFittingAccessories: [],
          selectedProducts: [],
          selectedSKUs: [],
          selectedFittingAccessories: [],
        });
        break;
      case 'gearProducts':
        let applicableGearProductsIds = [];
        applicableProductsOptionsList.map((item, index) => {
          applicableGearProductsIds.push(item.value);
        });
        setFormData({
          ...formData,
          applicableProducts: applicableGearProductsIds,
          selectedProducts: applicableProductsOptionsList,
          applicableCategories: [],
          applicableSKUs: [],
          applicableFittingAccessories: [],
          selectedCategories: [],
          selectedSKUs: [],
          selectedFittingAccessories: [],
        });
        break;
      case 'gearSku':
        let applicableGearSkuIds = [];
        applicableSkuOptionsList.map((item, index) => {
          applicableGearSkuIds.push(item.value);
        });
        setFormData({
          ...formData,
          applicableSKUs: applicableGearSkuIds,
          selectedSKUs: applicableSkuOptionsList,
          applicableCategories: [],
          applicableProducts: [],
          applicableFittingAccessories: [],
          selectedCategories: [],
          selectedProducts: [],
          selectedFittingAccessories: [],
        });
        break;
    }
  };

  const handleApplicableForCategories = (applicableCategoriesOptions) => {
    let applicableCategoriesIds = [];
    applicableCategoriesOptions.map((item, index) => {
      applicableCategoriesIds.push(item.value);
    });
    setFormData({
      ...formData,
      applicableCategories: applicableCategoriesIds,
      selectedCategories: applicableCategoriesOptions,
      applicableProducts: [],
      applicableSKUs: [],
      applicableFittingAccessories: [],
      selectedProducts: [],
      selectedSKUs: [],
      selectedFittingAccessories: [],
    });
  };

  const handleApplicableForProducts = (applicableProductsOptions) => {
    let applicableProductsIds = [];
    applicableProductsOptions.map((item, index) => {
      applicableProductsIds.push(item.value);
    });
    setFormData({
      ...formData,
      applicableProducts: applicableProductsIds,
      selectedProducts: applicableProductsOptions,
      applicableCategories: [],
      applicableSKUs: [],
      applicableFittingAccessories: [],
      selectedCategories: [],
      selectedSKUs: [],
      selectedFittingAccessories: [],
    });
  };

  const handleApplicableForSku = (applicableSkuOptions) => {
    let applicableSkuIds = [];
    applicableSkuOptions.map((item, index) => {
      applicableSkuIds.push(item.value);
    });
    setFormData({
      ...formData,
      applicableSKUs: applicableSkuIds,
      selectedSKUs: applicableSkuOptions,
      applicableCategories: [],
      applicableProducts: [],
      applicableFittingAccessories: [],
      selectedCategories: [],
      selectedProducts: [],
      selectedFittingAccessories: [],
    });
  };

  const handleApplicableForFittingAccessories = (
    applicableFittingAccessoriesOptions
  ) => {
    let applicableFittingAccessoriesIds = [];
    applicableFittingAccessoriesOptions.map((item, index) => {
      applicableFittingAccessoriesIds.push(item.value);
    });
    setFormData({
      ...formData,
      applicableFittingAccessories: applicableFittingAccessoriesIds,
      selectedFittingAccessories: applicableFittingAccessoriesOptions,
      applicableCategories: [],
      applicableProducts: [],
      applicableSKUs: [],
      selectedCategories: [],
      selectedProducts: [],
      selectedSKUs: [],
    });
  };

  const clearApplicableFor = () => {
    setFormData({
      ...formData,
      applicableFor: '',
      applicableCategories: [],
      applicableProducts: [],
      applicableSKUs: [],
      applicableFittingAccessories: [],
      selectedCategories: [],
      selectedProducts: [],
      selectedSKUs: [],
      selectedFittingAccessories: [],
    });
  };

  const disablePastDate = () => {
    const today = new Date();

    const mm = ('0' + (today.getMonth() + 1)).slice(-2); // get month and convert to 2 digits
    const dd = ('0' + today.getDate()).slice(-2); // get date and convert to 2 digits
    const yyyy = today.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  };

  /**************** handle on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    Object.keys(formData).forEach((k) => {
      typeof formData[k] === 'string'
        ? (formData[k] = formData[k]?.trim())
        : (formData[k] = formData[k]);
    });
    edit({ ...formData, single_use: singleUse }, navigate, coupon_id);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="12">
          <Card>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="coupon_code">
                        Coupon code <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="coupon_code"
                        name="coupon_code"
                        maxLength={50}
                        value={coupon_code}
                        readOnly
                        disabled
                        onChange={(e) => onChange(e)}
                        invalid={errorList.coupon_code ? true : false}
                      />
                      <Errors current_key="coupon_code" key="coupon_code" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="consumer_type">
                        Consumer type <span>*</span>
                      </Label>
                      <Input
                        type="select"
                        id="consumer_type"
                        name="consumer_type"
                        value={consumer_type}
                        defaultValue={CUSTOMER_TYPE.ALL}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.consumer_type ? true : false}
                      >
                        {/* <option value="">{'Select'}</option> */}
                        <option value={CUSTOMER_TYPE.ALL}>{'All'}</option>
                        <option value={CUSTOMER_TYPE.DEALER}>{'Dealer'}</option>
                        <option value={CUSTOMER_TYPE.CUSTOMER}>
                          {'Customer'}
                        </option>
                      </Input>
                      <Errors current_key="consumer_type" key="consumer_type" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="user_specific">User specific</Label>
                      {/* <Input
                        type="text"
                        id="user_specific"
                        name="user_specific"
                        value={user_specific}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.user_specific ? true : false}
                      /> */}
                      <Select
                        isMulti
                        id="user_specific"
                        name="user_specific"
                        options={userSpecificOptionsList}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(e) => handleUserSpecific(e)}
                        value={selectedUserSpecific}
                        // className="basic-multi-select"
                        // classNamePrefix="select"
                      />
                      <Errors current_key="user_specific" key="user_specific" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    {/* <FormGroup>
                      <Label htmlFor="discount">
                        Discount (in %) <span>*</span>{' '}
                      </Label>
                      <Input
                        type="number"
                        id="discount"
                        name="discount"
                        required
                        min={0}
                        value={discount}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.discount ? true : false}
                      />
                      <Errors current_key="discount" key="discount" />
                    </FormGroup> */}
                    <FormGroup>
                      <Label htmlFor="discount">
                        Discount in<span>*</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Input
                          type="radio"
                          name="discountValueType"
                          onChange={(e) => onChange(e)}
                          value="per"
                          checked={discountValueType === 'per'}
                        />
                        Percentage (%) &nbsp;&nbsp;&nbsp;&nbsp;
                        <Input
                          type="radio"
                          name="discountValueType"
                          onChange={(e) => onChange(e)}
                          value="fixed"
                          checked={discountValueType === 'fixed'}
                        />
                        Value ($) <span> {'    '}</span>
                      </Label>
                      <Input
                        type="number"
                        id="discountValue"
                        name="discountValue"
                        min={0}
                        step={0.01}
                        value={discountValue}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.discountValue ? true : false}
                      />
                      <Errors current_key="discountValue" key="discountValue" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="min_purchase">Minimum purchase</Label>
                      <Input
                        type="number"
                        id="min_purchase"
                        name="min_purchase"
                        min={0}
                        step={0.01}
                        value={min_purchase}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.min_purchase ? true : false}
                      />
                      <Errors current_key="min_purchase" key="min_purchase" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="max_discount">
                        Maximum discount <span>*</span>{' '}
                      </Label>
                      <Input
                        type="number"
                        id="max_discount"
                        name="max_discount"
                        min={0}
                        step={0.01}
                        value={max_discount}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.max_discount ? true : false}
                        disabled={discountValueType === 'fixed'}
                      />
                      <Errors current_key="max_discount" key="max_discount" />
                    </FormGroup>
                  </Col>

                  <FormGroup tag="fieldset">
                    <Label>Coupon applicable for</Label>
                    <Button
                      color="link"
                      disabled={!applicableFor}
                      onClick={(e) => {
                        clearApplicableFor(e);
                      }}
                    >
                      Clear
                    </Button>
                    <Row>
                      <FormGroup>
                        <Row>
                          <Col sm={6} md={4}>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="applicableFor"
                                  value="shaftCategories"
                                  checked={applicableFor === 'shaftCategories'}
                                  onChange={(e) => onChange(e)}
                                />{' '}
                                Shaft Categories
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col sm={6} md={4}>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="applicableFor"
                                  value="shaftProducts"
                                  checked={applicableFor === 'shaftProducts'}
                                  onChange={(e) => onChange(e)}
                                />{' '}
                                Shaft Products
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col sm={6} md={4}>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="applicableFor"
                                  value="shaftSku"
                                  checked={applicableFor === 'shaftSku'}
                                  onChange={(e) => onChange(e)}
                                />{' '}
                                Shaft SKU
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                      </FormGroup>
                      {applicableFor === 'shaftCategories' && (
                        <FormGroup>
                          <Label htmlFor="applicableCategories">
                            Applicable Categories <span>*</span>{' '}
                            <Button
                              onClick={() => selectAll()}
                              style={{
                                background: 'transparent',
                                padding: '1px',
                                border: 'none',
                              }}
                            >
                              Select All
                            </Button>
                          </Label>
                          <Select
                            isMulti
                            id="applicableCategories"
                            name="applicableCategories"
                            options={applicableCategoriesOptionsList}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) => handleApplicableForCategories(e)}
                            value={selectedCategories}
                            // className="basic-multi-select"
                            // classNamePrefix="select"
                          />
                          <Errors
                            current_key="applicableCategories"
                            key="applicableCategories"
                          />
                        </FormGroup>
                      )}
                      {applicableFor === 'shaftProducts' && (
                        <FormGroup>
                          <Label htmlFor="applicableProducts">
                            Applicable Products <span>*</span>{' '}
                            <Button
                              onClick={() => selectAll()}
                              style={{
                                background: 'transparent',
                                padding: '1px',
                                border: 'none',
                              }}
                            >
                              Select All
                            </Button>
                          </Label>
                          <Select
                            isMulti
                            id="applicableProducts"
                            name="applicableProducts"
                            options={applicableProductsOptionsList}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) => handleApplicableForProducts(e)}
                            value={selectedProducts}
                            // className="basic-multi-select"
                            // classNamePrefix="select"
                          />
                          <Errors
                            current_key="applicableProducts"
                            key="applicableProducts"
                          />
                        </FormGroup>
                      )}
                      {applicableFor === 'shaftSku' && (
                        <FormGroup>
                          <Label htmlFor="applicableSKUs">
                            Applicable SKU <span>*</span>{' '}
                            <Button
                              onClick={() => selectAll()}
                              style={{
                                background: 'transparent',
                                padding: '1px',
                                border: 'none',
                              }}
                            >
                              Select All
                            </Button>
                          </Label>
                          <Select
                            isMulti
                            id="applicableSKUs"
                            name="applicableSKUs"
                            options={applicableSkuOptionsList}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) => handleApplicableForSku(e)}
                            value={selectedSKUs}
                            // className="basic-multi-select"
                            // classNamePrefix="select"
                          />
                          <Errors
                            current_key="applicableSKUs"
                            key="applicableSKUs"
                          />
                        </FormGroup>
                      )}
                      {applicableFor === 'shaftFittingAccessories' && (
                        <FormGroup>
                          <Label htmlFor="applicableFittingAccessories">
                            Applicable Fitting Accessories <span>*</span>
                          </Label>
                          <Select
                            isMulti
                            id="applicableFittingAccessories"
                            name="applicableFittingAccessories"
                            options={applicableFittingAccessoriesOptionsList}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) =>
                              handleApplicableForFittingAccessories(e)
                            }
                            value={selectedFittingAccessories}
                            // className="basic-multi-select"
                            // classNamePrefix="select"
                          />
                          <Errors
                            current_key="applicableFittingAccessories"
                            key="applicableFittingAccessories"
                          />
                        </FormGroup>
                      )}
                      <FormGroup>
                        <Row>
                          <Col sm={6} md={4}>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="applicableFor"
                                  value="gearCategories"
                                  checked={applicableFor === 'gearCategories'}
                                  onChange={(e) => onChange(e)}
                                />{' '}
                                Gear Categories
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col sm={6} md={4}>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="applicableFor"
                                  value="gearProducts"
                                  checked={applicableFor === 'gearProducts'}
                                  onChange={(e) => onChange(e)}
                                />{' '}
                                Gear Products
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col sm={6} md={4}>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="applicableFor"
                                  value="gearSku"
                                  checked={applicableFor === 'gearSku'}
                                  onChange={(e) => onChange(e)}
                                />{' '}
                                Gear SKU
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                      </FormGroup>
                      {applicableFor === 'gearCategories' && (
                        <FormGroup>
                          <Label htmlFor="applicableCategories">
                            Applicable Categories <span>*</span>{' '}
                            <Button
                              onClick={() => selectAll()}
                              style={{
                                background: 'transparent',
                                padding: '1px',
                                border: 'none',
                              }}
                            >
                              Select All
                            </Button>
                          </Label>
                          <Select
                            isMulti
                            id="applicableCategories"
                            name="applicableCategories"
                            options={applicableCategoriesOptionsList}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) => handleApplicableForCategories(e)}
                            value={selectedCategories}
                            // className="basic-multi-select"
                            // classNamePrefix="select"
                          />
                          <Errors
                            current_key="applicableCategories"
                            key="applicableCategories"
                          />
                        </FormGroup>
                      )}
                      {applicableFor === 'gearProducts' && (
                        <FormGroup>
                          <Label htmlFor="applicableProducts">
                            Applicable Products <span>*</span>{' '}
                            <Button
                              onClick={() => selectAll()}
                              style={{
                                background: 'transparent',
                                padding: '1px',
                                border: 'none',
                              }}
                            >
                              Select All
                            </Button>
                          </Label>
                          <Select
                            isMulti
                            id="applicableProducts"
                            name="applicableProducts"
                            options={applicableProductsOptionsList}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) => handleApplicableForProducts(e)}
                            value={selectedProducts}
                            // className="basic-multi-select"
                            // classNamePrefix="select"
                          />
                          <Errors
                            current_key="applicableProducts"
                            key="applicableProducts"
                          />
                        </FormGroup>
                      )}
                      {applicableFor === 'gearSku' && (
                        <FormGroup>
                          <Label htmlFor="applicableSKUs">
                            Applicable SKU <span>*</span>{' '}
                            <Button
                              onClick={() => selectAll()}
                              style={{
                                background: 'transparent',
                                padding: '1px',
                                border: 'none',
                              }}
                            >
                              Select All
                            </Button>
                          </Label>
                          <Select
                            isMulti
                            id="applicableSKUs"
                            name="applicableSKUs"
                            options={applicableSkuOptionsList}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) => handleApplicableForSku(e)}
                            value={selectedSKUs}
                            // className="basic-multi-select"
                            // classNamePrefix="select"
                          />
                          <Errors
                            current_key="applicableSKUs"
                            key="applicableSKUs"
                          />
                        </FormGroup>
                      )}
                      {applicableFor === 'gearFittingAccessories' && (
                        <FormGroup>
                          <Label htmlFor="applicableFittingAccessories">
                            Applicable Fitting Accessories <span>*</span>
                          </Label>
                          <Select
                            isMulti
                            id="applicableFittingAccessories"
                            name="applicableFittingAccessories"
                            options={applicableFittingAccessoriesOptionsList}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) =>
                              handleApplicableForFittingAccessories(e)
                            }
                            value={selectedFittingAccessories}
                            // className="basic-multi-select"
                            // classNamePrefix="select"
                          />
                          <Errors
                            current_key="applicableFittingAccessories"
                            key="applicableFittingAccessories"
                          />
                        </FormGroup>
                      )}
                      <Col sm={6} md={4}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="radio"
                              name="applicableFor"
                              value="shipping"
                              checked={applicableFor === 'shipping'}
                              onChange={(e) => onChange(e)}
                            />{' '}
                            Shipping
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Errors current_key="applicableFor" key="applicableFor" />
                  </FormGroup>

                  <Col sm="5">
                    <FormGroup>
                      <Label htmlFor="usage_limit">
                        Usage limit <span>*</span>
                      </Label>
                      <Input
                        type="number"
                        id="usage_limit"
                        name="usage_limit"
                        min={0}
                        value={usage_limit}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.usage_limit ? true : false}
                      />
                      <Errors current_key="usage_limit" key="usage_limit" />
                    </FormGroup>
                  </Col>
                  <Col sm="1">
                    <FormGroup>
                      <Label htmlFor="singleUse">Single Use {'  '}</Label>
                      {/* <Input
                          type="number"
                          id="limit_per_user"
                          name="limit_per_user"
                          min={1}
                          value={limit_per_user}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.limit_per_user ? true : false}
                        /> */}
                      <Input
                        type="checkbox"
                        id="singleUse"
                        name="singleUse"
                        value={singleUse}
                        checked={singleUse}
                        onChange={(e) => onChangeSingleUse(e)}
                        style={{
                          display: 'block',
                          height: '30px',
                          width: '30px',
                          margin: '2px 0px 0px 0px',
                        }}
                      />
                      <Errors current_key="singleUse" key="singleUse" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="expiry_date">Expiry date</Label>
                      <Input
                        type="date"
                        id="expiry_date"
                        name="expiry_date"
                        value={expiry_date}
                        min={disablePastDate()}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.expiry_date ? true : false}
                      />
                      <Errors current_key="expiry_date" key="expiry_date" />
                    </FormGroup>
                  </Col>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="comment_text">Comment</Label>
                      <Input
                        type="textarea"
                        id="comment_text"
                        name="comment_text"
                        value={comment_text}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.comment_text ? true : false}
                      />
                      <Errors current_key="comment_text" key="comment_text" />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button
                  type="submit"
                  size="sm"
                  color="primary"
                  disabled={loading}
                >
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
                <Link to="/admin/coupons">
                  <Button type="reset" size="sm" color="danger">
                    <i className="fa fa-ban"></i> Cancel
                  </Button>
                </Link>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditCoupon.propTypes = {
  edit: PropTypes.func.isRequired,
  cancelSave: PropTypes.func.isRequired,
  getCouponById: PropTypes.func.isRequired,
  // coupon: PropTypes.object.isRequired,
  loading: PropTypes.bool.loading,
  currentCoupon: PropTypes.object.currentCoupon,
  errorList: PropTypes.array.isRequired,
  getUserSpecificOptionList: PropTypes.func.isRequired,
  userSpecificOptionsList: PropTypes.array.isRequired,
  userSpecificOptionsListLoading: PropTypes.bool.isRequired,
  getCategoriesOptionList: PropTypes.func.isRequired,
  applicableCategoriesOptionsList: PropTypes.array.isRequired,
  getProductsOptionList: PropTypes.func.isRequired,
  applicableProductsOptionsList: PropTypes.array.isRequired,
  getSkuOptionList: PropTypes.func.isRequired,
  applicableSkuOptionsList: PropTypes.array.isRequired,
  getFittingAccessoriesOptionList: PropTypes.func.isRequired,
  applicableFittingAccessoriesOptionsList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  errorList: state.errors,
  // coupon: state.coupon,
  currentCoupon: state.coupon.currentCoupon,
  loading: state.coupon.loading,
  userSpecificOptionsListLoading: state.coupon.userSpecificOptionsListLoading,
  userSpecificOptionsList: state.coupon.userSpecificOptionsList,
  applicableCategoriesOptionsList: state.coupon.applicableCategoriesOptionsList,
  applicableProductsOptionsList: state.coupon.applicableProductsOptionsList,
  applicableSkuOptionsList: state.coupon.applicableSkuOptionsList,
  applicableFittingAccessoriesOptionsList:
    state.coupon.applicableFittingAccessoriesOptionsList,
});

export default connect(mapStateToProps, {
  edit,
  getCouponById,
  getUserSpecificOptionList,
  getCategoriesOptionList,
  getProductsOptionList,
  getSkuOptionList,
  getFittingAccessoriesOptionList,
})(EditCoupon);
