import {
  EMAIL_TEMPLATE_LOADING,
  EMAIL_TEMPLATE_CREATED,
  EMIAL_TEMPLATE_CREATE_ERROR,
  EMAIL_TEMPLATE_FAIL,
  EMAIL_TEMPLATE_UPDATED,
  EMIAL_TEMPLATE_UPDATE_ERROR,
  EMAIL_TEMPLATE_DELETED,
  EMAIL_TEMPLATE_DELETE_ERROR,
  EMAIL_TEMPLATE_LIST_UPDATED,
  GET_EMAIL_TEMPLATE_BY_ID,
  EMAIL_TEMPLATE_CHANGE_STATUS,
  EMAIL_TEMPLATE_CHANGE_STATUS_ERROR,
  ANNOUNCEMENT_LIST_LOADING,
  ANNOUNCEMENT_LIST_UPDATED,
  ANNOUNCEMENT_LIST_FAIL
} from "actions/types";

const initalState = {
  emailTemplateList: {
    page: 1,
    data: [],
    count: 0,
  },
  announcementsList: {
    page: 1,
    data: [],
    count: 0
  },
  currentEmailTemplate: {},
  loading: false,
  isSubLoading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case EMAIL_TEMPLATE_LOADING:
      return {
        ...state,
        emailTemplateList: payload.is_sub_element
          ? state.emailTemplateList
          : initalState.emailTemplateList,
        currentEmailTemplate: payload.is_sub_element
          ? state.currentEmailTemplate
          : initalState.currentEmailTemplate,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case ANNOUNCEMENT_LIST_LOADING:
      return {
        ...state,
        announcementsList: payload.is_sub_element
          ? state.announcementsList
          : initalState.announcementsList,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case EMAIL_TEMPLATE_CREATED:
      return {
        ...state,
        loading: false,
      };
    case EMIAL_TEMPLATE_CREATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case EMAIL_TEMPLATE_FAIL:
    case ANNOUNCEMENT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case EMAIL_TEMPLATE_UPDATED:
      return {
        ...state,
        currentEmailTemplate: {},
        loading: false,
      };
    case EMIAL_TEMPLATE_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case EMAIL_TEMPLATE_DELETED:
      return {
        ...state,
        loading: false,
      };
    case EMAIL_TEMPLATE_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case EMAIL_TEMPLATE_LIST_UPDATED:
      return {
        ...state,
        emailTemplateList: {
          data: payload.data.emailTemplateList.data,
          page: payload.data.emailTemplateList.metadata[0].current_page,
          count: payload.data.emailTemplateList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case ANNOUNCEMENT_LIST_UPDATED:
      return {
        ...state,
        announcementsList: {
          data: payload.data.announcementsList.data,
          page: payload.data.announcementsList.metadata[0].current_page,
          count: payload.data.announcementsList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_EMAIL_TEMPLATE_BY_ID:
      return {
        ...state,
        currentEmailTemplate: payload.data,
        loading: false,
      };
    case EMAIL_TEMPLATE_CHANGE_STATUS:
      return {
        ...state,
        emailTemplateList: {
          ...state.emailTemplateList,
          data: state.emailTemplateList.data.map((template) =>
            template._id === payload.data._id
              ? { ...template, status: payload.data.status }
              : template
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case EMAIL_TEMPLATE_CHANGE_STATUS_ERROR:
      return {
        ...state,
        emailTemplateList: {
          ...state.emailTemplateList,
          data: state.emailTemplateList.data.map((template) =>
            template._id === payload.data
              ? { ...template, is_failed: true }
              : { ...template, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };

    default:
      return state;
  }
}
