import React, { useState, useEffect } from 'react';
import PropTypes, { object } from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { CKEditor } from 'ckeditor4-react';
import slugify from 'react-slugify';
import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';

import { getResourceById, create, edit } from 'actions/admin/resource';

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import { CKEditorConfig } from 'constants';

const AddEditResource = ({
    create,
    history,
    errorList,
    loading,
    getResourceById,
    edit,
    resource: { currentResource },
}) => {
    let navigate = useNavigate();
    /**************** use state for form data *********************/
    const [formData, setFormData] = useState({
        meta_title: '',
        meta_description: '',
        title: '',
        slug: '',
        subtitle: '',
        selectedFile: [],
        image: [],
        deleteFile: [],
        videoUrl: '',
        selectedVideoFile: '',
        deletedVideoFile: '',
        description: '',
    });
    const [descriptionData, setDescriptionData] = useState('');
    const [baseUrl, setBaseUrl] = useState([]);
    const [imagePreview, setImagePreview] = useState([]);

    const { resource_id: resource_id } = useParams();
    useEffect(() => {
        if (resource_id) getResourceById(resource_id);
    }, [resource_id]);

    /**************** expload form data *********************/
    const {
        meta_title,
        meta_description,
        title,
        slug,
        subtitle,
        image,
        videoUrl,
        selectedVideoFile,
        deletedVideoFile,
        deleteFile,
        selectedFile,
        description,
    } = formData;

    useEffect(() => {
        if (Object.keys(currentResource).length > 0 && resource_id) {
            setFormData({ ...currentResource, image: currentResource.images, selectedFile: currentResource.images, deletedVideoFile: '' });
            setBaseUrl(currentResource.images);
            setImagePreview(currentResource.images);
        }
    }, [currentResource]);

    // handle input change
    const handleResourceChange = (e) => {
        if (e.target.name === 'title') {
            let new_slug = slugify(e.target.value);
            setFormData({
                ...formData,
                [e.target.name]: e.target.value.trim(),
                slug: new_slug.trim(),
            });
        }
        if (e?.target?.name === 'thumbnail') {
            let file = e.target.files[0];
            const allowedFormats = [
                "image/png",
                "image/jpeg",
                "image/gif",
                "image/bmp",
                "image/tiff",
                "image/webp",
                "image/svg+xml",
                "image/x-icon",
                "application/pdf",
                "text/csv",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
                "application/msword", // .doc
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
                "application/vnd.oasis.opendocument.text", // .odt
            ];

            if (!allowedFormats.includes(file.type)) {
                // Display an error message to the user
                alert("Unsupported file format. Please select a valid file.");
                // Clear the file input
                e.target.value = null;
                return;
            }

            const prevImag = URL.createObjectURL(file);
            setImagePreview((data) => ({
                ...data, [Object.keys(imagePreview).length]: prevImag
            }))

            const reader = new FileReader();
            reader.onloadend = () => {
                // Use a regex to remove data url part
                const base64String = reader.result;
                setBaseUrl((data1) => ({
                    ...data1,
                    [Object.keys(baseUrl).length]: base64String
                }));

                setFormData((data1) => ({
                    ...data1,
                    selectedFile: baseUrl, image: imagePreview,
                }));
            };
            reader.readAsDataURL(file);
            e.target.value = '';

        } else if (e.target.name === 'videoUrl') {
            let video_file = e.target.files[0];

            let video_url = URL.createObjectURL(video_file);

            const reader = new FileReader();
            reader.readAsDataURL(video_file);
            reader.onload = () => {
                // Use a regex to remove data url part
                const base64String = reader.result;
                setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                    selectedVideoFile: base64String,
                    videoUrl: video_url,
                    deletedVideoFile: formData.videoUrl
                });
            };
            document.getElementById(`thumbnail`).value = '';
        } else {
            setFormData((data1) => ({
                ...data1,
                [e?.target?.name]: e?.target?.value.trimLeft()
            }));
        }
    };

    const removeVideoFile = (e) => {
        e.preventDefault();
        setFormData({ ...formData, selectedVideoFile: "", videoUrl: "", deletedVideoFile: !formData?.videoUrl?.includes('blob') ? formData.videoUrl : "" });
    };

    useEffect(() => {
        setFormData((data1) => ({
            ...data1, image: imagePreview, selectedFile: baseUrl
        }))
    }, [imagePreview, baseUrl]);

    const handelDescriptionChange = (event) => {
        setDescriptionData(event.editor.getData());
    };
    let deleteImage = [];
    //remove file
    let removeFile = (index) => (e) => {

        let all_files = Object.values(formData.image);
        let all_selected = Object.values(formData.selectedFile);


        // setDeletedImage(all_selected);
        all_files = all_files.filter(file => file._id !== e?.currentTarget?.getAttribute('data-id'));

        if (all_selected[index]?.original == undefined) {
            all_files = all_files.filter(data => data !== all_files[index]);
            all_selected = all_selected.filter(data => data !== all_selected[index]);
        } else {
            const sdeleteImage = all_selected.find(file => file._id === e?.currentTarget?.getAttribute('data-id'));
            deleteImage.push(sdeleteImage)
            all_selected = all_selected.filter(file => file._id !== e?.currentTarget?.getAttribute('data-id'));
        }
        setImagePreview(all_files)
        setBaseUrl(all_selected)

        setFormData((data1) => ({
            ...data1,
            image: all_files, selectedFile: all_selected, deleteFile: deleteImage
        }));
    };

    useEffect(() => {
        formData.description = descriptionData;
    }, [descriptionData])

    /**************** on submit event *********************/
    const onSubmit = (e) => {
        e.preventDefault();
        // let params = new window.FormData();
        // for (let i = 0; i < Object.values(formData.selectedFile).length; i += 1) {
        //     if (!Object.keys(Object.values(formData.selectedFile)[i]).includes("original")) {
        //         params.append('file', Object.values(formData.selectedFile)[i]);
        //     } else {
        //         params.append("file", JSON.stringify(Object.values(formData.selectedFile)[i]));
        //     }
        // }
        // params.append("meta_title", formData.meta_title);
        // params.append("meta_description", formData.meta_description);
        // params.append("title", formData.title);
        // params.append("slug", formData.slug);
        // params.append("subtitle", formData.subtitle);
        // params.append("videoUrl", formData.videoUrl);
        // params.append("selectedVideoFile", formData.selectedVideoFile);
        // params.append("description", formData.description);
        // params.append("deleteFile", JSON.stringify(formData.deleteFile));
        if (resource_id) {
            edit(formData, navigate, resource_id);
        } else {

            create(formData, navigate);
        }
    };

    return (
        <div className="animated fadeIn">
            {loading ? (
                <Spinner />
            ) : (
                <Row>
                    <Col xs="12" sm="12">
                        <Card>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => onSubmit(e)}
                                encType="multipart/form-data"
                            >
                                <CardBody>
                                    <div>
                                        <Card className="my-2">
                                            <CardHeader tag="h5">SEO Information</CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <Label htmlFor="meta_title">
                                                                Title
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                id="meta_title"
                                                                name="meta_title"
                                                                value={meta_title}
                                                                onChange={handleResourceChange}
                                                                invalid={errorList.meta_title ? true : false}
                                                            />
                                                            <Errors
                                                                current_key="meta_title"
                                                                key="meta_title"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <Label htmlFor="meta_description">
                                                                Meta Description
                                                            </Label>
                                                            <Input
                                                                type="textarea"
                                                                id="meta_description"
                                                                name="meta_description"
                                                                value={meta_description}
                                                                onChange={handleResourceChange}
                                                                invalid={errorList.meta_description ? true : false}
                                                            />
                                                            <Errors
                                                                current_key="meta_description"
                                                                key="meta_description"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <Card className="my-2">
                                        <CardHeader tag="h5">
                                            Resource Information
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="title">
                                                            Resource Title <span>*</span>
                                                        </Label>
                                                        <input
                                                            className="form-control"
                                                            name="title"
                                                            value={title}
                                                            maxLength={100}
                                                            onChange={e => handleResourceChange(e)}
                                                            invalid={errorList[`title`]}
                                                        />
                                                        <Errors current_key={`title`} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="slug">
                                                            Resource Slug <span>*</span>
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="slug"
                                                            name="slug"
                                                            maxLength="100"
                                                            value={slug}
                                                            required
                                                            readOnly
                                                            invalid={errorList.slug ? true : false}
                                                        />
                                                        <Errors current_key="slug" key="slug" />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="subtitle">
                                                            Resource Sub Title
                                                        </Label>
                                                        <input
                                                            className="ml10 form-control"
                                                            name="subtitle"
                                                            value={subtitle}
                                                            maxLength={100}
                                                            onChange={e => handleResourceChange(e)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                {/* <Col xs="6" className="justify-flex-end">
                                                    <FormGroup>
                                                        <Label htmlFor="thumbnail">
                                                            Video URL
                                                        </Label>
                                                        <Input
                                                            type="url"
                                                            id="videoUrl"
                                                            name="videoUrl"
                                                            onChange={(e) => handleResourceChange(e)}
                                                            value={videoUrl}
                                                            className="form-control"
                                                        />
                                                    </FormGroup>
                                                </Col> */}
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="videoUrl">
                                                            Upload Video (Video maximum size should be 500MB.)
                                                        </Label>
                                                        <Input
                                                            type="file"
                                                            accept="video/*"
                                                            id="videoUrl"
                                                            name="videoUrl"
                                                            onChange={(e) => handleResourceChange(e)}
                                                            invalid={errorList.videoUrl ? true : false}
                                                        />
                                                        {videoUrl !== '' &&
                                                            <div className="thumb">
                                                                <video
                                                                    controls={true}
                                                                    src={videoUrl}
                                                                    className="preview-video"
                                                                    alt=""
                                                                />
                                                                <Button
                                                                    title="Remove Video"
                                                                    className="btn btn-sm btn-danger"
                                                                    style={{ height: "30px", borderRadius: "20px", textAlign: "center", paddingTop: "3px" }}
                                                                    onClick={(e) => removeVideoFile(e)}
                                                                >
                                                                    <i className="fa fa-close"></i>
                                                                </Button>
                                                            </div>
                                                        }
                                                        <Errors current_key="videoUrl" key="videoUrl" />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="thumbnail">
                                                            Files Upload
                                                        </Label>
                                                        <Input
                                                            type="file"
                                                            accept="image/*, .pdf, .csv, .docx, .doc, .xlsx, .odt"
                                                            id="thumbnail"
                                                            name="thumbnail"
                                                            onChange={e => handleResourceChange(e)}
                                                        />
                                                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                            {formData.image != undefined && image &&
                                                                Object.values(image).map(
                                                                    (imageItems, index) => {
                                                                        return (
                                                                            <div className="thumb" key={index}>
                                                                                <Col key={`img-${formData.image}`}>
                                                                                    {imageItems && imageItems?.original ? (
                                                                                        <iframe
                                                                                            src={imageItems?.original ? imageItems?.original : imageItems}
                                                                                        // className="preview-img"
                                                                                        // alt="resource file"
                                                                                        // style={{ height: "150px", width: "100px" }}
                                                                                        />
                                                                                        // <p style={{ marginRight: "5px" }}>{imageItems.path ? imageItems.path.replace(/resources-\d{13}/, "") : imageItems}</p>
                                                                                    ) : null}
                                                                                    {imageItems && !imageItems?.original &&
                                                                                        <>
                                                                                            <iframe src={imageItems} />
                                                                                        </>
                                                                                    }
                                                                                </Col>
                                                                                <Button
                                                                                    title="Remove Image"
                                                                                    className="btn btn-sm btn-danger"
                                                                                    data-id={imageItems._id}
                                                                                    onClick={removeFile(index)}
                                                                                >
                                                                                    <i className="fa fa-close"></i>
                                                                                </Button>
                                                                            </div>
                                                                        )
                                                                    })}

                                                        </div>
                                                        {selectedFile && <Errors current_key={`selectedFile`} key="selectedFile" />}
                                                    </FormGroup>
                                                </Col>
                                                {resource_id ?
                                                    <Col sm="12">
                                                        <FormGroup>
                                                            <Label htmlFor="description">
                                                                Description
                                                            </Label>
                                                            {description &&
                                                                <CKEditor
                                                                    initData={description}
                                                                    id="description"
                                                                    name="description"
                                                                    config={CKEditorConfig}
                                                                    onChange={e => handelDescriptionChange(e)}
                                                                />
                                                            }
                                                            {description == '' &&
                                                                <CKEditor
                                                                    initData={description}
                                                                    id="description"
                                                                    name="description"
                                                                    config={CKEditorConfig}
                                                                    onChange={e => handelDescriptionChange(e)}
                                                                />}
                                                        </FormGroup>
                                                    </Col>
                                                    :
                                                    <Col sm="12">
                                                        <FormGroup>
                                                            <Label htmlFor="description">
                                                                Description
                                                            </Label>
                                                            <CKEditor
                                                                initData={description}
                                                                id="description"
                                                                name="description"
                                                                config={CKEditorConfig}
                                                                onChange={e => handelDescriptionChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                }
                                            </Row>
                                        </CardBody>
                                        <CardFooter>
                                            <Button type="submit" size="sm" color="primary" disabled={loading}>
                                                <i className="fa fa-dot-circle-o"></i> Submit
                                            </Button>
                                            <Link to="/admin/resources">
                                                <Button type="reset" size="sm" color="danger">
                                                    <i className="fa fa-ban"></i> Cancel
                                                </Button>
                                            </Link>
                                        </CardFooter>
                                    </Card>
                                </CardBody>
                            </Form>
                        </Card>
                    </Col >
                </Row >
            )}
        </div >
    );
};

AddEditResource.propTypes = {
    getResourceById: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    resources: PropTypes.object.isRequired,
    create: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
    resource: state.resource,
    errorList: state.errors,
    loading: state.resource.loading,
});

export default connect(mapStateToProps, {
    create,
    getResourceById,
    edit,
})(AddEditResource);
