import {
  SUB_CATEGORY_MODAL_LOADING,
  SUB_CATEGORY_CREATED,
  SUB_CATEGORY_CREATE_ERROR,
  SUB_CATEGORY_FAIL,
  SUB_CATEGORY_LOADING,
  SUB_CATEGORY_UPDATED,
  SUB_CATEGORY_UPDATE_ERROR,
  SUB_CATEGORY_DELETED,
  SUB_CATEGORY_DELETE_ERROR,
  SUB_CATEGORY_LIST_UPDATED,
  GET_SUB_CATEGORY_BY_ID,
  SUB_CATEGORY_CHANGE_STATUS,
  SUB_CATEGORY_CHANGE_STATUS_ERROR,
} from "actions/types";

const initalState = {
  subCategoryList: {
    page: 1,
    data: [],
    count: 0,
    parentName: "",
  },
  currentSubCategory: {},
  loading: false,
  isSubLoading: false,
  error: {},

  modal_loading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case SUB_CATEGORY_MODAL_LOADING:
      return {
        ...state,
        modal_loading: true,
      };
    case SUB_CATEGORY_CREATED:
      return {
        ...state,
        subCategoryList: {
          data: payload.data.childrens,
        },
        modal_loading: false,
      };
    case SUB_CATEGORY_CREATE_ERROR:
      return {
        ...state,
        modal_loading: false,
      };
    case SUB_CATEGORY_FAIL:
      return {
        ...state,
        modal_loading: false,
        loading: false,
        isSubLoading: false,
      };
    case SUB_CATEGORY_LOADING:
      return {
        ...state,
        subCategoryList: payload.is_sub_element
          ? state.subCategoryList
          : initalState.subCategoryList,
        currentSubCategory: payload.is_sub_element
          ? state.currentSubCategory
          : initalState.currentSubCategory,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case SUB_CATEGORY_UPDATED:
      return {
        ...state,
        currentSubCategory: {},
        loading: false,
      };
    case SUB_CATEGORY_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SUB_CATEGORY_DELETED:
      return {
        ...state,
        loading: false,
      };
    case SUB_CATEGORY_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SUB_CATEGORY_LIST_UPDATED:
      return {
        ...state,
        subCategoryList: {
          data: payload.data.subCategoryList.data,
          parentName:
            payload.data.subCategoryList.data.length > 0
              ? payload.data.subCategoryList.data[0].parent_name
              : payload.data.subCategoryList.parent_name,
          page: payload.data.subCategoryList.metadata[0].current_page,
          count: payload.data.subCategoryList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_SUB_CATEGORY_BY_ID:
      return {
        ...state,
        currentSubCategory: payload.data,
        loading: false,
      };
    case SUB_CATEGORY_CHANGE_STATUS:
      return {
        ...state,
        subCategoryList: {
          ...state.subCategoryList,
          data: state.subCategoryList.data.map((subcategory) =>
            subcategory._id === payload.data._id
              ? { ...subcategory, status: payload.data.status }
              : subcategory
          ),
          parentName: payload.data.parent_name,
        },
        loading: false,
        isSubLoading: false,
      };
    case SUB_CATEGORY_CHANGE_STATUS_ERROR:
      return {
        ...state,
        subCategoryList: {
          ...state.subCategoryList,
          data: state.subCategoryList.data.map((subcategory) =>
            subcategory._id === payload.data
              ? { ...subcategory, is_failed: true }
              : { ...subcategory, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };

    default:
      return state;
  }
}
