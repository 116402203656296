import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Errors from 'views/Notifications/Errors';
import Spinner from 'views/ButtonSpinner';
import { AddFleetRemark } from 'actions/admin/shippingBox';

import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    ModalFooter,
    Modal,
    ModalBody,
    ModalHeader,
} from 'reactstrap';


const MarkAsFleetShipping = ({ AddFleetRemark, isOpen, isClose, boxData, setFleetModalRes, errorList, modal_loading }) => {
    const initialState = {
        remarks: '',
        boxId: '',
        fleet_receipt: '',
        fleet_receipt_url: '',
    };
    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        setFormData({
            ...formData,
            remarks: boxData?.remarks,
            boxId: boxData?._id,
            fleet_receipt_url: boxData?.fleet_receipt
        })
    }, [boxData])

    const resetModal = () => {
    };
    const onChange = (e) => {
        const { name, value } = e.target
        if (name === "fleet_receipt") {
            const image_file = e.target.files[0];
            setFormData({ ...formData, [name]: image_file });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    const toggle = useCallback(() => {
        isClose(!isOpen)
        setFormData(initialState)
    })
    const onSubmit = (e) => {
        e.preventDefault();
        formData.boxId = boxData?._id;
        let params = new window.FormData()
        params.append("remarks", formData.remarks);
        params.append("boxId", formData.boxId);
        params.append("fleet_receipt", formData.fleet_receipt);
        AddFleetRemark(params).then((res) => {
            if (res.status === true) {
                toggle();
                setFleetModalRes(true)
            }
        });
    };


    return (
        <div className="animated fadeIn">
            <Modal isOpen={isOpen} toggle={toggle} onClosed={resetModal}>
                <ModalHeader toggle={toggle}>Freight Shipment</ModalHeader>
                <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
                    <ModalBody>
                        <Row>
                            <Col xs="12" sm="12">
                                <FormGroup>
                                    <Label htmlFor="name">
                                        Remarks <span>*</span>
                                    </Label>
                                    <Input
                                        type="textarea"
                                        id="remarks"
                                        name="remarks"
                                        value={formData.remarks}
                                        placeholder={'Enter remarks'}
                                        required
                                        onChange={(e) => onChange(e)}
                                    />
                                    <Errors current_key="remarks" key="remarks" />
                                </FormGroup>
                            </Col>
                            <Col xs="12" sm="12">
                                <FormGroup>
                                    <Label htmlFor="fleet_receipt">
                                        Freight Receipt
                                    </Label>
                                    <Input
                                        type="file"
                                        id="fleet_receipt"
                                        name="fleet_receipt"
                                        // value={""}
                                        placeholder={'Select Freight Receipt'}
                                        onChange={(e) => onChange(e)}
                                        accept="image/*,application/pdf"
                                    />
                                    <Errors current_key="fleet_receipt" key="fleet_receipt" />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <div className="modalFooter Shippingmodalfooter">
                            {modal_loading ? (
                                <Spinner />
                            ) : (<Button
                                className="mr-05"
                                type="submit"
                                size="sm"
                                color="primary"
                                disabled={modal_loading}
                            >
                                <i className="fa fa-dot-circle-o"></i> Submit
                            </Button>)}
                            <Button disabled={modal_loading} type="reset" size="sm" color="danger" onClick={toggle}>
                                <i className="fa fa-ban"></i> Cancel
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    )
}

MarkAsFleetShipping.propTypes = {
    AddFleetRemark: PropTypes.func.isRequired,
    modal_loading: PropTypes.bool.isRequired,
    errorList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    errorList: state.errors,
    modal_loading: state.shippingBox.modal_loading,
});
export default connect(mapStateToProps, { AddFleetRemark })(MarkAsFleetShipping);