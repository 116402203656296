import axios from 'axios';

import {
  SMS_TEMPLATE_LOADING,
  SMS_TEMPLATE_CREATED,
  SMS_TEMPLATE_CREATE_ERROR,
  SMS_TEMPLATE_FAIL,
  SMS_TEMPLATE_UPDATED,
  SMS_TEMPLATE_UPDATE_ERROR,
  SMS_TEMPLATE_DELETED,
  SMS_TEMPLATE_DELETE_ERROR,
  SMS_TEMPLATE_LIST_UPDATED,
  GET_SMS_TEMPLATE_BY_ID,
  SMS_TEMPLATE_CHANGE_STATUS,
  SMS_TEMPLATE_CHANGE_STATUS_ERROR,
} from 'actions/types';

// Create Sms Template
export const create = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: SMS_TEMPLATE_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      '/api/admin/smsTemplate/create',
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: SMS_TEMPLATE_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      history('/admin/sms-templates');
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: SMS_TEMPLATE_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: SMS_TEMPLATE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit Sms Template
export const edit =
  (formData, history, sms_template_id) => async (dispatch) => {
    try {
      dispatch({
        type: SMS_TEMPLATE_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `/api/admin/smsTemplate/${sms_template_id}`,
        formData,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: SMS_TEMPLATE_UPDATED,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
        history('/admin/sms-templates');
      } else {
        const errors = res.data.errors;
        let errorsList = [];
        if (errors) {
          await errors.forEach((error) => {
            errorsList[error.param] = error.msg;
          });
        }
        dispatch({
          type: SMS_TEMPLATE_UPDATE_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
            errorsList,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: SMS_TEMPLATE_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

// Delete Sms Template
export const deleteSmsTemplate =
  (sms_template_id, history) => async (dispatch) => {
    try {
      dispatch({
        type: SMS_TEMPLATE_LOADING,
        payload: { is_loading: true, is_sub_element: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.delete(
        `/api/admin/smsTemplate/${sms_template_id}`,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: SMS_TEMPLATE_DELETED,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
        return true;
      } else {
        dispatch({
          type: SMS_TEMPLATE_DELETE_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
          },
        });
        return false;
      }
    } catch (err) {
      dispatch({
        type: SMS_TEMPLATE_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

//get Sms Template list

export const getSmsTemplateList =
  (smsTemplateParams) => async (dispatch) => {
    try {
      dispatch({
        type: SMS_TEMPLATE_LOADING,
        payload: { is_loading: true, is_sub_element: false },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const query = smsTemplateParams.query
        ? smsTemplateParams.query
        : '&query=';
      
      const res = await axios.get(
        `/api/admin/smsTemplate?limit=${smsTemplateParams.limit}&page=${smsTemplateParams.page}&orderBy=${smsTemplateParams.orderBy}&ascending=${smsTemplateParams.ascending}${query}`,
        config
      );
      

      dispatch({
        type: SMS_TEMPLATE_LIST_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: {
            smsTemplateParams: smsTemplateParams,
            smsTemplateList: res.data.response[0],
          },
        },
      });
    } catch (err) {
      dispatch({
        type: SMS_TEMPLATE_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

// Get sms template by id
export const getSmsTemplateById = (sms_template_id) => async (dispatch) => {
  try {
    dispatch({
      type: SMS_TEMPLATE_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.get(
      `/api/admin/smsTemplate/${sms_template_id}`,
      config
    );
    await dispatch({
      type: GET_SMS_TEMPLATE_BY_ID,
      payload: {
        message: res.data.message,
        alertType: 'success',
        data: res.data.response,
      },
    });
  } catch (err) {
    dispatch({
      type: SMS_TEMPLATE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

//change status
export const changeStatus = (sms_template_id, status) => async (dispatch) => {
  try {
    dispatch({
      type: SMS_TEMPLATE_LOADING,
      payload: { is_loading: true, is_sub_element: true },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/smsTemplate/change-status/${sms_template_id}`,
      { status },
      config
    );
    
    if (res.data.status === true) {
      await dispatch({
        type: SMS_TEMPLATE_CHANGE_STATUS,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
    } else {
      //add a key is_failed=true else is_failed=false
      dispatch({
        type: SMS_TEMPLATE_CHANGE_STATUS_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          data: sms_template_id,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: SMS_TEMPLATE_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};
