import {
  EMAIL_TEMPLATE_CATEGORY_LOADING,
  EMAIL_TEMPLATE_CATEGORY_CREATED,
  EMIAL_TEMPLATE_CATEGORY_CREATE_ERROR,
  EMAIL_TEMPLATE_CATEGORY_FAIL,
  EMAIL_TEMPLATE_CATEGORY_UPDATED,
  EMIAL_TEMPLATE_CATEGORY_UPDATE_ERROR,
  EMAIL_TEMPLATE_CATEGORY_DELETED,
  EMAIL_TEMPLATE_CATEGORY_DELETE_ERROR,
  EMAIL_TEMPLATE_CATEGORY_LIST_UPDATED,
  GET_EMAIL_TEMPLATE_CATEGORY_BY_ID,
} from 'actions/types';

const initalState = {
  emailTemplateCategoryList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentEmailTemplateCategory: {},
  loading: false,
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case EMAIL_TEMPLATE_CATEGORY_LOADING:
      return {
        ...state,
        loading: payload.is_loading ? payload.is_loading : false,
      };
    case EMAIL_TEMPLATE_CATEGORY_CREATED:
    case EMIAL_TEMPLATE_CATEGORY_CREATE_ERROR:
    case EMIAL_TEMPLATE_CATEGORY_UPDATE_ERROR:
    case EMAIL_TEMPLATE_CATEGORY_DELETED:
    case EMAIL_TEMPLATE_CATEGORY_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case EMAIL_TEMPLATE_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        isSubLoading: false,
      };
    case EMAIL_TEMPLATE_CATEGORY_UPDATED:
      return {
        ...state,
        currentEmailTemplateCategory: {},
        loading: false,
      };
    case EMAIL_TEMPLATE_CATEGORY_LIST_UPDATED:
      return {
        ...state,
        emailTemplateCategoryList: {
          data: payload.data.emailTemplateCategoryList.data,
          page: payload.data.emailTemplateCategoryList.metadata[0].current_page,
          count: payload.data.emailTemplateCategoryList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case GET_EMAIL_TEMPLATE_CATEGORY_BY_ID:
      return {
        ...state,
        currentEmailTemplateCategory: payload.data,
        loading: false,
      };
    default:
      return state;
  }
}
