import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import * as Constants from 'constants/index';
import Loading from '../../../../utils/Loading';
import { useSearchParams } from 'react-router-dom';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  Label,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from 'reactstrap';

import {
  getAttributes,
  saveVariation,
  removeVariation,
  setWholesaleError,
  setSaleError,
  markDefault,
  getAllVariations,
  addNewOption,
} from 'actions/admin/GearProduct/gearProduct';
import { getGearShippingBoxList } from 'actions/admin/gearShippingBox';
import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';

import { ALLOW_BACK_ORDERS, ATTRIBUTE_WEIGHT_UNIT } from 'constants/index';
import { removeErrors } from 'actions/admin/removeErrors';

const GearProductVariations = ({
  product_id,
  loading,
  attributes_loading,
  attributes,
  fitting_specs,
  basic_specs,
  getAttributes,
  gearAttributesValueList,
  removeVariation,
  saveVariation,
  markDefault,
  getAllVariations,
  getGearShippingBoxList,
  gearShippingBoxList,
  gearProductAllVariations,
  errorList,
  addNewOption,
  openvariation,
  removeErrors
}) => {
  const [attributesValueListData, setAttributesValueListData] = useState([]);
  useEffect(() => {
    setAttributesValueListData(
      gearAttributesValueList?.attributeList ?? gearAttributesValueList
    );
    /* basic_specs = basic_specs.sort((a, b) => (a.created_at > b.created_at) ? 1 : -1);
        fitting_specs = fitting_specs.sort((a, b) => (a.created_at > b.created_at) ? 1 : -1); */
  }, [gearAttributesValueList]);

  //########################## set already exist attributes list in form data ############################
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [showForm, setShowForm] = useState(false);

  //########### handel onChange of options ########################

  const [selectedVariation, setSelectedVariation] = useState({});

  const [selectedFittingSpec, setSelectedFittingSpec] = useState({});
  const [selectedTechnicalSpec, setSelectedTechnicalSpec] = useState({});

  //################ View Variations Details ############################
  const [viewDetailModal, setViewDetailModal] = useState(false);
  const [modalDetails, setModalDetails] = useState();
  const [addVariationModal, setAddVariationModal] = useState(false);
  const [isSpecModal, setIsSpecModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const [isClone, setIsClone] = useState(false);

  const [attributeMessage, setAttributeMessgae] = useState('');
  const [gearShippingBox, setGearShippingBox] = useState([]);

  const [allVariations, setAllVariations] = useState([]);
  let selectedShippingBox = {};
  useEffect(() => {
    if (
      allVariations.length > 0 &&
      searchParams.get('variation') &&
      firstLoad
    ) {
      const sku = searchParams.get('variation');
      const jumpVariation = allVariations.find((vari) => vari.sku === sku);
      editVariation({ details: jumpVariation });
      setFirstLoad(false);
    }
  }, [allVariations]);

  useEffect(() => {
    if (columns.length <= 4) {
      {
        selectedAttributes.sort((a, b) =>
          a.attributeId.name === 'Color' ? 1 : -1
        );
        Object.keys(selectedAttributes).forEach((value, attrIndex, attrbt) =>
          setColumns((columns) => [
            {
              field: selectedAttributes[attrIndex].attributeId
                ? selectedAttributes[attrIndex].attributeId.name
                : '',
              suppressMovable: true,
              cellClass: 'suppress-movable-col',
              headerName: selectedAttributes[attrIndex].attributeId
                ? selectedAttributes[attrIndex].attributeId.name
                : '',
              width: 115,
              sortable: true,
              filter: true,
              filterParams: {
                suppressAndOrCondition: true,
              },
              cellRenderer: (params) => {
                return (
                  <div>
                    {
                      params.data?.[
                      selectedAttributes[attrIndex].attributeId.name
                      ]
                    }
                  </div>
                );
              },
            },
            ...columns,
          ])
        );
      }
    }
  }, [selectedAttributes]);

  const [columns, setColumns] = useState([
    {
      field: 'sku',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      headerName: 'SKU',
      width: 145,
      sortable: true,
      filter: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
    },
    {
      field: 'stock_quantity',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      headerName: 'QUANTITY',
      width: 150,
      sortable: true,
      filter: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => {
        return <div>{params.data.stock_quantity}</div>;
      },
    },
    {
      field: 'msrp_price',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      headerName: 'PRICE',
      suppressSizeToFit: false,
      width: 140,
      sortable: true,
      filter: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => {
        return <div>{params.data.msrp_price}</div>;
      },
    },
    {
      field: '_id',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      headerName: 'ACTIONS',
      width: 210,
      cellRenderer: (params) => {
        return (
          <div>
            <Button
              type="button"
              size="sm"
              color="info"
              title="Clone"
              data-index={params.data._id}
              onClick={(e) => onClickCloneVariation(e, params.data)}
            >
              <i className="fa fa-clone"></i>
            </Button>
            &nbsp;
            <Button
              type="button"
              size="sm"
              color="info"
              title="Details"
              onClick={(e) => onClickViewVariation(e, params.data)}
            >
              <i className="fa fa-eye"></i>
            </Button>
            &nbsp;
            <Button
              type="button"
              size="sm"
              color="success"
              title="Edit"
              onClick={(e) => editVariation({ e, details: params.data })}
            >
              <i className="fa fa-pencil"></i>
            </Button>
            &nbsp;
            <Button
              type="button"
              size="sm"
              color="danger"
              onClick={(e) => {
                if (params.data?.isDefault == 1) {
                  window.confirm(
                    `You can not delete the default variation. Please set another variation as the default.`
                  );
                } else {
                  if (
                    window.confirm(
                      `Are you sure, You want to delete this variation ?`
                    )
                  ) {
                    deleteProductVariation(e, params.data);
                  }
                }
              }}
            >
              <i className="fa fa-trash"></i>
            </Button>
            &nbsp;
            {params.data?.isDefault ? (
              <span title="Default Variation">
                <i
                  className="fa fa-check-square-o check-square-position"
                  aria-hidden="true"
                ></i>
              </span>
            ) : (
              <Button
                type="button"
                size="sm"
                color="success"
                title="Click to make default variation"
                className="btn btn-sm btn-success"
                onClick={(e) => {
                  if (
                    window.confirm(
                      `Are you sure, you want to set this variation as Default to show ?`
                    )
                  ) {
                    handleDefaultProduct(e, params.data);
                  }
                }}
              >
                <i className="fa fa-check"></i>
              </Button>
            )}
          </div>
        );
      },
    },
  ]);

  const onSelectShippingBox = (e) => {
    setVariationInventoryDetail({
      ...variationInventoryDetail,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(async () => {
    await getAttributes();
  }, [getAttributes]);


  useMemo(() => {
    if (attributes) {
      setSelectedAttributes(attributes);
      getGearShippingBoxList({ limit: 1000, page: 1, filter: {} }).then((res) => {
        setGearShippingBox(res.data.response[0].data);
        //console.log(res.data.response[0].data, "11111111111111111111")
      });
    }
  }, [attributes]);

  const get_option_name = (attribute_id, option_id) => {
    let attrDetail = attributesValueListData.filter((attr) =>
      attr._id.includes(attribute_id)
    );
    let termName = attrDetail[0].terms.filter((term) =>
      term._id.includes(option_id)
    );
    return termName[0] ? termName[0].option : '';
  };

  const get_fitting_option_name = (spec_id, option_id) => {
    let terms = '';
    fitting_specs.forEach((items, ind) => {
      items.spec_data.forEach((newItems, inx) => {
        newItems.data.forEach((item, indx) => {
          let attrDetail = item.terms.findIndex((attr) =>
            attr._id.includes(option_id)
          );
          const termName = attrDetail >= 0 ? item.terms[attrDetail] : '';
          if (termName) {
            terms = { term_name: termName.option, label: newItems.label };
          }
        });
      });
    });
    return terms;
  };

  const get_technical_option_name = (spec_id, option_id) => {
    let terms = '';
    basic_specs.forEach((items, ind) => {
      items.spec_data.forEach((newItems, inx) => {
        newItems.data.forEach((item, indx) => {
          let attrDetail = item.terms.findIndex((attr) =>
            attr._id.includes(option_id)
          );

          const termName = attrDetail >= 0 ? item.terms[attrDetail] : '';
          if (termName) {
            terms = { term_name: termName.option, label: newItems.label };
          }
        });
      });
    });
    return terms;
  };
  let initialFormData = {
    sku: '',
    variation_id: '',
    stock_quantity: 0,
    msrp_price: 0,
    wholesale_price: 0,
    sale_price: 0,
    allow_backorders: 1,
    weight: 0,
    // height: 0,
    // width: 0,
    // product_length: 0,
    shippingBoxId: '',
    is_external: false,
    external_link: '',
  };

  const [variationInventoryDetail, setVariationInventoryDetail] =
    useState(initialFormData);

  useEffect(() => {
    let variationIsFound = false;

    if (Object.keys(allVariations).length > 0) {
      allVariations.forEach((variationDetail) => {
        let variationCount = variationDetail.variation.length;
        let totalCount = 0;
        variationDetail.variation.forEach((val) => {
          if (
            selectedVariation[val.attribute_id] &&
            selectedVariation[val.attribute_id].option_id === val.option_id
          )
            totalCount += 1;
        });
        if (totalCount === variationCount) {
          variationIsFound = true;
          //if variation matches set it

          setFittingData(variationDetail);
          let newDetails = {
            sku: variationDetail.sku,
            stock_quantity: variationDetail.stock_quantity,
            msrp_price: variationDetail.msrp_price,
            wholesale_price: variationDetail.wholesale_price,
            sale_price: variationDetail.sale_price,
            weight: variationDetail.weight,
            // height: variationDetail.height,
            // width: variationDetail.width,
            // product_length: variationDetail.product_length,
            shippingBoxId: variationDetail?.shippingBoxId,
            allow_backorders: variationDetail.allow_backorders == '2' ? 2 : 1,
            is_external: variationDetail?.is_external
              ? variationDetail?.is_external
              : false,
            external_link: variationDetail?.external_link
              ? variationDetail?.external_link
              : '',
          };
          if (variationDetail._id !== variationInventoryDetail.variation_id) {
            console.log(
              variationDetail._id,
              variationInventoryDetail.variation_id
            );
            setAttributeMessgae(
              'Variation already exist with selected combination '
            );
          } else {
            setAttributeMessgae('');
          }
          setVariationInventoryDetail({
            ...variationInventoryDetail,
            ...newDetails,
          });
        }
      });

      if (!variationIsFound) {
        setAttributeMessgae('');
      }
    }
  }, [selectedVariation]);

  const setFittingData = (variationDetail) => {
    let addNewFitting = {};

    variationDetail?.fitting_specs?.map((item) => {
      addNewFitting[item.spec_id] = {
        spec_name: item.spec_name,
        spec_id: item.spec_id,
        option_id: item.option_id,
      };
    });

    setSelectedFittingSpec(addNewFitting);

    let addNewTechFitting = {};

    variationDetail?.technical_specs?.map((item) => {
      addNewTechFitting[item.spec_id] = {
        spec_name: item.spec_name,
        spec_id: item.spec_id,
        option_id: item.option_id,
      };
    });
    setSelectedTechnicalSpec(addNewTechFitting);
  };
  const onChangeProductVariationInventory = (e, dataName = '') => {
    //const [name, value] = e.target;
    let updateFormData = {};
    //console.log(e.target.value.replace(/\D/gi, ""), "yyyyyyyyyyyyyyy")
    const digitField = [
      'msrp_price',
      'wholesale_price',
      'sale_price',
      'stock_quantity',
      'weight',
      'height',
      'product_length',
      'width',
    ];
    // console.log(e.target.value.replace(/\^[1-9][0-9]/g, ""), "yyyyyyyyyyyyyyy")
    if (e.target) {
      updateFormData = { [e.target.name]: e.target.value };
    }

    setVariationInventoryDetail({
      ...variationInventoryDetail,
      ...updateFormData,
    });
  };

  const handleDefaultProduct = (e, variation) => {
    e.preventDefault();
    markDefault(variation, product_id).then((res) => {
      if (res.status) {
        getAllVariations(product_id).then((res) => {
          if (res.status) {
            setAllVariations(res.variations);
          }
        });
      }
    });
    setShowForm(false);
  };

  const onChangeExternalProduct = (e) => {
    setVariationInventoryDetail({
      ...variationInventoryDetail,
      is_external: !variationInventoryDetail.is_external,
      external_link: '',
      stock_quantity: 0,
      msrp_price: 0,
      wholesale_price: 0,
      sale_price: 0,
    });
  };

  const onChange = useCallback((e) => {
    let addNew = { ...selectedVariation };
    addNew[e.target.name] = {
      attribute_id: e.target.name,
      attribute_name: e.target.getAttribute('custom-attrname'),
      option_id: e.target.value,
    };

    setSelectedVariation(addNew);
  });

  const onChangeFittingSpec = (e) => {
    let result = e.target.value.includes('_add');
    if (result == true) {
      const addArray = e.target.value.split('_');
      let spec_id = addArray[0];
      setIsSpecModal(true);
      setSpecFormData({ ...specFormData, spec_id: spec_id });
    } else {
      let addNewFittingSpec = { ...selectedFittingSpec };
      let AddFittingTermsData = get_fitting_option_name('', e.target.value);
      addNewFittingSpec[e.target.name] = {
        spec_label: AddFittingTermsData.label,
        spec_name: e.target.getAttribute('custom-attrname'),
        option_id: e.target.value,
        option_term: AddFittingTermsData.term_name,
      };

      setSelectedFittingSpec(addNewFittingSpec);
    }
  };

  const onChangeTechnicalSpec = (e) => {
    let result = e.target.value.includes('_add');
    if (result == true) {
      const addArray = e.target.value.split('_');
      let spec_id = addArray[0];
      setIsSpecModal(true);
      setSpecFormData({ ...specFormData, spec_id: spec_id });
    } else {
      let addNewTechnicalSpec = { ...selectedTechnicalSpec };
      let AddTechnicalTermsData = get_technical_option_name('', e.target.value);

      addNewTechnicalSpec[e.target.name] = {
        spec_label: AddTechnicalTermsData.label,
        spec_name: e.target.getAttribute('custom-attrname'),
        option_id: e.target.value,
        option_term: AddTechnicalTermsData.terms_name,
      };

      setSelectedTechnicalSpec(addNewTechnicalSpec);
    }
  };

  //########### handel add variation button click ########################

  useEffect(() => {
    if (gearProductAllVariations) {
      setAllVariations(gearProductAllVariations);
    }
  }, [gearProductAllVariations]);

  const onClickAddVariation = useCallback(
    (e) => {
      e.preventDefault();
      let newVariation = [];
      let newAttrOption = {};
      setIsClone(false);
      Object.keys(selectedVariation).forEach((index) => {
        newAttrOption = {
          attribute_id: index,
          attribute_name: selectedVariation[index].attribute_name
            ? selectedVariation[index].attribute_name
            : selectedVariation[index].spec_name,
          option_id: selectedVariation[index].option_id,
          option_term: get_option_name(
            index,
            selectedVariation[index].option_id
          ),
        };
        newVariation.push(newAttrOption);
      });

      let newFittingSpec = [];
      let newFittingSpecOption = {};

      let fittingSpecName = Object.values(selectedFittingSpec)
        .filter((data) => data.option_id === '')
        .map((filterName) => {
          return filterName.spec_name;
        });
      Object.keys(selectedFittingSpec).forEach((index) => {
        let termsData = get_fitting_option_name(
          index,
          selectedFittingSpec[index].option_id
        );
        const filteredfittingData = Object.values(selectedFittingSpec).filter(
          (data) =>
            data.spec_name === selectedFittingSpec[index].spec_name &&
            fittingSpecName.includes(data.spec_name)
        );

        if (filteredfittingData.length === 0) {
          newFittingSpecOption = {
            spec_label: termsData.label,
            spec_id: index,
            spec_name: selectedFittingSpec[index].attribute_name
              ? selectedFittingSpec[index].attribute_name
              : selectedFittingSpec[index].spec_name,
            option_id: selectedFittingSpec[index].option_id,
            option_term: termsData.term_name,
          };
          newFittingSpec.push(newFittingSpecOption);
        }
      });

      let newTechnicalSpec = [];
      let newTechnicalSpecOption = {};
      let techSpecName = Object.values(selectedTechnicalSpec)
        .filter((data) => data.option_id === '')
        .map((filterName) => {
          return filterName.spec_name;
        });
      Object.keys(selectedTechnicalSpec).forEach((index, newDatas) => {
        let technicalTermsData = [];
        if (selectedTechnicalSpec[index].option_id) {
          technicalTermsData = get_technical_option_name(
            index,
            selectedTechnicalSpec[index].option_id
          );
        }

        const filteredTechnicalData = Object.values(
          selectedTechnicalSpec
        ).filter(
          (data) =>
            data.spec_name === selectedTechnicalSpec[index].spec_name &&
            techSpecName.includes(data.spec_name)
        );

        if (filteredTechnicalData.length === 0) {
          newTechnicalSpecOption = {
            spec_label: technicalTermsData.label,
            spec_id: index,
            spec_name: selectedTechnicalSpec[index].attribute_name
              ? selectedTechnicalSpec[index].attribute_name
              : selectedTechnicalSpec[index].spec_name,
            option_id: selectedTechnicalSpec[index].option_id,
            option_term: technicalTermsData.term_name
              ? technicalTermsData.term_name
              : '',
          };
          newTechnicalSpec.push(newTechnicalSpecOption);
        }
      });

      let newVariationDetail = {
        ...variationInventoryDetail,
        variation: newVariation,
      };

      let newFittingSpecDetail = {
        ...variationInventoryDetail,
        fitting_specs: newFittingSpec,
      };

      let newTechnicalSpecDetail = {
        ...variationInventoryDetail,
        technical_specs: newTechnicalSpec,
      };

      let dataArray = {
        ...newVariationDetail,
        ...newFittingSpecDetail,
        ...newTechnicalSpecDetail,
        sale_price: !variationInventoryDetail.sale_price ? 0 : variationInventoryDetail.sale_price,
      };

      saveVariation(product_id, dataArray).then((res) => {
        if (res) {
          let formInput = document.querySelector('#add-new-variation');
          let selectInput = formInput.querySelectorAll('select');
          selectInput.forEach((inpt) => {
            inpt.selectedIndex = 0;
          });
          setShowForm(false);
          setAddVariationModal(false);
        }
      });
    },
    [
      selectedVariation,
      selectedTechnicalSpec,
      selectedFittingSpec,
      variationInventoryDetail,
    ]
  );

  const onClickViewVariation = (e, details) => {
    let box = gearShippingBox.filter((box) => {
      return box._id === details.shippingBoxId;
    });
    setModalDetails({ ...details, ...box[0] });
    toggleViewDetailModal();
  };

  const toggleViewDetailModal = () => {
    setViewDetailModal(!viewDetailModal);
  };

  const toggleViewSpecModal = () => {
    setIsSpecModal(!isSpecModal);
  };

  const onClickCloneVariation = (e, details) => {
    e.preventDefault();
    removeErrors();
    setIsEdit(false);
    let addNew = [];
    details.variation.map((item) => {
      addNew[item.attribute_id] = {
        attribute_id: item.attribute_id,
        attribute_name: item.attribute_name,
        option_id: item.option_id,
      };
    });
    setSelectedVariation(addNew);
    let newDetails = {
      sku: details.sku,
      stock_quantity: details.stock_quantity,
      msrp_price: details.msrp_price,
      wholesale_price: details.wholesale_price,
      sale_price: details.sale_price,
      allow_backorders: details.allow_backorders,
      shippingBoxId: details.shippingBoxId,
      variation_id: ""
    };
    setVariationInventoryDetail({ ...variationInventoryDetail, ...newDetails });
    setShowForm(!showForm);

  };

  const toggleAddVariationModal = useCallback(
    (e) => {
      e.preventDefault();
      setIsEdit(false);
      removeErrors();
      setFittingData({});

      setSelectedVariation([]);

      setVariationInventoryDetail({
        ...initialFormData,
        shippingBoxId: gearShippingBox[0]?._id ? gearShippingBox[0]?._id : '',
      });
      setShowForm(!showForm);

    },
    [showForm, allVariations, selectedAttributes]
  );

  const deleteProductVariation = (e, details) => {
    e.preventDefault();
    removeVariation(details.sku, product_id);
  };

  const editVariation = ({ e, details }) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    removeErrors();
    setIsClone(false);
    setIsEdit(true);
    setShowForm(true);
    setAddVariationModal(!addVariationModal);
    let addNew = [];
    details.variation.map((item) => {
      addNew[item.attribute_id] = {
        attribute_id: item.attribute_id,
        attribute_name: item.attribute_name,
        option_id: item.option_id,
      };
    });
    setSelectedVariation(addNew);
    const initial_Shipping_Box = gearShippingBox[0]?._id
      ? gearShippingBox[0]?._id
      : '';

    let newDetails = {
      variation_id: details._id,
      sku: details.sku,
      stock_quantity: details.stock_quantity,
      msrp_price: details.msrp_price,
      wholesale_price: details.wholesale_price,
      sale_price: details.sale_price,
      allow_backorders: details.allow_backorders,
      variation_description: details.variation_description,
      shippingBoxId: details.shippingBoxId,
    };
    setVariationInventoryDetail({ ...variationInventoryDetail, ...newDetails });
  };

  const getBackPage = () => {
    setShowForm(false);
  };

  const IntiSpecData = {
    add_spec: '',
    spec_id: '',
  };
  const [specFormData, setSpecFormData] = useState(IntiSpecData);
  /**************** expload form data *********************/
  const handleSpecChange = (e) => {
    setSpecFormData({ ...specFormData, [e.target.name]: e.target.value });
  };
  /**************** on submit event *********************/
  const onAddSpec = (e) => {
    e.preventDefault();
    addNewOption(specFormData).then((res) => {
      if (res.status == true) {
        toggleViewSpecModal();
        getAttributes();
        setSpecFormData(IntiSpecData);
      }
    });
  };

  //################ End View Variations Details ############################
  return loading || attributes_loading || selectedAttributes.length === 0 ? (
    <Spinner />
  ) : (
    <>
      <Button
        color="primary"
        size="sm"
        data-index="-1"
        onClick={toggleAddVariationModal}
        className="mb-2"
      >
        {showForm ? (
          'Back'
        ) : (
          <>
            <i className="fa fa-plus"></i> Add Variation
          </>
        )}
      </Button>

      {showForm ? (
        <Card className="variation-Outer">
          <Form
            className="form-horizontal"
            onSubmit={onClickAddVariation}
            id="add-new-variation"
          >
            <CardBody>
              <Row>
                <Col xs="12" sm="4">
                  <Card>
                    <CardHeader>Select Variation</CardHeader>
                    <CardBody>
                      {attributeMessage && (
                        <Alert color="danger">{attributeMessage}</Alert>
                      )}
                      {Object.keys(selectedAttributes).map(
                        (value, attrIndex, attrbt) => {
                          return (
                            selectedAttributes[attrIndex].attributeId && (
                              <FormGroup
                                check
                                className="checkbox attribute-body"
                                key={
                                  selectedAttributes[attrIndex].attributeId._id
                                }
                              >
                                <Label
                                  htmlFor={
                                    selectedAttributes[attrIndex].attributeId
                                      ._id
                                  }
                                  className="Attribute_Section"
                                >
                                  {
                                    selectedAttributes[attrIndex].attributeId
                                      .name
                                  }{' '}
                                  *
                                </Label>
                                <Input
                                  type="select"
                                  name={
                                    selectedAttributes[attrIndex].attributeId
                                      ._id
                                  }
                                  custom-attrname={
                                    selectedAttributes[attrIndex].attributeId
                                      .name
                                  }
                                  id={
                                    selectedAttributes[attrIndex].attributeId
                                      .name
                                  }
                                  value={
                                    selectedVariation[
                                      selectedAttributes[attrIndex]?.attributeId
                                        ._id
                                    ]?.option_id
                                  }
                                  onChange={onChange}
                                  required
                                >
                                  <option value={''}>Choose an option</option>
                                  {Object.keys(attributesValueListData).length >
                                    0 &&
                                    selectedAttributes[attrIndex].values &&
                                    selectedAttributes[attrIndex].values.map(
                                      (optionsData, index) => {
                                        return (
                                          <option
                                            value={optionsData}
                                            key={optionsData}
                                          >
                                            {get_option_name(
                                              selectedAttributes[attrIndex]
                                                .attributeId._id,
                                              optionsData
                                            )}
                                          </option>
                                        );
                                      }
                                    )}
                                </Input>
                              </FormGroup>
                            )
                          );
                        }
                      )}
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>Shipping</CardHeader>
                    <CardBody>
                      <FormGroup>
                        <Label htmlFor="sku">
                          Product Weight {ATTRIBUTE_WEIGHT_UNIT}
                          <span>*</span>
                        </Label>
                        <Input
                          type="number"
                          id="weight"
                          name="weight"
                          min={0}
                          step={0.01}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          value={variationInventoryDetail.weight}
                          required
                          invalid={!!errorList.weight}
                          onChange={(e) => onChangeProductVariationInventory(e)}
                        />
                        <Errors current_key="weight" key="weight" />
                      </FormGroup>
                      <FormGroup>
                        <Label>Shipping Box *</Label>
                        <Input
                          type="select"
                          name="shippingBoxId"
                          required
                          onChange={(e) => onSelectShippingBox(e)}
                        >
                          {gearShippingBox.length > 0 &&
                            gearShippingBox.map((box, inx) => {
                              if (
                                !variationInventoryDetail?.shippingBoxId &&
                                inx === 0
                              ) {
                                return (
                                  <>
                                    <option disabled selected key={`default-${inx}`}>
                                      Select Shipping Box
                                    </option>
                                    <option
                                      value={box._id}
                                      key={box._id}
                                      selected={
                                        variationInventoryDetail?.shippingBoxId ===
                                        box._id
                                      }
                                    >
                                      {box.name}
                                    </option>
                                  </>
                                );
                              }
                              return (
                                <option
                                  value={box._id}
                                  key={box._id}
                                  selected={
                                    variationInventoryDetail?.shippingBoxId ===
                                    box._id
                                  }
                                >
                                  {box.name}
                                </option>
                              );
                            })}
                        </Input>
                        <Errors
                          current_key="shippingBoxId"
                          key="shippingBoxId"
                        />
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="12" sm="4">
                  <Card>
                    {' '}
                    <CardHeader>Price</CardHeader>
                    <CardBody>
                      <FormGroup>
                        <Label htmlFor="msrp_price">
                          MSRP Price <span>*</span>
                        </Label>
                        <Input
                          type="number"
                          id="msrp_price"
                          name="msrp_price"
                          value={variationInventoryDetail.msrp_price}
                          required={!variationInventoryDetail.is_external}
                          disabled={variationInventoryDetail.is_external}
                          min={0}
                          step={0.01}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          invalid={!!errorList.msrp_price}
                          onChange={(e) => onChangeProductVariationInventory(e)}
                        />
                        <Errors current_key="msrp_price" key="msrp_price" />
                      </FormGroup>
                      {/* <FormGroup>
                        <Label htmlFor="wholesale_price">
                          Wholesale Price <span>*</span>
                        </Label>
                        <Input
                          type="number"
                          id="wholesale_price"
                          name="wholesale_price"
                          value={variationInventoryDetail.wholesale_price}
                          // required={!variationInventoryDetail.is_external}
                          disabled={variationInventoryDetail.is_external}
                          min={0}
                          step={0.01}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          invalid={!!errorList.wholesale_price}
                          onChange={(e) => onChangeProductVariationInventory(e)}
                        />
                        <Errors
                          current_key="wholesale_price"
                          key="wholesale_price"
                        />
                      </FormGroup> */}
                      <FormGroup>
                        <Label htmlFor="sale_price">Sale Price</Label>
                        <Input
                          type="number"
                          id="sale_price"
                          name="sale_price"
                          min={0}
                          step={0.01}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          invalid={!!errorList.sale_price}
                          value={variationInventoryDetail.sale_price}
                          onChange={(e) => onChangeProductVariationInventory(e)}
                          disabled={variationInventoryDetail.is_external}
                        />
                        <Errors current_key="sale_price" key="sale_price" />
                      </FormGroup>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>Variation Description</CardHeader>
                    <CardBody>
                      <Col sm="12">
                        <FormGroup>
                          <Label htmlFor="sku">
                            Description
                          </Label>
                          <Input
                            type="textarea"
                            id="sku"
                            name="variation_description"
                            maxLength="100"
                            placeholder="Variation Description"
                            minLength="3"
                            value={variationInventoryDetail.variation_description}
                            //invalid={!!errorList.sku}
                            onChange={(e) => onChangeProductVariationInventory(e)}
                          />
                          <Errors current_key="variation_description" key="variation_description" />
                        </FormGroup>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="12" sm="4">
                  <Card>
                    <CardHeader>Model No.</CardHeader>
                    <CardBody>
                      <FormGroup>
                        <Label htmlFor="sku">
                          SKU <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="sku"
                          name="sku"
                          maxLength="100"
                          placeholder="Enter SKU"
                          minLength="3"
                          value={variationInventoryDetail.sku}
                          required
                          invalid={!!errorList.sku}
                          onChange={(e) => onChangeProductVariationInventory(e)}
                        />
                        <Errors current_key="sku" key="sku" />
                      </FormGroup>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>External Product</CardHeader>
                    <CardBody>
                      <FormGroup>
                        <Label htmlFor="is_external">Is External</Label>
                        <Input
                          type="checkbox"
                          id="is_external"
                          name="external"
                          value={variationInventoryDetail.is_external}
                          checked={variationInventoryDetail.is_external}
                          onChange={(e) => onChangeExternalProduct(e)}
                          style={{
                            marginTop: '0px',
                            marginLeft: '5px',
                            height: '20px',
                            width: '20px',
                          }}
                        />
                        {variationInventoryDetail.is_external ? (
                          <>
                            <Input
                              type="text"
                              id="external_link"
                              name="external_link"
                              placeholder="Enter External Product Link"
                              minLength="3"
                              value={variationInventoryDetail.external_link}
                              required={variationInventoryDetail.is_external}
                              invalid={!!errorList.external_link}
                              onChange={(e) =>
                                onChangeProductVariationInventory(e)
                              }
                              style={{ marginTop: '10px' }}
                            />
                            <Errors
                              current_key="external_link"
                              key="external_link"
                            />
                          </>
                        ) : null}
                      </FormGroup>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>Stock</CardHeader>
                    <CardBody>
                      <FormGroup>
                        <Label htmlFor="stock_quantity">
                          Stock Quantity <span>*</span>
                        </Label>
                        <Input
                          type="number"
                          id="stock_quantity"
                          name="stock_quantity"
                          value={variationInventoryDetail.stock_quantity}
                          required={!variationInventoryDetail.is_external}
                          disabled={variationInventoryDetail.is_external}
                          min={0}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          invalid={!!errorList.stock_quantity}
                          onChange={(e) => onChangeProductVariationInventory(e)}
                        />
                        <Errors
                          current_key="stock_quantity"
                          key="stock_quantity"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="allow_backorders">
                          Allow Backorders <span>*</span>
                        </Label>
                        <Input
                          type="select"
                          id="allow_backorders"
                          name="allow_backorders"
                          value={variationInventoryDetail.allow_backorders}
                          required={!variationInventoryDetail.is_external}
                          disabled={variationInventoryDetail.is_external}
                          onChange={(e) => onChangeProductVariationInventory(e)}
                        >
                          {Object.keys(ALLOW_BACK_ORDERS).map((key) => (
                            <option value={key} key={key}>
                              {ALLOW_BACK_ORDERS[key]}
                            </option>
                          ))}
                        </Input>
                        <Errors
                          current_key="allow_backorders"
                          key="allow_backorders"
                        />
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>

            <CardFooter>
              <Button
                type="submit"
                className="mr-05"
                size="sm"
                color="primary"
                disabled={loading}
              >
                Save
              </Button>
              <Button
                type="button"
                className="mr-05"
                size="sm"
                color="danger"
                onClick={getBackPage}
              >
                Cancel
              </Button>
            </CardFooter>
          </Form>
        </Card>
      ) : (
        Object.keys(allVariations).length > 0 &&
        Object.keys(selectedAttributes).length > 0 && (
          <Card key={2}>
            <CardHeader>All Variations</CardHeader>
            <CardBody>
              <div
                className="ag-theme-alpine"
                style={{ height: 400, width: 600 }}
              >
                <AgGridReact rowData={allVariations} columnDefs={columns} />
              </div>
            </CardBody>
          </Card>
        )
      )}

      {/*########### modal to display variation detail ########################*/}

      <Modal isOpen={viewDetailModal} toggle={toggleViewDetailModal}>
        <ModalHeader toggle={toggleViewDetailModal}>
          Variation Details
        </ModalHeader>
        <ModalBody>
          <Row>
            {modalDetails && (
              <Col>
                <Table bordered striped responsive size="sm">
                  <tbody>
                    <tr>
                      <td>SKU</td>
                      <td>{modalDetails.sku}</td>
                    </tr>
                    <tr>
                      <td>Stock Quantity</td>
                      <td>{modalDetails.stock_quantity}</td>
                    </tr>
                    <tr>
                      <td>MSRP Price</td>
                      <td>${Number(modalDetails.msrp_price).toFixed(2)}</td>
                    </tr>
                    {/* <tr>
                      <td>Wholesale Price</td>
                      <td>
                        ${Number(modalDetails.wholesale_price).toFixed(2)}
                      </td>
                    </tr> */}
                    <tr>
                      <td>Sale Price</td>
                      <td>${Number(modalDetails.sale_price).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Shipping Weight</td>
                      <td>{Number(modalDetails.weight)}</td>
                    </tr>
                    {/* <tr>
                      <td>Product Height</td>
                      <td>
                        {Number(modalDetails.height)} {'Inch'}
                      </td>
                    </tr>
                    <tr>
                      <td>Product Length</td>
                      <td>
                        {Number(modalDetails.product_length)} {'Inch'}
                      </td>
                    </tr>
                    <tr>
                      <td>Product Width</td>
                      <td>
                        {Number(modalDetails.width)} {'Inch'}
                      </td>
                    </tr> */}
                    <tr>
                      <td>Shippig Box</td>
                      <td>{gearShippingBox.forEach((box) => { if (box._id === modalDetails.shippingBoxId) { selectedShippingBox = box } })}{selectedShippingBox.name}</td>
                    </tr>
                    <tr>
                      <td>Allow Backorders</td>
                      <td>
                        {ALLOW_BACK_ORDERS[modalDetails.allow_backorders]}
                      </td>
                    </tr>
                    <tr>
                      <td>External Product</td>
                      <td>{modalDetails?.is_external ? 'Yes' : 'No'}</td>
                    </tr>
                    {modalDetails?.is_external ? (
                      <>
                        <tr>
                          <td>External Product Link</td>
                          <td>{modalDetails?.external_link}</td>
                        </tr>{' '}
                      </>
                    ) : null}
                  </tbody>
                </Table>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="danger" onClick={toggleViewDetailModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      {/*###########################  modal for add specs   ####################################*/}
      <Modal isOpen={isSpecModal}>
        <Form className="form-horizontal">
          <ModalHeader toggle={toggleViewSpecModal}>Add Option</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <Label className="p-0">Enter Name</Label>
                <Input
                  type="text"
                  name="add_spec"
                  id="add_spec"
                  placeholder="Enter Name"
                  invalid={errorList.add_spec ? true : false}
                  value={specFormData.add_spec}
                  onChange={(e) => handleSpecChange(e)}
                ></Input>
                <Errors current_key="add_spec" key="add_spec" />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              color="primary"
              type="button"
              onClick={(e) => onAddSpec(e)}
            >
              Add
            </Button>
            <Button size="sm" color="danger" onClick={toggleViewSpecModal}>
              Close
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

GearProductVariations.defaultProps = {
  product_id: null,
};

GearProductVariations.propTypes = {
  loading: PropTypes.bool.isRequired,
  attributes: PropTypes.array,
  getAttributes: PropTypes.func.isRequired,
  getGearShippingBoxList: PropTypes.func.isRequired,
  gearShippingBoxList: PropTypes.object.isRequired,
  saveVariation: PropTypes.func.isRequired,
  gearProductAllVariations: PropTypes.array,
  gearCurrentProduct: PropTypes.object.isRequired,
  setWholesaleError: PropTypes.func.isRequired,
  attributes_loading: PropTypes.bool.isRequired,
  errorList: PropTypes.array.isRequired,
  removeErrors: PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
  loading: state.gearProduct.loading,
  attributes: state.gearProduct.gearCurrentProduct.attributes,
  gearShippingBoxList: state.gearShippingBox.gearShippingBoxList,
  fitting_specs: state.gearProduct.gearAttributesValueList?.fittingList ?? [],
  basic_specs: state.gearProduct.gearAttributesValueList?.technicalList ?? [],
  gearAttributesValueList: state.gearProduct.gearAttributesValueList,
  gearProductAllVariations: state.gearProduct.gearCurrentProduct.variations,
  gearCurrentProduct: state.gearProduct.gearCurrentProduct,
  attributes_loading: state.gearProduct.attributes_loading,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  getAttributes,
  removeVariation,
  getGearShippingBoxList,
  saveVariation,
  markDefault,
  getAllVariations,
  setWholesaleError,
  setSaleError,
  addNewOption,
  removeErrors
})(GearProductVariations);
