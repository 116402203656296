import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Spinner from 'views/Spinner';
import { CKEditor } from 'ckeditor4-react';
import { create, getFlexCodeList } from 'actions/admin/flexCode';

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    Form,
    FormGroup,
    Label,
    Row,
} from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import Errors from 'views/Notifications/Errors';
import { FlexCode_LOADING } from 'actions/types';
import { CKEditorConfig } from 'constants';

const FlexCodeEditor = ({
    getFlexCodeList,
    create,
    loading,
    errorList,
    flexCode,
}) => {

    /**************** use state for form data *********************/
    const [formData, setFormData] = useState({
        description: '',
    });
    /**************** expload form data *********************/


    useEffect(async () => {
        await getFlexCodeList();
    }, [])

    useMemo(() => {
        if (Object.keys(flexCode).length > 0) {
            setFormData({
                description: flexCode?.description
            })
        }
    }, [flexCode])

    // handle input change
    const handleChange = (event) => {
        setFormData((data1) => ({
            ...data1,
            description: event.editor.getData()
        }));
    };

    /**************** on submit event *********************/
    const onSubmit = (e) => {
        e.preventDefault();
        create(formData);
    };

    const {
        description
    } = formData;

    return (
        <div className="animated fadeIn">
            {loading ? (
                <Spinner />
            ) : (
                <Row>
                    <Col xs="12" sm="12">
                        <Card>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => onSubmit(e)}
                                encType="multipart/form-data"
                            >
                                <CardBody>
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label htmlFor="description">
                                                    Flex Code Nomenclature
                                                </Label>
                                                {<CKEditor

                                                    initData={description}
                                                    id="description"
                                                    name="description"
                                                    config={CKEditorConfig}
                                                    invalid={errorList.description ? true : false}
                                                    onChange={handleChange}
                                                />
                                                }
                                                <Errors
                                                    current_key="description"
                                                    key="description"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <Button type="submit" size="sm" color="primary" disabled={loading}>
                                        <i className="fa fa-dot-circle-o"></i> Submit
                                    </Button>
                                    {/* <Link to="" onClick={() => window.location.reload()}>
                                        <Button type="reset" size="sm" color="danger">
                                            <i className="fa fa-ban"></i> Cancel
                                        </Button>
                                    </Link> */}
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            )}
        </div>
    );
};

FlexCodeEditor.propTypes = {
    getFlexCodeList: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
    loading: state.flexCode.loading,
    flexCode: state.flexCode.flexCodeList,
    errorList: state.errors,
});

export default connect(mapStateToProps, {
    getFlexCodeList,
    create,
})(FlexCodeEditor);
