/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import Errors from 'views/Notifications/Errors';
import {
  getOrderById,
  addInternalNotes,
  getAllInternalNotesByOrderId,
  deleteInternalNotes,
} from 'actions/admin/order';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,

} from 'reactstrap';

const InternalNotes = ({
  getOrderById,
  addInternalNotes,
  getAllInternalNotesByOrderId,
  deleteInternalNotes,
  user_id,
  order_id,
  errorList, loading
}) => {
  //##################### billing address list for logged in user #########################
  //console.log(selectedShippingMode.carrier,'selectedShippingMode data here');

  const [internalNotesData, setInternalNotesData] = useState();
  useEffect(async () => {
    let notesData = await getAllInternalNotesByOrderId(order_id);
    //console.log(notesData, 'notesData');
    setInternalNotesData(notesData);

  }, []);


  const handleInternalNotes = (user_id, order_id) => {

    let internal_notes = document.getElementById('internal_notes').value;
    addInternalNotes(user_id, order_id, internal_notes);
    getAllInternalNotesByOrderId(order_id);
    getOrderById(order_id);

  };

  const handleDeleteInternalNotes = (notes_id, order_id) => {
    deleteInternalNotes(notes_id);
    getOrderById(order_id);


  };



  return (
    <>

      <Form>
        <FormGroup>
          <Label><strong>Internal Notes</strong></Label>
          <Input
            type="textarea"
            name="internal_notes"
            id="internal_notes"
            invalid={errorList.internal_notes ? true : false}

          />
          <Button className="mt-2" onClick={async () => { await handleInternalNotes(user_id, order_id) }} type="button" size="sm" color="primary">
            Save internal notes
          </Button>
        </FormGroup>
      </Form>
      <div className='notes-card'>
        {
          internalNotesData &&
            internalNotesData.length > 0 ?
            internalNotesData.map((item) => (
              <>
                <Card>
                  <CardBody>
                    <div className='flex-outer'>
                      <div>
                        <p>{item.first_name}{item.last_name && item.last_name != null ? ' ' + item.last_name : ''} : {item.notes}</p>
                        <p>
                          (<span className='time_formatting'>

                            <Moment format={process.env.REACT_APP_DISPLAY_DATE_FORMATE ?? 'MM/DD/YYYY'} locale="de">
                              {item.created_at}
                            </Moment>
                          </span>
                          {" "}
                          <span className='time_formatting'>
                            <Moment format="HH:mm" locale="de">
                              {item.created_at}
                            </Moment>
                          </span>
                          )

                        </p>
                      </div>
                      {
                        user_id === item.user_id &&

                        <div>
                          <Button className="mt-2" onClick={async () => { await handleDeleteInternalNotes(item._id, order_id) }} type="button" size="sm" color="danger">
                            <i className="fa fa-trash"></i>
                          </Button>
                        </div>
                      }
                    </div>
                  </CardBody>
                </Card>
              </>


            ))
            :
            null
        }
      </div>
    </>
  );
};

InternalNotes.propTypes = {
  errorList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
});

export default connect(mapStateToProps,
  {
    getOrderById,
    addInternalNotes,
    getAllInternalNotesByOrderId,
    deleteInternalNotes,
  }
)(InternalNotes);
