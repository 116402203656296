/* eslint-disable */
import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';
import { edit, getImageById, notFound } from 'actions/admin/imageGallery';
import Spinner from 'views/Spinner';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import Errors from 'views/Notifications/Errors';
import { REMOVE_ERRORS } from 'actions/types';

const EditImage = ({
  getImageById,
  edit,
  image: { currentImage, loading },
  history,
  match,
  errorList,
}) => {
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    oldImage: '', // old image name (thumbnail)
    selectedFile: '', // image file
    image: '', // object url of image (image src)
    isNewFileSelected: 0,
  });

  // to resolve useEffect dependency error
  
  //**************** use memo to get current image *********************
  const { image_id: image_id } = useParams();
  useMemo(() => {
    
    getImageById(image_id).then((res) => {
      if (res === undefined) {
        notFound(navigate); // page not found
      }
    });
  }, [getImageById, image_id]);

  //**************** use memo to set form data from current image *********************

  useMemo(() => {
    if (Object.keys(currentImage).length > 0) {
      setFormData({
        ...currentImage,
        image: !currentImage.thumbnail
          ? ''
          : //process.env.REACT_APP_AWS_STORAGE_URL +
            'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/image-gallery/' +
            currentImage.thumbnail,
        oldImage: !currentImage.thumbnail ? '' : currentImage.thumbnail,
        isNewFileSelected: 0,
      });
    }
  }, [currentImage]);

  //**************** expload form data *********************
  const {
    title,
    description,
    // oldImage,
    image,
  } = formData;

  //**************** use dispatch *********************
  

  //**************** handle on change event *********************
  const onChange = (e) => {
    if (e.target.name === 'thumbnail') {
      let image_file = e.target.files[0];
      let image_url = URL.createObjectURL(image_file);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        selectedFile: image_file,
        image: image_url,
        isNewFileSelected: 1,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    
  };

  //**************** handle on submit event *********************
  const onSubmit = (e) => {
    e.preventDefault();
    edit(formData, navigate, image_id);
  };

  return loading || Object.keys(currentImage).length === 0 ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="6">
          <Card>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="title">
                        Title <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="title"
                        name="title"
                        maxLength="100"
                        value={title}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.title ? true : false}
                      />
                      <Errors current_key="title" key="title" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="description">
                        Description <span>*</span>
                      </Label>
                      <Input
                        type="textarea"
                        id="description"
                        name="description"
                        value={description}
                        required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.description ? true : false}
                      />
                      <Errors current_key="description" key="description" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="thumbnail">
                        Thumbnail<span>*</span>
                      </Label>
                      <Input
                        type="file"
                        id="thumbnail"
                        name="thumbnail"
                        onChange={(e) => onChange(e)}
                        multiple
                        invalid={errorList.image ? true : false}
                      />
                      <img src={image} className="preview-img" alt="" />
                      <Errors current_key="image" key="image" />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button type="submit" size="sm" color="primary">
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
                <Link to="/admin/gallery-image">
                  <Button type="reset" size="sm" color="danger">
                    <i className="fa fa-ban"></i> Cancel
                  </Button>
                </Link>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditImage.propTypes = {
  getImageById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  notFound: PropTypes.func.isRequired,
  image: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  image: state.imageGallery,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  getImageById,
  edit,
  notFound,
})(EditImage);
