/* eslint-disable */
import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { edit, getScript } from 'actions/admin/setting';
import Errors from 'views/Notifications/Errors';
import Spinner from 'views/Spinner';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { REMOVE_ERRORS } from 'actions/types';

const WebsiteSettings = ({
  getScript,
  edit,
  setting: { currentSetting, loading },
  errorList,
}) => {
  // to resolve useEffect dependency error

  //################ get current website settings ############################
  useMemo(() => {

    getScript();
  }, []);

  //################ set initial form data ############################
  const [initialFormData, setInitialFormData] = useState({
    header_script: '',
    footer_script: '',
    copyright_text: '',
  });

  useMemo(() => {
    if (Object.keys(currentSetting).length > 0) {
      setInitialFormData({
        header_script: currentSetting.header_script,
        footer_script: currentSetting.footer_script,
        copyright_text: currentSetting.copyright_text,
      });
    }
  }, [currentSetting]);

  //################ set form data ############################

  const [formData, setFormData] = useState(initialFormData);

  useMemo(() => {
    setFormData(initialFormData);
  }, [initialFormData]);

  const { header_script, footer_script, copyright_text } = formData;

  //################ onChange event ############################
  const dispatch = useDispatch();

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    dispatch({ type: REMOVE_ERRORS });
  };

  //########################## set the submit state ##############################
  const [isSubmit, setIsSubmit] = useState(false);

  //################ on form submit ############################
  const onSubmit = (e) => {
    setIsSubmit(true);
    e.preventDefault();
    edit(formData).then((res) => {
      setIsSubmit(false);
    });
  };

  //################ on cancle save ############################
  const onClickCancle = (e) => {
    e.preventDefault();
    setFormData(initialFormData);
  };

  return Object.keys(currentSetting).length === 0 ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="12">
          <Card>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                <h4>Update website setting</h4>
                <br></br>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="header_script">Header Site Script</Label>
                      <Input
                        type="textarea"
                        id="header_script"
                        name="header_script"
                        maxLength="500"
                        value={header_script}
                        placeholder="Enter Header Site Script"
                        // required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.header_script ? true : false}
                      />
                      <Errors current_key="header_script" key="header_script" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="footer_script">Footer Site Script</Label>
                      <Input
                        type="textarea"
                        id="footer_script"
                        name="footer_script"
                        maxLength="500"
                        value={footer_script}
                        placeholder="Enter Footer Site Script"
                        // required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.footer_script ? true : false}
                      />
                      <Errors current_key="footer_script" key="footer_script" />
                    </FormGroup>
                  </Col>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="copyright_text">Copyright text</Label>
                      <Input
                        type="textarea"
                        id="copyright_text"
                        name="copyright_text"
                        maxLength="500"
                        value={copyright_text}
                        placeholder="Enter copyright text"
                        // required
                        onChange={(e) => onChange(e)}
                        invalid={errorList.copyright_text ? true : false}
                      />
                      <Errors
                        current_key="copyright_text"
                        key="copyright_text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                {loading ? (
                  <div className="modalFooter">
                    <Spinner />
                  </div>
                ) : (
                  <div className="modalFooter">
                    <Button
                      type="reset"
                      size="sm"
                      className="canclebtn"
                      color="danger"
                      onClick={onClickCancle}
                    >
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                    <Button
                      type="submit"
                      size="sm"
                      color="primary"
                      disabled={isSubmit}
                    >
                      <i className="fa fa-dot-circle-o"></i> Update
                    </Button>
                  </div>
                )}
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

WebsiteSettings.propTypes = {
  getScript: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  setting: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  setting: state.setting,
  errorList: state.errors,
  loading: state.setting.loading,
});

export default connect(mapStateToProps, { edit, getScript })(WebsiteSettings);
