import {
  ORDER_LOADING,
  ORDER_FAIL,
  ORDER_LIST_UPDATED,
  ORDER_CHANGE_STATUS,
  ORDER_CHANGE_STATUS_ERROR,
  GET_ORDER_BY_ID,
  ORDER_CREATED,
  ORDER_CREATE_ERROR,
  ORDER_USER_LIST_UPDATED,
  ORDER_USER_LIST_LOADING,
  ORDER_ADDRESS_LOADER,
  ORDER_ADDRESS_LIST,
  ORDER_ADDRESS_ADDED,
  ORDER_SHIPPING_RATES_LIST,
  ORDER_SHIPPING_RATES_LIST_ERROR,
  ORDER_SHIPPING_LOADER,
  ORDER_SHIPPING_FAIL,
  ORDER_CREATE_LOADER,
  ORDER_CREATE_RESULT,
  ORDER_CREATE_RESULT_ERROR,
  ORDER_CREATE_FAIL,
  ORDER_LABEL_FAIL,
  ORDER_LABEL_CREATE_ERROR,
  ORDER_LABEL_CREATED,
  ORDER_LABEL_LOADING,
  SHIPPING_ORDER_LOADING,
  GET_SHIPPING_ORDER_BY_ID,
  SHIPPING_ORDER_FAIL,
  LABEL_PRINT_ORDER_SHIPPING_RATES_LIST,
  LABEL_PRINT_ORDER_SHIPPING_RATES_LIST_ERROR,
  LABEL_PRINT_ORDER_SHIPPING_LOADER,
  LABEL_PRINT_ORDER_SHIPPING_FAIL,
  ORDER_ADDRESS_LIST_ERROR,
  ORDER_USER_LIST_UPDATE_ERROR,
  ADMIN_ORDER_PRODUCT_DETAIL_LOADING,
  ADMIN_ORDER_PRODUCT_DETAIL,
  ADMIN_ORDER_PRODUCT_DETAIL_ERROR,
  ADMIN_ORDER_PRODUCT_DETAIL_FAIL,
  ADMIN_ORDER_PRODUCT_LOADING,
  ADMIN_ORDER_PRODUCT_LIST_UPDATED,
  ADMIN_ORDER_PRODUCT_FAIL,

  ORDER_INVOICE_FAIL,
  ORDER_INVOICE_CREATE_ERROR,
  ORDER_INVOICE_CREATED,
  ORDER_INVOICE_LOADING,
  SEND_EMAIL_LOADING,
  SEND_EMAIL_CREATED,
  SEND_EMAIL_CREATE_ERROR,
  SEND_EMAIL_FAIL,
  ADMIN_ORDER_CART_ERROR,
  GET_ORDER_CART,
  CART_LOADING,
  ORDER_ADDRESS_ADD_LOADER,
  SET_VARIATIONS,
  ADMIN_ORDER_CART_SUCCESS,
  ADMIN_PRODUCT_PRODUCT_LIST,
  RETURN_INITIATE_LOADING,
  GET_RETURN_INITIATE,
  LOADING_SHIPPING_BOX,
  GET_SHIPPING_BOX,
  CREATE_RETURN_INITIATE,
  RETURN_INITIATE_FAIL,
  CHANGE_RETURN_INITIATE_STATUS,
  ADMIN_ORDER_COUPON_LOADER,
  ADMIN_ORDER_REMOVE_COUPON_RESULT,
  ADMIN_ORDER_APPLY_COUPON_RESULT,
  ADMIN_ORDER_APPLY_COUPON_RESULT_ERROR,
  ADMIN_ORDER_REMOVE_COUPON_RESULT_ERROR,
  ADMIN_ORDER_COUPON_FAIL,
  ADMIN_ORDER_RE_APPLY_COUPON_RESULT,
  GET_BUILD_SUPPLY_SERVICE_LIST,
  ADDITIONAL_PRODUCT_CART_SUCCESS,
  ADDITIONAL_PRODUCT_CART_ERROR,
  MERGE_SHIPPING_LOADING,
  MERGE_SHIPPING_SUCCESS,
  MERGE_SHIPPING_ERROR,
  MERGE_SHIPPING_FAIL,
  DELETE_MERGE_SHIPPING_SUCCESS,
  DELETE_MERGE_SHIPPING_ERROR,
  DELETE_MERGE_SHIPPING_FAIL,
  LOADING_START,
  LOADING_END,

} from "actions/types";

const initalState = {
  orderList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentOrder: {},
  returnInitiateList: {
    page: 1,
    data: [],
    count: 0,
    totalRecord: 0,
  },
  loading: false,
  modal_loading: false,
  isSubLoading: false,
  error: {},
  usersList: [],
  user_list_loading: false,
  address_loading: false,
  currentAddressList: [],
  shipping_rates_loading: false,
  shippingRatesList: [],
  order_id: null,
  order_loading: false,
  currentShippingOrder: {},
  shippingOrderLoading: false,
  labelPrintShippingRatesLoading: false,
  labelPrintShippingRatesList: [],
  orderLabelLoading: false,
  productLoading: false,
  currentProduct: {},
  productsList: [],
  productListLoading: false,
  cartDetail: {},
  variationData: {},
  productsAttributeList: [],
  shippingBoxList: [],
  coupon_loading: false,
  getBuildShopService: [],
  buildShopSupplyCart: {},
  mergeShippingData: {}
};

export default function (state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOADING_END:
    case LOADING_START:
      return {
        ...state,
        loading: payload.is_loading,
      }
    case SET_VARIATIONS:
      return {
        ...state,
        variationData: payload
      }
    case GET_BUILD_SUPPLY_SERVICE_LIST:
      return {
        ...state,
        getBuildShopService: payload.data,
        loading: false,
      };

    case MERGE_SHIPPING_SUCCESS:
      return {
        ...state,
        mergeShippingData: payload.data,
        loading: false,
      };

    case MERGE_SHIPPING_LOADING:
    case LOADING_SHIPPING_BOX:
    case RETURN_INITIATE_LOADING:
    case CART_LOADING:
      return {
        ...state,
        loading: payload.is_loading
      }
    case ORDER_ADDRESS_ADD_LOADER:
      return {
        ...state,
        modal_loading: payload.is_loading
      };
    case GET_ORDER_CART:
      return {
        ...state,
        cartDetail: payload.data,
        loading: false,
      };
    case ADDITIONAL_PRODUCT_CART_SUCCESS:
      return {
        ...state,
        buildShopSupplyCart: payload.data,
        loading: false,
      };

    case DELETE_MERGE_SHIPPING_FAIL:
    case DELETE_MERGE_SHIPPING_ERROR:
    case DELETE_MERGE_SHIPPING_SUCCESS:
    case MERGE_SHIPPING_FAIL:
    case MERGE_SHIPPING_ERROR:
    case ADDITIONAL_PRODUCT_CART_ERROR:
    case ADMIN_ORDER_CART_ERROR:
    case ADMIN_ORDER_CART_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ORDER_LOADING:
      return {
        ...state,
        orderList: payload.is_sub_element
          ? state.orderList
          : initalState.orderList,
        currentOrder: payload.is_sub_element
          ? state.currentOrder
          : initalState.currentOrder,
        loading: payload.is_loading ? payload.is_loading : false,
        isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
      };
    case ADMIN_ORDER_COUPON_LOADER:
      return {
        ...state,
        coupon_loading: payload,
      }
    case ADMIN_ORDER_APPLY_COUPON_RESULT_ERROR:
    case ADMIN_ORDER_APPLY_COUPON_RESULT:
    case ADMIN_ORDER_REMOVE_COUPON_RESULT:
    case ADMIN_ORDER_REMOVE_COUPON_RESULT_ERROR:
    case ADMIN_ORDER_COUPON_FAIL:
    case ADMIN_ORDER_RE_APPLY_COUPON_RESULT:
      return {
        ...state,
        coupon_loading: false,
      }
    case CREATE_RETURN_INITIATE:
    case CHANGE_RETURN_INITIATE_STATUS:
    case RETURN_INITIATE_FAIL:
    case ORDER_FAIL:
      return {
        ...state,
        loading: false,
        is_loading: false,
        isSubLoading: false,
        address_loading: false,
        coupon_loading: false,
      };
    case ORDER_LIST_UPDATED:
      return {
        ...state,
        orderList: {
          data: payload.data.orderList.data,
          page: payload.data.orderList.metadata[0].current_page,
          count: payload.data.orderList.metadata[0].totalRecord,
        },
        loading: false,
      };
    case ORDER_CHANGE_STATUS:
      return {
        ...state,
        orderList: {
          ...state.orderList,
          data: state.orderList.data.map((order) =>
            order._id === payload.data._id
              ? { ...order, payment_status: payload.data.payment_status }
              : order
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case ORDER_CHANGE_STATUS_ERROR:
      return {
        ...state,
        orderList: {
          ...state.orderList,
          data: state.orderList.data.map((order) =>
            order._id === payload.data
              ? { ...order, is_failed: true }
              : { ...order, is_failed: false }
          ),
        },
        loading: false,
        isSubLoading: false,
      };
    case GET_SHIPPING_BOX:
      return {
        ...state,
        shippingBoxList: payload.data,
        loading: false,
      }
    case GET_RETURN_INITIATE:
      return {
        ...state,
        returnInitiateList: {
          data: payload.data.data,
          page: payload.data.metadata[0].current_page,
          count: payload.data.metadata[0].totalRecord,
          totalRecord: payload.data.metadata[0].totalRecord
        },
        loading: false,
      }
    case GET_ORDER_BY_ID:
      return {
        ...state,
        currentOrder: payload.data,
        loading: false,
      };

    case ORDER_CREATED:
    case ORDER_CREATE_ERROR:
      return {
        ...state,
        currentOrder: payload.data,
      };
    case ORDER_USER_LIST_UPDATED:
      return {
        ...state,
        usersList: payload.data,
        user_list_loading: false,
      };
    case ORDER_USER_LIST_UPDATE_ERROR:
      return {
        ...state,
        usersList: payload,
        user_list_loading: false,
      };
    case ORDER_USER_LIST_LOADING:
      return {
        ...state,
        user_list_loading: true,
      };
    case ORDER_ADDRESS_LOADER:
      return {
        ...state,
        address_loading: payload,
        loading: true,
      };
    case ORDER_ADDRESS_LIST:
      return {
        ...state,
        currentAddressList: payload,
        address_loading: false,
        loading: false,
      };
    case ORDER_ADDRESS_LIST_ERROR:
      return {
        ...state,
        address_loading: false,
        loading: false,
        currentAddressList: [],
      };
    case ORDER_ADDRESS_ADDED:
      return {
        ...state,
        currentAddressList: [...state.currentAddressList, payload],
        modal_loading: false,
      };
    case ORDER_SHIPPING_LOADER:
      return {
        ...state,
        shipping_rates_loading: payload,
        loading: payload,
        shippingRatesList: [],
      };
    case ORDER_SHIPPING_RATES_LIST:
      return {
        ...state,
        shipping_rates_loading: false,
        loading: false,
        coupon_loading: false,
        shippingRatesList: payload,
      };
    case ORDER_SHIPPING_RATES_LIST_ERROR:
    case ORDER_SHIPPING_FAIL:
      return {
        ...state,
        shipping_rates_loading: false,
        loading: false,
        shippingRatesList: [],
      };
    case ORDER_CREATE_LOADER:
      return {
        ...state,
        order_id: null,
        order_loading: true,
      };
    case ORDER_CREATE_RESULT:
      return {
        ...state,
        order_id: payload,
        order_loading: false,
      };
    case ORDER_CREATE_RESULT_ERROR:
      return {
        ...state,
        order_id: null,
        order_loading: false,
      };
    case ORDER_CREATE_FAIL:
      return {
        ...state,
        order_loading: false,
      };
    case SHIPPING_ORDER_LOADING:
      return {
        ...state,
        currentShippingOrder: initalState.currentShippingOrder,
        shippingOrderLoading: true,
      };
    case SHIPPING_ORDER_FAIL:
      return {
        ...state,
        shippingOrderLoading: false,
      };
    case GET_SHIPPING_ORDER_BY_ID:
      return {
        ...state,
        currentShippingOrder: payload.data,
        shippingOrderLoading: false,
      };
    case LABEL_PRINT_ORDER_SHIPPING_LOADER:
      return {
        ...state,
        labelPrintShippingRatesLoading: payload,
        labelPrintShippingRatesList: [],
      };
    case LABEL_PRINT_ORDER_SHIPPING_RATES_LIST:
      return {
        ...state,
        labelPrintShippingRatesLoading: false,
        labelPrintShippingRatesList: payload,
      };
    case LABEL_PRINT_ORDER_SHIPPING_RATES_LIST_ERROR:
    case LABEL_PRINT_ORDER_SHIPPING_FAIL:
      return {
        ...state,
        labelPrintShippingRatesLoading: false,
        labelPrintShippingRatesList: [],
      };
    case ORDER_LABEL_FAIL:
    case ORDER_INVOICE_FAIL:
    case ORDER_INVOICE_CREATE_ERROR:
    case ORDER_LABEL_CREATE_ERROR:
    case ORDER_INVOICE_CREATED:
    case ORDER_LABEL_CREATED:
      return {
        ...state,
        orderLabelLoading: false,
      };
    case SEND_EMAIL_FAIL:
    case ORDER_LABEL_LOADING:
    case ORDER_INVOICE_LOADING:
    case SEND_EMAIL_CREATE_ERROR:
    case SEND_EMAIL_CREATED:
    case SEND_EMAIL_LOADING:
      return {
        ...state,
        orderLabelLoading: true,
      };
    case ADMIN_ORDER_PRODUCT_DETAIL_LOADING:
      return {
        ...state,
        productLoading: true,
      };
    case ADMIN_ORDER_PRODUCT_DETAIL:
      return {
        ...state,
        currentProduct: payload,
        productLoading: false,
      };
    case ADMIN_ORDER_PRODUCT_DETAIL_ERROR:
    case ADMIN_ORDER_PRODUCT_DETAIL_FAIL:
      return {
        ...state,
        productLoading: false,
      };
    case ADMIN_ORDER_PRODUCT_LOADING:
      return {
        ...state,
        loading: true,
        productListLoading: true,
      };
    case ADMIN_ORDER_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        productListLoading: false,
      };
    case ADMIN_ORDER_PRODUCT_LIST_UPDATED:
      return {
        ...state,
        productsList: payload.data,
        loading: false,
        productListLoading: false,
      };
    case ADMIN_PRODUCT_PRODUCT_LIST:
      return {
        ...state,
        productsAttributeList: payload.data,
        productListLoading: false,
      };

    default:
      return state;
  }
};
