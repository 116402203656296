/* eslint-disable */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Spinner from 'views/Spinner';
import {
  edit,
  getAttributeById,
  deleteTag,
  deleteAttributeAndVariations,
} from 'actions/admin/attribute';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import Errors from 'views/Notifications/Errors';
import { REMOVE_ERRORS, CANCEL_REQUEST } from 'actions/types';
import { useEffect } from 'react';

const EditAttribute = ({
  edit,
  getAttributeById,
  attribute: { currentAttribute, loading },
  history,
  match,
  errorList,
  deleteAttributeAndVariations,
  deleteTag,
}) => {
  /**************** use state for form data *********************/
  let navigate = useNavigate();
  const { attribute_id: attribute_id } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    termsArr: [],
    terms: [],
    status: '',
  });

  // to resolve useEffect dependency error

  /**************** use effect to get current attribute *********************/
  useEffect(() => {
    if (attribute_id) getAttributeById(attribute_id);
  }, [attribute_id]);

  /**************** use effect to set form data from current attribute *********************/
  useEffect(() => {
    if (Object.keys(currentAttribute).length > 0) {
      setFormData({
        name: currentAttribute.name ? currentAttribute.name : '',
        termsArr: currentAttribute.terms ? currentAttribute.terms : '',
        status: currentAttribute.status ? currentAttribute.status : '',
      });
      let term = [];
      currentAttribute.terms.forEach((item) => {
        term.push(item.option);
      });
      setOldTags(term);
    }
  }, [currentAttribute]);
  /**************** expload from data *********************/
  const { name, terms, termsArr, status } = formData;

  /**************** handel tags *********************/

  const [oldTag, setOldTags] = React.useState(termsArr);
  const [tags, setTags] = React.useState(terms);
  const [deletedTags, setDeletedTags] = React.useState([]);

  const Tag = ({ onDeleteTag, value }) => {
    var tag = (
      <div className="tag-item">
        <span className="tag-tagStyle" onClick={(e) => onDeleteTag(e, value)}>
          {/* style={tagStyle}> */}
          &#x2716; {''}
        </span>
        {value}
      </div>
    );

    return <React.Fragment>{tag}</React.Fragment>;
  };

  const OldTag = ({ value, tagname }) => {
    var tag = (
      <div className="tag-item">
        <span
          className="tag-tagStyle"
          onClick={(e) => {
            if (
              window.confirm(`Are you sure, you want to delete ${tagname} tag?`)
            ) {
              deleteTag(value).then((res) => {
                if (res.status == true) {
                  deleteOldTag(tagname);
                  setDeletedTags([...deletedTags, { tagId: value, tagname }]);
                } else {
                  Swal.fire({
                    title: 'Are you sure?',
                    text: 'This attribute is used in few products and in its variartions deleting this will delete it from the product and its variations',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    cancelButtonText: 'No, keep it',
                  }).then((result) => {
                    if (result.value) {
                      // Delete Attribute from the product and its Variations
                      deleteAttributeAndVariations(value).then((response) => {
                        if (response.status == true) {
                          deleteOldTag(tagname);
                        }
                      });
                      // For more information about handling dismissals please visit
                      // https://sweetalert2.github.io/#handling-dismissals
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                      Swal.fire(
                        'Cancelled',
                        'Your attribute is safe :)',
                        'error'
                      ).then((result) => {
                        setOldTags([...oldTag]);
                        dispatch({ type: CANCEL_REQUEST });
                        dispatch({ type: REMOVE_ERRORS });
                      });
                    } else if (result.isDismissed) {
                      setOldTags([...oldTag]);
                      dispatch({ type: CANCEL_REQUEST });
                      dispatch({ type: REMOVE_ERRORS });
                    }
                  });
                }
              });
            }
          }}
        >
          &#x2716;{' '}
        </span>
        {tagname}
      </div>
    );

    return <React.Fragment>{tag}</React.Fragment>;
  };

  const TagList = ({ tags, onDeleteTag }) => {
    if (tags.length) {
      let tagsUI = tags.map((tag, index) => {
        return (
          <Tag
            onDeleteTag={() => onDeleteTag(tag)}
            key={index}
            value={tag}
            tagname={'hello'}
          />
        );
      });
      return <div className="tag-list">{tagsUI}</div>;
    } else {
      return <div className="tag-list"></div>;
    }
  };

  const OldTagList = (oldTags) => {
    if (oldTag.length && termsArr.length) {
      let oldTagsId = [];
      termsArr.forEach((item) => {
        oldTagsId[item.option] = item._id;
      });
      let tagsUI = oldTag.map((tag, index) => {
        return <OldTag key={index} value={oldTagsId[tag]} tagname={tag} />;
      });
      return <div className="tag-list">{tagsUI}</div>;
    } else {
      return <div className="tag-list"></div>;
    }
  };

  const onAddTag = (tag) => {
    if (!tags.includes(tag) && !oldTag.includes(tag)) {
      setTags([...tags, tag]);
    } else {
      alert('Tags already exist');
    }
    console.log(tags, oldTag);
  };

  const onDeleteTag = (tag) => {
    if (window.confirm(`Are you sure, you want to delete ${tag} tag? `)) {
      let remainingTags = tags.filter((t) => {
        return t !== tag;
      });
      setTags([...remainingTags]);
    }
  };
  const deleteOldTag = (tag) => {
    let remainingOldTags = oldTag.filter((t) => {
      return t !== tag;
    });
    setOldTags([...remainingOldTags]);
  };

  const InputTag = (onAddTag, onDeleteTag, defaultTags, placeholder) => {
    const onKeyUp = (e) => {
      if (e.which === 188 || e.which === 13) {
        let input = e.target.value.trim().split(',');
        if (input.length === 0 || input[0] === '') return;
        onAddTag(input[0]);
        e.target.value = '';
      }
    };

    return (
      <div>
        <OldTagList tags={defaultTags} onDeleteTag={onDeleteTag} />
        <TagList tags={defaultTags} onDeleteTag={onDeleteTag} />
        <Input
          // style={iStyle}
          onKeyUp={(e) => onKeyUp(e)}
          type="textarea"
          placeholder={placeholder}
        />
      </div>
    );
  };

  /**************** use dispatch *********************/
  const dispatch = useDispatch();

  /**************** handle on change event *********************/
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /**************** handle on submit event *********************/
  const onSubmit = (e) => {
    formData['terms'] = tags;
    formData['deletedTags'] = deletedTags;
    e.preventDefault();
    edit(formData, navigate, attribute_id);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="12">
          <Card>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardHeader>
                <strong>Attribute</strong>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="name">
                        Name <span>*</span>
                      </Label>
                      <Input
                        required
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter name"
                        maxLength="50"
                        value={name}
                        readOnly={name === 'Color' ? true : false}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.name ? true : false}
                      />
                      <Errors current_key="name" key="name" />
                    </FormGroup>
                  </Col>
                  {/* <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="status">Select</Label>
                      <Input
                        required
                        type="select"
                        name="status"
                        id="status"
                        value={status}
                        onChange={(e) => onChange(e)}
                      >
                        <option value="0">Inactive</option>
                        <option value="1">Active</option>
                      </Input>
                    </FormGroup>
                  </Col> */}
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="terms">
                        Terms <span>*</span>
                      </Label>
                      {InputTag(
                        onAddTag,
                        onDeleteTag,
                        tags,
                        'Enter terms and use comma after enter desired terms'
                      )}
                      <Errors current_key="terms" key="terms" />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button
                  type="submit"
                  size="sm"
                  color="primary"
                  disabled={loading}
                >
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
                <Link to="/admin/attributes">
                  <Button type="reset" size="sm" color="danger">
                    <i className="fa fa-ban"></i> Cancel
                  </Button>
                </Link>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditAttribute.propTypes = {
  getAttributeById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired,
  attribute: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  attribute: state.attribute,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  getAttributeById,
  edit,
  deleteTag,
  deleteAttributeAndVariations,
})(EditAttribute);
