import {
    BUILD_SHOP_SUPPLY_LOADING,
    BUILD_SHOP_SUPPLY_CREATED,
    BUILD_SHOP_SUPPLY_CREATE_ERROR,
    BUILD_SHOP_SUPPLY_FAIL,
    BUILD_SHOP_SUPPLY_UPDATED,
    BUILD_SHOP_SUPPLY_UPDATE_ERROR,
    BUILD_SHOP_SUPPLY_DELETED,
    BUILD_SHOP_SUPPLY_DELETE_ERROR,
    BUILD_SHOP_SUPPLY_LIST_UPDATED,
    GET_BUILD_SHOP_SUPPLY_BY_ID,
    BUILD_SHOP_INVENTORY_LOADING,
    BUILD_SHOP_INVENTORY_UPLOADED,
    BUILD_SHOP_INVENTORY_ERROR,
    BUILD_SHOP_INVENTORY_FAIL
} from "actions/types";

const initalState = {
    buildShopSupplyList: {
        page: 1,
        data: [],
        count: 0,
    },
    currentBuildShopSupply: {},
    loading: false,
    isSubLoading: false,
};

export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
        case BUILD_SHOP_SUPPLY_LOADING:
            return {
                ...state,
                buildShopSupplyList: payload.is_sub_element ? state.buildShopSupplyList : initalState.buildShopSupplyList,
                currentBuildShopSupply: payload.is_sub_element
                    ? state.currentBuildShopSupply
                    : initalState.currentBuildShopSupply,
                loading: payload.is_loading ? payload.is_loading : false,
                isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
            };
        case BUILD_SHOP_INVENTORY_LOADING:
            return {
                ...state,
                loading: payload.is_loading ? payload.is_loading : false,
                isSubLoading: payload.is_sub_element ? payload.is_sub_element : false,
            };
        case BUILD_SHOP_SUPPLY_CREATED:
        case BUILD_SHOP_INVENTORY_UPLOADED:
        case BUILD_SHOP_INVENTORY_ERROR:
        case BUILD_SHOP_INVENTORY_FAIL:
            return {
                ...state,
                loading: false,
            };
        case BUILD_SHOP_SUPPLY_CREATE_ERROR:
            return {
                ...state,
                loading: false,
            };
        case BUILD_SHOP_SUPPLY_FAIL:
            return {
                ...state,
                loading: false,
                isSubLoading: false
            };
        case BUILD_SHOP_SUPPLY_UPDATED:
            return {
                ...state,
                currentBuildShopSupply: {},
                loading: false,
            };
        case BUILD_SHOP_SUPPLY_UPDATE_ERROR:
            return {
                ...state,
                loading: false,
            };
        case BUILD_SHOP_SUPPLY_DELETED:
            return {
                ...state,
                loading: false,
            };
        case BUILD_SHOP_SUPPLY_DELETE_ERROR:
            return {
                ...state,
                loading: false,
            };
        case BUILD_SHOP_SUPPLY_LIST_UPDATED:
            return {
                ...state,
                buildShopSupplyList: {
                    data: payload.data.buildShopSupplyList.data,
                    page: payload.data.buildShopSupplyList.metadata[0].current_page,
                    count: payload.data.buildShopSupplyList.metadata[0].totalRecord,
                },
                loading: false,
            };
        case GET_BUILD_SHOP_SUPPLY_BY_ID:
            return {
                ...state,
                currentBuildShopSupply: payload.data,
                loading: false,
            };
        default:
            return state;
    }
}
