import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PhoneNumber from 'react-number-format';
import { Link } from 'react-router-dom';

import { add } from 'actions/admin/inquiry';

import Errors from 'views/Notifications/Errors';
import Spinner from 'views/Spinner';

import { useNavigate } from 'react-router-dom';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { REMOVE_ERRORS } from 'actions/types';
import 'react-phone-number-input/style.css';
import PhoneInput, { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import extractNumber from "utils/extractNumber";
import { getCountryShort } from "utils/countryCode";

const AddInquiry = ({ add, history, errorList, loading }) => {
  /**************** use state for form data *********************/
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    ccode: "+1",
    phone: '',
    iute164_phone:'',
    email: '',
    message: '',
  });

  /**************** expload form data *********************/
  const { name, ccode, phone, iute164_phone, email, message } = formData;

  /**************** dispatch to remove error *********************/
  

  /**************** on Change event *********************/
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    
  };

  //########################## change event for phone number ##############################
  const onPhoneChange = number => {
    const phone = extractNumber(number, ccode);
    setFormData((form) => ({
      ...form,
      "phone": phone,
      "iute164_phone": number ?? '',
    }));
  }

  const onCountryChange = code => {
    setFormData((form) => ({
      ...form,
      "ccode": "+" + getCountryCallingCode(code ?? getCountryShort(ccode)),
    }));
  }
  
  /**************** on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    add(formData, navigate);
  };

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="Name">
                          Name <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="name"
                          name="name"
                          maxLength="50"
                          value={name}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.name ? true : false}
                        />
                        <Errors current_key="name" key="name" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="email">
                          Email <span>*</span>
                        </Label>
                        <Input
                          type="email"
                          id="email"
                          name="email"
                          maxLength="50"
                          value={email}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.email ? true : false}
                        />
                        <Errors current_key="email" key="email" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="phone">
                          Phone <span>*</span>
                        </Label>
                        {/* <PhoneNumber
                          style={{
                            borderColor: 'Black',
                            borderRadius: 4,
                          }}
                          type="tel"
                          pattern="[0-9]{10}"
                          className="form-control"
                          maxLength="10"
                          id="phone"
                          name="phone"
                          value={phone}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.phone ? true : false}
                        /> */}
                        <PhoneInput
                          className=""
                          autoComplete="tel"
                          withCountryCallingCode={true}
                          countryCallingCodeEditable={false}
                          smartCaret={true}
                          international={true}
                          defaultCountry="US"
                          value={iute164_phone}
                          name="phone"
                          addInternationalOption={false}
                          onChange={onPhoneChange}
                          onCountryChange={onCountryChange}
                        />
                        <Errors current_key="phone" key="phone" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="message">
                          Message <span>*</span>
                        </Label>
                        <Input
                          type="textarea"
                          id="message"
                          name="message"
                          maxLength="500"
                          value={message}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.message ? true : false}
                        />
                        <Errors current_key="message" key="message" />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary">
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>
                  <Link to="/admin/inquiries">
                    <Button type="reset" size="sm" color="danger">
                      <i className="fa fa-ban"></i> Cancel
                    </Button>
                  </Link>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

AddInquiry.propTypes = {
  add: PropTypes.func.isRequired,
  inquiry: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  inquiry: state.inquiry,
  errorList: state.errors,
  loading: state.inquiry.loading,
});

export default connect(mapStateToProps, { add })(AddInquiry);
