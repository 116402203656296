/* eslint-disable */
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'views/LoadingCellRenderer';
import Loading from '../../../utils/Loading';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  Label,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from 'reactstrap';

import Moment from 'react-moment';

import * as Constants from 'constants/index';
import noImagae from '../../../assets/productdummy.png';
import {
  getAllReviews,
  changeReviewStatus,
  getReviewDetailById,
} from 'actions/admin/product';

import CreateReview from 'views/Admin/Review/CreateReview';
import PerPageSelect from '../PerPageSelect';
import Spinner from 'views/Spinner';

const imageComponent = (params) => {
  return (
    <img
      src={
        params.data.image
          ? 'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/category/' +
          params.data.image
          : noImagae
      }
      className="preview-img"
      alt=""
    />
  );
};

const renderProductName = (params) => {
  if (params.data === undefined) {
    return <Loading />;
  } else if (
    params.data?.product_variation_sku &&
    params.data?.product_data?.length &&
    params.data?.category?.length
  ) {
    return (
      <div>
        {params.data?.product_data[0]?.product_title +
          '-' +
          params.data?.category[0]?.name +
          '-' +
          params.data?.product_variation_sku}
      </div>
    );
  } else {
    return <div>{params.data?.product_data[0]?.product_title}</div>;
  }
};

const renderProductVariation = (params) => {
  let variationData = '';
  if (params.data?.product_variation?.Color) {
    variationData = `Color :` + params.data?.product_variation?.Color + ', ';
  }
  if (params.data?.product_variation?.Flex) {
    variationData += 'Flex : ' + params.data?.product_variation?.Flex + ', ';
  }
  if (params.data?.product_variation?.Model) {
    variationData += 'Model : ' + params.data?.product_variation?.Model + ', ';
  }
  if (params.data?.product_variation?.Club) {
    variationData += 'Club : ' + params.data?.product_variation?.Club + ', ';
  }
  if (params.data?.product_variation?.Iron) {
    variationData += 'Iron : ' + params.data?.product_variation?.Iron + ', ';
  }
  return <div>{variationData}</div>;
};

const renderUsername = (params) => {
  if (params.data?.user_data) {
    return (
      <>
        {params.data?.user_data?.first_name
          ? params.data?.user_data?.first_name
          : ''}{' '}
        {params.data?.user_data?.last_name
          ? params.data?.user_data?.last_name
          : ''}
      </>
    );
  } else {
    return <></>;
  }
};

const renderRating = (params) => {
  if (params.data?.rating === 1) {
    return (
      <>
        <span className="fa fa-star star_checked"></span>
        <span className="fa fa-star"></span>
        <span className="fa fa-star"></span>
        <span className="fa fa-star"></span>
        <span className="fa fa-star "></span>
      </>
    );
  } else if (params.data?.rating === 2) {
    return (
      <>
        <span className="fa fa-star star_checked"></span>
        <span className="fa fa-star star_checked"></span>
        <span className="fa fa-star"></span>
        <span className="fa fa-star"></span>
        <span className="fa fa-star"></span>
      </>
    );
  } else if (params.data?.rating === 3) {
    return (
      <>
        <span className="fa fa-star star_checked"></span>
        <span className="fa fa-star star_checked"></span>
        <span className="fa fa-star star_checked"></span>
        <span className="fa fa-star"></span>
        <span className="fa fa-star"></span>
      </>
    );
  } else if (params.data?.rating === 4) {
    return (
      <>
        <span className="fa fa-star star_checked"></span>
        <span className="fa fa-star star_checked"></span>
        <span className="fa fa-star star_checked"></span>
        <span className="fa fa-star star_checked"></span>
        <span className="fa fa-star"></span>
      </>
    );
  } else if (params.data?.rating === 5) {
    return (
      <>
        <span className="fa fa-star star_checked"></span>
        <span className="fa fa-star star_checked"></span>
        <span className="fa fa-star star_checked"></span>
        <span className="fa fa-star star_checked"></span>
        <span className="fa fa-star star_checked"></span>
      </>
    );
  }
};

const renderReviewDate = (params) => {
  return (
    <div className="primary-Outer">
      <Moment
        format={process.env.REACT_APP_DISPLAY_DATE_FORMATE ?? 'MM/DD/YYYY'}
        locale="de"
      >
        {params.data?.created_at}
      </Moment>
      <span>
        <Moment format="HH:mm" locale="de">{params.data?.created_at}</Moment>
      </span>
    </div>
  );
};

// const renderPurchasedDate = (params) => {
//   if (params.data?.purchased_on?.length) {
//     return (
//       <div className="primary-Outer">
//         <Moment
//           format={process.env.REACT_APP_DISPLAY_DATE_FORMATE ?? 'MM/DD/YYYY'}
//           locale="de"
//         >
//           {params.data?.purchased_on[0]}
//           "date"
//         </Moment>
//         <span>
//           <Moment format="HH:mm" locale="de">{params.data?.purchased_on[0]}</Moment>
//         </span>
//       </div>
//     );
//   } else {
//     return <></>;
//   }
// };

const renderStatus = (params, changeStatus) => {
  return (
    <div>
      <Input
        type="select"
        name="status"
        id={params.data?._id}
        defaultValue={params.data?.status ? 1 : 0}
        onChange={(e, _a) => {
          changeStatus(params.data?._id, e.target.value);
        }}
      >
        <option
          value="0"
          selected={params.data?.status === false ? 'selected' : ''}
        >
          Un-Approved
        </option>
        <option
          value="1"
          selected={params.data?.status === true ? 'selected' : ''}
        >
          Approved
        </option>
      </Input>
    </div>
  );
};

const renderActions = (params, toggleViewDetailModal) => {
  return (
    <div>
      <Button
        type="button"
        size="sm"
        color="info"
        title="Details"
        onClick={(e) => toggleViewDetailModal(params.data?._id, params.data)}
      >
        <i className="fa fa-eye"></i>
      </Button>
    </div>
  );
};

const ReviewList = ({
  getAllReviews,
  reviewList,
  loading,
  subLoading,
  changeReviewStatus,
  getReviewDetailById,
}) => {
  const history = useNavigate();
  const location = useLocation();
  //################## Initillise sorting searching parameters by default values ###################
  const [sortingParams, setSortingParams] = useState(
    Object.keys(location?.state ?? {}).length
      ? { ...location.state, onLoad: true }
      : {
        limit: Constants.DEFAULT_PAGE_SIZE,
        page: 1,
        orderBy: 'created_at',
        ascending: 'desc',
        query: '',
        filter: {},
        onLoad: true,
      }
  );

  // to resolve useEffect dependency error

  //################## to get updated list ###################
  const [viewDetailModal, setViewDetailModal] = useState(false);
  const [modalDetails, setModalDetails] = useState();
  const [modalDetailsData, setModalDetailsData] = useState();

  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);

  const changeStatus = async (id, value) => {
    await changeReviewStatus(id, value);
    gridApi.purgeInfiniteCache();
  };

  const toggleViewDetailModal = async (reviewId, paramData) => {
    setModalDetails(reviewId);
    setModalDetailsData(paramData);
    setViewDetailModal(!viewDetailModal);
  };

  //#########################Colums defination start ###################
  const columns = [
    {
      field: 'product_data.0.product_title',
      headerName: 'Product Name',
      suppressSizeToFit: false,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      width: 250,
      // pass in additional parameters to the text filter
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      colSpan: (params) => {
        if (params.data === undefined) {
          return 9;
        } else {
          return 0;
        }
      },
      cellRenderer: (params) => renderProductName(params),
    },

    {
      field: 'product_variation',
      headerName: 'Product Variation',
      suppressSizeToFit: false,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      // sortable: true,
      // filter: 'agTextColumnFilter',
      width: 250,
      // pass in additional parameters to the text filter
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => renderProductVariation(params),
    },
    {
      field: 'inSensitiveUsername',
      headerName: 'User Name',
      suppressSizeToFit: false,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agTextColumnFilter',
      width: 120,
      // pass in additional parameters to the text filter
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => renderUsername(params),
    },
    {
      field: 'rating',
      headerName: 'Rating',
      suppressMovable: true,
      suppressSizeToFit: false,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agNumberColumnFilter',
      width: 100,
      // pass in additional parameters to the text filter
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.NUMBER_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
      cellRenderer: (params) => renderRating(params),
    },
    {
      field: 'review',
      headerName: 'Reviews',
      suppressSizeToFit: false,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      // sortable: true,
      filter: 'agTextColumnFilter',
      width: 250,
      // pass in additional parameters to the text filter
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.TEXT_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
      },
    },
    {
      field: 'created_at',
      headerName: 'Review Date',
      suppressSizeToFit: false,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      filter: 'agDateColumnFilter',
      width: 150,
      // pass in additional parameters to the text filter
      filterParams: {
        closeOnApply: true,
        filterOptions: Constants.DATE_FILTER_OPTIONS,
        suppressAndOrCondition: true,
        debounceMs: 800,
        buttons: ['reset', 'apply'],
      },
      cellRenderer: (params) => renderReviewDate(params),
    },
    // {
    //   field: 'purchased_on',
    //   headerName: ' Purchase Date',
    //   suppressSizeToFit: false,
    //   suppressMovable: true,
    //   cellClass: 'suppress-movable-col',
    //   sortable: true,
    //   filter: 'agDateColumnFilter',
    //   width: 150,
    //   // pass in additional parameters to the text filter
    //   filterParams: {
    //     closeOnApply: true,
    //     filterOptions: Constants.DATE_FILTER_OPTIONS,
    //     suppressAndOrCondition: true,
    //     debounceMs: 800,
    //     buttons: ['reset', 'apply'],
    //   },
    //   cellRenderer: (params) => renderPurchasedDate(params),
    // },

    {
      field: 'status',
      headerName: 'Status',
      suppressSizeToFit: false,
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      sortable: true,
      cellRenderer: (params) => renderStatus(params, changeStatus),
    },

    {
      field: '_id',
      headerName: 'Actions',
      suppressMovable: true,
      cellClass: 'suppress-movable-col',
      cellRenderer: (params) => renderActions(params, toggleViewDetailModal),
    },
  ];
  //#########################Colums defination end ###################

  //################ toggle category add/edit modal ############################
  const [categoryModal, setOpenCategoryModal] = useState(false);
  const toggleCategoryModal = useCallback(() => {
    setOpenCategoryModal(!categoryModal);
  });

  //################## START Set properties(options) of datatables ###################
  // const actions = (
  //   <div className="add-button-div specify-width">
  //     <Button color="primary" size="sm" onClick={toggleCategoryModal}>
  //       <i className="fa fa-plus"></i> Add New Category
  //     </Button>
  //     <CreateCategory
  //       isOpen={categoryModal}
  //       toggle={toggleCategoryModal}
  //     ></CreateCategory>
  //   </div>
  // );

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });

  const filterChanged = useCallback((params) => {
    const filterInstance = gridRef.current.api.getFilterInstance(
      params.columns[0].colId
    );
    if (filterInstance.appliedModel?.type === 'any') {
      setActiveClass('');
      gridRef.current.api.destroyFilter(params.columns[0].colId);
    }
  });

  const onPaginationChange = useCallback((pageSize) => {
    setSortingParams((initVals) => {
      return { ...initVals, limit: pageSize };
    });
    gridRef.current.api.gridOptionsWrapper.setProperty(
      'cacheBlockSize',
      pageSize
    );
    gridRef.current.api.infiniteRowModel.resetCache();
    gridRef.current.api.paginationSetPageSize(pageSize);
  });

  const customLoadingCellRenderer = useMemo(() => {
    return LoadingCellRenderer;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'Loading....',
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (Object.keys(sortingParams.filter).length) {
        gridApi.setFilterModel(sortingParams.filter);
      }
      const dataSource = {
        getRows: async (params) => {
          const page = gridApi.paginationGetCurrentPage() + 1;
          const limit = gridApi.paginationGetPageSize();

          const sortModel = params.sortModel.length
            ? params.sortModel[0]
            : null;
          const filterModel = params.filterModel ? params.filterModel : {};
          const customParams = {
            ...sortingParams,
            limit,
            orderBy: sortModel?.colId,
            ascending: sortModel?.sort,
            filter: filterModel,
            page,
          };

          const customData = await getAllReviews(customParams);

          if (customData?.data?.status) {
            console.log('Reviews', customData);
            params.successCallback(
              customData.data.response[0].data,
              customData.data.response[0].metadata[0].totalRecord
            );
            customParams.onLoad = false;
            setSortingParams({ ...customParams });
            history(location.pathname, { state: customParams });
          } else {
            params.successCallback([], 0);
          }
        },
      };
      gridApi.setDatasource(dataSource);
      gridApi.api?.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onFirstDataRendered = useCallback(() => {
    setTimeout(() => {
      gridRef.current.api.paginationGoToPage(sortingParams.page - 1);
    }, 1000);
  });

  const onPaginationChanged = useCallback((params) => {
    if (gridRef.current.api) {
      const page = gridApi?.paginationGetCurrentPage();
      setSortingParams({ ...sortingParams, page });
    }
  });
  return (
    <div className="animated fadeIn userTableList">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabletopsec">
                {/* {actions} */}
                <div className="rightpaneltop">
                  Review Per Page
                  <PerPageSelect
                    perPage={sortingParams.limit}
                    onPaginationChange={onPaginationChange}
                  />
                </div>
              </div>
              <div className="card w-100 p-0 border-0">
                <div className="card-body p-0 position-relative">
                  <div className="ag-theme-alpine">
                    <AgGridReact
                      defaultColDef={{
                        resizable: true,
                      }}
                      loadingCellRenderer={customLoadingCellRenderer}
                      loadingCellRendererParams={loadingCellRendererParams}
                      domLayout={'autoHeight'}
                      rowModelType={'infinite'}
                      columnDefs={columns}
                      pagination={true}
                      sizeColumnsToFit={true}
                      onFilterChanged={filterChanged}
                      onGridReady={onGridReady}
                      paginationPageSize={sortingParams.limit}
                      cacheBlockSize={sortingParams.limit}
                      cacheQuickFilter={true}
                      onFirstDataRendered={onFirstDataRendered}
                      onPaginationChanged={onPaginationChanged}
                      ref={gridRef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/*########### modal to display review detail ########################*/}

      <Modal isOpen={viewDetailModal} toggle={toggleViewDetailModal}>
        <ModalHeader toggle={toggleViewDetailModal}>Review Details</ModalHeader>
        <ModalBody>
          <Row>
            {modalDetails && (
              <Col>
                <Table bordered striped responsive size="sm">
                  {modalDetailsData && (
                    <tbody>
                      <tr>
                        <td>Product</td>
                        <td>
                          {modalDetailsData?.product_variation_sku &&
                            modalDetailsData?.product_variation_sku
                            ? modalDetailsData?.product_data[0]?.product_title +
                            '-' +
                            modalDetailsData?.category[0]?.name +
                            '-' +
                            modalDetailsData?.product_variation_sku
                            : modalDetailsData?.product_data[0]?.product_title}
                        </td>
                      </tr>
                      <tr>
                        <td>Customer Name</td>
                        <td>
                          {modalDetailsData?.user_data.first_name
                            ? modalDetailsData?.user_data.first_name
                              .charAt(0)
                              .toUpperCase() +
                            modalDetailsData?.user_data.first_name.slice(1)
                            : ''}{' '}
                          {modalDetailsData?.user_data.last_name
                            ? modalDetailsData?.user_data.last_name
                              .charAt(0)
                              .toUpperCase() +
                            modalDetailsData?.user_data.last_name.slice(1)
                            : ''}
                        </td>
                      </tr>
                      <tr>
                        <td>Rating</td>
                        <td>
                          {modalDetailsData?.rating === 1 && (
                            <>
                              <span className="fa fa-star star_checked"></span>
                              <span className="fa fa-star"></span>
                              <span className="fa fa-star"></span>
                              <span className="fa fa-star"></span>
                              <span className="fa fa-star "></span>
                            </>
                          )}
                          {modalDetailsData?.rating === 2 && (
                            <>
                              <span className="fa fa-star star_checked"></span>
                              <span className="fa fa-star star_checked"></span>
                              <span className="fa fa-star"></span>
                              <span className="fa fa-star"></span>
                              <span className="fa fa-star"></span>
                            </>
                          )}
                          {modalDetailsData?.rating === 3 && (
                            <>
                              <span className="fa fa-star star_checked"></span>
                              <span className="fa fa-star star_checked"></span>
                              <span className="fa fa-star star_checked"></span>
                              <span className="fa fa-star"></span>
                              <span className="fa fa-star"></span>
                            </>
                          )}
                          {modalDetailsData?.rating === 4 && (
                            <>
                              <span className="fa fa-star star_checked"></span>
                              <span className="fa fa-star star_checked"></span>
                              <span className="fa fa-star star_checked"></span>
                              <span className="fa fa-star star_checked"></span>
                              <span className="fa fa-star"></span>
                            </>
                          )}
                          {modalDetailsData?.rating === 5 && (
                            <>
                              <span className="fa fa-star star_checked"></span>
                              <span className="fa fa-star star_checked"></span>
                              <span className="fa fa-star star_checked"></span>
                              <span className="fa fa-star star_checked"></span>
                              <span className="fa fa-star star_checked"></span>
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Full Review</td>
                        <td>
                          <div style={{ width: "350px", overflowWrap: "break-word", overflow: "hidden" }}>
                            {modalDetailsData?.review}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="danger" onClick={toggleViewDetailModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

ReviewList.propTypes = {
  getAllReviews: PropTypes.func.isRequired,
  reviewList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  subLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.product.loading,
  reviewList: state.product.reviewList,
  subLoading: state.product.isSubLoading,
});

export default connect(mapStateToProps, {
  getAllReviews,
  changeReviewStatus,
  getReviewDetailById,
})(ReviewList);
