import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CKEditor } from 'ckeditor4-react';

import { createEmailTemplate } from 'actions/admin/emailTemplate';

import Errors from 'views/Notifications/Errors';
import Spinner from 'views/Spinner';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { REMOVE_ERRORS } from 'actions/types';
import { CKEditorConfig } from 'constants';

const CreateEmailTemplate = ({
  createEmailTemplate,
  history,
  errorList,
  loading,
}) => {
  /**************** use state for form data *********************/
  let navigate = useNavigate();
  const { sectionId, emailTemplateCategory_id } = useParams();
  const [formData, setFormData] = useState({
    subject: '',
    slug: '',
    description: '',
    dynamic_parameters: '',
  });

  /**************** expload form data *********************/
  const { subject, slug, description, dynamic_parameters } = formData;
  const dynamic_variables = [
    'app_name',
    'app_url',
    'copyright_year',
    'link',
    'name',
    'full_name',
    'password',
    'price',
    'email',
  ];

  /**************** dispatch to remove error *********************/

  /**************** on Change event *********************/
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handelDescriptionChange = (event) => {
    setFormData((ev) => ({
      ...ev,
      description: event.editor.getData(),
    }));

    //setFormData({ ...formData, description: event.editor.getData() });
  };

  /**************** on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    createEmailTemplate(
      formData,
      navigate,
      emailTemplateCategory_id,
      sectionId
    );
  };

  return (
    <div className="animated fadeIn">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="12">
            <Card>
              <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="slug">
                          Email Template Slug <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="slug"
                          name="slug"
                          maxLength="100"
                          value={slug}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.slug ? true : false}
                        />
                        <Errors current_key="slug" key="slug" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="subject">
                          Subject <span>*</span>
                        </Label>
                        <Input
                          type="text"
                          id="subject"
                          name="subject"
                          maxLength="100"
                          value={subject}
                          required
                          onChange={(e) => onChange(e)}
                          invalid={errorList.subject ? true : false}
                        />
                        <Errors current_key="subject" key="subject" />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="dynamic_parameters">
                          Dynamic Variables -
                        </Label>
                        <span style={{ marginLeft: '5px', fontSize: '10px' }}>
                          <span>Sample Dynamic Variables - </span>
                          {dynamic_variables.map((variable) => (
                            <span style={{ marginRight: '5px' }}>
                              {`{{${variable}}}`}
                              {','}
                            </span>
                          ))}
                        </span>
                        <Input
                          type="textarea"
                          id="dynamic_parameters"
                          name="dynamic_parameters"
                          maxLength="500"
                          value={dynamic_parameters}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.dynamic_parameters ? true : false}
                        />
                        <Errors
                          current_key="dynamic_parameters"
                          key="dynamic_parameters"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="description">
                          Body <span>*</span>
                        </Label>
                        <CKEditor
                          data={description}
                          id="description"
                          name="description"
                          config={CKEditorConfig}
                          onChange={(event) => handelDescriptionChange(event)}
                        />
                        <Errors current_key="description" key="description" />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button
                    type="submit"
                    size="sm"
                    color="primary"
                    disabled={loading}
                  >
                    <i className="fa fa-dot-circle-o"></i> Submit
                  </Button>

                  <Button
                    type="reset"
                    size="sm"
                    color="danger"
                    disabled={loading}
                    style={{ marginLeft: '10px' }}
                    onClick={() => navigate(-1)}
                  >
                    <i className="fa fa-ban"></i> Cancel
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

CreateEmailTemplate.propTypes = {
  createEmailTemplate: PropTypes.func.isRequired,
  emailTemplate: PropTypes.object,
  errorList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  emailTemplate: state.emailTemplate,
  errorList: state.errors,
  loading: state.emailTemplate.loading,
});

export default connect(mapStateToProps, { createEmailTemplate })(
  CreateEmailTemplate
);
