import { combineReducers } from 'redux';
//auth
import auth from './auth';
import alert from './alert';
//errors
import errors from './errors';
//admin
import user from './admin/user';
import product from './admin/product';
import productSelector from './admin/productSelector';
import emailTemplate from './admin/emailTemplate';
import emailTemplateCategories from './admin/emailTemplateCategories';
import emailTemplateSections from './admin/emailTemplateSections';
import cms from './admin/cms';
import home from './admin/home';
import news from './admin/news';
import additionalProduct from './admin/additionalProduct';
import newsLetter from './admin/newsLetter';
import inquiry from './admin/inquiry';
import setting from './admin/setting';
import socialSetting from './admin/socialSetting';
import carrierSetting from './admin/carrierSetting';
import profile from './admin/profile';
import category from './admin/category';
import subcategory from './admin/subcategory';
import dealerType from './admin/dealerType';
import dealer from './admin/dealer';
import attribute from './admin/attribute';
import specs from './admin/specs';
import blog from './admin/blog';
import coupon from './admin/coupon';
import imageGallery from './admin/imageGallery';
import videoGallery from './admin/videoGallery';
import dealerAddress from './admin/dealerAddress';
import shippingBox from './admin/shippingBox';
import userAddress from './admin/userAddress';
import order from './admin/order';
import orderShippingBoxes from './admin/orderShippingBoxes';
import fitting from './admin/fitting';
import fittingVariation from './admin/fittingVariation';
import packingSlip from './admin/packingSlip';
import staff from './admin/staff';
import smsTemplate from './admin/smsTemplate';

import commonActions from './commonActions';
import { LOGOUT } from 'actions/types';
import notify from './admin/notify';
import taxRate from './admin/taxRate';
import gearAttribute from './admin/GearProduct/gearAttribute';
import gearCategory from './admin/GearProduct/gearCategory';
import gearProduct from './admin/GearProduct/gearProduct';
import gearShippingBox from './admin/gearShippingBox';
import breadcrum from './admin/breadcrum';
import shippingAdditionals from './admin/shippingAdditionals';
import shippingCancelations from './admin/shippingCancelations';
import buildShopSupply from './admin/buildShopSupply';
import resource from './admin/resource';
import Loader from './admin/Loader';
import flexCode from './admin/flexCode';
import ironNumber from './admin/ironNumber';


// export default combineReducers({
const appReducer = combineReducers({
  auth,
  alert,
  user,
  product,
  productSelector,
  emailTemplate,
  cms,
  home,
  news,
  additionalProduct,
  newsLetter,
  blog,
  errors,
  inquiry,
  setting,
  profile,
  category,
  subcategory,
  dealerType,
  dealer,
  attribute,
  socialSetting,
  coupon,
  imageGallery,
  dealerAddress,
  shippingBox,
  userAddress,
  videoGallery,
  order,
  orderShippingBoxes,
  fitting,
  fittingVariation,
  commonActions,
  specs,
  packingSlip,
  staff,
  smsTemplate,
  notify,
  taxRate,
  gearAttribute,
  gearCategory,
  gearProduct,
  gearShippingBox,
  emailTemplateCategories,
  emailTemplateSections,
  breadcrum,
  shippingAdditionals,
  shippingCancelations,
  carrierSetting,
  buildShopSupply,
  resource,
  Loader,
  flexCode,
  ironNumber
});

const rootReducer = (state, action) => {
  // ############### Clear all data in redux store to initial on logout. #################
  if (action.type === LOGOUT) state = undefined;

  return appReducer(state, action);
};
export default rootReducer;
