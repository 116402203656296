/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMatch, useNavigate, useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Moment from 'react-moment';
import * as Constants from 'constants/index';
import { getOrderById, getOrderPaymentsByOrderId } from 'actions/admin/order';
import Spinner from 'views/Spinner';

const OrderLogs = ({
    getOrderPaymentsByOrderId,
    getOrderById,
    currentOrder,
    loader,
}) => {
    const match = useMatch('admin/orders/order-details/payments/:order_id');
    let i = 0;
    const { order_id: order_id } = useParams();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(async () => {
        setLoading(true);
        let res = await getOrderPaymentsByOrderId(order_id);
        if (res) {
            setData(res);
        }
        await getOrderById(match.params.order_id);
        setLoading(false);
    }, []);

    //################## END Set properties(options) of datatables ###################

    //#########################Colums defination start ###################

    return loader || loading ? (
        <Spinner />
    ) : (
        <div className="animated fadeIn userTableList">
            <div className="label">
                <h3>Order Payments ({currentOrder.order_id})</h3>
            </div>
            <table className="table table-striped border">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Activity</th>
                    </tr>
                </thead>
                <tbody>
                    {(data?.length > 0) ? data.map((item, index) => (
                        <tr key={`key-${index}`}>
                            <td>{index + 1}</td>
                            <td>{<p>{(item?.customer_name !== "undefined") ? item?.customer_name : "Admin"}</p>}</td>
                            <td>
                                {(item.status === "payment") ? <span>
                                    <label>${Number(item.amount).toFixed(2)} {" - "} {"Paid"}</label>
                                    {item?.payment_mode && <div>
                                        Payment Mode :
                                        <span className="time_formatting">
                                            {Constants.ORDER_LIST_PAYMENT_METHOD[item?.payment_mode]}
                                        </span>
                                    </div>}
                                    {item?.payment_comment && <div>
                                        Payment Comment :
                                        <span className="time_formatting">
                                            {item?.payment_comment}
                                        </span>
                                    </div>}
                                </span> : <span>
                                    <div> ${Number(item.amount).toFixed(2)} {" - "} {"Refunded "}</div>
                                </span>}
                                Date :
                                <span className="time_formatting">
                                    <Moment
                                        format={
                                            process.env.REACT_APP_DISPLAY_DATE_FORMATE ??
                                            'MM/DD/YYYY'
                                        }
                                        locale="de"
                                    >
                                        {item?.date}
                                    </Moment>
                                    <span className="time_formatting">
                                        <Moment format="HH:mm" locale="de">{item?.date}</Moment>
                                    </span>
                                </span>

                            </td>
                        </tr>
                    )) : <>
                        <tr>
                            <td colSpan="2">No Data Found...</td>
                        </tr>
                    </>

                    }
                </tbody>
            </table>
            <div>
                <Link to="/admin/orders">
                    <Button type="reset" size="sm" color="info">
                        <i className="fa fa-arrow-left"></i> Back
                    </Button>
                </Link>
            </div>
        </div>
    );
};

OrderLogs.propTypes = {
    getOrderById: PropTypes.func.isRequired,
    currentOrder: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    currentOrder: state.order.currentOrder,
    auth: state.auth,
    loader: state.order.order_loading,
});

export default connect(mapStateToProps, {
    getOrderPaymentsByOrderId,
    getOrderById,
})(OrderLogs);
