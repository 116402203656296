/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { CKEditor } from 'ckeditor4-react';

import { edit, getCMSById } from 'actions/admin/cms';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import Spinner from 'views/Spinner';
import Errors from 'views/Notifications/Errors';

import { REMOVE_ERRORS } from 'actions/types';

import { useNavigate } from 'react-router-dom';
import { CKEditorConfig } from 'constants';

const EditCMS = ({
  getCMSById,
  edit,
  cms: { currentCMS, loading },
  history,
  match,
  errorList,
}) => {
  /**************** use state for form data *********************/
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    page_title: '',
    page_header: '',
    slug: '',
    description: '',
    meta_description: '',
    oldImage: '', // old image name (thumbnail)
    selectedFile: '', // image file
    isNewFileSelected: 0,
    image: '', // object url of image (image src)
  });

  // to resolve useEffect dependency error

  /**************** use effect to get current attribute *********************/
  const { cms_id: cms_id } = useParams();
  useEffect(() => {


    if (cms_id) getCMSById(cms_id);
  }, [cms_id]);

  /**************** use effect to set form data from current attribute *********************/
  useEffect(() => {
    if (Object.keys(currentCMS).length > 0) {
      setFormData({
        ...currentCMS,
        image: !currentCMS.thumbnail
          ? ''
          : //process.env.REACT_APP_AWS_STORAGE_URL +
          'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/cms/' +
          currentCMS.thumbnail,
        oldImage: !currentCMS.thumbnail ? '' : currentCMS.thumbnail,
        isNewFileSelected: 0,
      });
    }
  }, [currentCMS]);

  /**************** expload from data *********************/
  const {
    page_title,
    page_header,
    slug,
    description,
    meta_description,
    // oldImage,
    image,
  } = formData;

  /**************** use dispatch *********************/


  /**************** handle on change event *********************/
  const onChange = (e) => {
    if (e.target.name === 'thumbnail') {
      let image_file = e.target.files[0];
      let image_url = URL.createObjectURL(image_file);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        selectedFile: image_file,
        image: image_url,
        isNewFileSelected: 1,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }

  };

  const handelDescriptionChange = (event) => {
    setFormData((ev) => ({
      ...ev,
      description: event.editor.getData(),
    }));
    //setFormData({ ...formData, description: event.editor.getData() });

  };

  /**************** handle on submit event *********************/
  const onSubmit = (e) => {
    e.preventDefault();
    edit(formData, navigate, cms_id);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="12">
          <Card>
            <Form className="form-horizontal" onSubmit={(e) => onSubmit(e)}>
              <CardBody>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="slug">
                        CMS Slug <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="slug"
                        name="slug"
                        maxLength="100"
                        value={slug}
                        onChange={(e) => onChange(e)}
                        readOnly
                        invalid={errorList.slug ? true : false}
                        disabled
                      />
                      <Errors current_key="slug" key="slug" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="page_title">
                        Subject <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="page_title"
                        name="page_title"
                        maxLength="100"
                        value={page_title}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.page_title ? true : false}
                      />
                      <Errors current_key="page_title" key="page_title" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="page_header">
                        Page Header <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="page_header"
                        name="page_header"
                        maxLength="100"
                        value={page_header}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.page_header ? true : false}
                      />
                      <Errors current_key="page_header" key="page_header" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="meta_description">Meta Description</Label>
                      <Input
                        type="textarea"
                        id="meta_description"
                        name="meta_description"
                        value={meta_description}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.meta_description ? true : false}
                      />
                      <Errors
                        current_key="meta_description"
                        key="meta_description"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="thumbnail">
                        Thumbnail<span>*</span>
                      </Label>
                      <Input
                        type="file"
                        id="thumbnail"
                        name="thumbnail"
                        onChange={(e) => onChange(e)}
                        multiple
                        invalid={errorList.image ? true : false}
                      />
                      <img src={image} className="preview-img" alt="" />
                      <Errors current_key="image" key="image" />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="description">
                        Body <span>*</span>
                      </Label>
                      <CKEditor
                        initData={description}
                        id="description"
                        name="description"
                        config={CKEditorConfig}
                        onChange={(event) => {
                          handelDescriptionChange(event);
                        }}
                        invalid={errorList.description ? true : false}
                      />
                      <Errors current_key="description" key="description" />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button type="submit" size="sm" color="primary">
                  <i className="fa fa-dot-circle-o"></i> Submit
                </Button>
                <Link to="/admin/cms">
                  <Button type="reset" size="sm" color="danger">
                    <i className="fa fa-ban"></i> Cancel
                  </Button>
                </Link>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EditCMS.propTypes = {
  getCMSById: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  cms: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  cms: state.cms,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  getCMSById,
  edit,
})(EditCMS);
