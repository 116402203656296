import store from "../store"
import { setLoading } from "../actions/admin/Loader"
const { dispatch } = store;
let resCount = [];
const onRequest = async (config) => {

    await dispatch(setLoading(true))
    resCount.push(1)
    return config
}
const onResponse = async (response) => {
    resCount.pop()
    if (resCount.length === 0) {
        await dispatch(setLoading(false))
    }
    return response
}

const onResponseError = async (error) => {
    resCount.pop()
    //if (resCount.length === 0) {
    await dispatch(setLoading(false))
    //}
    return Promise.reject(error)
}

export function setupInterceptorsTo(axiosInstance) {
    axiosInstance.interceptors.request.use(onRequest)
    axiosInstance.interceptors.response.use(onResponse, onResponseError)
    return axiosInstance
}