import axios from "axios";

import {
    CARRIER_SETTINGS_LOADING,
    CARRIER_SETTING_UPDATED,
    CARRIER_SETTING_UPDATE_ERROR,
    CARRIER_SETTING_FAIL,
    GET_CARRIER_SETTING,
    GET_CARRIER_SETTING_ERROR,
} from "actions/types";

import { CARRIER_SETTINGS_KEY } from "constants/index";

// Edit Setting
export const edit = (formData) => async (dispatch) => {
    try {
        dispatch({
            type: CARRIER_SETTINGS_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await axios.post(`/api/admin/carrier-setting`, formData, config);
        if (res.data.status === true) {
            let settings = await res.data.response;
            let key_value = {};
            CARRIER_SETTINGS_KEY.forEach((set_key) => {
                const setting = settings.find((setting) => setting.key === set_key);
                key_value[set_key] = setting
                    ? {
                        value: setting.value,
                        is_to_be_displayed: setting.is_to_be_displayed
                    }
                    : { value: "", is_to_be_displayed: true };
            });
            dispatch({
                type: CARRIER_SETTING_UPDATED,
                payload: {
                    message: res.data.message,
                    alertType: "success",
                    data: key_value,
                },
            });
            return true;
        } else {
            const errors = res.data.errors;
            let errorsList = [];
            if (errors) {
                await errors.forEach((error) => {
                    errorsList[error.param] = error.msg;
                });
            }
            dispatch({
                type: CARRIER_SETTING_UPDATE_ERROR,
                payload: {
                    message: res.data.message,
                    alertType: "danger",
                    errorsList,
                },
            });
            return false;
        }
    } catch (err) {
        dispatch({
            type: CARRIER_SETTING_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : "Something went wrong.",
                alertType: "danger",
                error: err,
            },
        });
        return false;
    }
};

// Get Setting
export const getSetting = () => async (dispatch) => {
    try {
        dispatch({
            type: CARRIER_SETTINGS_LOADING,
            payload: { is_loading: true, is_sub_element: false },
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await axios.get(`/api/admin/carrier-setting/`, config);

        if (res.data.status === true) {
            let settings = await res.data.response;
            let key_value = {};
            CARRIER_SETTINGS_KEY.forEach((set_key) => {
                const setting = settings.find((setting) => setting.key === set_key);
                key_value[set_key] = setting
                    ? {
                        value: setting.value,
                        is_to_be_displayed: setting.is_to_be_displayed
                    }
                    : { value: "", is_to_be_displayed: true };
            });
            dispatch({
                type: GET_CARRIER_SETTING,
                payload: {
                    message: res.data.message,
                    alertType: "success",
                    data: key_value,
                },
            });

            return true;
        } else {
            dispatch({
                type: GET_CARRIER_SETTING_ERROR,
                payload: {
                    message: res.data.message,
                    alertType: "danger",
                },
            });
        }
    } catch (err) {
        dispatch({
            type: CARRIER_SETTING_FAIL,
            payload: {
                message:
                    err.response && err.response.statusText
                        ? err.response.statusText
                        : "Something went wrong.",
                alertType: "danger",
                error: err,
            },
        });
        return false;
    }
};

