import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Input, Label, FormGroup, Button } from 'reactstrap';
import { DEALER_APPROVED_STATUS } from 'constants/index';

const CustomFilters = (props) => {
  let query;
  let dealer_type_id;
  let featured;
  let approved_status;

  const initialFilter = {
    query: '',
    dealer_type_id: '',
    featured: '',
    approved_status: '',
  };

  const [filter, setFilter] = useState(initialFilter);

  const handleClick = (e) => {
    if (e.target.type === 'checkbox') {
      setFilter({ ...filter, [e.target.name]: e.target.checked });
      var tempFilters = { ...filter, [e.target.name]: e.target.checked };
    } else {
      setFilter({ ...filter, [e.target.name]: e.target.value });
      tempFilters = { ...filter, [e.target.name]: e.target.value };
    }

    let final_query = '';
    Object.keys(tempFilters).forEach(function (key) {
      final_query = `${final_query}&${key}=${tempFilters[key]}`;
    });
    props.onSearch(final_query);
  };

  const handelReset = (e) => {
    //reset values on text and dropdown
    query.value = '';
    dealer_type_id.value = '';
    featured.checked = false;
    approved_status.value = '';
    //reset submiting filters
    setFilter(initialFilter);
    //rsend empty query
    props.onSearch('');
  };

  return (
    <div>
      <div className="reset_bt">
        <FormGroup check className="checkbox">
          <Input
            className="form-control"
            innerRef={(n) => (query = n)}
            type="text"
            placeholder="Search"
            name="query"
            id="query"
            onChange={(e) => handleClick(e)}
          />
        </FormGroup>

        <FormGroup check className="checkbox">
          <Input
            type="select"
            name="dealer_type_id"
            id="dealer_type_id"
            innerRef={(n) => (dealer_type_id = n)}
            onChange={(e) => handleClick(e)}
          >
            <option value={''}> Select Dealer Type </option>
            {props.dealerTypeList
              ? props.dealerTypeList.map((fieldData, index) => {
                return (
                  <option key={index} value={fieldData._id}>
                    {fieldData.name}
                  </option>
                );
              })
              : ''}
          </Input>
        </FormGroup>

        <FormGroup check className="checkbox">
          <Input
            type="select"
            name="approved_status"
            id="approved_status"
            innerRef={(n) => (approved_status = n)}
            onChange={(e) => handleClick(e)}
          >
            <option value={''}> {'All Status'} </option>
            {Object.keys(DEALER_APPROVED_STATUS).map(function (key) {
              return (
                <option key={key} value={key}>
                  {DEALER_APPROVED_STATUS[key]}
                </option>
              );
            })}
          </Input>
        </FormGroup>

        <FormGroup check className="checkbox">
          <Input
            className="form-check-input"
            type="checkbox"
            id="featured"
            name="featured"
            innerRef={(n) => (featured = n)}
            value="1"
            onChange={(e) => handleClick(e)}
          />
          <Label check className="form-check-Label" htmlFor="featured">
            Featured
          </Label>
          <Button
            className="mr-05 reset-btn"
            size="sm"
            color="danger"
            onClick={(e) => handelReset(e)}
          >
            Reset Filters
          </Button>
        </FormGroup>
      </div>
    </div>
  );
};
CustomFilters.propTypes = {
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  loading: state.user.loading,
});

export default connect(mapStateToProps)(CustomFilters);
