import axios from 'axios';

import {
  EMAIL_TEMPLATE_SECTION_LOADING,
  EMAIL_TEMPLATE_SECTION_CREATED,
  EMIAL_TEMPLATE_SECTION_CREATE_ERROR,
  EMAIL_TEMPLATE_SECTION_FAIL,
  EMAIL_TEMPLATE_SECTION_UPDATED,
  EMIAL_TEMPLATE_SECTION_UPDATE_ERROR,
  EMAIL_TEMPLATE_SECTION_DELETED,
  EMAIL_TEMPLATE_SECTION_DELETE_ERROR,
  EMAIL_TEMPLATE_SECTION_LIST_UPDATED,
  GET_EMAIL_TEMPLATE_SECTION_BY_ID,
  EMAIL_TEMPLATE_SECTION_CHANGE_STATUS,
  EMAIL_TEMPLATE_SECTION_CHANGE_STATUS_ERROR,
} from 'actions/types';

// Create Email Template Section
export const create = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: EMAIL_TEMPLATE_SECTION_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      '/api/admin/emailTemplateSection/create',
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: EMAIL_TEMPLATE_SECTION_CREATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: EMIAL_TEMPLATE_SECTION_CREATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: EMAIL_TEMPLATE_SECTION_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Edit Email Template Section
export const edit = (formData, emailTemplateSection_id) => async (dispatch) => {
  try {
    dispatch({
      type: EMAIL_TEMPLATE_SECTION_LOADING,
      payload: { is_loading: true, is_sub_element: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.post(
      `/api/admin/emailTemplateSection/${emailTemplateSection_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch({
        type: EMAIL_TEMPLATE_SECTION_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return true;
    } else {
      const errors = res.data.errors;
      let errorsList = [];
      if (errors) {
        await errors.forEach((error) => {
          errorsList[error.param] = error.msg;
        });
      }
      dispatch({
        type: EMIAL_TEMPLATE_SECTION_UPDATE_ERROR,
        payload: {
          message: res.data.message,
          alertType: 'danger',
          errorsList,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: EMAIL_TEMPLATE_SECTION_FAIL,
      payload: {
        message:
          err.response && err.response.statusText
            ? err.response.statusText
            : 'Something went wrong.',
        alertType: 'danger',
        error: err,
      },
    });
  }
};

// Delete Email Template Section
export const deleteEmailTemplateSection =
  (emailTemplateSection_id) => async (dispatch) => {
    try {
      dispatch({
        type: EMAIL_TEMPLATE_SECTION_LOADING,
        payload: { is_loading: true, is_sub_element: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.delete(
        `/api/admin/emailTemplateSection/${emailTemplateSection_id}`,
        config
      );
      if (res.data.status === true) {
        dispatch({
          type: EMAIL_TEMPLATE_SECTION_DELETED,
          payload: {
            message: res.data.message,
            alertType: 'success',
            data: res.data.response,
          },
        });
        return true;
      } else {
        dispatch({
          type: EMAIL_TEMPLATE_SECTION_DELETE_ERROR,
          payload: {
            message: res.data.message,
            alertType: 'danger',
            error: res.data.errors,
          },
        });
        return false;
      }
    } catch (err) {
      dispatch({
        type: EMAIL_TEMPLATE_SECTION_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };

//get Email Template Section list

export const getEmailTemplateSectionList =
  (emailTemplateParams) => async (dispatch) => {
    try {
      dispatch({
        type: EMAIL_TEMPLATE_SECTION_LOADING,
        payload: { is_loading: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: emailTemplateParams,
      };
      const res = await axios.get(`/api/admin/emailTemplateSection/`, config);
      dispatch({
        type: EMAIL_TEMPLATE_SECTION_LIST_UPDATED,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: {
            emailTemplateParams: emailTemplateParams,
            emailTemplateSectionList: res.data.response[0],
          },
        },
      });
      return res;
    } catch (err) {
      dispatch({
        type: EMAIL_TEMPLATE_SECTION_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };
//get email template section by id

export const getEmailTemplateSectionById =
  (emailTemplateSection_id) => async (dispatch) => {
    try {
      dispatch({
        type: EMAIL_TEMPLATE_SECTION_LOADING,
        payload: { is_loading: true },
      });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.get(
        `/api/admin/emailTemplateSection/${emailTemplateSection_id}`,
        config
      );
      await dispatch({
        type: GET_EMAIL_TEMPLATE_SECTION_BY_ID,
        payload: {
          message: res.data.message,
          alertType: 'success',
          data: res.data.response,
        },
      });
      return res;
    } catch (err) {
      dispatch({
        type: EMAIL_TEMPLATE_SECTION_FAIL,
        payload: {
          message:
            err.response && err.response.statusText
              ? err.response.statusText
              : 'Something went wrong.',
          alertType: 'danger',
          error: err,
        },
      });
    }
  };
