import React, { useState } from 'react';


import { connect } from 'react-redux';
import { Input, FormGroup } from 'reactstrap';

const CustomFilters = (props) => {
  

  const initialFilter = {
    query: '',
  };

  const [filter, setFilter] = useState(initialFilter);

  const handleClick = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
    let tempFilters = { ...filter, [e.target.name]: e.target.value };

    let final_query = '';
    Object.keys(tempFilters).forEach(function (key) {
      final_query = `${final_query}&${key}=${tempFilters[key]}`;
    });
    props.onSearch(final_query);
  };

  return (
    <div>
      <div className="reset_bt">
        <FormGroup check className="checkbox">
          <Input
            className="form-control"
            // innerRef={(n) => (query = n)}
            type="text"
            placeholder="Search"
            name="query"
            id="query"
            onChange={(e) => handleClick(e)}
          />
        </FormGroup>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(CustomFilters);
